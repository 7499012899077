/* eslint-disable security/detect-object-injection */
import { formatDay, isPerfectEmpty, startDay, toLower } from '@/core/helpers';
import { cloneDeep, isArray, isDate, isObject } from 'lodash';
import { FIELD_TYPES } from './constant';
import { paginationFields } from './pagination.filter';
import { sortFields } from './sort.filter';
export const getDefaultFilterValues = (fields) => {
  const filters = {};
  fields.map((field) => {
    const { key, defaultValue } = field;
    filters[key] = defaultValue;
  });
  return filters;
};

export const getFilterFieldTypes = (fields) => {
  const fieldTypes = {};
  fields.map((field) => {
    const { key, fieldType } = field;
    fieldTypes[key] = fieldType;
  });
  return fieldTypes;
};

export const isArrayDate = (object) => {
  if (isArray(object)) {
    return object.every((item) => isDate(item));
  }
  return false;
};

export const transformDateArray = (dates) => {
  return dates.map((d) => formatDay(d));
};

export const buildFilterQueryParams = (filters) => {
  const keys = Object.keys(filters);
  const query = {};
  keys.map((k) => {
    if (!isPerfectEmpty(filters[k])) {
      if (isObject(filters[k]) && !isArray(filters[k]) && !isDate(filters[k])) {
        query[k] = JSON.stringify(filters[k]);
      } else if (isDate(filters[k])) {
        query[k] = startDay(filters[k]);
      } else if (isArrayDate(filters[k])) {
        query[k] = transformDateArray(filters[k]).toString();
      } else {
        query[k] = filters[k].toString();
      }
    } else {
      // query[k] = filters[k];
    }
  });
  return query;
};

export const buildSortQueryParams = (sort) => {
  if (sort.orderField != '' && sort.orderBy != '') {
    return { sort: `${sort.orderField}:${sort.orderBy}` };
  }
  return {};
};

const parseField = ({ value, fieldType }) => {
  switch (fieldType) {
    case FIELD_TYPES.String:
      return value;
    case FIELD_TYPES.Int:
      return parseInt(value);
    case FIELD_TYPES.Float:
      return parseFloat(value);
    case FIELD_TYPES.ArrayInt:
      return value.split(',').map((v) => parseInt(v));
    case FIELD_TYPES.ArrayFloat:
      return value.split(',').map((v) => parseFloat(v));
    case FIELD_TYPES.ArrayString:
      return value.split(',');
    case FIELD_TYPES.Date:
      return new Date(value);
    case FIELD_TYPES.ArrayDate:
      return value.split(',').map((v) => new Date(v));
    case FIELD_TYPES.Boolean:
      return value === 'true';
    case FIELD_TYPES.Object:
      return JSON.parse(value);
    default:
      return value;
  }
};

export const buildFilterFromQuery = ({ defaultValues, fieldTypes }, query) => {
  const filters = cloneDeep(defaultValues);
  const keys = Object.keys(filters);
  for (let i = 0; i < keys.length; i++) {
    const k = keys[i];
    const fieldType = fieldTypes[k];
    if (!isPerfectEmpty(query[k])) {
      filters[k] = parseField({ value: query[k], fieldType });
    }
  }
  return filters;
};

export const cloneSortFilterFields = ({ orderField, orderBy }) => {
  const fields = cloneDeep(sortFields);
  return fields.map((field) => {
    if (field.key === 'orderField' && field.value != '') {
      return {
        ...field,
        defaultValue: orderField,
      };
    }
    if (field.key == 'orderBy' && field.value != '') {
      return {
        ...field,
        defaultValue: orderBy,
      };
    }
    return field;
  });
};

export const clonePaginationFields = () => {
  return cloneDeep(paginationFields);
};

export const searchOnFilterList = (items, term) => {
  const ItemList = [];
  if (term) {
    items.forEach((item) => {
      ItemList.push(...item.groupValues);
    });
    return ItemList.filter((item) => toLower(item.text).includes(toLower(term)));
  }
  return items;
};

export const searchOnFilterListByKey = (items, key) => {
  const ItemList = [];
  if (key) {
    items.forEach((item) => {
      ItemList.push(...item.groupValues);
    });
    const list = ItemList.filter((item) => toLower(item.key) === toLower(key));
    return list.length > 0 ? list[0] : null;
  }
  return null;
};
