<template>
  <div class="legend-search-wrapper">
    <div @click="isShow = !isShow" class="toggle-legend-btn">
      <span v-if="isShow">{{ $t('pages/case/CaseManagement/List/LegendSearch.hide') }}</span>
      <span v-else>{{ $t('pages/case/CaseManagement/List/LegendSearch.show') }}</span>
      <md-icon :class="!isShow ? 'is-hide' : ''">expand_less</md-icon>
    </div>
    <div class="legend-list-wrapper" v-show="isShow">
      <ul class="legend-list">
        <li>
          <md-icon class="grey-color">block</md-icon>
          {{ $t('pages/case/OcrUploadHistory/List/LegendSearch.Failed') }}
        </li>
        <li>
          <md-icon class="red-color">pause_circle</md-icon>
          {{ $t('pages/case/OcrUploadHistory/List/LegendSearch.inProgress') }}
        </li>
        <li>
          <md-icon class="status-invited">check_circle</md-icon>
          {{ $t('pages/case/OcrUploadHistory/List/LegendSearch.fullyCompleted') }}
        </li>
        <li>
          <md-icon class="status-enabled">check_circle</md-icon>
          {{ $t('pages/case/OcrUploadHistory/List/LegendSearch.partiallyCompleted') }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {},

  data() {
    return {
      isShow: true,
    };
  },
};
</script>
<style lang="scss"></style>
