<template>
  <md-dialog :md-close-on-esc="true" :md-active.sync="isVisible" @md-clicked-outside="close" class="lims-modal">
    <div class="dialog-icon-close" @click="close"><md-icon>close</md-icon></div>
    <md-dialog-title>{{ $t('entities/modals/ModalAuthorise/chooseAMode') }}</md-dialog-title>
    <md-dialog-content>
      <div class="choose-mode-type-padding">
        <input
          type="radio"
          id="provisionallyReported"
          name="chooseMode"
          value="onProvisionallyReported"
          v-model="chooseMode"
          checked
        />
        <label for="provisionallyReported">{{ $t('entities/modals/ModalAuthorise/provisionallyReported') }}</label>
      </div>
      <p v-if="chooseMode === 'onProvisionallyReported'" style="text-align: center">
        {{ $t('entities/modals/ModalAuthorise/provisionallyReportedNote') }}
      </p>
      <div class="choose-mode-type-padding">
        <input type="radio" id="reported" name="chooseMode" value="onReported" v-model="chooseMode" checked />
        <label for="reported">{{ $t('entities/modals/ModalAuthorise/reported') }}</label>
      </div>
      <p v-if="chooseMode === 'onReported'" style="text-align: center">
        {{ $t('entities/modals/ModalAuthorise/reportedNote') }}
      </p>
    </md-dialog-content>
    <md-dialog-actions>
      <md-button @click="close" class="lims-form-button">{{ $t('global/button/button.cancel') }}</md-button>
      <md-button @click="onContinue" class="lims-form-button md-primary">{{
        $t('global/button/button.continue')
      }}</md-button>
    </md-dialog-actions>
  </md-dialog>
</template>

<script>
import modalMixins from '@/components/Lims/modals/modal.mixins';
import { constantService } from '@/services';

export default {
  mixins: [modalMixins],
  data() {
    return {
      chooseMode: null,
    };
  },
  methods: {
    async open(val) {
      this.isVisible = true;
      const hasPendingAlwSo = val;
      const { reportingStatusId } = this.$store.getters['config/$appConfig'];
      const constantValue = await constantService.getConstantValue();
      const settingReportingStatus = constantValue?.data.userSettingReportingStatus;
      if (settingReportingStatus && settingReportingStatus.provisionallyReportedStatus.id == reportingStatusId) {
        this.chooseMode = 'onProvisionallyReported';
      }
      if (settingReportingStatus && settingReportingStatus.reportedStatus.id == reportingStatusId) {
        this.chooseMode = hasPendingAlwSo ? 'onProvisionallyReported' : 'onReported';
      }
    },

    onContinue() {
      this.$emit('onContinue', this.chooseMode);
    },
  },
};
</script>

<style lang="scss">
.choose-mode-type-padding {
  padding: 20px 0 0 100px;
}
</style>
