<template>
  <md-dialog :md-close-on-esc="true" :md-active.sync="isVisible" @md-clicked-outside="close" class="lims-modal">
    <div class="dialog-icon-close" @click="close"><md-icon>close</md-icon></div>
    <div class="lims-form dialog-content">
      <p class="text-center" style="margin-top: 50px">
        {{ $t('components/lims/modals/ModalConfirmSelectCorrectSnomed.contentTxt') }}
      </p>
    </div>
    <md-dialog-actions>
      <md-button @click="close" class="lims-form-button">{{ $t('global/button/button.ok') }}</md-button>
    </md-dialog-actions>
  </md-dialog>
</template>

<script>
import modalMixins from './modal.mixins';

export default {
  mixins: [modalMixins],
};
</script>

<style lang="scss"></style>
