<template>
  <md-dialog
    :md-close-on-esc="true"
    :md-active.sync="isVisible"
    :mdClickOutsideToClose="false"
    class="lims-modal confirm-password-modal-z-index"
  >
    <div class="icon-update"><md-icon>update</md-icon></div>
    <md-dialog-title>{{ $t('component/lims/modals/confirmPassword/dialogTitle') }}</md-dialog-title>
    <div class="lims-form dialog-content">
      <p class="text-center">{{ $t('component/lims/modals/confirmPassword/dialogContent') }}</p>
      <lims-field :model="formData" :schema="checkCodeSchema" field="password" :view-mode="'only'">
        <md-input
          slot="field"
          ref="confirmPassword"
          @keyup.enter="onClick"
          v-model="formData.password"
          type="password"
        ></md-input>
      </lims-field>
    </div>
    <md-dialog-actions>
      <md-button :disabled="!formData.password" @click="onClick" class="lims-form-button md-primary">{{
        $t('component/lims/modals/confirmPassword/resumeBtn')
      }}</md-button>
      <div class="log-out-text">
        <p v-html="$t('component/lims/modals/confirmPassword/logoutText', { sessionTime: sessionTime })"></p>
      </div>
    </md-dialog-actions>
  </md-dialog>
</template>

<script>
import { getCookie } from '@/core/helpers';
import modalMixins from './modal.mixins';
import { getCheckCodeSchema } from '@/schemas/check-code.schema';
import { LOCAL_STORAGE_KEYS } from '@/core/constants';

export default {
  name: 'ModalConfirmPassword',

  mixins: [modalMixins],

  props: {
    showPasswordError: {
      type: Boolean,
      default: false,
    },
    isWrongPassword: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      formData: {
        password: '',
      },
      enabledCountDown: false,
      sessionTime: 0,
    };
  },

  computed: {
    checkCodeSchema() {
      return getCheckCodeSchema(this.isWrongPassword);
    },
  },

  watch: {
    sessionTime() {
      const isEnablePasswordCheck = getCookie(LOCAL_STORAGE_KEYS.ENABLE_PASSWORD_CHECK) || false;
      if (!isEnablePasswordCheck) {
        this.$emit('onContinue');
        return;
      }
      if (this.enabledCountDown && this.sessionTime > 0) {
        setTimeout(() => {
          this.sessionTime--;
          this.$emit('onSessionTimeChanged', { leftTimeForPasswordCheck: this.sessionTime });
        }, 1000);
      }
      if (this.enabledCountDown && this.sessionTime <= 0) {
        this.$emit('onSessionExpired');
      }
    },
  },

  methods: {
    stopCountDown() {
      this.enabledCountDown = false;
      this.sessionTime = 0;
    },
    startCountDown({ leftTimeForPasswordCheck }) {
      this.enabledCountDown = true;
      this.sessionTime = leftTimeForPasswordCheck;
    },
    onClick() {
      this.$emit('onResume', this.formData);
    },
  },
};
</script>

<style lang="scss">
.icon-update {
  padding-top: 20px;
  text-align: center;
}
.confirm-password-modal-z-index {
  z-index: 111;
}
</style>
