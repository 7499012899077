<template>
  <div class="peding-case-path-list">
    <lims-collapse-custom-title
      :wrapperClass="'case-collapse double-reporting-collapse'"
      :collapse="['']"
      icon="keyboard_arrow_down"
      :is-collapsed="totalItem == 0 || isCollapsed"
      :expand-collapse-block-name="'PendingCasesCollapse'"
    >
      <template slot="collapseTitle">
        <div class="collapse-title-custom">
          <div class="left-side-collapse">
            {{ $t('pages/Case/CaseManagement/List/PendingCasePathList.collapseTitle') }}
            <lims-tooltip
              class="title-infor"
              :content="$t('pages/Case/CaseManagement/List/PendingCasePathList.collapseTitleTooltip')"
            ></lims-tooltip>
          </div>
          <div class="right-side-collapse">{{ totalItem }} cases</div>
        </div>
      </template>

      <template slot="md-collapse-pane-1">
        <md-table class="paginated-table table-striped table-hover lims-table case-list-tbl pending-cases-path-tbl">
          <div class="table-fix-header">
            <thead>
              <md-table-row>
                <md-table-head v-for="(col, index) in colTabl" :key="index" :class="col.class">
                  <div @mouseover="hoverIn(index)" @mouseout="hoverOut()" v-html="getColumnName(col)"></div>
                </md-table-head>
              </md-table-row>
            </thead>
            <tbody>
              <md-table-row v-for="(item, index) in caseList" :key="`case-${index}`" :class="checkRowHighlight(item)">
                <md-table-cell class="caseRef-labRef-column">
                  {{ item.caseReference }}
                  <md-button v-if="item.caseIssues.length > 0" class="md-just-icon md-warning md-simple">
                    <issue-information :case-issues="item.caseIssues"></issue-information>
                  </md-button>
                  <br />
                  {{ item.labReference }}
                </md-table-cell>
                <md-table-cell>{{ item.patientFullname }}</md-table-cell>
                <md-table-cell>{{ item.clinicName }}<br />{{ item.clinicianName }}</md-table-cell>
                <md-table-cell>{{ item.pathologistName }}</md-table-cell>
                <md-table-cell>{{ item.specimenName }}</md-table-cell>
                <md-table-cell class="date-column">
                  {{ item.procedureDate ? formatDateTimeGlobalNotIncludeTime(item.procedureDate, true) : '' }}
                </md-table-cell>
                <md-table-cell class="date-column">
                  {{ item.procedureLabEntry ? formatDateTimeGlobalNotIncludeTime(item.procedureLabEntry) : '' }}
                </md-table-cell>
                <md-table-cell class="date-column">
                  {{ item.reviewedDate ? formatDateTimeGlobalNotIncludeTime(item.reviewedDate) : '' }}
                </md-table-cell>
                <md-table-cell class="ALW-column">
                  <md-icon v-if="item.caseAlwStatusId == ALW_SO_STATUS.REQUESTED" class="status-disabled"
                    >adjust</md-icon
                  >
                  <md-icon v-if="item.caseAlwStatusId == ALW_SO_STATUS.PARTIALLY_COMPLETED" class="status-invited"
                    >adjust</md-icon
                  >
                  <md-icon v-if="item.caseAlwStatusId == ALW_SO_STATUS.COMPLETED" class="status-enabled"
                    >adjust</md-icon
                  >
                  <md-icon v-if="item.caseAlwStatusId == ALW_SO_STATUS.CANCELLED">adjust</md-icon>
                </md-table-cell>
                <md-table-cell class="SO-column">
                  <md-icon v-if="item.caseSoStatusId == ALW_SO_STATUS.REQUESTED" class="status-disabled"
                    >adjust</md-icon
                  >
                  <md-icon v-if="item.caseSoStatusId == ALW_SO_STATUS.COMPLETED" class="status-enabled">adjust</md-icon>
                  <md-icon v-if="item.caseSoStatusId == ALW_SO_STATUS.CANCELLED">adjust</md-icon>
                </md-table-cell>
                <md-table-cell class="status-column">
                  <md-icon v-if="item.statusId == STATUSES.LAB" class="black-color">hourglass_empty</md-icon>
                  <md-icon v-if="item.statusId == STATUSES.PATH" class="black-color">hourglass_top</md-icon>
                  <md-icon v-if="item.statusId == STATUSES.PROVISIONALLY_REPORTED" class="grey-color"
                    >hourglass_bottom</md-icon
                  >
                  <md-icon v-if="item.statusId == STATUSES.REPORTED" class="black-color">hourglass_bottom</md-icon>
                </md-table-cell>
                <md-table-cell class="actions-column">
                  <div v-if="isShowResponseToIssueIcon(item)" class="action-item">
                    <md-button class="md-just-icon md-simple" @click.native="handleAction('responseToIssue', item)">
                      <md-icon>bug_report</md-icon>
                    </md-button>
                  </div>
                </md-table-cell>
              </md-table-row>
            </tbody>
          </div>
        </md-table>
        <slot name="pagination"></slot>
      </template>
    </lims-collapse-custom-title>
  </div>
</template>
<script>
import CaseListMixin from '@/pages/Case/CaseManagement/List/caseList.mixins';
import IssueInformation from '@/pages/Case/CaseManagement/Components/IssueInformation';
import { CASE_COLLAPSE_NAME } from '@/core/constants';

export default {
  name: 'PendingCasePathList',
  mixins: [CaseListMixin],
  components: {
    IssueInformation,
  },
  props: {
    caseList: {
      required: false,
      default: function () {
        return [];
      },
    },
    totalItem: {
      required: false,
    },
  },
  data() {
    return {
      caseCollapseName: CASE_COLLAPSE_NAME.PENDING_CASE,
      colTabl: [
        {
          name: ['pages.case.caseManagement/list.caseRef', 'pages.case.caseManagement/list.labRef'],
          key: 'caseReference',
          sort: false,
          class: 'caseRef-labRef-column',
        },
        {
          name: 'pages.case.caseManagement/list.patient',
          key: 'patientFullname',
          sort: false,
          class: 'patient-column',
        },
        {
          name: ['pages.case.caseManagement/list.clinic', 'pages.case.caseManagement/list.clinician'],
          key: 'clinicName',
          sort: false,
          class: 'clinic-column',
        },
        {
          name: 'pages.case.caseManagement/list.pathologist',
          key: 'pathologistName',
          sort: false,
          class: 'pathologist-column',
        },
        {
          name: 'pages.case.caseManagement/list.specimenType',
          key: 'specimenName',
          sort: false,
          class: 'specimen-column',
        },
        {
          name: 'pages.case.caseManagement/list.Procedure',
          key: 'procedure',
          sort: false,
          class: 'date-column',
        },
        {
          name: 'pages.case.caseManagement/list.labEntry',
          key: 'procedureLabEntry',
          sort: false,
          class: 'date-column',
        },
        {
          name: 'pages.case.caseManagement/list.reviewedDate',
          key: 'reviewedDate',
          sort: false,
          class: 'date-column',
        },
        {
          name: 'pages.case.caseManagement/list.ALW',
          key: 'caseAlwStatus',
          sort: false,
          class: 'ALW-column',
        },
        {
          name: 'pages.case.caseManagement/list.SO',
          key: 'caseSoStatus',
          sort: false,
          class: 'SO-column',
        },
        {
          name: 'pages.case.caseManagement/list.status',
          key: 'statusName',
          sort: false,
          class: 'status-column',
        },
        {
          name: 'global/pages/list.actions',
          key: 'actions',
          sort: false,
          class: 'actions-column',
        },
      ],
      hover: '',
      orderState: {
        orderField: 'caseReference',
        orderBy: 'asc',
      },
    };
  },
};
</script>
<style lang="scss">
.lims-table.pending-cases-path-tbl .table-fix-header th,
.lims-table.pending-cases-path-tbl .table-fix-header td {
  width: 100px;
  min-width: 100px;
}
</style>
