<template>
  <md-dialog :md-close-on-esc="false" :md-active.sync="isVisible" :md-click-outside-to-close="false" class="lims-modal">
    <md-dialog-title></md-dialog-title>
    <div class="lims-form dialog-content">
      <p class="text-center">{{ message }}</p>
    </div>
    <md-dialog-actions>
      <md-button @click="onClick" class="lims-form-button md-primary">{{ $t('global/button/button.ok') }}</md-button>
    </md-dialog-actions>
  </md-dialog>
</template>

<script>
import modalMixins from '@/components/Lims/modals/modal.mixins';
import { APP_ROUTES } from '@/core/constants';

export default {
  name: 'modal-redirecting-to-login',
  mixins: [modalMixins],
  props: ['message'],
  methods: {
    onClick() {
      this.$router.push(APP_ROUTES.AUTH_LOGIN);
    },
  },
};
</script>

<style lang="scss"></style>
