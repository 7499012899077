<template>
  <div>
    <modal-confirm-generic
      ref="unSaveChangesModal"
      :description="$t('components/lims/modals/ModalFormCancel.text')"
      @onConfirm="onUnSaveChanges"
    />
    <component v-bind:is="activeUserType" :userTypeName="userTypeName" :viewType="viewType"></component>
  </div>
</template>

<script>
import ClinicAssociate from '@/pages/Account/User/Forms/ClinicAssociate/ClinicAssociate.vue';
import LabTechnician from '@/pages/Account/User/Forms/LabTechnician/LabTechnician';
import { USER_PROFILE_VIEW_TYPES } from '@/pages/Account/User/user.mixins';
import { UnSaveChangesMixins } from '@/core/mixins';

const ACTIVE_USER_TYPES = {
  clinicAssociate: ClinicAssociate,
  labTechnician: LabTechnician,
};

export default {
  mixins: [UnSaveChangesMixins],
  props: {
    userType: {
      require: false,
      default: null,
    },
  },
  computed: {
    activeUserType() {
      return ACTIVE_USER_TYPES[this.userType];
    },
    userTypeName() {
      return this.userType;
    },
    viewType() {
      return USER_PROFILE_VIEW_TYPES.MY_ENTITY;
    },
  },
  data: function () {
    return {};
  },
};
</script>

<style lang="scss" scoped></style>
