<template>
  <div
    class="pathologist-select-block"
    :class="isDefaultPathologist && isChangePathologist && formMode !== ADD_MODE ? 'default-pathologist' : ''"
  >
    <multiselect
      :options="pathologistOptions"
      v-model="model"
      :multiple="false"
      :show-labels="false"
      group-values="pathologistUsers"
      group-label="pathologistGroupName"
      placeholder=""
      track-by="pathologistId"
      label="pathologistName"
      :hide-selected="false"
      :group-select="false"
      :disabled="formMode == VIEW_MODE"
    >
      <span slot="noResult">Sorry, no matching options.</span>
    </multiselect>
    <p v-if="isDefaultPathologist && isChangePathologist && formMode !== ADD_MODE">
      {{ $t('entities/case/form/pathologistDefault/warning') }}
    </p>
  </div>
</template>

<script>
import { FORM_MODES, PATHOLOGIST_TYPE_ID, PATHOLOGIST_TYPE_NAME } from '@/core/constants';
import { createNewPathologist } from '../Case.helpers';
import { FormMixins } from '@/core/mixins';
import { mapGetters } from 'vuex';

const transformPathologistObject = (value) => {
  if (!value) {
    return createNewPathologist({
      ...value,
      pathologistId: null,
      pathologistType: PATHOLOGIST_TYPE_ID.None,
    });
  }
  if (value.pathologistType === PATHOLOGIST_TYPE_ID.Pull && !value.pathologistId) {
    return createNewPathologist({
      ...value,
      pathologistId: PATHOLOGIST_TYPE_NAME.Pull,
      pathologistType: PATHOLOGIST_TYPE_ID.Pull,
      pathologistName: PATHOLOGIST_TYPE_NAME.Pull,
      pathologistTitle: PATHOLOGIST_TYPE_NAME.Pull,
    });
  }
  return {
    ...value,
    pathologistId: value.pathologistId,
    pathologistType: value.pathologistType,
  };
};

const transformModelToPathologistObject = (model) => {
  if (!model) {
    return createNewPathologist({
      pathologistId: null,
      pathologistType: PATHOLOGIST_TYPE_ID.None,
    });
  }
  if (
    model.pathologistType === PATHOLOGIST_TYPE_ID.Pull &&
    (!model.pathologistId || model.pathologistId === PATHOLOGIST_TYPE_NAME.Pull)
  ) {
    return {
      ...createNewPathologist({ pathologistId: null, pathologistType: model.pathologistType }),
      pathologistId: null,
    };
  }
  return {
    ...model,
    pathologistId: model.pathologistId,
    pathologistType: model.pathologistType,
  };
};

const isValueChanged = (oldValue, newValue) => {
  if (!oldValue && !newValue) return false;
  if (!oldValue || !newValue) return true;
  return oldValue.pathologistId != newValue.pathologistId || oldValue.pathologistType != newValue.pathologistType;
};

export default {
  mixins: [FormMixins],
  props: {
    formMode: {
      type: Number,
      require: true,
      validator: function (value) {
        // The value must match one of these strings
        return Object.values(FORM_MODES).indexOf(value) !== -1;
      },
    },
    groupOptions: {
      type: Array,
      required: true,
      default: function () {
        return [];
      },
    },
    value: {
      required: false,
      default: function () {
        return null;
      },
    },
  },
  computed: {
    ...mapGetters('caseForm', ['isChangePathologist']),
    pathologistOptions() {
      return this.groupOptions;
    },
    isDefaultPathologist() {
      let isDefault = false;
      const pathologistUsers = [];
      this.groupOptions.forEach((g) => {
        if (g.pathologistUsers) {
          pathologistUsers.push(...g.pathologistUsers);
        }
      });
      if (this.model) {
        pathologistUsers.forEach((p) => {
          if (p.pathologistId === this.model.pathologistId) {
            isDefault = p.isDefault;
          }
        });
      }
      return isDefault;
    },
  },
  watch: {
    value: {
      handler: function (value) {
        this.model = transformPathologistObject(value);
      },
      deep: true,
    },
    model: {
      handler: function (v) {
        const newValue = transformModelToPathologistObject(v);
        if (isValueChanged(this.value, newValue)) {
          this.$emit('input', newValue);
        }
      },
      deep: true,
    },
  },
  data: function () {
    return {
      model: createNewPathologist({
        pathologistId: null,
        pathologistType: PATHOLOGIST_TYPE_ID.None,
      }),
    };
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.pathologist-select-block {
  width: calc(100% - 10px);
}
</style>
