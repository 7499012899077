import { DROPDOWN_SHORT_NAME, NUMBER_OF_CHARACTERS_SEARCH } from '@/core/constants';
import httpClient from '@/services/httpClient';

const RESOURCE = 'cases/pr';

export default {
  createPersonalizedReport(personalizedReportData) {
    return httpClient.post(`/${RESOURCE}`, personalizedReportData);
  },
  getPersonalizedReport(personalizedReportId) {
    return httpClient.get(`/${RESOURCE}/${personalizedReportId}`);
  },
  updatePersonalizedReport(personalizedReportId, personalizedReportData) {
    return httpClient.put(`/${RESOURCE}/${personalizedReportId}`, personalizedReportData);
  },
  deletePersonalizedReport(personalizedReportId) {
    return httpClient.post(`/${RESOURCE}/${personalizedReportId}/delete`);
  },
  disablePersonalizedReport(personalizedReportId) {
    return httpClient.post(`/${RESOURCE}/${personalizedReportId}/deactivate`);
  },
  enablePersonalizedReport(personalizedReportId) {
    return httpClient.post(`/${RESOURCE}/${personalizedReportId}/activate`);
  },
  getPersonalisedReportList({ filters, pagination }) {
    const { snomedMIds, snomedPIds, snomedTIds, statusIds, pathologistIds, search } = filters;
    const { pageNumber, pageSize } = pagination;
    const userQueryParams = {};
    if (snomedMIds) {
      userQueryParams['Filters.AdvanceSearch.SnomedM'] = snomedMIds;
    }
    if (snomedPIds) {
      userQueryParams['Filters.AdvanceSearch.SnomedP'] = snomedPIds;
    }
    if (snomedTIds) {
      userQueryParams['Filters.AdvanceSearch.SnomedT'] = snomedTIds;
    }
    if (pathologistIds) {
      userQueryParams['Filters.AdvanceSearch.PathologistIds'] = pathologistIds;
    }
    if (statusIds) {
      userQueryParams['Filters.AdvanceSearch.PrStatusIds'] = statusIds;
    }
    if (search && search.length >= NUMBER_OF_CHARACTERS_SEARCH) {
      userQueryParams['Filters.Search'] = search;
    }
    if (pageNumber) {
      userQueryParams['PageNumber'] = pageNumber;
    }
    if (pageSize) {
      userQueryParams['PageSize'] = pageSize;
    }
    return httpClient.get(`/${RESOURCE}/`, userQueryParams);
  },
  getAssignButtonListByPathologistId(pathologistId) {
    return httpClient.get(`/${RESOURCE}/assign-buttons`, {
      pathologistId: pathologistId,
    });
  },
  getAssignButtonListByPersonalisedReportId(personalizedReportId) {
    return httpClient.get(`/${RESOURCE}/${personalizedReportId}/assign-buttons`);
  },
  updateAssignButton(assignButtonsData) {
    return httpClient.put(`/${RESOURCE}/assign-buttons`, assignButtonsData);
  },
  removeAssignButton(assignButtonId) {
    return httpClient.post(`/${RESOURCE}/assign-buttons/remove/${assignButtonId}`);
  },
  async getNonCustomSnomeds(casePersonalizedReportId) {
    const snomeds = [DROPDOWN_SHORT_NAME.SNOMED_M, DROPDOWN_SHORT_NAME.SNOMED_P, DROPDOWN_SHORT_NAME.SNOMED_T];
    const params = {
      shortCodes: snomeds.join(','),
    };
    if (casePersonalizedReportId) {
      Reflect.set(params, 'casePersonalizedReportId', casePersonalizedReportId);
    }
    const { error, data } = await httpClient.get(`/${RESOURCE}/non-custom-snomeds`, params);
    const res = error ? {} : data;
    const dropdownOptions = {};
    snomeds.map((name) => {
      // eslint-disable-next-line security/detect-object-injection
      dropdownOptions[name] = res
        .find((row) => row.itemName === name)
        .items.map((item) => {
          return {
            itemName: name,
            fieldItemId: item.itemId,
            fieldItemName: item.itemName,
            value: item.itemId,
            label: item.itemName,
            ...item,
          };
        });
    });
    return dropdownOptions;
  },
};
