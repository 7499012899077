import { cloneDeep } from 'lodash';

const DropdownSchema = {
  information: {
    entity: 'dropdown/information',
  },
  _serverSideValidators: {
    itemName: {
      fieldName: 'itemName',
      errorType: 'unique',
      functionName: 'checkItemNameExisted',
      params: {},
    },
  },
};

const prepareRoleSchema = (dropdownSchema, informationDTO, params) => {
  informationDTO['field'] = 'required';
  informationDTO['itemName'] = 'required';
  informationDTO['isHide'] = 'required';
  informationDTO['itemOrder'] = 'required|mustBeLessThanOrEqual:checkItemOrderInvalid';
  DropdownSchema['information']['fields'] = informationDTO;

  const schema = cloneDeep(dropdownSchema);
  schema._serverSideValidators.itemName.params = {
    fieldId: params.fieldId ? params.fieldId : '',
    itemId: params.itemId ? params.itemId : '',
  };
  schema.information.fields.itemOrder = `${schema.information.fields.itemOrder},${JSON.stringify({
    maxOfItemOrder: params.maxOfItemOrder ? params.maxOfItemOrder : '',
  })}`;

  return schema;
};

export const getDropdownSchema = (informationDTO, params) => {
  return prepareRoleSchema(DropdownSchema, informationDTO, params);
};
