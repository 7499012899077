var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"formCaseBatchChange",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
return [_c('md-dialog',{staticClass:"lims-modal",attrs:{"md-close-on-esc":true,"md-active":_vm.isVisible},on:{"update:mdActive":function($event){_vm.isVisible=$event},"update:md-active":function($event){_vm.isVisible=$event},"md-clicked-outside":_vm.close}},[_c('div',{staticClass:"dialog-icon-close",on:{"click":_vm.close}},[_c('md-icon',[_vm._v("close")])],1),_c('md-dialog-title',[_vm._v(_vm._s(_vm.$t('components/ModalCaseBatchChange.modalTitle')))]),_c('form',{staticClass:"md-layout lims-form",on:{"submit":function($event){$event.preventDefault();return handleSubmit()}}},[_c('lims-block',{staticClass:"block-not-title"},[_c('modal-batch-change-specimen-type-problem',{ref:"batchChangeSpecimenTypeProblemModal",attrs:{"slot":"blockTitle"},on:{"onCancel":_vm.onCancelBatchChangeSpecimenTypeProblemModal},slot:"blockTitle"}),_c('div',{attrs:{"slot":"blockContent"},slot:"blockContent"},[_c('div',{staticClass:"md-layout lims-form-row"},[_c('div',{staticClass:"md-layout-item md-size-100 md-small-size-100"},[_c('lims-field',{attrs:{"model":_vm.formData,"schema":_vm.schema,"field":"fieldSelector"}},[_c('v-select',{attrs:{"slot":"field-outside","options":_vm.fieldSelector,"placeholder":_vm.$t('components/ModalCaseBatchChange.placeholderFieldSelector')},on:{"input":_vm.fieldSelectorVal},slot:"field-outside",scopedSlots:_vm._u([{key:"option",fn:function(ref){
var label = ref.label;
return [_vm._v(" "+_vm._s(_vm.$t(("entities/modals/ModalCaseBatchChange/" + label)))+" ")]}},{key:"selected-option",fn:function(ref){
var label = ref.label;
return [_vm._v(" "+_vm._s(_vm.$t(("entities/modals/ModalCaseBatchChange/" + label)))+" ")]}}],null,true),model:{value:(_vm.formData.fieldSelector),callback:function ($$v) {_vm.$set(_vm.formData, "fieldSelector", $$v)},expression:"formData.fieldSelector"}})],1)],1)]),_c('div',{staticClass:"md-layout lims-form-row"},[(_vm.formData.fieldSelector && _vm.formData.fieldSelector.text == 'Clinic')?_c('div',{staticClass:"md-layout-item md-size-100 md-small-size-100"},[_c('lims-field',{attrs:{"model":_vm.formData,"schema":_vm.schema,"field":"Clinic"}},[_c('v-select',{attrs:{"slot":"field-outside","options":_vm.clinicList,"placeholder":_vm.$t('components/ModalCaseBatchChange.placeholderClinic')},slot:"field-outside",scopedSlots:_vm._u([{key:"option",fn:function(ref){
var label = ref.label;
return [_vm._v(_vm._s(label))]}},{key:"selected-option",fn:function(ref){
var label = ref.label;
return [_vm._v(_vm._s(label))]}}],null,true),model:{value:(_vm.formData.Clinic),callback:function ($$v) {_vm.$set(_vm.formData, "Clinic", $$v)},expression:"formData.Clinic"}})],1)],1):_vm._e(),(_vm.formData.fieldSelector && _vm.formData.fieldSelector.text == 'Clinician')?_c('div',{staticClass:"md-layout-item md-size-100 md-small-size-100"},[_c('lims-field',{attrs:{"model":_vm.formData,"schema":_vm.schema,"field":"Clinician"}},[_c('v-select',{attrs:{"slot":"field-outside","options":_vm.clinicianList,"placeholder":_vm.$t('components/ModalCaseBatchChange.placeholderClinician')},slot:"field-outside",scopedSlots:_vm._u([{key:"option",fn:function(ref){
var label = ref.label;
return [_vm._v(_vm._s(label))]}},{key:"selected-option",fn:function(ref){
var label = ref.label;
return [_vm._v(_vm._s(label))]}}],null,true),model:{value:(_vm.formData.Clinician),callback:function ($$v) {_vm.$set(_vm.formData, "Clinician", $$v)},expression:"formData.Clinician"}})],1)],1):_vm._e(),(_vm.formData.fieldSelector && _vm.formData.fieldSelector.text == 'Comment')?_c('div',{staticClass:"md-layout-item md-size-100 md-small-size-100"},[_c('lims-field',{staticClass:"textarea-field",attrs:{"model":_vm.formData,"schema":_vm.schema,"field":"Comment"}},[_c('md-textarea',{attrs:{"slot":"field","type":"text"},slot:"field",model:{value:(_vm.formData.Comment),callback:function ($$v) {_vm.$set(_vm.formData, "Comment", $$v)},expression:"formData.Comment"}})],1)],1):_vm._e(),(_vm.formData.fieldSelector && _vm.formData.fieldSelector.text == 'Laboratory')?_c('div',{staticClass:"md-layout-item md-size-100 md-small-size-100"},[_c('lims-field',{attrs:{"model":_vm.formData,"schema":_vm.schema,"field":"Laboratory"}},[_c('v-select',{attrs:{"slot":"field-outside","options":_vm.laboratoryList,"placeholder":_vm.$t('components/ModalCaseBatchChange.placeholderLaboratory')},slot:"field-outside",scopedSlots:_vm._u([{key:"option",fn:function(ref){
var label = ref.label;
return [_vm._v(_vm._s(label))]}},{key:"selected-option",fn:function(ref){
var label = ref.label;
return [_vm._v(_vm._s(label))]}}],null,true),model:{value:(_vm.formData.Laboratory),callback:function ($$v) {_vm.$set(_vm.formData, "Laboratory", $$v)},expression:"formData.Laboratory"}})],1)],1):_vm._e(),(_vm.formData.fieldSelector && _vm.formData.fieldSelector.text == 'Pathologist')?_c('div',{staticClass:"md-layout-item md-size-100 md-small-size-100"},[_c('lims-field',{attrs:{"model":_vm.formData,"schema":_vm.schema,"field":"Pathologist"}},[_c('v-select',{attrs:{"slot":"field-outside","options":_vm.pathologistList,"placeholder":_vm.$t('components/ModalCaseBatchChange.placeholderPathologist')},slot:"field-outside",scopedSlots:_vm._u([{key:"option",fn:function(ref){
var label = ref.label;
return [_vm._v(_vm._s(label))]}},{key:"selected-option",fn:function(ref){
var label = ref.label;
return [_vm._v(_vm._s(label))]}}],null,true),model:{value:(_vm.formData.Pathologist),callback:function ($$v) {_vm.$set(_vm.formData, "Pathologist", $$v)},expression:"formData.Pathologist"}})],1)],1):_vm._e(),(_vm.formData.fieldSelector && _vm.formData.fieldSelector.text == 'FirstSpecimenType')?_c('div',{staticClass:"md-layout-item md-size-100 md-small-size-100"},[_c('lims-field',{attrs:{"model":_vm.formData,"schema":_vm.schema,"field":"FirstSpecimenType"}},[_c('multiselect',{attrs:{"slot":"field-outside","options":_vm.specimenTypeList,"multiple":false,"show-labels":false,"group-values":"items","group-label":"itemName","placeholder":_vm.$t('components/ModalCaseBatchChange.placeholderSpecimenType'),"track-by":"fieldItemId","label":"fieldItemName","hide-selected":false,"group-select":false},slot:"field-outside",model:{value:(_vm.formData.FirstSpecimenType),callback:function ($$v) {_vm.$set(_vm.formData, "FirstSpecimenType", $$v)},expression:"formData.FirstSpecimenType"}})],1)],1):_vm._e(),(_vm.formData.fieldSelector && _vm.formData.fieldSelector.text == 'Technician1')?_c('div',{staticClass:"md-layout-item md-size-100 md-small-size-100"},[_c('lims-field',{attrs:{"model":_vm.formData,"schema":_vm.schema,"field":"Technician1"}},[_c('v-select',{attrs:{"slot":"field-outside","options":_vm.labTechList1,"placeholder":_vm.$t('components/ModalCaseBatchChange.placeholderLabTech')},slot:"field-outside",scopedSlots:_vm._u([{key:"option",fn:function(ref){
var label = ref.label;
return [_vm._v(_vm._s(label))]}},{key:"selected-option",fn:function(ref){
var label = ref.label;
return [_vm._v(_vm._s(label))]}}],null,true),model:{value:(_vm.formData.Technician1),callback:function ($$v) {_vm.$set(_vm.formData, "Technician1", $$v)},expression:"formData.Technician1"}})],1)],1):_vm._e(),(_vm.formData.fieldSelector && _vm.formData.fieldSelector.text == 'Technician2')?_c('div',{staticClass:"md-layout-item md-size-100 md-small-size-100"},[_c('lims-field',{attrs:{"model":_vm.formData,"schema":_vm.schema,"field":"Technician2"}},[_c('v-select',{attrs:{"slot":"field-outside","options":_vm.labTechList2,"placeholder":_vm.$t('components/ModalCaseBatchChange.placeholderLabTech')},slot:"field-outside",scopedSlots:_vm._u([{key:"option",fn:function(ref){
var label = ref.label;
return [_vm._v(_vm._s(label))]}},{key:"selected-option",fn:function(ref){
var label = ref.label;
return [_vm._v(_vm._s(label))]}}],null,true),model:{value:(_vm.formData.Technician2),callback:function ($$v) {_vm.$set(_vm.formData, "Technician2", $$v)},expression:"formData.Technician2"}})],1)],1):_vm._e(),(_vm.formData.fieldSelector && _vm.formData.fieldSelector.text == 'BillingEntity')?_c('div',{staticClass:"md-layout-item md-size-100 md-small-size-100"},[_c('lims-field',{attrs:{"model":_vm.formData,"schema":_vm.schema,"field":"BillingEntity"}},[_c('v-select',{attrs:{"slot":"field-outside","options":_vm.billingEntityList,"placeholder":_vm.$t('components/ModalCaseBatchChange.placeholderBillingEntity')},slot:"field-outside",scopedSlots:_vm._u([{key:"option",fn:function(ref){
var label = ref.label;
return [_vm._v(_vm._s(label))]}},{key:"selected-option",fn:function(ref){
var label = ref.label;
return [_vm._v(_vm._s(label))]}}],null,true),model:{value:(_vm.formData.BillingEntity),callback:function ($$v) {_vm.$set(_vm.formData, "BillingEntity", $$v)},expression:"formData.BillingEntity"}})],1)],1):_vm._e(),(_vm.formData.fieldSelector && _vm.formData.fieldSelector.text == 'WorkStream')?_c('div',{staticClass:"md-layout-item md-size-100 md-small-size-100"},[_c('lims-field',{attrs:{"model":_vm.formData,"schema":_vm.schema,"field":"WorkStream"}},[_c('v-select',{attrs:{"slot":"field-outside","options":_vm.workStreamList,"placeholder":_vm.$t('components/ModalCaseBatchChange.placeholderWorkStream')},slot:"field-outside",scopedSlots:_vm._u([{key:"option",fn:function(ref){
var label = ref.label;
return [_vm._v(_vm._s(label))]}},{key:"selected-option",fn:function(ref){
var label = ref.label;
return [_vm._v(_vm._s(label))]}}],null,true),model:{value:(_vm.formData.WorkStream),callback:function ($$v) {_vm.$set(_vm.formData, "WorkStream", $$v)},expression:"formData.WorkStream"}})],1)],1):_vm._e(),(_vm.formData.fieldSelector && _vm.formData.fieldSelector.text == 'Tracking')?_c('div',{staticClass:"md-layout-item md-size-100 md-small-size-100"},[_c('lims-field',{attrs:{"model":_vm.formData,"schema":_vm.schema,"field":"Tracking"}},[_c('md-input',{attrs:{"slot":"field","type":"text"},slot:"field",model:{value:(_vm.formData.Tracking),callback:function ($$v) {_vm.$set(_vm.formData, "Tracking", $$v)},expression:"formData.Tracking"}})],1)],1):_vm._e(),(_vm.formData.fieldSelector && _vm.formData.fieldSelector.text == 'LabEntryDate')?_c('div',{staticClass:"md-layout-item md-size-100 md-small-size-100"},[_c('lims-field',{attrs:{"model":_vm.formData,"schema":_vm.schema,"field":"LabEntryDate"}},[_c('date-picker',{attrs:{"slot":"field","lang":{
                    formatLocale: {
                      firstDayOfWeek: 1,
                    },
                    monthBeforeYear: false,
                  },"type":"datetime","format":"DD/MM/YYYY HH:mm:ss","time-picker-options":{ start: '00:00', step: '00:10', end: '23:50' }},slot:"field",model:{value:(_vm.formData.LabEntryDate),callback:function ($$v) {_vm.$set(_vm.formData, "LabEntryDate", $$v)},expression:"formData.LabEntryDate"}})],1)],1):_vm._e()])])],1),_c('md-dialog-actions',[_c('md-button',{staticClass:"lims-form-button",on:{"click":_vm.close}},[_vm._v(_vm._s(_vm.$t('global/button/button.cancel')))]),_c('md-button',{staticClass:"lims-form-button md-primary",attrs:{"disabled":invalid || _vm.isProcessing},on:{"click":_vm.onContinueStep}},[_vm._v(_vm._s(_vm.$t('global/button/button.continue')))])],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }