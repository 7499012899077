var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
return [_c('form',{staticClass:"md-layout lims-form form-login",on:{"submit":function($event){$event.preventDefault();return handleSubmit()}}},[_c('md-dialog',{staticClass:"lims-modal",attrs:{"md-close-on-esc":true,"md-active":_vm.isVisible},on:{"update:mdActive":function($event){_vm.isVisible=$event},"update:md-active":function($event){_vm.isVisible=$event},"md-clicked-outside":_vm.close}},[_c('div',{staticClass:"dialog-icon-close",on:{"click":_vm.close}},[_c('md-icon',[_vm._v("close")])],1),_c('md-dialog-title',[_vm._v(_vm._s(_vm.$t('components/ModelSelectUserTypes.modalTitle')))]),_c('div',{staticClass:"dialog-content",staticStyle:{"text-align":"center"}},[_c('lims-field',{attrs:{"model":_vm.formData,"schema":_vm.schema,"field":"selectOption","viewMode":"only","validationMode":'eager'}},[(!_vm.entitySelectUser)?_c('v-select',{staticClass:"not-item-in-v-select-error",attrs:{"slot":"field-outside","options":_vm.$translateSelectOptions(_vm.selectOptions),"placeholder":_vm.$t('components/ModelSelectUserTypes.placeholderUserType')},slot:"field-outside",scopedSlots:_vm._u([{key:"option",fn:function(ref){
var label = ref.label;
return [_vm._v(_vm._s(label))]}},{key:"selected-option",fn:function(ref){
var label = ref.label;
return [_vm._v(_vm._s(label))]}}],null,true),model:{value:(_vm.formData.selectOption),callback:function ($$v) {_vm.$set(_vm.formData, "selectOption", $$v)},expression:"formData.selectOption"}}):_c('v-select',{attrs:{"slot":"field-outside","options":_vm.$translateSelectOptions(_vm.selectOptionsUserEntity),"placeholder":_vm.$t('components/ModelSelectUserTypes.placeholderUserType')},slot:"field-outside",scopedSlots:_vm._u([{key:"option",fn:function(ref){
var label = ref.label;
return [_vm._v(_vm._s(label))]}},{key:"selected-option",fn:function(ref){
var label = ref.label;
return [_vm._v(_vm._s(label))]}}],null,true),model:{value:(_vm.formData.selectOption),callback:function ($$v) {_vm.$set(_vm.formData, "selectOption", $$v)},expression:"formData.selectOption"}})],1)],1),_c('md-dialog-actions',[_c('md-button',{staticClass:"lims-form-button",on:{"click":_vm.close}},[_vm._v(_vm._s(_vm.$t('global/button/button.cancel')))]),_c('md-button',{staticClass:"lims-form-button md-primary",attrs:{"disabled":invalid},on:{"click":_vm.onClick}},[_vm._v(_vm._s(_vm.$t('global/button/button.continue')))])],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }