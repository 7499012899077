// import datasetMock from '@/mocks/dataset-mock';
import { DROPDOWN_SHORT_NAME } from '@/core/constants';
import dropdownService from './dropdown.service';
import httpClient from './httpClient';
const RESOURCE = 'case-datasets';

export default {
  getTriggerDataset(clinicId, options) {
    const { snomedM, snomedP, snomedT } = options;
    if (!clinicId || (!snomedM && !snomedP && !snomedT)) {
      return { error: false, data: [] };
    }
    // if (!snomedM || !snomedP || !snomedT) {
    //   return { error: false, data: [] };
    // }
    const payload = {
      clinicId,
    };
    if (snomedM) {
      payload['snomedMFieldItemId'] = snomedM;
    }
    if (snomedP) {
      payload['snomedPFieldItemId'] = snomedP;
    }
    if (snomedT) {
      payload['snomedTFieldItemId'] = snomedT;
    }
    return httpClient.post(`/${RESOURCE}/get-list-dataset-from-triger`, payload);
  },
  getListSnomed() {
    return httpClient.get(`/${RESOURCE}/get-list-snomed`);
  },
  async getListStatus() {
    const data = await dropdownService.getDropdownByShortNames([DROPDOWN_SHORT_NAME.CaseDatasetStatus]);
    return { data: data[DROPDOWN_SHORT_NAME.CaseDatasetStatus], error: null };
  },
  async getListControlType() {
    const data = await dropdownService.getDropdownByShortNames([DROPDOWN_SHORT_NAME.CaseDatasetControlType]);
    return data[DROPDOWN_SHORT_NAME.CaseDatasetControlType];
  },
  createCaseDataset(payload) {
    return httpClient.post(`/${RESOURCE}`, payload);
  },
  updateCaseDataset(datasetId, payload) {
    return httpClient.put(`/${RESOURCE}/${datasetId}`, payload);
  },
  disableCaseDataset(datasetId) {
    return httpClient.put(`/${RESOURCE}/disable/${datasetId}`);
  },
  enableCaseDataset(datasetId) {
    return httpClient.put(`/${RESOURCE}/enable/${datasetId}`);
  },
  deleteDataset(datasetId, password) {
    const params = {};
    params['password'] = password;
    return httpClient.deleteWithPayload(`/${RESOURCE}/${datasetId}`, params);
  },
  getDetailDataset(datasetId) {
    return httpClient.get(`/${RESOURCE}/${datasetId}`);
  },
  getListClinicsFromCurrentUser() {
    return httpClient.get(`/${RESOURCE}/get-list-clinics-from-current-user`);
  },
  getDatasetList({ filters, pagination, sort }) {
    const { pageNumber, pageSize } = pagination;
    const userQueryParams = {};

    Object.entries(filters).forEach(([key, value]) => {
      if (key === 'search') {
        userQueryParams['Filters.Search'] = value;
      } else if (key === 'EntityIds') {
        if (value === 'all') {
          userQueryParams[`Filters.IsClinicAll`] = true;
        } else {
          userQueryParams[`Filters.${key}`] = value;
        }
      } else if (value) {
        userQueryParams[`Filters.${key}`] = value;
      }
    });

    if (pageNumber) {
      userQueryParams['PageNumber'] = pageNumber;
    }
    if (pageSize) {
      userQueryParams['PageSize'] = pageSize;
    }
    if (sort) {
      userQueryParams['Sort'] = sort;
    }
    return httpClient.get(`/${RESOURCE}`, userQueryParams);
  },
};
