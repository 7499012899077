import { caseFormService } from '@/services';
import ModalConfirmSlideBlock from '@/components/Lims/modals/ModalConfirmSlideBlock';
import { CASE_STATUS } from '@/core/constants';
import { SPECIMEN_ELEMENT_OBJECT_TYPE, checkSlideInPendingAlwRequestError } from '../Case.helpers';

export default {
  components: { ModalConfirmSlideBlock },
  computed: {
    $isStatusShowModalConfirmSlideBlock() {
      return (
        this.dataEdit &&
        (this.dataEdit.status == CASE_STATUS.PATH ||
          this.dataEdit.status == CASE_STATUS.PROVISIONALLY_REPORTED ||
          this.dataEdit.status == CASE_STATUS.REPORTED)
      );
    },
  },
  methods: {
    $showModalConfirmSlideBlock(action) {
      this.confirmSlideBlockModalDescription = this.$t(
        'pages/case/CaseManagement/components/SpecimenDetail/ModalConfirmSlideBlock.description',
        {
          action: action,
          caseRef: this.dataEdit.caseReference,
          status: this.dataEdit.statusName,
        },
      );
      this.$refs.confirmSlideBlockModal.open({ action: action });
    },
    async $checkSlideInPendingAlwRequestBeforeDeleteSpecimen({ UpdatingNumberOfSpecimens, caseId }) {
      return await this.$checkSlideInPendingAlwRequest({
        UpdatingNumberOfSpecimens,
        caseId,
        objectType: SPECIMEN_ELEMENT_OBJECT_TYPE.SPECIMEN,
      });
    },
    async $checkSlideInPendingAlwRequestBeforeDeleteBlock({ specimenIndex, blockIndex, caseId, specimenId, blockId }) {
      return await this.$checkSlideInPendingAlwRequest({
        specimenIndex,
        blockIndex,
        caseId,
        objectType: SPECIMEN_ELEMENT_OBJECT_TYPE.BLOCK,
        specimenId,
        blockId,
      });
    },
    async $checkSlideInPendingAlwRequestBeforeDeleteSlides({
      specimenIndex,
      blockIndex,
      slideIds,
      caseId,
      specimenId,
      blockId,
    }) {
      return await this.$checkSlideInPendingAlwRequest({
        specimenIndex,
        blockIndex,
        specimenId,
        blockId,
        slideIds,
        caseId,
        objectType: SPECIMEN_ELEMENT_OBJECT_TYPE.SLIDE,
      });
    },
    async $checkSlideInPendingAlwRequest({
      specimenId,
      blockId,
      slideIds,
      caseId,
      UpdatingNumberOfSpecimens,
      objectType,
    }) {
      const { data, error } = await caseFormService.checkSlideInPendingAlwRequest(
        slideIds,
        caseId,
        specimenId,
        blockId,
        UpdatingNumberOfSpecimens,
      );
      if (error) {
        return {
          numberOfSpecimens: null,
          valid: false,
          error,
        };
      }
      if (data) {
        const { pendingAlwRequests, numberOfSpecimens } = data;
        if (pendingAlwRequests == null || pendingAlwRequests.length == 0) {
          return {
            numberOfSpecimens: UpdatingNumberOfSpecimens,
            valid: true,
            error: null,
          };
        }
        const errorMessage = checkSlideInPendingAlwRequestError({
          pendingAlwRequests,
          $t: (v, params) => {
            return this.$t(v, params);
          },
          objectType,
        });
        return {
          numberOfSpecimens: numberOfSpecimens,
          valid: false,
          error: errorMessage,
        };
      }
    },
  },
};
