<template>
  <div class="paginator">
    <div class="left-side">
      <p>
        {{
          $t('component/lims/pagination/totalItem', {
            fromItem: fromItem(),
            toItem: toItem(),
            totalItem: totalItem,
          })
        }}
      </p>
    </div>
    <div class="right-side">
      <pagination-custom
        ref="pager"
        :value="pageNumber"
        :per-page="paginationModel.pageSize"
        :total="totalItem"
        @input="onPageNumberChange"
      >
      </pagination-custom>
    </div>
  </div>
</template>

<script>
import PaginationCustom from '@/components/Lims/LimsPaginatonCustom.vue';

export default {
  components: {
    PaginationCustom,
  },

  computed: {
    pageNumber() {
      if (this.multiple) {
        return this.paginationModel.pageNumber[this.index];
      }
      return this.paginationModel.pageNumber;
    },
  },

  created() {
    this.paginationModel = this.pagination;
  },

  data() {
    return {
      paginationModel: {},
    };
  },

  props: {
    pagination: {
      type: Object,
      require: true,
    },
    multiple: {
      type: Boolean,
      require: false,
      default: false,
    },
    index: {
      type: Number,
      require: false,
      default: 0,
    },

    totalItem: {
      type: Number,
      require: true,
      default: 0,
    },
  },

  methods: {
    changePage(number) {
      this.$refs.pager.changePage(number);
    },
    fromItem() {
      if (this.totalItem <= 1) {
        return this.totalItem;
      } else {
        return (this.pageNumber - 1) * this.paginationModel.pageSize + 1;
      }
    },

    toItem() {
      if (this.totalItem <= 1) {
        return this.totalItem;
      } else {
        let toItemNumber = this.pageNumber * this.paginationModel.pageSize;
        if (toItemNumber > this.totalItem) {
          return this.totalItem;
        } else {
          return toItemNumber;
        }
      }
    },
    onPageNumberChange(value) {
      if (this.multiple) {
        this.paginationModel.pageNumber[this.index] = value;
        this.$set(this.paginationModel, 'pageNumber', [...this.paginationModel.pageNumber]);
      } else {
        this.paginationModel.pageNumber = value;
      }
    },
  },
};
</script>

<style lang="scss">
.paginator {
  width: 100%;
  .right-side {
    display: flex;
    justify-content: flex-end;
    .pagination {
      float: right;
    }
  }
  @media only screen and (max-width: 767px) {
    .left-side {
      float: none;
    }
  }
}
</style>
