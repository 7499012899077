<template>
  <md-dialog :md-close-on-esc="true" :md-active.sync="isVisible" @md-clicked-outside="close" class="lims-modal">
    <div class="dialog-icon-close" @click="close"><md-icon>close</md-icon></div>
    <md-dialog-title></md-dialog-title>
    <div class="lims-form dialog-content">
      <p class="text-center">{{ $t('components/lims/modals/ModalWarningNoUnreadReportedCases.content') }}</p>
    </div>
    <md-dialog-actions>
      <md-button @click="onCancel" class="lims-form-button">{{ $t('global/button/button.ok') }}</md-button>
    </md-dialog-actions>
  </md-dialog>
</template>

<script>
import modalMixins from '@/components/Lims/modals/modal.mixins';

export default {
  name: 'ModalWarningNoUnreadReportedCases',
  mixins: [modalMixins],
  props: {
    title: {
      type: String,
      require: true,
    },
    description: {
      type: String,
      require: false,
      default: '',
    },
  },
  methods: {
    onCancel() {
      this.close();
      this.$emit('onCancel');
    },
  },
};
</script>

<style lang="scss"></style>
