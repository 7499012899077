<template>
  <md-dialog :md-close-on-esc="true" :md-active.sync="isVisible" @md-clicked-outside="close" class="lims-modal">
    <div class="dialog-icon-close" @click="close"><md-icon>close</md-icon></div>
    <md-dialog-title>{{ $t('components/lims/modals/ModalAuthoriseCase.title') }}</md-dialog-title>
    <div class="dialog-content">
      <p class="text-center" v-html="$t(`components/lims/modals/ModalAuthoriseCase.authoriseCaseNote.${status}`)"></p>
      <p class="text-center">{{ $t('components/lims/modals/ModalAuthoriseCase.actionCannotBeUndone') }}</p>
      <lims-field :model="formData" :schema="checkCodeSchema" field="passwordForCase">
        <md-input ref="confirmPassword" slot="field" v-model="formData.password" type="password"></md-input>
      </lims-field>
    </div>
    <md-dialog-actions>
      <md-button @click="onCancel" class="lims-form-button" :disabled="disabled">
        {{ $t('global/button/button.cancel') }}
      </md-button>
      <md-button @click="onAuthorise" class="md-primary lims-form-button" :disabled="!formData.password || disabled">
        {{ $t('global/button/button.authorise') }}
      </md-button>
    </md-dialog-actions>
  </md-dialog>
</template>

<script>
import modalMixins from './modal.mixins';
import { getCheckCodeSchema } from '@/schemas/check-code.schema';
export default {
  mixins: [modalMixins],
  props: {},
  data() {
    return {
      formData: {
        password: null,
      },
      formStatus: {
        status: '',
        disabled: false,
        isWrongPassword: false,
      },
    };
  },
  computed: {
    status: function () {
      return this.formStatus.status;
    },
    disabled: function () {
      return this.formStatus.disabled;
    },
    checkCodeSchema() {
      return getCheckCodeSchema(this.formStatus.isWrongPassword);
    },
  },

  methods: {
    updateFormStatus(payload) {
      const formStatus = {
        ...this.formStatus,
        ...payload,
      };
      this.formStatus = formStatus;
    },
    onCancel() {
      this.close();
    },
    onAuthorise() {
      this.$emit('onAuthorise', {
        ...this.formData,
        status: this.formStatus.status,
      });
    },
  },
};
</script>

<style lang="scss"></style>
