import { getDefaultFilterValues, getFilterFieldTypes } from '@/query/utils';
import { FIELD_TYPES } from '@/query/constant';
const fields = [
  {
    key: 'entityType',
    listKey: 'entityType',
    sort: 1,
    fieldType: FIELD_TYPES.Int,
    translated: true,
    defaultValue: null,
  },
  {
    key: 'clinicAndLabEntityIds',
    listKey: 'clinicAndLabEntityIds',
    sort: 2,
    fieldType: FIELD_TYPES.ArrayString,
    translated: true,
    defaultValue: [],
  },
  {
    key: 'laboratoryIds',
    listKey: 'laboratoryIds',
    sort: 3,
    fieldType: FIELD_TYPES.ArrayString,
    translated: true,
    defaultValue: [],
  },
  {
    key: 'clinicIds',
    listKey: 'clinicIds',
    sort: 4,
    fieldType: FIELD_TYPES.ArrayString,
    translated: true,
    defaultValue: [],
  },
  {
    key: 'clinicianIds',
    listKey: 'clinicianIds',
    sort: 5,
    fieldType: FIELD_TYPES.ArrayString,
    translated: true,
    defaultValue: [],
  },
  {
    key: 'technician1Ids',
    listKey: 'technician1Ids',
    sort: 6,
    fieldType: FIELD_TYPES.ArrayString,
    translated: true,
    defaultValue: [],
  },
  {
    key: 'pathologists',
    listKey: 'pathologists',
    sort: 7,
    fieldType: FIELD_TYPES.ArrayString,
    translated: true,
    defaultValue: [],
  },
  {
    key: 'billingEntityIds',
    listKey: 'billingEntityIds',
    sort: 8,
    fieldType: FIELD_TYPES.ArrayInt,
    translated: true,
    defaultValue: [],
  },
  {
    key: 'workStreamIds',
    listKey: 'workStreamIds',
    sort: 9,
    fieldType: FIELD_TYPES.ArrayInt,
    translated: true,
    defaultValue: [],
  },
  {
    key: 'specimenTypeIds',
    listKey: 'specimenTypeIds',
    sort: 10,
    fieldType: FIELD_TYPES.ArrayString,
    translated: true,
    defaultValue: [],
  },
  {
    key: 'slides',
    listKey: 'slides',
    sort: 11,
    fieldType: FIELD_TYPES.ArrayString,
    translated: true,
    defaultValue: [],
  },
  {
    key: 'labEntryDate',
    listKey: 'labEntryDate',
    sort: 12,
    fieldType: FIELD_TYPES.ArrayDate,
    translated: true,
    defaultValue: [],
  },
  {
    key: 'LabEntryTime',
    listKey: 'LabEntryTime',
    sort: 13,
    fieldType: FIELD_TYPES.ArrayDate,
    translated: true,
    defaultValue: [],
  },
  {
    key: 'MacroDescription',
    listKey: 'MacroDescription',
    sort: 14,
    fieldType: FIELD_TYPES.String,
    translated: true,
    defaultValue: [],
  },
  {
    key: 'ClinicalDetails',
    listKey: 'ClinicalDetails',
    sort: 15,
    fieldType: FIELD_TYPES.String,
    translated: true,
    defaultValue: [],
  },
  {
    key: 'numberDefaultRows',
    listKey: 'numberDefaultRows',
    sort: 16,
    fieldType: FIELD_TYPES.Number,
    translated: true,
    defaultValue: 1,
  },
];
const defaultValues = getDefaultFilterValues(fields);
const fieldTypes = getFilterFieldTypes(fields);
export default {
  fields,
  defaultValues,
  fieldTypes,
};
