<template>
  <span class="c-tooltip">
    <md-icon>info</md-icon>
    <md-tooltip class="tooltip-align-left tooltip-form" v-html="content"></md-tooltip>
  </span>
</template>

<script>
export default {
  props: {
    content: {
      type: String,
      required: true,
      default: null,
    },
  },
};
</script>

<style lang="scss"></style>
