<template>
  <div class="sb-page" v-if="query && ready">
    <div class="sb-header" v-if="data">
      <sb-filter v-model="query.filters" :cases="data.cases">
        <lims-row-per-page
          slot="rowsPerPage"
          :numberOnly="true"
          :pageSize="query.pagination.pageSize"
          @pageSizeChanged="onPageSizeChanged"
        />
      </sb-filter>
    </div>
    <div class="sb-content">
      <div class="sb-content-items" v-if="data">
        <case-item-list :caseIds="caseIds" :casesData="cases" :commonlyUsedSlides="data.commonlyUsedSlides">
          <lims-pagination slot="pagination" :total-item="totalItems" :pagination="query.pagination"></lims-pagination>
        </case-item-list>
      </div>
      <div class="sb-content-right" v-if="data">
        <commonly-used-slides
          :options="data.commonlyUsedSlides"
          v-model="formData.selectedSlide"
        ></commonly-used-slides>
        <commonly-used-panels
          :options="data.commonlyUsedPanels"
          v-model="formData.selectedPanel"
        ></commonly-used-panels>
      </div>
    </div>
    <div class="md-layout lims-form-row sb-form-action">
      <md-button @click="onCancel()" class="lims-form-button">
        {{ $t('global/button/button.cancel') }}
      </md-button>
    </div>
  </div>
</template>
<script>
import SbFilter, { SB_FILTER_OPTIONS } from '@/pages/Case/CaseManagement/SB/Component/SbFilters.vue';
import CaseItemList from '@/pages/Case/CaseManagement/SB/Component/CaseItemList.vue';
import CommonlyUsedSlides from '@/pages/Case/CaseManagement/SB/Component/CommonlyUsedSlides.vue';
import CommonlyUsedPanels from '@/pages/Case/CaseManagement/SB/Component/CommonlyUsedPanels.vue';
import LimsPagination from '@/components/Lims/LimsPagination';
import LimsRowPerPage from '@/components/Lims/LimsRowPerPage';
import { mapActions, mapGetters } from 'vuex';
import { APP_EVENTS, APP_ROUTES, newAppEvent, CASE_STATUS } from '@/core/constants';
import { filter, debounce, sortBy, cloneDeep, uniqBy } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import { caseFormService, caseListService } from '@/services';
import { ConfigMixins } from '@/core/mixins';
import entityService from '@/services/entity.service';
import {
  DEFAULT_SPECIMEN_BLOCK_NAMING_RULE,
  generateSpecimenBlockName,
} from '@/pages/Case/CaseManagement/CaseSpecimen.mixins';
import { transformCaseData } from './Component/PanelBlock.vue';

const likeSearch = (o, fields, value) => {
  const fieldValues = fields.map((f) => o[f]);
  return fieldValues.some((v) => v?.toLowerCase().includes(value.toLowerCase()));
};
const inSearch = (o, field, values) => {
  return values.includes(o[field]);
};

export const searchCases = (res, filters, pagination) => {
  let cases = res;
  let items = [];

  if (filters && filters.search) {
    cases = filter(cases, (o) =>
      likeSearch(o, ['caseReference', 'laboratoryReference', 'hospitalReference'], filters.search),
    );
  }

  if (filters.laboratoryReferences && !isEmpty(filters.laboratoryReferences)) {
    cases = filter(cases, (o) => inSearch(o, 'laboratoryReference', filters.laboratoryReferences));
  }
  if (filters.caseReferences && !isEmpty(filters.caseReferences)) {
    cases = filter(cases, (o) => inSearch(o, 'caseReference', filters.caseReferences));
  }

  if (filters.clinicians && !isEmpty(filters.clinicians)) {
    cases = filter(cases, (o) => inSearch(o, 'clinicianId', filters.clinicians));
  }

  if (filters.pathologists && !isEmpty(filters.pathologists)) {
    cases = filter(cases, (o) => inSearch(o, 'pathologistId', filters.pathologists));
  }

  if (filters.clinics && !isEmpty(filters.clinics)) {
    cases = filter(cases, (o) => inSearch(o, 'clinicId', filters.clinics));
  }

  if (filters.specimenTypes && !isEmpty(filters.specimenTypes)) {
    cases = filter(
      cases,
      (o) => o.caseSpecimens[0] && inSearch(o.caseSpecimens[0], 'specimenTypeId', filters.specimenTypes),
    );
  }

  // hiddenCases : => show hidden case
  if (!filters.hiddenCases) {
    // hide hidden case by default
    cases = filter(cases, (o) => !o.hidden);
  } else {
    cases = filter(cases, (o) => o.hidden);
  }
  // lockedCases : => show only locked cases
  if (filters.lockedCases) {
    cases = filter(cases, (o) => o.locked);
  }

  // sort
  if (filters.sortBy) {
    if (filters.sortBy === SB_FILTER_OPTIONS.CASE_REF_ASC) {
      cases = sortBy(cases, ['caseReference']);
    }
    if (filters.sortBy === SB_FILTER_OPTIONS.CASE_REF_DESC) {
      cases = sortBy(cases, ['caseReference']).reverse();
    }
  }

  items = pagination
    ? cases.slice((pagination.pageNumber - 1) * pagination.pageSize, pagination.pageNumber * pagination.pageSize)
    : cases;

  return {
    items,
    totalItems: cases.length,
  };
};

// TODO: REMOVED LATER WHEN API NAMING FIXED
const transformCase = (c) => {
  return {
    ...c,
    caseSpecimens: c.caseSpecimens.map((spec) => {
      return {
        ...spec,
        caseSpecimenBlocks: spec.caseSpecimenBlocks.map((b) => {
          return {
            ...b,
            blockFieldItems: b.blockFieldItems,
          };
        }),
      };
    }),
  };
};

const computeVisibleSlides = (commonlyUsedSlides) => {
  return commonlyUsedSlides;
};

const computeVisiblePanels = (commonlyUsedPanels) => {
  return commonlyUsedPanels.map((p) => {
    return {
      ...p,
      panelItems: p.panelItems.filter((pItem) => !pItem.isHide),
    };
  });
};

export default {
  components: {
    SbFilter,
    CaseItemList,
    CommonlyUsedSlides,
    CommonlyUsedPanels,
    LimsPagination,
    LimsRowPerPage,
  },
  mixins: [ConfigMixins],
  computed: {
    ...mapGetters('app/event', [
      APP_EVENTS.EVT_ON_SB_ON_SEARCH,
      APP_EVENTS.EVT_ON_SB_ON_REFRESH,
      APP_EVENTS.EVT_ON_SB_ADD_SLIDE,
      APP_EVENTS.EVT_ON_SB_ADD_PANEL,
    ]),
    ...mapGetters('sb', ['$selectedBlocks']),
  },
  async created() {
    await this.prepareData();
    const dropdownOptions = await caseFormService.loadDropDownDataSource();

    this.updateDataset({
      ...dropdownOptions,
    });
    const pagination = {
      pageSize: this.$appConfig.itemPerPageDefault,
      pageNumber: 1,
    };
    const filters = {};
    this.query = {
      pagination,
      filters,
    };
    this.ready = true;
  },
  data() {
    return {
      data: null,
      formData: {
        selectedSlide: null,
        selectedPanel: null,
      },
      query: null,
      cases: [],
      parsedCases: {},
      totalItems: 0,
      caseIds: [],
      ready: false,
      settingRes: null,
    };
  },
  watch: {
    [APP_EVENTS.EVT_ON_SB_ON_SEARCH]: {
      deep: true,
      handler: function () {
        this.onSearch(this.query);
      },
    },
    [APP_EVENTS.EVT_ON_SB_ON_REFRESH]: {
      deep: true,
      handler: function (val) {
        if (val && val.refresh) {
          this.prepareData(true, val.res);
        }
      },
    },
    'query.filters': {
      deep: true,
      handler: function (_, prev) {
        // if the filters changed and query.filters has value, we must reset page value to 1
        if (prev && this.$refs.pager) {
          this.$refs.pager.changePage(1);
        }
      },
    },
    query: {
      handler: debounce(function (query) {
        this.onSearch(query);
      }, 500),
      deep: true,
    },
    'formData.selectedSlide': {
      handler: function (slide) {
        if (slide) {
          this.addSlides([slide]);
        }
      },
      deep: true,
    },
    'formData.selectedPanel': {
      handler: function (data) {
        if (data && data.panelItems && data.panelItems.length > 0) {
          this.addSlides([...data.panelItems]);
        }
      },
      deep: true,
    },
    [APP_EVENTS.EVT_ON_SB_ADD_SLIDE]: {
      handler: function (data) {
        if (data && data.slide) {
          this.removeEvent(newAppEvent(APP_EVENTS.EVT_ON_SB_ADD_SLIDE));
          this.addSlides([data.slide]);
        }
      },
      deep: true,
    },
    [APP_EVENTS.EVT_ON_SB_ADD_PANEL]: {
      handler: function (data) {
        if (data && data.panel && data.panel.panelItems && data.panel.panelItems.length > 0) {
          this.removeEvent(newAppEvent(APP_EVENTS.EVT_ON_SB_ADD_PANEL));
          this.addSlides([...data.panel.panelItems]);
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions('app/event', ['addEvent', 'removeEvent']),
    ...mapActions('app/data', ['updateDataset']),
    ...mapActions('sb', ['$resetSelectedBlocks', '$enableIsLoading', '$disableIsLoading']),
    onSearch(query) {
      const { items, totalItems } = searchCases(this.data.cases, query.filters, query.pagination);
      this.cases = items;
      this.totalItems = totalItems;
    },
    onPageSizeChanged(pageSize) {
      this.query.pagination.pageSize = pageSize;
    },
    mergeRes(res) {
      // reload cases to binding new data
      const currentCases = {};
      this.cases.map((c) => {
        Reflect.set(currentCases, c.caseId, c);
      });
      const { data, error } = res;
      const errorCases = [];

      if (data.cases && data.cases.items && data.cases.items.length > 0) {
        data.cases.items.map((c) => {
          if (currentCases[c.caseId]) {
            Reflect.set(c, 'hidden', currentCases[c.caseId].hidden || false);
            Reflect.set(c, 'locked', currentCases[c.caseId].locked || false);
            currentCases[c.caseId] = c;
            if (c.resultMessages && c.resultMessages.length > 0) {
              errorCases.push(c.caseReference);
            }
          }
        });
      }
      const genericUpdateError =
        errorCases.length === 0
          ? null
          : this.$t('case/sb/errorMessages/updateListCasesError', {
              cases: errorCases.join(', '),
            });
      return {
        data: {
          cases: {
            items: Object.values(currentCases),
          },
        },
        error: error || genericUpdateError,
      };
    },
    async prepareData(withFilter = false, newRes = null) {
      let sbData = [];
      const namingRule = DEFAULT_SPECIMEN_BLOCK_NAMING_RULE;
      const caseSBImportSession = sessionStorage.getItem('caseSBImport');
      let res = newRes ? this.mergeRes(newRes) : null;
      if (caseSBImportSession) {
        if (!res) {
          const caseIds = JSON.parse(caseSBImportSession);
          this.$enableIsLoading();
          res = await caseListService.getSBCase(caseIds);
          this.caseIds = caseIds;
        }
        if (res.error) {
          this.$alertError(res.error);
          this.$disableIsLoading();
          return;
        }
        // otherwise
        if (this.data && this.data.cases && this.data.cases.length > 0) {
          const items = this.data.cases.map((originalCase) => {
            const c = res.data.cases.items.find((c) => c.caseId === originalCase.caseId);
            return c ? c : originalCase;
          });
          sbData = {
            ...res,
            cases: {
              items,
            },
          };
        } else {
          sbData = res.data;
        }

        // load naming rule for the 1st time
        if (sbData.cases.items.length > 0 && !this.settingRes) {
          const firstSpecimen = sbData.cases.items[0];
          const laboratoryId = firstSpecimen.laboratoryId || null;
          if (laboratoryId) {
            this.settingRes = await entityService.getBlockNamingRuleSetting(laboratoryId);
            if (this.settingRes.data) {
              Reflect.set(namingRule, 'enable1ANamingConvention', this.settingRes.data.enable1ANamingConvention);
              Reflect.set(namingRule, 'skippedIO', this.settingRes.data.skippedIO);
            }
          }
        }
      }
      // first time set commonlyUsedSlides and commonlyUsedPanels
      const data = {
        commonlyUsedSlides: this.data?.commonlyUsedSlides
          ? this.data.commonlyUsedSlides
          : computeVisibleSlides(sbData.slides),
        commonlyUsedPanels: this.data?.commonlyUsedPanels
          ? this.data.commonlyUsedPanels
          : computeVisiblePanels(sbData.panels),
        cases: sbData.cases.items.map((c) => {
          return {
            ...transformCase(c),
            hidden: c.hidden || false,
            locked: c.locked || false,
            namingRule,
          };
        }),
      };

      this.data = data;

      if (withFilter) {
        this.onSearch(this.query);
      } else {
        this.cases = data.cases;
      }
      this.$disableIsLoading();
    },
    onCancel() {
      this.$router.push(APP_ROUTES.CASE);
    },
    shouldPreventLeaving() {
      const isBlockEmpty = (caseSpecimens) => {
        let isEmpty = false;
        caseSpecimens.forEach((caseSpecimen) => {
          if (caseSpecimen.caseSpecimenBlocks.length == 0) {
            isEmpty = true;
          } else {
            caseSpecimen.caseSpecimenBlocks.forEach((caseSpecimenBlock) => {
              if (caseSpecimenBlock.blockFieldItems.length == 0) {
                isEmpty = true;
                return;
              }
            });
          }
        });
        return isEmpty;
      };
      let isPreventLeaving = false;
      this.cases.forEach((caseData) => {
        const doesItNeedToBeChecked = [
          CASE_STATUS.PATH,
          CASE_STATUS.PROVISIONALLY_REPORTED,
          CASE_STATUS.REPORTED,
        ].includes(caseData.status);
        if (isBlockEmpty(caseData.caseSpecimens) && doesItNeedToBeChecked) {
          isPreventLeaving = true;
          return;
        }
      });

      return isPreventLeaving;
    },
    generateBlock(specimenIndex, blockIndex) {
      const namingRule = this.namingRule;
      return generateSpecimenBlockName(specimenIndex, blockIndex, namingRule);
    },
    addSlides(slides) {
      this.$enableIsLoading();
      const caseIds =
        Reflect.ownKeys(this.$selectedBlocks).filter((c) => {
          return c != '__ob__' && this.$selectedBlocks[c].length > 0;
        }) || [];
      const updatingCases = this.cases.filter((c) => caseIds.includes(c.caseId));
      const cases = [];
      updatingCases.map((c) => {
        const caseData = transformCaseData(cloneDeep(c), this.generateBlock);
        const selectedBlocks = this.$selectedBlocks[c.caseId];
        // append new slides to selected blocks
        selectedBlocks.map((ele) => {
          const { specimenIndex, blockIndex } = ele;
          const block = caseData.caseSpecimens[specimenIndex].caseSpecimenBlocks[blockIndex];
          const items = block.blockFieldItems;
          slides.map((slide) => {
            items.push(slide);
          });
          block.blockFieldItems = uniqBy(items, 'fieldItemId');
        });
        const { caseId, caseSpecimens } = caseData;
        cases.push({
          caseId,
          caseSpecimens,
        });
      });
      this.saveCases({ cases, caseIds });
    },
    saveCases({ cases, caseIds }) {
      if (cases && cases.length > 0) {
        // reset save cases
        const payload = {
          queryModel: {
            selectAll: false,
            searchQuery: {
              pageSize: this.caseIds.length,
              pageNumber: 1,
              sort: 'caseReference:asc',
            },
            caseIds,
          },
          updateModel: {
            cases,
          },
        };
        caseListService
          .saveSBCase(payload)
          .then(
            (res) => {
              this.addEvent(
                newAppEvent(APP_EVENTS.EVT_ON_SB_ON_REFRESH, {
                  refresh: true,
                  res,
                }),
              );
            },
            (error) => {
              this.$alertError(error);
            },
          )
          .finally(() => {
            this.$disableIsLoading();
          });
      }
    },
  },

  beforeRouteLeave(to, from, next) {
    if (this.shouldPreventLeaving()) {
      this.$alertError(this.$t('pages/case/CaseManagement/CaseForm/error/noSlideInBlock'));
      next(false);
    } else {
      // reset state
      this.$resetSelectedBlocks();
      next(true);
    }
  },
};
</script>
<style lang="scss">
.SB-page {
  .page-title {
    display: none;
  }
  .content {
    padding-top: 0;
  }
}
</style>
