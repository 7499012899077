<template>
  <ValidationObserver ref="form">
    <md-dialog :md-close-on-esc="true" :md-active.sync="isVisible" @md-clicked-outside="onCancel" class="lims-modal">
      <div class="dialog-icon-close" @click="onCancel"><md-icon>close</md-icon></div>
      <div class="lims-form dialog-content">
        <lims-field :model="formData" :schema="completeYourAccountSchema.validateInformation" field="newEmail">
          <md-input slot="field" @keyup.enter="onNext" v-model="formData.email" type="text" maxlength="200"></md-input>
        </lims-field>
      </div>
      <md-dialog-actions>
        <md-button @click="onCancel" class="lims-form-button">{{ $t('global/button/button.cancel') }}</md-button>
        <md-button :disabled="isDisabledNextButton()" @click="onNext" class="lims-form-button md-primary">{{
          $t('global/button/button.next')
        }}</md-button>
      </md-dialog-actions>
    </md-dialog>
  </ValidationObserver>
</template>

<script>
import modalMixins from './modal.mixins';
import { getCompleteYourAccountSchema } from '@/schemas/complete-your-account.schema';
import { VERIFY_MODE } from '@/core/constants';

export default {
  name: 'modal-input-new-email',

  mixins: [modalMixins],

  props: {
    userId: {
      type: String,
      require: true,
      default: null,
    },
  },

  data() {
    return {
      formData: {
        email: null,
      },
    };
  },

  computed: {
    completeYourAccountSchema() {
      return getCompleteYourAccountSchema(VERIFY_MODE.PHONE_NUMBER, this.userId, this.token);
    },
  },

  methods: {
    onNext() {
      this.$refs.form.validate().then((success) => {
        if (success) {
          try {
            this.$emit('onNext', this.formData.email);
            this.formData.email = null;
          } catch (errors) {
            this.$alertError(errors);
          }
        } else {
          this.$alertError(this.$t(`global/errors/message`));
        }
      });
    },

    isDisabledNextButton() {
      return !this.formData.email;
    },

    onCancel() {
      this.formData.email = null;
      this.close();
    },
  },
};
</script>

<style lang="scss"></style>
