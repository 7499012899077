<template>
  <div>
    <lims-input-strength-field
      :validated="beValidUserNameLength"
      :description="$t('component/lims/modals/usernameStrength/numberCharacters')"
    ></lims-input-strength-field>
    <lims-input-strength-field
      :validated="containOnlyLetterNumberDotAndUnderScore"
      :description="$t('component/lims/modals/usernameStrength/containCharacters')"
    ></lims-input-strength-field>
    <lims-input-strength-field
      :validated="notStartOrEndWithDot"
      :description="$t('component/lims/modals/usernameStrength/notStartEnd')"
    ></lims-input-strength-field>
    <lims-input-strength-field
      :validated="notContainDotAndUnderScoreSideBySide"
      :description="$t('component/lims/modals/usernameStrength/notContain')"
    ></lims-input-strength-field>
  </div>
</template>

<script>
import LimsInputStrengthField from '@/components/Lims/LimsInputStrengthField';

export default {
  components: { LimsInputStrengthField },
  props: {
    usernameString: {
      type: String,
      required: false,
      default: null,
    },
  },

  computed: {
    beValidUserNameLength() {
      let regex = /^.{8,128}$/;
      return this.usernameString ? regex.test(this.usernameString) : false;
    },

    containOnlyLetterNumberDotAndUnderScore() {
      let regex = /^[A-Za-z\d_.]*$/;
      return this.usernameString ? regex.test(this.usernameString) : false;
    },

    notStartOrEndWithDot() {
      let regex = /^[^/.](.*[^/.])?$/;
      return this.usernameString ? Boolean(this.usernameString.match(regex)) : false;
    },

    notContainDotAndUnderScoreSideBySide() {
      let regexOne = /^((?!__).)*$/;
      let regexTwo = /^((?!_\.).)*$/;
      let regexThree = /^((?!\._).)*$/;
      let regexFour = /^((?!\.\.).)*$/;
      return this.usernameString
        ? Boolean(
            this.usernameString.match(regexOne) &&
              this.usernameString.match(regexTwo) &&
              this.usernameString.match(regexThree) &&
              this.usernameString.match(regexFour),
          )
        : false;
    },
  },
};
</script>
<style lang="scss" scoped></style>
