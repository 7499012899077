<template>
  <form
    ref="datasetControlForm"
    class="md-layout lims-form dataset-control-form"
    @submit.prevent="onSubmit()"
    v-if="formData"
  >
    <div class="form-wrapper">
      <div class="md-layout lims-form-row">
        <div class="md-layout-item md-size-33 md-small-size-100">
          <label class="required">{{ $t('entities/dataset/form/control/label') }}</label>
          <md-field>
            <md-input :disabled="disabled" v-model="formData.label" type="text" maxlength="150"></md-input>
          </md-field>
        </div>
        <div class="md-layout-item md-size-33 md-small-size-100">
          <label class="required">{{ $t('entities/dataset/form/control/controlType') }}</label>
          <lims-single-select
            :disabled="disabled"
            v-model="formData.controlType"
            :options="controlTypes"
          ></lims-single-select>
        </div>
        <div class="md-layout-item md-size-33 md-small-size-100">
          <md-checkbox :disabled="disabled" v-model="formData.isRequired" class="lims-checkbox checkbox-padding">
            {{ $t('entities/dataset/form/control/isRequired') }}
          </md-checkbox>
        </div>
      </div>
      <div class="md-layout lims-form-row">
        <div class="md-layout-item md-size-67 md-small-size-100">
          <div style="display: flex">
            <label :class="{ required: isValueRequired }">
              {{ $t('entities/dataset/form/control/value') }}
            </label>
            <lims-tooltip slot="label-info" :content="$t('entities/dataset/form/control/value/help')"></lims-tooltip>
          </div>
          <md-field>
            <md-input
              :disabled="disabled || formData.controlType === 'Textbox'"
              v-model="formData.value"
              type="text"
              maxlength="1000"
            ></md-input>
          </md-field>
        </div>
      </div>
      <div class="md-layout lims-form-row btnRight">
        <md-button :disabled="inValid" @click="onSubmit()" class="md-button lims-form-button md-primary">
          {{ editControl ? $t('global/button/button.edit') : $t('global/button/button.add') }}
        </md-button>
      </div>
    </div>
  </form>
</template>

<script>
import { DatasetFieldTypes } from '@/core/constants';
import datasetService from '@/services/dataset.service';
import * as yup from 'yup';
import debounce from 'lodash/debounce';
import { cloneDeep } from 'lodash';

export default {
  props: {
    editControl: {
      type: Object,
      required: false,
      default: null,
    },
    disabled: {
      required: false,
      default: false,
    },
  },
  watch: {
    'formData.controlType': {
      handler: function (value) {
        if (value) {
          const isValueRequired = value === DatasetFieldTypes.DROPDOWN;
          this.$set(this, 'schema', this.setupSchema(isValueRequired));
        }
      },
    },
    formData: {
      deep: true,
      handler: debounce(async function (value, prev) {
        if (value && prev) {
          this.schema.fields
            .validate(value, { abortEarly: true })
            .then(() => {
              this.inValid = false;
            })
            .catch(() => {
              this.inValid = true;
            });
        }
      }, 300),
    },
    editControl: {
      deep: true,
      handler: function (value) {
        if (value) {
          this.$set(this, 'formData', cloneDeep(value));
        } else {
          this.$set(this, 'formData', {
            index: null,
            controlId: '',
            label: '',
            controlType: '',
            isRequired: true,
            value: '',
          });
        }
      },
    },
  },
  async created() {
    this.controlTypes = await datasetService.getListControlType();
    let formData = null;
    formData = {
      index: null,
      controlId: '',
      label: '',
      controlType: '',
      isRequired: true,
      value: '',
    };
    const isValueRequired = formData.controlType === DatasetFieldTypes.DROPDOWN;
    this.schema = this.setupSchema(isValueRequired);
    this.formData = formData;
  },
  computed: {
    isValueRequired() {
      return this.formData.controlType === DatasetFieldTypes.DROPDOWN;
    },
  },
  data() {
    return {
      formData: null,
      controlTypes: [],
      schema: null,
      errors: [],
      inValid: true,
    };
  },
  methods: {
    setupSchema(isValueRequired) {
      return {
        entity: 'dataset/form/control',
        fields: yup.object().shape({
          label: yup.string().required('required'),
          controlType: yup.string().nullable().required('required'),
          value: isValueRequired ? yup.string().required('required') : yup.string(),
        }),
      };
    },
    onSubmit() {
      this.schema.fields.validate(this.formData, { abortEarly: true }).then(() => {
        const values = cloneDeep(this.formData);
        this.$emit('onSave', values);
        // clear
        this.$nextTick(() => {
          this.formData = {
            index: null,
            controlId: '',
            label: '',
            controlType: '',
            isRequired: true,
            value: '',
          };
        });
      });
    },
  },
};
</script>

<style lang="scss">
.dataset-control-form .lims-checkbox.checkbox-padding {
  padding-top: 26px;
}
</style>
