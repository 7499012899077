<template>
  <div>
    <div v-if="isSetup" class="left-text">
      <md-icon :class="isSetup ? 'check-circle-color' : ''">check_circle</md-icon>
      <label>{{ content }}</label>
    </div>
    <div v-else>
      <md-button class="btn-set-width" @click="onClick">{{ content }}</md-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isSetup: {
      type: Boolean,
      required: true,
      default: false,
    },
    content: {
      type: String,
      required: true,
      default: null,
    },
  },

  methods: {
    onClick() {
      this.$emit('onButtonClick');
    },
  },
};
</script>
<style lang="scss">
.btn-set-width {
  width: 200px;
  margin: 0 0 15px 0 !important;
}
</style>
