import { getDefaultFilterValues, getFilterFieldTypes } from '@/query/utils';
import { FIELD_TYPES } from '../constant';
const fields = [
  {
    key: 'pageSize',
    listKey: null,
    sort: 1,
    fieldType: FIELD_TYPES.Int,
    defaultValue: 10,
  },
  {
    key: 'pageNumber',
    listKey: null,
    sort: 2,
    fieldType: FIELD_TYPES.Int,
    translated: false,
    defaultValue: 1,
  },
];
const defaultValues = getDefaultFilterValues(fields);
const fieldTypes = getFilterFieldTypes(fields);
export default {
  fields,
  defaultValues,
  fieldTypes,
};
