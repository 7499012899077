<template>
  <div>
    <modal-print-label-preview slot="blockTitle" ref="printLabelPreviewModal"></modal-print-label-preview>
    <modal-case-batch-change
      v-if="bulkAction"
      ref="caseBatchChangeModal"
      slot="blockTitle"
      :bulkActionSelect="bulkAction"
      @onContinueBatchChange="onContinueBatchChange"
    ></modal-case-batch-change>
    <modal-bulk-action-commom
      v-if="bulkAction"
      ref="bulkActionCommonModal"
      slot="blockTitle"
      :bulkActionSelect="bulkAction"
      :compatibleCase="compatibleCase"
      :notCompatibleCase="notCompatibleCase"
      :dataBatchChange="dataBatchChange"
      @onCompletedAction="clearSelectedData"
    ></modal-bulk-action-commom>
    <modal-bulk-action-problem
      v-if="bulkAction"
      ref="bulkActionProblemModal"
      slot="blockTitle"
      :bulkActionSelect="bulkAction"
      :compatibleCase="compatibleCase"
      :notCompatibleCase="notCompatibleCase"
      :batchChangeSelect="batchChangeSelect"
      @onContinue="onContinue"
    ></modal-bulk-action-problem>
    <modal-discard-tissue
      v-if="bulkAction"
      ref="discardTissueModal"
      slot="blockTitle"
      :bulkActionSelect="bulkAction"
      :compatibleCase="compatibleCase"
      @onCompletedAction="clearSelectedData"
    ></modal-discard-tissue>
    <modal-export-nav-billing ref="exportNavBillingModal" @onContinue="onContinueExportNav"></modal-export-nav-billing>
    <modal-choose-version-send-email
      ref="chooseVersionSendEmailModal"
      @onSelectedVersion="onSelectedVersion"
    ></modal-choose-version-send-email>
    <modal-send-email ref="sendEmailModal"></modal-send-email>
    <div class="toolbar-row">
      <div>
        <md-checkbox v-model="isSelectAllPageMode" class="lims-checkbox">{{
          $t('pages/case/CaseManagement/masterCheckBox/isSelectAllPageMode')
        }}</md-checkbox>
        <md-checkbox v-model="isSelectCurrentPageMode" class="lims-checkbox">{{
          $t('pages/case/CaseManagement/masterCheckBox/isSelectCurrentPageMode')
        }}</md-checkbox>
      </div>
      <div v-if="!isCaseOfInterest && caseList && caseList.length > 0" class="select-action">
        <v-select
          class="action-dropdown"
          v-model="bulkAction"
          :options="bulkActionHasPermission"
          :reduce="(item) => item.value"
          :placeholder="$t('pages/case/CaseManagement/actionDropdown/selectAction.placeholder')"
        >
          <template #option="{ label }">{{ $t(label) }}</template>
          <template #selected-option="{ label }">{{ $t(label) }}</template>
        </v-select>
        <md-button
          @click="onApply"
          class="md-button lims-form-button md-theme-default apply-btn"
          :disabled="isBulkActionApplyDisabled || isProcessingBulkAction"
          >{{ $t('global/button/button.apply') }}</md-button
        >
      </div>
      <div
        v-if="caseList && caseList.length > 0 && this.$isAuthorized(['Case_CaseExport_Retrieve'])"
        class="select-action export-template"
      >
        <v-select
          class="action-dropdown"
          v-model="templateExport"
          :options="exportList"
          :reduce="(item) => item.value"
          :placeholder="$t('pages/case/CaseManagement/actionDropdown/exportTemplate.placeholder')"
        >
          <template #option="{ label }">{{ $t(label) }}</template>
          <template #selected-option="{ label }">{{ $t(label) }}</template>
        </v-select>
        <md-button
          @click="onExportTemplate(templateExport)"
          :disabled="!templateExport || isProcessingExportTemplate"
          class="md-button lims-form-button md-theme-default apply-btn"
          >{{ $t('global/button/button.export') }}</md-button
        >
      </div>
    </div>
    <md-table class="paginated-table table-striped table-hover lims-table case-list-tbl case-list-admin">
      <div class="table-fix-header">
        <thead>
          <md-table-row>
            <md-table-head class="checkbox-column">
              <md-checkbox v-model="selectAll" :indeterminate="isIndeterminate"></md-checkbox>
            </md-table-head>
            <md-table-head v-for="(col, index) in colTabl" :key="index" :class="col.class">
              <div @mouseover="hoverIn(index)" @mouseout="hoverOut()" v-html="getColumnName(col)"></div>
            </md-table-head>
          </md-table-row>
        </thead>
        <tbody>
          <md-table-row
            v-for="(item, index) in caseList"
            :key="`case-${index}`"
            v-long-press="300"
            @click.native="onNavigate($event, item)"
            @long-press-start="onPlusStart"
            @long-press-stop="onPlusStop"
            :class="checkRowHighlight(item)"
          >
            <md-table-cell class="checkbox-column">
              <md-checkbox
                v-model="selectRow"
                @change="$onSelectItem(selectRow, item.caseId)"
                :value="item.caseId"
                class="lims-checkbox"
              ></md-checkbox>
            </md-table-cell>
            <md-table-cell class="caseRef-labRef-column">
              {{ item.caseReference }}
              <md-button v-if="item.caseIssues && item.caseIssues.length > 0" class="md-just-icon md-warning md-simple">
                <issue-information :case-issues="item.caseIssues"></issue-information>
              </md-button>
              <br />
              {{ item.labReference }}
            </md-table-cell>
            <md-table-cell>{{ item.patientFullname }}</md-table-cell>
            <md-table-cell>{{ item.clinicName }}<br />{{ item.clinicianName }}</md-table-cell>
            <md-table-cell>{{
              item.pathologistId
                ? item.pathologistName
                : item.pathologistTypeName === 'Pull'
                ? item.pathologistTypeName
                : ''
            }}</md-table-cell>
            <md-table-cell>
              {{ item.firstSpecimentType }} <br />
              {{ item.numCaseSpecimen }}, {{ item.numCaseBlocks }}, {{ item.numCaseSlides }},
              {{ item.numCaseALWSlides }}
            </md-table-cell>
            <md-table-cell class="date-column">
              {{ item.procedureDate ? formatDateTimeGlobalNotIncludeTime(item.procedureDate, true) : '' }} <br />
              {{ item.procedureLabEntry ? formatDateTimeGlobalNotIncludeTime(item.procedureLabEntry) : '' }} <br />
              {{ item.reviewedDate ? formatDateTimeGlobalNotIncludeTime(item.reviewedDate) : '' }} <br />
              {{ item.reportedDate ? formatDateTimeGlobalNotIncludeTime(item.reportedDate) : '' }}
            </md-table-cell>
            <md-table-cell class="TAT-column"
              >{{ item.tat }} <br />
              {{ item.tatWoWe }}</md-table-cell
            >
            <md-table-cell class="billing-column">
              <template v-if="item.isExportedForBilling">
                {{ item.isExportedForBilling ? 'Yes' : '' }} <br />
                {{ item.exportedForBillingDate ? formatDateTimeGlobalNotIncludeTime(item.exportedForBillingDate) : '' }}
              </template>
            </md-table-cell>
            <md-table-cell>{{ item.source }} </md-table-cell>
            <md-table-cell class="ALW-column">
              <span>
                <md-icon v-if="item.caseAlwStatusId == ALW_SO_STATUS.REQUESTED" class="status-disabled">adjust</md-icon>
                <md-icon v-if="item.caseAlwStatusId == ALW_SO_STATUS.PARTIALLY_COMPLETED" class="status-invited"
                  >adjust</md-icon
                >
                <md-tooltip
                  md-direction="bottom"
                  class="tooltip-align-left"
                  v-html="
                    $t('pages/case/CaseManagement/list/outstandingALW.tooltip', {
                      outstandingALW: item.outstandingALW,
                    })
                  "
                >
                </md-tooltip>
              </span>
              <md-icon v-if="item.caseAlwStatusId == ALW_SO_STATUS.COMPLETED" class="status-enabled">adjust</md-icon>
              <md-icon v-if="item.caseAlwStatusId == ALW_SO_STATUS.CANCELLED">adjust</md-icon>
            </md-table-cell>
            <md-table-cell class="SO-column">
              <span>
                <md-icon v-if="item.caseSoStatusId == ALW_SO_STATUS.REQUESTED" class="status-disabled">adjust</md-icon>
                <md-tooltip
                  md-direction="bottom"
                  class="tooltip-align-left"
                  v-html="
                    $t('pages/case/CaseManagement/list/outstandingSO.tooltip', {
                      outstandingSO: item.outstandingSO,
                    })
                  "
                >
                </md-tooltip>
              </span>
              <md-icon v-if="item.caseSoStatusId == ALW_SO_STATUS.COMPLETED" class="status-enabled">adjust</md-icon>
              <md-icon v-if="item.caseSoStatusId == ALW_SO_STATUS.CANCELLED">adjust</md-icon>
            </md-table-cell>
            <md-table-cell class="status-column">
              <md-icon v-if="item.statusId == STATUSES.LAB" class="black-color">hourglass_empty</md-icon>
              <md-icon v-if="item.statusId == STATUSES.PATH" class="black-color">hourglass_top</md-icon>
              <md-icon v-if="item.statusId == STATUSES.PROVISIONALLY_REPORTED" class="grey-color"
                >hourglass_bottom</md-icon
              >
              <md-icon v-if="item.statusId == STATUSES.REPORTED" class="black-color">hourglass_bottom</md-icon>
              <md-icon v-if="item.statusId == STATUSES.PRE_LAB" class="black-color">hourglass_disabled</md-icon>
              <md-icon v-if="item.statusId == STATUSES.EXT_CANCELLED" class="black-color ext-cancelled-icon">
                hourglass_disabled
              </md-icon>
            </md-table-cell>
            <md-table-cell class="actions-column">
              <div class="cell-action-inner">
                <div v-if="isShowViewIcon()" class="action-item">
                  <md-button class="md-just-icon md-info md-simple" @click.native="handleAction('view', item)">
                    <md-icon>visibility</md-icon>
                  </md-button>
                </div>
                <div v-if="isShowEditIcon(item)" class="action-item">
                  <md-button class="md-just-icon md-warning md-simple" @click.native="handleAction('edit', item)">
                    <md-icon>edit</md-icon>
                  </md-button>
                </div>
                <div class="action-item">
                  <template v-if="$isAuthorized(['Case_CaseForm_Delete']) && !item.isDeleted">
                    <md-button class="md-just-icon md-danger md-simple" @click.stop="handleAction('delete', item)">
                      <md-icon>delete_forever</md-icon>
                    </md-button>
                  </template>
                  <template v-if="$isAuthorized(['Case_CaseForm_Delete']) && item.isDeleted">
                    <md-button class="md-just-icon md-danger md-simple" @click.stop="handleAction('restore', item)">
                      <md-icon>settings_backup_restore</md-icon>
                    </md-button>
                  </template>
                </div>
                <div v-if="isShowReportIcon(item)" class="action-item">
                  <md-button
                    class="md-just-icon md-info md-simple"
                    @click.stop="onExportAsPdf(item)"
                    :disabled="isProcessing"
                  >
                    <img class="pdf-img" :src="require(`@/assets/images/pdf-file.png`)" />
                  </md-button>
                </div>
                <div v-if="isShowResend(item)" class="action-item">
                  <md-button class="md-just-icon md-simple" @click.stop="handleAction('resend', item)">
                    <md-icon>send</md-icon>
                  </md-button>
                </div>
                <div v-if="isShowSendEmail(item)" class="action-item">
                  <md-button
                    class="md-just-icon md-info md-simple"
                    @click.stop="onVerifySendEmail(item.caseId)"
                    :disabled="isProcessingSendEmail"
                  >
                    <md-icon>forward_to_inbox</md-icon>
                  </md-button>
                </div>
                <div v-if="isShowResponseToIssueIcon(item)" class="action-item">
                  <md-button class="md-just-icon md-simple" @click.native="handleAction('responseToIssue', item)">
                    <md-icon>bug_report</md-icon>
                  </md-button>
                </div>
              </div>
            </md-table-cell>
          </md-table-row>
        </tbody>
      </div>
    </md-table>
    <slot name="pagination"></slot>
  </div>
</template>
<script>
// eslint-disable no-case-declarations

import { ModalCaseBatchChange, ModalBulkActionCommom, ModalBulkActionProblem } from '@/components/Lims/modals';
import ModalDiscardTissue from '@/components/Lims/modals/ModalDiscardTissue.vue';
import { BULK_ACTION, EXPORT_TEMPLATE } from '@/core/constants';
import CaseListMixin from '@/pages/Case/CaseManagement/List/caseList.mixins';
import IssueInformation from '@/pages/Case/CaseManagement/Components/IssueInformation';
import { mapActions, mapGetters } from 'vuex';
import { ModalPrintLabelPreview } from '@/components/Lims/modals';
import ReportMixins from '@/pages/Case/CaseManagement/Report/report.mixins';
import ModalExportNavBilling from '@/components/Lims/modals/ModalExportNavBilling.vue';
import { MasterCheckBoxMixins } from '@/core/mixins';
import { LIST_NAMES } from '@/store/modules/item-list';
import ModalChooseVersionSendEmail from '@/components/Lims/modals/ModalChooseVersionSendEmail';
import CaseMixins from '@/pages/Case/CaseManagement/Case.mixins';

export default {
  name: 'CaseListAdmin',
  mixins: [CaseListMixin, ReportMixins, MasterCheckBoxMixins, CaseMixins],
  components: {
    IssueInformation,
    ModalCaseBatchChange,
    ModalBulkActionCommom,
    ModalBulkActionProblem,
    ModalDiscardTissue,
    ModalPrintLabelPreview,
    ModalExportNavBilling,
    ModalChooseVersionSendEmail,
  },
  props: {
    isCaseOfInterest: {
      require: false,
      type: Boolean,
    },
    caseList: {
      required: false,
      default: function () {
        return [];
      },
    },
    totalItem: {
      require: false,
      default: 0,
    },
  },
  data() {
    return {
      listName: LIST_NAMES.CASE_LIST_ADMIN,
      selectRow: [],
      selectRowInfo: [],
      selectedRF: [],
      bulkAction: '',
      compatibleCase: [],
      notCompatibleCase: {},
      dataBatchChange: {},
      batchChangeSelect: {},
      templateExport: '',
      colTabl: [
        {
          name: ['pages.case.caseManagement/list.caseRef', 'pages.case.caseManagement/list.labRef'],
          sort: false,
          class: 'caseRef-labRef-column',
        },
        {
          name: 'pages.case.caseManagement/list.patient',
          key: 'patientFullname',
          sort: false,
          class: 'patient-column',
        },
        {
          name: ['pages.case.caseManagement/list.clinic', 'pages.case.caseManagement/list.clinician'],
          key: 'clinicName',
          sort: false,
          class: 'clinic-clinician-column',
        },

        {
          name: 'pages.case.caseManagement/list.pathologist',
          key: 'pathologistName',
          sort: false,
          class: 'pathologist-column',
        },
        {
          name: 'pages.case.caseManagement/list.specimen',
          key: 'specimen',
          sort: false,
          class: 'specimen-column',
        },
        {
          name: [
            'pages.case.caseManagement/list.Procedure',
            'pages.case.caseManagement/list.labEntry',
            'pages.case.caseManagement/list.reviewedDate',
            'pages.case.caseManagement/list.reported',
          ],
          key: 'procedure-labEntry-reviewedDate-reported',
          sort: false,
          class: 'date-column',
        },
        {
          name: ['pages.case.caseManagement/list.TATCalendarDays', 'pages.case.caseManagement/list.TATWorkingDays'],
          key: 'TATCalendarDays-TATWorkingDays',
          sort: false,
          class: 'TAT-column',
        },
        {
          name: ['pages.case.caseManagement/list.isBilled', 'pages.case.caseManagement/list.billingExportedDate'],
          key: 'IsBilled-BillingExportedDate',
          sort: false,
          class: 'billing-column',
        },
        {
          name: 'pages.case.caseManagement/list.source',
          key: 'source',
          sort: false,
          class: 'source-column',
        },
        {
          name: 'pages.case.caseManagement/list.ALW',
          key: 'caseAlwStatus',
          sort: false,
          class: 'ALW-column',
        },
        {
          name: 'pages.case.caseManagement/list.SO',
          key: 'caseSoStatus',
          sort: false,
          class: 'SO-column',
        },
        {
          name: 'pages.case.caseManagement/list.status',
          key: 'statusName',
          sort: false,
          class: 'status-column',
        },
        {
          name: 'global/pages/list.actions',
          key: 'actions',
          sort: false,
          class: 'actions-column',
        },
      ],
      hover: '',
      orderState: {
        orderField: 'caseReference',
        orderBy: 'asc',
      },
      isChecked: false,
      bulkActionList: [
        {
          value: BULK_ACTION.UploadRF,
          label: 'entities/case/bulkActionName.UploadRF',
          permission: ['Case_CaseForm_Update'],
        },
        {
          value: BULK_ACTION.RemoveRF,
          label: 'entities/case/bulkActionName.RemoveRF',
          permission: ['Case_CaseForm_Update'],
        },
        {
          value: BULK_ACTION.BatchChange,
          label: 'entities/case/bulkActionName.BatchChange',
          permission: ['Case_CaseForm_Update'],
        },
        {
          value: BULK_ACTION.Delete,
          label: 'entities/case/bulkActionName.Delete',
          permission: ['Case_CaseForm_Delete'],
        },
        {
          value: BULK_ACTION.MarkAsUrgent,
          label: 'entities/case/bulkActionName.MarkAsUrgent',
          permission: ['Case_CaseForm_Update'],
        },
        {
          value: BULK_ACTION.ReviewCase,
          label: 'entities/case/bulkActionName.ReviewCase',
          permission: ['Case_CaseForm_Validate'],
        },
        {
          value: BULK_ACTION.MarkAsOptedOut,
          label: 'entities/case/bulkActionName.MarkAsOptedOut',
          permission: ['Case_CaseForm_Update'],
        },
        {
          value: BULK_ACTION.SBImport,
          label: 'entities/case/bulkActionName.SBImport',
          permission: ['Case_CaseForm_Update'],
        },
        {
          value: BULK_ACTION.DiscardTissue,
          label: 'entities/case/bulkActionName.DiscardTissue',
          permission: ['Case_CaseForm_Update'],
        },
        {
          value: BULK_ACTION.ResendExternalReport,
          label: 'entities/case/bulkActionName.ResendExternalReport',
          permission: ['Case_ExternalHospital_Update'],
        },
        {
          value: BULK_ACTION.RemoveBillingFlag,
          label: 'entities/case/bulkActionName.RemoveBillingFlag',
          permission: ['Case_CaseForm_Update'],
        },
      ],
      exportTemplateList: [
        {
          value: EXPORT_TEMPLATE.CompleteFile,
          label: 'entities/case/exportTemplateName.CompleteFile',
        },
        {
          value: EXPORT_TEMPLATE.MinimalFile,
          label: 'entities/case/exportTemplateName.MinimalFile',
        },
        {
          value: EXPORT_TEMPLATE.LabStatsFile,
          label: 'entities/case/exportTemplateName.LabStatsFile',
        },
        {
          value: EXPORT_TEMPLATE.MonthlyBill,
          label: 'entities/case/exportTemplateName.MonthlyBill',
        },
        {
          value: EXPORT_TEMPLATE.SlideP1000Pathologist,
          label: 'entities/case/exportTemplateName.SlideP1000Pathologist',
        },
        {
          value: EXPORT_TEMPLATE.SlideP1000Clinic,
          label: 'entities/case/exportTemplateName.SlideP1000Clinic',
        },
        {
          value: EXPORT_TEMPLATE.SlideP1000CaseReference,
          label: 'entities/case/exportTemplateName.SlideP1000CaseReference',
        },
        {
          value: EXPORT_TEMPLATE.SlideP1000AlwClinic,
          label: 'entities/case/exportTemplateName.SlideP1000AlwClinic',
        },
        {
          value: EXPORT_TEMPLATE.SlideP1000AlwCaseReference,
          label: 'entities/case/exportTemplateName.SlideP1000AlwCaseReference',
        },
        {
          value: EXPORT_TEMPLATE.Alw,
          label: 'entities/case/exportTemplateName.Alw',
        },
        {
          value: EXPORT_TEMPLATE.TissueRemaining,
          label: 'entities/case/exportTemplateName.TissueRemaining',
        },
        {
          value: EXPORT_TEMPLATE.PrintLabels,
          label: 'entities/case/exportTemplateName.PrintLabels',
        },
        {
          value: EXPORT_TEMPLATE.ReturnsEmail,
          label: 'entities/case/exportTemplateName.ReturnsEmail',
        },
        {
          value: EXPORT_TEMPLATE.ReturnsPrint,
          label: 'entities/case/exportTemplateName.ReturnsPrint',
        },
        {
          value: EXPORT_TEMPLATE.ReturnsSummary,
          label: 'entities/case/exportTemplateName.ReturnsSummary',
        },
        {
          value: EXPORT_TEMPLATE.MedservNAVBilling,
          label: 'entities/case/exportTemplateName.MedservNAVBilling',
        },
        {
          value: EXPORT_TEMPLATE.NonMedservNAVBilling,
          label: 'entities/case/exportTemplateName.NonMedservNAVBilling',
        },
      ],
      exportTemplateSupperAdminList: [
        {
          value: EXPORT_TEMPLATE.CompleteFile,
          label: 'entities/case/exportTemplateName.CompleteFile',
        },
        {
          value: EXPORT_TEMPLATE.MinimalFile,
          label: 'entities/case/exportTemplateName.MinimalFile',
        },
        {
          value: EXPORT_TEMPLATE.LabStatsFile,
          label: 'entities/case/exportTemplateName.LabStatsFile',
        },
        {
          value: EXPORT_TEMPLATE.MasterFile,
          label: 'entities/case/exportTemplateName.MasterFile',
        },
        {
          value: EXPORT_TEMPLATE.MonthlyBill,
          label: 'entities/case/exportTemplateName.MonthlyBill',
        },
        {
          value: EXPORT_TEMPLATE.SlideP1000Pathologist,
          label: 'entities/case/exportTemplateName.SlideP1000Pathologist',
        },
        {
          value: EXPORT_TEMPLATE.SlideP1000Clinic,
          label: 'entities/case/exportTemplateName.SlideP1000Clinic',
        },
        {
          value: EXPORT_TEMPLATE.SlideP1000CaseReference,
          label: 'entities/case/exportTemplateName.SlideP1000CaseReference',
        },
        {
          value: EXPORT_TEMPLATE.SlideP1000AlwClinic,
          label: 'entities/case/exportTemplateName.SlideP1000AlwClinic',
        },
        {
          value: EXPORT_TEMPLATE.SlideP1000AlwCaseReference,
          label: 'entities/case/exportTemplateName.SlideP1000AlwCaseReference',
        },
        {
          value: EXPORT_TEMPLATE.Alw,
          label: 'entities/case/exportTemplateName.Alw',
        },
        {
          value: EXPORT_TEMPLATE.TissueRemaining,
          label: 'entities/case/exportTemplateName.TissueRemaining',
        },
        {
          value: EXPORT_TEMPLATE.PrintLabels,
          label: 'entities/case/exportTemplateName.PrintLabels',
        },
        {
          value: EXPORT_TEMPLATE.ReturnsEmail,
          label: 'entities/case/exportTemplateName.ReturnsEmail',
        },
        {
          value: EXPORT_TEMPLATE.ReturnsPrint,
          label: 'entities/case/exportTemplateName.ReturnsPrint',
        },
        {
          value: EXPORT_TEMPLATE.ReturnsSummary,
          label: 'entities/case/exportTemplateName.ReturnsSummary',
        },
        {
          value: EXPORT_TEMPLATE.MedservNAVBilling,
          label: 'entities/case/exportTemplateName.MedservNAVBilling',
        },
        {
          value: EXPORT_TEMPLATE.NonMedservNAVBilling,
          label: 'entities/case/exportTemplateName.NonMedservNAVBilling',
        },
      ],
    };
  },
  computed: {
    ...mapGetters('auth', ['isSuperAdmin']),
    ...mapGetters('itemList', [LIST_NAMES.CASE_LIST_ADMIN]),
    exportList() {
      return this.isSuperAdmin ? this.exportTemplateSupperAdminList : this.exportTemplateList;
    },
    bulkActionHasPermission() {
      let listHasPermission = [];
      this.bulkActionList.forEach((item) => {
        if (this.$isAuthorized(item.permission)) {
          listHasPermission.push(item);
        }
      });
      return listHasPermission;
    },
  },
  watch: {
    [LIST_NAMES.CASE_LIST_ADMIN]: {
      deep: true,
      handler: function (listState) {
        this.$toggleSelectAllCheckbox(listState);
      },
    },
    templateExport: {
      handler: function (val) {
        if (val && this.selectRow && this.selectRow.length === 0) {
          this.selectAll = true;
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions('app/event', ['addEvent']),
    ...mapActions('caseList', ['updateList']),

    isShowInfoIcon(item) {
      return (
        (item.statusId == this.STATUSES.PATH || item.statusId == this.STATUSES.PROVISIONALLY_REPORTED) &&
        (item.numCaseAlwRequested > 0 || item.numCaseSoRequested > 0)
      );
    },

    onContinueExportNav(val) {
      this.onExportNav(val.template, val.dataExport);
    },
  },
};
</script>
<style lang="scss" scoped>
.lims-table.case-list-admin .table-fix-header th,
.lims-table.case-list-admin .table-fix-header td {
  width: 100px;
  min-width: 100px;
  &.TAT-column {
    width: 130px;
    min-width: 130px;
  }
  &.billing-column {
    width: 120px;
    min-width: 120px;
  }
}
.case-list-tbl.case-list-admin .actions-column {
  width: 200px !important;
}
</style>
