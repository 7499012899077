<template>
  <div>
    <modal-view-only-document-viewer
      ref="modalViewOnlyDocumentViewer"
      :case-form-resource="caseDocuments"
      @onHideDocumentViewer="onHideDocumentViewer"
    ></modal-view-only-document-viewer>
    <md-dialog
      :md-close-on-esc="true"
      :md-active.sync="isVisible"
      @md-clicked-outside="close"
      class="lims-modal large-modal searchSimilarPatient-modal"
      :style="onShowDocumentViewer ? { 'z-index': 1 } : {}"
    >
      <div class="dialog-icon-close" @click="close"><md-icon>close</md-icon></div>
      <div class="md-layout lims-form">
        <div class="md-layout-item" v-if="query">
          <search-similar-patient-list :caseList="items" @openDocumentViewer="openDocumentViewer">
            <md-table-toolbar slot="toolbar">
              <div class="toolbar-row similar-toolbar-row mg-top-30">
                <md-field class="field-btn">
                  <lims-row-per-page :pageSize="query.pagination.pageSize" @pageSizeChanged="$pageSizeChanged" />
                </md-field>
              </div>
              <div class="toolbar-row similar-toolbar-row">
                {{
                  $t('components/lims/modals/ModalSearchSimilarPatient.txt', {
                    numOfCase: totalItem,
                  })
                }}
              </div>
            </md-table-toolbar>
          </search-similar-patient-list>
          <lims-pagination :total-item="totalItem" :pagination="query.pagination"> </lims-pagination>
        </div>
        <md-dialog-actions>
          <md-button @click="close" class="md-button lims-form-button md-theme-default">
            {{ $t('global/button/button.back') }}
          </md-button>
        </md-dialog-actions>
      </div>
    </md-dialog>
  </div>
</template>

<script>
import modalMixins from './modal.mixins';
import { ConfigMixins, ListWithFilterMixins } from '@/core/mixins';
import { similarPatientQuery } from '@/query';
import SearchSimilarPatientList from '@/pages/Case/CaseManagement/List/SearchSimilarPatientList.vue';
import { buildFilterFromQuery, buildFilterQueryParams } from '@/query/utils';
import cloneDeep from 'lodash/cloneDeep';
import LimsPagination from '@/components/Lims/LimsPagination';
import LimsRowPerPage from '@/components/Lims/LimsRowPerPage';
import { caseFormService } from '@/services';
import ModalViewOnlyDocumentViewer from '@/components/Lims/modals/ModalViewOnlyDocumentViewer';

export default {
  name: 'ModalSearchSimilarPatient',
  mixins: [modalMixins, ConfigMixins, ListWithFilterMixins],
  props: {
    caseId: String,
  },
  components: {
    ModalViewOnlyDocumentViewer,
    LimsRowPerPage,
    LimsPagination,
    SearchSimilarPatientList,
  },
  created() {
    this.initState();
  },
  data() {
    return {
      totalItem: null,
      query: null,
      items: [],
      firstTime: true,
      caseDocuments: null,
      onShowDocumentViewer: false,
    };
  },
  computed: {
    fields() {
      return similarPatientQuery.filters.fields;
    },
    defaultValues() {
      return similarPatientQuery.filters.defaultValues;
    },
  },

  methods: {
    initState() {
      const queryParams = this.$route.query;
      const filters = buildFilterFromQuery(similarPatientQuery.filters, queryParams);
      const pagination = buildFilterFromQuery(similarPatientQuery.pagination, {
        pageSize: this.$appConfig.itemPerPageDefault,
        ...queryParams,
      });
      const sort = buildFilterFromQuery(similarPatientQuery.sort, queryParams);
      const query = { filters, pagination, sort };
      this.query = query;
    },
    async search(queryObject) {
      const tabId = sessionStorage.getItem('tabId');
      const { error, data } = await caseFormService.getSimilarPatient(this.caseId, tabId);
      // detect error and show alert to user
      if (error) {
        this.$alertError(error);
        // remove loading screen and allow user to interact
        return;
      }

      // binding data
      this.items = data.cases.items;
      this.totalItem = data.cases.pagination.totalItems;
      // update router
      const query = {
        ...buildFilterQueryParams(queryObject.filters),
        ...buildFilterQueryParams(queryObject.pagination),
        //...buildFilterQueryParams(queryObject.sort),
      };
      if (this.firstTime) {
        this.firstTime = false;
      } else {
        this.$router
          .push({
            query,
          })
          .catch((error) => {
            if (
              error.name === 'NavigationDuplicated' &&
              error.message.includes('Avoided redundant navigation to current location')
            ) {
              history.pushState({}, '', new URL(new URL(window.location)));
            }
          });
      }
    },
    onResetFilters() {
      this.query.filters = cloneDeep(similarPatientQuery.filters.defaultValues);
    },
    openDocumentViewer(caseDocuments) {
      this.onShowDocumentViewer = true;
      this.caseDocuments = caseDocuments;
      this.$refs.modalViewOnlyDocumentViewer.open();
    },
    onHideDocumentViewer() {
      this.onShowDocumentViewer = false;
    },
  },
};
</script>

<style lang="scss">
.similar-toolbar-row {
  margin-bottom: 50px !important;
}
</style>
