import { NUMBER_OF_CHARACTERS_SEARCH } from '@/core/constants';
import httpClient from './httpClient';

const RESOURCE = 'cases/anonymise-requests';

export default {
  getAnonymiseRequestList({ filters, pagination, sort }) {
    const { caseRefIds, requesterIds, requesterTypeIds, statusIds, search } = filters;
    const { pageNumber, pageSize } = pagination;
    const userQueryParams = {};

    if (caseRefIds) {
      userQueryParams['Filters.AdvanceSearch.caseReference'] = caseRefIds;
    }
    if (requesterIds) {
      userQueryParams['Filters.AdvanceSearch.CaseAnonymiseRequestUserId'] = requesterIds;
    }
    if (requesterTypeIds) {
      userQueryParams['Filters.AdvanceSearch.CaseAnonymiseRequestUserTypeId'] = requesterTypeIds;
    }
    if (statusIds) {
      userQueryParams['Filters.AdvanceSearch.caseAnonymiseRequestStatus'] = statusIds;
    }
    if (search && search.length >= NUMBER_OF_CHARACTERS_SEARCH) {
      userQueryParams['Filters.Search'] = search;
    }
    if (pageNumber) {
      userQueryParams['PageNumber'] = pageNumber;
    }
    if (pageSize) {
      userQueryParams['PageSize'] = pageSize;
    }
    if (sort) {
      userQueryParams['Sort'] = sort;
    }
    return httpClient.get(`/${RESOURCE}`, userQueryParams);
  },
  approve(requestId, password) {
    return httpClient.put(`/${RESOURCE}/${requestId}/approve`, {
      password: password,
    });
  },
  reject(comment, requestId, password) {
    return httpClient.put(`/${RESOURCE}/${requestId}/reject`, {
      comment: comment,
      password: password,
    });
  },
  validate(requestIds, action) {
    return httpClient.post(`/${RESOURCE}/validate`, {
      requests: requestIds,
      action: action,
    });
  },
  bulk(requestIds, action, password) {
    return httpClient.post(`/${RESOURCE}/bulk`, {
      requests: requestIds,
      action: action,
      password: password,
    });
  },
};
