<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit, invalid }">
    <md-dialog
      :md-close-on-esc="true"
      :md-active.sync="isVisible"
      @md-clicked-outside="close"
      class="lims-modal large-modal modal-create-alw-request"
    >
      <div class="dialog-icon-close" @click="close"><md-icon>close</md-icon></div>
      <form class="md-layout lims-form" @submit.prevent="handleSubmit()">
        <lims-block class="block-not-title">
          <div slot="blockContent">
            <div class="md-layout lims-form-row">
              <div class="md-layout-item md-size-100 md-small-size-100">
                <lims-field class="textarea-field" :model="formData" :schema="schema" field="caseBrief">
                  <md-textarea slot="field" v-model="formData.caseBrief"></md-textarea>
                </lims-field>
              </div>
              <div class="md-layout-item md-size-100 md-small-size-100">
                <lims-field class="textarea-field" :model="formData" :schema="schema" field="specialityId">
                  <v-select
                    multiple
                    slot="field-outside"
                    v-model="formData.specialityId"
                    label="fieldItemName"
                    :options="specialityList"
                    :reduce="(option) => option.fieldItemId"
                    :selectable="(option) => !formData.specialityId.includes(option.fieldItemId)"
                    @input="valLaboratory"
                    :placeholder="$t('components/ModalCreateSoRequest.placeholderSpeciality')"
                  >
                    <template #option="{ fieldItemName }">{{ fieldItemName }}</template>
                    <template #selected-option="{ fieldItemName }">{{ fieldItemName }}</template>
                  </v-select>
                </lims-field>
              </div>
              <div class="md-layout-item md-size-100 md-small-size-100">
                <lims-field class="textarea-field" :model="formData" :schema="schema" field="userIds">
                  <v-select
                    multiple
                    slot="field-outside"
                    v-model="formData.userIds"
                    label="text"
                    :options="eligiblePathologistsList"
                    :selectable="(option) => !formData.userIds.includes(option)"
                    :placeholder="$t('components/ModalCreateSoRequest.placeholderEligiblePathologists')"
                  >
                    <template #option="{ text }">{{ text }}</template>
                    <template #selected-option="{ text }">{{ text }}</template>
                  </v-select>
                </lims-field>
              </div>

              <div class="md-layout-item md-size-100 md-small-size-100">
                <label>{{ $t('entities/modals/ModalCreateSoRequest/pathologistNote') }}</label>
                <div class="pathologist-noteList pd-top-10" v-for="item in formData.userIds" :key="`note${item.id}`">
                  <div class="name">{{ item.text }}</div>
                  <div class="note">{{ item.note }}</div>
                </div>
              </div>
            </div>
          </div>
        </lims-block>

        <md-dialog-actions>
          <md-button @click="close" class="lims-form-button">{{ $t('global/button/button.cancel') }}</md-button>
          <md-button @click="onCreate" :disabled="invalid || isProcessing" class="lims-form-button md-primary">{{
            $t('global/button/button.create')
          }}</md-button>
        </md-dialog-actions>
      </form>
    </md-dialog>
  </ValidationObserver>
</template>

<script>
import { APP_EVENTS, DROPDOWN_SHORT_NAME, newAppEvent, USER_TYPE } from '@/core/constants';
import modalMixins from './modal.mixins';
import { DropdownService, pathCaseService } from '@/services';
import { mapActions } from 'vuex';

export default {
  name: 'ModalCreateSoRequest',
  mixins: [modalMixins],
  props: {
    caseId: {
      type: String,
      require: false,
    },
    caseBrief: {
      type: String,
      require: false,
    },
    specimenTypeId: {
      require: true,
    },
    isPauseCountReturnToPull: {
      type: Boolean,
      require: false,
    },
  },
  created: function () {
    this.fetchData();
  },
  data() {
    return {
      formData: {
        caseBrief: '',
        specialityId: '',
        userIds: '',
      },
      specialityList: [],
      eligiblePathologistsList: [],
    };
  },
  computed: {
    schema() {
      return {
        entity: 'modals/ModalCreateSoRequest',
        fields: {
          caseBrief: 'required',
          specialityId: '',
          userIds: 'required',
        },
      };
    },
  },
  methods: {
    ...mapActions('app/event', ['addEvent']),
    async fetchData() {
      this.specialityList = await this.loadPathologistBySpeciality();
    },
    async open() {
      this.isVisible = true;
      if (this.caseBrief) {
        this.formData.caseBrief = this.caseBrief;
      }
      // load Pathologists By Speciality Default
      this.formData.specialityId = await this.loadPathologistsBySpecialityIdsDefault();
      // load pathologist user list default
      await this.valLaboratory(this.formData.specialityId);
    },

    async loadPathologistsBySpecialityIdsDefault() {
      let pathologistsBySpecialityList;

      const res = await DropdownService.getFieldsInItemFormByItemId({
        itemId: this.specimenTypeId,
      });
      const detailOfSpecimenType = res.data;

      detailOfSpecimenType.internalFields.forEach(function (item) {
        if (item.fieldName == 'Speciality') {
          pathologistsBySpecialityList = item.selectedItems;
        }
      });
      return pathologistsBySpecialityList.map((obj) => obj.itemId);
    },

    async loadPathologistBySpeciality() {
      const res = await DropdownService.getDropdownByShortNames([DROPDOWN_SHORT_NAME.SPECIALITY]);
      return res.Speciality;
    },
    async loadSoRequestPathologistUser(value) {
      const { data } = await pathCaseService.getSoRequestPathologistUser(value);
      return data;
    },
    async valLaboratory(val) {
      const data = {
        specialityIds: val,
        caseId: this.caseId,
        search: '',
      };
      this.eligiblePathologistsList = await this.loadSoRequestPathologistUser(data);
    },
    dataSORequest() {
      const userIdData = [];
      const userSelect = this.formData.userIds;
      if (userSelect && userSelect.length > 0) {
        userSelect.forEach(function (item) {
          userIdData.push(item.id);
        });
      }
      const data = {
        caseBrief: this.formData.caseBrief,
        userIds: userIdData,
      };
      return data;
    },
    onCreate() {
      this.userType = this.$store.getters['auth/userType'];
      this.$refs.form.validate().then(async (success) => {
        if (success) {
          this.isProcessing = true;
          try {
            const dataForm = this.dataSORequest();
            const res = await pathCaseService.createCaseSoRequest(this.caseId, dataForm);
            this.isProcessing = false;
            if (res.err) {
              return this.$alertError(res.err + ' error');
            }
            this.close();
            this.$alertSuccess(this.$t('components/ModalCreateSoRequest.created.successMessage'));
            this.addEvent(
              newAppEvent(APP_EVENTS.EVT_ON_RELOAD_SO, {
                caseId: this.caseId,
              }),
            );
            if (this.userType === USER_TYPE.Pathologist && !this.isPauseCountReturnToPull) {
              this.addEvent(
                newAppEvent(APP_EVENTS.EVT_ON_CASE_HIDE_COUNT_DOWN, {
                  onHideCountDown: true,
                }),
              );
            }
          } catch (errors) {
            this.isProcessing = false;
            this.$alertError(errors);
          }
        } else {
          this.isProcessing = false;
          this.$alertError(this.$t(`global/errors/message`));
        }
      });
    },
    close() {
      this.isVisible = false;
      this.$nextTick(() => {
        this.formData = {
          caseBrief: '',
          specialityId: '',
          userIds: '',
        };
      });
    },
  },
};
</script>

<style lang="scss">
.pathologist-noteList {
  width: 100%;
  display: flex;
  padding-left: 10px;
  .name {
    width: 30%;
    margin-right: 10px;
  }
}
</style>
