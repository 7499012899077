// Layouts
const DashboardLayout = () => import('@/layouts/DashboardLayout.vue');

// Dataset pages
import Dataset from '@/pages/Dataset/Dataset.vue';
import DatasetAdd from '@/pages/Dataset/Add/DatasetAdd.vue';
import DatasetEdit from '@/pages/Dataset/Edit/DatasetEdit.vue';

import guards from './guards';
import { FORM_MODES } from '@/core/constants';

export default {
  path: '/dataset',
  component: DashboardLayout,
  meta: {
    zone: ['MyEntity_DatasetManagement_Retrieve'],
  },
  beforeEnter: guards.checkAuthorizedGuard,
  children: [
    {
      path: '/',
      name: 'DatasetManagement',
      component: Dataset,
      meta: {
        zone: ['MyEntity_DatasetManagement_Retrieve'],
      },
      beforeEnter: guards.checkAuthorizedGuard,
    },
    {
      path: '/dataset/add',
      name: 'DatasetAdd',
      component: DatasetAdd,
      meta: {
        zone: ['MyEntity_DatasetManagement_Create'],
      },
      beforeEnter: guards.checkAuthorizedGuard,
    },
    {
      path: '/dataset/edit/:datasetId',
      name: 'DatasetEdit',
      component: DatasetEdit,
      meta: {
        zone: ['MyEntity_DatasetManagement_Update'],
      },
      beforeEnter: guards.checkAuthorizedGuard,
      props: (route) => ({
        formMode: FORM_MODES.EDIT,
        datasetId: route.params.datasetId,
      }),
    },
    {
      path: '/dataset/view/:datasetId',
      name: 'DatasetView',
      component: DatasetEdit,
      meta: {
        zone: ['MyEntity_DatasetManagement_Retrieve'],
      },
      beforeEnter: guards.checkAuthorizedGuard,
      props: (route) => ({
        formMode: FORM_MODES.VIEW,
        datasetId: route.params.datasetId,
      }),
    },
  ],
};
