<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit, invalid }">
    <form class="md-layout lims-form" @submit.prevent="handleSubmit()">
      <md-dialog
        :md-close-on-esc="true"
        :md-active.sync="isVisible"
        @md-clicked-outside="onClose"
        class="lims-modal modal-report-issue"
      >
        <div class="dialog-icon-close" @click="onClose"><md-icon>close</md-icon></div>
        <md-dialog-title></md-dialog-title>
        <div class="dialog-content">
          <div class="md-layout lims-form-row issue-field">
            <div class="md-layout-item md-size-100 md-small-size-100">
              <lims-field :model="formData" :schema="schema" field="issue">
                <v-select
                  class="not-item-in-v-select-error"
                  slot="field-outside"
                  v-model="formData.issueId"
                  :options="issueCaseList"
                  :reduce="(item) => item.issueId"
                  label="issueName"
                  :placeholder="$t('modals/ModalReportIssue.placeholderIssue')"
                >
                  <template #option="{ issueName }">{{ issueName }}</template>
                  <template #selected-option="{ issueName }">{{ issueName }}</template>
                </v-select>
              </lims-field>
            </div>
            <div
              class="md-layout-item md-size-100 md-small-size-100 issue-prevent-update-status"
              v-if="!issuePreventUpdateStatusIsNone()"
            >
              {{
                $t('modals/ModalReportIssue.note', {
                  status: status,
                })
              }}
            </div>
          </div>
          <div class="md-layout lims-form-row mark-as-closed" v-if="formData.issueId && issueFieldIsNone()">
            <div class="md-layout-item md-size-50 md-small-size-100">
              <md-checkbox v-model="formData.isMarkIssueAsClose" class="lims-checkbox checkbox-field">
                {{ $t('entities/modals/ModalSelectEntityType/markIssueAsClosed') }}
              </md-checkbox>
            </div>
          </div>
          <div class="md-layout lims-form-row to-respond" v-if="formData.issueId && !issueFieldIsNone()">
            <div class="md-layout-item md-size-100 md-small-size-100">
              <lims-field :model="formData" :schema="schema" field="toRespond">
                <v-select
                  multiple
                  class="not-item-in-v-select-error"
                  slot="field-outside"
                  v-model="formData.toRespond"
                  :options="userTypeOptionList"
                  :placeholder="$t('modals/ModalReportIssue.placeholderToRespond')"
                >
                  <template #option="{ label }">{{ label }}</template>
                  <template #selected-option="{ label }">{{ label }}</template>
                </v-select>
              </lims-field>
            </div>
          </div>
          <div class="md-layout lims-form-row">
            <div class="md-layout-item md-size-100 md-small-size-100 dialog-texterea-field">
              <lims-field :model="formData" :schema="schema" field="comment">
                <md-textarea slot="field" v-model="formData.commentOnIssue" type="text"></md-textarea>
              </lims-field>
            </div>
          </div>
        </div>
        <md-dialog-actions>
          <md-button @click="onClose" class="lims-form-button">{{ $t('global/button/button.cancel') }}</md-button>
          <md-button @click="onCreateIssue" :disabled="invalid || isProcessing" class="lims-form-button md-danger">{{
            $t('pages/case/CaseManagement/components/issueLog/button.create')
          }}</md-button>
        </md-dialog-actions>
      </md-dialog>
    </form>
  </ValidationObserver>
</template>

<script>
import modalMixins from './modal.mixins';
import { CaseIssueMixins } from '@/pages/Case/CaseManagement/Case.mixins';
import { CASE_FORM_BLOCK, FORM_MODES } from '@/core/constants';
import { CaseIssueService } from '@/services';
import CaseBlockMixins from '@/pages/Case/CaseManagement/CaseBlock.mixins';

export default {
  mixins: [modalMixins, CaseIssueMixins, CaseBlockMixins],
  props: {
    formMode: {
      type: Number,
      require: true,
      validator: function (value) {
        // The value must match one of these strings
        return Object.values(FORM_MODES).indexOf(value) !== -1;
      },
    },
    issueCaseList: {
      type: Array,
      required: false,
    },
    caseId: {
      type: String,
      required: false,
    },
  },
  computed: {
    schema() {
      return {
        entity: 'modals/ModalSelectEntityType',
        fields: {
          issue: 'required',
          toRespond: 'required',
          comment: '',
        },
      };
    },
  },
  watch: {
    userTypeList: {
      deep: true,
      handler: function () {
        this.userTypeOptionList = this.userTypeList;
      },
    },
    'formData.toRespond': {
      handler(val) {
        if (val) {
          this.userTypeOptionList = this.userTypeList.filter(
            (option) => !val.find((item) => item.value === option.value),
          );
        }
      },
      deep: true,
    },
  },
  methods: {
    async onCreateIssue() {
      if (this.formMode === FORM_MODES.ADD) {
        if (this.formData.issueId) {
          this.isProcessing = true;
          this.$emit('onAddIssue', {
            ...this.createIssueData(),
          });
        }
      } else {
        if (this.formMode === FORM_MODES.EDIT) {
          this.isProcessing = true;
          const res = await CaseIssueService.createCaseIssue(this.caseId, this.issueDataEditMode());
          this.isProcessing = false;
          if (res.err) {
            return this.$alertError(res.err + ' error');
          } else {
            this.formData.caseIssueId = res.data.caseIssueId;
            this.$onUpdateRowVersion({ caseId: this.caseId, rowVersion: res.data.rowVersion });
            this.$emit('onAddIssue', {
              ...this.createIssueData(),
            });
          }
        }
      }
      this.formData = {};
    },
  },
  data() {
    return {
      userTypeOptionList: [],
      blockId: CASE_FORM_BLOCK.BLOCK_ISSUE_LOG,
    };
  },
};
</script>
<style lang="scss">
.modal-report-issue .checkbox-field {
  @media only screen and (max-width: 959px) {
    padding-top: 0;
  }
}
.mark-as-closed {
  margin: 10px 0 30px 0;
}
.to-respond {
  margin: 30px 0 30px 0;
}
.issue-field {
  margin-bottom: 30px;
}
</style>
