import { ENTITY_TYPES } from '@/core/constants';
import { cloneDeep } from 'lodash';

const InformationDTO = {
  entityTypeId: 'required',
  entityName: 'required',
  address1: '',
  address2: '',
  town: '',
  countryId: '',
  postalCode: '',
  email: 'email',
  telephoneOffice: 'notValid:validPhoneNumberCheck',
  comment: '',
};

const PanelsDTO = {
  name: '',
  statusId: '',
  HE: '',
  SpecialStains: '',
  Immunos: '',
  AddTechniques: '',
  comment: '',
};

const ClinicEntitySchema = {
  information: {
    entity: 'entity/information',
    fields: {
      ...InformationDTO,
      clinicTypeId: 'required',
      clinicBillingReference: 'required',
      accountReference: 'required',
      billingFrequency: '',
      commonlyUsedSpecimenTypes: '',
      entityBillings: 'required',
      entityWorkStreams: 'required',
    },
  },
  _serverSideValidators: {
    entityName: {
      fieldName: 'entityName',
      errorType: 'unique',
      functionName: 'entityCheckUnique',
      params: {},
    },
    clinicBillingReference: {
      fieldName: 'clinicBillingReference',
      errorType: 'unique',
      functionName: 'entityCheckUnique',
      params: {},
    },
  },
};
const ClinicLaboratoryEntitySchema = {
  information: {
    entity: 'entity/information',
    fields: {
      ...InformationDTO,
      clinicTypeId: 'required',
      clinicBillingReference: 'required',
      labBillingReference: 'required',
      address1: 'required',
      address2: '',
      town: 'required',
      countryId: 'required',
      postalCode: 'required',
      billingFrequency: '',
      commonlyUsedSpecimenTypes: '',
      entityBillings: 'required',
      entityWorkStreams: 'required',
      sendRescanRequestToEmail: 'listEmailValid|listEmailDuplicate',
    },
  },
  panels: {
    entity: 'entity/panels',
    fields: {
      ...PanelsDTO,
    },
  },
  _serverSideValidators: {
    entityName: {
      fieldName: 'entityName',
      errorType: 'unique',
      functionName: 'entityCheckUnique',
      params: {},
    },
    clinicBillingReference: {
      fieldName: 'clinicBillingReference',
      errorType: 'unique',
      functionName: 'entityCheckUnique',
      params: {},
    },
    labBillingReference: {
      fieldName: 'labBillingReference',
      errorType: 'unique',
      functionName: 'entityCheckUnique',
      params: {},
    },
  },
};
const LaboratoryEntitySchema = {
  information: {
    entity: 'entity/information',
    fields: {
      ...InformationDTO,
      labBillingReference: 'required',
      address1: 'required',
      address2: '',
      town: 'required',
      countryId: 'required',
      postalCode: 'required',
      canBookCase: '',
      canProcessAlw: '',
      canAllowCustomStain: '',
    },
  },
  _serverSideValidators: {
    entityName: {
      fieldName: 'entityName',
      errorType: 'unique',
      functionName: 'entityCheckUnique',
      params: {},
    },
    labBillingReference: {
      fieldName: 'labBillingReference',
      errorType: 'unique',
      functionName: 'entityCheckUnique',
      params: {},
    },
  },
};

const prepareEntitySchema = (entitySchema, params, disabledAccountReference) => {
  const schema = cloneDeep(entitySchema);

  schema._serverSideValidators.entityName.params = {
    entityId: params ? params.entityId : '',
    field: 'entityName',
  };

  if (schema._serverSideValidators.clinicBillingReference) {
    schema._serverSideValidators.clinicBillingReference.params = {
      entityId: params ? params.entityId : '',
      field: 'clinicBillingReference',
    };
  }
  if (schema._serverSideValidators.labBillingReference) {
    schema._serverSideValidators.labBillingReference.params = {
      entityId: params ? params.entityId : '',
      field: 'labBillingReference',
    };
  }

  if (disabledAccountReference) {
    schema.information.fields.accountReference = '';
  } else {
    schema.information.fields.accountReference = 'required';
  }

  // default add mode
  return schema;
};

export const getEntitySchema = (entityType, params, disabledAccountReference) => {
  if (entityType === ENTITY_TYPES.Clinic) {
    return prepareEntitySchema(ClinicEntitySchema, params || {}, disabledAccountReference);
  }
  if (entityType === ENTITY_TYPES.Laboratory) {
    return prepareEntitySchema(LaboratoryEntitySchema, params || {});
  }
  if (entityType === ENTITY_TYPES.ClinicLaboratory) {
    return prepareEntitySchema(ClinicLaboratoryEntitySchema, params || {}, disabledAccountReference);
  }
};
