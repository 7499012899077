<template>
  <div>
    <modal-confirm-generic
      ref="unSaveChangesModal"
      :description="$t('components/lims/modals/ModalFormCancel.text')"
      @onConfirm="onUnSaveChanges"
    />
    <dataset-form :formMode="formMode"></dataset-form>
  </div>
</template>

<script>
import { UnSaveChangesMixins } from '@/core/mixins';
import { FORM_MODES } from '@/core/constants';

import DatasetForm from '@/pages/Dataset/Forms/DatasetForm.vue';

export default {
  mixins: [UnSaveChangesMixins],
  components: {
    DatasetForm,
  },
  computed: {
    formMode() {
      return FORM_MODES.ADD;
    },
  },
  data: function () {
    return {};
  },
};
</script>

<style lang="scss" scoped></style>
