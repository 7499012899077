/* eslint-disable security/detect-object-injection */

import Vue from 'vue';

// initial state
const state = () => ({
  dataset: null,
});

// getters
const getters = {
  getDatasetByKey: (state) => (key) => {
    const { dataset } = state;
    return dataset ? dataset[key] : [];
  },
  dataset: (state) => {
    return state.dataset;
  },
};

// actions
const actions = {
  updateDataset({ commit }, dataset) {
    commit('updateDataset', dataset);
  },
};

// mutations
const mutations = {
  updateDataset: (state, dataset) => {
    const datasetValues = state.dataset || {};
    Vue.set(state, 'dataset', {
      ...datasetValues,
      ...dataset,
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
