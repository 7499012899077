<template>
  <lims-block :cardText="false" id="anchor-system" class="anchor-system">
    <div class="md-layout anchor-system-inner" slot="blockContent">
      <div
        v-if="headingTitle"
        :class="isUrgent ? 'highlight-top-header anchor-system-heading' : 'anchor-system-heading'"
        v-html="headingTitle"
      ></div>
      <div class="anchor-system-icon">
        <!-- Patient Data -->
        <span v-if="isHasElement('patientDataBlock')" @click="scrollMeTo('patientDataBlock')" class="c-tooltip">
          <md-icon>personal_injury</md-icon>
          <md-tooltip md-direction="top">{{ $t('pages/case/form/patientData/blockTitle') }}</md-tooltip>
        </span>
        <!-- Billing, Clinic, Laboratory -->
        <span
          v-if="isHasElement('billingClinicAndLaboratoryBlock')"
          @click="scrollMeTo('billingClinicAndLaboratoryBlock')"
          class="c-tooltip"
        >
          <img class="img" :src="require(`@/assets/images/clinical_notes.png`)" />
          <md-tooltip md-direction="top">{{ $t('pages/case/form/billingClinicAndLaboratory/blockTitle') }}</md-tooltip>
        </span>

        <!-- Specimen, Pathologist, Document -->
        <!-- For Pathologist -->
        <div style="display: inline-flex">
          <template v-if="isPathView && !(caseData && caseData.status == caseStatus.PATH)">
            <span
              v-if="isHasElement('specimenPathologist')"
              @click="scrollMeTo('specimenPathologist')"
              class="c-tooltip"
            >
              <md-icon>type_specimen</md-icon>
              <md-tooltip md-direction="top">{{
                $t('pages/case/form/specimenPathologistAndDocument/blockTitle')
              }}</md-tooltip>
            </span>
          </template>

          <template v-else>
            <!-- Specimen Details -->
            <span
              v-if="isHasElement('specimenDetailsBlock')"
              @click="scrollMeTo('specimenDetailsBlock')"
              class="c-tooltip"
            >
              <img class="img" :src="require(`@/assets/images/description.png`)" />
              <md-tooltip md-direction="top">{{
                $t('pages/case/CaseManagement/SpecimenDetail/blockTitle')
              }}</md-tooltip>
            </span>

            <!-- Specimen list -->
            <div v-if="specimenNumber" class="dropdown">
              <div class="dropbtn">
                <span class="c-tooltip">
                  <md-icon>type_specimen</md-icon>
                  <md-tooltip md-direction="top">Specimen</md-tooltip>
                </span>
                <i class="fa fa-caret-down"></i>
              </div>
              <div class="dropdown-content">
                <li v-for="item in specimenNumber" :key="item" @click="scrollMeTo(`specimen-${item}`)">
                  <md-icon>type_specimen</md-icon>
                  {{ $generateSpecimenName(item - 1) }}
                </li>
              </div>
            </div>

            <!-- Pathologist -->
            <span v-if="isHasElement('pathologistBlock')" @click="scrollMeTo('pathologistBlock')" class="c-tooltip">
              <img class="img" :src="require(`@/assets/images/person_2.png`)" />
              <md-tooltip md-direction="top">{{ $t('pages/case/CaseManagement/Pathologist/blockTitle') }}</md-tooltip>
            </span>
            <!-- Document -->
            <span v-if="isHasElement('documentBlock')" @click="scrollMeTo('documentBlock')" class="c-tooltip">
              <img class="img" :src="require(`@/assets/images/upload_file.png`)" />
              <md-tooltip md-direction="top">{{
                $t('pages/case/CaseManagement/components/Document/collapseTitle')
              }}</md-tooltip>
            </span>
          </template>
        </div>

        <template v-if="isProvisionallyReportedStatusPathView || isReportedStatusPathView">
          <!-- For: Provisionally Reported/Reported status - Path view -->
          <span v-if="isHasElement('documentBlock')" @click="scrollMeTo('documentBlock')" class="c-tooltip">
            <img class="img" :src="require(`@/assets/images/upload_file.png`)" />
            <md-tooltip md-direction="top">{{
              $t('pages/case/CaseManagement/components/Document/collapseTitle')
            }}</md-tooltip>
          </span>
        </template>

        <!-- Diagnosis-->
        <div class="dropdown" v-if="isHasElement('diagnosisBlock')">
          <div class="dropbtn">
            <span class="c-tooltip" @click="scrollMeTo('diagnosisBlock')">
              <img class="img" :src="require(`@/assets/images/diagnosis.png`)" />
              <md-tooltip md-direction="top">
                <template
                  v-if="
                    caseData &&
                    (caseData.status == caseStatus.PROVISIONALLY_REPORTED || caseData.status == caseStatus.REPORTED)
                  "
                  >Diagnosis - Report
                </template>
                <template v-else>{{ $t('pages/case/form/diagnosis/blockTitle') }}</template>
              </md-tooltip>
            </span>
            <i v-if="isPathView && diagnosisSpecimenNumber > 0" class="fa fa-caret-down"></i>
          </div>
          <div v-if="isPathView && diagnosisSpecimenNumber > 0" class="dropdown-content">
            <li v-for="item in diagnosisSpecimenNumber" :key="item" @click="scrollMeTo(`diagnosis-specimen-${item}`)">
              <img class="img" :src="require(`@/assets/images/diagnosis.png`)" width="24" />
              {{ $generateSpecimenName(item - 1) }}
            </li>
          </div>
        </div>
        <!-- </Diagnosis> -->

        <!-- Double Reporting -->
        <span v-if="isHasElement('doubleReportingBlock')" @click="scrollMeTo('doubleReportingBlock')" class="c-tooltip">
          <md-icon>diversity_2</md-icon>
          <md-tooltip md-direction="top">{{ $t('pages/case/form/doubleReporting/blockTitle') }}</md-tooltip>
        </span>

        <!-- supplementary report -->
        <div class="dropdown" v-if="isShowSupplementaryReport">
          <div class="dropbtn">
            <span class="c-tooltip" @click="scrollMeTo('caseSupplementaryReport-input')">
              <md-icon>note_add</md-icon>
              <md-tooltip md-direction="top">
                {{ $t('pages/case/CaseManagement/components/Report/collapseSupplementaryReportTitle') }}
              </md-tooltip>
            </span>
            <i
              v-if="caseData && caseData.caseSupplementaryReports && caseData.caseSupplementaryReports.length > 0"
              class="fa fa-caret-down"
            >
            </i>
          </div>
          <div class="dropdown-content" v-if="caseData && caseData.caseSupplementaryReports">
            <li
              v-for="item in caseData.caseSupplementaryReports.length"
              :key="item"
              @click="scrollMeTo(`caseSupplementaryReport-${item}`)"
            >
              <md-icon>note_add</md-icon>
              {{ $generateSpecimenName(item - 1) }}
            </li>
          </div>
        </div>

        <!-- ALW -->
        <span v-if="isHasElement('ALWBlock')" @click="scrollMeTo('ALWBlock')" class="c-tooltip">
          <img class="img" :src="require(`@/assets/images/lab_research.png`)" />
          <md-tooltip md-direction="top">{{ $t('pages/case/form/ALW/blockTitle') }}</md-tooltip>
        </span>
        <!-- SO -->
        <span v-if="isHasElement('SOBlock')" @click="scrollMeTo('SOBlock')" class="c-tooltip">
          <md-icon>contacts</md-icon>
          <md-tooltip md-direction="top">{{ $t('pages/case/form/SO/blockTitle') }}</md-tooltip>
        </span>

        <!-- Further Option -->
        <span v-if="isHasElement('furtherOptionBlock')" @click="scrollMeTo('furtherOptionBlock')" class="c-tooltip">
          <md-icon>more</md-icon>
          <md-tooltip md-direction="top">{{ $t('pages/case/form/furtherOptions/blockTitle') }}</md-tooltip>
        </span>
        <!-- Issue Log -->
        <span v-if="isHasElement('issueLogBlock')" @click="scrollMeTo('issueLogBlock')" class="c-tooltip">
          <md-icon>bug_report</md-icon>
          <md-tooltip md-direction="top">{{ $t('pages/case/form/issueLog/blockTitle') }}</md-tooltip>
        </span>
        <!-- Form button -->
        <span @click="scrollMeTo('formButton')" class="c-tooltip">
          <md-icon>unfold_more</md-icon>
          <md-tooltip md-direction="top">Form Button</md-tooltip>
        </span>
      </div>
    </div>
  </lims-block>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import CaseMixins from '@/pages/Case/CaseManagement/Case.mixins';
import CaseBlockMixins from '@/pages/Case/CaseManagement/CaseBlock.mixins';
import { APP_EVENTS, CASE_STATUS, newAppEvent } from '@/core/constants';
import CaseSpecimenMixins from '@/pages/Case/CaseManagement/CaseSpecimen.mixins';

export default {
  mixins: [CaseMixins, CaseBlockMixins, CaseSpecimenMixins],
  props: {
    isPathStatusPathView: {
      default: false,
    },
    isProvisionallyReportedStatusPathView: {
      default: false,
    },
    isReportedStatusPathView: {
      default: false,
    },
    hasSplitContent: {
      default: false,
    },
  },
  created() {
    this.userType = this.$store.getters['auth/userType'];
    if (this.isPathStatusPathView) {
      setTimeout(() => {
        this.scrollMeTo('diagnosisBlock');
      }, 1000);
    }
  },
  data: function () {
    return {
      userType: '',
      specimenNumber: 0,
      diagnosisSpecimenNumber: 0,
    };
  },
  computed: {
    ...mapGetters('app', ['headingTitle']),
    ...mapGetters('caseData', ['caseData']),
    ...mapGetters('app/event', [
      APP_EVENTS.EVT_ON_CASE_FORM_NO_OF_SPECIMENCE_CHANGED,
      APP_EVENTS.EVT_ON_CASE_FORM_NO_OF_DIAGNOSIS_SPECIMENCE_CHANGED,
      APP_EVENTS.EVT_ON_SCROLL_TO_BLOCK,
    ]),
    caseStatus: function () {
      return CASE_STATUS;
    },
    isAdminView() {
      return this.userType === this.USER_TYPES().Administrator;
    },
    isPathView() {
      return this.userType === this.USER_TYPES().Pathologist;
    },
    isHasElement() {
      return (idEl) => !!document.getElementById(idEl);
    },
    isShowSupplementaryReport() {
      return (
        this.isProvisionallyReportedStatusPathView ||
        (this.caseData && this.caseData?.caseSupplementaryReports?.length > 0)
      );
    },
    isUrgent() {
      return this.caseData?.urgent;
    },
  },
  watch: {
    [APP_EVENTS.EVT_ON_CASE_FORM_NO_OF_SPECIMENCE_CHANGED]: {
      deep: true,
      handler: async function (val) {
        if (val) {
          this.specimenNumber = val.noOfSpecimen;
        }
      },
    },
    [APP_EVENTS.EVT_ON_CASE_FORM_NO_OF_DIAGNOSIS_SPECIMENCE_CHANGED]: {
      deep: true,
      handler: async function (val) {
        if (val) {
          this.diagnosisSpecimenNumber = val.noOfSpecimen;
        }
      },
    },
    [APP_EVENTS.EVT_ON_SCROLL_TO_BLOCK]: {
      deep: true,
      handler: async function (val) {
        if (val && val.blockId) {
          const blockId = val.blockId;
          let enabledAutoScroll = false;
          enabledAutoScroll |= this.isProvisionallyReportedStatusPathView && blockId == 'CaseSupplementaryReportInput';
          enabledAutoScroll |= this.isReportedStatusPathView && blockId == 'CaseReportedBlock';

          // scroll
          enabledAutoScroll ? this.scrollMeTo(val.blockId) : '';

          // remove event
          this.removeEvent(newAppEvent(APP_EVENTS.EVT_ON_SCROLL_TO_BLOCK));
        }
      },
    },
  },
  methods: {
    ...mapActions('caseForm', ['setExpandCollapseBlockName']),
    ...mapActions('app/event', ['removeEvent']),
    scrollMeTo(id) {
      // open parent collaps and croll to item
      if (id.includes('caseSupplementaryReport-') || id.includes('diagnosis-specimen-')) {
        setTimeout(() => {
          this.setExpandCollapseBlockName('diagnosisBlock');
        }, 100);
      }
      if (id.includes('CaseReportedBlock') || id.includes('CaseReportedBlock')) {
        setTimeout(() => {
          this.setExpandCollapseBlockName('CaseReportedBlock');
        }, 100);
      }

      if (id.includes('diagnosisViewBlock')) {
        setTimeout(() => {
          this.setExpandCollapseBlockName('diagnosisViewBlock');
        }, 100);
      }

      if (
        id.includes('specimenDetailsBlock') ||
        id.includes('specimen-') ||
        id.includes('documentBlock') ||
        id.includes('pathologistBlock')
      ) {
        setTimeout(() => {
          this.setExpandCollapseBlockName('specimenPathologist');
        }, 100);

        if (id.includes('specimen-')) {
          this.setExpandCollapseBlockName('specimenDetailsItemBlock');
          setTimeout(() => {
            this.setExpandCollapseBlockName('specimenDetailsBlock');
          }, 100);
        }
        if (id.includes('specimenDetailsBlock')) {
          setTimeout(() => {
            this.setExpandCollapseBlockName('specimenDetailsItemBlock');
          }, 100);
        }
      }

      setTimeout(() => {
        this.setExpandCollapseBlockName(id);
      }, 100);

      // apply for review case/ bulk diagnosis
      const element = document.getElementById(id);
      const splitElement = document.getElementById('slotTwo');
      let elementScroll = splitElement ? splitElement : window;

      if (splitElement) {
        const otherPosition = document.getElementById('split-wrapper').getBoundingClientRect().top + window.scrollY;
        const headerHeight = document.getElementById('header').offsetHeight;

        if (!this.hasSplitContent) {
          if (id.includes('patientDataBlock') || id.includes('formButton')) {
            elementScroll = window;
          } else {
            window.scrollTo({ top: otherPosition - headerHeight, behavior: 'smooth' });
          }
        } else {
          window.scrollTo({ top: otherPosition - headerHeight, behavior: 'smooth' });
        }
      }

      setTimeout(() => {
        let distance; //distance from element to top of page after scroll
        if (splitElement) {
          distance = document.getElementById('split-wrapper').getBoundingClientRect().top;
        } else {
          distance = document.getElementById('patientDataBlock').getBoundingClientRect().top;
        }

        let position = 0;
        if (element) {
          position =
            element.getBoundingClientRect().top +
            (elementScroll.scrollY !== undefined ? elementScroll.scrollY : elementScroll.scrollTop - distance);
        }

        if (id == 'patientDataBlock') {
          elementScroll.scrollTo({ top: position - 160, behavior: 'smooth' });
        } else {
          elementScroll.scrollTo({ top: position - 80, behavior: 'smooth' });
        }
      }, 500);
    },
  },
};
</script>

<style lang="scss">
.anchor-system {
  margin-bottom: 20px;
  max-width: calc(100% - 100px) !important;
  min-width: calc(100% - 100px) !important;
  position: sticky;
  z-index: 1;
  top: 0;
  @media only screen and (max-width: 990px) {
    max-width: calc(100% - 40px) !important;
    min-width: calc(100% - 40px) !important;
  }
  &.isSticky {
    z-index: 9;
  }
  .md-card.md-theme-default {
    margin: 0;
  }
  .c-tooltip {
    img {
      width: auto;
      height: 24px;
    }
  }
  .md-card-content {
    padding: 5px 5px 5px 13px;
    min-height: 61px;
    display: flex;
    align-items: center;
  }
  .anchor-system-inner {
    display: flex;
    justify-content: space-between;
    flex-wrap: unset;
  }
  .anchor-system-heading {
    width: 60%;
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    padding-top: 3px;
  }
  .anchor-system-icon {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .dropdown {
    .dropbtn {
      display: flex;
      align-items: center;
    }
    .dropdown-content {
      display: none;
      position: absolute;
      background-color: #f9f9f9;
      min-width: 160px;
      box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
      z-index: 1;
      height: auto;
      max-height: 200px;
      overflow-y: auto;
      li {
        float: none;
        padding: 5px 10px;
        display: block;
        text-align: left;
        cursor: pointer;
        &:hover {
          background-color: #ddd;
        }
        img {
          height: 24px;
          width: auto;
        }
      }
    }
  }
  .dropdown:hover .dropdown-content {
    display: block;
  }
  .highlight-top-header {
    color: red;
  }
}
</style>
