<template>
  <lims-block>
    <h4 class="title" slot="blockTitle">
      {{ $t('pages/account/user/forms/pathologist/doubleReporting/doubleReportingBlock') }}
    </h4>
    <modal-reject-confirm
      slot="blockTitle"
      ref="modalRejectConfirm"
      :title="$t('pages/account/user/forms/pathologist/doubleReporting/modalRejectConfirm/title')"
      @onReject="onReject"
    ></modal-reject-confirm>
    <div slot="blockContent">
      <div class="md-layout lims-form-row">
        <md-checkbox
          v-model="formData.isDoubleReport"
          :disabled="formMode === VIEW_MODE"
          class="lims-checkbox"
          tabenable="yes"
        >
          {{ $t('pages/account/user/forms/pathologist/doubleReporting/isDoubleReport') }}
        </md-checkbox>
      </div>

      <div v-show="formData.isDoubleReport">
        <div class="md-layout lims-form-row">
          <div class="block01-title">
            {{ $t('pages/account/user/forms/pathologist/doubleReporting/tblRequestedHeading') }}
          </div>
          <md-table class="custom-tbl" md-card>
            <md-table-row>
              <md-table-head class="col-25">
                {{ $t('pages/account/user/forms/pathologist/doubleReporting/table.specialty') }}
              </md-table-head>
              <md-table-head class="col-25">
                {{ $t('pages/account/user/forms/pathologist/doubleReporting/table.partnerPathologist') }}
              </md-table-head>
              <md-table-head class="col-25">
                {{ $t('pages/account/user/forms/pathologist/doubleReporting/table.status') }}
              </md-table-head>
              <md-table-head class="col-25">
                {{ $t('pages/account/user/forms/pathologist/doubleReporting/table.date') }}
              </md-table-head>
            </md-table-row>
            <md-table-row v-for="(item, index) in formData.requestedPartners" :key="`requestedPartners-${index}`">
              <md-table-cell class="col-25">{{ item.specialtyName }}</md-table-cell>
              <md-table-cell class="col-25">
                <v-select
                  v-if="item.statusName != 'Rejected' && item.specialtyId"
                  :options="
                    partnerPathologistList.filter((c) => c.id == item.specialtyId.toString())[0] != undefined
                      ? partnerPathologistList.filter((c) => c.id == item.specialtyId.toString())[0].nodes
                      : []
                  "
                  :reduce="(option) => option.value"
                  label="label"
                  :appendToBody="true"
                  v-model="item.partnerId"
                  :disabled="item.statusName == 'Accepted' || formMode === VIEW_MODE"
                  tabenable="yes"
                  :tabindex="1"
                >
                  <template #option="{ label }">{{ label }}</template>
                  <template #selected-option="{ label }">{{ label }}</template>
                </v-select>
                <span v-else>{{ item.pathologistName }}</span>
              </md-table-cell>
              <md-table-cell class="col-25">{{ item.statusName }}</md-table-cell>
              <md-table-cell class="col-25">{{ item.date ? formatDateWithoutTime(item.date) : '' }}</md-table-cell>
            </md-table-row>
          </md-table>
        </div>
        <div class="md-layout lims-form-row">
          <div class="block01-title">
            {{ $t('pages/account/user/forms/pathologist/doubleReporting/tblAcceptedHeading') }}
          </div>
          <md-table class="custom-tbl" md-card>
            <md-table-row>
              <md-table-head class="col-25">
                {{ $t('pages/account/user/forms/pathologist/doubleReporting/table.specialty') }}
              </md-table-head>
              <md-table-head class="col-25">
                {{ $t('pages/account/user/forms/pathologist/doubleReporting/table.pathologist') }}
              </md-table-head>
              <md-table-head class="col-25">
                {{ $t('pages/account/user/forms/pathologist/doubleReporting/table.acceptedDate') }}
              </md-table-head>
              <md-table-head class="col-25">
                {{ $t('global/pages/list.actions') }}
              </md-table-head>
            </md-table-row>
            <md-table-row v-for="(item, index) in formData.acceptedPartners" :key="`acceptedPartners-${index}`">
              <md-table-cell class="col-25">{{ item.specialtyName }}</md-table-cell>
              <md-table-cell class="col-25">{{ item.pathologistName }}</md-table-cell>
              <md-table-cell class="col-25">{{
                item.date && item.statusName !== 'Pending' ? formatDateWithoutTime(item.date) : ''
              }}</md-table-cell>
              <md-table-cell class="col-25">
                <div>
                  <md-button
                    class="md-just-icon md-just-icon-danger md-default md-simple"
                    @click.stop="formMode === VIEW_MODE ? '' : rejectRequest(item)"
                  >
                    <md-icon>close</md-icon>
                  </md-button>
                  <md-button
                    v-if="item.statusName === 'Pending'"
                    class="md-just-icon md-just-icon-success md-default md-simple"
                    @click.stop="formMode === VIEW_MODE ? '' : acceptRequest(item)"
                  >
                    <md-icon>check</md-icon>
                  </md-button>
                </div>
              </md-table-cell>
            </md-table-row>
          </md-table>
        </div>
      </div>
    </div>
  </lims-block>
</template>

<script>
import { FormMixins } from '@/core/mixins';
import { caseDoubleReportService } from '@/services';
import { mapActions } from 'vuex';
import { APP_EVENTS, newAppEvent } from '@/core/constants';
import { orderBy } from 'lodash';
import { ModalRejectConfirm } from '@/components/Lims/modals';

export default {
  components: { ModalRejectConfirm },
  mixins: [FormMixins],
  props: {
    data: {
      type: Object,
      require: false,
    },
    listSpecialty: {
      type: Array,
      require: false,
    },
    formMode: {
      type: Number,
      require: true,
    },
  },
  created: function () {
    if (this.data) {
      this.loadData();
    } else {
      this.formData = {
        isDoubleReport: false,
        requestedPartners: [],
        acceptedPartners: [],
      };
      this.dataModel = this.data;
    }
  },
  watch: {
    listSpecialty: {
      deep: true,
      handler: async function (value) {
        const listData = value.filter((e) => e.specialty !== null);
        this.formData.requestedPartners = this.mergeTwoArrays(this.formData.requestedPartners, listData);
        const specialtyIds = listData.map((item) => item.specialtyId);
        const { data, error } = await caseDoubleReportService.getListPartnerPathologist(
          this.data != undefined ? this.data.userId : null,
          specialtyIds,
        );
        if (error) {
          this.$alertErrorServerSide(error);
        } else {
          const options = [];
          const groups = data.map((g) => {
            const { id, text, nodes } = g;
            return {
              id,
              text,
              nodes: nodes.map((n) => {
                const item = {
                  label: n.text,
                  value: n.id,
                };
                options.push(item);
                return item;
              }),
            };
          });
          this.partnerPathologistList = groups;
        }
      },
    },
    formData: {
      deep: true,
      handler: function (val) {
        this.$emit('input', val);
      },
    },
  },
  computed: {
    requestedPartners() {
      const items = this.formData.requestedPartners.map((v) => {
        const statusSorts = ['Pending', 'Accepted', 'Rejected'];
        const statusSortId = statusSorts.indexOf(v.statusName);
        return {
          statusSortId,
          ...v,
        };
      });
      return orderBy(items, ['statusSortId', 'date'], ['asc', 'desc']);
    },
  },
  data() {
    return {
      dataModel: null,
      partnerPathologistList: [
        {
          id: null,
          text: '',
          nodes: [],
        },
      ],
      formData: {
        isDoubleReport: true,
        requestedPartners: [
          {
            specialtyId: null,
            specialtyName: '',
            partnerId: null,
            status: '',
            requestedDate: '',
          },
        ],
        acceptedPartners: [],
      },
    };
  },
  methods: {
    ...mapActions('app/event', ['removeEvent', 'addEvent']),
    loadData() {
      this.formData = {
        isDoubleReport: this.data.isDoubleReport,
        requestedPartners: this.data.caseDoubleReports,
        acceptedPartners: this.data.caseDoubleReportBys.filter(
          (item) => item.statusName === 'Pending' || item.statusName === 'Accepted',
        ),
      };
      this.dataModel = this.data;
    },
    formatDateWithoutTime(date) {
      return this.formatDateTimeGlobalNotIncludeTime(date);
    },
    mergeTwoArrays(currentRequest, specialtyList) {
      //list rejected
      const listRejectRequest = currentRequest.filter((item) => item.statusName == 'Rejected');

      // list accept vs pending
      const listAcceptedAndPending = currentRequest.filter((item) => item.statusName != 'Rejected');
      //list specialty
      const specialtyIdsAcceptedAndPending = listAcceptedAndPending.map((item) => item.specialtyId);
      const specialtyListUnique = specialtyList.filter((x) => !specialtyIdsAcceptedAndPending.includes(x.specialtyId));

      const mergedArray = [...specialtyListUnique, ...listAcceptedAndPending, ...listRejectRequest];
      const data = mergedArray.map((item) => {
        return {
          specialtyId: item.specialtyId,
          specialtyName: item.specialtyName,
          partnerId: item.partnerId,
          statusName: item.statusName,
          date: item.date,
          pathologistName: item.pathologistName,
        };
      });
      return data;
    },
    async onReject({ item }) {
      const res = await caseDoubleReportService.rejectRequestDoubleReport(item.caseDoubleReportRequestPartnerId);
      if (res.error) {
        this.$alertErrorServerSide(res.error);
      }
      this.addEvent(
        newAppEvent(APP_EVENTS.EVT_ON_USER_PATHOLOGIST_RELOAD, {
          reloadPathologist: true,
        }),
      );
      this.$alertSuccess(this.$t(`pages/account/user/forms/pathologist/doubleReporting/rejectRequest.success`));
      setTimeout(() => {
        this.loadData();
      }, 500);
    },
    async rejectRequest(item) {
      const description = this.$t(
        'pages/account/user/forms/pathologist/doubleReporting/modalRejectConfirm/description',
        {
          pathologistName: item.pathologistName,
        },
      );
      this.$refs.modalRejectConfirm.setData({ item, description });
      this.$refs.modalRejectConfirm.open();
    },
    async acceptRequest(item) {
      const res = await caseDoubleReportService.acceptRequestDoubleReport(item.caseDoubleReportRequestPartnerId);
      if (res.error) {
        this.$alertErrorServerSide(res.error);
      }
      this.addEvent(
        newAppEvent(APP_EVENTS.EVT_ON_USER_PATHOLOGIST_RELOAD, {
          reloadPathologist: true,
        }),
      );
      this.$alertSuccess(this.$t(`pages/account/user/forms/pathologist/doubleReporting/acceptRequest.success`));
      setTimeout(() => {
        this.loadData();
      }, 500);
    },
  },
};
</script>

<style lang="scss" scoped></style>
