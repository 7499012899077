<template>
  <div v-if="hasProblems">
    <p class="text-center" v-html="problemHeading"></p>
    <ul class="list-cases">
      <li
        v-for="(item, key) in casesFailure[problemKey].listCasesFailure"
        :key="`${problemKey}-${key}-${item.slide.fieldItemId}`"
      >
        {{ item.caseReference }}, {{ item.block.text }}, {{ item.slide.fieldItemName }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    bulkAction: {
      type: Object,
      require: true,
    },
    problemKey: {
      type: String,
      required: true,
    },
    casesFailure: {
      type: Object,
      required: true,
    },
  },
  computed: {
    hasProblems() {
      const { casesFailure, problemKey } = this;
      const hasProblem =
        Reflect.has(casesFailure, problemKey) && Reflect.get(casesFailure, problemKey).listCasesFailure.length > 0;
      return hasProblem;
    },

    problemHeading() {
      const { casesFailure, problemKey, bulkAction } = this;

      // components/ModalBulkActionProblemSlide.conditionProblem
      return this.$translate(`components/ModalBulkActionProblemSlide.${problemKey}`, {
        action: this.$translate(`pages/case/slideManagement/list/bulkActions/${bulkAction.label}`),
        // eslint-disable-next-line security/detect-object-injection
        value: casesFailure[problemKey].allowValue,
      });
    },
  },
  data: function () {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.list-cases {
  display: table;
  margin: 10px auto;
}
</style>
