<template>
  <lims-block class="c-block01 specimen-reference-block">
    <h4 class="title" slot="blockTitle">
      {{ $t('pages/account/user/forms/pathologist/specimenPreference/specimenBlock') }}
    </h4>
    <lims-tooltip
      slot="blockTitle-info"
      :content="$t('pages/account/user/forms/pathologist/specimenPreference/blockTooltip')"
    ></lims-tooltip>
    <div slot="blockContent">
      <div class="md-layout">
        <div class="md-layout-item md-size-50 md-small-size-100">
          <label>{{ $t('pages/account/user/forms/pathologist/specimenPreference/availableSpecimensLabel') }}</label>
          <v-select
            slot="field-outside"
            v-model="formData.specimen"
            :options="selectOptions"
            :placeholder="
              $t('pages/account/user/forms/pathologist/specimenPreference/availableSpecimensLabelPlaceholder')
            "
            :disabled="viewMode"
            @input="valSpecimen"
            tabenable="yes"
            :tabindex="31"
          >
            <template #option="{ label }">{{ label }}</template>
            <template #selected-option="{ label }">{{ label }}</template>
          </v-select>
        </div>
        <div class="md-layout-item md-size-50 md-small-size-100">
          <md-checkbox
            v-model="formData.isPopUp"
            class="lims-checkbox checkbox-popup"
            :disabled="viewMode"
            tabenable="yes"
            >{{ $t('pages/account/user/forms/pathologist/specimenPreference/popupLabel') }}</md-checkbox
          >
          <md-button
            type="button"
            @click="add()"
            class="lims-form-button block01-btn md-primary"
            :disabled="viewMode || !isValidSpecimenPreference"
          >
            {{ $t('pages/account/user/forms/pathologist/specimenPreference/addSpecimenPreference') }}
          </md-button>
        </div>
      </div>
      <div class="md-layout">
        <div class="md-layout-item md-size-100 md-small-size-100 textarea-field">
          <label>{{ $t('pages/account/user/forms/pathologist/specimenPreference/commentLable') }}</label>
          <md-field>
            <md-textarea v-model="formData.comment" :disabled="viewMode" tabenable="yes"></md-textarea>
          </md-field>
        </div>
      </div>
      <md-table class="custom-tbl" md-card>
        <md-table-row>
          <md-table-head width="25%">
            {{ $t('pages/account/user/forms/pathologist/specimenPreference/table.specimentLable') }}
          </md-table-head>
          <md-table-head width="15%">
            {{ $t('pages/account/user/forms/pathologist/specimenPreference/table.popupLable') }}
          </md-table-head>
          <md-table-head>
            {{ $t('pages/account/user/forms/pathologist/specimenPreference/table.commentLable') }}
          </md-table-head>
          <md-table-head class="th-center cell-action">
            {{ $t('global/pages/list.actions') }}
          </md-table-head>
        </md-table-row>
        <md-table-row v-for="item in selectedOptions" :key="`specimenList${item.specimenId}`">
          <md-table-cell>{{ item.specimenName }}</md-table-cell>
          <md-table-cell>
            <md-checkbox :disabled="viewMode" v-model="item.isPopUp" class="lims-checkbox"></md-checkbox>
          </md-table-cell>
          <md-table-cell>{{ item.comment }}</md-table-cell>
          <md-table-cell class="cell-action">
            <div @click="viewMode ? '' : del(item)"><md-icon>close</md-icon></div>
          </md-table-cell>
        </md-table-row>
      </md-table>
    </div>
  </lims-block>
</template>

<script>
import { UserService } from '@/services';
import { MyAccountService } from '@/services';
import UserMixins from '@/pages/Account/User/user.mixins';

export default {
  name: 'UserSpecimenPreferenceBlock',
  mixins: [UserMixins],
  props: {
    viewMode: {
      type: Boolean,
      require: true,
    },
    value: {
      type: Array,
      require: true,
    },
  },
  data() {
    return {
      availableOptions: [],
      selectedOptions: [],
      formData: {
        specimen: '',
        isPopUp: false,
        comment: '',
      },
      isValidSpecimenPreference: false,
    };
  },
  watch: {
    selectedOptions: {
      deep: true,
      handler: function (value) {
        this.$emit('input', value);
      },
    },
    value: {
      deep: true,
      handler: function () {
        this.selectedOptions = this.value;
      },
    },
  },
  async mounted() {
    this.availableOptions = await this.loadAvailableSpecimens();
  },
  computed: {
    selectOptions() {
      return this.availableOptions.filter(
        (item) => !this.selectedOptions.map((item) => item.specimenId).includes(item.value),
      );
    },
  },
  methods: {
    async loadAvailableSpecimens() {
      let responseData;
      if (this.isDefaultView) {
        responseData = await UserService.findAvailableSpecimens();
      } else {
        if (this.isMyProfileView) {
          responseData = await MyAccountService.findAvailableSpecimens();
        }
      }
      return responseData.data.map((item) => {
        return {
          value: item.id,
          label: item.text,
        };
      });
    },
    valSpecimen() {
      if (this.formData.specimen == null) {
        this.isValidSpecimenPreference = false;
      } else {
        this.isValidSpecimenPreference = true;
      }
    },
    add() {
      if (this.isValidSpecimenPreference) {
        this.selectedOptions.push({
          specimenId: this.formData.specimen.value,
          specimenName: this.formData.specimen.label,
          isPopUp: this.formData.isPopUp,
          comment: this.formData.comment,
        });
        // reset
        this.isValidSpecimenPreference = false;
        this.formData = {
          specimen: '',
          isPopUp: false,
          comment: '',
        };
      }
    },
    del(delItem) {
      this.selectedOptions = this.selectedOptions.filter((item) => item.specimenId !== delItem.specimenId);
    },
  },
};
</script>

<style lang="scss" scoped></style>
