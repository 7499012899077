import { mapActions } from 'vuex';

import '@/schemas/vee-validate';
import { isRestrictedArea } from '@/core/helpers';
import configService from '@/services/config.service';
const AppBase = {};

// eslint-disable-next-line no-unused-vars
AppBase.install = function (Vue, options = {}) {
  // 0. use external plugin
  // 1. add global method or property
  Vue.myGlobalMethod = function () {
    // some logic ...
  };

  // 2. add a global asset
  Vue.directive('my-directive', {
    // When the bound element is inserted into the DOM...
    inserted: function (el) {
      // Focus the element
      el.focus();
    },
  });

  // 3. inject some component options
  Vue.mixin({
    data() {
      return {
        DOMAIN_CONTEXT: 'global',
      };
    },
    computed: {
      $eagerMode: function () {
        return this.eagerMode;
      },
    },
    // created: function () {
    // },
    // kind of global mixin
    methods: {
      $myPdfViewer(documentFile) {
        if (documentFile) {
          if (documentFile.format === 'pdf') {
            let url = `//${window.location.hostname}`;
            if (window.location.port) {
              url = `${url}:${window.location.port}`;
            }
            return `${url}/viewer.html?file=${documentFile.link}`;
          }
          return documentFile.fileUrl;
        }
        return '';
      },
      USER_TYPES() {
        return configService.getConfig().userTypes;
      },
      USERTYPES_ENUM() {
        return configService.getConfig().userTypeOptions;
      },
      USER_TITLES() {
        return configService.getConfig().userTitles;
      },
      ...mapActions('app', ['$showLoading', '$hideLoading', '$setHeadingTitle']),
      $isAuthorized(permissions) {
        return isRestrictedArea({ permissions, userPermissions: this.$store.getters['auth/permissions'] });
      },
      $helpText(helpText) {
        alert(`[HELP]: ${helpText}`);
      },
      $translate(k, params = null) {
        if (params) {
          return this.$t(k, params);
        }
        return this.$t(k);
      },
      $transformErrors(errors) {
        // [{field:'fieldName', code:'errorCode'}]
        const errorObjects = {};
        errors.map((e) => (errorObjects[e.field] = e.code));
        return errorObjects;
      },
      $translateSelectOptions(options) {
        if (options.length == 0) return [];
        return options.map((item) => {
          return {
            value: item.value,
            label: this.$translate(item.label),
          };
        });
      },
      // form validation mode for list of data
      eagerMode(context) {
        const { value, rules } = context;

        // change if need http request to check
        if (value && value.length > 0 && rules.includes('unique')) {
          return { on: ['change'] };
        }
        // blur and input if not need http request check
        return { on: ['blur', 'input'] };
      },
    },
    beforeRouteLeave(to, from, next) {
      if (this.$notifications) {
        this.$notifications.clear();
      }
      next();
    },
  });
  // const alertTypes = ['', 'info', 'success', 'warning', 'danger'];
  // 4. add an instance method
  Vue.prototype.$alertErrorServerSide = function (errorCode) {
    const errorMessage = this.$t('global/errorServerSide/' + errorCode);
    if (errorMessage.indexOf('MISSING') < 0) {
      this.$alertError(errorMessage);
    } else {
      this.$alertError(this.$t('global/errorServerSide/UnknowError'));
    }
  };
  Vue.prototype.$alertNotification = function (message, type = 'info', onClick = null) {
    this.$notify({
      timeout: 1000 * 10, // 10 seconds
      message,
      icon: 'add_alert',
      verticalAlign: 'bottom',
      horizontalAlign: 'right',
      type,
      onClick,
    });
  };
  Vue.prototype.$alertError = function (message, timeout = 30000) {
    this.$notify({
      timeout,
      message,
      icon: 'add_alert',
      verticalAlign: 'top',
      horizontalAlign: 'right',
      type: 'danger',
    });
  };
  Vue.prototype.$alertSuccess = function (message, timeout = 3000) {
    this.$notify({
      timeout,
      message,
      icon: 'add_alert',
      verticalAlign: 'top',
      horizontalAlign: 'right',
      type: 'success',
    });
  };

  Vue.prototype.$alertWarning = function (message, timeout = 30000) {
    this.$notify({
      timeout,
      message,
      icon: 'add_alert',
      verticalAlign: 'top',
      horizontalAlign: 'right',
      type: 'warning',
    });
  };

  Vue.prototype.$alertWarningWithNoTimeOut = function (message) {
    this.$notify({
      timeout: 1000000,
      message,
      icon: 'add_alert',
      verticalAlign: 'top',
      horizontalAlign: 'right',
      type: 'warning',
    });
  };

  // 5. add filter
  Vue.filter('capitalize', function (value) {
    if (!value) return '';
    value = value.toString();
    return value.charAt(0).toUpperCase() + value.slice(1);
  });
};

export default AppBase;
