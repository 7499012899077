<template>
  <form ref="form" v-if="filters && dataSource">
    <div class="list-filter normal-list-filter">
      <div class="normal-list-filter-wrapper">
        <slot name="rowsPerPage"></slot>
        <div class="filter-input">
          <div class="custom-select">
            <div class="custom-select-input" @click="toggleSelectList()">
              <md-field>
                <md-input
                  v-model="valueFilter"
                  autocomplete="off"
                  :placeholder="$t('component/lims/normalList/placeholder/SelectFilter')"
                  @input="searchOnFilter"
                ></md-input>
              </md-field>
              <span :class="!isOpen ? 'is-hide icon' : 'icon'"></span>
            </div>
            <div v-if="isOpen" class="custom-select-content">
              <md-list :md-expand-single="false">
                <div v-if="valueFilter && valueFilter.length > 0">
                  <template v-if="searchFilter.length > 0">
                    <div v-for="(i, index) in searchFilter" :key="`item-${index}`" @click="isOpen = !isOpen">
                      <md-checkbox v-model="selectFilter" :value="i">{{ i.text }}</md-checkbox>
                    </div>
                  </template>
                  <div v-else class="no-result">Sorry, no matching options.</div>
                </div>
                <md-list-item
                  v-else
                  v-for="(item, index) in filterList"
                  :key="`select-${index}`"
                  md-expand
                  :md-expanded.sync="expandItem[item.key]"
                >
                  <span class="md-list-item-text">{{ item.groupName }}</span>
                  <md-list slot="md-expand">
                    <div v-for="(i, index) in item.groupValues" :key="`item-${index}`" @click="isOpen = !isOpen">
                      <md-checkbox v-model="selectFilter" :value="i">{{ i.text }}</md-checkbox>
                    </div>
                  </md-list>
                </md-list-item>
              </md-list>
            </div>
          </div>

          <md-field class="input-search">
            <md-icon>search</md-icon>
            <md-input
              v-model="filters.search"
              :placeholder="$t('component/lims/normalList/placeholder/search')"
              maxlength="250"
              @keyup.enter="onSearch"
            ></md-input>
          </md-field>
        </div>
      </div>

      <div v-if="selectFilter && selectFilter.length > 0" class="lims-form advance-search-wrapper">
        <div class="md-layout lims-form-row">
          <div v-for="item in selectFilter" :key="item.key" class="md-layout-item md-size-50 md-small-size-100">
            <div class="advance-search-inner">
              <md-checkbox v-model="checkboxValue[item.key]"></md-checkbox>
              <template v-if="isTextInput(item)">
                <span class="label-item">{{ item.text }}</span>
                <md-field>
                  <md-input v-model="filterValues[item.key]"></md-input>
                </md-field>
              </template>

              <template v-if="isDropdownMenuMulti(item)">
                <span class="label-item">{{ item.text }}</span>
                <lims-select-with-filter
                  v-model="filterValues[item.key]"
                  label="text"
                  :options="dataSource[item.key]"
                  :translated="true"
                  :placeholder="''"
                ></lims-select-with-filter>
              </template>
              <template v-if="isFilterBlock(item)">
                <span class="label-item">{{ item.text }}</span>
                <filter-block v-model="filterValues[item.key]"></filter-block>
              </template>

              <template v-if="IsDateRangePicker(item)">
                <span class="label-item">{{ item.text }}</span>
                <date-picker range v-model="filterValues[item.key]" format="DD/MM/YYYY"></date-picker>
              </template>

              <template v-if="IsDatePicker(item)">
                <span class="label-item">{{ item.text }}</span>
                <date-picker v-model="filterValues[item.key]" format="DD/MM/YYYY"></date-picker>
              </template>

              <template v-if="isDropdownMenuMultiGroup(item)">
                <span class="label-item">{{ item.text }}</span>
                <div style="width: 100%">
                  <lims-multi-select-with-filter
                    :options="dataSource[item.key]"
                    :groupOptions="dataSource[`group${item.key}`]"
                    v-model="filterValues[item.key]"
                    itemName="groupItems"
                    groupName="itemName"
                    :placeholder="''"
                  ></lims-multi-select-with-filter>
                </div>
              </template>

              <template v-if="isToggle(item)">
                <span class="label-item">{{ item.text }}</span>

                <md-switch v-model="filterValues[item.key]" class="md-primary"></md-switch>
              </template>
            </div>
          </div>
        </div>

        <lims-chip-area :options="chipOptions" @onRemoveChip="$onRemoveChip"></lims-chip-area>

        <div class="filter-action">
          <md-button @click="onSearch" class="md-button md-primary lims-form-button md-theme-default">
            {{ $t('global/button/button.search') }}
          </md-button>
        </div>
      </div>

      <div class="filter-action">
        <md-button @click="onClear">
          {{ $t('global/button/button.clear') }}
        </md-button>
      </div>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex';
import { cloneDeep } from 'lodash';
import { ConfigMixins, ListFilterMixins } from '@/core/mixins';
import LimsSelectWithFilter from '@/components/Lims/LimsSelectWithFilter.vue';
import LimsChipArea from '@/components/Lims/LimsChipArea.vue';

import { isPerfectEmpty } from '@/core/helpers';
import { searchOnFilterList, searchOnFilterListByKey } from '@/query/utils';
import {
  FF_AlwRequestDate,
  FF_AlwRespondDate,
  FF_CaseReference,
  FF_CaseStatusIds,
  FF_ClinicianIds,
  FF_ClinicIds,
  FF_ClinicTypeIds,
  FF_HospitalReference,
  FF_LabEntryDate,
  FF_LaboratoryIds,
  FF_LabReference,
  FF_LocationExternal,
  FF_LocationLab,
  FF_LocationReturnedToClinic,
  FF_LocationReturnedToLab,
  FF_LocationSentToPath,
  FF_PathologistIds,
  FF_PatientFirstName,
  FF_PatientLastName,
  FF_ReportedDate,
  FF_SlideBlocks,
  FF_SlideIds,
  FF_SlideLastUpdated,
  FF_SlideReturnedToClinicDate,
  FF_SlideReturnedToLabDate,
  FF_SlideSentToExternalDate,
  FF_SlideSentToLabDate,
  FF_SlideSentToPathDate,
  FF_SlideTypes,
  FF_SpecimenTypeIds,
  FF_TrackingNo,
  FILTER_SLIDE_LIST_ADMIN,
  FILTER_SLIDE_LIST_LABTECHNICIAN,
} from '@/query/filter-fields';
import FilterBlock from '@/pages/Case/SlideManagement/List/FilterBlock';
import LimsMultiSelectWithFilter from '@/components/Lims/modals/LimsMultiSelectWithFilter';

export default {
  mixins: [ConfigMixins, ListFilterMixins],
  components: {
    LimsMultiSelectWithFilter,
    FilterBlock,
    LimsSelectWithFilter,
    LimsChipArea,
  },
  props: {
    fields: {
      type: Array,
      require: true,
    },
    defaultValues: {
      type: Object,
      require: true,
    },
    value: {
      type: Object,
      require: true,
    },
    dataSource: {
      type: Object,
      require: true,
    },
  },
  async created() {
    this.userType = this.$store.getters['auth/userType'];
    this.defaultValuesFilter = cloneDeep(this.defaultValues);
    this.filterList = this.getFilterList();
    this.searchFilter = this.filterList;
    this.initialState();
  },
  data() {
    return {
      valueFilter: null,
      searchFilter: [],
      filters: {},
      filterValues: {},
      filterList: [],
      chipOptions: [],
      selectFilter: [],
      isOpen: false,
      checkboxValue: {},
      expandItem: {},
      listSlideGroup: [],
      defaultValuesFilter: null,
    };
  },

  computed: {
    ...mapGetters('app/data', ['getDatasetByKey']),
  },

  watch: {
    filterValues: {
      handler(filterValues) {
        this.$material.locale.dateFormat = 'dd/MM/yyyy';
        this.filters = filterValues;
        this.$updateChipList({ filterValues, dataSource: this.dataSource, filterFields: this.fields });
      },
      deep: true,
    },
    'filters.search': {
      handler(val) {
        if (val && val.length > 2) {
          this.onSearch();
        }
      },
      deep: true,
    },

    selectFilter: {
      handler(val) {
        if (val) {
          val.forEach((item) => {
            if (this.filterValues[item.key] === undefined) {
              this.$set(this.filterValues, item.key, this.isToggle(item) ? false : null);
            }
            if (this.checkboxValue[item.key] === undefined) {
              this.$set(this.checkboxValue, item.key, true);
            }
            // default value of some special filter field
            if (
              item.key &&
              [
                FF_LocationLab,
                FF_LocationExternal,
                FF_LocationSentToPath,
                FF_LocationReturnedToLab,
                FF_LocationReturnedToClinic,
              ].includes(item.key)
            ) {
              // set selected by default if its filter value is not false
              if (this.checkboxValue[item.key]) {
                this.$set(this.filterValues, item.key, this.filterValues[item.key] !== false);
              }
            }
          });
        }
      },
    },
  },
  methods: {
    getFilterList() {
      let filterFields = [];
      if (this.userType === this.USER_TYPES().Administrator) {
        filterFields = FILTER_SLIDE_LIST_ADMIN;
      } else if (this.userType === this.USER_TYPES().LabTechnician) {
        filterFields = FILTER_SLIDE_LIST_LABTECHNICIAN;
      }
      return filterFields.map((group) => {
        const { groupValues, ...g } = group;
        return {
          ...g,
          groupValues: groupValues.map((f) => {
            return {
              key: f,
              text: this.$translate(`query/filterFields/${f}`),
            };
          }),
        };
      });
    },
    async initialState() {
      const searchValue = this.value ? this.value.search : '';
      const { search, ...filters } = this.value;
      this.filters = {
        ...filters,
        search: '',
      };
      const optionFields = this.fields.filter((f) => f.listKey).map((f) => f.key);

      Object.keys(this.filters).map((k) => {
        if (k && !['search', 'IsIncludedReportedCase'].includes(k)) {
          // eslint-disable-next-line security/detect-object-injection
          const value = this.filters[k];

          // option field type
          if (!isPerfectEmpty(value) && optionFields.includes(k)) {
            const filterItem = searchOnFilterListByKey(this.filterList, k);
            if (filterItem) {
              this.selectFilter.push(filterItem);
            }
            // other field type
          } else if (!isPerfectEmpty(value)) {
            const filterItem = searchOnFilterListByKey(this.filterList, k);
            if (filterItem) {
              this.selectFilter.push(filterItem);
            }
          }
        }
      });
      // binding
      this.selectFilter.forEach((item) => {
        if (item && this.checkboxValue[item.key] === undefined) {
          this.$set(this.checkboxValue, item.key, true);
        }
      });
      // binding search
      this.filters['search'] = search || searchValue;
      const filterValues = {
        ...cloneDeep(this.defaultValues),
        ...cloneDeep(this.filters),
      };
      this.$set(this, 'filterValues', filterValues);
    },

    toggleSelectList() {
      this.isOpen = !this.isOpen;
      if (this.filterList && this.filterList.length > 0) {
        this.filterList.forEach((item) => {
          this.expandItem[item.key] = true;
        });
      }
    },

    isTextInput(item) {
      return [
        FF_CaseReference,
        FF_HospitalReference,
        FF_LabReference,
        FF_PatientFirstName,
        FF_PatientLastName,
        FF_TrackingNo,
      ].includes(item.key);
    },

    isDropdownMenuMulti(item) {
      return [
        FF_ClinicianIds,
        FF_ClinicIds,
        FF_ClinicTypeIds,
        FF_ClinicianIds,
        FF_LaboratoryIds,
        FF_PathologistIds,
        FF_SlideTypes,
        FF_CaseStatusIds,
      ].includes(item.key);
    },

    isFilterBlock(item) {
      return [FF_SlideBlocks].includes(item.key);
    },

    isDropdownMenuMultiGroup(item) {
      return [FF_SlideIds, FF_SpecimenTypeIds].includes(item.key);
    },

    IsDateRangePicker(item) {
      return [
        FF_LabEntryDate,
        FF_ReportedDate,
        FF_AlwRequestDate,
        FF_AlwRespondDate,
        FF_SlideLastUpdated,
        FF_SlideSentToLabDate,
        FF_SlideSentToExternalDate,
        FF_SlideSentToPathDate,
        FF_SlideReturnedToLabDate,
      ].includes(item.key);
    },

    IsDatePicker(item) {
      return [FF_SlideReturnedToClinicDate].includes(item.key);
    },

    isToggle(item) {
      return [
        FF_LocationLab,
        FF_LocationExternal,
        FF_LocationSentToPath,
        FF_LocationReturnedToClinic,
        FF_LocationReturnedToLab,
      ].includes(item.key);
    },

    isNumber(evt) {
      let charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    searchOnFilter() {
      this.isOpen = true;
      this.searchFilter = searchOnFilterList(this.filterList, this.valueFilter);
    },

    onSearch() {
      const filter = {
        search: this.filters.search,
      };

      Object.keys(this.checkboxValue).map((key) => {
        if (!isPerfectEmpty(this.filterValues[key]) && this.checkboxValue[key]) {
          filter[key] = this.filterValues[key];
        }
      });

      this.$emit('input', { ...filter, onSearch: true });
    },

    onClear() {
      this.$nextTick(() => {
        this.$refs.form.reset();
        this.selectFilter = [];
      });

      Object.keys(this.filters).map((k) => {
        // eslint-disable-next-line security/detect-object-injection
        this.filters[k] = cloneDeep(this.defaultValuesFilter[k]);
        this.filterValues[k] = cloneDeep(this.defaultValuesFilter[k]);
      });
      this.$emit('onResetFilters');
    },
  },
};
</script>
<style lang="scss">
.mx-datepicker-range {
  margin-top: 10px;
}
</style>
