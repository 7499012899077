<template>
  <ValidationObserver ref="diagnosis" v-slot="{ handleSubmit }">
    <ModalDiagnosisSaveDraftWarning ref="modalDiagnosisSaveDraftWarning"></ModalDiagnosisSaveDraftWarning>
    <modal-confirm-select-correct-snomed ref="modalConfirmSelectCorrectSnomed"></modal-confirm-select-correct-snomed>
    <modal-notify-dataset-deleted
      ref="notifyDatasetDeletedModal"
      @onConfirmDatasetDelete="onConfirmDatasetDelete"
    ></modal-notify-dataset-deleted>
    <form class="md-layout lims-form" @submit.prevent="handleSubmit()">
      <div class="form-wrapper">
        <div class="md-layout lims-form-row">
          <div class="md-layout-item md-size-100 md-small-size-100 same-specimen-type">
            <md-checkbox
              v-model="formData.isOneMicroscopyForAllSpecimens"
              class="lims-checkbox"
              @change="onChangeOneMicroscopyForAllSpecimens"
              :disabled="isAdminView || viewMode || dataEdit.isDeleted || isPathViewOnly"
              tabenable="yes"
            >
              {{ $t('entities/case/form/diagnosis/oneMicroscopyForAllSpecimens') }}
            </md-checkbox>
          </div>
        </div>
        <diagnosis-specimen
          v-for="n in formData.noOfSpecimen"
          :key="`diagnosis-specimen-` + n"
          :id="'diagnosis-specimen-' + `${n}`"
          :is-show-document-viewer="isShowDocumentViewer"
          :isBulkDiagnosis="isBulkDiagnosis"
          :isOneMicroscopyForAllSpecimens="formData.isOneMicroscopyForAllSpecimens"
          :number="formData.isOneMicroscopyForAllSpecimens ? 1 : n"
          :caseSpecimen="formData.caseSpecimens[n - 1]"
          :snomedTList="snomedTList"
          :snomedPList="snomedPList"
          :snomedMList="snomedMList"
          :pathologistId="dataEdit.pathologistId"
          :entityId="dataEdit.clinicId"
          :formMode="formMode"
          :disabled="isPathViewOnly"
          :assignedButtons="assignedButtons"
          :personalisedReports="personalisedReports"
          :clinic-name="dataEdit.clinicName"
          :clinic-id="dataEdit.clinicId"
          :totalSpecimen="formData.noOfSpecimen"
          @onChangeCaseSpecimen="onChangeCaseSpecimen"
          @onChangeCaseCommentOrDiagnosisSummary="onChangeCaseCommentOrDiagnosisSummary"
          @onChangePersonalizedReportId="onChangePersonalizedReportId"
          @onDiagnosisSpecimenReady="onDiagnosisSpecimenReady"
        ></diagnosis-specimen>
        <div class="md-layout lims-form-row">
          <div class="md-layout-item md-size-100 md-small-size-100">
            <lims-field
              class="textarea-field"
              :model="formData"
              :schema="caseFormSchema.diagnosis"
              field="caseCommentOrDiagnosisSummary"
            >
              <md-textarea
                slot="field"
                v-model="formData.caseCommentOrDiagnosisSummary"
                :disabled="isAdminView || viewMode || dataEdit.isDeleted || isPathViewOnly"
              ></md-textarea>
            </lims-field>
          </div>
        </div>
        <div>
          <div class="md-layout lims-form-row" v-if="!isAdminView">
            <div class="md-layout-item md-size-100 md-small-size-100 same-specimen-type">
              <md-checkbox
                :key="'markCaseAsUrgent'"
                v-model="formData.markCaseAsUrgent"
                :disabled="viewMode || dataEdit.isDeleted || isPathViewOnly"
                class="lims-checkbox"
              >
                {{ $t('entities/case/form/diagnosis/markCaseAsUrgent') }}
              </md-checkbox>
              <lims-tooltip :content="$t('entities/case/form/diagnosis/markCaseAsUrgentHelpText')"></lims-tooltip>
            </div>
          </div>
        </div>
        <div>
          <div class="md-layout lims-form-row">
            <div class="md-layout-item md-size-100 md-small-size-100 same-specimen-type">
              <md-checkbox
                :key="'isUnexpectedFinding'"
                v-model="formData.isUnexpectedFindings"
                :disabled="viewMode || dataEdit.isDeleted || isPathViewOnly"
                class="lims-checkbox"
              >
                {{ $t('entities/case/form/diagnosis/isUnexpectedFindings') }}
              </md-checkbox>
              <lims-tooltip :content="$t('entities/case/form/diagnosis/isUnexpectedFindingsHelpText')"></lims-tooltip>
            </div>
          </div>
        </div>
        <div>
          <div class="md-layout lims-form-row">
            <div class="md-layout-item md-size-100 md-small-size-100">
              <md-checkbox
                :key="'sendMdmNotification'"
                v-model="formData.isSentMdmNotification"
                :disabled="viewMode || dataEdit.isDeleted || isPathViewOnly"
                class="lims-checkbox"
              >
                {{ $t('entities/case/form/diagnosis/sendMdmNotification') }}
              </md-checkbox>
            </div>
          </div>
        </div>
        <div v-if="formMode === EDIT_MODE && !dataEdit.isDeleted && !isPathViewOnly" class="md-layout lims-form-row">
          <div class="md-layout-item md-size-100 md-small-size-100 lims-form-actions case-block-action">
            <lims-form-cancel></lims-form-cancel>
            <md-button
              @click="onSave()"
              :disabled="isProcessing"
              class="md-button md-primary lims-form-button md-theme-default"
              v-if="isAdminView"
            >
              {{ $t('global/button/button.save') }}
            </md-button>
            <md-button
              @click="onSave()"
              :disabled="isProcessing"
              class="md-button md-primary lims-form-button md-theme-default"
              v-else
            >
              {{ $t('global/button/button.saveDraft') }}
            </md-button>
          </div>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
/* eslint-disable security/detect-object-injection */

import { FormMixins } from '@/core/mixins';
import CaseMixins from '@/pages/Case/CaseManagement/Case.mixins';
import { caseFormService, pathCaseService } from '@/services';
import {
  APP_EVENTS,
  CASE_FORM_BLOCK,
  DROPDOWN_FIELD_NAME,
  DROPDOWN_SHORT_NAME,
  newAppEvent,
  SNOMED_NAME,
  CASE_STATUS,
} from '@/core/constants';
import { getCaseFormSchema } from '@/schemas/case-form.schema';
import { mapActions, mapGetters } from 'vuex';
import DiagnosisSpecimen from './DiagnosisSpecimen.vue';
import dropdownService from '@/services/dropdown.service';
import CaseBlockMixins from '@/pages/Case/CaseManagement/CaseBlock.mixins';
import cloneDeep from 'lodash/cloneDeep';
import ModalDiagnosisSaveDraftWarning from '@/components/Lims/modals/ModalDiagnosisSaveDraftWarning.vue';
import { debounce } from 'lodash';
import ModalConfirmSelectCorrectSnomed from '@/components/Lims/modals/ModalConfirmSelectCorrectSnomed';
import ModalNotifyDatasetDeleted from '@/components/Lims/modals/ModalNotifyDatasetDeleted.vue';
import { filterDropdownListByHiddenFieldDiagnosis, filterDropdownListByHiddenField } from '@/core/helpers';

export default {
  mixins: [FormMixins, CaseMixins, CaseBlockMixins],
  components: {
    ModalConfirmSelectCorrectSnomed,
    DiagnosisSpecimen,
    ModalDiagnosisSaveDraftWarning,
    ModalNotifyDatasetDeleted,
  },
  created() {
    this.fetchData();
  },
  props: {
    dataEdit: {
      type: Object,
      require: false,
    },
    formMode: {
      type: Number,
      require: true,
    },
    isShowDocumentViewer: {
      type: Boolean,
      require: false,
    },
    isBulkDiagnosis: {
      type: Boolean,
      require: false,
      default: false,
    },
  },
  data() {
    return {
      formData: {
        isOneMicroscopyForAllSpecimens: false,
        noOfSpecimen: null,
        diagnosisSpecimens: [],
        caseSpecimens: [],
        markCaseAsUrgent: false,
        isUnexpectedFindings: false,
        rowVersion: 0,
      },
      assignedButtons: [],
      personalisedReports: [],
      snomedTList: [],
      snomedPList: [],
      snomedMList: [],
      blockId: CASE_FORM_BLOCK.BLOCK_DIAGNOSIS,
      isProcessing: false,
    };
  },
  computed: {
    isPathologistView() {
      return this.isPathologist();
    },
    isAdminView() {
      return this.userType === this.USER_TYPES().Administrator;
    },
    isPathViewOnly() {
      return !this.dataEdit.isUpdatePermission && this.isPathologistView;
    },
    caseFormSchema: function () {
      return getCaseFormSchema(this.formMode);
    },
    collapseReport() {
      const name = 'pages/case/CaseManagement/components/Diagnosis/collapseReportTitle';
      return [this.$translate(name)];
    },
    ...mapGetters('app/data', ['getDatasetByKey']),
    ...mapGetters('app/event', [
      APP_EVENTS.EVT_ON_RELOAD_SPECIMEN_DATA,
      APP_EVENTS.EVT_ON_CASE_FORM_NO_OF_DIAGNOSIS_SPECIMENCE_CHANGED,
      APP_EVENTS.EVT_ON_SAVE_CLINIC_AND_LABORATORY_BLOCK,
    ]),
  },
  watch: {
    [APP_EVENTS.EVT_ON_RELOAD_SPECIMEN_DATA]: {
      deep: true,
      handler: function (val) {
        if (val) {
          // reload data
          this.onReloadData(val);
        }
      },
    },
    [APP_EVENTS.EVT_ON_SAVE_CLINIC_AND_LABORATORY_BLOCK]: {
      deep: true,
      handler: function (val) {
        if (val) {
          if (!this.formData.isOneMicroscopyForAllSpecimens) {
            // reload data
            this.formData.isOneMicroscopyForAllSpecimens = val.isOneMicroscopyForAllSpecimens;
          }
          this.removeEvent(newAppEvent(APP_EVENTS.EVT_ON_SAVE_CLINIC_AND_LABORATORY_BLOCK));
        }
      },
    },
    formData: {
      deep: true,
      handler: debounce(function (val) {
        if (val) {
          const dataForm = cloneDeep(this.diagnosisInfo(this.formData.caseSpecimens));
          const { caseSpecimens, ...rest } = dataForm;
          this.appendCaseData({
            caseData: {
              ...rest,
              caseSpecimenDataForm: dataForm,
              diagnosisSpecimens: [...caseSpecimens],
              isSentMdmNotification: this.formData.isSentMdmNotification,
            },
            blockId: CASE_FORM_BLOCK.BLOCK_DIAGNOSIS,
          });
        }
      }, 500),
    },
    'formData.noOfSpecimen': {
      deep: true,
      handler: function (val) {
        this.addEvent(
          newAppEvent(APP_EVENTS.EVT_ON_CASE_FORM_NO_OF_DIAGNOSIS_SPECIMENCE_CHANGED, {
            noOfSpecimen: val,
          }),
        );
      },
    },
  },
  methods: {
    isPathologist() {
      return this.userType === this.USER_TYPES().Pathologist;
    },
    ...mapActions('app/event', ['removeEvent', 'addEvent']),
    async fetchData() {
      this.userType = this.$store.getters['auth/userType'];
      if (this.isPathologist()) {
        const personalisedReports = await this.getPersonalisedReportsData();
        this.assignedButtons = this.dataEdit.casePersonalizedReportButtons;
        this.personalisedReports = personalisedReports;
      }
      const snomedData = await this.getSnomedCodeList(this.dataEdit.clinicId);

      const snomedTList = snomedData.snomed1;
      const snomedPList = snomedData.snomed2;
      const snomedMList = snomedData.snomed3;

      this.snomedTList = filterDropdownListByHiddenField(snomedData.snomed1);
      this.snomedPList = filterDropdownListByHiddenField(snomedData.snomed2);
      this.snomedMList = filterDropdownListByHiddenField(snomedData.snomed3);
      if (
        (this.formMode == this.EDIT_MODE && this.dataEdit.status == CASE_STATUS.REPORTED) ||
        this.formMode == this.VIEW_MODE
      ) {
        this.snomedTList = filterDropdownListByHiddenFieldDiagnosis(
          snomedTList,
          this.dataEdit?.caseSpecimens,
          DROPDOWN_FIELD_NAME.snomedT,
        );

        this.snomedPList = filterDropdownListByHiddenFieldDiagnosis(
          snomedPList,
          this.dataEdit?.caseSpecimens,
          DROPDOWN_FIELD_NAME.snomedP,
        );

        this.snomedMList = filterDropdownListByHiddenFieldDiagnosis(
          snomedMList,
          this.dataEdit?.caseSpecimens,
          DROPDOWN_FIELD_NAME.snomedM1,
        );
      }
      const isOneMicroscopyForAllSpecimens = this.dataEdit.isOneMicroscopyForAllSpecimens;
      const formData = {
        isUnexpectedFindings: this.dataEdit.isUnexpectedFindings,
        isSentMdmNotification: this.dataEdit.isSentMdmNotification,
        markCaseAsUrgent: this.dataEdit.urgent || false,
        isOneMicroscopyForAllSpecimens,
        caseCommentOrDiagnosisSummary: this.dataEdit.diagnosisSummary || '',
        caseSpecimens: cloneDeep(this.dataEdit.caseSpecimens),
        noOfSpecimen: isOneMicroscopyForAllSpecimens ? 1 : this.dataEdit.caseSpecimens.length,
      };

      this.formData = formData;
      this.onCheckCustomSnomed(snomedData, this.dataEdit?.caseSpecimens);
      this.$resetBlockChanged();
    },
    onCheckCustomSnomed(snomedData, caseSpecimens) {
      if (snomedData && caseSpecimens && caseSpecimens.length > 0) {
        caseSpecimens.forEach((caseSpecimen, index) => {
          const snomedM = caseSpecimen.caseSpecimenDiagnosis.find((item) => item.fieldName === SNOMED_NAME.SNOMED_M);
          const snomedP = caseSpecimen.caseSpecimenDiagnosis.find((item) => item.fieldName === SNOMED_NAME.SNOMED_P);
          const snomedT = caseSpecimen.caseSpecimenDiagnosis.find((item) => item.fieldName === SNOMED_NAME.SNOMED_T);
          const checkSnomedM = this.checkItemIncludedInSnomedList(
            snomedM?.fieldItemId,
            this.snomedMList,
            SNOMED_NAME.SNOMED_M,
            index,
          );
          const checkSnomedP = this.checkItemIncludedInSnomedList(
            snomedP?.fieldItemId,
            this.snomedPList,
            SNOMED_NAME.SNOMED_P,

            index,
          );
          const checkSnomedT = this.checkItemIncludedInSnomedList(
            snomedT?.fieldItemId,
            this.snomedTList,
            SNOMED_NAME.SNOMED_T,
            index,
          );
          if (checkSnomedM || checkSnomedP || checkSnomedT) {
            this.$refs.modalConfirmSelectCorrectSnomed?.open();
          }
        });
      }
    },
    async getSnomedCodeList(entityId) {
      const data = await caseFormService.getStainByEntityId(entityId, [DROPDOWN_SHORT_NAME.SNOMED]);
      const options = {
        snomed1: [],
        snomed2: [],
        snomed3: [],
      };
      data.map((d) => {
        const { itemName, items } = d;
        Object.keys(options).map((k) => {
          if (itemName.toLowerCase() === k.toLowerCase()) {
            // eslint-disable-next-line security/detect-object-injection
            options[k] = items.map((n) => {
              return {
                ...n,
              };
            });
          }
        });
      });
      return options;
    },
    checkItemIncludedInSnomedList(snomedId, snomedList, snomedName, caseSpecimenId) {
      // if existed do nothing
      if (this.findSnomedInSnomedList(snomedId, snomedList) || snomedId === undefined) {
        return false;
      }
      if (
        this.formData.caseSpecimens[caseSpecimenId] &&
        this.formData.caseSpecimens[caseSpecimenId].caseSpecimenDiagnosis &&
        this.formData.caseSpecimens[caseSpecimenId].caseSpecimenDiagnosis.length > 0
      ) {
        const expectedSnomed = this.formData.caseSpecimens[caseSpecimenId].caseSpecimenDiagnosis.find(
          (item) => item.fieldName === snomedName,
        );
        let index = this.formData.caseSpecimens[caseSpecimenId].caseSpecimenDiagnosis.findIndex(
          (item) => expectedSnomed?.fieldItemId === item.fieldItemId,
        );
        if (index !== -1) {
          this.formData.caseSpecimens[caseSpecimenId].caseSpecimenDiagnosis[index] = null;
        }
      }
      return true;
    },
    findSnomedInSnomedList(snomedId, snomedList) {
      return snomedList.find((snomedItem) => snomedItem.fieldItemId == snomedId);
    },
    async getPersonalisedReportsData() {
      const data = await dropdownService.getDropdownByShortNames([DROPDOWN_SHORT_NAME.CasePersonalizedReports]);

      return data[DROPDOWN_SHORT_NAME.CasePersonalizedReports];
    },
    findSnomed(caseSpecimen) {
      const snomedTfieldId = this.snomedTList.length ? this.snomedTList[0].fieldId : null;
      const snomedPfieldId = this.snomedPList.length ? this.snomedPList[0].fieldId : null;
      const snomedMfieldId = this.snomedMList.length ? this.snomedMList[0].fieldId : null;
      const snomed = {
        snomedT: null,
        snomedB: null,
        snomedP: null,
      };
      caseSpecimen.caseSpecimenDiagnosis.map((item) => {
        const { fieldId } = item;
        if (fieldId === snomedTfieldId) {
          snomed.snomedT = item;
        }
        if (fieldId === snomedPfieldId) {
          snomed.snomedP = item;
        }
        if (fieldId === snomedMfieldId) {
          snomed.snomedM = item;
        }
      });
      return snomed;
    },
    onChangeOneMicroscopyForAllSpecimens() {
      if (this.formData.isOneMicroscopyForAllSpecimens) {
        this.formData.noOfSpecimen = 1;
      } else {
        this.formData.noOfSpecimen = this.dataEdit.caseSpecimens.length;
      }
    },
    diagnosisInfo(caseSpecimens) {
      const data = {
        isOneMicroscopyForAllSpecimens: this.formData.isOneMicroscopyForAllSpecimens,
        diagnosisSummary: this.formData.caseCommentOrDiagnosisSummary,
        isUnexpectedFindings: this.formData.isUnexpectedFindings,
        isSentMdmNotification: this.formData.isSentMdmNotification,
        caseSpecimens: cloneDeep(caseSpecimens).map((caseSpecimen) => {
          if (this.formData.isOneMicroscopyForAllSpecimens) {
            caseSpecimen.caseSpecimenDiagnosis = caseSpecimens[0].caseSpecimenDiagnosis;
            caseSpecimen.microscopy = caseSpecimens[0].microscopy;
            caseSpecimen.caseDataset = caseSpecimens[0].caseDataset;
            caseSpecimen.caseDatasetValues = caseSpecimens[0].caseDatasetValues;
          }
          if (caseSpecimen.caseDataset && caseSpecimen.caseDatasetValues) {
            // transform
            caseSpecimen.caseDataset.controls = caseSpecimen.caseDataset.controls.map((c) => {
              return {
                ...c,
                selectValue: caseSpecimen.caseDatasetValues[`field_${c.controlId}`],
              };
            });
          }
          return {
            // API naming issue: caseSpecimenId vs caseSpecimentId
            caseSpecimentId: caseSpecimen.caseSpecimentId, // keep the field for other API issue - save all
            caseSpecimenId: caseSpecimen.caseSpecimentId,
            microscopy: caseSpecimen.microscopy,
            caseSpecimenDiagnosis: caseSpecimen.caseSpecimenDiagnosis,
            caseDataset: caseSpecimen.caseDataset,
          };
        }),
      };
      if (this.isPathologist()) {
        Reflect.set(data, 'urgent', this.formData.markCaseAsUrgent);
      }
      return data;
    },
    async onSave() {
      this.$refs.diagnosis.validate().then(async (success) => {
        if (success) {
          this.isProcessing = true;
          try {
            const dataForm = this.diagnosisInfo(this.formData.caseSpecimens);
            const res = await pathCaseService.updateCaseDiagnosis(this.dataEdit.caseId, dataForm);
            this.isProcessing = false;
            if (res.err) {
              const errDataset = res.err.split('_');
              if (errDataset && errDataset.length > 1 && errDataset[0] == 'CanNotFindCaseDataset') {
                this.$refs.notifyDatasetDeletedModal.open({ theOrderOfSpecimenWhichHasDatasetRemoved: errDataset[1] });
              } else {
                return this.$alertError(res.err + ' error');
              }
            } else {
              this.formData.rowVersion = res.data.rowVersion;
              if (this.isPathologistView) {
                this.setCaseIdWithRowVersion({
                  caseId: this.dataEdit.caseId,
                  rowVersion: res.data.rowVersion,
                });
                if (!this.dataEdit.isPauseCountReturnToPull) {
                  this.addEvent(
                    newAppEvent(APP_EVENTS.EVT_ON_CASE_HIDE_COUNT_DOWN, {
                      onHideCountDown: true,
                    }),
                  );
                }
                this.$refs.modalDiagnosisSaveDraftWarning.open();
                this.$resetBlockChanged();
              } else {
                this.$alertSuccess(
                  this.$t('pages/case/CaseManagement/CaseForm/update.success', {
                    caseReference: this.dataEdit.caseReference,
                  }),
                );
              }
            }
          } catch (errors) {
            this.isProcessing = false;
            this.$alertError(errors);
          }
        } else {
          this.isProcessing = false;
          this.$alertError(this.$t(`global/errors/message`));
        }
      });
    },
    onReloadData({ caseData, rowVersion }) {
      if (caseData) {
        const formData = {
          isUnexpectedFindings: caseData.isUnexpectedFindings,
          isSentMdmNotification: caseData.isSentMdmNotification,
          markCaseAsUrgent: caseData.urgent || false,
          isOneMicroscopyForAllSpecimens: caseData.isOneMicroscopyForAllSpecimens,
          caseCommentOrDiagnosisSummary: caseData.diagnosisSummary || '',
          caseSpecimens: caseData.caseSpecimens,
          noOfSpecimen: caseData.isOneMicroscopyForAllSpecimens ? 1 : caseData.caseSpecimens.length,
          rowVersion: rowVersion,
        };
        this.formData = formData;
        this.$resetBlockChanged();
      }
    },
    onChangeCaseCommentOrDiagnosisSummary(value) {
      const commentOrDiagnosisSummaryNew = value ? '\n' + value : '';
      this.formData.caseCommentOrDiagnosisSummary = this.formData.caseCommentOrDiagnosisSummary
        ? this.formData.caseCommentOrDiagnosisSummary + commentOrDiagnosisSummaryNew
        : commentOrDiagnosisSummaryNew.replace(/^[\r\n]/, '');
    },
    onChangeCaseSpecimen({ value, number }) {
      this.formData.caseSpecimens[number - 1] = cloneDeep(value);
      const dataForm = cloneDeep(this.diagnosisInfo(this.formData.caseSpecimens));
      const { caseSpecimens, ...rest } = dataForm;

      this.appendCaseData({
        caseData: {
          ...rest,
          caseSpecimenDataForm: dataForm,
          diagnosisSpecimens: [...caseSpecimens],
        },
        blockId: CASE_FORM_BLOCK.BLOCK_DIAGNOSIS,
      });
    },
    onChangePersonalizedReportId(value) {
      this.$emit('onChangePersonalizedReportId', value);
    },
    onDiagnosisSpecimenReady(value) {
      if (value && value.t) {
        this.$resetBlockChanged();
      }
    },
    onConfirmDatasetDelete() {
      this.$router.go();
    },
  },
};
</script>
<style lang="scss">
.one-specimen {
  padding: 0 30px 0 30px;
}
</style>
