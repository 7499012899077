import httpClient from './httpClient';
import environment from '@/core/environment';
import { NUMBER_OF_CHARACTERS_SEARCH } from '@/core/constants';

const RESOURCE = 'my-entity';

export default {
  updateOneMyEntityProfile(payload) {
    const { entityId, ...data } = payload;
    return httpClient.put(`/${RESOURCE}/${entityId}`, data);
  },

  create(data = {}) {
    return httpClient.post(`/${RESOURCE}/users`, data);
  },

  findOne(userId) {
    return httpClient.get(`/${RESOURCE}/users/${userId}`);
  },

  updateOneMyEntityUser(payload) {
    const { userId, ...data } = payload;
    return httpClient.put(`/${RESOURCE}/users/${userId}`, data);
  },

  getMyEntityProfile(myEntityId) {
    return httpClient.get(`/${RESOURCE}/${myEntityId}`);
  },

  getMyEntityOption() {
    return httpClient.get(`/${RESOURCE}/entity-option`);
  },

  getMyEntityType() {
    return httpClient.get(`/${RESOURCE}/entity-type`);
  },

  findClinicRestrictedPathologist({ search, entityId }) {
    const params = {};
    if (search) {
      params['search'] = search;
    }
    if (entityId) {
      params['entityId'] = entityId;
    }
    return httpClient.get(`/${RESOURCE}/clinic-restricted-pathologist`, params);
  },

  findLabRestrictedClinician({ search, entityId }) {
    const params = {};
    if (search) {
      params['search'] = search;
    }
    if (entityId) {
      params['entityId'] = entityId;
    }
    return httpClient.get(`/${RESOURCE}/lab-restricted-clinician`, params);
  },

  findLabRestrictedClinic({ search, entityId }) {
    const params = {};
    if (search) {
      params['search'] = search;
    }
    if (entityId) {
      params['entityId'] = entityId;
    }
    return httpClient.get(`/${RESOURCE}/lab-restricted-clinic`, params);
  },

  findLabRestrictedPathologist({ search, entityId }) {
    const params = {};
    if (search) {
      params['search'] = search;
    }
    if (entityId) {
      params['entityId'] = entityId;
    }
    return httpClient.get(`/${RESOURCE}/lab-restricted-pathologist`, params);
  },

  disableUser({ userId, password }) {
    return httpClient.patch(`/${RESOURCE}/disable/${userId}`, {
      password,
    });
  },

  enableUser({ userId }) {
    return httpClient.patch(`/${RESOURCE}/enable/${userId}`);
  },

  getUserListInMyEntityByFilter({ filters, pagination }) {
    const { userTypeIds, userStatusIds, search } = filters;
    const { pageNumber, pageSize } = pagination;

    return httpClient.get(`/${RESOURCE}/users`, {
      'Filters.UsertypeIds': userTypeIds,
      'Filters.UserStatusIds': userStatusIds,
      'Filters.Search': search,
      PageNumber: pageNumber,
      PageSize: pageSize,
    });
  },

  resetPasswordOfMyEntityIsUser({ userId }) {
    return httpClient.put(`/${RESOURCE}/reset-user-password/${userId}`);
  },

  getUserAboutInformation(userId) {
    return httpClient.get(`/${RESOURCE}/users/about/${userId}`);
  },

  async getMyEntityName() {
    if (sessionStorage.getMyEntityName != null) {
      const data = JSON.parse(sessionStorage.getMyEntityName);
      var fiveMinutesLater = new Date();
      const expirationTime = parseInt(environment.VUE_APP_EXPIRED_DROPDOWN_DATA);
      fiveMinutesLater.setMinutes(fiveMinutesLater.getMinutes() - expirationTime);
      if (data.timestamp <= fiveMinutesLater.getTime()) {
        return await this.privateGetMyEntityName();
      } else {
        return data.value;
      }
    } else {
      return await this.privateGetMyEntityName();
    }
  },
  async privateGetMyEntityName() {
    const { data, error } = await httpClient.get(`/${RESOURCE}/get-my-entity-name`);
    if (error) {
      return {};
    }
    sessionStorage.getMyEntityName = JSON.stringify({
      value: data,
      timestamp: new Date().getTime(),
    });
    return data;
  },

  //get list My Entity Profile
  search({ filters, pagination, sort }) {
    const { entityTypeIds, clinicTypeIds, entityStatusIds, search } = filters;
    const { pageNumber, pageSize } = pagination;

    const entityQueryParams = {};
    if (entityTypeIds) {
      entityQueryParams['Filters.EntityTypes'] = entityTypeIds;
    }
    if (clinicTypeIds) {
      entityQueryParams['Filters.ClinicTypes'] = clinicTypeIds;
    }
    if (entityStatusIds) {
      entityQueryParams['Filters.EntityStatuses'] = entityStatusIds;
    }
    if (search && search.length >= NUMBER_OF_CHARACTERS_SEARCH) {
      entityQueryParams['Filters.Search'] = search;
    }
    if (pageNumber) {
      entityQueryParams['PageNumber'] = pageNumber;
    }
    if (pageSize) {
      entityQueryParams['PageSize'] = pageSize;
    }
    if (sort) {
      entityQueryParams['Sort'] = sort;
    }
    return httpClient.get(`/${RESOURCE}/clinic-associate`, entityQueryParams);
  },

  getLaboratoryInfo() {
    return httpClient.get(`/${RESOURCE}/clinic-and-lab-entities`);
  },
};
