<template>
  <md-dialog
    :md-close-on-esc="true"
    :md-active.sync="isVisible"
    @md-clicked-outside="close"
    class="lims-modal large-modal"
  >
    <div class="dialog-icon-close" @click="close"><md-icon>close</md-icon></div>
    <br />
    <div class="dialog-content">
      <div class="md-layout lims-form-row mg-top-24">
        <iframe
          :src="
            $myPdfViewer({
              link: linkUrl,
              format: 'pdf',
            })
          "
          style="width: 100%; height: 1200px"
          frameborder="0"
        ></iframe>
      </div>
    </div>
  </md-dialog>
</template>

<script>
import modalMixins from './modal.mixins';
export default {
  name: 'ModalPreviewButton',

  mixins: [modalMixins],

  props: {
    linkUrl: {
      require: true,
    },
  },
};
</script>

<style lang="scss"></style>
