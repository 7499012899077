<template>
  <div v-if="isReady" class="md-layout text-center">
    <lims-complete-your-account-card header-color="green" v-if="isLogged">
      <h4 slot="title" class="title">{{ $t('page/auth/CompleteYourAccount/title') }}</h4>
      <p slot="description">...</p>
    </lims-complete-your-account-card>

    <div class="md-xsmall-size-100">
      <ValidationObserver ref="form" v-slot="{ handleSubmit }">
        <form class="md-layout lims-form form-check-password-strength" @submit.prevent="handleSubmit()">
          <lims-complete-your-account-card header-color="primary" v-if="!isLogged">
            <h4 slot="title" class="title">{{ $t('page/auth/CompleteYourAccount/title') }}</h4>
            <modal-input-your-telephone
              slot="title"
              ref="inputYourTelephoneModal"
              :userId="userId"
              :token="token"
              :verify-two-fa-mode="verifyTwoFaMode"
              :mobile-phone="telephoneMobileData"
              @onNext="onCompleteInputTelephoneMobile"
            ></modal-input-your-telephone>
            <modal-scan-qr-code
              slot="title"
              ref="scanQrCodeModal"
              :qr-code-uri="formData.qrCodeUri"
              @onNext="onCompleteScanQrCode"
              @onShowSecretCode="onShowSecretCode"
            ></modal-scan-qr-code>
            <modal-show-secret-code
              slot="title"
              ref="showSecretCode"
              :secret-code="formData.secretCode"
              @onNext="onCompleteScanQrCode"
            ></modal-show-secret-code>
            <modal-verify-code
              slot="title"
              ref="verifyCodeModal"
              :option="verifyContent"
              :is-wrong-code="isWrongCode"
              @onVerify="onVerifyCode"
              @onResendCode="onResendVerifyPhoneNumberCode"
            ></modal-verify-code>
            <modal-verify-successfully
              slot="title"
              ref="verifySuccessfullyModal"
              :method="verifyContent === 'Authy app' ? verifyContent : 'SMS'"
              @onVerifySuccessfully="onVerifySuccessfully"
            ></modal-verify-successfully>
            <modal-redirecting-to-login
              slot="title"
              ref="redirectingToLoginModal"
              :message="$t('page/auth/CompleteYourAccount/message.redirectingToLogin')"
            ></modal-redirecting-to-login>
            <div slot="account" class="account-setting complete-your-account-password">
              <lims-field :model="formData" :schema="completeYourAccountSchema.validateInformation" field="username">
                <lims-tooltip
                  slot="label-info"
                  :content="$t('page/auth/CompleteYourAccount/userNameNote')"
                ></lims-tooltip>
                <md-input
                  slot="field"
                  @keyup.enter="onClickCompleteAccount"
                  v-model="formData.username"
                  type="text"
                  maxlength="250"
                  autocomplete="off"
                  @change="$lowercase(formData, 'username')"
                ></md-input>
              </lims-field>

              <div class="check-password-strength-group username-validate">
                <lims-username-strength :username-string="formData.username"></lims-username-strength>
              </div>
              <lims-field
                :model="formData"
                :schema="completeYourAccountSchema.validateInformation"
                field="password"
                class="input-title"
              >
                <md-input
                  slot="field"
                  @keyup.enter="onClickCompleteAccount"
                  v-model="formData.password"
                  type="password"
                  maxlength="250"
                  autocomplete="off"
                ></md-input>
              </lims-field>
              <div class="check-password-strength-group password-validate">
                <lims-password-strength :password-string="formData.password"></lims-password-strength>
              </div>
              <lims-field
                :model="formData"
                :schema="completeYourAccountSchema.validateInformation"
                field="confirmPassword"
                class="input-title"
              >
                <md-input
                  slot="field"
                  @keyup.enter="onClickCompleteAccount"
                  v-model="formData.confirmPassword"
                  type="password"
                  maxlength="250"
                  autocomplete="off"
                ></md-input>
              </lims-field>
            </div>
            <div slot="two-FA" class="two-fa-setting">
              <p class="padding-label two-fa">{{ $t('page/auth/CompleteYourAccount/twoFA.txt') }}</p>
              <lims-setup-two-fa-field
                :is-setup="formData.isSetupTotp"
                :content="authyContentBaseOnIsSetup"
                @onButtonClick="onClickAuthySetup"
              ></lims-setup-two-fa-field>
              <lims-setup-two-fa-field
                :is-setup="formData.isPhoneVerified"
                :content="smsContentBaseOnIsSetup"
                @onButtonClick="onClickSmsSetup"
              ></lims-setup-two-fa-field>
              <lims-setup-two-fa-field
                :is-setup="true"
                :content="emailContentBaseOnIsSetup"
                class="left-side"
              ></lims-setup-two-fa-field>
            </div>
          </lims-complete-your-account-card>
          <div class="btn-complete-your-account">
            <md-button
              class="md-primary"
              @click="onClickCompleteAccount"
              :disabled="!formData.username || !formData.password || !formData.confirmPassword"
            >
              {{ $t('page/auth/CompleteYourAccount/completeAccountBtn') }}
            </md-button>
          </div>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { APP_ROUTES, VERIFY_MODE, VERIFY_TW0_FA_MODE } from '@/core/constants';
import { FormMixins } from '@/core/mixins';
import { LimsCompleteYourAccountCard } from '@/components/Lims/Cards';
import LimsPasswordStrength from '@/components/Lims/LimsPasswordStrength';
import LimsUsernameStrength from '@/components/Lims/modals/LimsUsernameStrength';
import LimsSetupTwoFaField from '@/components/Lims/LimsSetupTwoFaField';
import ModalInputYourTelephone from '@/components/Lims/modals/ModalInputYourTelephone';
import ModalVerifyCode from '@/components/Lims/modals/ModalVerifyCode';
import ModalVerifySuccessfully from '@/components/Lims/modals/ModalVerifySuccessfully';
import ModalRedirectingToLogin from '@/components/Lims/modals/ModalRedirectingToLogin.vue';
import ModalScanQrCode from '@/components/Lims/modals/ModalScanQrCode';
import { AuthService } from '@/services';
import { getCompleteYourAccountSchema } from '@/schemas/complete-your-account.schema';
import { VERIFY_SMS_ERROR } from '@/core/error-constants';
import { partiallyHideEmail, partiallyHidePhoneNumber } from '@/core/helpers';
import ModalShowSecretCode from '@/components/Lims/modals/ModalShowSecretCode';

export default {
  components: {
    ModalShowSecretCode,
    ModalScanQrCode,
    ModalVerifySuccessfully,
    ModalVerifyCode,
    ModalInputYourTelephone,
    LimsSetupTwoFaField,
    LimsUsernameStrength,
    LimsCompleteYourAccountCard,
    LimsPasswordStrength,
    ModalRedirectingToLogin,
  },

  mixins: [FormMixins],

  props: {
    redirectTo: {
      type: String,
      require: false,
      default: '',
    },
    userId: {
      require: true,
    },
    token: {
      require: true,
    },
  },

  data: function () {
    return {
      formData: {
        email: null,
        username: null,
        password: null,
        confirmPassword: null,
        countryCode: null,
        phoneNumber: null,
        locale: null,
        isPhoneVerified: false,
        isSetupTotp: false,
        qrCodeUri: '',
        secretCode: '',
        sid: null,
      },
      verifyContent: '',
      telephoneMobileData: {},
      isWrongCode: false,
      isReady: false,
    };
  },

  computed: {
    ...mapState('auth', {
      isLogged: (state) => state.isLogged,
    }),

    completeYourAccountSchema() {
      return getCompleteYourAccountSchema(VERIFY_MODE.USERNAME, this.userId, this.token, this.formData.password);
    },

    authyContentBaseOnIsSetup() {
      return this.formData.isSetupTotp
        ? this.$t('page/auth/CompleteYourAccount/authyContent/2FaForAuthyApp')
        : this.$t('page/auth/CompleteYourAccount/authyContent/setUp2FAViaAuthy');
    },

    smsContentBaseOnIsSetup() {
      return this.formData.isPhoneVerified
        ? this.$t('page/auth/CompleteYourAccount/2FaForSms') + partiallyHidePhoneNumber('+' + this.telephoneMobile)
        : this.$t('page/auth/CompleteYourAccount/setUp2FAViaSms');
    },

    emailContentBaseOnIsSetup() {
      return this.$t('page/auth/CompleteYourAccount/setupEmail') + partiallyHideEmail(this.formData.email);
    },

    telephoneMobile() {
      return this.formData.countryCode + this.formData.phoneNumber;
    },

    verifyTwoFaMode() {
      return VERIFY_TW0_FA_MODE.UPDATE;
    },
  },

  created() {
    this.fetchData();
  },

  watch: {
    isLogged: {
      handler: function (isLogged) {
        if (isLogged) {
          const redirectTo = this.redirectTo || APP_ROUTES.DASHBOARD;
          this.$router.push(redirectTo);
        }
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions('auth', ['CHECK_USER_COOKIE']),

    async fetchData() {
      // check link expired when click to Complete Account form email
      const res = await AuthService.firstTimeLogin({
        userId: this.userId,
        token: this.token,
      });
      if (res.err) {
        this.$router.push(APP_ROUTES.AUTH_LOGIN);
        this.$alertError(this.$t('page/auth/CompleteYourAccount/error/linkExpired'));
      }
      this.isReady = true;
      this.formData.email = res.data.email;
      this.formData.username = res.data.username;
      this.formData.countryCode = res.data.countryCode;
      this.formData.phoneNumber = res.data.phoneNumber;
      this.formData.isPhoneVerified = res.data.isPhoneVerified;
      this.formData.isSetupTotp = res.data.isSetupTotp;
      this.telephoneMobileData = {
        countryCodeMobile: res.data.countryCode,
        telephoneMobile: res.data.phoneNumber,
        localeMobile: res.data.localeMobile,
      };
    },

    onClickSmsSetup() {
      if (!this.formData.isPhoneVerified) {
        this.$refs.inputYourTelephoneModal.open();
      }
    },

    async onClickAuthySetup() {
      if (!this.formData.isSetupTotp) {
        const res = await AuthService.getTotpInfo(this.userId, this.token);

        if (res.err) {
          return this.$alertError('Error: ' + res.error);
        }

        this.formData.qrCodeUri = res.data.binding.uri;
        this.formData.secretCode = res.data.binding.secret;
        this.formData.sid = res.data.sid;
        this.$refs.scanQrCodeModal.open();
      }
    },

    async onCompleteInputTelephoneMobile(data) {
      this.$refs.inputYourTelephoneModal.close();
      this.formData.countryCode = data.countryCodeMobile.code;
      this.formData.locale = data.countryCodeMobile.locale;
      this.formData.phoneNumber = data.telephoneMobile;

      const res = await AuthService.sendCodeVerifyPhoneNumber({
        userId: this.userId,
        token: this.token,
        countryCode: this.formData.countryCode,
        phoneNumber: this.formData.phoneNumber,
      });

      if (res.error) {
        switch (res.error) {
          case VERIFY_SMS_ERROR.ServiceIsUnavailable:
            this.$alertError(this.$t('page/auth/CompleteYourAccount/error/serverIsUnAvailable'));
            break;
          default:
            this.$alertError('Error: ' + res.error);
        }
      } else {
        this.verifyContent = partiallyHidePhoneNumber('+' + this.telephoneMobile);
        this.$refs.verifyCodeModal.open();
      }
    },

    async onResendVerifyPhoneNumberCode() {
      const res = await AuthService.sendCodeVerifyPhoneNumber({
        userId: this.userId,
        token: this.token,
        countryCode: this.formData.countryCode,
        phoneNumber: this.formData.phoneNumber,
      });

      if (res.error) {
        this.$alertError('Error: ' + res.error);
      } else {
        this.$alertSuccess(this.$t('page/auth/CompleteYourAccount/notification/newCodeSent'));
      }
    },

    onCompleteScanQrCode() {
      this.$refs.scanQrCodeModal.close();
      this.$refs.showSecretCode.close();
      this.verifyContent = 'Authy app';
      this.$refs.verifyCodeModal.open();
    },

    onShowSecretCode() {
      this.$refs.scanQrCodeModal.close();
      this.$refs.showSecretCode.open();
    },

    async onVerifyCode(data) {
      if (this.verifyContent === 'Authy app') {
        const res = await AuthService.verifySetupTotp(this.userId, this.token, data.code, this.formData.sid);

        if (res.error) {
          switch (res.error) {
            case VERIFY_SMS_ERROR.ServiceIsUnavailable:
              this.$alertError(this.$t('page/auth/CompleteYourAccount/error/serverIsUnAvailableAuthy'));
              break;
            case VERIFY_SMS_ERROR.VerifyCodeIncorrect:
              this.isWrongCode = true;
              break;
            default:
              this.$alertError('Error: ' + res.error);
          }
        } else {
          this.$refs.verifyCodeModal.close();
          this.$refs.verifySuccessfullyModal.open();
        }
      } else {
        const res = await AuthService.verifyPhoneNumber({
          userId: this.userId,
          token: this.token,
          countryCode: this.formData.countryCode,
          phoneNumber: this.formData.phoneNumber,
          code: data.code,
          localeMobile: this.formData.locale,
        });

        if (res.error) {
          switch (res.error) {
            case VERIFY_SMS_ERROR.ServiceIsUnavailable:
              this.$alertError(this.$t('page/auth/CompleteYourAccount/error/serverIsUnAvailableSms'));
              break;
            case VERIFY_SMS_ERROR.MaxSendAttemptsReached:
              this.$alertError(this.$t('page/auth/CompleteYourAccount/error/waitTenMinutes'));
              break;
            case VERIFY_SMS_ERROR.VerifyCodeIncorrect:
              this.$alertError(this.$t('page/auth/CompleteYourAccount/error/codeIncorrectOrExpired'));
              break;
            default:
              this.$alertError('Error: ' + res.error);
          }
        } else {
          this.$refs.verifyCodeModal.close();
          this.verifyContent = 'SMS';
          this.$refs.verifySuccessfullyModal.open();
        }
      }
    },

    onVerifySuccessfully() {
      this.$refs.verifySuccessfullyModal.close();
      if (this.verifyContent === 'Authy app') {
        this.formData.isSetupTotp = true;
      } else {
        if (this.verifyContent === 'SMS') {
          this.formData.isPhoneVerified = true;
        }
      }
    },

    onClickCompleteAccount() {
      this.$refs.form.validate().then(async (success) => {
        if (success) {
          try {
            const res = await AuthService.completeYourAccount({
              userId: this.userId,
              token: this.token,
              username: this.formData.username,
              password: this.formData.password,
            });
            if (res.errors && res.errors.length > 0) {
              this.$alertError(this.$t(`global/errors/message`));
              return this.$refs.form.setErrors(this.$transformErrors(res.errors));
            }
            if (res.err || res.error) {
              return this.$alertError(res.error);
            }
            if (res) {
              //redirect to login page
              this.$refs.redirectingToLoginModal.open();
            }
          } catch (errors) {
            this.$alertError(errors);
          }
        } else {
          this.$alertError(this.$t(`global/errors/message`));
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.account-setting {
  padding: 15px;
}
.two-fa-setting {
  padding: 0 15px 15px 15px;
  overflow: hidden;
}
.padding-label {
  &.two-fa {
    padding: 0 0 10px;
  }
  padding-top: 10px;
  margin-bottom: 0;
  text-align: left;
  display: inline-block;
}
.btn-complete-your-account {
  margin: 15px auto 0;
  display: table;
  width: 100%;
}
</style>
