<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit, invalid }">
    <form class="md-layout lims-form form-login" @submit.prevent="handleSubmit()">
      <md-dialog
        :md-close-on-esc="true"
        :md-active.sync="isVisible"
        @md-clicked-outside="close"
        class="lims-modal large-modal send-email-modal"
      >
        <div class="dialog-icon-close" @click="close"><md-icon>close</md-icon></div>
        <div class="lims-form dialog-content mg-top-24">
          <div class="md-layout lims-form-row">
            <div class="md-layout-item md-size-10 md-small-size-100">
              <label class="title-label">{{ $t('entities/modals/ModalSendEmail/fromAddress') }}</label>
            </div>
            <div class="md-layout-item md-size-90 md-small-size-100">
              <lims-field
                :model="formData"
                :schema="schema"
                viewMode="only"
                field="fromAddress"
                :validationMode="'eager'"
              >
                <md-input slot="field" v-model="formData.fromAddress" type="text"></md-input>
              </lims-field>
            </div>
          </div>
          <div class="md-layout lims-form-row">
            <div class="md-layout-item md-size-10 md-small-size-100">
              <label class="title-label required">{{ $t('entities/modals/ModalSendEmail/toAddress') }}</label>
            </div>
            <div class="md-layout-item md-size-90 md-small-size-100">
              <lims-field
                :model="formData"
                :schema="schema"
                viewMode="only"
                field="toAddress"
                :validationMode="'eager'"
              >
                <md-input slot="field" v-model="formData.toAddress" type="text"></md-input>
              </lims-field>
            </div>
          </div>
          <div class="md-layout lims-form-row">
            <div class="md-layout-item md-size-10 md-small-size-100">
              <label class="title-label">{{ $t('entities/modals/ModalSendEmail/ccAddress') }}</label>
            </div>
            <div class="md-layout-item md-size-90 md-small-size-100">
              <lims-field
                :model="formData"
                :schema="schema"
                viewMode="only"
                field="ccAddress"
                :validationMode="'eager'"
              >
                <md-input slot="field" v-model="formData.ccAddress" type="text"></md-input>
              </lims-field>
            </div>
          </div>
          <div class="md-layout lims-form-row">
            <div class="md-layout-item md-size-10 md-small-size-100">
              <label class="title-label required">{{ $t('entities/modals/ModalSendEmail/subject') }}</label>
            </div>
            <div class="md-layout-item md-size-90 md-small-size-100">
              <lims-field :model="formData" :schema="schema" viewMode="only" field="subject" :validationMode="'eager'">
                <md-input slot="field" v-model="formData.subject" type="text"></md-input>
              </lims-field>
            </div>
          </div>
          <div class="md-layout lims-form-row">
            <div class="md-layout-item md-size-100 md-small-size-100">
              <lims-field class="textarea-field" :model="formData" viewMode="only" :schema="schema" field="content">
                <ckeditor slot="field" :editor="editor" v-model="formData.content"></ckeditor>
              </lims-field>
            </div>
          </div>
          <div class="md-layout lims-form-row">
            <div class="md-layout-item md-size-100 md-small-size-100">
              <a class="attachment-link" @click.stop="onExportAsPdf(caseId)" :disabled="isProcessing">
                <md-icon>attachment</md-icon>
                {{ caseReference }}
              </a>
            </div>
          </div>
        </div>
        <md-dialog-actions>
          <md-button @click="close" class="lims-form-button">{{ $t('global/button/button.cancel') }}</md-button>
          <md-button @click="onSendEmail" :disabled="invalid || isProcessing" class="lims-form-button md-primary">{{
            $t('global/button/button.sendEmail')
          }}</md-button>
        </md-dialog-actions>
      </md-dialog>
    </form>
  </ValidationObserver>
</template>

<script>
import { caseReportService } from '@/services';
import modalMixins from './modal.mixins';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { downloadBlob, extractFileNameFromResponseHeader } from '@/core/helpers';

export default {
  name: 'ModalSendEmail',
  mixins: [modalMixins],
  data() {
    return {
      formData: {
        fromAddress: '',
        toAddress: '',
        ccAddress: '',
        subject: '',
        content: '',
      },
      caseReference: '',
      caseId: '',
      isRedacted: false,
      editor: ClassicEditor,
    };
  },
  computed: {
    schema() {
      return {
        entity: 'modals/ModalSendEmail',
        fields: {
          fromAddress: '',
          toAddress: 'required',
          ccAddress: '',
          subject: 'required',
          content: '',
        },
      };
    },
  },
  methods: {
    open(val) {
      if (val) {
        this.isVisible = true;
        this.caseId = val.caseId;
        this.isRedacted = val ? val.isRedacted : true; // default value is always redacted
        this.caseReference = val.emailContent?.fileAttachmentName ?? `${val.caseId}`;
        this.formData = val.emailContent;
      }
    },
    close() {
      this.isVisible = false;
      this.$nextTick(() => {
        this.formData = {
          fromAddress: '',
          toAddress: '',
          ccAddress: '',
          subject: '',
          content: '',
        };
      });
    },
    async onSendEmail() {
      this.isProcessing = true;
      this.formData.isRedacted = this.isRedacted;
      const res = await caseReportService.sendEmail(this.formData);
      this.isProcessing = false;
      if (res.error) {
        let errorMessage = res.error;
        const serverErrors = ['SendCaseReportFailed'];
        if (serverErrors.includes(errorMessage)) {
          errorMessage = this.$t(`entities/modals/ModalSendEmail/serverErrors/${errorMessage}`);
        }
        return this.$alertError(errorMessage);
      } else {
        this.$alertSuccess(
          this.$t('components/ModalBulkActionCommom.completeActionSuccess', {
            bulkAction: 'Send email',
          }),
        );
      }
      this.close();
    },
    async onExportAsPdf(caseId) {
      try {
        this.isProcessing = true;
        const res = await caseReportService.downloadCaseReport(caseId, this.isRedacted);
        this.isProcessing = false;
        if (res.err) {
          return this.$alertError(res.err + ' error');
        }
        const fileName = extractFileNameFromResponseHeader(res.headers);
        if (res.data) {
          downloadBlob(res.data, fileName || this.caseReference);
        }
      } catch (errors) {
        this.$alertError(errors);
      }
    },
  },
};
</script>

<style lang="scss">
.send-email-modal {
  .title-label {
    padding-top: 15px;
    display: block;
    @media only screen and (max-width: 959px) {
      padding-top: 0;
    }
  }
  .email-content {
    height: 300px !important;
  }
  .attachment-link {
    cursor: pointer;
  }
}
</style>
