import { CASE_ISSUE_VALUE_NAME, CASE_STATUS, FORM_MODES } from '@/core/constants';
import { formatDateWithoutTime } from '@/core/helpers';
import { getCaseFormSchema } from '@/schemas/case-form.schema';
import { mapActions, mapState } from 'vuex';
import { constantService } from '@/services';

export default {
  computed: {
    caseFormSchema: function () {
      return getCaseFormSchema(this.formMode);
    },
    viewMode() {
      return this.formMode === FORM_MODES.VIEW;
    },
    isAdminView() {
      const userType = this.$store.getters['auth/userType'];
      return userType === this.USER_TYPES()?.Administrator;
    },
  },
  methods: {
    ...mapActions('caseData', ['setSpecimenNumber']),
    async $getCaseSpecimenSettings() {
      const { data, error } = await constantService.getCaseSpecimenSettings();
      if (error) {
        this.$alertError(error);
        return 1;
      }
      if (data) {
        this.setSpecimenNumber(data.maxNumberOfSpecimens);
        return data.maxNumberOfSpecimens;
      }
      return 1;
    },
    isShowResend(item) {
      return (
        this.$isAuthorized(['Case_ExternalHospital_Update']) &&
        !item.isDeleted &&
        item.statusId == CASE_STATUS.REPORTED &&
        item.source !== 'Case Form' &&
        item.source !== 'Case Simple Booking' &&
        item.source !== 'Case Import'
      );
    },
    // TODO: refactor in client v3
    isShowResendCaseDetail(item) {
      return (
        this.$isAuthorized(['Case_ExternalHospital_Update']) &&
        !item.isDeleted &&
        item.status == CASE_STATUS.REPORTED &&
        item.fromSourceName !== 'Case Form' &&
        item.fromSourceName !== 'Case Simple Booking' &&
        item.fromSourceName !== 'Case Import'
      );
    },
    isAnonymiseField(formData, fieldName) {
      if (formData.anonymiseFields) {
        return formData.anonymiseFields.find((item) => item === fieldName) ? true : false;
      } else {
        return false;
      }
    },
  },
};

export const CaseIssueMixins = {
  data() {
    return {
      formData: {},
      status: null,
      userTypeList: [],
      rowVersion: null,
    };
  },
  watch: {
    'formData.issueId': {
      deep: true,
      handler: function (val) {
        const findItemWithIssueId = this.issueCaseList.find((issueCase) => issueCase.issueId === val);
        if (findItemWithIssueId) {
          const userTypeList = findItemWithIssueId.toRespondUserTypes;
          if (userTypeList) {
            this.userTypeList = userTypeList.map((userType) => {
              return {
                value: userType.id,
                label: userType.text,
              };
            });
          }
        }
      },
    },
  },
  computed: {
    ...mapState('auth', {
      user: (state) => state.user,
    }),
  },
  methods: {
    issueFieldIsNone() {
      const findItemWithIssueId = this.issueCaseList.find((issueCase) => issueCase.issueId === this.formData.issueId);
      if (findItemWithIssueId) {
        return findItemWithIssueId.issueFields.length === 0;
      } else {
        return false;
      }
    },
    issueFieldIsNoneWithIssueId(issueId) {
      const findItemWithIssueId = this.issueCaseList.find((issueCase) => issueCase.issueId === issueId);
      if (findItemWithIssueId) {
        return findItemWithIssueId.issueFields.length === 0;
      } else {
        return false;
      }
    },
    issuePreventUpdateStatusIsNone() {
      const findItemWithIssueId = this.issueCaseList.find((issueCase) => issueCase.issueId === this.formData.issueId);
      if (findItemWithIssueId) {
        if (findItemWithIssueId.issuePreventUpdateStatusTo.length > 0) {
          this.status = findItemWithIssueId.issuePreventUpdateStatusTo.join(', ');
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    issueDataEditMode(reload = false) {
      let toRespondUserTypeIds = [];
      if (this.formData.toRespond) {
        this.formData.toRespond.forEach((toRespondItem) => {
          toRespondUserTypeIds.push(toRespondItem.value);
        });
      }
      const payload = {
        status: this.formData.isMarkIssueAsClose === true ? 'Closed' : this.formData.status,
        issueId: this.formData.issueId,
        comment: this.formData.commentOnIssue,
        isMarkIssueAsClose: this.formData.isMarkIssueAsClose,
        toRespondUserTypeIds: toRespondUserTypeIds,
        reportedAt: new Date(),
      };
      if (reload) {
        const toRespond = {
          toRespondUserTypeIds: [],
          toRespondUser: [],
        };
        if (this.formData.toRespond) {
          this.formData.toRespond.forEach((toRespondItem) => {
            toRespond.toRespondUserTypeIds.push(toRespondItem.value);
            toRespond.toRespondUser.push(toRespondItem.label);
          });
          Reflect.set(payload, 'toRespond', {
            toRespondUserTypeIds: toRespond.toRespondUserTypeIds,
            toRespondUserListName: toRespond.toRespondUser.join(', '),
          });
          Reflect.deleteProperty(payload, 'toRespondUserTypeIds');
        }
      }
      return payload;
    },
    createIssueData() {
      const status = this.formData.isMarkIssueAsClose ? 'Closed' : 'Open';
      const issueName = this.getIssueItemByIssueId(this.formData.issueId).issueName;
      const toRespond = {
        toRespondUserTypeIds: [],
        toRespondUser: [],
      };
      if (this.formData.toRespond) {
        this.formData.toRespond.forEach((toRespondItem) => {
          toRespond.toRespondUserTypeIds.push(toRespondItem.value);
          toRespond.toRespondUser.push(toRespondItem.label);
        });
      }
      const findItemWithIssueId = this.issueCaseList.find((issueCase) => issueCase.issueId === this.formData.issueId);
      return {
        issue: {
          issueName: issueName,
          issueId: this.formData.issueId,
        },
        caseIssueId: this.formData.caseIssueId,
        reportedBy: this.user.firstName + ' ' + this.user.lastName,
        reportedAt: new Date(),
        status: status,
        comment: this.formData.commentOnIssue,
        billingEntity: this.formData.billingEntity,
        isMarkIssueAsClose: this.formData.isMarkIssueAsClose,
        toRespond: {
          toRespondUserTypeIds: toRespond.toRespondUserTypeIds,
          toRespondUserListName: toRespond.toRespondUser.join(', '),
        },
        respondedAt: '',
        action: this.actionByStatus(status),
        isHaveIssueFields: findItemWithIssueId?.isHaveIssueFields,
        reportedByUserTypeIds: findItemWithIssueId?.reportedByUserTypeIds,
      };
    },
    getIssueItemByIssueId(issueId) {
      return this.issueCaseList.find((issueCase) => issueCase.issueId === issueId);
    },
    actionByStatus(status) {
      if (status === 'Deleted') {
        return '';
      } else {
        if (status === 'Closed') {
          return 'view';
        } else {
          if (status === 'Open' && !this.issueFieldIsNone()) {
            return 'edit, delete, send';
          } else {
            if (status === 'Pending Review') {
              return 'edit';
            } else {
              return 'edit, delete';
            }
          }
        }
      }
    },
    actionByStatusAndIssueId(status, issueId) {
      if (status === 'Deleted') {
        return '';
      } else {
        if (status === 'Closed') {
          return 'view';
        } else {
          if (status === 'Open' && !this.issueFieldIsNoneWithIssueId(issueId)) {
            return 'edit, delete, send';
          } else {
            if (status === 'Pending Review') {
              return 'edit';
            } else {
              return 'edit, delete';
            }
          }
        }
      }
    },
    onClose() {
      this.isVisible = false;
      this.formData = {};
    },
    $setFormDataCaseIssue(caseIssue) {
      switch (caseIssue.caseIssueValueName) {
        case CASE_ISSUE_VALUE_NAME.DOB: {
          this.formData.dob = formatDateWithoutTime(caseIssue.caseIssueValue);
          break;
        }
        case CASE_ISSUE_VALUE_NAME.BILLING_ENTITY: {
          let billingEntity = this.billingEntityList.find(
            (billingEntityItem) => billingEntityItem.fieldItemId === caseIssue.fieldItemId,
          );
          this.formData.billingEntity = billingEntity.fieldItemName;
          break;
        }
        case CASE_ISSUE_VALUE_NAME.COUNTRY: {
          let country = this.countries.find((country) => country.value.toString() === caseIssue.caseIssueValue);
          this.formData.country = country.label;
          break;
        }
        case CASE_ISSUE_VALUE_NAME.INSURANCE_COMPANY: {
          let insuranceCompany = this.insuranceCompanyList.find(
            (insuranceCompany) => insuranceCompany.fieldItemId.toString() === caseIssue.caseIssueValue,
          );
          this.formData.insuranceCompany = insuranceCompany.fieldItemName;
          break;
        }
        case CASE_ISSUE_VALUE_NAME.INSURANCE_NUMBER: {
          this.formData.insuranceNumber = caseIssue.caseIssueValue;
          break;
        }
        case CASE_ISSUE_VALUE_NAME.INSURANCE_AUTHORIZATION_NUMBER: {
          this.formData.insuranceAuthorisationNumber = caseIssue.caseIssueValue;
          break;
        }
        case CASE_ISSUE_VALUE_NAME.INSURANCE_NOTE: {
          this.formData.insuranceNote = caseIssue.caseIssueValue;
          break;
        }
        case CASE_ISSUE_VALUE_NAME.SPECIMEN_TYPE: {
          let specimenType = this.specimenTypeList.find(
            (specimenType) => specimenType.fieldItemId.toString() === caseIssue.caseIssueValue,
          );
          this.formData.specimenType = specimenType.fieldItemName;
          break;
        }
        case CASE_ISSUE_VALUE_NAME.POSTAL_CODE: {
          this.formData.postalCode = caseIssue.caseIssueValue;
          break;
        }
        case CASE_ISSUE_VALUE_NAME.TOWN: {
          this.formData.town = caseIssue.caseIssueValue;
          break;
        }
        case CASE_ISSUE_VALUE_NAME.ADDRESS_1: {
          this.formData.address1 = caseIssue.caseIssueValue;
          break;
        }
        case CASE_ISSUE_VALUE_NAME.ADDRESS_2: {
          this.formData.address2 = caseIssue.caseIssueValue;
          break;
        }
      }
    },
  },
};
