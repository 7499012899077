<template>
  <lims-block :cardText="false" class="list-page">
    <div class="md-layout" slot="blockContent">
      <modal-delete-dataset ref="deleteDatasetModal" @onDelete="onDeleteDataset"></modal-delete-dataset>
      <div class="md-layout-item" v-if="query">
        <dataset-list @action="onRowAction" :dataset="items" :sort="query.sort">
          <md-table-toolbar slot="toolbar">
            <div class="toolbar-row">
              <md-field class="field-btn">
                <md-button
                  v-if="$isAuthorized(['MyEntity_DatasetManagement_Create'])"
                  @click="onAddDataset"
                  class="btn-addUser"
                  >{{ $t('pages.dataset.addDataset') }}</md-button
                >
              </md-field>
            </div>
            <div class="toolbar-row">
              <div class="field-rows-per-page">
                <lims-row-per-page :pageSize="query.pagination.pageSize" @pageSizeChanged="$pageSizeChanged" />
              </div>
              <div class="field-search">
                <dataset-filter
                  v-model="query.filters"
                  :defaultValues="defaultValues"
                  :fields="fields"
                  @onResetFilters="onResetFilters"
                ></dataset-filter>
              </div>
            </div>
            <lims-pagination ref="pager" :total-item="totalItem" :pagination="query.pagination"> </lims-pagination>
          </md-table-toolbar>
        </dataset-list>
        <lims-pagination :total-item="totalItem" :pagination="query.pagination"> </lims-pagination>
      </div>
    </div>
  </lims-block>
</template>

<script>
import DatasetList from './List/DatasetList.vue';
import DatasetFilter from './List/DatasetFilter.vue';
import LimsRowPerPage from '@/components/Lims/LimsRowPerPage';
import LimsPagination from '@/components/Lims/LimsPagination';

import { ConfigMixins, ListWithFilterMixins } from '@/core/mixins';
import { datasetQuery } from '@/query';
import { buildFilterFromQuery, buildFilterQueryParams } from '@/query/utils';
import cloneDeep from 'lodash/cloneDeep';
import { APP_ROUTES, FORM_ACTIONS } from '@/core/constants';
import datasetService from '@/services/dataset.service';
import DatasetMixins from './dataset.mixins';
import ModalDeleteDataset from '@/components/Lims/modals/ModalDeleteDataset.vue';

export default {
  mixins: [ConfigMixins, ListWithFilterMixins, DatasetMixins],
  components: {
    DatasetList,
    DatasetFilter,
    LimsRowPerPage,
    LimsPagination,
    ModalDeleteDataset,
  },
  created() {
    this.initState();
    this.userType = this.$store.getters['auth/userType'];
  },
  data() {
    return {
      items: [],
      totalItem: null,
      query: null,
    };
  },
  computed: {
    fields() {
      return datasetQuery.filters.fields;
    },
    defaultValues() {
      return datasetQuery.filters.defaultValues;
    },
    isAdminView() {
      return this.userType === this.USER_TYPES().Administrator;
    },
    isClinicAssociate() {
      return this.userType === this.USER_TYPES().ClinicAssociate;
    },
  },
  methods: {
    // common methods
    initState() {
      const queryParams = this.$route.query;
      const filters = buildFilterFromQuery(datasetQuery.filters, queryParams);
      const pagination = buildFilterFromQuery(datasetQuery.pagination, {
        pageSize: this.$appConfig.itemPerPageDefault,
        ...queryParams,
      });
      const sort = buildFilterFromQuery(datasetQuery.sort, queryParams);
      const query = { filters, pagination, sort };
      this.query = query;
    },
    async search(queryObject) {
      const { error, data } = await datasetService.getDatasetList({
        filters: buildFilterQueryParams(queryObject.filters),
        pagination: buildFilterQueryParams(queryObject.pagination),
        sort: null,
      });
      // detect error and show alert to user
      if (error) {
        this.$alertError(error);
        // remove loading screen and allow user to interact
        return;
      }
      // binding data
      this.items = data.items;
      this.totalItem = data.pagination.totalItems;
      // update router
      const query = {
        ...buildFilterQueryParams(queryObject.filters),
        ...buildFilterQueryParams(queryObject.pagination),
        //...buildFilterQueryParams(queryObject.sort),
      };

      this.$router
        .push({
          query,
        })
        .catch((error) => {
          if (
            error.name === 'NavigationDuplicated' &&
            error.message.includes('Avoided redundant navigation to current location')
          ) {
            history.pushState({}, '', new URL(new URL(window.location)));
          }
        });
    },
    onResetFilters() {
      this.query.filters = cloneDeep(datasetQuery.filters.defaultValues);
    },
    onAddDataset() {
      this.$router.push(APP_ROUTES.DATASET_ADD);
    },
    onRowAction({ action, item }) {
      if (action === FORM_ACTIONS.EDIT) {
        this.editRow(item);
      }
      if (action === FORM_ACTIONS.VIEW) {
        this.viewRow(item);
      }
      if (action === FORM_ACTIONS.DELETE) {
        this.deleteRow(item);
      }
    },
    editRow(item) {
      this.$router.push(`${APP_ROUTES.DATASET_EDIT}/${item.caseDatasetId}`);
    },
    viewRow(item) {
      this.$router.push(`${APP_ROUTES.DATASET_VIEW}/${item.caseDatasetId}`);
    },
    deleteRow(item) {
      this.$refs.deleteDatasetModal.setDatasetData(item.caseDatasetId, item.title);
      this.$refs.deleteDatasetModal.open();
    },
    async onDeleteDataset(deleteDatasetData) {
      const status = await this.$onDeleteDataset(deleteDatasetData);
      if (status) {
        await this.search(this.query);
      }
    },
  },
};
</script>
<style lang="scss"></style>
