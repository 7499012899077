<template>
  <div>
    <multiselect
      :options="groupOptions"
      v-model="model"
      :multiple="true"
      :show-labels="false"
      :group-values="itemName"
      :group-label="groupName"
      :placeholder="placeholder"
      track-by="value"
      label="label"
      open-direction="bottom"
      :hide-selected="true"
      :group-select="false"
    >
      <span class="selection-count" slot="selection" slot-scope="{ values, isOpen }">
        <template v-if="!isOpen && values.length">
          {{ placeholder }}
        </template>
      </span>
      <span slot="noResult">Sorry, no matching options.</span>
    </multiselect>
  </div>
</template>

<script>
import { isEqual } from 'lodash';
export default {
  props: {
    options: {
      type: Array,
      required: false,
      default: function () {
        return [];
      },
    },
    groupOptions: {
      type: Array,
      required: false,
      default: function () {
        return [];
      },
    },
    value: {
      type: Array,
      required: false,
      default: function () {
        return [];
      },
    },
    itemName: {
      type: String,
      required: true,
    },
    groupName: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: true,
    },
  },
  computed: {},
  watch: {
    value: {
      handler: function (value) {
        const options = this.options.filter((o) => value.includes(o.value));
        // if different do update
        if (!isEqual(this.model, options)) {
          this.model = options;
        }
      },
      deep: true,
    },
    model: {
      handler: function () {
        const value = this.model.map((o) => o.value);
        if (!isEqual(value, this.value)) {
          this.$emit('input', value);
        }
      },
      deep: true,
    },
  },
  data: function () {
    return {
      model: [],
    };
  },
  methods: {},
};
</script>
<style lang="scss" scoped></style>
