<template>
  <md-dialog :md-close-on-esc="true" :md-active.sync="isVisible" @md-clicked-outside="close" class="lims-modal">
    <div class="dialog-icon-close" @click="close"><md-icon>close</md-icon></div>
    <md-dialog-title>{{ $t('entities/modals/ModalChooseVersionSendEmail/chooseAVersion') }}</md-dialog-title>
    <md-dialog-content>
      <p style="padding-top: 20px; font-weight: 400">
        {{ $t('entities/modals/ModalChooseVersionSendEmail/selectReportVersion') }}
      </p>
      <div style="padding-left: 20px">
        <md-checkbox class="lims-checkbox" v-model="version" value="redacted">
          <span style="font-weight: 400">
            {{ $t('entities/modals/ModalChooseVersionSendEmail/redacted') }}</span
          ></md-checkbox
        >
        <md-checkbox class="lims-checkbox" v-model="version" value="nonRedacted">
          <span style="font-weight: 400">
            {{ $t('entities/modals/ModalChooseVersionSendEmail/nonRedacted') }}</span
          ></md-checkbox
        >
      </div>
    </md-dialog-content>
    <md-dialog-actions>
      <md-button @click="onCancel" class="lims-form-button">{{ $t('global/button/button.cancel') }}</md-button>
      <md-button @click="onContinue" :disabled="!version" class="lims-form-button md-primary">{{
        $t('global/button/button.continue')
      }}</md-button>
    </md-dialog-actions>
  </md-dialog>
</template>

<script>
import modalMixins from '@/components/Lims/modals/modal.mixins';

export default {
  mixins: [modalMixins],
  data() {
    return {
      version: null,
    };
  },
  methods: {
    onCancel() {
      this.version = null;
      this.close();
    },
    onContinue() {
      this.$emit('onSelectedVersion', this.version == 'redacted');
      this.version = null;
    },
  },
};
</script>

<style lang="scss"></style>
