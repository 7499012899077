<template>
  <div class="md-layout text-center" v-if="isReady">
    <div class="md-layout-item md-size-33 md-medium-size-50 md-small-size-70 md-xsmall-size-100">
      <ValidationObserver ref="form" v-slot="{ handleSubmit }">
        <form class="md-layout lims-form form-check-password-strength" @submit.prevent="handleSubmit()">
          <lims-reset-password-card header-color="primary">
            <h4 slot="title" class="title">{{ $t('pages/auth/resetPassword/title.resetPassword') }}</h4>
            <modal-redirecting-to-login
              slot="title"
              ref="redirectingToLoginModal"
              :message="$t('pages/auth/resetPassword/message.redirectingToLogin')"
            >
            </modal-redirecting-to-login>
            <div slot="new-password" class="complete-your-account-password">
              <lims-field
                :model="formData"
                :schema="passwordSchema.information"
                field="newPassword"
                class="input-title"
              >
                <md-input
                  slot="field"
                  @keyup.enter="onClickResetPassword"
                  v-model="formData.newPassword"
                  type="password"
                  maxlength="250"
                  autocomplete="off"
                ></md-input>
              </lims-field>
            </div>
            <div slot="password-strength" class="password-strength-label">
              {{ $t('page/auth/ResetPassword/passwordStrengthLabel') }}
            </div>
            <div slot="check-password-strength" class="check-password-strength-group">
              <lims-password-strength :password-string="formData.newPassword"></lims-password-strength>
            </div>
            <div slot="confirm-password" class="complete-your-account-password">
              <lims-field
                :model="formData"
                :schema="passwordSchema.information"
                field="confirmPassword"
                class="input-title"
              >
                <md-input
                  slot="field"
                  @keyup.enter="onClickResetPassword"
                  v-model="formData.confirmPassword"
                  type="password"
                  maxlength="250"
                  autocomplete="confirm-password"
                ></md-input>
              </lims-field>
            </div>
            <p slot="actions">
              <md-button
                @click="onClickResetPassword"
                slot="actions"
                class="md-primary"
                :disabled="!formData.newPassword || !formData.confirmPassword"
              >
                {{ $t('pages/auth/resetPassword/button.resetPassword') }}
              </md-button>
            </p>
            <p slot="back-to-login">
              <label class="text-underline" @click="onClickBackToLogin">{{
                $t('pages/auth/resetPassword/label.backToLogin')
              }}</label>
            </p>
          </lims-reset-password-card>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import LimsField from '@/components/Lims/LimsField';
import { LimsResetPasswordCard } from '@/components/Lims/Cards';
import { mapActions, mapState } from 'vuex';
import { APP_ROUTES } from '@/core/constants';
import LimsPasswordStrength from '@/components/Lims/LimsPasswordStrength';
import { AuthService } from '@/services';
import { getPasswordSchema } from '@/schemas/password.schema';
import ModalRedirectingToLogin from '@/components/Lims/modals/ModalRedirectingToLogin.vue';

export default {
  components: {
    LimsPasswordStrength,
    LimsResetPasswordCard,
    LimsField,
    ModalRedirectingToLogin,
  },

  props: {
    userId: {
      type: String,
      require: true,
      default: null,
    },
    token: {
      type: String,
      require: true,
      default: null,
    },
  },

  created() {
    this.fetchData();
    this.CHECK_USER_COOKIE();
  },

  data: function () {
    return {
      formData: {
        newPassword: null,
        confirmPassword: null,
      },
      isReady: false,
    };
  },

  computed: {
    ...mapState('auth', {
      isLogged: (state) => state.isLogged,
    }),

    passwordSchema() {
      return getPasswordSchema(this.formData.newPassword);
    },
  },

  watch: {
    isLogged: {
      handler: function (isLogged) {
        if (isLogged) {
          const redirectTo = this.redirectTo || APP_ROUTES.DASHBOARD;
          this.$router.push(redirectTo);
        }
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions('auth', ['CHECK_USER_COOKIE']),

    async fetchData() {
      await this.checkLinkResetPasswordExpired();
      this.isReady = true;
    },

    async checkLinkResetPasswordExpired() {
      const res = await AuthService.resetPasswordCheckLinkExpired({
        userId: this.userId,
        token: this.token,
      });
      if (res.err) {
        this.$router.push(APP_ROUTES.AUTH_LOGIN);
        this.$alertError(this.$t(`pages/auth/resetPassword/error.linkResetPasswordExpired`));
      }
    },

    onClickResetPassword() {
      this.$refs.form.validate().then(async (success) => {
        if (success) {
          try {
            const res = await AuthService.resetPassword({
              userId: this.userId,
              token: this.token,
              newPassword: this.formData.newPassword,
            });
            if (res.errors && res.errors.length > 0) {
              this.$alertError(this.$t(`global/errors/message`));
              return this.$refs.form.setErrors(this.$transformErrors(res.errors));
            }
            if (res.err) {
              return this.$alertError(res.err + ' error');
            }
            //redirect to login page
            this.$refs.redirectingToLoginModal.open();
          } catch (errors) {
            this.$alertError(errors);
          }
        } else {
          this.$alertError(this.$t(`global/errors/message`));
        }
      });
    },

    onClickBackToLogin() {
      this.$router.push(APP_ROUTES.AUTH_LOGIN);
    },
  },
};
</script>

<style lang="scss" scoped></style>
