<template>
  <lims-block class="c-block01">
    <h4 class="title" slot="blockTitle">
      {{ $t('pages/entity/components/workStream/workStream') }}
    </h4>
    <div slot="blockContent">
      <div class="md-layout">
        <div class="md-layout-item md-size-100">
          <lims-form-error :failed="failed" :error="$t('pages/MyEntity/alertMessage/atLeastOneWorkStream')" />
        </div>
      </div>
      <div class="md-layout">
        <div class="md-layout-item md-size-33 md-small-size-100">
          <label class="required">{{ $t('pages/entity/components/workStream/workStream') }}</label>
          <md-field :class="[{ 'md-error': failed }]">
            <v-select
              v-model="workStreamId"
              :options="workStreamList"
              label="fieldItemName"
              :disabled="disabled"
              :placeholder="$t('pages/entity/components/workStream/placeholder.selectAWorkStream')"
              tabenable="yes"
              :tabindex="10"
            >
              <template #option="{ fieldItemName }">{{ fieldItemName }}</template>
              <template #selected-option="{ fieldItemName }">{{ fieldItemName }}</template>
            </v-select>
          </md-field>
        </div>
        <div class="md-layout-item md-size-33 md-small-size-100">
          <md-button
            type="button"
            class="lims-form-button block01-btn md-primary"
            :disabled="!workStreamId || disabled"
            @click="addWorkStream"
          >
            {{ $t('pages/entity/components/workStream/button.addWorkStream') }}
          </md-button>
        </div>
      </div>
      <div class="md-layout lims-form-row">
        <md-table class="custom-tbl" md-card>
          <md-table-row>
            <md-table-head>
              {{ $t('pages/entity/components/workStream/column.workStream') }}
            </md-table-head>
            <md-table-head class="th-center cell-action">
              {{ $t('global/pages/list.actions') }}
            </md-table-head>
          </md-table-row>
          <md-table-row v-for="(item, idx) in workStreamDataList" :key="`${item.value}-${idx}`">
            <md-table-cell class="cell-clinicType">{{ item.fieldItemName }}</md-table-cell>
            <md-table-cell class="cell-action">
              <span @click="!disabled ? delWorkStream(item) : ''"><md-icon>close</md-icon></span>
            </md-table-cell>
          </md-table-row>
        </md-table>
      </div>
      <div class="md-layout lims-form-row">
        <div class="md-layout-item md-size-50 md-small-size-100">
          <commonly-used-specimen-type
            v-model="formData.commonlyUsedSpecimenTypes"
            :specimenTypeList="specimenTypeList"
            :work-stream-item-add="workStreamItemAdd"
            :work-stream-item-delete="workStreamItemDelete"
            :work-stream-list="workStreamDataList"
            :disabled="disabled"
          ></commonly-used-specimen-type>
        </div>
      </div>
    </div>
  </lims-block>
</template>
<script>
import dropdownService from '@/services/dropdown.service';
import { DROPDOWN_SHORT_NAME } from '@/core/constants';
import CommonlyUsedSpecimenType from '@/pages/Entity/Components/CommonlyUsedSpecimenType';

export default {
  components: { CommonlyUsedSpecimenType },
  created() {
    this.fetchData();
  },
  props: {
    failed: {
      type: Boolean,
      require: false,
      default: false,
    },
    value: {
      type: Array,
      require: true,
    },
    disabled: {
      type: Boolean,
      require: false,
      default: false,
    },
  },
  data() {
    return {
      formData: {},
      workStreamId: null,
      workStreamList: [],
      workStreamInputList: [],
      workStreamDataList: [],
      workStreamItemAdd: {},
      workStreamItemDelete: {},
      specimenTypeList: [],
    };
  },
  watch: {
    workStreamDataList: {
      handler(val) {
        this.$emit('input', val);
      },
      deep: true,
    },
    value() {
      if (this.value) {
        this.workStreamDataList = this.value;
        this.resetWorkStreamList();
      }
    },
  },
  methods: {
    async fetchData() {
      this.workStreamInputList = await this.getWorkStreamList();
      this.workStreamList = this.workStreamInputList;
      this.specimenTypeList = await dropdownService.getDropdownByShortNameTemporary([
        DROPDOWN_SHORT_NAME.SPECIMEN_TYPE_PER_WORK_STREAM,
      ]);
      this.specimenTypeList = this.specimenTypeList.data;
    },
    async getWorkStreamList() {
      const workStreamList = await dropdownService.getDropdownByShortNames([DROPDOWN_SHORT_NAME.WORK_STREAM]);
      return workStreamList.WorkStream;
    },
    addWorkStream() {
      const dataAdd = {
        fieldItemName: this.workStreamId.fieldItemName,
        fieldItemId: this.workStreamId.fieldItemId,
        fieldId: this.workStreamId.fieldId,
        billingCode: this.workStreamId.billingCode,
      };
      this.workStreamDataList.push(dataAdd);
      this.workStreamItemAdd = dataAdd;
      this.resetWorkStreamList();
    },
    resetWorkStreamList() {
      let listFieldItemIdOfWorkStreamList = [];
      this.workStreamDataList.forEach((workStreamData) =>
        listFieldItemIdOfWorkStreamList.push(workStreamData.fieldItemId),
      );
      this.workStreamList = this.workStreamInputList.filter(
        (workStreamData) => !listFieldItemIdOfWorkStreamList.includes(workStreamData.fieldItemId),
      );
      this.workStreamId = null;
    },
    delWorkStream(item) {
      this.workStreamDataList = this.workStreamDataList.filter(
        (workStreamData) => workStreamData.fieldItemId !== item.fieldItemId,
      );
      this.workStreamItemDelete = item;
      this.resetWorkStreamList();
    },
  },
};
</script>
