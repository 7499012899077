<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit }">
    <form class="md-layout lims-form form-login" @submit.prevent="handleSubmit()">
      <md-dialog :md-close-on-esc="true" :md-active.sync="isVisible" @md-clicked-outside="close" class="lims-modal">
        <div class="dialog-icon-close" @click="close"><md-icon>close</md-icon></div>
        <md-dialog-title>{{ $t('components/lims/modals/ModalConfirmation.title') }}</md-dialog-title>
        <div class="lims-form dialog-content">
          <p
            class="text-center"
            v-html="
              $t('components/ModalBulkActionSlide.text', {
                action: bulkActionSelect ? bulkActionSelect.label : '',
              })
            "
          ></p>
          <ul class="list-cases">
            <li v-for="(item, key) in slides" :key="`valid-${item.slide.fieldItemId}-${key}`">
              {{ getItemDetail(item) }}
            </li>
          </ul>
          <p
            v-show="isAddTrackingNo"
            style="text-align: center"
            v-html="
              $t('components/ModalBulkActionSlide.text.forLocationFlag', {
                locationFlag: locationFlag,
                date: date,
              })
            "
          ></p>
          <lims-field v-show="isAddTrackingNo" class="text-field" :model="formData" :schema="schema" field="trackingNo">
            <md-input slot="field" v-model="formData.trackingNo" maxlength="120"></md-input>
          </lims-field>
          <lims-field
            v-show="isAddTrackingNo"
            class="textarea-field"
            :model="formData"
            :schema="schema"
            field="comment"
          >
            <md-textarea slot="field" v-model="formData.comment"></md-textarea>
          </lims-field>
          <lims-field
            v-show="!isAddTrackingNo"
            class="text-field"
            :model="formData"
            :schema="schema"
            field="locationFlagComment"
          >
            <md-input slot="field" v-model="formData.comment"></md-input>
          </lims-field>
          <lims-field
            v-show="!isAddTrackingNo"
            class="textarea-field"
            :model="formData"
            :schema="schema"
            field="tracking"
          >
            <md-input slot="field" v-model="formData.trackingNo" maxlength="120"></md-input>
          </lims-field>
          <lims-field
            v-show="!isAddTrackingNo"
            class="textarea-field"
            :model="formData"
            :schema="schema"
            field="trackingComment"
          >
            <md-textarea slot="field" v-model="formData.trackingComment"></md-textarea>
          </lims-field>
        </div>
        <md-dialog-actions>
          <md-button @click="close" class="lims-form-button">{{ $t('global/button/button.cancel') }}</md-button>
          <md-button @click="onSubmitAction" :disabled="isProcessing" class="lims-form-button md-primary">{{
            $t('global/button/button.completeAction')
          }}</md-button>
        </md-dialog-actions>
      </md-dialog>
    </form>
  </ValidationObserver>
</template>

<script>
import { caseSlideService } from '@/services';
import modalMixins from './modal.mixins';
import cloneDeep from 'lodash/cloneDeep';
import { LOCATION_BULK_ACTIONS } from '@/core/constants';
import slideMixins from '@/pages/Case/SlideManagement/slide.mixins';
import { formatDateWithoutTime } from '@/core/helpers';

const transformSlideData = (slides) => {
  return slides.map((item) => {
    return {
      fieldItemId: item.slide.fieldItemId,
      caseSpecimenBlockId: item.block.id,
      caseAlwRequestId: item.caseAlwRequestId ? item.caseAlwRequestId : null,
      caseSpecimenId: item.caseSpecimenId,
      slideTypeName: item.slideTypeName,
      caseSpecimenBlockItemReferenceId: item.caseSpecimenBlockItemReferenceId
        ? item.caseSpecimenBlockItemReferenceId
        : item.slide?.caseSpecimenBlockItemReferenceId,
    };
  });
};

export default {
  name: 'ModalBulkActionSlide',
  mixins: [modalMixins, slideMixins],
  props: {
    bulkActionSelect: {
      require: true,
    },
  },
  data() {
    return {
      formData: {},
      slides: [],
      locationFlag: '',
      date: null,
    };
  },
  computed: {
    schema() {
      return {
        entity: 'modals/ModalBulkActionSlide',
        fields: {
          comment: '',
          locationFlagComment: '',
          tracking: '',
          trackingComment: '',
          trackingNo: this.isAddTrackingNo ? 'required' : '',
        },
      };
    },
    isAddTrackingNo() {
      return this.bulkActionSelect.value === LOCATION_BULK_ACTIONS.AddTrackingNo;
    },
  },
  methods: {
    getItemDetail(item) {
      const block = typeof item.block === 'object' ? item.block.text : item.block;
      const slideName = item.slide ? item.slide.fieldItemName : '';
      return `${item.caseReference}, ${block}, ${slideName}`;
    },
    setSlides(slides, filterValue) {
      this.slides = cloneDeep(slides);
      const filter = cloneDeep(filterValue);
      this.locationFlag = filter?.locationFlag;
      this.date = formatDateWithoutTime(filter?.date?.substring(0, 10));
    },
    async onSubmitAction() {
      this.$refs.form.validate().then(async (success) => {
        if (success) {
          this.isProcessing = true;
          try {
            let res = null;
            const slides = this.slides;
            const { comment, trackingNo, trackingComment } = this.formData;
            const action = this.bulkActionSelect.value;
            switch (action) {
              case LOCATION_BULK_ACTIONS.SendToPath:
              case LOCATION_BULK_ACTIONS.ReturnToLab:
              case LOCATION_BULK_ACTIONS.ReturnToClinic:
              case LOCATION_BULK_ACTIONS.SetToExternal:
                res = await this.changeLocation({
                  slides,
                  action,
                  comment,
                  trackingNo,
                  trackingComment,
                });
                break;
              case LOCATION_BULK_ACTIONS.AddTrackingNo:
                res = await this.addTrackingNo({
                  slides,
                  trackingNo,
                  comment,
                });
                break;
              default:
                break;
            }
            this.isProcessing = false;
            if (!res) {
              return this.$alertError(`${action} was not implemented!`);
            }
            // if error
            if (res.err) {
              return this.$alertError(res.err + ' error');
            }
            // otherwise show notifcation
            this.$emit('onCompletedAction', { action });
            this.close();
          } catch (errors) {
            this.isProcessing = false;
            this.$alertError(errors);
          }
        } else {
          this.isProcessing = false;
          this.$alertError(this.$t(`global/errors/message`));
        }
      });
    },
    async addTrackingNo({ slides, comment, trackingNo }) {
      return await caseSlideService.addTrackingNo({
        slides: transformSlideData(slides),
        comment,
        trackingNo,
      });
    },
    async changeLocation({ slides, action, comment, trackingNo, trackingComment }) {
      const locationFlag = this.convertActionToLocationFlag(action);

      if (locationFlag) {
        const dataCheck = {
          slides: transformSlideData(slides),
          locationFlag,
          comment,
          trackingNo,
          trackingComment,
        };
        return await caseSlideService.changeLocationFlag(dataCheck);
      }
      // throw error
      this.$alertError(`Can not find approriate locationFlag for ${action}!`);
      return null;
    },
    close() {
      this.isVisible = false;
      this.formData.comment = '';
      this.formData.trackingNo = '';
      this.formData.trackingComment = '';
    },
  },
};
</script>

<style lang="scss" scoped>
.list-cases {
  display: table;
  margin: 10px auto;
}
</style>
