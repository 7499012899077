<template>
  <md-button class="lims-form-button md-primary" @click="onClick">
    {{ $t('global/button/button.edit') }}
  </md-button>
</template>

<script>
export default {
  props: {
    editUrl: {
      type: String,
      required: true,
    },
  },
  data: () => ({}),
  methods: {
    onClick() {
      this.$router.push({ path: this.editUrl });
    },
  },
};
</script>
<style lang="scss" scoped>
.model-cancle-user-form {
  display: inline-flex;
}
</style>
