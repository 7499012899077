// Layouts
const DashboardLayout = () => import('@/layouts/DashboardLayout.vue');
// User pages
const Role = () => import('@/pages/Account/Role/Role.vue');
const RoleAdd = () => import('@/pages/Account/Role/Add/Add.vue');
const RoleEdit = () => import('@/pages/Account/Role/Edit/Edit.vue');

import guards from './guards';

export default {
  path: '/roles',
  component: DashboardLayout,
  beforeEnter: guards.checkAuthorizedGuard,
  children: [
    {
      path: '',
      name: 'RoleManagement',
      component: Role,
      beforeEnter: guards.checkAuthorizedGuard,
      meta: {
        zone: ['Accounts_RoleManagement_Retrieve'],
      },
    },
    {
      path: 'add/:userType',
      name: 'RoleManagementAdd',
      component: RoleAdd,
      props: true,
      beforeEnter: guards.checkAuthorizedGuard,
      meta: {
        zone: ['Accounts_RoleManagement_Create'],
      },
    },
    {
      path: 'edit/:roleId',
      name: 'RoleManagementEdit',
      component: RoleEdit,
      props: true,
      beforeEnter: guards.checkAuthorizedGuard,
      meta: {
        zone: ['Accounts_RoleManagement_Update'],
      },
    },
    {
      path: 'view/:roleId',
      name: 'RoleManagementView',
      component: RoleEdit,
      props: (route) => ({
        isViewMode: true,
        roleId: route.params.roleId,
      }),
      beforeEnter: guards.checkAuthorizedGuard,
      meta: {
        zone: ['Accounts_RoleManagement_Retrieve'],
      },
    },
  ],
};
