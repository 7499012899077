<template>
  <md-dialog :md-close-on-esc="true" :md-active.sync="isVisible" @md-clicked-outside="close" class="lims-modal">
    <div class="dialog-icon-close" @click="close"><md-icon>close</md-icon></div>
    <md-dialog-title>{{ $t('components/lims/modals/ModalDeleteCase.title') }}</md-dialog-title>
    <div class="dialog-content lims-form">
      <p class="text-center">{{ $t('components/lims/modals/ModalDeleteCase.actionCannotBeUndone') }}</p>
      <p class="text-center">{{ $t('components/lims/modals/ModalDeleteAlwRequest.provideReason') }}</p>
      <template v-if="isVisible && formData.itemsAlwRequests.length > 0">
        <ul class="list-of-compatible-cases">
          <li v-for="item in formData.itemsAlwRequests" :key="item.caseAlwRequestId">
            {{ item.caseReference }}, {{ item.block }}, {{ item.stain }}, {{ item.typeName }}
          </li>
        </ul>
      </template>
      <lims-field class="textarea-field" :model="formData" :schema="schema" field="comment">
        <md-textarea slot="field" v-model="formData.comment"></md-textarea>
      </lims-field>
    </div>
    <md-dialog-actions>
      <md-button class="lims-form-button" @click="close">
        {{ $t('global/button/button.cancel') }}
      </md-button>
      <md-button
        @click="onCancelRequest()"
        class="md-danger lims-form-button"
        :disabled="!formData.comment || !formData.comment.replace(/\s/g, '').length || isProcessing"
      >
        {{ $t('global/button/button.cancelRequest') }}
      </md-button>
    </md-dialog-actions>
  </md-dialog>
</template>

<script>
import modalMixins from './modal.mixins';
import { caseAlwService } from '@/services';
import { mapActions } from 'vuex';
import { APP_EVENTS, newAppEvent } from '@/core/constants';

export default {
  name: 'ModalDeleteAlwRequest',
  mixins: [modalMixins],
  data() {
    return {
      isVisible: false,
      formData: {
        comment: '',
        itemsAlwRequests: [
          {
            caseAlwRequestId: null,
            caseReference: '',
            block: '',
            stain: '',
            typeName: '',
          },
        ],
        invalidItemAlwRequests: [],
      },
    };
  },
  computed: {
    schema() {
      return {
        entity: 'modals/ModalDeleteAlwRequest',
        fields: {
          comment: 'required',
        },
      };
    },
  },
  methods: {
    ...mapActions('app/event', ['addEvent']),
    async open(data) {
      this.isVisible = true;
      if (data) {
        this.formData = {
          ...data,
        };
      }
    },
    async onCancelRequest() {
      this.isProcessing = true;
      const dataCheck = {
        caseAlwRequests: this.formData.itemsAlwRequests.map((item) => item.caseAlwRequestId),
        comment: this.formData.comment,
      };
      const res = await caseAlwService.cancelAlwRequest(dataCheck);
      this.isProcessing = false;
      if (res.err) {
        return this.$alertError(res.err + ' error');
      } else {
        this.addEvent(newAppEvent(APP_EVENTS.EVT_ON_ALW_FORM_ON_REFRESH, {}));
        this.close();
        return this.$alertSuccess(
          this.$t('pages/case/CaseManagement/components/AdditionalLabWork/alert/updateSuccessfully'),
        );
      }
    },
  },
};
</script>

<style></style>
