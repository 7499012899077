<template>
  <ValidationObserver ref="formCaseBatchChange" v-slot="{ handleSubmit, invalid }">
    <md-dialog :md-close-on-esc="true" :md-active.sync="isVisible" @md-clicked-outside="close" class="lims-modal">
      <div class="dialog-icon-close" @click="close"><md-icon>close</md-icon></div>
      <md-dialog-title>{{ $t('components/ModalCaseBatchChange.modalTitle') }}</md-dialog-title>
      <form class="md-layout lims-form" @submit.prevent="handleSubmit()">
        <lims-block class="block-not-title">
          <modal-batch-change-specimen-type-problem
            ref="batchChangeSpecimenTypeProblemModal"
            slot="blockTitle"
            @onCancel="onCancelBatchChangeSpecimenTypeProblemModal"
          ></modal-batch-change-specimen-type-problem>
          <div slot="blockContent">
            <div class="md-layout lims-form-row">
              <div class="md-layout-item md-size-100 md-small-size-100">
                <lims-field :model="formData" :schema="schema" field="fieldSelector">
                  <v-select
                    slot="field-outside"
                    v-model="formData.fieldSelector"
                    :options="fieldSelector"
                    :placeholder="$t('components/ModalCaseBatchChange.placeholderFieldSelector')"
                    @input="fieldSelectorVal"
                  >
                    <template #option="{ label }">
                      {{ $t(`entities/modals/ModalCaseBatchChange/${label}`) }}
                    </template>
                    <template #selected-option="{ label }">
                      {{ $t(`entities/modals/ModalCaseBatchChange/${label}`) }}
                    </template>
                  </v-select>
                </lims-field>
              </div>
            </div>

            <div class="md-layout lims-form-row">
              <div
                v-if="formData.fieldSelector && formData.fieldSelector.text == 'Clinic'"
                class="md-layout-item md-size-100 md-small-size-100"
              >
                <lims-field :model="formData" :schema="schema" field="Clinic">
                  <v-select
                    slot="field-outside"
                    v-model="formData.Clinic"
                    :options="clinicList"
                    :placeholder="$t('components/ModalCaseBatchChange.placeholderClinic')"
                  >
                    <template #option="{ label }">{{ label }}</template>
                    <template #selected-option="{ label }">{{ label }}</template>
                  </v-select>
                </lims-field>
              </div>
              <div
                v-if="formData.fieldSelector && formData.fieldSelector.text == 'Clinician'"
                class="md-layout-item md-size-100 md-small-size-100"
              >
                <lims-field :model="formData" :schema="schema" field="Clinician">
                  <v-select
                    slot="field-outside"
                    v-model="formData.Clinician"
                    :options="clinicianList"
                    :placeholder="$t('components/ModalCaseBatchChange.placeholderClinician')"
                  >
                    <template #option="{ label }">{{ label }}</template>
                    <template #selected-option="{ label }">{{ label }}</template>
                  </v-select>
                </lims-field>
              </div>
              <div
                v-if="formData.fieldSelector && formData.fieldSelector.text == 'Comment'"
                class="md-layout-item md-size-100 md-small-size-100"
              >
                <lims-field :model="formData" :schema="schema" field="Comment" class="textarea-field">
                  <md-textarea slot="field" v-model="formData.Comment" type="text"> </md-textarea>
                </lims-field>
              </div>
              <div
                v-if="formData.fieldSelector && formData.fieldSelector.text == 'Laboratory'"
                class="md-layout-item md-size-100 md-small-size-100"
              >
                <lims-field :model="formData" :schema="schema" field="Laboratory">
                  <v-select
                    slot="field-outside"
                    v-model="formData.Laboratory"
                    :options="laboratoryList"
                    :placeholder="$t('components/ModalCaseBatchChange.placeholderLaboratory')"
                  >
                    <template #option="{ label }">{{ label }}</template>
                    <template #selected-option="{ label }">{{ label }}</template>
                  </v-select>
                </lims-field>
              </div>
              <div
                v-if="formData.fieldSelector && formData.fieldSelector.text == 'Pathologist'"
                class="md-layout-item md-size-100 md-small-size-100"
              >
                <lims-field :model="formData" :schema="schema" field="Pathologist">
                  <v-select
                    slot="field-outside"
                    v-model="formData.Pathologist"
                    :options="pathologistList"
                    :placeholder="$t('components/ModalCaseBatchChange.placeholderPathologist')"
                  >
                    <template #option="{ label }">{{ label }}</template>
                    <template #selected-option="{ label }">{{ label }}</template>
                  </v-select>
                </lims-field>
              </div>
              <div
                v-if="formData.fieldSelector && formData.fieldSelector.text == 'FirstSpecimenType'"
                class="md-layout-item md-size-100 md-small-size-100"
              >
                <lims-field :model="formData" :schema="schema" field="FirstSpecimenType">
                  <multiselect
                    slot="field-outside"
                    v-model="formData.FirstSpecimenType"
                    :options="specimenTypeList"
                    :multiple="false"
                    :show-labels="false"
                    group-values="items"
                    group-label="itemName"
                    :placeholder="$t('components/ModalCaseBatchChange.placeholderSpecimenType')"
                    track-by="fieldItemId"
                    label="fieldItemName"
                    :hide-selected="false"
                    :group-select="false"
                  >
                  </multiselect>
                </lims-field>
              </div>
              <div
                v-if="formData.fieldSelector && formData.fieldSelector.text == 'Technician1'"
                class="md-layout-item md-size-100 md-small-size-100"
              >
                <lims-field :model="formData" :schema="schema" field="Technician1">
                  <v-select
                    slot="field-outside"
                    v-model="formData.Technician1"
                    :options="labTechList1"
                    :placeholder="$t('components/ModalCaseBatchChange.placeholderLabTech')"
                  >
                    <template #option="{ label }">{{ label }}</template>
                    <template #selected-option="{ label }">{{ label }}</template>
                  </v-select>
                </lims-field>
              </div>
              <div
                v-if="formData.fieldSelector && formData.fieldSelector.text == 'Technician2'"
                class="md-layout-item md-size-100 md-small-size-100"
              >
                <lims-field :model="formData" :schema="schema" field="Technician2">
                  <v-select
                    slot="field-outside"
                    v-model="formData.Technician2"
                    :options="labTechList2"
                    :placeholder="$t('components/ModalCaseBatchChange.placeholderLabTech')"
                  >
                    <template #option="{ label }">{{ label }}</template>
                    <template #selected-option="{ label }">{{ label }}</template>
                  </v-select>
                </lims-field>
              </div>
              <div
                v-if="formData.fieldSelector && formData.fieldSelector.text == 'BillingEntity'"
                class="md-layout-item md-size-100 md-small-size-100"
              >
                <lims-field :model="formData" :schema="schema" field="BillingEntity">
                  <v-select
                    slot="field-outside"
                    v-model="formData.BillingEntity"
                    :options="billingEntityList"
                    :placeholder="$t('components/ModalCaseBatchChange.placeholderBillingEntity')"
                  >
                    <template #option="{ label }">{{ label }}</template>
                    <template #selected-option="{ label }">{{ label }}</template>
                  </v-select>
                </lims-field>
              </div>
              <div
                v-if="formData.fieldSelector && formData.fieldSelector.text == 'WorkStream'"
                class="md-layout-item md-size-100 md-small-size-100"
              >
                <lims-field :model="formData" :schema="schema" field="WorkStream">
                  <v-select
                    slot="field-outside"
                    v-model="formData.WorkStream"
                    :options="workStreamList"
                    :placeholder="$t('components/ModalCaseBatchChange.placeholderWorkStream')"
                  >
                    <template #option="{ label }">{{ label }}</template>
                    <template #selected-option="{ label }">{{ label }}</template>
                  </v-select>
                </lims-field>
              </div>
              <div
                v-if="formData.fieldSelector && formData.fieldSelector.text == 'Tracking'"
                class="md-layout-item md-size-100 md-small-size-100"
              >
                <lims-field :model="formData" :schema="schema" field="Tracking">
                  <md-input slot="field" v-model="formData.Tracking" type="text"> </md-input>
                </lims-field>
              </div>
              <div
                v-if="formData.fieldSelector && formData.fieldSelector.text == 'LabEntryDate'"
                class="md-layout-item md-size-100 md-small-size-100"
              >
                <lims-field :model="formData" :schema="schema" field="LabEntryDate">
                  <date-picker
                    slot="field"
                    v-model="formData.LabEntryDate"
                    :lang="{
                      formatLocale: {
                        firstDayOfWeek: 1,
                      },
                      monthBeforeYear: false,
                    }"
                    type="datetime"
                    format="DD/MM/YYYY HH:mm:ss"
                    :time-picker-options="{ start: '00:00', step: '00:10', end: '23:50' }"
                  ></date-picker>
                </lims-field>
              </div>
            </div>
          </div>
        </lims-block>

        <md-dialog-actions>
          <md-button @click="close" class="lims-form-button">{{ $t('global/button/button.cancel') }}</md-button>
          <md-button @click="onContinueStep" :disabled="invalid || isProcessing" class="lims-form-button md-primary">{{
            $t('global/button/button.continue')
          }}</md-button>
        </md-dialog-actions>
      </form>
    </md-dialog>
  </ValidationObserver>
</template>

<script>
import { caseListService, DropdownService } from '@/services';
import modalMixins from './modal.mixins';
import { CASE_BATCH_CHANGE_FIELD_ENUM, CASE_LIST_DROPDOWN, DROPDOWN_SHORT_NAME } from '@/core/constants';
import { convertDateTimeToUTCFormat, filterDropdownListByHiddenField } from '@/core/helpers';
import ModalBatchChangeSpecimenTypeProblem from '@/components/Lims/modals/ModalBatchChangeSpecimenTypeProblem.vue';

export default {
  name: 'ModalCaseBatchChange',
  mixins: [modalMixins],
  components: {
    ModalBatchChangeSpecimenTypeProblem,
  },
  props: {
    bulkActionSelect: {
      require: true,
    },
  },
  async created() {
    this.fetchData();
  },

  data() {
    return {
      masterCheckBoxData: null,
      formData: {
        fieldSelector: '',
        Clinic: '',
        Laboratory: '',
        Tracking: '',
        Clinician: '',
        Pathologist: '',
        Technician1: '',
        Technician2: '',
        BillingEntity: '',
        WorkStream: '',
        FirstSpecimenType: '',
        Comment: '',
      },
      clinicList: [],
      laboratoryList: [],
      clinicianList: [],
      pathologistList: [],
      labTechList1: [],
      labTechList2: [],
      billingEntityList: [],
      specimenTypeList: [],
      specimenTypeOptions: [],
      workStreamList: [],
      fieldSelector: [],
    };
  },
  computed: {
    schema() {
      return {
        entity: 'modals/ModalCaseBatchChange',
        fields: {
          fieldSelector: 'required',
          Clinic: 'required',
          Laboratory: 'required',
          Tracking: 'required',
          Clinician: 'required',
          Pathologist: 'required',
          Technician1: 'required',
          Technician2: 'required',
          BillingEntity: 'required',
          FirstSpecimenType: 'required',
          Comment: 'required',
          WorkStream: 'required',
          LabEntryDate: 'required',
        },
      };
    },
  },
  methods: {
    setMasterCheckBoxData(masterCheckBoxData) {
      this.masterCheckBoxData = masterCheckBoxData;
    },
    async fetchData() {
      const dropdownOptions = await DropdownService.getDropdownByShortNames([
        DROPDOWN_SHORT_NAME.WORK_STREAM,
        DROPDOWN_SHORT_NAME.BILLING_ENTITY,
      ]);
      const specimenTypeListReq = await DropdownService.getDropdownByShortNameTemporary([
        DROPDOWN_SHORT_NAME.SPECIMEN_TYPE_PER_WORK_STREAM,
      ]);
      const specimenTypeList = specimenTypeListReq.data || [];
      const dropdownCaseList = await caseListService.getDropdown([
        CASE_LIST_DROPDOWN.BATCH_CHANGE_FIELD,
        CASE_LIST_DROPDOWN.CLINIC,
        CASE_LIST_DROPDOWN.CLINICIAN,
        CASE_LIST_DROPDOWN.PATHOLOGIST_WITH_PULL,
        CASE_LIST_DROPDOWN.LABORATORY,
        CASE_LIST_DROPDOWN.TECHNICIAN1,
        CASE_LIST_DROPDOWN.TECHNICIAN2,
      ]);

      this.fieldSelector = dropdownCaseList[CASE_LIST_DROPDOWN.BATCH_CHANGE_FIELD];
      this.clinicList = dropdownCaseList[CASE_LIST_DROPDOWN.CLINIC];
      this.laboratoryList = dropdownCaseList[CASE_LIST_DROPDOWN.LABORATORY];
      this.clinicianList = dropdownCaseList[CASE_LIST_DROPDOWN.CLINICIAN];
      this.pathologistList = dropdownCaseList[CASE_LIST_DROPDOWN.PATHOLOGIST_WITH_PULL];
      this.labTechList1 = dropdownCaseList[CASE_LIST_DROPDOWN.TECHNICIAN1];
      this.labTechList2 = dropdownCaseList[CASE_LIST_DROPDOWN.TECHNICIAN2];
      this.billingEntityList = dropdownOptions[DROPDOWN_SHORT_NAME.BILLING_ENTITY];
      this.workStreamList = filterDropdownListByHiddenField(dropdownOptions[CASE_LIST_DROPDOWN.WORK_STREAM]);
      const { groupOptions, options } = this.mappingSpecimenTypeList(specimenTypeList);
      this.$set(this, 'specimenTypeOptions', options);
      this.$set(this, 'specimenTypeList', groupOptions);
    },

    mappingSpecimenTypeList(specimenTypeList) {
      const groupOptions = [];
      const options = [];
      specimenTypeList.forEach((specimenTypeItem) => {
        const { itemName, itemId, items, isHide } = specimenTypeItem;
        if (!isHide) {
          groupOptions.push({
            itemName,
            itemId,
            items: filterDropdownListByHiddenField(items),
          });
          options.push([...items]);
        }
      });
      return { groupOptions, options };
    },

    async fieldSelectorVal(val) {
      // TODO: mastercheckbox
      if (val && val.text == 'FirstSpecimenType') {
        const payload = this.masterCheckBoxData;
        const { error, data } = await caseListService.checkWorkStreamBatchChangeSpecimenType(payload);
        if (error) {
          return this.$alertError(error);
        }
        if (data && !data.isSameWorkStream) {
          this.$refs.batchChangeSpecimenTypeProblemModal.open();
        } else {
          if (data.workStreamId) {
            const specimenTypeListByWorkStreamId = this.specimenTypeList.filter(
              (item) => item.itemId == data.workStreamId,
            );
            this.$set(this, 'specimenTypeList', specimenTypeListByWorkStreamId);
          }
        }
      }
    },

    onCancelBatchChangeSpecimenTypeProblemModal() {
      this.close();
    },

    async onContinueStep() {
      this.$refs.formCaseBatchChange.validate().then(async (success) => {
        if (success) {
          this.isProcessing = true;
          let fieldValueData;
          if (this.formData.fieldSelector && this.formData.fieldSelector.text == 'FirstSpecimenType') {
            fieldValueData = this.formData[this.formData.fieldSelector.text].fieldItemId;
          } else {
            fieldValueData = this.formData[this.formData.fieldSelector.text].value
              ? this.formData[this.formData.fieldSelector.text].value
              : this.formData[this.formData.fieldSelector.text];
          }

          const dataForm = {
            isForcedUpdate: false,
            fieldType: this.formData.fieldSelector.id,
            fieldValue: fieldValueData,
          };
          const batchChangeSelect = {
            fieldName: this.formData.fieldSelector.text,
            fieldValue: this.formData[this.formData.fieldSelector.text].text,
          };
          if (this.formData.fieldSelector.id === CASE_BATCH_CHANGE_FIELD_ENUM.LabEntryDate) {
            // API required this for parsing date value
            Reflect.set(dataForm, 'date', convertDateTimeToUTCFormat(dataForm.fieldValue));
          }
          const { error, data } = await caseListService.updateBatchCases({ ...this.masterCheckBoxData, ...dataForm });
          this.isProcessing = false;
          if (error) {
            return this.$alertError(error);
          }
          if (data) {
            this.$emit('onContinueBatchChange', {
              data,
              dataForm,
              batchChangeSelect,
              masterCheckBoxData: this.masterCheckBoxData,
            });
            this.close();
          }
        }
      });
    },
    close() {
      this.isVisible = false;
      this.$nextTick(() => {
        this.formData = {
          selectOption: '',
        };
      });
    },
  },
};
</script>

<style lang="scss"></style>
