<template>
  <div class="custom-tel-input">
    <md-field class="tel-input-wrapper">
      <v-select
        class="select-country"
        :disabled="disabled"
        v-model="formData.countryCodeMobile"
        label="name"
        :clearable="false"
        :options="countryCodeMobileList"
        tabenable="yes"
        :tabindex="1"
      >
        <template #option="{ name, code, locale }">
          <img class="img-flag" :src="require(`@/assets/images/flags/${locale}.png`)" /> <strong>{{ name }}</strong>
          {{ code }}
        </template>
        <template #selected-option="{ locale }">
          <template v-if="locale">
            <img class="img-flag" :src="require(`@/assets/images/flags/${locale}.png`)" />
          </template>
          <template v-else>{{ locale }} </template>
        </template>
        <template #open-indicator="{ attributes }">
          <span v-bind="attributes"><md-icon>arrow_drop_down</md-icon></span>
        </template>
      </v-select>
      <div class="content-wrapper">
        <span>{{ countryCodeInHtml }}</span>
        <md-input
          :disabled="disabled"
          @change="onChange"
          maxlength="50"
          v-model="formData.telephoneMobile"
          :placeholder="!countryCodeInHtml ? $t('global/placeholder.telephone') : ''"
          tabenable="yes"
        ></md-input>
      </div>
    </md-field>
  </div>
</template>
<script>
import countryService from '@/services/country.service';
import debounce from 'lodash/debounce';
import validators from '@/schemas/validators';

export default {
  created: function () {
    this.fetch();
  },
  props: {
    value: {
      type: Object,
      require: true,
    },
    disabled: {
      type: Boolean,
      require: false,
      default: null,
    },
  },
  computed: {},
  data() {
    return {
      formData: {
        countryCodeMobile: {},
        telephoneMobile: '',
      },
      countryCodeInHtml: '',
      countryCodeMobileList: [],
      ready: false,
    };
  },
  watch: {
    formData: {
      handler(val) {
        this.$emit('input', val);
        this.onChange();
      },
      deep: true,
    },
    value: {
      handler(val) {
        this.formData = val;
        this.formatData();
      },
      deep: true,
    },
  },
  methods: {
    async onChange() {
      const isValid = await validators.validMobilePhoneCheck(this.formData);
      if (isValid) {
        this.emitChanges(this.formData, isValid);
      }
    },
    emitChanges: debounce(function (val, isValid) {
      if (val.countryCodeMobile && val.telephoneMobile && val.telephoneMobile.length > 0 && isValid) {
        this.$emit('phoneNumberChange', val);
      }
    }, 500),
    async fetch() {
      await this.countryCodeMobile();
      this.formData = this.value;
      this.formatData();
    },
    async countryCodeMobile() {
      const { data } = await countryService.findCountrySupportSms();
      this.countryCodeMobileList = data;
      return this.countryCodeMobileList;
    },
    formatData() {
      if (this.formData.countryCodeMobile && typeof this.formData.countryCodeMobile != 'object') {
        const countryCodeMobile = this.countryCodeMobileList.find(
          (item) => item.countryId == this.formData.countryIdMobile,
        );
        if (countryCodeMobile) {
          this.formData.countryCodeMobile = { ...countryCodeMobile };
        }
      }
      this.countryCodeInHtml =
        this.formData.countryCodeMobile && this.formData.countryCodeMobile.code
          ? '+' + this.formData.countryCodeMobile.code
          : '';
      this.ready = true;
    },
  },
};
</script>
<style lang="scss">
.custom-tel-input {
  width: 100%;
  margin: 10px 0;
  .tel-input-wrapper {
    display: flex;
  }
  .content-wrapper {
    width: 100%;
    height: 36px;
    display: flex;
    align-items: center;
    padding-left: 5px;
    border: 1px solid #ccc;
    border-left: unset;
  }
  .md-disabled .content-wrapper {
    background: rgb(248 248 248);
  }

  .v-select.select-country {
    width: 70px;
    margin: 0;
    background: #cccccc40;
    .vs__search,
    .vs__search:focus {
      background-color: #f2f2f2;
    }
    .vs__dropdown-toggle {
      width: 70px;
      height: 36px;
      border: 1px solid #ccc;
      border-right: unset;
      border-radius: unset;
      .vs__open-indicator {
        margin-top: -2px;
      }
    }
    .vs__dropdown-menu {
      margin: 3px 0 0 -1px;
      border-top: 1px solid #ccc;
      min-width: 220px;
      position: absolute;
      top: 35px;
    }
    .vs__selected {
      padding-top: 0;
      margin-top: 4px;
    }
    .img-flag {
      width: auto;
      height: 20px;
      margin-right: 5px;
    }
  }
  .md-field {
    .md-input {
      height: 34px;
      padding-left: 10px;
      width: inherit;
      margin: 0 !important;
      border: unset !important;
      padding: 0 5px;
    }
    .md-count {
      bottom: -20px;
    }
  }
}
</style>
