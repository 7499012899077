<template>
  <lims-block v-if="isClinicAssociateView" :cardText="false" class="list-page">
    <div class="md-layout" slot="blockContent">
      <div class="md-layout-item" v-if="query">
        <my-entity-profile-list :entities="items" @action="onAction" :sort="query.sort">
          <md-table-toolbar slot="toolbar">
            <div class="toolbar-row">
              <div class="field-rows-per-page">
                <lims-row-per-page :pageSize="query.pagination.pageSize" @pageSizeChanged="$pageSizeChanged" />
              </div>
              <div class="field-search">
                <my-entity-profile-list-filter
                  v-model="query.filters"
                  :defaultValues="defaultValues"
                  :fields="fields"
                  @onResetFilters="onResetFilters"
                >
                </my-entity-profile-list-filter>
              </div>
            </div>
            <lims-pagination ref="pager" :total-item="totalItem" :pagination="query.pagination" />
          </md-table-toolbar>
        </my-entity-profile-list>
        <lims-pagination :total-item="totalItem" :pagination="query.pagination" />
      </div>
    </div>
  </lims-block>
</template>

<script>
import LimsRowPerPage from '@/components/Lims/LimsRowPerPage';
import MyEntityProfileListFilter from '@/pages/MyEntity/Profile/List/MyEntityProfileListFilter';
import MyEntityProfileList from '@/pages/MyEntity/Profile/List/MyEntityProfileList';
import LimsPagination from '@/components/Lims/LimsPagination';
import { ConfigMixins, ListWithFilterMixins } from '@/core/mixins';
import { APP_ROUTES, FORM_ACTIONS } from '@/core/constants';
import cloneDeep from 'lodash/cloneDeep';
import { buildFilterFromQuery, buildFilterQueryParams } from '@/query/utils';
import { myEntityProfileQuery } from '@/query';
import myEntityService from '@/services/myEntity.service';
import { mapState } from 'vuex';

export default {
  name: 'LimsEntity',
  components: {
    LimsRowPerPage,
    MyEntityProfileListFilter,
    MyEntityProfileList,
    LimsPagination,
  },
  mixins: [ConfigMixins, ListWithFilterMixins],
  created() {
    this.userType = this.$store.getters['auth/userType'];
    if (this.isLabTechnicianView) {
      const entityId = this.user.entityId;
      if (this.$isAuthorized(['MyEntity_EntityInformation_Update'])) {
        this.$router.push(`${APP_ROUTES.MYENTITY_PROFILE_EDIT}/${entityId}`);
      } else {
        this.$router.push(`${APP_ROUTES.MYENTITY_PROFILE_VIEW}/${entityId}`);
      }
    } else {
      this.initState();
    }
  },
  data() {
    return {
      items: [],
      totalItem: null,
      query: null,
    };
  },
  computed: {
    ...mapState('auth', {
      user: (state) => state.user,
    }),
    fields() {
      return myEntityProfileQuery.filters.fields;
    },
    defaultValues() {
      return myEntityProfileQuery.filters.defaultValues;
    },
    isLabTechnicianView() {
      return this.userType == this.USER_TYPES().LabTechnician;
    },
    isClinicAssociateView() {
      return this.userType == this.USER_TYPES().ClinicAssociate;
    },
  },
  methods: {
    // common methods
    initState() {
      const queryParams = this.$route.query;
      const filters = buildFilterFromQuery(myEntityProfileQuery.filters, queryParams);
      const pagination = buildFilterFromQuery(myEntityProfileQuery.pagination, {
        pageSize: this.$appConfig.itemPerPageDefault,
        ...queryParams,
      });
      const sort = buildFilterFromQuery(myEntityProfileQuery.sort, queryParams);
      const query = { filters, pagination, sort };
      this.query = query;
    },
    async search(queryObject) {
      const { error, data } = await myEntityService.search({
        filters: buildFilterQueryParams(queryObject.filters),
        pagination: buildFilterQueryParams(queryObject.pagination),
        sort: `${queryObject.sort.orderField}:${queryObject.sort.orderBy}`,
      });
      // detect error and show alert to user
      if (error) {
        this.$alertError(error);
        // remove loading screen and allow user to interact
        return;
      }
      // binding data
      this.items = data.items;
      this.totalItem = data.pagination.totalItems;

      // update router
      const query = {
        ...buildFilterQueryParams(queryObject.filters),
        ...buildFilterQueryParams(queryObject.pagination),
        ...buildFilterQueryParams(queryObject.sort),
      };

      this.$router
        .push({
          query,
        })
        .catch((error) => {
          if (
            error.name === 'NavigationDuplicated' &&
            error.message.includes('Avoided redundant navigation to current location')
          ) {
            history.pushState({}, '', new URL(new URL(window.location)));
          }
        });
    },

    onResetFilters() {
      this.query.filters = cloneDeep(myEntityProfileQuery.filters.defaultValues);
    },

    onAction({ action, item }) {
      if (action === FORM_ACTIONS.EDIT) {
        this.$router.push(`${APP_ROUTES.MYENTITY_PROFILE_EDIT}/${item.entityId}`);
      }
      if (action === FORM_ACTIONS.VIEW) {
        this.$router.push(`${APP_ROUTES.MYENTITY_PROFILE_VIEW}/${item.entityId}`);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
