<template>
  <div class="chart-container">
    <div class="chart-card-wrapper">
      <chart-card
        header-animation="false"
        :chart-data="data"
        :chart-options="getChartOptions()"
        :chart-responsive-options="getResponsiveOptions()"
        chart-type="Bar"
        chart-inside-header
        no-footer
        background-color="main"
      >
        <template slot="content">
          <h4 class="title">
            <md-icon>people</md-icon><span>{{ $t('pages/Dashboard/Components/NewUsersPerMonthChart.title') }}</span>
          </h4>
          <div class="category">
            <md-icon>schedule</md-icon><span>{{ $t('pages/Dashboard/Components/NewUsersPerMonthChart.time') }}</span>
          </div>
        </template>
      </chart-card>
    </div>
  </div>
</template>

<script>
import ChartsMixins from './charts.mixins';
export default {
  props: {
    data: {},
  },
  mixins: [ChartsMixins],
};
</script>

<style></style>
