<template>
  <lims-block :cardText="false" class="list-page">
    <div class="md-layout" slot="blockContent">
      <div class="md-layout-item" v-if="query">
        <import-history-list :importHistoryList="items">
          <md-table-toolbar slot="toolbar">
            <div class="toolbar-row">
              <div class="field-rows-per-page">
                <lims-row-per-page :pageSize="query.pagination.pageSize" @pageSizeChanged="$pageSizeChanged" />
              </div>
              <div class="field-search">
                <import-history-list-filter
                  v-model="query.filters"
                  :defaultValues="defaultValues"
                  :fields="fields"
                  @onResetFilters="onResetFilters"
                ></import-history-list-filter>
              </div>
            </div>
            <lims-pagination ref="pager" :total-item="totalItem" :pagination="query.pagination"></lims-pagination>
          </md-table-toolbar>
        </import-history-list>
        <lims-pagination :total-item="totalItem" :pagination="query.pagination"></lims-pagination>
      </div>
    </div>
  </lims-block>
</template>

<script>
import { ConfigMixins, ListWithFilterMixins } from '@/core/mixins';
import ImportHistoryList from '@/pages/Case/ImportHistory/List/ImportHistoryList.vue';
import ImportHistoryListFilter from '@/pages/Case/ImportHistory/List/ImportHistoryListFilter.vue';
import LimsRowPerPage from '@/components/Lims/LimsRowPerPage';
import LimsPagination from '@/components/Lims/LimsPagination';

import { importHistoryQuery } from '@/query';
import { buildFilterFromQuery, buildFilterQueryParams } from '@/query/utils';
import { isDeepEqual } from '@/core/helpers';
import cloneDeep from 'lodash/cloneDeep';
import { caseImportService } from '@/services';

export default {
  name: 'ImportHistoryPage',
  mixins: [ConfigMixins, ListWithFilterMixins],
  components: {
    ImportHistoryList,
    ImportHistoryListFilter,
    LimsRowPerPage,
    LimsPagination,
  },
  created() {
    this.initState();
  },
  data() {
    return {
      items: [],
      totalItem: null,
      query: null,
    };
  },
  computed: {
    fields() {
      return importHistoryQuery.filters.fields;
    },
    defaultValues() {
      return importHistoryQuery.filters.defaultValues;
    },
  },
  methods: {
    // common methods
    initState() {
      const queryParams = this.$route.query;
      const filters = buildFilterFromQuery(importHistoryQuery.filters, queryParams);
      const pagination = buildFilterFromQuery(importHistoryQuery.pagination, {
        pageSize: this.$appConfig.itemPerPageDefault,
        ...queryParams,
      });
      const sort = buildFilterFromQuery(importHistoryQuery.sort, queryParams);
      const query = { filters, pagination, sort };
      this.query = query;
    },

    async search(queryObject) {
      if (
        queryObject &&
        queryObject.filters.importDate &&
        (queryObject.filters.importDate[0] == null) & (queryObject.filters.importDate[1] == null)
      ) {
        queryObject.filters.importDate = null;
      }
      const { error, data } = await caseImportService.getImportCaseHistory({
        filters: buildFilterQueryParams(queryObject.filters),
        pagination: buildFilterQueryParams(queryObject.pagination),
      });

      // detect error and show alert to user
      if (error) {
        this.$alertError(error);
        return;
      }
      // binding data
      this.items = data.imports.items;
      this.totalItem = data.imports.pagination.totalItems;

      // update router
      const query = {
        ...buildFilterQueryParams(queryObject.filters),
        ...buildFilterQueryParams(queryObject.pagination),
      };

      if (!isDeepEqual(query, this.$route.query)) {
        this.$router
          .push({
            query,
          })
          .catch((error) => {
            if (
              error.name === 'NavigationDuplicated' &&
              error.message.includes('Avoided redundant navigation to current location')
            ) {
              history.pushState({}, '', new URL(new URL(window.location)));
            }
          });
      }
    },

    onResetFilters() {
      this.query.filters = cloneDeep(importHistoryQuery.filters.defaultValues);
    },
  },
};
</script>

<style lang="scss"></style>
