<template>
  <lims-block :cardText="false" class="list-page">
    <modal-select-user-types
      ref="userTypesModal"
      slot="blockTitle"
      @onSelect="onSelectUserType"
    ></modal-select-user-types>
    <div class="md-layout" slot="blockContent">
      <div class="md-layout-item" v-if="query">
        <account-user-list @action="onRowAction" :users="items" :sort="query.sort">
          <md-table-toolbar slot="toolbar">
            <div class="toolbar-row">
              <md-field class="field-btn">
                <md-button
                  v-if="$isAuthorized(['Accounts_UserManagement_Create', 'MyEntity_UserManagement_Create'])"
                  @click="onAddUser"
                  class="btn-addUser"
                  >{{ $t('global/button/button.addUser') }}</md-button
                >
              </md-field>
            </div>
            <div class="toolbar-row">
              <div class="field-rows-per-page">
                <lims-row-per-page :pageSize="query.pagination.pageSize" @pageSizeChanged="$pageSizeChanged" />
              </div>
              <div class="field-search">
                <UserListFilter
                  v-model="query.filters"
                  :defaultValues="defaultValues"
                  :fields="fields"
                  @onResetFilters="onResetFilters"
                />
              </div>
            </div>
            <lims-pagination ref="pager" :total-item="totalItem" :pagination="query.pagination"> </lims-pagination>
          </md-table-toolbar>
        </account-user-list>
        <lims-pagination :total-item="totalItem" :pagination="query.pagination"> </lims-pagination>
      </div>
    </div>
  </lims-block>
</template>

<script>
import AccountUserList from './List/UserList.vue';
import { ModalSelectUserTypes } from '@/components/Lims/modals';
import LimsRowPerPage from '@/components/Lims/LimsRowPerPage';
import UserListFilter from '@/pages/Account/User/List/UserListFilter.vue';
import LimsPagination from '@/components/Lims/LimsPagination';

import { ConfigMixins, ListWithFilterMixins } from '@/core/mixins';

import cloneDeep from 'lodash/cloneDeep';
import { APP_ROUTES, FORM_ACTIONS, USER_TYPE_VALUES } from '@/core/constants';
import { buildFilterFromQuery, buildFilterQueryParams } from '@/query/utils';

import { userQuery } from '@/query';
import { UserService } from '@/services';
import { isDeepEqual } from '@/core/helpers';

export default {
  name: 'AccountUserPage',
  components: {
    AccountUserList,
    ModalSelectUserTypes,
    LimsRowPerPage,
    UserListFilter,
    LimsPagination,
  },
  mixins: [ConfigMixins, ListWithFilterMixins],
  created() {
    this.initState();
  },
  data() {
    return {
      items: [],
      totalItem: null,
      query: null,
    };
  },
  computed: {
    fields() {
      return userQuery.filters.fields;
    },
    defaultValues() {
      return userQuery.filters.defaultValues;
    },
  },
  watch: {},
  methods: {
    // common methods
    initState() {
      const queryParams = this.$route.query;
      const filters = buildFilterFromQuery(userQuery.filters, queryParams);
      const pagination = buildFilterFromQuery(userQuery.pagination, {
        pageSize: this.$appConfig.itemPerPageDefault,
        ...queryParams,
      });
      const sort = buildFilterFromQuery(userQuery.sort, queryParams);
      const query = { filters, pagination, sort };
      this.query = query;
    },

    async search(queryObject) {
      const { error, data } = await UserService.search({
        filters: buildFilterQueryParams(queryObject.filters),
        pagination: buildFilterQueryParams(queryObject.pagination),
        sort: `${queryObject.sort.orderField}:${queryObject.sort.orderBy}`,
      });
      // detect error and show alert to user
      if (error) {
        this.$alertError(error);
        // remove loading screen and allow user to interact
        return;
      }

      // binding data
      this.items = data.items;
      this.totalItem = data.pagination.totalItems;

      // update router
      const query = {
        ...buildFilterQueryParams(queryObject.filters),
        ...buildFilterQueryParams(queryObject.pagination),
        ...buildFilterQueryParams(queryObject.sort),
      };

      if (!isDeepEqual(query, this.$route.query)) {
        this.$router
          .push({
            query,
          })
          .catch((error) => {
            if (
              error.name === 'NavigationDuplicated' &&
              error.message.includes('Avoided redundant navigation to current location')
            ) {
              history.pushState({}, '', new URL(new URL(window.location)));
            }
          });
      }
    },
    onResetFilters() {
      this.query.filters = cloneDeep(userQuery.filters.defaultValues);
    },
    // event handles - start with on prefix
    onAddUser() {
      this.$refs.userTypesModal.open();
    },
    onSelectUserType(formData) {
      // navigate
      if (formData.selectOption) {
        this.$router.push({
          name: 'UserManagementAdd',
          params: {
            userType: USER_TYPE_VALUES[formData.selectOption.value - 1],
          },
        });

        this.$refs.userTypesModal.close();
      }
    },
    onRowAction({ action, item }) {
      if (action === FORM_ACTIONS.EDIT) {
        this.editRow(item);
      }
      if (action === FORM_ACTIONS.VIEW) {
        this.viewRow(item);
      }
      if (action === FORM_ACTIONS.RESET_PASSWORD) {
        this.resetPassword(item);
      }
    },
    // methods
    editRow(item) {
      this.$router.push(`${APP_ROUTES.USER_EDIT}/${item.userId}`);
    },
    viewRow(item) {
      this.$router.push(`${APP_ROUTES.USER_VIEW}/${item.userId}`);
    },
    async resetPassword(item) {
      const userId = item.userId;
      const res = await UserService.userResetPassword({ userId });
      if (res.err) {
        return this.$alertError(res.error);
      } else {
        if (!item.isCompleteAccount) {
          this.$alertSuccess(
            this.$t('entities/entity/status/resetPassword/isNotCompleteAccount.success', {
              userType: item.userTypeName,
              firstName: item.firstname,
              lastName: item.lastname,
            }),
          );
        } else {
          this.$alertSuccess(
            this.$t('entities/entity/status/resetPassword.success', {
              userType: item.userTypeName,
              firstName: item.firstname,
              lastName: item.lastname,
            }),
          );
        }
      }
    },
  },
};
</script>

<style lang="scss">
.field-btn.md-field:not(.md-disabled):after {
  background-color: transparent !important;
}
.toolbar-row {
  width: 100%;
  margin-bottom: 10px;
  .field-rows-per-page {
    float: left;
    padding: 10px 0 10px;
  }
  .field-search {
    float: right;
    padding: 10px 0 10px;
    @media only screen and (max-width: 767px) {
      float: none;
      width: 100%;
      display: inline-block;
    }
  }
  .field-btn {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
