<template>
  <div>
    <modal-confirm-generic
      ref="unSaveChangesModal"
      :description="$t('components/lims/modals/ModalFormCancel.text')"
      @onConfirm="onUnSaveChanges"
    />
    <policy-form :formMode="formMode"></policy-form>
  </div>
</template>

<script>
import PolicyForm from '../Forms/PolicyForm.vue';
import { UnSaveChangesMixins } from '@/core/mixins';

export default {
  name: 'LimsPolicyAdd',
  mixins: [UnSaveChangesMixins],
  components: {
    PolicyForm,
  },
  props: {
    formMode: {
      type: Number,
      required: true,
    },
  },
  computed: {},
  data: function () {
    return {};
  },
};
</script>

<style lang="scss" scoped></style>
