import httpClient from './httpClient';

const RESOURCE = 'notifications';
export default {
  getNotificationsSettingsByUserType(userTypeId) {
    return httpClient.get(`/${RESOURCE}/settings/usertypes/${userTypeId}`);
  },
  getNotificationSettingByUser(userId) {
    return httpClient.get(`/${RESOURCE}/settings/users/${userId}`);
  },
  getNotificationSettingByRole(roleId) {
    return httpClient.get(`/${RESOURCE}/settings/userroles/${roleId}`);
  },
  getLastUnNotifiedMessages() {
    return httpClient.get(`/${RESOURCE}/last-un-notified-messages`);
  },
  setLastNotifiedMessages(notificationId) {
    return httpClient.post(`/${RESOURCE}/last-notified-messages/${notificationId}`);
  },
  search({ filters, pagination, sort, userId }) {
    const { search } = filters;
    const { pageNumber, pageSize } = pagination;
    const userQueryParams = {};
    if (search) {
      userQueryParams['filters.Search'] = search;
    }
    if (pageNumber) {
      userQueryParams['PageNumber'] = pageNumber;
    }
    if (pageSize) {
      userQueryParams['PageSize'] = pageSize;
    }
    if (sort) {
      userQueryParams['Sort'] = sort;
    }
    return httpClient.get(`/${RESOURCE}/users/${userId}`, userQueryParams);
  },
  updateNotification(data) {
    return httpClient.post(`/${RESOURCE}/bulk-update`, data);
  },
};
