<template>
    <md-dialog :md-close-on-esc="true" :md-active.sync="isVisible" @md-clicked-outside="close" class="lims-modal">
      <div class="dialog-icon-close" @click="close"><md-icon>close</md-icon></div>
      <md-dialog-title>{{ title }}</md-dialog-title>
      <div class="lims-form dialog-content">
        <p class="text-center">{{ description }}</p>
      </div>
      <md-dialog-actions>
        <md-button @click="onCancel" class="lims-form-button">{{ $t('global/button/button.cancel') }}</md-button>
        <md-button @click="onClick" class="lims-form-button md-primary">{{
          $t('global/button/button.confirm')
        }}</md-button>
      </md-dialog-actions>
    </md-dialog>
  </template>
  
  <script>
  import modalMixins from '@/components/Lims/modals/modal.mixins';
  
  export default {
    name: 'modal-review-case-confirm',
    mixins: [modalMixins],
    props: {
      title: {
        type: String,
        require: true,
      },
      description: {
        type: String,
        require: false,
        default: '',
      },
    },
    data(){
        return {
            action: null,
        }
    },
    methods: {
      openWithAction(action){
        this.action = action;
        this.open();
      },
      onClick() {
        this.close();
        this.$emit('onConfirm', {action: this.action});
        this.action = null;
      },
      onCancel() {
        this.close();
        this.$emit('onCancel');
      },
    },
  };
  </script>
  
  <style lang="scss"></style>
  