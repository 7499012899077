<template>
  <div class="lims-block-wrapper md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
    <md-card>
      <md-card-header class="md-card-header-text" v-if="visibleHeader">
        <div :class="{ 'card-text': cardText }">
          <slot name="blockTitle"></slot>
        </div>
        <div class="card-info"><slot name="blockTitle-info"></slot></div>
      </md-card-header>
      <md-card-content>
        <slot name="blockContent"></slot>
      </md-card-content>
    </md-card>
  </div>
</template>
<script>
export default {
  props: {
    cardText: {
      type: Boolean,
      require: false,
      default: true,
    },
    visibleHeader: {
      type: Boolean,
      require: false,
      default: true,
    },
  },
};
</script>
<style lang=""></style>
