import { DROPDOWN_SHORT_NAME, PATHOLOGIST_TYPE_ID } from '@/core/constants';
import dropdownService from './dropdown.service';
import httpClient from './httpClient';

const RESOURCE = 'cases';

export default {
  loadDropDownDataSource() {
    return dropdownService.getDropdownByShortNames([
      DROPDOWN_SHORT_NAME.COUNTRY,
      DROPDOWN_SHORT_NAME.GENDER_TYPES,
      DROPDOWN_SHORT_NAME.BILLING_ENTITY,
      DROPDOWN_SHORT_NAME.CASE_DOCUMENT_TYPE,
      DROPDOWN_SHORT_NAME.SPECIMEN_TYPE,
      DROPDOWN_SHORT_NAME.INSURANCE_COMPANY,
      DROPDOWN_SHORT_NAME.SNOMED_T,
      DROPDOWN_SHORT_NAME.SNOMED_P,
      DROPDOWN_SHORT_NAME.SNOMED_M,
      DROPDOWN_SHORT_NAME.CaseAWLRequestType,
    ]);
  },
  create(data = {}) {
    // mapping fieldName
    const { labEntryDate, ...payload } = data;
    return httpClient.post(`/${RESOURCE}`, {
      ...payload,
      labEntry: labEntryDate,
    });
  },
  updateOne(data) {
    const { caseId, labEntryDate, ...payload } = data;

    return httpClient.put(`/${RESOURCE}/${caseId}`, {
      ...payload,
      labEntry: labEntryDate,
      labEntryDate,
    });
  },
  findOne(caseId, isAuditLog, anonymiseToken, doubleReportResponseId = null) {
    const params = {
      anonymiseToken,
      isAuditLog,
    };
    if (doubleReportResponseId) {
      params['DoubleReportResponseId'] = doubleReportResponseId;
    }
    return httpClient.get(`/${RESOURCE}/${caseId}`, params);
  },
  updatePatientData(caseId, patientData) {
    const { labEntryDate, ...payload } = patientData;
    return httpClient.put(`/${RESOURCE}/update-patient-data/${caseId}`, {
      ...payload,
      labEntry: labEntryDate,
    });
  },
  updateCaseDocument(caseId, payload) {
    const url = `/${RESOURCE}/update-document/${caseId}`;
    if (payload.rowVersion) {
      return httpClient.put(`${url}?rowVersion=${payload.rowVersion}`, payload.data);
    }
    return httpClient.put(`${url}`, payload.data);
  },
  updateFurtherOptions(caseId, optOut, rowVersion) {
    return httpClient.put(`/${RESOURCE}/update-further-options/${caseId}`, null, {
      rowVersion: rowVersion,
      optOut: optOut,
    });
  },
  updateClinicalIncident(caseId, clinicalIncidentResource) {
    return httpClient.put(`/${RESOURCE}/update-clinical-incident/${caseId}`, clinicalIncidentResource);
  },
  getUsersByUserType({ search, userTypeId, entityId }) {
    const params = {};
    if (search) {
      params['search'] = search;
    }
    if (userTypeId) {
      params['userTypeId'] = userTypeId;
    }
    if (entityId) {
      params['entityId'] = entityId;
    }
    return httpClient.get(`/${RESOURCE}/get-users-by-usertype`, params);
  },
  getEntityByEntityType({ search, entityTypeIds, dependencyEntityId }) {
    const params = {};
    if (search) {
      params['search'] = search;
    }
    if (entityTypeIds) {
      params['entityTypeIds'] = entityTypeIds;
    }
    if (dependencyEntityId) {
      params['dependencyEntityId'] = dependencyEntityId;
    }
    return httpClient.get(`/${RESOURCE}/entities-by-entity-type`, params);
  },
  getPathologistUsers({ specimenTypeId, clinicianId, clinicId, laboratoryId, caseId }) {
    const params = {};
    if (specimenTypeId) {
      params['specimenTypeId'] = specimenTypeId;
    }
    if (clinicianId) {
      params['clinicianId'] = clinicianId;
    }
    if (clinicId) {
      params['clinicId'] = clinicId;
    }
    if (laboratoryId) {
      params['laboratoryId'] = laboratoryId;
    }
    if (caseId) {
      params['caseId'] = caseId;
    }
    return httpClient.get(`/${RESOURCE}/get-pathologist-users`, params);
  },
  getPathologistNote({ pathologistUserId, clinicianId, clinicId }) {
    const params = {};
    if (pathologistUserId) {
      params['pathologistUserId'] = pathologistUserId;
    }
    if (clinicianId) {
      params['clinicianId'] = clinicianId;
    }
    if (clinicId) {
      params['clinicId'] = clinicId;
    }
    return httpClient.get(`/${RESOURCE}/get-pathologist-note`, params);
  },
  updateBillingClinicAndLab(caseId, billingClinicAndLabResource) {
    return httpClient.put(`/${RESOURCE}/update-billing-clinic-lab/${caseId}`, billingClinicAndLabResource);
  },
  updateBillingWorkstream(caseId, data) {
    return httpClient.put(`/${RESOURCE}/update-billing-workstream/${caseId}`, data);
  },
  updateSpecimenDetail(caseId, specimenDetail) {
    return httpClient.put(`/${RESOURCE}/update-specimen/${caseId}`, specimenDetail);
  },
  updatePathologist(caseId, pathologist) {
    const payload = { ...pathologist };
    return httpClient.put(`/${RESOURCE}/update-pathologist/${caseId}`, payload);
  },
  uploadAttachfiles(data = {}) {
    return httpClient.postFileAndData(`/${RESOURCE}/upload-attachfiles`, data);
  },
  reviewCase(caseId) {
    return httpClient.put(`/${RESOURCE}/review/${caseId}`);
  },
  deleteCase(reason, password, caseId) {
    const params = {};
    params['reason'] = reason;
    params['password'] = password;
    return httpClient.deleteWithPayload(`/${RESOURCE}/delete-case/${caseId}`, params);
  },

  restoreCase(caseId, params = {}) {
    return httpClient.deleteWithPayload(`/${RESOURCE}/restore-case/${caseId}`, params);
  },

  resendCase(data) {
    return httpClient.post(`/${RESOURCE}/bulk-resend-report-cases`, data);
  },
  async getStainByEntityId(entityId, shortNames, caseAlwRequestId) {
    const params = {};
    params['shortCodes'] = shortNames.join(',');
    params['entityId'] = entityId;
    params['CaseAlwRequestId'] = caseAlwRequestId;
    const { data, error } = await httpClient.get(`/${RESOURCE}/get-by-shortnames`, params);
    if (error) {
      return {};
    }
    return data;
  },
  async getStainByEntityIdInCaseForm(entityId, shortNames, caseId) {
    const params = {};
    params['shortCodes'] = shortNames.join(',');
    params['entityId'] = entityId;
    params['CaseId'] = caseId;
    const { data, error } = await httpClient.get(`/${RESOURCE}/get-by-shortnames`, params);
    if (error) {
      return {};
    }
    // otherwise mapping
    const dropdownOptions = {};
    shortNames.map((name) => {
      // eslint-disable-next-line security/detect-object-injection

      if (name !== 'Snomed') {
        dropdownOptions[name] = data
          .filter((row) => row.itemName === name)[0]
          .items.map((item) => {
            if (name == DROPDOWN_SHORT_NAME.COUNTRY || name == DROPDOWN_SHORT_NAME.CASE_SPECIMEN_BLOCK) {
              return {
                ...item,
                value: item.id,
                label: item.text,
              };
            }
            return {
              itemName: name,
              value: item.fieldItemId,
              label: item.fieldItemName,
              ...item,
            };
          });
      }
    });
    return dropdownOptions;
  },
  anonymizeRequests(caseId, comment) {
    return httpClient.post(`/${RESOURCE}/${caseId}/anonymise-requests`, {
      comment: comment,
    });
  },
  cancelRequest(caseId) {
    return httpClient.put(`/${RESOURCE}/${caseId}/anonymise-requests/cancel`);
  },
  getSimilarPatient(caseId, tabId) {
    return httpClient.get(`/${RESOURCE}/${caseId}/anonymize-requests/similiar-patient`, {
      anonymiseToken: tabId,
    });
  },
  laboratoryReferenceCheck({ value, caseId }) {
    const params = {};
    params['labReference'] = value;
    if (caseId) {
      params['caseId'] = caseId;
    }

    // {isExisted: false/true}
    return httpClient.get(`/${RESOURCE}/identity-check`, params);
  },

  reassignCase({ caseId, data }) {
    return httpClient.put(`/${RESOURCE}/reassign-case/${caseId}`, data);
  },
  discardTissueCase(caseId, data) {
    return httpClient.put(`/${RESOURCE}/discard-tissue/${caseId}`, null, data);
  },

  reSendReportCases(data) {
    return httpClient.post(`/${RESOURCE}/bulk-resend-report-cases`, data);
  },

  createCaseBySimpleBookingForm(data = {}) {
    if (data.pathologistType === PATHOLOGIST_TYPE_ID.Pull) {
      Reflect.set(data, 'pathologistId', null);
    }
    return httpClient.post(`/${RESOURCE}/simple`, data);
  },

  endBookingCreateCase(data) {
    return httpClient.post(`/${RESOURCE}/end-simple-create`, data);
  },

  getEntitiesByEntityTypeAndUser({ search, entityTypeIds, dependencyEntityId }) {
    const params = {};
    if (search) {
      params['search'] = search;
    }
    if (entityTypeIds) {
      params['entityTypeIds'] = entityTypeIds;
    }
    if (dependencyEntityId) {
      params['dependencyEntityId'] = dependencyEntityId;
    }
    return httpClient.get(`/${RESOURCE}/entities-by-entity-type-and-user`, params);
  },

  getCaseQualityControl(params) {
    return httpClient.get(`/${RESOURCE}/quality-controls`, params);
  },

  updateNumberOfSpecimenChangedState(caseId) {
    return httpClient.put(`/${RESOURCE}/update-number-of-specimen-changed-state/${caseId}`);
  },

  async getNewAlwRequestSlides({ caseId, caseSpecimenBlockIds, entityId, shortNames }) {
    const params = {};
    params['shortCodes'] = shortNames.join(',');
    params['caseId'] = caseId;
    params['caseSpecimenBlockIds'] = caseSpecimenBlockIds;
    params['entityId'] = entityId;
    const { data, error } = await httpClient.get(`/${RESOURCE}/alw-request/new-request-slides`, params);
    if (error) {
      return {};
    }
    return data;
  },

  requestDigitalSlide(caseId) {
    return httpClient.post(`/${RESOURCE}/${caseId}/request-digital-slide`);
  },

  removeRequestDigitalSlideFlag(caseId) {
    return httpClient.post(`/${RESOURCE}/${caseId}/remove-request-digital-slide-flag`);
  },

  retrieveDigitalSlide(caseId) {
    return httpClient.post(`/${RESOURCE}/${caseId}/retrieve-digital-slide`);
  },

  checkSlideInPendingAlwRequest(slideIds, caseId, specimenId, blockId, UpdatingNumberOfSpecimens) {
    const payload = {
      caseId,
    };
    if (UpdatingNumberOfSpecimens) {
      Reflect.set(payload, 'UpdatingNumberOfSpecimens', UpdatingNumberOfSpecimens);
    }
    if (specimenId) {
      Reflect.set(payload, 'caseSpecimenId', specimenId);
    }
    if (slideIds) {
      Reflect.set(payload, 'slideIds', slideIds);
    }
    if (blockId) {
      Reflect.set(payload, 'caseSpecimenBlockId', blockId);
    }
    return httpClient.post(`/${RESOURCE}/${caseId}/check-slides-in-pending-alw-request`, payload);
  },
};
