// Layouts
const DashboardLayout = () => import('@/layouts/DashboardLayout.vue');
// MyEntity's pages
import Profile from '@/pages/MyEntity/Profile/Profile.vue';
import MyEntityProfileEdit from '@/pages/MyEntity/Profile/Edit/MyEntityProfileEdit.vue';
import User from '@/pages/MyEntity/User/User.vue';
import MyEntityUserAdd from '@/pages/MyEntity/User/Add/MyEntityAdd.vue';
import MyEntityUserEdit from '@/pages/MyEntity/User/Edit/MyEntityEdit.vue';

import guards from './guards';
import { FORM_MODES } from '@/core/constants';

export default {
  path: '/my-entity',
  component: DashboardLayout,
  beforeEnter: guards.checkAuthorizedGuard,
  children: [
    {
      path: 'profile',
      name: 'MyEntityProfile',
      component: Profile,
      meta: {
        zone: ['MyEntity_EntityInformation_Retrieve'],
      },
    },
    {
      path: 'profile/edit/:entityId',
      name: 'MyEntityProfileEdit',
      component: MyEntityProfileEdit,
      props: (route) => ({
        formMode: FORM_MODES.EDIT,
        entityId: route.params.entityId,
      }),
      meta: {
        zone: ['MyEntity_EntityInformation_Update'],
      },
    },
    {
      path: 'profile/view/:entityId',
      name: 'MyEntityProfileView',
      component: MyEntityProfileEdit,
      props: (route) => ({
        formMode: FORM_MODES.VIEW,
        entityId: route.params.entityId,
      }),
      meta: {
        zone: ['MyEntity_EntityInformation_Retrieve'],
      },
    },
    {
      path: 'users',
      name: 'MyEntityUserManagement',
      component: User,
      meta: {
        zone: ['MyEntity_UserManagement_Retrieve'],
      },
    },
    {
      path: 'users/add/:userType',
      name: 'MyEntityUserManagementAdd',
      component: MyEntityUserAdd,
      props: true,
      meta: {
        zone: ['MyEntity_UserManagement_Create'],
      },
    },
    {
      path: 'users/edit/:userId',
      name: 'MyEntityUserManagementEdit',
      component: MyEntityUserEdit,
      props: true,
      meta: {
        zone: ['MyEntity_UserManagement_Update'],
      },
    },
    {
      path: 'users/view/:userId',
      name: 'MyEntityUserManagementView',
      component: MyEntityUserEdit,
      props: (route) => ({
        isView: true,
        userId: route.params.userId,
      }),
      meta: {
        zone: ['MyEntity_UserManagement_Retrieve'],
      },
    },
  ],
};
