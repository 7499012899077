<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit, invalid }">
    <form class="md-layout lims-form form-login" @submit.prevent="handleSubmit()">
      <md-dialog :md-close-on-esc="true" :md-active.sync="isVisible" @md-clicked-outside="close" class="lims-modal">
        <div class="dialog-icon-close" @click="close"><md-icon>close</md-icon></div>
        <md-dialog-title>{{ $t('components/lims/modals/ModalDiscardTissue.title') }}</md-dialog-title>
        <div class="lims-form dialog-content">
          <p
            class="text-center"
            v-html="
              $t('components/ModalBulkActionCommom.text', {
                bulkAction: $translateBulkActionSelect(bulkActionSelect),
              })
            "
          ></p>
          <template>
            <ul class="list-of-compatible-cases">
              <li v-for="item in compatibleCase" :key="item.caseId">{{ item.caseReference }}</li>
            </ul>
          </template>

          <form class="md-layout lims-form">
            <div>
              <div class="md-layout lims-form-row">
                <div class="md-layout-item md-size-50 md-small-size-100">
                  <md-checkbox v-model="formData.tissueDiscard" class="lims-checkbox checkbox-tissueDiscard">
                    {{ $t('components/lims/modals/ModalDiscardTissue/label.tissueDiscard') }}
                  </md-checkbox>
                </div>
                <div class="md-layout-item md-size-50 md-small-size-100">
                  <lims-field :model="formData" :schema="schema" field="discardDate">
                    <date-picker
                      :lang="{
                        formatLocale: {
                          firstDayOfWeek: 1,
                        },
                        monthBeforeYear: false,
                      }"
                      :disabled="!formData.tissueDiscard"
                      slot="field"
                      v-model="formData.discardDate"
                      format="DD/MM/YYYY"
                    ></date-picker>
                  </lims-field>
                </div>
              </div>
            </div>
          </form>
        </div>
        <md-dialog-actions>
          <md-button @click="close" class="lims-form-button">{{ $t('global/button/button.cancel') }}</md-button>
          <md-button
            @click="onCompleteAction"
            :disabled="invalid || !formData.tissueDiscard || isProcessing"
            class="lims-form-button md-primary"
            >{{ $t('global/button/button.completeAction') }}</md-button
          >
        </md-dialog-actions>
      </md-dialog>
    </form>
  </ValidationObserver>
</template>

<script>
import { CASE_BATCH_CHANGE_FIELD_ENUM } from '@/core/constants';
import { convertFromDateTimezoneToIsoString } from '@/core/helpers';
import modalMixins from './modal.mixins';
import { caseListService } from '@/services';
import { mapActions } from 'vuex';
import { APP_EVENTS, newAppEvent } from '@/core/constants';

export default {
  name: 'ModalDiscardTissue',
  mixins: [modalMixins],
  props: {
    bulkActionSelect: {
      require: true,
    },
    compatibleCase: {
      required: true,
    },
  },
  data() {
    return {
      formData: {
        tissueDiscard: false,
        discardDate: null,
      },
      isFormValid: false,
      masterCheckBoxData: null,
    };
  },
  watch: {},
  computed: {
    schema() {
      return {
        entity: 'modals/ModalDiscardTissue',
        fields: {
          tissueDiscard: '',
          discardDate: 'required',
        },
      };
    },
  },
  methods: {
    ...mapActions('app/event', ['addEvent']),
    setMasterCheckBoxData(masterCheckBoxData) {
      this.masterCheckBoxData = masterCheckBoxData;
    },
    async onCompleteAction() {
      this.isProcessing = true;
      const data = {
        ...this.masterCheckBoxData,
        isForcedUpdate: true,
        fieldType: CASE_BATCH_CHANGE_FIELD_ENUM.TissueDiscarded,
        fieldValue: this.formData.tissueDiscard ? 'true' : 'false',
        date: convertFromDateTimezoneToIsoString(this.formData.discardDate),
      };
      const res = await caseListService.discardTissue({ ...data });
      this.isProcessing = false;
      if (res.error) {
        return this.$alertError(res.error);
      }
      if (data) {
        this.$emit('onCompletedAction');
        // emit event
        this.addEvent(
          newAppEvent(APP_EVENTS.EVT_ON_RELOAD_CASE_LIST, {
            bulkActionSelect: this.bulkActionSelect,
          }),
        );
        this.$alertSuccess(
          this.$t('components/ModalBulkActionCommom.completeActionSuccess', {
            bulkAction: this.$translateBulkActionSelect(this.bulkActionSelect),
          }),
        );
        this.close();
      }
    },
    close() {
      this.isVisible = false;
      this.$nextTick(() => {
        this.formData = {
          tissueDiscard: false,
          discardDate: '',
        };
      });
    },
  },
};
</script>

<style lang="scss">
.checkbox-tissueDiscard {
  margin-top: 40px;
}
</style>
