<template>
  <lims-block>
    <h4 class="title" slot="blockTitle">{{ $t('pages/account/user/form/notifications/blockTitle') }}</h4>
    <div class="md-layout" slot="blockContent">
      <div class="md-layout lims-form-row">
        <md-table class="custom-tbl" md-card>
          <md-table-row>
            <md-table-head></md-table-head>
            <md-table-head class="th-center">
              <md-checkbox
                :disabled="disabled"
                v-model="selectAllLims"
                :class="disabled ? 'disabled checkbox-all' : 'checkbox-all'"
                tabenable="yes"
              >
                {{ $t('pages/account/user/form/notifications/limsLabel') }}
                <md-tooltip
                  v-if="!disabled"
                  class="tooltip-selectAll"
                  v-html="$t('global/tooltip/selectAll')"
                ></md-tooltip>
              </md-checkbox>
            </md-table-head>
            <md-table-head class="th-center">
              <md-checkbox
                :disabled="disabled"
                v-model="selectAllEmail"
                :class="disabled ? 'disabled checkbox-all' : 'checkbox-all'"
                tabenable="yes"
              >
                {{ $t('pages/account/user/form/notifications/emailLabel') }}
                <md-tooltip
                  v-if="!disabled"
                  class="tooltip-selectAll"
                  v-html="$t('global/tooltip/selectAll')"
                ></md-tooltip>
              </md-checkbox>
            </md-table-head>
            <md-table-head class="th-center">
              <md-checkbox
                :disabled="disabled || disabledSMS"
                v-model="selectAllSms"
                :class="disabled || disabledSMS ? 'disabled checkbox-all' : 'checkbox-all'"
                tabenable="yes"
              >
                {{ $t('pages/account/user/form/notifications/smsLabel') }}
                <md-tooltip
                  v-if="!(disabled || disabledSMS)"
                  class="tooltip-selectAll"
                  v-html="$t('global/tooltip/selectAll')"
                ></md-tooltip>
              </md-checkbox>
            </md-table-head>
          </md-table-row>
          <md-table-row v-for="(item, name) in value" :key="name">
            <md-table-cell v-if="item.isEnabled">{{
              item.notificationDescription || item.notificationSetting.notificationDescription
            }}</md-table-cell>
            <md-table-cell v-if="item.isEnabled" class="cell-action">
              <md-checkbox
                v-if="item.canUseInApp"
                v-model="item.isUseInApp"
                class="lims-checkbox"
                :disabled="disabled"
                tabenable="yes"
              ></md-checkbox>
            </md-table-cell>
            <md-table-cell v-if="item.isEnabled" class="cell-action">
              <md-checkbox
                v-if="item.canUseEmail"
                v-model="item.isUseEmail"
                class="lims-checkbox"
                :disabled="disabled"
                tabenable="yes"
              ></md-checkbox>
            </md-table-cell>
            <md-table-cell v-if="item.isEnabled" class="cell-action">
              <md-checkbox
                v-if="item.canUseSMS"
                v-model="item.isUseSMS"
                class="lims-checkbox"
                :disabled="disabled || disabledSMS"
                tabenable="yes"
              ></md-checkbox>
            </md-table-cell>
          </md-table-row>
        </md-table>
      </div>
    </div>
  </lims-block>
</template>

<script>
export default {
  created: function () {
    this.notificationValues = this.value;
  },
  props: {
    value: {
      type: Array,
      require: true,
    },
    disabled: {
      type: Boolean,
      require: false,
      default: false,
    },
    disabledSMS: {
      type: Boolean,
      require: false,
      default: false,
    },
  },
  name: 'UserNotifications',
  computed: {
    selectAllLims: {
      set(selected) {
        if (this.notificationValues) {
          this.notificationValues.forEach(function (val) {
            val.isUseInApp = selected;
          });
        }
      },
      get() {
        if (this.notificationValues) {
          return this.notificationValues.every(function (val) {
            return val.isUseInApp;
          });
        } else {
          return null;
        }
      },
    },
    selectAllEmail: {
      set(selected) {
        if (this.notificationValues) {
          this.notificationValues.forEach(function (val) {
            val.isUseEmail = selected;
          });
        }
      },
      get() {
        if (this.notificationValues) {
          return this.notificationValues.every(function (val) {
            return val.isUseEmail;
          });
        } else {
          return null;
        }
      },
    },
    selectAllSms: {
      set(selected) {
        if (this.notificationValues) {
          this.notificationValues.forEach(function (val) {
            val.isUseSMS = selected;
          });
        }
      },
      get() {
        if (this.notificationValues) {
          return this.notificationValues.every(function (val) {
            return val.isUseSMS;
          });
        } else {
          return null;
        }
      },
    },
  },
  watch: {
    notificationValues: {
      deep: true,
      handler: function (val) {
        this.$emit('input', val);
      },
    },
    value: {
      deep: true,
      handler: function (val) {
        this.notificationValues = val;
      },
    },
  },
  data() {
    return {
      notificationValues: [],
    };
  },
};
</script>

<style lang="scss" scoped></style>
