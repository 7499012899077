<template>
  <lims-block :cardText="false" class="list-page">
    <div class="md-layout" slot="blockContent">
      <div class="md-layout-item" v-if="query">
        <field-list :fields="items" @onClickView="onClickViewField">
          <md-table-toolbar slot="toolbar">
            <div class="toolbar-row">
              <div class="field-rows-per-page">
                <lims-row-per-page :pageSize="query.pagination.pageSize" @pageSizeChanged="$pageSizeChanged" />
              </div>
              <div class="field-search">
                <field-list-filter
                  v-model="query.filters"
                  :defaultValues="defaultValues"
                  :fields="fields"
                  @onResetFilters="onResetFilters"
                ></field-list-filter>
              </div>
            </div>
            <lims-pagination ref="pager" :total-item="totalItem" :pagination="query.pagination"></lims-pagination>
          </md-table-toolbar>
        </field-list>
        <lims-pagination ref="pager" :total-item="totalItem" :pagination="query.pagination"></lims-pagination>
      </div>
    </div>
  </lims-block>
</template>

<script>
import FieldListFilter from '@/pages/Data/Dropdown/FieldList/FieldListFilter';
import FieldList from '@/pages/Data/Dropdown/FieldList/FieldList';
import LimsRowPerPage from '@/components/Lims/LimsRowPerPage';
import LimsPagination from '@/components/Lims/LimsPagination';
import { APP_ROUTES, NUMBER_OF_CHARACTERS_SEARCH } from '@/core/constants';
import { DropdownService } from '@/services';

import { dropDownQuery } from '@/query';

import { ConfigMixins, ListWithFilterMixins } from '@/core/mixins';
import cloneDeep from 'lodash/cloneDeep';
import { buildFilterFromQuery, buildFilterQueryParams, buildSortQueryParams } from '@/query/utils';

export default {
  components: {
    LimsPagination,
    LimsRowPerPage,
    FieldList,
    FieldListFilter,
  },
  mixins: [ConfigMixins, ListWithFilterMixins],
  data() {
    return {
      items: [],
      totalItem: null,
      query: null,
    };
  },

  computed: {
    fields() {
      return dropDownQuery.filters.fields;
    },
    defaultValues() {
      return dropDownQuery.filters.defaultValues;
    },
  },

  created() {
    this.initState();
  },

  methods: {
    // common methods
    initState() {
      const queryParams = this.$route.query;
      const filters = buildFilterFromQuery(dropDownQuery.filters, queryParams);
      const pagination = buildFilterFromQuery(dropDownQuery.pagination, {
        pageSize: this.$appConfig.itemPerPageDefault,
        ...queryParams,
      });
      const sort = buildFilterFromQuery(dropDownQuery.sort, queryParams);
      const query = { filters, pagination, sort };
      this.query = query;
    },

    async search(queryObject) {
      const queryObjNew = cloneDeep(queryObject);
      if (queryObjNew.filters.search.length > 0 && queryObjNew.filters.search.length < NUMBER_OF_CHARACTERS_SEARCH) {
        return;
      }

      const { error, data } = await DropdownService.searchFieldList({
        filters: buildFilterQueryParams(queryObject.filters),
        pagination: buildFilterQueryParams(queryObject.pagination),
        ...buildSortQueryParams(queryObject.sort),
      });
      // detect error and show alert to user
      if (error) {
        this.$alertError(error);
        // remove loading screen and allow user to interact
        return;
      }

      // binding data
      this.items = data.items;
      this.totalItem = data.pagination.totalItems;

      // update router
      const query = {
        ...buildFilterQueryParams(queryObject.filters),
        ...buildFilterQueryParams(queryObject.pagination),
        ...buildFilterQueryParams(queryObject.sort),
      };

      this.$router
        .push({
          query,
        })
        .catch((error) => {
          if (
            error.name === 'NavigationDuplicated' &&
            error.message.includes('Avoided redundant navigation to current location')
          ) {
            history.pushState({}, '', new URL(new URL(window.location)));
          }
        });
    },
    onResetFilters() {
      this.query.filters = cloneDeep(dropDownQuery.filters.defaultValues);
    },

    onClickViewField(item) {
      this.$router.push({
        path: `${APP_ROUTES.DROPDOWN}/${item.fieldId}`,
        query: {
          pageNumber: 1,
          pageSize: this.$appConfig.itemPerPageDefault,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
