<template>
  <md-dialog :md-close-on-esc="true" :md-active.sync="isVisible" @md-clicked-outside="close" class="lims-modal">
    <div class="dialog-icon-close" @click="close"><md-icon>close</md-icon></div>
    <md-dialog-title v-html="$t('components/lims/modals/ModalWarningResetPassword.title')"></md-dialog-title>
    <div class="lims-form dialog-content">
      <p class="text-center">{{ $t('components/lims/modals/ModalWarningResetPassword.content') }}</p>
    </div>
  </md-dialog>
</template>

<script>
import modalMixins from '@/components/Lims/modals/modal.mixins';

export default {
  name: 'modal-warning-reset-password',
  mixins: [modalMixins],
  props: {},
  methods: {},
};
</script>

<style lang="scss"></style>
