<template>
  <md-dialog :md-close-on-esc="true" :md-active.sync="isVisible" class="lims-modal">
    <div class="dialog-icon-close" @click="close"><md-icon>close</md-icon></div>
    <div class="lims-form dialog-content">
      <p class="text-center">{{ $t('component/lims/modals/ModalNoCaseFound/NoCaseFoundContent') }}</p>
    </div>
    <md-dialog-actions>
      <md-button @click="close" class="lims-form-button md-primary">{{ $t('global/button/button.ok') }}</md-button>
    </md-dialog-actions>
  </md-dialog>
</template>

<script>
import modalMixins from '@/components/Lims/modals/modal.mixins';

export default {
  mixins: [modalMixins],
  props: {},
  data() {
    return {};
  },
  created() {},
  methods: {
    close() {
      this.isVisible = false;
      this.$emit('closeModalNoCaseFound');
    },
  },
};
</script>
<style lang="scss"></style>
