<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit, invalid }">
    <md-dialog :md-close-on-esc="true" :md-active.sync="isVisible" @md-clicked-outside="close" class="lims-modal">
      <div class="dialog-icon-close" @click="close"><md-icon>close</md-icon></div>
      <md-dialog-title>{{ $t('components/lims/modals/ModalConfirmation.title') }}</md-dialog-title>
      <form class="md-layout lims-form" @submit.prevent="handleSubmit()">
        <p
          class="text-center"
          v-html="
            $t('pages/dataset/DatasetManagement/DatasetForm/modals/ModalDeleteConfirmation/txt', {
              dataset: datasetName,
            })
          "
        ></p>
        <div style="margin-bottom: 24px">
          <lims-field :model="formData" :schema="schema" field="password">
            <md-input slot="field" ref="confirmPassword" v-model="formData.password" type="password"></md-input>
          </lims-field>
        </div>

        <md-dialog-actions>
          <md-button @click="onCancel" class="lims-form-button">
            {{ $t('global/button/button.cancel') }}
          </md-button>
          <md-button @click="onDelete" class="md-danger lims-form-button" :disabled="invalid">
            {{ $t('global/button/button.delete') }}
          </md-button>
        </md-dialog-actions>
      </form>
    </md-dialog>
  </ValidationObserver>
</template>

<script>
import modalMixins from './modal.mixins';
import { getCheckCodeSchema } from '@/schemas/check-code.schema';
export default {
  mixins: [modalMixins],
  props: {
    isWrongPassword: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      datasetName: '',
      datasetId: '',
      formData: {
        password: null,
      },
    };
  },
  computed: {
    checkCodeSchema() {
      return getCheckCodeSchema(this.isWrongPassword);
    },
    schema() {
      return {
        entity: 'modals/ModalDeleteDataset',
        fields: {
          password: 'required',
        },
      };
    },
  },

  methods: {
    setDatasetData(datasetId, datasetName) {
      this.datasetId = datasetId;
      this.datasetName = datasetName;
    },
    onCancel() {
      this.close();
    },
    onDelete() {
      this.$emit('onDelete', {
        ...this.formData,
        datasetName: this.datasetName,
        datasetId: this.datasetId,
      });
      this.close();
    },
  },
};
</script>

<style lang="scss"></style>
