var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
return [_c('form',{staticClass:"md-layout lims-form form-login",on:{"submit":function($event){$event.preventDefault();return handleSubmit()}}},[_c('md-dialog',{staticClass:"lims-modal",attrs:{"md-close-on-esc":true,"md-active":_vm.isVisible},on:{"update:mdActive":function($event){_vm.isVisible=$event},"update:md-active":function($event){_vm.isVisible=$event},"md-clicked-outside":_vm.close}},[_c('div',{staticClass:"dialog-icon-close",on:{"click":_vm.close}},[_c('md-icon',[_vm._v("close")])],1),_c('md-dialog-title',[_vm._v(_vm._s(_vm.$t('components/lims/modals/ModalConfirmation.title')))]),_c('div',{staticClass:"lims-form dialog-content"},[_c('p',{staticClass:"text-center",domProps:{"innerHTML":_vm._s(
            _vm.$t('components/ModalBulkActionRequest.text', {
              bulkAction: _vm.$translateBulkActionSelect(_vm.bulkActionSelect),
            })
          )}}),_c('ul',{staticClass:"list-cases"},_vm._l((_vm.bulkActionItem),function(item){return _c('li',{key:item.requestDate},[_vm._v(" "+_vm._s(item.requesterName)+", "+_vm._s(item.caseReference)+" ")])}),0),_c('lims-field',{attrs:{"model":_vm.formData,"schema":_vm.schema,"field":"password"}},[_c('md-input',{attrs:{"slot":"field","type":"password"},slot:"field",model:{value:(_vm.formData.password),callback:function ($$v) {_vm.$set(_vm.formData, "password", $$v)},expression:"formData.password"}})],1)],1),_c('md-dialog-actions',[_c('md-button',{staticClass:"lims-form-button",on:{"click":_vm.close}},[_vm._v(_vm._s(_vm.$t('global/button/button.cancel')))]),_c('md-button',{staticClass:"lims-form-button md-primary",attrs:{"disabled":invalid || _vm.isProcessing},on:{"click":_vm.onCompleteAction}},[_vm._v(_vm._s(_vm.$t('global/button/button.completeAction')))])],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }