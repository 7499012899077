import { DROPDOWN_SHORT_NAME } from '@/core/constants';
import httpClient from './httpClient';

const RESOURCE = 'dropdowns';

export const dropdownOptionMapping = (res, shortNames) => {
  const dropdownOptions = {};
  shortNames.map((name) => {
    // eslint-disable-next-line security/detect-object-injection
    dropdownOptions[name] = res
      .find((row) => row.itemName === name)
      .items.map((item) => {
        if (
          name == DROPDOWN_SHORT_NAME.COUNTRY ||
          name == DROPDOWN_SHORT_NAME.CASE_SPECIMEN_BLOCK ||
          name == DROPDOWN_SHORT_NAME.CaseDatasetControlType
        ) {
          return {
            ...item,
            value: item.id,
            label: item.text,
          };
        }
        return {
          itemName: name,
          value: item.fieldItemId,
          label: item.fieldItemName,
          ...item,
        };
      });
  });
  return dropdownOptions;
};

export default {
  getFieldsInItemFormByFieldId({ fieldId }) {
    return httpClient.get(`/${RESOURCE}/get-fielditem-by-fieldid`, {
      fieldId,
    });
  },

  getFieldsInItemFormByItemId({ itemId }) {
    return httpClient.get(`/${RESOURCE}/fielditem/${itemId}`);
  },

  updateItem(FieldItemUpdateModel, fieldItemId) {
    return httpClient.put(`/${RESOURCE}/update-fielditem/${fieldItemId}`, FieldItemUpdateModel);
  },

  addItem(FieldItemInputModel) {
    return httpClient.post(`/${RESOURCE}/add-fielditem`, FieldItemInputModel);
  },

  getFieldDataByFieldId({ fieldId }) {
    return httpClient.get(`/${RESOURCE}/get-fielditems-by-fieldId`, {
      fieldId,
    });
  },

  getExternalFieldDataByFieldId({ externalFieldStoreId }) {
    return httpClient.get(`/${RESOURCE}/get-fielditems-by-storeprocedure`, {
      externalFieldStoreId,
    });
  },

  searchFieldList({ filters, pagination }) {
    const { pageNumber, pageSize } = pagination;

    const userQueryParams = {};
    if (filters.search) {
      userQueryParams['Filters.Search'] = filters.search;
    }
    if (pageNumber) {
      userQueryParams['PageNumber'] = pageNumber;
    }
    if (pageSize) {
      userQueryParams['PageSize'] = pageSize;
    }
    return httpClient.get(`/${RESOURCE}/fields`, userQueryParams);
  },

  searchItemList({ fieldId, filters, pagination }) {
    const { pageNumber, pageSize } = pagination;

    const userQueryParams = {};
    if (filters.search) {
      userQueryParams['Filters.Search'] = filters.search;
    }
    if (pageNumber) {
      userQueryParams['PageNumber'] = pageNumber;
    }
    if (pageSize) {
      userQueryParams['PageSize'] = pageSize;
    }
    return httpClient.get(`/${RESOURCE}/fielditems/${fieldId}`, userQueryParams);
  },

  reOrderItem(reOrderFieldItemModel) {
    return httpClient.post(`/${RESOURCE}/re-order-fielditem`, reOrderFieldItemModel);
  },

  deleteItem({ fieldItemId }) {
    return httpClient.delete(`/${RESOURCE}/delete-fielditem/${fieldItemId}`);
  },

  checkItemNameExisted(itemName, fieldId, itemId) {
    const userQueryParams = {};
    if (itemName) {
      userQueryParams['itemName'] = itemName;
    }
    if (fieldId) {
      userQueryParams['fieldId'] = fieldId;
    }
    if (itemId) {
      userQueryParams['itemId'] = itemId;
    }

    return httpClient.get(`/${RESOURCE}/identity-check`, userQueryParams);
  },

  getDropdownByShortName(shortName) {
    return httpClient.get(`/${RESOURCE}/get-by-shortname`, {
      shortCode: shortName,
    });
  },

  getDropdownByShortNameTemporary(shortName) {
    return httpClient.get(`/${RESOURCE}/get-by-shortnames`, {
      shortCodes: shortName.join(','),
    });
  },

  async getDropdownByShortNames(shortNames) {
    const res = await this.privateCallGetDropdownByShortNames(shortNames);
    const dropdownOptions = {};
    shortNames.map((name) => {
      // eslint-disable-next-line security/detect-object-injection
      dropdownOptions[name] = res
        .find((row) => row.itemName === name)
        .items.map((item) => {
          if (
            name == DROPDOWN_SHORT_NAME.COUNTRY ||
            name == DROPDOWN_SHORT_NAME.CASE_SPECIMEN_BLOCK ||
            name == DROPDOWN_SHORT_NAME.CaseDatasetControlType
          ) {
            return {
              ...item,
              value: item.id,
              label: item.text,
            };
          }
          return {
            itemName: name,
            value: item.fieldItemId,
            label: item.fieldItemName,
            ...item,
          };
        });
    });
    return dropdownOptions;
  },
  async privateCallGetDropdownByShortNames(shortNames) {
    const { data, error } = await httpClient.get(`/${RESOURCE}/get-by-shortnames`, {
      shortCodes: shortNames.join(','),
    });
    if (error) {
      return {};
    }
    return data;
  },

  async getStainsAssignedCustom(shortNames, entityId, isEditMode) {
    // isEditMode = true: show items isHidden = false
    const { data, error } = await httpClient.get(`/${RESOURCE}/assigned-custom-stains`, {
      ShortCodes: shortNames.join(','),
      EntityId: entityId,
      IsEditMode: isEditMode,
    });
    if (error) {
      return {};
    }
    return data;
  },

  async getStainsUnAssignedCustom(shortNames, entityId, isEditMode) {
    // isEditMode = true: show items isHidden = false
    const { data, error } = await httpClient.get(`/${RESOURCE}/unassigned-custom-stains`, {
      ShortCodes: shortNames.join(','),
      EntityId: entityId,
      IsEditMode: isEditMode,
    });
    if (error) {
      return {};
    }
    return data;
  },
};
