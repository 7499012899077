<template>
  <ValidationObserver ref="formCommonData" v-slot="{ handleSubmit }">
    <form class="md-layout lims-form commonDataForm" @submit.prevent="handleSubmit()">
      <div class="form-wrapper">
        <h4 class="text-center simpleBooking-txt">
          {{ $t('pages/case/SimpleBooking/commonData/text') }}
        </h4>
        <div class="common-content-wrapper">
          <div class="md-layout">
            <div class="md-layout-item md-size-55 md-small-size-100">
              <lims-field :model="formData" :schema="simpleBookingSchema.commonData" field="clinicAndLaboratory">
                <lims-single-select
                  slot="field"
                  :options="clinicAndLaboratoryList"
                  v-model="formData.clinicAndLaboratory"
                  reduceKey="id"
                  labelKey="text"
                  @input="changeClinicAndLabVal"
                  :disabled="
                    formData.clinicId || formData.laboratoryId || disabledCommonData || entityClinicAndLaboratoryLogin
                      ? true
                      : false
                  "
                  :placeholder="$t('pages/case/SimpleBooking/commonData/dropdown.placeholder')"
                ></lims-single-select>
              </lims-field>
            </div>
          </div>
          <div class="md-layout">
            <div class="md-layout-item md-size-55 md-small-size-100">
              <lims-field :model="formData" :schema="simpleBookingSchema.commonData" field="clinicId">
                <lims-single-select
                  slot="field"
                  :options="clinicList"
                  v-model="formData.clinicId"
                  reduceKey="id"
                  labelKey="text"
                  @input="changeClinicVal"
                  :disabled="formData.clinicAndLaboratory || disabledCommonData ? true : false"
                  :placeholder="$t('pages/case/SimpleBooking/commonData/dropdown.placeholder')"
                ></lims-single-select>
              </lims-field>
            </div>
          </div>
          <div class="md-layout">
            <div class="md-layout-item md-size-55 md-small-size-100">
              <lims-field :model="formData" :schema="simpleBookingSchema.commonData" field="laboratoryId">
                <lims-single-select
                  slot="field"
                  v-model="formData.laboratoryId"
                  :options="laboratoryList"
                  reduceKey="id"
                  labelKey="text"
                  @input="changeLaboratoryVal"
                  :disabled="
                    formData.clinicAndLaboratory || disabledCommonData || isEntityLaboratoryLogin ? true : false
                  "
                  :placeholder="$t('pages/case/SimpleBooking/commonData/dropdown.placeholder')"
                ></lims-single-select>
              </lims-field>
            </div>
          </div>
          <div class="md-layout">
            <div class="md-layout-item md-size-55 md-small-size-100">
              <p v-html="$t('pages/case/SimpleBooking/commonData/note')"></p>
            </div>
          </div>
          <div class="md-layout">
            <div class="md-layout-item md-size-55 md-small-size-100">
              <lims-field :model="formData" :schema="simpleBookingSchema.commonData" field="workStreamId">
                <lims-single-select
                  slot="field"
                  v-model="formData.workStreamId"
                  :options="workStreamList"
                  reduceKey="fieldItemId"
                  labelKey="fieldItemName"
                  :placeholder="$t('pages/case/SimpleBooking/commonData/dropdown.placeholder')"
                  :disabled="disabledCommonData"
                  @input="changeWorkStreamVal"
                ></lims-single-select>
              </lims-field>
            </div>
          </div>
          <div class="md-layout">
            <div class="md-layout-item md-size-55 md-small-size-100">
              <lims-field :model="formData" :schema="simpleBookingSchema.commonData" field="labEntry">
                <date-picker
                  :lang="{
                    formatLocale: {
                      firstDayOfWeek: 1,
                    },
                    monthBeforeYear: false,
                  }"
                  slot="field"
                  type="datetime"
                  v-model="formData.labEntry"
                  format="DD/MM/YYYY HH:mm:ss"
                  :time-picker-options="{ start: '00:00', step: '00:10', end: '23:50' }"
                  :disabled="disabledCommonData"
                ></date-picker>
              </lims-field>
            </div>
          </div>
          <div class="md-layout">
            <div class="md-layout-item md-size-55 md-small-size-100">
              <lims-field :model="formData" :schema="simpleBookingSchema.commonData" field="technician1Id">
                <lims-single-select
                  slot="field"
                  v-model="formData.technician1Id"
                  :options="technician1List"
                  reduceKey="id"
                  labelKey="text"
                  :disabled="isLabTechnicianView || disabledCommonData"
                ></lims-single-select>
              </lims-field>
            </div>
          </div>
          <div class="md-layout">
            <div class="md-layout-item md-size-55 md-small-size-100">
              <lims-field :model="formData" :schema="simpleBookingSchema.commonData" field="clinicianId">
                <lims-single-select
                  slot="field"
                  v-model="formData.clinicianId"
                  :options="clinicianList"
                  reduceKey="id"
                  labelKey="text"
                  :disabled="disabledCommonData"
                ></lims-single-select>
              </lims-field>
            </div>
          </div>
          <div class="md-layout">
            <div class="md-layout-item md-size-55 md-small-size-100">
              <lims-field :model="formData" :schema="simpleBookingSchema.commonData" field="slideIds">
                <multiselect
                  slot="field"
                  v-model="formData.slideIds"
                  :options="slideList"
                  :multiple="true"
                  :show-labels="false"
                  group-values="items"
                  group-label="itemName"
                  placeholder=""
                  track-by="fieldItemId"
                  label="fieldItemName"
                  :hide-selected="true"
                  :group-select="false"
                  :disabled="disabledCommonData"
                >
                </multiselect>
              </lims-field>
            </div>
          </div>
          <div class="md-layout">
            <div class="md-layout-item md-size-55 md-small-size-100">
              <lims-field :model="formData" :schema="simpleBookingSchema.commonData" field="specimenTypeId">
                <multiselect
                  slot="field"
                  v-model="formData.specimenTypeId"
                  :options="specimenTypeList"
                  :multiple="false"
                  :show-labels="false"
                  group-values="items"
                  group-label="itemName"
                  placeholder=""
                  track-by="fieldItemId"
                  label="fieldItemName"
                  :hide-selected="false"
                  :group-select="false"
                  :disabled="disabledCommonData"
                  :selectable="(option) => !formData.specimenTypeId.includes(option)"
                >
                </multiselect>
              </lims-field>
            </div>
          </div>
          <div class="md-layout">
            <div class="md-layout-item md-size-55 md-small-size-100">
              <lims-field
                class="textarea-field"
                :model="formData"
                :schema="simpleBookingSchema.commonData"
                field="specimenDetails"
              >
                <md-textarea
                  slot="field"
                  v-model="formData.specimenDetails"
                  :disabled="disabledCommonData"
                ></md-textarea>
              </lims-field>
            </div>
          </div>
          <div class="md-layout">
            <div class="md-layout-item md-size-55 md-small-size-100">
              <lims-field
                class="textarea-field"
                :model="formData"
                :schema="simpleBookingSchema.commonData"
                field="clinicalDetails"
              >
                <md-textarea
                  slot="field"
                  v-model="formData.clinicalDetails"
                  :disabled="disabledCommonData"
                ></md-textarea>
              </lims-field>
            </div>
          </div>
          <div class="md-layout">
            <div class="md-layout-item md-size-55 md-small-size-100">
              <lims-field
                class="textarea-field"
                :model="formData"
                :schema="simpleBookingSchema.commonData"
                field="macroDescription"
              >
                <md-textarea
                  slot="field"
                  v-model="formData.macroDescription"
                  :disabled="disabledCommonData"
                ></md-textarea>
              </lims-field>
            </div>
          </div>
        </div>
        <div class="md-layout lims-form-row">
          <div class="md-layout-item md-size-100 md-small-size-100 lims-form-actions">
            <lims-form-cancel></lims-form-cancel>
            <md-button
              @click="onStartBooking()"
              :disabled="
                !(formData.clinicAndLaboratory || formData.clinicId || formData.laboratoryId) || disabledCommonData
              "
              class="md-button md-primary lims-form-button md-theme-default"
            >
              {{ $t('pages/case/SimpleBooking/commonData/button.startBooking') }}
            </md-button>
            <md-button
              @click="onClear()"
              :disabled="disabledCommonData"
              class="md-button md-danger lims-form-button md-theme-default"
            >
              {{ $t('global/button/button.clear') }}
            </md-button>
          </div>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import SimpleBookingMixins from '@/pages/Case/SimpleBooking/SimpleBooking.mixins';
import { getSimpleBookingSchema } from '@/schemas/simpleBooking.schema';
import { ENTITY_TYPES } from '@/core/constants';
import { convertDateTimeToUTCFormat } from '@/core/helpers';
import myEntityService from '@/services/myEntity.service';

export default {
  mixins: [SimpleBookingMixins],
  props: {
    disabledCommonData: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.fetchData();
  },
  computed: {
    simpleBookingSchema: function () {
      return getSimpleBookingSchema(this.validateDateTime);
    },
  },
  data() {
    return {
      formData: {},
    };
  },
  watch: {
    'formData.clinicAndLaboratory': {
      handler(val) {
        if (val) {
          this.formData.clinicId = '';
          this.formData.laboratoryId = '';
        }
      },
      deep: true,
    },
  },
  methods: {
    async fetchData() {
      this.formData.clinicalDetails = 'Please see request form';
      this.formData.macroDescription = 'Please see request form';
      if (this.isLabTechnicianView) {
        this.myLaboratory = await myEntityService.privateGetMyEntityName();
        this.clinicAndLaboratoryList = await this.loadEntityType({
          entityType: ENTITY_TYPES.ClinicLaboratory,
          dependencyEntityId: null,
        });
        this.entityClinicAndLaboratoryLogin = await this.clinicAndLaboratoryList.find(
          (x) => x.id == this.myLaboratory.id,
        );
        if (this.entityClinicAndLaboratoryLogin) {
          this.formData.clinicAndLaboratory = this.myLaboratory.id;
          await this.changeClinicAndLabVal(this.formData.clinicAndLaboratory);
        } else {
          this.isEntityLaboratoryLogin = true;
          this.formData.laboratoryId = this.myLaboratory.id;
          await this.changeLaboratoryVal(this.formData.laboratoryId);
        }
      }
      this.formData.labEntry = convertDateTimeToUTCFormat();
    },
    async changeLaboratoryVal(val) {
      await this.loadDataDependLaboratory(val);
      this.clinicList = await this.loadEntityType({
        entityType: ENTITY_TYPES.Clinic,
        dependencyEntityId: val ? val : null,
      });
    },
    async changeClinicVal(val) {
      await this.loadDataDependClinic(val);
      this.laboratoryList = await this.loadEntityType({
        entityType: ENTITY_TYPES.Laboratory,
        dependencyEntityId: val ? val : null,
      });
    },
    async changeClinicAndLabVal(val) {
      await this.loadDataDependLaboratory(val);
      await this.loadDataDependClinic(val);
    },
    async changeWorkStreamVal(val) {
      await this.loadSpecimenTypeDependWorkStream(val);
    },
    onStartBooking() {
      if (this.formData.clinicAndLaboratory || this.formData.clinicId || this.formData.laboratoryId) {
        let dataForm;
        let listData;
        if (this.formData.clinicAndLaboratory) {
          dataForm = {
            ...this.formData,
            clinicId: this.formData.clinicAndLaboratory,
            laboratoryId: this.formData.clinicAndLaboratory,
          };
          listData = {
            clinicList: this.clinicAndLaboratoryList,
            laboratoryList: this.clinicAndLaboratoryList,
            technician1List: this.technician1List,
            clinicianList: this.clinicianList,
            slideList: this.slideList,
            specimenTypeList: this.specimenTypeList,
            specimenTypeListTmp: this.specimenTypeListTmp,
            workStreamList: this.workStreamList,
          };
        } else {
          dataForm = this.formData;
          listData = {
            clinicList: this.clinicList,
            laboratoryList: this.laboratoryList,
            technician1List: this.technician1List,
            clinicianList: this.clinicianList,
            slideList: this.slideList,
            specimenTypeList: this.specimenTypeList,
            workStreamList: this.workStreamList,
          };
        }
        this.$emit('input', dataForm);
        this.$emit('listData', listData);
        this.$emit('startBooking', true);
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    },
    onClear() {
      this.$nextTick(() => {
        this.$refs.formCommonData.reset();
      });
      this.formData = {};
      this.formData.clinicalDetails = 'Please see request form';
      this.formData.macroDescription = 'Please see request form';
      this.formData.labEntry = convertDateTimeToUTCFormat();
      if (this.isLabTechnicianView) {
        this.formData.technician1Id = this.user.userId;
        if (this.entityClinicAndLaboratoryLogin) {
          this.formData.clinicAndLaboratory = this.user.entityId;
          this.changeClinicAndLabVal(this.formData.clinicAndLaboratory);
        } else {
          this.isEntityLaboratoryLogin = true;
          this.formData.laboratoryId = this.user.entityId;
          this.changeLaboratoryVal(this.formData.laboratoryId);
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
