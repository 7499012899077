<template>
  <form ref="form">
    <div class="list-filter normal-list-filter download-case-import-template">
      <div class="md-layout lims-form-row" v-if="isReady">
        <div class="md-layout-item md-size-50 md-small-size-100 select-filter-import-case">
          <v-select
            multiple
            v-model="selectedFilterFields"
            :map-keydown="handlers"
            :options="filterList"
            :placeholder="$t('pages/case/ImportCase/components/DownloadCaseImportTemplate/placeholder.selectFilter')"
            :selectable="(option) => !selectedFilterFields.includes(option)"
          >
            <template #option="{ text }">
              {{ text }}
            </template>
            <template #selected-option="{ text }">
              {{ text }}
            </template>
            <template #search="{ events }">
              <input
                :placeholder="
                  $t('pages/case/ImportCase/components/DownloadCaseImportTemplate/placeholder.selectFilter')
                "
                type="search"
                class="vs__search"
                v-on="events"
              />
            </template>
          </v-select>
        </div>

        <div v-if="selectedFilterFields && selectedFilterFields.length > 0" class="lims-form advance-search-wrapper">
          <div class="md-layout lims-form-row">
            <div
              v-for="(item, index) in selectedFilterFields"
              :key="index"
              class="md-layout-item md-size-50 md-small-size-100"
            >
              <md-checkbox v-model="checkboxValue[item.key]"></md-checkbox>

              <template v-if="isDropdownMenuSingle(item)">
                <span class="label-item">{{ item.text }}</span>
                <lims-select
                  v-model="filterValues[item.key]"
                  label="text"
                  :options="dataSource[item.key]"
                  :translated="true"
                  :placeholder="
                    $t('pages/case/ImportCase/components/DownloadCaseImportTemplate/placeholder.selectValue')
                  "
                  :class="
                    (filterValues[item.key] && filterValues[item.key].length > 0) ||
                    isCheckEntityTypeFilterValue(item.key)
                      ? 'is-disabled'
                      : ''
                  "
                ></lims-select>
              </template>

              <template v-if="isDropdownMenuMulti(item)">
                <span class="label-item">{{ item.text }}</span>
                <lims-select
                  v-model="filterValues[item.key]"
                  label="text"
                  :options="dataSource[item.key]"
                  :translated="true"
                  :placeholder="
                    $t('pages/case/ImportCase/components/DownloadCaseImportTemplate/placeholder.selectMultiValue', {
                      value: item.text,
                    })
                  "
                ></lims-select>
              </template>

              <template v-if="isDropdownMenuMultiGroup(item)">
                <span class="label-item">{{ item.text }}</span>
                <lims-multi-select
                  :options="dataSource[item.key]"
                  :groupOptions="dataSource[`group${item.key}`]"
                  v-model="filterValues[item.key]"
                  itemName="items"
                  groupName="itemName"
                  :placeholder="''"
                  style="width: 100%"
                ></lims-multi-select>
              </template>

              <template v-if="IsDatePicker(item)">
                <span class="label-item">{{ item.text }}</span>
                <date-picker v-model="filterValues[item.key]" format="DD/MM/YYYY"></date-picker>
              </template>

              <template v-if="IsTimePicker(item)">
                <span style="width: 170px" class="label-item">{{ item.text }}</span>
                <date-picker
                  v-model="filterValues[item.key]"
                  :time-picker-options="{ start: '00:00', step: '00:10', end: '23:50' }"
                  format="HH:mm:ss"
                  type="time"
                  :placeholder="item.text"
                ></date-picker>
              </template>

              <template v-if="IsTextArea(item)">
                <span style="width: 170px" class="label-item">{{ item.text }}</span>
                <md-field>
                  <md-textarea v-model="filterValues[item.key]" :placeholder="item.text"></md-textarea>
                </md-field>
              </template>

              <template v-if="IsSpinbox(item)">
                <span class="label-item">{{ item.text }}</span>
                <md-field style="width: 100px">
                  <md-input
                    type="number"
                    min="0"
                    v-model="filterValues[item.key]"
                    @keypress="isNumber($event)"
                    style="width: 100%"
                  ></md-input>
                </md-field>
              </template>
            </div>
          </div>
          <lims-chip-area :options="chipOptions" @onRemoveChip="removeChip"></lims-chip-area>
        </div>

        <div class="md-layout-item md-size-100 md-small-size-100 filter-action mg-top-24">
          <md-button @click="onClear" class="md-button lims-form-button md-theme-default">
            {{ $t('global/button/button.clear') }}
          </md-button>
          <md-button
            @click="onExport"
            :disabled="!hasEntityFilterValue"
            class="md-button md-primary lims-form-button md-theme-default"
          >
            {{ $t('global/button/button.export') }}
          </md-button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
// eslint-disable-next-line security/detect-object-injection
import { ConfigMixins, ListFilterMixins } from '@/core/mixins';
import LimsSelect from '@/components/Lims/LimsSelect.vue';
import LimsChipArea from '@/components/Lims/LimsChipArea.vue';
import LimsMultiSelect from '@/components/Lims/LimsMultiSelect.vue';
import {
  CASE_LIST_DROPDOWN,
  DROPDOWN_SHORT_NAME,
  ENTITY_TYPES,
  ENTITY_TYPE_ENUMS,
  USER_TYPE_NAME,
} from '@/core/constants';
import { caseImportTemplateQuery } from '@/query';
import { caseFormService, caseImportService, caseListService, EntityService, MyEntityService } from '@/services';
import {
  sortDropDown,
  isPerfectEmpty,
  downloadBlob,
  DateTimeHelper,
  convertFromDateTimezoneToIsoString,
  getOnlyDateTimeFromIsoString,
} from '@/core/helpers';
import cloneDeep from 'lodash/cloneDeep';
import { mapState } from 'vuex';
import {
  FF_DCI_billingEntityIds,
  FF_DCI_clinicalDetails,
  FF_DCI_clinicAndLabEntityIds,
  FF_DCI_clinicianIds,
  FF_DCI_clinicIds,
  FF_DCI_entityType,
  FF_DCI_labEntryDate,
  FF_DCI_labEntryTime,
  FF_DCI_laboratoryIds,
  FF_DCI_macroDescription,
  FF_DCI_numberDefaultRows,
  FF_DCI_pathologists,
  FF_DCI_slides,
  FF_DCI_specimenTypeIds,
  FF_DCI_technician1Ids,
  FF_DCI_workStreamIds,
  FILTER_DOWNLOAD_IMPORT_TEMPLATE,
} from '@/query/filter-fields';

export default {
  mixins: [ConfigMixins, ListFilterMixins],
  components: {
    LimsSelect,
    LimsChipArea,
    LimsMultiSelect,
  },
  created() {
    this.fetchData();
  },
  computed: {
    fields() {
      return caseImportTemplateQuery.filters.fields;
    },
    entityTypeList() {
      return ENTITY_TYPE_ENUMS;
    },
    ...mapState('auth', {
      user: (state) => state.user,
    }),
    isLabTechnicianView() {
      return this.user.userTypeId === this.USER_TYPES().LabTechnician;
    },
  },
  data() {
    return {
      isReady: false, // ready flag prevent user
      isLabTechnician: false,
      filterList: [], // list supported filter : constants
      selectedFilterFields: [], // selectedFilterFields
      filterValues: {}, // currentSelectedFilterValues
      checkboxValue: {}, // usingFilterFields
      dataSource: {}, // dataSource for all filter
      chipOptions: [], // chip
      hasLabAndClinicFilterValue: false, // enable filter field (by entity type: lab, cliniclab, clinic)
      hasEntityFilterValue: false, // enabledExport
      specimenTypeList: [], // move dataSource
      specimenTypeListTmp: [],
    };
  },
  watch: {
    filterValues: {
      handler(filterValues) {
        this.$updateChipList({ filterValues, dataSource: this.dataSource, filterFields: this.fields });
        if (this.filterValues['numberDefaultRows'] === undefined) {
          this.$set(this.filterValues, 'numberDefaultRows', 1);
        }
      },
      deep: true,
    },
    'filterValues.clinicAndLabEntityIds': {
      async handler(val) {
        if (val && val.length > 0) {
          const entityId = val[0];

          // reload: clinician, slides, technician01, billing, workstream, specimenType
          const clinicianList = await this.loadUsersByUserType({
            userTypeId: this.USER_TYPES()[USER_TYPE_NAME.Clinician],
            entityId: entityId,
          });
          // slides: groupOptions, options
          const stainList = await this.loadStainList(entityId);
          const technician01List = await this.loadUsersByUserType({
            userTypeId: this.USER_TYPES().LabTechnician,
            entityId: entityId,
          });
          const billingEntityList = await this.loadBillingEntityList(entityId);
          const workStreamList = await this.loadWorkStreamList(entityId);
          // specimenItems, specimenList
          this.specimenTypeListTmp = await this.loadSpecimenType(entityId);
          // binding datasource
          this.$set(this.dataSource, FF_DCI_clinicianIds, clinicianList);
          this.$set(this.dataSource, FF_DCI_slides, stainList.options);
          this.$set(this.dataSource, `group${FF_DCI_slides}`, stainList.groupOptions);
          this.$set(this.dataSource, FF_DCI_technician1Ids, technician01List);
          this.$set(this.dataSource, FF_DCI_billingEntityIds, billingEntityList);
          this.$set(this.dataSource, FF_DCI_workStreamIds, workStreamList);
          // this.$set(this.dataSource, FF_DCI_specimenTypeIds, specimenTypeList.options);
          // this.$set(this.dataSource, `group${FF_DCI_specimenTypeIds}`, specimenTypeList.groupOptions);
        } else {
          this.$set(this.dataSource, FF_DCI_clinicianIds, []);
          this.$set(this.dataSource, FF_DCI_slides, []);
          this.$set(this.dataSource, `group${FF_DCI_slides}`, []);
          this.$set(this.dataSource, FF_DCI_technician1Ids, []);
          this.$set(this.dataSource, FF_DCI_billingEntityIds, []);
          this.$set(this.dataSource, FF_DCI_workStreamIds, []);
          // this.$set(this.dataSource, FF_DCI_specimenTypeIds, []);
          // this.$set(this.dataSource, `group${FF_DCI_specimenTypeIds}`, []);
        }
      },
      deep: true,
    },
    'filterValues.clinicIds': {
      async handler(val) {
        let laboratoryList = [];
        if (val && val.length > 0) {
          const clinicId = val[0];
          // reload: Laboratory, BillingEntity, specimenType, workstream,
          laboratoryList = await this.loadEntityType(ENTITY_TYPES.Laboratory, clinicId);
          const billingEntityList = await this.loadBillingEntityList(clinicId);
          const workStreamList = await this.loadWorkStreamList(clinicId);
          this.specimenTypeListTmp = await this.loadSpecimenType(clinicId);

          this.$set(this.dataSource, FF_DCI_laboratoryIds, laboratoryList);
          this.$set(this.dataSource, FF_DCI_billingEntityIds, billingEntityList);
          this.$set(this.dataSource, FF_DCI_workStreamIds, workStreamList);
          // this.$set(this.dataSource, FF_DCI_specimenTypeIds, specimenTypeList.options);
          // this.$set(this.dataSource, `group${FF_DCI_specimenTypeIds}`, specimenTypeList.groupOptions);
        } else {
          laboratoryList = await this.loadEntityType(ENTITY_TYPES.Laboratory, null);
          this.$set(this.dataSource, FF_DCI_laboratoryIds, laboratoryList);
          this.$set(this.dataSource, FF_DCI_billingEntityIds, []);
          this.$set(this.dataSource, FF_DCI_workStreamIds, []);
          // this.$set(this.dataSource, FF_DCI_specimenTypeIds, []);
          // this.$set(this.dataSource, `group${FF_DCI_specimenTypeIds}`, []);
        }
      },
      deep: true,
    },
    'filterValues.laboratoryIds': {
      async handler(val) {
        let clinicList = [];
        if (val && val.length > 0) {
          const laboratoryId = val[0];

          // reload: clinic,slides,technician01, Clinician
          clinicList = await this.loadEntityType(ENTITY_TYPES.Clinic, laboratoryId);
          // slides: groupOptions, options
          const stainList = await this.loadStainList(laboratoryId);
          const technician01List = await this.loadUsersByUserType({
            userTypeId: this.USER_TYPES().LabTechnician,
            entityId: laboratoryId,
          });
          const clinicianList = await this.loadUsersByUserType({
            userTypeId: this.USER_TYPES()[USER_TYPE_NAME.Clinician],
            entityId: laboratoryId,
          });
          this.$set(this.dataSource, FF_DCI_clinicIds, clinicList);
          this.$set(this.dataSource, FF_DCI_slides, stainList.options);
          this.$set(this.dataSource, `group${FF_DCI_slides}`, stainList.groupOptions);
          this.$set(this.dataSource, FF_DCI_technician1Ids, technician01List);
          this.$set(this.dataSource, FF_DCI_clinicianIds, clinicianList);
        } else {
          clinicList = await this.loadEntityType(ENTITY_TYPES.Clinic, null);
          this.$set(this.dataSource, FF_DCI_clinicIds, clinicList);
          this.$set(this.dataSource, FF_DCI_slides, []);
          this.$set(this.dataSource, `group${FF_DCI_slides}`, []);
          this.$set(this.dataSource, FF_DCI_technician1Ids, []);
          this.$set(this.dataSource, FF_DCI_clinicianIds, []);
        }
      },
      deep: true,
    },
    'filterValues.workStreamIds': {
      async handler(val) {
        if (val && val.length > 0) {
          //loadSpecimenTypeDependWorkStream;
          let specimenTypeListArr = [];
          const specimenTypeListDependOnWorkStream = this.specimenTypeListTmp.reduce((i, value) => {
            const listItem = {
              ...value,
              items: value.items.filter((child) => val.includes(child.itemId)),
            };
            if (listItem.items.length > 0) {
              specimenTypeListArr.push(listItem);
            }
            return specimenTypeListArr;
          }, 0);
          const specimenTypeList = this.mappingSpecimenTypeList(specimenTypeListDependOnWorkStream);

          this.$set(this.dataSource, FF_DCI_specimenTypeIds, specimenTypeList.options ? specimenTypeList.options : []);
          this.$set(
            this.dataSource,
            `group${FF_DCI_specimenTypeIds}`,
            specimenTypeList.groupOptions ? specimenTypeList.groupOptions : [],
          );
        } else {
          this.$set(this.dataSource, FF_DCI_specimenTypeIds, []);
          this.$set(this.dataSource, `group${FF_DCI_specimenTypeIds}`, []);
        }
      },
      deep: true,
    },
    selectedFilterFields: {
      handler(val) {
        if (val) {
          val.forEach((item) => {
            if (this.checkboxValue[item.key] === undefined) {
              this.$set(this.checkboxValue, item.key, true);
            }
          });
        }
      },
      deep: true,
    },

    chipOptions: {
      handler: async function (val) {
        if (val) {
          const numOfEntityFilterValue = val.filter((item) => item.key == FF_DCI_entityType);
          this.hasEntityFilterValue = numOfEntityFilterValue.length > 0 ? true : false;

          // find value EntityTypeSelected
          // if EntityTypeSelected is Clinic & Laboratory -> remove value clinic or lab selected before (neu co)
          // else remove value clinic and lab selected before (neu co)
          const entityTypeSelected = val.find((item) => item.key == FF_DCI_entityType);
          this.hasLabAndClinicFilterValue =
            entityTypeSelected && entityTypeSelected.label == 'Clinic & Laboratory' ? true : false;
          if (this.hasLabAndClinicFilterValue) {
            this.chipOptions.map((chip) => {
              if (chip.key == FF_DCI_clinicIds || chip.key == FF_DCI_laboratoryIds) {
                this.$onRemoveChip(chip);
              }
            });
          } else {
            this.chipOptions.map((chip) => {
              if (chip.key == FF_DCI_clinicAndLabEntityIds) {
                this.$onRemoveChip(chip);
              }
            });
          }
        }
      },
    },
  },
  methods: {
    // https://vue-select.org/guide/keydown.html#mapkeydown
    // handler v-select issue :
    handlers: (map, vm) => ({
      ...map,
      8: (e) => {
        if (vm.search === 0) {
          e.preventDefault();
        }
      },
      46: (e) => {
        if (vm.search === 0) {
          e.preventDefault();
        }
      },
    }),
    async loadEntityData(entityId) {
      const { data, error } = await MyEntityService.getMyEntityProfile(entityId);
      if (error) {
        this.$alertError(error);
        return false;
      }
      return {
        entityId,
        entityName: data.information.entityName,
        entityType: data.information.entityType,
      };
    },
    async loadDataSource({ isLabTechnician, entityTypeRes }) {
      const dataSource = {};
      // call api
      // if isLabTechnician need: entityType, entityName, entityId
      let entityTypeList = [];
      let listClinicAndLab = [];
      let listLaboratory = [];
      let listClinic = [];

      if (isLabTechnician) {
        if (entityTypeRes) {
          const { entityId, entityName, entityType } = entityTypeRes;
          const entityData = { id: entityId, text: entityName, label: entityName, value: entityId };
          entityTypeList = this.$translateSelectOptions(ENTITY_TYPE_ENUMS).filter((item) => item.value == entityType);
          if (entityType === ENTITY_TYPES.Laboratory) {
            listLaboratory.push(entityData);
          }

          if (entityType === ENTITY_TYPES.ClinicLaboratory) {
            listClinicAndLab.push(entityData);
          }
        }
      } else {
        // admin
        entityTypeList = this.$translateSelectOptions(ENTITY_TYPE_ENUMS);
        listLaboratory = await this.loadEntityType(ENTITY_TYPES.Laboratory, null);
        listClinicAndLab = await this.loadEntityType(ENTITY_TYPES.ClinicLaboratory, null);
        listClinic = await this.loadEntityType(ENTITY_TYPES.Clinic, null);
      }
      const dropdownOptionsList = await caseListService.getDropdown([CASE_LIST_DROPDOWN.PATHOLOGIST]);
      // build datasource
      Reflect.set(dataSource, FF_DCI_entityType, entityTypeList);
      Reflect.set(dataSource, FF_DCI_clinicAndLabEntityIds, listClinicAndLab);
      Reflect.set(dataSource, FF_DCI_laboratoryIds, listLaboratory);
      Reflect.set(dataSource, FF_DCI_clinicIds, listClinic);
      Reflect.set(
        dataSource,
        FF_DCI_pathologists,
        sortDropDown(dropdownOptionsList[CASE_LIST_DROPDOWN.PATHOLOGIST], 'text'),
      );
      return dataSource;
    },
    async fetchData() {
      // detect user
      const userType = this.$store.getters['auth/userType'];
      const userTypes = this.USER_TYPES();
      const entityId = this.$store.getters['auth/entityId'] || '';

      const isLabTechnician = userType === userTypes[USER_TYPE_NAME.LabTechnician];
      const filterValues = {
        ...cloneDeep(this.filters),
      };
      let entityTypeRes = null;
      if (isLabTechnician) {
        entityTypeRes = await this.loadEntityData(entityId);
        const { entityType } = entityTypeRes;
        // set default value for entity Type
        Reflect.set(filterValues, FF_DCI_entityType, [entityType]);
        // set default value for entityId of approriate list
        if (entityType === ENTITY_TYPES.Laboratory) {
          Reflect.set(filterValues, FF_DCI_laboratoryIds, [entityId]);
        }
        if (entityType === ENTITY_TYPES.ClinicLaboratory) {
          Reflect.set(filterValues, FF_DCI_clinicAndLabEntityIds, [entityId]);
        }
      }
      // binding component state
      this.isLabTechnician = isLabTechnician;
      this.filterList = FILTER_DOWNLOAD_IMPORT_TEMPLATE.map((f) => {
        return {
          text: this.$translate(`query/filterFields/${f}`),
          label: this.$translate(`query/filterFields/${f}`),
          key: f,
          value: f,
        };
      });
      this.dataSource = await this.loadDataSource({ isLabTechnician, entityTypeRes });
      // default data: default select filter, default filter Values
      // reload: clinician, slides, technician01, billing, workstream, specimenType
      this.selectedFilterFields = this.filterList.filter((item) =>
        [
          FF_DCI_entityType,
          FF_DCI_clinicAndLabEntityIds,
          FF_DCI_clinicIds,
          FF_DCI_laboratoryIds,
          FF_DCI_numberDefaultRows,
        ].includes(item.key),
      );
      this.filterValues = filterValues;
      this.isReady = true;
    },
    async loadWorkStreamList(clinicId) {
      const res = await caseFormService.getStainByEntityIdInCaseForm(clinicId, [DROPDOWN_SHORT_NAME.WORK_STREAM]);
      if (res) {
        const data = res.WorkStream?.filter((item) => !item.isHide);
        return data?.map((item) => {
          return {
            ...item,
            value: item.id,
            label: item.text,
          };
        });
      }
    },
    async loadEntityType(entityId, dependencyEntityId) {
      const entityIds = [entityId].join(',');
      const { data } = await caseFormService.getEntitiesByEntityTypeAndUser({
        search: '',
        entityTypeIds: entityIds,
        dependencyEntityId: dependencyEntityId,
      });
      return data.map((item) => {
        return {
          ...item,
          value: item.id,
          label: item.text,
        };
      });
    },
    // get data clinicianList,technician1List depend on LabboratoryId
    async loadUsersByUserType({ userTypeId, entityId }) {
      const entityIds = [entityId].join(',');
      const { data } = await caseFormService.getUsersByUserType({
        search: '',
        userTypeId: userTypeId,
        entityId: entityIds,
      });
      if (!data) {
        return;
      } else {
        return data.map((item) => {
          return {
            ...item,
            value: item.id,
            label: item.text,
          };
        });
      }
    },
    // get specimenType
    async loadBillingEntityList(clinicId) {
      const { data } = await EntityService.getEntityBilling(clinicId);
      if (!data) {
        return;
      } else {
        return data.map((item) => {
          return {
            ...item,
            value: item.fieldItemId,
            label: item.fieldItemName,
          };
        });
      }
    },
    async loadStainList(laboratoryId) {
      const data = await caseFormService.getStainByEntityId(laboratoryId, [
        DROPDOWN_SHORT_NAME.H_AND_E,
        DROPDOWN_SHORT_NAME.IMMUNOS,
        DROPDOWN_SHORT_NAME.SPECIAL_STAINS,
        DROPDOWN_SHORT_NAME.ADDITIONAL_TECHNIQUE,
      ]);
      // unify data
      const fts = [];
      const stainListGroup = data.map((item) => {
        const { items, ...rest } = item;
        const newStains = items.map((stain) => {
          const { fieldItemName, fieldItemId, ...restStain } = stain;
          return {
            value: fieldItemId,
            label: fieldItemName,
            ...restStain,
          };
        });
        fts.push(...newStains);
        return {
          items: newStains,
          ...rest,
        };
      });
      return {
        groupOptions: stainListGroup,
        options: fts,
      };
    },
    isDropdownMenuSingle(item) {
      return [FF_DCI_entityType, FF_DCI_laboratoryIds, FF_DCI_clinicAndLabEntityIds, FF_DCI_clinicIds].includes(
        item.key,
      );
    },
    isDropdownMenuMulti(item) {
      return [
        FF_DCI_clinicianIds,
        FF_DCI_technician1Ids,
        FF_DCI_pathologists,
        FF_DCI_billingEntityIds,
        FF_DCI_workStreamIds,
      ].includes(item.key);
    },
    isDropdownMenuMultiGroup(item) {
      return [FF_DCI_slides, FF_DCI_specimenTypeIds].includes(item.key);
    },
    IsDatePicker(item) {
      return [FF_DCI_labEntryDate].includes(item.key);
    },
    IsTimePicker(item) {
      return [FF_DCI_labEntryTime].includes(item.key);
    },
    IsTextArea(item) {
      return [FF_DCI_macroDescription, FF_DCI_clinicalDetails].includes(item.key);
    },
    IsSpinbox(item) {
      return [FF_DCI_numberDefaultRows].includes(item.key);
    },
    isNumber(evt) {
      let charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    // if Entytype == Clinic&Laboratory -> disable Laboratory filter and clinic filter
    isCheckEntityTypeFilterValue(filterKey) {
      if (!this.hasEntityFilterValue) {
        return (
          filterKey == FF_DCI_clinicAndLabEntityIds ||
          filterKey == FF_DCI_laboratoryIds ||
          filterKey == FF_DCI_clinicIds
        );
      } else {
        if (!this.hasLabAndClinicFilterValue) {
          return filterKey == FF_DCI_clinicAndLabEntityIds;
        } else {
          return filterKey == FF_DCI_laboratoryIds || filterKey == FF_DCI_clinicIds;
        }
      }
    },
    async onExport() {
      let timezoneValue = DateTimeHelper.getTimezoneString();
      let filter = {};
      filter.timeZone = timezoneValue;
      Object.keys(this.checkboxValue).map((key) => {
        if (!isPerfectEmpty(this.filterValues[key]) && this.checkboxValue[key]) {
          filter[key] = this.filterValues[key];
        }
      });

      if (filter.entityType) {
        filter.entityType = filter.entityType[0];
      }
      if (filter.labEntryDate) {
        filter.labEntryDate = convertFromDateTimezoneToIsoString(filter.labEntryDate);
      }
      if (filter.labEntryTime) {
        filter.labEntryTime = getOnlyDateTimeFromIsoString(filter.labEntryTime);
      }

      const res = await caseImportService.downloadCaseImportTemplate(filter);
      if (res.err) {
        return this.$alertError(res.err + ' error');
      } else {
        if (res.data) {
          downloadBlob(res.data, `CaseImportTemplate`);
          this.$alertSuccess(this.$t('pages/case/ImportCase/components/DownloadCaseImportTemplate/Success'));
        }
      }
    },
    onClear() {
      this.$nextTick(() => {
        this.$refs.form.reset();
        this.fetchData();
      });
      this.chipOptions.map((chip) => {
        this.$onRemoveChip(chip);
      });
    },
    // get specimenType
    async loadSpecimenType(clinicId) {
      const dropdownStainOptions = await caseFormService.getStainByEntityIdInCaseForm(clinicId, [
        DROPDOWN_SHORT_NAME.COMMONLY_USED_SPECIMEN_TYPE_PER_WORK_STREAM,
      ]);
      const data = dropdownStainOptions[DROPDOWN_SHORT_NAME.COMMONLY_USED_SPECIMEN_TYPE_PER_WORK_STREAM];
      return data;
    },

    mappingSpecimenTypeList(specimenTypeList) {
      let specimenList = [];
      let specimenItems = [];
      if (specimenTypeList && specimenTypeList.length > 0) {
        specimenTypeList.forEach((specimenTypeItem) => {
          specimenList.push({
            itemName: specimenTypeItem.itemName,
            items: [
              {
                fieldItemName: '',
              },
            ],
          });
          specimenTypeItem.items.forEach((item) => {
            let fullPropItemList = item.items.map((it) => {
              it.value = it.fieldItemId || null;
              it.label = it.fieldItemName || null;
              return it;
            });
            specimenList.push({
              itemName: item.itemName,
              items: fullPropItemList,
            });
          });
        });
        specimenList.forEach((i) => {
          specimenItems.push(...i.items);
        });
        return {
          groupOptions: specimenList,
          options: specimenItems,
        };
      }
    },
    removeChip(chip) {
      if (chip.key == FF_DCI_clinicAndLabEntityIds) {
        this.chipOptions.map((chip) => {
          if (
            chip.key == FF_DCI_clinicianIds ||
            chip.key == FF_DCI_technician1Ids ||
            chip.key == FF_DCI_slides ||
            chip.key == FF_DCI_billingEntityIds ||
            chip.key == FF_DCI_workStreamIds ||
            chip.key == FF_DCI_specimenTypeIds ||
            chip.key == FF_DCI_clinicAndLabEntityIds
          ) {
            this.$onRemoveChip(chip);
          }
        });
      } else if (chip.key == FF_DCI_clinicIds) {
        this.chipOptions.map((chip) => {
          if (
            chip.key == FF_DCI_laboratoryIds ||
            chip.key == FF_DCI_billingEntityIds ||
            chip.key == FF_DCI_workStreamIds ||
            chip.key == FF_DCI_specimenTypeIds ||
            chip.key == FF_DCI_clinicIds
          ) {
            this.$onRemoveChip(chip);
          }
        });
      } else if (chip.key == FF_DCI_workStreamIds) {
        this.$onRemoveChip(chip);
        this.chipOptions.map((chip) => {
          if (chip.key == FF_DCI_specimenTypeIds) {
            this.$onRemoveChip(chip);
          }
        });
      } else if (chip.key == FF_DCI_laboratoryIds) {
        this.chipOptions.map((chip) => {
          if (
            chip.key == FF_DCI_clinicIds ||
            chip.key == FF_DCI_slides ||
            chip.key == FF_DCI_technician1Ids ||
            chip.key == FF_DCI_clinicianIds ||
            chip.key == FF_DCI_laboratoryIds
          ) {
            this.$onRemoveChip(chip);
          }
        });
      } else {
        this.$onRemoveChip(chip);
      }
    },
  },
};
</script>

<style lang="scss"></style>
