<template>
  <div>
    <modal-confirm-generic
      ref="unSaveChangesModal"
      :description="$t('components/lims/modals/ModalFormCancel.text')"
      @onConfirm="onUnSaveChanges"
    />
    <dropdown-item-form
      :dropdown-field-id="dropdownFieldId"
      :dropdown-item-id="dropdownItemId"
      :form-mode="formMode"
    ></dropdown-item-form>
  </div>
</template>

<script>
import DropdownItemForm from '@/pages/Data/Dropdown/Forms/DropdownItemForm';
import { UnSaveChangesMixins, ResetHeadingTitleMixins } from '@/core/mixins';

export default {
  mixins: [UnSaveChangesMixins, ResetHeadingTitleMixins],
  components: { DropdownItemForm },
  props: {
    dropdownFieldId: {
      require: true,
      default: null,
    },
    dropdownItemId: {
      require: true,
      default: null,
    },
    formMode: {
      require: true,
    },
  },
};
</script>

<style lang="scss" scoped></style>
