<template>
  <md-dialog :md-close-on-esc="true" :md-active.sync="isVisible" @md-clicked-outside="close" class="lims-modal">
    <div class="dialog-icon-close" @click="close"><md-icon>close</md-icon></div>
    <md-dialog-title>{{ $t('components/lims/modals/ModalDeleteCase.title') }}</md-dialog-title>
    <div class="dialog-content lims-form">
      <p class="text-center">{{ $t('components/lims/modals/ModalDeleteCase.actionCannotBeUndone') }}</p>
      <p class="text-center">{{ $t('components/lims/modals/ModalDeleteCase.provideReason') }}</p>
      <lims-field class="textarea-field" :model="formData" :schema="checkCodeSchema" field="reason">
        <md-textarea slot="field" v-model="formData.reason"></md-textarea>
      </lims-field>
      <lims-field :model="formData" :schema="checkCodeSchema" field="passwordForCase">
        <md-input ref="confirmPassword" slot="field" v-model="formData.password" type="password"></md-input>
      </lims-field>
    </div>
    <md-dialog-actions>
      <md-button @click="close" class="lims-form-button">
        {{ $t('global/button/button.cancel') }}
      </md-button>
      <md-button
        @click="onDelete"
        class="md-danger lims-form-button"
        :disabled="!formData.password || !formData.reason || isProcessing"
      >
        {{ $t('global/button/button.delete') }}
      </md-button>
    </md-dialog-actions>
  </md-dialog>
</template>

<script>
import modalMixins from './modal.mixins';
import { getCheckCodeSchema } from '@/schemas/check-code.schema';
export default {
  mixins: [modalMixins],
  data() {
    return {
      formData: {
        reason: null,
        password: null,
      },
    };
  },
  computed: {
    checkCodeSchema() {
      return getCheckCodeSchema(this.isWrongPasswordError);
    },
  },

  methods: {
    close() {
      this.isVisible = false;
      this.$nextTick(() => {
        this.formData = {
          reason: null,
          password: null,
        };
      });
    },
    onDelete() {
      this.isProcessing = true;
      this.$emit('onDelete', this.formData);
    },
  },
};
</script>

<style lang="scss"></style>
