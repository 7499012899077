<template>
  <div v-if="caseInfor">
    <audit-trail-information :audit-trial-information="caseInfor"></audit-trail-information>
    <audit-trail-list-and-filter :caseId="caseInfor.caseId" :caseInfor="caseInfor"></audit-trail-list-and-filter>
    <div class="md-layout lims-form">
      <div class="md-layout lims-form-row">
        <div class="md-layout-item md-size-100 md-small-size-100 lims-form-actions">
          <md-button @click="onBackToCase()" class="lims-form-button">
            {{ $t('global/button/button.backToCase') }}
          </md-button>
          <md-button
            @click="downloadAuditTrail()"
            :disabled="isProcessing"
            class="md-button md-primary lims-form-button md-theme-default"
          >
            {{ $t('global/button/button.downloadAuditTrail') }}
          </md-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AuditTrailInformation from '@/pages/Case/CaseManagement/AuditTrail/AuditTrailInformation';
import AuditTrailListAndFilter from '@/pages/Case/CaseManagement/AuditTrail/AuditTrailListAndFilter';
import { auditService, caseFormService } from '@/services';
import { APP_ROUTES } from '@/core/constants';

export default {
  components: {
    AuditTrailInformation,
    AuditTrailListAndFilter,
  },
  created() {
    this.getCaseById(this.caseId);
  },
  props: {
    caseId: {
      require: false,
      default: null,
    },
  },
  data() {
    return {
      caseInfor: null,
      isProcessing: false,
    };
  },
  methods: {
    async getCaseById(caseId) {
      if (caseId) {
        const { data } = await caseFormService.findOne(caseId, false);
        this.caseInfor = data;
      }
    },
    onBackToCase() {
      this.$router.push(`${APP_ROUTES.CASE_EDIT}/${this.caseId}`);
    },
    async downloadAuditTrail() {
      this.isProcessing = true;
      const res = await auditService.downloadAuditTrail({
        objectType: 'Case',
        objectKey: this.caseId,
      });
      this.isProcessing = false;
      if (res.error) {
        return this.$alertError('Err: ' + res.error);
      } else {
        this.exportFile(res, 'AuditLogs');
      }
    },
    exportFile(response, filename) {
      var fileURL = window.URL.createObjectURL(new Blob([response.data]));
      var fileLink = document.createElement('a');
      fileLink.href = fileURL;
      fileLink.download = filename + '.csv';
      document.body.appendChild(fileLink);
      fileLink.click();
    },
  },
};
</script>
