const DashboardLayout = () => import('@/layouts/DashboardLayout.vue');
import ImportCase from '@/pages/Case/ImportCase/ImportCase.vue';
import ConfirmUpload from '@/pages/Case/ImportCase/ConfirmUpload/ConfirmUpload.vue';

import guards from './guards';

export default {
  path: '/import-case',
  component: DashboardLayout,
  meta: {
    zone: 'private',
    rbac: 'cases',
  },
  beforeEnter: guards.checkAuthorizedGuard,
  children: [
    {
      path: '',
      name: 'ImportCase',
      component: ImportCase,
      meta: {
        zone: ['Case_CaseImport_Create'],
      },
    },
    {
      path: '/import-case/confirm-upload',
      name: 'ConfirmUpload',
      component: ConfirmUpload,
      meta: {
        zone: ['Case_CaseImport_Create'],
      },
    },
  ],
};
