<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit, invalid }">
    <form class="md-layout lims-form form-login" @submit.prevent="handleSubmit()">
      <md-dialog :md-close-on-esc="true" :md-active.sync="isVisible" @md-clicked-outside="close" class="lims-modal">
        <div class="dialog-icon-close" @click="close"><md-icon>close</md-icon></div>
        <md-dialog-title>{{ $t('components/ModalBulkActionProblem.title') }}</md-dialog-title>
        <div class="lims-form dialog-content">
          <template v-if="listItemValidated.listFailure && listItemValidated.listFailure.length > 0">
            <p class="text-center">{{ $t('components/ModalBulkActionProblemRequest.text') }}</p>
            <ul class="list-cases">
              <li v-for="item in listItemValidated.listFailure" :key="item.caseAnonymiseRequestId">
                {{ item.requesterName }}, {{ item.caseReference }}
              </li>
            </ul>
          </template>
          <template v-if="listItemValidated.listSuccess && listItemValidated.listSuccess.length > 0">
            <p class="text-center">{{ $t('components/ModalBulkActionProblemRequest.text2') }}</p>
            <ul class="list-cases">
              <li v-for="item in listItemValidated.listSuccess" :key="item.caseAnonymiseRequestId">
                {{ item.requesterName }}, {{ item.caseReference }}
              </li>
            </ul>
          </template>
          <!-- <p v-else class="text-center" v-html="$t('components/ModalBulkActionProblem.text3')"></p> -->
        </div>
        <md-dialog-actions>
          <template>
            <md-button @click="close" class="lims-form-button">{{ $t('global/button/button.cancel') }}</md-button>
            <md-button @click="onContinue" :disabled="invalid" class="lims-form-button md-primary">{{
              $t('global/button/button.continue')
            }}</md-button>
          </template>
          <!-- <md-button @click="close" class="lims-form-button md-primary">{{
            $t('global/button/button.backToList')
          }}</md-button> -->
        </md-dialog-actions>
      </md-dialog>
    </form>
  </ValidationObserver>
</template>

<script>
import modalMixins from './modal.mixins';

export default {
  name: 'ModalBulkActionProblemRequest',
  mixins: [modalMixins],
  props: {
    listItemValidated: {
      require: true,
    },
  },
  methods: {
    onContinue() {
      this.close();
      this.$emit('onContinue', this.listItemValidated.listSuccess);
    },
  },
};
</script>

<style lang="scss" scoped>
.list-cases {
  display: table;
  margin: 10px auto;
  padding-left: 0;
}
</style>
