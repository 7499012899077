<template>
  <md-dialog :md-close-on-esc="true" :md-active.sync="isVisible" @md-clicked-outside="onCancel" class="lims-modal">
    <div class="dialog-icon-close" @click="onCancel"><md-icon>close</md-icon></div>
    <div class="lims-form dialog-content">
      <p class="text-center">{{ $t('components/lims/modals/ModalVerifyCode.enterTheVerification') }} {{ option }}</p>
      <lims-field :model="formData" :schema="checkCodeSchema" field="code" :view-mode="'only'">
        <md-input slot="field" @keyup.enter="onVerify" v-model="formData.code"></md-input>
      </lims-field>
    </div>
    <md-dialog-actions>
      <md-button @click="onCancel" class="lims-form-button">{{ $t('global/button/button.cancel') }}</md-button>
      <md-button :disabled="isDisableVerify()" @click="onVerify" class="lims-form-button md-primary">{{
        $t('pages/auth/verifyTwoFa/label.verify')
      }}</md-button>
    </md-dialog-actions>
    <div v-if="option !== 'Authy app'" class="text-center">
      <p>
        {{ $t('pages/auth/verifyTwoFa/label.didNotReceiveACode') }}
        <span class="text-underline" @click="onClickResendCode" :class="isDisableResendCode ? 'un-clickable-span' : ''">
          {{ $t('pages/auth/verifyTwoFa/label.resendCode') }}</span
        >
      </p>
      <p v-if="isDisableResendCode">
        {{ $t('components/lims/modals/ModalVerifyCode.wait') }}
        <span class="set-color-countdown-time">{{ resendCodeCountdownTime }}</span>
        {{ $t('components/lims/modals/ModalVerifyCode.secondsToResend') }}
      </p>
    </div>
  </md-dialog>
</template>

<script>
import modalMixins from './modal.mixins';
import { getCheckCodeSchema } from '@/schemas/check-code.schema';

export default {
  name: 'modal-verify-code',

  mixins: [modalMixins],

  props: {
    option: {
      type: String,
      required: true,
      default: null,
    },
    isWrongCode: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      formData: {
        code: '',
      },
      isDisableResendCode: false,
      resendCodeCountdownTime: 0,
    };
  },

  computed: {
    checkCodeSchema() {
      return getCheckCodeSchema(this.isWrongCode);
    },
  },

  watch: {
    isDisableResendCode() {
      if (this.isDisableResendCode) {
        this.resendCodeCountdownTime = 61;
        this.resendCodeCountdownTime--;
      }
    },

    resendCodeCountdownTime() {
      if (this.resendCodeCountdownTime > 0) {
        setTimeout(() => {
          this.resendCodeCountdownTime--;
        }, 1000);
      } else {
        this.isDisableResendCode = false;
      }
    },
  },

  methods: {
    onVerify() {
      this.$emit('onVerify', this.formData);
    },

    isDisableVerify() {
      return this.formData.code.length !== 6;
    },

    onClickResendCode() {
      if (!this.isDisableResendCode) {
        this.isDisableResendCode = true;
        this.$emit('onResendCode');
      }
    },

    onCancel() {
      this.resendCodeCountdownTime = 0;
      this.formData.code = '';
      this.close();
    },
  },
};
</script>

<style lang="scss">
.un-clickable-span {
  cursor: default;
  color: #3c4858;
}
.set-color-countdown-time {
  color: red;
}
</style>
