import { caseListService } from '@/services';
import { downloadBlob, extractFileNameFromResponseHeader } from '@/core/helpers';
import myAccountService from '@/services/myAccount.service';
import { isObject } from 'lodash';

export default {
  data: function () {
    return {
      numberOfUnreadReportedCases: 0,
    };
  },

  methods: {
    async getNumberOfUnreadReportedCases() {
      const res = await caseListService.getExportBackportingNumberOfUnreadCases();
      const { data, error } = res;
      if (error) {
        // this.$alertError(error);
        this.numberOfUnreadReportedCases = 0;
        this.isAllowedExportUnreadCases = false;
        return;
      }
      this.isAllowedExportUnreadCases = true;
      this.numberOfUnreadReportedCases = isObject(data) ? data.payload : data || 0;
    },
    async getExportTemplateOption() {
      const res = await myAccountService.getMyProfile(this.$store.getters['auth/userId']);
      const { err, data } = res;
      if (err) {
        return this.$alertError(res.err + ' error');
      }
      const { enableTalkingPoint, enableText } = data.option;
      return {
        enableTalkingPoint,
        enableText,
      };
    },

    async onExportUnreadCases() {
      const res = await caseListService.exportBackporting();
      const downloadFileName = extractFileNameFromResponseHeader(res.headers);
      if (res.err && res.status === 400) {
        return this.$refs.modalWarningNoUnreadReportedCases.open();
      }
      if (res.err) {
        return this.$alertError(res.err + ' error');
      }
      downloadBlob(res.data, downloadFileName);
      this.$nextTick(() => {
        this.search(this.query);
        this.getNumberOfUnreadReportedCases();
      });
    },
  },
};
