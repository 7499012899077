<template>
  <div class="legend-search-wrapper">
    <div @click="isShow = !isShow" class="toggle-legend-btn">
      <span v-if="isShow">{{ $t('pages/case/AlwManagement/List/LegendSearch.hide') }}</span>
      <span v-else>{{ $t('pages/case/AlwManagement/List/LegendSearch.show') }}</span>
      <md-icon :class="!isShow ? 'is-hide' : ''">expand_less</md-icon>
    </div>
    <div class="legend-list-wrapper" v-show="isShow">
      <ul class="legend-list">
        <li class="red-color">{{ $t('pages/case/AlwManagement/List/LegendSearch.Urgent') }}</li>
        <li>
          <md-icon class="status-disabled">adjust</md-icon>
          {{ $t('pages/case/AlwManagement/List/LegendSearch.Requested') }}
        </li>
        <li>
          <md-icon class="status-enabled">adjust</md-icon>
          {{ $t('pages/case/AlwManagement/List/LegendSearch.Completed') }}
        </li>
        <li>
          <md-icon>adjust</md-icon>
          {{ $t('pages/case/AlwManagement/List/LegendSearch.Cancelled') }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    this.userType = this.$store.getters['auth/userType'];
  },

  computed: {},

  data() {
    return {
      isShow: true,
    };
  },
};
</script>
<style lang="scss"></style>
