<template>
  <ValidationObserver ref="diagnosis" v-slot="{ handleSubmit }">
    <form class="md-layout lims-form" @submit.prevent="handleSubmit()">
      <div class="form-wrapper">
        <lims-collapse-with-information
          v-for="(caseReportHistory, index) in formData.caseReportHistories"
          :key="index"
          :wrapperClass="'case-collapse diagnosis-collapse'"
          :collapse="collapseReport"
          :information="getInformationByEntityId(caseReportHistory.caseSpecimens[0].caseReportId)"
          icon="keyboard_arrow_down"
        >
          <template slot="md-collapse-pane-1">
            <div v-if="caseReportHistory.isOneMicroscopyForAllSpecimens">
              <div class="md-layout lims-form-row">
                <div class="md-layout-item md-size-100 md-small-size-100">
                  <div class="static-text">{{ getSpecimenHeader(caseReportHistory.caseSpecimens) }}</div>
                </div>
              </div>
              <div class="md-layout lims-form-row">
                <div class="md-layout-item md-size-33 md-small-size-100">
                  <lims-field :model="formData" :schema="caseFormSchema.diagnosis" field="snomedT"> </lims-field>
                  <div v-if="caseReportHistory.caseSpecimens[0].snomedT" class="static-text line-through">
                    {{ caseReportHistory.caseSpecimens[0].snomedT.fieldItemName }}
                  </div>
                </div>
                <div class="md-layout-item md-size-33 md-small-size-100">
                  <lims-field :model="formData" :schema="caseFormSchema.diagnosis" field="snomedP"> </lims-field>
                  <div v-if="caseReportHistory.caseSpecimens[0].snomedP" class="static-text line-through">
                    {{ caseReportHistory.caseSpecimens[0].snomedP.fieldItemName }}
                  </div>
                </div>
                <div class="md-layout-item md-size-33 md-small-size-100">
                  <lims-field :model="formData" :schema="caseFormSchema.diagnosis" field="snomedM"> </lims-field>
                  <div v-if="caseReportHistory.caseSpecimens[0].snomedM" class="static-text line-through">
                    {{ caseReportHistory.caseSpecimens[0].snomedM.fieldItemName }}
                  </div>
                </div>
              </div>
              <div class="md-layout lims-form-row">
                <div class="md-layout-item md-size-100 md-small-size-100">
                  <lims-field :model="formData" :schema="caseFormSchema.diagnosis" field="microscopy"> </lims-field>
                  <div
                    class="static-text line-through"
                    v-lims-text-area="caseReportHistory.caseSpecimens[0].microscopy"
                  ></div>
                </div>
              </div>
              <div v-if="caseReportHistory.caseSpecimens[0].caseDataset" class="caseDataset">
                <div
                  v-for="(control, controlIndex) in caseReportHistory.caseSpecimens[0].caseDataset.controls"
                  :key="'A' + controlIndex"
                  class="md-layout"
                >
                  <div class="md-layout-item md-size-25">
                    <label>{{ control.label }}</label>
                  </div>
                  <div class="md-layout-item md-size-75">
                    <div class="static-text line-through" style="padding: 0">{{ control.selectValue }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <div v-for="(caseSpecimen, index) in caseReportHistory.caseSpecimens" :key="'X' + index">
                <div class="md-layout lims-form-row">
                  <div class="md-layout-item md-size-100 md-small-size-100">
                    <label>{{ specimenName(index) }}</label>
                    <div class="static-text line-through">
                      {{ caseSpecimen.specimentTypeName }}
                    </div>
                  </div>
                </div>
                <div class="md-layout lims-form-row">
                  <div class="md-layout-item md-size-33 md-small-size-100">
                    <lims-field :model="formData" :schema="caseFormSchema.diagnosis" field="snomedT"> </lims-field>
                    <div v-if="caseSpecimen.snomedT" class="static-text line-through">
                      {{ caseSpecimen.snomedT.fieldItemName }}
                    </div>
                  </div>
                  <div class="md-layout-item md-size-33 md-small-size-100">
                    <lims-field :model="formData" :schema="caseFormSchema.diagnosis" field="snomedP"> </lims-field>
                    <div v-if="caseSpecimen.snomedP" class="static-text line-through">
                      {{ caseSpecimen.snomedP.fieldItemName }}
                    </div>
                  </div>
                  <div class="md-layout-item md-size-33 md-small-size-100">
                    <lims-field :model="formData" :schema="caseFormSchema.diagnosis" field="snomedM"> </lims-field>
                    <div v-if="caseSpecimen.snomedM" class="static-text line-through">
                      {{ caseSpecimen.snomedM.fieldItemName }}
                    </div>
                  </div>
                </div>
                <div class="md-layout lims-form-row">
                  <div class="md-layout-item md-size-100 md-small-size-100">
                    <lims-field :model="formData" :schema="caseFormSchema.diagnosis" field="microscopy"> </lims-field>
                    <div class="static-text line-through" v-lims-text-area="caseSpecimen.microscopy"></div>
                  </div>
                </div>
                <div v-if="caseSpecimen.caseDataset" class="caseDataset">
                  <div
                    v-for="(control, controlIndex) in caseSpecimen.caseDataset.controls"
                    :key="'A' + controlIndex"
                    class="md-layout"
                  >
                    <div class="md-layout-item md-size-25">
                      <label>{{ control.label }}</label>
                    </div>
                    <div class="md-layout-item md-size-75">
                      <div class="static-text line-through" style="padding: 0">{{ control.selectValue }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="md-layout lims-form-row">
              <div class="md-layout-item md-size-100 md-small-size-100">
                <lims-field :model="formData" :schema="caseFormSchema.diagnosis" field="caseCommentOrDiagnosisSummary">
                </lims-field>
                <div class="static-text line-through" v-lims-text-area="caseReportHistory.diagnosisSummary"></div>
              </div>
            </div>
            <div class="md-layout lims-form-row" v-if="formData.isUnexpectedFindings">
              <div class="md-layout-item md-size-100 md-small-size-100 same-specimen-type">
                <md-checkbox disabled v-model="formData.isUnexpectedFindings" class="lims-checkbox">
                  {{ $t('entities/case/form/diagnosis/isUnexpectedFindings') }}
                </md-checkbox>
                <lims-tooltip :content="$t('entities/case/form/diagnosis/isUnexpectedFindingsHelpText')"></lims-tooltip>
              </div>
            </div>
          </template>
        </lims-collapse-with-information>
        <div
          v-for="(caseSupplementaryReportHistory, index) in formData.caseSupplementaryReportHistories"
          :key="'Y' + index"
        >
          <lims-collapse-with-information
            v-for="(caseSupplementaryReport, index) in caseSupplementaryReportHistory.caseSupplementaryReports"
            :key="'T' + index"
            :wrapperClass="'case-collapse diagnosis-collapse'"
            :collapse="collapseSupplementaryReport"
            :information="getInformationByEntityId(caseSupplementaryReport.caseReportId)"
            icon="keyboard_arrow_down"
          >
            <template slot="md-collapse-pane-1">
              <div class="md-layout lims-form-row">
                <div class="md-layout-item md-size-100 md-small-size-100">
                  <lims-field :model="formData" :schema="caseFormSchema.diagnosis" field="snomedM"> </lims-field>
                  <div class="static-text line-through">{{ caseSupplementaryReport.snomedM }}</div>
                </div>
              </div>
              <div class="md-layout lims-form-row">
                <div class="md-layout-item md-size-100 md-small-size-100">
                  <lims-field
                    class="textarea-field"
                    :model="formData"
                    :schema="caseFormSchema.diagnosis"
                    field="microscopy"
                  >
                  </lims-field>
                  <div class="static-text line-through">
                    {{ caseSupplementaryReport.microscopy }}
                  </div>
                </div>
              </div>
              <div class="md-layout lims-form-row">
                <div class="md-layout-item md-size-100 md-small-size-100">
                  <lims-field
                    class="textarea-field"
                    :model="formData"
                    :schema="caseFormSchema.diagnosis"
                    field="caseCommentOrDiagnosisSummary"
                  >
                  </lims-field>
                  <div class="static-text line-through">
                    {{ caseSupplementaryReport.caseCommentOrDiagnosisSummarySupplementary }}
                  </div>
                </div>
              </div>
            </template>
          </lims-collapse-with-information>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
import { FormMixins } from '@/core/mixins';
import CaseMixins from '@/pages/Case/CaseManagement/Case.mixins';
import { APP_EVENTS, DROPDOWN_SHORT_NAME } from '@/core/constants';
import { getCaseFormSchema } from '@/schemas/case-form.schema';
import { mapActions, mapGetters } from 'vuex';
import LimsCollapseWithInformation from '@/components/Lims/LimsCollapseWithInformation';
import CaseSpecimenMixins, { generateSpecimenName } from '@/pages/Case/CaseManagement/CaseSpecimen.mixins';

export default {
  mixins: [FormMixins, CaseMixins, CaseSpecimenMixins],
  components: { LimsCollapseWithInformation },
  created() {
    this.fetchData();
  },
  props: {
    dataEdit: {
      type: Object,
      require: false,
    },
    formMode: {
      type: Number,
      require: true,
    },
  },
  data() {
    return {
      formData: {
        isOneMicroscopyForAllSpecimens: false,
        noOfSpecimen: null,
        diagnosisSpecimens: [],
        caseSpecimens: [],
        markCaseAsUrgent: false,
        isUnexpectedFindings: false,
        isSentMdmNotification: false,
      },
      assignedButtons: [],
      personalisedReports: [],
      snomedTList: [],
      snomedPList: [],
      snomedMList: [],
    };
  },
  computed: {
    ...mapGetters('auth', ['entityId']),
    isPathologistView() {
      return this.isPathologist();
    },
    isAdminView() {
      return this.userType === this.USER_TYPES().Administrator;
    },
    caseFormSchema: function () {
      return getCaseFormSchema(this.formMode);
    },
    collapseReport() {
      const name = 'pages/case/CaseManagement/components/Diagnosis/collapseReportTitle';
      return [this.$translate(name)];
    },
    collapseSupplementaryReport() {
      const name = 'pages/case/CaseManagement/components/DiagnosisView/collapseSupplementaryReportTitle';
      return [this.$translate(name)];
    },
    ...mapGetters('app/data', ['getDatasetByKey']),
    ...mapGetters('app/event', [APP_EVENTS.EVT_ON_CANCEL_EDIT_FORM]),
  },
  methods: {
    isPathologist() {
      return this.userType === this.USER_TYPES().Pathologist;
    },
    ...mapActions('app/event', ['removeEvent']),
    async fetchData() {
      this.userType = this.$store.getters['auth/userType'];
      this.formData.isUnexpectedFindings = this.dataEdit.isUnexpectedFindings;
      this.formData.isSentMdmNotification = this.dataEdit.isSentMdmNotification;
      this.snomedTList = this.getDatasetByKey(DROPDOWN_SHORT_NAME.SNOMED_T);
      this.snomedPList = this.getDatasetByKey(DROPDOWN_SHORT_NAME.SNOMED_P);
      this.snomedMList = this.getDatasetByKey(DROPDOWN_SHORT_NAME.SNOMED_M);
      this.formData.caseCommentOrDiagnosisSummary = this.dataEdit.diagnosisSummary || '';
      this.formData.markCaseAsUrgent = this.dataEdit.urgent || false;
      this.formData.caseSpecimens = this.dataEdit.caseSpecimens;
      this.formData.noOfSpecimen = this.dataEdit.caseSpecimens.length;
      if (this.dataEdit.caseReportHistories.length > 0) {
        this.formData.caseReportHistories = this.dataEdit.caseReportHistories.map((caseReportHistory) => {
          return {
            ...caseReportHistory,
            createdDate: this.formatDateTimeGlobal(caseReportHistory.createdDate),
            caseSpecimens: caseReportHistory.caseSpecimens.map((caseSpecimen) => {
              return this.mappingCaseSpecimen(caseSpecimen);
            }),
          };
        });
        this.formData.caseSupplementaryReportHistories = this.formData.caseReportHistories.filter(
          (caseReportHistory) => caseReportHistory.caseSupplementaryReports.length > 0,
        );
        if (this.formData.caseSupplementaryReportHistories.length > 0) {
          this.formData.caseSupplementaryReportHistories = this.formData.caseSupplementaryReportHistories.map(
            (caseSupplementaryReportHistory) => {
              return {
                ...caseSupplementaryReportHistory,
                caseSupplementaryReports: caseSupplementaryReportHistory.caseSupplementaryReports.map(
                  (caseSupplementaryReport) => {
                    return {
                      snomedM: caseSupplementaryReport.fieldItems[0]?.fieldItemName,
                      createdDate: this.formatDateTimeGlobal(caseSupplementaryReport.createdDate),
                      microscopy: caseSupplementaryReport.microscopy,
                      caseCommentOrDiagnosisSummarySupplementary: caseSupplementaryReport.caseComment,
                      isTemporary: caseSupplementaryReport.isTemporary,
                      markCaseAsUrgent: caseSupplementaryReport.markCaseAsUrgent,
                      isUnexpectedFindings: caseSupplementaryReport.isUnexpectedFindings,
                      isSentMdmNotification: caseSupplementaryReport.isSentMdmNotification,
                      caseSupplementaryReportId: caseSupplementaryReport.caseSupplementaryReportId,
                      caseReportId: caseSupplementaryReport.caseReportId,
                    };
                  },
                ),
              };
            },
          );
        }
      }
    },
    getInformationByEntityId(entityId) {
      if (this.dataEdit.casePathologistAuthoriseTrackings.length > 0) {
        let casePathologistAuthoriseTracking = this.dataEdit.casePathologistAuthoriseTrackings.find(
          (casePathologistAuthoriseTracking) => casePathologistAuthoriseTracking?.entityId === entityId,
        );
        return this.$t('entities/case/form/diagnosis/reportedInformation', {
          authoriseDate: casePathologistAuthoriseTracking?.authoriseDate
            ? this.formatDateTimeGlobal(casePathologistAuthoriseTracking?.authoriseDate)
            : '',
          pathologistFullName: casePathologistAuthoriseTracking?.pathologistFullName,
        });
      }
    },
    mappingCaseSpecimen(caseSpecimen) {
      const snomed = this.findSnomed(caseSpecimen);
      let caseDataset = caseSpecimen.caseDataset || null;
      return {
        ...caseSpecimen,
        ...snomed,
        caseDataset,
      };
    },
    findSnomed(caseSpecimen) {
      const snomedTfieldId = this.snomedTList[0]?.fieldId;
      const snomedPfieldId = this.snomedPList[0]?.fieldId;
      const snomedMfieldId = this.snomedMList[0]?.fieldId;
      const snomed = {
        snomedT: null,
        snomedB: null,
        snomedP: null,
      };
      caseSpecimen.caseSpecimenDiagnosis.map((item) => {
        const { fieldId } = item;
        if (fieldId === snomedTfieldId) {
          snomed.snomedT = item;
        }
        if (fieldId === snomedPfieldId) {
          snomed.snomedP = item;
        }
        if (fieldId === snomedMfieldId) {
          snomed.snomedM = item;
        }
      });
      return snomed;
    },
    getSpecimenHeader(specimens) {
      if (specimens && specimens.length > 0) {
        let specimenHeader = '';
        for (let i = 0; i < specimens.length; i++) {
          if (specimenHeader !== '') {
            specimenHeader += ', ';
          }
          specimenHeader +=
            this.$t('entities/case/form/diagnosis/specimen') +
            ' ' +
            generateSpecimenName(i, this.blockNamingRuleSetting);
        }
        return specimenHeader;
      }
    },
    specimenName(index) {
      const name = 'entities/case/form/diagnosis/specimen';
      return this.$translate(name) + ' ' + generateSpecimenName(index, this.blockNamingRuleSetting);
    },
  },
};
</script>
<style lang="scss"></style>
