<template>
  <form ref="form" class="list-filter ocrHistory-list-filter" v-if="filters">
    <div class="filter-input">
      <lims-select
        v-model="filters.batchIds"
        :options="dataSource.batchIdList"
        :translated="true"
        :placeholder="$t('pages/case/OcrUploadHistory/List/OcrUploadHistoryFilter/placeholder.batchID')"
      ></lims-select>
      <date-picker
        range
        v-model="filters.uploadedDate"
        format="DD/MM/YYYY"
        :lang="lang"
        :placeholder="$t('global/placeholder.dateRangePicker')"
      ></date-picker>
      <lims-select
        v-model="filters.userIds"
        :options="dataSource.userIdList"
        :translated="true"
        :placeholder="$t('pages/case/OcrUploadHistory/List/OcrUploadHistoryFilter/placeholder.user')"
      ></lims-select>
      <md-field class="input-search">
        <md-icon>search</md-icon>
        <md-input v-model="filters.search" maxlength="250"></md-input>
      </md-field>
      <md-button class="clear-btn" @click="onClear">
        {{ $t('global/button/button.clear') }}
      </md-button>
    </div>
    <div class="filter-input mg-top-24">
      <lims-select
        v-model="filters.batchStatusIds"
        :options="dataSource.batchStatusList"
        :translated="true"
        :placeholder="$t('pages/case/OcrUploadHistory/List/OcrUploadHistoryFilter/placeholder.batchStatus')"
      ></lims-select>
      <lims-chip-area :options="chipOptions" @onRemoveChip="$onRemoveChip"></lims-chip-area>
    </div>
  </form>
</template>
<script>
/* eslint-disable security/detect-object-injection */
import { ConfigMixins, ListFilterMixins } from '@/core/mixins';

import LimsChipArea from '@/components/Lims/LimsChipArea.vue';
import LimsSelect from '@/components/Lims/LimsSelect.vue';

export default {
  mixins: [ConfigMixins, ListFilterMixins],
  components: { LimsChipArea, LimsSelect },
  props: {
    fields: {
      type: Array,
      require: true,
    },
    defaultValues: {
      type: Object,
      require: true,
    },
    value: {
      type: Object,
      require: true,
    },
  },
  async created() {
    this.onCreated();
  },

  computed: {
    lang() {
      return {
        formatLocale: {
          firstDayOfWeek: 1, // SAMPLE FOR DATE-PICKER 1stDayOfTheWeek
        },
      };
    },
  },
  data() {
    return {
      filters: null,
      chipOptions: [],
      dataSource: {
        batchIdList: [],
        userIdList: [],
        batchStatusList: [],
      },
    };
  },
  watch: {
    filters: {
      handler(filterValues) {
        this.$updateChipList({ filterValues, dataSource: this.dataSource, filterFields: this.fields });
      },
      deep: true,
    },
  },
  methods: {
    async onCreated() {
      this.filters = this.value;
    },

    onClear() {
      this.$nextTick(() => {
        this.$refs.form.reset();
      });
      Object.keys(this.filters).map((k) => {
        this.filters[k] = this.defaultValues[k];
      });
    },
  },
};
</script>
<style lang="scss">
.ocrHistory-list-filter {
  .v-select {
    width: 170px;
    @media only screen and (max-width: 767px) {
      width: 100%;
    }
  }
  .mx-datepicker {
    width: 200px;
    margin: 5px 0;
    @media only screen and (max-width: 767px) {
      width: 100%;
      margin: 0;
    }
  }
  .input-search .md-input {
    width: 250px;
    @media only screen and (max-width: 767px) {
      width: 100%;
    }
  }
  .clear-btn {
    margin: 5px 0 0 5px;
    height: 34px;
  }
  .chips-area {
    min-width: calc(100% - 175px);
    margin: 5px 0 0 2px;
  }
}
</style>
