<template>
  <div class="lims-form">
    <div class="md-layout lims-form-row">
      <div class="md-layout-item md-size-25 md-small-size-100">
        <h5 class="audit-id">
          {{
            $t('pages/case/CaseManagement/AuditTrail/AuditTrailInformation/title', {
              caseReference: formData.caseReference,
            })
          }}
        </h5>
      </div>
    </div>
  </div>
</template>

<script>
import { getAuditTrailSchema } from '@/schemas/audit-trail.schema';
import { DateTimeHelper } from '@/core/helpers';

export default {
  props: {
    auditTrialInformation: {
      type: Object,
      require: false,
    },
  },
  data() {
    return {
      formData: {},
    };
  },
  computed: {
    auditTrailSchema() {
      return getAuditTrailSchema();
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.formData = this.auditTrialInformation;
    },
    formatDate(date) {
      const { timezoneValue, dateTimeAppFormat } = this.$store.getters['config/$appConfig'];
      return DateTimeHelper.formatDateTimeGlobal(date, {
        timezoneValue,
        dateTimeAppFormat,
      });
    },
  },
};
</script>
<style lang="scss">
.audit-id {
  font-weight: 500;
  font-size: 19px;
}
</style>
