<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit, invalid }">
    <md-dialog
      :md-close-on-esc="true"
      :md-active.sync="isVisible"
      @md-clicked-outside="close"
      class="lims-modal document-modal"
    >
      <div class="dialog-icon-close" @click="close"><md-icon>close</md-icon></div>
      <md-dialog-title>{{ $t('modals/ModalEditAlwResponse.modalTitle') }}</md-dialog-title>
      <form class="md-layout lims-form" @submit.prevent="handleSubmit()">
        <div class="mg-top-24">
          <div class="md-layout lims-form-row">
            <div class="md-layout-item md-size-100 md-small-size-100">
              <lims-field :model="formData" :schema="schema" field="typeName"></lims-field>
              <p class="static-text">{{ formData.typeName }}</p>
            </div>
            <div class="md-layout-item md-size-100 md-small-size-100">
              <lims-field :model="formData" :schema="schema" field="block"></lims-field>
              <p class="static-text">{{ formData.caseSpecimenBlockName }}</p>
            </div>
            <div class="md-layout-item md-size-100 md-small-size-100">
              <lims-field :model="formData" :schema="schema" field="stainName"></lims-field>
              <p class="static-text">{{ formData.stainName }}</p>
            </div>
            <div class="md-layout-item md-size-100 md-small-size-100">
              <lims-field :model="formData" :schema="schema" field="requesterComment"></lims-field>
              <p class="static-text">{{ formData.requesterComment }}</p>
            </div>
            <div v-if="formData.typeName == 'New'" class="md-layout-item md-size-100 md-small-size-100">
              <lims-field :model="formData" :schema="schema" field="respondStainFieldItems" class="textarea-field">
                <multiselect
                  slot="field"
                  v-model="formData.respondStainFieldItems"
                  :options="respondedStainList"
                  :multiple="true"
                  :show-labels="false"
                  group-values="items"
                  group-label="itemName"
                  :placeholder="$t('components/ModalCaseBatchChange.placeholderSpecialStain')"
                  track-by="fieldItemId"
                  label="fieldItemName"
                  :hide-selected="true"
                  :group-select="false"
                >
                </multiselect>
              </lims-field>
            </div>
            <div class="md-layout-item md-size-100 md-small-size-100">
              <lims-field class="textarea-field" :model="formData" :schema="schema" field="comment">
                <md-textarea slot="field" v-model="formData.comment"></md-textarea>
              </lims-field>
            </div>
          </div>
        </div>
        <md-dialog-actions>
          <md-button @click="close" class="lims-form-button">{{ $t('global/button/button.cancel') }}</md-button>
          <md-button @click="onRespond()" :disabled="invalid || isProcessing" class="lims-form-button md-primary">{{
            $t('global/button/button.respond')
          }}</md-button>
        </md-dialog-actions>
      </form>
    </md-dialog>
  </ValidationObserver>
</template>

<script>
import { mapActions } from 'vuex';
import modalMixins from './modal.mixins';
import { caseAlwService, caseFormService } from '@/services';
import { DROPDOWN_SHORT_NAME, APP_EVENTS, newAppEvent } from '@/core/constants';
import { filterDropdownListByHiddenField } from '@/core/helpers';
import { isArray } from 'lodash';

export default {
  name: 'ModalEditAlwResponse',
  mixins: [modalMixins],
  props: {},

  computed: {
    schema() {
      return {
        entity: 'modals/ModalEditAlwResponse',
        fields: {
          typeName: 'required',
          block: 'required',
          stainName: 'required',
          requesterComment: '',
          respondStainFieldItems: 'required',
          comment: '',
        },
      };
    },
  },

  data() {
    return {
      formData: {},
      respondedStainList: [],
    };
  },
  watch: {},
  methods: {
    ...mapActions('app/event', ['addEvent']),
    async open(val, laboratoryId) {
      this.isVisible = true;
      if (val) {
        this.formData = {
          ...val,
          requesterComment: val.comment,
          comment: '',
        };
      }
      if (laboratoryId) {
        this.laboratoryId = laboratoryId;
        const dropdownOptions = await caseFormService.getStainByEntityId(
          this.laboratoryId,
          [
            DROPDOWN_SHORT_NAME.H_AND_E,
            DROPDOWN_SHORT_NAME.IMMUNOS,
            DROPDOWN_SHORT_NAME.SPECIAL_STAINS,
            DROPDOWN_SHORT_NAME.ADDITIONAL_TECHNIQUE,
          ],
          this.formData?.caseALWRequestId,
        );
        if (dropdownOptions) {
          let listData = [];
          const respondedStainList = dropdownOptions.reduce((i, value) => {
            if (!value.isHide) {
              const listDataItem = {
                ...value,
                items: filterDropdownListByHiddenField(value.items),
              };
              if (listDataItem.items.length > 0) {
                listData.push(listDataItem);
              }
            }
            return listData;
          }, 0);

          this.respondedStainList = respondedStainList;

          if (this.formData.stainId) {
            this.respondedStainList.forEach((list) => {
              list.items.forEach((item) => {
                if (item.fieldItemId === this.formData.stainId) {
                  this.formData.respondStainFieldItems = item;
                }
              });
            });
          }
        }
      }
    },
    async onRespond() {
      this.isProcessing = true;
      const dataUpdate = {
        ...this.formData,
        respondStainFieldItems: isArray(this.formData.respondStainFieldItems)
          ? this.formData.respondStainFieldItems
          : [this.formData.respondStainFieldItems],
      };
      const res = await caseAlwService.updateRespondAlw(dataUpdate);
      this.isProcessing = false;
      if (res.err) {
        this.close();
        return this.$alertError(res.err + ' error');
      } else {
        this.addEvent(newAppEvent(APP_EVENTS.EVT_ON_ALW_FORM_ON_REFRESH, {}));
        this.close();
        return this.$alertSuccess(
          this.$t('pages/case/CaseManagement/components/AdditionalLabWork/alert/updateSuccessfully'),
        );
      }
    },
  },
};
</script>

<style lang="scss"></style>
