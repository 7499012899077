import { caseAlwService } from '@/services';

export default {
  methods: {
    async reloadCaseAlw(alwRequestId) {
      const { data } = await caseAlwService.getCaseDetailByAlw(alwRequestId);
      return data;
    },
  },
};
