<template>
  <form ref="form" class="user-list-filter" v-if="filters">
    <div class="filter-input">
      <lims-select
        v-model="filters.userTypeIds"
        :options="dataSource.userTypeList"
        :placeholder="$t('global/placeholder.allUserType')"
      ></lims-select>
      <lims-select
        v-model="filters.userStatusIds"
        :options="dataSource.statusList"
        :placeholder="$t('global/placeholder.allStatus')"
      ></lims-select>
      <md-field class="input-search">
        <md-icon>search</md-icon>
        <md-input
          v-model="filters.search"
          :placeholder="$t('components/UserListFilter.placeholderSearch')"
          maxlength="250"
        ></md-input>
      </md-field>
    </div>
    <lims-chip-area :options="chipOptions" @onRemoveChip="$onRemoveChip"></lims-chip-area>
    <div class="filter-action">
      <md-button @click="onClear">
        {{ $t('global/button/button.clear') }}
      </md-button>
    </div>
  </form>
</template>
<script>
/* eslint-disable security/detect-object-injection */
import { USER_STATUSES_ENUM } from '@/core/constants';
import { ConfigMixins, ListFilterMixins } from '@/core/mixins';

import LimsChipArea from '@/components/Lims/LimsChipArea.vue';
import LimsSelect from '@/components/Lims/LimsSelect.vue';

export default {
  mixins: [ConfigMixins, ListFilterMixins],
  components: { LimsChipArea, LimsSelect },
  props: {
    fields: {
      type: Array,
      require: true,
    },
    defaultValues: {
      type: Object,
      require: true,
    },
    value: {
      type: Object,
      require: true,
    },
  },
  async created() {
    this.onCreated();
  },

  computed: {},
  data() {
    return {
      filters: null,
      dataSource: {
        roleList: [],
        userTypeList: [],
        statusList: [],
      },
      chipOptions: [],
    };
  },
  watch: {
    filters: {
      handler(filterValues) {
        this.$updateChipList({ filterValues, dataSource: this.dataSource, filterFields: this.fields });
      },
      deep: true,
    },
  },
  methods: {
    async onCreated() {
      this.dataSource = await this.fetchDataSource();
      this.filters = this.value;
    },

    async fetchDataSource() {
      // load and binding datasource
      const userTypeList = this.loadUserList();
      const statusList = this.loadStatusList();
      return {
        userTypeList,
        statusList,
      };
    },

    onClear() {
      this.$nextTick(() => {
        this.$refs.form.reset();
      });
      Object.keys(this.filters).map((k) => {
        this.filters[k] = this.defaultValues[k];
      });
    },

    loadUserList() {
      const USER_TYPE_OPTIONS = this.$appConfig.userTypeOptions;
      return USER_TYPE_OPTIONS.filter(
        (e) => e.value === this.USER_TYPES().ClinicAssociate || e.value === this.USER_TYPES().LabTechnician,
      );
    },
    loadStatusList() {
      return USER_STATUSES_ENUM;
    },
  },
};
</script>
<style lang="scss">
.user-list-filter {
  .filter-input {
    display: inline-flex;
    @media only screen and (max-width: 959px) {
      display: block;
    }
  }
  .v-select {
    width: 215px;
    display: inline-block;
    margin: 5px;
    @media only screen and (max-width: 767px) {
      width: 100%;
      margin: 5px 0;
    }
  }
  .input-search {
    height: 34px;
    align-items: center;
    border: 1px solid rgba(60, 60, 60, 0.26);
    margin: 5px 0 0 5px;
    @media only screen and (max-width: 767px) {
      width: 100%;
      margin: 5px 0;
    }
    .md-icon {
      margin: 0 5px;
      &:after {
        display: none;
      }
    }
    .md-input {
      width: 310px;
      height: 32px;
      padding: 5px;
      margin: 0;
      border: transparent;
      @media only screen and (max-width: 767px) {
        width: calc(100% - 35px);
      }
    }
  }
  .input-search.md-field:after,
  .input-search.md-field:before {
    display: none;
  }
  .filter-action {
    display: flex;
    justify-content: flex-end;
    margin: 20px 0 0;
    .md-button {
      height: 32px;
      margin-left: 10px;
      @media only screen and (max-width: 767px) {
        justify-content: center;
      }
    }
  }
}
.select-for-chips .vs__selected {
  display: none;
}
.retrieve-permission-filter {
  display: flex;
  @media only screen and (max-width: 767px) {
    display: block;
  }
  .multiselect {
    width: 215px;
    display: inline-block;
    @media only screen and (max-width: 767px) {
      width: 100% !important;
      margin: 5px 0;
    }
  }
}
</style>
