<template>
  <ValidationObserver ref="form">
    <md-dialog :md-close-on-esc="true" :md-active.sync="isVisible" @md-clicked-outside="close" class="lims-modal">
      <div class="dialog-icon-close" @click="close"><md-icon>close</md-icon></div>
      <md-dialog-title>{{ $t('components/lims/modals/ModalConfirmation.title') }}</md-dialog-title>
      <div class="text-center mg-top-24" v-html="$t('components/lims/modals/ModalReassign.text')"></div>
      <div class="lims-form dialog-content">
        <lims-field :model="formData" :schema="schema" field="pathologist" class="textarea-field">
          <multiselect
            slot="field"
            v-model="formData.pathologist"
            :options="pathologistOptions"
            :multiple="false"
            :show-labels="false"
            group-values="pathologistUsers"
            group-label="pathologistGroupName"
            placeholder=""
            track-by="pathologistId"
            label="pathologistName"
            :hide-selected="false"
            :group-select="false"
          >
          </multiselect>
        </lims-field>
        <lims-field class="textarea-field" :model="formData" :schema="schema" field="reason">
          <md-textarea slot="field" v-model="formData.reason"></md-textarea>
        </lims-field>
        <input class="visible-none" type="text" />
        <lims-field :model="formData" :schema="checkCodeSchema" field="passwordForCase">
          <md-input slot="field" ref="confirmPassword" v-model="formData.password" type="password"></md-input>
        </lims-field>
      </div>
      <md-dialog-actions>
        <md-button @click="close" class="lims-form-button">{{ $t('global/button/button.cancel') }}</md-button>
        <md-button
          @click="onReassign"
          :disabled="!formData.pathologist || !formData.reason || !formData.password || isProcessing"
          class="lims-form-button md-danger"
          >{{ $t('global/button/button.reassign') }}</md-button
        >
      </md-dialog-actions>
    </md-dialog>
  </ValidationObserver>
</template>

<script>
import { caseFormService, pathCaseService } from '@/services';
import modalMixins from './modal.mixins';
import { mappingGroupPathologists } from '@/pages/Case/CaseManagement/Case.helpers';
import { getCheckCodeSchema } from '@/schemas/check-code.schema';

export default {
  mixins: [modalMixins],
  props: {
    dataCase: {
      type: Object,
      require: false,
    },
  },
  created() {
    this.fetchData();
  },
  data() {
    return {
      formData: {
        pathologist: '',
        reason: '',
        password: '',
      },
      pathologistOptions: [],
      defaultPathologist: null,
    };
  },
  computed: {
    schema() {
      return {
        entity: 'modals/ModalReassign',
        fields: {
          pathologist: 'required',
          reason: 'required',
          password: 'required',
        },
      };
    },
    checkCodeSchema() {
      return getCheckCodeSchema(this.isWrongPasswordError);
    },
  },
  methods: {
    async fetchData() {
      await this.loadPathologistListEditMode();
    },
    async loadPathologistListEditMode() {
      if (
        this.dataCase.pathologistId &&
        this.dataCase.clinicId &&
        this.dataCase.clinicianId &&
        this.dataCase.caseSpecimens[0]?.specimenTypeId &&
        this.dataCase.laboratoryId
      ) {
        const { pathologistOptions, defaultPathologist } = await this.loadPathologistUsers({
          pathologistUserId: this.dataCase.pathologistId,
          clinicId: this.dataCase.clinicId,
          clinicianId: this.dataCase.clinicianId,
          specimenTypeId: this.dataCase.caseSpecimens[0].specimenTypeId,
          laboratoryId: this.dataCase.laboratoryId,
        });

        this.pathologistOptions = pathologistOptions;
        this.formData.pathologist = defaultPathologist;
      }
    },
    async loadPathologistUsers({ pathologistUserId, clinicId, clinicianId, specimenTypeId, laboratoryId }) {
      const { error, data } = await pathCaseService.getPathologistUsersReallocate({
        pathologistUserId,
        specimenTypeId,
        clinicianId,
        clinicId,
        laboratoryId,
      });
      if (error) {
        this.$alertError(error);
      }

      return mappingGroupPathologists(data);
    },
    async onReassign() {
      this.isProcessing = true;
      try {
        const dataForm = {
          comment: this.formData.reason,
          pathologistId: this.formData.pathologist.pathologistId,
          password: this.formData.password,
        };
        const res = await caseFormService.reassignCase({
          caseId: this.dataCase.caseId,
          data: dataForm,
        });
        this.isProcessing = false;
        if (res.err) {
          return this.handleErrorMessageModal(res.err);
        }
        this.$alertSuccess(
          this.$t('components/lims/modals/ModalReassign/alert/reassign.success', {
            caseRef: this.dataCase.caseReference,
            pathologist: this.formData.pathologist.pathologistName,
          }),
        );
        this.close();
        this.$emit('onReassigned', { caseId: this.dataCase.caseId });
      } catch (errors) {
        this.isProcessing = false;
        this.$alertError(errors);
      }
    },
    close() {
      this.isVisible = false;
      this.$nextTick(() => {
        this.formData = {
          reason: '',
          pathologist: '',
          password: '',
        };
      });
    },
  },
};
</script>
