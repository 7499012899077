import { render, staticRenderFns } from "./BulkActionProblemSlide.vue?vue&type=template&id=3161c272&scoped=true&"
import script from "./BulkActionProblemSlide.vue?vue&type=script&lang=js&"
export * from "./BulkActionProblemSlide.vue?vue&type=script&lang=js&"
import style0 from "./BulkActionProblemSlide.vue?vue&type=style&index=0&id=3161c272&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3161c272",
  null
  
)

export default component.exports