<template>
  <div class="p-complete-upload">
    <md-table class="paginated-table table-striped table-hover lims-table case-list-tbl case-import-tbl">
      <md-table-toolbar>
        <div class="toolbar-row">
          <div class="md-layout btnRight">
            <lims-row-per-page :pageSize="query.pagination.pageSize" @pageSizeChanged="$pageSizeChanged" />
          </div>
          <div class="md-layout lims-form-row">
            <div class="md-layout-item md-size-100 md-small-size-100 infor-cases">
              <label>{{ $t('pages/case/ImportCase/components/ConfirmUpload/fileName') }}</label>
              <div class="static-text">{{ fileName }}</div>
            </div>
          </div>
          <div class="md-layout lims-form-row">
            <div class="md-layout-item md-size-30 md-small-size-100 infor-cases">
              <label>{{ $t('pages/case/ImportCase/components/ConfirmUpload/numberOfCases') }}</label>
              <div class="static-text">{{ totalCase }}</div>
            </div>
            <div class="md-layout-item md-size-30 md-small-size-100 infor-cases">
              <label>{{ $t('pages/case/ImportCase/components/ConfirmUpload/numberOfValidCases') }}</label>
              <div>{{ numberValidCases }}</div>
            </div>
            <div class="md-layout-item md-size-30 md-small-size-100 infor-cases red-color">
              <label>{{ $t('pages/case/ImportCase/components/ConfirmUpload/numberOfInValidCases') }}</label>
              <div class="static-text">{{ numberInvalidCases }}</div>
            </div>
          </div>
          <div class="md-layout lims-form-row">
            <div class="md-layout-item md-size-30 md-small-size-100 infor-cases">
              <label>{{ $t('pages/case/ImportCase/components/ConfirmUpload/selectCases') }}</label>
              <div class="static-text">{{ numOfCaseSelected }}</div>
            </div>
          </div>
        </div>
      </md-table-toolbar>
      <div class="table-fix-header">
        <thead>
          <md-table-row>
            <md-table-head class="checkbox-column">
              <md-checkbox v-model="selectAll"></md-checkbox>
            </md-table-head>
            <md-table-head v-for="(col, index) in colTabl" :key="index" :class="col.class">
              <div @mouseover="hoverIn(index)" @mouseout="hoverOut()" v-html="getColumnName(col)"></div>
            </md-table-head>
          </md-table-row>
        </thead>
        <tbody>
          <md-table-row v-for="(item, index) in caseImportList" :key="`case-${index}`" :class="checkRowHighlight(item)">
            <md-table-cell class="checkbox-column">
              <md-checkbox v-if="item.isValidInput" v-model="item.isImportSelected" class="lims-checkbox"></md-checkbox>
            </md-table-cell>
            <md-table-cell>{{ item.lineNumber }}</md-table-cell>
            <md-table-cell>{{ patientName(item.firstName, item.lastName) }}</md-table-cell>
            <md-table-cell>{{ item.laboratoryReference }}</md-table-cell>
            <md-table-cell>{{ item.hospitalReference }}</md-table-cell>
            <md-table-cell>{{ convertName(item.clinicianName) }}</md-table-cell>
            <md-table-cell>{{ convertName(item.laboratoryName) }}</md-table-cell>
            <md-table-cell>{{ convertName(item.pathologistName) }}</md-table-cell>
            <md-table-cell>{{ convertName(item.clinicName) }}</md-table-cell>
            <md-table-cell class="status-column">
              <md-button v-if="!item.isValidInput" class="md-just-icon md-simple">
                <md-icon>info</md-icon>
                <md-tooltip md-direction="bottom" class="tooltip-align-left">
                  <div v-html="errorCaseImport(item.errorMessages)"></div>
                </md-tooltip>
              </md-button>
              <template v-else> {{ item.caseStatusName }}</template>
            </md-table-cell>
          </md-table-row>
        </tbody>
      </div>
    </md-table>
    <lims-pagination :total-item="totalCase" :pagination="query.pagination"> </lims-pagination>
    <div class="md-layout-item md-size-100 md-small-size-100 comfirm-upload-action mg-top-24">
      <md-button @click="onCancel()" class="md-button lims-form-button md-theme-default">
        {{ $t('global/button/button.cancel') }}
      </md-button>
      <md-button
        :disabled="numOfCaseSelected == 0"
        @click="onImportCase()"
        class="md-button md-primary lims-form-button md-theme-default"
      >
        {{ $t('pages/case/ImportCase/components/ConfirmUpload/button.importCases') }}
      </md-button>
    </div>
  </div>
</template>

<script>
import CaseImportListMixin from '@/pages/Case/ImportCase/List/CaseImportList.mixins';
import LimsRowPerPage from '@/components/Lims/LimsRowPerPage';
import LimsPagination from '@/components/Lims/LimsPagination';
import { caseImportService } from '@/services';
import { ListWithFilterMixins } from '@/core/mixins';

export default {
  name: 'CompleteUploadList',
  mixins: [CaseImportListMixin, ListWithFilterMixins],
  components: {
    LimsRowPerPage,
    LimsPagination,
  },
  created() {
    this.getDatacaseImport();
  },
  computed: {
    selectAll: {
      get: function () {
        const caseValid = this.caseImportList.filter((item) => item.isValidInput);
        const caseSelected = this.caseImportList.filter((item) => item.isImportSelected);
        return this.caseImportList ? caseValid.length == caseSelected.length : false;
      },
      set: function (value) {
        this.caseImportList.forEach(function (item) {
          if (item.isValidInput) {
            item.isImportSelected = value ? true : false;
          }
        });
      },
    },
  },
  watch: {
    'query.pagination.pageSize': {
      handler: function (val) {
        if (val) {
          this.caseImportList = this.onPaginate(this.caseImportListTemp, val, this.query.pagination.pageNumber);
        }
      },
      deep: true,
    },
    'query.pagination.pageNumber': {
      handler: function (val) {
        if (val) {
          this.caseImportList = this.onPaginate(this.caseImportListTemp, this.query.pagination.pageSize, val);
        }
      },
      deep: true,
    },
    caseImportList: {
      handler: function (val) {
        if (val) {
          const caseSelected = this.caseImportList.filter((item) => item.isImportSelected);
          this.numOfCaseSelected = caseSelected ? caseSelected.length : 0;
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      colTabl: [
        {
          name: 'pages/case/ImportCase/list.row',
          sort: false,
          class: 'row-column',
        },
        {
          name: 'pages/case/ImportCase/list.patient',
          key: 'patientFullname',
          sort: false,
          class: 'patient-column',
        },
        {
          name: 'pages/case/ImportCase/list.labRef',
          key: 'labReference',
          sort: false,
          class: 'labRef-column',
        },
        {
          name: 'pages/case/ImportCase/list.hospitalRef',
          key: 'hospitalReference',
          sort: false,
          class: 'hospitalRef-column',
        },
        {
          name: 'pages/case/ImportCase/list.clinician',
          key: 'clinicianName',
          sort: false,
          class: 'clinician-column',
        },
        {
          name: 'pages/case/ImportCase/list.laboratory',
          key: 'laboratory',
          sort: false,
          class: 'laboratory-column',
        },
        {
          name: 'pages/case/ImportCase/list.pathologist',
          key: 'pathologistName',
          sort: false,
          class: 'pathologist-column',
        },
        {
          name: 'pages/case/ImportCase/list.hospital',
          key: 'hospital',
          sort: false,
          class: 'hospital-column',
        },
        {
          name: 'global/pages/list.status',
          key: 'statusName',
          sort: false,
          class: 'status-column',
        },
      ],

      fileName: '',
      numberValidCases: 0,
      numberInvalidCases: 0,
      numOfCaseSelected: 0,
      totalCase: 0,
      caseImportList: [],
      caseImportListTemp: [],
    };
  },
  methods: {
    checkRowHighlight(item) {
      if (!item.isValidInput) {
        return 'red-color';
      }
      return '';
    },

    getDatacaseImport() {
      const caseImportData = JSON.parse(sessionStorage.getItem('caseImport'));
      if (caseImportData) {
        this.caseImportListTemp = caseImportData.importCases.map((item) => {
          return {
            ...item,
            isImportSelected: false,
          };
        });

        const listCaseSuccess = [];
        const listCaseFailure = [];

        this.caseImportListTemp.forEach(function (item) {
          if (item.isValidInput) {
            listCaseSuccess.push(item);
          } else {
            listCaseFailure.push(item);
          }
        });
        this.fileName = caseImportData.fileName;
        this.numberValidCases = listCaseSuccess.length;
        this.numberInvalidCases = listCaseFailure.length;
        this.totalCase = this.numberValidCases + this.numberInvalidCases;

        this.caseImportList = this.onPaginate(
          this.caseImportListTemp,
          this.query.pagination.pageSize,
          this.query.pagination.pageNumber,
        );
      }
    },

    patientName(firstName, lastName) {
      const patientFirstName = firstName ? firstName : '';
      const patientLastName = lastName ? lastName : '';
      return patientFirstName + ' ' + patientLastName;
    },

    convertName(str) {
      if (!str) {
        return '';
      }
      const name = str.substr(0, str.indexOf('(')).trim();
      return name;
    },

    errorCaseImport(errorMessagesList) {
      let err = '';
      if (errorMessagesList) {
        Object.entries(errorMessagesList).forEach(([key, val]) => {
          if (val.length > 0) {
            err += `<b>${key}</b>: <br>` + val.join('<br/>') + '<br>';
          }
        });
      }
      return err;
    },

    async onImportCase() {
      const res = await caseImportService.importCase({
        cases: this.caseImportList,
      });
      if (res.err) {
        return this.$alertError(res.err + ' error');
      } else {
        this.$emit('caseImportSuccess', res.data);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.infor-cases {
  display: inline-flex;
  align-items: center;
  label {
    width: 170px;
  }
}
</style>
