<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit, invalid }">
    <form class="md-layout lims-form form-login" @submit.prevent="handleSubmit()">
      <md-dialog :md-close-on-esc="true" :md-active.sync="isVisible" @md-clicked-outside="close" class="lims-modal">
        <div class="dialog-icon-close" @click="close"><md-icon>close</md-icon></div>
        <md-dialog-title>{{ $t('modals/ModalSelectPathologist.modalTitle') }}</md-dialog-title>
        <div class="dialog-content">
          <lims-field :model="formData" :schema="schema" field="selectOption" viewMode="only">
            <v-select
              slot="field-outside"
              :placeholder="$t('modals/ModalSelectPathologist.placeholderEntityType')"
              :options="pathologistOptions"
              v-model="formData.selectOption"
            >
              <template #option="{ label }">{{ label }}</template>
              <template #selected-option="{ label }">{{ label }}</template>
            </v-select>
          </lims-field>
        </div>
        <md-dialog-actions>
          <md-button @click="close" class="lims-form-button">{{ $t('global/button/button.cancel') }}</md-button>
          <md-button @click="onClick" :disabled="invalid" class="lims-form-button md-primary">{{
            $t('global/button/button.continue')
          }}</md-button>
        </md-dialog-actions>
      </md-dialog>
    </form>
  </ValidationObserver>
</template>

<script>
import modalMixins from './modal.mixins';
import { DropdownService } from '@/services';
import { DROPDOWN_SHORT_NAME } from '@/core/constants';

export default {
  name: 'ModalSelectPathologist',
  mixins: [modalMixins],
  props: {
    errorMessage: {
      type: String,
      required: false,
      default: '',
    },
  },
  created() {
    this.fetchData();
  },
  data() {
    return {
      formData: {
        selectOption: '',
      },
      pathologistOptions: [],
    };
  },
  computed: {
    schema() {
      return {
        entity: 'modals/ModalSelectPathologist',
        fields: {
          selectOption: 'required',
        },
      };
    },
    selectOptions() {
      return this.dummyData;
    },
  },
  methods: {
    async fetchData() {
      const dropdownOptions = await DropdownService.getDropdownByShortNames([DROPDOWN_SHORT_NAME.PATHOLOGIST]);
      this.pathologistOptions = dropdownOptions[DROPDOWN_SHORT_NAME.PATHOLOGIST].map((item) => {
        return {
          ...item,
          value: item.id,
          label: item.text,
        };
      });
    },
    onSelect() {},
    onClick() {
      if (this.formData.selectOption) {
        this.$emit('onSelect', {
          ...this.formData,
        });
      }
    },
  },
};
</script>

<style lang="scss"></style>
