<template>
  <div v-if="datasetResource">
    <dataset-form :formMode="formMode" :datasetResource="datasetResource"></dataset-form>
  </div>
</template>

<script>
import DatasetForm from '@/pages/Dataset/Forms/DatasetForm.vue';
import datasetService from '@/services/dataset.service';
import { ResetHeadingTitleMixins } from '@/core/mixins';
import { APP_ROUTES, FORM_MODES } from '@/core/constants';

export default {
  mixins: [ResetHeadingTitleMixins],
  components: {
    DatasetForm,
  },
  props: {
    datasetId: {
      type: String,
      required: true,
    },
    formMode: {
      type: Number,
      required: true,
    },
  },
  computed: {
    isAdminView() {
      return this.userType === this.USER_TYPES().Administrator;
    },
  },
  async created() {
    this.userType = this.$store.getters['auth/userType'];
    const { data, error } = await datasetService.getDetailDataset(this.datasetId);
    if (error) {
      this.$alertError(error);
    } else {
      this.datasetResource = data;
      if (this.isAdminView) {
        return;
      }
      if (this.formMode == FORM_MODES.EDIT && !data.isEnableManageDataset) {
        this.$router.push(APP_ROUTES.ERROR_FORBIDDEN);
      }
    }
  },
  data() {
    return {
      datasetResource: null,
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped></style>
