<template>
  <div>
    <div class="md-layout lims-form">
      <div class="form-wrapper">
        <template v-if="isShowPendingRequest(formData)">
          <div class="md-layout lims-form-row">
            <div class="md-layout-item md-size-100 md-small-size-100">
              <lims-field :model="formData" :schema="caseFormSchema" field="comment" class="textarea-field">
                <md-textarea
                  slot="field"
                  v-model="formData.comment"
                  :disabled="viewMode || dataEdit.isDeleted"
                  type="text"
                  :class="commentRequired ? 'isErr' : ''"
                ></md-textarea>
              </lims-field>
            </div>
          </div>
          <div v-if="formMode === EDIT_MODE && !dataEdit.isDeleted" class="md-layout lims-form-row">
            <div class="md-layout-item md-size-100 md-small-size-100 lims-form-actions case-block-action">
              <md-button
                @click="onAgree()"
                :disabled="isProcessingAgree"
                class="md-button md-primary lims-form-button md-theme-default"
              >
                {{ $t('global/button/button.agree') }}
              </md-button>
              <md-button
                @click="onDisagree()"
                :disabled="isProcessingDisagree"
                class="md-button md-danger lims-form-button md-theme-default"
              >
                {{ $t('global/button/button.Disagree') }}
              </md-button>
            </div>
          </div>
        </template>

        <template v-if="isShowDoneRequest(formData)">
          <div class="md-layout lims-form-row">
            <div class="md-layout-item md-size-33 md-small-size-100">
              <label>
                {{ $t('pages/case/CaseManagement/components/DoubleReporting/partnerPathologist') }}
              </label>
              <div class="static-text">{{ formData.partnerName }}</div>
            </div>
            <div class="md-layout-item md-size-33 md-small-size-100">
              <label>
                {{ $t('pages/case/CaseManagement/components/DoubleReporting/response') }}
              </label>
              <div class="static-text">{{ formData.statusName }}</div>
            </div>
            <div class="md-layout-item md-size-33 md-small-size-100">
              <label>
                {{ $t('pages/case/CaseManagement/components/DoubleReporting/date') }}
              </label>
              <div class="static-text">{{ formatDateTimeGlobal(formData.date) }}</div>
            </div>
          </div>
          <div class="md-layout lims-form-row">
            <div class="md-layout-item md-size-100 md-small-size-100">
              <label>
                {{ $t('pages/case/CaseManagement/components/DoubleReporting/comment') }}
              </label>
              <div class="static-text">
                {{ formData.comment }}
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { FormMixins } from '@/core/mixins';
import CaseMixins from '@/pages/Case/CaseManagement/Case.mixins';
import { pathCaseService } from '@/services';
import { APP_ROUTES } from '@/core/constants';
import { mapGetters } from 'vuex';
import { cloneDeep } from 'lodash';

export default {
  mixins: [FormMixins, CaseMixins],
  created() {
    this.fetchData();
  },
  props: {
    formMode: {
      type: Number,
      require: true,
    },
    dataEdit: {
      type: Object,
      require: false,
    },
  },
  computed: {
    ...mapGetters('auth', ['userId']),
  },
  data() {
    return {
      formData: {},
      commentRequired: false,
      isProcessingAgree: false,
      isProcessingDisagree: false,
    };
  },
  methods: {
    async fetchData() {
      if (this.dataEdit.caseDoubleReportResponses && this.dataEdit.caseDoubleReportResponses.length > 0) {
        this.formData = cloneDeep(this.dataEdit.caseDoubleReportResponses[0]);
      }
    },
    async onAgree() {
      this.isProcessingAgree = true;
      const res = await pathCaseService.agreeDoubleReport(
        this.formData.caseDoubleReportResponseId,
        this.formData.comment,
      );
      this.isProcessingAgree = false;
      if (res.error) {
        this.$alertErrorServerSide(res.error);
      } else {
        this.$alertSuccess(this.$t('pages/case/CaseManagement/components/DoubleReporting/agree.success'));
        setTimeout(() => {
          this.$router.push(APP_ROUTES.CASE);
        }, 1000);
      }
    },
    async onDisagree() {
      this.isProcessingDisagree = true;
      if (this.formData.comment) {
        this.commentRequired = false;
        const res = await pathCaseService.disagreeDoubleReport(
          this.formData.caseDoubleReportResponseId,
          this.formData.comment,
        );
        this.isProcessingDisagree = false;
        if (res.error) {
          this.$alertErrorServerSide(res.error);
        } else {
          this.$alertSuccess(this.$t('pages/case/CaseManagement/components/DoubleReporting/disagree.success'));
          setTimeout(() => {
            this.$router.push(APP_ROUTES.CASE);
          }, 1000);
        }
      } else {
        this.commentRequired = true;
        this.isProcessingDisagree = false;
        this.$alertError(this.$t(`global/errors/message`));
      }
    },
    isShowPendingRequest(request) {
      return request.statusName == 'Pending' && request.partnerId == this.userId;
    },
    isShowDoneRequest(request) {
      return request.statusName != 'Pending';
    },
  },
};
</script>

<style lang="scss"></style>
