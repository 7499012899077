/* eslint-disable security/detect-object-injection */

import Vue from 'vue';

// initial state
const state = () => ({
  dataSetData: null,
});

// getters
const getters = {
  getDataSetData: (state) => {
    return state.dataSetData ? state.dataSetData : null;
  },
};

// actions
const actions = {
  copyDataSetData({ commit }, dataSetData) {
    commit('saveDataSetData', dataSetData);
  },
};

// mutations
const mutations = {
  saveDataSetData: (state, dataSetData) => {
    Vue.set(state, 'dataSetData', dataSetData);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
