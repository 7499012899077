<template>
  <lims-block :cardText="false" class="list-page">
    <div slot="blockContent">
      <div class="md-layout lims-form-row" v-if="query">
        <ocr-upload-history-list :ocrUploadHistoryList="items">
          <md-table-toolbar slot="toolbar">
            <div class="toolbar-row">
              <div class="field-rows-per-page">
                <lims-row-per-page :pageSize="query.pagination.pageSize" @pageSizeChanged="$pageSizeChanged" />
              </div>
              <div class="field-search">
                <ocr-upload-history-filter
                  v-model="query.filters"
                  :defaultValues="defaultValues"
                  :fields="fields"
                  @onResetFilters="onResetFilters"
                ></ocr-upload-history-filter>
              </div>
            </div>
            <div class="toolbar-row"><legend-seach></legend-seach></div>
            <lims-pagination ref="pager" :total-item="totalItem" :pagination="query.pagination"></lims-pagination>
          </md-table-toolbar>
        </ocr-upload-history-list>
        <lims-pagination :total-item="totalItem" :pagination="query.pagination"></lims-pagination>
      </div>
    </div>
  </lims-block>
</template>

<script>
import { ConfigMixins, ListWithFilterMixins } from '@/core/mixins';
import OcrUploadHistoryList from '@/pages/Case/OcrUploadHistory/List/OcrUploadHistoryList.vue';
import OcrUploadHistoryFilter from '@/pages/Case/OcrUploadHistory/List/OcrUploadHistoryFilter.vue';
import LegendSeach from '@/pages/Case/OcrUploadHistory/List/LegendSeach.vue';
import LimsRowPerPage from '@/components/Lims/LimsRowPerPage';
import LimsPagination from '@/components/Lims/LimsPagination';

import { ocrUploadHistoryQuery } from '@/query';
import { buildFilterFromQuery, buildFilterQueryParams } from '@/query/utils';
import { isDeepEqual } from '@/core/helpers';
import cloneDeep from 'lodash/cloneDeep';

export default {
  name: 'ImportHistoryPage',
  mixins: [ConfigMixins, ListWithFilterMixins],
  components: {
    OcrUploadHistoryList,
    OcrUploadHistoryFilter,
    LegendSeach,
    LimsRowPerPage,
    LimsPagination,
  },
  created() {
    this.initState();
  },
  data() {
    return {
      //items: [],
      totalItem: null,
      query: null,

      //dummy data - remove when API Integration
      items: [
        {
          batchID: 'A1BC234123',
          uploadedDate: '2023-01-03T04:14:04.904096Z',
          user: 'Mo Bui',
          statusName: 'Fully Completed',
        },
        {
          batchID: 'A1BC234123',
          uploadedDate: '2023-01-03T04:14:04.904096Z',
          user: 'Mo Bui',
          statusName: 'Failed',
        },
        {
          batchID: 'A1BC234123',
          uploadedDate: '2023-01-03T04:14:04.904096Z',
          user: 'Mo Bui',
          statusName: 'In Progress',
        },
        {
          batchID: 'A1BC234123',
          uploadedDate: '2023-01-03T04:14:04.904096Z',
          user: 'Mo Bui',
          statusName: 'Partially Completed',
        },
        {
          batchID: 'A1BC234123',
          uploadedDate: '2023-01-03T04:14:04.904096Z',
          user: 'Mo Bui',
          statusName: 'Fully Completed',
        },
      ],
    };
  },
  computed: {
    fields() {
      return ocrUploadHistoryQuery.filters.fields;
    },
    defaultValues() {
      return ocrUploadHistoryQuery.filters.defaultValues;
    },
  },
  methods: {
    // common methods
    initState() {
      const queryParams = this.$route.query;
      const filters = buildFilterFromQuery(ocrUploadHistoryQuery.filters, queryParams);
      const pagination = buildFilterFromQuery(ocrUploadHistoryQuery.pagination, {
        pageSize: this.$appConfig.itemPerPageDefault,
        ...queryParams,
      });
      const sort = buildFilterFromQuery(ocrUploadHistoryQuery.sort, queryParams);
      const query = { filters, pagination, sort };
      this.query = query;
    },

    async search(queryObject) {
      //WIP call API

      // update router
      const query = {
        ...buildFilterQueryParams(queryObject.filters),
        ...buildFilterQueryParams(queryObject.pagination),
      };

      if (!isDeepEqual(query, this.$route.query)) {
        this.$router
          .push({
            query,
          })
          .catch((error) => {
            if (
              error.name === 'NavigationDuplicated' &&
              error.message.includes('Avoided redundant navigation to current location')
            ) {
              history.pushState({}, '', new URL(new URL(window.location)));
            }
          });
      }
    },

    onResetFilters() {
      this.query.filters = cloneDeep(ocrUploadHistoryQuery.filters.defaultValues);
    },
  },
};
</script>

<style lang="scss"></style>
