import { cloneDeep } from 'lodash';

const InformationDTO = {
  currentPassword: 'required|notValid:validPasswordCheck',
  newPassword: 'required|notValid:validPasswordCheck',
  confirmPassword: 'required|mustEqual:mustEqualConfirmPasswordCheck',
};

const PasswordSchema = {
  information: {
    entity: 'auth/reset-password',
    fields: {
      ...InformationDTO,
    },
  },
};

const preparePasswordSchema = (passwordSchema, params = {}) => {
  const schema = cloneDeep(passwordSchema);

  schema.information.fields.confirmPassword = `${schema.information.fields.confirmPassword},${JSON.stringify({
    password: params ? params : '',
  })}`;

  // default add mode
  return schema;
};

export const getPasswordSchema = (params) => {
  return preparePasswordSchema(PasswordSchema, params);
};
