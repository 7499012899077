import { ChartCard } from '@/components';
const chartOptions = {
  seriesBarDistance: 2,
  chartPadding: {
    top: 50,
    right: 20,
    bottom: 10,
    left: 20,
  },
  width: '600px',
  height: '300px',
  axisX: {
    showGrid: false,
  },
  axisY: {
    onlyInteger: true,
  },
};
export default {
  components: {
    ChartCard,
  },
  methods: {
    getChartOptions(onlyInteger = true) {
      return {
        ...chartOptions,
        axisY: {
          onlyInteger,
        },
      };
    },
    getResponsiveOptions() {
      return [
        [
          'screen and (max-width: 640px)',
          {
            seriesBarDistance: 5,
            axisX: {
              labelInterpolationFnc: function (value) {
                return value[0];
              },
            },
          },
        ],
      ];
    },
  },
};
