<template>
  <div>
    <md-table class="paginated-table table-striped table-hover lims-table user-tbl">
      <md-table-toolbar>
        <slot name="toolbar"></slot>
      </md-table-toolbar>
      <div class="table-fix-header">
        <thead>
          <md-table-row>
            <md-table-head v-for="(column, index) in columnOfTable" :key="index" :class="column.class">
              <div>
                {{ $t(column.name) }}
              </div>
            </md-table-head>
          </md-table-row>
        </thead>
        <tbody>
          <md-table-row v-for="(item, index) in fields" :key="index">
            <md-table-cell>{{ item.timeStamp ? formatDate(item.timeStamp) : '' }}</md-table-cell>
            <md-table-cell>{{ item.action }}</md-table-cell>
            <md-table-cell>{{ item.userTypeName }}</md-table-cell>
            <md-table-cell>{{ item.userName }}</md-table-cell>
            <md-table-cell>{{ item.ip }}</md-table-cell>
            <md-table-cell class="cell-action">
              <div class="cell-action-inner">
                <md-button
                  v-show="EVENT_IS_VIEWED_AUDIT_TRAIL[item.action]"
                  class="md-just-icon md-info md-simple"
                  @click.stop="handleAction(item)"
                >
                  <md-icon>visibility</md-icon>
                </md-button>
              </div>
            </md-table-cell>
          </md-table-row>
        </tbody>
      </div>
    </md-table>
  </div>
</template>

<script>
import { DateTimeHelper } from '@/core/helpers';
import { EVENT_IS_VIEWED_AUDIT_TRAIL } from '@/core/constants';

export default {
  props: {
    fields: {
      require: false,
    },
  },

  data() {
    return {
      longPressMode: false,
      plusInterval: null,
      columnOfTable: [
        {
          name: 'pages.auditTrail/list.date',
          key: 'date',
          class: 'date-column',
        },
        {
          name: 'pages.auditTrail/list.event',
          key: 'event',
          class: 'action-column',
        },
        {
          name: 'pages.auditTrail/list.userType',
          key: 'userType',
          class: 'userType-column',
        },
        {
          name: 'pages.auditTrail/list.user',
          key: 'user',
          class: 'user-column',
        },
        {
          name: 'pages.auditTrail/list.ip',
          key: 'ip',
          class: 'ip-column',
        },
        {
          name: 'pages.auditTrail/list.actions',
          key: 'actions',
          class: 'actions-column',
        },
      ],
    };
  },

  computed: {
    EVENT_IS_VIEWED_AUDIT_TRAIL() {
      return EVENT_IS_VIEWED_AUDIT_TRAIL;
    },
  },

  methods: {
    formatDate(date) {
      const { timezoneValue, dateTimeAppFormat } = this.$store.getters['config/$appConfig'];
      return DateTimeHelper.formatDateTimeGlobal(date, {
        timezoneValue,
        dateTimeAppFormat,
      });
    },

    handleAction(item) {
      this.$emit('onClickView', item);
    },
  },
};
</script>
<style lang="scss"></style>
