const DashboardLayout = () => import('@/layouts/DashboardLayout.vue');
import { FORM_MODES } from '@/core/constants';
import SlideManagement from '@/pages/Case/SlideManagement/Slide.vue';
import SlideEdit from '@/pages/Case/SlideManagement/Edit/EditSlide.vue';

import guards from './guards';

export default {
  path: '/slides',
  component: DashboardLayout,
  meta: {
    zone: 'private',
    rbac: 'slides',
  },
  beforeEnter: guards.checkAuthorizedGuard,
  children: [
    {
      path: '',
      name: 'SlideManagement',
      component: SlideManagement,
      meta: {
        zone: ['Case_SlideManagement_Retrieve'],
      },
    },
    {
      path: '/slides/:slideId/view/:caseSpecimenBlockId/:CaseAlwRequestId/:CaseSpecimenId/:SlideTypeName',
      name: 'SlideView',
      component: SlideEdit,
      props: (route) => ({
        formMode: FORM_MODES.VIEW,
        slideId: route.params.slideId,
        caseSpecimenBlockId: route.params.caseSpecimenBlockId,
        CaseAlwRequestId: route.params.CaseAlwRequestId,
        CaseSpecimenId: route.params.CaseSpecimenId,
        SlideTypeName: route.params.SlideTypeName,
      }),
      meta: {
        zone: ['Case_SlideManagement_Retrieve'],
      },
    },
    {
      path: '/slides/:slideId/edit/:caseSpecimenBlockId/:CaseAlwRequestId/:CaseSpecimenId/:SlideTypeName',
      name: 'SlideEdit',
      component: SlideEdit,
      props: (route) => ({
        formMode: FORM_MODES.EDIT,
        slideId: route.params.slideId,
        caseSpecimenBlockId: route.params.caseSpecimenBlockId,
        CaseAlwRequestId: route.params.CaseAlwRequestId,
        CaseSpecimenId: route.params.CaseSpecimenId,
        SlideTypeName: route.params.SlideTypeName,
      }),
      meta: {
        zone: ['Case_SlideManagement_Update'],
      },
    },
  ],
};
