// =========================================================
// * Vue Material Dashboard PRO - v1.4.0
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vue-material-dashboard-pro
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import Vue from 'vue';
import VueRouter from 'vue-router';
import DashboardPlugin from './material-dashboard';

// Extensions
import { i18n } from './i18n';
import store from './store';

// Plugins
import App from './App.vue';
import Chartist from 'chartist';
import AppBasePlugin from './plugins/AppBase';
import CKEditor from '@ckeditor/ckeditor5-vue2';

// plugin setup
Vue.use(VueRouter);
Vue.use(DashboardPlugin);
Vue.use(AppBasePlugin);
Vue.use(CKEditor);

// global library setup
Vue.prototype.$Chartist = Chartist;

// Application Core
import router from './router';

new Vue({
  el: '#app',
  render: (h) => h(App),
  store,
  i18n,
  router,
});
