<template>
  <md-dialog :md-close-on-esc="true" :md-active.sync="isVisible" @md-clicked-outside="close" class="lims-modal">
    <div class="dialog-icon-close" @click="close"><md-icon>close</md-icon></div>
    <md-dialog-title>{{ $t('components/lims/modals/ModalDuplicateConfirmation.title') }}</md-dialog-title>
    <div class="lims-form dialog-content">
      <p class="text-center">{{ $t('components/lims/modals/ModalDuplicateDataset.aboutDuplicateText') }}</p>
      <p class="text-center">{{ $t('components/lims/modals/ModalDuplicateDataset.wouldYouLikeDisable') }}</p>
      <div style="padding: 20px 0 0 180px">
        <input type="radio" id="yes" name="chooseMode" value="yes" v-model="isDisable" />
        <label for="yes">{{ $t('global/button/button.yes') }}</label>
      </div>
      <div style="padding: 20px 0 0 180px">
        <input type="radio" id="no" name="chooseMode" value="no" v-model="isDisable" />
        <label for="no">{{ $t('global/button/button.no') }}</label>
      </div>
    </div>
    <md-dialog-actions>
      <md-button @click="onCancel" class="lims-form-button">{{ $t('global/button/button.cancel') }}</md-button>
      <md-button @click="onConfirm" class="lims-form-button md-primary">{{
        $t('global/button/button.confirm')
      }}</md-button>
    </md-dialog-actions>
  </md-dialog>
</template>

<script>
import modalMixins from '@/components/Lims/modals/modal.mixins';

export default {
  mixins: [modalMixins],
  data() {
    return {
      isDisable: 'null',
    };
  },
  methods: {
    onConfirm() {
      this.$emit('onConfirm', this.isDisable);
    },
    onCancel() {
      this.close();
    },
  },
};
</script>

<style lang="scss"></style>
