<template>
  <md-dialog :md-close-on-esc="true" :md-active.sync="isVisible" @md-clicked-outside="close" class="lims-modal">
    <div class="dialog-icon-close" @click="close"><md-icon>close</md-icon></div>
    <md-dialog-title>
      <template v-if="currentUploadIndex !== numItem">{{
        $t('components/lims/modals/ModalUploadProgress/title.uploading')
      }}</template>
      <template v-else>{{ $t('components/lims/modals/ModalUploadProgress/title.successfully') }}</template>
    </md-dialog-title>

    <div class="lims-form dialog-content">
      <div class="progressbar">
        <div class="progress">
          <span v-for="index in numItem" :key="index" :class="{ done: index <= currentUploadIndex }"></span>
        </div>
        <div class="percent">{{ currentUploadIndex }} / {{ numItem }}</div>
      </div>
      <p v-if="currentUploadIndex !== numItem">
        {{ $t('components/lims/modals/ModalUploadProgress/txtInProgress') }}
      </p>
    </div>
    <md-dialog-actions>
      <md-button v-if="currentUploadIndex !== numItem" @click="close" class="lims-form-button">{{
        $t('global/button/button.cancel')
      }}</md-button>
      <md-button v-else @click="close" class="lims-form-button md-primary">{{
        $t('global/button/button.ok')
      }}</md-button>
    </md-dialog-actions>
  </md-dialog>
</template>

<script>
import modalMixins from '@/components/Lims/modals/modal.mixins';

export default {
  name: 'ModalUploadProgress',
  mixins: [modalMixins],
  props: {},
  data() {
    return {
      numItem: 0,
      currentUploadIndex: 0,
    };
  },
  methods: {
    open(val) {
      if (val) {
        this.isVisible = true;
        this.numItem = val.numberOfFile;
        this.currentUploadIndex = 0;
      }
    },
    setUploadStatus({ currentUploadIndex }) {
      this.currentUploadIndex = currentUploadIndex;
      this.nextOne();
    },
    nextOne() {
      this.$emit('onNext', { currentUploadIndex: this.currentUploadIndex });
    },
    close() {
      this.$emit('onClose', { currentUploadIndex: this.currentUploadIndex });
      this.currentUploadIndex = 0;
      this.numItem = 0;
      this.isVisible = false;
    },
  },
};
</script>

<style lang="scss">
.progressbar {
  position: relative;
}
.percent {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  text-align: center;
}
.progress {
  border: 1px solid;
  display: flex;
}
.progress span {
  height: 20px;
  flex: 1;
}
.progress span.done {
  background: #009688;
}
</style>
