/**
 * Auth Module Routing
 * Includes:
 * - Login
 * - Login - 2FA
 * - Login - Forget Password
 * - Login - Reset Password
 * - Login - First time
 */
import ForgotPassword from '@/pages/Auth/ForgotPassword/ForgotPassword';
import ResetPassword from '@/pages/Auth/ResetPassword/ResetPassword';
import CompleteYourAccount from '@/pages/Auth/CompleteYourAccount/CompleteYourAccount';
import VerifyTwoFa from '@/pages/Auth/VerifyTwoFa/VerifyTwoFa';
import ReviewPolicy from '@/pages/Auth/ReviewPolicy/ReviewPolicy';

// Layouts
const AuthLayout = () => import('@/layouts/AuthLayout');
// Pages
const AuthLogin = () => import('@/pages/Auth/Login/AuthLogin.vue');
// eslint-disable-next-line no-unused-vars
import guards from './guards';

export default {
  path: '/auth',
  component: AuthLayout,
  meta: {
    zone: 'public',
  },
  beforeEnter: guards.checkAuthorizedGuard,
  children: [
    {
      path: '/',
      name: 'AuthLogin',
      component: AuthLogin,
      props: (route) => ({
        redirectTo: route.query['redirectTo'] || '',
      }),
    },
    {
      path: 'verify-two-fa',
      name: 'VerifyTwoFa',
      component: VerifyTwoFa,
      props: true,
    },
    {
      path: 'forgot-password',
      name: 'AuthForgotPassword',
      component: ForgotPassword,
    },
    {
      path: 'reset-password/:userId/:token',
      name: 'AuthResetPassword',
      component: ResetPassword,
      props: true,
    },
    {
      path: 'complete-your-account/:userId/:token',
      name: 'CompleteYourAccount',
      component: CompleteYourAccount,
      props: true,
    },
    {
      path: 'review-policy',
      name: 'ReviewPolicy',
      component: ReviewPolicy,
      props: true,
    },
  ],
};
