<template>
  <ValidationObserver ref="billingClinicAndLaboratoryForm">
    <collapse :wrapperClass="'case-collapse'" :collapse="collapseName" icon="keyboard_arrow_down">
      <template slot="md-collapse-pane-1">
        <div class="md-layout lims-form-row">
          <div class="md-layout-item md-size-33 md-small-size-100">
            <label class="required">{{ $t('entities/case/form/clinicianId') }}</label>
            <div class="static-text">{{ formData.clinicianName }}</div>
          </div>
          <div class="md-layout-item md-size-33 md-small-size-100">
            <label class="required">{{ $t('entities/case/form/clinicId') }}</label>
            <div class="static-text">{{ formData.clinicName }}</div>
          </div>
          <div class="md-layout-item md-size-33 md-small-size-100">
            <label class="required">{{ $t('entities/case/form/laboratory') }}</label>
            <div class="static-text">{{ formData.laboratoryName }}</div>
          </div>
        </div>
        <div class="md-layout lims-form-row">
          <div class="md-layout-item md-size-33 md-small-size-100">
            <label>{{ $t('entities/case/form/technicianOneDataEntry') }}</label>
            <div class="static-text">{{ formData.technician1Name }}</div>
          </div>
          <div class="md-layout-item md-size-33 md-small-size-100">
            <lims-field :model="formData" :schema="caseFormSchema" field="technician2Id"> </lims-field>
            <div class="static-text">{{ formData.technician2Name }}</div>
          </div>
          <div class="md-layout-item md-size-33 md-small-size-100">
            <label>{{ $t('entities/case/form/reviewerId') }}</label>
            <div class="static-text">{{ formData.reviewerName }}</div>
          </div>
        </div>
        <div class="md-layout lims-form-row">
          <div class="md-layout-item md-size-33 md-small-size-100">
            <lims-field :model="formData" :schema="caseFormSchema" field="courierCompany"> </lims-field>
            <div class="static-text">{{ formData.courierCompany }}</div>
          </div>
          <div class="md-layout-item md-size-33 md-small-size-100">
            <lims-field :model="formData" :schema="caseFormSchema" field="pickupDateAndTime"> </lims-field>
            <div class="static-text">{{ formData.pickupDate }}</div>
          </div>
        </div>
      </template>
    </collapse>
  </ValidationObserver>
</template>
<script>
import { FormMixins } from '@/core/mixins';
import CaseMixins from '@/pages/Case/CaseManagement/Case.mixins';
import { Collapse } from '@/components';
import { mapState } from 'vuex';
import { FORM_MODES } from '@/core/constants';

export default {
  mixins: [FormMixins, CaseMixins],
  components: {
    Collapse,
  },
  props: {
    formMode: {
      type: Number,
      require: true,
      validator: function (value) {
        // The value must match one of these strings
        return Object.values(FORM_MODES).indexOf(value) !== -1;
      },
    },
    dataEdit: {
      type: Object,
      require: false,
    },
  },

  computed: {
    ...mapState('auth', {
      user: (state) => state.user,
    }),
    collapseName() {
      const name = 'pages/case/CaseManagement/components/ClinicAndLaboratory/collapseTitle';
      return [this.$translate(name)];
    },
  },
  data() {
    return {
      formData: {},
      laboratory: [],
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.formData = {
        ...this.dataEdit,
        pickupDate: this.dataEdit.pickupDate ? this.formatDateTimeGlobal(this.dataEdit.pickupDate) : '',
      };
      this.laboratory = {
        id: this.dataEdit.laboratoryId,
        text: this.dataEdit.laboratoryName,
      };
    },
  },
};
</script>
<style lang="scss">
.self-payers-information {
  border: 1px solid #ddd;
  padding: 30px 20px 40px 20px;
  margin-bottom: 15px;
}
.if-billing-entity {
  font-weight: 500;
}
</style>
