<template>
  <div class="md-layout text-center">
    <lims-verification-code-card header-color="green" v-if="isLogged">
      <h4 slot="title" class="title">{{ $t('pages/auth/verifyTwoFa/title.verificationCode') }}</h4>
      <p slot="description">...</p>
    </lims-verification-code-card>
    <div class="md-layout-item md-size-33 md-medium-size-50 md-small-size-70 md-xsmall-size-100">
      <ValidationObserver ref="form" v-slot="{ handleSubmit }">
        <form class="md-layout lims-form form-login" @submit.prevent="handleSubmit()">
          <lims-verification-code-card header-color="primary" v-if="!isLogged">
            <h4 slot="title" class="title">{{ $t('pages/auth/verifyTwoFa/title.verificationCode') }}</h4>
            <div slot="verification-code">
              <p>{{ $t('components/lims/modals/ModalVerifyCode.enterTheVerification') }} {{ verifyContent() }}</p>
              <lims-field :model="formData" :schema="checkCodeSchema" field="verificationCode" viewMode="custom">
                <md-input
                  slot="field"
                  @keyup.enter="onClickVerify"
                  v-model="formData.verificationCode"
                  type="text"
                  autocomplete="off"
                ></md-input>
              </lims-field>
              <p v-if="isShowResendCodeText()">
                {{ $t('pages/auth/verifyTwoFa/label.didNotReceiveACode') }}
                <span
                  class="text-underline"
                  @click="onClickResendCode()"
                  :class="isDisableResendCode ? 'un-clickable-span' : ''"
                  >{{ $t('pages/auth/verifyTwoFa/label.resendCode') }}</span
                >
              </p>
              <p v-if="isDisableResendCode">
                {{ $t('components/lims/modals/ModalVerifyCode.wait') }}
                <span class="set-color-countdown-time">{{ resendCodeCountdownTime }}</span>
                {{ $t('components/lims/modals/ModalVerifyCode.secondsToResendOrChange2Fa') }}
              </p>
            </div>
            <div slot="actions" class="actions-padding">
              <md-button @click="onClickVerify" :disabled="isDisableVerify()" class="md-primary">{{
                $t('pages/auth/verifyTwoFa/label.verify')
              }}</md-button>
            </div>
            <div slot="choose-another-method" class="left-text">
              <p v-if="isShowChooseAnotherMethod()" class="left-text">
                {{ $t('pages/auth/verifyTwoFa/label.chooseAnotherMethod') }}
              </p>
              <md-icon v-if="isShowClickUseTheAuthyApp()">link</md-icon>
              <span
                v-if="isShowClickUseTheAuthyApp()"
                class="left-text text-underline margin-right"
                @click="onClickUseTheAuthyApp"
                :class="isDisableResendCode ? 'un-clickable-span' : ''"
                >{{ $t('pages/auth/verifyTwoFa/label.useTheAuthyApp') }}</span
              >
              <md-icon v-if="isShowClickReceiveViaEmail()">email</md-icon>
              <span
                v-if="isShowClickReceiveViaEmail()"
                class="left-text text-underline margin-right"
                @click="onClickReceiveViaEmail"
                :class="isDisableResendCode ? 'un-clickable-span' : ''"
                >{{ $t('pages/auth/verifyTwoFa/label.receiveViaEmail') }}</span
              >
              <md-icon v-if="isShowClickReceiveViaSms()">phone_iphone</md-icon>
              <span
                v-if="isShowClickReceiveViaSms()"
                class="left-text text-underline margin-right"
                @click="onClickReceiveViaSms"
                :class="isDisableResendCode ? 'un-clickable-span' : ''"
                >{{ $t('pages/auth/verifyTwoFa/label.receiveViaSms') }}</span
              >
              <p slot="back-to-login" class="text-center">
                <label class="text-underline" @click="onClickBackToLogin">{{
                  $t('pages/auth/verifyTwoFa/label.backToLogin')
                }}</label>
              </p>
            </div>
          </lims-verification-code-card>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { APP_ROUTES, CHALLENGE_NAMES, TWO_FA_METHODS } from '@/core/constants';
import { VERIFY_SMS_ERROR } from '@/core/error-constants';
import { getCheckCodeSchema } from '@/schemas/check-code.schema';
import { partiallyHideEmail, partiallyHidePhoneNumber } from '@/core/helpers';
import LimsVerificationCodeCard from '@/components/Lims/Cards/LimsVerifyTwoFa';
import { AuthService } from '@/services';
import AuthMixins from '@/pages/Auth/auth.mixins';

export default {
  mixins: [AuthMixins],

  components: {
    LimsVerificationCodeCard,
  },

  data: function () {
    return {
      currentMethod: null,
      isDisableResendCode: false,
      resendCodeCountdownTime: 0,
      formData: {
        verificationCode: null,
      },
      isWrongCode: false,
    };
  },

  created() {
    this.CHECK_USER_COOKIE();
    this.currentMethod = this.challengeName;
  },

  computed: {
    ...mapState('auth', {
      isLogged: (state) => state.isLogged,
      challengeName: (state) => state.challengeName,
      sessionId: (state) => state.sessionId,
      isSetupSms: (state) => state.isSetupSms,
      isSetupTotp: (state) => state.isSetupTotp,
      email: (state) => state.email,
      phoneNumber: (state) => state.phoneNumber,
      rememberMe: (state) => state.rememberMe,
    }),

    checkCodeSchema() {
      return getCheckCodeSchema(this.isWrongCode);
    },
  },

  watch: {
    isLogged: {
      handler: function (isLogged) {
        if (isLogged) {
          const redirectTo = this.redirectTo || APP_ROUTES.DASHBOARD;
          this.$router.push(redirectTo);
        }
      },
      deep: true,
    },

    isDisableResendCode() {
      if (this.isDisableResendCode) {
        this.resendCodeCountdownTime = 61;
        this.resendCodeCountdownTime--;
      }
    },

    resendCodeCountdownTime() {
      if (this.resendCodeCountdownTime > 0) {
        setTimeout(() => {
          this.resendCodeCountdownTime--;
        }, 1000);
      } else {
        this.isDisableResendCode = false;
      }
    },
  },

  methods: {
    ...mapActions('auth', ['CHECK_USER_COOKIE']),

    isDisableVerify() {
      if (this.formData.verificationCode) {
        return this.formData.verificationCode.length !== 6;
      } else {
        return true;
      }
    },

    async onClickResendCode() {
      if (!this.isDisableResendCode) {
        this.isDisableResendCode = true;

        const { error } = await AuthService.sendVerifyCode({
          challengeName: this.currentMethod,
          sessionId: this.sessionId,
        });

        if (error) {
          this.handleReSendCodeResponseError(error);
        } else {
          this.$alertSuccess(this.$t('components/lims/modals/ModalVerifyCode.aNewCodeHasBeenSent'));
        }
      }
    },

    handleReSendCodeResponseError(error) {
      switch (error) {
        case VERIFY_SMS_ERROR.ServiceIsUnavailable:
          this.$alertError(this.$t('page/auth/CompleteYourAccount/error/serverIsUnAvailableSms'));
          break;
        case VERIFY_SMS_ERROR.MaxSendAttemptsReached:
          this.$alertError(this.$t('page/auth/CompleteYourAccount/error/waitTenMinutes'));
          break;
        case VERIFY_SMS_ERROR.MaxCheckAtemptsReached:
          this.$alertError(this.$t('page/auth/CompleteYourAccount/error/waitFiveMinutes'));
          break;
        case VERIFY_SMS_ERROR.RecipientNotValid:
          this.$alertError(this.$t('global/errorServerSide/RecipientNotValid'));
          break;
        case VERIFY_SMS_ERROR.SessionExpired:
          this.$alertError(this.$t('global/errors/SessionExpired'));
          this.$router.push(APP_ROUTES.AUTH_LOGIN);
          break;
        default:
          this.$alertError(error);
      }
    },

    async onClickVerify() {
      const { error, data } = await AuthService.verifyCode({
        challengeName: this.currentMethod,
        sessionId: this.sessionId,
        code: this.formData.verificationCode,
      });

      if (error) {
        this.handleVerifyCodeResponseError(error);
      } else {
        this.handleVerifyCodeResponseData(data);
      }
    },

    handleVerifyCodeResponseError(error) {
      switch (error) {
        case VERIFY_SMS_ERROR.ServiceIsUnavailable:
          if (this.currentMethod === TWO_FA_METHODS.SOFTWARE_TOKEN_MFA) {
            this.$alertError(this.$t('page/auth/CompleteYourAccount/error/authyError'));
          } else {
            this.$alertError(this.$t('page/auth/CompleteYourAccount/error/try2FaAnotherMethod'));
          }
          break;
        case VERIFY_SMS_ERROR.VerifyCodeIncorrect:
          this.isWrongCode = true;
          break;
        case VERIFY_SMS_ERROR.MaxSendAttemptsReached:
          this.$alertError(this.$t('page/auth/CompleteYourAccount/error/waitTenMinutes'));
          break;
        case VERIFY_SMS_ERROR.MaxCheckAtemptsReached:
          this.$alertError(this.$t('page/auth/CompleteYourAccount/error/waitFiveMinutes'));
          break;
        case VERIFY_SMS_ERROR.RecipientNotValid:
          this.$alertError(this.$t('global/errorServerSide/RecipientNotValid'));
          break;
        case VERIFY_SMS_ERROR.SessionExpired:
          this.$alertError(this.$t('global/errors/SessionExpired'));
          this.$router.push(APP_ROUTES.AUTH_LOGIN);
          break;
        default:
          this.$alertError(error);
      }
    },

    handleVerifyCodeResponseData(data) {
      const isChallengeMode = data.challengeName && data.sessionId;
      const isReviewPolicyMode = isChallengeMode && data.challengeName === CHALLENGE_NAMES.POLICY;

      // REVIEW POLICY
      if (isReviewPolicyMode) {
        this.openReviewPolicyScreen(data);
        return;
      }
      // NO POLICY JUST CONTINUE
      this.$getAuthMixins().saveLoginState(this.$store, data);
    },

    openReviewPolicyScreen(data) {
      // SAVE STATE FOR POLICY LIST
      AuthService.saveReviewPolicyState(this.$store, data);
      Promise.resolve(this.$router.push(`${APP_ROUTES.AUTH_REVIEW_POLICY}`));
    },

    verifyContent() {
      if (this.currentMethod === TWO_FA_METHODS.EMAIL_MFA) {
        return partiallyHideEmail(this.email);
      } else {
        if (this.currentMethod === TWO_FA_METHODS.SMS_MFA) {
          return partiallyHidePhoneNumber(this.phoneNumber);
        } else {
          if (this.currentMethod === TWO_FA_METHODS.SOFTWARE_TOKEN_MFA) {
            return this.$t('pages/auth/verifyTwoFa/label.yourAuthyApp');
          }
        }
      }
    },

    isShowResendCodeText() {
      return this.currentMethod !== TWO_FA_METHODS.SOFTWARE_TOKEN_MFA;
    },

    isShowChooseAnotherMethod() {
      return this.isSetupTotp || this.isSetupSms;
    },

    isShowClickUseTheAuthyApp() {
      return this.isSetupTotp && this.currentMethod !== TWO_FA_METHODS.SOFTWARE_TOKEN_MFA;
    },

    isShowClickReceiveViaEmail() {
      return this.currentMethod !== TWO_FA_METHODS.EMAIL_MFA;
    },

    isShowClickReceiveViaSms() {
      return this.isSetupSms && this.currentMethod !== TWO_FA_METHODS.SMS_MFA;
    },

    onClickUseTheAuthyApp() {
      if (!this.isDisableResendCode) {
        this.currentMethod = TWO_FA_METHODS.SOFTWARE_TOKEN_MFA;
        this.isDisableResendCode = true;
      }
    },

    onClickReceiveViaEmail() {
      if (!this.isDisableResendCode) {
        this.currentMethod = TWO_FA_METHODS.EMAIL_MFA;
        this.onClickResendCode();
      }
    },

    onClickReceiveViaSms() {
      if (!this.isDisableResendCode) {
        this.currentMethod = TWO_FA_METHODS.SMS_MFA;
        this.onClickResendCode();
      }
    },

    onClickBackToLogin() {
      this.$router.push(APP_ROUTES.AUTH_LOGIN);
    },
  },
};
</script>

<style lang="scss" scoped>
.actions-padding {
  padding-top: 100px;
}
.margin-right {
  margin-right: 14px;
}
</style>
