<template>
  <md-dialog :md-close-on-esc="true" :md-active.sync="isVisible" @md-clicked-outside="close" class="lims-modal">
    <div class="dialog-icon-close" @click="close"><md-icon>close</md-icon></div>
    <md-dialog-title>{{ $t('components/lims/modals/ModalReallocateCase.title') }}</md-dialog-title>
    <div class="dialog-content lims-form">
      <p class="text-center">{{ $t('components/lims/modals/ModalReallocateCase.selectedPathologist') }}</p>
      <p class="text-center">{{ $t('components/lims/modals/ModalReallocateCase.actionCannotBeUndone') }}</p>
      <lims-field :model="formData" :schema="checkCodeSchema" field="pathologist" class="textarea-field">
        <multiselect
          slot="field"
          v-model="pathologistUser"
          :options="pathologistOptions"
          :multiple="false"
          :show-labels="false"
          group-values="pathologistUsers"
          group-label="pathologistGroupName"
          placeholder=""
          track-by="pathologistId"
          label="pathologistName"
          :hide-selected="false"
          :group-select="false"
        >
        </multiselect>
      </lims-field>
      <p v-if="pathologistUser">{{ pathologistUser.pathologistNote ? pathologistUser.pathologistNote : '' }}</p>
      <lims-field class="textarea-field" :model="formData" :schema="checkCodeSchema" field="comment">
        <md-textarea slot="field" v-model="formData.comment"></md-textarea>
      </lims-field>
      <input class="visible-none" type="text" />
      <lims-field :model="formData" :schema="checkCodeSchema" field="passwordForCase">
        <md-input slot="field" ref="confirmPassword" v-model="formData.password" type="password"></md-input>
      </lims-field>
    </div>
    <md-dialog-actions>
      <md-button @click="onCancel" class="lims-form-button">
        {{ $t('global/button/button.cancel') }}
      </md-button>
      <md-button
        @click="onReallocate"
        class="md-danger lims-form-button"
        :disabled="!formData.password || !formData.comment || !pathologistUser || isProcessing"
      >
        {{ $t('global/button/button.reallocate') }}
      </md-button>
    </md-dialog-actions>
  </md-dialog>
</template>

<script>
import modalMixins from './modal.mixins';
import { getCheckCodeSchema } from '@/schemas/check-code.schema';
import { pathCaseService } from '@/services';
import { mappingGroupPathologists } from '@/pages/Case/CaseManagement/Case.helpers';

export default {
  mixins: [modalMixins],
  props: {
    dataEdit: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      formData: {
        password: null,
        comment: null,
      },
      pathologistOptions: [],
      defaultPathologist: null,
      pathologistUser: null,
    };
  },
  created() {
    this.fetchData(this.dataEdit);
  },
  computed: {
    checkCodeSchema() {
      return getCheckCodeSchema(this.isWrongPasswordError);
    },
  },
  methods: {
    async fetchData(dataEdit) {
      if (
        dataEdit.clinicId &&
        dataEdit.clinicianId &&
        dataEdit.caseSpecimens[0]?.specimenTypeId &&
        dataEdit.laboratoryId
      ) {
        const { pathologistOptions, defaultPathologist } = await this.loadPathologistUsers({
          pathologistUserId: dataEdit.pathologistId,
          clinicId: dataEdit.clinicId,
          clinicianId: dataEdit.clinicianId,
          specimenTypeId: dataEdit.caseSpecimens[0].specimenTypeId,
          laboratoryId: dataEdit.laboratoryId,
        });

        this.pathologistOptions = pathologistOptions;
        this.pathologistUser = defaultPathologist;
      }
    },
    async loadPathologistUsers({ pathologistUserId, clinicId, clinicianId, specimenTypeId, laboratoryId }) {
      const { error, data } = await pathCaseService.getPathologistUsersReallocate({
        pathologistUserId,
        specimenTypeId,
        clinicianId,
        clinicId,
        laboratoryId,
      });
      if (error) {
        this.$alertError(error);
      }

      return mappingGroupPathologists(data);
    },
    onCancel() {
      this.close();
    },
    onReallocate() {
      this.isProcessing = true;
      this.$emit('onReallocate', this.formData, this.pathologistUser);
    },

    close() {
      this.isVisible = false;
      this.$nextTick(() => {
        this.pathologistUser = '';
        this.formData = {
          password: null,
          comment: null,
        };
      });
    },
  },
};
</script>

<style lang="scss"></style>
