import { render, staticRenderFns } from "./CommonData.vue?vue&type=template&id=6c3b1552&scoped=true&"
import script from "./CommonData.vue?vue&type=script&lang=js&"
export * from "./CommonData.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c3b1552",
  null
  
)

export default component.exports