<template>
  <stats-card header-color="primary">
    <template slot="header">
      <div class="card-icon">
        <md-icon>bug_report</md-icon>
      </div>
      <p class="category">Open Issues</p>
    </template>

    <template slot="footer">
      <div class="group-stats-wrapper">
        <div class="group-stats">
          <div class="group-stats-item">
            <h3 @click="openIssuesNavigate()">
              <span>{{ data.value }}</span>
            </h3>
          </div>
        </div>
        <div class="stats">
          <md-icon>date_range</md-icon>
          {{ data.time }}
        </div>
      </div>
    </template>
  </stats-card>
</template>

<script>
import { CASE_ISSUE_FILTER_ENUM, CASSE_ISSUE_STATUS_ENUM } from '@/core/constants';
import StatsMixins from './stats.mixins';
export default {
  props: {
    data: {},
  },
  mixins: [StatsMixins],
  methods: {
    openIssuesNavigate() {
      this.$navigateToList({
        path: 'cases',
        query: { CaseIssues: CASE_ISSUE_FILTER_ENUM.CaseWithIssues, CaseIssueStatusIds: CASSE_ISSUE_STATUS_ENUM.Open },
      });
    },
  },
};
</script>

<style></style>
