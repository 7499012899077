<template>
  <lims-block>
    <modal-add-user-entity
      :fData="fData"
      :listUsers="listUsers"
      :myEntityView="isMyEntityView"
      ref="addUserModal"
      @userData="getuserData"
      @setData="setData"
      slot="blockTitle"
    ></modal-add-user-entity>
    <modal-select-user-types
      ref="selectUserTypesModal"
      slot="blockTitle"
      :entitySelectUser="entitySelectUser"
      @onSelect="onSelectUserType"
    ></modal-select-user-types>
    <h4 class="title" slot="blockTitle">{{ $t('pages/entity/components/EntityUserList/blockTitle') }}</h4>
    <div slot="blockContent">
      <div class="md-layout lims-form-row">
        <md-table class="custom-tbl entity-usertbl">
          <md-table-toolbar class="entity-usertbl-toolbar">
            <div class="md-toolbar-section-end">
              <md-button
                :disabled="disabled || isCheckPermissionCreateUser == false"
                @click="openAddUserModal"
                class="md-primary"
              >
                {{ $t('pages/entity/components/EntityUserList/button.addUser') }}
              </md-button>
            </div>
          </md-table-toolbar>
          <md-table-head v-for="(col, index) in colTabl" :key="index" :class="col.class">
            {{ col.name }}
          </md-table-head>

          <md-table-row v-for="item in listUsers" :key="item.id">
            <md-table-cell>{{ item.tempId ? item.personalInfo.firstname : item.firstname }}</md-table-cell>
            <md-table-cell>{{ item.tempId ? item.personalInfo.lastname : item.lastname }}</md-table-cell>
            <md-table-cell v-if="isClinicLaboratory">
              {{ item.tempId ? item.personalInfo.userTypeName : item.userTypeName }}
            </md-table-cell>
            <md-table-cell>{{ item.tempId ? item.personalInfo.roleName : item.roleName }}</md-table-cell>
            <md-table-cell>{{ item.tempId ? item.personalInfo.email : item.email }}</md-table-cell>
            <md-table-cell class="status-cell col-center">
              <md-icon
                :class="[
                  { 'status-invited': item.statusId == STATUSES.Invited },
                  { 'status-enabled': item.statusId == STATUSES.Enabled },
                  { 'status-disabled': item.statusId == STATUSES.Disabled },
                ]"
                >adjust</md-icon
              >
            </md-table-cell>
            <md-table-cell v-if="item.tempId">{{ item.login.hasLogin ? 'Yes' : '' }}</md-table-cell>
            <md-table-cell v-if="!item.tempId">{{ item.hasLogin ? 'Yes' : '' }}</md-table-cell>

            <md-table-cell>{{ item.isPending ? 'Yes' : '' }}</md-table-cell>
            <md-table-cell class="col-center">
              <span v-if="item.userId" @click="redirectUser(item.userId)"><md-icon>visibility</md-icon></span>
              <template v-else>
                <span @click="editUser(item)" class="margin-icon"><md-icon>edit</md-icon></span>
                <span @click="delUser(item)" class="margin-icon"><md-icon>close</md-icon></span>
              </template>
            </md-table-cell>
          </md-table-row>
        </md-table>
      </div>
    </div>
  </lims-block>
</template>

<script>
import { ENTITY_TYPES, USER_STATUSES } from '@/core/constants';

import { ModalAddUserEntity, ModalSelectUserTypes } from '@/components/Lims/modals';

export default {
  name: 'EntityUserList',
  components: {
    ModalAddUserEntity,
    ModalSelectUserTypes,
  },
  created: function () {
    if (this.fData) {
      this.formData = this.fData;
    }
  },
  props: {
    entityTypeId: {
      type: Number,
      require: false,
      default: null,
    },
    value: {
      type: Array,
      require: true,
    },
    disabled: {
      type: Boolean,
      require: false,
      default: false,
    },
    isMyEntityView: {
      type: Boolean,
      require: false,
    },
  },
  data() {
    return {
      listUsers: [],
      colTabl: [
        {
          name: 'pages/entity/components/EntityUserList/column.firstName',
          key: 'firstName',
          class: 'col-15',
        },
        {
          name: 'pages/entity/components/EntityUserList/column.lastName',
          key: 'lastName',
          class: 'col-15',
        },
        {
          name: 'pages/entity/components/EntityUserList/column.userType',
          key: 'userType',
          class: this.entityTypeId === ENTITY_TYPES.ClinicLaboratory ? 'col-15' : 'col-hidden',
        },
        {
          name: 'pages/entity/components/EntityUserList/column.role',
          key: 'role',
          class: 'col-15',
        },
        {
          name: 'pages/entity/components/EntityUserList/column.email',
          key: 'email',
          class: 'col-25',
        },
        {
          name: 'global/pages/list.status',
          key: 'status',
          class: 'th-center col-10',
        },
        {
          name: 'pages/entity/components/EntityUserList/column.login',
          key: 'login',
          class: 'col-10',
        },
        {
          name: 'pages/entity/components/EntityUserList/column.pending',
          key: 'pending',
          class: 'col-10',
        },
        {
          name: 'global/pages/list.actions',
          key: 'action',
          class: 'th-center col-10',
        },
      ],
      fData: null,
      entitySelectUser: true,
    };
  },
  watch: {
    value: {
      handler(val) {
        this.listUsers = val;
      },
      deep: true,
    },
    listUsers: {
      handler(val) {
        this.$emit('input', val);
      },
      deep: true,
    },
  },
  computed: {
    STATUSES: function () {
      return USER_STATUSES;
    },
    isClinicLaboratory() {
      return this.entityTypeId === ENTITY_TYPES.ClinicLaboratory ? true : false;
    },
    isCheckPermissionCreateUser() {
      return this.$isAuthorized(['Accounts_UserManagement_Create']) ||
        this.$isAuthorized(['MyEntity_UserManagement_Create'])
        ? true
        : false;
    },
  },

  methods: {
    openAddUserModal() {
      if (this.entityTypeId === ENTITY_TYPES.Clinic) {
        this.$refs.addUserModal.open(this.USER_TYPES().ClinicAssociate);
      } else if (this.entityTypeId === ENTITY_TYPES.Laboratory) {
        this.$refs.addUserModal.open(this.USER_TYPES().LabTechnician);
      } else {
        this.$refs.selectUserTypesModal.open();
      }
    },
    // eslint-disable-next-line no-unused-vars
    onSelectUserType(formData) {
      // navigate
      if (formData.selectOption) {
        this.$refs.selectUserTypesModal.close();
        this.$refs.addUserModal.open(formData.selectOption.value);
      }
    },
    redirectUser(val) {
      this.$router.push({
        name: this.isMyEntityView ? 'MyEntityUserManagementView' : 'UserManagementView',
        params: {
          userId: val,
        },
      });
    },
    getuserData(val) {
      if (val) {
        const arrayUser = this.listUsers.filter((user) => user?.tempId === val.tempId);
        const indexUser = this.listUsers.findIndex((user) => user.tempId === arrayUser[0].tempId);
        if (indexUser >= 0) {
          this.listUsers[indexUser] = val;
        } else {
          this.listUsers.push(val);
        }
      }
    },
    setData(val) {
      this.fData = val;
    },
    editUser(item) {
      if (item) {
        this.fData = item;
        this.$refs.addUserModal.open(this.fData?.personalInfo.userType.value);
      }
    },
    delUser(item) {
      const id = this.listUsers.findIndex((listUser) => listUser.entityId === item.entityId);
      this.listUsers.splice(id, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.entity-usertbl {
  width: 100%;
  .entity-usertbl-toolbar {
    padding: 0 0 24px;
  }
}
</style>
