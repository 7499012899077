import { render, staticRenderFns } from "./ModalBulkActionProblemRequest.vue?vue&type=template&id=2d2a3d00&scoped=true&"
import script from "./ModalBulkActionProblemRequest.vue?vue&type=script&lang=js&"
export * from "./ModalBulkActionProblemRequest.vue?vue&type=script&lang=js&"
import style0 from "./ModalBulkActionProblemRequest.vue?vue&type=style&index=0&id=2d2a3d00&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d2a3d00",
  null
  
)

export default component.exports