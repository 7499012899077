<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit }">
    <form class="md-layout lims-form form-login" @submit.prevent="handleSubmit()">
      <md-dialog :md-close-on-esc="true" :md-active.sync="isVisible" @md-clicked-outside="close" class="lims-modal">
        <div class="dialog-icon-close" @click="close"><md-icon>close</md-icon></div>
        <md-dialog-title>{{ $t('components/lims/modals/ModalConfirmation.title') }}</md-dialog-title>
        <div class="dialog-content lims-form">
          <p class="text-center" v-html="$t('components/lims/modals/ModalRestoreCase.txt')"></p>
          <lims-field class="textarea-field" :model="formData" :schema="schema" field="reason">
            <md-textarea slot="field" v-model="formData.reason"></md-textarea>
          </lims-field>
          <lims-field :model="formData" :schema="checkCodeSchema" field="passwordForCase">
            <md-input slot="field" ref="confirmPassword" v-model="formData.password" type="password"></md-input>
          </lims-field>
        </div>
        <md-dialog-actions>
          <md-button @click="close" class="lims-form-button">
            {{ $t('global/button/button.cancel') }}
          </md-button>
          <md-button
            @click="onDelete"
            class="md-danger lims-form-button"
            :disabled="!formData.reason || !formData.password || isProcessing"
          >
            {{ $t('global/button/button.restore') }}
          </md-button>
        </md-dialog-actions>
      </md-dialog>
    </form>
  </ValidationObserver>
</template>

<script>
import modalMixins from './modal.mixins';
import { getCheckCodeSchema } from '@/schemas/check-code.schema';
export default {
  mixins: [modalMixins],
  props: {},
  data() {
    return {
      formData: {
        reason: null,
        password: null,
      },
    };
  },
  computed: {
    checkCodeSchema() {
      return getCheckCodeSchema(this.isWrongPasswordError);
    },
    schema() {
      return {
        entity: 'modals/ModalRestoreCase',
        fields: {
          reason: 'required',
          password: 'required',
        },
      };
    },
  },

  methods: {
    close() {
      this.isVisible = false;
      this.$nextTick(() => {
        this.formData = {
          reason: null,
          password: null,
        };
      });
    },
    onDelete() {
      this.isProcessing = true;
      this.$emit('onRestore', this.formData);
    },
  },
};
</script>

<style lang="scss"></style>
