<template>
  <md-card class="md-card-login p-complete-your-account" :class="{ 'md-card-hidden': cardHidden }">
    <md-card-header :class="getClass(headerColor)">
      <slot name="title"></slot>
    </md-card-header>

    <md-card-content>
      <div class="border-label">
        <label>{{ $t('component/lims/cards/completeYourAccountCard/accountLabel') }}</label>
      </div>
      <slot name="account"></slot>
      <div class="border-label two-fa">
        <label>{{ $t('component/lims/cards/completeYourAccountCard/2FALabel') }}</label>
      </div>
      <slot class="two-FA" name="two-FA"></slot>
    </md-card-content>
  </md-card>
</template>

<script>
export default {
  name: 'lims-complete-your-account-card',
  props: {
    headerColor: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      cardHidden: true,
    };
  },
  beforeMount() {
    setTimeout(this.showCard, 0);
  },
  methods: {
    showCard: function () {
      this.cardHidden = false;
    },
    getClass: function (headerColor) {
      return 'md-card-header-' + headerColor + '';
    },
  },
};
</script>

<style lang="scss">
.border-label {
  &.two-fa {
    padding-top: 10px;
  }
  border-bottom: 1px solid black;
  text-align: left;
  font-weight: 500;
  color: #008080;
  display: inline-block !important;
}
</style>
