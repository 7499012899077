import { cloneDeep } from 'lodash';

const CheckCodeSchema = {
  entity: 'checkCode/information',
  fields: {
    reason: 'required',
    comment: 'required',
    pathologist: 'required',
    password: 'required|checkCode:checkCodeIsRight',
    passwordForCase: 'required|checkCode:checkCodeIsRight',
    code: 'required|checkCode:checkCodeIsRight',
    verificationCode: 'required|checkCode:checkCodeIsRight',
  },
};

const prepareCheckCodeSchema = (checkCodeSchema, param) => {
  const schema = cloneDeep(checkCodeSchema);

  schema.fields.password = `${schema.fields.password},${JSON.stringify({
    codeIsRight: param ? param : false,
  })}`;

  schema.fields.passwordForCase = `${schema.fields.passwordForCase},${JSON.stringify({
    codeIsRight: param ? param : false,
  })}`;

  schema.fields.code = `${schema.fields.code},${JSON.stringify({
    codeIsRight: param ? param : false,
  })}`;

  schema.fields.verificationCode = `${schema.fields.verificationCode},${JSON.stringify({
    codeIsRight: param ? param : false,
  })}`;

  return schema;
};

export const getCheckCodeSchema = (param) => {
  return prepareCheckCodeSchema(CheckCodeSchema, param);
};
