<template>
  <md-dialog :md-close-on-esc="true" :md-active.sync="isVisible" @md-clicked-outside="close" class="lims-modal">
    <div class="dialog-icon-close" @click="close"><md-icon>close</md-icon></div>
    <md-dialog-title>{{ $t('components/lims/modals/ModalReallocateCase.title') }}</md-dialog-title>
    <div class="dialog-content lims-form">
      <p class="text-center">{{ $t('components/lims/modals/ModalReturnToPull.ableToPull') }}</p>
      <p class="text-center">{{ $t('components/lims/modals/ModalReallocateCase.actionCannotBeUndone') }}</p>
      <lims-field class="textarea-field" :model="formData" :schema="checkCodeSchema" field="reason">
        <md-textarea slot="field" v-model="formData.reason"></md-textarea>
      </lims-field>
    </div>
    <md-dialog-actions>
      <md-button @click="onCancel" class="lims-form-button">
        {{ $t('global/button/button.cancel') }}
      </md-button>
      <md-button @click="onReturn" class="md-danger lims-form-button" :disabled="!formData.reason || isProcessing">
        {{ $t('global/button/button.return') }}
      </md-button>
    </md-dialog-actions>
  </md-dialog>
</template>

<script>
import modalMixins from './modal.mixins';
import { getCheckCodeSchema } from '@/schemas/check-code.schema';

export default {
  mixins: [modalMixins],
  computed: {
    checkCodeSchema() {
      return getCheckCodeSchema();
    },
  },
  data() {
    return {
      formData: {
        reason: null,
      },
    };
  },
  methods: {
    onCancel() {
      this.close();
    },
    onReturn() {
      this.isProcessing = true;
      this.$emit('onReturn', this.formData.reason);
    },
  },
};
</script>

<style lang="scss"></style>
