export const FIELD_TYPES = {
  String: 'String',
  Int: 'Int',
  Float: 'Float',
  Date: 'Date',
  ArrayString: 'ArrayString',
  ArrayInt: 'ArrayInt',
  ArrayFloat: 'ArrayFloat',
  ArrayDate: 'ArrayDate',
  Boolean: 'Boolean',
  Number: 'Number',
};
