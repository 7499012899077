<template>
  <div>
    <modal-confirm-generic
      ref="unSaveChangesModal"
      :description="$t('components/lims/modals/ModalFormCancel.text')"
      @onConfirm="onUnSaveChanges"
    />
    <policy-form v-if="policyResource" :policyResource="policyResource" :policyId="getPolicyId" :formMode="formMode">
    </policy-form>
  </div>
</template>

<script>
import { UnSaveChangesMixins, ResetHeadingTitleMixins } from '@/core/mixins';
import PolicyForm from '../Forms/PolicyForm.vue';
import PolicyService from '@/services/policy.service';

export default {
  name: 'LimsPolicyEdit',
  mixins: [UnSaveChangesMixins, ResetHeadingTitleMixins],
  components: {
    PolicyForm,
  },
  created() {
    this.policyResolver(this.policyId);
  },
  props: {
    policyId: {
      require: false,
      default: null,
    },
    formMode: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      policyResource: null,
    };
  },
  computed: {
    getPolicyId() {
      if (this.policyResource) {
        return this.policyResource.policyId;
      }
      return 0;
    },
  },

  methods: {
    async policyResolver(policyId) {
      if (policyId) {
        const { data } = await PolicyService.findOne(policyId);
        this.policyResource = data;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
