<template>
  <md-dialog :md-close-on-esc="true" :md-active.sync="isVisible" @md-clicked-outside="close" class="lims-modal">
    <div class="dialog-icon-close" @click="close"><md-icon>close</md-icon></div>
    <md-dialog-title style="margin-bottom: 10px">{{
      $t('entities/modals/ModalConcurrencyIssue/warning')
    }}</md-dialog-title>
    <md-dialog-content>
      <p
        style="text-align: center; margin: 0; font-weight: 400"
        v-html="
          $t('entities/modals/ModalConcurrencyIssue/updateOnBlock', {
            nameOfBlock: nameOfBlock,
          })
        "
      ></p>
      <p style="text-align: center; margin: 0; font-weight: 400">
        {{ $t('entities/modals/ModalConcurrencyIssue/selectAction') }}
      </p>
      <div style="padding: 20px 0 0 180px">
        <input type="radio" id="discard" name="chooseMode" value="onDiscard" v-model="chooseMode" checked />
        <label for="discard">{{ $t('entities/modals/ModalConcurrencyIssue/discard') }}</label>
      </div>
      <p style="text-align: center">
        {{ $t('entities/modals/ModalConcurrencyIssue/discardNote') }}
      </p>
      <div style="padding: 20px 0 0 180px">
        <input type="radio" id="overwrite" name="chooseMode" value="onOverwrite" v-model="chooseMode" checked />
        <label for="overwrite">{{ $t('entities/modals/ModalConcurrencyIssue/overwrite') }}</label>
      </div>
      <p style="text-align: center">
        {{ $t('entities/modals/ModalConcurrencyIssue/overwriteNote') }}
      </p>
    </md-dialog-content>
    <md-dialog-actions>
      <md-button @click="close" class="lims-form-button">{{ $t('global/button/button.cancel') }}</md-button>
      <md-button @click="onContinue" class="lims-form-button md-primary">{{
        $t('global/button/button.continue')
      }}</md-button>
    </md-dialog-actions>
  </md-dialog>
</template>

<script>
import modalMixins from '@/components/Lims/modals/modal.mixins';

export default {
  mixins: [modalMixins],
  props: {
    nameOfBlock: {
      require: true,
    },
  },
  data() {
    return {
      chooseMode: 'onDiscard',
    };
  },
  methods: {
    onContinue() {
      this.$emit('onContinue', this.chooseMode);
    },
  },
};
</script>
