import axios from 'axios';
import store from '@/store';
import { Subject } from 'rxjs';

import { LOCAL_STORAGE_KEYS } from '@/core/constants';
import { deleteCookie, formatCurrentDateAndTime, getCookie, setCookie } from '@/core/helpers';
import environment from '@/core/environment';
import authService from './auth.service';

export const BASE_URL = environment.VUE_APP_API ? environment.VUE_APP_API : 'https://api.ldpath.dirox.app/api/v1';

const api = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
    'X-Timezone': formatCurrentDateAndTime().toString(),
  },
});

let refreshTokenSubject = new Subject('');

const consolidateErrorResponse = (message, error) => {
  const { data, status, headers } = error.response;

  return {
    err: data.error || error, // keep temporately until remove all res.err reference in the app
    error: data.error || error,
    headers,
    status,
    data: data || {},
  };
};

api.interceptors.request.use(
  (config) => {
    const accessToken = getCookie(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
    if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`;
    store.dispatch('app/$showLoading');
    const isUnsavedChanged = store.getters['caseForm/isUnsavedChanged'] || false;
    Reflect.set(config.headers, 'X-IsUnsavedChanges', isUnsavedChanged ? true : false);
    return config;
  },
  (err) => {
    store.dispatch('app/$showLoading');
    return consolidateErrorResponse('[REQUEST ERROR]', err);
  },
);

api.interceptors.response.use(
  (response) => {
    store.dispatch('app/$hideLoading');
    store.dispatch('auth/SET_LAST_TIME_CALL_API', Date.now());
    // because axios grab the response body into data object and api wrap data into object

    const payload = response.data.payload || response.data;
    let data = payload.data && payload.meta ? { items: payload.data, pagination: payload.meta } : payload;
    return { errors: [], err: false, data: data || {}, headers: response.headers };
  },
  async (err) => {
    const originalConfig = err.config;
    const requestURL = err.config.url;
    const isRefreshTokenURL = `${requestURL}`.includes('authentication/refresh-token');

    store.dispatch('app/$hideLoading');
    const forceLogout = () => {
      // force logout, reset all state
      store.dispatch('caseForm/resetBlockState');
      store.dispatch('auth/DO_LOGOUT');
    };

    if (isRefreshTokenURL) {
      forceLogout();
      return Promise.reject(err);
    }

    if (err.response.status === 401 && !originalConfig._retry && !isRefreshTokenURL) {
      try {
        originalConfig._retry = true;
        const refreshToken = getCookie(LOCAL_STORAGE_KEYS.REFRESH_TOKEN);
        const isRefreshingToken = getCookie(LOCAL_STORAGE_KEYS.IS_REFRESHING_TOKEN);
        // if there is no refresh token force logout
        if (!refreshToken) {
          forceLogout();
          return Promise.resolve(false);
        }
        // other wise if there is no refreshing token request
        // send a new one
        if (!isRefreshingToken) {
          setCookie(LOCAL_STORAGE_KEYS.IS_REFRESHING_TOKEN, 1);
          const rs = await authService.refreshToken(refreshToken);
          // when done
          if (rs.data && rs.data.accessToken) {
            setCookie(LOCAL_STORAGE_KEYS.ACCESS_TOKEN, rs.data.accessToken);
            refreshTokenSubject.next(rs.data.accessToken);
            deleteCookie(LOCAL_STORAGE_KEYS.IS_REFRESHING_TOKEN);
            return api(originalConfig);
          } else {
            deleteCookie(LOCAL_STORAGE_KEYS.IS_REFRESHING_TOKEN);
            forceLogout();
            return Promise.resolve(false);
          }
        }
        // by default, return a retry request
        const retryOrigin = new Promise((resolve) => {
          refreshTokenSubject.subscribe((token) => {
            if (token) {
              resolve(api(originalConfig));
            }
          });
        });
        return retryOrigin;
      } catch (_error) {
        forceLogout();
        if (_error.response && _error.response.data) {
          return Promise.reject(_error.response.data);
        }
        return Promise.reject(_error);
      }
    }
    // if (err.response.status === 404) {
    //   location.href = APP_ROUTES.ERROR_NOT_FOUND;
    // }
    return consolidateErrorResponse('[RESPONSE ERROR]', err);
  },
);

export default {
  option(path) {
    return api.options(path);
  },
  get(path, params = {}) {
    return api.get(path, { params });
  },
  post(path, data = {}, params = {}) {
    return api.post(path, data, {
      params,
    });
  },
  put(path, data = {}, params = {}) {
    return api.put(path, data, {
      params,
    });
  },
  patch(path, data = {}, params = {}) {
    return api.patch(path, data, {
      params,
    });
  },
  delete(path, params = {}) {
    return api.delete(path, {
      params,
    });
  },
  deleteWithPayload(path, data = {}, params = {}) {
    return api.delete(path, {
      data,
      params,
    });
  },
  getBlob(path, params = {}) {
    return api.get(path, {
      responseType: 'blob',
      params,
    });
  },
  postBlob(path, data, params = {}) {
    return api.post(path, data, {
      responseType: 'blob',
      params,
    });
  },
  postFileAndData(path, data = {}, params = {}) {
    return api.post(path, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      params,
    });
  },
};
