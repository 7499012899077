// Layouts
const DashboardLayout = () => import('@/layouts/DashboardLayout.vue');
// User pages
import User from '@/pages/Account/User/User.vue';
import UserAdd from '@/pages/Account/User/Add/UserAdd.vue';
import UserEdit from '@/pages/Account/User/Edit/UserEdit.vue';
import UserView from '@/pages/Account/User/View/UserView.vue';

import guards from './guards';

export default {
  path: '/users',
  component: DashboardLayout,
  children: [
    {
      path: '',
      name: 'UserManagement',
      component: User,
      beforeEnter: guards.checkAuthorizedGuard,
      meta: {
        zone: ['Accounts_UserManagement_Retrieve'],
      },
    },
    {
      path: 'add/:userType',
      name: 'UserManagementAdd',
      component: UserAdd,
      props: true,
      beforeEnter: guards.checkAuthorizedGuard,
      meta: {
        zone: ['Accounts_UserManagement_Create'],
      },
    },
    {
      path: 'edit/:userId',
      name: 'UserManagementEdit',
      component: UserEdit,
      props: true,
      beforeEnter: guards.checkAuthorizedGuard,
      meta: {
        zone: ['Accounts_UserManagement_Update'],
      },
    },
    {
      path: 'view/:userId',
      name: 'UserManagementView',
      component: UserView,
      props: true,
      beforeEnter: guards.checkAuthorizedGuard,
      meta: {
        zone: ['Accounts_UserManagement_Retrieve'],
      },
    },
  ],
};
