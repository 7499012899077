<template>
  <form ref="form" class="list-filter dataset-fiter" v-if="filters">
    <div class="filter-input">
      <lims-select
        v-if="isAdminView"
        v-model="filters.EntityIds"
        :options="dataSource.entityNameList"
        :translated="true"
        :multiple="false"
        :placeholder="$t('components/DatasetFilter.placeholderSelectEntity')"
      ></lims-select>
      <lims-multi-select
        :options="dataSource.snomedCodeList"
        :groupOptions="dataSource.snomedCodeListGroup"
        :multiple="false"
        v-model="filters.SnomedIds"
        itemName="nodes"
        groupName="text"
        trackBy="id"
        label="text"
        :placeholder="$t('components/DatasetFilter.placeholderSelectSnomed')"
      ></lims-multi-select>
      <lims-select
        v-model="filters.statusIds"
        :options="dataSource.statusList"
        :translated="true"
        :placeholder="$t('global/placeholder.allStatus')"
        :class="filters.statusIds && filters.statusIds.length > 0 ? 'is-disabled' : ''"
      ></lims-select>
      <md-field class="input-search">
        <md-icon>search</md-icon>
        <md-input
          v-model="filters.search"
          :placeholder="$t('components/DatasetFilter.placeholderSearch')"
          maxlength="250"
        ></md-input>
      </md-field>
    </div>
    <lims-chip-area :options="chipOptions" @onRemoveChip="$onRemoveChip"></lims-chip-area>
    <div class="filter-action">
      <md-button @click="onClear">
        {{ $t('global/button/button.clear') }}
      </md-button>
    </div>
  </form>
</template>
<script>
import { ConfigMixins, ListFilterMixins } from '@/core/mixins';

import LimsChipArea from '@/components/Lims/LimsChipArea.vue';
import LimsSelect from '@/components/Lims/LimsSelect.vue';
import datasetService from '@/services/dataset.service';
import LimsMultiSelect from '@/components/Lims/LimsMultiSelect.vue';

export default {
  mixins: [ConfigMixins, ListFilterMixins],
  components: { LimsChipArea, LimsSelect, LimsMultiSelect },
  props: {
    fields: {
      type: Array,
      require: true,
    },
    defaultValues: {
      type: Object,
      require: true,
    },
    value: {
      type: Object,
      require: true,
    },
  },
  async created() {
    this.userType = this.$store.getters['auth/userType'];
    this.fetchData();
  },

  computed: {
    isAdminView() {
      return this.userType === this.USER_TYPES().Administrator;
    },
  },
  data() {
    return {
      filters: null,
      dataSource: {
        entityNameList: [],
        snomedCodeList: [],
        snomedCodeListGroup: [],
        statusList: [],
      },
      chipOptions: [],
    };
  },
  watch: {
    filters: {
      handler(filterValues) {
        this.$updateChipList({ filterValues, dataSource: this.dataSource, filterFields: this.fields });
      },
      deep: true,
    },
  },
  methods: {
    async fetchData() {
      if (this.userType === this.USER_TYPES().Administrator) {
        this.dataSource.entityNameList = await this.getEntityList();
      }
      const snomedData = await this.getSnomedCodeList();
      this.dataSource.snomedCodeList = snomedData.options;
      this.dataSource.snomedCodeListGroup = snomedData.groups;
      this.dataSource.statusList = await this.getListStatus();

      this.filters = this.value;
    },

    async getListStatus() {
      const { data, error } = await datasetService.getListStatus();
      if (error) {
        this.$alertError(error);
      }
      return data.map((row) => {
        return {
          value: row.id,
          label: row.text === 'DeActive' ? 'Disabled' : 'Enabled',
        };
      });
    },

    async getEntityList() {
      const { data, error } = await datasetService.getListClinicsFromCurrentUser();
      if (error) {
        this.$alertError(error);
      }
      return data.map((row) => {
        return {
          value: row.id || 'all',
          label: row.text,
        };
      });
    },
    async getSnomedCodeList() {
      const { data, error } = await datasetService.getListSnomed();
      if (error) {
        this.$alertError(error);
      }
      const options = [];
      const groups = data.map((g) => {
        const { id, text, nodes } = g;
        return {
          id,
          text,
          nodes: nodes.map((n) => {
            const item = {
              label: n.text,
              value: n.id,
            };
            options.push(item);
            return item;
          }),
        };
      });
      return {
        groups,
        options,
      };
    },

    onClear() {
      this.$nextTick(() => {
        this.$refs.form.reset();
      });
      Object.keys(this.filters).map((k) => {
        this.filters[k] = this.defaultValues[k];
      });
    },
  },
};
</script>
<style lang="scss"></style>
