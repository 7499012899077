<template>
  <md-dialog :md-close-on-esc="true" :md-active.sync="isVisible" @md-clicked-outside="close" class="lims-modal">
    <div class="dialog-icon-close" @click="close"><md-icon>close</md-icon></div>
    <md-dialog-title>{{ $t('components/lims/modals/ModalExportNavBilling.title') }}</md-dialog-title>
    <div class="lims-form dialog-content">
      <p
        v-if="invalidCaseList && invalidCaseList.length > 0"
        class="text-center"
        v-html="
          $t('components/lims/modals/ModalExportNavBilling.description', {
            exportName: exportName,
          })
        "
      ></p>
      <ul class="list-of-compatible-cases">
        <li v-for="(item, index) in invalidCaseList" :key="`item-${index}`">
          {{ item.caseReference }} - {{ item.caseSpecimenType }}
        </li>
      </ul>

      <p class="text-center">
        <template v-if="validCaseList && validCaseList.length > 0">
          {{ $t('components/lims/modals/ModalExportNavBilling.text') }}
        </template>
        <template v-else>
          {{ $t('components/ModalBulkActionProblem.text3') }}
        </template>
      </p>
    </div>
    <md-dialog-actions>
      <template v-if="validCaseList && validCaseList.length > 0">
        <md-button @click="onCancel" class="lims-form-button">{{ $t('global/button/button.cancel') }}</md-button>
        <md-button @click="onContinue" class="lims-form-button md-primary">{{
          $t('global/button/button.continue')
        }}</md-button>
      </template>
      <template v-else>
        <md-button @click="onCancel" class="lims-form-button">{{ $t('global/button/button.ok') }}</md-button>
      </template>
    </md-dialog-actions>
  </md-dialog>
</template>
<script>
import modalMixins from '@/components/Lims/modals/modal.mixins';

export default {
  name: 'ModalExportNavBilling',
  mixins: [modalMixins],
  data() {
    return {
      exportName: '',
      validCaseList: [],
      invalidCaseList: [],
      dataExport: [],
    };
  },
  methods: {
    open(val) {
      if (val) {
        // TODO: adjust after mastercheckbox api for navexport okie
        this.template = val.template;
        this.dataExport = val.dataToExport;
        this.exportName = val.exportName;
        this.invalidCaseList = val.invalidCaseList;
        this.validCaseList = val.validCaseList;
        this.isVisible = true;

        if (this.invalidCaseList) {
          this.dataExport.exceptCaseRefs = [];
          this.invalidCaseList.forEach((item) => {
            this.dataExport.exceptCaseRefs.push(item.caseReference);
          });
        }
      }
    },
    onContinue() {
      this.close();
      this.$emit('onContinue', {
        template: this.template,
        dataExport: this.dataExport,
      });
    },
    onCancel() {
      this.close();
      this.$emit('onCancel');
    },
  },
};
</script>

<style lang="scss"></style>
