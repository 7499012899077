<template>
  <div>
    <p>
      <label>{{ $t('pages/account/user/components/userPolicyActions/label') }}</label>
    </p>
    <div class="md-content md-table-content md-scrollbar md-theme-default">
      <md-table class="custom-tbl" md-card>
        <md-table-row>
          <md-table-head width="60%">
            {{ $t('pages/account/user/components/userPolicyActions/columnHeader.policyName') }}
          </md-table-head>
          <md-table-head width="15%">
            {{ $t('pages/account/user/components/userPolicyActions/columnHeader.action') }}
          </md-table-head>
          <md-table-head>
            {{ $t('pages/account/user/components/userPolicyActions/columnHeader.timestamp') }}
          </md-table-head>
        </md-table-row>
        <md-table-row v-for="(item, idx) in userPolicyActions" :key="`policyAction${idx}`">
          <md-table-cell>{{ item.policyName }}</md-table-cell>
          <md-table-cell>{{ item.actionName }}</md-table-cell>
          <md-table-cell>{{ formatDateTimeGlobal(item.dateAction) }}</md-table-cell>
        </md-table-row>
      </md-table>
    </div>
  </div>
</template>

<script>
import { FormMixins } from '@/core/mixins';
export default {
  name: 'UserPolicyActions',
  mixins: [FormMixins],
  props: {
    userPolicyActions: {
      type: Array,
      require: false,
      default: function () {
        return [];
      },
    },
  },
};
</script>

<style lang="scss" scoped></style>
