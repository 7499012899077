export const VERIFY_SMS_ERROR = {
  VerifyCodeExpired: 'VerifyCodeExpired',
  VerifyCodeIncorrect: 'VerifyCodeIncorrect',
  MaxSendAttemptsReached: 'MaxSendAtemptsReached',
  MaxCheckAtemptsReached: 'MaxCheckAtemptsReached',
  SessionExpired: 'SessionExpired',
  NotExistUser: 'NotExistUser',
  ServiceIsUnavailable: 'ServiceIsUnavaiable',
  UnverifiablePhoneNumber: 'UnverifiablePhoneNumber',
  RecipientNotValid: 'RecipientNotValid',
};

export const ENABLE_USER_ERROR = {
  ROLE_IS_DISABLE: 'RoleIsDisable',
  ENTITY_IS_DISABLE: 'EntityIsDisable',
};

export const DROPDOWN_ORDER_ITEM_ERROR = {
  ITEM_IS_USED: 'itemIsUsed',
};

export const AUTH_LOGIN_ERROR = {
  NOT_EXIST_USER: 'NotExistUser',
};

export const SERVER_ERROR_CODE = {
  // Global Error
  NotFound: 'NotFound',
  NotExistUser: 'NotExistUser',
  Forbidden: 'Forbidden',
  //Case
  CannotAuthoriseCase: 'CannotAuthoriseCase',
  CannotReallocateCase: 'CannotReallocateCase',
  CannotReturnPullCase: 'CannotReturnPullCase',
  CannotCreateSORequest: 'CannotCreateSORequest',
  CannotReviewCase: 'CannotReviewCase',
  MaxPullLCaseimited: 'MaxPullLCaseimited',
  CannotRecallCaseFromPathToLab: 'CannotRecallCaseFromPathToLab',
  CannotRecallCaseFromPathToPath: 'CannotRecallCaseFromPathToPath',
  CannotRetrieveCase: 'CannotRetrieveCase',
  CannotDownloadReport: 'CannotDownloadReport',
  CannotFindCaseSpecimen: 'CanNotFindCaseSpecimen',
  CannotDeleteCaseIssue: 'CannotDeleteCaseIssue',
  IssuePreventUpdateCaseStatus: 'IssuePreventUpdateCaseStatus',
  CannotRejectIssue: 'CannotRejectIssue',
  CannotUpdateIssue: 'CannotUpdateIssue',
  CannotUpdateSBCases: 'CannotUpdateSBCases',
  CannotUpdateCaseDataset: 'CannotUpdateCaseDataset',
};
