<template>
  <div class="lims-input-number">
    <md-input
      v-on:blur="onEmitChanged()"
      slot="field"
      @keypress="isNumber($event)"
      v-model.number="model"
      :disabled="disabled"
      tabenable="yes"
    ></md-input>
    <button class="add" @click.prevent="onIncrease" :disabled="disabled"><md-icon>arrow_drop_up</md-icon></button>
    <button class="remove" @click.prevent="onDecrease" :disabled="disabled"><md-icon>arrow_drop_down</md-icon></button>
  </div>
</template>

<script>
import { isNumber } from 'lodash';
export default {
  props: {
    min: {
      type: Number,
      required: false,
      default: 1,
    },
    max: {
      type: Number,
      required: false,
      default: 9999,
    },
    value: {
      type: Number,
      required: false,
      default: 0,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    warningError: {
      type: String,
      default: '',
    },
    warningMinError: {
      type: String,
      default: '',
    },
  },
  watch: {
    value(val) {
      this.model = val;
    },
  },
  created() {
    this.model = this.value;
  },
  data() {
    return {
      model: 1,
    };
  },
  methods: {
    onEmitChanged() {
      if (this.model < this.min) {
        this.model = this.value;
        this.$alertError(this.$t(this.warningMinError));
      }
      if (!this.model && !isNumber(this.model)) {
        this.model = this.value;
      }
      // reach limit reset and show warning error
      if (this.model > this.max) {
        this.model = this.value;
        this.$alertError(
          this.$t(this.warningError, {
            maxNumberofSpecimens: this.max,
          }),
        );
      }
      if (this.model != this.value) {
        this.$emit('onChange', parseInt(this.model));
      }
    },
    isNumber(evt) {
      let charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    onIncrease() {
      if (this.model < this.max) {
        // this.model += 1;
        this.$emit('onIncrease', 1);
      }
    },
    onDecrease() {
      if (this.model > 1) {
        // this.model -= 1;
        this.$emit('onDecrease', 1);
      }
    },
    setModel(value) {
      this.model = value;
    },
  },
};
</script>

<style lang="scss">
.lims-input-number {
  position: relative;
  .add,
  .remove {
    border: none;
    background: #f1f1f1;
    cursor: pointer;
    width: 24px;
    height: 18px;
    margin: 0;
    padding: 0;
    &:hover {
      background: #c1c1c1;
    }
    &[disabled] {
      background: #f1f1f1;
      cursor: not-allowed;
    }
    position: absolute;
    right: 2px;
  }
  .add {
    top: 11px;
  }
  .remove {
    bottom: 11px;
  }

  .md-icon {
    font-size: 16px !important;
    width: 18px;
    height: 18px;
    margin: 0;
    display: block;
  }
}
.lims-input-number.no-of-specimen .md-input {
  width: 70px;
}
.case-form-block .lims-block-wrapper .lims-input-number {
  .add,
  .remove {
    width: 24px;
    height: 14px;
  }
  .add {
    top: 6px;
  }
  .remove {
    bottom: 11px;
  }
}
</style>
