import { getDefaultFilterValues, getFilterFieldTypes } from '@/query/utils';
import { FIELD_TYPES } from '@/query/constant';
const fields = [
  {
    key: 'caseRefIds',
    listKey: 'caseRefList',
    sort: 1,
    fieldType: FIELD_TYPES.ArrayString,
    translated: true,
    defaultValue: [],
  },
  {
    key: 'requesterIds',
    listKey: 'requesterList',
    sort: 2,
    fieldType: FIELD_TYPES.ArrayString,
    translated: true,
    defaultValue: [],
  },
  {
    key: 'requesterTypeIds',
    listKey: 'requesterTypeList',
    sort: 3,
    fieldType: FIELD_TYPES.ArrayInt,
    translated: true,
    defaultValue: [],
  },
  {
    key: 'statusIds',
    listKey: 'statusList',
    sort: 4,
    fieldType: FIELD_TYPES.ArrayInt,
    translated: true,
    defaultValue: [],
  },
  { key: 'search', listKey: null, sort: 5, fieldType: FIELD_TYPES.String, translated: false, defaultValue: [] },
];
const defaultValues = getDefaultFilterValues(fields);
const fieldTypes = getFilterFieldTypes(fields);
export default {
  fields,
  defaultValues,
  fieldTypes,
};
