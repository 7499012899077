import datasetService from '@/services/dataset.service';
export default {
  methods: {
    async $onDeleteDataset(deleteDatasetData) {
      const { err, data } = await datasetService.deleteDataset(deleteDatasetData.datasetId, deleteDatasetData.password);
      if (err) {
        this.$alertErrorServerSide(err);
        return false;
      }
      if (data) {
        this.$refs.deleteDatasetModal.close();
        this.$alertSuccess(
          this.$t('pages/dataset/form/alert/delete', {
            dataset: deleteDatasetData.datasetName,
          }),
        );
        return true;
      }
      return false;
    },
  },
};
