import { NUMBER_OF_CHARACTERS_SEARCH } from '@/core/constants';
import httpClient from './httpClient';
const RESOURCE = 'policies';

export default {
  getPoliciesLoginPage() {
    return httpClient.get(`/${RESOURCE}/get-policies-login-page`);
  },
  downloadFilePolicyLoginPage(policyId) {
    return httpClient.getBlob(`/authentication/${policyId}/download`);
  },
  find(params = {}) {
    return httpClient.get(`/${RESOURCE}`, params);
  },
  findOne(policyId) {
    return httpClient.get(`/${RESOURCE}/${policyId}`);
  },
  create(data = {}) {
    return httpClient.postFileAndData(`/${RESOURCE}`, data);
  },
  updateOne(payload) {
    const { policyId, ...data } = payload;
    return httpClient.put(`/${RESOURCE}/${policyId}`, data);
  },

  policyTitleCheckUnique({ value, policyId }) {
    const params = {};
    params['title'] = value;
    if (policyId) {
      params['policyId'] = policyId;
    }
    // {isExisted: false/true}
    return httpClient.get(`/${RESOURCE}/check-unique`, params);
  },

  search({ filters, pagination, sort }) {
    const { policyTypeIds, policyStatusIds, search } = filters;
    const { pageNumber, pageSize } = pagination;
    const userQueryParams = {};
    if (policyTypeIds) {
      userQueryParams['Filters.PolicyTypeIds'] = policyTypeIds;
    }
    if (policyStatusIds) {
      userQueryParams['Filters.PolicyStatusIds'] = policyStatusIds;
    }
    if (search && search.length >= NUMBER_OF_CHARACTERS_SEARCH) {
      userQueryParams['Filters.Search'] = search;
    }
    if (pageNumber) {
      userQueryParams['PageNumber'] = pageNumber;
    }
    if (pageSize) {
      userQueryParams['PageSize'] = pageSize;
    }
    if (sort && sort != ':') {
      // empty
      userQueryParams['Sort'] = sort;
    }
    return httpClient.get(`/${RESOURCE}/`, userQueryParams);
  },

  downloadFilePolicy(policyId) {
    return httpClient.getBlob(`/${RESOURCE}/${policyId}/download`);
  },

  downloadUserAction(policyId) {
    return httpClient.get(`/${RESOURCE}/${policyId}/download-user-action`);
  },

  downloadUserNoAction(policyId) {
    return httpClient.get(`/${RESOURCE}/${policyId}/download-user-no-action`);
  },

  getPolicyStatusList() {
    return httpClient.get(`/${RESOURCE}/list-status`);
  },

  deletePolicy(policyId) {
    return httpClient.patch(`/${RESOURCE}/${policyId}/delete`);
  },

  enablePolicy(policyId) {
    return httpClient.patch(`/${RESOURCE}/${policyId}/enable`);
  },

  getPoliciesAccessByUserlogin() {
    return httpClient.get(`/${RESOURCE}/get-policies-access-by-userlogin`);
  },
};
