<template>
  <form ref="form" class="list-filter review-case-filter" v-if="filters">
    <div class="filter-input">
      <lims-select
        v-model="filters.caseRefIds"
        :options="dataSource.caseRefList"
        :translated="true"
        :placeholder="$t('components/ReviewCaseFilter.caseRef')"
      ></lims-select>
      <md-field class="input-search">
        <md-icon>search</md-icon>
        <md-input
          v-model="filters.search"
          :placeholder="$t('components/ReviewCaseFilter.placeholderSearch')"
          maxlength="250"
        ></md-input>
      </md-field>
      <md-button class="clear-btn" @click="onClear">
        {{ $t('global/button/button.clear') }}
      </md-button>
    </div>
    <lims-chip-area :options="chipOptions" @onRemoveChip="$onRemoveChip"></lims-chip-area>
  </form>
</template>
<script>
import { ConfigMixins, ListFilterMixins } from '@/core/mixins';
import LimsChipArea from '@/components/Lims/LimsChipArea.vue';
import LimsSelect from '@/components/Lims/LimsSelect.vue';

export default {
  mixins: [ConfigMixins, ListFilterMixins],
  components: { LimsChipArea, LimsSelect },
  props: {
    fields: {
      type: Array,
      require: true,
    },
    defaultValues: {
      type: Object,
      require: true,
    },
    value: {
      type: Object,
      require: true,
    },
    caseRefList: {
      type: Array,
      require: true,
    },
  },
  created() {
    this.onCreated();
  },
  computed: {},
  data() {
    return {
      filters: null,
      dataSource: {
        caseRefList: [],
      },
      chipOptions: [],
    };
  },
  watch: {
    filters: {
      handler(filterValues) {
        this.$updateChipList({ filterValues, dataSource: this.dataSource, filterFields: this.fields });
      },
      deep: true,
    },
    caseRefList: {
      deep: true,
      handler: function (val) {
        this.dataSource.caseRefList = val;
      },
    },
  },
  methods: {
    async onCreated() {
      this.dataSource = await this.fetchDataSource();
      this.filters = this.value;
    },

    async fetchDataSource() {
      const caseRefList = this.caseRefList;
      return {
        caseRefList,
      };
    },

    onClear() {
      this.$nextTick(() => {
        this.$refs.form.reset();
      });
      Object.keys(this.filters).map((k) => {
        this.filters[k] = this.defaultValues[k];
      });
    },
  },
};
</script>
<style lang="scss">
.review-case-filter {
  width: 100%;
  .v-select {
    margin: 0;
    height: 34px;
  }
  .filter-input {
    .input-search {
      margin: 0 0 0 30px;
    }
    @media only screen and (max-width: 959px) {
      display: inline-flex;
    }
    @media only screen and (max-width: 767px) {
      display: block;
      .input-search {
        margin: 5px 0;
      }
    }
  }
  .chips-area {
    margin-left: 0;
  }
  .clear-btn {
    margin: 0 0 0 5px;
    height: 34px;
  }
}
</style>
