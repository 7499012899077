import { DROPDOWN_SHORT_NAME, FORM_MODES } from '@/core/constants';
import { DropdownService } from '@/services';
import policyService from '@/services/policy.service';

export default {
  props: {
    formMode: {
      type: Number,
      require: true,
      validator: function (value) {
        // The value must match one of these strings
        return Object.values(FORM_MODES).indexOf(value) !== -1;
      },
    },
    policyResource: {
      type: Object,
      require: false,
    },
  },
  data() {
    return {
      policyTypesList: [],
      policyFile: '',
    };
  },
  computed: {
    isViewMode() {
      return this.formMode === FORM_MODES.VIEW;
    },
    isEditMode() {
      return this.formMode === FORM_MODES.EDIT;
    },
    isAddMode() {
      return this.formMode === FORM_MODES.ADD;
    },
    isShowUserWithNoActionBlock() {
      if (
        this.isViewMode &&
        this.policyResource &&
        this.policyResource.policyStatusName === 'Enabled' &&
        this.policyResource.isNeedAcceptance == true
      ) {
        return true;
      } else if (this.isEditMode && this.policyResource && this.policyResource.isNeedAcceptance == true) {
        return true;
      } else {
        return false;
      }
    },
    isShowEnableButton() {
      if (
        this.policyResource &&
        this.$isAuthorized(['Policy_PolicyManagement_Update']) &&
        this.policyResource.policyStatusName === 'Disabled' &&
        this.policyResource.isNeedAcceptance == false
      ) {
        return true;
      } else {
        return false;
      }
    },
    isShowDeleteButton() {
      if (
        this.policyResource &&
        this.$isAuthorized(['Policy_PolicyManagement_Delete']) &&
        this.policyResource.policyStatusName === 'Disabled' &&
        this.policyResource.isNeedAcceptance == false
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    async loadPolicyTypes() {
      const { data } = await DropdownService.getDropdownByShortName(DROPDOWN_SHORT_NAME.POLICY_TYPE);
      return data;
    },

    async downloadFilePDF(policyId) {
      const res = await policyService.downloadFilePolicy(policyId);
      const fileURL = URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' }));
      return fileURL;
    },

    async $fetchData() {
      this.policyTypesList = await this.loadPolicyTypes();
      if (this.policyResource) {
        this.formData = {
          ...this.policyResource,
          policyTypeId: this.policyTypesList.filter((x) => x.fieldItemId === this.policyResource.policyTypeId),
        };
        this.policyFile = await this.downloadFilePDF(this.policyResource.policyId);
        this.$setHeadingTitle(this.policyResource.title);
      }
      //reset change detection
      this.$nextTick(function () {
        this.$resetChangeDetection();
      });
    },
  },
};
