<template>
  <div>
    <h3 class="block01-title">
      {{ $t('pages/entity/components/EntitySlides/blockTitle') }}
      <lims-tooltip :content="$t('pages/entity/components/EntitySlides/blockTitleTooltip')"></lims-tooltip>
    </h3>
    <div class="block-content">
      <div class="md-layout lims-form-row">
        <div class="md-layout-item md-size-50 md-small-size-100">
          <collapse-search-tbl
            :titleCollapse="
              $t('pages/entity/components/EntitySlides/titleCollapse.HAndE') + ' (' + numberHESelected + ')'
            "
            :disabled="disabled"
            :panelAdded="panelAdded"
            :listPanelSelected="listPanelSelected"
            :isCustomStains="isCustomStains"
            v-model="slides.HE"
          ></collapse-search-tbl>
        </div>
        <div class="md-layout-item md-size-50 md-small-size-100">
          <collapse-search-tbl
            :titleCollapse="
              $t('pages/entity/components/EntitySlides/titleCollapse.Immunos') + ' (' + numberImmunosSelected + ')'
            "
            :disabled="disabled"
            :panelAdded="panelAdded"
            :listPanelSelected="listPanelSelected"
            :isCustomStains="isCustomStains"
            v-model="slides.Immunos"
          ></collapse-search-tbl>
        </div>
      </div>
      <div class="md-layout lims-form-row">
        <div class="md-layout-item md-size-50 md-small-size-100">
          <collapse-search-tbl
            :titleCollapse="
              $t('pages/entity/components/EntitySlides/titleCollapse.specialStains') +
              ' (' +
              numberSpecialStainsSelected +
              ')'
            "
            :disabled="disabled"
            :panelAdded="panelAdded"
            :listPanelSelected="listPanelSelected"
            :isCustomStains="isCustomStains"
            v-model="slides.SpecialStains"
          ></collapse-search-tbl>
        </div>
        <div class="md-layout-item md-size-50 md-small-size-100">
          <collapse-search-tbl
            :titleCollapse="
              $t('pages/entity/components/EntitySlides/titleCollapse.additionalTechniques') +
              ' (' +
              numberAddTechniqueSelected +
              ')'
            "
            :disabled="disabled"
            :panelAdded="panelAdded"
            :listPanelSelected="listPanelSelected"
            :isCustomStains="isCustomStains"
            v-model="slides.AdditionalTechnique"
          ></collapse-search-tbl>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { APP_EVENTS, DROPDOWN_SHORT_NAME, newAppEvent } from '@/core/constants';
import CollapseSearchTbl from '@/pages/Entity/Components/CollapseSearchTbl.vue';
import debounce from 'lodash/debounce';

export default {
  components: { CollapseSearchTbl },
  async mounted() {
    await this.fetchData();
    this.renderSlides();
    this.addEvent(
      newAppEvent(APP_EVENTS.EVT_ON_FIRST_LOAD_ENTITY_SLIDE, {
        forceResetState: true,
      }),
    );
  },
  props: {
    value: {
      type: Array,
      require: true,
    },
    panelAdded: {
      require: false,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      require: false,
      default: false,
    },
    listPanelSelected: {
      require: false,
      default: () => [],
    },
  },
  watch: {
    slides: {
      handler(value) {
        this.emitChanges(value);
        this.numberHESelected = value.HE.filter((x) => x.selected == true).length;
        this.numberImmunosSelected = value.Immunos.filter((x) => x.selected == true).length;
        this.numberSpecialStainsSelected = value.SpecialStains.filter((x) => x.selected == true).length;
        this.numberAddTechniqueSelected = value.AdditionalTechnique.filter((x) => x.selected == true).length;
      },
      deep: true,
    },
    [APP_EVENTS.EVT_ON_CHANGE_CUSTOM_STAINS]: {
      deep: true,
      handler: async function (val) {
        this.isChangeCustomStains = val?.isChangeCustomStains;
        this.isCustomStains = val?.isCustomStains;
        await this.fetchData();
        this.renderSlides();
      },
    },
  },
  data() {
    return {
      slides: {
        HE: [],
        Immunos: [],
        SpecialStains: [],
        AdditionalTechnique: [],
      },

      listHAndE: [],
      listImmunos: [],
      listSpecialStains: [],
      listAdditionalTechniques: [],
      numberHESelected: '',
      numberImmunosSelected: '',
      numberSpecialStainsSelected: '',
      numberAddTechniqueSelected: '',
      isChangeCustomStains: false,
      isCustomStains: false,
    };
  },

  computed: {
    ...mapGetters('app/data', ['getDatasetByKey']),
    ...mapGetters('app/event', [APP_EVENTS.EVT_ON_CHANGE_CUSTOM_STAINS]),
  },

  methods: {
    ...mapActions('app/event', ['addEvent']),
    getListSlidesByName(slideName) {
      const data = this.getDatasetByKey(slideName);
      if (!data) {
        return;
      }
      return data.map((item) => {
        return {
          ...item,
          fieldName: slideName,
          selected: false,
          isFavorite: false,
        };
      });
    },

    async fetchData() {
      this.listHAndE = this.getListSlidesByName(DROPDOWN_SHORT_NAME.H_AND_E);
      this.listImmunos = this.getListSlidesByName(DROPDOWN_SHORT_NAME.IMMUNOS);
      this.listSpecialStains = this.getListSlidesByName(DROPDOWN_SHORT_NAME.SPECIAL_STAINS);
      this.listAdditionalTechniques = this.getListSlidesByName(DROPDOWN_SHORT_NAME.ADDITIONAL_TECHNIQUE);
    },

    renderSlides() {
      const selectedIds = this.value.map((item) => item.fieldItemId);
      const favoriteIds = this.value.filter((item) => item.isFavorite).map((item) => item.fieldItemId);
      this.slides = {
        HE: this.listHAndE.map((item) => {
          item.selected = this.isChangeCustomStains ? true : selectedIds.includes(item.fieldItemId);
          item.isFavorite = favoriteIds.includes(item.fieldItemId);
          return item;
        }),
        Immunos: this.listImmunos.map((item) => {
          item.selected = this.isChangeCustomStains ? true : selectedIds.includes(item.fieldItemId);
          item.isFavorite = favoriteIds.includes(item.fieldItemId);
          return item;
        }),
        SpecialStains: this.listSpecialStains.map((item) => {
          item.selected = this.isChangeCustomStains ? true : selectedIds.includes(item.fieldItemId);
          item.isFavorite = favoriteIds.includes(item.fieldItemId);
          return item;
        }),
        AdditionalTechnique: this.listAdditionalTechniques.map((item) => {
          item.selected = this.isChangeCustomStains ? true : selectedIds.includes(item.fieldItemId);
          item.isFavorite = favoriteIds.includes(item.fieldItemId);
          return item;
        }),
      };
    },

    emitChanges: debounce(function (value) {
      // merge array, and filter
      let slides = [...value.HE, ...value.Immunos, ...value.SpecialStains, ...value.AdditionalTechnique];
      const newValue = slides
        .filter((item) => item.selected)
        .map((item) => {
          return {
            fieldId: item.fieldId,
            fieldItemId: item.fieldItemId,
            isFavorite: item.isFavorite,
            fieldName: item.fieldName,
            fieldItemName: item.fieldItemName,
          };
        });
      // emit
      this.$emit('onChanged', newValue);
    }, 300),
  },
};
</script>
