<template>
  <form class="md-layout lims-form">
    <lims-block :cardText="false">
      <div class="md-layout" slot="blockContent">
        <div class="md-layout-item" v-if="query">
          <audit-trail-list :fields="items" @onClickView="onClickView">
            <md-table-toolbar slot="toolbar">
              <div class="toolbar-row">
                <div class="field-rows-per-page">
                  <lims-row-per-page :pageSize="query.pagination.pageSize" @pageSizeChanged="$pageSizeChanged" />
                </div>
                <div class="field-search">
                  <audit-trail-filter
                    v-model="query.filters"
                    :defaultValues="defaultValues"
                    :fields="fields"
                    @onResetFilters="onResetFilters"
                  ></audit-trail-filter>
                </div>
              </div>
              <lims-pagination ref="pager" :total-item="totalItem" :pagination="query.pagination"></lims-pagination>
            </md-table-toolbar>
          </audit-trail-list>
          <lims-pagination ref="pager" :total-item="totalItem" :pagination="query.pagination"></lims-pagination>
        </div>
      </div>
    </lims-block>
    <template v-if="isShowInforCaseBlock">
      <audit-trail-information-of-case
        id="caseInforBlock"
        class="show-info-case-block"
        :auditTrialInfor="auditTrailInfor"
      ></audit-trail-information-of-case>
    </template>
  </form>
</template>

<script>
import AuditTrailList from '@/pages/Case/CaseManagement/AuditTrail/AuditTrailList';
import LimsRowPerPage from '@/components/Lims/LimsRowPerPage';
import LimsPagination from '@/components/Lims/LimsPagination';
import { auditQuery } from '@/query';
import { ConfigMixins, ListWithFilterMixins } from '@/core/mixins';
import AuditTrailFilter from '@/pages/Case/CaseManagement/AuditTrail/AuditTrailFilter';
import AuditTrailInformationOfCase from '@/pages/Case/CaseManagement/AuditTrail/AuditTrailInformationOfCase';
import { buildFilterFromQuery, buildFilterQueryParams } from '@/query/utils';
import { auditService } from '@/services';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';

export default {
  components: {
    LimsPagination,
    LimsRowPerPage,
    AuditTrailList,
    AuditTrailFilter,
    AuditTrailInformationOfCase,
  },
  mixins: [ConfigMixins, ListWithFilterMixins],
  created() {
    this.initState();
  },
  props: {
    caseId: {
      type: String,
      required: false,
    },
    caseInfor: {
      type: Object,
      require: false,
    },
  },
  data() {
    return {
      items: [],
      totalItem: null,
      query: null,
      auditTrailInfor: {},
      isShowInforCaseBlock: false,
    };
  },
  computed: {
    fields() {
      return auditQuery.filters.fields;
    },
    defaultValues() {
      return auditQuery.filters.defaultValues;
    },
  },
  methods: {
    initState() {
      const queryParams = this.$route.query;
      const filters = buildFilterFromQuery(auditQuery.filters, queryParams);
      const pagination = buildFilterFromQuery(auditQuery.pagination, {
        pageSize: this.$appConfig.itemPerPageDefault,
        ...queryParams,
      });
      const sort = buildFilterFromQuery(auditQuery.sort, queryParams);
      const query = { filters, pagination, sort };
      this.query = query;
    },
    async search(queryObject) {
      const { error, data } = await auditService.getListAuditLogs({
        objectType: 'Case',
        objectKey: this.caseId,
        filters: buildFilterQueryParams(queryObject.filters),
        pagination: buildFilterQueryParams(queryObject.pagination),
      });
      if (error) {
        this.$alertError(error);
        return;
      }
      this.items = data.items;
      this.totalItem = data.pagination.totalItems;
      const query = {
        ...buildFilterQueryParams(queryObject.filters),
        ...buildFilterQueryParams(queryObject.pagination),
      };
      this.$router
        .push({
          query,
        })
        .catch((error) => {
          if (
            error.name === 'NavigationDuplicated' &&
            error.message.includes('Avoided redundant navigation to current location')
          ) {
            history.pushState({}, '', new URL(new URL(window.location)));
          }
        });
    },
    onResetFilters() {
      this.query.filters = cloneDeep(auditQuery.filters.defaultValues);
    },
    onClickView(item) {
      if (!isEmpty(item['objectData'])) {
        this.isShowInforCaseBlock = true;
        this.auditTrailInfor = {
          ...item,
          caseReference: this.caseInfor.caseReference,
          patient: `${this.caseInfor.firstName} ${this.caseInfor.lastName}`,
          clinicianName: this.caseInfor.clinicianName,
          pathologistName: this.caseInfor.pathologistName,
        };
        setTimeout(() => {
          const el = document.getElementById('caseInforBlock');
          if (el) {
            const position = el.getBoundingClientRect().top + window.scrollY;
            window.scrollTo({ top: position - 60, behavior: 'smooth' });
          }
        }, 100);
      } else {
        this.isShowInforCaseBlock = false;
        this.auditTrailInfor = null;
      }
    },
  },
};
</script>
