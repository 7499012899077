<template>
  <!-- <div class="md-layout lims-form-row specialty-list"> -->
  <!-- Add form -->
  <!-- <specialty-list-item :disabled="disabled" :availableOptions="availableOptions" mode="add" @onAdd="onAdd" />
    <slot name="error"></slot> -->
  <!-- list with edit form -->
  <!-- <specialty-list-item
      v-for="(item, index) in selectedOptions"
      :availableOptions="availableOptions"
      mode="edit"
      :disabled="disabled"
      :item="item"
      @onSave="onSave"
      @onDelete="onDelete"
      :key="`specialty-item-${index}`"
    /> -->
  <!-- </div> -->
  <ValidationObserver ref="formSpecialListItem" v-slot="{ handleSubmit }" tag="div" :disabled="true">
    <form class="lims-form" @submit.prevent="handleSubmit()">
      <div class="md-layout">
        <div class="md-layout-item md-size-25 md-small-size-100">
          <label class="required">{{ $t('entities/user/personalInfo/specialtyListItem/specialty') }}</label>
        </div>
        <div class="md-layout-item md-size-50 md-small-size-100">
          <label>{{ $t('entities/user/personalInfo/specialtyListItem/subSpecialty') }}</label>
        </div>
      </div>
      <div v-for="(item, index) in selectedOptions" :key="`specialty-item-${index}`" class="md-layout">
        <div class="md-layout-item md-size-25 md-small-size-100">
          <v-select
            v-model="item.specialty"
            :options="availableOptions"
            :disabled="disabled"
            :placeholder="$t('entities/user/personalInfo/specialtyListItem/specialty.placeholder')"
            @input="valSpecimen"
            :tabindex="9"
          >
            <template #option="{ label }">{{ label }}</template>
            <template #selected-option="{ label }">{{ label }}</template>
          </v-select>
        </div>
        <div class="md-layout-item md-size-50 md-small-size-100">
          <md-field>
            <md-input
              :disabled="disabled"
              v-model="item.subSpecialtyValue"
              type="text"
              maxlength="100"
              :tabindex="10"
            ></md-input>
          </md-field>
        </div>
        <div class="md-layout-item groupBtn-for-newrow">
          <span @click="disabled ? '' : deleteSpecialty(item)" style="margin-top: -25px"
            ><md-icon>close</md-icon>
          </span>
        </div>
      </div>
    </form>
    <slot name="error"></slot>
    <div class="md-layout lims-form-row">
      <md-button class="lims-form-button md-primary" @click="addSpecialty" :disabled="disabled">
        {{ $t('entities/user/personalInfo/specialtyListItem/button.addNewSpecialty') }}
      </md-button>
    </div>
  </ValidationObserver>
</template>

<script>
import { UserService } from '@/services';
import { MyAccountService } from '@/services';
import UserMixins from '@/pages/Account/User/user.mixins';

export default {
  name: 'AccountUserSpecialtyList',
  mixins: [UserMixins],
  props: {
    disabled: {
      type: Boolean,
      require: false,
      default: false,
    },
    value: {
      type: Array,
      require: false,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      specialtyList: [],
      selectedOptions: [
        {
          specialty: null,
          subSpecialtyValue: null,
        },
      ],
    };
  },
  watch: {
    selectedOptions: {
      handler: function (val) {
        this.$emit('input', val);
      },
      deep: true,
    },
    value: {
      deep: true,
      handler: function (val) {
        this.selectedOptions = val;
      },
    },
  },
  async mounted() {
    this.specialtyList = await this.loadSpecialtyList();
  },
  computed: {
    availableOptions() {
      return this.specialtyList.filter(
        (item) => !this.selectedOptions.map((item) => item.specialtyId).includes(item.value),
      );
    },
  },
  methods: {
    async loadSpecialtyList() {
      let responseData;

      if (this.isDefaultView) {
        responseData = await UserService.findSpecialties();
      } else {
        if (this.isMyProfileView) {
          responseData = await MyAccountService.findSpecialties();
        }
      }
      return responseData.data.map((item) => {
        return {
          value: item.id,
          label: item.text,
        };
      });
    },
    valSpecimen() {
      const dataSpecialtyList = this.selectedOptions.filter((i) => i.specialty !== null);
      if (dataSpecialtyList.length > 0) {
        const specialtyData = dataSpecialtyList.map((item) => {
          return {
            specialty: item.specialty,
            specialtyId: item.specialty.value,
            specialtyName: item.specialty.label,
            subSpecialtyValue: item.subSpecialtyValue,
          };
        });
        this.selectedOptions = specialtyData;
      }
    },
    addSpecialty() {
      this.selectedOptions.push({
        specialty: null,
        subSpecialtyValue: null,
      });
    },
    deleteSpecialty(item) {
      const idx = this.selectedOptions.findIndex((selectedOption) => selectedOption.specialtyId === item.specialtyId);
      this.selectedOptions.splice(idx, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.specialty-list > div {
  width: 100%;
}
</style>
