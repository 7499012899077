<template>
  <div>
    <md-table class="paginated-table table-striped table-hover lims-table import-history-tbl">
      <md-table-toolbar>
        <slot name="toolbar"></slot>
      </md-table-toolbar>
      <div class="table-fix-header">
        <thead>
          <md-table-row>
            <md-table-head v-for="(col, index) in colTabl" :key="index" :class="col.class">
              <div>{{ $t(col.name) }}</div>
            </md-table-head>
          </md-table-row>
        </thead>
        <tbody>
          <md-table-row v-for="(item, index) in ocrUploadHistoryList" :key="`ocrUploadHistoryList${index}`">
            <md-table-cell>{{ item.batchID }}</md-table-cell>
            <md-table-cell>
              {{ item.uploadedDate ? formatDateTimeGlobalNotIncludeTime(item.uploadedDate) : '' }}
            </md-table-cell>
            <md-table-cell>{{ item.user }}</md-table-cell>
            <md-table-cell>
              <!-- WIP -->
              <md-icon v-if="item.statusName == 'Fully Completed'" class="status-enabled">check_circle</md-icon>
              <md-icon v-if="item.statusName == 'Failed'" class="grey-color">block</md-icon>
              <md-icon v-if="item.statusName == 'In Progress'" class="red-color">pause_circle</md-icon>
              <md-icon v-if="item.statusName == 'Partially Completed'" class="status-invited">check_circle</md-icon>
            </md-table-cell>
            <md-table-cell class="cell-action">
              <div class="cell-action-inner">
                <div style="width: 30px">
                  <md-button class="md-just-icon md-simple">
                    <md-icon>visibility</md-icon>
                  </md-button>
                </div>
              </div>
            </md-table-cell>
          </md-table-row>
        </tbody>
      </div>
    </md-table>
  </div>
</template>

<script>
import { DateTimeHelper } from '@/core/helpers';

export default {
  name: 'OcrUploadHistoryList',

  components: {},
  props: {
    ocrUploadHistoryList: {
      required: false,
      default: function () {
        return [];
      },
    },
  },

  data() {
    return {
      colTabl: [
        {
          name: 'pages/case/OcrUploadHistory/list.batchID',
          key: 'batchID',
          sort: false,
          class: 'batchID-column',
        },
        {
          name: 'pages/case/OcrUploadHistory/list.uploadedDate',
          key: 'uploadedDate',
          sort: false,
          class: 'uploadedDate-column',
        },
        {
          name: 'pages/case/OcrUploadHistory/list.user',
          key: 'user',
          sort: false,
          class: 'user-column',
        },
        {
          name: 'global/pages/list.status',
          key: 'status',
          sort: false,
          class: 'status-column',
        },
        {
          name: 'global/pages/list.actions',
          key: 'actions',
          sort: false,
          class: 'actions-column',
        },
      ],
    };
  },
  methods: {
    formatDateTimeGlobalNotIncludeTime(datetimeValue) {
      const dateTimeAppFormat = 'dd/MM/yyyy';
      const { timezoneValue } = this.$store.getters['config/$appConfig'];
      return DateTimeHelper.formatDateTimeGlobal(datetimeValue, {
        timezoneValue,
        dateTimeAppFormat,
      });
    },
  },
};
</script>

<style lang="scss"></style>
