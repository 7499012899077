<template>
  <ValidationObserver ref="form">
    <md-dialog
      :md-close-on-esc="true"
      :md-active.sync="isVisible"
      @md-clicked-outside="onCancel"
      class="lims-modal modal-tel-input"
    >
      <div class="dialog-icon-close" @click="onCancel"><md-icon>close</md-icon></div>
      <div class="lims-form dialog-content">
        <lims-field
          :model="formData.telephoneMobileData"
          :schema="completeYourAccountSchema.validateInformation"
          :field="fieldName"
        >
          <lims-tel-input slot="field" @keyup.enter="onNext" v-model="formData.telephoneMobileData"></lims-tel-input>
        </lims-field>
      </div>
      <md-dialog-actions>
        <md-button @click="onCancel" class="lims-form-button">{{ $t('global/button/button.cancel') }}</md-button>
        <md-button :disabled="isDisabledNextButton()" @click="onNext" class="lims-form-button md-primary">{{
          $t('global/button/button.next')
        }}</md-button>
      </md-dialog-actions>
    </md-dialog>
  </ValidationObserver>
</template>

<script>
import modalMixins from './modal.mixins';
import LimsTelInput from '@/components/Lims/LimsTelInput';
import { getCompleteYourAccountSchema } from '@/schemas/complete-your-account.schema';
import { VERIFY_MODE, VERIFY_TW0_FA_MODE } from '@/core/constants';

export default {
  name: 'modal-input-your-telephone',
  components: {
    LimsTelInput,
  },
  mixins: [modalMixins],

  props: {
    userId: {
      type: String,
      default: null,
    },

    token: {
      type: String,
      default: null,
    },

    verifyTwoFaMode: {
      type: Number,
    },

    mobilePhone: {
      type: Object,
    },
  },

  data() {
    return {
      formData: {
        telephoneMobileData: {},
      },
    };
  },

  computed: {
    completeYourAccountSchema() {
      return getCompleteYourAccountSchema(VERIFY_MODE.PHONE_NUMBER, this.userId, this.token);
    },

    fieldName() {
      return this.verifyTwoFaMode === VERIFY_TW0_FA_MODE.UPDATE ? 'phoneNumber' : 'newTelephone';
    },
  },

  watch: {
    mobilePhone() {
      this.formData.telephoneMobileData = this.mobilePhone;
    },

    isVisible() {
      if (this.isVisible) {
        this.formData.telephoneMobileData = this.mobilePhone;
      }
    },
  },

  methods: {
    onNext() {
      this.$refs.form.validate().then((success) => {
        if (success) {
          try {
            this.$emit('onNext', this.formData.telephoneMobileData);
          } catch (errors) {
            this.$alertError(errors);
          }
        } else {
          this.$alertError(this.$t(`global/errors/message`));
        }
      });
    },

    isDisabledNextButton() {
      return !this.formData.telephoneMobileData.countryCodeMobile || !this.formData.telephoneMobileData.telephoneMobile;
    },

    onCancel() {
      this.formData.telephoneMobileData = {};
      this.close();
    },
  },
};
</script>

<style lang="scss">
.modal-tel-input {
  .custom-tel-input {
    .v-select.select-country {
      &.vs--open {
        margin-bottom: 350px;
      }
    }
  }
}
</style>
