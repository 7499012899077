<template>
  <div v-if="ready && alwResource">
    <alw-form :alwFormResource="alwResource" :formMode="formMode"></alw-form>
  </div>
</template>

<script>
import AlwForm from '@/pages/Case/AlwManagement/Form/AlwForm.vue';
import { caseAlwService } from '@/services';
import CaseAlwMixins from '@/pages/Case/AlwManagement/CaseAlw.mixins';
import { ResetHeadingTitleMixins } from '@/core/mixins';
import entityService from '@/services/entity.service';
import { mapActions } from 'vuex';

export default {
  mixins: [CaseAlwMixins, ResetHeadingTitleMixins],
  components: {
    AlwForm,
  },
  props: {
    caseALWRequestId: {
      type: String,
      required: true,
    },
    formMode: {
      type: Number,
      required: true,
    },
  },
  async created() {
    await this.getCaseDetail(this.caseALWRequestId);
  },

  data() {
    return {
      ready: false,
      alwResource: {},
    };
  },
  methods: {
    ...mapActions('caseForm', ['setBlockNamingRuleSetting']),
    async getCaseDetail(caseALWRequestId) {
      const res = await caseAlwService.findOne(caseALWRequestId);
      if (res.err) {
        return this.$alertErrorServerSide(res.err);
      }
      if (!res.data) {
        return this.$alertError('Data is empty!!!');
      }
      const blockNamingRuleSetting = await entityService.getBlockNamingRuleSetting(res.data.laboratoryId);
      this.setBlockNamingRuleSetting({ blockNamingRuleSetting });
      this.alwResource = { ...res.data };
      this.ready = true;
    },
  },
};
</script>

<style lang="scss" scoped></style>
