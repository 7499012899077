<template>
  <div :class="wrapperClass">
    <div
      :class="['lims-md-collapse', activeCollapse(index + 1), { [getColorCollapse(colorCollapse)]: true }]"
      v-for="(item, index) in collapse"
      :key="item"
    >
      <div class="lims-md-collapse-label" @click="toggle(index + 1)">
        <md-icon>{{ icon }}</md-icon>
      </div>

      <collapse-transition>
        <div class="md-collapse-content" v-show="getActiveCollapse(index + 1)">
          <slot :name="getCollapseContent(index + 1)"></slot>
        </div>
      </collapse-transition>
    </div>
  </div>
</template>

<script>
import { CollapseTransition } from 'vue2-transitions';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'collapse',
  components: {
    CollapseTransition,
  },
  props: {
    wrapperClass: String,
    collapse: Array,
    icon: String,
    colorCollapse: String,
    isCollapsed: Boolean,
    expandCollapseBlockName: String,
  },
  data() {
    return {
      isActive: 1,
    };
  },
  created() {
    this.isActive = this.isCollapsed ? 0 : 1;
  },
  computed: {
    ...mapGetters('caseForm', ['getExpandCollapseBlockName']),
  },
  watch: {
    getExpandCollapseBlockName() {
      if (this.getExpandCollapseBlockName === this.expandCollapseBlockName) {
        this.setCollapseIsOpen();
        this.clearExpandCollapseBlockName();
      }
    },
  },
  methods: {
    ...mapActions('caseForm', ['clearExpandCollapseBlockName']),
    ...mapActions('reviewCase', ['setExpandCollapseReviewSession']),
    setCollapseIsOpen() {
      this.isActive = 1;
    },
    getActiveCollapse(index) {
      return this.isActive === index;
    },
    activeCollapse(index) {
      return {
        'is-active': this.isActive === index,
      };
    },
    toggle(index) {
      if (index === this.isActive) {
        this.isActive = 0;
      } else {
        this.isActive = index;
      }
      this.setExpandCollapseReviewSession({ blockName: this.expandCollapseBlockName, blockValue: !this.isActive });
    },
    getCollapseContent: function (index) {
      return 'md-collapse-pane-' + index + '';
    },
    getColorCollapse: function (colorCollapse) {
      return 'md-' + colorCollapse + '';
    },
  },
};
</script>

<style lang="scss" scoped>
.text-center {
  display: flex;
}
</style>
