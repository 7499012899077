import { render, staticRenderFns } from "./AdditionalLabWork.vue?vue&type=template&id=f56f0918&scoped=true&"
import script from "./AdditionalLabWork.vue?vue&type=script&lang=js&"
export * from "./AdditionalLabWork.vue?vue&type=script&lang=js&"
import style0 from "./AdditionalLabWork.vue?vue&type=style&index=0&id=f56f0918&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f56f0918",
  null
  
)

export default component.exports