// Layouts
const DashboardLayout = () => import('@/layouts/DashboardLayout.vue');

// Global Variables pages
const GlobalVariables = () => import('@/pages/GlobalVariables/GlobalVariables.vue');

import guards from './guards';

export default {
  path: '/global-variables',
  component: DashboardLayout,
  beforeEnter: guards.checkAuthorizedGuard,
  children: [
    {
      path: '',
      name: 'GlobalVariables',
      component: GlobalVariables,
      meta: {
        zone: ['GlobalVariables_GlobalVariables_Retrieve'],
      },
    },
  ],
};
