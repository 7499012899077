/* eslint-disable security/detect-object-injection */
import LimsField from '@/components/Lims/LimsField.vue';
import LimsFormCancel from '@/components/Lims/LimsFormCancel.vue';
import LimsFormEditButton from '@/components/Lims/LimsFormEditButton.vue';
import ModalConfirmGeneric from '@/components/Lims/modals/ModalConfirmGeneric.vue';
import { CASE_STATUS, FORM_MODES } from './constants';
import _, { debounce } from 'lodash';
import { mapActions, mapGetters, mapState } from 'vuex';
import { DateTimeHelper, isPerfectEmpty } from './helpers';
import validators from '@/schemas/validators';
import { FF_ALW_CaseSpecimentBlocks, FF_SlideBlocks } from '@/query/filter-fields';
import { FIELDS_WAS_RESET_TO_NULL, getSwapEntityInvalidFields } from '@/pages/Case/CaseManagement/CaseBlock.mixins';
import { buildUserQueryParams } from '@/services/caseList.service';

export const ConfigMixins = {
  computed: {
    ...mapGetters('config', ['$appConfig']),
  },
};

export const ResetHeadingTitleMixins = {
  beforeRouteLeave(to, from, next) {
    // called when the route that renders this component is about to
    // be navigated away from.
    // has access to `this` component instance.
    this.$store.commit('app/setHeadingTitle', '');
    this.$store.commit('app/setIsUrgent', false);
    next(true);
  },
};

export const UnSaveChangesMixins = {
  components: { ModalConfirmGeneric },
  computed: {
    ...mapState('app', {
      $changeDetection: (state) => state.changeDetection,
    }),
    ...mapGetters('caseForm', ['isUnsavedChanged', 'fieldsToBeSetNull']),
  },
  watch: {
    '$changeDetection.confirmed': function () {
      const { confirmed, toUrl } = this.$changeDetection;
      if (confirmed && toUrl) {
        this.$router.push(toUrl);
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    // called when the route that renders this component is about to
    // be navigated away from.
    // has access to `this` component instance.
    const isCaseFormEdit = from.path.includes('cases/edit');
    const isCaseFormView = from.path.includes('cases/view');
    const isReviewCase = from.path.includes('review-cases');
    const isCaseFormAdd = from.path.includes('cases/add');
    const isCaseFormUnsavedChanged = this.isUnsavedChanged;
    // formData changed
    const { changed, confirmed } = this.$changeDetection;
    if (confirmed) {
      next(true);
      return;
    }
    // other form and unsaved change, then show modal
    if (!isCaseFormEdit && !isCaseFormView && !isCaseFormAdd && !isReviewCase && changed) {
      this.$setToUrl({ toUrl: to });
      this.$refs.unSaveChangesModal.open();
      next(false);
      return;
    }
    // case form
    if (isCaseFormEdit || isReviewCase) {
      const userType = this.$store.getters['auth/userType'];
      const isAdministrator = userType === this.USER_TYPES()?.Administrator;
      const caseData = this.$store.getters['caseData/caseData'];
      const isFromPathStatus = ![CASE_STATUS.LAB, CASE_STATUS.PRE_LAB].includes(caseData?.status);
      const fieldsToBeSetNull = this.$store.getters['caseForm/fieldsToBeSetNull'];
      let invalidFields = getSwapEntityInvalidFields(fieldsToBeSetNull);
      invalidFields = invalidFields.filter((f) => f !== FIELDS_WAS_RESET_TO_NULL.ENTITY_BILLING);
      if (isAdministrator && isFromPathStatus && invalidFields.length > 0) {
        if (invalidFields.length > 0) {
          this.$alertError(this.$t('pages/case/CaseManagement/CaseForm/SwapEntity/ValueReset/warning'));
          next(false);
          return;
        }
      }
      if (isCaseFormUnsavedChanged && isCaseFormEdit) {
        this.$setToUrl({ toUrl: to });
        this.$refs.unSaveChangesModal.open();
        next(false);
        return;
      }
      if (isReviewCase) {
        this.$store.commit('reviewCase/resetExpandCollapseReviewSession');
      }
    }
    if (isCaseFormAdd && isCaseFormUnsavedChanged) {
      this.$setToUrl({ toUrl: to });
      this.$refs.unSaveChangesModal.open();
      next(false);
      return;
    }
    next(true);
  },
  methods: {
    onUnSaveChanges() {
      this.$confirmUnSaveChanges();
    },
    ...mapActions('app', ['$setToUrl', '$confirmUnSaveChanges']),
  },
};

export const TabMixins = {
  data() {
    return {
      isTabIndexNotReady: true,
    };
  },
  mounted() {
    // eslint-disable-next-line no-undef
    const setChildrenTabIndex = (ele, querySelector, index) => {
      const inputElements = ele.querySelectorAll(querySelector);
      for (const e of inputElements) {
        e.setAttribute('tabindex', index);
        e.classList.add('tabindex');
      }
    };
    if (this.isTabIndexNotReady) {
      const tabElements = document.querySelectorAll('[tabEnable*="yes"]');
      let index = 0;
      for (const ele of tabElements) {
        const isCustomSelectField = ele.classList.contains('v-select');
        const isCustomCheckbox = ele.classList.contains('md-checkbox');
        const isCustomDatePicker = ele.classList.contains('mx-datepicker');
        if (isCustomSelectField) {
          setChildrenTabIndex(ele, '.vs__selected-options input[type="search"]', ++index);
        } else if (isCustomCheckbox) {
          setChildrenTabIndex(ele, 'input[type="checkbox"]', ++index);
        } else if (isCustomDatePicker) {
          setChildrenTabIndex(ele, 'input[type="text"]', ++index);
        } else {
          ele.setAttribute('tabindex', ++index);
        }
      }
      if (tabElements.length > 0) {
        tabElements[0].focus();
      }
      this.isTabIndexNotReady = false;
    }
  },
};

export const FormMixins = {
  components: { LimsFormCancel, LimsFormEditButton, LimsField },

  computed: {
    ADD_MODE() {
      return FORM_MODES.ADD;
    },
    EDIT_MODE() {
      return FORM_MODES.EDIT;
    },
    VIEW_MODE() {
      return FORM_MODES.VIEW;
    },
    requestStatus() {
      if (this.caseFormResource.userCaseAnonymizeRequest) {
        return this.caseFormResource.userCaseAnonymizeRequest.status;
      } else {
        return '';
      }
    },
  },

  watch: {
    filters: {
      handler(val) {
        if (val.search.length > 2) {
          this.onSearch();
        }
      },
      deep: true,
    },
    formData: {
      handler: function () {
        this.$setChangeDetection();
      },
      deep: true,
    },
  },

  methods: {
    $lowercase(model, field) {
      model[`${field}`] = model[`${field}`].toLowerCase();
      return model[`${field}`];
    },

    $lowercaseAndTrimSpace(model, field) {
      model[`${field}`] = model[`${field}`].toLowerCase().trim();
      return model[`${field}`];
    },

    ...mapActions('app', ['$setChangeDetection', '$resetChangeDetection']),
    formatDateTimeGlobal(datetimeValue) {
      const { timezoneValue, dateTimeAppFormat } = this.$store.getters['config/$appConfig'];
      return DateTimeHelper.formatDateTimeGlobal(datetimeValue, {
        timezoneValue,
        dateTimeAppFormat,
      });
    },
    formatDateTimeGlobalNotIncludeTime(datetimeValue) {
      const dateTimeAppFormat = 'dd/MM/yyyy';
      const { timezoneValue } = this.$store.getters['config/$appConfig'];
      return DateTimeHelper.formatDateTimeGlobal(datetimeValue, {
        timezoneValue,
        dateTimeAppFormat,
      });
    },
    async $runServerSideValidation(constraint, params) {
      const { fieldName, errorType, functionName } = constraint;
      const parentForm = params.formRef || null;
      let value = params[fieldName] || '';
      const valid = await validators[functionName](value, params);
      if (!valid) {
        const error = {};
        // eslint-disable-next-line security/detect-object-injection
        error[fieldName] = errorType;
        if (parentForm) {
          parentForm.setErrors(error);
        }
        // this.$refs[formRef].setErrors(error);
      }
      return valid;
    },
    async $onServerSideValidation(e, fieldValidator, filters = []) {
      const { fieldName, errorType, functionName, params } = fieldValidator;
      const formRef = fieldValidator.formRef || 'form';
      const parentForm = params.formRef || null;
      let value = e && e.target ? e.target.value : e;
      // apply filters to transform data
      if (filters.length > 0) {
        for (let i = 0; i < filters.length; i++) {
          value = filters[i](value);
        }
      }

      // eslint-disable-next-line security/detect-object-injection
      const valid = await validators[functionName](value, params);
      if (!valid) {
        const error = {};
        // eslint-disable-next-line security/detect-object-injection
        error[fieldName] = errorType;
        if (parentForm) {
          parentForm.setErrors(error);
        }
        this.$refs[formRef].setErrors(error);
      }
      return valid;
    },
  },
};

export const ListMixins = {
  watch: {
    filters: {
      handler(val) {
        if (val.search.length > 2) {
          this.onSearch();
        }
      },
      deep: true,
    },
  },
};

export const ListWithFilterMixins = {
  watch: {
    'query.filters': {
      deep: true,
      handler: function (_, prev) {
        // if the filters changed and query.filters has value, we must reset page value to 1
        if (prev && this.$refs.pager) {
          this.$refs.pager.changePage(1);
        }
      },
    },
    query: {
      deep: true,
      // watch query changed with debounce to prevent spamming request
      handler: debounce(function (value) {
        this.$queryChange(value);
      }, 300),
    },
  },
  methods: {
    $queryChange: debounce(function (query) {
      this.search(query);
    }),
    $pageSizeChanged(number) {
      this.query.pagination.pageSize = number;
      if (typeof this.query.pagination.pageNumber === 'number') {
        this.query.pagination.pageNumber = 1;
      } else {
        this.query.pagination.pageNumber = this.query.pagination.pageNumber.map(() => {
          return 1;
        });
      }
    },
  },
};

export const ListFilterMixins = {
  ...mapActions('itemList', ['unsetSelectAll']),
  mounted() {},
  methods: {
    $onClearFilter() {
      this.unsetSelectAll();
      this.$nextTick(() => {
        this.$refs.form.reset();
      });
      if (this.checkboxValue) {
        this.$set('checkboxValue', {});
      }
      if (this.filterValues) {
        this.$set('filterValues', {});
      }
      Object.keys(this.filters).map((k) => {
        this.filters[k] = this.defaultValues[k];
      });
    },
    $updateChipList({ filterValues, dataSource, filterFields }) {
      let chipOptions = [];

      for (let i = 0; i < filterFields.length; i++) {
        const { key, listKey, sort, translated } = filterFields[i];
        const values = filterValues[key];
        const isChip = listKey != null;
        if (!isPerfectEmpty(values) && isChip && dataSource[listKey]) {
          const selectedOptions = dataSource[listKey]
            .filter((option) => {
              return values.includes(option.value);
            })
            .map((item) => {
              return {
                value: item.value,
                label: translated ? item.label : this.$translate(item.label),
                key,
                sortKeyType: sort,
              };
            });
          chipOptions.push(...selectedOptions);
        } else if (key === FF_ALW_CaseSpecimentBlocks || key === FF_SlideBlocks) {
          const value = values.map((item) => {
            return {
              value: item,
              label: item,
              key,
              sortKeyType: sort,
            };
          });
          chipOptions.push(...value);
        }
        this.chipOptions = chipOptions;
      }
    },
    $onRemoveChip(chip) {
      const { key, value } = chip;
      if (this.filterValues) {
        return (this.filterValues[key] = this.filterValues[key].filter((v) => v != value));
      }
      return (this.filters[key] = this.filters[key].filter((v) => v != value));
    },
  },
  watch: {},
};

export const MasterCheckBoxMixins = {
  computed: {
    masterCheckBoxParams() {
      if (!this.listName) {
        this.$alertError('Missing listName for mastercheckbox!');
        return;
      }
      return this[this.listName];
    },
    isBulkActionApplyDisabled() {
      if (!this.listName) {
        this.$alertError('Missing listName for mastercheckbox!');
        return;
      }
      const { selectedItemIds, selectAll, isSelectCurrentPageMode } = this.masterCheckBoxParams;
      return !this.bulkAction || !(selectAll || isSelectCurrentPageMode || selectedItemIds.length > 0);
    },
    numberOfSelectedItems() {
      const { selectedItemIds, unselectedItemIds, selectAll, isSelectCurrentPageMode, searchQuery } =
        this.masterCheckBoxParams;
      const pageSize = parseInt(searchQuery?.pagination?.pageSize) || 0;
      if (isSelectCurrentPageMode) {
        return pageSize - unselectedItemIds.length;
      }
      if (selectAll) {
        return this.totalItem - unselectedItemIds.length;
      }
      // default
      return selectedItemIds.length;
    },
  },
  watch: {
    selectAll: function (value) {
      this.validateRequiredListName();
      value ? this.setSelectAll(this.listName) : this.unsetSelectAll(this.listName);
      if (this.toggleSelectionList) {
        this.toggleSelectionList(value);
        return;
      }
      // otherwise throw error
      this.$alertError(`Missing implementation for toggleSelectionList of ${this.listName}!`);
    },
  },
  data() {
    return {
      selectAll: false,
      listName: '',
    };
  },
  methods: {
    ...mapActions('itemList', ['setSelectAll', 'unsetSelectAll', 'addSelectedItemId', 'removeSelectedItemId']),
    // requireListName
    validateRequiredListName() {
      if (!this.listName) {
        this.$alertError(`Missing configuration of listName!`);
        return;
      }
    },
    // toggle select item
    $onSelectItem(selectRow, itemId) {
      this.validateRequiredListName();
      // if in list selectRow then add
      if (_.includes(selectRow, itemId)) {
        this.addSelectedItemId({ listName: this.listName, itemId, totalItem: this.totalItem || 0 });
        return;
      }
      // otherwise then remove
      this.removeSelectedItemId({ listName: this.listName, itemId, totalItem: this.totalItem || 0 });
    },
    // toogle selectAll checkbox
    $toggleSelectAllCheckbox(listState) {
      if (this.selectAll !== listState.selectAll) {
        this.selectAll = listState.selectAll;
      }
      if (this.isSelectCurrentPageMode !== listState.isSelectCurrentPageMode) {
        this.isSelectCurrentPageMode = listState.isSelectCurrentPageMode;
      }
    },
    $getMasterCheckBoxData() {
      const { selectedItemIds, unselectedItemIds, selectAll, searchQuery, isSelectCurrentPageMode } =
        this.masterCheckBoxParams;
      const buildQuery = buildUserQueryParams(searchQuery, true);
      if (selectAll) {
        Reflect.set(buildQuery, 'pageNumber', 0);
      }
      return {
        selectAll,
        caseIds: selectedItemIds,
        exceptCaseIds: unselectedItemIds,
        searchQuery: buildQuery,
        selectCurrentPage: isSelectCurrentPageMode,
      };
    },
  },
};
