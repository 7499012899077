<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit }">
    <form class="md-layout lims-form form-login" @submit.prevent="handleSubmit()">
      <md-dialog :md-close-on-esc="true" :md-active.sync="isVisible" @md-clicked-outside="close" class="lims-modal">
        <div class="dialog-icon-close" @click="close"><md-icon>close</md-icon></div>
        <md-dialog-title>{{ $t('components/ModalBulkActionProblem.title') }}</md-dialog-title>
        <div class="lims-form dialog-content">
          <p class="text-center" v-html="$t('components/ModalBatchChangeSpecimenceTypeProblem.text01')"></p>
          <p class="text-center" style="margin-top: 24px">
            {{ $t('components/ModalBatchChangeSpecimenceTypeProblem.text02') }}
          </p>
        </div>
        <md-dialog-actions>
          <md-button @click="close" class="lims-form-button md-primary">{{ $t('global/button/button.ok') }}</md-button>
        </md-dialog-actions>
      </md-dialog>
    </form>
  </ValidationObserver>
</template>

<script>
import modalMixins from './modal.mixins';

export default {
  name: 'ModalBatchChangeSpecimenceTypeProblem',
  mixins: [modalMixins],
  props: {},
  data() {
    return {};
  },
  computed: {},
  methods: {
    close() {
      this.isVisible = false;
      this.$emit('onCancel');
    },
  },
};
</script>

<style lang="scss"></style>
