<template>
  <div class="lims-form">
    <div class="md-layout lims-form-row">
      <div :class="requireFile && fileAttachments.length == 0 ? 'dropzone-err dropzone-wrapper' : 'dropzone-wrapper'">
        <vue-dropzone
          ref="myVueDropzone"
          id="dropzone"
          :options="dropzoneOptions"
          :useCustomSlot="true"
          @vdropzone-file-added="onAddFile"
          :class="fileAttachments.length > 0 ? 'disabled' : ''"
        >
          <div class="dropzone-container">
            <h4>{{ $t('global/vueDropzone.txt') }}</h4>
            <md-button class="md-button md-primary lims-form-button md-theme-default">
              {{ $t('global/button/button.chooseFile') }}
            </md-button>
          </div>
        </vue-dropzone>

        <div v-for="(item, index) in fileAttachments" :key="index" class="dropzone-file-add">
          <div class="file-item">
            <div class="icon">
              <span @click="onRemoveFile(item)"><md-icon>close</md-icon></span>
            </div>
            <img class="img" :src="require(`@/assets/images/icon-file-excel.png`)" />
            <div>{{ item.name }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="md-layout lims-form-row mg-top-24">
      <div class="md-layout-item md-size-100 md-small-size-100 lims-form-actions">
        <md-button class="md-button md-primary lims-form-button" @click="onUploadAndValidate()">
          {{ $t('pages/case/ImportCase/components/ImportCases/button.UploadAndValidate') }}
        </md-button>
      </div>
    </div>
  </div>
</template>

<script>
import { caseImportService } from '@/services';
import { APP_ROUTES } from '@/core/constants';
import { DateTimeHelper } from '@/core/helpers';
export default {
  data() {
    return {
      dropzoneOptions: {
        url: '/',
        autoProcessQueue: false,
        acceptedFiles: '.xls,.xlsx,.xlsm,.csv',
        uploadMultiple: true,
        maxFiles: 99,
        previewsContainer: false,
        init: function () {
          this.on('error', function (file) {
            if (!file.accepted) this.removeFile(file);
          });
        },
      },
      reviewLink: '',
      fileAttachments: [],
      requireFile: false,
    };
  },
  methods: {
    onAddFile(file) {
      const url = URL.createObjectURL(file);
      this.reviewLink = url;
      var fileReader = new FileReader();
      fileReader.onload = () => {
        this.fileAttachments = this.$refs.myVueDropzone.dropzone.files.filter((x) => x.accepted == true);
      };
      fileReader.readAsDataURL(file);
    },
    onRemoveFile(item) {
      const idx = this.fileAttachments.findIndex((fileAttachment) => fileAttachment.name === item.name);
      this.fileAttachments.splice(idx, 1);
      this.$refs.myVueDropzone.removeFile(item);
      this.reviewLink = '';
    },

    async onUploadAndValidate() {
      if (this.fileAttachments.length == 0) {
        this.$alertError(this.$t(`global/errors/message`));
        this.requireFile = true;
      } else {
        let formData = new FormData();
        this.fileAttachments.forEach((item) => {
          formData.append('caseImportFile', item);
        });
        try {
          let timezoneValue = DateTimeHelper.getTimezoneString();
          const res = await caseImportService.validateCasesImport(formData, timezoneValue);
          if (res.err) {
            return this.$alertError(res.err + ' error');
          } else {
            const caseImportInfor = {
              ...res.data,
              fileName: this.fileAttachments[0].name,
            };
            sessionStorage.setItem('caseImport', JSON.stringify(caseImportInfor));
            this.$router.push({ path: APP_ROUTES.CASE_IMPORT_CONFIRM_UPLOAD });
          }
        } catch (errors) {
          this.$alertError(errors);
        }
      }
    },
  },
};
</script>

<style lang="scss"></style>
