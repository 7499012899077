<template>
  <ValidationObserver ref="formPatientData" v-slot="{ handleSubmit }">
    <form class="md-layout lims-form" @submit.prevent="handleSubmit()">
      <div class="form-wrapper" v-if="formData">
        <diagnosis-histories
          v-if="dataEdit.caseReportHistories"
          :data-edit="dataEdit"
          :form-mode="formMode"
        ></diagnosis-histories>

        <lims-collapse-with-information
          :wrapperClass="'case-collapse diagnosis-collapse'"
          :collapse="collapseReport"
          :information="getInformationByEntityId(formData.caseSpecimens[0].caseSpecimentId)"
          icon="keyboard_arrow_down"
        >
          <template slot="md-collapse-pane-1">
            <div v-for="(specimen, index) in formData.caseSpecimens" :key="index">
              <div class="md-layout lims-form-row">
                <div class="md-layout-item md-size-25 md-small-size-100">
                  <label>{{ specimenName(index) }}</label>
                  <div class="static-text">{{ specimen.specimentTypeName }}</div>
                </div>
              </div>
              <div class="md-layout">
                <div class="md-layout-item md-size-100 md-small-size-100">
                  <lims-field :model="formData" :schema="caseFormSchema.caseSpecimens" field="specimenDetailsOnPot">
                  </lims-field>
                  <div class="static-text" v-lims-text-area="specimen.specimenDetails"></div>
                </div>
              </div>
              <div class="md-layout lims-form-row">
                <div class="md-layout-item md-size-100 md-small-size-100">
                  <label>{{ $t('entities/case/form/diagnosis/macroDescription') }}</label>
                  <div class="static-text" style="padding: 0" v-lims-text-area="specimen.macroDescription"></div>
                </div>
              </div>
              <div class="md-layout lims-form-row microscopy-row">
                <div class="md-layout-item md-size-100 md-small-size-100 pd-bottom-10">
                  <label>{{ $t('entities/case/form/diagnosis/microscopy') }}</label>
                </div>
                <div class="caseDataset" v-if="specimen.caseDataset">
                  <div
                    v-for="(control, controlIndex) in specimen.caseDataset.controls"
                    :key="controlIndex"
                    class="md-layout"
                  >
                    <div class="md-layout-item md-size-30">
                      <label>{{ control.label }}</label>
                    </div>
                    <div class="md-layout-item md-size-70">
                      <div class="static-text" style="padding: 0">{{ control.selectValue }}</div>
                    </div>
                  </div>
                </div>
                <div
                  class="static-text"
                  style="padding: 0"
                  v-if="!specimen.caseDataset"
                  v-lims-text-area="specimen.microscopy"
                ></div>
              </div>
              <div class="md-layout lims-form-row">
                <div class="md-layout-item md-size-100 md-small-size-100">
                  <lims-field :model="formData" :schema="caseFormSchema.diagnosis" field="snomed"></lims-field>
                  <div class="static-text">{{ specimen.snomed }}</div>
                </div>
              </div>
            </div>
          </template>
        </lims-collapse-with-information>

        <div v-for="(caseSupplementaryReport, index) in formData.caseSupplementaryReports" :key="index">
          <lims-collapse-with-information
            v-if="caseSupplementaryReport.isTemporary === false"
            :wrapperClass="'case-collapse diagnosis-collapse'"
            :collapse="collapseSupplementaryReport(index + 1)"
            :information="getInformationByEntityId(caseSupplementaryReport.caseSupplementaryReportId)"
            icon="keyboard_arrow_down"
          >
            <template slot="md-collapse-pane-1">
              <div class="md-layout lims-form-row">
                <div class="md-layout-item md-size-100 md-small-size-100">
                  <label>{{ $t('entities/case/form/diagnosis/microscopy') }}</label>
                  <div class="static-text" v-lims-text-area="caseSupplementaryReport.microscopy"></div>
                </div>
              </div>
              <div class="md-layout lims-form-row">
                <div class="md-layout-item md-size-33 md-small-size-100">
                  <label>{{ $t('entities/case/form/diagnosis/snomedM') }}</label>
                  <div class="static-text">{{ caseSupplementaryReport.snomedM }}</div>
                </div>
              </div>
              <div class="md-layout lims-form-row">
                <div class="md-layout-item md-size-100 md-small-size-100">
                  <label>{{ $t('entities/case/form/diagnosis/caseCommentOrDiagnosisSummary') }}</label>
                  <div
                    class="static-text"
                    v-lims-text-area="caseSupplementaryReport.caseCommentOrDiagnosisSummarySupplementary"
                  ></div>
                </div>
              </div>
            </template>
          </lims-collapse-with-information>
        </div>
        <div v-if="formMode === EDIT_MODE && !dataEdit.isDeleted" class="md-layout lims-form-row">
          <div class="md-layout-item md-size-100 md-small-size-100 lims-form-actions case-block-action">
            <lims-form-cancel></lims-form-cancel>
            <md-button @click="onSave()" class="md-button md-primary lims-form-button md-theme-default">
              {{ $t('global/button/button.save') }}
            </md-button>
          </div>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
import { FormMixins } from '@/core/mixins';
import CaseMixins from '@/pages/Case/CaseManagement/Case.mixins';
import { getCaseFormSchema } from '@/schemas/case-form.schema';
import LimsCollapseWithInformation from '@/components/Lims/LimsCollapseWithInformation';
import DiagnosisHistories from '@/pages/Case/CaseManagement/Components/DiagnosisHistories';
import CaseSpecimenMixins, { generateSpecimenName } from '@/pages/Case/CaseManagement/CaseSpecimen.mixins';
import { mapGetters } from 'vuex';

export default {
  mixins: [FormMixins, CaseMixins, CaseSpecimenMixins],
  components: { LimsCollapseWithInformation, DiagnosisHistories },
  created() {
    this.fetchData();
  },
  props: {
    dataEdit: {
      type: Object,
      require: false,
    },
    formMode: {
      type: Number,
      require: true,
    },
  },
  data() {
    return {
      formData: {
        caseSpecimens: [],
      },
    };
  },
  computed: {
    ...mapGetters('auth', ['entityId']),
    caseFormSchema: function () {
      return getCaseFormSchema(this.formMode);
    },
    collapseReport() {
      const name = 'pages/case/CaseManagement/components/Report/collapseReportTitle';
      return [this.$translate(name)];
    },
  },
  watch: {
    formData: {
      deep: true,
      handler: function (val) {
        this.$emit('input', {
          ...val,
        });
      },
    },
  },
  methods: {
    async fetchData() {
      this.formData.caseCommentOrDiagnosisSummary = this.dataEdit.diagnosisSummary;
      for (let i = 0; i < this.dataEdit.caseSpecimens.length; i++) {
        this.formData.caseSpecimens.push({
          snomed: this.getSnomed(this.dataEdit.caseSpecimens[i]),
          microscopy: this.dataEdit.caseSpecimens[i].microscopy,
          specimenDetails: this.dataEdit.caseSpecimens[i].specimenDetails,
          macroDescription: this.dataEdit.caseSpecimens[i].macroDescription,
          specimentTypeName: this.dataEdit.caseSpecimens[i].specimentTypeName,
          caseSpecimentId: this.dataEdit.caseSpecimens[i].caseSpecimentId,
          ...this.dataEdit.caseSpecimens[i],
        });
      }
      if (this.dataEdit.caseSupplementaryReports.length > 0) {
        this.formData.caseSupplementaryReports = this.dataEdit.caseSupplementaryReports;
        this.formData.caseSupplementaryReports = this.formData.caseSupplementaryReports.map(
          (caseSupplementaryReport) => {
            return {
              snomedM: caseSupplementaryReport.fieldItems[0]?.fieldItemName,
              microscopy: caseSupplementaryReport.microscopy,
              caseCommentOrDiagnosisSummarySupplementary: caseSupplementaryReport.caseComment,
              isTemporary: caseSupplementaryReport.isTemporary,
              caseSupplementaryReportId: caseSupplementaryReport.caseSupplementaryReportId,
            };
          },
        );
      }
    },
    getSnomed(caseSpecimen) {
      const listSnomed = [];
      caseSpecimen.caseSpecimenDiagnosis.forEach((item) => {
        if (item.fieldName === 'Snomed M1') {
          listSnomed.push('Snomed M' + ': ' + item.fieldItemName);
        } else {
          listSnomed.push(item.fieldName + ': ' + item.fieldItemName);
        }
      });
      return listSnomed.join(', ');
    },
    getInformationByEntityId(entityId) {
      let casePathologistAuthoriseTracking = this.dataEdit.casePathologistAuthoriseTrackings.find(
        (casePathologistAuthoriseTracking) => casePathologistAuthoriseTracking.entityId === entityId,
      );
      if (casePathologistAuthoriseTracking) {
        return this.$t('entities/case/form/diagnosis/reportedInformation', {
          authoriseDate: this.formatDateTimeGlobal(casePathologistAuthoriseTracking.authoriseDate),
          pathologistFullName: casePathologistAuthoriseTracking.pathologistFullName,
        });
      } else {
        return null;
      }
    },
    collapseSupplementaryReport(index) {
      const name = 'pages/case/CaseManagement/components/DiagnosisView/collapseSupplementaryReportTitle';
      return [this.$translate(name) + ' ' + index];
    },
    specimenName(index) {
      const name = 'entities/case/form/diagnosis/specimen';
      return this.$translate(name) + ' ' + generateSpecimenName(index, this.blockNamingRuleSetting);
    },
  },
};
</script>
<style lang="scss"></style>
