import { cloneDeep } from 'lodash';

// PersonalInfo
const PersonalInfoDTO = {
  userType: 'required',
  role: 'required',
  username: 'required|notValid:validUserNameCheck',
  title: 'required',
  firstName: 'required',
  lastName: 'required',
  address1: 'required',
  address2: '',
  town: 'required',
  country: 'required',
  postCode: '',
  email: 'required|email',
  telephoneOffice: 'notValid:validPhoneNumberCheck',
  telephoneMobile: 'notValid:validMobilePhoneCheck',
  countryCodeMobile: '',
  description: 'required',
  status: 'required',
  sendMdmEmailTo: 'listEmailValid|listEmailDuplicate',
};

const _serverSideValidators = {
  username: {
    fieldName: 'username',
    errorType: 'unique',
    functionName: 'usersIdentityCheck',
    params: {},
  },
  email: {
    fieldName: 'email',
    errorType: 'unique',
    functionName: 'usersIdentityCheck',
    params: {},
  },
  telephoneMobile: {
    fieldName: 'telephoneMobile',
    errorType: 'unique',
    functionName: 'usersIdentityCheck',
    params: {},
  },
};

const ClinicAssociateUserSchema = {
  personalInfo: {
    entity: 'user/personalInfo',
    fields: {
      ...PersonalInfoDTO,
    },
  },
  clinicInfo: {
    entity: 'user/clinicInfo',
    fields: {
      clinic: 'required',
    },
  },
  option: {
    entity: 'user/option',
    fields: {
      enableTextExport: '',
    },
  },
  _serverSideValidators,
};

const ClinicianAssociateUserSchema = {
  personalInfo: {
    entity: 'user/personalInfo',
    fields: {
      ...PersonalInfoDTO,
      associatedClinicians: 'associatedClinicians',
    },
  },
  _serverSideValidators,
};

const LabTechnicianUserSchema = {
  personalInfo: {
    entity: 'user/personalInfo',
    fields: {
      ...PersonalInfoDTO,
      availableLaboratories: 'required',
    },
  },
  _serverSideValidators,
};

const PathologistUserSchema = {
  personalInfo: {
    entity: 'user/personalInfo',
    fields: {
      ...PersonalInfoDTO,
      gmc: '',
      reference: 'required',
      pullSystem: '',
      listPathologistSpecialties: 'requiredArray',
    },
  },
  specialtyListItem: {
    entity: 'user/personalInfo/specialtyListItem',
    fields: {
      specialty: 'required',
      subSpecialty: '',
    },
  },
  _serverSideValidators,
};

const AdministratorUserSchema = {
  personalInfo: {
    entity: 'user/personalInfo',
    fields: {
      ...PersonalInfoDTO,
    },
  },
  _serverSideValidators,
};

const ClinicianSchema = {
  personalInfo: {
    entity: 'user/personalInfo',
    fields: {
      ...PersonalInfoDTO,
      speciality: '',
      accountReference: 'required',
      gmc: '',
    },
  },
  _serverSideValidators,
};

/**
 * @name Get Example Schema for Field Validation
 * @param mode : String // edit,add
 * @param params : Object // params you may need, in this case, it should be {identiyId: 1} which can
 * be used to validate unique field
 * return an object of schema
 */
const prepareUserSchema = (userSchema, _mode, params = {}) => {
  const schema = cloneDeep(userSchema);

  schema._serverSideValidators.username.params = {
    userId: params ? params.userId : '',
    field: 'username',
  };

  schema._serverSideValidators.email.params = {
    userId: params ? params.userId : '',
    field: 'email',
  };

  schema._serverSideValidators.telephoneMobile.params = {
    userId: params ? params.userId : '',
    field: 'telephoneMobile',
  };

  // default add mode
  return schema;
};

export const getUserSchema = (userType, mode, params) => {
  if (userType === 'Pathologist') {
    return prepareUserSchema(PathologistUserSchema, mode, params);
  } else if (userType === 'LabTechnician') {
    return prepareUserSchema(LabTechnicianUserSchema, mode, params);
  } else if (userType === 'ClinicianAssociate') {
    return prepareUserSchema(ClinicianAssociateUserSchema, mode, params);
  } else if (userType === 'Clinician') {
    return prepareUserSchema(ClinicianSchema, mode, params);
  } else if (userType === 'ClinicAssociate') {
    return prepareUserSchema(ClinicAssociateUserSchema, mode, params);
  } else if (userType === 'Administrator') {
    return prepareUserSchema(AdministratorUserSchema, mode, params);
  }
};
