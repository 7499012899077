<template>
  <lims-block class="c-block01">
    <h4 class="title" slot="blockTitle">
      {{ $t('pages/entity/components/billingData/blockTitle') }}
    </h4>
    <div slot="blockContent">
      <div class="md-layout">
        <div class="md-layout-item md-size-100">
          <lims-form-error :failed="failed" :error="$t('pages/MyEntity/alertMessage/atLeastOneBillingEntity')" />
        </div>
      </div>
      <div class="md-layout">
        <div class="md-layout-item md-size-33 md-small-size-100">
          <label class="required">{{ $t('pages/entity/components/billingData/billingEntity') }}</label>
          <md-field :class="[{ 'md-error': failed }]">
            <v-select
              v-model="billingEntityId"
              :options="billingEntityList"
              label="fieldItemName"
              :disabled="disabled"
              :placeholder="$t('pages/entity/components/billingData/placeholder.selectABillingEntity')"
              tabenable="yes"
              :tabindex="10"
            >
              <template #option="{ fieldItemName }">{{ fieldItemName }}</template>
              <template #selected-option="{ fieldItemName }">{{ fieldItemName }}</template>
            </v-select>
          </md-field>
        </div>
        <div class="md-layout-item md-size-33 md-small-size-100">
          <lims-field :model="formData" :schema="entitySchema.information" field="accountReference">
            <md-input
              slot="field"
              v-model="accountReference"
              type="text"
              :disabled="disabled || disabledAccountReference"
              tabenable="yes"
            >
            </md-input>
          </lims-field>
        </div>
        <div class="md-layout-item md-size-33 md-small-size-100">
          <md-button
            type="button"
            class="lims-form-button block01-btn md-primary"
            :disabled="!enabledAddBillingDataButton || disabled"
            @click="addBillingData"
          >
            <template v-if="!editBillingEntityMode">{{
              $t('pages/entity/components/billingData/button.addBillingData')
            }}</template>
            <template v-else>{{ $t('pages/entity/components/billingData/button.editBillingData') }}</template>
          </md-button>
        </div>
      </div>
      <div class="md-layout lims-form-row">
        <md-table class="custom-tbl" md-card>
          <md-table-row>
            <md-table-head width="40%">
              {{ $t('pages/entity/components/billingData/column.billingEntity') }}
            </md-table-head>
            <md-table-head>
              {{ $t('pages/entity/components/billingData/column.accountReference') }}
            </md-table-head>
            <md-table-head class="th-center cell-action">
              {{ $t('global/pages/list.actions') }}
            </md-table-head>
          </md-table-row>
          <md-table-row v-for="(item, idx) in billingDataList" :key="`${item.value}-${idx}`">
            <md-table-cell class="cell-clinicType">{{ item.fieldItemName }}</md-table-cell>
            <md-table-cell>{{ item.accountReference }}</md-table-cell>
            <md-table-cell class="cell-action">
              <span @click="!disabled ? editBillingEntity(item, idx) : ''"><md-icon>edit</md-icon></span>
              <span @click="!disabled ? delBillingEntity(item) : ''"><md-icon>close</md-icon></span>
            </md-table-cell>
          </md-table-row>
        </md-table>
      </div>
    </div>
  </lims-block>
</template>
<script>
import dropdownService from '@/services/dropdown.service';
import { DROPDOWN_SHORT_NAME, ENTITY_TYPES } from '@/core/constants';
import { getEntitySchema } from '@/schemas/entities.schema';
import { constantService } from '@/services';
const ENTITY_TYPE = ENTITY_TYPES.Clinic;

export default {
  created() {
    this.fetchData();
  },
  props: {
    failed: {
      type: Boolean,
      require: false,
      default: false,
    },
    value: {
      type: Array,
      require: true,
    },
    disabled: {
      type: Boolean,
      require: false,
      default: false,
    },
  },
  data() {
    return {
      formData: {},
      billingEntityId: null,
      billingEntityList: [],
      billingEntityInputList: [],
      billingDataList: [],
      accountReference: null,
      editBillingEntityMode: false,
      selectedId: null,
      disabledAccountReference: true,
      befConstantValue: null,
    };
  },
  computed: {
    entitySchema() {
      return getEntitySchema(ENTITY_TYPE, this.entityResource, this.disabledAccountReference);
    },
    enabledAddBillingDataButton() {
      return (
        this.billingEntityId?.fieldItemId === this.befConstantValue?.insurance?.id ||
        this.billingEntityId?.fieldItemId === this.befConstantValue?.selfPayers?.id ||
        this.billingEntityId?.fieldItemId === this.befConstantValue?.ppClinicians?.id ||
        // this.billingEntityId?.fieldItemId === this.befConstantValue?.ldP_PPClinicians?.id ||
        // this.billingEntityId?.fieldItemId === this.befConstantValue?.sbS_PPClinicians?.id ||
        this.accountReference
      );
    },
  },
  watch: {
    billingDataList: {
      handler(val) {
        this.$emit('input', val);
      },
      deep: true,
    },
    value() {
      if (this.value) {
        this.billingDataList = this.value;
        this.resetForm();
      }
    },
    billingEntityId() {
      if (!this.editBillingEntityMode) {
        this.accountReference = null;
      }
      if (this.billingEntityId) {
        this.disabledAccountReference =
          this.billingEntityId?.fieldItemId === this.befConstantValue?.insurance?.id ||
          this.billingEntityId?.fieldItemId === this.befConstantValue?.selfPayers?.id ||
          this.billingEntityId?.fieldItemId === this.befConstantValue?.ppClinicians?.id;
        // this.billingEntityId?.fieldItemId === this.befConstantValue?.ldP_PPClinicians?.id ||
        // this.billingEntityId?.fieldItemId === this.befConstantValue?.sbS_PPClinicians?.id;
      } else {
        this.disabledAccountReference = true;
      }
    },
  },
  methods: {
    async fetchData() {
      this.billingEntityInputList = await this.getBillingEntityList();
      this.billingEntityList = this.billingEntityInputList;
      this.befConstantValue = await this.getBEFConstantValue();
    },
    async getBillingEntityList() {
      const billingEntityList = await dropdownService.getDropdownByShortNames([DROPDOWN_SHORT_NAME.BILLING_ENTITY]);
      return billingEntityList.BEF;
    },
    async getBEFConstantValue() {
      const constantValue = await constantService.getConstantValue();
      if (constantValue.err) {
        return {};
      }
      return constantValue.data?.bef;
    },
    addBillingData() {
      const dataAdd = {
        fieldItemName: this.billingEntityId.fieldItemName,
        fieldItemId: this.billingEntityId.fieldItemId,
        fieldId: this.billingEntityId.fieldId,
        accountReference: this.accountReference,
      };
      if (!this.editBillingEntityMode) {
        this.billingDataList.push(dataAdd);
        this.resetForm();
      } else {
        this.billingDataList[this.selectedId].fieldItemName = dataAdd.fieldItemName;
        this.billingDataList[this.selectedId].fieldItemId = dataAdd.fieldItemId;
        this.billingDataList[this.selectedId].fieldId = dataAdd.fieldId;
        this.billingDataList[this.selectedId].accountReference = dataAdd.accountReference;
        this.resetForm();
      }
    },
    delBillingEntity(item) {
      this.billingDataList = this.billingDataList.filter((billingData) => billingData.fieldItemId !== item.fieldItemId);
      this.resetForm();
    },
    editBillingEntity(item, idx) {
      this.editBillingEntityMode = true;
      let listFieldItemIdOfBillingDataList = [];
      this.billingDataList.forEach((billingData) => {
        if (billingData.fieldItemId !== item.fieldItemId) {
          listFieldItemIdOfBillingDataList.push(billingData.fieldItemId);
        }
      });
      this.billingEntityList = this.billingEntityInputList.filter(
        (billingEntity) => !listFieldItemIdOfBillingDataList.includes(billingEntity.fieldItemId),
      );
      this.billingEntityId = this.billingEntityInputList.find(
        (billingEntity) => billingEntity.fieldItemId === item.fieldItemId,
      );
      this.accountReference = item.accountReference;
      this.selectedId = idx;
    },
    resetForm() {
      let listFieldItemIdOfBillingDataList = [];
      this.billingDataList.forEach((billingData) => listFieldItemIdOfBillingDataList.push(billingData.fieldItemId));
      this.billingEntityList = this.billingEntityInputList.filter(
        (billingEntity) => !listFieldItemIdOfBillingDataList.includes(billingEntity.fieldItemId),
      );
      this.billingEntityId = null;
      this.accountReference = null;
      this.editBillingEntityMode = false;
    },
  },
};
</script>
