// initial state

export const LIST_NAMES = {
  CASE_LIST_ADMIN: 'case_list_admin_checkbox',
  CASE_LIST_LABTECHNICIAN: 'case_list_labtechnician_checkbox',
  CASE_LIST_REPORTED_CASE_LIST: 'case_list_reported_checkbox',
  CASE_LIST_UNREPORTED_CASE_LIST: 'case_list_unreported_checkbox',
  CASE_LIST_REPORT_CASE_FULL: 'case_list_report_case_full_checkbox',
  CASE_LIST_READY_FOR_REPORT_LIST: 'case_list_ready_for_report_checkbox',
  CASE_LIST_PENDING_FURTHER_WORK_LIST: 'case_list_pending_further_worki_checkbox',
};

import _ from 'lodash';
import Vue from 'vue';

const init_list_state = () => {
  return {
    selectAll: false,
    selectedItemIds: [],
    unselectedItemIds: [],
    searchQuery: null,
    isSelectCurrentPageMode: false,
  };
};

const state = () => ({
  [LIST_NAMES.CASE_LIST_ADMIN]: init_list_state(),
  [LIST_NAMES.CASE_LIST_LABTECHNICIAN]: init_list_state(),
  [LIST_NAMES.CASE_LIST_REPORTED_CASE_LIST]: init_list_state(),
  [LIST_NAMES.CASE_LIST_UNREPORTED_CASE_LIST]: init_list_state(),
  [LIST_NAMES.CASE_LIST_REPORT_CASE_FULL]: init_list_state(),
  [LIST_NAMES.CASE_LIST_READY_FOR_REPORT_LIST]: init_list_state(),
  [LIST_NAMES.CASE_LIST_PENDING_FURTHER_WORK_LIST]: init_list_state(),
});

// getters
const getters = {
  [LIST_NAMES.CASE_LIST_ADMIN]: (state) => {
    return state[LIST_NAMES.CASE_LIST_ADMIN];
  },
  [LIST_NAMES.CASE_LIST_LABTECHNICIAN]: (state) => {
    return state[LIST_NAMES.CASE_LIST_LABTECHNICIAN];
  },
  [LIST_NAMES.CASE_LIST_REPORTED_CASE_LIST]: (state) => {
    return state[LIST_NAMES.CASE_LIST_REPORTED_CASE_LIST];
  },
  [LIST_NAMES.CASE_LIST_UNREPORTED_CASE_LIST]: (state) => {
    return state[LIST_NAMES.CASE_LIST_UNREPORTED_CASE_LIST];
  },
  [LIST_NAMES.CASE_LIST_REPORT_CASE_FULL]: (state) => {
    return state[LIST_NAMES.CASE_LIST_REPORT_CASE_FULL];
  },
  [LIST_NAMES.CASE_LIST_READY_FOR_REPORT_LIST]: (state) => {
    return state[LIST_NAMES.CASE_LIST_READY_FOR_REPORT_LIST];
  },
  [LIST_NAMES.CASE_LIST_PENDING_FURTHER_WORK_LIST]: (state) => {
    return state[LIST_NAMES.CASE_LIST_PENDING_FURTHER_WORK_LIST];
  },
};

// actions
const actions = {
  setIsSelectCurrentPageMode({ commit }, { listName, isSelected }) {
    commit('setIsSelectCurrentPageMode', { listName, isSelected });
  },
  setSelectedItemIds({ commit }, { listName, itemIds }) {
    commit('setSelectedItemIds', { listName, itemIds });
  },
  resetAllState({ commit }) {
    commit('resetAllState');
  },
  setSelectAll({ commit }, listName) {
    if (!listName) {
      return;
    }
    commit('setSelectAll', listName);
  },
  unsetSelectAll({ commit }, listName) {
    if (!listName) {
      return;
    }
    commit('unsetSelectAll', listName);
  },
  addSelectedItemId({ commit }, { listName, itemId, totalItem }) {
    if (!listName) {
      return;
    }
    commit('addSelectedItemId', { listName, itemId, totalItem });
  },
  removeSelectedItemId({ commit }, { listName, itemId, totalItem }) {
    if (!listName) {
      return;
    }
    commit('removeSelectedItemId', { listName, itemId, totalItem });
  },
  setSearchQuery({ commit }, { listName, searchQuery }) {
    if (!listName) {
      return;
    }
    commit('setSearchQuery', { listName, searchQuery });
  },
};

// mutations
const mutations = {
  resetAllState: (state) => {
    Reflect.ownKeys(LIST_NAMES).map((listKey) => {
      Vue.set(state, LIST_NAMES[listKey], init_list_state());
    });
  },
  setSelectAll: (state, listName) => {
    const listState = state[listName];
    listState.selectAll = true;
    listState.selectedItemIds = [];
    listState.unselectedItemIds = [];
    Vue.set(state, listName, listState);
  },
  unsetSelectAll: (state, listName) => {
    const listState = state[listName];
    listState.selectAll = false;
    listState.selectedItemIds = [];
    listState.unselectedItemIds = [];
    Vue.set(state, listName, listState);
  },
  addSelectedItemId: (state, { listName, itemId, totalItem }) => {
    const listState = state[listName];
    if (listState.selectAll) {
      const unselectedItemIds = listState.unselectedItemIds.filter((id) => id != itemId);
      listState.unselectedItemIds = unselectedItemIds;
    } else {
      listState.selectedItemIds.push(itemId);
    }
    // mark check all
    if (listState.selectedItemIds.length === totalItem) {
      listState.selectAll = true;
      listState.selectedItemIds = [];
    }
  },
  removeSelectedItemId: (state, { listName, itemId, totalItem }) => {
    const listState = state[listName];
    // save unselected items if selectAll
    if (listState.selectAll || listState.isSelectCurrentPageMode) {
      listState.unselectedItemIds.push(itemId);
      listState.selectedItemIds = [];
      if (listState.unselectedItemIds.length === totalItem) {
        listState.selectAll = false;
        listState.isSelectCurrentPageMode = false;
        listState.unselectedItemIds = [];
      }
      return;
    }
    // otherwise remove from selected items
    listState.selectedItemIds = _.without(listState.selectedItemIds, itemId);
    listState.unselectedItemIds = [];
  },
  setSearchQuery: (state, { listName, searchQuery }) => {
    const listState = state[listName];
    listState.searchQuery = searchQuery;
  },
  setIsSelectCurrentPageMode: (state, { listName, isSelected }) => {
    const listState = state[listName];
    listState.isSelectCurrentPageMode = isSelected;
    listState.unselectedItemIds = [];
    listState.selectedItemIds = [];
  },
  setSelectedItemIds: (state, { listName, itemIds }) => {
    const listState = state[listName];
    listState.selectedItemIds = itemIds;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
