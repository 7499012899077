import { caseFormService, caseReportService, pathCaseService } from '@/services';
import {
  APP_EVENTS,
  APP_ROUTES,
  AUTO_RETRIEVE_DIGITAL_SLIDE_ACTIONS,
  CASE_STATUS,
  FORM_MODES,
  newAppEvent,
} from '@/core/constants';
import { downloadBlob, extractFileNameFromResponseHeader } from '@/core/helpers';
import AnchorSystem from '@/pages/Case/CaseManagement/Components/AnchorSystem.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    AnchorSystem,
  },
  computed: {
    ...mapGetters('app/event', [APP_EVENTS.EVT_ON_AUTO_RETRIEVE_COUNTDOWN]),
  },
  data() {
    return {
      isAutoTriggerArchivingService: false,
      isProcessing: false,
      autoRetrieveDSFailed_Retrive: false,
      autoRetrieveDSFailed_CancelDiagnosis: false,
      isProcessingExportPdf: false,
    };
  },
  watch: {
    [APP_EVENTS.EVT_ON_AUTO_RETRIEVE_COUNTDOWN]: {
      deep: true,
      handler: function (val) {
        if (val) {
          if (val.timercountDownComplete) {
            this.autoRetrieveDSFailed_Retrive = false;
            this.autoRetrieveDSFailed_CancelDiagnosis = false;
          } else {
            this.autoRetrieveDSFailed_Retrive = true;
            this.autoRetrieveDSFailed_CancelDiagnosis = true;
          }
        }
      },
    },
  },
  methods: {
    ...mapActions('app/event', ['addEvent']),
    async onExportAsPdf() {
      try {
        this.isProcessingExportPdf = true;
        const res = await caseReportService.downloadCaseReport(this.caseFormResource.caseId, false);
        this.isProcessingExportPdf = false;
        if (res.err) {
          return this.$alertError(res.err + ' error');
        }
        const fileName = extractFileNameFromResponseHeader(res.headers);
        if (res.data) {
          downloadBlob(res.data, fileName || this.caseFormResource.caseReference);
        }
      } catch (errors) {
        this.isProcessingExportPdf = false;
        this.$alertError(errors);
      }
    },
    // TODO : REFACTOR TO GROUP RELATED LOGIC LATER
    isShowDeleteBtn(item) {
      return this.$isAuthorized(['Case_CaseForm_Delete']) && !item.isDeleted;
    },
    isShowEditBtn(item) {
      return this.formMode === FORM_MODES.VIEW && item.status !== CASE_STATUS.EXT_CANCELLED;
    },
    isShowRestoreCaseBtn(item) {
      return this.$isAuthorized(['Case_CaseForm_Delete']) && item.isDeleted;
    },

    checkAutoTriggerArchivingService(caseArchiveStatusData) {
      if (caseArchiveStatusData) {
        return caseArchiveStatusData.isAvailableRetrieveVS && caseArchiveStatusData.isEnableRetrieveVs;
      }
      return false;
    },

    onDelete() {
      this.$refs.deleteCase.open();
    },
    async onDeleteCase(deleteCaseFormData) {
      const { err, data } = await caseFormService.deleteCase(
        deleteCaseFormData.reason,
        deleteCaseFormData.password,
        this.caseFormResource.caseId,
      );
      this.$refs.deleteCase.isProcessingStatus(false);
      if (err) {
        return this.$refs.deleteCase.handleErrorMessageModal(err);
      }
      if (data) {
        this.$refs.deleteCase.close();
        this.$alertSuccess(
          this.$t('pages/cases/form/alert/delete', {
            caseReference: this.caseFormResource.caseReference,
          }),
        );
        if (Reflect.has(deleteCaseFormData, 'callback')) {
          deleteCaseFormData.callback();
        } else {
          setTimeout(() => {
            this.$router.push({ path: APP_ROUTES.CASE });
          }, 3000);
        }
      }
    },
    onRestore() {
      this.$refs.restoreCaseModal.open();
    },
    async onRestoreCase(dataConfirm) {
      if (dataConfirm) {
        const { err, data } = await caseFormService.restoreCase(this.caseFormResource.caseId, dataConfirm);
        this.$refs.restoreCaseModal.isProcessingStatus(false);
        if (err) {
          return this.$refs.restoreCaseModal.handleErrorMessageModal(err);
        }
        if (data) {
          this.$refs.restoreCaseModal.close();
          this.$alertSuccess(
            this.$t('pages/cases/form/alert/restoreCase', {
              caseReference: this.caseFormResource.caseReference,
              caseStatus: this.caseFormResource.statusName,
            }),
          );
          if (Reflect.has(dataConfirm, 'callback')) {
            dataConfirm.callback();
          } else {
            setTimeout(() => {
              this.$router.push({ path: APP_ROUTES.CASE });
            }, 3000);
          }
        }
      }
    },
    // Retrieve
    onRetrieve() {
      this.isAutoTriggerArchivingService = this.checkAutoTriggerArchivingService(
        this.caseFormResource.caseArchiveStatus,
      );
      if (this.isAutoTriggerArchivingService) {
        this.$alertWarning(this.$t('pages/cases/form/alert/retrieveDigitalSlideAutomatic'));
      }
      this.$refs.retrieveModal.open();
    },
    async onSubmitRetrieve(retrieveFormData) {
      if (this.isAutoTriggerArchivingService) {
        const res = await this.retrieveDigitalSlide(AUTO_RETRIEVE_DIGITAL_SLIDE_ACTIONS.RETRIEVING);
        if (!res) {
          this.$refs.retrieveModal.isProcessingStatus(false);
          this.$refs.retrieveModal.close();
          this.autoRetrieveDSFailed_Retrive = true;
          return;
        }
      }
      const { err, data } = await pathCaseService.retrieveCase(this.caseFormResource.caseId, retrieveFormData.password);
      this.$refs.retrieveModal.isProcessingStatus(false);
      if (err) {
        return this.$refs.retrieveModal.handleErrorMessageModal(err);
      }
      if (data) {
        this.$refs.retrieveModal.close();
        this.$alertSuccess(
          this.$t('pages/cases/form/alert/retrieve', {
            caseReference: this.caseFormResource.caseReference,
          }),
        );
        if (this.isAutoTriggerArchivingService) {
          this.$alertSuccess(this.$t('pages/case/CaseManagement/components/Document/success.retrieveDigitalSlide'));
        }
        setTimeout(async () => {
          await this.$router.go();
        }, 1000);
      }
    },

    //Cancel Diagnosis or Recall To Path
    onRecallToPath() {
      this.isAutoTriggerArchivingService = this.checkAutoTriggerArchivingService(
        this.caseFormResource.caseArchiveStatus,
      );
      if (this.isAutoTriggerArchivingService) {
        this.$alertWarning(this.$t('pages/cases/form/alert/retrieveDigitalSlideAutomatic'));
      }
      this.$refs.reCallToPathModal.open();
    },
    async onSubmitRecallToPath(recallToPathFormData) {
      if (this.isAutoTriggerArchivingService) {
        const res = await this.retrieveDigitalSlide(AUTO_RETRIEVE_DIGITAL_SLIDE_ACTIONS.CANCEL_DIAGNOSIS);
        if (!res) {
          this.$refs.reCallToPathModal.isProcessingStatus(false);
          this.$refs.reCallToPathModal.close();
          this.autoRetrieveDSFailed_CancelDiagnosis = true;
          return;
        }
      }
      const { err, data } = await pathCaseService.reCallToPathFromReport(
        this.caseFormResource.caseId,
        recallToPathFormData.reason,
        recallToPathFormData.password,
      );
      this.$refs.reCallToPathModal.isProcessingStatus(false);
      if (err) {
        return this.$refs.reCallToPathModal.handleErrorMessageModal(err);
      }
      if (data) {
        this.$refs.reCallToPathModal.close();
        this.$alertSuccess(
          this.$t('pages/cases/form/alert/reCallToPath', {
            caseReference: this.caseFormResource.caseReference,
          }),
        );
        if (this.isAutoTriggerArchivingService) {
          this.$alertSuccess(this.$t('pages/case/CaseManagement/components/Document/success.retrieveDigitalSlide'));
        }
        setTimeout(async () => {
          await this.$router.go();
        }, 1000);
      }
    },
    //retrieve digital slide
    async retrieveDigitalSlide(action) {
      const { data } = await caseFormService.retrieveDigitalSlide(this.caseFormResource.caseId);
      if (data && data.errorMessages) {
        if (data.responseCode == 'DigitalSlideRetrievalRequestJsonFailed') {
          this.$alertError(
            this.$t('pages/case/CaseManagement/components/Document/error.DigitalSlideRetrievalRequestJsonFailed'),
          );
          this.$alertError(
            this.$t('pages/case/CaseManagement/autoRetrieveDSFailed/error.DigitalSlideRetrievalRequestJsonFailed', {
              action: this.$t(`pages/case/CaseManagement/autoRetrieveDSFailed/${action}`),
            }),
          );
        } else if (data.responseCode == 'DigitalSlideRetrievalRequestUnavailable') {
          this.$alertError(
            this.$t('pages/case/CaseManagement/components/Document/error.DigitalSlideRetrievalRequestUnavailable'),
          );
          this.$alertError(
            this.$t('pages/case/CaseManagement/autoRetrieveDSFailed/error.DigitalSlideRetrievalRequestUnavailable', {
              action: this.$t(`pages/case/CaseManagement/autoRetrieveDSFailed/${action}`),
            }),
          );
        } else {
          this.$alertError(data.errorMessages);
        }
        this.addEvent(
          newAppEvent(APP_EVENTS.EVT_ON_AUTO_RETRIEVE_DS, {
            autoRetrieveDSFailed: true,
            countDown: data.responseCode == 'DigitalSlideRetrievalRequestUnavailable' ? true : false,
          }),
        );
        return false;
      }
      return true;
    },
  },
};
