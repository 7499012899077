<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit }">
    <md-dialog :md-close-on-esc="true" :md-active.sync="isVisible" @md-clicked-outside="close" class="lims-modal">
      <div class="dialog-icon-close" @click="close"><md-icon>close</md-icon></div>
      <form class="md-layout lims-form" @submit.prevent="handleSubmit()">
        <div class="mg-top-24">
          <div class="md-layout lims-form-row">
            <div class="md-layout-item md-size-100 md-small-size-100">
              <lims-field :model="formData" :schema="schema" field="requestor">
                <v-select slot="field" :options="documentTypeListTemp" v-model="formData.requestor" disabled>
                  <template #option="{ label }">{{ label }}</template>
                  <template #selected-option="{ label }">{{ label }}</template>
                </v-select>
              </lims-field>
            </div>
            <div>
              {{
                $t('components/lims/modals/ModalViewRequest.requestedTxt', {
                  date: formData.requestDate ? formatDateTimeGlobal(formData.requestDate) : '',
                })
              }}
            </div>
          </div>
          <div class="md-layout lims-form-row">
            <div class="md-layout-item md-size-100 md-small-size-100">
              <lims-field class="textarea-field" :model="formData" :schema="schema" field="commentOnRequest">
                <md-textarea slot="field" v-model="formData.commentOnRequest" disabled></md-textarea>
              </lims-field>
            </div>
            <div v-if="formData.status === 'Approved'">
              {{
                $t('components/lims/modals/ModalViewRequest.approvedTxt', {
                  date: formData.requestDate ? formatDateTimeGlobal(formData.requestDate) : '',
                  approvedBy: formData.responderName,
                })
              }}
            </div>
            <div v-if="formData.status === 'Rejected'">
              {{
                $t('components/lims/modals/ModalViewRequest.rejectTxt', {
                  date: formData.requestDate ? formatDateTimeGlobal(formData.requestDate) : '',
                  approvedBy: formData.responderName,
                })
              }}
            </div>
          </div>
          <div v-if="formData.status === 'Rejected'" class="md-layout lims-form-row">
            <div class="md-layout-item md-size-100 md-small-size-100">
              <lims-field class="textarea-field" :model="formData" :schema="schema" field="commentOnReject">
                <md-textarea slot="field" v-model="formData.commentOnReject" disabled></md-textarea>
              </lims-field>
            </div>
          </div>
        </div>
        <md-dialog-actions class="add-document-action">
          <md-button @click="close" class="lims-form-button">
            {{ $t('global/button/button.cancel') }}
          </md-button>
          <md-button v-if="formData.status === 'Requested'" @click="onApprove" class="lims-form-button md-primary">{{
            $t('global/button/button.approve')
          }}</md-button>
          <md-button v-if="formData.status === 'Requested'" @click="onReject" class="lims-form-button md-danger">{{
            $t('global/button/button.reject')
          }}</md-button>
        </md-dialog-actions>
      </form>
    </md-dialog>
  </ValidationObserver>
</template>

<script>
import { DateTimeHelper } from '@/core/helpers';
import modalMixins from './modal.mixins';
import { FormMixins } from '@/core/mixins';

export default {
  name: 'ModalViewRequest',
  mixins: [modalMixins, FormMixins],
  computed: {
    schema() {
      return {
        entity: 'modals/ModalViewRequest',
        fields: {
          requestor: '',
          commentOnRequest: '',
          commentOnReject: '',
        },
      };
    },
  },
  data() {
    return {
      formData: {},
      documentTypeListTemp: [],
    };
  },
  methods: {
    open(data) {
      if (data) {
        this.isVisible = true;
        this.formData = {
          ...data,
          requestor: data.requesterName,
          commentOnRequest: data.comment,
          commentOnReject: data.responseComment,
        };
      }
    },
    formatDateTime(date) {
      const { timezoneValue, dateTimeAppFormat } = this.$store.getters['config/$appConfig'];
      return DateTimeHelper.formatDateTimeGlobal(date, {
        timezoneValue,
        dateTimeAppFormat,
      });
    },
    onApprove() {
      this.$emit('onApprove');
      this.close();
    },
    onReject() {
      this.$emit('onReject');
      this.close();
    },
  },
};
</script>

<style lang="scss"></style>
