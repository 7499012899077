import { render, staticRenderFns } from "./DatasetEdit.vue?vue&type=template&id=48d1646d&scoped=true&"
import script from "./DatasetEdit.vue?vue&type=script&lang=js&"
export * from "./DatasetEdit.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48d1646d",
  null
  
)

export default component.exports