import { initializeApp } from 'firebase/app';
import { getDatabase, onValue, ref } from 'firebase/database';
import { getAuth, onAuthStateChanged, signInWithCustomToken } from 'firebase/auth';
import httpClient from './httpClient';
const RESOURCE = 'notifications';

export default {
  async getInstance() {
    const { data, error } = await this.fetchFirebaseConfiguration();
    if (data) {
      // must be loaded from API later

      const app = initializeApp(data);
      const auth = getAuth(app);
      const db = getDatabase(app);
      window.LDPATH = {
        AUTH: auth,
      };
      return { auth, db, error: null };
    }

    return { auth: null, db: null, error };
  },
  fetchFirebaseConfiguration() {
    return httpClient.get(`/${RESOURCE}/firebase-configuration`);
  },
  fetchCustomToken() {
    return httpClient.post(`/${RESOURCE}/generate-firebase-custom-token`);
  },
  signIn(auth, customToken) {
    return signInWithCustomToken(auth, customToken);
  },
  listenOnNotification(auth, db, userId, callback) {
    return onAuthStateChanged(auth, (user) => {
      if (user) {
        const dbRef = ref(db, `Users/${userId}`);
        window.unSubscribeFirebaseMessage = onValue(
          dbRef,
          (snapshot) => {
            callback(snapshot.val());
          },
          {
            onlyOnce: false,
          },
        );
      }
    });
  },
};
