<template>
  <div>
    <md-table class="paginated-table table-striped table-hover lims-table tbl-drag dropdown-iten-tbl">
      <md-table-toolbar>
        <slot name="toolbar"></slot>
      </md-table-toolbar>
      <div class="table-fix-header">
        <thead>
          <md-table-row>
            <md-table-head v-for="(column, index) in columnOfTable" :key="index" :class="column.class">
              <div>
                {{ $t(column.name) }}
              </div>
            </md-table-head>
          </md-table-row>
        </thead>
        <tbody>
          <draggable class="tbl-body" @end="endDrag" :disabled="!$isAuthorized(['Data_DropdownManagement_Update'])">
            <md-table-row v-for="item in items" :key="item.itemId" @click.native="onClickAction('view', item)">
              <md-table-cell :class="item.isHide ? 'order-column disable-cell' : 'order-column'">{{
                item.displayOrder
              }}</md-table-cell>
              <md-table-cell :class="item.isHide ? 'disable-cell' : ''">{{ item.itemName }}</md-table-cell>
              <md-table-cell class="cell-action">
                <div class="cell-action-inner">
                  <div style="width: 30px">
                    <md-button
                      v-if="$isAuthorized(['Data_DropdownManagement_Retrieve'])"
                      class="md-just-icon md-info md-simple"
                      @click.native="onClickAction('view', item)"
                    >
                      <md-icon>visibility</md-icon>
                    </md-button>
                  </div>
                  <div style="width: 30px">
                    <md-button v-if="$isAuthorized(['Data_DropdownManagement_Update'])" class="md-just-icon md-simple">
                      <md-icon>open_with</md-icon>
                    </md-button>
                  </div>
                  <div style="width: 30px">
                    <md-button
                      v-if="$isAuthorized(['Data_DropdownManagement_Update'])"
                      class="md-just-icon md-warning md-simple"
                      @click.native="onClickAction('edit', item)"
                    >
                      <md-icon>edit</md-icon>
                    </md-button>
                  </div>
                  <div style="width: 30px">
                    <md-button
                      v-if="$isAuthorized(['Data_DropdownManagement_Delete'])"
                      class="md-just-icon md-danger md-simple"
                      @click.stop="onClickAction('delete', item)"
                    >
                      <md-icon>delete_forever</md-icon>
                    </md-button>
                  </div>
                </div>
              </md-table-cell>
            </md-table-row>
          </draggable>
        </tbody>
      </div>
    </md-table>
  </div>
</template>

<script>
import draggable from 'vuedraggable';

export default {
  name: 'ItemList',
  components: {
    draggable,
  },

  props: {
    items: {
      require: false,
    },
  },

  data() {
    return {
      columnOfTable: [
        {
          name: 'pages/Data/Dropdown/ItemList/ItemList/column.order',
          key: 'order',
          class: 'order-column',
        },
        {
          name: 'pages/Data/Dropdown/ItemList/ItemList/column.item',
          key: 'item',
          class: 'item-column',
        },
        {
          name: 'global/pages/list.actions',
          key: 'actions',
          class: 'actions-column',
        },
      ],
    };
  },

  watch: {},

  methods: {
    onClickAction(action, item) {
      this.$emit('onClickAction', action, item);
    },

    endDrag(dragEventData) {
      const indexOfDragEvent = {
        oldIndex: dragEventData.oldIndex,
        newIndex: dragEventData.newIndex,
      };

      this.$emit('onDragAction', indexOfDragEvent);
    },
  },
};
</script>
<style lang="scss">
.lims-table.dropdown-iten-tbl {
  .order-column {
    width: 80px;
  }
  .actions-column,
  .cell-action {
    width: 170px !important;
  }
}
.tbl-body {
  .disable-cell {
    color: grey;
  }
}
</style>
