<template>
  <div>
    <md-table class="paginated-table table-striped table-hover lims-table field-list-tbl">
      <md-table-toolbar>
        <slot name="toolbar"></slot>
      </md-table-toolbar>
      <div class="table-fix-header">
        <thead>
          <md-table-row>
            <md-table-head v-for="(column, index) in columnOfTable" :key="index" :class="column.class">
              <div>
                {{ $t(column.name) }}
              </div>
            </md-table-head>
          </md-table-row>
        </thead>
        <tbody>
          <md-table-row
            v-for="item in fields"
            :key="item.fieldId"
            v-long-press="300"
            @click.native="onNavigate($event, item)"
            @long-press-start="onPlusStart"
            @long-press-stop="onPlusStop"
          >
            <md-table-cell class="field-column">{{ item.fieldName }}</md-table-cell>
            <md-table-cell class="cell-action">
              <div class="cell-action-inner">
                <md-button class="md-just-icon md-info md-simple" @click.native="handleAction(item)">
                  <md-icon>visibility</md-icon>
                </md-button>
              </div>
            </md-table-cell>
          </md-table-row>
        </tbody>
      </div>
    </md-table>
  </div>
</template>

<script>
export default {
  name: 'FieldList',

  props: {
    fields: {
      require: false,
    },
  },

  data() {
    return {
      longPressMode: false,
      plusInterval: null,
      columnOfTable: [
        {
          name: 'pages/Data/Dropdown/FieldList/column.field',
          key: 'field',
          class: 'field-column',
        },
        {
          name: 'global/pages/list.actions',
          key: 'actions',
          class: 'actions-column',
        },
      ],
    };
  },

  methods: {
    handleAction(item) {
      this.$emit('onClickView', item);
    },

    onNavigate(e, item) {
      if (!this.longPressMode) {
        e.preventDefault();
        this.handleAction(item);
      }
    },
    onPlusStart() {
      this.plusInterval = setInterval(() => {
        this.longPressMode = true;
      }, 100);
    },

    onPlusStop() {
      setTimeout(() => {
        this.longPressMode = false;
      }, 500);
      clearInterval(this.plusInterval);
    },
  },
};
</script>
<style lang="scss">
.field-list-tbl {
  .field-column {
    width: 100% !important;
  }
}
</style>
