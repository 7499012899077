/* eslint-disable security/detect-object-injection */
import { CASE_STATUS, USER_TYPE_NAME } from '@/core/constants';
import { caseReportService } from '@/services';
import { ModalSendEmail } from '@/components/Lims/modals';

export default {
  components: {
    ModalSendEmail,
  },
  data() {
    return {
      isProcessingSendEmail: false,
      caseIdSelected: false,
    };
  },
  methods: {
    async onVerifySendEmail(caseId) {
      this.caseIdSelected = caseId;
      this.isProcessingSendEmail = true;
      const res = await caseReportService.checkClinicCustomTemplate(caseId);
      if (res.error) {
        this.isProcessingSendEmail = false;
        return this.$alertError(res.error);
      }
      if (res.data) {
        if (res.data.isClinicCustomTemplate) {
          this.$refs.chooseVersionSendEmailModal.open();
        } else {
          await this.onSelectedVersion(true);
        }
      }
      this.isProcessingSendEmail = false;
    },
    async onSelectedVersion(isRedacted) {
      this.$refs.chooseVersionSendEmailModal.close();
      const resContent = await caseReportService.getContentEmail(this.caseIdSelected, isRedacted);
      if (resContent.error) {
        return this.$alertError(resContent.error);
      }
      if (resContent.data) {
        this.$refs.sendEmailModal.open({
          emailContent: resContent.data,
          caseId: this.caseIdSelected,
          isRedacted: isRedacted,
        });
      }
    },
    isShowSendEmail(item) {
      const userType = this.$store.getters['auth/userType'];
      const { Administrator, ClinicAssociate, Clinician, ClinicianAssociate } = USER_TYPE_NAME;
      const userTypes = this.USER_TYPES();
      // userType
      const isAdministrator = userType === userTypes[Administrator];
      const isClinicAssociate = userType === userTypes[ClinicAssociate];
      const isClinician = userType === userTypes[Clinician];
      const isClinicianAssociate = userType === userTypes[ClinicianAssociate];
      const isAllowedStatus =
        item?.statusId == CASE_STATUS.PROVISIONALLY_REPORTED ||
        item?.statusId == CASE_STATUS.REPORTED ||
        item?.status == CASE_STATUS.PROVISIONALLY_REPORTED ||
        item?.status == CASE_STATUS.REPORTED;
      const isAllowedUserType = isAdministrator || isClinicAssociate || isClinician || isClinicianAssociate;
      const isAllowedPermission =
        this.$isAuthorized(['Case_CaseForm_Update']) || this.$isAuthorized(['Case_CaseReport_Retrieve']);
      return isAllowedStatus && isAllowedUserType && isAllowedPermission;
    },
  },
};
