import Vue from 'vue';

// initial state
const state = () => ({
  expandCollapseReviewSession: {},
});

// getters
const getters = {
  getExpandCollapseReviewSession: (state) => {
    return state.expandCollapseReviewSession;
  },
};

// actions
const actions = {
  resetExpandCollapseReviewSession({ commit }) {
    commit('resetExpandCollapseReviewSession');
  },
  setExpandCollapseReviewSession({ commit }, block) {
    commit('expandCollapseReviewSession', block);
  },
};

// mutations
const mutations = {
  expandCollapseReviewSession: (state, { blockName, blockValue }) => {
    Vue.set(state.expandCollapseReviewSession, blockName, blockValue);
  },
  resetExpandCollapseReviewSession: (state) => {
    Vue.set(state, 'expandCollapseReviewSession', {});
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
