<template>
  <div v-if="listData && workStreamList" :class="disabled ? 'md-disabled' : ''">
    <md-table v-model="listData" class="tbl-commonly-used-specimen-type" :md-selected-value.sync="selectedValue">
      <md-table-toolbar>
        <label>{{ $t('pages/entity/components/CommonlyUsedSpecimenType/title') }}</label>
        <md-field>
          <md-input
            class="input-search"
            :placeholder="$t('pages/entity/components/CommonlyUsedSpecimenType/placeholder.search')"
            v-model="search"
            @input="searchOnTable"
            :disabled="disabled"
          />
        </md-field>
      </md-table-toolbar>
      <md-table-row
        slot="md-table-row"
        slot-scope="{ item }"
        md-selectable="multiple"
        :md-disabled="isDisableRow(item.itemName)"
        :class="isDisableRow(item.itemName) ? 'row-disabled' : ''"
      >
        <md-table-cell :md-label="$t('pages/entity/components/CommonlyUsedSpecimenType/selectAll')">
          <div class="custom-select-content">
            <md-list :md-expand-single="false">
              <md-list-item
                md-expand
                @click.stop="onClickItem($event)"
                :md-expanded="isCollapseWhenClearItem(item.itemName)"
              >
                <span class="md-list-item-text"> {{ item.itemName }}</span>
                <md-list slot="md-expand">
                  <div v-for="(i, index) in item.items" :key="`item-${index}`">
                    <md-checkbox
                      v-model="selectFilter"
                      :value="i.fieldItemId"
                      @change="handlerSelectedAll(item)"
                      :disabled="disabled"
                      >{{ i.fieldItemName }}</md-checkbox
                    >
                  </div>
                </md-list>
              </md-list-item>
            </md-list>
          </div>
        </md-table-cell>
      </md-table-row>
    </md-table>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { APP_EVENTS, newAppEvent } from '@/core/constants';
import { cloneDeep } from 'lodash';

const toLower = (text) => {
  return text.toString().toLowerCase();
};
const searchByName = (items, term, $this) => {
  if (term && term.length >= 3) {
    const parentItems = items.filter((item) => toLower(item.itemName).includes(toLower(term)));
    if (parentItems.length > 0) {
      $this.expandWhenSearch = false;
      return parentItems;
    } else {
      let arr = [];
      const listFilter = items.reduce((i, value) => {
        const listItem = {
          ...value,
          items: value.items.filter((child) => toLower(child.fieldItemName).includes(toLower(term))),
        };
        if (listItem.items.length > 0) {
          arr.push(listItem);
        }
        $this.expandWhenSearch = true;
        return arr;
      }, 0);
      return listFilter;
    }
  }
  $this.expandWhenSearch = false;
  return items;
};

export default {
  props: {
    disabled: {
      type: Boolean,
      require: false,
      default: false,
    },
    value: {
      type: Array,
      require: true,
    },
    workStreamItemAdd: {
      type: Object,
      require: false,
    },
    workStreamItemDelete: {
      type: Object,
      require: false,
    },
    specimenTypeList: {
      type: Array,
      require: true,
    },
    workStreamList: {
      type: Array,
      require: false,
    },
  },
  data() {
    return {
      search: null,
      listData: [],
      expandItem: {},
      isOpen: false,
      selectFilter: [],
      onClickInItem: false,
      selectedValue: null,
      isSelectedInSubItem: false,
      expandWhenSearch: false,
    };
  },
  watch: {
    specimenTypeList: {
      handler(val) {
        this.listData = val;
        this.listData = this.listData.map((item) => {
          return {
            ...item,
            isOpen: false,
          };
        });
        this.checkedSelectBoxWhenFetchData();
      },
      deep: true,
    },
    selectFilter: {
      handler(val) {
        this.addEvent(newAppEvent(APP_EVENTS.EVT_ON_CHANGE_COMMON_USED_SPECIMEN_TYPES, cloneDeep(val)));
      },
      deep: true,
    },
    workStreamItemAdd: {
      handler(val) {
        const item = this.listData.find((item) => item.itemName === val.fieldItemName);
        if (item) {
          this.selectedValue = this.selectedValue ? [...this.selectedValue, item] : [item];
        }
      },
      deep: true,
    },
    workStreamItemDelete: {
      handler(val) {
        const unselectedItem = this.listData.find((i) => i.itemName === val.fieldItemName);
        unselectedItem.items.forEach((i) => {
          if (this.selectFilter.includes(i.fieldItemId)) {
            this.selectFilter = this.selectFilter.filter((x) => x !== i.fieldItemId);
          }
        });
        if (this.selectedValue) {
          this.selectedValue = this.selectedValue.filter((item) => item.itemName !== val.fieldItemName);
        }
      },
      deep: true,
    },
    selectedValue: {
      handler(val, prev) {
        if (this.isSelectedInSubItem) {
          this.isSelectedInSubItem = false;
        } else {
          if ((prev && val.length > prev.length) || !prev) {
            const selectedItems = prev ? val.filter((item) => !prev.includes(item)) : val;
            selectedItems.forEach((selectedItem) => {
              selectedItem.items.forEach((i) => {
                if (!this.selectFilter.includes(i.fieldItemId)) {
                  this.selectFilter.push(i.fieldItemId);
                }
              });
            });
          } else {
            const unselectedItems = prev.filter((item) => !val.includes(item));
            unselectedItems.forEach((unselectedItem) => {
              unselectedItem.items.forEach((i) => {
                if (this.selectFilter.includes(i.fieldItemId)) {
                  this.selectFilter = this.selectFilter.filter((x) => x !== i.fieldItemId);
                }
              });
            });
          }
        }
      },
      deep: true,
    },
    [APP_EVENTS.EVT_ON_LOAD_COMMON_USED_SPECIMEN_DATA]: {
      deep: true,
      handler: async function (val) {
        if (val) {
          this.selectFilter = cloneDeep(val);
          this.checkedSelectBoxWhenFetchData();
        }
      },
    },
  },
  computed: {
    ...mapGetters('app/event', [APP_EVENTS.EVT_ON_LOAD_COMMON_USED_SPECIMEN_DATA]),
  },
  methods: {
    ...mapActions('app/event', ['addEvent']),
    checkedSelectBoxWhenFetchData() {
      if (this.listData && this.listData.length > 0 && this.selectFilter && this.selectFilter.length > 0) {
        this.listData.forEach((item) => {
          if (item.items && item.items.find((i) => this.selectFilter.find((x) => x === i.fieldItemId))) {
            this.handlerSelectedAll(item);
          }
        });
      }
      if (this.selectedValue) {
        this.selectedValue = this.selectedValue.filter((item) =>
          this.workStreamList.find((i) => i.fieldItemName === item.itemName),
        );
      }
    },
    isDisableRow(itemName) {
      return !this.workStreamList.find((i) => i.fieldItemName === itemName);
    },
    isCollapseWhenClearItem(itemName) {
      if (!this.workStreamList.find((i) => i.fieldItemName === itemName)) {
        return false;
      }
    },
    searchOnTable() {
      this.listData = searchByName(this.specimenTypeList, this.search, this);
    },
    onClickItem(event) {
      event.stopPropagation();
    },
    handlerSelectedAll(item) {
      let allItemInListIsSelected = true;
      item.items.forEach((i) => {
        if (this.selectFilter && !this.selectFilter.find((item) => item === i.fieldItemId)) {
          allItemInListIsSelected = false;
        }
      });

      this.selectedValue = this.selectedValue ? [...this.selectedValue] : [];
      const findParentInSelectedList = this.selectedValue.find((i) => i.itemName === item.itemName);
      this.isSelectedInSubItem = true;
      if (allItemInListIsSelected) {
        if (!findParentInSelectedList) {
          this.selectedValue = this.selectedValue ? [...this.selectedValue, item] : [item];
        }
      } else {
        if (findParentInSelectedList) {
          this.selectedValue = this.selectedValue.filter((i) => i.itemName !== item.itemName);
        }
      }
    },
  },
};
</script>

<style lang="scss">
.tbl-commonly-used-specimen-type {
  height: 250px;
  margin-bottom: 15px;
  .md-toolbar {
    padding: 10px 0 0;
  }
  .input-search {
    margin: 10px 0 0 !important;
  }
  .md-table-cell {
    height: auto;
    padding: 10px 5px;
  }
  .md-table-head {
    padding: 5px;
    .md-table-head-label {
      font-size: 14px;
    }
  }
  .md-table-cell-selection {
    width: 30px;
    padding-left: 0 !important;
  }
  .md-list-item-content > .md-icon:last-child {
    margin: 10px;
    top: 0;
    right: 0;
    position: absolute;
  }
  .md-list-item-content {
    min-height: unset;
    .md-list-expand-icon {
      position: absolute;
      right: 5px;
    }
  }
  &.md-table-cell-container {
    position: absolute !important;
    top: 20px;
    right: 15px;
  }
  .md-has-selection {
    .md-table-cell-selection {
      vertical-align: top;
      padding-top: 28px;
    }
    .md-list-item-expand {
      border: unset;
    }
  }
  .md-list-item-text {
    display: contents;
  }
}
.row-disabled {
  background-color: rgba(236, 240, 241, 0.5);
  pointer-events: none;
}
</style>
