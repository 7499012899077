const DashboardLayout = () => import('@/layouts/DashboardLayout.vue');
import OcrRfUpload from '@/pages/Case/OcrRfUpload/OcrRfUpload.vue';

import guards from './guards';

export default {
  path: '/ocr-rf-upload',
  component: DashboardLayout,
  meta: {
    zone: ['Case_OCR_Create'],
  },
  beforeEnter: guards.checkAuthorizedGuard,
  children: [
    {
      path: '',
      name: 'OcrRfUpload',
      component: OcrRfUpload,
      meta: {
        zone: ['Case_OCR_Create'],
      },
    },
  ],
};
