<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit }">
    <form class="md-layout lims-form form-login" @submit.prevent="handleSubmit()">
      <md-dialog :md-close-on-esc="true" :md-active.sync="isVisible" @md-clicked-outside="close" class="lims-modal">
        <div v-show="!isLoading" class="dialog-icon-close" @click="close"><md-icon>close</md-icon></div>
        <md-dialog-title>{{
          $t(
            isLoading
              ? 'components/lims/modals/ModalLoadingGeneric.title'
              : 'components/lims/modals/ModalConfirmation.title',
          )
        }}</md-dialog-title>
        <div v-show="!isLoading" class="lims-form dialog-content">
          <p
            class="text-center"
            v-html="
              $t('components/ModalBulkActionCommom.text', {
                bulkAction: $translateBulkActionSelect(bulkActionSelect),
              })
            "
          ></p>
          <template v-if="compatibleCase && compatibleCase.length > 0">
            <p class="text-center" v-html="$t('components/ModalBulkActionCommom.text2')"></p>
            <ul class="list-of-compatible-cases">
              <li v-for="item in getPartOfCases(compatibleCase)" :key="item.caseId">{{ item.caseReference }}</li>
            </ul>
          </template>

          <lims-field
            v-if="bulkActionSelect === bulkAction.Delete || bulkActionSelect === bulkAction.RemoveBillingFlag"
            class="textarea-field"
            :model="formData"
            :schema="schema"
            field="reasonRequired"
          >
            <md-textarea slot="field" v-model="formData.reasonRequired"></md-textarea>
          </lims-field>
          <lims-field v-else class="textarea-field" :model="formData" :schema="schema" field="reason">
            <md-textarea slot="field" v-model="formData.reason" @keyup.enter="onCompleteAction"></md-textarea>
          </lims-field>

          <lims-field :model="formData" :schema="checkCodeSchema" field="passwordForCase">
            <md-input
              slot="field"
              ref="confirmPassword"
              v-model="formData.password"
              type="password"
              @keyup.enter="onCompleteAction"
            ></md-input>
          </lims-field>
        </div>
        <div v-show="isLoading" class="lims-form dialog-content">
          <div style="text-align: center">
            <md-progress-spinner :md-diameter="100" :md-stroke="10" md-mode="indeterminate"></md-progress-spinner>
          </div>
        </div>
        <md-dialog-actions v-show="!isLoading">
          <md-button @click="close" class="lims-form-button">{{ $t('global/button/button.cancel') }}</md-button>
          <md-button
            @click="onCompleteAction"
            :disabled="isDisabledCompleteBtn() || isProcessing"
            class="lims-form-button md-primary"
            >{{ $t('global/button/button.completeAction') }}</md-button
          >
        </md-dialog-actions>
      </md-dialog>
    </form>
  </ValidationObserver>
</template>

<script>
import { BULK_ACTION } from '@/core/constants';
import modalMixins from './modal.mixins';
import { caseListService, caseFormService } from '@/services';
import { mapActions } from 'vuex';
import { APP_EVENTS, newAppEvent } from '@/core/constants';
import { getCheckCodeSchema } from '@/schemas/check-code.schema';

export default {
  name: 'ModalBulkActionCommom',
  mixins: [modalMixins],
  props: {
    bulkActionSelect: {
      require: true,
    },
    compatibleCase: {
      type: Array,
      require: true,
    },
    notCompatibleCase: {
      require: true,
    },
    dataBatchChange: {
      require: false,
    },
  },
  data() {
    return {
      formData: {
        reason: '',
        reasonRequired: '',
        password: '',
      },
      masterCheckBoxData: null,
      isLoading: false,
    };
  },
  computed: {
    checkCodeSchema() {
      return getCheckCodeSchema(this.isWrongPasswordError);
    },
    schema() {
      return {
        entity: 'modals/ModalBulkActionCommom',
        fields: {
          reason: '',
          reasonRequired: 'required',
        },
      };
    },
    bulkAction: function () {
      return BULK_ACTION;
    },
  },
  methods: {
    ...mapActions('app/event', ['addEvent']),
    ...mapActions('app', ['$openModalGenericLoading', '$closeModalGenericLoading']),

    getPartOfCases(cases) {
      if (cases.length > 5 && this.masterCheckBoxData && this.masterCheckBoxData.selectAll) {
        return [
          ...cases.slice(0, 5),
          {
            caseId: Math.random(),
            caseReference: '...',
          },
        ];
      }
      return cases;
    },

    setMasterCheckBoxData(masterCheckBoxData) {
      this.masterCheckBoxData = masterCheckBoxData;
    },

    async onCompleteAction() {
      let res = {};

      const data = {
        ...this.masterCheckBoxData,
        isForcedUpdate: true,
        password: this.formData.password,
        reason:
          this.bulkActionSelect === BULK_ACTION.Delete || this.bulkActionSelect === BULK_ACTION.RemoveBillingFlag
            ? this.formData.reasonRequired
            : this.formData.reason,
      };

      // show loading modal
      this.isLoading = true;
      this.isProcessing = true;
      try {
        if (this.bulkActionSelect === BULK_ACTION.RemoveRF) {
          res = await caseListService.removeRequestFormCases({ ...data });
        }
        if (this.bulkActionSelect === BULK_ACTION.Delete) {
          res = await caseListService.deteteCase({ ...data });
        }
        if (this.bulkActionSelect === BULK_ACTION.BatchChange) {
          res = await caseListService.updateBatchCases({
            ...this.dataBatchChange,
            ...data,
          });
        }
        if (this.bulkActionSelect === BULK_ACTION.ResendExternalReport) {
          res = await caseFormService.resendCase({ ...data });
        }
        if (this.bulkActionSelect === BULK_ACTION.RemoveBillingFlag) {
          res = await caseListService.removeBillingFlag({ ...data });
        }
        this.isProcessing = false;
        if (res.error) {
          this.isLoading = false;
          return this.handleErrorMessageModal(res.error);
        } else if (data) {
          this.$emit('onCompletedAction');
          // emit event
          this.addEvent(
            newAppEvent(APP_EVENTS.EVT_ON_RELOAD_CASE_LIST, {
              bulkActionSelect: this.bulkActionSelect,
            }),
          );
          this.$alertSuccess(
            this.$t('components/ModalBulkActionCommom.completeActionSuccess', {
              bulkAction: this.$translateBulkActionSelect(this.bulkActionSelect),
            }),
          );
          this.close();
        }
      } catch (error) {
        this.$alertError(error);
      }
      // finally
      this.isLoading = false;
    },
    close() {
      this.isVisible = false;
      this.$nextTick(() => {
        this.formData = {
          reason: '',
          reasonRequired: '',
          password: '',
        };
      });
    },

    isDisabledCompleteBtn() {
      if (this.bulkActionSelect === BULK_ACTION.Delete || this.bulkActionSelect === BULK_ACTION.RemoveBillingFlag) {
        return !this.formData.reasonRequired || !this.formData.password;
      } else {
        return !this.formData.password;
      }
    },
  },
};
</script>

<style lang="scss"></style>
