<template>
  <component
    v-if="userType && userResource"
    v-bind:is="activeUserType"
    :userResource="userResource"
    :userId="userId"
    :userTypeName="userTypeName"
    :viewMode="true"
  ></component>
</template>

<script>
import Administrator from './../Forms/Administrator/Administrator';
import ClinicAssociate from './../Forms/ClinicAssociate/ClinicAssociate';
import Clinician from './../Forms/Clinician/Clinician';
import ClinicianAssociate from './../Forms/ClinicianAssociate/ClinicianAssociate';
import LabTechnician from './../Forms/LabTechnician/LabTechnician';
import Pathologist from './../Forms/Pathologist/Pathologist';
import DashboardAdmin from '@/pages/Dashboard/Admin/DashboardAdmin';
import UserService from '@/services/user.service';
import { userTypeIdToComponent } from '@/core/helpers';
import { ResetHeadingTitleMixins } from '@/core/mixins';

const ACTIVE_USER_TYPES = {
  administrator: Administrator,
  clinicAssociate: ClinicAssociate,
  clinician: Clinician,
  clinicianAssociate: ClinicianAssociate,
  labTechnician: LabTechnician,
  pathologist: Pathologist,
};

export default {
  mixins: [ResetHeadingTitleMixins],
  props: {
    userId: {
      require: true,
    },
  },
  component: {
    Administrator,
    ClinicAssociate,
    Clinician,
    ClinicianAssociate,
    LabTechnician,
    Pathologist,
    DashboardAdmin,
  },
  computed: {
    userTypeName() {
      return this.userType;
    },
    activeUserType() {
      return ACTIVE_USER_TYPES[this.userType];
    },
  },
  data: function () {
    return {
      userResource: {},
      userType: false,
    };
  },
  watch: {
    userId(val, oldVal) {
      if (val !== oldVal) {
        this.userResolver(val);
      }
    },
  },
  methods: {
    async userResolver(userId) {
      if (userId) {
        const { data } = await UserService.findOne(userId);

        this.userResource = data;
        this.userType = userTypeIdToComponent(this.USER_TYPES(), data.personalInfo.userTypeId);
      }
    },
  },
  created() {
    this.userResolver(this.userId);
  },
};
</script>

<style lang="scss" scoped></style>
