/* eslint-disable security/detect-object-injection */
import { DROPDOWN_SHORT_NAME, LOCATION_FLAG_NAMES } from '@/core/constants';
import { endDay, isPerfectEmpty, startDay } from '@/core/helpers';
import {
  FF_AlwRequestDate,
  FF_AlwRespondDate,
  FF_LabEntryDate,
  FF_LocationExternal,
  FF_LocationLab,
  FF_LocationReturnedToClinic,
  FF_LocationReturnedToLab,
  FF_LocationSentToPath,
  FF_ReportedDate,
  FF_SlideLastUpdated,
  FF_SlideReturnedToClinicDate,
  FF_SlideReturnedToLabDate,
  FF_SlideSentToExternalDate,
  FF_SlideSentToLabDate,
  FF_SlideSentToPathDate,
} from '@/query/filter-fields';
import httpClient from './httpClient';

const RESOURCE = 'cases/slides';

export const mappingDateRangeSearch = (key, values) => {
  if (key === FF_LabEntryDate) {
    return [
      {
        param: 'LabEntryDateFrom',
        value: startDay(values[0]),
      },
      { param: 'LabEntryDateTo', value: endDay(values[1]) },
    ];
  }
  if (key === FF_ReportedDate) {
    return [
      {
        param: 'ReportedDateFrom',
        value: startDay(values[0]),
      },
      { param: 'ReportedDateTo', value: endDay(values[1]) },
    ];
  }
  if (key === FF_AlwRequestDate) {
    return [
      {
        param: 'AlwRequestDateFrom',
        value: startDay(values[0]),
      },
      { param: 'AlwRequestDateTo', value: endDay(values[1]) },
    ];
  }
  if (key === FF_AlwRespondDate) {
    return [
      {
        param: 'AlwRespondDateFrom',
        value: startDay(values[0]),
      },
      { param: 'AlwRespondDateTo', value: endDay(values[1]) },
    ];
  }
  if (key === FF_SlideLastUpdated) {
    return [
      {
        param: 'SlideLastUpdatedFrom',
        value: startDay(values[0]),
      },
      { param: 'SlideLastUpdatedTo', value: endDay(values[1]) },
    ];
  }

  if (key === FF_SlideSentToLabDate) {
    return [
      {
        param: 'SlideSentToLabFrom',
        value: startDay(values[0]),
      },
      { param: 'SlideSentToLabTo', value: endDay(values[1]) },
    ];
  }

  if (key === FF_SlideSentToExternalDate) {
    return [
      {
        param: 'SlideSentToExternalFrom',
        value: startDay(values[0]),
      },
      { param: 'SlideSentToExternalTo', value: endDay(values[1]) },
    ];
  }

  if (key === FF_SlideSentToPathDate) {
    return [
      {
        param: 'SlideSentToPathFrom',
        value: startDay(values[0]),
      },
      { param: 'SlideSentToPathTo', value: endDay(values[1]) },
    ];
  }

  if (key === FF_SlideReturnedToLabDate) {
    return [
      {
        param: 'SlideReturnToLabFrom',
        value: startDay(values[0]),
      },
      { param: 'SlideReturnToLabTo', value: endDay(values[1]) },
    ];
  }
  if (key === FF_SlideReturnedToClinicDate) {
    return [
      {
        param: 'SlideReturnToClinicDateFrom',
        value: startDay(values),
      },
      { param: 'SlideReturnToClinicDateTo', value: endDay(values) },
    ];
  }
};

export const isDateRangeSearch = (key, value) => {
  return (
    [
      FF_LabEntryDate,
      FF_ReportedDate,
      FF_AlwRequestDate,
      FF_AlwRespondDate,
      FF_SlideLastUpdated,
      FF_SlideSentToLabDate,
      FF_SlideSentToExternalDate,
      FF_SlideSentToPathDate,
      FF_SlideReturnedToLabDate,
      FF_SlideReturnedToClinicDate,
    ].includes(key) && value
  );
};

const isLocationFlag = (key, value) => {
  return (
    [
      FF_LocationLab,
      FF_LocationExternal,
      FF_LocationSentToPath,
      FF_LocationReturnedToLab,
      FF_LocationReturnedToClinic,
    ].includes(key) && value
  );
};

const mergeLocationFlagValues = ({ userQueryParams, key, value }, LOCATION_FLAG) => {
  const SelectedFlagsKey = 'Filters.AdvanceSearch.SlideLocationFlagIdsIsTrue';
  const NotSelectedFlagsKey = 'Filters.AdvanceSearch.SlideLocationFlagIdsIsFalse';

  if (!userQueryParams[SelectedFlagsKey]) {
    userQueryParams[SelectedFlagsKey] = '';
  }
  if (!userQueryParams[NotSelectedFlagsKey]) {
    userQueryParams[NotSelectedFlagsKey] = '';
  }
  // then check logic to append flag to the approriate list
  let selectedValues = userQueryParams[SelectedFlagsKey].split(',').filter((v) => v.length > 0);
  let notSelectedValues = userQueryParams[NotSelectedFlagsKey].split(',').filter((v) => v.length > 0);

  if (value === 'true') {
    selectedValues.push(LOCATION_FLAG[key]);
    userQueryParams[SelectedFlagsKey] = selectedValues.join(',');
  } else {
    // otherwise
    notSelectedValues.push(LOCATION_FLAG[key]);
    userQueryParams[NotSelectedFlagsKey] = notSelectedValues.join(',');
  }

  if (isPerfectEmpty(userQueryParams[SelectedFlagsKey])) {
    Reflect.deleteProperty(userQueryParams, SelectedFlagsKey);
  }
  if (isPerfectEmpty(userQueryParams[NotSelectedFlagsKey])) {
    Reflect.deleteProperty(userQueryParams, NotSelectedFlagsKey);
  }

  return userQueryParams;
};

export default {
  findOne({ slideId, caseSpecimenBlockId, CaseAlwRequestId, CaseSpecimenId, SlideTypeName }) {
    return httpClient.get(`/${RESOURCE}/${slideId}/casespecimenblocks/${caseSpecimenBlockId}`, {
      CaseAlwRequestId: CaseAlwRequestId,
      CaseSpecimenId: CaseSpecimenId,
      SlideTypeName: SlideTypeName,
    });
  },
  getCaseSlideList({ filters, pagination, sort }, LOCATION_FLAG) {
    const { pageNumber, pageSize } = pagination;
    let userQueryParams = {};
    Object.entries(filters).forEach(([key, value]) => {
      if (key === 'search') {
        userQueryParams['Filters.Search'] = value;
        // date range values
      } else if (isDateRangeSearch(key, value)) {
        const values = key === FF_SlideReturnedToClinicDate ? value : value.split(',');
        if (
          (values.length == 2 || key === FF_SlideReturnedToClinicDate) &&
          !isPerfectEmpty(values[0]) &&
          !isPerfectEmpty(values[1])
        ) {
          mappingDateRangeSearch(key, values).forEach((f) => {
            Reflect.set(userQueryParams, ['Filters.AdvanceSearch.' + f.param], f.value);
          });
        }
      } else if (isLocationFlag(key, value)) {
        userQueryParams = mergeLocationFlagValues({ userQueryParams, key, value }, LOCATION_FLAG);
      } else {
        userQueryParams['Filters.AdvanceSearch.' + key] = value;
      }
    });

    if (pageNumber) {
      userQueryParams['PageNumber'] = pageNumber;
    }
    if (pageSize) {
      userQueryParams['PageSize'] = pageSize;
    }
    if (sort) {
      userQueryParams['Sort'] = sort;
    }
    return httpClient.get(`/${RESOURCE}`, userQueryParams);
  },
  exports(exportFileName, { filters, pagination, sort }, data, LOCATION_FLAG) {
    const { pageNumber, pageSize } = pagination;
    let userQueryParams = {};
    Object.entries(filters).forEach(([key, value]) => {
      if (key === 'search') {
        userQueryParams['Filters.Search'] = value;
        // date range values
      } else if (isDateRangeSearch(key, value)) {
        const values = key === FF_SlideReturnedToClinicDate ? value : value.split(',');
        if (
          (values.length == 2 || key === FF_SlideReturnedToClinicDate) &&
          !isPerfectEmpty(values[0]) &&
          !isPerfectEmpty(values[1])
        ) {
          mappingDateRangeSearch(key, values).forEach((f) => {
            Reflect.set(userQueryParams, ['Filters.AdvanceSearch.' + f.param], f.value);
          });
        }
      } else if (isLocationFlag(key, value)) {
        userQueryParams = mergeLocationFlagValues({ userQueryParams, key, value }, LOCATION_FLAG);
      } else {
        userQueryParams['Filters.AdvanceSearch.' + key] = value;
      }
    });

    if (pageNumber) {
      userQueryParams['PageNumber'] = pageNumber;
    }
    if (pageSize) {
      userQueryParams['PageSize'] = pageSize;
    }
    if (sort) {
      userQueryParams['Sort'] = sort;
    }
    return httpClient.postBlob(`/cases/export/${exportFileName}`, data, userQueryParams);
  },

  checkValidBulkAction(data = {}) {
    return httpClient.post(`/${RESOURCE}/validate`, data);
  },
  changeLocationFlag(data = {}) {
    return httpClient.post(`/${RESOURCE}/changelocation`, data);
  },
  addTrackingNo(data = {}) {
    return httpClient.post(`/${RESOURCE}/add-tracking-no`, data);
  },
  save(data = {}) {
    return httpClient.put(`/${RESOURCE}/tracking-no`, data);
  },
  /*
    enum CaseSlideLocationFlagEnum
      {
          [Display(Name = "Lab")]
          Lab = 0,
          [Display(Name = "Sent To Path")]
          SentToPath = 1,
          [Display(Name = "Return To Lab")]
          ReturnedToLab = 2,
          [Display(Name = "Return To Clinic")]
          ReturnedToClinic = 3,
          [Display(Name = "External")]
          External = 4
      }
    */
  async getSlideLocationFlags() {
    return new Promise((resolve) => {
      const items = [];
      items.push({
        fieldItemId: 0,
        fieldItemName: LOCATION_FLAG_NAMES.Lab,
      });
      items.push({
        fieldItemId: 1,
        fieldItemName: LOCATION_FLAG_NAMES.SentToPath,
      });
      items.push({
        fieldItemId: 2,
        fieldItemName: LOCATION_FLAG_NAMES.ReturnedToLab,
      });
      items.push({
        fieldItemId: 3,
        fieldItemName: LOCATION_FLAG_NAMES.ReturnedToClinic,
      });
      items.push({
        fieldItemId: 4,
        fieldItemName: LOCATION_FLAG_NAMES.External,
      });
      const res = {};
      Reflect.set(
        res,
        DROPDOWN_SHORT_NAME.CASE_SLIDE_LOCATION_FLAG,
        items.map((item) => {
          return { ...item, label: item.fieldItemName, value: item.fieldItemId };
        }),
      );
      resolve(res);
    });
  },
};
