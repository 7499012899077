<template>
  <div class="md-layout">
    <div class="md-layout-item">
      <h1>Error 403</h1>
      <p>Forbidden error</p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
