<template>
  <md-dialog
    :md-close-on-esc="true"
    :md-active.sync="isVisible"
    @md-clicked-outside="close"
    class="lims-modal large-modal"
  >
    <div class="dialog-icon-close" @click="close"><md-icon>close</md-icon></div>
    <div class="lims-form dialog-content">
      <button @click="onExport()">Test</button>
      <div class="qr-code-position" v-for="(item, index) in caseListData" :key="index">
        <qrcode-vue :value="coverData(item)" :size="qrCodeImageSize" level="L" />
      </div>
    </div>
  </md-dialog>
</template>

<script>
import modalMixins from './modal.mixins';
import QrcodeVue from 'qrcode.vue';
import jsPDF from 'jspdf';
import {
  addNewLabel,
  convertIndexToPageRowCol,
  DEFAULT_PRINT_SETTING_VALUES,
  PRINT_LABEL_SIZES,
} from '@/pages/Example/ExamplePrintLabel.vue';

export default {
  name: 'modal-scan-qr-code',
  mixins: [modalMixins],
  components: {
    QrcodeVue,
  },
  computed: {
    qrCodeImageSize() {
      return PRINT_LABEL_SIZES.QRCODE_SIZE;
    },
  },
  created() {
    this.coverData();
  },
  updated() {
    if (this.isVisible) {
      this.onExport();
    }
  },
  props: {},
  data() {
    return {
      expandSpace: 0,
      caseListData: null,
    };
  },
  methods: {
    setCaseListData(payload) {
      this.caseListData = payload;
    },
    coverData(caseListItem) {
      if (caseListItem) {
        let qrCodeData = '';
        if (caseListItem.caseReference) {
          qrCodeData += caseListItem.caseReference;
        }
        if (caseListItem.labReference) {
          qrCodeData += ',' + caseListItem.labReference;
        }
        if (caseListItem.lastName) {
          qrCodeData += ',' + caseListItem.lastName;
        }
        if (caseListItem.firstName) {
          qrCodeData += ',' + caseListItem.firstName;
        }
        return `${qrCodeData},`;
      }
    },
    onExport() {
      const pdfName = 'Print Label';
      const doc = new jsPDF({
        orientation: 'portrait',
        unit: 'cm',
        format: [21, 29.7],
      });
      //   let pdfName = 'Print Label';
      doc.setFontSize(PRINT_LABEL_SIZES.FONT_SIZE);
      const qrImages = document.getElementsByTagName('canvas');
      let currentPage = 0;
      const printSettingValues = DEFAULT_PRINT_SETTING_VALUES;
      for (let index = 0; index < qrImages.length; index++) {
        const qrImage = qrImages[index];
        const caseItem = this.caseListData[index];
        const { i, j, page } = convertIndexToPageRowCol(index);
        if (page > currentPage) {
          // create new page
          doc.addPage();
          currentPage = page;
        }
        addNewLabel(doc, i, j, caseItem, qrImage, printSettingValues);
      }
      window.open(doc.output('bloburl'));
      doc.save(pdfName + '.pdf');
      this.close();
    },
  },
};
</script>

<style lang="scss">
.left-text {
  text-align: left;
}
.qr-code-position {
  text-align: center;
}
</style>
