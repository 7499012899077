import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}
export default {
  methods: {
    initPerfectScrollBar() {
      let isWindows = navigator.platform.startsWith('Win');
      if (isWindows) {
        const className = 'chart-container';
        const perfectScrollBarOptions = {
          wheelSpeed: 1,
          wheelPropagation: true,
          swipeEasing: false,
          // minScrollbarLength: 5,
          always: true,
          // maxScrollbarLength: 40,
        };
        if (hasElement(className)) {
          const elements = document.querySelectorAll(`.${className}`);
          for (let i = 0; i < elements.length; i++) {
            // eslint-disable-next-line security/detect-object-injection
            const ps = new PerfectScrollbar(elements[i], perfectScrollBarOptions);
            this.$nextTick(() => {
              ps.update();
            });
          }
        }
      }
    },
  },
};
