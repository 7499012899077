<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit }">
    <modal-duplicate-dataset
      ref="duplicateDatasetModal"
      @onConfirm="onDuplicateDatasetConfirm"
    ></modal-duplicate-dataset>
    <form class="md-layout lims-form" @submit.prevent="handleSubmit()" v-if="formData">
      <modal-delete-dataset ref="deleteDatasetModal" @onDelete="onDeleteDataset"></modal-delete-dataset>
      <lims-block>
        <h4 class="title" slot="blockTitle">{{ $t('pages/dataset/forms/information/blockTitle') }}</h4>
        <md-button
          slot="blockTitle"
          v-if="isShowEditBtn()"
          @click="onEdit()"
          class="md-button md-primary lims-form-button md-theme-default edit-btn-has-collapse"
        >
          {{ $t('global/button/button.edit') }}
        </md-button>
        <lims-collapse slot="blockContent" :collapse="[$t('pages/dataset/forms/collapse')]" icon="keyboard_arrow_down">
          <div class="md-layout lims-form" slot="md-collapse-pane-1">
            <div class="form-wrapper">
              <div class="md-layout lims-form-row">
                <div class="md-layout-item md-size-30 md-small-size-100">
                  <lims-field :model="formData" :schema="schema" field="clinicId">
                    <lims-single-select
                      slot="field"
                      v-model="formData.clinicId"
                      :options="dataSource.entityList"
                      reduceKey="value"
                      labelKey="label"
                      :disabled="isDisabledForm"
                    ></lims-single-select>
                  </lims-field>
                </div>
                <div class="md-layout-item md-size-70 md-small-size-100">
                  <lims-field :model="formData" :schema="schema" field="title">
                    <md-input
                      slot="field"
                      v-model="formData.title"
                      :disabled="isDisabledForm"
                      type="text"
                      maxlength="250"
                      tabenable="yes"
                    ></md-input>
                  </lims-field>
                </div>
              </div>
              <div class="md-layout lims-form-row">
                <div class="md-layout-item md-size-100 md-small-size-100">
                  <lims-field :model="formData" :schema="schema" field="description" class="textarea-field">
                    <md-textarea
                      slot="field"
                      v-model="formData.description"
                      :disabled="isDisabledForm"
                      type="text"
                      maxlength="1000"
                      tabenable="yes"
                    ></md-textarea>
                  </lims-field>
                </div>
              </div>
              <div class="md-layout lims-form-row">
                <div class="md-layout-item md-size-100 md-small-size-100">
                  <lims-field :model="formData" :schema="schema" field="statusName">
                    <div slot="field" class="static-text">
                      {{ formMode === ADD_MODE ? $t('pages/dataset/forms/statusNameLabel/disabled') : statusNameLabel }}
                    </div>
                  </lims-field>
                </div>
              </div>
            </div>
          </div>
        </lims-collapse>
      </lims-block>
      <!-- trigger -->
      <lims-block>
        <h4 class="title" slot="blockTitle">{{ $t('pages/dataset/forms/trigger/blockTitle') }}</h4>
        <lims-collapse slot="blockContent" :collapse="[$t('pages/dataset/forms/collapse')]" icon="keyboard_arrow_down">
          <div class="md-layout lims-form" slot="md-collapse-pane-1">
            <div class="form-wrapper">
              <div class="md-layout lims-form-row">
                <div class="md-layout-item md-size-33 md-small-size-100">
                  <lims-field :model="formData" :schema="schema" field="snomedMFieldItemId">
                    <lims-tooltip slot="label-info" :content="$t('pages/dataset/forms/trigger/txt')"></lims-tooltip>
                    <lims-single-select
                      slot="field"
                      v-model="formData.snomedMFieldItemId"
                      :options="dataSource.snomedList.snomed3"
                      reduceKey="value"
                      labelKey="label"
                      :disabled="isDisabledForm"
                    ></lims-single-select>
                  </lims-field>
                </div>
                <div class="md-layout-item md-size-33 md-small-size-100">
                  <lims-field :model="formData" :schema="schema" field="snomedPFieldItemId">
                    <lims-single-select
                      slot="field"
                      v-model="formData.snomedPFieldItemId"
                      :options="dataSource.snomedList.snomed2"
                      reduceKey="value"
                      labelKey="label"
                      :disabled="isDisabledForm"
                    ></lims-single-select>
                  </lims-field>
                </div>
                <div class="md-layout-item md-size-33 md-small-size-100">
                  <lims-field :model="formData" :schema="schema" field="snomedTFieldItemId">
                    <lims-single-select
                      slot="field"
                      v-model="formData.snomedTFieldItemId"
                      :options="dataSource.snomedList.snomed1"
                      reduceKey="value"
                      labelKey="label"
                      :disabled="isDisabledForm"
                    ></lims-single-select>
                  </lims-field>
                </div>
              </div>
            </div>
          </div>
        </lims-collapse>
      </lims-block>
      <!-- controls -->
      <dataset-controls :dataEdit="formData" :formMode="formMode"></dataset-controls>
      <!-- Begin action form -->
      <div class="md-layout lims-form-row">
        <div class="md-layout-item md-size-100 md-small-size-100 lims-form-actions">
          <LimsFormCancel />
          <md-button
            v-if="formMode === ADD_MODE"
            @click="onCreateDataset()"
            class="md-button md-primary lims-form-button md-theme-default"
          >
            {{ $t('pages/dataset/forms/btn.createDataset') }}
          </md-button>
          <md-button
            v-if="formMode === EDIT_MODE && !formData.isUsedInAuthorisedCase"
            @click="onSave()"
            class="md-button md-primary lims-form-button md-theme-default"
          >
            {{ $t('global/button/button.save') }}
          </md-button>
          <md-button
            v-if="formMode === EDIT_MODE && formData.statusName === DATASET_STATUS_NAME.ACTIVE"
            class="md-primary lims-form-button"
            @click="onDuplicate"
            >{{ $t('global/button/button.duplicate') }}</md-button
          >
          <md-button
            v-if="formMode === EDIT_MODE && formData.statusName !== DATASET_STATUS_NAME.ACTIVE"
            @click="onEnable()"
            class="md-button md-primary lims-form-button md-theme-default"
          >
            {{ $t('global/button/button.enable') }}
          </md-button>
          <md-button
            v-if="formMode === EDIT_MODE && formData.statusName === DATASET_STATUS_NAME.ACTIVE"
            @click="onDisable()"
            class="md-button md-danger lims-form-button md-theme-default"
          >
            {{ $t('global/button/button.disable') }}
          </md-button>
          <md-button
            v-if="isShowDeleteBtn()"
            @click="onDelete()"
            class="md-button md-danger lims-form-button md-theme-default"
          >
            {{ $t('global/button/button.delete') }}
          </md-button>
          <md-button
            v-if="isShowEditBtn()"
            @click="onEdit()"
            class="md-button md-primary lims-form-button md-theme-default"
          >
            {{ $t('global/button/button.edit') }}
          </md-button>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import { APP_ROUTES, DATASET_STATUS_NAME, FORM_MODES } from '@/core/constants';
import { FormMixins, TabMixins } from '@/core/mixins';
import LimsCollapse from '@/components/Lims/LimsCollapse';
import ModalDeleteDataset from '@/components/Lims/modals/ModalDeleteDataset.vue';
import datasetService from '@/services/dataset.service';
import DatasetControls from '../Components/DatasetControls.vue';
import DatasetMixins from '../dataset.mixins';
import ModalDuplicateDataset from '@/components/Lims/modals/ModalDuplicateDataset';
import { mapActions, mapGetters } from 'vuex';
import { caseFormService } from '@/services';
const { DROPDOWN_SHORT_NAME } = require('@/core/constants');

export default {
  mixins: [FormMixins, DatasetMixins, TabMixins],
  components: {
    ModalDuplicateDataset,
    ModalDeleteDataset,
    LimsCollapse,
    DatasetControls,
  },
  props: {
    formMode: {
      type: Number,
      require: true,
      validator: function (value) {
        // The value must match one of these strings
        return Object.values(FORM_MODES).indexOf(value) !== -1;
      },
    },
    datasetResource: {
      type: Object,
      require: false,
    },
  },
  computed: {
    ...mapGetters('dataSet', ['getDataSetData']),
    ...mapActions('dataSet', ['copyDataSetData']),
    DATASET_STATUS_NAME() {
      return DATASET_STATUS_NAME;
    },
    statusNameLabel() {
      // isUsed: flag for case data
      // isUsedInAuthorisedCase: flat to prevent delete/edit
      return this.formData.statusName === DATASET_STATUS_NAME.DEACTIVE
        ? this.$t('pages/dataset/forms/statusNameLabel/disabled')
        : this.$t('pages/dataset/forms/statusNameLabel/enabled');
    },
    isDisabledForm() {
      return (
        this.formMode === this.VIEW_MODE ||
        this.formData.statusName === DATASET_STATUS_NAME.DEACTIVE ||
        this.formData.isUsedInAuthorisedCase
      );
    },
    isAdminView() {
      return this.userType === this.USER_TYPES().Administrator;
    },
    schema() {
      return {
        entity: 'dataset/form',
        fields: {
          title: 'required',
          description: 'required',
          clinicId: 'required',
          statusName: '',
          snomedMFieldItemId: 'required',
          snomedPFieldItemId: '',
          snomedTFieldItemId: '',
          controls: '',
        },
      };
    },
  },
  watch: {
    'formData.clinicId': {
      deep: true,
      async handler(val) {
        if (val) {
          this.dataSource.snomedList = await this.getSnomedCodeList(val);
          const checkSnomedM = this.checkItemIncludedInSnomedList(
            this.formData.snomedMFieldItemId,
            this.dataSource.snomedList.snomed3,
            'M',
          );
          const checkSnomedP = this.checkItemIncludedInSnomedList(
            this.formData.snomedPFieldItemId,
            this.dataSource.snomedList.snomed2,
            'P',
          );
          const checkSnomedT = this.checkItemIncludedInSnomedList(
            this.formData.snomedTFieldItemId,
            this.dataSource.snomedList.snomed1,
            'T',
          );
          if (checkSnomedM || checkSnomedP || checkSnomedT) {
            this.$alertSuccess(
              this.$t('entities/personalised-report/form/report/warning', {
                casesClinic: this.formData.clinicName,
                snomedName: this.snomedName.join(', '),
              }),
            );
          }
        }
      },
    },
  },
  created: async function () {
    this.userType = this.$store.getters['auth/userType'];
    await this.fetchData();
  },
  data() {
    return {
      formData: null,
      dataSource: {
        entityList: [],
        snomedList: {
          snomed1: [],
          snomed2: [],
          snomed3: [],
        },
      },
      entityId: null,
      entityName: '',
      snomedName: [],
    };
  },
  methods: {
    isAdmin() {
      return this.userType === this.USER_TYPES().Administrator;
    },
    async fetchData() {
      const entityFullList = await this.getEntityList();
      if ((this.formMode === this.ADD_MODE || this.formMode === this.EDIT_MODE) && !this.isAdmin()) {
        this.dataSource.entityList = entityFullList.filter((item) => item.isEnableManageDataset);
      } else {
        this.dataSource.entityList = entityFullList;
      }

      if (this.formMode === this.ADD_MODE) {
        if (this.getDataSetData) {
          this.formData = {
            ...this.getDataSetData,
          };
          if (!this.getDataSetData.clinicId) {
            this.formData.clinicId = 'all';
          }
          await this.$store.dispatch('dataSet/copyDataSetData', null);
        } else {
          this.formData = {
            title: '',
            description: '',
            clinicId: this.isAdmin() ? '' : this.entityId,
            snomedMFieldItemId: null,
            snomedPFieldItemId: null,
            snomedTFieldItemId: null,
            statusName: DATASET_STATUS_NAME.ACTIVE,
            controls: [],
          };
        }
      } else {
        if (this.formMode === this.EDIT_MODE || this.formMode === this.VIEW_MODE) {
          if (this.datasetResource) {
            this.formData = this.datasetResource;
            if (!this.datasetResource.clinicId) {
              this.formData.clinicId = 'all';
            }
          } else {
            this.formData = {
              title: '',
              description: '',
              clinicId: this.isAdmin() ? '' : this.entityId,
              snomedMFieldItemId: null,
              snomedPFieldItemId: null,
              snomedTFieldItemId: null,
              statusName: DATASET_STATUS_NAME.ACTIVE,
              controls: [],
            };
          }
        }
      }
      this.$nextTick(function () {
        this.$resetChangeDetection();
      });
    },
    async getEntityList() {
      const { data, error } = await datasetService.getListClinicsFromCurrentUser();
      if (error) {
        this.$alertError(error);
      }
      return data.map((row) => {
        return {
          ...row,
          value: row.id || 'all',
          label: row.text,
        };
      });
    },
    async getSnomedCodeList(entityId) {
      if (entityId === 'all') {
        entityId = null;
      }
      const data = await caseFormService.getStainByEntityId(entityId, [DROPDOWN_SHORT_NAME.SNOMED]);
      const options = {
        snomed1: [],
        snomed2: [],
        snomed3: [],
      };
      data.map((d) => {
        const { itemName, items } = d;
        Object.keys(options).map((k) => {
          if (itemName.toLowerCase() === k.toLowerCase()) {
            // eslint-disable-next-line security/detect-object-injection
            options[k] = items.map((n) => {
              return {
                label: n.fieldItemName,
                value: n.fieldItemId,
              };
            });
          }
        });
      });
      return options;
    },
    checkItemIncludedInSnomedList(snomedId, snomedList, snomedName) {
      if (!this.findSnomedInSnomedList(snomedId, snomedList) && snomedId) {
        if (snomedName === 'M') {
          this.formData.snomedMFieldItemId = null;
        } else {
          if (snomedName === 'P') {
            this.formData.snomedPFieldItemId = null;
          } else {
            if (snomedName === 'T') {
              this.formData.snomedTFieldItemId = null;
            }
          }
        }
        this.snomedName.push(snomedName);
        return true;
      } else {
        return false;
      }
    },
    findSnomedInSnomedList(snomedId, snomedList) {
      return snomedList.find((snomedItem) => snomedItem.value == snomedId);
    },
    preparePayload(formData) {
      if (formData.clinicId === 'all') {
        return {
          ...formData,
          clinicId: null,
        };
      }
      return {
        ...formData,
      };
    },
    async onCreateDataset() {
      const isValid = await this.$refs.form.validate();
      if (isValid) {
        // save
        const res = await datasetService.createCaseDataset(
          this.preparePayload({
            ...this.formData,
            statusName: DATASET_STATUS_NAME.DEACTIVE,
          }),
        );
        if (res.err) {
          return this.$alertErrorServerSide(res.err);
        }
        this.$resetChangeDetection();
        await this.$router.push(APP_ROUTES.DATASET).then(
          this.$alertSuccess(
            this.$t('pages/dataset/DatasetManagement/DatasetForm/create.success', {
              datasetName: this.formData.title,
            }),
          ),
        );
      } else {
        this.$alertError(this.$t(`global/errors/message`));
      }
    },
    async onDuplicate() {
      this.$refs.duplicateDatasetModal.open();
    },
    async onDuplicateDatasetConfirm(data) {
      this.$refs.duplicateDatasetModal.close();
      if (data === 'yes') {
        const res = await datasetService.disableCaseDataset(this.datasetResource.datasetId);
        if (res.err) {
          return this.$alertErrorServerSide(res.err);
        }
        this.$alertSuccess(
          this.$t('pages/dataset/DatasetManagement/DatasetForm/disable.success', {
            dataset: this.formData.title,
          }),
        );
      }
      const dataSetData = {
        ...this.formData,
      };
      dataSetData.isUsed = false;
      dataSetData.isUsedInAuthorisedCase = false;
      await this.$store.dispatch('dataSet/copyDataSetData', dataSetData);
      await this.$router.push(APP_ROUTES.DATASET_ADD);
    },
    onDelete() {
      this.$refs.deleteDatasetModal.setDatasetData(this.datasetResource.datasetId, this.formData.title);
      this.$refs.deleteDatasetModal.open();
    },
    async onDeleteDataset(deleteDatasetData) {
      const status = await this.$onDeleteDataset(deleteDatasetData);
      if (status) {
        await this.$router.push(APP_ROUTES.DATASET);
      }
    },
    async onDisable() {
      const res = await datasetService.disableCaseDataset(this.datasetResource.datasetId);
      if (res.err) {
        return this.$alertErrorServerSide(res.err);
      }
      this.$alertSuccess(
        this.$t('pages/dataset/DatasetManagement/DatasetForm/disable.success', {
          dataset: this.formData.title,
        }),
      );
      this.formData.statusName = DATASET_STATUS_NAME.DEACTIVE;
      this.$resetChangeDetection();
    },
    async onEnable() {
      const res = await datasetService.enableCaseDataset(this.datasetResource.datasetId);
      if (res.err) {
        return this.$alertErrorServerSide(res.err);
      }
      this.$alertSuccess(
        this.$t('pages/dataset/DatasetManagement/DatasetForm/enable.success', {
          dataset: this.formData.title,
        }),
      );
      this.formData.statusName = DATASET_STATUS_NAME.ACTIVE;
      this.$resetChangeDetection();
    },
    onEdit() {
      this.$router.push(`${APP_ROUTES.DATASET_EDIT}/${this.datasetResource.datasetId}`);
    },
    async onSave() {
      const isValid = await this.$refs.form.validate();
      if (isValid) {
        // save
        const res = await datasetService.updateCaseDataset(
          this.datasetResource.datasetId,
          this.preparePayload(this.formData),
        );
        if (res.err) {
          return this.$alertErrorServerSide(res.err);
        }
        this.$resetChangeDetection();
        this.$router.push(APP_ROUTES.DATASET).then(
          this.$alertSuccess(
            this.$t('pages/dataset/DatasetManagement/DatasetForm/update.success', {
              datasetName: this.formData.title,
            }),
          ),
        );
      } else {
        this.$alertError(this.$t(`global/errors/message`));
      }
    },

    isShowEditBtn() {
      return (
        this.formMode === this.VIEW_MODE &&
        this.$isAuthorized(['MyEntity_DatasetManagement_Update']) &&
        !this.formData.isUsedInAuthorisedCase &&
        this.formData.isEnableManageDataset
      );
    },

    isShowDeleteBtn() {
      return (
        this.formMode === this.EDIT_MODE &&
        this.$isAuthorized(['MyEntity_DatasetManagement_Delete']) &&
        this.formData.statusName === DATASET_STATUS_NAME.DEACTIVE &&
        !this.formData.isUsedInAuthorisedCase &&
        this.formData.isEnableManageDataset
      );
    },
  },
};
</script>

<style lang="scss" scoped></style>
