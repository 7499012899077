<template>
  <div class="md-layout text-center">
    <lims-forgot-password-card header-color="green" v-if="isLogged">
      <h4 slot="title" class="title">{{ $t('pages/auth/forgotPassword/title.forgotPassword') }}</h4>
      <p slot="description">...</p>
    </lims-forgot-password-card>
    <div class="md-layout-item md-size-33 md-medium-size-50 md-small-size-70 md-xsmall-size-100">
      <ValidationObserver ref="form" v-slot="{ handleSubmit }">
        <form class="md-layout lims-form form-login" @submit.prevent="handleSubmit()">
          <lims-forgot-password-card header-color="primary" v-if="!isLogged">
            <h4 slot="title" class="title">{{ $t('pages/auth/forgotPassword/title.forgotPassword') }}</h4>
            <div slot="inputs">
              <lims-field :model="formData" :schema="schema" field="inputValue" viewMode="custom">
                <md-icon slot="icon">mail</md-icon>
                <md-input
                  slot="field"
                  @keyup.enter="onClickSend"
                  v-model="formData.inputValue"
                  type="text"
                  autocomplete="off"
                ></md-input>
              </lims-field>
            </div>
            <p slot="description">{{ $t('pages/auth/forgotPassword/label.enterYourEmail') }}</p>
            <p slot="actions">
              <md-button @click="onClickSend" slot="actions" class="md-primary" :disabled="!formData.inputValue">
                {{ $t('pages/auth/forgotPassword/button.send') }}
              </md-button>
            </p>
            <p slot="back-to-login">
              <label class="text-underline" @click="onClickBackToLogin">{{
                $t('pages/auth/forgotPassword/label.backToLogin')
              }}</label>
            </p>
          </lims-forgot-password-card>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import { LimsForgotPasswordCard } from '@/components/Lims/Cards';
import LimsField from '@/components/Lims/LimsField';
import { mapActions, mapState } from 'vuex';
import { APP_ROUTES } from '@/core/constants';
import { AuthService } from '@/services';

export default {
  components: {
    LimsForgotPasswordCard,
    LimsField,
  },

  created() {
    this.CHECK_USER_COOKIE();
  },

  data: function () {
    return {
      formData: {
        inputValue: null,
      },
    };
  },

  computed: {
    ...mapState('auth', {
      isLogged: (state) => state.isLogged,
    }),
    schema() {
      return {
        entity: 'auth/forgot-password',
        fields: {
          inputValue: 'required',
        },
      };
    },
  },

  watch: {
    isLogged: {
      handler: function (isLogged) {
        if (isLogged) {
          const redirectTo = this.redirectTo || APP_ROUTES.DASHBOARD;
          this.$router.push(redirectTo);
        }
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions('auth', ['CHECK_USER_COOKIE']),

    onClickSend() {
      this.$refs.form.validate().then(async (success) => {
        if (success) {
          try {
            const res = AuthService.forgotPassword(this.formData.inputValue);
            if (res.errors && res.errors.length > 0) {
              this.$alertError(this.$t(`global/errors/message`));
              return this.$refs.form.setErrors(this.$transformErrors(res.errors));
            }
            if (res.err) {
              return this.$alertError(res.err + ' error');
            }
            this.$alertSuccess(
              this.$t('pages/auth/forgotPassword/message.success', {
                account: this.formData.inputValue,
              }),
            );
          } catch (errors) {
            this.$alertError(errors);
          }
        } else {
          this.$alertError(this.$t(`global/errors/message`));
        }
      });
    },

    onClickBackToLogin() {
      this.$router.push(APP_ROUTES.AUTH_LOGIN);
    },
  },
};
</script>

<style lang="scss" scoped></style>
