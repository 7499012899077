import { getDefaultFilterValues, getFilterFieldTypes } from '@/query/utils';
import { FIELD_TYPES } from '@/query/constant';

const fields = [
  {
    key: 'entityTypeIds',
    listKey: 'entityTypeList',
    sort: 1,
    fieldType: FIELD_TYPES.ArrayInt,
    translated: false,
    defaultValue: [],
  },
  {
    key: 'clinicTypeIds',
    listKey: 'clinicTypeList',
    sort: 2,
    fieldType: FIELD_TYPES.ArrayInt,
    translated: true,
    defaultValue: [],
  },
  {
    key: 'entityStatusIds',
    listKey: 'entityStatusList',
    sort: 3,
    fieldType: FIELD_TYPES.ArrayInt,
    translated: false,
    defaultValue: [],
  },
  { key: 'search', listKey: null, sort: 6, fieldType: FIELD_TYPES.String, translated: false, defaultValue: [] },
];
const defaultValues = getDefaultFilterValues(fields);
const fieldTypes = getFilterFieldTypes(fields);
export default {
  fields,
  defaultValues,
  fieldTypes,
};
