import { cloneDeep } from 'lodash';
import { FORM_MODES } from '@/core/constants';

export const _serverSideValidators = {
  // laboratoryReference: {
  //   fieldName: 'laboratoryReference',
  //   errorType: 'unique',
  //   functionName: 'laboratoryReferenceCheck',
  //   formRef: 'formPatientData',
  //   params: {},
  // },
};

const CaseFormDTO = {
  caseRef: '',
  lastName: 'required',
  firstName: 'required',
  sexId: 'required',
  dob: 'lessThanOrEqualCurrentDate:checkDateLessOrEqualCurrentDate|dobLessThanProcedureDate',
  phone: 'notValid:validPhoneNumberCheck',
  hospitalReference: '',
  laboratoryReference: '',
  procedureDate: 'required|lessThanOrEqualCurrentDate:checkDateLessOrEqualCurrentDate|procedureDateGreaterThanDob',
  patientNhsNumber: '',
  address1: '',
  address2: '',
  town: '',
  countryId: '',
  postalCode: '',
  comment: '',
  pathologistId: 'required',
  pathologistNote: '',
  urgent: '',
  isUnExpectedFindings: '',
  status: '',
  optOut: '',
  noOfSpecimen: 'required|minNoOfSpecimen|validNoOfSpecimen',
  specimenTypeId: 'required',
  specimenDetails: '',
  clinicalDetails: '',
  countOfOriginalSlides: '',
  caseTrackingNumber: '',
  incidentComment: '',
  fromSourceName: '',
  labEntryDate: 'required|labEntryDateGreaterThanDob',
};

const BillingClinicAndLaboratoryDTO = {
  entityBillingId: '',
  workStreamId: 'required',
  insuranceCompanyId: '',
  insuranceNumber: '',
  insuranceAuthorisationNumber: '',
  address1: '',
  address2: '',
  town: '',
  countryId: '',
  postalCode: '',
  insuranceNote: '',
  clinicianId: 'required',
  clinicId: 'required',
  laboratory: 'required',
  technician1Id: 'required',
  technician2Id: '',
  courierCompany: '',
  pickupDateAndTime: '',
  reviewerId: '',
};

const diagnosis = {
  entity: 'case/form/diagnosis',
  fields: {
    specimen: '',
    snomed: '',
    snomedT: '',
    snomedP: '',
    snomedM: '',
    microscopy: '',
    macroDescription: '',
    caseCommentOrDiagnosisSummary: '',
    reporter: '',
    mobile: '',
  },
};

const CaseFormSchema = {
  entity: 'case/form',
  fields: {
    ...CaseFormDTO,
    ...BillingClinicAndLaboratoryDTO,
  },
  caseSpecimens: {
    entity: 'case/form/caseSpecimens',
    fields: {
      specimenTypeId: 'required',
      specimenDetailsOnPot: '',
      macroDescription: '',
      nbOfBlocks: '',
      tissueDiscardedDate: '',
    },
  },
  diagnosis,
  _serverSideValidators,
};

const CaseFormSchemaReleaseMode = {
  entity: 'case/form',
  fields: {
    ...CaseFormDTO,
    ...BillingClinicAndLaboratoryDTO,
  },
  caseSpecimens: {
    entity: 'case/form/caseSpecimens',
    fields: {
      sameSpecimenTypeId: '',
      specimenTypeId: 'required',
      specimenDetailsOnPot: '',
      macroDescription: '',
      nbOfBlocks: '',
    },
  },
  diagnosis,
  _serverSideValidators,
};

const prepareCaseFormSchema = (
  caseFormSchema,
  formMode,
  isSameSpecimen,
  validateDateTime,
  clinicalIncident,
  params,
) => {
  let caseFormSchemaDTO = {};
  if (params && params.noOfSpecimen) {
    for (var i = 0; i < params.noOfSpecimen; i++) {
      caseFormSchemaDTO['specimenTypeId-specimen' + `${i}`] = 'required';
      caseFormSchemaDTO['specimenDetailsOnPot-specimen' + `${i}`] = '';

      if (isSameSpecimen) {
        caseFormSchemaDTO['specimenTypeId-specimen' + `${i}`] = '';
      }
      if (params && params.isReviewCaseScreen && !params.isPreLabStatus) {
        caseFormSchemaDTO['specimenDetailsOnPot-specimen' + `${i}`] = 'required';
      }
      if (params && params.isReviewCaseScreen && params.isPreLabStatus && i > 0) {
        caseFormSchemaDTO['specimenTypeId-specimen' + `${i}`] = '';
      }
      if (params && params.isRequired) {
        caseFormSchemaDTO['specimenDetailsOnPot-specimen' + `${i}`] = 'required';
      }
    }
    caseFormSchema['caseSpecimens']['fields'] = {
      ...caseFormSchema['caseSpecimens']['fields'],
      ...caseFormSchemaDTO,
    };
  }

  const schema = cloneDeep(caseFormSchema);

  if (isSameSpecimen) {
    schema.fields['sameSpecimenTypeId'] = 'required';
  } else {
    schema.fields['sameSpecimenTypeId'] = '';
  }

  if (params && params.tissueDiscarded) {
    schema.fields['tissueDiscardedDate'] = 'required';
  } else {
    schema.fields['tissueDiscardedDate'] = '';
  }

  if (clinicalIncident) {
    schema.fields['incidentComment'] = 'required';
  } else {
    schema.fields['incidentComment'] = '';
  }

  if (params && params.isReviewCaseScreen) {
    if (params.isPreLabStatus) {
      schema.fields['lastName'] = 'required';
      schema.fields['firstName'] = 'required';
      schema.fields['sexId'] = 'required';
      schema.fields['dob'] = 'lessThanOrEqualCurrentDate:checkDateLessOrEqualCurrentDate|dobLessThanProcedureDate';
      schema.fields['procedureDate'] =
        'required|lessThanOrEqualCurrentDate:checkDateLessOrEqualCurrentDate|procedureDateGreaterThanDob';
      schema.fields['pathologistId'] = 'required';
      schema.fields['noOfSpecimen'] = 'required|minNoOfSpecimen|validNoOfSpecimen';
      schema.fields['clinicianId'] = 'required';
      schema.fields['laboratory'] = 'required';
      schema.fields['technician1Id'] = 'required';
      schema.caseSpecimens.fields['specimenTypeId'] = 'required';
      schema.fields['specimenDetails'] = '';
      schema.fields['clinicalDetails'] = '';
    } else {
      schema.fields['specimenDetails'] = 'required';
      schema.fields['clinicalDetails'] = 'required';
      schema.fields['dob'] =
        'required|lessThanOrEqualCurrentDate:checkDateLessOrEqualCurrentDate|dobLessThanProcedureDate';
    }
  }

  if (params && params.isPreLabStatus && !params.isReviewCaseScreen) {
    schema.fields['lastName'] = '';
    schema.fields['firstName'] = '';
    schema.fields['sexId'] = '';
    schema.fields['dob'] = 'lessThanOrEqualCurrentDate:checkDateLessOrEqualCurrentDate|dobLessThanProcedureDate';
    schema.fields['procedureDate'] =
      'lessThanOrEqualCurrentDate:checkDateLessOrEqualCurrentDate|procedureDateGreaterThanDob';
    schema.fields['pathologistId'] = '';
    schema.fields['noOfSpecimen'] = 'minNoOfSpecimen|validNoOfSpecimen';
    schema.fields['clinicianId'] = '';
    schema.fields['laboratory'] = '';
    schema.caseSpecimens.fields['specimenTypeId'] = '';
    schema.fields['specimenDetails'] = '';
    schema.fields['clinicalDetails'] = '';
  }

  if (params && params.isRequiredDob) {
    schema.fields['dob'] =
      'required|lessThanOrEqualCurrentDate:checkDateLessOrEqualCurrentDate|dobLessThanProcedureDate';
  }
  // labEntryDate
  if (params && params.isPreLabStatus) {
    schema.fields['labEntryDate'] = '';
  }
  if (params && params.isRequired) {
    schema.fields['specimenDetails'] = 'required';
    schema.fields['clinicalDetails'] = 'required';
  }
  if (params) {
    schema.fields['dob'] = `${schema.fields.dob}:${JSON.stringify({
      validateDateTime: params.isDobValid,
    })}`;
  }
  if (params) {
    schema.fields.procedureDate = `${schema.fields.procedureDate}:${JSON.stringify({
      validateDateTime: params.isProcedureDateValid,
    })}`;
  }
  if (params) {
    schema.fields.labEntryDate = `${schema.fields.labEntryDate}:${JSON.stringify({
      validateDateTime: params.isLabEntryDateValid,
    })}`;
  }
  if (params && params.caseSpecimenSettingNumber) {
    schema.fields.noOfSpecimen = `${schema.fields.noOfSpecimen}:${JSON.stringify({
      caseSpecimenSettingNumber: params.caseSpecimenSettingNumber,
    })}`;
  }

  // isAnonymized
  if (params && params.isAnonymized) {
    // ignore validations for anonymized fields and related fields
    schema.fields['lastName'] = '';
    schema.fields['firstName'] = '';
    schema.fields['dob'] = '';
    schema.fields['phone'] = '';
  }

  return schema;
};

export const getCaseFormSchema = (formMode, isSameSpecimen, validateDateTime, clinicalIncident, params) => {
  if (formMode === FORM_MODES.EDIT) {
    return prepareCaseFormSchema(
      CaseFormSchemaReleaseMode,
      formMode,
      isSameSpecimen,
      validateDateTime,
      clinicalIncident,
      params,
    );
  } else {
    return prepareCaseFormSchema(CaseFormSchema, formMode, isSameSpecimen, validateDateTime, clinicalIncident, params);
  }
};
