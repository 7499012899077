<template>
  <lims-block :cardText="false" class="list-page">
    <div class="md-layout" slot="blockContent">
      <div class="md-layout-item" v-if="query && dropDownsFilter">
        <request-list :requestList="items" :sort="query.sort">
          <md-table-toolbar slot="toolbar">
            <div class="toolbar-row">
              <div class="field-rows-per-page">
                <lims-row-per-page :pageSize="query.pagination.pageSize" @pageSizeChanged="$pageSizeChanged" />
              </div>
              <div class="field-search">
                <request-fiter
                  v-model="query.filters"
                  :defaultValues="defaultValues"
                  :fields="fields"
                  :filter="dropDownsFilter"
                  @onResetFilters="onResetFilters"
                ></request-fiter>
              </div>
            </div>
            <lims-pagination ref="pager" :total-item="totalItem" :pagination="query.pagination"> </lims-pagination>
          </md-table-toolbar>
        </request-list>
        <lims-pagination :total-item="totalItem" :pagination="query.pagination"> </lims-pagination>
      </div>
    </div>
  </lims-block>
</template>

<script>
import RequestList from './List/RequestList.vue';
import RequestFiter from './List/RequestFilter.vue';
import LimsRowPerPage from '@/components/Lims/LimsRowPerPage';
import LimsPagination from '@/components/Lims/LimsPagination';
import { ConfigMixins, ListWithFilterMixins } from '@/core/mixins';
import { requestQuery } from '@/query';
import { buildFilterFromQuery, buildFilterQueryParams } from '@/query/utils';
import cloneDeep from 'lodash/cloneDeep';
import { caseAnonymiseRequestService } from '@/services';

export default {
  mixins: [ConfigMixins, ListWithFilterMixins],
  components: {
    RequestList,
    RequestFiter,
    LimsRowPerPage,
    LimsPagination,
  },
  created() {
    this.initState();
    this.userType = this.$store.getters['auth/userType'];
  },
  data() {
    return {
      dropDownsFilter: null,
      items: [],
      totalItem: null,
      query: null,
    };
  },
  computed: {
    fields() {
      return requestQuery.filters.fields;
    },
    defaultValues() {
      return requestQuery.filters.defaultValues;
    },
  },
  methods: {
    // common methods
    initState() {
      const queryParams = this.$route.query;
      const filters = buildFilterFromQuery(requestQuery.filters, queryParams);
      const pagination = buildFilterFromQuery(requestQuery.pagination, {
        pageSize: this.$appConfig.itemPerPageDefault,
        ...queryParams,
      });
      const sort = buildFilterFromQuery(requestQuery.sort, queryParams);
      const query = { filters, pagination, sort };
      this.query = query;
    },
    async search(queryObject) {
      const { error, data } = await caseAnonymiseRequestService.getAnonymiseRequestList({
        filters: buildFilterQueryParams(queryObject.filters),
        pagination: buildFilterQueryParams(queryObject.pagination),
      });
      if (error) {
        this.$alertErrorServerSide(error);
        return;
      }
      this.items = data.requests.items;
      this.dropDownsFilter = data.dropDowns;
      this.totalItem = data.requests.pagination.totalItems;
      // update router
      const query = {
        ...buildFilterQueryParams(queryObject.filters),
        ...buildFilterQueryParams(queryObject.pagination),
        //...buildFilterQueryParams(queryObject.sort),
      };
      this.$router
        .push({
          query,
        })
        .catch((error) => {
          if (
            error.name === 'NavigationDuplicated' &&
            error.message.includes('Avoided redundant navigation to current location')
          ) {
            history.pushState({}, '', new URL(new URL(window.location)));
          }
        });
    },
    onResetFilters() {
      this.query.filters = cloneDeep(requestQuery.filters.defaultValues);
    },
  },
};
</script>
<style lang="scss"></style>
