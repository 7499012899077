<template>
  <md-dialog
    :md-close-on-esc="true"
    :md-active.sync="isVisible"
    @md-clicked-outside="close"
    class="lims-modal large-modal searchSimilarPatient-modal"
  >
    <div class="dialog-icon-close" @click="close"><md-icon>close</md-icon></div>
    <div style="padding: 50px 20px 20px">
      <document-view :data-edit="caseFormResource" :noResizePopup="true"></document-view>
    </div>
  </md-dialog>
</template>
<script>
import DocumentView from '@/pages/Case/CaseManagement/Components/DocumentView';
import modalMixins from '@/components/Lims/modals/modal.mixins';
export default {
  components: { DocumentView },
  mixins: [modalMixins],
  props: {
    caseFormResource: {
      type: Object,
      require: true,
    },
  },
  watch: {
    isVisible() {
      if (!this.isVisible) {
        this.$emit('onHideDocumentViewer', false);
      }
    },
  },
  data() {
    return {};
  },
};
</script>
