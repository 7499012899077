<template>
  <stats-card header-color="primary">
    <template slot="header">
      <div class="card-icon">
        <md-icon>store</md-icon>
      </div>
      <p class="category">SO Received</p>
    </template>

    <template slot="footer">
      <div class="group-stats-wrapper">
        <div class="group-stats">
          <div class="group-stats-item">
            <h3 @click="SOReceivedNavigate()">
              <span>{{ data.value }}</span>
            </h3>
          </div>
        </div>
        <div class="stats">
          <md-icon>date_range</md-icon>
          {{ data.time }}
        </div>
      </div>
    </template>
  </stats-card>
</template>

<script>
import { CASE_SO_REQUEST_STATUS_ENUM } from '@/core/constants';
import StatsMixins from './stats.mixins';
import { mapActions } from 'vuex';
export default {
  props: {
    data: {},
  },
  mixins: [StatsMixins],
  methods: {
    ...mapActions('app', ['$setRedirectName']),
    SOReceivedNavigate() {
      this.$setRedirectName('ReadyForReport');
      this.$navigateToList({
        path: 'cases',
        query: { IsSoCase: true, SoRequestStatus: CASE_SO_REQUEST_STATUS_ENUM.Requested },
      });
    },
  },
};
</script>

<style></style>
