<template>
  <md-dialog :md-close-on-esc="true" :md-active.sync="isVisible" @md-clicked-outside="close" class="lims-modal">
    <div class="dialog-icon-close" @click="close"><md-icon>close</md-icon></div>
    <md-dialog-title>{{ $t('components/lims/modals/ModalDeleteRole.title') }}</md-dialog-title>
    <div class="dialog-content">
      <p class="text-center">
        {{
          $t('components/lims/modals/ModalDeleteIssue.deleteIssue', {
            issueTitle: deleteIssueName,
          })
        }}
      </p>
    </div>
    <md-dialog-actions>
      <md-button @click="onCancel" class="lims-form-button">
        {{ $t('global/button/button.cancel') }}
      </md-button>
      <md-button @click="onDelete" :disabled="isProcessing" class="md-danger lims-form-button">
        {{ $t('global/button/button.deleteIssue') }}
      </md-button>
    </md-dialog-actions>
  </md-dialog>
</template>

<script>
import modalMixins from './modal.mixins';
import { FORM_MODES } from '@/core/constants';
import { CaseIssueService } from '@/services';
export default {
  mixins: [modalMixins],
  props: {
    formMode: {
      type: Number,
      require: true,
      validator: function (value) {
        // The value must match one of these strings
        return Object.values(FORM_MODES).indexOf(value) !== -1;
      },
    },
    deleteIssueName: {
      type: String,
      required: false,
    },
    caseIssueId: {
      type: String,
      required: false,
    },
  },
  methods: {
    onCancel() {
      this.$emit('onCancel');
    },
    async onDelete() {
      if (this.formMode === FORM_MODES.ADD) {
        this.$emit('onDelete');
      } else {
        if (this.formMode === FORM_MODES.EDIT) {
          this.isProcessing = true;
          const { error, data } = await CaseIssueService.deleteCaseIssue(this.caseIssueId);
          this.isProcessing = false;
          if (error) {
            this.$alertError(error);
          } else {
            if (data) {
              this.$emit('onDelete');
            }
          }
        }
      }
    },
  },
};
</script>

<style lang="scss"></style>
