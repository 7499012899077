/* eslint-disable security/detect-object-injection */
import Vue from 'vue';

// initial state
const state = () => ({
  personalisedReportData: null,
});

// getters
const getters = {
  getPersonalisedReportData: (state) => {
    return state.personalisedReportData ? state.personalisedReportData : null;
  },
};

// actions
const actions = {
  copyPersonalisedReportData({ commit }, personalisedReportData) {
    commit('savePersonalisedReportData', personalisedReportData);
  },
};

// mutations
const mutations = {
  savePersonalisedReportData: (state, personalisedReportData) => {
    Vue.set(state, 'personalisedReportData', personalisedReportData);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
