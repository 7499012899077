<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit, invalid }">
    <form class="md-layout lims-form form-login" @submit.prevent="handleSubmit()">
      <md-dialog :md-close-on-esc="true" :md-active.sync="isVisible" @md-clicked-outside="close" class="lims-modal">
        <div class="dialog-icon-close" @click="close"><md-icon>close</md-icon></div>
        <md-dialog-title>{{ $t('components/ModelSelectUserTypes.modalTitle') }}</md-dialog-title>
        <div class="dialog-content" style="text-align: center">
          <lims-field :model="formData" :schema="schema" field="selectOption" viewMode="only" :validationMode="'eager'">
            <v-select
              slot="field-outside"
              v-if="!entitySelectUser"
              v-model="formData.selectOption"
              :options="$translateSelectOptions(selectOptions)"
              :placeholder="$t('components/ModelSelectUserTypes.placeholderUserType')"
              class="not-item-in-v-select-error"
            >
              <template #option="{ label }">{{ label }}</template>
              <template #selected-option="{ label }">{{ label }}</template>
            </v-select>
            <v-select
              slot="field-outside"
              v-else
              v-model="formData.selectOption"
              :options="$translateSelectOptions(selectOptionsUserEntity)"
              :placeholder="$t('components/ModelSelectUserTypes.placeholderUserType')"
            >
              <template #option="{ label }">{{ label }}</template>
              <template #selected-option="{ label }">{{ label }}</template>
            </v-select>
          </lims-field>
        </div>
        <md-dialog-actions>
          <md-button @click="close" class="lims-form-button">{{ $t('global/button/button.cancel') }}</md-button>
          <md-button @click="onClick" :disabled="invalid" class="lims-form-button md-primary">{{
            $t('global/button/button.continue')
          }}</md-button>
        </md-dialog-actions>
      </md-dialog>
    </form>
  </ValidationObserver>
</template>

<script>
import modalMixins from './modal.mixins';
export default {
  name: 'ModalSelectUserTypes',
  mixins: [modalMixins],
  props: {
    errorMessage: {
      type: String,
      required: false,
      default: '',
    },
    entitySelectUser: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formData: {
        selectOption: '',
      },
    };
  },
  watch: {},
  computed: {
    schema() {
      return {
        entity: 'modals/ModalSelectUserTypes',
        fields: {
          selectOption: 'required',
        },
      };
    },
    selectOptions() {
      const userType = this.$store.getters['auth/userTypeId'];
      if (userType === this.USER_TYPES().ClinicAssociate) {
        return this.USERTYPES_ENUM().filter((e) => e.value === this.USER_TYPES().ClinicAssociate);
      }
      if (userType === this.USER_TYPES().LabTechnician) {
        return this.USERTYPES_ENUM().filter((e) => e.value === this.USER_TYPES().LabTechnician);
      }
      return this.USERTYPES_ENUM();
    },
    selectOptionsUserEntity() {
      return this.USERTYPES_ENUM().filter(
        (e) => e.value === this.USER_TYPES().ClinicAssociate || e.value === this.USER_TYPES().LabTechnician,
      );
    },
  },
  methods: {
    onSelect() {},
    onClick() {
      this.$emit('onSelect', {
        ...this.formData,
      });
    },
    close() {
      this.isVisible = false;
      this.$nextTick(() => {
        this.formData = {
          selectOption: '',
        };
      });
    },
  },
};
</script>

<style lang="scss"></style>
