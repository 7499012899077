<template>
  <form ref="form" class="role-list-filter" v-if="filters">
    <div class="filter-input">
      <md-field class="input-search">
        <md-icon>search</md-icon>
        <md-input
          v-model="filters.search"
          :placeholder="$t('pages/Data/Dropdown/FieldList/FieldListFilter/placeholderSearch')"
          maxlength="250"
        ></md-input>
      </md-field>
    </div>
    <div class="filter-action">
      <md-button @click="onClear">
        {{ $t('global/button/button.clear') }}
      </md-button>
    </div>
  </form>
</template>
<script>
export default {
  mixins: [],
  async created() {
    this.onCreated();
  },
  props: {
    fields: {
      type: Array,
      require: true,
    },
    defaultValues: {
      type: Object,
      require: true,
    },
    value: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {
      filters: null,
    };
  },
  methods: {
    onCreated() {
      this.filters = this.value;
    },
    onClear() {
      this.$nextTick(() => {
        this.$refs.form.reset();
      });
      Object.keys(this.filters).map((k) => {
        // eslint-disable-next-line security/detect-object-injection
        this.filters[k] = this.defaultValues[k];
      });
    },
  },
};
</script>
<style lang="scss">
.role-list-filter {
  .filter-input {
    display: inline-flex;
    @media only screen and (max-width: 959px) {
      display: block;
    }
  }
  .input-search {
    height: 34px;
    align-items: center;
    border: 1px solid rgba(60, 60, 60, 0.26);
    margin: 5px 0 0 5px;
    @media only screen and (max-width: 767px) {
      width: 100%;
      margin: 5px 0;
    }
    .md-icon {
      margin: 0 5px;
      &:after {
        display: none;
      }
    }
    .md-input {
      width: 350px;
      height: 32px;
      padding: 5px;
      margin: 0;
      border: transparent;
      @media only screen and (max-width: 767px) {
        width: calc(100% - 35px);
      }
    }
  }
  .input-search.md-field:after,
  .input-search.md-field:before {
    display: none;
  }
  .filter-action {
    display: flex;
    justify-content: flex-end;
    margin: 20px 0 0;
    .md-button {
      height: 32px;
      margin-left: 10px;
      @media only screen and (max-width: 767px) {
        justify-content: center;
      }
    }
  }
}
</style>
