/* eslint-disable security/detect-object-injection */
import { cloneDeep } from 'lodash';
import { VERIFY_MODE } from '@/core/constants';

const UserNameSchema = {
  validateInformation: {
    entity: 'auth/complete-your-account',
    fields: {
      username: 'required|notValid:validUserNameCheck|unique:completeYourAccountIdentityCheck',
      password: 'required|notValid:validPasswordCheck',
      confirmPassword: 'required|mustEqual:mustEqualConfirmPasswordCheck',
    },
  },
};

const PhoneNumberSchema = {
  validateInformation: {
    entity: 'auth/complete-your-account',
    fields: {
      phoneNumber: 'required|notValid:validMobilePhoneCheck|unique:completeYourAccountIdentityCheck',
      newTelephone: 'required|notValid:validMobilePhoneCheck|unique:usersIdentityCheck',
      newEmail: 'required|email|unique:usersIdentityCheck',
    },
  },
};

const prepareFieldValidation = (schema, field, payload) => {
  return `${schema.validateInformation.fields[field]},${JSON.stringify(payload)}`;
};

const prepareCompleteYourAccountSchema = (ValidateCompleteYourAccountSchema, userId, token, password) => {
  const schema = cloneDeep(ValidateCompleteYourAccountSchema);
  const userIdField = { userId: userId || '' };
  schema.validateInformation.fields.username = prepareFieldValidation(schema, 'username', {
    ...userIdField,
    token: token || '',
    field: 'username',
  });

  schema.validateInformation.fields.phoneNumber = prepareFieldValidation(schema, 'phoneNumber', {
    ...userIdField,
    token: token || '',
    field: 'phoneNumber',
  });

  schema.validateInformation.fields.newTelephone = prepareFieldValidation(schema, 'newTelephone', {
    ...userIdField,
    field: 'newTelephone',
  });

  schema.validateInformation.fields.newEmail = prepareFieldValidation(schema, 'newEmail', {
    ...userIdField,
    field: 'newEmail',
  });

  schema.validateInformation.fields.confirmPassword = prepareFieldValidation(schema, 'confirmPassword', {
    password: password || '',
  });

  // default add mode
  return schema;
};

export const getCompleteYourAccountSchema = (verifyMode, userId, token, password) => {
  if (verifyMode === VERIFY_MODE.USERNAME) {
    return prepareCompleteYourAccountSchema(UserNameSchema, userId, token, password);
  }
  if (verifyMode === VERIFY_MODE.PHONE_NUMBER) {
    return prepareCompleteYourAccountSchema(PhoneNumberSchema, userId, token);
  }
  return null;
};
