var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
return [_c('md-dialog',{staticClass:"lims-modal",attrs:{"md-close-on-esc":true,"md-active":_vm.isVisible},on:{"update:mdActive":function($event){_vm.isVisible=$event},"update:md-active":function($event){_vm.isVisible=$event},"md-clicked-outside":_vm.close}},[_c('div',{staticClass:"dialog-icon-close",on:{"click":_vm.close}},[_c('md-icon',[_vm._v("close")])],1),_c('md-dialog-title',[_vm._v(_vm._s(_vm.$t('components/lims/modals/ModalConfirmation.title')))]),_c('form',{staticClass:"md-layout lims-form",on:{"submit":function($event){$event.preventDefault();return handleSubmit()}}},[_c('p',{staticClass:"text-center",domProps:{"innerHTML":_vm._s(
          _vm.$t('pages/dataset/DatasetManagement/DatasetForm/modals/ModalDeleteConfirmation/txt', {
            dataset: _vm.datasetName,
          })
        )}}),_c('div',{staticStyle:{"margin-bottom":"24px"}},[_c('lims-field',{attrs:{"model":_vm.formData,"schema":_vm.schema,"field":"password"}},[_c('md-input',{ref:"confirmPassword",attrs:{"slot":"field","type":"password"},slot:"field",model:{value:(_vm.formData.password),callback:function ($$v) {_vm.$set(_vm.formData, "password", $$v)},expression:"formData.password"}})],1)],1),_c('md-dialog-actions',[_c('md-button',{staticClass:"lims-form-button",on:{"click":_vm.onCancel}},[_vm._v(" "+_vm._s(_vm.$t('global/button/button.cancel'))+" ")]),_c('md-button',{staticClass:"md-danger lims-form-button",attrs:{"disabled":invalid},on:{"click":_vm.onDelete}},[_vm._v(" "+_vm._s(_vm.$t('global/button/button.delete'))+" ")])],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }