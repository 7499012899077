<template>
  <md-dialog :md-close-on-esc="true" :md-active.sync="isVisible" @md-clicked-outside="close" class="lims-modal">
    <document-view :data-edit="caseFormResource" @openNewWindow="rangesliderVal = 0"></document-view>
  </md-dialog>
</template>

<script>
import DocumentView from '@/pages/Case/CaseManagement/Components/DocumentView';
import modalMixins from './modal.mixins';

export default {
  components: {
    DocumentView,
  },
  mixins: [modalMixins],
  props: {
    caseFormResource: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {
      rangesliderVal: 50,
    };
  },
};
</script>
