<template>
  <form ref="form" class="user-list-filter">
    <div class="filter-input">
      <lims-select
        v-model="filters.action"
        :options="dataSource.actionList"
        :translated="true"
        :placeholder="$t('components/AuditTrailFilter.placeholderAllAction')"
      ></lims-select>
      <lims-select
        v-model="filters.userType"
        :options="dataSource.userTypeList"
        :translated="false"
        :placeholder="$t('global/placeholder.allUserType')"
      ></lims-select>

      <date-picker
        range
        v-model="rangeDate"
        format="DD/MM/YYYY"
        :placeholder="$t('global/placeholder.dateRangePicker')"
        class="audit-trail-date-picker"
      ></date-picker>
    </div>
    <lims-chip-area :options="chipOptions" @onRemoveChip="$onRemoveChip"></lims-chip-area>
    <div class="filter-action">
      <md-button @click="onClear">
        {{ $t('global/button/button.clear') }}
      </md-button>
    </div>
  </form>
</template>
<script>
import { ConfigMixins, ListFilterMixins } from '@/core/mixins';
import LimsChipArea from '@/components/Lims/LimsChipArea.vue';
import LimsSelect from '@/components/Lims/LimsSelect.vue';
import { auditService } from '@/services';

export default {
  mixins: [ConfigMixins, ListFilterMixins],
  components: { LimsChipArea, LimsSelect },
  async created() {
    this.onCreated();
  },
  props: {
    fields: {
      type: Array,
      require: true,
    },
    defaultValues: {
      type: Object,
      require: true,
    },
    value: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {
      filters: null,
      dataSource: {
        actionList: [],
        userTypeList: [],
      },
      chipOptions: [],
      rangeDate: {},
    };
  },
  watch: {
    filters: {
      handler(filterValues) {
        this.$emit('input', { ...filterValues });
        this.$updateChipList({ filterValues, dataSource: this.dataSource, filterFields: this.fields });
      },
      deep: true,
    },
    rangeDate: {
      handler(val) {
        this.filters.fromDateTime = val[0];
        this.filters.toDateTime = val[1];
        this.$emit('input', { ...this.filters });
      },
      deep: true,
    },
  },
  methods: {
    async onCreated() {
      this.filters = this.value;
      this.dataSource.userTypeList = this.loadUserList();
      this.dataSource.actionList = await this.loadActionList();
    },

    async loadActionList() {
      const arrayList = [];
      const { data } = await auditService.getAuditAction();
      data.forEach((item) => {
        arrayList.push({ value: item, label: item });
      });
      return arrayList;
    },

    loadUserList() {
      const USER_TYPE_OPTIONS = this.$appConfig.userTypeOptions;
      return USER_TYPE_OPTIONS;
    },

    onClear() {
      this.$nextTick(() => {
        this.$refs.form.reset();
        this.rangeDate = {};
      });
      Object.keys(this.filters).map((k) => {
        // eslint-disable-next-line security/detect-object-injection
        this.filters[k] = this.defaultValues[k];
      });
    },
  },
};
</script>
<style lang="scss">
.audit-trail-date-picker {
  margin: 5px 0 5px 0 !important;
  width: 215px !important;
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
  .mx-input {
    height: 36px;
  }
}
</style>
