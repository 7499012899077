<template>
  <md-button class="lims-form-button" @click="onCancel">
    {{ $t('global/button/button.cancel') }}
  </md-button>
</template>

<script>
export default {
  props: {
    redirectUrl: {
      type: String,
      required: false,
      default: '', // by default redirect to previous page
    },
  },
  data: () => ({
    showDialog: false,
  }),
  methods: {
    onCancel() {
      if (this.redirectUrl !== '') {
        this.$router.push({ path: this.redirectUrl });
      } else {
        this.$router.back();
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
