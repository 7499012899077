<template>
  <login-card header-color="primary">
    <h4 slot="title" class="title">{{ policy.title }}</h4>
    <div slot="inputs">
      <modal-confirm-generic
        ref="modalPolicyConfirm"
        :title="modalPolicyConfirmTitle"
        :description="modalPolicyConfirmDescription"
        @onConfirm="onConfirm"
      >
      </modal-confirm-generic>
      <iframe class="policy-iframe" :src="policy.src" frameborder="0"></iframe>
      <p class="text-right">
        <md-button v-if="isNeedAcceptance" class="md-button md-primary" @click="onAccept()">{{
          $t('global/button/button.accept')
        }}</md-button>
        <md-button v-if="!isNeedAcceptance" class="md-button md-primary" @click="onAccept()">{{
          $t('global/button/button.ok')
        }}</md-button>
        <md-button v-if="isNeedAcceptance" class="md-button md-danger" @click="onDecline()">{{
          $t('global/button/button.decline')
        }}</md-button>
      </p>
    </div>
  </login-card>
</template>
<script>
import { LoginCard } from '@/components';

import AuthMixins from '@/pages/Auth/auth.mixins';
import authService from '@/services/auth.service';
import { ModalConfirmGeneric } from '@/components/Lims/modals';

export default {
  components: {
    LoginCard,
    ModalConfirmGeneric,
  },

  mixins: [AuthMixins],

  props: {
    policyId: {
      type: Number,
      require: true,
    },
    sessionId: {
      type: String,
      require: true,
    },
    title: {
      type: String,
      require: true,
    },
    isNeedAcceptance: {
      type: Boolean,
      require: true,
    },
  },
  created() {
    const { policyId, sessionId } = this;
    this.loadPolicy({
      policyId,
      sessionId,
    });
  },
  mounted() {},

  data() {
    return {
      policy: {
        title: 'Main Policy',
        src: '',
        // src: 'http://localhost/mock/1.pdf',
        // src: '/api/v1/authentication/23/download?sessionId=adc5c579-64e9-4811-a593-d24f7ec23fd0'
      },
    };
  },
  computed: {
    modalPolicyConfirmTitle() {
      return '';
    },
    modalPolicyConfirmDescription() {
      return this.$t('pages/Auth/ReviewPolicy/ReviewPolicyDetails/modalPolicyConfirm/description', {
        title: this.policy.title,
      });
    },
  },

  watch: {
    policyId() {
      const { policyId, sessionId } = this;
      this.loadPolicy({
        policyId,
        sessionId,
      });
    },
  },

  methods: {
    async loadPolicy({ policyId, sessionId }) {
      const res = await authService.downloadFilePolicyWithSession({
        sessionId,
        policyId,
      });
      // convert blob to url
      const policyURL = URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' }));
      this.policy.title = this.title;
      this.policy.src = policyURL;
    },
    async onAccept() {
      try {
        const { policyId, sessionId, isNeedAcceptance } = this;
        const { data, error } = await authService.acceptPolicy({ policyId, sessionId });
        if (error) {
          throw Error(error);
        }
        // pass data to parent
        this.$emit('onSubmitPolicy', {
          policyId: this.policyId,
          data,
          isAccepted: true,
          isNeedAcceptance,
        });
      } catch (error) {
        this.handleError(error);
      }
    },
    async onDecline() {
      const { isNeedAcceptance } = this;

      if (isNeedAcceptance) {
        this.$refs.modalPolicyConfirm.open();
      }
    },

    async onConfirm() {
      try {
        this.$refs.modalPolicyConfirm.close();
        const { policyId, sessionId, isNeedAcceptance } = this;
        const { data, error } = await authService.declinePolicy({ policyId, sessionId });
        if (error) {
          throw Error(error);
        }

        // pass data to parent
        this.$emit('onSubmitPolicy', {
          policyId,
          data,
          isAccepted: false,
          isNeedAcceptance,
        });
      } catch (error) {
        this.handleError(error);
      }
    },

    handleError(error) {
      this.$emit('onSubmitPolicyError', {
        error,
      });
    },
  },
};
</script>
<style src="./ReviewPolicyDetails.scss" lang="scss" scoped></style>
