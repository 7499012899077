/* eslint-disable security/detect-object-injection */
import { ChartCard } from '@/components';
import {
  NotificationStats,
  CaseListOverViewStats,
  LabCasesStats,
  UnreportedCasesStats,
  ReportedCasesStats,
  ClosedIssuesStats,
  OpenIssuesStats,
  OutstandingALWRequestsStats,
  RespondedALWStats,
  SlidesSentToPathStats,
  UserForValidationStats,
  NewUsersPerMonthChart,
  AverageTurnAroundTimePerDayChart,
  NewEntitiesPerMonthChart,
  DeactivateEntitiesPerMonthChart,
} from '@/pages/Dashboard/Components/index';
import perfectScrollBarMixins from '@/pages/Dashboard/perfectScrollBar.mixins';
import dashboardMixins from '@/pages/Dashboard//dashboard.mixins';
import dashboardService from '@/services/dashboard.service';

export default {
  mixins: [perfectScrollBarMixins, dashboardMixins],
  components: {
    ChartCard,
    NotificationStats,
    CaseListOverViewStats,
    LabCasesStats,
    UnreportedCasesStats,
    ReportedCasesStats,
    ClosedIssuesStats,
    OpenIssuesStats,
    OutstandingALWRequestsStats,
    RespondedALWStats,
    SlidesSentToPathStats,
    UserForValidationStats,
    NewUsersPerMonthChart,
    AverageTurnAroundTimePerDayChart,
    NewEntitiesPerMonthChart,
    DeactivateEntitiesPerMonthChart,
  },
  computed: {
    caseListOverViewStats() {
      const d = new Date();
      let year = d.getFullYear();
      let output = '';
      if (this.statsData.caseListOverview && this.statsData.caseListOverviewCaseReportedPerMonth) {
        const { caseListOverview, caseListOverviewCaseReportedPerMonth } = this.statsData;
        // month details
        let count = 0;
        for (let t in caseListOverviewCaseReportedPerMonth) {
          // eslint-disable-next-line no-prototype-builtins
          if (caseListOverviewCaseReportedPerMonth.hasOwnProperty(t)) {
            count++;
          }
        }
        let lastFourthMonth = 0;
        Object.keys(caseListOverviewCaseReportedPerMonth).map((k) => {
          if (count - lastFourthMonth < 5) {
            output = `${k}: ${caseListOverviewCaseReportedPerMonth[k]}  - ` + output;
          }
          lastFourthMonth++;
        });
        // total
        output += `${year}: ${caseListOverview.ReportedCase}`;
        output = 'Reported cases: ' + output;
      }
      return output;
    },
  },
  created() {},

  async mounted() {
    if (!this.isReady) {
      await this.fetchData();
      this.statsData = await this.fetchStatsData();
      this.isReady = true;
      this.$nextTick(() => {
        this.initPerfectScrollBar();
      });
    }
  },
  data: function () {
    return {
      isReady: false,
      statsData: null,
      newUsersPerMonth: null,
      averageTurnAroundTimePerDay: null,
      newEntitiesPerMonth: null,
      deactivateEntitiesPerMonth: null,
    };
  },
  methods: {
    async fetchStatsData() {
      const { data, error } = await dashboardService.getDashboardAdminStats();
      if (error) {
        this.$alertError(error);
        return null;
      }
      return this.transformStatsData(data);
    },
    async fetchData() {
      this.newUsersPerMonth = await this.fetchNewUsersPerMonthData();
      this.averageTurnAroundTimePerDay = await this.fetchAverageTurnAroundTimePerDay();
      this.newEntitiesPerMonth = await this.fetchNewEntitiesPerMonth();
      this.deactivateEntitiesPerMonth = await this.fetchDeactivatedEntitiesPerMonth();
    },
    async fetchNewUsersPerMonthData() {
      if (!this.$isAuthorized(['Accounts_UserManagement_Retrieve'])) {
        return null;
      }
      const { data, error } = await dashboardService.getNewUsersPerMonth();
      if (error) {
        this.$alertError(error);
        return null;
      }
      return data;
    },
    async fetchAverageTurnAroundTimePerDay() {
      if (!this.$isAuthorized(['Case_CaseForm_Update'])) {
        return null;
      }
      const { data, error } = await dashboardService.getAverageTurnAround();
      if (error) {
        this.$alertError(error);
        return null;
      }
      return data;
    },
    async fetchNewEntitiesPerMonth() {
      if (!this.$isAuthorized(['Accounts_EntityManagement_Retrieve'])) {
        return null;
      }
      const { data, error } = await dashboardService.getNewEntitiesPerMonth();
      if (error) {
        this.$alertError(error);
        return null;
      }
      return data;
    },
    async fetchDeactivatedEntitiesPerMonth() {
      if (!this.$isAuthorized(['Accounts_EntityManagement_Retrieve'])) {
        return null;
      }
      const { data, error } = await dashboardService.getDeactivatedEntitiesPerMonth();
      if (error) {
        this.$alertError(error);
        return null;
      }
      return data;
    },
  },
};
