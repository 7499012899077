<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit }">
    <form class="md-layout lims-form" @submit.prevent="handleSubmit()" :key="localKey">
      <anchor-system :isReportedStatusPathView="true"></anchor-system>
      <!-- Begin patient data block -->
      <lims-block id="patientDataBlock" class="patient-data-block">
        <h4 class="title" slot="blockTitle">{{ $t('pages/case/form/patientData/blockTitle') }}</h4>
        <modal-retrieve slot="blockTitle" ref="retrieveModal" @onRetrieve="onSubmitRetrieve"></modal-retrieve>
        <modal-recall-to-path
          slot="blockTitle"
          ref="reCallToPathModal"
          @onRecallToPath="onSubmitRecallToPath"
        ></modal-recall-to-path>
        <lims-collapse
          :is-collapsed="true"
          slot="blockContent"
          :collapse="['Case Collapse']"
          icon="keyboard_arrow_down"
          :expand-collapse-block-name="'patientDataBlock'"
        >
          <patient-data-view
            slot="md-collapse-pane-1"
            :form-mode="formMode"
            :dataEdit="caseFormResource"
          ></patient-data-view>
        </lims-collapse>
      </lims-block>
      <!-- End patient data block -->
      <!-- Begin document viewer and request view information -->
      <document-viewer-and-request-view-information
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
        :case-form-resource="caseFormResource"
        :form-mode="formMode"
        @changedShowDocumentViewer="isShowDocumentViewer = $event"
      ></document-viewer-and-request-view-information>
      <!-- End document viewer and request view information -->
      <!-- Begin billing clinic and laboratory block -->
      <lims-block id="billingClinicAndLaboratoryBlock" v-show="!isShowDocumentViewer">
        <h4 class="title" slot="blockTitle">{{ $t('pages/case/form/billingClinicAndLaboratory/blockTitle') }}</h4>
        <lims-collapse
          :is-collapsed="true"
          slot="blockContent"
          :collapse="['Case Collapse']"
          icon="keyboard_arrow_down"
          :expand-collapse-block-name="'billingClinicAndLaboratoryBlock'"
        >
          <div slot="md-collapse-pane-1">
            <clinic-and-laboratory-view
              :form-mode="formMode"
              :data-edit="caseFormResource"
            ></clinic-and-laboratory-view>
            <billing-data-view :form-mode="formMode" :data-edit="caseFormResource"></billing-data-view>
          </div>
        </lims-collapse>
      </lims-block>
      <!-- End billing clinic and laboratory block -->
      <!-- Begin specimen, pathologist and document block  -->
      <lims-block id="specimenPathologist" v-show="!isShowDocumentViewer">
        <h4 class="title" slot="blockTitle">{{ $t('pages/case/form/specimenPathologistAndDocument/blockTitle') }}</h4>
        <lims-collapse
          :is-collapsed="true"
          slot="blockContent"
          :collapse="['Case Collapse']"
          icon="keyboard_arrow_down"
          :expand-collapse-block-name="'specimenPathologist'"
        >
          <div slot="md-collapse-pane-1">
            <specimen-detail-view
              v-model="formData.specimenDetail"
              :form-mode="formMode"
              :dataEdit="caseFormResource"
            ></specimen-detail-view>
            <pathologist-view
              v-model="formData.pathologist"
              :form-mode="formMode"
              :dataEdit="caseFormResource"
            ></pathologist-view>
            <document :form-mode="formMode" :dataEdit="caseFormResource" :is-reported-status="true"></document>
          </div>
        </lims-collapse>
      </lims-block>
      <!-- End specimen, pathologist and document block -->
      <!-- Begin diagnosis histories block -->
      <lims-block
        id="diagnosisHistoryBlock"
        class="diagnosis-block"
        v-show="!isShowDocumentViewer"
        v-if="
          formMode !== ADD_MODE &&
          caseFormResource.caseReportHistories &&
          caseFormResource.caseReportHistories.length > 0
        "
      >
        <h4 class="title" slot="blockTitle">{{ $t('pages/case/form/diagnosis/blockTitle') }}</h4>
        <lims-collapse slot="blockContent" :collapse="['Case Collapse']" icon="keyboard_arrow_down">
          <diagnosis-histories
            slot="md-collapse-pane-1"
            :data-edit="caseFormResource"
            :form-mode="formMode"
          ></diagnosis-histories>
        </lims-collapse>
      </lims-block>
      <!-- End diagnosis histories block -->
      <!-- Begin diagnosis view block -->
      <lims-block
        id="diagnosisViewBlock"
        class="diagnosis-block"
        v-if="formMode !== ADD_MODE"
        v-show="!isShowDocumentViewer"
      >
        <h4 class="title" slot="blockTitle">{{ $t('pages/case/form/diagnosis/blockTitle') }}</h4>
        <lims-collapse
          :is-collapsed="isAdminView && !isProvisionallyReportedStatus"
          slot="blockContent"
          :collapse="['Case Collapse']"
          icon="keyboard_arrow_down"
          :expand-collapse-block-name="'diagnosisBlock'"
        >
          <diagnosis-view
            slot="md-collapse-pane-1"
            v-model="formData.diagnosis"
            :form-mode="formMode"
            :data-edit="caseFormResource"
            :is-reported-status-and-path-view="true"
          ></diagnosis-view>
        </lims-collapse>
      </lims-block>
      <!-- End diagnosis view block -->
      <!-- Begin double reporting block -->
      <lims-block id="doubleReportingBlock" v-if="isShowDoubleReport()" v-show="!isShowDocumentViewer">
        <h4 class="title" slot="blockTitle">{{ $t('pages/case/form/doubleReporting/blockTitle') }}</h4>
        <lims-collapse
          slot="blockContent"
          :collapse="['Case Collapse']"
          icon="keyboard_arrow_down"
          :expand-collapse-block-name="'doubleReportingBlock'"
        >
          <double-reporting
            slot="md-collapse-pane-1"
            v-model="formData.caseDoubleReportResponses"
            :form-mode="formMode"
            :dataEdit="caseFormResource"
          ></double-reporting>
        </lims-collapse>
      </lims-block>
      <!-- end double reporting block -->
      <!-- ALW block  -->
      <lims-block id="ALWBlock" v-show="!isShowDocumentViewer">
        <h4 class="title" slot="blockTitle">
          {{ $t('pages/case/form/ALW/blockTitle') + ' (' + totalNumberOfRequestsALW + ')' }}
        </h4>
        <lims-collapse
          slot="blockContent"
          :collapse="['Case Collapse']"
          icon="keyboard_arrow_down"
          :expand-collapse-block-name="'ALWBlock'"
        >
          <div slot="md-collapse-pane-1">
            <additional-lab-work
              :form-mode="formMode"
              :dataEdit="caseFormResource"
              @totalNumberOfRequests="getTotalNumberOfRequestsALW"
            ></additional-lab-work>
          </div>
        </lims-collapse>
      </lims-block>
      <!-- SO block  -->
      <lims-block id="SOBlock" v-show="!isShowDocumentViewer">
        <h4 class="title" slot="blockTitle">
          {{ $t('pages/case/form/SO/blockTitle') + ' (' + totalNumberOfRequestsSO + ')' }}
        </h4>
        <lims-collapse
          slot="blockContent"
          :collapse="['Case Collapse']"
          icon="keyboard_arrow_down"
          :expand-collapse-block-name="'SOBlock'"
        >
          <div slot="md-collapse-pane-1">
            <second-opinion
              :form-mode="formMode"
              :dataEdit="caseFormResource"
              @totalNumberOfRequests="getTotalNumberOfRequestsSO"
            ></second-opinion>
          </div>
        </lims-collapse>
      </lims-block>
      <!-- Begin further options block -->
      <lims-block id="furtherOptionBlock" v-show="!isShowDocumentViewer">
        <h4 class="title" slot="blockTitle">{{ $t('pages/case/form/furtherOptions/blockTitle') }}</h4>
        <lims-collapse
          slot="blockContent"
          :collapse="['Case Collapse']"
          icon="keyboard_arrow_down"
          :expand-collapse-block-name="'furtherOptionBlock'"
        >
          <further-options
            slot="md-collapse-pane-1"
            v-model="formData.furtherOptions"
            :form-mode="formMode"
            :dataEdit="caseFormResource"
            :isPartnerDoubleReport="isPartnerDoubleReport"
          ></further-options>
        </lims-collapse>
      </lims-block>
      <!-- End further options block -->
      <!-- Begin issue log block -->
      <lims-block id="issueLogBlock" v-show="!isShowDocumentViewer">
        <h4 class="title" slot="blockTitle">{{ $t('pages/case/form/issueLog/blockTitle') }}</h4>
        <lims-collapse
          slot="blockContent"
          :collapse="['Case Collapse']"
          icon="keyboard_arrow_down"
          :expand-collapse-block-name="'issueLogBlock'"
        >
          <issue-log
            slot="md-collapse-pane-1"
            :form-mode="formMode"
            :data-edit="caseFormResource"
            :isPartnerDoubleReport="isPartnerDoubleReport"
          ></issue-log>
        </lims-collapse>
      </lims-block>
      <!-- End issue log block -->
      <!-- Begin action form -->
      <div id="formButton" class="md-layout lims-form-row">
        <div class="md-layout-item md-size-100 md-small-size-100 lims-form-actions">
          <md-button @click="onBack()" class="lims-form-button">
            {{ $t('global/button/button.back') }}
          </md-button>
          <LimsFormEditButton v-if="formMode === VIEW_MODE && caseFormResource.isUpdatePermission" :editUrl="editUrl" />
          <template v-if="formMode !== VIEW_MODE && !isPartnerDoubleReport">
            <md-button
              v-if="$isAuthorized(['Case_CaseReport_ExportPDF'])"
              @click="onExportAsPdf()"
              :disabled="isProcessingExportPdf"
              class="md-button md-primary lims-form-button md-theme-default"
            >
              {{ $t('global/button/button.exportAsPdf') }}
            </md-button>
            <md-button
              @click="onSaveCase()"
              :disabled="isProcessingSave"
              class="md-button md-primary lims-form-button md-theme-default"
            >
              {{ $t('global/button/button.save') }}
            </md-button>
            <md-button
              @click="onRetrieve()"
              :disabled="autoRetrieveDSFailed_Retrive"
              class="md-button md-retrieve-highlight lims-form-button md-theme-default"
            >
              {{ $t('global/button/button.retrieve') }}
            </md-button>
            <md-button
              @click="onRecallToPath()"
              :disabled="autoRetrieveDSFailed_CancelDiagnosis"
              class="md-button md-danger lims-form-button md-theme-default"
            >
              {{ $t('global/button/button.recallToPath') }}
            </md-button>
          </template>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import { FormMixins, TabMixins } from '@/core/mixins';
import CaseEditMixins from '@/pages/Case/CaseManagement/Edit/caseEdit.mixins';
import { APP_ROUTES, FORM_MODES } from '@/core/constants';
import LimsBlock from '@/components/Lims/LimsBlock.vue';
import SecondOpinion from '@/pages/Case/CaseManagement/Components/SecondOpinion.vue';
import FurtherOptions from '@/pages/Case/CaseManagement/Components/FurtherOptions';
import IssueLog from '@/pages/Case/CaseManagement/Components/IssueLog';
import LimsCollapse from '@/components/Lims/LimsCollapse';
import DiagnosisView from '@/pages/Case/CaseManagement/Components/DiagnosisView';
import PatientDataView from '@/pages/Case/CaseManagement/Components/PatientDataView';
import BillingDataView from '@/pages/Case/CaseManagement/Components/BillingDataView';
import ClinicAndLaboratoryView from '@/pages/Case/CaseManagement/Components/ClinicAndLaboratoryView';
import PathologistView from '@/pages/Case/CaseManagement/Components/PathologistView';
import SpecimenDetailView from '@/pages/Case/CaseManagement/Components/SpecimenDetailView';
import Document from '@/pages/Case/CaseManagement/Components/Document';
import AdditionalLabWork from '@/pages/Case/CaseManagement/Components/AdditionalLabWork';
import DoubleReporting from '@/pages/Case/CaseManagement/Components/DoubleReporting.vue';
import ModalRecallToPath from '@/components/Lims/modals/ModalRecallToPath';
import ModalRetrieve from '@/components/Lims/modals/ModalRetrieve';
import caseSaveMixins, { CASE_VIEW_TYPES } from './caseSave.mixins';
import { mapGetters } from 'vuex';
import DiagnosisHistories from '@/pages/Case/CaseManagement/Components/DiagnosisHistories';
import DocumentViewerAndRequestViewInformation from '@/pages/Case/CaseManagement/Components/DocumentViewerAndRequestViewInformation';

export default {
  mixins: [FormMixins, CaseEditMixins, TabMixins, caseSaveMixins],
  components: {
    DiagnosisHistories,
    ModalRetrieve,
    ModalRecallToPath,
    AdditionalLabWork,
    Document,
    SpecimenDetailView,
    PathologistView,
    ClinicAndLaboratoryView,
    BillingDataView,
    PatientDataView,
    DiagnosisView,
    DoubleReporting,
    LimsCollapse,
    SecondOpinion,
    FurtherOptions,
    IssueLog,
    LimsBlock,
    DocumentViewerAndRequestViewInformation,
  },
  props: {
    formMode: {
      type: Number,
      require: true,
      validator: function (value) {
        // The value must match one of these strings
        return Object.values(FORM_MODES).indexOf(value) !== -1;
      },
    },
    caseFormResource: {
      type: Object,
      require: false,
    },
    caseStatus: {
      type: Number,
      require: false,
    },
  },
  data() {
    return {
      viewType: CASE_VIEW_TYPES.REPORTED_STATUS_PATH,
      formData: {
        patientData: {},
        billingData: {},
        clinicAndLaboratory: {},
        specimenDetail: {},
        specimenNumber: null,
        furtherOptions: {},
        pathologist: {},
        diagnosis: {},
      },
      dataForm: {},
      totalNumberOfRequestsALW: 0,
      totalNumberOfRequestsSO: 0,
      localKey: 0,
      isShowDocumentViewer: false,
    };
  },
  computed: {
    ...mapGetters('auth', ['userId']),
    isAdminView() {
      const userType = this.$store.getters['auth/userType'];
      return userType === this.USER_TYPES().Administrator;
    },
    isProvisionallyReportedStatus() {
      return true;
      // return this.caseStatus === CASE_STATUS.PROVISIONALLY_REPORTED;
    },
    editUrl() {
      return APP_ROUTES.CASE_EDIT + '/' + this.caseFormResource.caseId;
    },
    isPartnerDoubleReport() {
      if (
        !Reflect.has(this.caseFormResource, 'caseDoubleReportResponses') ||
        this.caseFormResource.caseDoubleReportResponses.length === 0
      ) {
        return false;
      }
      const caseDoubleReportData = this.caseFormResource.caseDoubleReportResponses[0];
      return caseDoubleReportData.statusName == 'Pending' && caseDoubleReportData.partnerId == this.userId;
    },
  },
  watch: {
    caseFormResource() {
      this.localKey += 1;
    },
  },
  methods: {
    getTotalNumberOfRequestsALW(val) {
      this.totalNumberOfRequestsALW = val;
    },
    getTotalNumberOfRequestsSO(val) {
      this.totalNumberOfRequestsSO = val;
    },
    isShowDoubleReport() {
      const caseDoubleReportData = this.caseFormResource.caseDoubleReportResponses[0];
      if (caseDoubleReportData) {
        return (
          (caseDoubleReportData.statusName == 'Pending' && caseDoubleReportData.partnerId == this.userId) ||
          caseDoubleReportData.statusName != 'Pending'
        );
      } else {
        return false;
      }
    },

    onBack() {
      this.$router.back();
    },
  },
};
</script>
<style lang="scss"></style>
