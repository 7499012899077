<template>
  <div>
    <modal-cancel-so-request ref="cancelSoRequest" @onCancelSORequest="onCancelSORequest"></modal-cancel-so-request>
    <template>
      <div class="md-layout lims-form-row">
        <div class="md-layout-item md-size-100 md-small-size-100">
          <label>{{ $t('pages/case/CaseManagement/components/SO/caseBrief') }}</label>
          <div class="pd-top-10">{{ caseBrief }}</div>
        </div>
        <div class="md-layout-item md-size-100 md-small-size-100 pd-top-10">
          <div class="textarea-field">
            <label>{{ $t('pages/case/CaseManagement/components/SO/secondOpinion') }}</label>
            <md-field>
              <md-textarea v-model="respondeComment" :disabled="dataEdit.isDeleted"></md-textarea>
            </md-field>
          </div>
        </div>
      </div>
      <div v-if="!dataEdit.isDeleted" class="md-layout lims-form-row">
        <div class="md-layout-item md-size-100 md-small-size-100 lims-form-actions case-block-action">
          <md-button @click="onClear()" class="lims-form-button">
            {{ $t('global/button/button.clear') }}
          </md-button>
          <md-button
            :disabled="!respondeComment || isProcessingSubmit"
            @click="onsubmit()"
            class="md-button md-primary lims-form-button md-theme-default"
          >
            {{ $t('global/button/button.submit') }}
          </md-button>
          <md-button @click="onCancelRequest()" class="md-button md-danger lims-form-button md-theme-default">
            {{ $t('global/button/button.cancelRequest') }}
          </md-button>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { ModalCancelSoRequest } from '@/components/Lims/modals';
import { pathCaseService } from '@/services';
import { ALW_SO_STATUS, APP_ROUTES } from '@/core/constants';
import { mapGetters } from 'vuex';

export default {
  components: {
    ModalCancelSoRequest,
  },
  props: {
    dataEdit: {
      type: Object,
      require: false,
    },
    isBulkDiagnosis: {
      type: Boolean,
      require: false,
    },
  },
  created() {
    this.getCaseSORequestedId();
  },
  data() {
    return {
      caseBrief: '',
      respondeComment: '',
      isProcessingSubmit: false,
    };
  },
  computed: {
    ...mapGetters('auth', ['userId']),
    STATUSES: function () {
      return ALW_SO_STATUS;
    },
  },
  methods: {
    onClear() {
      this.respondeComment = '';
    },
    getCaseSORequestedId() {
      this.dataEdit.caseSORequests.forEach((caseSO) => {
        if (caseSO.status == this.STATUSES.REQUESTED) {
          //get caseSoRequestId
          const pathUser = caseSO.pathologistUsers.filter(
            (i) => i.pathId == this.userId && i.status == this.STATUSES.REQUESTED,
          );
          if (pathUser.length > 0) {
            this.caseSoRequestId = pathUser[0].caseSORequestId;
            if (caseSO.pathologistUsers.includes(pathUser[0])) {
              this.caseBrief = caseSO.caseBrief;
            }
          }
        }
      });
    },
    async onsubmit() {
      this.isProcessingSubmit = true;
      try {
        const res = await pathCaseService.submitSORequest(this.caseSoRequestId, this.respondeComment);
        this.isProcessingSubmit = false;
        if (res.err) {
          return this.$alertError(res.err + ' error');
        } else {
          this.$alertSuccess(
            this.$t('pages/case/CaseManagement/components/SO/alert.submitSuccess', {
              caseRef: this.dataEdit.caseReference,
            }),
          );
          if (this.isBulkDiagnosis) {
            this.$emit('updatedCaseInDiagnosisView', this.dataEdit?.caseId);
          } else {
            setTimeout(async () => {
              await this.$router.push(APP_ROUTES.CASE);
            }, 1200);
          }
        }
      } catch (errors) {
        this.isProcessingSubmit = false;
        this.$alertError(errors);
      }
    },
    onCancelRequest() {
      this.$refs.cancelSoRequest.open();
    },
    async onCancelSORequest(cancelSORequestData) {
      try {
        const res = await pathCaseService.cancelSORequest(this.caseSoRequestId, cancelSORequestData.reason);
        this.$refs.cancelSoRequest.isProcessingStatus(false);
        if (res.err) {
          return this.$alertError(res.err + ' error');
        } else {
          this.$alertSuccess(
            this.$t('pages/case/CaseManagement/components/SO/error.cancelSuccess', {
              caseRef: this.dataEdit.caseReference,
            }),
          );
          if (this.isBulkDiagnosis) {
            this.$emit('updatedCaseInDiagnosisView', this.dataEdit?.caseId);
          } else {
            setTimeout(async () => {
              await this.$router.push(APP_ROUTES.CASE);
            }, 1200);
          }
        }
      } catch (errors) {
        this.$alertError(errors);
      }
    },
  },
};
</script>
<style lang="scss">
.strikethrough .md-table-cell {
  text-decoration: line-through;
  &.status-column {
    text-decoration: unset;
  }
}
</style>
