import httpClient from './httpClient';
import { NUMBER_OF_CHARACTERS_SEARCH } from '@/core/constants';
const RESOURCE = 'entity';

export default {
  find(params = {}) {
    return httpClient.get(`/${RESOURCE}`, params);
  },
  findOne(entityId) {
    return httpClient.get(`/${RESOURCE}/${entityId}`);
  },
  create(data = {}) {
    return httpClient.post(`/${RESOURCE}`, data);
  },
  updateOne(payload) {
    const { entityId, ...data } = payload;
    return httpClient.put(`/${RESOURCE}/${entityId}`, data);
  },

  entityCheckUnique({ value, field, entityId }) {
    const params = {};
    params[`${field}`] = value;
    if (entityId) {
      params['entityId'] = entityId;
    }
    // {isExisted: false/true}
    return httpClient.get(`/${RESOURCE}/check-unique`, params);
  },

  findClinicPreferredPathologist({ search, entityId }) {
    const params = {};
    if (search) {
      params['search'] = search;
    }
    if (entityId) {
      params['entityId'] = entityId;
    }
    return httpClient.get(`/${RESOURCE}/clinic-preferred-pathologist`, params);
  },

  findClinicRestrictedPathologist({ search, entityId }) {
    const params = {};
    if (search) {
      params['search'] = search;
    }
    if (entityId) {
      params['entityId'] = entityId;
    }
    return httpClient.get(`/${RESOURCE}/clinic-restricted-pathologist`, params);
  },

  findLabRestrictedClinician({ search, entityId }) {
    const params = {};
    if (search) {
      params['search'] = search;
    }
    if (entityId) {
      params['entityId'] = entityId;
    }
    return httpClient.get(`/${RESOURCE}/lab-restricted-clinician`, params);
  },

  findLabRestrictedClinic({ search, entityId }) {
    const params = {};
    if (search) {
      params['search'] = search;
    }
    if (entityId) {
      params['entityId'] = entityId;
    }
    return httpClient.get(`/${RESOURCE}/lab-restricted-clinic`, params);
  },

  findLabRestrictedPathologist({ search, entityId }) {
    const params = {};
    if (search) {
      params['search'] = search;
    }
    if (entityId) {
      params['entityId'] = entityId;
    }
    return httpClient.get(`/${RESOURCE}/lab-restricted-pathologist`, params);
  },

  getDisplayOption() {
    return httpClient.get(`/${RESOURCE}/entity-option`);
  },

  enable({ entityId }) {
    return httpClient.patch(`/${RESOURCE}/enable/${entityId}`);
  },

  disable({ entityId, password }) {
    return httpClient.patch(`/${RESOURCE}/disable/${entityId}`, {
      password,
    });
  },

  search({ filters, pagination, sort }) {
    const { entityTypeIds, clinicTypeIds, entityStatusIds, search } = filters;
    const { pageNumber, pageSize } = pagination;

    const entityQueryParams = {};
    if (entityTypeIds) {
      entityQueryParams['Filters.EntityTypes'] = entityTypeIds;
    }
    if (clinicTypeIds) {
      entityQueryParams['Filters.ClinicTypes'] = clinicTypeIds;
    }
    if (entityStatusIds) {
      entityQueryParams['Filters.EntityStatuses'] = entityStatusIds;
    }
    if (search && search.length >= NUMBER_OF_CHARACTERS_SEARCH) {
      entityQueryParams['Filters.Search'] = search;
    }
    if (pageNumber) {
      entityQueryParams['PageNumber'] = pageNumber;
    }
    if (pageSize) {
      entityQueryParams['PageSize'] = pageSize;
    }
    if (sort) {
      entityQueryParams['Sort'] = sort;
    }
    return httpClient.get(`/${RESOURCE}/`, entityQueryParams);
  },
  getEntityBilling(entityId) {
    return httpClient.get(`/${RESOURCE}/get-entity-billing/${entityId}`);
  },
  getEntityByEntityTypes(entityTypes) {
    return httpClient.get(`/${RESOURCE}/get-entities-by-entitytypes`, {
      entityTypes: entityTypes,
    });
  },

  getBlockNamingRuleSetting(laboratoryId) {
    return httpClient.get(`/${RESOURCE}/lab/block-display-option/${laboratoryId}`);
  },

  checkHasCustomSnomed(entityId) {
    return httpClient.get(`/${RESOURCE}/${entityId}/has-custom-snomed`);
  },
};
