<template>
  <div>
    <lims-input-strength-field
      :validated="beValidPasswordLength"
      :description="$t('component/lims/passwordStrength/numberCharacters')"
    ></lims-input-strength-field>
    <lims-input-strength-field
      :validated="haveAtLeastOneUpperCase"
      :description="$t('component/lims/passwordStrength/leastUppercase')"
    ></lims-input-strength-field>
    <lims-input-strength-field
      :validated="haveAtLeastOneLowerCase"
      :description="$t('component/lims/passwordStrength/leastLowercase')"
    ></lims-input-strength-field>
    <lims-input-strength-field
      :validated="haveAtLeastOneSpecialCharacter"
      :description="$t('component/lims/passwordStrength/specialCharacter')"
    ></lims-input-strength-field>
    <lims-input-strength-field
      :validated="haveAtLeastOneNumber"
      :description="$t('component/lims/passwordStrength/leastNumber')"
    ></lims-input-strength-field>
  </div>
</template>

<script>
import LimsInputStrengthField from '@/components/Lims/LimsInputStrengthField';

export default {
  components: { LimsInputStrengthField },
  props: {
    passwordString: {
      type: String,
      required: false,
      default: null,
    },
  },

  computed: {
    beValidPasswordLength() {
      // PASSWORD LENGTH : 8-99
      let regex = /^.{8,99}$/;
      return this.passwordString ? regex.test(this.passwordString) : false;
    },

    haveAtLeastOneUpperCase() {
      let regex = /^.*[A-Z].*$/;
      return this.passwordString ? regex.test(this.passwordString) : false;
    },

    haveAtLeastOneLowerCase() {
      let regex = /^.*[a-z].*$/;
      return this.passwordString ? Boolean(this.passwordString.match(regex)) : false;
    },

    haveAtLeastOneSpecialCharacter() {
      let regex = /^.*[~!@#$%^&*()_+{}|:"?><;'=,./\[\]\\\-].*$/; //eslint-disable-line
      return this.passwordString ? Boolean(this.passwordString.match(regex)) : false;
    },

    haveAtLeastOneNumber() {
      let regex = /^.*[0-9].*$/;
      return this.passwordString ? Boolean(this.passwordString.match(regex)) : false;
    },
  },
};
</script>
<style lang="scss" scoped>
.special-character {
  margin: 0;
  padding-left: 26px;
}
</style>
