<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit }">
    <form class="md-layout lims-form" @submit.prevent="handleSubmit()">
      <modal-confirmation ref="confirmModal" @onConfirm="$onConfirmDisabled" :typeEntity="true"></modal-confirmation>
      <!-- begin information block -->
      <lims-block>
        <h4 class="title" slot="blockTitle">{{ $t('pages/entity/forms/blockTitle.Information') }}</h4>
        <div slot="blockContent">
          <LimsFormEditButton class="edit-btn" v-if="isViewMode && $canEdit()" :editUrl="$editUrl" />
          <!-- new row -->
          <div class="md-layout lims-form-row">
            <div class="md-layout-item md-size-25 md-small-size-100" v-if="isDefaultView">
              <lims-field :model="formData.information" :schema="entitySchema.information" field="entityTypeId">
                <lims-single-select
                  slot="field"
                  v-model="formData.information.entityTypeId"
                  :options="entityTypeList"
                  disabled
                  :translated="false"
                  tabenable="yes"
                ></lims-single-select>
              </lims-field>
            </div>
            <div class="md-layout-item md-size-25 md-small-size-100">
              <lims-field :model="formData.information" :schema="entitySchema.information" field="clinicTypeId">
                <lims-single-select
                  slot="field"
                  v-model="formData.information.clinicTypeId"
                  :options="clinicTypes"
                  :placeholder="$t('pages/entity/forms/placeholder.clinicType')"
                  :disabled="isViewMode || isMyEntityView"
                  tabenable="yes"
                ></lims-single-select>
              </lims-field>
            </div>
          </div>
          <!-- end row -->
          <!-- begin row -->
          <div class="md-layout lims-form-row">
            <div class="md-layout-item md-size-50 md-small-size-100">
              <lims-field :model="formData.information" :schema="entitySchema.information" field="entityName">
                <md-input
                  slot="field"
                  v-model="formData.information.entityName"
                  type="text"
                  :disabled="isViewMode"
                  maxlength="250"
                  @change="$onServerSideValidation($event, entitySchema._serverSideValidators.entityName)"
                  tabenable="yes"
                >
                </md-input>
              </lims-field>
            </div>
            <!-- <div class="md-layout-item md-size-25 md-small-size-100" v-if="isDefaultView">
              <lims-field
                :model="formData.information"
                :schema="entitySchema.information"
                field="clinicBillingReference"
              >
                <md-input
                  slot="field"
                  v-model="formData.information.clinicBillingReference"
                  type="text"
                  maxlength="100"
                  :disabled="isViewMode"
                  @change="$onServerSideValidation($event, entitySchema._serverSideValidators.clinicBillingReference)"
                  tabenable="yes"
                >
                </md-input>
              </lims-field>
            </div> -->
          </div>
          <!-- end row -->
          <!-- new row -->
          <div class="md-layout lims-form-row">
            <div class="md-layout-item md-size-100">
              <lims-field
                :model="formData.information"
                :schema="entitySchema.information"
                field="address1"
                class="textarea-field"
              >
                <md-textarea
                  slot="field"
                  v-model="formData.information.address1"
                  :disabled="isViewMode"
                  tabenable="yes"
                ></md-textarea>
              </lims-field>
              <lims-field
                :model="formData.information"
                :schema="entitySchema.information"
                field="address2"
                class="textarea-field"
              >
                <md-textarea
                  slot="field"
                  v-model="formData.information.address2"
                  :disabled="isViewMode"
                  tabenable="yes"
                ></md-textarea>
              </lims-field>
            </div>
          </div>
          <!-- end row -->
          <!-- new row -->
          <div class="md-layout lims-form-row">
            <div class="md-layout-item md-size-25 md-small-size-100">
              <lims-field :model="formData.information" :schema="entitySchema.information" field="town">
                <md-input
                  slot="field"
                  v-model="formData.information.town"
                  type="text"
                  maxlength="100"
                  :disabled="isViewMode"
                  tabenable="yes"
                ></md-input>
              </lims-field>
            </div>
            <div class="md-layout-item md-size-25 md-small-size-100">
              <lims-field :model="formData.information" :schema="entitySchema.information" field="countryId">
                <lims-single-select
                  slot="field"
                  v-model="formData.information.countryId"
                  :options="countries"
                  :disabled="isViewMode"
                  tabenable="yes"
                ></lims-single-select>
              </lims-field>
            </div>
            <div class="md-layout-item md-size-25 md-small-size-100">
              <lims-field :model="formData.information" :schema="entitySchema.information" field="postalCode">
                <md-input
                  slot="field"
                  v-model="formData.information.postalCode"
                  type="text"
                  maxlength="20"
                  :disabled="isViewMode"
                  tabenable="yes"
                ></md-input>
              </lims-field>
            </div>
          </div>
          <!-- end row -->
          <!-- new row -->
          <div class="md-layout lims-form-row">
            <div class="md-layout-item md-size-50 md-small-size-100">
              <lims-field :model="formData.information" :schema="entitySchema.information" field="email">
                <md-input
                  slot="field"
                  v-model="formData.information.email"
                  type="text"
                  @change="$lowercase(formData.information, 'email')"
                  maxlength="200"
                  :disabled="isViewMode"
                  tabenable="yes"
                ></md-input>
              </lims-field>
            </div>
            <div class="md-layout-item md-size-25 md-small-size-100">
              <lims-field :model="formData.information" :schema="entitySchema.information" field="telephoneOffice">
                <md-input
                  slot="field"
                  v-model="formData.information.telephoneOffice"
                  :placeholder="$t('global/placeholder.telephone')"
                  type="text"
                  maxlength="15"
                  :disabled="isViewMode"
                  tabenable="yes"
                ></md-input>
              </lims-field>
            </div>
          </div>
          <!-- end row -->
          <!-- new row -->
          <div class="md-layout lims-form-row">
            <div class="md-layout-item md-size-50 md-small-size-100"></div>
            <div class="md-layout-item md-size-25 md-small-size-100" v-if="isDefaultView">
              <lims-field :model="formData.information" :schema="entitySchema.information" field="billingFrequency">
                <lims-single-select
                  slot="field"
                  v-model="formData.information.billingFrequencyId"
                  :options="billingFrequencyList"
                  :disabled="isViewMode"
                  tabenable="yes"
                ></lims-single-select>
              </lims-field>
            </div>
          </div>
          <!-- end row -->
          <!-- new row -->
          <div class="md-layout lims-form-row">
            <div class="md-layout-item md-size-25 md-small-size-100" v-if="isDefaultView">
              <md-checkbox
                :disabled="isViewMode"
                v-model="formData.information.canProcessManagerDataset"
                class="lims-checkbox"
                tabenable="yes"
              >
                {{ $t('entities/entity/information/canProcessManagerDataset') }}
              </md-checkbox>
            </div>
            <div class="md-layout-item md-size-25 md-small-size-100" v-if="isDefaultView">
              <md-checkbox
                :disabled="isViewMode"
                v-model="formData.information.canAllowCustomSnomeds"
                class="lims-checkbox"
                tabenable="yes"
              >
                {{ $t('entities/entity/information/customSnomeds') }}
              </md-checkbox>
            </div>
          </div>
          <!-- begin row -->
          <div class="md-layout lims-form-row" v-if="isDefaultView">
            <div class="md-layout-item md-size-100 md-small-size-100">
              <lims-field
                :model="formData.information"
                :schema="entitySchema.information"
                field="comment"
                class="textarea-field"
              >
                <md-textarea
                  :disabled="isViewMode"
                  slot="field"
                  v-model="formData.information.comment"
                  tabenable="yes"
                ></md-textarea>
              </lims-field>
            </div>
          </div>
          <!-- end row -->
        </div>
      </lims-block>
      <!-- Begin user block - temporary hide-->
      <entity-user-list
        v-if="false"
        :entityTypeId="entityTypeId"
        v-model="formData.addedUsers"
        :disabled="isViewMode"
        :isMyEntityView="isMyEntityView"
      ></entity-user-list>
      <!-- End user block -->
      <ValidationProvider slim :name="'entityBillings'" rules="required" v-slot="{ failed }" v-if="isDefaultView">
        <billing-data :failed="failed" v-model="formData.entityBillings" :disabled="isViewMode"></billing-data>
      </ValidationProvider>
      <ValidationProvider slim :name="'entityWorkStreams'" rules="required" v-slot="{ failed }" v-if="isDefaultView">
        <work-stream :failed="failed" v-model="formData.entityWorkStreams" :disabled="isViewMode"></work-stream>
      </ValidationProvider>
      <!-- Begin Preferred Pathologist form -->
      <preferred-pathologist
        :disabled="isViewMode"
        :entityId="formData.entityId"
        :restrictedPathologist="restrictedPathologistsTemp"
        :isDefaultView="isDefaultView"
        @tempData="preferredPathologistTemp = $event"
        v-model="formData.clinicPreferredPathologists"
      ></preferred-pathologist>
      <!-- Begin Restricted Pathologist form -->
      <restricted-pathologist
        :disabled="isViewMode"
        :entityId="formData.entityId"
        :preferredPathologist="preferredPathologistTemp"
        :isMyEntityView="isMyEntityView"
        @tempData="restrictedPathologistsTemp = $event"
        v-model="formData.clinicRestrictedPathologists"
      ></restricted-pathologist>
      <!-- Begin action form -->
      <div class="md-layout lims-form-row">
        <div class="md-layout-item md-size-100 md-small-size-100 lims-form-actions">
          <LimsFormCancel :redirectUrl="isMyEntityView && isLabTechnicianView ? '/' : ''" />
          <md-button
            v-if="!isViewMode"
            @click="$onSave()"
            class="md-button md-primary lims-form-button md-theme-default"
          >
            {{ $t('global/button/button.save') }}
          </md-button>
          <LimsFormEditButton v-if="isViewMode && $canEdit()" :editUrl="$editUrl" />
          <md-button v-if="!isAddMode && isDisabledEntity" class="md-primary lims-form-button" @click="$onEnableEntity">
            {{ $t('global/button/button.enable') }}
          </md-button>
          <md-button v-if="isEditMode && isEnabledEntity" class="md-danger lims-form-button" @click="$onDisableEntity">
            {{ $t('global/button/button.disable') }}
          </md-button>
        </div>
      </div>
      <!-- End action form -->
    </form>
  </ValidationObserver>
</template>

<script>
import EntityMixins from '@/pages/Entity/entity.mixins';
import { FormMixins, TabMixins } from '@/core/mixins';
import { ENTITY_TYPES, ENTITY_TYPE_ENUMS, APP_EVENTS } from '@/core/constants';
import { getEntitySchema } from '@/schemas/entities.schema';
import BillingData from '@/pages/Entity/Components/BillingData';
import WorkStream from '@/pages/Entity/Components/WorkStream';
import { mapGetters } from 'vuex';
import { cloneDeep } from 'lodash';
const ENTITY_TYPE = ENTITY_TYPES.Clinic;

export default {
  name: 'LimsEntityClinic',
  components: { BillingData, WorkStream },
  mixins: [FormMixins, EntityMixins, TabMixins],
  created: function () {
    this.$fetchData(ENTITY_TYPE);
  },
  data() {
    return {
      clinicTypes: [],
      preferredPathologistTemp: [],
      restrictedPathologistsTemp: [],
    };
  },
  computed: {
    ...mapGetters('app/event', [APP_EVENTS.EVT_ON_CHANGE_COMMON_USED_SPECIMEN_TYPES]),
    entitySchema() {
      return getEntitySchema(ENTITY_TYPE, this.entityResource);
    },
    entityTypeList() {
      return ENTITY_TYPE_ENUMS;
    },
    entityTypeId() {
      return ENTITY_TYPE;
    },
  },
  watch: {
    [APP_EVENTS.EVT_ON_CHANGE_COMMON_USED_SPECIMEN_TYPES]: {
      deep: true,
      handler: async function (val) {
        if (val) {
          this.formData.commonlyUsedSpecimenTypes = cloneDeep(val);
        }
      },
    },
  },
};
</script>
<style lang="scss" scoped></style>
