/* eslint-disable security/detect-object-injection */
import { currentTime } from '@/core/helpers';
import { isArray, isUndefined } from 'lodash';

export default {
  computed: {
    currentTime() {
      return this.$translate('pages/Dashboard/Components/TimeLabel/asOf', { time: currentTime() });
    },
  },
  methods: {
    isAllow(statValue) {
      return !isUndefined(statValue);
    },
    lastHours(hour) {
      return this.$translate('pages/Dashboard/Components/TimeLabel/lastHours', { hour });
    },
    lastWeek(week) {
      return this.$translate('pages/Dashboard/Components/TimeLabel/lastWeeks', { week });
    },
    transformStatsData(data) {
      const values = {};
      Object.keys(data).map((k) => {
        if (isArray(data[k])) {
          data[k].map((item) => {
            if (!values[k]) {
              values[k] = {};
            }
            values[k][item.dashboardName] = item?.numberOfItem;
          });
        } else {
          values[k] = data[k]?.numberOfItem;
        }
      });
      return values;
    },
  },
};
