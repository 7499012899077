<template>
  <lims-block class="c-block01">
    <h4 class="title" slot="blockTitle">
      {{ $t('pages/account/user/forms/ClinicianAssociate/associatedClinicianBlock') }}
    </h4>
    <div slot="blockContent">
      <div class="md-layout" v-if="isDefaultView">
        <div class="md-layout-item md-size-100">
          <lims-form-error
            :failed="failed"
            :error="$t('pages/account/user/forms/ClinicianAssociate/addAtLeastOneClinicianAssociate')"
          />
        </div>
      </div>
      <div class="md-layout" v-if="isDefaultView">
        <div class="md-layout-item md-size-50 md-small-size-100">
          <div>
            <label class="required">
              {{ $t('pages/account/user/forms/ClinicianAssociate/availableCliniciansLabel') }}
            </label>
            <lims-tooltip
              class="associated-clinician-tooltip"
              :content="$t('pages/account/user/forms/ClinicianAssociate/associatedClinicianTooltip')"
            ></lims-tooltip>
          </div>
          <md-field :class="[{ 'md-error': failed }]">
            <v-select
              multiple
              v-model="clinicianAssociatedSelected"
              :options="clinicianPending"
              :placeholder="$t('pages/account/user/forms/ClinicianAssociate/availableCliniciansLabelPlaceholder')"
              :disabled="viewMode"
              tabenable="yes"
              :tabindex="22"
              :selectable="(option) => !clinicianAssociatedSelected.includes(option)"
            >
              <template #option="{ label }">{{ label }}</template>
              <template #selected-option="{ label }">{{ label }}</template>
            </v-select>
          </md-field>
        </div>
        <div class="md-layout-item md-size-50 md-small-size-100">
          <md-button
            @click="addClinician()"
            type="button"
            class="lims-form-button block01-btn md-primary"
            :disabled="viewMode || noClinicianSelected"
          >
            {{ $t('pages/account/user/forms/ClinicianAssociate/addClinician') }}
          </md-button>
        </div>
      </div>
      <div class="md-layout">
        <md-table class="custom-tbl col-3-th-center" md-card v-model="formData.associatedClinician">
          <md-table-row>
            <md-table-head width="40%">
              {{ $t('pages/account/user/forms/ClinicianAssociate/table.nameLabel') }}
            </md-table-head>
            <md-table-head>
              {{ $t('pages/account/user/forms/ClinicianAssociate/table.specialtyLabel') }}
            </md-table-head>
            <md-table-head v-if="isDefaultView" class="th-center cell-action">
              {{ $t('global/pages/list.actions') }}
            </md-table-head>
          </md-table-row>
          <md-table-row v-for="item in formData.associatedClinician" :key="item.id">
            <md-table-cell>{{ item.clinicianName }}</md-table-cell>
            <md-table-cell>{{ item.specialty }}</md-table-cell>
            <md-table-cell v-if="isDefaultView" class="cell-action">
              <div @click="viewMode ? '' : deleteAssociatedClinician(item)"><md-icon>close</md-icon></div>
            </md-table-cell>
          </md-table-row>
        </md-table>
      </div>
    </div>
  </lims-block>
</template>

<script>
import { SELECT_LIST_STATUS } from '@/core/constants';
import UserService from '@/services/user.service';

export default {
  props: {
    failed: {
      type: Boolean,
      require: false,
      default: false,
    },
    viewMode: {
      type: Boolean,
      require: false,
      default: null,
    },
    value: {
      type: Array,
      require: true,
    },
    isDefaultView: {
      type: Boolean,
      require: true,
    },
  },
  created() {
    this.fetchData();
  },
  watch: {
    clinicianAssociatedSelected() {
      this.noClinicianSelected = this.clinicianAssociatedSelected.length === 0;
    },
    'formData.associatedClinician': {
      handler(val) {
        this.$emit('input', val);
      },
      deep: true,
    },
    async value() {
      if (this.value) {
        this.formData.associatedClinician = this.value;
        this.associatedClinicianList = await this.loadAssociatedClinician();
        if (this.associatedClinicianList) {
          this.formData.associatedClinician.forEach((item) => {
            this.associatedClinicianList.forEach((el) => {
              if (el.value === item.userId) {
                el.status = SELECT_LIST_STATUS.IN_PROGRESS;
              }
            });
          });
        }
      }
    },
  },
  data() {
    return {
      clinicianAssociatedSelected: [],
      associatedClinicianList: [],
      noClinicianSelected: true,
      formData: {
        associatedClinician: [],
      },
    };
  },
  computed: {
    clinicianPending() {
      return this.associatedClinicianList.filter((cli) => cli.status === SELECT_LIST_STATUS.PENDING);
    },
  },
  methods: {
    async fetchData() {
      this.associatedClinicianList = await this.loadAssociatedClinician();
    },
    async loadAssociatedClinician() {
      let responseData;
      if (this.isDefaultView) {
        responseData = await UserService.findAssociatedClinician({
          userId: this.userId,
          search: '',
        });
        return responseData.data.map((item) => {
          return {
            value: item.id,
            specialty: item.specialty,
            label: item.text,
            status: SELECT_LIST_STATUS.PENDING,
          };
        });
      }
    },
    addClinician() {
      if (this.clinicianAssociatedSelected) {
        this.clinicianAssociatedSelected.forEach((cli) => {
          let clinicianData = this.associatedClinicianList.find((e) => e.value === cli.value);
          this.formData.associatedClinician.push({
            userId: clinicianData.value,
            specialty: clinicianData.specialty,
            clinicianName: clinicianData.label,
          });
          let id = this.associatedClinicianList.findIndex(
            (associatedClinician) => associatedClinician.value === clinicianData.value,
          );
          this.associatedClinicianList[id].status = SELECT_LIST_STATUS.IN_PROGRESS;
        });
      }
      this.clinicianAssociatedSelected = [];
    },
    deleteAssociatedClinician(item) {
      let clinicianData = this.associatedClinicianList.find((e) => e.value === item.userId);
      if (clinicianData) {
        let idx = this.associatedClinicianList.findIndex(
          (associatedClinician) => associatedClinician.value === clinicianData.value,
        );
        this.associatedClinicianList[idx].status = SELECT_LIST_STATUS.PENDING;
      } else {
        this.associatedClinicianList.push({
          value: item.id,
          specialty: item.specialty,
          label: item.clinicianName,
          status: SELECT_LIST_STATUS.PENDING,
        });
      }
      let id = this.formData.associatedClinician.findIndex((i) => i.userId === item.userId);
      this.formData.associatedClinician.splice(id, 1);
    },
  },
};
</script>
<style lang="scss">
.associated-clinician-tooltip {
  &.c-tooltip {
    display: revert !important;
  }
}
</style>
