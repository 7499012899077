/* eslint-disable security/detect-unsafe-regex */
import userService from '@/services/user.service';
import roleService from '@/services/role.service';
import entityService from '@/services/entity.service';
import { AuthService, caseFormService } from '@/services';
import { DropdownService } from '@/services';
import policyService from '@/services/policy.service';
import { currentDay, startDay } from '@/core/helpers';

export default {
  exampleIdentityCheck(value, params) {
    return new Promise((resolve) => {
      resolve(false && { value, params });
    });
  },

  usersIdentityCheck(value, params) {
    const { userId, field } = params;
    if (field && (field === 'telephoneMobile' || field === 'newTelephone') && value.countryCodeMobile) {
      if (!value.countryCodeMobile || !value.countryCodeMobile.code || !value.telephoneMobile) {
        return new Promise((resolve) => resolve(true));
      }
      value = value.countryCodeMobile.code + value.telephoneMobile;
    }
    // field: email,username,mobilePhone
    return new Promise((resolve) => {
      userService
        .identityCheck({ value, field, userId })
        .then((res) => {
          resolve(res.data.error !== 'UserIsExisted');
        })
        .catch(() => {
          resolve(false);
        });
    });
  },

  laboratoryReferenceCheck(value, params) {
    const { caseId, field } = params;

    // field: email,username,mobilePhone
    return new Promise((resolve) => {
      caseFormService
        .laboratoryReferenceCheck({ value, field, caseId })
        .then((res) => {
          resolve(!res.data.isExisted);
        })
        .catch(() => {
          resolve(false);
        });
    });
  },

  checkCodeIsRight(value, params) {
    return new Promise((resolve) => {
      if (value) {
        if (params.codeIsRight === false) {
          resolve(true);
        } else {
          resolve(false);
        }
      }
    });
  },

  checkTwoFaMethodAvailable(value, params) {
    return new Promise((resolve) => {
      let methodIsAvailable = params.listTwoFaMethodAvailable.includes(value.value);
      if (methodIsAvailable) {
        resolve(true);
      } else {
        resolve(false);
      }
    });
  },

  checkItemNameExisted(value, params) {
    const { fieldId, itemId } = params;

    return new Promise((resolve) => {
      DropdownService.checkItemNameExisted(value, fieldId, itemId)
        .then((res) => {
          resolve(!res.data.isExisted);
        })
        .catch(() => {
          resolve(false);
        });
    });
  },

  checkItemOrderInvalid(value, params) {
    return new Promise((resolve) => {
      if (!value) resolve(true);
      // eslint-disable-next-line security/detect-possible-timing-attacks
      if (value > params.maxOfItemOrder || value < 1) {
        resolve(false);
      } else {
        resolve(true);
      }
    });
  },

  mustEqualConfirmPasswordCheck(value, params) {
    return new Promise((resolve) => {
      if (!value) resolve(true);
      // eslint-disable-next-line security/detect-possible-timing-attacks
      if (value === params.password) {
        resolve(true);
      } else {
        resolve(false);
      }
    });
  },

  completeYourAccountIdentityCheck(value, params) {
    const { userId, token, field } = params;
    if (field && field === 'phoneNumber' && value.countryCodeMobile) {
      value = value.countryCodeMobile.code + value.telephoneMobile;
    }
    // field: email,username,mobilePhone
    return new Promise((resolve) => {
      AuthService.completeYourAccountIdentityCheck({ value, field, userId, token })
        .then((res) => {
          resolve(!res.data.isExisted);
        })
        .catch(() => {
          resolve(false);
        });
    });
  },

  validUserNameCheck(value) {
    // eslint-disable-next-line no-useless-escape
    const regex = /^(?=[a-zA-Z0-9%^&@#$^*:'.\-_\\/]{8,128}$)(?!.*[._]{2})(?!.*[._\\]{3})[^.].*[^.]$/;
    return new Promise((resolve) => {
      if (!value) resolve(true);
      if (value) {
        resolve(Boolean(value.match(regex)));
      }
    });
  },

  validPasswordCheck(value) {
    // PASSWORD LENGTH : 8-99
    const finalRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~!@#$%^&*()_+{}|:"?><;'=,.-/\[\]\\\-])[A-Za-z\d~!@#$%^&*()_+{}|:"?><;'=,./\[\]\\\-]{8,99}$/; //eslint-disable-line
    return new Promise((resolve) => {
      if (!value) resolve(true);
      if (value) {
        resolve(Boolean(value.match(finalRegex)));
      }
    });
  },

  validPhoneNumberCheck(value) {
    const regex = /^(\+[0-9]+$)/g;
    return new Promise((resolve) => {
      if (!value) resolve(true);
      if (value) {
        resolve(Boolean(value.match(regex)));
      }
    });
  },

  validMobilePhoneCheck(value) {
    const regex = /^\s*\+\s*([0-9][\s-]*){9,}$/;
    return new Promise((resolve) => {
      if (!value.telephoneMobile) resolve(true);
      if (value.telephoneMobile) {
        const tel = '+' + value.telephoneMobile;
        resolve(Boolean(tel.match(regex)));
      }
    });
  },

  roleNameCheckUnique(value, params) {
    const { roleId } = params;
    return new Promise((resolve) => {
      roleService
        .roleNameCheckUnique({ value, roleId })
        .then((res) => {
          resolve(!res.data.isExisted);
        })
        .catch(() => {
          resolve(false);
        });
    });
  },

  entityCheckUnique(value, params) {
    const { entityId, field } = params;
    return new Promise((resolve) => {
      entityService
        .entityCheckUnique({ value, field, entityId })
        .then((res) => {
          resolve(!res.data.isExisted);
        })
        .catch(() => {
          resolve(false);
        });
    });
  },

  policyTitleCheckUnique(value, params) {
    const { policyId } = params;
    return new Promise((resolve) => {
      policyService
        .policyTitleCheckUnique({ value, policyId })
        .then((res) => {
          resolve(!res.data.isExisted);
        })
        .catch(() => {
          resolve(false);
        });
    });
  },

  checkDateLessOrEqualCurrentDate(value) {
    return new Promise((resolve) => {
      if (!value) resolve(true);
      // eslint-disable-next-line security/detect-possible-timing-attacks
      if (startDay(value) > currentDay()) {
        resolve(false);
      } else {
        resolve(true);
      }
    });
  },
};
