// Layouts
const DashboardLayout = () => import('@/layouts/DashboardLayout.vue');
// Entity pages
const Entity = () => import('@/pages/Entity/Entity.vue');
const EntityAdd = () => import('@/pages/Entity/Add/EntityAdd.vue');
const EntityEdit = () => import('@/pages/Entity/Edit/EntityEdit.vue');

import guards from './guards';
import { ucFirst } from '@/core/helpers';
import { ENTITY_TYPES, FORM_MODES } from '@/core/constants';

export default {
  path: '/entities',
  component: DashboardLayout,
  meta: {
    zone: 'private',
    rbac: 'entities',
  },
  beforeEnter: guards.checkAuthorizedGuard,
  children: [
    {
      path: '',
      name: 'EntityManagement',
      component: Entity,
      meta: {
        zone: ['Accounts_EntityManagement_Retrieve'],
      },
    },
    {
      path: 'add/:entityType',
      name: 'EntityManagementAdd',
      component: EntityAdd,
      props: (route) => ({
        entityTypeId: ENTITY_TYPES[ucFirst(route.params.entityType)],
        formMode: FORM_MODES.ADD,
      }),
      meta: {
        zone: ['Accounts_EntityManagement_Create'],
      },
    },
    {
      path: 'edit/:entityId',
      name: 'EntityManagementEdit',
      component: EntityEdit,
      props: (route) => ({
        formMode: FORM_MODES.EDIT,
        entityId: route.params.entityId,
      }),
      meta: {
        zone: ['Accounts_EntityManagement_Update'],
      },
    },
    {
      path: 'view/:entityId',
      name: 'EntityManagementView',
      component: EntityEdit,
      props: (route) => ({
        formMode: FORM_MODES.VIEW,
        entityId: route.params.entityId,
      }),
      meta: {
        zone: ['Accounts_EntityManagement_Retrieve'],
      },
    },
  ],
};
