export default {
  dateTimeAppFormat: 'dd/MM/yyyy HH:mm:ss',
  itemPerPage: [],
  languageId: 0,
  itemPerPageDefault: 50,
  policyAcceptedByUser: [],
  timezoneValue: '00:00',
  userType: '',
  userTypeId: '',
  userTypes: {
    Administrator: 1,
    ClinicAssociate: 2,
    Clinician: 3,
    ClinicianAssociate: 4,
    LabTechnician: 5,
    Pathologist: 6,
  },
  userTypeOptions: [
    { value: 1, label: 'entities/user/userTypeName.Administrator' },
    { value: 2, label: 'entities/user/userTypeName.ClinicAssociate' },
    { value: 3, label: 'entities/user/userTypeName.Clinician' },
    { value: 4, label: 'entities/user/userTypeName.ClinicianAssociate' },
    { value: 5, label: 'entities/user/userTypeName.LabTechnician' },
    { value: 6, label: 'entities/user/userTypeName.Pathologist' },
  ],
};
