const DashboardLayout = () => import('@/layouts/DashboardLayout.vue');
// Dashboard pages
const Dashboard = () => import('@/pages/Dashboard/Dashboard.vue');
import guards from './guards';

export default {
  path: '/',
  component: DashboardLayout,
  children: [
    {
      path: 'dashboard',
      name: 'Dashboard',
      components: { default: Dashboard },
      meta: {
        zone: 'private',
      },
      beforeEnter: guards.checkAuthorizedGuard,
    },
  ],
};
