<template>
  <div>
    <modal-view-case-import-history ref="viewCaseImportHistoryModal"></modal-view-case-import-history>
    <md-table class="paginated-table table-striped table-hover lims-table import-history-tbl">
      <md-table-toolbar>
        <slot name="toolbar"></slot>
      </md-table-toolbar>
      <div class="table-fix-header">
        <thead>
          <md-table-row>
            <md-table-head v-for="(col, index) in colTabl" :key="index" :class="col.class">
              <div>{{ $t(col.name) }}</div>
            </md-table-head>
          </md-table-row>
        </thead>
        <tbody>
          <md-table-row v-for="(item, index) in importHistoryList" :key="`importHistoryList${index}`">
            <md-table-cell class="no-column">{{ index + 1 }}</md-table-cell>
            <md-table-cell>{{ item.importId }}</md-table-cell>
            <md-table-cell>{{ formatDateTimeGlobalNotIncludeTime(item.importDate) }}</md-table-cell>
            <md-table-cell>{{ item.importer }}</md-table-cell>
            <md-table-cell class="cell-action">
              <div class="cell-action-inner">
                <div style="width: 30px">
                  <md-button class="md-just-icon md-simple" @click="onNavigateCaseList(item)">
                    <md-icon>visibility</md-icon>
                  </md-button>
                </div>
                <div style="width: 30px">
                  <md-button class="md-just-icon md-simple" @click="viewInforImportHistory(item)">
                    <md-icon>info</md-icon>
                  </md-button>
                </div>
              </div>
            </md-table-cell>
          </md-table-row>
        </tbody>
      </div>
    </md-table>
  </div>
</template>

<script>
import { DateTimeHelper } from '@/core/helpers';
import ModalViewCaseImportHistory from '@/components/Lims/modals/ModalViewCaseImportHistory.vue';
import StatsMixins from '@/pages/Dashboard/Components/stats.mixins';

export default {
  name: 'ImportHistoryList',
  mixins: [StatsMixins],
  components: {
    ModalViewCaseImportHistory,
  },
  props: {
    importHistoryList: {
      required: false,
      default: function () {
        return [];
      },
    },
  },

  data() {
    return {
      colTabl: [
        {
          name: 'pages/case/ImportHistory/list.No',
          key: 'No',
          sort: false,
          class: 'no-column',
        },
        {
          name: 'pages/case/ImportHistory/list.importID',
          key: 'importID',
          sort: false,
          class: 'importID-column',
        },
        {
          name: 'pages/case/ImportHistory/list.importDate',
          key: 'importDate',
          sort: false,
          class: 'importDate-column',
        },
        {
          name: 'pages/case/ImportHistory/list.importer',
          key: 'importer',
          sort: false,
          class: 'importer-column',
        },
        {
          name: 'global/pages/list.actions',
          key: 'actions',
          sort: false,
          class: 'actions-column',
        },
      ],
    };
  },
  methods: {
    formatDateTimeGlobalNotIncludeTime(datetimeValue) {
      const dateTimeAppFormat = 'dd/MM/yyyy';
      const { timezoneValue } = this.$store.getters['config/$appConfig'];
      return DateTimeHelper.formatDateTimeGlobal(datetimeValue, {
        timezoneValue,
        dateTimeAppFormat,
      });
    },
    onNavigateCaseList(item) {
      this.$navigateToList({ path: 'cases', query: { CaseImportBatchId: item.importId } });
    },
    async viewInforImportHistory(item) {
      this.$refs.viewCaseImportHistoryModal.open({ importId: item.importId });
    },
  },
};
</script>

<style lang="scss">
.import-history-tbl {
  .no-column {
    width: 50px;
  }
}
</style>
