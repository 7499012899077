export const LimsTextAreaDirective = {
  bind: function (el, binding) {
    const value = binding.value;
    const richText = value
      ? value
          .split('\n')
          .map((v) => `${v}<br/>`)
          .join('')
      : '';
    el.innerHTML = richText;
  },
};
