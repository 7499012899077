import VueRouter from 'vue-router';

// define routes
import routes from './routes';

// configure router
const router = new VueRouter({
  routes, // short for routes: routes
  mode: 'history', // allow history and remove hash
  scrollBehavior: (to) => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  },
  linkExactActiveClass: 'nav-item active',
});

export default router;
