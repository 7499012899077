<template>
  <div>
    <md-table class="paginated-table table-striped table-hover lims-table policy-table">
      <md-table-toolbar>
        <slot name="toolbar"></slot>
      </md-table-toolbar>
      <div class="table-fix-header">
        <thead>
          <md-table-row>
            <md-table-head v-for="(col, index) in colTabl" :key="index" :class="col.class">
              <div @mouseover="hoverIn(index)" @mouseout="hoverOut()" @click="sortCol(col.key, col.sort)">
                {{ $t(col.name) }}
                <span>
                  <md-icon v-if="col.sort">
                    {{
                      col.key === orderState.orderField
                        ? orderState.orderBy === 'asc'
                          ? 'arrow_drop_up'
                          : 'arrow_drop_down'
                        : hover === index
                        ? 'arrow_drop_up'
                        : ''
                    }}
                  </md-icon>
                </span>
              </div>
            </md-table-head>
          </md-table-row>
        </thead>
        <tbody>
          <md-table-row
            v-for="item in policies"
            :key="`policy-${item.policyId}`"
            v-long-press="300"
            @click.native="onNavigate($event, item)"
            @long-press-start="onPlusStart"
            @long-press-stop="onPlusStop"
          >
            <md-table-cell class="policyId-column">{{ item.policyId }}</md-table-cell>
            <md-table-cell>{{ item.title }}</md-table-cell>
            <md-table-cell>{{ item.policyTypeName }}</md-table-cell>
            <md-table-cell>{{ item.createdByName }}</md-table-cell>
            <md-table-cell>{{ formatDateTimeGlobal(item.createdDate) }}</md-table-cell>
            <md-table-cell class="right-side-column"
              ><template v-if="item.isNeedAcceptance">{{ item.numAccept }}</template></md-table-cell
            >
            <md-table-cell class="right-side-column"
              ><template v-if="item.isNeedAcceptance">{{ item.numDecline }}</template></md-table-cell
            >
            <md-table-cell class="pdf-column">
              <img @click.stop="onShowPdf(item)" class="pdf-img" :src="require(`@/assets/images/pdf-file.png`)" />
            </md-table-cell>
            <md-table-cell class="statusName-column">
              <md-icon
                :class="[
                  { 'status-enabled': item.statusName == 'Enabled' },
                  { 'status-disabled': item.statusName == 'Disabled' },
                ]"
                >adjust</md-icon
              >
            </md-table-cell>
            <md-table-cell class="cell-action">
              <div class="cell-action-inner">
                <div style="width: 33%" v-if="isShowViewIcon(item)">
                  <md-button class="md-just-icon md-info md-simple" @click.stop="handleAction('view', item)">
                    <md-icon>visibility</md-icon>
                  </md-button>
                </div>
                <div style="width: 33%" v-if="isShowEditIcon(item)">
                  <md-button class="md-just-icon md-warning md-simple" @click.stop="handleAction('edit', item)">
                    <md-icon>edit</md-icon>
                  </md-button>
                </div>
                <div style="width: 33%" v-if="isShowDeleteIcon(item)">
                  <md-button class="md-just-icon md-danger md-simple" @click.stop="handleAction('delete', item)">
                    <md-icon>delete_forever</md-icon>
                  </md-button>
                </div>
              </div>
            </md-table-cell>
          </md-table-row>
        </tbody>
      </div>
    </md-table>
  </div>
</template>

<script>
import PolicyMixins from '@/pages/Policies/Policy/policy.mixin';
import { FormMixins } from '@/core/mixins';
import policyService from '@/services/policy.service';

export default {
  name: 'policy-list',
  mixins: [FormMixins, PolicyMixins],
  props: {
    policies: {
      type: Array,
    },
  },

  data() {
    return {
      longPressMode: false,
      plusInterval: null,
      colTabl: [
        {
          name: 'pages/policy/list/PolicyList/id',
          key: 'policyId',
          sort: false,
          class: 'policyId-column',
        },
        {
          name: 'pages/policy/list/PolicyList/title',
          key: 'title',
          sort: false,
          class: 'title-column',
        },
        {
          name: 'pages/policy/list/PolicyList/policyType',
          key: 'policyTypeId',
          sort: false,
          class: 'policyType-column',
        },
        {
          name: 'pages/policy/list/PolicyList/createdBy',
          key: 'createdBy',
          sort: false,
          class: 'createdBy-column',
        },
        {
          name: 'pages/policy/list/PolicyList/createdOn',
          key: 'createdOn',
          sort: false,
          class: 'createdOn-column',
        },
        {
          name: 'pages/policy/list/PolicyList/accept',
          key: 'accept',
          sort: false,
          class: 'accept-column right-side-column',
        },
        {
          name: 'pages/policy/list/PolicyList/decline',
          key: 'decline',
          sort: false,
          class: 'decline-column right-side-column',
        },
        {
          name: 'pages/policy/list/PolicyList/pdf',
          key: 'pdf',
          sort: false,
          class: 'pdf-column',
        },
        {
          name: 'global/pages/list.status',
          key: 'statusName',
          sort: false,
          class: 'statusName-column',
        },
        {
          name: 'global/pages/list.actions',
          key: 'actions',
          sort: false,
          class: 'actions-column',
        },
      ],
      hover: '',
      orderState: {
        orderField: 'policyTypeId',
        orderBy: 'asc',
      },
      pdfFile: '',
    };
  },
  watch: {
    orderState: {
      handler() {
        let sortType = this.orderState.orderField + ':' + this.orderState.orderBy;
        this.$emit('sort', sortType);
      },
      deep: true,
    },
  },

  computed: {},

  methods: {
    handleAction(action, item) {
      this.$emit('action', {
        action,
        item,
      });
    },

    onNavigate(e, item) {
      if (!this.longPressMode) {
        e.preventDefault();
        this.handleAction('view', item);
      }
    },
    onPlusStart() {
      this.plusInterval = setInterval(() => {
        this.longPressMode = true;
      }, 100);
    },

    onPlusStop() {
      setTimeout(() => {
        this.longPressMode = false;
      }, 500);
      clearInterval(this.plusInterval);
    },

    hoverIn(index) {
      this.hover = index;
    },
    hoverOut() {
      this.hover = '';
    },
    sortCol(colName, isSort) {
      if (isSort) {
        if (this.orderState.orderField === colName) {
          if (this.orderState.orderBy === 'asc') {
            this.orderState.orderBy = 'desc';
          } else {
            this.orderState.orderBy = 'asc';
          }
        } else {
          this.orderState.orderField = colName;
          this.orderState.orderBy = 'asc';
        }
      }
    },

    async onShowPdf(item) {
      const policyId = item.policyId;
      const fileURL = await this.downloadFilePdf(policyId);
      this.$emit('onShowPdf', fileURL);
    },

    async downloadFilePdf(policyId) {
      const res = await policyService.downloadFilePolicy(policyId);
      const fileURL = URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' }));
      return fileURL;
    },

    isShowViewIcon() {
      return this.$isAuthorized(['Policy_PolicyManagement_Retrieve']);
    },

    isShowEditIcon(item) {
      return this.$isAuthorized(['Policy_PolicyManagement_Update']) && item.statusName == 'Enabled';
    },

    isShowDeleteIcon(item) {
      return (
        this.$isAuthorized(['Policy_PolicyManagement_Delete']) &&
        item.statusName == 'Disabled' &&
        item.isNeedAcceptance == false
      );
    },
  },
};
</script>
<style lang="scss">
.policy-table {
  .policyId-column {
    width: 70px !important;
  }
  .pdf-column {
    width: 50px !important;
  }
  .right-side-column {
    width: 80px !important;
    text-align: right;
    .md-table-cell-container {
      padding-right: 22px;
    }
  }
  .pdf-img {
    width: 20px !important;
    margin-left: -3px;
    cursor: pointer;
  }
}
</style>
