<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit }">
    <form class="md-layout lims-form" @submit.prevent="handleSubmit()">
      <md-dialog
        :md-close-on-esc="true"
        :md-active.sync="isVisible"
        @md-clicked-outside="close"
        class="lims-modal modal-report-issue"
      >
        <div class="dialog-icon-close" @click="close"><md-icon>close</md-icon></div>
        <md-dialog-title></md-dialog-title>
        <div class="dialog-content">
          <div class="md-layout lims-form-row issue-field">
            <div class="md-layout-item md-size-100 md-small-size-100">
              <lims-field :model="formData" :schema="schema" field="issue">
                <v-select
                  slot="field-outside"
                  v-model="formData.issueId"
                  :options="issueCaseList"
                  :reduce="(item) => item.issueId"
                  label="issueName"
                  disabled
                >
                  <template #option="{ issueName }">{{ issueName }}</template>
                  <template #selected-option="{ issueName }">{{ issueName }}</template>
                </v-select>
              </lims-field>
            </div>
            <div
              class="md-layout-item md-size-100 md-small-size-100 issue-prevent-update-status"
              v-if="!issuePreventUpdateStatusIsNone()"
            >
              {{
                $t('modals/ModalReportIssue.note', {
                  status: status,
                })
              }}
            </div>
          </div>
          <div class="md-layout lims-form-row mark-as-closed" v-if="formData.issueId && issueFieldIsNone()">
            <div class="md-layout-item md-size-50 md-small-size-100">
              <md-checkbox v-model="formData.isMarkIssueAsClose" class="lims-checkbox checkbox-field" disabled>
                {{ $t('entities/modals/ModalSelectEntityType/markIssueAsClosed') }}
              </md-checkbox>
            </div>
          </div>
          <div class="md-layout lims-form-row to-respond" v-if="formData.issueId && !issueFieldIsNone()">
            <div class="md-layout-item md-size-100 md-small-size-100">
              <lims-field :model="formData" :schema="schema" field="toRespond">
                <v-select
                  slot="field-outside"
                  v-model="formData.toRespond"
                  :options="userTypeList"
                  :placeholder="$t('modals/ModalReportIssue.placeholderToRespond')"
                  disabled
                >
                  <template #option="{ label }">{{ label }}</template>
                  <template #selected-option="{ label }">{{ label }}</template>
                </v-select>
              </lims-field>
            </div>
          </div>
          <div class="md-layout lims-form-row">
            <div class="md-layout-item md-size-100 md-small-size-100 dialog-texterea-field">
              <lims-field :model="formData" :schema="schema" field="comment">
                <md-textarea slot="field" v-model="formData.commentOnIssue" type="text" disabled></md-textarea>
              </lims-field>
            </div>
          </div>
          <div v-if="caseIssueFieldLength">
            <div class="md-layout lims-form-row" v-for="(item, index) in formData.caseIssueFields" :key="index">
              <div class="md-layout-item md-size-100 md-small-size-100" v-if="item.uiControl === 'Datepicker'">
                <lims-field class="md-field-after-none" :model="formData" :schema="schema" field="dob"></lims-field>
                <div class="static-text">
                  {{ formData.dob }}
                </div>
              </div>
              <div
                class="md-layout-item md-size-100 md-small-size-100"
                v-if="item.uiControl === 'Dropdown Menu' && item.caseIssueValueName === 'Billing Entity'"
              >
                <lims-field class="md-field-after-none" :model="formData" :schema="schema" field="billingEntity">
                </lims-field>
                <div class="static-text">{{ formData.billingEntity }}</div>
              </div>
              <div
                class="md-layout-item md-size-100 md-small-size-100"
                v-if="item.uiControl === 'Dropdown Menu' && item.caseIssueValueName === 'Country'"
              >
                <lims-field class="md-field-after-none" :model="formData" :schema="schema" field="country">
                </lims-field>
                <div class="static-text">{{ formData.country }}</div>
              </div>
              <div
                class="md-layout-item md-size-100 md-small-size-100"
                v-if="item.uiControl === 'Dropdown Menu' && item.caseIssueValueName === 'Insurance Company'"
              >
                <lims-field class="md-field-after-none" :model="formData" :schema="schema" field="insuranceCompany">
                </lims-field>
                <div class="static-text">{{ formData.insuranceCompany }}</div>
              </div>
              <div
                class="md-layout-item md-size-100 md-small-size-100"
                v-if="
                  item.uiControl === 'Dropdown Menu' && item.caseIssueValueName === 'Specimen Type (of 1st specimen)'
                "
              >
                <lims-field class="md-field-after-none" :model="formData" :schema="schema" field="specimenType">
                </lims-field>
                <div class="static-text">{{ formData.specimenType }}</div>
              </div>
              <div
                class="md-layout-item md-size-100 md-small-size-100"
                v-if="item.uiControl === 'Text Input' && item.caseIssueValueName === 'Insurance Authorisation Number'"
              >
                <lims-field
                  class="md-field-after-none"
                  :model="formData"
                  :schema="schema"
                  field="insuranceAuthorisationNumber"
                >
                </lims-field>
                <div class="static-text">{{ formData.insuranceAuthorisationNumber }}</div>
              </div>
              <div
                class="md-layout-item md-size-100 md-small-size-100"
                v-if="item.uiControl === 'Text Input' && item.caseIssueValueName === 'Insurance Number'"
              >
                <lims-field class="md-field-after-none" :model="formData" :schema="schema" field="insuranceNumber">
                </lims-field>
                <div class="static-text">{{ formData.insuranceNumber }}</div>
              </div>
              <div
                class="md-layout-item md-size-100 md-small-size-100"
                v-if="item.uiControl === 'Text Input' && item.caseIssueValueName === 'Insurance Note'"
              >
                <lims-field
                  class="md-field-after-none"
                  :model="formData"
                  :schema="schema"
                  field="insuranceNote"
                ></lims-field>
                <div class="static-text">{{ formData.insuranceNote }}</div>
              </div>
              <div
                class="md-layout-item md-size-100 md-small-size-100"
                v-if="item.uiControl === 'Text Input' && item.caseIssueValueName === 'Postal Code'"
              >
                <lims-field class="md-field-after-none" :model="formData" :schema="schema" field="postalCode">
                </lims-field>
                <div class="static-text">{{ formData.postalCode }}</div>
              </div>
              <div
                class="md-layout-item md-size-100 md-small-size-100"
                v-if="item.uiControl === 'Text Input' && item.caseIssueValueName === 'Town'"
              >
                <lims-field class="md-field-after-none" :model="formData" :schema="schema" field="town"> </lims-field>
                <div class="static-text">{{ formData.town }}</div>
              </div>
              <div
                class="md-layout-item md-size-100 md-small-size-100"
                v-if="item.uiControl === 'Textarea' && item.caseIssueValueName === 'Address 1'"
              >
                <lims-field class="md-field-after-none" :model="formData" :schema="schema" field="address1">
                </lims-field>
                <div class="static-text">{{ formData.address1 }}</div>
              </div>
              <div
                class="md-layout-item md-size-50 md-small-size-100"
                v-if="item.uiControl === 'Textarea' && item.caseIssueValueName === 'Address 2'"
              >
                <lims-field class="md-field-after-none" :model="formData" :schema="schema" field="address2">
                </lims-field>
                <div class="static-text">{{ formData.address2 }}</div>
              </div>
            </div>
          </div>
          <div class="md-layout lims-form-row">
            <div class="md-layout-item md-size-100 md-small-size-100">
              <p>
                {{
                  $t('pages/case/CaseManagement/component/viewIssue/note/createdTime', {
                    reportedTime: formatDateTime(formData.reportedAt),
                    reportedName: formData.reportedBy,
                  })
                }}
              </p>
            </div>
          </div>
        </div>
        <md-dialog-actions>
          <md-button @click="close" class="lims-form-button">{{ $t('global/button/button.back') }}</md-button>
        </md-dialog-actions>
      </md-dialog>
    </form>
  </ValidationObserver>
</template>

<script>
import modalMixins from './modal.mixins';
import { CaseIssueMixins } from '@/pages/Case/CaseManagement/Case.mixins';
import { DROPDOWN_SHORT_NAME, ENTITY_TYPE_ENUMS } from '@/core/constants';
import { mapActions, mapGetters } from 'vuex';
import dropdownService from '@/services/dropdown.service';

export default {
  mixins: [modalMixins, CaseIssueMixins],
  props: {
    issueCaseList: {
      type: Array,
      required: false,
    },
    issueData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      formData: {
        issue: null,
        isMarkIssueAsClose: false,
        toRespond: null,
        comment: null,
        billingEntity: null,
        commentOnIssueResponse: null,
      },
      notifyList: [],
      respondList: [],
      billingEntityList: [],
    };
  },
  watch: {
    issueData() {
      const findItemWithIssueId = this.issueCaseList.find(
        (issueCase) => issueCase.issueId === this.issueData.issue.issueId,
      );
      if (findItemWithIssueId) {
        const userTypeList = findItemWithIssueId.toRespondUserTypes;
        if (userTypeList) {
          this.userTypeList = userTypeList.map((userType) => {
            return {
              value: userType.id,
              label: userType.text,
            };
          });
        }
      }
      this.formData = {
        caseIssueId: this.issueData.caseIssueId,
        issueId: this.issueData.issue.issueId,
        caseIssueFields: this.issueData.caseIssueFields,
        status: this.issueData.status,
        isMarkIssueAsClose: this.issueData.isMarkIssueAsClose,
        toRespond: this.userTypeList?.filter((userTypeItem) =>
          this.issueData.toRespond.toRespondUserTypeIds.includes(userTypeItem.value),
        ),
        commentOnIssue: this.issueData.comment,
        commentOnResponse: this.issueData.commentOnResponse,
        reportedAt: this.issueData.reportedAt,
        reportedBy: this.issueData.reportedBy,
      };
      this.billingEntityList = this.getDatasetByKey(DROPDOWN_SHORT_NAME.BILLING_ENTITY);
      this.countries = this.getDatasetByKey(DROPDOWN_SHORT_NAME.COUNTRY);
      this.insuranceCompanyList = this.getDatasetByKey(DROPDOWN_SHORT_NAME.INSURANCE_COMPANY);
      this.specimenTypeList = this.getDatasetByKey(DROPDOWN_SHORT_NAME.SPECIMEN_TYPE);
      if (this.caseIssueFieldLength) {
        this.formData.caseIssueFields.forEach((caseIssue) => {
          this.$setFormDataCaseIssue(caseIssue);
        });
      }
    },
  },
  computed: {
    ...mapGetters('app/data', ['getDatasetByKey']),
    schema() {
      return {
        entity: 'modals/ModalSelectEntityType',
        fields: {
          issue: 'required',
          toRespond: 'required',
          comment: '',
          dob: '',
          billingEntity: 'required',
          commentOnIssueResponse: '',
          country: '',
          insuranceCompany: '',
          specimenType: '',
          insuranceAuthorisationNumber: '',
          insuranceNumber: '',
          insuranceNote: '',
          postalCode: '',
          town: '',
          address1: '',
          address2: '',
        },
      };
    },
    selectOptions() {
      return ENTITY_TYPE_ENUMS;
    },
    caseIssueFieldLength() {
      return this.formData.caseIssueFields?.length > 0;
    },
  },
  methods: {
    ...mapActions('app/data', ['updateDataset']),
    async prepareData() {
      const dropdownOptions = await dropdownService.getDropdownByShortNames([
        DROPDOWN_SHORT_NAME.COUNTRY,
        DROPDOWN_SHORT_NAME.SPECIMEN_TYPE,
        DROPDOWN_SHORT_NAME.INSURANCE_COMPANY,
        DROPDOWN_SHORT_NAME.BILLING_ENTITY,
      ]);
      this.updateDataset({
        ...dropdownOptions,
      });
    },
  },
};
</script>
<style lang="scss">
.modal-report-issue .checkbox-field {
  @media only screen and (max-width: 959px) {
    padding-top: 0;
  }
}
.issue-prevent-update-status {
  color: red;
  margin-top: 20px;
}
</style>
