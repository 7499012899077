<template>
  <md-dialog :md-close-on-esc="true" :md-active.sync="isVisible" @md-clicked-outside="onClose" class="lims-modal">
    <div class="dialog-icon-close" @click="onClose"><md-icon>close</md-icon></div>
    <div class="lims-form dialog-content">
      <p class="text-center">
        <template v-if="authySuccess">{{ $t('components/lims/modals/ModalVerifySuccessfully/authySuccess') }}</template>
        <template v-else>
          {{ $t('components/lims/modals/ModalVerifySuccessfully', { method: method }) }}
        </template>
      </p>
    </div>
    <md-dialog-actions>
      <md-button @click="onClose" class="lims-form-button">
        {{ $t('global/button/button.close') }}
      </md-button>
    </md-dialog-actions>
  </md-dialog>
</template>

<script>
import modalMixins from './modal.mixins';
export default {
  name: 'modal-verify-successfully',
  mixins: [modalMixins],
  props: {
    method: {
      type: String,
      require: true,
      default: null,
    },
    authySuccess: {
      type: Boolean,
      require: false,
    },
  },
  watch: {},
  computed: {},
  methods: {
    onClose() {
      this.$emit('onVerifySuccessfully');
    },
  },
};
</script>

<style lang="scss"></style>
