import { cloneSortFilterFields, getDefaultFilterValues, getFilterFieldTypes } from '@/query/utils';

const fields = cloneSortFilterFields({ orderField: 'caseReference', orderBy: 'asc' });
const defaultValues = getDefaultFilterValues(fields);
const fieldTypes = getFilterFieldTypes(fields);
export default {
  fields,
  defaultValues,
  fieldTypes,
};
