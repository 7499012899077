import { isArray } from 'lodash';
import { ConfigMixins } from '@/core/mixins';

export default {
  mixins: [ConfigMixins],
  created() {
    this.query.pagination.pageSize = this.$appConfig.itemPerPageDefault;
  },
  data() {
    return {
      query: {
        pagination: {
          pageSize: null,
          pageNumber: 1,
        },
      },
    };
  },
  methods: {
    hoverIn(index) {
      this.hover = index;
    },
    hoverOut() {
      this.hover = '';
    },

    getColumnName(c) {
      if (isArray(c.name)) {
        return c.name.map((n) => this.$translate(n)).join('<br/>');
      }
      return this.$translate(c.name);
    },

    onPaginate(array, page_size, page_number) {
      return array.slice((page_number - 1) * page_size, page_number * page_size);
    },

    onCancel() {
      this.$router.back();
    },
  },
};
