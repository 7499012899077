<template>
  <div v-if="countries && specimenTypeList && insuranceCompanyList">
    <md-icon>report_problem</md-icon>
    <md-tooltip mdDirection="bottom" class="tooltip-align-left tooltip-case-list">
      <md-table class="custom-tbl">
        <md-table-row>
          <md-table-head
            ><div v-html="$t('pages/case/CaseManagement/list/issueInformation/issueNameAndRequestedOn')"></div>
          </md-table-head>
          <md-table-head
            ><div v-html="$t('pages/case/CaseManagement/list/issueInformation/statusAndResponder')"></div>
          </md-table-head>
          <md-table-head
            ><div v-html="$t('pages/case/CaseManagement/list/issueInformation/issueResponseAndRespondedOn')"></div>
          </md-table-head>
          <md-table-head
            ><div v-html="$t('pages/case/CaseManagement/list/issueInformation/respondedComment')"></div>
          </md-table-head>
        </md-table-row>
        <md-table-row v-for="(item, index) in caseIssuesSorted" :key="index">
          <md-table-cell
            >{{ item.issueName }} <br />
            {{ item.reportedAt ? formatDateTimeGlobalNotIncludeTime(item.reportedAt) : '' }}</md-table-cell
          >
          <md-table-cell>
            <div style="width: 120px">{{ item.caseIssueStatus }}</div>
            <br />
            {{ item.toRespondUser ? item.toRespondUser.join(', ') : '' }}</md-table-cell
          >
          <md-table-cell>
            <div style="width: 200px" v-for="(i, index) in item.caseIssueFields" :key="index">
              {{ i.caseIssueValueName }} - {{ setCaseIssueValue(i.caseIssueValueName, i) }} <br />
            </div>
            <br />
            {{ item.respondedAt ? formatDateTimeGlobalNotIncludeTime(item.respondedAt) : '' }}</md-table-cell
          >
          <md-table-cell>{{ item.commentOnResponse }} </md-table-cell>
        </md-table-row>
      </md-table>
    </md-tooltip>
  </div>
</template>

<script>
import CaseListMixin from '@/pages/Case/CaseManagement/List/caseList.mixins';
import { FormMixins } from '@/core/mixins';
import { CASE_ISSUE_VALUE_NAME, DROPDOWN_SHORT_NAME } from '@/core/constants';
import { formatDateWithoutTime } from '@/core/helpers';
import { mapGetters } from 'vuex';

export default {
  name: 'IssueInformation',
  mixins: [CaseListMixin, FormMixins],
  props: {
    caseIssues: {
      type: Array,
      require: false,
    },
  },
  computed: {
    ...mapGetters('app/data', ['getDatasetByKey']),
  },
  created() {
    this.prepareData();
    this.caseIssuesSorted = this.caseIssues;
    this.caseIssuesSorted.sort((a, b) => {
      return new Date(b.reportedAt) - new Date(a.reportedAt);
    });
  },
  data() {
    return {
      caseIssuesSorted: [],
      countries: [],
      insuranceCompanyList: [],
      specimenTypeList: [],
    };
  },
  methods: {
    async prepareData() {
      this.countries = this.getDatasetByKey(DROPDOWN_SHORT_NAME.COUNTRY);
      this.insuranceCompanyList = this.getDatasetByKey(DROPDOWN_SHORT_NAME.INSURANCE_COMPANY);
      this.specimenTypeList = this.getDatasetByKey(DROPDOWN_SHORT_NAME.SPECIMEN_TYPE);
    },
    setCaseIssueValue(caseIssueValueName, caseIssue) {
      switch (caseIssueValueName) {
        case CASE_ISSUE_VALUE_NAME.DOB: {
          return formatDateWithoutTime(caseIssue.caseIssueValue);
        }
        case CASE_ISSUE_VALUE_NAME.BILLING_ENTITY: {
          return caseIssue.fieldItemValue;
        }
        case CASE_ISSUE_VALUE_NAME.COUNTRY: {
          let country = this.countries?.find(
            (country) => country.value.toString() == caseIssue.caseIssueValue.toString(),
          );
          return country?.label;
        }
        case CASE_ISSUE_VALUE_NAME.INSURANCE_COMPANY: {
          let insuranceCompany = this.insuranceCompanyList?.find(
            (insuranceCompany) => insuranceCompany.value.toString() == caseIssue.caseIssueValue.toString(),
          );
          return insuranceCompany?.label;
        }
        case CASE_ISSUE_VALUE_NAME.INSURANCE_NUMBER: {
          return caseIssue.caseIssueValue;
        }
        case CASE_ISSUE_VALUE_NAME.INSURANCE_AUTHORIZATION_NUMBER: {
          return caseIssue.caseIssueValue;
        }
        case CASE_ISSUE_VALUE_NAME.INSURANCE_NOTE: {
          return caseIssue.caseIssueValue;
        }
        case CASE_ISSUE_VALUE_NAME.SPECIMEN_TYPE: {
          let specimenType = this.specimenTypeList?.find(
            (specimenType) => specimenType.value.toString() == caseIssue.caseIssueValue.toString(),
          );
          return specimenType?.label;
        }
        case CASE_ISSUE_VALUE_NAME.POSTAL_CODE: {
          return caseIssue.caseIssueValue;
        }
        case CASE_ISSUE_VALUE_NAME.TOWN: {
          return caseIssue.caseIssueValue;
        }
        case CASE_ISSUE_VALUE_NAME.ADDRESS_1: {
          return caseIssue.caseIssueValue;
        }
        case CASE_ISSUE_VALUE_NAME.ADDRESS_2: {
          return caseIssue.caseIssueValue;
        }
      }
    },
  },
};
</script>
