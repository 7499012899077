<template>
  <v-select
    :multiple="multiple"
    :deselectFromDropdown="true"
    v-model="model"
    :options="computedOptions"
    :reduce="(item) => item[reduceKey]"
    :disabled="disabled"
    :selectable="isSelectable"
  >
    <template #option="{ label }">{{ translated ? label : translate(label) }}</template>
    <template #selected-option="{ label }">{{ translated ? label : translate(label) }}</template>
    <template #search="{ events }">
      <input :placeholder="placeholder" type="search" class="vs__search" v-on="events" />
    </template>
  </v-select>
</template>

<script>
import { isArray, isEqual } from 'lodash';
export default {
  props: {
    multiple: {
      type: Boolean,
      required: false,
      default: false,
    },
    translated: {
      type: Boolean,
      required: false,
      default: true,
    },
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
    options: {
      type: Array,
      required: false,
      default: function () {
        return [];
      },
    },
    value: {
      required: false,
      default: '',
    },
    reduceKey: {
      type: String,
      required: false,
      default: function () {
        return 'value';
      },
    },
    labelKey: {
      type: String,
      required: false,
      default: function () {
        return 'label';
      },
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    isInternalFields: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    computedOptions: function () {
      return this.options.map((o) => {
        return {
          ...o,
          label: o[this.labelKey],
        };
      });
    },
  },
  watch: {
    value: {
      handler: function (value) {
        this.model = value;
      },
      deep: true,
    },
    model: {
      handler: function (value) {
        if (this.isInternalFields) {
          this.$emit('input', value);
          return;
        }
        if (!isEqual(value, this.value)) {
          this.$emit('input', value);
        }
      },
      deep: true,
    },
  },
  created() {
    if (this.isInternalFields) {
      this.model = [];
      this.value.forEach((item) => {
        this.model.push(item[this.reduceKey]);
      });
    } else {
      this.model = this.value;
    }
  },
  data: function () {
    return {
      model: '',
    };
  },
  methods: {
    translate(value) {
      if (this.translated) {
        return value;
      }
      return this.$translate(value);
    },
    isSelectable(option) {
      if (!this.model) {
        return option;
      }
      // otherwise check includes
      if (this.model && isArray(this.model)) {
        return !this.model.includes(option.value);
      }
      return option;
    },
  },
};
</script>

<style lang="scss" scoped></style>
