<template>
  <v-select
    v-model="model"
    :options="computedOptions"
    :reduce="(item) => item[reduceKey]"
    :disabled="disabled"
    :placeholder="placeholder"
    tabenable="yes"
    :tabindex="100"
  >
    <template #option="{ label }"
      ><div
        class="custom-color-v-select"
        :style="{ 'background-color': `${rgbColor[label]} !important` }"
        v-if="customColor"
      ></div>
      {{ translated ? label : translate(label) }}</template
    >
    <template #selected-option="{ label }">
      <div
        class="custom-color-v-select"
        :style="{ 'background-color': `${rgbColor[label]} !important` }"
        v-if="customColor"
      ></div>
      {{ translated ? label : translate(label) }}</template
    >
  </v-select>
</template>

<script>
import { isEqual } from 'lodash';
import { RGB_COLORS } from '@/core/constants';
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    translated: {
      type: Boolean,
      required: false,
      default: true,
    },
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
    options: {
      type: Array,
      required: false,
      default: function () {
        return [];
      },
    },
    value: {
      required: false,
      default: '',
    },
    customColor: {
      required: false,
      default: false,
    },
    reduceKey: {
      type: String,
      required: false,
      default: function () {
        return 'value';
      },
    },
    labelKey: {
      type: String,
      required: false,
      default: function () {
        return 'label';
      },
    },
  },
  computed: {
    rgbColor() {
      return RGB_COLORS;
    },
    computedOptions: function () {
      return this.options.map((o) => {
        return {
          ...o,
          label: o[this.labelKey],
        };
      });
    },
  },
  watch: {
    value: {
      handler: function (value) {
        this.model = value;
      },
      deep: true,
    },
    model: {
      handler: function (value) {
        if (!isEqual(value, this.value)) {
          this.$emit('input', value);
        }
      },
      deep: true,
    },
  },
  created() {
    this.model = this.value;
  },
  data: function () {
    return {
      model: '',
    };
  },
  methods: {
    translate(value) {
      if (this.translated) {
        return value;
      }
      return this.$translate(value);
    },
  },
};
</script>
<style lang="scss" scoped>
.custom-color-v-select {
  width: 10px;
  height: 10px;
  display: inline-block;
}
</style>
