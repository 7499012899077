<template>
  <div>
    <md-table class="paginated-table table-striped table-hover lims-table user-tbl">
      <md-table-toolbar>
        <slot name="toolbar"></slot>
      </md-table-toolbar>
      <div class="table-fix-header">
        <thead>
          <md-table-row v-if="isAdminView">
            <md-table-head v-for="(col, index) in colTablAdmin" :key="index" :class="col.class">
              <div>{{ $t(col.name) }}</div>
            </md-table-head>
          </md-table-row>

          <md-table-row v-if="isClinicAssociateView">
            <md-table-head v-for="(col, index) in colTabClinicAssociate" :key="index" :class="col.class">
              <div>{{ $t(col.name) }}</div>
            </md-table-head>
          </md-table-row>
        </thead>
        <tbody>
          <md-table-row
            v-for="(item, index) in dataset"
            :key="`dataset-${index}`"
            v-long-press="300"
            @click.native="onNavigate($event, item)"
            @long-press-start="onPlusStart"
            @long-press-stop="onPlusStop"
            :class="item.isUsedInAuthorisedCase ? 'color-grey' : 'color-black'"
          >
            <md-table-cell>{{ item.entityName || $t('pages.dataset/list.clinicType.all') }} </md-table-cell>
            <md-table-cell v-if="isAdminView">{{ item.clinicType }}</md-table-cell>
            <md-table-cell>{{ item.dataset }}</md-table-cell>
            <md-table-cell>{{ item.snomedM }}</md-table-cell>
            <md-table-cell>{{ item.snomedP }}</md-table-cell>
            <md-table-cell>{{ item.snomedT }}</md-table-cell>
            <md-table-cell>
              <md-icon :class="[{ 'status-enabled': item.statusName == 'Active' }]">adjust</md-icon>
            </md-table-cell>
            <md-table-cell class="cell-action">
              <div class="cell-action-inner">
                <div style="width: 33%">
                  <md-button
                    v-if="isShowViewIcon"
                    class="md-just-icon md-info md-simple"
                    @click.native="handleAction('view', item)"
                  >
                    <md-icon>visibility</md-icon>
                  </md-button>
                </div>
                <div style="width: 33%">
                  <md-button
                    v-if="isShowEditIcon(item)"
                    class="md-just-icon md-warning md-simple"
                    @click.native="handleAction('edit', item)"
                  >
                    <md-icon>edit</md-icon>
                  </md-button>
                </div>
                <div style="width: 33%">
                  <md-button
                    v-if="isShowDeleteIcon(item)"
                    class="md-just-icon md-danger md-simple"
                    @click.stop="handleAction('delete', item)"
                  >
                    <md-icon>delete_forever</md-icon>
                  </md-button>
                </div>
              </div>
            </md-table-cell>
          </md-table-row>
        </tbody>
      </div>
    </md-table>
  </div>
</template>

<script>
import { DATASET_STATUS_NAME } from '@/core/constants';
export default {
  components: {},
  created() {
    this.userType = this.$store.getters['auth/userType'];
  },
  props: {
    dataset: {
      required: false,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      longPressMode: false,
      plusInterval: null,
      colTablAdmin: [
        {
          name: 'pages.dataset/list.entityName',
          key: 'entityName',
          sort: false,
          class: 'entityName-column',
        },
        {
          name: 'pages.dataset/list.clinicType',
          key: 'clinicType',
          sort: false,
          class: 'clinicType-column',
        },
        {
          name: 'pages.dataset/list.dataset',
          key: 'dataset',
          sort: false,
          class: 'dataset-column',
        },
        {
          name: 'pages.dataset/list.snomedM',
          key: 'snomedM',
          sort: false,
          class: 'snomedM-column',
        },
        {
          name: 'pages.dataset/list.snomedP',
          key: 'snomedP',
          sort: false,
          class: 'snomedP-column',
        },
        {
          name: 'pages.dataset/list.snomedT',
          key: 'snomedT',
          sort: false,
          class: 'snomedT-column',
        },
        {
          name: 'global/pages/list.status',
          key: 'status',
          sort: false,
          class: 'status-column',
        },
        {
          name: 'global/pages/list.actions',
          key: 'actions',
          sort: false,
          class: 'actions-column',
        },
      ],
      colTabClinicAssociate: [
        {
          name: 'pages.dataset/list.entityName',
          key: 'entityName',
          sort: false,
          class: 'entityName-column',
        },
        {
          name: 'pages.dataset/list.dataset',
          key: 'dataset',
          sort: false,
          class: 'dataset-column',
        },
        {
          name: 'pages.dataset/list.snomedM',
          key: 'snomedM',
          sort: false,
          class: 'snomedM-column',
        },
        {
          name: 'pages.dataset/list.snomedP',
          key: 'snomedP',
          sort: false,
          class: 'snomedP-column',
        },
        {
          name: 'pages.dataset/list.snomedT',
          key: 'snomedT',
          sort: false,
          class: 'snomedT-column',
        },
        {
          name: 'global/pages/list.status',
          key: 'status',
          sort: false,
          class: 'status-column',
        },
        {
          name: 'global/pages/list.actions',
          key: 'actions',
          sort: false,
          class: 'actions-column',
        },
      ],
    };
  },
  computed: {
    isAdminView() {
      return this.userType === this.USER_TYPES().Administrator;
    },
    isClinicAssociateView() {
      return this.userType === this.USER_TYPES().ClinicAssociate;
    },
  },
  methods: {
    handleAction(action, item) {
      this.$emit('action', {
        action,
        item,
      });
    },
    onNavigate(e, item) {
      if (!this.longPressMode) {
        e.preventDefault();
        this.handleAction('view', item);
      }
    },
    onPlusStart() {
      this.plusInterval = setInterval(() => {
        this.longPressMode = true;
      }, 100);
    },
    onPlusStop() {
      setTimeout(() => {
        this.longPressMode = false;
      }, 500);
      clearInterval(this.plusInterval);
    },
    isShowViewIcon() {
      return this.$isAuthorized(['MyEntity_DatasetManagement_Retrieve']);
    },
    isShowEditIcon(item) {
      const isEnableManageDataset = this.isAdminView || item.isEnableManageDataset;
      return (
        this.$isAuthorized(['MyEntity_DatasetManagement_Update']) &&
        !item.isUsedInAuthorisedCase &&
        isEnableManageDataset
      );
    },
    isShowDeleteIcon(item) {
      const isEnableManageDataset = this.isAdminView || item.isEnableManageDataset;
      return (
        this.$isAuthorized(['MyEntity_DatasetManagement_Delete']) &&
        item.statusName === DATASET_STATUS_NAME.DEACTIVE &&
        !item.isUsedInAuthorisedCase &&
        isEnableManageDataset
      );
    },
  },
};
</script>
<style lang="scss">
.color-grey {
  color: grey;
}
.color-black {
  color: black;
}
</style>
