<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit, invalid }">
    <form class="md-layout lims-form" @submit.prevent="handleSubmit()">
      <md-dialog
        :md-close-on-esc="true"
        :md-active.sync="isVisible"
        @md-clicked-outside="close"
        class="lims-modal autosize-modal"
      >
        <div class="dialog-icon-close" @click="close"><md-icon>close</md-icon></div>
        <md-dialog-title></md-dialog-title>
        <div class="dialog-content">
          <div class="md-layout lims-form-row">
            <div class="md-layout-item md-size-100 md-small-size-100">
              <lims-field :model="formData" :schema="schema" field="specimenTypeId">
                <multiselect
                  slot="field-outside"
                  v-model="formData.specimenTypeId"
                  :options="specimenTypeList"
                  :multiple="false"
                  :show-labels="false"
                  group-values="items"
                  group-label="itemName"
                  :placeholder="$t('modals/ModalCreateSpecimen/specimenTypeId.placeholder')"
                  track-by="fieldItemId"
                  label="fieldItemName"
                  :hide-selected="false"
                  :group-select="false"
                >
                </multiselect>
              </lims-field>
            </div>
          </div>
          <div class="md-layout lims-form-row">
            <div class="md-layout-item md-size-100 md-small-size-100 dialog-texterea-field">
              <lims-field :model="formData" :schema="schema" field="specimenDetails">
                <md-textarea slot="field" v-model="formData.specimenDetails" type="text"></md-textarea>
              </lims-field>
            </div>
          </div>
          <div class="md-layout lims-form-row">
            <div class="md-layout-item md-size-100 md-small-size-100 dialog-texterea-field">
              <lims-field :model="formData" :schema="schema" field="macroDescription">
                <md-textarea
                  slot="field"
                  v-model="formData.macroDescription"
                  type="text"
                  :placeholder="$t('modals/ModalCreateSpecimen/macroDescription.placeholder')"
                ></md-textarea>
              </lims-field>
            </div>
          </div>
        </div>
        <md-dialog-actions>
          <md-button @click="close" class="lims-form-button">{{ $t('global/button/button.cancel') }}</md-button>
          <md-button @click="onAddSpecimen" :disabled="invalid" class="lims-form-button md-danger">{{
            $t('modals/ModalCreateSpecimen/AddSpecimenButton')
          }}</md-button>
        </md-dialog-actions>
      </md-dialog>
    </form>
  </ValidationObserver>
</template>

<script>
import modalMixins from './modal.mixins';
import { mapGetters } from 'vuex';
import { DROPDOWN_SHORT_NAME } from '@/core/constants';
import { caseFormService } from '@/services';
import { filterDropdownListByHiddenField } from '@/core/helpers';

const mappingSpecimenTypeList = (specimenTypeList) => {
  const groupOptions = [];
  const options = [];
  specimenTypeList.forEach((specimenTypeItem) => {
    const { itemName, items, isHide } = specimenTypeItem;
    if (!isHide) {
      groupOptions.push({
        itemName,
        items: [
          {
            fieldItemName: '',
          },
        ],
      });

      items.forEach((item) => {
        options.push(...item.items);
        groupOptions.push({
          itemName: item.itemName,
          items: filterDropdownListByHiddenField(item.items),
        });
      });
    }
  });
  return { groupOptions, options };
};

export default {
  mixins: [modalMixins],
  data() {
    return {
      formData: {
        specimenTypeId: '',
        specimenDetails: '',
        macroDescription: '',
      },
      specimenTypeList: [],
      specimenTypeOptions: [],
      workStreamId: null,
      clinicId: null,
    };
  },
  computed: {
    ...mapGetters('app/data', ['getDatasetByKey']),
    schema() {
      return {
        entity: 'modals/ModalCreateSpecimen',
        fields: {
          specimenTypeId: 'required',
          specimenDetails: '',
          macroDescription: '',
        },
      };
    },
  },
  watch: {
    async workStreamId() {
      const { groupOptions, options } = await this.loadSpecimenTypeData(this.clinicId, this.workStreamId);
      this.$set(this, 'specimenTypeOptions', options);
      this.$set(this, 'specimenTypeList', groupOptions);
    },
  },
  methods: {
    onAddSpecimen() {
      if (this.formData.specimenTypeId) {
        let specimenType;
        this.specimenTypeList.forEach((l) => {
          l.items.forEach((s) => {
            if (s.fieldItemId === this.formData.specimenTypeId.fieldItemId) {
              specimenType = s;
            }
          });
        });
        if (specimenType) {
          this.$emit('onAddedSpecimen', {
            ...this.formData,
            specimenTypeId: specimenType.fieldItemId,
            specimentTypeName: specimenType.fieldItemName,
          });
          // Wait until the models are updated in the UI
          this.$set(this, 'formData', {
            specimenTypeId: '',
            specimenDetails: '',
            macroDescription: '',
          });

          this.$refs.form.reset();
        }
      }
    },
    async loadSpecimenTypeData(clinicId, workStreamId) {
      if (!workStreamId) {
        return;
      } else {
        const dropdownStainOptions = await caseFormService.getStainByEntityIdInCaseForm(clinicId, [
          DROPDOWN_SHORT_NAME.COMMONLY_USED_SPECIMEN_TYPE_PER_WORK_STREAM,
        ]);
        const specimenTypeList = dropdownStainOptions[DROPDOWN_SHORT_NAME.COMMONLY_USED_SPECIMEN_TYPE_PER_WORK_STREAM];

        // filter specimence type by WorkStream
        if (specimenTypeList.length > 0) {
          let specimenTypeListArr = [];
          const specimenTypeListDependOnWorkStream = specimenTypeList.reduce((i, value) => {
            const listItem = {
              ...value,
              items: value.items.filter((child) => child.itemId == workStreamId),
            };
            if (listItem.items.length > 0) {
              specimenTypeListArr.push(listItem);
            }
            return specimenTypeListArr;
          }, 0);

          return mappingSpecimenTypeList(specimenTypeListDependOnWorkStream);
        } else {
          return null;
        }
      }
    },
  },
};
</script>
<style lang="scss"></style>
