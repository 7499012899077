import { FIELD_TYPES } from './constant';
export const paginationFields = [
  {
    key: 'pageSize',
    listKey: null,
    sort: 1,
    fieldType: FIELD_TYPES.Int,
    defaultValue: 10,
  },
  {
    key: 'pageNumber',
    listKey: null,
    sort: 2,
    fieldType: FIELD_TYPES.Int,
    translated: false,
    defaultValue: 1,
  },
];
