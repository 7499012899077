<template>
  <date-picker
    v-model="date"
    v-if="range"
    range
    :disabled="disabled"
    :lang="{
      formatLocale: {
        firstDayOfWeek: 1,
      },
      monthBeforeYear: false,
    }"
    :type="dataType"
    :format="format"
    :time-picker-options="{ start: '00:00', step: '00:10', end: '23:50' }"
    :placeholder="placeholder"
    tabenable="yes"
  ></date-picker>
  <date-picker
    v-else
    v-model="date"
    :disabled="disabled"
    :lang="{
      formatLocale: {
        firstDayOfWeek: 1,
      },
      monthBeforeYear: false,
    }"
    :type="dataType"
    :format="format"
    :time-picker-options="{ start: '00:00', step: '00:10', end: '23:50' }"
    :placeholder="placeholder"
    tabenable="yes"
  ></date-picker>
</template>

<script>
import moment from 'moment';

export default {
  props: {
    range: {
      type: Boolean,
      default: false,
    },
    format: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    dataType: {
      type: String,
      default: 'date',
    },
    placeholder: {
      type: String,
      default: '',
    },
    value: {},
  },

  created() {
    // value is also isostring
    if (this.range) {
      this.data = this.value ? this.value.map((v) => this.value2Date(v, false)) : [];
    } else {
      this.data = this.value ? this.value2Date(this.value, false) : '';
    }
  },

  data() {
    return {
      data: null,
    };
  },
  computed: {
    timezoneValue() {
      const { timezoneValue } = this.$store.getters['config/$appConfig'];
      let offset = timezoneValue;
      if (!offset.includes('-') && !offset.includes('+')) {
        offset = `+${timezoneValue}`;
      }
      return offset;
    },
    date: {
      get() {
        return this.data;
      },
      set(val) {
        if (this.range) {
          this.data = val;
        } else {
          this.data = val;
        }

        this.$emit('input', this.convertOutput(this.data));
      },
    },
  },
  methods: {
    value2Date(v, output = false) {
      if (output) {
        return new Date(moment(v).utcOffset(this.timezoneValue, true));
      } else {
        const browserOffset = moment().utcOffset();
        return new Date(moment(v).utcOffset(this.timezoneValue, false).utcOffset(browserOffset, true));
      }
    },
    convertOutput(v) {
      if (this.range) {
        return v.map((v) => (v ? this.value2Date(v, true) : v));
      } else {
        return v ? this.value2Date(v, true) : '';
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
