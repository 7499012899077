const DashboardLayout = () => import('@/layouts/DashboardLayout.vue');
import SimpleBooking from '@/pages/Case/SimpleBooking/SimpleBooking.vue';

import guards from './guards';

export default {
  path: '/simple-booking',
  component: DashboardLayout,
  meta: {
    zone: 'private',
    rbac: 'cases',
  },
  beforeEnter: guards.checkAuthorizedGuard,
  children: [
    {
      path: '',
      name: 'SimpleBooking',
      component: SimpleBooking,
      meta: {
        zone: ['Case_SimpleBookingForm_Create'],
      },
    },
  ],
};
