<template>
  <div>
    <modal-confirm-generic
      ref="unSaveChangesModal"
      :description="$t('components/lims/modals/ModalFormCancel.text')"
      @onConfirm="onUnSaveChanges"
    />
    <component
      v-if="userType && userResource"
      v-bind:is="activeUserType"
      :userResource="userResource"
      :userId="userId"
      :userTypeName="userTypeName"
    ></component>
  </div>
</template>

<script>
import Administrator from '@/pages/Account/User/Forms/Administrator/Administrator.vue';
import ClinicAssociate from '@/pages/Account/User/Forms/ClinicAssociate/ClinicAssociate';
import Clinician from '@/pages/Account/User/Forms/Clinician/Clinician';
import ClinicianAssociate from '@/pages/Account/User/Forms/ClinicianAssociate/ClinicianAssociate';
import LabTechnician from '@/pages/Account/User/Forms/LabTechnician/LabTechnician';
import Pathologist from '@/pages/Account/User/Forms/Pathologist/Pathologist';
import DashboardAdmin from '@/pages/Dashboard/Admin/DashboardAdmin';
import UserService from '@/services/user.service';
import { userTypeIdToComponent } from '@/core/helpers';
import { APP_EVENTS, newAppEvent } from '@/core/constants';
import { mapActions, mapGetters } from 'vuex';

import { UnSaveChangesMixins, ResetHeadingTitleMixins } from '@/core/mixins';

const ACTIVE_USER_TYPES = {
  administrator: Administrator,
  clinicAssociate: ClinicAssociate,
  clinician: Clinician,
  clinicianAssociate: ClinicianAssociate,
  labTechnician: LabTechnician,
  pathologist: Pathologist,
};

export default {
  mixins: [UnSaveChangesMixins, ResetHeadingTitleMixins],
  props: {
    userId: {
      require: true,
    },
  },
  component: {
    Administrator,
    ClinicAssociate,
    Clinician,
    ClinicianAssociate,
    LabTechnician,
    Pathologist,
    DashboardAdmin,
  },
  computed: {
    ...mapGetters('app/event', [APP_EVENTS.EVT_ON_USER_PATHOLOGIST_RELOAD]),
    userTypeName() {
      return this.userType;
    },
    activeUserType() {
      return ACTIVE_USER_TYPES[this.userType];
    },
  },
  data: function () {
    return {
      userResource: {},
      userType: false,
    };
  },
  watch: {
    userId(val, oldVal) {
      if (val !== oldVal) {
        this.userResolver(val);
      }
    },
    [APP_EVENTS.EVT_ON_USER_PATHOLOGIST_RELOAD]: {
      deep: true,
      handler: async function (val) {
        if (val) {
          await this.userResolver(this.userId);
          this.removeEvent(newAppEvent(APP_EVENTS.EVT_ON_USER_PATHOLOGIST_RELOAD));
        }
      },
    },
  },
  methods: {
    ...mapActions('app/event', ['removeEvent']),
    async userResolver(userId) {
      if (userId) {
        const { data } = await UserService.findOne(userId);

        this.userResource = data;
        this.userType = userTypeIdToComponent(this.USER_TYPES(), data.personalInfo.userTypeId);
      }
    },
  },
  created() {
    this.userResolver(this.userId);
  },
};
</script>

<style lang="scss" scoped></style>
