<template>
  <div class="legend-search-wrapper">
    <div @click="isShow = !isShow" class="toggle-legend-btn">
      <span v-if="isShow">{{ $t('pages/case/CaseManagement/List/LegendSearch.hide') }}</span>
      <span v-else>{{ $t('pages/case/CaseManagement/List/LegendSearch.show') }}</span>
      <md-icon :class="!isShow ? 'is-hide' : ''">expand_less</md-icon>
    </div>
    <div class="legend-list-wrapper" v-show="isShow">
      <ul v-if="isAdminView" class="legend-list">
        <li class="red-color">{{ $t('pages/case/CaseManagement/List/LegendSearch.Urgent') }}</li>
        <li v-if="!isCaseOfInterest" class="strikethrough">
          {{ $t('pages/case/CaseManagement/List/LegendSearch.Deleted') }}
        </li>
        <li class="blue-color">{{ $t('pages/case/CaseManagement/List/LegendSearch.DraftDiagnosis') }}</li>
        <li>
          <md-icon class="status-disabled">adjust</md-icon>
          {{ $t('pages/case/CaseManagement/List/LegendSearch.Requested') }}
        </li>
        <li>
          <md-icon class="status-invited">adjust</md-icon>
          {{ $t('pages/case/CaseManagement/List/LegendSearch.PartiallyCompleted') }}
        </li>
        <li>
          <md-icon class="status-enabled">adjust</md-icon>
          {{ $t('pages/case/CaseManagement/List/LegendSearch.Completed') }}
        </li>
        <li>
          <md-icon>adjust</md-icon>
          {{ $t('pages/case/CaseManagement/List/LegendSearch.Cancelled') }}
        </li>
        <li>
          <md-icon class="black-color">hourglass_disabled</md-icon>
          {{ $t('pages/case/CaseManagement/List/LegendSearch.PreLab') }}
        </li>
        <li>
          <md-icon class="black-color">hourglass_empty</md-icon>
          {{ $t('pages/case/CaseManagement/List/LegendSearch.Lab') }}
        </li>
        <li>
          <md-icon class="black-color">hourglass_top</md-icon>
          {{ $t('pages/case/CaseManagement/List/LegendSearch.Path') }}
        </li>
        <li>
          <md-icon class="grey-color">hourglass_bottom</md-icon>
          {{ $t('pages/case/CaseManagement/List/LegendSearch.ProvisionallyReported') }}
        </li>
        <li>
          <md-icon class="black-color">hourglass_bottom</md-icon>
          {{ $t('pages/case/CaseManagement/List/LegendSearch.Reported') }}
        </li>
        <li>
          <md-icon class="black-color ext-cancelled-icon">hourglass_disabled</md-icon>
          {{ $t('pages/case/CaseManagement/List/LegendSearch.ExtCancelled') }}
        </li>
      </ul>

      <ul v-if="!isAdminView && !isLabTechnicianView && !isPathologistView" class="legend-list">
        <li class="red-color">{{ $t('pages/case/CaseManagement/List/LegendSearch.Urgent') }}</li>
        <li class="grey-color">{{ $t('pages/case/CaseManagement/List/LegendSearch.Read') }}</li>
        <li>
          <md-icon class="status-disabled">adjust</md-icon>
          {{ $t('pages/case/CaseManagement/List/LegendSearch.Requested') }}
        </li>
        <li>
          <md-icon class="status-invited">adjust</md-icon>
          {{ $t('pages/case/CaseManagement/List/LegendSearch.PartiallyCompleted') }}
        </li>
        <li>
          <md-icon class="status-enabled">adjust</md-icon>
          {{ $t('pages/case/CaseManagement/List/LegendSearch.Completed') }}
        </li>
        <li>
          <md-icon>adjust</md-icon>
          {{ $t('pages/case/CaseManagement/List/LegendSearch.Cancelled') }}
        </li>
        <li>
          <md-icon class="black-color">hourglass_empty</md-icon>
          {{ $t('pages/case/CaseManagement/List/LegendSearch.Lab') }}
        </li>
        <li>
          <md-icon class="black-color">hourglass_top</md-icon>
          {{ $t('pages/case/CaseManagement/List/LegendSearch.Path') }}
        </li>
        <li>
          <md-icon class="grey-color">hourglass_bottom</md-icon>
          {{ $t('pages/case/CaseManagement/List/LegendSearch.ProvisionallyReported') }}
        </li>
        <li>
          <md-icon class="black-color">hourglass_bottom</md-icon>
          {{ $t('pages/case/CaseManagement/List/LegendSearch.Reported') }}
        </li>
      </ul>

      <ul v-if="isLabTechnicianView" class="legend-list">
        <li class="red-color">{{ $t('pages/case/CaseManagement/List/LegendSearch.Urgent') }}</li>
        <li>
          <md-icon class="black-color">hourglass_empty</md-icon>
          {{ $t('pages/case/CaseManagement/List/LegendSearch.Lab') }}
        </li>
        <li>
          <md-icon class="black-color">hourglass_bottom</md-icon>
          {{ $t('pages/case/CaseManagement/List/LegendSearch.Reported') }}
        </li>
      </ul>

      <ul v-if="isPathologistView" class="legend-list">
        <li class="red-color">{{ $t('pages/case/CaseManagement/List/LegendSearch.Urgent') }}</li>
        <li class="blue-color">{{ $t('pages/case/CaseManagement/List/LegendSearch.DraftDiagnosis') }}</li>
        <li>
          <md-icon class="status-disabled">adjust</md-icon>
          {{ $t('pages/case/CaseManagement/List/LegendSearch.Requested') }}
        </li>
        <li>
          <md-icon class="status-invited">adjust</md-icon>
          {{ $t('pages/case/CaseManagement/List/LegendSearch.PartiallyCompleted') }}
        </li>
        <li>
          <md-icon class="status-enabled">adjust</md-icon>
          {{ $t('pages/case/CaseManagement/List/LegendSearch.Completed') }}
        </li>
        <li>
          <md-icon>adjust</md-icon>
          {{ $t('pages/case/CaseManagement/List/LegendSearch.Cancelled') }}
        </li>
        <li>
          <md-icon class="black-color">hourglass_empty</md-icon>
          {{ $t('pages/case/CaseManagement/List/LegendSearch.Lab') }}
        </li>
        <li>
          <md-icon class="black-color">hourglass_top</md-icon>
          {{ $t('pages/case/CaseManagement/List/LegendSearch.Path') }}
        </li>
        <li>
          <md-icon class="grey-color">hourglass_bottom</md-icon>
          {{ $t('pages/case/CaseManagement/List/LegendSearch.ProvisionallyReported') }}
        </li>
        <li>
          <md-icon class="black-color">hourglass_bottom</md-icon>
          {{ $t('pages/case/CaseManagement/List/LegendSearch.Reported') }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isCaseOfInterest: {
      type: Boolean,
      require: false,
    },
  },
  created() {
    this.userType = this.$store.getters['auth/userType'];
  },

  computed: {
    isAdminView() {
      return this.userType === this.USER_TYPES().Administrator;
    },
    isLabTechnicianView() {
      return this.userType === this.USER_TYPES().LabTechnician;
    },
    isPathologistView() {
      return this.userType === this.USER_TYPES().Pathologist;
    },
  },

  data() {
    return {
      isShow: true,
    };
  },
};
</script>
<style lang="scss"></style>
