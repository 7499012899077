<template>
  <div v-if="ready">
    <modal-confirm-generic
      ref="unSaveChangesModal"
      :description="$t('components/lims/modals/ModalFormCancel.text')"
      @onConfirm="onUnSaveChanges"
    />
    <case-form :formMode="formMode"></case-form>
  </div>
</template>

<script>
import CaseForm from '@/pages/Case/CaseManagement/Forms/CaseForm';
import { mapActions } from 'vuex';

import { UnSaveChangesMixins } from '@/core/mixins';
import { DROPDOWN_SHORT_NAME, FORM_MODES } from '@/core/constants';
import { DropdownService, caseFormService, MyEntityService } from '@/services';

export default {
  mixins: [UnSaveChangesMixins],
  components: {
    CaseForm,
  },
  async created() {
    await this.prepareData();
  },
  computed: {
    formMode() {
      return FORM_MODES.ADD;
    },
  },
  data: function () {
    return {
      ready: false,
    };
  },
  methods: {
    ...mapActions('app/data', ['updateDataset']),
    ...mapActions('caseData', ['resetCaseData']),
    async prepareData() {
      // reset case data
      this.resetCaseData();
      const dropdownOptions = await DropdownService.getDropdownByShortNames([
        DROPDOWN_SHORT_NAME.COUNTRY,
        DROPDOWN_SHORT_NAME.GENDER_TYPES,
        DROPDOWN_SHORT_NAME.BILLING_ENTITY,
        DROPDOWN_SHORT_NAME.CASE_DOCUMENT_TYPE,
        DROPDOWN_SHORT_NAME.INSURANCE_COMPANY,
        DROPDOWN_SHORT_NAME.SNOMED_T,
        DROPDOWN_SHORT_NAME.SNOMED_P,
        DROPDOWN_SHORT_NAME.SNOMED_M,
      ]);
      const data = await MyEntityService.getMyEntityName();
      const dropdownStainOptions = await caseFormService.getStainByEntityIdInCaseForm(data.id, [
        DROPDOWN_SHORT_NAME.H_AND_E,
        DROPDOWN_SHORT_NAME.IMMUNOS,
        DROPDOWN_SHORT_NAME.SPECIAL_STAINS,
        DROPDOWN_SHORT_NAME.ADDITIONAL_TECHNIQUE,
      ]);
      this.updateDataset({
        ...dropdownOptions,
        ...dropdownStainOptions,
      });
      this.ready = true;
    },
  },
};
</script>

<style lang="scss" scoped></style>
