<template>
  <div class="input-strength-field">
    <md-icon :class="validated ? 'check-circle-color' : ''">{{ validated ? 'check_circle' : 'info' }}</md-icon>
    <label v-html="description"></label>
  </div>
</template>

<script>
export default {
  props: {
    validated: {
      type: Boolean,
      required: true,
      default: false,
    },
    description: {
      type: String,
      required: true,
      default: null,
    },
  },
};
</script>
<style lang=""></style>
