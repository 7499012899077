<template>
  <div class="filter-block">
    <lims-single-select
      slot="field"
      v-model="formData.block"
      :options="blockList"
      reduceKey="id"
      labelKey="text"
      :translated="true"
      :placeholder="'Block'"
    ></lims-single-select>
    <lims-single-select
      slot="field"
      v-model="formData.number"
      :options="numberList"
      reduceKey="id"
      labelKey="text"
      :translated="true"
      :placeholder="'Number'"
    ></lims-single-select>
    <span @click="onAdd" class="add-button" :class="formData.block && formData.number && !duplicated ? 'can-add' : ''">
      <md-icon>add_circle</md-icon>
    </span>
  </div>
</template>
<script>
import { ALW_FILTER_BLOCK_ALL, ALW_FILTER_BLOCK_NUMBER, ALW_FILTER_BLOCK_CHARACTER } from '@/core/constants';
import { cloneDeep } from 'lodash';

export default {
  props: {
    value: {
      type: Array,
      require: false,
    },
  },
  data() {
    return {
      formData: {
        block: null,
        number: null,
      },
      filterBlock: [],
    };
  },
  computed: {
    blockList() {
      if (this.formData.number) {
        if (/^\d$/.test(this.formData.number)) {
          return ALW_FILTER_BLOCK_CHARACTER;
        } else {
          return ALW_FILTER_BLOCK_NUMBER;
        }
      } else {
        return ALW_FILTER_BLOCK_ALL;
      }
    },
    numberList() {
      if (this.formData.block) {
        if (/^\d$/.test(this.formData.block)) {
          return ALW_FILTER_BLOCK_CHARACTER;
        } else {
          return ALW_FILTER_BLOCK_NUMBER;
        }
      } else {
        return ALW_FILTER_BLOCK_ALL;
      }
    },
    duplicated() {
      return this.filterBlock.includes(this.formData.block + this.formData.number);
    },
  },
  created() {
    this.filterBlock = cloneDeep(this.value);
  },
  watch: {
    value: {
      deep: true,
      handler: function (newValue) {
        this.filterBlock = cloneDeep(newValue);
      },
    },
  },
  methods: {
    onAdd() {
      if (this.formData.number && this.formData.block && !this.duplicated) {
        this.filterBlock.push(this.formData.block + this.formData.number);
        this.$emit('input', cloneDeep(this.filterBlock));
        this.formData.number = null;
        this.formData.block = null;
      }
    },
  },
};
</script>
<style lang="scss">
.filter-block {
  display: inline-flex;
}
.add-button {
  padding-top: 13px;
}
.can-add {
  cursor: pointer;
}
</style>
