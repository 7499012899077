<template>
  <stats-card header-color="primary">
    <template slot="header">
      <div class="card-icon">
        <md-icon>earbuds</md-icon>
      </div>
      <p class="category">{{ $t('pages/Dashboard/Components/ReportedCasesStats/title') }}</p>
    </template>

    <template slot="footer">
      <div class="group-stats-wrapper">
        <div class="group-stats">
          <div class="group-stats-item">
            <h3 @click="reportedCasesNavigate()">
              <span>{{ data.value }}</span>
            </h3>
          </div>
        </div>
        <div class="stats">
          <md-icon>date_range</md-icon>
          {{ data.time }}
        </div>
      </div>
    </template>
  </stats-card>
</template>

<script>
import StatsMixins from './stats.mixins';
import { CASE_STATUS } from '@/core/constants';
import { currentDay, startDay } from '@/core/helpers';
import { mapActions } from 'vuex';

export default {
  props: {
    data: {},
  },
  mixins: [StatsMixins],
  methods: {
    ...mapActions('app', ['$setRedirectName']),
    reportedCasesNavigate() {
      const userType = this.$store.getters['auth/userType'];
      const isPathologistView = userType === this.USER_TYPES()?.Pathologist;
      const isAdminView = userType === this.USER_TYPES()?.Administrator;
      const twoDayBefore = new Date().setDate(new Date().getDate() - 2);
      const lastTwoWeeks = new Date().setDate(new Date().getDate() - 14);
      const date = isPathologistView || isAdminView ? twoDayBefore : lastTwoWeeks;
      const reportedDate = startDay(date) + ',' + currentDay();
      this.$setRedirectName('ReportedCases');
      this.$navigateToList({
        path: 'cases',
        query: { CaseStatusIds: CASE_STATUS.REPORTED, ReportedDate: reportedDate, IsIncludedReportedCase: true },
      });
    },
  },
};
</script>

<style></style>
