import CountryService from '@/services/country.service';
import UserService from '@/services/user.service';
import ModalConfirmation from '@/components/Lims/modals/ModalConfirmation.vue';
import ModalConfirmGeneric from '@/components/Lims/modals/ModalConfirmGeneric.vue';
import ModalValidateUser from '@/components/Lims/modals/ModalValidateUser.vue';
import UserPolicyActions from './Components/UserPolicyActions.vue';
import { MyAccountService, MyEntityService, RoleService } from '@/services';
import { APP_ROUTES, FORM_MODES, PENDING_ACTION_TYPE, PENDING_ACTION_TYPE_ENUM, USER_STATUSES } from '@/core/constants';
import { ENABLE_USER_ERROR } from '@/core/error-constants';
import { FormMixins, ConfigMixins } from '@/core/mixins';
import { DateTimeHelper } from '@/core/helpers';
import { VERIFY_SMS_ERROR } from '@/core/error-constants';
import { partiallyHidePhoneNumber } from '@/core/helpers';
import { partiallyHideEmail } from '@/core/helpers';

import NotificationService from '@/services/notifications.service';

export const USER_PROFILE_VIEW_TYPES = {
  DEFAULT: 'default',
  MY_PROFILE: 'myProfile',
  MY_ENTITY: 'myEntity',
};

export const UPDATE_TWO_FA_MODE = {
  EMAIL: 1,
  PHONE: 2,
};

export default {
  mixins: [FormMixins, ConfigMixins],
  components: {
    ModalConfirmation,
    ModalConfirmGeneric,
    ModalValidateUser,
    UserPolicyActions,
  },
  props: {
    viewType: {
      type: String,
      require: false,
      default: USER_PROFILE_VIEW_TYPES.DEFAULT,
    },
  },
  watch: {
    'formData.personalInfo.roleId': {
      deep: true,
      handler: async function (role, prevRole) {
        if (role && (!prevRole || (prevRole && role.value !== prevRole.value))) {
          // load notification setting depends on role

          await this.$setupNotificationSetting(role.value);
        }
      },
    },
  },
  data() {
    return {
      dataEditChange: {
        email: false,
        telephoneMobile: false,
        hasLogin: false,
      },
      isWrongPassword: false,
      isWrongCode: false,
      firstLoad: true,
    };
  },
  computed: {
    $editUrl() {
      return this.$isMyEntityView()
        ? APP_ROUTES.MYENTITY_USERS_FORM_EDIT + '/' + this.userResource.userId
        : APP_ROUTES.USER_EDIT + '/' + this.userResource.userId;
    },
    isDefaultView() {
      return this.viewType === USER_PROFILE_VIEW_TYPES.DEFAULT;
    },
    isMyProfileView() {
      return this.viewType === USER_PROFILE_VIEW_TYPES.MY_PROFILE;
    },
    isMyEntityView() {
      return this.viewType === USER_PROFILE_VIEW_TYPES.MY_ENTITY;
    },
    isAddMode() {
      return this.formMode === FORM_MODES.ADD;
    },
    isEditMode() {
      return this.formMode === FORM_MODES.EDIT;
    },
    isViewMode() {
      return this.formMode === FORM_MODES.VIEW;
    },
    isDisabledUser() {
      if (this.$isMyEntityView()) {
        return (
          this.userResource &&
          this.userResource.statusId === USER_STATUSES.Disabled &&
          this.$isAuthorized(['MyEntity_UserManagement_Update']) &&
          !this.userResource.isPending
        );
      } else {
        return (
          this.userResource &&
          this.userResource.statusId === USER_STATUSES.Disabled &&
          this.$isAuthorized(['Accounts_UserManagement_Update']) &&
          !this.userResource.isSuperAdmin
        );
      }
    },
    isEnabledUser() {
      if (this.$isMyEntityView()) {
        return (
          this.userResource &&
          this.userResource.statusId === USER_STATUSES.Enabled &&
          this.$isAuthorized(['MyEntity_UserManagement_Update']) &&
          !this.userResource.isPending
        );
      } else {
        return (
          this.userResource &&
          this.userResource.statusId === USER_STATUSES.Enabled &&
          this.$isAuthorized(['Accounts_UserManagement_Update']) &&
          !this.userResource.isSuperAdmin
        );
      }
    },
    canSave() {
      return (!this.viewMode && this.$isAuthorized(['Accounts_UserManagement_Update'])) || this.isMyProfileView;
    },
    isValidateUser() {
      return (
        this.userResource &&
        this.userResource?.isPending === true &&
        this.$isAuthorized(['Accounts_UserManagement_Validate']) &&
        !this.userResource.isSuperAdmin
      );
    },
    isResetPasswordUser() {
      if (
        this.userResource &&
        this.$isAuthorized(['Accounts_UserManagement_Update', 'MyEntity_UserManagement_Update']) &&
        this.userResource.statusId === USER_STATUSES.Enabled &&
        !this.userResource.isPending &&
        this.userResource.login.hasLogin
      ) {
        return true;
      } else {
        return false;
      }
    },
    isCheckCompleteAccount() {
      if (this.userResource.login.hasLogin && !this.userResource.isCompleteAccount) {
        return true;
      } else if (
        (this.userResource.login.hasLogin && !this.userResource.isCompleteAccount) ||
        !this.userResource.login.hasLogin
      ) {
        return false;
      } else {
        return false;
      }
    },
    getLastTimeLogin() {
      const { timezoneValue, dateTimeAppFormat } = this.$store.getters['config/$appConfig'];
      return DateTimeHelper.formatDateTimeGlobal(this.userResource.lastTimeLogin, {
        timezoneValue,
        dateTimeAppFormat,
      });
    },
    getListPendingActionTypeID() {
      const listId = [];
      const listName = [];
      if (this.userResource.pendingActionValidations) {
        Object.values(this.userResource.pendingActionValidations).forEach(function (val) {
          listId.push(val.pendingActionTypeId);
        });
        const action = PENDING_ACTION_TYPE_ENUM.filter((item) => listId.includes(item.id));
        const actionTranslate = action.map((item) => {
          return {
            value: item.id,
            text: this.$t(item.text),
          };
        });
        actionTranslate.forEach(function (val) {
          listName.push(val.text);
        });
      }
      return listName;
    },
  },

  methods: {
    async loadCountries() {
      const { data } = await CountryService.find();
      return data.map((item) => {
        return {
          value: item.id,
          label: item.text,
        };
      });
    },

    async loadRoleList(userTypeId) {
      const { data } = await RoleService.getSelectList({
        userTypeId: userTypeId,
      });
      return data.map((item) => {
        return {
          value: item.id,
          label: item.text,
        };
      });
    },

    $setCountryAndMobileCodeFollowGlobalVariablesDataInAddMode() {
      if (this.formMode === FORM_MODES.ADD) {
        this.formData.personalInfo.countryId = this.countries.find((e) => e.value == this.$appConfig.countryId);
        this.telephoneMobileData = {
          countryCodeMobile: this.$appConfig.countryCodeMobile,
          localeMobile: this.$appConfig.localeMobile,
          countryIdMobile: this.$appConfig.countryId,
        };
      }
    },

    $getTelephoneMobileData() {
      return {
        countryCodeMobile: this.formData.personalInfo.telephoneMobile
          ? this.formData.personalInfo.countryCodeMobile
          : this.$appConfig.countryCodeMobile,
        telephoneMobile: this.formData.personalInfo.telephoneMobile,
        localeMobile: this.formData.personalInfo.localeMobile,
        countryIdMobile: this.formData.personalInfo.telephoneMobile
          ? this.formData.personalInfo.countryIdMobile
          : this.$appConfig.countryId,
      };
    },
    $getTelephoneMobileDataInVerify() {
      return {
        countryCodeMobile: this.formData.personalInfo.telephoneMobile
          ? this.formData.personalInfo.countryCodeMobile
          : this.$appConfig.countryCodeMobile,
        telephoneMobile: this.formData.personalInfo.telephoneMobile,
        localeMobile: this.formData.personalInfo.localeMobile,
        countryIdMobile: this.formData.personalInfo.telephoneMobile
          ? this.formData.personalInfo.countryIdMobile
          : this.$appConfig.countryId,
      };
    },

    $onConfirmDisabled(values) {
      // on confirm modal
      const { password } = values;
      this.$disableUser({
        userId: this.userId,
        password,
        userType: this.$t(this.formData.personalInfo.userType.label),
        firstName: this.formData.personalInfo.firstname,
        lastName: this.formData.personalInfo.lastname,
      });
    },
    $onDisableUser() {
      this.$refs.confirmModal.open();
    },

    $onConfirmEnable() {
      this.$enableUser({
        userId: this.userId,
        userType: this.$t(this.formData.personalInfo.userType.label),
        firstName: this.formData.personalInfo.firstname,
        lastName: this.formData.personalInfo.lastname,
      });
    },

    $onEnableUser() {
      if (this.$isMyEntityView()) {
        this.$refs.confirmEnableUserModal.open();
      } else {
        this.$enableUser({
          userId: this.userId,
          userType: this.$t(this.formData.personalInfo.userType.label),
          firstName: this.formData.personalInfo.firstname,
          lastName: this.formData.personalInfo.lastname,
        });
      }
    },

    mappingClinicInfo(clinicData, status) {
      if (this.isDefaultView || this.isMyProfileView) {
        return clinicData.map((item) => {
          return {
            value: item.entityId,
            label: item.entityName,
            clinicName: item.entityName,
            clinicType: item.clinicTypeName,
            address: item.address1,
            email: item.email,
            status: status,
          };
        });
      }
    },

    async $disableUser({ userId, userType, firstName, lastName, password }) {
      const res = this.isMyEntityView
        ? await MyEntityService.disableUser({ userId, password })
        : await UserService.disable({ userId, password });

      if (res.err) {
        this.isWrongPassword = true;
      } else {
        if (this.$isAuthorized(['Accounts_UserManagement_Update'])) {
          this.$alertSuccess(
            this.$t(`entities/user/status/disable.success`, {
              userType,
              firstName,
              lastName,
            }),
          );
        } else {
          this.$alertSuccess(
            this.$t(`entities/user/status/pendingReview`, {
              firstName,
              lastName,
            }),
          );
        }
        this.$refs.confirmModal.close();
        await this.$nextTick(function () {
          this.userModel.statusId = USER_STATUSES.Disabled;
          this.onCancel();
        });
        // update user status
        return { err: false };
      }
    },
    async $enableUser({ userId, userType, firstName, lastName }) {
      const res = this.isMyEntityView
        ? await MyEntityService.enableUser({ userId })
        : await UserService.enable({ userId });
      if (res.err) {
        if (res.error === ENABLE_USER_ERROR.ROLE_IS_DISABLE) {
          this.$alertError(this.$t(`entities/user/status/enable.roleIsDisableError`));
          return { err: this.$t(`entities/user/status/enable.roleIsDisableError`) };
        }
        if (res.error === ENABLE_USER_ERROR.ENTITY_IS_DISABLE) {
          this.$alertError(this.$t(`entities/user/status/enable.entityIsDisableError`));
          return { err: this.$t(`entities/user/status/enable.entityIsDisableError`) };
        }
      } else {
        if (this.$isAuthorized(['Accounts_UserManagement_Update'])) {
          this.$alertSuccess(
            this.$t(`entities/user/status/enable.success`, {
              userType,
              firstName,
              lastName,
            }),
          );
        } else {
          this.$alertSuccess(
            this.$t(`entities/user/status/pendingReview`, {
              firstName,
              lastName,
            }),
          );
        }
      }

      await this.$nextTick(function () {
        this.userModel.statusId = USER_STATUSES.Enabled;
        this.onCancel();
      });
      return { err: false };
    },

    async validateServerSideFields() {
      const fields = Reflect.ownKeys(this.userSchema._serverSideValidators);
      const validationRequests = fields.map((fieldName) => {
        return this.formData?.personalInfo[fieldName]
          ? this.$onServerSideValidation(
              this.formData?.personalInfo[fieldName],
              this.userSchema._serverSideValidators[fieldName],
            )
          : true;
      });

      const validationResults = await Promise.all(validationRequests);
      return validationResults.every((el) => el === true);
    },

    async $onSave(onConfirm = false) {
      try {
        const isValid = onConfirm ? true : await this.$refs.form.validate();
        const validationResults = await this.validateServerSideFields();

        // if ADD_MODE and hasLogin is unchecked display a confirm modal
        // if ADD_MODE and hasLogin is checked or EDIT_MODE then save
        // TODO : will update later when spec done
        if (this.telephoneMobileData.telephoneMobile && !this.telephoneMobileData.countryCodeMobile) {
          return this.$alertError(this.$t('global/errors/noInputCountryCodeMobile'));
        }

        if (isValid && validationResults) {
          if (this.formMode === FORM_MODES.ADD && !onConfirm) {
            if (this.isMyEntityView) {
              this.$refs.confirmCreateNewUserMyEntityModal.open();
              return;
            } else {
              if (!this.formData.login.hasLogin) {
                this.$refs.hasLoginConfirmModal.open();
                return;
              }
            }
          }
          if (this.formMode === FORM_MODES.EDIT && this.$isMyEntityView() && !onConfirm) {
            this.compareValueEditChange();
            if (Object.values(this.dataEditChange).includes(true)) {
              this.$refs.confirmUpdateMyEntityUserModal.open();
              return;
            }
          }
          const res = this.formMode === FORM_MODES.ADD ? await this.createUser() : await this.updateUser();
          if (res.error) {
            if (res.error === 'InvalidPhoneNumber') {
              return this.$alertError(this.$t(`global/errors/invalidPhoneNumber`));
            }
            if (res.error === 'UnverifiablePhoneNumber') {
              return this.$alertError(this.$t(`global/errors/unverifiablePhoneNumber`));
            }
            if (res.error === 'UserIsExisted') {
              return this.$alertError(this.$t(`global/errorServerSide/UserIsExisted`));
            }
            if (res.error === 'NotFound') {
              return this.$alertError(this.$t(`global/errorServerSide/NotFound`));
            }
            if (res.data && res.data.errors && res.data.errors[0].field === 'telephoneOffice') {
              return this.$alertError(this.$t(`global/errors/unverifiablePhoneNumber`));
            }
            return this.$alertError(res.error);
          }
          if (res.errors && res.errors.length > 0) {
            this.$alertError(this.$t(`global/errors/message`));
            return this.$refs.form.setErrors(this.$transformErrors(res.errors));
          }
          this.$showSuccessMessage();
          setTimeout(async () => {
            this.$resetChangeDetection();
            if (this.isDefaultView) {
              await this.$router.push(APP_ROUTES.USER);
            }
            if (this.isMyEntityView) {
              await this.$router.push(APP_ROUTES.MYENTITY_USERS);
            }
          }, 1500);
        } else {
          this.$alertError(this.$t(`global/errors/message`));
        }
      } catch (error) {
        this.$alertError('Err: ' + error);
      }
    },

    $onHasLoginConfirmModal() {
      if (this.formMode === FORM_MODES.ADD) {
        this.$refs.hasLoginConfirmModal.close();
        // trigger save
        this.$onSave(true);
      }
    },

    $onConfirmUpdateMyEntityUser() {
      this.$refs.confirmUpdateMyEntityUserModal.close();
      // trigger save
      this.$onSave(true);
    },

    $onConfirmCreateNewUserMyEntity() {
      this.$refs.confirmCreateNewUserMyEntityModal.close();
      // trigger save
      this.$onSave(true);
    },

    compareValueEditChange() {
      if (this.formData.personalInfo.email !== this.userResource.personalInfo.email) {
        this.dataEditChange.email = true;
      }
      if (this.formData.personalInfo.email === this.userResource.personalInfo.email) {
        this.dataEditChange.email = false;
      }
      if (
        typeof this.telephoneMobileData.countryCodeMobile !== 'undefined' &&
        (this.telephoneMobileData.telephoneMobile !== this.userResource.personalInfo.telephoneMobile ||
          this.telephoneMobileData?.countryCodeMobile?.code !== this.userResource.personalInfo.countryCodeMobile)
      ) {
        this.dataEditChange.telephoneMobile = true;
      }
      if (
        typeof this.telephoneMobileData.countryCodeMobile !== 'undefined' &&
        (this.telephoneMobileData.telephoneMobile === this.userResource.personalInfo.telephoneMobile ||
          this.telephoneMobileData?.countryCodeMobile?.code === this.userResource.personalInfo.countryCodeMobile)
      ) {
        this.dataEditChange.telephoneMobile = false;
      }
      if (this.formData.login.hasLogin !== this.userResource.login.hasLogin) {
        this.dataEditChange.hasLogin = true;
      }
      if (this.formData.login.hasLogin === this.userResource.login.hasLogin) {
        this.dataEditChange.hasLogin = false;
      }
    },

    // validate user
    $onOpenModalValidateUser() {
      this.$refs.validateUserModal.open();
    },

    $onApproveValidate(values) {
      // on approve modal
      this.$validateUser({
        userId: this.userId,
        userType: this.$t(this.formData.personalInfo.userType.label),
        firstName: this.formData.personalInfo.firstname,
        lastName: this.formData.personalInfo.lastname,
        pendingActionList:
          values.listValidate.length > 0
            ? values.listValidate
            : [this.userResource.pendingActionValidations[0].pendingUserValidationId],
        action: this.getListPendingActionTypeID.toString(),
      });
    },
    $onRejectValidate(values) {
      this.$rejectUser({
        userId: this.userId,
        userType: this.$t(this.formData.personalInfo.userType.label),
        firstName: this.formData.personalInfo.firstname,
        lastName: this.formData.personalInfo.lastname,
        pendingActionList:
          values.listValidate.length > 0
            ? values.listValidate
            : [this.userResource.pendingActionValidations[0].pendingUserValidationId],
        action: this.getListPendingActionTypeID.toString(),
      });
    },

    async $validateUser({ userId, userType, firstName, lastName, action, pendingActionList }) {
      const res = await UserService.validate({ userId, pendingActionList });
      if (res.err) {
        this.$alertError(res.err.response.data.error);
      } else {
        let message = '';
        if (this.userResource.pendingActionValidations.length > 0) {
          message = this.$t(`entities/user/validateAction.approve.messageSucess.manyAction`, {
            userType,
            firstName,
            lastName,
            action,
          });
        } else {
          const actionId = this.userResource.pendingActionValidations[0].pendingActionTypeId;
          message = this.$t(
            actionId === PENDING_ACTION_TYPE.Created
              ? `entities/user/validateAction.approve.messageSucess.invitedUser`
              : actionId === PENDING_ACTION_TYPE.EnableUser
              ? `entities/user/validateAction.approve.messageSucess.enabledUser`
              : actionId === PENDING_ACTION_TYPE.DisableUser
              ? `entities/user/validateAction.approve.messageSucess.disabledUser`
              : actionId === PENDING_ACTION_TYPE.EnableHasLogin || actionId === PENDING_ACTION_TYPE.DisableHasLogin
              ? `entities/user/validateAction.approve.messageSucess.hasLoginUser`
              : `entities/user/validateAction.approve.messageSucess.manyAction`,
            {
              userType,
              firstName,
              lastName,
              action,
            },
          );
        }
        this.$router.push(APP_ROUTES.USER).then(() => {
          this.$alertSuccess(message);
        });
      }
    },

    async $rejectUser({ userId, userType, firstName, lastName, action, pendingActionList }) {
      const res = await UserService.reject({ userId, pendingActionList });
      if (res.err) {
        this.$alertError(res.err.response.data.error);
      } else {
        this.onCancel();
        if (this.userResource.pendingActionValidations.length > 0) {
          this.$alertSuccess(
            this.$t(`entities/user/validateAction.reject.messageSucess.manyAction`, {
              userType,
              firstName,
              lastName,
              action,
            }),
          );
        } else {
          const actionId = this.userResource.pendingActionValidations[0].pendingActionTypeId;
          this.$alertSuccess(
            this.$t(
              actionId === PENDING_ACTION_TYPE.Created
                ? `entities/user/validateAction.reject.messageSucess.invitedUser`
                : actionId === PENDING_ACTION_TYPE.EnableUser
                ? `entities/user/validateAction.reject.messageSucess.enabledUser`
                : actionId === PENDING_ACTION_TYPE.DisableUser
                ? `entities/user/validateAction.reject.messageSucess.disabledUser`
                : actionId === PENDING_ACTION_TYPE.EnableHasLogin || actionId === PENDING_ACTION_TYPE.DisableHasLogin
                ? `entities/user/validateAction.reject.messageSucess.hasLoginUser`
                : `entities/user/validateAction.reject.messageSucess.manyAction`,
              {
                userType,
                firstName,
                lastName,
                action,
              },
            ),
          );
        }
      }
    },

    async $onResetPassword() {
      const userId = this.userId;
      const res = this.isMyEntityView
        ? await MyEntityService.resetPasswordOfMyEntityIsUser({ userId })
        : await UserService.userResetPassword({ userId });
      if (res.err) {
        return this.$alertError(res.error);
      } else {
        if (!this.isCompleteAccount) {
          this.$alertSuccess(
            this.$t('entities/entity/status/resetPassword/isNotCompleteAccount.success', {
              userType: this.$t(this.formData.personalInfo.userType.label),
              firstName: this.formData.personalInfo.firstname,
              lastName: this.formData.personalInfo.lastname,
            }),
          );
        } else {
          this.$alertSuccess(
            this.$t('entities/entity/status/resetPassword.success', {
              userType: this.$t(this.formData.personalInfo.userType.label),
              firstName: this.formData.personalInfo.firstname,
              lastName: this.formData.personalInfo.lastname,
            }),
          );
        }
      }
    },
    // SHOW SUCCESS MESSAGE FUNCTION
    $showSuccessMessage() {
      if (this.formMode === FORM_MODES.ADD) {
        return this.$alertSuccess(
          this.$t(`global/alert/create`, {
            userType: !this.$isMyEntityView() ? this.$t(this.formData.personalInfo.userType.label) : '',
            firstName: this.formData.personalInfo.firstname,
            lastName: this.formData.personalInfo.lastname,
          }),
        );
      }
      // else edit mode
      if (this.$isDefaultView() || this.$isMyEntityView()) {
        return this.$alertSuccess(
          this.$t(`global/alert/update`, {
            userType: !this.$isMyEntityView() ? this.$t(this.formData.personalInfo.userType.label) : '',
            firstName: this.formData.personalInfo.firstname,
            lastName: this.formData.personalInfo.lastname,
          }),
        );
      }
      if (this.$isMyProfileView()) {
        if (this.isPathologistView) {
          this.$store.dispatch('config/$UPDATE_PATH_PULL_SYSTEM', {
            value: this.formData.personalInfo.pullSystem,
          });
        }
        return this.$alertSuccess(this.$t('pages/myAccount/profile/update/updateSuccess'));
      }
    },
    // CANCEL BUTTON CLICK
    $redirectUrl() {
      if (this.$isDefaultView() || this.$isMyEntityView) {
        return '';
      } else if (this.$isMyProfileView()) {
        return APP_ROUTES.DASHBOARD;
      } else {
        return APP_ROUTES.DASHBOARD;
      }
    },
    onCancel() {
      this.$resetChangeDetection();
      setTimeout(() => {
        if (this.$redirectUrl() !== '') {
          this.$router.push({ path: this.$redirectUrl() });
        } else {
          this.$router.back();
        }
      }, 500);
    },
    // FUNCTION TO DETECT MY PROFILE VIEW
    $isDefaultView() {
      return this.viewType === USER_PROFILE_VIEW_TYPES.DEFAULT;
    },
    $isMyProfileView() {
      return this.viewType === USER_PROFILE_VIEW_TYPES.MY_PROFILE;
    },
    $isMyEntityView() {
      return this.viewType === USER_PROFILE_VIEW_TYPES.MY_ENTITY;
    },

    $getUserMixins() {
      return {
        isNotAllowedUpdatePhoneOrEmail: () => {
          const isAdminEditMode = this.$isDefaultView() && this.formMode === FORM_MODES.EDIT;
          // Replace "Accounts_UpdateUser-Email&telephone(mobile)_Update"
          // with "Accounts_UserManagement_UpdateEmailOrPhone"
          return isAdminEditMode && !this.$isAuthorized(['Accounts_UserManagement_UpdateEmailOrPhone']);
        },

        isNotAllowedUpdatePhoneOrEmailMyEntityMode: () => {
          const isMyEntityEditMode = this.$isMyEntityView() && this.formMode === FORM_MODES.EDIT;
          return isMyEntityEditMode && !this.$isAuthorized(['MyEntity_UserManagement_UpdateEmailOrPhone']);
        },
      };
    },

    $canEdit() {
      const item = this.userResource;
      return (
        this.$isAuthorized(['Accounts_UserManagement_Update', 'MyEntity_UserManagement_Update']) &&
        item.statusId !== USER_STATUSES.Disabled &&
        !item.isPending &&
        !item.isSuperAdmin &&
        item.userId !== this.$store.getters['auth/userId']
      );
    },

    // load notification settings
    async $setupNotificationSetting(roleId = '') {
      let notifications = await this.getNotificationSetting({ roleId });
      if (this.firstLoad && (this.isEditMode || this.isViewMode)) {
        this.firstLoad = false;
        this.$nextTick(() => {
          this.$resetChangeDetection();
        });
      } else {
        this.$set(this.formData, 'notificationSettings', notifications);
      }
    },
    async getNotificationSetting({ userId = '', roleId = '' }) {
      let res = null;
      if (userId) {
        res = await NotificationService.getNotificationSettingByUser(userId);
      }
      if (roleId) {
        res = await NotificationService.getNotificationSettingByRole(roleId);
      }
      const { data, error } = res;
      if (error) {
        this.$alertErrorServerSide(error);
      }
      return data;
    },
    // EDIT PHONE, EMAIL FUNCTION
    onClickUpdateEmail() {
      this.$refs.inputNewEmailModal.open();
    },
    onClickUpdateTelephone() {
      this.$refs.inputYourTelephoneModal.open();
    },
    async onCompleteInputNewTelephone(phoneNumberFromInputModal) {
      this.countryCode = phoneNumberFromInputModal.countryCodeMobile.code;
      this.phoneNumber = phoneNumberFromInputModal.telephoneMobile;
      Reflect.set(this.formData.personalInfo, 'countryCodeMobileObject', phoneNumberFromInputModal.countryCodeMobile);
      this.verifyMode = UPDATE_TWO_FA_MODE.PHONE;
      const { error, data } = await MyAccountService.sendCodeUpdatePhone(
        this.userId,
        this.countryCode,
        this.phoneNumber,
      );
      // const { error, data } = { error: null, data: true };
      if (error) {
        this.handleSendCodeUpdatePhoneOrEmailResponseError(error);
      } else {
        this.formData.personalInfo = {
          ...this.formData.personalInfo,
          ...phoneNumberFromInputModal,
        };
        this.telephoneMobileData = this.$getTelephoneMobileData();
        this.handleSendCodeUpdatePhoneResponseData(data);
      }
    },
    async onCompleteInputNewEmail(emailFromInputModal) {
      this.$refs.inputNewEmailModal.close();
      this.email = emailFromInputModal;
      this.verifyMode = UPDATE_TWO_FA_MODE.EMAIL;
      const { error, data } = await MyAccountService.sendCodeUpdateEmail(this.userId, this.email);
      if (error) {
        this.handleSendCodeUpdatePhoneOrEmailResponseError(error);
      } else {
        this.handleSendCodeUpdateEmailResponseData(data);
      }
    },
    handleSendCodeUpdatePhoneOrEmailResponseError(error) {
      switch (error) {
        case VERIFY_SMS_ERROR.ServiceIsUnavailable:
          this.$alertError(this.$t('page/auth/CompleteYourAccount/error/serverIsUnAvailable'));
          break;
        case VERIFY_SMS_ERROR.UnverifiablePhoneNumber:
          this.$alertError(this.$t('global/errorServerSide/UnverifiablePhoneNumber'));
          break;
        default:
          this.$alertError('Error: ' + error);
      }
    },
    handleSendCodeUpdateEmailResponseData(data) {
      if (data) {
        this.verifyContent = partiallyHideEmail(this.email);
        this.$refs.verifyCodeModal.open();
      }
    },
    handleSendCodeUpdatePhoneResponseData(data) {
      if (data) {
        let telephoneNumber = '+' + this.countryCode + this.phoneNumber;
        this.verifyContent = partiallyHidePhoneNumber(telephoneNumber);
        this.$refs.verifyCodeModal.open();
      }
    },
    async onVerifyCode(codeFromVerifyModal) {
      if (this.verifyMode === UPDATE_TWO_FA_MODE.PHONE) {
        const verifyPhonePayload = {
          userId: this.userId,
          countryCode: this.countryCode,
          phoneNumber: this.phoneNumber,
          code: codeFromVerifyModal.code,
          countryIdMobile: this.formData.personalInfo.countryCodeMobileObject?.countryId,
          locale: this.formData.personalInfo.countryCodeMobileObject?.locale,
        };

        const { error, data } = await MyAccountService.verifyCodeUpdatePhone(verifyPhonePayload);

        if (error) {
          this.handleVerifyCodeResponseError(error);
        } else {
          this.handleVerifyCodeResponseData(data);
        }
      } else {
        if (this.verifyMode === UPDATE_TWO_FA_MODE.EMAIL) {
          const { error, data } = await MyAccountService.verifyCodeUpdateEmail(
            this.userId,
            this.email,
            codeFromVerifyModal.code,
          );

          if (error) {
            this.handleVerifyCodeResponseError(error);
          } else {
            this.handleVerifyCodeResponseData(data);
          }
        }
      }
    },
    handleVerifyCodeResponseError(error) {
      switch (error) {
        case VERIFY_SMS_ERROR.ServiceIsUnavailable:
          this.$alertError(this.$t('page/auth/CompleteYourAccount/error/serverIsUnAvailableSms'));
          break;
        case VERIFY_SMS_ERROR.MaxSendAttemptsReached:
          this.$alertError(this.$t('page/auth/CompleteYourAccount/error/waitTenMinutes'));
          break;
        case VERIFY_SMS_ERROR.VerifyCodeIncorrect:
          this.isWrongCode = true;
          break;
        default:
          this.$alertError('Error: ' + error);
      }
    },
    handleVerifyCodeResponseData(data) {
      if (data) {
        this.$refs.verifyCodeModal.close();
        this.$refs.verifySuccessfullyModal.open();
      }
    },
    async onResendVerifyCode() {
      if (this.verifyMode === UPDATE_TWO_FA_MODE.PHONE) {
        const { error } = await MyAccountService.sendCodeUpdatePhone(this.userId, this.countryCode, this.phoneNumber);

        if (error) {
          this.$alertError('Error: ' + error);
        } else {
          this.$alertSuccess(this.$t('page/auth/CompleteYourAccount/notification/newCodeSent'));
        }
      } else if (this.verifyMode === UPDATE_TWO_FA_MODE.EMAIL) {
        const { error } = await MyAccountService.sendCodeUpdateEmail(this.userId, this.email);

        if (error) {
          this.$alertError('Error: ' + error);
        } else {
          this.$alertSuccess(this.$t('page/auth/CompleteYourAccount/notification/newCodeSent'));
        }
      }
    },
    onUpdateSuccessfully() {
      this.$refs.verifySuccessfullyModal.close();
      this.$router.go();
    },
  },
};
