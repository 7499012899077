<template>
  <md-dialog :md-close-on-esc="true" :md-active.sync="isVisible" @md-clicked-outside="close" class="lims-modal">
    <div class="dialog-icon-close" @click="close"><md-icon>close</md-icon></div>
    <div class="lims-form dialog-content">
      <p class="left-text">{{ $t('component/lims/modals/modalScanQrCode/step1') }}</p>
      <p class="left-text">{{ $t('component/lims/modals/modalScanQrCode/step2') }}</p>
      <p class="left-text">{{ $t('component/lims/modals/modalScanQrCode/step3') }}</p>
      <div class="qr-code-position">
        <qrcode-vue :value="qrCodeUri" :size="200" level="L" />
        <p>
          {{ $t('component/lims/modals/modalScanQrCode/cannotScanQr') }}
          <span class="text-underline" @click="onClickShowSecretCode">
            {{ $t('component/lims/modals/modalScanQrCode/getCodeDirectly') }}</span
          >
        </p>
      </div>
    </div>
    <md-dialog-actions>
      <md-button @click="onCancel" class="lims-form-button">{{ $t('global/button/button.cancel') }}</md-button>
      <md-button @click="onNext" class="lims-form-button md-primary">
        {{ $t('component/lims/modals/modalScanQrCode/nextBtn') }}
      </md-button>
    </md-dialog-actions>
  </md-dialog>
</template>

<script>
import modalMixins from './modal.mixins';
import QrcodeVue from 'qrcode.vue';

export default {
  name: 'modal-scan-qr-code',

  mixins: [modalMixins],

  components: {
    QrcodeVue,
  },

  props: {
    qrCodeUri: {
      type: String,
    },
  },

  methods: {
    onNext() {
      this.$emit('onNext');
    },

    onCancel() {
      this.close();
    },

    onClickShowSecretCode() {
      this.$emit('onShowSecretCode');
    },
  },
};
</script>

<style lang="scss">
.left-text {
  text-align: left;
}
.qr-code-position {
  text-align: center;
}
</style>
