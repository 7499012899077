<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit, invalid }">
    <form class="md-layout lims-form form-login" @submit.prevent="handleSubmit()">
      <md-dialog :md-close-on-esc="true" :md-active.sync="isVisible" @md-clicked-outside="close" class="lims-modal">
        <div class="dialog-icon-close" @click="close"><md-icon>close</md-icon></div>
        <md-dialog-title>{{ $t('modals/ModalSelectEntityType.modalTitle') }}</md-dialog-title>
        <div class="dialog-content">
          <lims-field :model="formData" :schema="schema" field="selectOption" viewMode="only">
            <v-select
              slot="field-outside"
              v-model="formData.selectOption"
              :options="$translateSelectOptions(selectOptions)"
              :placeholder="$t('modals/ModalSelectEntityType.placeholderEntityType')"
            >
              <template #option="{ label }">{{ label }}</template>
              <template #selected-option="{ label }">{{ label }}</template>
            </v-select>
          </lims-field>
        </div>
        <md-dialog-actions>
          <md-button @click="close" class="lims-form-button">{{ $t('global/button/button.cancel') }}</md-button>
          <md-button @click="onClick" :disabled="invalid" class="lims-form-button md-primary">{{
            $t('global/button/button.continue')
          }}</md-button>
        </md-dialog-actions>
      </md-dialog>
    </form>
  </ValidationObserver>
</template>

<script>
import modalMixins from './modal.mixins';
import { ENTITY_TYPE_ENUMS } from '@/core/constants';
export default {
  name: 'ModalSelectEntityType',
  mixins: [modalMixins],
  props: {
    errorMessage: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      formData: {
        selectOption: '',
      },
    };
  },
  watch: {},
  computed: {
    schema() {
      return {
        entity: 'modals/ModalSelectEntityType',
        fields: {
          selectOption: 'required',
        },
      };
    },
    selectOptions() {
      return ENTITY_TYPE_ENUMS;
    },
  },
  methods: {
    onSelect() {},
    onClick() {
      if (this.formData.selectOption) {
        this.$emit('onSelect', {
          ...this.formData,
        });
      }
    },
  },
};
</script>

<style lang="scss"></style>
