/* eslint-disable security/detect-object-injection */

// initial state
const state = () => ({
  userRoleId: null,
});

// getters
const getters = {
  $userRoleId: (state) => {
    return state.userRoleId;
  },
};

// actions
const actions = {
  $setUserRoleId({ commit }, userRoleId) {
    commit('setUserRoleId', userRoleId);
  },
};

// mutations
const mutations = {
  setUserRoleId(state, userRoleId) {
    state.userRoleId = userRoleId;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
