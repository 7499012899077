// FF : FILTER FIELD
export const FF_CaseReference = 'CaseReference';
export const FF_CaseStatusIds = 'CaseStatusIds';
export const FF_ClinicIds = 'ClinicIds';
export const FF_ClinicTypeIds = 'ClinicTypeIds';
export const FF_ClinicianIds = 'ClinicianIds';
export const FF_HospitalReference = 'HospitalReference';
export const FF_LabReference = 'LabReference';
export const FF_LaboratoryIds = 'LaboratoryIds';
export const FF_PathologistIds = 'PathologistIds';
export const FF_PatientFirstName = 'PatientFirstName';
export const FF_PatientLastName = 'PatientLastName';
export const FF_TrackingNo = 'TrackingNo';
export const FF_SpecimenTypeIds = 'SpecimenTypeIds';
export const FF_LabEntryDate = 'LabEntryDate';
export const FF_ReportedDate = 'ReportedDate';
export const FF_SlideSentToLabDate = 'SlideSentToLabDate';
export const FF_SlideSentToPathDate = 'SlideSentToPathDate';
export const FF_SlideSentToExternalDate = 'SlideSentToExternalDate';
export const FF_SlideReturnedToLabDate = 'SlideReturnedToLabDate';
export const FF_SlideReturnedToClinicDate = 'SlideReturnToClinicDate';
export const FF_SlideBlocks = 'CaseSpecimentBlocks';
export const FF_SlideIds = 'SlideIds';
export const FF_AlwRequestDate = 'AlwRequestDate';
export const FF_AlwRespondDate = 'AlwRespondDate';
export const FF_SlideLastUpdated = 'SlideLastUpdated';
export const FF_SlideTypes = 'SlideTypes';
export const FF_LocationLab = 'LocationLab';
export const FF_LocationExternal = 'LocationExternal';
export const FF_LocationSentToPath = 'LocationSentToPath';
export const FF_LocationReturnedToLab = 'LocationReturnedToLab';
export const FF_LocationReturnedToClinic = 'LocationReturnedToClinic';

// Filter fields for ALW
export const FF_ALW_CaseSpecimentBlocks = 'CaseSpecimentBlocks';
export const FF_ALW_AlwRequestStatusIds = 'AlwRequestStatusIds';
export const FF_ALW_AlwRequestTypeIds = 'AlwRequestTypeIds';
export const FF_ALW_AlwRequestDate = 'AlwRequestDate';
export const FF_ALW_AlwRequestStainIds = 'AlwRequestStainIds';
export const FF_ALW_AlwRespondDate = 'AlwRespondDate';
export const FF_ALW_AlwRespondStainIds = 'AlwRespondStainIds';
// Filter fields for Download Case Import Template
export const FF_DCI_entityType = 'entityType';
export const FF_DCI_clinicAndLabEntityIds = 'clinicAndLabEntityIds';
export const FF_DCI_laboratoryIds = 'laboratoryIds';
export const FF_DCI_clinicIds = 'clinicIds';
export const FF_DCI_clinicianIds = 'clinicianIds';
export const FF_DCI_technician1Ids = 'technician1Ids';
export const FF_DCI_pathologists = 'pathologists';
export const FF_DCI_billingEntityIds = 'billingEntityIds';
export const FF_DCI_workStreamIds = 'workStreamIds';
export const FF_DCI_specimenTypeIds = 'specimenTypeIds';
export const FF_DCI_slides = 'slides';
export const FF_DCI_labEntryDate = 'labEntryDate';
export const FF_DCI_labEntryTime = 'labEntryTime';
export const FF_DCI_macroDescription = 'macroDescription';
export const FF_DCI_clinicalDetails = 'clinicalDetails';
export const FF_DCI_numberDefaultRows = 'numberDefaultRows';

export const FILTER_SLIDE_LIST_ADMIN = [
  {
    id: 1,
    groupName: 'Case',
    groupValues: [
      FF_CaseReference,
      FF_CaseStatusIds,
      FF_ClinicIds,
      FF_ClinicTypeIds,
      FF_ClinicianIds,
      FF_HospitalReference,
      FF_LabReference,
      FF_LaboratoryIds,
      FF_PathologistIds,
      FF_PatientFirstName,
      FF_PatientLastName,
      FF_SpecimenTypeIds,
      FF_LabEntryDate,
      FF_ReportedDate,
    ],
  },
  {
    id: 2,
    groupName: 'Date',
    groupValues: [
      FF_SlideSentToLabDate,
      FF_SlideSentToPathDate,
      FF_SlideSentToExternalDate,
      FF_SlideReturnedToLabDate,
      FF_SlideReturnedToClinicDate,
    ],
  },
  {
    id: 3,
    groupName: 'Slide',
    groupValues: [
      FF_SlideBlocks,
      FF_SlideIds,
      FF_AlwRequestDate,
      FF_AlwRespondDate,
      FF_SlideLastUpdated,
      FF_SlideTypes,
      FF_TrackingNo,
    ],
  },
  {
    id: 4,
    groupName: 'Location flag',
    groupValues: [
      FF_LocationLab,
      FF_LocationExternal,
      FF_LocationSentToPath,
      FF_LocationReturnedToLab,
      FF_LocationReturnedToClinic,
    ],
  },
];

export const FILTER_SLIDE_LIST_LABTECHNICIAN = [
  {
    id: 1,
    groupName: 'Case',
    groupValues: [
      FF_CaseReference,
      FF_CaseStatusIds,
      FF_ClinicIds,
      FF_ClinicTypeIds,
      FF_ClinicianIds,
      FF_HospitalReference,
      FF_LabReference,
      FF_PathologistIds,
      FF_PatientFirstName,
      FF_PatientLastName,
      FF_SpecimenTypeIds,
      FF_LabEntryDate,
      FF_ReportedDate,
    ],
  },
  {
    id: 2,
    groupName: 'Date',
    groupValues: [
      FF_SlideSentToLabDate,
      FF_SlideSentToPathDate,
      FF_SlideSentToExternalDate,
      FF_SlideReturnedToLabDate,
      FF_SlideReturnedToClinicDate,
    ],
  },
  {
    id: 3,
    groupName: 'Slide',
    groupValues: [
      FF_SlideBlocks,
      FF_SlideIds,
      FF_AlwRequestDate,
      FF_AlwRespondDate,
      FF_SlideLastUpdated,
      FF_SlideTypes,
      FF_TrackingNo,
    ],
  },
  {
    id: 4,
    groupName: 'Location flag',
    groupValues: [
      FF_LocationLab,
      FF_LocationExternal,
      FF_LocationSentToPath,
      FF_LocationReturnedToLab,
      FF_LocationReturnedToClinic,
    ],
  },
];

export const FILTER_DOWNLOAD_IMPORT_TEMPLATE = [
  FF_DCI_entityType,
  FF_DCI_clinicAndLabEntityIds,
  FF_DCI_laboratoryIds,
  FF_DCI_clinicIds,
  FF_DCI_clinicianIds,
  FF_DCI_technician1Ids,
  FF_DCI_pathologists,
  FF_DCI_billingEntityIds,
  FF_DCI_workStreamIds,
  FF_DCI_specimenTypeIds,
  FF_DCI_slides,
  FF_DCI_labEntryDate,
  FF_DCI_labEntryTime,
  FF_DCI_macroDescription,
  FF_DCI_clinicalDetails,
  FF_DCI_numberDefaultRows,
];

export const FILTER_AWL_LIST = [
  {
    id: 1,
    groupName: 'Case',
    groupValues: [
      FF_CaseReference,
      FF_CaseStatusIds,
      FF_ClinicIds,
      FF_ClinicTypeIds,
      FF_ClinicianIds,
      FF_HospitalReference,
      FF_LabReference,
      FF_LaboratoryIds,
      FF_PathologistIds,
      FF_PatientFirstName,
      FF_PatientLastName,
      FF_SpecimenTypeIds,
    ],
  },
  {
    id: 2,
    groupName: 'ALW',
    groupValues: [
      FF_ALW_CaseSpecimentBlocks,
      FF_ALW_AlwRequestStatusIds,
      FF_ALW_AlwRequestTypeIds,
      FF_ALW_AlwRequestDate,
      FF_ALW_AlwRequestStainIds,
      FF_ALW_AlwRespondDate,
      FF_ALW_AlwRespondStainIds,
    ],
  },
];
