import exampleRoutes from './examples.routes';
import caseRoutes from './case-management.routes';
import personalisedReportRoutes from './personalised-report.routes';
import reviewCasesRoutes from './review-cases.routes';
import casesOfInterestRoutes from './cases-of-interest.routes';
import authRoutes from './auth.routes';
import dashboardRoutes from './dashboard.routes';
import myAccountRoutes from './my-account.routes';
import errorRoutes from './error.routes';
import userRoutes from './users.routes';
import roleRoutes from './roles.routes';
import entityRoutes from './entity.routes';
import myEntityRoutes from './my-entity.routes';
import dropdownRoutes from './dropdown.routes';
import policyRoutes from './policy.routes';
import GlobalVariables from './global-variables.routes';
import Dataset from './dataset.routes';
import SlideRoutes from './slides.routes';
import RequestRoutes from './request.routes';
import ImportCaseRoutes from './import-case.routes';
import importHistoryRoutes from './import-history.routes';
import shortenLinkRoutes from './shortenLink.routes';
import ocrRfUploadRoutes from './ocr-rf-upload.routes';
import ocrHistoryRoutes from './ocr-history.routes';
import simpleBookingRoutes from './simpleBooking.routes';

const routes = [
  {
    path: '/',
    redirect: '/dashboard',
    name: 'Home',
  },
  caseRoutes,
  reviewCasesRoutes,
  casesOfInterestRoutes,
  authRoutes,
  exampleRoutes,
  dashboardRoutes,
  myAccountRoutes,
  userRoutes,
  roleRoutes,
  entityRoutes,
  myEntityRoutes,
  dropdownRoutes,
  policyRoutes,
  GlobalVariables,
  personalisedReportRoutes,
  Dataset,
  SlideRoutes,
  RequestRoutes,
  ImportCaseRoutes,
  importHistoryRoutes,
  shortenLinkRoutes,
  ocrRfUploadRoutes,
  ocrHistoryRoutes,
  simpleBookingRoutes,
  // 404NotFound, 403Forbidden
  errorRoutes,
];

export default routes;
