/* eslint-disable security/detect-object-injection */
import { CASE_FORM_BLOCK } from '@/core/constants';
import Vue from 'vue';

// initial state
const state = () => ({
  specimenNumber: null,
  caseData: null,
  clinicComposeData: null,
  caseSupplementaryReport: null,
});

export const computeSpecimenTypeId = (val) => {
  // val is formData
  if (val.isSameSpecimenType && val.sameSpecimenTypeId) {
    // 3 possibilities:
    // sameSpecimenTypeId is number // primitive value from http request
    // sameSpecimenTypeId is object // object from select box
    // sameSpecimenTypeId is object without fieldItemId
    if (typeof val.sameSpecimenTypeId === 'object') {
      return val.sameSpecimenTypeId.fieldItemId ? val.sameSpecimenTypeId.fieldItemId : '';
    }
    return val.sameSpecimenTypeId;
  } else if (val.caseSpecimens && val.caseSpecimens.length > 0) {
    if (typeof val.caseSpecimens[0].specimenTypeId === 'object') {
      return val.caseSpecimens[0].specimenTypeId?.fieldItemId;
    }
    return val.caseSpecimens[0].specimenTypeId;
  } else if (
    val.caseSpecimens &&
    val.caseSpecimens.caseSpecimenUpdateModels &&
    val.caseSpecimens.caseSpecimenUpdateModels.length > 0
  ) {
    if (typeof val.caseSpecimens.caseSpecimenUpdateModels[0].specimenTypeId === 'object') {
      return val.caseSpecimens.caseSpecimenUpdateModels[0].specimenTypeId?.fieldItemId;
    }
    return val.caseSpecimens.caseSpecimenUpdateModels[0].specimenTypeId;
  }
  return '';
};

const computeClinicComposeData = (caseData) => {
  const specimenTypeId = computeSpecimenTypeId(caseData);
  const clinicId = caseData?.clinicId;
  const laboratoryId = caseData?.laboratoryId;
  const clinicianId = caseData?.clinicianId;
  const status = caseData?.status;

  return {
    specimenTypeId,
    clinicId,
    laboratoryId,
    clinicianId,
    status,
  };
};

/*
"caseSpecimens": {
    "specimenDetails": "string",
    "clinicalDetails": "string",
    "specimenLineBreakMapping": true,
    "rowVersion": 0,
    "caseSpecimenUpdateModels": [
      {
        "specimenDetails": "string",
        "macroDescription": "string",
        "microscopy": "string",
        "specimenTypeId": 0,
        "createdDate": "2023-03-02T04:21:23.525Z",
        "tissueRemaining": true,
        "caseSpecimentId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        "caseSpecimenBlocks": [
        ],
        "caseSpecimenDiagnosis": [
        ],
        "caseDataset": {
          "datasetId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
          "controls": [
          ]
        }
      }
    ]
  },
*/
const getCaseSpecimenModel = () => {
  return {
    specimenLineBreakMapping: false,
    specimenDetails: '',
    clinicalDetails: '',
    sameSpecimenTypeId: 2,
    isSameSpecimenType: false,
    caseSpecimenUpdateModels: [],
  };
};

const mergeSpecimens = (originalSpecimens, updatingSpecimens) => {
  return updatingSpecimens.map((specimen) => {
    const mergedSpecimen = originalSpecimens.find((s) => s.caseSpecimentId === specimen.caseSpecimentId);
    if (mergedSpecimen) {
      return {
        ...mergedSpecimen,
        ...specimen,
      };
    }
    return specimen;
  });
};

// return caseSpecimens;
// };

// getters
const getters = {
  specimenNumber: (state) => {
    return state.specimenNumber;
  },
  caseData: (state) => {
    return state.caseData;
  },
  clinicComposeData: (state) => {
    return state.clinicComposeData;
  },
  caseDiagnosis: (state) => {
    return state.caseData?.caseSpecimens?.caseSpecimenUpdateModels;
  },
  $caseSupplementaryReport: (state) => {
    return state.caseSupplementaryReport || null;
  },
};

// actions
const actions = {
  $setCaseSupplementaryReport({ commit }, caseSupplementaryReport) {
    commit('setCaseSupplementaryReport', caseSupplementaryReport);
  },
  resetCaseData({ commit }) {
    commit('resetCaseData');
  },
  setCaseData({ commit }, caseData) {
    const caseSpecimensModels = getCaseSpecimenModel();
    Reflect.ownKeys(caseSpecimensModels).map((key) => {
      key === 'caseSpecimenUpdateModels'
        ? Reflect.set(caseSpecimensModels, key, Reflect.get(caseData, 'caseSpecimens'))
        : Reflect.set(caseSpecimensModels, key, Reflect.get(caseData, key));
    });

    caseSpecimensModels.caseSpecimenUpdateModels = caseSpecimensModels.caseSpecimenUpdateModels?.map((specimen) => {
      if (!Reflect.has(specimen, 'caseSpecimenDiagnosis')) {
        Reflect.set(specimen, 'caseSpecimenDiagnosis', []);
      }
      // caseDataset[], caseSpecimenDatasetControls[]
      return specimen;
    });
    commit('setCaseData', {
      ...caseData,
      caseSpecimens: caseSpecimensModels,
    });
  },
  appendCaseData({ commit }, { caseData, blockId }) {
    commit('appendCaseData', { caseData, blockId });
  },
  setSpecimenNumber({ commit }, specimenNumber) {
    commit('setSpecimenNumber', specimenNumber);
  },
};

// mutations
const mutations = {
  resetCaseData: (state) => {
    Vue.set(state, 'clinicComposeData', null);
    Vue.set(state, 'caseData', null);
  },
  setCaseData: (state, caseData) => {
    const clinicComposeData = computeClinicComposeData(caseData);

    Vue.set(state, 'clinicComposeData', clinicComposeData);
    Vue.set(state, 'caseData', caseData);
  },
  setSpecimenNumber: (state, specimenNumber) => {
    Vue.set(state, 'specimenNumber', specimenNumber);
  },
  appendCaseData: (state, { caseData, blockId }) => {
    if (caseData.caseSpecimens) {
      const { caseSpecimens, ...rest } = caseData;
      const mergedCaseSpecimens = state.caseData.caseSpecimens;

      const { caseSpecimenUpdateModels, ...otherParts } = caseSpecimens;
      mergedCaseSpecimens.caseSpecimenUpdateModels = mergeSpecimens(
        state.caseData.caseSpecimens.caseSpecimenUpdateModels,
        caseSpecimenUpdateModels,
      );
      Vue.set(state, 'caseData', {
        ...state.caseData,
        caseSpecimens: {
          ...mergedCaseSpecimens,
          ...otherParts,
        },
        ...otherParts,
        ...rest,
      });
    } else if (caseData.diagnosisSpecimens) {
      const { diagnosisSpecimens, ...rest } = caseData;
      const mergedCaseSpecimens = state.caseData.caseSpecimens;

      mergedCaseSpecimens.caseSpecimenUpdateModels = mergeSpecimens(
        state.caseData.caseSpecimens.caseSpecimenUpdateModels,
        diagnosisSpecimens,
      );
      Vue.set(state, 'caseData', {
        ...state.caseData,
        caseSpecimens: mergedCaseSpecimens,
        ...rest,
      });
    } else {
      Vue.set(state, 'caseData', {
        ...state.caseData,
        ...caseData,
      });
    }

    // compose data
    const { BLOCK_CLINIC_LABORATORY, BLOCK_SPECIMEN } = CASE_FORM_BLOCK;
    if (![BLOCK_CLINIC_LABORATORY, BLOCK_SPECIMEN].includes(blockId)) {
      return;
    }

    const isSpecimenTypeChanged =
      blockId === BLOCK_SPECIMEN && state.clinicComposeData?.specimenTypeId != caseData?.specimenTypeId;
    if (isSpecimenTypeChanged) {
      const specimenTypeId =
        typeof caseData.specimenTypeId !== 'undefined'
          ? caseData.specimenTypeId
          : state.clinicComposeData?.specimenTypeId;
      Vue.set(state, 'clinicComposeData', {
        ...state.clinicComposeData,
        status: state.caseData?.status,
        specimenTypeId,
      });
    }
    if (
      blockId === BLOCK_CLINIC_LABORATORY &&
      (state.clinicComposeData?.clinicId != caseData?.clinicId ||
        state.clinicComposeData?.clinicianId != caseData?.clinicianId ||
        state.clinicComposeData?.laboratoryId != caseData?.laboratoryId)
    ) {
      const clinicId = typeof caseData.clinicId !== 'undefined' ? caseData.clinicId : state.clinicComposeData?.clinicId;
      const laboratoryId =
        typeof caseData.laboratoryId !== 'undefined' ? caseData.laboratoryId : state.clinicComposeData?.laboratoryId;
      const clinicianId =
        typeof caseData.clinicianId !== 'undefined' ? caseData.clinicianId : state.clinicComposeData?.clinicianId;

      Vue.set(state, 'clinicComposeData', {
        ...state.clinicComposeData,
        clinicId,
        clinicianId,
        laboratoryId,
      });
    }
  },
  setCaseSupplementaryReport: (state, caseSupplementaryReport) => {
    state.caseSupplementaryReport = caseSupplementaryReport;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
