<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit, invalid }">
    <form class="md-layout lims-form form-login" @submit.prevent="handleSubmit()">
      <md-dialog :md-close-on-esc="true" :md-active.sync="isVisible" @md-clicked-outside="close" class="lims-modal">
        <div class="dialog-icon-close" @click="closeAndClear()"><md-icon>close</md-icon></div>
        <md-dialog-title>{{ $t('components/ModalBulkActionProblem.title') }}</md-dialog-title>
        <div class="lims-form dialog-content">
          <template v-if="isVisible && formData.invalidItemAlwRequests.length > 0">
            <p class="text-center">{{ $t('components/ModalBulkActionProblem/alw.text') }}</p>
            <ul class="list-of-compatible-cases">
              <li v-for="item in formData.invalidItemAlwRequests" :key="item.caseAlwRequestId">
                {{ item.caseReference }}, {{ item.block }}, {{ item.stain }}, {{ item.typeName }}
              </li>
            </ul>
          </template>
          <template v-if="isVisible && formData.itemsAlwRequests.length > 0">
            <p class="text-center" v-html="$t('components/ModalBulkActionProblem.text2')"></p>
            <ul class="list-of-compatible-cases">
              <li v-for="item in formData.itemsAlwRequests" :key="item.caseAlwRequestId">
                {{ item.caseReference }}, {{ item.block }}, {{ item.stain }}, {{ item.typeName }}
              </li>
            </ul>
          </template>
          <p v-else-if="isVisible" class="text-center" v-html="$t('components/ModalBulkActionProblem.text3')"></p>
        </div>
        <md-dialog-actions>
          <template v-if="isVisible && formData.itemsAlwRequests.length > 0">
            <md-button @click="closeAndClear()" class="lims-form-button">{{
              $t('global/button/button.cancel')
            }}</md-button>
            <md-button @click="onContinue" :disabled="invalid" class="lims-form-button md-primary">{{
              $t('global/button/button.continue')
            }}</md-button>
          </template>
          <md-button v-else @click="closeAndClear()" class="lims-form-button md-primary">{{
            $t('global/button/button.backToList')
          }}</md-button>
        </md-dialog-actions>
      </md-dialog>
    </form>
  </ValidationObserver>
</template>

<script>
import modalMixins from './modal.mixins';
import { caseAlwService } from '@/services';

export default {
  name: 'ModalBulkActionEditProblemAlw',
  mixins: [modalMixins],
  props: {
    compatibleCase: {
      type: Array,
      require: true,
    },
    notCompatibleCase: {
      type: Array,
      require: true,
    },
  },
  data() {
    return {
      formData: {
        itemsAlwRequests: [
          {
            caseAlwRequestId: null,
            caseReference: '',
            block: '',
            stain: '',
            typeName: '',
          },
        ],
        invalidItemAlwRequests: [],
      },
      isVisible: false,
    };
  },
  watch: {},
  computed: {},
  methods: {
    async open(data) {
      if (data) {
        const dataCheck = {
          caseAlwRequests: data.itemsAlwRequests.map((item) => item.caseAlwRequestId),
          comment: 'empty',
        };
        const res = await caseAlwService.checkValidAlwRequest(dataCheck);
        if (res.err) {
          return this.$alertError(res.err + ' error');
        } else {
          const listValidRequestIds = res.data.listCaseSuccess.map((item) => item.caseALWRequestId);
          const listInvalidRequestIds = res.data.listCaseFailure.map((item) => item.caseALWRequestId);
          const validRequestAlw = data.itemsAlwRequests.filter((request) =>
            listValidRequestIds.includes(request.caseAlwRequestId),
          );
          if (listInvalidRequestIds.length > 0) {
            const invalidRequestAlw = data.itemsAlwRequests.filter((request) =>
              listInvalidRequestIds.includes(request.caseAlwRequestId),
            );
            this.formData.itemsAlwRequests = validRequestAlw;
            this.formData.invalidItemAlwRequests = invalidRequestAlw;
            this.isVisible = true;
          } else {
            this.formData.itemsAlwRequests = validRequestAlw;
            this.close();
            this.$emit('openModalBulkEditAlw', this.formData);
            this.formData = {
              itemsAlwRequests: [],
              invalidItemAlwRequests: [],
            };
          }
        }
      }
    },
    onContinue() {
      this.close();
      this.$emit('openModalBulkEditAlw', this.formData);
      this.formData = {
        itemsAlwRequests: [],
        invalidItemAlwRequests: [],
      };
    },
    closeAndClear() {
      this.formData = {
        itemsAlwRequests: [],
        invalidItemAlwRequests: [],
      };
      this.isVisible = false;
      this.close();
    },
  },
};
</script>

<style lang="scss"></style>
