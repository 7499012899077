import httpClient from './httpClient';

const RESOURCE = 'authentication';
export default {
  login({ username, password }) {
    return httpClient.post(`/${RESOURCE}`, {
      username,
      password,
    });
  },

  firstTimeLogin({ userId, token }) {
    return httpClient.get(`/${RESOURCE}/complete-account/${userId}/${token}`);
  },

  completeYourAccountCheckLinkExpired({ userId, token }) {
    return httpClient.put(`/${RESOURCE}/complete-account/${userId}/${token}`);
  },

  completeYourAccount({ userId, token, username, password }) {
    const params = {};
    params['username'] = username;
    params['password'] = password;
    params['userId'] = userId;
    return httpClient.put(`/${RESOURCE}/complete-account/${userId}/${token}`, params);
  },

  resetPasswordCheckLinkExpired({ userId, token }) {
    return httpClient.get(`/${RESOURCE}/reset-password/${userId}/${token}`);
  },

  resetPassword({ userId, token, newPassword }) {
    const params = {};
    if (newPassword) {
      params['newPassword'] = newPassword;
    }
    return httpClient.put(`/${RESOURCE}/reset-password/${userId}/${token}`, params);
  },

  forgotPassword(inputValue) {
    return httpClient.post(`/${RESOURCE}/forgot-password`, { inputValue });
  },

  completeYourAccountIdentityCheck({ value, field, userId, token }) {
    const params = {};
    params[`${field}`] = value;

    return httpClient.get(`/${RESOURCE}/identity-check/${userId}/${token}`, params);
  },

  sendVerifyCode({ challengeName, sessionId }) {
    return httpClient.post(`/${RESOURCE}/mfa-send-verifycode-login`, {
      challengeName,
      sessionId,
    });
  },

  verifyCode({ challengeName, sessionId, code }) {
    const params = {};
    params['challengeName'] = challengeName;
    params['sessionId'] = sessionId;
    params['code'] = code;

    return httpClient.post(`/${RESOURCE}/mfa-verifycode-login`, params);
  },

  sendCodeVerifyPhoneNumber({ userId, token, countryCode, phoneNumber }) {
    return httpClient.put(`/${RESOURCE}/send-code-verify-phone/${userId}/${token}`, {
      countryCode,
      phoneNumber,
    });
  },

  verifyPhoneNumber({ userId, token, countryCode, phoneNumber, code, localeMobile }) {
    return httpClient.put(`/${RESOURCE}/verify-phone/${userId}/${token}`, {
      countryCode,
      phoneNumber,
      code,
      localeMobile,
    });
  },

  getTotpInfo(userId, token) {
    return httpClient.get(`/${RESOURCE}/setup-totp/${userId}/${token}`);
  },

  verifySetupTotp(userId, token, code, sid) {
    return httpClient.put(`/${RESOURCE}/verify-setup-totp/${userId}/${token}`, {
      code: code,
      sid: sid,
    });
  },

  isAccessTokenValid() {
    return httpClient.get(`/${RESOURCE}/check-reset-password`);
  },

  saveReviewPolicyState($store, data) {
    if (data.policies && data.policies.length > 0) {
      $store.dispatch('auth/SET_ACTIVE_POLICY', {
        activePolicy: data.policies[0],
        sessionId: data.sessionId,
      });
    }
  },

  clearReviewPolicyState($store) {
    $store.dispatch('auth/SET_ACTIVE_POLICY', {
      activePolicy: null,
      sessionId: '',
    });
  },

  downloadFilePolicyWithSession({ policyId, sessionId }) {
    return httpClient.getBlob(`/${RESOURCE}/${policyId}/download?sessionId=${sessionId}`);
  },

  acceptPolicy({ policyId, sessionId }) {
    return httpClient.post(`/${RESOURCE}/user-accept-policy`, {
      policyId,
      sessionId,
    });
  },

  declinePolicy({ policyId, sessionId }) {
    return httpClient.post(`/${RESOURCE}/user-decline-policy`, {
      policyId,
      sessionId,
    });
  },

  refreshToken(refreshToken) {
    return httpClient.post(`/${RESOURCE}/refresh-token/${refreshToken}`);
  },
};
