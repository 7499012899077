<template>
  <ValidationObserver ref="form" v-slot="{ invalid }">
    <md-dialog :md-close-on-esc="true" :md-active.sync="isVisible" @md-clicked-outside="close" class="lims-modal">
      <div class="dialog-icon-close" @click="close"><md-icon>close</md-icon></div>
      <md-dialog-title>{{ $t('components/lims/modals/ModalConfirmation.title') }}</md-dialog-title>
      <div class="text-center mg-top-24" v-html="$t('components/lims/modals/ModalCancelSoRequest/text')"></div>
      <div class="lims-form dialog-content">
        <lims-field class="textarea-field" :model="formData" :schema="schema" field="reason">
          <md-textarea slot="field" v-model="formData.reason" maxlength="1000"></md-textarea>
        </lims-field>
      </div>
      <md-dialog-actions>
        <md-button @click="close" class="lims-form-button">{{ $t('global/button/button.cancel') }}</md-button>
        <md-button @click="onCancelRequest" :disabled="invalid || isProcessing" class="lims-form-button md-danger">{{
          $t('global/button/button.cancelRequest')
        }}</md-button>
      </md-dialog-actions>
    </md-dialog>
  </ValidationObserver>
</template>

<script>
import modalMixins from './modal.mixins';

export default {
  name: 'ModalCancelSoRequest',
  mixins: [modalMixins],
  props: {},

  data() {
    return {
      formData: {
        reason: '',
      },
    };
  },

  computed: {
    schema() {
      return {
        entity: 'modals/ModalCancelSoRequest',
        fields: {
          reason: 'required',
        },
      };
    },
  },
  methods: {
    onCancelRequest() {
      this.$refs.form.validate().then(async (success) => {
        if (success) {
          this.isProcessing = true;
          try {
            this.$emit('onCancelSORequest', this.formData);
          } catch (errors) {
            this.isProcessing = false;
            this.$alertError(errors);
          }
        } else {
          this.isProcessing = false;
          this.$alertError(this.$t(`global/errors/message`));
        }
      });
    },

    close() {
      this.isVisible = false;
      this.$nextTick(() => {
        this.formData = {
          reason: '',
        };
      });
    },
  },
};
</script>
