var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
return [_c('form',{staticClass:"md-layout lims-form form-login",on:{"submit":function($event){$event.preventDefault();return handleSubmit()}}},[_c('md-dialog',{staticClass:"lims-modal",attrs:{"md-close-on-esc":true,"md-active":_vm.isVisible},on:{"update:mdActive":function($event){_vm.isVisible=$event},"update:md-active":function($event){_vm.isVisible=$event},"md-clicked-outside":_vm.close}},[_c('div',{staticClass:"dialog-icon-close",on:{"click":_vm.close}},[_c('md-icon',[_vm._v("close")])],1),_c('md-dialog-title',[_vm._v(_vm._s(_vm.$t('components/ModalBulkActionProblem.title')))]),_c('div',{staticClass:"lims-form dialog-content"},[(_vm.haveAtLeastOneNotCompatibleCase)?[_c('p',{staticClass:"text-center",domProps:{"innerHTML":_vm._s(
              _vm.$t('components/ModalBulkActionProblem/actionCannotBePerformed', {
                bulkAction: _vm.$translateBulkActionSelect(_vm.bulkActionSelect),
              })
            )}})]:_vm._e(),(_vm.isHaveIncompatibleStatus)?[_c('p',{staticClass:"text-center",domProps:{"innerHTML":_vm._s(
              _vm.$t('components/ModalBulkActionProblem/incompatibleStatus', {
                bulkAction: _vm.$translateBulkActionSelect(_vm.bulkActionSelect),
                listOfCaseStatusIsAllowed: _vm.notCompatibleCase.incompatibleStatus.allowValue,
              })
            )}}),_c('ul',{staticClass:"list-of-compatible-cases"},_vm._l((_vm.getPartOfCases(_vm.notCompatibleCase.incompatibleStatus.listCasesFailure)),function(item){return _c('li',{key:item.caseId},[_vm._v(" "+_vm._s(item.caseReference)+" ")])}),0)]:_vm._e(),(_vm.isHaveSbImportSameLaboratory)?[_c('p',{staticClass:"text-center",domProps:{"innerHTML":_vm._s(
              _vm.$t('components/ModalBulkActionProblem/sbImportSameLaboratory', {
                isAnd: _vm.isHaveIncompatibleStatus ? 'AND' : '',
                laboratoryOfCompatibleCases: _vm.notCompatibleCase.sbImportSameLaboratory.allowValue,
              })
            )}}),_c('ul',{staticClass:"list-of-compatible-cases"},_vm._l((_vm.getPartOfCases(_vm.notCompatibleCase.sbImportSameLaboratory.listCasesFailure)),function(item){return _c('li',{key:item.caseId},[_vm._v(" "+_vm._s(item.caseReference)+" ")])}),0)]:_vm._e(),(_vm.isHaveDiscardTissueUnCheckRemaining)?[_c('p',{staticClass:"text-center",domProps:{"innerHTML":_vm._s(
              _vm.$t('components/ModalBulkActionProblem/discardTissueUnCheckRemaining', {
                isAnd: _vm.isHaveIncompatibleStatus ? 'AND' : '',
              })
            )}}),_c('ul',{staticClass:"list-of-compatible-cases"},_vm._l((_vm.getPartOfCases(_vm.notCompatibleCase.discardTissueUnCheckRemaining.listCasesFailure)),function(item){return _c('li',{key:item.caseId},[_vm._v(" "+_vm._s(item.caseReference)+" ")])}),0)]:_vm._e(),(_vm.isHaveInValidCaseSource)?[_c('p',{staticClass:"text-center",domProps:{"innerHTML":_vm._s(
              _vm.$t('components/ModalBulkActionProblem/isHaveInValidCaseSource', {
                isAnd: _vm.isHaveIncompatibleStatus ? 'AND' : '',
              })
            )}}),_c('ul',{staticClass:"list-of-compatible-cases"},_vm._l((_vm.getPartOfCases(_vm.notCompatibleCase.inValidCaseSource.listCasesFailure)),function(item){return _c('li',{key:item.caseId},[_vm._v(" "+_vm._s(item.caseReference)+" ")])}),0)]:_vm._e(),(_vm.bulkActionSelect == _vm.bulkAction.RemoveBillingFlag)?[_c('p',{staticClass:"text-center",domProps:{"innerHTML":_vm._s(
              _vm.$t('components/ModalBulkActionProblem/RemoveBillingFlag', {
                bulkAction: _vm.$translateBulkActionSelect(_vm.bulkActionSelect),
              })
            )}}),_c('ul',{staticClass:"list-of-compatible-cases"},_vm._l((_vm.getPartOfCases(_vm.notCompatibleCase)),function(item){return _c('li',{key:item.caseId},[_vm._v(" "+_vm._s(item.caseReference)+" ")])}),0)]:_vm._e(),(_vm.bulkActionSelect == _vm.bulkAction.BatchChange)?[_c('p',{staticClass:"text-center",domProps:{"innerHTML":_vm._s(
              _vm.$t('components/ModalBulkActionProblem/BatchChange', {
                bulkAction: _vm.$translateBulkActionSelect(_vm.bulkActionSelect),
                field: _vm.batchChangeSelect.fieldName,
                fieldValue: _vm.batchChangeSelect.fieldValue,
              })
            )}}),_c('ul',{staticClass:"list-of-compatible-cases"},_vm._l((_vm.getPartOfCases(_vm.notCompatibleCase)),function(item){return _c('li',{key:item.caseId},[_vm._v(" "+_vm._s(item.caseReference)+" - "+_vm._s(_vm.translateReasonFailBatchChange(item.reason))+" ")])}),0)]:_vm._e(),(_vm.compatibleCase && _vm.compatibleCase.length > 0)?[_c('p',{staticClass:"text-center",domProps:{"innerHTML":_vm._s(_vm.$t('components/ModalBulkActionProblem.text2'))}}),_c('ul',{staticClass:"list-of-compatible-cases"},_vm._l((_vm.getPartOfCases(_vm.compatibleCase)),function(item){return _c('li',{key:item.caseId},[_vm._v(" "+_vm._s(item.caseReference ? item.caseReference : item)+" ")])}),0)]:_c('p',{staticClass:"text-center",domProps:{"innerHTML":_vm._s(_vm.$t('components/ModalBulkActionProblem.text3'))}})],2),_c('md-dialog-actions',[(_vm.compatibleCase && _vm.compatibleCase.length > 0)?[_c('md-button',{staticClass:"lims-form-button",on:{"click":_vm.close}},[_vm._v(_vm._s(_vm.$t('global/button/button.cancel')))]),_c('md-button',{staticClass:"lims-form-button md-primary",attrs:{"disabled":invalid},on:{"click":_vm.onContinue}},[_vm._v(_vm._s(_vm.$t('global/button/button.continue')))])]:_c('md-button',{staticClass:"lims-form-button md-primary",on:{"click":_vm.close}},[_vm._v(_vm._s(_vm.$t('global/button/button.backToList')))])],2)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }