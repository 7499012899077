import LimsField from '@/components/Lims/LimsField.vue';
import { DateTimeHelper } from '@/core/helpers';
import * as moment from 'moment';
export default {
  components: { LimsField },
  data: function () {
    return {
      isVisible: false,
      isProcessing: false,
      isWrongPasswordError: false,
    };
  },
  methods: {
    open() {
      this.isVisible = true;
      this.isWrongPasswordError = false;
    },
    close() {
      this.isVisible = false;
      this.isWrongPasswordError = false;
    },
    toUTC(dateString) {
      return moment.utc(dateString);
    },
    formatDateTime(date) {
      const { timezoneValue, dateTimeAppFormat } = this.$store.getters['config/$appConfig'];
      return DateTimeHelper.formatDateTimeGlobal(date, {
        timezoneValue,
        dateTimeAppFormat,
      });
    },
    $translateBulkActionSelect(bulkActionSelect) {
      return this.$t(`entities/case/bulkActionName.${bulkActionSelect}`);
    },
    isProcessingStatus(status) {
      this.isProcessing = status;
    },

    handleErrorMessageModal(err) {
      if (err === 'Incorrect username or password.' || err === 'Password attempts exceeded') {
        if (err === 'Password attempts exceeded') {
          this.$alertError(err);
        }
        this.isWrongPasswordError = true;
        //focus on confirm input
        setTimeout(() => {
          this.$refs.confirmPassword.$el.focus();
        }, 500);
      } else {
        this.isWrongPasswordError = false;
        return this.$alertError(err);
      }
    },
  },
};
