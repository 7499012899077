<template>
  <md-dialog :md-close-on-esc="true" :md-active.sync="isVisible" @md-clicked-outside="close" class="lims-modal">
    <div class="dialog-icon-close" @click="close"><md-icon>close</md-icon></div>
    <br />
    <div class="dialog-content">
      <p class="text-center">{{ $t('components/lims/modals/ModalConfirmDeletePrButton.contentTxt') }}</p>
    </div>
    <md-dialog-actions>
      <md-button @click="close" class="lims-form-button md-primary">
        {{ $t('global/button/button.ok') }}
      </md-button>
    </md-dialog-actions>
  </md-dialog>
</template>

<script>
import modalMixins from './modal.mixins';
export default {
  mixins: [modalMixins],
};
</script>

<style lang="scss"></style>
