<template>
  <md-dialog :md-close-on-esc="true" :md-active.sync="isVisible" @md-clicked-outside="close" class="lims-modal">
    <div class="dialog-icon-close" @click="close"><md-icon>close</md-icon></div>
    <md-dialog-title></md-dialog-title>
    <div class="lims-form dialog-content">
      <p
        class="text-center"
        v-html="
          $t('components/lims/modals/ModalNotifyDatasetDeleted.content', {
            theOrderOfSpecimenWhichHasDatasetRemoved: orderErr,
          })
        "
      ></p>
    </div>
    <md-dialog-actions>
      <md-button @click="onOK" class="lims-form-button">{{ $t('global/button/button.ok') }}</md-button>
    </md-dialog-actions>
  </md-dialog>
</template>

<script>
import modalMixins from '@/components/Lims/modals/modal.mixins';

export default {
  name: 'ModalNotifyDatasetDeleted',
  mixins: [modalMixins],
  data() {
    return {
      orderErr: '',
    };
  },
  methods: {
    open(data) {
      if (data) {
        this.isVisible = true;
        this.orderErr = data.theOrderOfSpecimenWhichHasDatasetRemoved;
      }
    },
    onOK() {
      this.close();
      this.$emit('onConfirmDatasetDelete');
    },
  },
};
</script>

<style lang="scss"></style>
