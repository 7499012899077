import { CASE_STATUS, PATHOLOGIST_TYPE_ID, PATHOLOGIST_TYPE_NAME } from '@/core/constants';
import { sortDropDown, startDay } from '@/core/helpers';
// import { sortDropDown } from '@/core/helpers';

export const isAvailablePathologist = (pathologistId, pathologistList) => {
  if (!pathologistId) {
    return false;
  }
  let result = false;
  pathologistList.forEach((pathologistGroup) => {
    if (pathologistGroup.pathologistUsers.find((item) => item.pathologistId == pathologistId)) {
      result = true;
    }
  });
  return result;
};

export const createNewPathologist = (data, isEditMode = false) => {
  // several cases
  // empty
  if (!data.pathologistId && data.pathologistType === PATHOLOGIST_TYPE_ID.None) {
    return null;
  }
  // or Pull
  if (data.pathologistType === PATHOLOGIST_TYPE_ID.Pull) {
    if (isEditMode) {
      return {
        pathologistId: data.pathologistId || PATHOLOGIST_TYPE_NAME.Pull,
        pathologistType: PATHOLOGIST_TYPE_ID.Pull,
        pathologistTypeName: PATHOLOGIST_TYPE_NAME.Pull,
        pathologistName: data.pathologistName || PATHOLOGIST_TYPE_NAME.Pull,
        pathologistTitle: data.pathologistName || PATHOLOGIST_TYPE_NAME.Pull,
        isDefault: data.isDefault || false,
      };
    } else {
      return {
        pathologistId: data.pathologistId || PATHOLOGIST_TYPE_NAME.Pull,
        pathologistType: PATHOLOGIST_TYPE_ID.Pull,
        pathologistTypeName: PATHOLOGIST_TYPE_NAME.Pull,
        pathologistName: data.pathologistName || PATHOLOGIST_TYPE_NAME.Pull,
        pathologistTitle: data.pathologistName || PATHOLOGIST_TYPE_NAME.Pull,
        isDefault: data.isDefault || false,
      };
    }
  }
  return {
    pathologistId: data.pathologistId || null,
    pathologistType: data.pathologistType || 0,
    pathologistName: data.pathologistName,
    pathologistTypeName: data.pathologistTypeName,
    pathologistTitle: data.pathologistTitle || '',
    isDefault: data.isDefault || false,
  };
};

export const mappingGroupPathologists = (groupPathologists, isEditMode = false) => {
  let pathologistOptions = [];
  let defaultPathologist = null;
  // and another requirement is we have to
  // sort pathologist by fullname
  // find the default pathologist if existed : we'll use this flag "isDefault"
  if (groupPathologists && groupPathologists.length > 0) {
    pathologistOptions = groupPathologists.map((g) => {
      const { pathologistGroupId, pathologistGroupName } = g;

      const pathologistUsers = sortDropDown(
        (Reflect.get(g, 'pathologistUsers') || []).map((pathologistUser) => {
          const pathologist = createNewPathologist({
            pathologistId: pathologistUser.userId,
            pathologistType: pathologistGroupId,
            pathologistTypeName: pathologistGroupName,
            pathologistName: pathologistUser?.firstName + ' ' + pathologistUser?.lastName,
            pathologistTitle: pathologistUser?.title,
            isDefault: pathologistUser?.isDefault || false,
          });
          if (pathologistUser.isDefault) {
            defaultPathologist = pathologist;
          }
          return pathologist;
        }),
        'pathologistName',
      );

      if (pathologistGroupId === PATHOLOGIST_TYPE_ID.Pull) {
        pathologistUsers.push(
          createNewPathologist({
            pathologistId: null,
            pathologistType: pathologistGroupId,
            pathologistTypeName: pathologistGroupName,
            pathologistName: PATHOLOGIST_TYPE_NAME.Pull,
            pathologistTitle: PATHOLOGIST_TYPE_NAME.Pull,
            isEditMode,
          }),
        );
      }
      return {
        ...g,
        pathologistUsers,
      };
    });
  }
  return {
    pathologistOptions,
    defaultPathologist,
  };
};

export const calculatePatientAge = ({ procedure, birth }) => {
  if (!procedure || !birth) {
    return 0;
  }
  let procedureDate = new Date(procedure);
  let birthDate = new Date(birth);
  let age = procedureDate.getFullYear() - birthDate.getFullYear();
  let m = procedureDate.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && procedureDate.getDate() < birthDate.getDate())) {
    age--;
  }
  return age < 0 ? 0 : age;
};

// verify if case has at least 01 specimen that does NOT have one of the following: Snomed T, Snomed P, Snomed M, Microscopy
// export const isCaseAllowedToBeAuthorized = (caseData) => {

// }

// Date of birth should be less than Procedure Date and Lab Entry Date
export const validateDob = (dob, { labEntryDate, procedureDate }) => {
  if (!dob) {
    return true;
  }
  if (!labEntryDate && !procedureDate) {
    return true;
  }
  if (labEntryDate && procedureDate) {
    return startDay(dob) < startDay(labEntryDate) && startDay(dob) < startDay(procedureDate);
  }
  if (labEntryDate) {
    return startDay(dob) < startDay(labEntryDate);
  }
  if (procedureDate) {
    return startDay(dob) < startDay(procedureDate);
  }
  return true;
};

// Procedure Date should be greater than Date of Birth and less than Lab Entry Date
export const validateProcedureDate = (procedureDate, { labEntryDate, dob }) => {
  if (!procedureDate) {
    return true;
  }
  if (!labEntryDate && !dob) {
    return true;
  }
  if (labEntryDate && dob) {
    return startDay(procedureDate) > startDay(dob) && startDay(procedureDate) <= startDay(labEntryDate);
  }
  if (labEntryDate) {
    return startDay(procedureDate) <= startDay(labEntryDate);
  }
  if (dob) {
    return startDay(procedureDate) > startDay(dob);
  }
  return true;
};

// Lab Entry Date must greater than DOB and Procedure Date
export const validateLabEntryDate = (labEntryDate, { dob, procedureDate }) => {
  if (!labEntryDate) {
    return true;
  }
  if (!dob && !procedureDate) {
    return true;
  }
  if (dob && procedureDate) {
    return startDay(labEntryDate) > startDay(dob) && startDay(labEntryDate) >= startDay(procedureDate);
  }
  if (dob) {
    return startDay(labEntryDate) > startDay(dob);
  }
  if (procedureDate) {
    return startDay(labEntryDate) >= startDay(procedureDate);
  }
  return true;
};
/**
 * Function to check can reduce number of specimens
 * @param {CASE_STATUS} caseStatus
 * @param {Specimen} specimens
 * @param {number} to
 * @returns
 */
export const canReduceNumberOfSpecimens = (caseStatus, specimens, to) => {
  // Case status must not be prov.reported/reported
  if ([CASE_STATUS.REPORTED, CASE_STATUS.PROVISIONALLY_REPORTED].includes(caseStatus)) {
    return false;
  }
  const totalSpecimens = specimens.length;
  // must have at least one specimens
  if (to < 1) {
    return false;
  }
  let canReduce = true;
  for (let i = totalSpecimens - 1; i > to - 1; i--) {
    if (!isSpecimenRemovable(caseStatus, specimens, i)) {
      canReduce = false;
      break;
    }
  }
  return canReduce;
};

/**
 * Function to check specimen can be removed or not
 * @param {CASE_STATUS} caseStatus
 * @param {Specimen} specimen
 * @param {number} specimenIndex
 *
 * ### Constraints
 * 1. Must have at least one specimen
 * 2. Specimens are going to be removed must have no slides
 * 3. Case status must not be prov.reported/reported
 */
export const isSpecimenRemovable = (caseStatus, specimens, specimenIndex) => {
  const specimen = specimens[specimenIndex];
  if (!specimen) {
    return false;
  }
  // Case status must not be prov.reported/reported
  if ([CASE_STATUS.REPORTED, CASE_STATUS.PROVISIONALLY_REPORTED].includes(caseStatus)) {
    return false;
  }
  const totalSpecimens = specimens.length;
  // must have at least one specimens
  if (totalSpecimens - 1 <= 0) {
    return false;
  }
  const { caseSpecimenBlocks } = specimen;
  let specimenHasSlides = false;
  for (let i = 0; i < caseSpecimenBlocks.length; i++) {
    // blockFields : currentValue
    const blockFields = caseSpecimenBlocks[i].blockField ? caseSpecimenBlocks[i].blockField.flat() : [];
    // blockFieldItems: savedValue
    // if (caseSpecimenBlocks[i].blockFieldItems.length > 0) {
    // use blockField instead of blockFieldItems
    if (blockFields.length > 0) {
      specimenHasSlides = true;
      break;
    }
  }
  if (specimenHasSlides) {
    return false;
  }
  return true;
};

export const SPECIMEN_ELEMENT_OBJECT_TYPE = {
  SLIDE: 'Slide',
  BLOCK: 'Block',
  SPECIMEN: 'Specimen',
};

export const ALW_REQUEST_TYPE = {
  Completed: 'Completed',
  Requested: 'Requested',
};

export const PENDING_ALW_REQUEST_ERROR_TYPE = {
  Completed: 'Completed',
  Requested: 'Requested',
};

export const checkSlideInPendingAlwRequestError = ({ pendingAlwRequests, $t, objectType }) => {
  // alwRequestTypeName, blockName, slideName
  // [type - block - slide]
  // detect alw requests type: completed > requested
  let objectName = null;
  let messageType = null;
  let firstRequest = null;
  const completedRequests = pendingAlwRequests.filter((r) => r.statusName === ALW_REQUEST_TYPE.Completed);
  const requestedRequests = pendingAlwRequests.filter((r) => r.statusName === ALW_REQUEST_TYPE.Requested);
  if (completedRequests.length > 0) {
    messageType = PENDING_ALW_REQUEST_ERROR_TYPE.Completed;
    firstRequest = completedRequests[0];
  } else if (requestedRequests.length > 0) {
    messageType = PENDING_ALW_REQUEST_ERROR_TYPE.Requested;
    firstRequest = requestedRequests[0];
  }

  const slideMessages = [];

  if (messageType === PENDING_ALW_REQUEST_ERROR_TYPE.Completed) {
    completedRequests.map((r) => {
      slideMessages.push(`${r.alwRequestTypeName} - ${r.blockName} - ${r.slideName}`);
    });
  } else if (messageType === PENDING_ALW_REQUEST_ERROR_TYPE.Requested) {
    requestedRequests.map((r) => {
      slideMessages.push(`${r.alwRequestTypeName} - ${r.blockName} - ${r.slideName}`);
    });
  }

  switch (objectType) {
    case SPECIMEN_ELEMENT_OBJECT_TYPE.SLIDE:
      objectName = `${firstRequest.blockName} - ${firstRequest.slideName}`;
      break;
    case SPECIMEN_ELEMENT_OBJECT_TYPE.BLOCK:
      objectName = `${firstRequest.blockName}`;
      break;
    case SPECIMEN_ELEMENT_OBJECT_TYPE.SPECIMEN:
      objectName = `${firstRequest.specimenIndex + 1} `;
      break;
  }

  const errorMessage = $t(`pages/case/CaseManagement/components/SpecimenDetail/DeletedSlide/${messageType}/error`, {
    objectType,
    objectName,
    slideMessages: slideMessages.join(', '),
  });

  return errorMessage;
};
