<template>
  <div>
    <md-table class="paginated-table table-striped table-hover lims-table user-tbl">
      <md-table-toolbar>
        <slot name="toolbar"></slot>
      </md-table-toolbar>
      <div class="table-fix-header">
        <thead>
          <md-table-row>
            <md-table-head v-for="(col, index) in colTabl" :key="index" :class="col.class">
              <div @mouseover="hoverIn(index)" @mouseout="hoverOut()" @click="sortCol(col.key, col.sort)">
                {{ $t(col.name) }}
                <span>
                  <md-icon v-if="col.sort">
                    {{
                      col.key === orderState.orderField
                        ? orderState.orderBy === 'asc'
                          ? 'arrow_drop_up'
                          : 'arrow_drop_down'
                        : hover === index
                        ? 'arrow_drop_up'
                        : ''
                    }}
                  </md-icon>
                </span>
              </div>
            </md-table-head>
          </md-table-row>
        </thead>
        <tbody>
          <md-table-row
            v-for="item in users"
            :key="`users${item.userId}`"
            v-long-press="300"
            @click.native="onNavigate($event, item)"
            @long-press-start="onPlusStart"
            @long-press-stop="onPlusStop"
            :class="isCheckCompleteAccount(item) ? 'isNotCompleteAccount' : ''"
          >
            <md-table-cell>{{ $t(`entities/user/userTypeName.${item.userTypeName}`) }}</md-table-cell>
            <md-table-cell>{{ item.firstname }}</md-table-cell>
            <md-table-cell>{{ item.lastname }}</md-table-cell>
            <md-table-cell>{{ item.username }}</md-table-cell>
            <md-table-cell>{{ item.email }}</md-table-cell>
            <md-table-cell class="statusName-column">
              <md-icon
                :class="[
                  { 'status-invited': item.statusId == STATUSES.Invited },
                  { 'status-enabled': item.statusId == STATUSES.Enabled },
                  { 'status-disabled': item.statusId == STATUSES.Disabled },
                ]"
                >adjust</md-icon
              >
            </md-table-cell>
            <md-table-cell class="about-column">
              <AboutInformation
                :user-id-of-item="item.userId"
                :is-clinician-item="item.userTypeId === 3"
              ></AboutInformation>
            </md-table-cell>
            <md-table-cell class="isPending-column">{{ item.isPending ? 'Yes' : '' }}</md-table-cell>
            <md-table-cell class="cell-action">
              <div class="cell-action-inner">
                <div style="width: 33%">
                  <md-button
                    v-if="isShowViewIcon(item)"
                    class="md-just-icon md-info md-simple"
                    @click.native="handleAction('view', item)"
                  >
                    <md-icon>visibility</md-icon>
                  </md-button>
                </div>
                <div style="width: 33%">
                  <md-button
                    v-if="isShowEditIcon(item)"
                    class="md-just-icon md-warning md-simple"
                    @click.native="handleAction('edit', item)"
                  >
                    <md-icon>edit</md-icon>
                  </md-button>
                </div>
                <div style="width: 33%">
                  <md-button
                    v-if="isShowRestPassword(item)"
                    class="md-just-icon md-default md-simple"
                    @click.stop="handleAction('reset-password', item)"
                  >
                    <md-icon>lock_reset</md-icon>
                  </md-button>
                </div>
              </div>
            </md-table-cell>
          </md-table-row>
        </tbody>
      </div>
    </md-table>
  </div>
</template>

<script>
import { USER_STATUSES } from '@/core/constants';
import AboutInformation from '@/pages/Account/User/List/AboutInformation';
import { mapGetters } from 'vuex';

export default {
  name: 'AccountUserList',

  components: {
    AboutInformation,
  },

  created() {
    this.orderState = this.sort;
  },

  props: {
    sort: {
      type: Object,
      require: true,
    },
    users: {
      required: false,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      longPressMode: false,
      plusInterval: null,
      colTabl: [
        {
          name: 'pages/account/user/list.usertype',
          key: 'userTypeName',
          sort: true,
          sortType: 'desc',
          class: 'userTypeName-column',
        },
        {
          name: 'pages/account/user/list.firstname',
          key: 'firstname',
          sort: true,
          sortType: 'asc',
          class: 'firstname-column',
        },
        {
          name: 'pages/account/user/list.lastname',
          key: 'lastname',
          sort: true,
          sortType: 'asc',
          class: 'lastname-column',
        },
        {
          name: 'pages/account/user/list.username',
          key: 'username',
          sort: true,
          sortType: 'asc',
          class: 'username-column',
        },
        {
          name: 'pages/account/user/list.email',
          key: 'email',
          sort: true,
          sortType: 'asc',
          class: 'email-column',
        },
        {
          name: 'global/pages/list.status',
          key: 'statusName',
          sort: true,
          sortType: 'asc',
          class: 'statusName-column',
        },
        {
          name: 'pages/account/user/list.about',
          key: 'about',
          sort: false,
          class: 'about-column',
        },
        {
          name: 'pages/account/user/list.pending',
          key: 'isPending',
          sort: false,
          class: 'isPending-column',
        },
        {
          name: 'global/pages/list.actions',
          key: 'actions',
          sort: false,
          class: 'actions-column',
        },
      ],
      hover: '',
      orderState: {
        orderField: 'userTypeName',
        orderBy: 'asc',
      },
    };
  },
  computed: {
    STATUSES: function () {
      return USER_STATUSES;
    },
    ...mapGetters('auth', ['userId']),
  },
  watch: {},
  methods: {
    handleAction(action, item) {
      this.$emit('action', {
        action,
        item,
      });
    },

    onNavigate(e, item) {
      if (!this.longPressMode) {
        e.preventDefault();
        this.handleAction('view', item);
      }
    },
    onPlusStart() {
      this.plusInterval = setInterval(() => {
        this.longPressMode = true;
      }, 100);
    },

    onPlusStop() {
      setTimeout(() => {
        this.longPressMode = false;
      }, 500);
      clearInterval(this.plusInterval);
    },

    isShowViewIcon(item) {
      return (
        this.$isAuthorized(['Accounts_UserManagement_Retrieve', 'MyEntity_UserManagement_Retrieve']) ||
        (item.isPending &&
          item.statusId === USER_STATUSES.Disabled &&
          this.$isAuthorized(['Accounts_UserManagement_Update', 'MyEntity_UserManagement_Update']))
      );
    },

    isShowEditIcon(item) {
      return (
        this.$isAuthorized(['Accounts_UserManagement_Update', 'MyEntity_UserManagement_Update']) &&
        item.statusId !== USER_STATUSES.Disabled &&
        !item.isPending &&
        !item.isSuperAdmin &&
        item.userId !== this.userId
      );
    },

    isShowRestPassword(item) {
      return (
        this.$isAuthorized(['Accounts_UserManagement_Update']) &&
        item.statusId === USER_STATUSES.Enabled &&
        !item.isPending &&
        item.hasLogin
      );
    },

    isCheckCompleteAccount(item) {
      if (item.hasLogin && !item.isCompleteAccount) {
        return true;
      } else if ((item.hasLogin && !item.isCompleteAccount) || !item.hasLogin) {
        return false;
      } else {
        return false;
      }
    },

    hoverIn(index) {
      this.hover = index;
    },
    hoverOut() {
      this.hover = '';
    },
    sortCol(colName, isSort) {
      if (isSort) {
        if (this.orderState.orderField === colName) {
          if (this.orderState.orderBy === 'asc') {
            this.orderState.orderBy = 'desc';
          } else {
            this.orderState.orderBy = 'asc';
          }
        } else {
          this.orderState.orderField = colName;
          this.orderState.orderBy = 'asc';
        }
      }
    },
  },
};
</script>
<style lang="scss">
.statusName-column {
  width: 80px !important;
  padding-left: 15px;
}
.about-column {
  width: 80px !important;
  text-align: center;
  .md-table-head-label {
    padding-right: 0 !important;
  }
}
.isPending-column {
  width: 80px !important;
}
</style>
