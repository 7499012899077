// Layouts
const DashboardLayout = () => import('@/layouts/DashboardLayout.vue');
// Policy pages
import Policy from '@/pages/Policies/Policy/Policy.vue';
import PolicyAdd from '@/pages/Policies/Policy/Add/PolicyAdd.vue';
import PolicyEdit from '@/pages/Policies/Policy/Edit/PolicyEdit.vue';

import guards from './guards';
import { FORM_MODES } from '@/core/constants';

export default {
  path: '/policies',
  component: DashboardLayout,
  children: [
    {
      path: '',
      name: 'PolicyManagement',
      component: Policy,
      beforeEnter: guards.checkAuthorizedGuard,
      meta: {
        zone: ['Policy_PolicyManagement_Retrieve'],
      },
    },
    {
      path: 'add/',
      name: 'PolicyManagementAdd',
      component: PolicyAdd,
      beforeEnter: guards.checkAuthorizedGuard,
      props: () => ({
        formMode: FORM_MODES.ADD,
      }),
      meta: {
        zone: ['Policy_PolicyManagement_Create'],
      },
    },
    {
      path: 'edit/:policyId',
      name: 'PolicyManagementEdit',
      component: PolicyEdit,
      beforeEnter: guards.checkAuthorizedGuard,
      props: (route) => ({
        formMode: FORM_MODES.EDIT,
        policyId: route.params.policyId,
      }),
      meta: {
        zone: ['Policy_PolicyManagement_Update'],
      },
    },
    {
      path: 'view/:policyId',
      name: 'PolicyManagementView',
      component: PolicyEdit,
      beforeEnter: guards.checkAuthorizedGuard,
      props: (route) => ({
        formMode: FORM_MODES.VIEW,
        policyId: route.params.policyId,
      }),
      meta: {
        zone: ['Policy_PolicyManagement_Retrieve'],
      },
    },
  ],
};
