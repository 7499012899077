<template>
  <div class="sb-panel">
    <modal-sb-add-slides
      :commonlyUsedSlides="commonlyUsedSlides"
      ref="addSlideModal"
      @SlideAdded="getSlideAdded"
    ></modal-sb-add-slides>
    <modal-create-specimen ref="createSpecimenModal" @onAddedSpecimen="onAddedSpecimen"></modal-create-specimen>
    <modal-confirm-slide-block
      ref="confirmSlideBlockModal"
      :title="$t('components/lims/modals/ModalConfirmation.title')"
      :description="confirmSlideBlockModalDescription"
      @onConfirm="onConfirmActionInSlideBlock"
      @onCancel="onCancelActionInSlideBlock"
    />
    <div v-for="(specimen, specimenIndex) in specimens" :key="specimenIndex" class="sb-panel">
      <div
        v-show="expand"
        v-for="(specimenBlock, blockIndex) in specimen.caseSpecimenBlocks"
        :key="blockIndex"
        class="sb-panelBlock sb-panelBlock-expand"
        @dragover.prevent
        @dragenter.prevent
        @drop="onShowConfirmDropSlide(specimenBlock, specimenIndex, blockIndex, $event)"
      >
        <div class="sb-panel-content">
          <div class="title">{{ generateBlock(specimenIndex, blockIndex) }}</div>
          <div
            class="content"
            :class="{ selectedBlock: isSelectedBlock(specimenIndex, blockIndex) }"
            @click="onToggleSelectedBlock(specimenIndex, blockIndex, specimenBlock)"
          >
            <div v-for="(slide, slideIdx) in specimenBlock.blockFieldItems" :key="slideIdx">
              <div class="list-item" v-if="!slide.isHide || isReportedStatus" :class="sbCTAClass">
                {{ slide.fieldItemName }}
                <span @click="onShowConfirmRemoveSlide(specimenBlock, slideIdx, $event)"><md-icon>close</md-icon></span>
              </div>
            </div>
          </div>
        </div>
        <!-- end sb-panel-content -->
        <div class="sb-panel-action">
          <div class="add-action sb-dropdown-menu" :class="sbCTAClass">
            <md-icon>add_circle</md-icon>
            <ul class="dropdown-menu-list">
              <li @click="onShowConfirmAddBlock(specimen)">Add block</li>
              <li @click="onShowConfirmAddSlide(specimenBlock)">Add slide</li>
            </ul>
          </div>
          <div class="sb-dropdown-menu" :class="sbCTAClass">
            <md-icon>do_not_disturb_on</md-icon>
            <ul class="dropdown-menu-list">
              <li
                v-if="isDeleteAble(specimenIndex, specimens.length, specimen.caseSpecimenBlocks)"
                :class="sbCTAClass"
                @click="onDeleteSpecimen(specimenIndex)"
              >
                Delete Specimen
              </li>
              <li
                @click="
                  onShowConfirmDeleteBlock(specimen, specimenIndex, blockIndex, specimen.caseSpecimenBlocks[blockIndex])
                "
              >
                Delete block
              </li>
              <li @click="onDeleteSlides(specimenBlock)">Delete slides</li>
            </ul>
          </div>
        </div>
        <!-- end sb-panel-action -->
      </div>
      <!-- end specimenBlock -->
      <div class="sb-specimen" v-show="!expand">
        <carousel
          class="sb-panelBlock"
          :navigationEnabled="true"
          :perPage="1"
          :paginationEnabled="false"
          easing="linear"
          :speed="300"
        >
          <slide v-for="(specimenBlock, blockIndex) in specimen.caseSpecimenBlocks" :key="blockIndex">
            <div
              @dragover.prevent
              @dragenter.prevent
              @drop="onShowConfirmDropSlide(specimenBlock, specimenIndex, blockIndex, $event)"
              class="sb-panelBlock"
            >
              <div class="sb-panel-content">
                <div class="title">
                  {{ generateBlock(specimenIndex, blockIndex) }}
                </div>
                <div
                  class="content"
                  :class="{ selectedBlock: isSelectedBlock(specimenIndex, blockIndex) }"
                  @click="onToggleSelectedBlock(specimenIndex, blockIndex, specimenBlock)"
                >
                  <div
                    v-for="(slide, slideIdx) in specimenBlock.blockFieldItems"
                    :key="slideIdx"
                    style="display: inline-flex"
                  >
                    <div
                      class="list-item"
                      v-if="!slide.isHide || caseObjectData.status == caseStatus.REPORTED"
                      :class="sbCTAClass"
                    >
                      {{ slide.fieldItemName }}
                      <span @click="onShowConfirmRemoveSlide(specimenBlock, slideIdx, $event)"
                        ><md-icon>close</md-icon></span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <!-- end sb-panel-content -->
              <div class="sb-panel-action">
                <div class="add-action sb-dropdown-menu" :class="sbCTAClass">
                  <md-icon>add_circle</md-icon>
                  <ul class="dropdown-menu-list">
                    <li @click="onShowConfirmAddBlock(specimen)">Add block</li>
                    <li @click="onShowConfirmAddSlide(specimenBlock)">Add slide</li>
                  </ul>
                </div>
                <div class="sb-dropdown-menu" :class="sbCTAClass">
                  <md-icon>do_not_disturb_on</md-icon>
                  <ul class="dropdown-menu-list">
                    <li
                      v-if="isDeleteAble(specimenIndex, specimens.length, specimen.caseSpecimenBlocks)"
                      @click="onDeleteSpecimen(specimenIndex)"
                    >
                      Delete Specimen
                    </li>
                    <li
                      v-if="isShowDeleteBlock(blockIndex, specimen.caseSpecimenBlocks)"
                      @click="
                        onShowConfirmDeleteBlock(
                          specimen,
                          specimenIndex,
                          blockIndex,
                          specimen.caseSpecimenBlocks[blockIndex],
                        )
                      "
                    >
                      Delete block
                    </li>
                    <li @click="onDeleteSlides(specimenBlock)">Delete slides</li>
                  </ul>
                </div>
              </div>
              <!-- end sb-panel-action -->
            </div>
          </slide>
        </carousel>
      </div>
    </div>
    <div class="add-new-panel" :class="sbCTAClass">
      <span v-if="isAllowedAddSpecimen" @click="onAddNewSpecimen()" class="add-new-specimen-btn">
        <md-icon>add_circle</md-icon>
        <md-tooltip md-direction="top" class="tooltip-align-center tooltip-modal"> Add specimen </md-tooltip>
      </span>
    </div>
  </div>
</template>
<script>
/* eslint-disable-next-line security/detect-object-injection */
import ModalSbAddSlides from '@/components/Lims/modals/ModalSbAddSlides.vue';
import ModalCreateSpecimen from '@/components/Lims/modals/ModalCreateSpecimen.vue';
import { Carousel, Slide } from 'vue-carousel';
import { uniqBy, cloneDeep } from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import { ACTION_IN_SLIDE_BLOCK, APP_EVENTS, CASE_STATUS, newAppEvent } from '@/core/constants';
import { generateSpecimenBlockName } from '../../CaseSpecimen.mixins';
import ModalConfirmSlideBlock from '@/components/Lims/modals/ModalConfirmSlideBlock.vue';
import { caseFormService } from '@/services';
import { SPECIMEN_ELEMENT_OBJECT_TYPE, checkSlideInPendingAlwRequestError } from '../../Case.helpers';

export const transformCaseData = (caseData, generateBlockName) => {
  let { caseSpecimens, sameSpecimenTypeName } = caseData;
  const specimenIndex = caseSpecimens.length;
  const blockIndex = 0;
  // if a specimen has data but has no caseSpecimenBlocks, add default block with empty data
  caseSpecimens = caseSpecimens.map((specimen) => {
    if (specimen.caseSpecimenBlocks.length == 0) {
      return {
        ...specimen,
        caseSpecimenBlocks: [
          {
            // caseSpecimenBlockId: '',
            specimentTypeName: sameSpecimenTypeName,
            blockFieldItems: [],
            block: generateBlockName(specimenIndex, blockIndex),
          },
        ],
      };
    }
    return specimen;
  });
  return {
    ...caseData,
    caseSpecimens,
  };
};

export default {
  components: {
    ModalSbAddSlides,
    ModalCreateSpecimen,
    ModalConfirmSlideBlock,
    Carousel,
    Slide,
  },
  props: {
    caseObjectData: {
      type: Object,
      require: true,
    },
    commonlyUsedSlides: {
      type: Array,
      required: true,
    },
    specimenNumberSetting: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    caseStatus() {
      return CASE_STATUS;
    },
    isReportedStatus() {
      return this.caseObjectData.status == CASE_STATUS.REPORTED;
    },
    commonlyUsedSlidesFilterByStatus() {
      return this.commonlyUsedSlides.filter((item) => item.isHide === false);
    },
    isAllowedAddSpecimen() {
      //  Hide "Add Specimen" icon for case status = Provisionally reported/Reported
      const noOfSpecimen =
        this.caseObjectData && this.caseObjectData.caseSpecimens ? this.caseObjectData.caseSpecimens.length : 1;
      return (
        ![CASE_STATUS.REPORTED, CASE_STATUS.PROVISIONALLY_REPORTED].includes(this.caseObjectData.status) &&
        noOfSpecimen < this.specimenNumberSetting
      );
    },
    specimens() {
      return this.formData.caseSpecimens;
    },
    ...mapGetters('app/event', [APP_EVENTS.EVT_ON_SB_DESELECT_ALL]),
    ...mapGetters('sb', ['caseRefHasSelectedBlocksPassLab', 'sbCTAClass', 'expand']),
    isStatusShowModalConfirmSlideBlock() {
      return [CASE_STATUS.PATH, CASE_STATUS.REPORTED, CASE_STATUS.PROVISIONALLY_REPORTED].includes(
        this.caseObjectData.status,
      );
    },
  },
  async created() {
    this.caseObject = this.caseObjectData;
    await this.fetchData();
  },
  watch: {
    sbCTAClass: {
      deep: true,
      handler: function (v) {
        this.isLoading = v !== '';
      },
    },
    selectedBlocks: {
      deep: true,
      handler: function (v) {
        this.$updateSelectedBlocks({ caseId: this.caseObjectData.caseId, blocks: cloneDeep(v) });
        this.updateCaseRefHasSelectedPassLab({
          caseReference: this.caseObjectData.caseReference,
          count: this.isStatusShowModalConfirmSlideBlock ? v.length : 0,
        });
      },
    },
    caseObjectData: {
      deep: true,
      handler: function (v) {
        this.caseObject = v;
        this.fetchData();
      },
    },
    [APP_EVENTS.EVT_ON_SB_DESELECT_ALL]: {
      deep: true,
      handler: function (v) {
        if (v && v.trigger) {
          this.$set(this, 'selectedBlocks', []);
        }
      },
    },
    // 'formData.caseSpecimens': {
    //   deep: true,
    //   handler: function (v, prevV) {
    //     if (v && prevV) {
    //       this.saveCaseSpecimens(v);
    //     }
    //   },
    // },
  },
  data() {
    return {
      formData: {},
      selectedBlocks: [],
      noOfBlock: 1,
      activeBlock: null,
      caseObject: null,
      confirmSlideBlockModalDescription: '',
      dataAddBlock: null,
      dataDeleteBlock: null,
      dataAddSlide: null,
      dataDropSlide: null,
      dataRemoveSlide: null,
      slideIdsRemoved: [],
      slideIdsRemovedList: [],
      isLoading: false,
    };
  },
  methods: {
    ...mapActions('app/event', ['removeEvent', 'addEvent']),
    ...mapActions('sb', [
      'updateCaseRefHasSelectedPassLab',
      '$updateSelectedBlocks',
      '$enableIsLoading',
      '$disableIsLoading',
    ]),
    toggleExpand(expand) {
      this.expand = expand;
    },
    onToggleSelectedBlock(specimenIndex, blockIndex, specimenBlock) {
      const selectedBlockIndexKey = this.getBlockIndexKey(specimenIndex, blockIndex);
      if (this.isSelectedBlock(specimenIndex, blockIndex)) {
        this.removeSelectedBlock(specimenIndex, blockIndex);
      } else {
        this.selectedBlocks.push({
          blockKey: selectedBlockIndexKey,
          specimenIndex,
          blockIndex,
          block: specimenBlock,
        });
      }
    },
    getBlockIndexKey(specimenIndex, blockIndex) {
      return `k_${specimenIndex}_${blockIndex}`;
    },
    isSelectedBlock(specimenIndex, blockIndex) {
      const selectedBlockIndexKey = this.getBlockIndexKey(specimenIndex, blockIndex);
      return this.selectedBlocks.map((v) => v.blockKey).includes(selectedBlockIndexKey);
    },
    removeSelectedBlock(specimenIndex, blockIndex) {
      const selectedBlockIndexKey = this.getBlockIndexKey(specimenIndex, blockIndex);
      const newSelectedBlocks = this.selectedBlocks.filter((v) => v.blockKey != selectedBlockIndexKey);
      this.selectedBlocks = newSelectedBlocks;
    },
    async fetchData() {
      this.formData = transformCaseData(this.caseObject, this.generateBlock);
    },
    generateBlock(specimenIndex, blockIndex) {
      const namingRule = this.caseObject.namingRule;
      return generateSpecimenBlockName(specimenIndex, blockIndex, namingRule);
    },
    saveCaseSpecimens(caseSpecimens) {
      this.$enableIsLoading();
      this.$emit('onSaveSpecimen', {
        caseId: this.formData.caseId,
        caseSpecimens,
      });
    },

    onShowConfirmAddSlide(specimenBlock) {
      // CTA: AddSlide
      if (this.isDisabledCTA()) {
        return;
      }
      this.dataAddSlide = specimenBlock;
      if (this.isStatusShowModalConfirmSlideBlock) {
        this.showModalConfirmSlideBlock({
          action: ACTION_IN_SLIDE_BLOCK.ADD_SLIDE,
          caseRef: this.caseObjectData.caseReference,
        });
      } else {
        this.onAddSlide(this.dataAddSlide);
      }
    },

    onAddSlide(specimenBlock) {
      this.$refs.addSlideModal.setExistedSlides(specimenBlock.blockFieldItems);
      const addDeletedSlideToSlideList = this.isReportedStatus
        ? this.commonlyUsedSlides.filter(
            (f) => (this.slideIdsRemovedList.includes(f.fieldItemId) && f.isHide) || !f.isHide,
          )
        : this.commonlyUsedSlidesFilterByStatus;
      this.slideIdsRemovedList = [];
      this.$refs.addSlideModal.listSlides = addDeletedSlideToSlideList;
      this.$refs.addSlideModal.open();
      this.activeBlock = specimenBlock;
      this.dataAddSlide = null;
    },

    onShowConfirmDropSlide(specimenBlock, specimenIndex, blockIndex, $event) {
      const rawData = $event.dataTransfer.getData('text');
      const data = JSON.parse(rawData);

      this.dataDropSlide = { specimenBlock, specimenIndex, blockIndex, data };

      if (this.isStatusShowModalConfirmSlideBlock) {
        this.showModalConfirmSlideBlock({
          action: ACTION_IN_SLIDE_BLOCK.ADD_SLIDE,
          caseRef:
            this.caseRefHasSelectedBlocksPassLab && this.caseRefHasSelectedBlocksPassLab.length > 0
              ? this.caseRefHasSelectedBlocksPassLab.join(', ')
              : this.caseObjectData.caseReference,
        });
      } else {
        this.onDrop(this.dataDropSlide);
      }
    },

    onDrop(dataDropSlide) {
      const specimenBlock = dataDropSlide.specimenBlock;
      const specimenIndex = dataDropSlide.specimenIndex;
      const blockIndex = dataDropSlide.blockIndex;
      const data = dataDropSlide.data;

      this.activeBlock = specimenBlock;
      if (data.panelItems) {
        // isPanel
        if (this.isSelectedBlock(specimenIndex, blockIndex)) {
          this.addEvent(
            newAppEvent(APP_EVENTS.EVT_ON_SB_ADD_PANEL, {
              panel: data,
            }),
          );
        } else {
          this.getSlideAdded([...data.panelItems]);
        }
      } else {
        if (this.isSelectedBlock(specimenIndex, blockIndex)) {
          this.addEvent(
            newAppEvent(APP_EVENTS.EVT_ON_SB_ADD_SLIDE, {
              slide: data,
            }),
          );
        } else {
          this.getSlideAdded([data]);
        }
      }
      this.dataDropSlide = null;
    },

    getSlideAdded(val) {
      const items = cloneDeep(this.activeBlock.blockFieldItems);
      items.push(...val);
      this.activeBlock.blockFieldItems = uniqBy(items, 'fieldItemId');
      // save
      this.saveCaseSpecimens(this.formData.caseSpecimens);
    },

    onAddNewSpecimen() {
      // CTA: AddNewSpecimen
      if (this.isDisabledCTA()) {
        return;
      }
      this.$refs.createSpecimenModal.clinicId = this.caseObjectData.clinicId;
      this.$refs.createSpecimenModal.workStreamId = this.caseObjectData.workStreamId;
      this.$refs.createSpecimenModal.open();
    },
    onAddedSpecimen(data) {
      this.$refs.createSpecimenModal.close();
      const { specimenTypeId, specimenDetails, macroDescription, specimentTypeName } = data;
      const specimenIndex = this.formData.caseSpecimens.length + 1;
      const blockIndex = 0;
      const selectedSpecimentTypeName = this.formData.isSameSpecimenType
        ? this.formData.sameSpecimenTypeName
        : specimentTypeName;

      this.formData.caseSpecimens.push({
        // caseSpecimentId: '',
        specimentTypeName: selectedSpecimentTypeName, // [TODO]SELECT FROM MODAL
        specimenTypeId: specimenTypeId,
        specimenDetails: specimenDetails,
        macroDescription: macroDescription,
        caseSpecimenBlocks: [
          {
            // caseSpecimenBlockId: '',
            specimentTypeName: selectedSpecimentTypeName,
            blockFieldItems: [],
            block: this.generateBlock(specimenIndex, blockIndex),
          },
        ],
      });
      // save
      this.saveCaseSpecimens(this.formData.caseSpecimens);
    },

    onShowConfirmAddBlock(specimen) {
      // CTA: AddBlock
      if (this.isDisabledCTA()) {
        return;
      }
      this.dataAddBlock = specimen;
      if (this.isStatusShowModalConfirmSlideBlock) {
        this.showModalConfirmSlideBlock({
          action: ACTION_IN_SLIDE_BLOCK.ADD_BLOCK,
          caseRef: this.caseObjectData.caseReference,
        });
      } else {
        this.onAddBlock(this.dataAddBlock);
      }
    },

    onAddBlock(specimen) {
      specimen.caseSpecimenBlocks.push({
        // caseSpecimenBlockId: '',
        specimentTypeName: specimen.specimentTypeName,
        blockFieldItems: [],
        block: '',
      });
      // save
      this.saveCaseSpecimens(this.formData.caseSpecimens);
      this.dataAddBlock = null;
    },
    getDeletedSlideWhenRemovedBlock(blockFieldItems) {
      let deletedSlides = [];
      blockFieldItems.forEach((item) => {
        deletedSlides.push(item.fieldItemId);
      });
      this.slideIdsRemoved = deletedSlides;
    },
    async onShowConfirmDeleteBlock(specimen, specimenIndex, blockIndex, block) {
      // CTA: DeleteBlock
      if (this.isDisabledCTA()) {
        return;
      }
      this.$enableIsLoading();
      this.dataDeleteBlock = { specimen, specimenIndex, blockIndex };
      this.getDeletedSlideWhenRemovedBlock(specimen.caseSpecimenBlocks[blockIndex].blockFieldItems);
      if (
        ![CASE_STATUS.REPORTED, CASE_STATUS.PROVISIONALLY_REPORTED, CASE_STATUS.PATH].includes(this.formData.status)
      ) {
        this.onDeleteBlock(this.dataDeleteBlock);
        return;
      }
      const specimenId = specimen.caseSpecimentId;
      const blockId = block.caseSpecimenBlockId;
      const { valid, error } = await this.checkSlideInPendingAlwRequest(specimenId, blockId);
      if (valid) {
        if (this.isStatusShowModalConfirmSlideBlock) {
          this.showModalConfirmSlideBlock({
            action: ACTION_IN_SLIDE_BLOCK.DELETE_BLOCK,
            caseRef: this.caseObjectData.caseReference,
          });
        } else {
          this.onDeleteBlock(this.dataDeleteBlock);
        }
      } else {
        this.$alertError(error);
        this.$disableIsLoading();
      }
    },

    onDeleteBlock(dataDeleteBlock) {
      const specimen = dataDeleteBlock.specimen;
      const specimenIndex = dataDeleteBlock.specimenIndex;
      const blockIndex = dataDeleteBlock.blockIndex;
      // remove from selectedBlocks
      this.removeSelectedBlock(specimenIndex, blockIndex);
      const caseSpecimenBlocks = cloneDeep(specimen.caseSpecimenBlocks);
      caseSpecimenBlocks.splice(blockIndex, 1);
      specimen.caseSpecimenBlocks = caseSpecimenBlocks;
      if (specimen.caseSpecimenBlocks.length === 0) {
        this.onDeleteSpecimen(specimenIndex);
      } else {
        // save
        this.saveCaseSpecimens(this.formData.caseSpecimens);
      }
    },

    onDeleteSlides(specimenBlock) {
      // CTA: DeleteSlides
      if (this.isDisabledCTA()) {
        return;
      }
      if (
        ![CASE_STATUS.PATH, CASE_STATUS.REPORTED, CASE_STATUS.PROVISIONALLY_REPORTED].includes(
          this.caseObjectData.status,
        )
      ) {
        specimenBlock.blockFieldItems = [];
        // save
        this.saveCaseSpecimens(this.formData.caseSpecimens);
      } else {
        this.$alertError(this.$t('pages/case/CaseManagement/SB/error/noSlideInBlock'));
      }
    },

    async onShowConfirmRemoveSlide(specimenBlock, slideIdx, $event) {
      // CTA: RemoveSlide
      if (this.isDisabledCTA()) {
        return;
      }
      const specimenId = specimenBlock.caseSpecimenId;
      const blockId = specimenBlock.caseSpecimenBlockId;
      this.$enableIsLoading();
      this.dataRemoveSlide = { specimenBlock, slideIdx, $event };
      this.slideIdsRemoved.push(specimenBlock.blockFieldItems[slideIdx].fieldItemId);
      this.slideIdsRemovedList.push(specimenBlock.blockFieldItems[slideIdx].fieldItemId);
      if (
        ![CASE_STATUS.REPORTED, CASE_STATUS.PROVISIONALLY_REPORTED, CASE_STATUS.PATH].includes(this.formData.status)
      ) {
        this.onRemoveSlide(this.dataRemoveSlide);
        return;
      }
      const isDeletingSlide = true;
      const { valid, error } = await this.checkSlideInPendingAlwRequest(specimenId, blockId, isDeletingSlide);
      if (valid) {
        if (this.isStatusShowModalConfirmSlideBlock) {
          this.showModalConfirmSlideBlock({
            action: ACTION_IN_SLIDE_BLOCK.DELETE_SLIDE,
            caseRef: this.caseObjectData.caseReference,
          });
        } else {
          this.onRemoveSlide(this.dataRemoveSlide);
        }
      } else {
        this.$alertError(error);
        this.$disableIsLoading();
      }
    },

    async checkSlideInPendingAlwRequest(specimenId, blockId, isDeletingSlide = false) {
      // pass slide ids if is deleting slide
      const slideIdsRemoved = isDeletingSlide ? this.slideIdsRemoved : null;
      const { data, error } = await caseFormService.checkSlideInPendingAlwRequest(
        slideIdsRemoved,
        this.formData.caseId,
        specimenId,
        blockId,
      );
      this.slideIdsRemoved = [];
      if (error) {
        return {
          valid: false,
          error: error,
        };
      }
      const { pendingAlwRequests } = data;
      if (pendingAlwRequests == null || pendingAlwRequests.length == 0) {
        return {
          valid: true,
          error: null,
        };
      }
      // show custom error message for relevance pending/completed alw requests
      const objectType = isDeletingSlide ? SPECIMEN_ELEMENT_OBJECT_TYPE.SLIDE : SPECIMEN_ELEMENT_OBJECT_TYPE.BLOCK;
      const errorMessage = checkSlideInPendingAlwRequestError({
        pendingAlwRequests,
        $t: (v, params) => {
          return this.$t(v, params);
        },
        objectType,
      });

      return {
        valid: false,
        error: errorMessage,
      };
    },

    onRemoveSlide(dataRemoveSlide) {
      const specimenBlock = dataRemoveSlide.specimenBlock;
      const slideIdx = dataRemoveSlide.slideIdx;
      const $event = dataRemoveSlide.$event;

      const isLastSlideInBlock = specimenBlock ? specimenBlock.blockFieldItems.length == 1 : false;
      if (
        isLastSlideInBlock &&
        [CASE_STATUS.PATH, CASE_STATUS.REPORTED, CASE_STATUS.PROVISIONALLY_REPORTED].includes(
          this.caseObjectData.status,
        )
      ) {
        this.$alertError(this.$t('pages/case/CaseManagement/SB/error/noSlideInBlock'));
        this.$disableIsLoading();
      } else {
        const blockFieldItems = cloneDeep(dataRemoveSlide.specimenBlock.blockFieldItems);
        blockFieldItems.splice(slideIdx, 1);
        dataRemoveSlide.specimenBlock.blockFieldItems = blockFieldItems;
        $event.stopPropagation();
        // save
        this.saveCaseSpecimens(this.formData.caseSpecimens);
      }
    },

    onDeleteSpecimen(specimenIndex) {
      // CTA: DeleteSpecimen
      if (this.isDisabledCTA()) {
        return;
      }
      this.formData.caseSpecimens.splice(specimenIndex, 1);
      // save
      this.saveCaseSpecimens(this.formData.caseSpecimens);
    },

    isDeleteAble(specimenIndex, specimensLength, caseSpecimenBlocks) {
      const isLastSpecimen = specimenIndex === specimensLength - 1;
      let specimenHasNoSlidesLeft = true;
      for (let i = 0; i < caseSpecimenBlocks.length; i++) {
        if (caseSpecimenBlocks[i].blockFieldItems.length > 0) {
          specimenHasNoSlidesLeft = false;
          break;
        }
      }
      const caseHasMoreThanOneSpecimen = this.formData.caseSpecimens.length > 1;

      return (
        isLastSpecimen &&
        specimenHasNoSlidesLeft &&
        caseHasMoreThanOneSpecimen &&
        ![CASE_STATUS.REPORTED, CASE_STATUS.PROVISIONALLY_REPORTED].includes(this.caseObjectData.status)
      );
    },

    // PATH, REPORTED, PROVISIONALLY_REPORTED Status -> Hide “Delete Block” if the current block is the last block of specimen
    isShowDeleteBlock(blockIndex, caseSpecimenBlocksList) {
      const lastBlockOfSpecimen = caseSpecimenBlocksList ? caseSpecimenBlocksList.length == 1 : false;
      const isSpecimenBlocksHasSlide = caseSpecimenBlocksList[blockIndex].blockFieldItems.length > 0 ? true : false;

      let caseSpecimenBlocksHasSlide = [];
      caseSpecimenBlocksList.forEach((element) => {
        if (element.blockFieldItems.length > 0) {
          caseSpecimenBlocksHasSlide.push(element);
        }
      });
      return !(
        (lastBlockOfSpecimen || (isSpecimenBlocksHasSlide && caseSpecimenBlocksHasSlide.length == 1)) &&
        [CASE_STATUS.PATH, CASE_STATUS.REPORTED, CASE_STATUS.PROVISIONALLY_REPORTED].includes(
          this.caseObjectData.status,
        )
      );
    },

    showModalConfirmSlideBlock(data) {
      this.confirmSlideBlockModalDescription = this.$t(
        'pages/case/CaseManagement/SB/ModalConfirmSlideBlock.description',
        {
          action: data.action,
          caseRef: data.caseRef,
        },
      );
      this.$refs.confirmSlideBlockModal.open({ action: data.action });
    },

    onCancelActionInSlideBlock() {
      this.$disableIsLoading();
    },

    onConfirmActionInSlideBlock(val) {
      if (val == ACTION_IN_SLIDE_BLOCK.ADD_BLOCK) {
        this.onAddBlock(this.dataAddBlock);
      }
      if (val == ACTION_IN_SLIDE_BLOCK.DELETE_BLOCK) {
        this.onDeleteBlock(this.dataDeleteBlock);
      }
      if (val == ACTION_IN_SLIDE_BLOCK.ADD_SLIDE) {
        if (this.dataAddSlide) {
          this.onAddSlide(this.dataAddSlide);
        }
        if (this.dataDropSlide) {
          this.onDrop(this.dataDropSlide);
        }
      }
      if (val == ACTION_IN_SLIDE_BLOCK.DELETE_SLIDE) {
        this.onRemoveSlide(this.dataRemoveSlide);
      }
    },
    isDisabledCTA() {
      return this.isLoading;
    },
  },
};
</script>
<style lang="scss">
.VueCarousel-navigation button {
  z-index: 10;
}
.VueCarousel-inner {
  height: 100% !important;
}
.VueCarousel-wrapper {
  height: 100% !important;
}
.VueCarousel-navigation {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
.VueCarousel-navigation button {
  position: relative !important;
  transform: translateX(0) translateY(0) !important;
}
</style>
