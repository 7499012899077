<template>
  <div class="sb-card">
    <modal-confirm-slide-block
      ref="confirmSlideBlockModal"
      :title="$t('components/lims/modals/ModalConfirmation.title')"
      :description="confirmSlideBlockModalDescription"
      @onConfirm="onConfirmActionInSlideBlock"
    />
    <div class="sb-card-header">
      <md-icon>filter_none</md-icon>
      <span>{{ $t('pages/case/CaseManagement/SB/Component/CommonlyUsedPanels/cardTitle') }}</span>
    </div>
    <div class="sb-card-body">
      <div class="list-group">
        <div
          class="list-item"
          v-for="(element, panelIndex) in options"
          :class="sbCTAClass"
          :key="element.name"
          :draggable="!disabled"
          @dragstart="onDrag(element, $event)"
        >
          <md-icon>open_with</md-icon>
          {{ element.name }}
          <md-tooltip md-direction="top" class="tooltip-align-center tooltip-modal">
            <ul style="text-align: left; margin: 0; padding: 5px; list-style-type: none">
              <li v-for="(item, itemIndex) in element.panelItems" :key="`${panelIndex}-${itemIndex}`">
                <template v-if="!item.isHide">{{ item.fieldItemName }}</template>
              </li>
            </ul>
          </md-tooltip>
        </div>
      </div>
      <div class="drop-area" :class="dropAreaClass" @dragover.prevent @dragenter.prevent @drop="onDrop($event)">
        <div class="text-center">
          {{ dropAreaLabel() }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import ModalConfirmSlideBlock from '@/components/Lims/modals/ModalConfirmSlideBlock.vue';
import { ACTION_IN_SLIDE_BLOCK } from '@/core/constants';

export default {
  components: {
    ModalConfirmSlideBlock,
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      confirmSlideBlockModalDescription: '',
      dataDrop: null,
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters('sb', ['numberOfSelectedBlocks', 'caseRefHasSelectedBlocksPassLab', 'dropAreaClass', 'sbCTAClass']),
    disabled(){
      return this.isLoading;
    }
  },
  watch: {
    sbCTAClass: {
      deep: true,
      handler: function (v) {
        this.isLoading = v !== '';
      },
    },
  },
  methods: {
    dropAreaLabel() {
      if (this.numberOfSelectedBlocks === 0) {
        return this.$translate('pages/case/CaseManagement/SB/Component/CommonlyUsedPanels/dropArea');
      }
      if (this.numberOfSelectedBlocks === 1) {
        return this.$translate('pages/case/CaseManagement/SB/Component/CommonlyUsedPanels/dropArea.single');
      }
      return this.$translate('pages/case/CaseManagement/SB/Component/CommonlyUsedPanels/dropArea.multiple', {
        count: this.numberOfSelectedBlocks,
      });
    },
    onDrag(value, $event) {
      $event.dataTransfer.setData('text', JSON.stringify(value));
    },
    onDrop(event) {
      const isDisabled = this.dropAreaClass === 'disabled';
      if (isDisabled) {
        return;
      }
      this.dataDrop = event.dataTransfer.getData('text');
      if (this.caseRefHasSelectedBlocksPassLab && this.caseRefHasSelectedBlocksPassLab.length > 0) {
        this.showModalConfirmSlideBlock();
      } else {
        this.dropData(this.dataDrop);
      }
      event.preventDefault();
    },
    dropData(data) {
      if (typeof data !== 'undefined') {
        this.$emit('input', JSON.parse(data));
      }
      this.dataDrop = null;
    },

    showModalConfirmSlideBlock() {
      this.confirmSlideBlockModalDescription = this.$t(
        'pages/case/CaseManagement/SB/ModalConfirmSlideBlock.description',
        {
          action: ACTION_IN_SLIDE_BLOCK.ADD_SLIDE,
          caseRef: this.caseRefHasSelectedBlocksPassLab.join(', '),
        },
      );
      this.$refs.confirmSlideBlockModal.open({ action: ACTION_IN_SLIDE_BLOCK.ADD_SLIDE });
    },

    onConfirmActionInSlideBlock() {
      this.dropData(this.dataDrop);
    },
  },
};
</script>
