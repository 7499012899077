const DashboardLayout = () => import('@/layouts/DashboardLayout.vue');
import CaseManagement from '@/pages/Case/CaseManagement/Case.vue';
import AddCase from '@/pages/Case/CaseManagement/Add/AddCase.vue';
import EditCase from '@/pages/Case/CaseManagement/Edit/EditCase.vue';
import AlwManagement from '@/pages/Case/AlwManagement/Alw.vue';
import EditAlw from '@/pages/Case/AlwManagement/Edit/EditAlw';
import AuditTrail from '@/pages/Case/CaseManagement/AuditTrail/ViewAuditTrail.vue';
import RespondToIssue from '@/pages/Case/CaseManagement/RespondToIssue/RespondToIssue.vue';
import BulkDiagnosis from '@/pages/Case/CaseManagement/BulkDiagnosis/BulkDiagnosis.vue';
import Report from '@/pages/Case/CaseManagement/Report/Report.vue';
import SB from '@/pages/Case/CaseManagement/SB/SB.vue';
import RFUpload from '@/pages/Case/CaseManagement/RFUpload/RFUpload.vue';
import RFUploadSuccess from '@/pages/Case/CaseManagement/RFUpload/RFUploadSuccess.vue';
import RFUploadCheck from '@/pages/Case/CaseManagement/RFUpload/RFUploadCheck.vue';
import guards from './guards';
import { FORM_MODES } from '@/core/constants';

export default {
  path: '/cases',
  component: DashboardLayout,
  meta: {
    zone: 'private',
    rbac: 'cases',
  },
  beforeEnter: guards.checkAuthorizedGuard,
  children: [
    {
      path: '',
      name: 'CaseManagement',
      component: CaseManagement,
      props: () => ({
        isCaseOfInterest: false,
      }),
    },

    {
      path: 'add',
      name: 'CaseManagementAdd',
      component: AddCase,
      props: true,
      meta: {
        zone: ['Case_CaseForm_Create'],
      },
    },
    {
      path: 'edit/:caseId',
      name: 'CaseManagementEdit',
      component: EditCase,
      props: (route) => ({
        formMode: FORM_MODES.EDIT,
        caseId: route.params.caseId,
      }),
      meta: {
        zone: ['Case_CaseForm_Update'],
      },
    },
    {
      path: 'view/:caseId',
      name: 'CaseManagementView',
      component: EditCase,
      props: (route) => ({
        formMode: FORM_MODES.VIEW,
        caseId: route.params.caseId,
      }),
      meta: {
        zone: ['Case_CaseForm_Update'],
      },
    },
    {
      path: 'alw-management',
      name: 'AlwManagement',
      component: AlwManagement,
      meta: {
        zone: ['Case_ALWManagement_Retrieve'],
      },
    },
    {
      path: 'alw-management/edit/:caseALWRequestId',
      name: 'AlwManagementEdit',
      component: EditAlw,
      props: (route) => ({
        formMode: FORM_MODES.EDIT,
        caseALWRequestId: route.params.caseALWRequestId,
      }),
      meta: {
        zone: ['Case_ALWManagement_Update'],
      },
    },
    {
      path: 'alw-management/view/:caseALWRequestId',
      name: 'AlwManagementView',
      component: EditAlw,
      props: (route) => ({
        formMode: FORM_MODES.VIEW,
        caseALWRequestId: route.params.caseALWRequestId,
      }),
      meta: {
        zone: ['Case_ALWManagement_Retrieve'],
      },
    },
    {
      path: 'edit-so-path-view/:caseId',
      name: 'CaseManagementEditSoPathView',
      component: EditCase,
      props: (route) => ({
        formMode: FORM_MODES.EDIT,
        caseId: route.params.caseId,
        soPathView: true,
      }),
      meta: {
        zone: ['Case_CaseForm_Update'],
      },
    },
    {
      path: 'audit-trail/:caseId',
      name: 'ViewAuditTrail',
      component: AuditTrail,
      props: (route) => ({
        caseId: route.params.caseId,
      }),
      meta: {
        zone: ['Case_CaseForm_Update'],
      },
    },
    {
      path: 'respond-to-issue/:caseIssueId/:token',
      name: 'RespondToIssue',
      component: RespondToIssue,
      props: true,
    },
    {
      path: 'respond-to-issue/:caseId',
      name: 'RespondToIssueFromList',
      component: RespondToIssue,
      props: (route) => ({
        caseId: route.params.caseId,
        respondToIssueFromList: true,
      }),
    },
    {
      path: 'bulk-diagnosis',
      name: 'BulkDiagnosis',
      component: BulkDiagnosis,
      beforeEnter: guards.checkAuthorizedGuard,
      meta: {
        zone: ['Case_CaseForm_Update'],
      },
    },
    {
      path: 'report/:caseId',
      name: 'CaseManagementReport',
      component: Report,
      props: (route) => ({
        caseId: route.params.caseId,
      }),
      meta: {
        zone: ['Case_CaseForm_Update', 'Case_CaseReport_Retrieve'],
      },
    },
    {
      path: 'sb',
      name: 'SB',
      component: SB,
      meta: {
        zone: ['Case_CaseForm_Update'],
      },
    },
    {
      path: 'rfupload',
      name: 'RFUpload',
      component: RFUpload,
      beforeEnter: guards.checkAuthorizedGuard,
      meta: {
        zone: ['Case_CaseForm_Update'],
      },
    },
    {
      path: 'rfuploadcheck',
      name: 'RFUploadCheck',
      component: RFUploadCheck,
      beforeEnter: guards.checkAuthorizedGuard,
      meta: {
        zone: ['Case_CaseForm_Update'],
      },
    },
    {
      path: 'RFUploadSuccess',
      name: 'RFUploadSuccess',
      component: RFUploadSuccess,
      beforeEnter: guards.checkAuthorizedGuard,
      meta: {
        zone: ['Case_CaseForm_Update'],
      },
    },
  ],
};
