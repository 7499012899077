// Layouts
const DashboardLayout = () => import('@/layouts/DashboardLayout.vue');

// Request pages
import Request from '@/pages/Case/RequestManagement/Request.vue';

import guards from './guards';

export default {
  path: '/request',
  component: DashboardLayout,
  beforeEnter: guards.checkAuthorizedGuard,
  meta: {
    zone: 'private',
  },
  children: [
    {
      path: '/',
      name: 'RequestManagement',
      component: Request,
      beforeEnter: guards.checkAuthorizedGuard,
      meta: {
        zone: ['Case_AnonymiseInformation-Approve_Validate'],
      },
    },
  ],
};
