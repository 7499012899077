<template>
  <div>
    <md-table class="paginated-table table-striped table-hover lims-table case-list-tbl">
      <md-table-toolbar>
        <slot name="toolbar"></slot>
      </md-table-toolbar>
      <div class="table-fix-header">
        <thead>
          <md-table-row>
            <md-table-head class="checkbox-column"> </md-table-head>
            <md-table-head v-for="(col, index) in colTabl" :key="index" :class="col.class">
              <div @mouseover="hoverIn(index)" @mouseout="hoverOut()" @click="sortCol(col.key, col.sort)">
                {{ $t(col.name) }}
                <span>
                  <md-icon v-if="col.sort">
                    {{
                      col.key === orderState.orderField
                        ? orderState.orderBy === 'asc'
                          ? 'arrow_drop_up'
                          : 'arrow_drop_down'
                        : hover === index
                        ? 'arrow_drop_up'
                        : ''
                    }}
                  </md-icon>
                </span>
              </div>
            </md-table-head>
          </md-table-row>
        </thead>
        <tbody>
          <md-table-row v-for="(item, index) in caseListItems" :key="`case-${index}`" :class="checkRowHighlight(item)">
            <md-table-cell class="checkbox-column">
              <input type="radio" id="index" :value="item" v-model="selectedRow" class="lims-checkbox" />
            </md-table-cell>
            <md-table-cell class="caseRef-column">
              {{ item.caseReference }}
            </md-table-cell>
            <md-table-cell>{{ item.labReference }}</md-table-cell>
            <md-table-cell>{{ item.patientFullname }}</md-table-cell>
            <md-table-cell>{{ item.clinicName }}</md-table-cell>
            <md-table-cell>{{ item.clinicianName }}</md-table-cell>
            <md-table-cell>{{ item.pathologistName }}</md-table-cell>
            <md-table-cell>{{ item.specimenName }}</md-table-cell>
            <md-table-cell class="date-column">
              {{ item.procedureDate ? formatDateTime(item.procedureDate) : '' }}
            </md-table-cell>
            <md-table-cell>
              {{ item.procedureLabEntry ? formatDateTime(item.procedureLabEntry) : '' }}
            </md-table-cell>
          </md-table-row>
        </tbody>
      </div>
    </md-table>
    <slot name="pagination"></slot>
  </div>
</template>
<script>
import CaseListMixin from '@/pages/Case/CaseManagement/List/caseList.mixins';

export default {
  mixins: [CaseListMixin],
  props: {
    caseListItems: {
      required: false,
      default: function () {
        return [];
      },
    },
  },
  created() {
    this.fetchData();
  },
  data() {
    return {
      selectedRow: null,
      colTabl: [
        {
          name: 'pages.case.caseManagement/list.caseRef',
          key: 'caseReference',
          sort: false,
          class: 'caseRef-column',
        },
        {
          name: 'pages.case.caseManagement/list.labRef',
          key: 'labReference',
          sort: false,
          class: 'labRef-column',
        },
        {
          name: 'pages.case.caseManagement/list.patient',
          key: 'patientFullname',
          sort: false,
          class: 'patient-column',
        },
        {
          name: 'pages.case.caseManagement/list.clinic',
          key: 'clinicName',
          sort: false,
          class: 'clinic-column',
        },
        {
          name: 'pages.case.caseManagement/list.clinician',
          key: 'clinicianName',
          sort: false,
          class: 'clinician-column',
        },
        {
          name: 'pages.case.caseManagement/list.pathologist',
          key: 'pathologistName',
          sort: false,
          class: 'pathologist-column',
        },
        {
          name: 'pages.case.caseManagement/list.specimenType',
          key: 'specimenName',
          sort: false,
          class: 'specimen-column',
        },
        {
          name: 'pages.case.caseManagement/list.Procedure',
          key: 'procedureDate',
          sort: false,
          class: 'date-column',
        },
        {
          name: 'pages.case.caseManagement/list.labEntry',
          key: 'procedureLabEntry',
          sort: false,
          class: 'date-column',
        },
      ],
    };
  },
  watch: {
    selectedRow(val) {
      this.$emit('onChangeItem', val);
    },
    caseListItems() {
      this.selectedRow = this.caseListItems[0];
    },
  },
  methods: {
    fetchData() {},
  },
};
</script>

<style lang="scss"></style>
