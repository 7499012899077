<template>
  <md-dialog :md-close-on-esc="true" :md-active.sync="isVisible" @md-clicked-outside="close" class="lims-modal">
    <div class="dialog-icon-close" @click="close"><md-icon>close</md-icon></div>
    <div class="lims-form dialog-content text-center">
      <p>{{ $t('component/lims/modals/modalScanQrCode/enterCode') }}</p>
      <p class="secret-code">{{ secretCode }}</p>
    </div>
    <md-dialog-actions>
      <md-button @click="onCancel" class="lims-form-button">{{ $t('global/button/button.cancel') }}</md-button>
      <md-button @click="onNext" class="lims-form-button md-primary">
        {{ $t('component/lims/modals/modalScanQrCode/nextBtn') }}
      </md-button>
    </md-dialog-actions>
  </md-dialog>
</template>

<script>
import modalMixins from './modal.mixins';

export default {
  name: 'modal-show-secret-code',

  mixins: [modalMixins],

  props: {
    secretCode: {
      type: String,
    },
  },

  methods: {
    onNext() {
      this.$emit('onNext');
    },

    onCancel() {
      this.close();
    },
  },
};
</script>

<style lang="scss">
.left-text {
  text-align: left;
}
.qr-code-position {
  text-align: center;
}
.secret-code {
  color: #2189dc;
  font-weight: 600;
}
</style>
