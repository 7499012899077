import { cloneDeep } from 'lodash';

const AuditTrailSchema = {
  entity: 'case/audit-trail',
  fields: {
    name: '',
    sex: '',
    dob: '',
    clinic: '',
    clinician: '',
    laboratory: '',
    pathologist: '',
    case: '',
    created: '',
    action: '',
    actorType: '',
    actorName: '',
    ipAddress: '',
    patient: '',
    infoAfterAction: '',
  },
};

const prepareAuditTrailSchema = (auditTrailSchema) => {
  const schema = cloneDeep(auditTrailSchema);
  // default add mode
  return schema;
};

export const getAuditTrailSchema = () => {
  return prepareAuditTrailSchema(AuditTrailSchema);
};
