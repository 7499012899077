var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
return [_c('md-dialog',{staticClass:"lims-modal large-modal modal-create-alw-request",attrs:{"md-close-on-esc":true,"md-active":_vm.isVisible},on:{"update:mdActive":function($event){_vm.isVisible=$event},"update:md-active":function($event){_vm.isVisible=$event},"md-clicked-outside":_vm.close}},[_c('div',{staticClass:"dialog-icon-close",on:{"click":_vm.close}},[_c('md-icon',[_vm._v("close")])],1),_c('form',{staticClass:"md-layout lims-form",on:{"submit":function($event){$event.preventDefault();return handleSubmit()}}},[_c('lims-block',{staticClass:"block-not-title"},[_c('div',{attrs:{"slot":"blockContent"},slot:"blockContent"},[_c('div',{staticClass:"md-layout lims-form-row"},[_c('div',{staticClass:"md-layout-item md-size-100 md-small-size-100"},[_c('lims-field',{staticClass:"textarea-field",attrs:{"model":_vm.formData,"schema":_vm.schema,"field":"caseBrief"}},[_c('md-textarea',{attrs:{"slot":"field"},slot:"field",model:{value:(_vm.formData.caseBrief),callback:function ($$v) {_vm.$set(_vm.formData, "caseBrief", $$v)},expression:"formData.caseBrief"}})],1)],1),_c('div',{staticClass:"md-layout-item md-size-100 md-small-size-100"},[_c('lims-field',{staticClass:"textarea-field",attrs:{"model":_vm.formData,"schema":_vm.schema,"field":"specialityId"}},[_c('v-select',{attrs:{"slot":"field-outside","multiple":"","label":"fieldItemName","options":_vm.specialityList,"reduce":function (option) { return option.fieldItemId; },"selectable":function (option) { return !_vm.formData.specialityId.includes(option.fieldItemId); },"placeholder":_vm.$t('components/ModalCreateSoRequest.placeholderSpeciality')},on:{"input":_vm.valLaboratory},slot:"field-outside",scopedSlots:_vm._u([{key:"option",fn:function(ref){
var fieldItemName = ref.fieldItemName;
return [_vm._v(_vm._s(fieldItemName))]}},{key:"selected-option",fn:function(ref){
var fieldItemName = ref.fieldItemName;
return [_vm._v(_vm._s(fieldItemName))]}}],null,true),model:{value:(_vm.formData.specialityId),callback:function ($$v) {_vm.$set(_vm.formData, "specialityId", $$v)},expression:"formData.specialityId"}})],1)],1),_c('div',{staticClass:"md-layout-item md-size-100 md-small-size-100"},[_c('lims-field',{staticClass:"textarea-field",attrs:{"model":_vm.formData,"schema":_vm.schema,"field":"userIds"}},[_c('v-select',{attrs:{"slot":"field-outside","multiple":"","label":"text","options":_vm.eligiblePathologistsList,"selectable":function (option) { return !_vm.formData.userIds.includes(option); },"placeholder":_vm.$t('components/ModalCreateSoRequest.placeholderEligiblePathologists')},slot:"field-outside",scopedSlots:_vm._u([{key:"option",fn:function(ref){
var text = ref.text;
return [_vm._v(_vm._s(text))]}},{key:"selected-option",fn:function(ref){
var text = ref.text;
return [_vm._v(_vm._s(text))]}}],null,true),model:{value:(_vm.formData.userIds),callback:function ($$v) {_vm.$set(_vm.formData, "userIds", $$v)},expression:"formData.userIds"}})],1)],1),_c('div',{staticClass:"md-layout-item md-size-100 md-small-size-100"},[_c('label',[_vm._v(_vm._s(_vm.$t('entities/modals/ModalCreateSoRequest/pathologistNote')))]),_vm._l((_vm.formData.userIds),function(item){return _c('div',{key:("note" + (item.id)),staticClass:"pathologist-noteList pd-top-10"},[_c('div',{staticClass:"name"},[_vm._v(_vm._s(item.text))]),_c('div',{staticClass:"note"},[_vm._v(_vm._s(item.note))])])})],2)])])]),_c('md-dialog-actions',[_c('md-button',{staticClass:"lims-form-button",on:{"click":_vm.close}},[_vm._v(_vm._s(_vm.$t('global/button/button.cancel')))]),_c('md-button',{staticClass:"lims-form-button md-primary",attrs:{"disabled":invalid || _vm.isProcessing},on:{"click":_vm.onCreate}},[_vm._v(_vm._s(_vm.$t('global/button/button.create')))])],1)],1)])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }