import { cloneDeep } from 'lodash';

const OcrRfUploadDTO = {
  clinicId: 'required',
  laboratoryId: 'required',
  workStreamId: 'required',
  clinicianId: '',
  technician1Id: '',
  billingEntityId: '',
  clinicType: '',
  labEntryDate: '',
};

const OcrRfUploadSchema = {
  entity: 'OcrRfUpload/information',
  fields: {
    ...OcrRfUploadDTO,
  },
};

const prepareOcrRfUploadSchema = (OcrRfUploadSchema) => {
  const schema = cloneDeep(OcrRfUploadSchema);

  // default add mode
  return schema;
};

export const getOcrRfUploadSchema = (mode, params) => {
  return prepareOcrRfUploadSchema(OcrRfUploadSchema, mode, params);
};
