<template>
  <md-dialog
    :md-close-on-esc="true"
    :md-active.sync="isVisible"
    @md-clicked-outside="close"
    class="lims-modal large-modal searchSimilarPatient-modal"
  >
    <div class="dialog-icon-close" @click="close"><md-icon>close</md-icon></div>
    <div class="md-layout lims-form mg-top-30">
      <md-table class="paginated-table table-striped table-hover lims-table case-viewImportHistory-tbl">
        <md-table-toolbar>
          <div class="toolbar-row">
            <div class="md-layout lims-form-row">
              <div class="infor-cases">
                <label>{{ $t('components/lims/modals/ModalViewCaseImportHistory/numberOfImportedRecod') }}</label>
                <div class="static-text">{{ totalItem }}</div>
              </div>
              <div class="md-layout btnRight">
                <lims-row-per-page :pageSize="pagination.pageSize" @pageSizeChanged="$pageSizeChanged" />
              </div>
            </div>
          </div>
        </md-table-toolbar>
        <div class="table-fix-header">
          <thead>
            <md-table-row>
              <md-table-head v-for="(col, index) in colTabl" :key="index" :class="col.class">
                <div @mouseover="hoverIn(index)" @mouseout="hoverOut()" v-html="getColumnName(col)"></div>
              </md-table-head>
            </md-table-row>
          </thead>
          <tbody>
            <md-table-row
              v-for="(item, index) in caseImportHistoryList"
              :key="`case-${index}`"
              :class="checkRowHighlight(item)"
            >
              <md-table-cell class="row-column">{{ item.lineNumber }}</md-table-cell>
              <md-table-cell>{{ patientName(item.firstName, item.lastName) }}</md-table-cell>
              <md-table-cell>{{ item.laboratoryReference }}</md-table-cell>
              <md-table-cell>{{ item.hospitalReference }}</md-table-cell>
              <md-table-cell>{{ item.clinicianName }}</md-table-cell>
              <md-table-cell>{{ item.laboratoryName }}</md-table-cell>
              <md-table-cell>{{ item.pathologistName }}</md-table-cell>
              <md-table-cell>{{ item.clinicName }}</md-table-cell>
              <md-table-cell class="infor-column">
                <md-button v-if="!item.isValidInput" class="md-just-icon md-simple">
                  <md-icon>info</md-icon>
                  <md-tooltip md-direction="bottom" class="tooltip-align-left tooltip-status">
                    <div v-html="errorMessages(item.errorMessages)"></div>
                  </md-tooltip>
                </md-button>
                <template v-else> {{ item.caseReference }}</template>
              </md-table-cell>
            </md-table-row>
          </tbody>
        </div>
      </md-table>
      <lims-pagination :total-item="totalItem" :pagination="pagination"> </lims-pagination>
    </div>
    <md-dialog-actions class="dialog-actions">
      <md-button @click="close" class="md-button lims-form-button md-theme-default">
        {{ $t('global/button/button.close') }}
      </md-button>
    </md-dialog-actions>
  </md-dialog>
</template>

<script>
import modalMixins from './modal.mixins';
import { isArray } from 'lodash';
import { caseImportService } from '@/services';
import { ConfigMixins } from '@/core/mixins';
import LimsRowPerPage from '@/components/Lims/LimsRowPerPage';
import LimsPagination from '@/components/Lims/LimsPagination';

export default {
  name: 'ModalViewCaseImportHistory',
  mixins: [modalMixins, ConfigMixins],
  components: {
    LimsRowPerPage,
    LimsPagination,
  },

  data() {
    return {
      caseImportHistoryList: [],
      totalItem: null,
      importId: '',

      pagination: {
        pageSize: null,
        pageNumber: 1,
      },

      colTabl: [
        {
          name: 'pages/case/ImportCase/list.row',
          sort: false,
          class: 'row-column',
        },
        {
          name: 'pages/case/ImportCase/list.patient',
          key: 'patientFullname',
          sort: false,
          class: 'patient-column',
        },
        {
          name: 'pages/case/ImportCase/list.labRef',
          key: 'labReference',
          sort: false,
          class: 'labRef-column',
        },
        {
          name: 'pages/case/ImportCase/list.hospitalRef',
          key: 'hospitalReference',
          sort: false,
          class: 'hospitalRef-column',
        },
        {
          name: 'pages/case/ImportCase/list.clinician',
          key: 'clinicianName',
          sort: false,
          class: 'clinician-column',
        },
        {
          name: 'pages/case/ImportCase/list.laboratory',
          key: 'laboratory',
          sort: false,
          class: 'laboratory-column',
        },
        {
          name: 'pages/case/ImportCase/list.pathologist',
          key: 'pathologistName',
          sort: false,
          class: 'pathologist-column',
        },
        {
          name: 'pages/case/ImportCase/list.hospital',
          key: 'hospital',
          sort: false,
          class: 'hospital-column',
        },
        {
          name: 'global/pages/list.status',
          key: 'statusName',
          sort: false,
          class: 'infor-column',
        },
      ],
    };
  },

  watch: {
    pagination: {
      handler: function (val) {
        if (val) {
          this.getImportCaseHistoryDetails(val);
        }
      },
      deep: true,
    },
  },

  methods: {
    open(val) {
      if (val) {
        this.isVisible = true;
        this.importId = val.importId;
        this.getImportCaseHistoryDetails(this.pagination);
      }
    },

    close() {
      this.isVisible = false;
      this.pagination = {
        pageSize: null,
        pageNumber: 1,
      };
    },

    hoverIn(index) {
      this.hover = index;
    },
    hoverOut() {
      this.hover = '';
    },

    getColumnName(c) {
      if (isArray(c.name)) {
        return c.name.map((n) => this.$translate(n)).join('<br/>');
      }
      return this.$translate(c.name);
    },

    checkRowHighlight(item) {
      if (!item.isValidInput) {
        return 'red-color';
      }
      return '';
    },

    patientName(firstName, lastName) {
      const patientFirstName = firstName ? firstName : '';
      const patientLastName = lastName ? lastName : '';
      return patientFirstName + patientLastName;
    },

    errorMessages(errorMessagesList) {
      let err = '';
      if (errorMessagesList) {
        Object.entries(errorMessagesList).forEach(([key, val]) => {
          if (val.length > 0) {
            err += `<b>${key}</b>: <br>` + val.join('<br/>') + '<br>';
          }
        });
      }
      return err;
    },

    $pageSizeChanged(number) {
      this.pagination.pageSize = number;
      if (typeof this.pagination.pageNumber === 'number') {
        this.pagination.pageNumber = 1;
      } else {
        this.pagination.pageNumber = this.pagination.pageNumber.map(() => {
          return 1;
        });
      }
    },

    async getImportCaseHistoryDetails(pagination) {
      const { error, data } = await caseImportService.getImportCaseHistoryDetails({
        importId: this.importId,
        pagination: pagination,
      });
      // detect error and show alert to user
      if (error) {
        this.$alertError(error);
        return;
      }
      this.caseImportHistoryList = data.cases.items;
      this.totalItem = data.cases.pagination.totalItems;
    },
  },
};
</script>

<style lang="scss" scoped>
.case-viewImportHistory-tbl {
  .row-column {
    width: 50px !important;
  }
  .infor-cases {
    display: inline-flex;
    align-items: center;
    label {
      margin-right: 20px;
    }
  }
  .dialog-actions {
    margin-top: 30px !important;
  }
}
.tooltip-status {
  z-index: 999999;
}
</style>
