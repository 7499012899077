const DashboardLayout = () => import('@/layouts/DashboardLayout.vue');
import CaseManagement from '@/pages/Case/CaseManagement/Case.vue';

import guards from './guards';

export default {
  path: '/cases-of-interest',
  component: DashboardLayout,
  meta: {
    zone: 'private',
    rbac: 'cases',
  },
  beforeEnter: guards.checkAuthorizedGuard,
  children: [
    {
      path: '',
      name: 'CaseOfInterest',
      component: CaseManagement,
      props: () => ({
        isCaseOfInterest: true,
      }),
    },
  ],
};
