<template>
  <lims-block class="c-block01 lab-preference-block">
    <h4 class="title" slot="blockTitle">
      {{ $t('pages/account/user/forms/pathologist/labPreference/labPreferenceBlock') }}
    </h4>
    <lims-tooltip
      slot="blockTitle-info"
      :content="$t('pages/account/user/forms/pathologist/labPreference/blockTooltip')"
    ></lims-tooltip>
    <div slot="blockContent">
      <div class="md-layout lims-form-row">
        <div class="md-layout-item md-size-50 md-small-size-100">
          <label>{{ $t('pages/account/user/forms/pathologist/labPreference/availableLaboratoriesLabel') }}</label>
          <v-select
            slot="field-outside"
            :options="selectOptions"
            v-model="formData.laboratory"
            :placeholder="$t('pages/account/user/forms/pathologist/labPreference/availableLaboratoriesPlaceholder')"
            :disabled="viewMode"
            @input="valLaboratory"
            tabenable="yes"
            :tabindex="27"
          >
            <template #option="{ label }">{{ label }}</template>
            <template #selected-option="{ label }">{{ label }}</template>
          </v-select>
        </div>
        <div class="md-layout-item md-size-50 md-small-size-100">
          <md-checkbox
            class="lims-checkbox checkbox-popup"
            v-model="formData.isPopUp"
            :disabled="viewMode"
            tabenable="yes"
            >{{ $t('pages/account/user/forms/pathologist/labPreference/popupLabel') }}</md-checkbox
          >

          <md-button
            type="button"
            @click="add()"
            class="lims-form-button block01-btn md-primary"
            :disabled="viewMode || !isValidLabPreference"
          >
            {{ $t('pages/account/user/forms/pathologist/labPreference/addLabPreference') }}
          </md-button>
        </div>
      </div>
      <div class="md-layout">
        <div class="md-layout-item md-size-100 md-small-size-100 textarea-field">
          <label>{{ $t('pages/account/user/forms/pathologist/labPreference/commentLable') }}</label>
          <md-field>
            <md-textarea v-model="formData.comment" :disabled="viewMode" tabenable="yes"></md-textarea>
          </md-field>
        </div>
      </div>
      <md-table class="custom-tbl" md-card>
        <md-table-row>
          <md-table-head width="25%">
            {{ $t('pages/account/user/forms/pathologist/labPreference/table.specimentLable') }}
          </md-table-head>
          <md-table-head width="15%">
            {{ $t('pages/account/user/forms/pathologist/labPreference/table.popupLable') }}
          </md-table-head>
          <md-table-head>
            {{ $t('pages/account/user/forms/pathologist/labPreference/table.commentLable') }}
          </md-table-head>
          <md-table-head class="th-center cell-action">
            {{ $t('global/pages/list.actions') }}
          </md-table-head>
        </md-table-row>
        <md-table-row v-for="item in selectedOptions" :key="`labList${item.laboratoryId}`">
          <md-table-cell>{{ item.laboratoryName }}</md-table-cell>
          <md-table-cell>
            <md-checkbox :disabled="viewMode" v-model="item.isPopUp" class="lims-checkbox"></md-checkbox>
          </md-table-cell>
          <md-table-cell>{{ item.comment }}</md-table-cell>
          <md-table-cell class="cell-action">
            <div @click="viewMode ? '' : del(item)"><md-icon>close</md-icon></div>
          </md-table-cell>
        </md-table-row>
      </md-table>
    </div>
  </lims-block>
</template>

<script>
import { UserService } from '@/services';
import { MyAccountService } from '@/services';
import UserMixins from '@/pages/Account/User/user.mixins';
import { ENTITY_TYPES } from '@/core/constants';

export default {
  name: 'UserLabPreferenceBlock',
  mixins: [UserMixins],
  props: {
    viewMode: {
      type: Boolean,
      require: true,
    },
    value: {
      type: Array,
      require: false,
      default: function () {
        return [];
      },
    },
    userId: {
      type: String,
    },
  },
  data() {
    return {
      availableOptions: [],
      selectedOptions: [],
      labList: [],
      formData: {
        laboratory: '',
        isPopUp: false,
        comment: '',
      },
      isValidLabPreference: false,
    };
  },
  watch: {
    selectedOptions: {
      deep: true,
      handler: function (value) {
        this.$emit('input', value);
      },
    },
    value: {
      deep: true,
      handler: function () {
        this.selectedOptions = this.value;
      },
    },
  },
  async mounted() {
    this.availableOptions = await this.loadAvailableLaboratories();
  },
  computed: {
    selectOptions() {
      return this.availableOptions.filter(
        (item) => !this.selectedOptions.map((item) => item.laboratoryId).includes(item.value),
      );
    },
  },
  methods: {
    async loadAvailableLaboratories() {
      const entityIds = [ENTITY_TYPES.Laboratory, ENTITY_TYPES.ClinicLaboratory].join(',');
      let responseData;

      if (this.isDefaultView) {
        responseData = await UserService.findAvailableLaboratories(entityIds, this.userId);
      } else {
        if (this.isMyProfileView) {
          responseData = await MyAccountService.findAvailableLaboratories(entityIds, this.userId);
        }
      }
      return responseData.data.map((item) => {
        return {
          value: item.entityId,
          label: item.entityName,
        };
      });
    },
    valLaboratory() {
      if (this.formData.laboratory == null) {
        this.isValidLabPreference = false;
      } else {
        this.isValidLabPreference = true;
      }
    },
    add() {
      if (this.isValidLabPreference) {
        this.selectedOptions.push({
          laboratoryId: this.formData.laboratory.value,
          laboratoryName: this.formData.laboratory.label,
          isPopUp: this.formData.isPopUp,
          comment: this.formData.comment,
        });
        // reset
        this.isValidLabPreference = false;
        this.formData = {
          laboratory: '',
          isPopUp: false,
          comment: '',
        };
      }
    },
    del(delItem) {
      this.selectedOptions = this.selectedOptions.filter((item) => item.laboratoryId !== delItem.laboratoryId);
    },
  },
};
</script>

<style lang="scss" scoped></style>
