<template>
  <ValidationObserver ref="form">
    <md-dialog :md-close-on-esc="true" :md-active.sync="isVisible" @md-clicked-outside="close" class="lims-modal">
      <div class="dialog-icon-close" @click="close"><md-icon>close</md-icon></div>
      <md-dialog-title>{{ $t('components/lims/modals/ModalConfirmation.title') }}</md-dialog-title>
      <div class="text-center mg-top-24" v-html="$t('components/lims/modals/ModalRetrieve.provideReason')"></div>
      <div class="lims-form dialog-content">
        <lims-field :model="formData" :schema="checkCodeSchema" field="passwordForCase">
          <md-input slot="field" ref="confirmPassword" v-model="formData.password" type="password"></md-input>
        </lims-field>
      </div>
      <md-dialog-actions>
        <md-button @click="close" class="lims-form-button">{{ $t('global/button/button.cancel') }}</md-button>
        <md-button
          @click="onRetrieve"
          :disabled="!formData.password || isProcessing"
          class="lims-form-button md-retrieve-highlight"
        >
          {{ $t('global/button/button.retrieve') }}
        </md-button>
      </md-dialog-actions>
    </md-dialog>
  </ValidationObserver>
</template>

<script>
import modalMixins from './modal.mixins';
import { getCheckCodeSchema } from '@/schemas/check-code.schema';

export default {
  mixins: [modalMixins],
  data() {
    return {
      formData: {
        reason: '',
      },
    };
  },
  computed: {
    checkCodeSchema() {
      return getCheckCodeSchema(this.isWrongPasswordError);
    },
  },
  methods: {
    onRetrieve() {
      this.isProcessing = true;
      this.$emit('onRetrieve', this.formData);
    },
    close() {
      this.isVisible = false;
      this.$nextTick(() => {
        this.formData = {
          password: '',
        };
      });
    },
  },
};
</script>
