<template>
  <ValidationObserver id="patientDataBlock" ref="formPatientData" v-slot="{ handleSubmit }">
    <form class="md-layout lims-form" @submit.prevent="handleSubmit()">
      <div class="form-wrapper">
        <div class="md-layout lims-form-row">
          <div class="md-layout-item md-size-25 md-small-size-100">
            <label>{{ $t('entities/case/form/caseRef') }}</label>
            <div class="static-text">{{ formData.caseReference }}</div>
          </div>
          <div class="md-layout-item md-size-25 md-small-size-100">
            <label class="required">{{ $t('entities/case/form/firstName') }}</label>
            <div class="static-text" :class="isAnonymiseField(formData, 'FirstName') ? 'anonymise-field' : ''">
              {{ formData.firstName }}
            </div>
          </div>
          <div class="md-layout-item md-size-25 md-small-size-100">
            <label class="required">{{ $t('entities/case/form/lastName') }}</label>
            <div class="static-text" :class="isAnonymiseField(formData, 'LastName') ? 'anonymise-field' : ''">
              {{ formData.lastName }}
            </div>
          </div>
        </div>
        <div class="md-layout lims-form-row">
          <div class="md-layout-item md-size-25 md-small-size-100">
            <label class="required">{{ $t('entities/case/form/sexId') }}</label>
            <div class="static-text">
              {{ formData.sexLabel }}
            </div>
          </div>
          <div class="md-layout-item md-size-25 md-small-size-100">
            <label class="required">{{ $t('entities/case/form/dob') }}</label>
            <div class="static-text" v-if="formData.dob">
              <span :class="isAnonymiseField(formData, 'DOB') ? 'anonymise-field' : ''">{{ formData.dob }}</span>
              <span>{{
                $t('entities/case/form/ageComputation', {
                  age: formData.age,
                })
              }}</span>
              <lims-tooltip :content="$t('entities/case/form/ageTooltip')" class="age-tooltip"></lims-tooltip>
            </div>
          </div>
          <div class="md-layout-item md-size-25 md-small-size-100">
            <label>{{ $t('entities/case/form/phone') }}</label>
            <div class="static-text" :class="isAnonymiseField(formData, 'Phone') ? 'anonymise-field' : ''">
              {{ formData.phone }}
            </div>
          </div>
        </div>
        <div class="md-layout lims-form-row">
          <div class="md-layout-item md-size-25 md-small-size-100">
            <label>{{ $t('entities/case/form/hospitalReference') }}</label>
            <div class="static-text">{{ formData.hospitalReference }}</div>
          </div>
          <div class="md-layout-item md-size-25 md-small-size-100">
            <label>{{ $t('entities/case/form/laboratoryReference') }}</label>
            <div class="static-text">{{ formData.laboratoryReference }}</div>
          </div>
          <div class="md-layout-item md-size-25 md-small-size-100">
            <label class="required">{{ $t('entities/case/form/procedureDate') }}</label>
            <div class="static-text">{{ formData.procedureDate }}</div>
          </div>
        </div>
        <div class="md-layout lims-form-row">
          <div class="md-layout-item md-size-25 md-small-size-100">
            <label>{{ $t('entities/case/form/patientNhsNumber') }}</label>
            <div class="static-text">{{ formData.patientNhsNumber }}</div>
          </div>
          <div class="md-layout-item md-size-25 md-small-size-100">
            <label class="required">{{ $t('entities/case/form/labEntryDate') }}</label>
            <div class="static-text">{{ formData.labEntryDate }}</div>
          </div>
          <div v-if="!alwFormView" class="md-layout-item md-size-25 md-small-size-100">
            <label>{{ $t('entities/case/form/fromSourceName') }}</label>
            <p class="static-text HL7-field">{{ formData.fromSourceName }}</p>
          </div>
          <div v-if="alwFormView" class="md-layout-item md-size-25 md-small-size-100">
            <label>{{ $t('entities/case/form/pathologistId') }}</label>
            <p class="static-text">
              {{
                formData.pathologistId
                  ? formData.pathologistName
                  : formData.pathologistTypeName === PATHOLOGIST_TYPE_NAME.Pull
                  ? formData.pathologistTypeName
                  : ''
              }}
            </p>
          </div>
        </div>
        <div class="md-layout lims-form-row">
          <div class="md-layout-item md-size-100 md-small-size-100">
            <label>{{ $t('entities/case/form/comment') }}</label>
            <div class="static-text">{{ formData.comment }}</div>
          </div>
        </div>
        <div class="md-layout lims-form-row">
          <div class="md-layout-item md-size-25 md-small-size-100">
            <div>
              <label>{{ $t('entities/case/form/urgent') }}</label>
            </div>
            <md-checkbox disabled v-model="formData.urgent" class="lims-checkbox"></md-checkbox>
          </div>
          <div class="md-layout-item md-size-25 md-small-size-100">
            <label>{{ $t('entities/case/form/status') }}</label>
            <p class="static-text">{{ formData.statusName }}</p>
          </div>
          <div v-if="alwFormView" class="md-layout-item md-size-25 md-small-size-100">
            <label>{{ $t('entities/case/form/fromSourceName') }}</label>
            <p class="static-text HL7-field">{{ formData.fromSourceName }}</p>
          </div>
          <div
            class="md-layout-item md-size-50 md-small-size-100"
            v-if="
              isCountDownShowed &&
              timerReturnToPullInSecond &&
              !formData.isPauseCountReturnToPull &&
              !onHideCountdownPullCase
            "
          >
            <label>{{ $t('entities/case/form/countDown') }}</label>
            <p class="static-text red-color">
              {{ countDownTimeByHour ? coverTime(countDownTimeByHour) : '00' }} :
              {{ countDownTimeByMinute ? coverTime(countDownTimeByMinute) : '00' }} :
              {{ countDownTimeBySecond ? coverTime(countDownTimeBySecond) : '00' }}
            </p>
          </div>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
import { APP_EVENTS, CASE_STATUS, PATHOLOGIST_TYPE_NAME } from '@/core/constants';
import { FormMixins } from '@/core/mixins';
import CaseMixins from '@/pages/Case/CaseManagement/Case.mixins';
import { getCaseFormSchema } from '@/schemas/case-form.schema';
import {
  formatDateWithoutTime,
  formatDateAndTime,
  fromISOToCurrentTimezone,
  convertDateTimeToUTCFormat,
} from '@/core/helpers';
import { mapGetters } from 'vuex';
// import { calculatePatientAge } from '../Case.helpers';

export default {
  mixins: [FormMixins, CaseMixins],
  props: {
    formMode: {
      type: Number,
      require: true,
    },
    dataEdit: {
      type: Object,
      require: false,
    },
    onHideCountdownPullCase: {
      type: Boolean,
      require: false,
    },
    alwFormView: {
      type: Boolean,
      require: false,
    },
  },
  data() {
    return {
      countDownTimeByHour: 0,
      countDownTimeByMinute: 0,
      countDownTimeBySecond: 0,
      formData: {},
      timerReturnToPullInSecond: 0,
    };
  },
  created() {
    this.fetchData();
  },
  computed: {
    ...mapGetters('app/event', [APP_EVENTS.EVT_ON_CASE_HIDE_COUNT_DOWN]),
    caseFormSchema: function () {
      return getCaseFormSchema(this.formMode);
    },
    isCountDownShowed() {
      const isNotAllowedCaseStatus =
        this.dataEdit.status === CASE_STATUS.PROVISIONALLY_REPORTED || this.dataEdit.status === CASE_STATUS.REPORTED;
      return this.dataEdit && !isNotAllowedCaseStatus;
    },
    PATHOLOGIST_TYPE_NAME() {
      return PATHOLOGIST_TYPE_NAME;
    },
  },
  watch: {
    [APP_EVENTS.EVT_ON_CASE_HIDE_COUNT_DOWN]: {
      deep: true,
      handler: async function (val) {
        if (val) {
          this.formData.isPauseCountReturnToPull = true;
        }
      },
    },
    timerReturnToPullInSecond: {
      deep: true,
      handler: function (val) {
        if (val && val >= 0) {
          const totalTimeBySecond = parseInt(val);
          this.countDownTimeByHour = Math.floor(totalTimeBySecond / 60 / 60);
          this.countDownTimeByMinute = Math.floor(totalTimeBySecond / 60 - this.countDownTimeByHour * 60);
          this.countDownTimeBySecond =
            totalTimeBySecond - this.countDownTimeByHour * 60 * 60 - this.countDownTimeByMinute * 60;
          if (!this.formData.isPauseCountReturnToPull) {
            setTimeout(() => {
              this.timerReturnToPullInSecond--;
            }, 1000);
          }
        }
      },
    },
  },
  methods: {
    fetchData() {
      if (this.dataEdit) {
        let dob = this.dataEdit.dob;
        if (!this.dataEdit.anonymiseFields) {
          // transform if not anonymize and not null
          dob = dob ? formatDateWithoutTime(fromISOToCurrentTimezone(dob)) : null;
        }
        this.formData = {
          ...this.dataEdit,
          dob,
          procedureDate: this.dataEdit.procedureDate
            ? formatDateWithoutTime(fromISOToCurrentTimezone(this.dataEdit.procedureDate))
            : null,
          labEntryDate: this.dataEdit.labEntryDate
            ? formatDateAndTime(convertDateTimeToUTCFormat(this.dataEdit.labEntryDate))
            : null,
          age: this.dataEdit?.age,
        };

        this.timerReturnToPullInSecond = this.formData.timerReturnToPullInSecond;
        const headingTitleItem = [
          this.dataEdit.caseReference,
          this.dataEdit.laboratoryReference ? this.dataEdit.laboratoryReference : '',
          this.dataEdit.hospitalReference ? this.dataEdit.hospitalReference : '',
          this.dataEdit.firstName + ' ' + this.dataEdit.lastName,
          dob,
          '<br>',
          this.dataEdit.clinicName,
          'Clinician: ' + this.dataEdit.clinicianName,
          'Pathologist: ' + this.dataEdit.pathologistName,
        ];
        const headingTitle = headingTitleItem.filter((item) => item);
        this.$setHeadingTitle(headingTitle.join(' - ').replace('- <br> -', '<br>'));
      }
    },
    coverTime(time) {
      return time < 10 ? '0' + time : time;
    },
  },
};
</script>
<style lang="scss" scoped>
.age-tooltip {
  position: relative;
  top: 4px;
}
</style>
