<template>
  <lims-block class="c-block01" v-if="!(isMyEntityView && ADD_MODE)">
    <h4 class="title" slot="blockTitle">
      {{ $t('pages/account/user/forms/ClinicAssociate/clinicInformation/clinicInformationBlock') }}
    </h4>
    <div slot="blockContent">
      <div class="md-layout" v-if="isDefaultView">
        <div class="md-layout-item md-size-100">
          <lims-form-error
            :failed="failed"
            :error="$t('pages/account/user/forms/ClinicAssociate/clinicInformation/pleaseAddAtLeastOneClinic')"
          />
        </div>
      </div>
      <div class="md-layout" v-if="isDefaultView">
        <div class="md-layout-item md-size-50 md-small-size-100 selectClinic">
          <label class="required">{{ $t('pages/entity/forms/laboratory/restriction/clinic/selectLabel') }}</label>
          <md-field :class="[{ 'md-error': failed }]">
            <v-select
              multiple
              v-model="clinicAssociateSelected"
              :options="clinicAssociatePending"
              :placeholder="$t('pages/account/user/forms/ClinicAssociate/clinicInformation/placeholderSelectClinic')"
              :disabled="viewMode"
              tabenable="yes"
              :tabindex="22"
            >
              <template #option="{ label }">{{ label }}</template>
              <template #selected-option="{ label }">{{ label }}</template>
            </v-select>
          </md-field>
        </div>
        <div class="md-layout-item md-small-size-100 addBtn">
          <md-button
            type="button"
            class="lims-form-button block01-btn md-primary"
            @click="addClinic"
            :disabled="viewMode || noClinicAssociate"
            >{{ $t('pages/account/user/forms/ClinicAssociate/clinicInformation/addClinic') }}</md-button
          >
        </div>
      </div>
      <div class="md-layout lims-form-row">
        <md-table class="custom-tbl tbl-drag" md-card>
          <md-table-row>
            <md-table-head>
              {{ $t('pages/account/user/forms/ClinicAssociate/clinicInformation/clinicName') }}
            </md-table-head>
            <md-table-head>
              {{ $t('pages/account/user/forms/ClinicAssociate/clinicInformation/clinicType') }}
            </md-table-head>
            <md-table-head>
              {{ $t('pages/account/user/forms/ClinicAssociate/clinicInformation/address') }}
            </md-table-head>
            <md-table-head>
              {{ $t('pages/account/user/forms/ClinicAssociate/clinicInformation/email') }}
            </md-table-head>
            <md-table-head class="th-center cell-action" v-if="isDefaultView">
              {{ $t('global/pages/list.actions') }}
            </md-table-head>
          </md-table-row>
          <draggable v-model="formData.clinicAssociate" class="tbl-body" @end="endDrag">
            <md-table-row v-for="(item, index) in formData.clinicAssociate" :key="index">
              <md-table-cell class="cell-clinicType">{{ item.clinicName }}</md-table-cell>
              <md-table-cell class="cell-clinicType">{{ item.clinicType }}</md-table-cell>
              <md-table-cell class="cell-clinicType">{{ item.address }}</md-table-cell>
              <md-table-cell class="cell-clinicType">{{ item.email }}</md-table-cell>
              <md-table-cell class="cell-action" v-if="isDefaultView">
                <span><md-icon>open_with</md-icon></span>
                <span @click="viewMode ? '' : delClinicAssociate(item)"><md-icon>close</md-icon></span>
              </md-table-cell>
            </md-table-row>
          </draggable>
        </md-table>
      </div>
    </div>
  </lims-block>
</template>

<script>
import userMixins from '@/pages/Account/User/user.mixins';
import { ENTITY_TYPES, SELECT_LIST_STATUS } from '@/core/constants';
import UserService from '@/services/user.service';
import draggable from 'vuedraggable';

export default {
  mixins: [userMixins],
  components: {
    draggable,
  },
  props: {
    failed: {
      type: Boolean,
      require: false,
      default: false,
    },
    viewMode: {
      type: Boolean,
      require: false,
      default: null,
    },
    value: {
      type: Array,
      require: true,
    },
  },
  created() {
    this.fetchData();
  },
  data() {
    return {
      noClinicAssociate: true,
      clinicAssociateSelected: [],
      clinicAssociateList: [],
      clinicAssociateOldSelectedData: [],
      clinicAssociateOldSelectedLength: 0,
      onAddClinic: false,
      formData: {
        clinicAssociate: [],
      },
    };
  },
  computed: {
    clinicAssociatePending() {
      return this.clinicAssociateList.filter((cli) => cli.status === SELECT_LIST_STATUS.PENDING);
    },
  },
  watch: {
    'formData.clinicAssociate': {
      handler(val) {
        this.$emit('input', val);
      },
      deep: true,
    },
    value() {
      if (this.value) {
        this.formData.clinicAssociate = this.value;
        this.updateClinicAssociateList(this.formData.clinicAssociate);
      }
    },
    clinicAssociateSelected() {
      this.noClinicAssociate = this.clinicAssociateSelected.length === 0;

      if (this.onAddClinic) {
        this.onAddClinic = false;
      } else {
        if (this.clinicAssociateOldSelectedLength < this.clinicAssociateSelected.length) {
          let dataChangedClinicAssociateList = this.clinicAssociateSelected.filter(
            (x) => !this.clinicAssociateOldSelectedData.includes(x),
          );
          this.changeClinicAssociateListStatus(dataChangedClinicAssociateList[0].value, SELECT_LIST_STATUS.IN_PROGRESS);
        } else if (this.clinicAssociateOldSelectedLength > this.clinicAssociateSelected.length) {
          let dataChangedClinicAssociateList = this.clinicAssociateOldSelectedData.filter(
            (x) => !this.clinicAssociateSelected.includes(x),
          );
          this.changeClinicAssociateListStatus(dataChangedClinicAssociateList[0].value, SELECT_LIST_STATUS.PENDING);
        }
      }
      this.clinicAssociateOldSelectedLength = this.clinicAssociateSelected.length;
      this.clinicAssociateOldSelectedData = this.clinicAssociateSelected;
    },
  },
  methods: {
    async fetchData() {
      this.clinicAssociateList = await this.loadAssociatedClinic();
    },
    async loadAssociatedClinic() {
      if (this.isDefaultView) {
        const entityIds = [ENTITY_TYPES.ClinicLaboratory, ENTITY_TYPES.Clinic].join(',');
        let responseData;
        responseData = await UserService.findAssociatedClinic({
          entityIds: entityIds,
          userId: this.userId ? this.userId : '',
        });
        return this.mappingClinicInfo(responseData.data, SELECT_LIST_STATUS.PENDING);
      }
    },
    updateClinicAssociateList(clinicInfo) {
      if (this.isDefaultView) {
        this.clinicAssociateList = this.clinicAssociateList.map((item) => {
          return {
            ...item,
            status:
              clinicInfo.find((i) => i.value === item.value) !== undefined
                ? SELECT_LIST_STATUS.IN_PROGRESS
                : SELECT_LIST_STATUS.PENDING,
          };
        });
      }
    },
    changeClinicAssociateListStatus(entityIdClinicAssociate, status) {
      let dataClinic = this.clinicAssociateList.find((e) => e.value === entityIdClinicAssociate);
      let id = this.clinicAssociateList.findIndex((item) => item.value === dataClinic.value);
      this.clinicAssociateList[id].status = status;
    },
    addClinic() {
      this.onAddClinic = true;
      if (this.clinicAssociateSelected) {
        this.clinicAssociateSelected.forEach((item) => {
          this.changeClinicAssociateListStatus(item.value, SELECT_LIST_STATUS.IN_PROGRESS);
          this.formData.clinicAssociate.push({
            value: item.value,
            label: item.label,
            clinicName: item.clinicName,
            clinicType: item.clinicType,
            address: item.address,
            email: item.email,
          });
        });
      }
      this.clinicAssociateSelected = [];
    },
    delClinicAssociate(item) {
      let clinicData = this.clinicAssociateList.find((e) => e.value === item.value);
      if (clinicData) {
        this.changeClinicAssociateListStatus(clinicData.value, SELECT_LIST_STATUS.PENDING);
      } else {
        this.clinicAssociateList.push({
          value: item.value,
          clinicName: item.clinicName,
          clinicType: item.clinicType,
          address: item.address,
          email: item.email,
          status: SELECT_LIST_STATUS.PENDING,
        });
      }
      const idx = this.formData.clinicAssociate.findIndex((i) => i.value === item.value);
      if (idx !== -1) {
        this.formData.clinicAssociate.splice(idx, 1);
      }
    },

    endDrag() {
      this.formData.clinicAssociate.forEach((item, index) => {
        item.displayOrder = index + 1;
      });
    },
  },
};
</script>
