import httpClient from './httpClient';

const RESOURCE = 'audits';

export default {
  getListAuditLogs({ objectType, objectKey, filters, pagination, sort }) {
    const { action, userType, fromDateTime, toDateTime } = filters;
    const { pageNumber, pageSize } = pagination;
    const userQueryParams = {};

    if (action) {
      userQueryParams['Filters.Action'] = action;
    }
    if (userType) {
      userQueryParams['Filters.UserType'] = userType;
    }
    if (fromDateTime) {
      userQueryParams['Filters.FromDateTime'] = fromDateTime;
    }
    if (toDateTime) {
      userQueryParams['Filters.ToDateTime'] = toDateTime;
    }
    if (pageNumber) {
      userQueryParams['PageNumber'] = pageNumber;
    }
    if (pageSize) {
      userQueryParams['PageSize'] = pageSize;
    }
    if (sort) {
      userQueryParams['Sort'] = sort;
    }
    return httpClient.get(`/${RESOURCE}/${objectType}/${objectKey}/auditlogs`, userQueryParams);
  },

  downloadAuditTrail({ objectType, objectKey }) {
    const userQueryParams = {};
    return httpClient.get(`/${RESOURCE}/${objectType}/${objectKey}/auditlogs/download`, userQueryParams);
  },

  getAuditAction() {
    return httpClient.get(`/${RESOURCE}/actions`);
  },
};
