var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('md-dialog',{staticClass:"lims-modal",attrs:{"md-close-on-esc":true,"md-active":_vm.isVisible},on:{"update:mdActive":function($event){_vm.isVisible=$event},"update:md-active":function($event){_vm.isVisible=$event},"md-clicked-outside":_vm.close}},[_c('div',{staticClass:"dialog-icon-close",on:{"click":_vm.close}},[_c('md-icon',[_vm._v("close")])],1),_c('md-dialog-title',[_vm._v(_vm._s(_vm.$t('components/lims/modals/ModalValidateUser.title')))]),_c('div',{staticClass:"dialog-content"},[(_vm.pendingAction && Object.keys(_vm.pendingAction).length === 1)?_vm._l((_vm.pendingAction),function(item,index){return _c('div',{key:index,staticClass:"md-dense md-info action-message"},[(
            item.pendingActionTypeId == _vm.pendingActionType.Created ||
            item.pendingActionTypeId == _vm.pendingActionType.EnableUser ||
            item.pendingActionTypeId == _vm.pendingActionType.DisableUser
          )?_c('p',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.$t("global/message/actionMessage", _vm.actionMessage(item)))+" ")]):_vm._e(),(
            item.pendingActionTypeId == _vm.pendingActionType.EnableHasLogin ||
            item.pendingActionTypeId == _vm.pendingActionType.DisableHasLogin
          )?_c('p',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.$t("global/message/actionMessage.hasLogin", _vm.actionMessage(item)))+" ")]):_vm._e(),(
            item.pendingActionTypeId == _vm.pendingActionType.ChangeEmail ||
            item.pendingActionTypeId == _vm.pendingActionType.ChangePhoneNumber
          )?_c('p',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.$t("global/message/actionMessage.manyActions", _vm.actionMessage(item)))+" ")]):_vm._e()])}):_vm._e(),(_vm.pendingAction && Object.keys(_vm.pendingAction).length > 1)?[_c('p',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.$t('components/lims/modals/ModalValidateUser/validate.txt')))]),_c('ul',{staticClass:"list-validate"},[_c('li',[_c('md-checkbox',{staticClass:"lims-checkbox",model:{value:(_vm.selectAll),callback:function ($$v) {_vm.selectAll=$$v},expression:"selectAll"}},[_vm._v(" "+_vm._s(_vm.$t('components/lims/modals/ModalValidateUser/validate.selectAll'))+" ")])],1),_vm._l((_vm.pendingAction),function(item,index){return _c('li',{key:index},[_c('md-checkbox',{staticClass:"lims-checkbox",attrs:{"value":item.pendingUserValidationId},model:{value:(_vm.formData.listValidate),callback:function ($$v) {_vm.$set(_vm.formData, "listValidate", $$v)},expression:"formData.listValidate"}},[(item.pendingActionTypeId == _vm.pendingActionType.ChangePhoneNumber)?[_vm._v(" "+_vm._s(_vm.$t('components/lims/modals/ModalValidateUser/validate.tel'))+" ")]:_vm._e(),(item.pendingActionTypeId == _vm.pendingActionType.ChangeEmail)?[_vm._v(" "+_vm._s(_vm.$t('components/lims/modals/ModalValidateUser/validate.email'))+" ")]:_vm._e(),(
                item.pendingActionTypeId == _vm.pendingActionType.EnableHasLogin ||
                item.pendingActionTypeId == _vm.pendingActionType.DisableHasLogin
              )?[_vm._v(" "+_vm._s(_vm.$t('components/lims/modals/ModalValidateUser/validate.hasLogin')))]:_vm._e()],2)],1)})],2)]:_vm._e(),_c('md-field',{staticClass:"dialog-texterea-field"},[_c('md-textarea',{attrs:{"placeholder":_vm.$t('components/lims/modals/ModalValidateUser.placeholderTextarea')},model:{value:(_vm.formData.note),callback:function ($$v) {_vm.$set(_vm.formData, "note", $$v)},expression:"formData.note"}})],1)],2),_c('md-dialog-actions',[_c('md-button',{staticClass:"lims-form-button",on:{"click":_vm.close}},[_vm._v(_vm._s(_vm.$t('global/button/button.cancel')))]),_c('md-button',{staticClass:"lims-form-button md-primary",on:{"click":_vm.onApprove}},[_vm._v(" "+_vm._s(_vm.$t('components/lims/modals/ModalValidateUser.approveBtn'))+" ")]),_c('md-button',{staticClass:"lims-form-button md-danger",on:{"click":_vm.onReject}},[_vm._v(" "+_vm._s(_vm.$t('components/lims/modals/ModalValidateUser.rejectBtn'))+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }