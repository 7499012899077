<template>
  <collapse
    :wrapperClass="'case-collapse specimen-detail-collapse'"
    :collapse="collapseName"
    icon="keyboard_arrow_down"
  >
    <template slot="md-collapse-pane-1">
      <div class="md-layout lims-form-row">
        <div class="md-layout-item md-size-33 md-small-size-100">
          <lims-field :model="formData" :schema="caseFormSchema" field="noOfSpecimen"></lims-field>
          <div class="static-text">{{ formData.noOfSpecimen }}</div>
        </div>
      </div>
      <div class="md-layout lims-form-row">
        <div class="md-layout-item md-size-100 md-small-size-100">
          <lims-field class="textarea-field" :model="formData" :schema="caseFormSchema" field="specimenDetails">
          </lims-field>
          <div class="static-text">{{ formData.specimenDetails }}</div>
        </div>
      </div>
      <div class="md-layout lims-form-row">
        <div class="md-layout-item md-size-100 md-small-size-100">
          <lims-field class="textarea-field" :model="formData" :schema="caseFormSchema" field="clinicalDetails">
          </lims-field>
          <div class="static-text">{{ formData.clinicalDetails }}</div>
        </div>
      </div>
      <div class="md-layout lims-form-row">
        <div class="md-layout-item md-size-20 md-small-size-100">
          <lims-field class="textarea-field" :model="formData" :schema="caseFormSchema" field="countOfOriginalSlides">
          </lims-field>
          <div v-if="formData.caseSpecimens" class="static-text">{{ formData.countOfOriginalSlides }}</div>
        </div>
      </div>
      <md-table class="custom-tbl" md-card>
        <md-table-row>
          <md-table-head>{{ $t('pages/case/CaseManagement/component/specimen/table/specimenNo') }}</md-table-head>
          <md-table-head>{{ $t('pages/case/CaseManagement/component/specimen/table/specimenType') }}</md-table-head>
          <md-table-head>{{
            $t('pages/case/CaseManagement/component/specimen/table/specimenDetailsOnPot')
          }}</md-table-head>
          <md-table-head>{{
            $t('pages/case/CaseManagement/component/specimen/table/macroDescriptionFromLab')
          }}</md-table-head>
          <md-table-head>{{ $t('pages/case/CaseManagement/component/specimen/table/block') }}</md-table-head>
          <md-table-head>{{ $t('pages/case/CaseManagement/component/specimen/table/slide') }}</md-table-head>
        </md-table-row>
        <md-table-row v-for="(item, index) in formData.caseSpecimenBlocks" :key="index">
          <md-table-cell>{{ convertSpecimenName(item.specimenNumber - 1) }}</md-table-cell>
          <md-table-cell>{{ item.specimentTypeName }}</md-table-cell>
          <md-table-cell>{{ item.specimenDetails }}</md-table-cell>
          <md-table-cell>{{ item.macroDescription }}</md-table-cell>
          <md-table-cell>{{ item.block }}</md-table-cell>
          <md-table-cell>{{ item.slide }}</md-table-cell>
        </md-table-row>
      </md-table>
    </template>
  </collapse>
</template>
<script>
import { Collapse } from '@/components';
import { FormMixins } from '@/core/mixins';
import { getCaseFormSchema } from '@/schemas/case-form.schema';
import { FORM_MODES } from '@/core/constants';
import CaseSpecimenMixins, { generateSpecimenName } from '@/pages/Case/CaseManagement/CaseSpecimen.mixins';

export default {
  mixins: [FormMixins, CaseSpecimenMixins],
  components: {
    Collapse,
  },

  props: {
    formMode: {
      type: Number,
      require: true,
      validator: function (value) {
        // The value must match one of these strings
        return Object.values(FORM_MODES).indexOf(value) !== -1;
      },
    },
    dataEdit: {
      type: Object,
      require: false,
    },
  },
  created() {
    this.fetchData();
  },

  computed: {
    caseFormSchema: function () {
      return getCaseFormSchema(this.formMode);
    },
    collapseName() {
      const name = 'pages/case/CaseManagement/SpecimenDetail/blockTitle';
      return [this.$translate(name)];
    },
  },
  data() {
    return {
      formData: {},
    };
  },
  methods: {
    async fetchData() {
      let caseSpecimenBlocks = [];
      let caseSpecimenItem = {};
      this.dataEdit.caseSpecimens.forEach((caseSpecimen, index) => {
        if (caseSpecimen.caseSpecimenBlocks.length > 0) {
          caseSpecimen.caseSpecimenBlocks.forEach((caseSpecimenBlock) => {
            let slide = [];
            caseSpecimenBlock.blockFieldItems.forEach((blockFieldItem) => {
              slide.push(blockFieldItem.fieldItemName);
            });

            caseSpecimenItem = {
              ...caseSpecimenBlock,
              specimenNumber: index + 1,
              specimentTypeName: caseSpecimen.specimentTypeName,
              specimenDetails: caseSpecimen.specimenDetails,
              macroDescription: caseSpecimen.macroDescription,
              slide: slide.join(', '),
            };
            caseSpecimenBlocks.push(caseSpecimenItem);
          });
        } else {
          caseSpecimenItem = {
            specimenNumber: index + 1,
            specimentTypeName: caseSpecimen.specimentTypeName,
            specimenDetails: caseSpecimen.specimenDetails,
            macroDescription: caseSpecimen.macroDescription,
            slide: '',
          };
          caseSpecimenBlocks.push(caseSpecimenItem);
        }
      });

      let countOfOriginalSlides = 0;
      this.dataEdit.caseSpecimens.forEach((caseSpecimen) => {
        caseSpecimen.caseSpecimenBlocks.forEach((caseSpecimenBlock) => {
          countOfOriginalSlides += caseSpecimenBlock.blockFieldItems.length;
        });
      });
      this.formData = {
        ...this.dataEdit,
        noOfSpecimen: this.dataEdit.caseSpecimens.length,
        countOfOriginalSlides: countOfOriginalSlides,
        caseSpecimenBlocks: caseSpecimenBlocks,
      };
    },
    convertSpecimenName(index) {
      return generateSpecimenName(index, this.blockNamingRuleSetting);
    },
  },
};
</script>
<style lang="scss"></style>
