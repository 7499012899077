<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit, invalid }">
    <form v-if="dataValidate && bulkAction" class="md-layout lims-form form-login" @submit.prevent="handleSubmit()">
      <md-dialog :md-close-on-esc="true" :md-active.sync="isVisible" @md-clicked-outside="close" class="lims-modal">
        <div class="dialog-icon-close" @click="close"><md-icon>close</md-icon></div>
        <md-dialog-title>{{ $t('components/ModalBulkActionProblem.title') }}</md-dialog-title>
        <div class="lims-form dialog-content">
          <BulkActionProblemSlide
            :key="'caseSlideInvalidCondition'"
            :casesFailure="dataValidate.casesFailure"
            :problemKey="'caseSlideInvalidCondition'"
            :bulkAction="bulkAction"
          ></BulkActionProblemSlide>
          <BulkActionProblemSlide
            :key="'incompatibleStatus'"
            :casesFailure="dataValidate.casesFailure"
            :problemKey="'incompatibleStatus'"
            :bulkAction="bulkAction"
          ></BulkActionProblemSlide>
          <BulkActionProblemSlide
            :key="'caseSlideIsNotPull'"
            :casesFailure="dataValidate.casesFailure"
            :problemKey="'caseSlideIsNotPull'"
            :bulkAction="bulkAction"
          ></BulkActionProblemSlide>
          <template v-if="dataValidate.listCaseSuccess && dataValidate.listCaseSuccess.length > 0">
            <p class="text-center">{{ $t('components/ModalBulkActionProblemSlide.text2') }}</p>
            <ul class="list-cases">
              <li v-for="(item, key) in dataValidate.listCaseSuccess" :key="`success-${item.slide.fieldItemId}-${key}`">
                {{ item.caseReference }}, {{ item.block.text }}, {{ item.slide.fieldItemName }}
              </li>
            </ul>
          </template>
          <p v-else class="text-center" v-html="$t('components/ModalBulkActionProblemSlide.noCompatibleSlide')"></p>
        </div>
        <md-dialog-actions>
          <template v-if="dataValidate.listCaseSuccess && dataValidate.listCaseSuccess.length > 0">
            <md-button @click="close" class="lims-form-button">{{ $t('global/button/button.cancel') }}</md-button>
            <md-button @click="onContinue" :disabled="invalid" class="lims-form-button md-primary">{{
              $t('global/button/button.continue')
            }}</md-button>
          </template>
          <md-button v-else @click="close" class="lims-form-button md-primary">{{
            $t('global/button/button.backToList')
          }}</md-button>
        </md-dialog-actions>
      </md-dialog>
    </form>
  </ValidationObserver>
</template>

<script>
import modalMixins from './modal.mixins';
import BulkActionProblemSlide from '@/pages/Case/SlideManagement/Components/BulkActionProblemSlide.vue';

export default {
  name: 'ModalBulkActionProblemSlide',
  mixins: [modalMixins],
  components: {
    BulkActionProblemSlide,
  },
  data() {
    return {
      dataValidate: null,
      bulkAction: null,
    };
  },
  methods: {
    setData({ dataValidate, bulkAction }) {
      this.dataValidate = dataValidate;
      this.bulkAction = bulkAction;
    },
    onContinue() {
      this.close();
      this.$emit('onContinue');
    },
  },
};
</script>

<style lang="scss" scoped>
.list-cases {
  display: table;
  margin: 10px auto;
}
</style>
