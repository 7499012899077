<template>
  <lims-block :cardText="false" class="list-page">
    <modal-select-user-types
      ref="selectUserTypesModal"
      slot="blockTitle"
      :entitySelectUser="isClinicAndLaboratory"
      @onSelect="onSelectUserType"
    ></modal-select-user-types>
    <div class="md-layout" slot="blockContent">
      <div class="md-layout-item" v-if="entityType && query">
        <entity-user-list @action="onAction" :users="items" :sort="query.sort">
          <md-table-toolbar slot="toolbar">
            <div class="toolbar-row">
              <md-field class="field-btn">
                <md-button
                  v-if="$isAuthorized(['MyEntity_UserManagement_Create'])"
                  @click="openSelectUserTypesModal"
                  class="btn-addUser"
                  >{{ $t('global/button/button.addUser') }}</md-button
                >
              </md-field>
            </div>
            <div class="toolbar-row">
              <div class="field-rows-per-page">
                <lims-row-per-page :pageSize="query.pagination.pageSize" @pageSizeChanged="$pageSizeChanged" />
              </div>
              <div class="field-search">
                <UserListFilter
                  v-model="query.filters"
                  :defaultValues="defaultValues"
                  :fields="fields"
                  @onResetFilters="onResetFilters"
                />
              </div>
            </div>
            <lims-pagination ref="pager" :total-item="totalItem" :pagination="query.pagination"> </lims-pagination>
          </md-table-toolbar>
        </entity-user-list>
        <lims-pagination ref="pager" :total-item="totalItem" :pagination="query.pagination"> </lims-pagination>
      </div>
    </div>
  </lims-block>
</template>

<script>
import EntityUserList from '@/pages/MyEntity/User/List/UserList.vue';
import { APP_ROUTES, FORM_ACTIONS, USER_TYPE_VALUES } from '@/core/constants';
import { ModalSelectUserTypes } from '@/components/Lims/modals';
import LimsRowPerPage from '@/components/Lims/LimsRowPerPage';
import UserListFilter from '@/pages/MyEntity/User/List/UserListFileter';
import LimsPagination from '@/components/Lims/LimsPagination';
import { cloneDeep } from 'lodash/cloneDeep';

import { buildFilterFromQuery, buildFilterQueryParams } from '@/query/utils';
import { entityUsersQuery } from '@/query';
import { MyEntityService } from '@/services';
import { ConfigMixins, ListWithFilterMixins } from '@/core/mixins';

export default {
  name: 'AccountUserPage',

  mixins: [ConfigMixins, ListWithFilterMixins],

  components: {
    EntityUserList,
    ModalSelectUserTypes,
    LimsRowPerPage,
    UserListFilter,
    LimsPagination,
  },
  async created() {
    this.initState();
    await this.getMyEntityType();
  },
  data() {
    return {
      items: [],
      totalItem: null,
      query: null,
      entityType: null,
    };
  },
  computed: {
    fields() {
      return entityUsersQuery.filters.fields;
    },
    defaultValues() {
      return entityUsersQuery.filters.defaultValues;
    },
    isClinicAndLaboratory() {
      return this.entityType?.includes('ClinicAndLaboratory');
    },
  },

  methods: {
    // common methods
    initState() {
      const queryParams = this.$route.query;
      const filters = buildFilterFromQuery(entityUsersQuery.filters, queryParams);
      const pagination = buildFilterFromQuery(entityUsersQuery.pagination, {
        pageSize: this.$appConfig.itemPerPageDefault,
        ...queryParams,
      });
      const sort = buildFilterFromQuery(entityUsersQuery.sort, queryParams);
      const query = { filters, pagination, sort };
      this.query = query;
    },

    async search(queryObject) {
      const { error, data } = await MyEntityService.getUserListInMyEntityByFilter({
        filters: buildFilterQueryParams(queryObject.filters),
        pagination: buildFilterQueryParams(queryObject.pagination),
        sort: `${queryObject.sort.orderField}:${queryObject.sort.orderBy}`,
      });
      // detect error and show alert to user
      if (error) {
        this.$alertError(error);
        // remove loading screen and allow user to interact
        return;
      }

      // binding data
      this.items = data.items;
      this.totalItem = data.pagination.totalItems;

      // update router
      const query = {
        ...buildFilterQueryParams(queryObject.filters),
        ...buildFilterQueryParams(queryObject.pagination),
        ...buildFilterQueryParams(queryObject.sort),
      };

      this.$router
        .push({
          query,
        })
        .catch((error) => {
          if (
            error.name === 'NavigationDuplicated' &&
            error.message.includes('Avoided redundant navigation to current location')
          ) {
            history.pushState({}, '', new URL(new URL(window.location)));
          }
        });
    },
    onResetFilters() {
      this.query.filters = cloneDeep(entityUsersQuery.filters.defaultValues);
    },

    async getMyEntityType() {
      const { error, data } = await MyEntityService.getMyEntityType();

      if (error) {
        this.$alertError(error);
      } else {
        if (data) {
          this.entityType = data;
        }
      }
    },

    openSelectUserTypesModal() {
      if (this.entityType.includes('ClinicAndLaboratory')) {
        this.$refs.selectUserTypesModal.open();
      } else {
        if (this.entityType.includes('Clinic')) {
          this.$router.push({
            name: 'MyEntityUserManagementAdd',
            params: {
              userType: 'clinicAssociate',
            },
          });
        } else if (this.entityType.includes('Laboratory')) {
          this.$router.push({
            name: 'MyEntityUserManagementAdd',
            params: {
              userType: 'labTechnician',
            },
          });
        }
      }
    },

    // eslint-disable-next-line no-unused-vars
    onSelectUserType(formData) {
      // navigate
      if (formData.selectOption) {
        this.$router.push({
          name: 'MyEntityUserManagementAdd',
          params: {
            userType: USER_TYPE_VALUES[formData.selectOption.value - 1],
          },
        });

        this.$refs.selectUserTypesModal.close();
      }
    },

    editRow(item) {
      this.$router.push(`${APP_ROUTES.MYENTITY_USERS_FORM_EDIT}/${item.userId}`);
    },

    viewRow(item) {
      this.$router.push(`${APP_ROUTES.MYENTITY_USERS_FORM_VIEW}/${item.userId}`);
    },

    async resetPassword(item) {
      const userId = item.userId;
      const res = await MyEntityService.resetPasswordOfMyEntityIsUser({ userId });
      if (res.err) {
        return this.$alertError(res.error);
      } else {
        if (!item.isCompleteAccount) {
          this.$alertSuccess(
            this.$t('entities/entity/status/resetPassword/isNotCompleteAccount.success', {
              userType: item.userTypeName,
              firstName: item.firstname,
              lastName: item.lastname,
            }),
          );
        } else {
          this.$alertSuccess(
            this.$t('entities/entity/status/resetPassword.success', {
              userType: item.userTypeName,
              firstName: item.firstname,
              lastName: item.lastname,
            }),
          );
        }
      }
    },

    onAction({ action, item }) {
      if (action === FORM_ACTIONS.EDIT) {
        this.editRow(item);
      }
      if (action === FORM_ACTIONS.VIEW) {
        this.viewRow(item);
      }
      if (action === FORM_ACTIONS.RESET_PASSWORD) {
        this.resetPassword(item);
      }
    },
  },
};
</script>

<style lang="scss">
.field-btn.md-field:not(.md-disabled):after {
  background-color: transparent !important;
}
.toolbar-row {
  width: 100%;
  margin-bottom: 10px;
  .field-rows-per-page {
    float: left;
    padding: 10px 0 10px;
  }
  .field-search {
    float: right;
    padding: 10px 0 10px;
    @media only screen and (max-width: 767px) {
      float: none;
      width: 100%;
      display: inline-block;
    }
  }
  .field-btn {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
