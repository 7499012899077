<template>
  <md-card class="md-card-calendar md-calendar-list">
    <md-card-header class="md-card-header-icon">
      <div class="card-icon">
        <md-icon>calendar_month</md-icon>
      </div>
      <div class="card-title" style="position: absolute; right: 20px; top: 20px">
        <span>{{ $t('pages/Dashboard/Components/CalendarListPathologist/title') }}</span>
      </div>
    </md-card-header>
    <md-card-content>
      <fullCalendar v-if="isReady" ref="calendar" :options="calendarOptions" />
    </md-card-content>
    <md-card-actions class="md-alignment-left">
      <ul class="pathologist-calendar-list">
        <li v-for="(item, index) in calendars" :key="index">
          <md-checkbox v-model="selectedCalendars" :value="item.id" :class="`event-group-${index}`">{{
            item.name
          }}</md-checkbox>
        </li>
      </ul>
    </md-card-actions>
  </md-card>
</template>
<script>
import FullCalendar from '@fullcalendar/vue';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import DashboardService from '@/services/dashboard.service';

export default {
  components: {
    FullCalendar,
  },
  created() {
    this.fetchData();
  },
  watch: {
    selectedCalendars: {
      deep: true,
      handler: function (val) {
        if (this.$refs.calendar) {
          this.$refs.calendar
            .getApi()
            .getEvents()
            .map((event) => {
              event.remove();
            });
          this.initialEvents.map((e) => {
            if (val.map((v) => v.toString()).includes(e.groupId.toString())) {
              this.$refs.calendar.getApi().addEvent(e);
            }
          });
        }
      },
    },
  },
  computed: {
    calendarOptions() {
      return {
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin, // needed for dateClick
          listPlugin,
        ],
        headerToolbar: {
          //center: 'dayGridMonth,timeGridWeek,timeGridDay',
          right: 'prev,next,today',
        },
        initialDate: new Date(),
        firstDay: new Date().getDay(), // today
        initialView: 'listWeek',
        initialEvents: this.initialEvents, // alternatively, use the `events` setting to fetch from a feed
        editable: true,
        selectable: true,
        noEventsContent: this.noEventsContent,
        dayMaxEvents: true,
        height: 300,
      };
    },
  },
  data() {
    return {
      isReady: false,
      calendars: [],
      calendarOptionValues: null,
      initialEvents: [],
      events: [],
      selectedCalendars: [],
    };
  },
  methods: {
    async fetchData() {
      const { data, error } = await DashboardService.myCalendarDashboard();
      if (error) {
        this.$alertError(error);
        return;
      }
      // transform data
      const { MyCalendar, ClinicCalendar } = data;
      const INITIAL_EVENTS = [];
      const calendars = [];
      const selectedCalendars = [];
      if (MyCalendar) {
        MyCalendar.map((event) => {
          INITIAL_EVENTS.push({
            id: event.id,
            title: event.title,
            start: event.startDate,
            end: event.endDate,
            allDay: true,
            groupId: 'myCalendar',
            className: 'event event-group-0',
          });
        });
      }
      calendars.push({ id: 'myCalendar', name: 'My Calendar' });
      selectedCalendars.push('myCalendar');

      ClinicCalendar.map((c, index) => {
        const { events, clinicId, clinicName } = c;
        calendars.push({ id: clinicId, name: clinicName });
        selectedCalendars.push(clinicId);

        events.map((event) => {
          INITIAL_EVENTS.push({
            groupId: clinicId,
            id: event.id,
            title: event.title,
            start: event.startDate,
            end: event.endDate,
            allDay: true,
            className: `event event-group-${index + 1}`,
          });
        });
      });
      this.initialEvents = INITIAL_EVENTS;
      this.calendars = calendars;
      this.selectedCalendars = selectedCalendars;

      this.isReady = true;
    },

    noEventsContent() {
      return {
        html: this.$translate('pages/Dashboard/Components/CalendarListPathologist/noEvents'),
      };
    },
  },
};
</script>
<style lang="scss" scoped>
#fullCalendar {
  min-height: 300px;
}

.md-card-calendar {
  .md-card-content {
    padding: 0 !important;
  }
}
.pathologist-calendar-list {
  display: flex;
  justify-content: space-between;
  list-style: none;
  padding: 0;
  margin: 0;
  li {
    margin: 0;
    padding: 0;
  }

  .md-checkbox {
    margin: 0;
    display: flex;
    padding-right: 10px;
  }
}

.text-center {
  text-align: center;
}
</style>
