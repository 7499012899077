<template>
  <div class="p-import-complete">
    <modal-print-label-preview slot="blockTitle" ref="printLabelPreviewModal"></modal-print-label-preview>
    <md-table class="paginated-table table-striped table-hover lims-table case-list-tbl">
      <md-table-toolbar>
        <div class="toolbar-row">
          <div class="md-layout btnRight">
            <lims-row-per-page :pageSize="query.pagination.pageSize" @pageSizeChanged="$pageSizeChanged" />
          </div>
          <div class="md-layout lims-form-row">
            <div class="md-layout-item md-size-30 md-small-size-100 infor-cases">
              <label>{{ $t('pages/case/ImportCase/components/ImportComplete/importedCases') }}</label>
              <div class="static-text">{{ importedCasesTotal }}</div>
            </div>
            <div class="md-layout-item md-size-30 md-small-size-100 infor-cases">
              <label>{{ $t('pages/case/ImportCase/components/ImportComplete/importID') }}</label>
              <div class="static-text">{{ caseImportID }}</div>
            </div>
            <div class="md-layout-item md-size-30 md-small-size-100 infor-cases">
              <label>{{ $t('pages/case/ImportCase/components/ImportComplete/importer') }}</label>
              <div class="static-text">{{ userName }}</div>
            </div>
          </div>
        </div>
      </md-table-toolbar>
      <div class="table-fix-header">
        <thead>
          <md-table-row>
            <md-table-head v-for="(col, index) in colTabl" :key="index" :class="col.class">
              <div @mouseover="hoverIn(index)" @mouseout="hoverOut()" v-html="getColumnName(col)"></div>
            </md-table-head>
          </md-table-row>
        </thead>
        <tbody>
          <md-table-row v-for="(item, index) in caseImportedList" :key="`case-${index}`">
            <md-table-cell>{{ item.caseReference }}</md-table-cell>
            <md-table-cell>{{ item.lineNumber }}</md-table-cell>
            <md-table-cell>{{ item.laboratoryReference }}</md-table-cell>
            <md-table-cell>{{ item.hospitalReference }}</md-table-cell>
            <md-table-cell>{{ item.clinicianName }}</md-table-cell>
            <md-table-cell>{{ item.clinicTypeName }}</md-table-cell>
            <md-table-cell>{{ item.laboratoryName }}</md-table-cell>
            <md-table-cell>{{ item.pathologistName }}</md-table-cell>
          </md-table-row>
        </tbody>
      </div>
    </md-table>
    <lims-pagination :total-item="importedCasesTotal" :pagination="query.pagination"> </lims-pagination>

    <div class="md-layout-item md-size-100 md-small-size-100 comfirm-upload-action mg-top-24">
      <md-button @click="onCancel()" class="md-button lims-form-button md-theme-default">
        {{ $t('global/button/button.cancel') }}
      </md-button>
      <md-button @click="onPrintLabel()" class="md-button md-primary lims-form-button md-theme-default">
        {{ $t('pages/case/ImportCase/components/ImportComplete/button.printLabel') }}
      </md-button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { ModalPrintLabelPreview } from '@/components/Lims/modals';
import { ListWithFilterMixins } from '@/core/mixins';
import CaseImportListMixin from '@/pages/Case/ImportCase/List/CaseImportList.mixins';
import LimsRowPerPage from '@/components/Lims/LimsRowPerPage';
import LimsPagination from '@/components/Lims/LimsPagination';

export default {
  name: 'ImportCompleteList',
  mixins: [CaseImportListMixin, ListWithFilterMixins],
  components: {
    LimsRowPerPage,
    LimsPagination,
    ModalPrintLabelPreview,
  },
  props: {
    value: {
      required: false,
      default: [],
    },
    caseImportID: {
      required: false,
      default: '',
    },
  },

  data() {
    return {
      colTabl: [
        {
          name: 'pages/case/ImportCase/list.createdCaseRef',
          key: 'caseRef',
          sort: false,
          class: 'caseRef-column',
        },
        {
          name: 'pages/case/ImportCase/list.excelRowNo',
          key: 'excelRow',
          sort: false,
          class: 'excelRow-column',
        },
        {
          name: 'pages/case/ImportCase/list.labRef',
          key: 'labReference',
          sort: false,
          class: 'labRef-column',
        },

        {
          name: 'pages/case/ImportCase/list.hospitalRef',
          key: 'hospitalReference',
          sort: false,
          class: 'hospitalRef-column',
        },
        {
          name: 'pages/case/ImportCase/list.clinician',
          key: 'clinicianName',
          sort: false,
          class: 'clinician-column',
        },
        {
          name: 'pages/case/ImportCase/list.clinicType',
          key: 'clinicType',
          sort: false,
          class: 'clinicType-column',
        },
        {
          name: 'pages/case/ImportCase/list.lab',
          key: 'lab',
          sort: false,
          class: 'lab-column',
        },
        {
          name: 'pages/case/ImportCase/list.pathologist',
          key: 'pathologistName',
          sort: false,
          class: 'pathologist-column',
        },
      ],
      caseImportedList: [],
      caseImportedListTemp: [],
      importedCasesTotal: 0,
      importer: '',
    };
  },
  computed: {
    ...mapState('auth', {
      userName: (state) => state.user.userName,
    }),
  },
  watch: {
    value: {
      handler: function (val) {
        if (val) {
          this.caseImportedList = this.onPaginate(
            val,
            this.query.pagination.pageSize,
            this.query.pagination.pageNumber,
          );
          this.caseImportedListTemp = val;
          this.importedCasesTotal = val.length;
        }
      },
      deep: true,
    },
    'query.pagination.pageSize': {
      handler: function (val) {
        if (val) {
          this.caseImportedList = this.onPaginate(this.caseImportedListTemp, val, this.query.pagination.pageNumber);
        }
      },
      deep: true,
    },
    'query.pagination.pageNumber': {
      handler: function (val) {
        if (val) {
          this.caseImportedList = this.onPaginate(this.caseImportedListTemp, this.query.pagination.pageSize, val);
        }
      },
      deep: true,
    },
  },
  methods: {
    onPrintLabel() {
      const dataPrintLabel = [];
      this.caseImportedList.forEach((item) => {
        dataPrintLabel.push({
          ...item,
          patientFullname: item.firstName + ' ' + item.lastName,
          labReference: item.laboratoryReference,
        });
      });
      this.$refs.printLabelPreviewModal.setCaseListData(dataPrintLabel);
      this.$refs.printLabelPreviewModal.open();
    },
  },
};
</script>

<style lang="scss" scoped>
.infor-cases {
  display: inline-flex;
  align-items: center;
  label {
    width: 170px;
  }
}
.paginated-table .md-table-cell:last-child .md-table-cell-container {
  text-align: left !important;
  justify-content: flex-start !important;
}
</style>
