<template>
  <lims-block class="p-dataset-heading">
    <h4 class="title" slot="blockTitle">{{ $t('pages/dataset/forms/heading/blockTitle') }}</h4>
    <lims-collapse slot="blockContent" :collapse="[$t('pages/dataset/forms/collapse')]" icon="keyboard_arrow_down">
      <div class="md-layout lims-form" slot="md-collapse-pane-1">
        <div class="form-wrapper">
          <dataset-control-form
            :editControl="editControl"
            :disabled="isDisabled"
            @onSave="onSaveControl"
          ></dataset-control-form>
          <div class="md-layout lims-form-row">
            <md-table class="custom-tbl tbl-drag" md-card>
              <md-table-row v-model="controls">
                <md-table-head style="width: 50px">{{ $t('entities/dataset/form/heading/tbl.line') }}</md-table-head>
                <md-table-head class="col-15">{{ $t('entities/dataset/form/heading/tbl.label') }}</md-table-head>
                <md-table-head class="col-10">{{ $t('entities/dataset/form/heading/tbl.type') }}</md-table-head>
                <md-table-head class="th-center" style="width: 50px">
                  {{ $t('entities/dataset/form/heading/tbl.required') }}
                </md-table-head>
                <md-table-head>
                  {{ $t('entities/dataset/form/heading/tbl.value') }}
                </md-table-head>
                <md-table-head class="th-center cell-action">
                  {{ $t('global/pages/list.actions') }}
                </md-table-head>
              </md-table-row>
              <draggable v-model="controls" class="tbl-body" @end="endDrag" :disabled="isDisabled">
                <md-table-row v-for="(item, index) in controls" :key="index">
                  <md-table-cell style="width: 50px">{{ index + 1 }}</md-table-cell>
                  <md-table-cell class="col-15">{{ item.label }}</md-table-cell>
                  <md-table-cell class="col-10">{{ item.controlType }}</md-table-cell>
                  <md-table-cell class="col-center" style="width: 50px">
                    <md-checkbox v-model="item.isRequired" :disabled="isDisabled" class="lims-checkbox"></md-checkbox
                  ></md-table-cell>
                  <md-table-cell>{{ item.value }}</md-table-cell>
                  <md-table-cell class="cell-action">
                    <span v-if="!isDisabled"><md-icon>open_with</md-icon></span>
                    <span v-if="!isDisabled" @click="editRow(item, index)"><md-icon>edit</md-icon></span>
                    <span v-if="!isDisabled" @click="delRow(index)"><md-icon>close</md-icon></span>
                  </md-table-cell>
                </md-table-row>
              </draggable>
            </md-table>
          </div>
        </div>
      </div>
    </lims-collapse>
  </lims-block>
</template>

<script>
import { FormMixins } from '@/core/mixins';
import draggable from 'vuedraggable';
import LimsCollapse from '@/components/Lims/LimsCollapse';
import DatasetControlForm from './DatasetControlForm.vue';
import { arrangeOrder } from '@/core/helpers';
import { DATASET_STATUS_NAME } from '@/core/constants';

export default {
  mixins: [FormMixins],
  components: {
    draggable,
    LimsCollapse,
    DatasetControlForm,
  },
  created() {
    if (this.dataEdit) {
      this.datasetSource = this.dataEdit;
      this.controls = this.dataEdit.controls;
    }
  },
  props: {
    dataEdit: {
      type: Object,
      required: false,
    },
    formMode: {
      required: true,
    },
  },
  computed: {
    isDisabled() {
      return (
        this.formMode === this.VIEW_MODE ||
        this.dataEdit?.statusName === DATASET_STATUS_NAME.DEACTIVE ||
        this.dataEdit?.isUsedInAuthorisedCase
      );
    },
  },
  data() {
    return {
      controls: [],
      datasetSource: null,
      editControl: null,
    };
  },
  methods: {
    editRow(control, index) {
      this.$set(this, 'editControl', {
        ...control,
        index,
      });
    },
    delRow(index) {
      this.controls.splice(index, 1);
    },
    onSaveControl(value) {
      if (value.index != null) {
        // update
        this.controls[value.index] = value;
        // reset
        this.$set(this, 'editControl', null);
      } else {
        // insert
        this.controls.push(value);
      }
    },
    endDrag(dragEventData) {
      const controls = this.controls.map((c, order) => {
        return {
          order,
          ...c,
        };
      });

      arrangeOrder(controls, dragEventData.oldIndex, dragEventData.newIndex);
      // reset
      this.editControl = null;
      this.$set(
        this,
        'controls',
        controls.sort((a, b) => a.order - b.order),
      );
      if (this.datasetSource) {
        this.$set(
          this.datasetSource,
          'controls',
          controls.sort((a, b) => a.order - b.order),
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.p-dataset-heading {
  .checkbox-padding {
    padding-top: 26px;
    @media only screen and (max-width: 959px) {
      padding-top: 0;
    }
  }
}
</style>
