import { NUMBER_OF_CHARACTERS_SEARCH } from '@/core/constants';
import httpClient from './httpClient';
import { endDay, startDay } from '@/core/helpers';

const RESOURCE = 'cases/import';

export default {
  downloadCaseImportTemplate(data) {
    return httpClient.postBlob(`/${RESOURCE}/template`, data);
  },

  validateCasesImport(caseImportFile, timeZone) {
    return httpClient.postFileAndData(`/${RESOURCE}/validate`, caseImportFile, {
      TimeZone: timeZone,
    });
  },

  importCase(data) {
    return httpClient.post(`/${RESOURCE}`, data);
  },

  getImportCaseHistory({ filters, pagination, sort }) {
    const { ListImportIds, ListImporterIds, importDate, search } = filters;
    const { pageNumber, pageSize } = pagination;
    const userQueryParams = {};
    if (ListImportIds) {
      userQueryParams['Filters.ListImportIds'] = ListImportIds;
    }
    if (ListImporterIds) {
      userQueryParams['Filters.ListImporterIds'] = ListImporterIds;
    }
    if (importDate) {
      const values = importDate.split(',');

      if (values[0] && values[1]) {
        userQueryParams['Filters.ImportDateFrom'] = startDay(values[0]);
        userQueryParams['Filters.ImportDateTo'] = endDay(values[1]);
      }
    }
    if (search && search.length >= NUMBER_OF_CHARACTERS_SEARCH) {
      userQueryParams['Filters.Search'] = search;
    }
    if (pageNumber) {
      userQueryParams['PageNumber'] = pageNumber;
    }
    if (pageSize) {
      userQueryParams['PageSize'] = pageSize;
    }
    if (sort) {
      userQueryParams['Sort'] = sort;
    }
    return httpClient.get(`/${RESOURCE}/history`, userQueryParams);
  },

  getImportCaseHistoryDetails({ importId, pagination }) {
    const { pageNumber, pageSize } = pagination;
    const userQueryParams = {};
    if (pageNumber) {
      userQueryParams['PageNumber'] = pageNumber;
    }
    if (pageSize) {
      userQueryParams['PageSize'] = pageSize;
    }
    return httpClient.get(`/${RESOURCE}/history/${importId}`, userQueryParams);
  },
};
