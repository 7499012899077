import { cloneDeep } from 'lodash';

const PolicyInfoDTO = {
  title: 'required',
  policyTypeId: 'required',
  comment: '',
  isNeedAcceptance: '',
  policyFile: 'required',
};

const PolicySchema = {
  entity: 'policy/information',
  fields: {
    ...PolicyInfoDTO,
  },
  _serverSideValidators: {
    title: {
      fieldName: 'title',
      errorType: 'unique',
      functionName: 'policyTitleCheckUnique',
      params: {},
    },
  },
};

const preparePolicySchema = (policySchema, params = {}) => {
  const schema = cloneDeep(policySchema);
  schema._serverSideValidators.title.params = {
    policyId: params ? params.policyId : '',
  };

  // default add mode
  return schema;
};

export const getPolicySchema = (mode, params) => {
  return preparePolicySchema(PolicySchema, mode, params);
};
