<template>
  <md-dialog :md-close-on-esc="true" :md-active.sync="isVisible" @md-clicked-outside="close" class="lims-modal">
    <div class="dialog-icon-close" @click="close"><md-icon>close</md-icon></div>
    <md-dialog-title>{{ title }}</md-dialog-title>
    <div class="lims-form dialog-content">
      <p class="text-center">{{ description }}</p>
    </div>
    <md-dialog-actions>
      <md-button @click="onCancel" class="lims-form-button">{{ $t('global/button/button.cancel') }}</md-button>
      <md-button @click="onClick" class="lims-form-button md-danger">{{ $t('global/button/button.reject') }}</md-button>
    </md-dialog-actions>
  </md-dialog>
</template>

<script>
import modalMixins from '@/components/Lims/modals/modal.mixins';

export default {
  name: 'modal-confirm-generic',
  mixins: [modalMixins],
  props: {
    title: {
      type: String,
      require: true,
    },
  },
  data: function () {
    return {
      description: '',
      item: null,
    };
  },
  methods: {
    setData({ item, description }) {
      this.item = item;
      this.description = description;
    },
    onClick() {
      this.$emit('onReject', { item: this.item });
      this.close();
    },
    onCancel() {
      this.close();
      this.$emit('onCancel');
    },
  },
};
</script>

<style lang="scss"></style>
