<template>
  <md-dialog :md-close-on-esc="false" :md-active.sync="isVisible" @md-clicked-outside="false" class="lims-modal">
    <md-dialog-title>{{ $t('components/lims/modals/ModalLoadingGeneric.title') }}</md-dialog-title>
    <div class="lims-form dialog-content">
      <div style="text-align: center">
        <md-progress-spinner :md-diameter="100" :md-stroke="10" md-mode="indeterminate"></md-progress-spinner>
      </div>
    </div>
  </md-dialog>
</template>

<script>
import modalMixins from '@/components/Lims/modals/modal.mixins';

export default {
  name: 'modal-loading-generic',
  mixins: [modalMixins],
  props: {},
  methods: {},
};
</script>

<style lang="scss"></style>
