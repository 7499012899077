/* eslint-disable no-undef */
/* eslint-disable security/detect-object-injection */
const getEnvironmentValue = (key) => {
  if (!LDPATH_ENVIRONMENTS) {
    return process.env[key];
  }
  return LDPATH_ENVIRONMENTS[key] ? LDPATH_ENVIRONMENTS[key] : process.env[key];
};
export default {
  VUE_APP_NAME: getEnvironmentValue('VUE_APP_NAME'),
  VUE_APP_API: getEnvironmentValue('VUE_APP_API'),
  VUE_APP_EXPIRED_DAYS: getEnvironmentValue('VUE_APP_EXPIRED_DAYS'),
  VUE_APP_IDDLE_TIME_LIMIT: getEnvironmentValue('VUE_APP_IDDLE_TIME_LIMIT'),
  VUE_APP_EXPIRED_DROPDOWN_DATA: getEnvironmentValue('VUE_APP_EXPIRED_DROPDOWN_DATA'),
  VUE_APP_IDDLE_TIMEOUT_INTERVAL: getEnvironmentValue('VUE_APP_IDDLE_TIMEOUT_INTERVAL'),
  VUE_APP_BUILD_VERSION: getEnvironmentValue('VUE_APP_BUILD_VERSION'),
};
