import { cloneDeep } from 'lodash';
import { mapGetters } from 'vuex';

const ALPHABET = 'abcdefghijklmnopqrstuvwxyz';

export const generateSpecimenBlockName = (specimenIndex, idxRow, blockNamingRuleSetting) => {
  const { enable1ANamingConvention, skippedIO } = blockNamingRuleSetting;
  let blockName;
  if (enable1ANamingConvention) {
    const specimenName = generateSpecimenName(idxRow, {
      enable1ANamingConvention: false,
      skippedIO: skippedIO,
    });
    blockName = `${specimenIndex + 1}${specimenName}`.toUpperCase();
  } else {
    const specimenName = generateSpecimenName(specimenIndex, {
      enable1ANamingConvention: false,
      skippedIO: skippedIO,
    });
    blockName = `${specimenName}${idxRow + 1}`.toUpperCase();
  }
  return blockName;
};

export const generateSpecimenName = (specimenIndex, blockNamingRuleSetting) => {
  const { enable1ANamingConvention, skippedIO } = blockNamingRuleSetting;
  let azchars = skippedIO ? ALPHABET.replaceAll('o', '').replaceAll('i', '') : ALPHABET;
  let alphabet = azchars.split('');
  let index = specimenIndex % 26;

  let positionPreCharacter = Math.floor(parseInt(`${specimenIndex}`) / 26) - 1;
  let isOutAZchars = specimenIndex > 25 ? `${alphabet[positionPreCharacter]}` : '';

  if (skippedIO) {
    index = specimenIndex % 24;
    positionPreCharacter = Math.floor(parseInt(`${specimenIndex}`) / 24) - 1;
    isOutAZchars = specimenIndex > 23 ? `${alphabet[positionPreCharacter]}` : '';
  }

  let specimenName = `${isOutAZchars}${alphabet[index]}`.toUpperCase();
  if (enable1ANamingConvention) {
    specimenName = `${specimenIndex + 1}`;
  }
  return specimenName;
};

export const DEFAULT_SPECIMEN_BLOCK_NAMING_RULE = { enable1ANamingConvention: false, skippedIO: false };

export default {
  computed: {
    ...mapGetters('caseForm', ['$blockNamingRuleSetting']),
    blockNamingRuleSetting() {
      const namingRule = cloneDeep(DEFAULT_SPECIMEN_BLOCK_NAMING_RULE);
      const res = this.$blockNamingRuleSetting;
      if (res && res.data) {
        const data = res.data;
        Reflect.set(namingRule, 'enable1ANamingConvention', data.enable1ANamingConvention);
        Reflect.set(namingRule, 'skippedIO', data.skippedIO);
      }
      return namingRule;
    },
  },
  methods: {
    $convertBlockName({ specimenIndex, blockIndex, blockNamingRuleSetting }) {
      return generateSpecimenBlockName(specimenIndex, blockIndex, blockNamingRuleSetting);
    },
    $generateBlockName(specimenIndex, blockIndex) {
      const blockName = generateSpecimenBlockName(specimenIndex, blockIndex, this.blockNamingRuleSetting);
      this.formData.caseSpecimens[specimenIndex].caseSpecimenBlocks[blockIndex].block = blockName;
      return blockName;
    },

    $generateSpecimenName(specimenIndex) {
      const specimenName = generateSpecimenName(specimenIndex, this.blockNamingRuleSetting);
      return specimenName;
    },
  },
};
