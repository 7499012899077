<template>
  <div>
    <modal-confirm-generic
      ref="unSaveChangesModal"
      :description="$t('components/lims/modals/ModalFormCancel.text')"
      @onConfirm="onUnSaveChanges"
    />
    <component v-bind:is="activeUserType" :userTypeName="userTypeName"></component>
  </div>
</template>

<script>
import Administrator from './../Forms/Administrator/Administrator';
import ClinicAssociate from './../Forms/ClinicAssociate/ClinicAssociate';
import Clinician from './../Forms/Clinician/Clinician';
import ClinicianAssociate from './../Forms/ClinicianAssociate/ClinicianAssociate';
import LabTechnician from './../Forms/LabTechnician/LabTechnician';
import Pathologist from './../Forms/Pathologist/Pathologist';

import { UnSaveChangesMixins } from '@/core/mixins';

const ACTIVE_USER_TYPES = {
  administrator: Administrator,
  clinicAssociate: ClinicAssociate,
  clinician: Clinician,
  clinicianAssociate: ClinicianAssociate,
  labTechnician: LabTechnician,
  pathologist: Pathologist,
};

export default {
  mixins: [UnSaveChangesMixins],
  props: {
    userType: {
      require: false,
      default: null,
    },
  },
  computed: {
    activeUserType() {
      return ACTIVE_USER_TYPES[this.userType];
    },
    userTypeName() {
      return this.userType;
    },
  },
};
</script>

<style lang="scss" scoped></style>
