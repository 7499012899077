/**
 * Error's routes. Includes
 * 404 : Notfound
 * 403 : Forbidden
 */
// Layouts
const DashboardLayout = () => import('@/layouts/DashboardLayout.vue');

// Error's pages
const ErrorNotFound = () => import('@/pages/Error/ErrorNotFound.vue');
const ErrorForbidden = () => import('@/pages/Error/ErrorForbidden.vue');

export default {
  path: '*',
  component: DashboardLayout,
  children: [
    {
      path: 'error-forbidden',
      name: 'ErrorForbidden',
      component: ErrorForbidden,
    },
    {
      path: 'error-not-found',
      name: 'ErrorNotFound',
      component: ErrorNotFound,
    },
  ],
};
