<template>
  <lims-block :cardText="false" class="list-page">
    <div slot="blockContent">
      <collapse
        :wrapperClass="'download-case-import-template-collapse'"
        :collapse="downloadCaseImportTemplate"
        icon="keyboard_arrow_down"
      >
        <template slot="md-collapse-pane-1">
          <p>{{ $t('pages/case/ImportCase/downloadCaseImportTemplate/txt') }}</p>
          <download-case-import-template></download-case-import-template>
        </template>
      </collapse>

      <collapse :wrapperClass="'import-cases-collapse'" :collapse="ImportCases" icon="keyboard_arrow_down">
        <template slot="md-collapse-pane-1">
          <p>{{ $t('pages/case/ImportCase/ImportCases/txt') }}</p>
          <import-cases></import-cases>
        </template>
      </collapse>
    </div>
  </lims-block>
</template>

<script>
import { Collapse } from '@/components';
import DownloadCaseImportTemplate from '@/pages/Case/ImportCase/Components/DownloadCaseImportTemplate.vue';
import ImportCases from '@/pages/Case/ImportCase/Components/ImportCases.vue';

export default {
  components: {
    Collapse,
    DownloadCaseImportTemplate,
    ImportCases,
  },
  computed: {
    downloadCaseImportTemplate() {
      const name = 'pages/case/ImportCase/collapeName.downloadCaseImportTemplate';
      return [this.$translate(name)];
    },
    ImportCases() {
      const name = 'pages/case/ImportCase/collapeName.ImportCases';
      return [this.$translate(name)];
    },
  },
};
</script>

<style lang="scss"></style>
