<template>
  <ValidationObserver id="patientDataBlock" ref="formPatientData" v-slot="{ handleSubmit }">
    <modal-concurrency-issue
      ref="concurrencyIssueModal"
      :name-of-block="'Patient Data'"
      @onContinue="$onConcurrencyIssueContinue"
    ></modal-concurrency-issue>
    <form class="md-layout lims-form" @submit.prevent="handleSubmit()">
      <div class="form-wrapper">
        <div class="md-layout lims-form-row">
          <div class="md-layout-item md-size-25 md-small-size-50">
            <lims-field :model="formData" :schema="caseFormSchema" field="caseRef"></lims-field>
            <div v-if="formMode !== ADD_MODE && dataEdit" class="md-static-field">
              <md-field>
                <md-input disabled :value="dataEdit.caseReference" type="text" tabenable="yes"></md-input>
              </md-field>
            </div>
          </div>
          <div class="md-layout-item md-size-25 md-small-size-50">
            <lims-field :model="formData" :schema="caseFormSchema" field="firstName">
              <md-input
                slot="field"
                v-model="formData.firstName"
                type="text"
                maxlength="250"
                :disabled="
                  viewMode || isAnonymiseField(formData, 'FirstName') || (dataEdit ? dataEdit.isDeleted : false)
                "
                :class="isAnonymiseField(formData, 'FirstName') ? 'anonymise-field' : ''"
                tabenable="yes"
              ></md-input>
            </lims-field>
          </div>
          <div class="md-layout-item md-size-25 md-small-size-50">
            <lims-field :model="formData" :schema="caseFormSchema" field="lastName">
              <md-input
                slot="field"
                v-model="formData.lastName"
                type="text"
                maxlength="250"
                :disabled="
                  viewMode || isAnonymiseField(formData, 'LastName') || (dataEdit ? dataEdit.isDeleted : false)
                "
                :class="isAnonymiseField(formData, 'LastName') ? 'anonymise-field' : ''"
                tabenable="yes"
              ></md-input>
            </lims-field>
          </div>
          <div class="md-layout-item md-size-25 md-small-size-50">
            <lims-field :model="formData" :schema="caseFormSchema" field="sexId">
              <v-select
                slot="field"
                :options="sexList"
                v-model="formData.sexId"
                label="fieldItemName"
                :placeholder="$t('entities/case/form/sexId.placeholder')"
                :reduce="(option) => option.fieldItemId"
                :disabled="viewMode || (dataEdit ? dataEdit.isDeleted : false)"
                tabenable="yes"
                :tabindex="1"
              >
                <template #option="{ fieldItemName }">{{ fieldItemName }}</template>
                <template #selected-option="{ fieldItemName }">{{ fieldItemName }}</template>
              </v-select>
            </lims-field>
          </div>
          <div class="md-layout-item md-size-25 md-small-size-50">
            <lims-field :model="formData" :schema="caseFormSchema" field="dob">
              <template v-if="isAnonymiseField(formData, 'DOB')">
                <md-input
                  slot="field"
                  v-model="formData.dob"
                  type="text"
                  :disabled="viewMode || isAnonymiseField(formData, 'DOB') || (dataEdit ? dataEdit.isDeleted : false)"
                  :class="isAnonymiseField(formData, 'DOB') ? 'anonymise-field' : ''"
                  tabenable="yes"
                ></md-input>
              </template>
              <template v-else>
                <date-picker
                  :lang="{
                    formatLocale: {
                      firstDayOfWeek: 1,
                    },
                    monthBeforeYear: false,
                  }"
                  slot="field"
                  v-model="formData.dob"
                  format="DD/MM/YYYY"
                  :disabled="viewMode || isAnonymiseField(formData, 'DOB') || (dataEdit ? dataEdit.isDeleted : false)"
                  :class="isAnonymiseField(formData, 'DOB') ? 'anonymise-field' : ''"
                  tabenable="yes"
                ></date-picker>
              </template>
            </lims-field>
          </div>
          <div class="md-layout-item md-size-25 md-small-size-50 dobNotProvide-col">
            <div class="dobNotProvide-wrapper">
              <div class="age-patientData">
                {{
                  $t('entities/case/form/ageComputation', {
                    age: formData.age,
                  })
                }}
                <lims-tooltip :content="$t('entities/case/form/ageTooltip')"></lims-tooltip>
              </div>
            </div>
          </div>
          <div class="md-layout-item md-size-25 md-small-size-50 phone-field">
            <lims-field :model="formData" :schema="caseFormSchema" field="phone">
              <md-input
                slot="field"
                :placeholder="$t('global/placeholder.telephone')"
                v-model="formData.phone"
                type="text"
                maxlength="50"
                :disabled="viewMode || isAnonymiseField(formData, 'Phone') || (dataEdit ? dataEdit.isDeleted : false)"
                :class="isAnonymiseField(formData, 'Phone') ? 'anonymise-field' : ''"
                tabenable="yes"
              ></md-input>
            </lims-field>
          </div>
          <div class="md-layout-item md-size-25 md-small-size-50">
            <lims-field :model="formData" :schema="caseFormSchema" field="hospitalReference">
              <lims-tooltip slot="label-info" :content="$t('entities/case/form/hospitalReference.txt')"></lims-tooltip>
              <md-input
                slot="field"
                v-model="formData.hospitalReference"
                type="text"
                maxlength="50"
                :placeholder="$t('entities/case/form/hospitalReference.placeholder')"
                :disabled="viewMode || (dataEdit ? dataEdit.isDeleted : false)"
                tabenable="yes"
              ></md-input>
            </lims-field>
          </div>
          <div class="md-layout-item md-size-25 md-small-size-50">
            <lims-field :model="formData" :schema="caseFormSchema" field="laboratoryReference">
              <md-input
                slot="field"
                v-model="formData.laboratoryReference"
                type="text"
                maxlength="50"
                :placeholder="$t('entities/case/form/laboratoryReference.placeholder')"
                :disabled="viewMode || (dataEdit ? dataEdit.isDeleted : false)"
                tabenable="yes"
              ></md-input>
            </lims-field>
          </div>
          <div class="md-layout-item md-size-25 md-small-size-50">
            <lims-field :model="formData" :schema="caseFormSchema" field="procedureDate">
              <date-picker
                slot="field"
                v-model="formData.procedureDate"
                format="DD/MM/YYYY"
                :disabled="viewMode || (dataEdit ? dataEdit.isDeleted : false)"
                tabenable="yes"
              ></date-picker>
            </lims-field>
          </div>
        </div>
        <div class="md-layout lims-form-row">
          <div class="md-layout-item md-size-25 md-small-size-100">
            <lims-field :model="formData" :schema="caseFormSchema" field="patientNhsNumber">
              <md-input
                slot="field"
                v-model="formData.patientNhsNumber"
                type="text"
                maxlength="50"
                :placeholder="$t('entities/case/form/patientNhsNumber.placeholder')"
                :disabled="viewMode || (dataEdit ? dataEdit.isDeleted : false)"
                tabenable="yes"
              ></md-input>
            </lims-field>
          </div>
          <div class="md-layout-item md-size-25 md-small-size-50">
            <lims-field :model="formData" :schema="caseFormSchema" field="labEntryDate">
              <date-picker
                slot="field"
                v-model="formData.labEntryDate"
                :disabled="viewMode || (dataEdit ? dataEdit.isDeleted : false) || isLabEntryDateDisabled"
                :lang="{
                  formatLocale: {
                    firstDayOfWeek: 1,
                  },
                  monthBeforeYear: false,
                }"
                type="datetime"
                format="DD/MM/YYYY HH:mm:ss"
                :time-picker-options="{ start: '00:00', step: '00:10', end: '23:50' }"
                tabenable="yes"
              ></date-picker>
            </lims-field>
          </div>
          <div v-if="formMode === EDIT_MODE" class="md-layout-item md-size-25 md-small-size-100">
            <lims-field :model="formData" :schema="caseFormSchema" field="fromSourceName"></lims-field>
            <p class="static-text HL7-field">{{ formData.fromSourceName }}</p>
          </div>
        </div>
        <div class="md-layout lims-form-row">
          <div class="md-layout-item md-size-100 md-small-size-100">
            <lims-field :model="formData" :schema="caseFormSchema" field="comment" class="textarea-field">
              <md-textarea
                slot="field"
                v-model="formData.comment"
                type="text"
                :disabled="viewMode || (dataEdit ? dataEdit.isDeleted : false)"
                tabenable="yes"
              ></md-textarea>
            </lims-field>
          </div>
          <div class="md-layout-item md-size-50 md-small-size-50">
            <lims-field :model="formData" :schema="caseFormSchema" field="urgent">
              <lims-tooltip slot="label-info" :content="$t('entities/case/form/urgent.txt')"></lims-tooltip>
              <md-checkbox
                slot="field"
                v-model="formData.urgent"
                class="lims-checkbox"
                :disabled="viewMode || (dataEdit ? dataEdit.isDeleted : false)"
                tabenable="yes"
              ></md-checkbox>
            </lims-field>
          </div>
          <div class="md-layout-item md-size-50 md-small-size-50">
            <label>{{ $t('entities/case/form/status') }}</label>
            <p class="static-text pd-top-10">
              {{ formData.statusName ? formData.statusName : $t('entities/case/form/status.Lab') }}
            </p>
          </div>
        </div>
        <div v-if="formMode === EDIT_MODE && !(dataEdit ? dataEdit.isDeleted : false)" class="md-layout lims-form-row">
          <div class="md-layout-item md-size-100 md-small-size-100 lims-form-actions case-block-action">
            <lims-form-cancel></lims-form-cancel>
            <md-button
              @click="onSave()"
              :disabled="isProcessing"
              class="md-button md-primary lims-form-button md-theme-default"
            >
              {{ $t('global/button/button.save') }}
            </md-button>
          </div>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
import cloneDeep from 'lodash/cloneDeep';
import { FormMixins } from '@/core/mixins';
import CaseMixins from '@/pages/Case/CaseManagement/Case.mixins';
import {
  formatDateWithoutTime,
  convertDateTimeToUTCFormat,
  fromISOToCurrentTimezone,
  convertFromDateTimezoneToIsoString,
} from '@/core/helpers';
import { caseFormService } from '@/services';
import { APP_EVENTS, CASE_FORM_BLOCK, CASE_STATUS, DROPDOWN_SHORT_NAME, newAppEvent } from '@/core/constants';
import { getCaseFormSchema } from '@/schemas/case-form.schema';
import { mapActions, mapGetters } from 'vuex';
import ModalConcurrencyIssue from '@/components/Lims/modals/ModalConcurrencyIssue';
import CaseBlockMixins from '@/pages/Case/CaseManagement/CaseBlock.mixins';
import {
  calculatePatientAge,
  validateDob,
  validateProcedureDate,
  validateLabEntryDate,
} from '@/pages/Case/CaseManagement/Case.helpers';

export default {
  components: { ModalConcurrencyIssue },
  mixins: [FormMixins, CaseMixins, CaseBlockMixins],
  created() {
    this.sexList = this.getDatasetByKey(DROPDOWN_SHORT_NAME.GENDER_TYPES);
    this.fetchData(cloneDeep(this.dataEdit));
  },
  props: {
    formRef: {
      require: true,
    },
    formMode: {
      type: Number,
      require: true,
    },
    dataEdit: {
      type: Object,
      require: false,
    },
    isReviewCaseScreen: {
      type: Boolean,
      require: false,
    },
  },
  data() {
    return {
      formData: {
        age: 0,
      },
      sexList: [],
      blockId: CASE_FORM_BLOCK.BLOCK_PATIENT,
      isDobValid: true,
      isLabEntryDateValid: true,
      isProcedureDateValid: true,
      isProcessing: false,
    };
  },
  computed: {
    // Read Only:
    // Case Form - Path/Provisionally Reported/Reported
    // Case Form - Pre Lab status (disabled)
    isLabEntryDateDisabled: function () {
      return this.dataEdit?.status === CASE_STATUS.PRE_LAB;
    },
    isPreLabStatus: function () {
      return this.dataEdit?.status === CASE_STATUS.PRE_LAB;
    },
    isRequiredDob() {
      return (
        this.isAdminView &&
        (this.dataEdit?.status === CASE_STATUS.PATH ||
          this.dataEdit?.status === CASE_STATUS.PROVISIONALLY_REPORTED ||
          this.dataEdit?.status === CASE_STATUS.REPORTED)
      );
    },

    caseFormSchema: function () {
      return getCaseFormSchema(this.formMode, null, false, null, {
        ...this.dataEdit,
        formRef: this.formRef,
        caseId: this.dataEdit?.caseId,
        isReviewCaseScreen: this.isReviewCaseScreen,
        isPreLabStatus: this.isPreLabStatus,
        isAnonymized: this.formData.anonymiseFields ? true : false,
        isDobValid: this.isDobValid,
        isLabEntryDateValid: this.isLabEntryDateValid,
        isProcedureDateValid: this.isProcedureDateValid,
        isRequiredDob: this.isRequiredDob,
      });
    },
    ...mapGetters('app/data', ['getDatasetByKey']),
    ...mapGetters('app/event', [APP_EVENTS.EVT_ON_CANCEL_EDIT_FORM]),
  },
  watch: {
    formData: {
      deep: true,
      handler: function (val) {
        const dataForm = this.patientData();
        this.appendCaseData({
          caseData: dataForm,
          blockId: CASE_FORM_BLOCK.BLOCK_PATIENT,
        });
        if (this.formMode === this.ADD_MODE) {
          this.$emit('input', {
            ...val,
            dob: dataForm.dob,
            procedureDate: dataForm.procedureDate,
            labEntryDate: dataForm.labEntryDate,
          });
        }
      },
    },
    'formData.dob': {
      handler(val) {
        if (this.formData.anonymiseFields) {
          this.formData.age = 0;
        } else {
          this.formData.age = this.getAge(this.formData.procedureDate, val);
        }

        this.isDobValid = this.formData.anonymiseFields || validateDob(val, this.formData);
        if (this.isDobValid) {
          this.isProcedureDateValid =
            this.formData.anonymiseFields || validateProcedureDate(this.formData.procedureDate, this.formData);
          this.isLabEntryDateValid =
            this.formData.anonymiseFields || validateLabEntryDate(this.formData.labEntryDate, this.formData);
        }
      },
      deep: true,
    },
    'formData.procedureDate': {
      handler(val) {
        if (this.formData.anonymiseFields) {
          this.formData.age = 0;
        } else {
          this.formData.age = this.getAge(val, this.formData.dob);
        }

        this.isProcedureDateValid = this.formData.anonymiseFields || validateProcedureDate(val, this.formData);
        if (this.isProcedureDateValid) {
          this.isDobValid = this.formData.anonymiseFields || validateDob(this.formData.dob, this.formData);
          this.isLabEntryDateValid =
            this.formData.anonymiseFields || validateLabEntryDate(this.formData.labEntryDate, this.formData);
        }
      },
      deep: true,
    },
    'formData.labEntryDate': {
      handler(val) {
        this.isLabEntryDateValid = this.formData.anonymiseFields || validateLabEntryDate(val, this.formData);
        if (this.isLabEntryDateValid) {
          this.isDobValid = this.formData.anonymiseFields || validateDob(this.formData.dob, this.formData);
          this.isProcedureDateValid =
            this.formData.anonymiseFields || validateProcedureDate(this.formData.procedureDate, this.formData);
        }
      },
      deep: true,
    },
    [APP_EVENTS.EVT_ON_CANCEL_EDIT_FORM]: {
      deep: true,
      handler: function (val) {
        if (val) {
          // reload data
          this.reloadData();
        }
      },
    },
  },
  methods: {
    ...mapActions('app/event', ['removeEvent']),
    ...mapActions('caseData', ['appendCaseData']),
    getAge(procedure, birth) {
      return calculatePatientAge({ procedure, birth });
    },
    async fetchData(dataEdit) {
      if (this.formMode === this.EDIT_MODE || this.formMode === this.VIEW_MODE) {
        this.formData = {
          ...cloneDeep(dataEdit),
          dob: dataEdit.dob
            ? !dataEdit.anonymiseFields
              ? fromISOToCurrentTimezone(dataEdit.dob)
              : dataEdit.dob
            : null,
          procedureDate: dataEdit.procedureDate ? fromISOToCurrentTimezone(dataEdit.procedureDate) : null,
          labEntryDate: dataEdit.labEntryDate ? convertDateTimeToUTCFormat(dataEdit.labEntryDate) : null,
        };

        const headingTitleDOB = dataEdit.dob
          ? !dataEdit.anonymiseFields
            ? formatDateWithoutTime(fromISOToCurrentTimezone(dataEdit.dob))
            : dataEdit.dob
          : null;
        const headingTitleItem = [
          dataEdit.caseReference,
          dataEdit.laboratoryReference ? dataEdit.laboratoryReference : '',
          dataEdit.hospitalReference ? dataEdit.hospitalReference : '',
          dataEdit.firstName + ' ' + ' ' + dataEdit.lastName,
          headingTitleDOB,
          '<br>',
          dataEdit.clinicName,
          'Clinician: ' + dataEdit.clinicianName,
          'Pathologist: ' + dataEdit.pathologistName,
        ];
        const headingTitle = headingTitleItem.filter((item) => item);
        this.$setHeadingTitle(headingTitle.join(' - ').replace('- <br> -', '<br>'));
        // reset changed
        this.$resetBlockChanged();
      } else {
        this.formData = {
          labEntryDate: convertDateTimeToUTCFormat(), // default value is current time
        };
        // reset changed
        this.$resetBlockChanged();
      }
    },
    async reloadData() {
      const { data } = await caseFormService.findOne(this.dataEdit.caseId, true);
      if (data) {
        this.formData = {
          ...data,
          dob: fromISOToCurrentTimezone(data.dob),
          procedureDate: fromISOToCurrentTimezone(data.procedureDate),
          labEntryDate: data.labEntryDate ? convertDateTimeToUTCFormat(data.labEntryDate) : null,
        };
      }
      this.removeEvent(newAppEvent(APP_EVENTS.EVT_ON_CANCEL_EDIT_FORM));
    },
    patientData() {
      const data = {
        lastName: this.formData.lastName,
        firstName: this.formData.firstName,
        phone: this.formData.phone,
        sexId: this.formData.sexId,
        dob: convertFromDateTimezoneToIsoString(this.formData.dob),
        dobNotProvide: this.formData.dobNotProvide,
        laboratoryReference: this.formData.laboratoryReference,
        hospitalReference: this.formData.hospitalReference,
        patientNhsNumber: this.formData.patientNhsNumber,
        procedureDate: convertFromDateTimezoneToIsoString(this.formData.procedureDate),
        comment: this.formData.comment,
        urgent: this.formData.urgent,
        labEntryDate: this.formData.labEntryDate,
        anonymiseFields: this.dataEdit ? this.dataEdit.anonymiseFields ?? null : null,
      };
      if (this.formData.anonymiseFields) {
        Reflect.deleteProperty(data, 'lastName');
        Reflect.deleteProperty(data, 'firstName');
        Reflect.deleteProperty(data, 'dob');
        Reflect.deleteProperty(data, 'dobNotProvide');
        Reflect.deleteProperty(data, 'phone');
      }

      return data;
    },
    async onSave(overWrite = false) {
      this.$refs.formPatientData.validate().then(async (success) => {
        if (success) {
          this.isProcessing = true;
          try {
            const rowVersion = this.getRowVersionByCaseId(this.dataEdit.caseId);
            const dataForm = this.patientData();
            const res = await caseFormService.updatePatientData(this.dataEdit.caseId, {
              ...dataForm,
              rowVersion: overWrite ? null : rowVersion,
            });
            this.isProcessing = false;
            this.$onAfterSaveHandler({
              res,
              dataEdit: this.dataEdit,
            });
          } catch (errors) {
            this.isProcessing = false;
            this.$alertError(errors);
          }
        } else {
          this.isProcessing = false;
          this.$alertError(this.$t(`global/errors/message`));
        }
      });
    },
  },
};
</script>
<style lang="scss">
.form-wrapper {
  width: 100%;
}
.dobNotProvide-col {
  margin-top: 30px;
  .dobNotProvide-wrapper {
    min-height: 36px;
    display: flex;
    align-items: center;
    margin: 5px 0;
  }
}
.dobNotProvide-checkbox {
  margin: 0;
}
.age-patientData {
  display: inline-flex;
  margin-right: 10px;
  position: relative;
}
</style>
