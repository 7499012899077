<template>
  <ValidationObserver ref="form">
    <md-dialog :md-close-on-esc="true" :md-active.sync="isVisible" @md-clicked-outside="close" class="lims-modal">
      <div class="dialog-icon-close" @click="close"><md-icon>close</md-icon></div>
      <md-dialog-title>{{ $t('components/lims/modals/ModalConfirmation.title') }}</md-dialog-title>
      <div class="lims-form dialog-content">
        <p
          class="text-center"
          v-html="
            $t('components/lims/modals/ModalResendReport.txt', {
              caseRef: caseRef,
              fromSourceName: fromSourceName,
            })
          "
        ></p>
        <lims-field :model="formData" :schema="checkCodeSchema" field="password">
          <md-input slot="field" v-model="formData.password" type="password"></md-input>
        </lims-field>
      </div>
      <md-dialog-actions>
        <md-button @click="close" class="lims-form-button">{{ $t('global/button/button.cancel') }}</md-button>
        <md-button @click="onResend" :disabled="!formData.password || isProcessing" class="lims-form-button md-primary">
          {{ $t('global/button/button.resend') }}
        </md-button>
      </md-dialog-actions>
    </md-dialog>
  </ValidationObserver>
</template>

<script>
import { caseFormService } from '@/services';
import modalMixins from './modal.mixins';
import { getCheckCodeSchema } from '@/schemas/check-code.schema';

export default {
  name: 'ModalResendReport',
  mixins: [modalMixins],
  props: {
    caseId: {
      type: String,
      require: true,
    },
    caseRef: {
      type: String,
      require: true,
    },
    fromSourceName: {
      type: String,
      require: false,
      default: '',
    },
  },
  data() {
    return {
      formData: {
        password: '',
      },
    };
  },
  computed: {
    checkCodeSchema() {
      return getCheckCodeSchema(this.isWrongPasswordError);
    },
  },
  methods: {
    async onResend() {
      this.isProcessing = true;
      const dataResend = {
        password: this.formData.password,
        caseIds: [this.caseId],
        isForcedUpdate: true,
      };
      const res = await caseFormService.reSendReportCases(dataResend);
      this.isProcessing = false;
      if (res.err) {
        return this.handleErrorMessageModal(res.err);
      } else {
        this.$alertSuccess(
          this.$t('components/lims/modals/ModalResendReport/SendReportCasesSuccess', {
            caseRef: this.caseRef,
            fromSourceName: this.fromSourceName,
          }),
        );
        this.close();
      }
    },
    close() {
      this.isVisible = false;
      this.$nextTick(() => {
        this.formData = {
          password: '',
        };
      });
    },
  },
};
</script>

<style lang="scss"></style>
