<template>
  <div>
    <md-icon :class="className">adjust</md-icon>
    {{ label }}
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    showLabel: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    className: function () {
      return `slide-location-legend-${this.name}`;
    },
    label: function () {
      return this.showLabel ? this.$translate(`pages/case/SlideManagement/List/LegendSearch.${this.name}`) : '';
    },
  },
};
</script>

<style lang="scss" scoped>
.md-icon.md-theme-default.md-icon-font.slide-location-legend {
  &-Lab {
    color: navy;
  }
  &-External {
    color: orange;
  }
  &-SentToPath {
    color: red;
  }
  &-ReturnedToLab {
    color: green;
  }
  &-ReturnedToClinic {
    color: gray;
  }
}
</style>
