import {
  APP_EVENTS,
  APP_ROUTES,
  DROPDOWN_SHORT_NAME,
  ENTITY_STATUSES,
  ENTITY_TYPES,
  FORM_MODES,
  newAppEvent,
  USER_STATUSES,
} from '@/core/constants';
import {
  EntityUserList,
  PreferredPathologist,
  RestrictedPathologist,
  DisplayOption,
  SlideAndPanel,
} from './Components';
import { DropdownService, EntityService, MyEntityService } from '@/services';
import ModalConfirmation from '@/components/Lims/modals/ModalConfirmation.vue';
import { mapActions } from 'vuex';
import dropdownService from '@/services/dropdown.service';
import { ConfigMixins } from '@/core/mixins';
import { cloneDeep } from 'lodash';

export const ENTITY_PROFILE_VIEW_TYPES = {
  DEFAULT: 'default',
  MY_ENTITY: 'myEntity',
};

export default {
  mixins: [ConfigMixins],
  components: {
    ModalConfirmation,
    EntityUserList,
    PreferredPathologist,
    RestrictedPathologist,
    DisplayOption,
    SlideAndPanel,
  },
  props: {
    viewType: {
      type: String,
      require: false,
      default: ENTITY_PROFILE_VIEW_TYPES.DEFAULT,
    },
    formMode: {
      type: Number,
      require: true,
      validator: function (value) {
        return Object.values(FORM_MODES).indexOf(value) !== -1;
      },
    },
    entityResource: {
      type: Object,
      require: false,
    },
  },
  data() {
    return {
      formData: {
        information: {},
        addedUsers: [],
        labRestriction: {
          labRestrictedClinicians: [],
          labRestrictedClinics: [],
          labRestrictedPathologists: [],
        },
        labDisplayOptions: [],
        entityBillings: [],
      },
      countries: [],
      billingFrequencyList: [],
      data: {},
      dataset: {},
      dropdownTypesStains: [],
      isChangeCustomStains: false,
    };
  },
  computed: {
    $editUrl() {
      return APP_ROUTES.ENTITY_EDIT + '/' + this.entityResource.entityId;
    },
    isDefaultView() {
      return this.viewType === ENTITY_PROFILE_VIEW_TYPES.DEFAULT;
    },
    isMyEntityView() {
      return this.viewType === ENTITY_PROFILE_VIEW_TYPES.MY_ENTITY;
    },
    isViewMode() {
      return this.formMode === FORM_MODES.VIEW;
    },
    isEditMode() {
      return this.formMode === FORM_MODES.EDIT;
    },
    isAddMode() {
      return this.formMode === FORM_MODES.ADD;
    },
    isDisabledEntity() {
      if (
        this.entityResource &&
        this.entityResource.entityStatus === ENTITY_STATUSES.Disabled &&
        this.$isAuthorized(['Accounts_EntityManagement_Update'])
      ) {
        return true;
      } else {
        return false;
      }
    },
    isEnabledEntity() {
      if (
        this.entityResource &&
        this.entityResource.entityStatus === ENTITY_STATUSES.Enabled &&
        this.$isAuthorized(['Accounts_EntityManagement_Update'])
      ) {
        return true;
      } else {
        return false;
      }
    },
    isCheckHasUserActive() {
      if (
        this.entityResource.users &&
        this.entityResource.users.some(
          (elem) => elem.statusId === USER_STATUSES.Enabled || elem.statusId === USER_STATUSES.Invited,
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    isLabTechnicianView() {
      const userType = this.$store.getters['auth/userType'];
      return userType == this.USER_TYPES().LabTechnician;
    },
  },
  watch: {
    'formData.information.canAllowCustomStain': {
      handler(val) {
        this.getValueStainList(val);
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions('app/data', ['updateDataset']),
    ...mapActions('app/event', ['addEvent']),
    async $fetchData(entityTypeId) {
      let stainList;
      let dropdownTypes = [
        DROPDOWN_SHORT_NAME.COUNTRY,
        DROPDOWN_SHORT_NAME.CLINIC_TYPE,
        DROPDOWN_SHORT_NAME.BILLING_FREQUENCY,
      ];
      if (entityTypeId != ENTITY_TYPES.Clinic) {
        // load data related to lab
        dropdownTypes = [...dropdownTypes, DROPDOWN_SHORT_NAME.SLIDE_PANEL_STATUS];
        this.dropdownTypesStains = [
          DROPDOWN_SHORT_NAME.H_AND_E,
          DROPDOWN_SHORT_NAME.IMMUNOS,
          DROPDOWN_SHORT_NAME.SPECIAL_STAINS,
          DROPDOWN_SHORT_NAME.ADDITIONAL_TECHNIQUE,
        ];
        stainList = await this.getSlides(
          this.entityResource?.information.canAllowCustomStain,
          this.dropdownTypesStains,
          this.entityResource?.entityId,
          true,
        );
      }
      this.dataset = await dropdownService.getDropdownByShortNames(dropdownTypes);
      this.updateDataset({ ...this.dataset, ...stainList });

      this.clinicTypes = this.dataset[DROPDOWN_SHORT_NAME.CLINIC_TYPE];
      this.countries = this.dataset[DROPDOWN_SHORT_NAME.COUNTRY];
      this.billingFrequencyList = this.dataset[DROPDOWN_SHORT_NAME.BILLING_FREQUENCY];

      const displayOptions = await this.entityOption();
      if (this.entityResource) {
        this.formData = {
          entityId: this.entityResource.entityId,
          information: {
            ...this.entityResource.information,
            entityTypeId,
          },
          addedUsers: [...this.entityResource.users],
          slideAndPanel: {
            slides: this.entityResource.slides,
            panels: this.entityResource.panels,
          },
          clinicPreferredPathologists: this.entityResource.clinicPreferredPathologists,
          clinicRestrictedPathologists: this.entityResource.clinicRestrictedPathologists,
          labRestriction: {
            labRestrictedClinicians: this.entityResource.labRestriction?.labRestrictedClinicians,
            labRestrictedClinics: this.entityResource.labRestriction?.labRestrictedClinics,
            labRestrictedPathologists: this.entityResource.labRestriction?.labRestrictedPathologists,
          },
          labDisplayOptions: this.entityResource.labDisplayOptions,
          entityBillings: this.entityResource.entityBillings,
          entityWorkStreams: this.entityResource.entityWorkStreams,
        };

        this.$setHeadingTitle(this.formData.information.entityName);
        this.addEvent(
          newAppEvent(
            APP_EVENTS.EVT_ON_LOAD_COMMON_USED_SPECIMEN_DATA,
            cloneDeep(this.entityResource.commonlyUsedSpecimenTypes),
          ),
        );
      } else {
        this.formData = {
          information: {
            entityTypeId,
            countryId: this.$appConfig.countryId,
          },
          addedUsers: [],
          labRestriction: {
            labRestrictedClinicians: [],
            labRestrictedClinics: [],
            labRestrictedPathologists: [],
          },
          slideAndPanel: {
            slides: [],
            panels: [],
          },
          labDisplayOptions: displayOptions.data,
        };
      }

      // reset change detection
      this.$nextTick(function () {
        this.$resetChangeDetection();
      });
    },
    async fetchClinicTypes() {
      const { data } = await DropdownService.getDropdownByShortName(DROPDOWN_SHORT_NAME.CLINIC_TYPE);
      return data.map((item) => {
        return {
          value: item.fieldItemId,
          label: item.fieldItemName,
        };
      });
    },
    async entityOption() {
      if (this.isMyEntityView) {
        return MyEntityService.getMyEntityOption();
      } else {
        return EntityService.getDisplayOption();
      }
    },

    async getSlides(hasCustomStains, shortNames, entityId, isEditMode) {
      let data;
      if (hasCustomStains) {
        data = await DropdownService.getStainsAssignedCustom(shortNames, entityId, isEditMode);
      } else {
        data = await DropdownService.getStainsUnAssignedCustom(shortNames, entityId, isEditMode);
      }
      return data.reduce((obj, item) => ({ ...obj, [item.itemName]: item.items }), {});
    },

    async changeValueCustomStain() {
      this.isChangeCustomStains = true;
    },

    async getValueStainList(isCustomStain) {
      let stainList;
      if (this.isAddMode) {
        if (isCustomStain) {
          stainList = {
            HE: [],
            Immunos: [],
            SpecialStains: [],
            AddTechniques: [],
          };
        } else {
          stainList = await this.getSlides(false, this.dropdownTypesStains, null, true);
          this.isChangeCustomStains = true;
        }
      } else {
        const entityTypeId = this.formData.information.entityTypeId;
        if (entityTypeId != ENTITY_TYPES.Clinic) {
          stainList = await this.getSlides(
            isCustomStain,
            this.dropdownTypesStains,
            this.entityResource?.entityId,
            true,
          );
        }
      }
      this.updateDataset({ ...this.dataset, ...stainList });
      this.addEvent(
        newAppEvent(APP_EVENTS.EVT_ON_CHANGE_CUSTOM_STAINS, {
          isChangeCustomStains: this.isChangeCustomStains,
          isCustomStains: isCustomStain,
        }),
      );
    },

    dataForm() {
      const entityTypeId = this.formData.information.entityTypeId;
      const { Laboratory, Clinic, ClinicLaboratory } = ENTITY_TYPES;
      const data = {
        ...this.formData,
        addedUsers: this.formData.addedUsers.filter((item) => item?.tempId),
      };

      // associated data
      if (entityTypeId === Laboratory) {
        Reflect.set(data, 'labRestriction', {
          labRestrictedClinicians: this.formData.labRestriction.labRestrictedClinicians,
          labRestrictedClinics: this.formData.labRestriction.labRestrictedClinics,
          labRestrictedPathologists: this.formData.labRestriction.labRestrictedPathologists,
        });
      }
      if (entityTypeId === Laboratory || entityTypeId === ClinicLaboratory) {
        Reflect.set(data, 'slides', this.formData.slideAndPanel?.slides || []);
        Reflect.set(data, 'panels', this.formData.slideAndPanel?.panels || []);
        Reflect.deleteProperty(data, 'slideAndPanel');
      }
      if (entityTypeId === Clinic || entityTypeId === ClinicLaboratory) {
        const commonlyUsedSpecimenTypes = this.formData.commonlyUsedSpecimenTypes
          ? this.formData.commonlyUsedSpecimenTypes
          : [];
        Reflect.set(data, 'commonlyUsedSpecimenTypes', commonlyUsedSpecimenTypes);
      }

      if (this.formData.information && !this.formData.information?.email) {
        Reflect.deleteProperty(data.information, 'email');
      }

      return data;
    },

    createEntity() {
      return EntityService.create(this.dataForm());
    },

    updateEntity() {
      const dataForm = this.dataForm();
      if (this.isMyEntityView) {
        return MyEntityService.updateOneMyEntityProfile({ entityId: this.entityResource.entityId, ...dataForm });
      } else {
        return EntityService.updateOne({ entityId: this.entityResource.entityId, ...dataForm });
      }
    },

    $onSave() {
      this.$refs.form.validate().then(async (success) => {
        if (success) {
          try {
            const res = this.formMode === FORM_MODES.ADD ? await this.createEntity() : await this.updateEntity();
            if (res.err) {
              if (res.err.includes('BillingEntityInUsed')) {
                const billingEntityName = res.err.slice(20, res.err.length);
                return this.$alertError(
                  this.$t('pages/MyEntity/alertMessage/error', {
                    billingEntityName: billingEntityName,
                  }),
                );
              } else {
                return this.$alertError(res.err + ' error');
              }
            }

            this.$resetChangeDetection();
            if (this.isMyEntityView) {
              await this.$router
                .push(APP_ROUTES.MYENTITY_PROFILE)
                .then(this.$alertSuccess(this.$t(`pages/MyEntity/alertMessage/update`)));
            } else {
              this.$router.push(APP_ROUTES.ENTITY).then(
                this.$alertSuccess(
                  this.formMode === FORM_MODES.ADD
                    ? this.$t(`pages/entity/alertMessage/create.success`, {
                        entityName: this.formData.information.entityName,
                      })
                    : this.$t(`pages/entity/alertMessage/update.success`, {
                        entityName: this.formData.information.entityName,
                      }),
                ),
              );
            }
          } catch (errors) {
            this.$alertError(errors);
          }
        } else {
          this.$alertError(this.$t(`global/errors/message`));
        }
      });
    },

    $onDisableEntity() {
      if (this.isCheckHasUserActive) {
        this.$alertError(
          this.$t(`pages/entity/alertMessage/disable.e_has_user_active`, {
            entityName: this.formData.information.entityName,
          }),
        );
      } else {
        this.$refs.confirmModal.open();
      }
    },

    async $onConfirmDisabled(values) {
      // on confirm modal
      const { password } = values;
      const res = await EntityService.disable({
        entityId: this.entityResource.entityId,
        password,
      });
      if (res.err) {
        this.$alertError(this.$t(`pages/entity/alertMessage/disable.e_invalid_password`));
        return { err: this.$t(`pages/entity/alertMessage/disable.e_invalid_password`) };
      } else {
        this.$alertSuccess(
          this.$t(`pages/entity/alertMessage/disable.success`, {
            entityName: this.formData.information.entityName,
          }),
        );
        this.$onCancel();
      }
    },

    async $onEnableEntity() {
      const res = await EntityService.enable({
        entityId: this.entityResource.entityId,
      });
      if (res.err) {
        this.$alertError(this.$t(`pages/entity/alertMessage/enable.error`));
        return { err: this.$t(`pages/entity/alertMessage/enable.error`) };
      } else {
        this.$alertSuccess(
          this.$t(`pages/entity/alertMessage/enable.success`, {
            entityName: this.formData.information.entityName,
          }),
        );
        this.$onCancel();
      }
    },

    $canEdit() {
      const item = this.entityResource;
      return this.$isAuthorized(['Accounts_EntityManagement_Update']) && item.entityStatus === ENTITY_STATUSES.Enabled;
    },

    $onCancel() {
      this.$resetChangeDetection();
      setTimeout(() => {
        if (this.isMyEntityView) {
          if (this.isLabTechnicianView) {
            this.$router.push('/');
          } else {
            this.$router.back();
          }
        } else {
          this.$router.back();
        }
      }, 500);
    },
  },
};
