// Dashboard pages

const Redirect = () => import('@/pages/Redirect/Redirect.vue');
import guards from './guards';

export default {
  path: '/s/:caseReference',
  name: 'Redirect',
  meta: {
    zone: 'private',
  },
  beforeEnter: guards.checkAuthorizedGuard,
  component: Redirect,
  props: (route) => ({
    caseReference: route.params.caseReference,
  }),
};
