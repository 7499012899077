<template>
  <ValidationObserver ref="formPatientData" v-slot="{ handleSubmit }">
    <form class="md-layout lims-form" @submit.prevent="handleSubmit()">
      <div class="form-wrapper">
        <collapse
          :wrapperClass="'case-collapse diagnosis-collapse'"
          :collapse="collapseName"
          icon="keyboard_arrow_down"
        >
          <template slot="md-collapse-pane-1">
            <div class="md-layout lims-form-row">
              <div class="md-layout-item md-size-25 md-small-size-100">
                <lims-field :model="formData" :schema="caseFormSchema.diagnosis" field="reporter"> </lims-field>
                <div class="static-text">{{ formData.pathologistName }}</div>
              </div>
              <div class="md-layout-item md-size-25 md-small-size-100">
                <lims-field :model="formData" :schema="caseFormSchema.diagnosis" field="mobile"> </lims-field>
                <div class="static-text">{{ formData.pathologistMobile }}</div>
              </div>
            </div>
          </template>
        </collapse>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
import { Collapse } from '@/components';
import { FormMixins } from '@/core/mixins';
import CaseMixins from '@/pages/Case/CaseManagement/Case.mixins';
import { getCaseFormSchema } from '@/schemas/case-form.schema';

export default {
  mixins: [FormMixins, CaseMixins],
  components: { Collapse },
  created() {
    this.fetchData();
  },
  props: {
    dataEdit: {
      type: Object,
      require: false,
    },
  },
  data() {
    return {
      formData: {},
    };
  },
  computed: {
    collapseName() {
      const name = 'pages/case/CaseManagement/components/Reporter/ReporterTitle';
      return [this.$translate(name)];
    },
    caseFormSchema: function () {
      return getCaseFormSchema(this.formMode);
    },
  },
  watch: {
    formData: {
      deep: true,
      handler: function (val) {
        this.$emit('input', {
          ...val,
        });
      },
    },
  },
  methods: {
    async fetchData() {
      this.formData.pathologistName = this.dataEdit.pathologistName;
      this.formData.pathologistMobile = this.dataEdit.pathologistMobile;
    },
  },
};
</script>
<style lang="scss"></style>
