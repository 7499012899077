<template>
  <md-dialog :md-close-on-esc="true" :md-active.sync="isVisible" @md-clicked-outside="close" class="lims-modal">
    <div class="dialog-icon-close" @click="close"><md-icon>close</md-icon></div>
    <br />
    <md-dialog-title>{{ announcement }}</md-dialog-title>
    <br />
    <br />
  </md-dialog>
</template>

<script>
import modalMixins from './modal.mixins';
export default {
  name: 'ModalShowAnnouncement',

  mixins: [modalMixins],

  props: {
    announcement: {
      require: true,
    },
  },
};
</script>

<style lang="scss"></style>
