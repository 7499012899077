<template>
  <md-dialog :md-close-on-esc="true" :md-active.sync="isVisible" @md-clicked-outside="close" class="lims-modal">
    <div class="dialog-icon-close" @click="close"><md-icon>close</md-icon></div>
    <div class="lims-form dialog-content">
      <md-table class="custom-tbl" md-card>
        <md-table-row>
          <md-table-head style="width: 50px">
            {{ $t('components/ModalViewAlwBatchDetail/table.AlwID') }}
          </md-table-head>
          <md-table-head class="th-center">
            {{ $t('global/pages/list.status') }}
          </md-table-head>
          <md-table-head>
            {{ $t('components/ModalViewAlwBatchDetail/table.detail') }}
          </md-table-head>
        </md-table-row>
        <md-table-row v-for="item in AlwBatchDetailList" :key="`alw-${item.batchId}`">
          <md-table-cell>{{ item.batchId }}</md-table-cell>
          <md-table-cell class="th-center status-column">
            <md-icon
              :class="[
                { 'status-enabled': item.status == STATUSES.COMPLETED },
                { 'status-disabled': item.status == STATUSES.REQUESTED },
                { 'status-invited': item.status == STATUSES.PARTIALLY_COMPLETED },
              ]"
              >adjust</md-icon
            >
          </md-table-cell>
          <md-table-cell>
            <ul v-for="(detail, index) in item.details" :key="`${index}`" class="list-Alw-batch-detail">
              <li v-for="(i, idx1) in detail.caseALWRequests" :key="`${idx1}`">
                - {{ i.statusName }}: {{ i.caseSpecimenBlockName }} - {{ i.stainName }}
              </li>
            </ul>
          </md-table-cell>
        </md-table-row>
      </md-table>
    </div>
    <md-dialog-actions>
      <md-button @click="close" class="lims-form-button">{{ $t('global/button/button.back') }}</md-button>
    </md-dialog-actions>
  </md-dialog>
</template>

<script>
import modalMixins from '@/components/Lims/modals/modal.mixins';
import { ALW_SO_STATUS } from '@/core/constants';
import { mapActions } from 'vuex';

export default {
  mixins: [modalMixins],
  props: {
    caseId: {
      type: String,
      require: false,
    },
  },
  computed: {
    STATUSES: function () {
      return ALW_SO_STATUS;
    },
  },
  data() {
    return {
      AlwBatchDetailList: [],
    };
  },
  methods: {
    ...mapActions('app/event', ['addEvent']),

    open(data) {
      if (data) {
        this.isVisible = true;
        this.AlwBatchDetailList = data;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.list-Alw-batch-detail {
  list-style: none;
  padding: 0;
  margin: 0;
  li {
    padding-bottom: 5px;
  }
}
</style>
