import { getDefaultFilterValues, getFilterFieldTypes } from '@/query/utils';
import { FIELD_TYPES } from '@/query/constant';
import {
  FF_AlwRequestDate,
  FF_AlwRespondDate,
  FF_CaseReference,
  FF_CaseStatusIds,
  FF_ClinicianIds,
  FF_ClinicIds,
  FF_ClinicTypeIds,
  FF_HospitalReference,
  FF_LabEntryDate,
  FF_LaboratoryIds,
  FF_LabReference,
  FF_LocationExternal,
  FF_LocationLab,
  FF_LocationReturnedToClinic,
  FF_LocationReturnedToLab,
  FF_LocationSentToPath,
  FF_PathologistIds,
  FF_PatientFirstName,
  FF_PatientLastName,
  FF_ReportedDate,
  FF_SlideBlocks,
  FF_SlideIds,
  FF_SlideLastUpdated,
  FF_SlideReturnedToClinicDate,
  FF_SlideReturnedToLabDate,
  FF_SlideSentToExternalDate,
  FF_SlideSentToLabDate,
  FF_SlideSentToPathDate,
  FF_SlideTypes,
  FF_SpecimenTypeIds,
  FF_TrackingNo,
} from '../filter-fields';
const fields = [
  {
    key: FF_CaseReference,
    listKey: FF_CaseReference,
    sort: 1,
    fieldType: FIELD_TYPES.String,
    translated: true,
    defaultValue: '',
  },
  {
    key: FF_CaseStatusIds,
    listKey: FF_CaseStatusIds,
    sort: 2,
    fieldType: FIELD_TYPES.ArrayInt,
    translated: true,
    defaultValue: [],
  },
  {
    key: FF_ClinicIds,
    listKey: FF_ClinicIds,
    sort: 3,
    fieldType: FIELD_TYPES.ArrayString,
    translated: true,
    defaultValue: [],
  },
  {
    key: FF_ClinicTypeIds,
    listKey: FF_ClinicTypeIds,
    sort: 4,
    fieldType: FIELD_TYPES.ArrayInt,
    translated: true,
    defaultValue: [],
  },
  {
    key: FF_ClinicianIds,
    listKey: FF_ClinicianIds,
    sort: 5,
    fieldType: FIELD_TYPES.ArrayString,
    translated: true,
    defaultValue: [],
  },
  {
    key: FF_HospitalReference,
    listKey: null,
    sort: 6,
    fieldType: FIELD_TYPES.String,
    translated: false,
    defaultValue: '',
  },
  { key: FF_LabReference, listKey: null, sort: 7, fieldType: FIELD_TYPES.String, translated: false, defaultValue: '' },
  {
    key: FF_LaboratoryIds,
    listKey: FF_LaboratoryIds,
    sort: 8,
    fieldType: FIELD_TYPES.ArrayString,
    translated: true,
    defaultValue: [],
  },
  {
    key: FF_PathologistIds,
    listKey: FF_PathologistIds,
    sort: 9,
    fieldType: FIELD_TYPES.ArrayString,
    translated: true,
    defaultValue: [],
  },
  {
    key: FF_PatientFirstName,
    listKey: null,
    sort: 10,
    fieldType: FIELD_TYPES.String,
    translated: false,
    defaultValue: '',
  },
  {
    key: FF_PatientLastName,
    listKey: null,
    sort: 11,
    fieldType: FIELD_TYPES.String,
    translated: false,
    defaultValue: '',
  },
  {
    key: FF_SpecimenTypeIds,
    listKey: FF_SpecimenTypeIds,
    sort: 12,
    fieldType: FIELD_TYPES.ArrayString,
    translated: true,
    defaultValue: [],
  },
  {
    key: FF_LabEntryDate,
    listKey: null,
    sort: 13,
    fieldType: FIELD_TYPES.ArrayDate,
    translated: false,
    defaultValue: null,
  },
  {
    key: FF_ReportedDate,
    listKey: null,
    sort: 14,
    fieldType: FIELD_TYPES.ArrayDate,
    translated: false,
    defaultValue: null,
  },

  {
    key: FF_SlideBlocks,
    listKey: null,
    sort: 15,
    fieldType: FIELD_TYPES.ArrayString,
    translated: true,
    defaultValue: [],
  },
  {
    key: FF_SlideIds,
    listKey: FF_SlideIds,
    sort: 16,
    fieldType: FIELD_TYPES.ArrayInt,
    translated: true,
    defaultValue: [],
  },
  {
    key: FF_AlwRequestDate,
    listKey: null,
    sort: 17,
    fieldType: FIELD_TYPES.ArrayDate,
    translated: false,
    defaultValue: null,
  },
  {
    key: FF_AlwRespondDate,
    listKey: null,
    sort: 18,
    fieldType: FIELD_TYPES.ArrayDate,
    translated: false,
    defaultValue: null,
  },
  {
    key: FF_SlideLastUpdated,
    listKey: null,
    sort: 19,
    fieldType: FIELD_TYPES.ArrayDate,
    translated: false,
    defaultValue: null,
  },
  {
    key: FF_SlideTypes,
    listKey: FF_SlideTypes,
    sort: 20,
    fieldType: FIELD_TYPES.ArrayString,
    translated: true,
    defaultValue: [],
  },

  {
    key: FF_LocationLab,
    listKey: null,
    sort: 21,
    fieldType: FIELD_TYPES.Boolean,
    translated: false,
    defaultValue: null,
  },
  {
    key: FF_LocationExternal,
    listKey: null,
    sort: 21,
    fieldType: FIELD_TYPES.Boolean,
    translated: false,
    defaultValue: null,
  },
  {
    key: FF_LocationSentToPath,
    listKey: null,
    sort: 21,
    fieldType: FIELD_TYPES.Boolean,
    translated: false,
    defaultValue: null,
  },
  {
    key: FF_LocationReturnedToLab,
    listKey: null,
    sort: 21,
    fieldType: FIELD_TYPES.Boolean,
    translated: false,
    defaultValue: null,
  },
  {
    key: FF_LocationReturnedToClinic,
    listKey: null,
    sort: 21,
    fieldType: FIELD_TYPES.Boolean,
    translated: false,
    defaultValue: null,
  },
  {
    key: FF_SlideSentToLabDate,
    listKey: null,
    sort: 22,
    fieldType: FIELD_TYPES.ArrayDate,
    translated: false,
    defaultValue: null,
  },
  {
    key: FF_SlideSentToPathDate,
    listKey: null,
    sort: 23,
    fieldType: FIELD_TYPES.ArrayDate,
    translated: false,
    defaultValue: null,
  },
  {
    key: FF_SlideSentToExternalDate,
    listKey: null,
    sort: 24,
    fieldType: FIELD_TYPES.ArrayDate,
    translated: false,
    defaultValue: null,
  },
  {
    key: FF_SlideReturnedToClinicDate,
    listKey: null,
    sort: 25,
    fieldType: FIELD_TYPES.Date,
    translated: false,
    defaultValue: null,
  },
  {
    key: FF_SlideReturnedToLabDate,
    listKey: null,
    sort: 26,
    fieldType: FIELD_TYPES.ArrayDate,
    translated: false,
    defaultValue: null,
  },
  {
    key: FF_SlideSentToLabDate,
    listKey: null,
    sort: 27,
    fieldType: FIELD_TYPES.ArrayDate,
    translated: false,
    defaultValue: null,
  },
  {
    key: FF_TrackingNo,
    listKey: null,
    sort: 28,
    fieldType: FIELD_TYPES.String,
    translated: false,
    defaultValue: '',
  },
  { key: 'search', listKey: null, sort: 28, fieldType: FIELD_TYPES.String, translated: false, defaultValue: '' },
];
const defaultValues = getDefaultFilterValues(fields);
const fieldTypes = getFilterFieldTypes(fields);

export default {
  fields,
  defaultValues,
  fieldTypes,
};
