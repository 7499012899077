<template>
  <lims-block>
    <h4 class="title" slot="blockTitle">
      {{ $t('pages/entity/components/SlideAndPanel/blockTitle') }}
    </h4>
    <div slot="blockContent">
      <!-- Slides -->
      <entity-slides
        v-if="formData"
        v-model="formData.slides"
        @onChanged="onChanged"
        :panelAdded="formData.panels"
        :disabled="disabled"
        :listPanelSelected="listPanelSelected"
      ></entity-slides>

      <!-- Panels -->
      <entity-panels
        v-if="formData"
        v-model="formData.panels"
        :selectedSlideData="formData.slides"
        :disabled="disabled"
        @panelsSelected="panelsSelected"
      >
      </entity-panels>
    </div>
  </lims-block>
</template>
<script>
import EntitySlides from '@/pages/Entity/Components/EntitySlides.vue';
import EntityPanels from './EntityPanels.vue';
import isEqual from 'lodash/isEqual';
import { mapActions, mapGetters } from 'vuex';
import { APP_EVENTS, newAppEvent } from '@/core/constants';

export default {
  components: { EntitySlides, EntityPanels },
  created() {
    this.formData = this.value;
  },
  props: {
    value: {
      type: Object,
      require: true,
    },
    disabled: {
      type: Boolean,
      require: false,
      default: false,
    },
  },
  watch: {
    [APP_EVENTS.EVT_ON_FIRST_LOAD_ENTITY_SLIDE]: {
      deep: true,
      handler: async function (val) {
        if (val) {
          this.forceResetState = val.forceResetState;
        }
      },
    },
  },
  computed: {
    ...mapGetters('app/event', [APP_EVENTS.EVT_ON_FIRST_LOAD_ENTITY_SLIDE]),
  },
  data() {
    return {
      formData: null,
      listPanelSelected: [],
      forceResetState: false,
    };
  },
  methods: {
    ...mapActions('app', ['$resetChangeDetection']),
    ...mapActions('app/event', ['removeEvent']),
    compareSlides({ slides }) {
      const formDataSlides = this.formData.slides
        .map((o) => {
          return {
            fieldItemId: o.fieldItemId,
            isFavorite: o.isFavorite,
          };
        })
        .sort((a, b) => b.fieldItemId - a.fieldItemId);
      const changeSlides = slides
        .map((o) => {
          return {
            fieldItemId: o.fieldItemId,
            isFavorite: o.isFavorite,
          };
        })
        .sort((a, b) => b.fieldItemId - a.fieldItemId);
      return isEqual(formDataSlides, changeSlides);
    },
    onChanged(slides) {
      // compare
      if (this.formData.slides && !this.compareSlides({ slides })) {
        this.formData.slides = slides;
        if (this.forceResetState) {
          // reset change detection
          this.$nextTick(function () {
            this.$resetChangeDetection();
          });
          this.removeEvent(newAppEvent(APP_EVENTS.EVT_ON_FIRST_LOAD_ENTITY_SLIDE));
          this.forceResetState = false;
        }
      }
    },
    panelsSelected(val) {
      this.listPanelSelected = val;
    },
  },
};
</script>
