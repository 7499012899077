import httpClient from './httpClient';

const RESOURCE = 'OCR';
export default {
  uploadTemporaryFile(data = {}, { batchIndex, lastModified }) {
    return httpClient.postFileAndData(
      `/cases/${RESOURCE}/upload-attachfiles?batchIndex=${batchIndex}&lastModified=${lastModified}`,
      data,
    );
  },
  uploadRfs(data = {}) {
    return httpClient.post(`/cases/${RESOURCE}/upload-rfs`, data);
  },
};
