// Layouts
const DashboardLayout = () => import('@/layouts/DashboardLayout.vue');
// User pages
const PersonalisedReport = () => import('@/pages/PersonalisedReport/PersonalisedReport.vue');
const PersonalisedReportAdd = () => import('@/pages/PersonalisedReport/Add/Add.vue');
const PersonalisedReportEdit = () => import('@/pages/PersonalisedReport/Edit/Edit.vue');
const PersonalisedReportView = () => import('@/pages/PersonalisedReport/View/View.vue');
const AssignButton = () => import('@/pages/PersonalisedReport/Form/AssignButton.vue');

import guards from './guards';

export default {
  path: '/personalised-reports',
  component: DashboardLayout,
  meta: {
    zone: 'private',
    rbac: 'personalised-reports',
  },
  beforeEnter: guards.checkAuthorizedGuard,
  children: [
    {
      path: '',
      name: 'PersonalisedReport',
      component: PersonalisedReport,
      meta: {
        zone: ['Case_PersonalizedReports_Retrieve', 'My_Personalised_Report'],
      },
    },
    {
      path: 'add',
      name: 'PersonalisedReportAdd',
      component: PersonalisedReportAdd,
      meta: {
        zone: ['Case_PersonalizedReports_Create'],
      },
    },
    {
      path: 'edit/:personalisedReportId',
      name: 'PersonalisedReportEdit',
      component: PersonalisedReportEdit,
      props: true,
      meta: {
        zone: ['Case_PersonalizedReports_Update'],
      },
    },
    {
      path: 'view/:personalisedReportId',
      name: 'PersonalisedReportView',
      component: PersonalisedReportView,
      props: true,
      meta: {
        zone: ['Case_PersonalizedReports_Retrieve'],
      },
    },
    {
      path: 'pathologist/:pathologistId/:pathologistName/assign-button',
      name: 'PathologistAssignButton',
      component: AssignButton,
      props: true,
      meta: {
        zone: ['Case_PersonalizedReports_Update'],
      },
    },
    {
      path: 'pr/:personalizedReportId/assign-button',
      name: 'PersonalisedReportAssignButton',
      component: AssignButton,
      props: true,
      meta: {
        zone: ['Case_PersonalizedReports_Update'],
      },
    },
  ],
};
