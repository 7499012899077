import ModalAuthoriseCase from '@/components/Lims/modals/ModalAuthoriseCase';
import ModalSelectModeAuthorise from '@/components/Lims/modals/ModalSelectModeAuthorise';
import ModalAuthorisationIssue from '@/components/Lims/modals/ModalAuthorisationIssue';
import { pathCaseService } from '@/services';
import { APP_ROUTES } from '@/core/constants';
import { mapActions, mapGetters } from 'vuex';

const AUTHORIZE_STATUS_CODE = {
  CannotAuthoriseCase: 'CannotAuthoriseCase',
  None: 'None',
  ProvisionallyReported: 'ProvisionallyReported',
  Reported: 'Reported',
  All: 'All',
};

export const REPORT_MODE = {
  onReported: 'onReported',
  onProvisionallyReported: 'onProvisionallyReported',
};

export default {
  components: {
    ModalSelectModeAuthorise,
    ModalAuthoriseCase,
    ModalAuthorisationIssue,
  },
  data: function () {
    return {
      authoriseMode: null,
      authoriseStatus: null,
      isProcessingOnSaveAndAuthorise: false,
    };
  },
  computed: {
    ...mapGetters('caseForm', ['isChangePathologist', 'confictSnomed', 'getCasePersonalizedReportId']),
  },
  methods: {
    ...mapActions('caseForm', ['resetConfictSnomed']),
    checkConfictSnomed() {
      const valueConfictSnomed = Object.values(this.confictSnomed);
      if (valueConfictSnomed && valueConfictSnomed.length > 0) {
        const foundConfictSnomed = valueConfictSnomed.find((element) => element == true);
        return foundConfictSnomed ? true : false;
      } else {
        return false;
      }
    },
    async $onSaveAndAuthorise(caseId) {
      this.isProcessingOnSaveAndAuthorise = true;
      const res = await this.onSaveCase();
      if (res) {
        await this.$onAuthorise(caseId);
      }
      this.isProcessingOnSaveAndAuthorise = false;
    },
    async $onAuthorise(caseId) {
      try {
        const { data, error } = await pathCaseService.checkAuthoriseCase({ caseId });
        if (error) {
          if (error === AUTHORIZE_STATUS_CODE.CannotAuthoriseCase) {
            return this.$alertError(this.$t('pages/case/form/cannotAuthorise'));
          }
          return this.$alertError(error);
        }
        if (data.status === AUTHORIZE_STATUS_CODE.All) {
          const hasPendingAlwSo = data.hasPendingAlwSo;
          return this.$refs.selectModeAuthorise.open(hasPendingAlwSo);
        }
        this.$refs.authorisationIssueModal.setAuthorizedStatusResponse(data);
        this.$refs.authorisationIssueModal.open();
      } catch (errors) {
        this.$alertError(errors);
      }
    },
    $onContinueAuthorise({ authorizedStatusResponse }) {
      const selectedMode =
        authorizedStatusResponse.status === AUTHORIZE_STATUS_CODE.Reported
          ? REPORT_MODE.onReported
          : REPORT_MODE.onProvisionallyReported;
      this.$onSelectedModeAuthorise(selectedMode);
    },
    $onSelectedModeAuthorise(selectedMode) {
      this.$refs.selectModeAuthorise.close();
      this.$refs.authoriseCaseModal.updateFormStatus({
        status: selectedMode,
      });
      this.$refs.authoriseCaseModal.open();
    },
    async $onAuthoriseCase(authoriseCaseFormData) {
      const isConfictSnomed = this.checkConfictSnomed();
      // disabled form before calling api
      this.$refs.authoriseCaseModal.updateFormStatus({
        disabled: true,
      });
      const casePersonalizedReportId = this.getCasePersonalizedReportId;

      // validate data
      if (authoriseCaseFormData.status === REPORT_MODE.onReported) {
        const { err, data } = await pathCaseService.authoriseCase(
          this.caseFormResource.caseId,
          authoriseCaseFormData.password,
          casePersonalizedReportId,
          isConfictSnomed,
        );
        await this.handleAuthoriseCase(err, { data, authoriseCaseFormData });
        return;
      }
      // otherwise
      const { err, data } = await pathCaseService.authoriseProvisionallyCase(
        this.caseFormResource.caseId,
        authoriseCaseFormData.password,
        casePersonalizedReportId,
        isConfictSnomed,
      );
      await this.handleAuthoriseCase(err, { data, authoriseCaseFormData });
    },
    async handleAuthoriseCase(err, { data, authoriseCaseFormData }) {
      this.$refs.authoriseCaseModal.updateFormStatus({
        disabled: false,
      });
      if (err === 'Incorrect username or password.' || err === 'Password attempts exceeded') {
        if (err === 'Password attempts exceeded') {
          this.$alertError(err);
        }
        this.$refs.authoriseCaseModal.updateFormStatus({
          isWrongPassword: true,
          disabled: false,
        });
        return;
      } else if (err) {
        return this.$alertError(err);
      }
      this.$refs.authoriseCaseModal.close();
      this.resetConfictSnomed();
      this.$alertSuccess(
        this.$t(`pages/cases/form/alert/authorise.${authoriseCaseFormData.status}`, {
          caseReference: this.caseFormResource.caseReference,
        }),
      );

      // TODO: later when API works well with multiple pathologists, adjust this
      const nameOfPartnerPathologist = typeof data === 'object' ? data.payload || '' : data;
      if (authoriseCaseFormData.status === REPORT_MODE.onReported && nameOfPartnerPathologist != '') {
        this.$alertSuccess(
          this.$t('pages/cases/form/alert/authorise/doubleReporting', {
            nameOfPartnerPathologist: nameOfPartnerPathologist,
          }),
        );
      }
      if (this.isBulkDiagnosis) {
        this.$emit('updatedCaseInDiagnosisView', this.caseFormResource?.caseId);
      } else {
        setTimeout(async () => {
          await this.$router.push(APP_ROUTES.CASE);
        }, 2000);
      }
    },
  },
};
