<template>
  <md-list :md-expand-single="expandSingle">
    <md-list-item md-expand :md-expanded.sync="expandSlides" class="slide-collapse">
      <span class="md-list-item-text">{{ titleCollapse }}</span>
      <md-list slot="md-expand">
        <md-checkbox :disabled="disabled" v-model="selectAll" class="select-all"> </md-checkbox>
        <md-table md-fixed-header v-model="dataTable" :md-height="200" :class="disabled ? 'md-disabled' : ''">
          <md-table-toolbar>
            <md-field md-clearable>
              <md-input
                :disabled="disabled"
                :placeholder="$t('pages/entity/components/CollapseSearchTbl/placeholder.search')"
                v-model="search"
                @input="searchOnTable"
                tabenable="yes"
              />
            </md-field>
          </md-table-toolbar>
          <md-table-row slot="md-table-row" slot-scope="{ item }">
            <md-table-cell md-label="">
              <md-checkbox :disabled="isDisabledCheckbox(item.fieldItemId)" v-model="item.selected"></md-checkbox>
            </md-table-cell>
            <md-table-cell md-label="Slide">{{ item.fieldItemName }}</md-table-cell>
            <md-table-cell md-label="Action">
              <span v-if="item.selected == true" @click="!disabled ? toggleFavorite(item) : ''">
                <template v-if="item.isFavorite == false"><md-icon>favorite_border</md-icon></template>
                <template v-else><md-icon>favorite</md-icon></template>
              </span>
            </md-table-cell>
          </md-table-row>
        </md-table>
      </md-list>
    </md-list-item>
  </md-list>
</template>
<script>
const toLower = (text) => {
  return text.toString().toLowerCase();
};
const searchByName = (items, term) => {
  if (term && term.length >= 3) {
    return items.filter((item) => toLower(item.fieldItemName).includes(toLower(term)));
  }
  return items;
};

export default {
  props: {
    titleCollapse: {
      type: String,
      require: false,
    },
    disabled: {
      type: Boolean,
      require: false,
      default: false,
    },
    value: {
      type: Array,
      require: true,
    },
    panelAdded: {
      type: Array,
      require: false,
    },
    listPanelSelected: {
      type: Array,
      require: false,
    },
    isCustomStains: {
      type: Boolean,
      require: false,
      default: false,
    },
  },

  computed: {
    selectAll: {
      set(selected) {
        let slideAdded = [];
        this.panelAdded.forEach((element) => {
          element.panelItems.forEach((elChild) => {
            slideAdded.push(elChild);
          });
        });
        if (slideAdded.length > 0) {
          this.dataTable = this.dataTable.filter((x) => !slideAdded.some((y) => x.fieldItemId === y.fieldItemId));
        }
        this.dataTable.forEach(function (val) {
          val.selected = selected;
        });
      },
      get() {
        return this.value.every(function (val) {
          return val.selected;
        });
      },
    },
  },
  data() {
    return {
      search: null,
      expandSlides: false,
      expandSingle: false,
    };
  },
  watch: {
    dataTable: {
      handler(val) {
        val.forEach((element) => {
          if (element.selected == false) {
            element.isFavorite = false;
          }
        });
        this.$emit('input', val);
      },
      deep: true,
    },
    value: {
      handler(val) {
        this.dataTable = val;
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    searchOnTable() {
      this.dataTable = searchByName(this.value, this.search);
    },
    toggleFavorite(item) {
      item.isFavorite = !item.isFavorite;
    },
    isDisabledCheckbox(fieldId) {
      let listPanel = [];
      this.panelAdded.forEach((element) => {
        element.panelItems.forEach((elChild) => {
          listPanel.push(elChild);
        });
      });
      return (
        listPanel.some(function (el) {
          return el.fieldItemId === fieldId;
        }) ||
        this.listPanelSelected.some(function (el) {
          return el.fieldItemId === fieldId;
        })
      );
    },
  },
};
</script>

<style lang="scss">
.slide-collapse {
  margin: 0 10px 20px 0;
  .md-list-item-expand {
    border: none;
  }
  .md-list-item-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #ccc;
    padding: 10px 0 !important;
    .md-list-item-text {
      display: contents;
    }
    &:hover {
      background: unset !important;
    }
    .md-list-expand-icon {
      position: absolute;
      right: 5px;
    }
  }
  .md-list-expand {
    padding: 0 15px;
  }
  .select-all {
    position: relative;
    margin: 0;
    top: 65px;
    left: 15px;
    z-index: 10;
  }
  .md-toolbar {
    min-height: auto;
    padding: 0;
    .md-input {
      margin: 0;
    }
    .md-button {
      margin: 1px;
    }
  }
  .md-table {
    .md-ripple {
      padding: 0 !important;
    }
    .md-table-fixed-header {
      padding-right: 0 !important;
    }
    table {
      width: 100% !important;
      border-collapse: unset;
    }
    .md-table-head {
      padding: 5px !important;
      background: #d2d2d2;
      .md-table-head-label {
        font-size: 15px;
        height: auto;
        font-weight: 400;
      }
      &:last-child {
        width: 100px !important;
        text-align: center;
        .md-table-head-label {
          padding: 0;
        }
      }
    }
    .md-table-cell {
      padding: 7px 5px;
      font-size: 14px;
      height: 39px;
      &:first-child {
        width: 30px;
      }
      &:last-child {
        width: 100px;
        text-align: center;
        padding-left: 23px;
      }
      .md-checkbox {
        margin: 5px 10px;
      }
    }
    .md-table-row {
      &:nth-child(even) {
        background-color: #f2f2f2;
        &.md-selected {
          background-color: #f2f2f2;
        }
      }
      &.md-selected {
        background: unset;
      }
    }
  }
}
</style>
