<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit }">
    <form class="md-layout lims-form" @submit.prevent="handleSubmit()">
      <!-- display validate action message -->
      <template v-if="viewMode && isValidateUser">
        <md-toolbar class="md-dense md-info action-message">
          <div v-for="(item, index) in formData.pendingActionValidations" :key="index">
            <template
              v-if="
                item.pendingActionTypeId == pendingActionType.Created ||
                item.pendingActionTypeId == pendingActionType.EnableUser ||
                item.pendingActionTypeId == pendingActionType.DisableUser
              "
            >
              {{ $t(`global/message/actionMessage`, actionMessageVariable(item)) }}
            </template>
            <template
              v-if="
                item.pendingActionTypeId == pendingActionType.EnableHasLogin ||
                item.pendingActionTypeId == pendingActionType.DisableHasLogin
              "
            >
              {{ $t(`global/message/actionMessage.hasLogin`, actionMessageVariable(item)) }}
            </template>
            <template
              v-if="
                item.pendingActionTypeId == pendingActionType.ChangeEmail ||
                item.pendingActionTypeId == pendingActionType.ChangePhoneNumber
              "
            >
              {{ $t(`global/message/actionMessage.manyActions`, actionMessageVariable(item)) }}
            </template>
          </div>
        </md-toolbar>
      </template>
      <!-- Begin personal information -->
      <lims-block class="personal-info-block">
        <h4 class="title" slot="blockTitle">
          {{ $t('pages/account/user/forms/personalInfoBlock') }}
        </h4>
        <modal-confirmation
          ref="confirmModal"
          slot="blockTitle"
          :is-wrong-password="isWrongPassword"
          @onConfirm="$onConfirmDisabled"
          :isMyEntityView="isMyEntityView"
        ></modal-confirmation>
        <modal-confirm-generic
          slot="blockTitle"
          ref="confirmEnableUserModal"
          :title="$t('pages/myentity/form/confirmEnableUserModal/title')"
          :description="$t('pages/myentity/form/confirmEnableUserModal/content')"
          @onConfirm="$onConfirmEnable"
        >
        </modal-confirm-generic>
        <modal-validate-user
          ref="validateUserModal"
          slot="blockTitle"
          :actionMessage="actionMessageVariable"
          :pendingAction="formData.pendingActionValidations"
          @onApprove="$onApproveValidate"
          @onReject="$onRejectValidate"
        ></modal-validate-user>
        <modal-has-login-confirm
          ref="hasLoginConfirmModal"
          slot="blockTitle"
          @onConfirm="$onHasLoginConfirmModal"
        ></modal-has-login-confirm>
        <modal-view-permissions
          ref="viewPermissions"
          slot="blockTitle"
          :permission-data="roleInformation"
        ></modal-view-permissions>
        <modal-input-your-telephone
          slot="blockTitle"
          ref="inputYourTelephoneModal"
          :user-id="userId"
          :verify-two-fa-mode="verifyTwoFaMode"
          :mobile-phone="telephoneMobileDataInVerify"
          @onNext="onCompleteInputNewTelephone"
        ></modal-input-your-telephone>
        <modal-input-new-email
          slot="blockTitle"
          ref="inputNewEmailModal"
          :user-id="userId"
          @onNext="onCompleteInputNewEmail"
        ></modal-input-new-email>
        <modal-verify-code
          slot="blockTitle"
          ref="verifyCodeModal"
          :option="verifyContent"
          :is-wrong-code="isWrongCode"
          @onVerify="onVerifyCode"
          @onResendCode="onResendVerifyCode"
        ></modal-verify-code>
        <modal-update-successfully
          slot="blockTitle"
          ref="verifySuccessfullyModal"
          :method="verifyMode === onUpdateEmail ? 'Email' : 'Telephone (Mobile)'"
          @onUpdateSuccessfully="onUpdateSuccessfully"
        ></modal-update-successfully>
        <modal-confirm-update-my-entity-user
          slot="blockTitle"
          ref="confirmUpdateMyEntityUserModal"
          :actionChange="dataEditChange"
          @onConfirm="$onConfirmUpdateMyEntityUser"
        ></modal-confirm-update-my-entity-user>
        <modal-confirm-create-new-user-my-entity
          slot="blockTitle"
          ref="confirmCreateNewUserMyEntityModal"
          @onConfirm="$onConfirmCreateNewUserMyEntity"
        ></modal-confirm-create-new-user-my-entity>
        <div slot="blockContent">
          <LimsFormEditButton class="edit-btn" v-if="viewMode && $canEdit()" :editUrl="$editUrl" />
          <!-- new row -->
          <div class="md-layout lims-form-row" v-if="isDefaultView || isMyEntityView">
            <div class="md-layout-item md-size-25 md-small-size-100">
              <lims-field :model="formData.personalInfo" field="userType" :schema="userSchema.personalInfo">
                <v-select disabled slot="field" :options="userTypesList" v-model="formData.personalInfo.userType">
                  <template #option="{ label }">{{ $t(label) }}</template>
                  <template #selected-option="{ label }">{{ $t(label) }}</template>
                </v-select>
              </lims-field>
            </div>
            <div class="md-layout-item md-size-25 md-small-size-100">
              <lims-field :model="formData.personalInfo" :schema="userSchema.personalInfo" field="role">
                <v-select
                  slot="field"
                  v-model="formData.personalInfo.roleId"
                  :options="roleList"
                  :disabled="viewMode"
                  tabenable="yes"
                  :tabindex="1"
                >
                  <template #option="{ label }">{{ label }}</template>
                  <template #selected-option="{ label }">{{ label }}</template>
                </v-select>
              </lims-field>
            </div>
            <div class="md-layout-item md-size-25 md-small-size-100">
              <div
                class="view-permissions"
                @click="onClickViewPermissions"
                :class="formData.personalInfo.roleId ? '' : 'disable-view-permissions'"
              >
                {{ $t('entities/user/personalInfo/viewPermissions') }}
              </div>
            </div>
          </div>
          <!-- end row -->
          <!-- new row -->
          <div class="md-layout lims-form-row">
            <div class="md-layout-item md-size-25 md-small-size-100">
              <lims-field :model="formData.personalInfo" :schema="userSchema.personalInfo" field="username">
                <lims-tooltip
                  slot="label-info"
                  :content="$t('entities/user/personalInfo/userNameTooltip')"
                ></lims-tooltip>
                <md-input
                  slot="field"
                  v-model="formData.personalInfo.username"
                  type="text"
                  :disabled="isEditMode || viewMode"
                  v-on:blur="
                    $onServerSideValidation($event, userSchema._serverSideValidators.username, [
                      () => $lowercase(formData.personalInfo, 'username'),
                    ])
                  "
                  tabenable="yes"
                ></md-input>
                <lims-tooltip
                  slot="icon"
                  :content="$t('entities/user/personalInfo/userNameTooltipRules')"
                ></lims-tooltip>
              </lims-field>
            </div>
            <div class="md-layout-item md-size-25 md-small-size-100">
              <lims-field :model="formData.personalInfo" :schema="userSchema.personalInfo" field="title">
                <v-select
                  slot="field"
                  v-model="formData.personalInfo.titleId"
                  :options="userTitles"
                  :disabled="viewMode"
                  tabenable="yes"
                  :tabindex="3"
                >
                  <template #option="{ label }">{{ label }}</template>
                  <template #selected-option="{ label }">{{ label }}</template>
                </v-select>
              </lims-field>
            </div>
            <div class="md-layout-item md-size-25 md-small-size-100">
              <lims-field :model="formData.personalInfo" :schema="userSchema.personalInfo" field="firstName">
                <md-input
                  slot="field"
                  v-model="formData.personalInfo.firstname"
                  type="text"
                  maxlength="250"
                  :disabled="viewMode"
                  tabenable="yes"
                ></md-input>
              </lims-field>
            </div>
            <div class="md-layout-item md-size-25 md-small-size-100">
              <lims-field :model="formData.personalInfo" :schema="userSchema.personalInfo" field="lastName">
                <md-input
                  slot="field"
                  v-model="formData.personalInfo.lastname"
                  type="text"
                  maxlength="250"
                  :disabled="viewMode"
                  tabenable="yes"
                ></md-input>
              </lims-field>
            </div>
          </div>
          <!-- end row -->
          <!-- new row -->
          <div class="md-layout lims-form-row">
            <div class="md-layout-item md-size-100">
              <lims-field
                :model="formData.personalInfo"
                :schema="userSchema.personalInfo"
                field="address1"
                class="textarea-field"
              >
                <md-textarea
                  slot="field"
                  v-model="formData.personalInfo.address1"
                  :disabled="viewMode"
                  tabenable="yes"
                ></md-textarea>
              </lims-field>
              <lims-field
                :model="formData.personalInfo"
                :schema="userSchema.personalInfo"
                field="address2"
                class="textarea-field"
              >
                <md-textarea
                  slot="field"
                  v-model="formData.personalInfo.address2"
                  :disabled="viewMode"
                  tabenable="yes"
                ></md-textarea>
              </lims-field>
            </div>
          </div>
          <!-- end row -->
          <!-- new row -->
          <div class="md-layout lims-form-row">
            <div class="md-layout-item md-size-25 md-small-size-100">
              <lims-field :model="formData.personalInfo" :schema="userSchema.personalInfo" field="town">
                <md-input
                  slot="field"
                  v-model="formData.personalInfo.town"
                  type="text"
                  maxlength="100"
                  :disabled="viewMode"
                  tabenable="yes"
                ></md-input>
              </lims-field>
            </div>
            <div class="md-layout-item md-size-25 md-small-size-100">
              <lims-field :model="formData.personalInfo" :schema="userSchema.personalInfo" field="country">
                <v-select
                  slot="field"
                  v-model="formData.personalInfo.countryId"
                  :options="countries"
                  :disabled="viewMode"
                  :tabindex="100"
                  tabenable="yes"
                >
                  <template #option="{ label }">{{ label }}</template>
                  <template #selected-option="{ label }">{{ label }}</template>
                </v-select>
              </lims-field>
            </div>
            <div class="md-layout-item md-size-25 md-small-size-100">
              <lims-field :model="formData.personalInfo" :schema="userSchema.personalInfo" field="postCode">
                <md-input
                  slot="field"
                  v-model="formData.personalInfo.postCode"
                  type="text"
                  maxlength="50"
                  :disabled="viewMode"
                  tabenable="yes"
                ></md-input>
              </lims-field>
            </div>
          </div>
          <!-- end row -->
          <!-- new row -->
          <div class="md-layout lims-form-row">
            <div class="md-layout-item md-size-50 md-small-size-100">
              <lims-field :model="formData.personalInfo" :schema="userSchema.personalInfo" field="email">
                <lims-tooltip slot="label-info" :content="$t('entities/user/personalInfo/emailTooltip')"></lims-tooltip>
                <md-input
                  slot="field"
                  v-model="formData.personalInfo.email"
                  type="text"
                  v-on:blur="
                    $onServerSideValidation($event, userSchema._serverSideValidators.email, [
                      () => $lowercase(formData.personalInfo, 'email'),
                    ])
                  "
                  :placeholder="$t('entities/user/personalInfo/email.placeholder')"
                  maxlength="200"
                  :disabled="
                    viewMode ||
                    isMyProfileView ||
                    $getUserMixins().isNotAllowedUpdatePhoneOrEmail() ||
                    $getUserMixins().isNotAllowedUpdatePhoneOrEmailMyEntityMode()
                  "
                  tabenable="yes"
                ></md-input>
              </lims-field>
              <div class="text-underline" v-if="isMyProfileView" @click="onClickUpdateEmail">
                {{ $t('pages/myAccount/profile/update/email') }}
              </div>
            </div>
            <div class="md-layout-item md-size-25 md-small-size-100">
              <lims-field :model="formData.personalInfo" :schema="userSchema.personalInfo" field="telephoneOffice">
                <md-input
                  slot="field"
                  v-model="formData.personalInfo.telephoneOffice"
                  type="text"
                  :placeholder="$t('global/placeholder.telephone')"
                  maxlength="50"
                  :disabled="viewMode"
                  tabenable="yes"
                ></md-input>
              </lims-field>
            </div>
            <div class="md-layout-item md-size-25 md-small-size-100">
              <lims-field :model="formData.personalInfo" :schema="userSchema.personalInfo" field="telephoneMobile">
                <lims-tel-input
                  slot="field"
                  :disabled="
                    viewMode ||
                    isMyProfileView ||
                    $getUserMixins().isNotAllowedUpdatePhoneOrEmail() ||
                    $getUserMixins().isNotAllowedUpdatePhoneOrEmailMyEntityMode()
                  "
                  v-model="telephoneMobileData"
                  @phoneNumberChange="
                    ($event) => $onServerSideValidation($event, userSchema._serverSideValidators.telephoneMobile)
                  "
                ></lims-tel-input>
              </lims-field>
              <div class="text-underline" v-if="isMyProfileView" @click="onClickUpdateTelephone">
                {{ $t('pages/myAccount/profile/update/telephone') }}
              </div>
            </div>
          </div>
          <!-- end row -->
        </div>
      </lims-block>
      <!-- End personal information -->
      <!-- Begin associated clinics -->
      <ValidationProvider slim :name="'clinicAssociate'" :rules="isDefaultView ? 'required' : ''" v-slot="{ failed }">
        <clinic-associate
          :failed="failed"
          :view-mode="viewMode"
          v-model="formData.clinicAssociate"
          :view-type="viewType"
        ></clinic-associate>
      </ValidationProvider>
      <!-- End associated clinics -->
      <!-- option Block MyProfileView-->
      <lims-block v-if="isMyProfileView">
        <h4 class="title" slot="blockTitle">{{ $t('pages/account/user/forms/optionBlock') }}</h4>
        <div slot="blockContent">
          <div class="md-layout lims-form-row">
            <div class="md-layout-item md-size-100 md-small-size-100">
              <div class="pd-top-10">
                <span>{{ $t('entities/user/option/textExport') }}</span>
                <span>{{
                  formData.option.enableTextExport
                    ? $t(formData.option.enableTextExport.label)
                    : $t('entities/user/userEnableTextExportEnum.Disabled')
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </lims-block>

      <!-- Begin notification -->
      <user-notifications
        v-if="formData.notificationSettings"
        v-model="formData.notificationSettings"
        :disabled="viewMode"
        :disabledSMS="!telephoneMobileData.telephoneMobile || telephoneMobileData.telephoneMobile == ''"
      ></user-notifications>

      <!-- option Block DefaultView-->
      <lims-block v-if="isDefaultView">
        <h4 class="title" slot="blockTitle">{{ $t('pages/account/user/forms/optionBlock') }}</h4>
        <div slot="blockContent">
          <div class="md-layout lims-form-row">
            <div class="md-layout-item md-size-50 md-small-size-100">
              <lims-field :model="formData" :schema="userSchema.option" field="enableTextExport">
                <v-select
                  slot="field"
                  v-model="formData.option.enableTextExport"
                  :options="enableTextExportList"
                  :disabled="viewMode"
                  :placeholder="$t('entities/user/option/enableTextExport.placeholder')"
                  :clearable="false"
                  tabenable="yes"
                >
                  <template #option="{ label }">{{ $t(label) }}</template>
                  <template #selected-option="{ label }">{{ $t(label) }}</template>
                </v-select>
              </lims-field>
            </div>
          </div>
        </div>
      </lims-block>

      <!-- Begin login -->
      <lims-block v-if="isDefaultView || isMyEntityView">
        <h4 class="title" slot="blockTitle">{{ $t('pages/account/user/forms/loginBlock') }}</h4>
        <div class="md-layout" slot="blockContent">
          <div class="md-layout lims-form-row">
            <div
              class="md-layout-item md-size-100 md-small-size-100"
              :class="isCheckPendingAction ? 'hightlight-field-validation' : ''"
            >
              <md-checkbox
                v-model="formData.login.hasLogin"
                class="lims-checkbox"
                :disabled="viewMode"
                checked
                tabenable="yes"
              >
                {{ $t('pages/account/user/forms/loginLabel') }}
              </md-checkbox>
            </div>
          </div>

          <div class="md-layout-item md-size-100 md-small-size-100">
            <user-policy-actions
              v-if="!isAddMode"
              :userPolicyActions="userResource.userPolicyActions"
            ></user-policy-actions>
          </div>
          <div v-if="userResource && userResource.lastTimeLogin" class="last-login">
            {{ $t('pages/account/user/forms/lastTimeLogin', { lastTimeLogin: getLastTimeLogin }) }}
          </div>
        </div>
      </lims-block>
      <!-- End login -->
      <!-- Display if the user has not completed their account -->
      <div class="isNotCompleteAccount" v-if="!isMyProfileView">
        <div v-if="!isAddMode && isCheckCompleteAccount">
          {{ $t('pages/account/user/forms/isNotCompleteAccount') }}
        </div>
      </div>
      <!-- Begin action form -->
      <div class="md-layout lims-form-row">
        <div class="md-layout-item md-size-100 md-small-size-100 lims-form-actions">
          <LimsFormCancel :redirectUrl="$redirectUrl()" />
          <md-button v-if="canSave" @click="$onSave()" class="md-button md-primary lims-form-button md-theme-default">
            {{ $t('global/button/button.save') }}
          </md-button>
          <LimsFormEditButton v-if="viewMode && $canEdit()" :editUrl="$editUrl" />
          <md-button
            v-if="!isAddMode && isDisabledUser && !isMyProfileView"
            class="md-primary lims-form-button"
            @click="$onEnableUser"
            >{{ $t('global/button/button.enable') }}</md-button
          >
          <md-button
            v-if="viewMode && isValidateUser && isDefaultView"
            class="md-primary lims-form-button"
            @click="$onOpenModalValidateUser"
            >{{ $t('global/button/button.validate') }}</md-button
          >
          <template v-if="!isMyProfileView">
            <md-button
              v-if="isEditMode && isResetPasswordUser && !isMyProfileView"
              class="md-danger lims-form-button"
              @click="$onResetPassword"
              >{{ $t('global/button/button.resetPassword') }}</md-button
            >
          </template>
          <md-button
            v-if="!viewMode && isEnabledUser && !isMyProfileView"
            class="md-danger lims-form-button"
            @click="$onDisableUser"
            >{{ $t('global/button/button.disable') }}</md-button
          >
        </div>
      </div>
      <!-- End action form -->
    </form>
  </ValidationObserver>
</template>
<script>
import { FormMixins, TabMixins } from '@/core/mixins';
import UserMixins, { UPDATE_TWO_FA_MODE } from '@/pages/Account/User/user.mixins';
import UserRoleMixins from '@/pages/Account/User/user.role.mixins';
import UserService from '@/services/user.service';
import {
  FORM_MODES,
  PENDING_ACTION_TYPE,
  PENDING_ACTION_TYPE_ENUM,
  SELECT_LIST_STATUS,
  USER_ENABLE_TEXT_EXPORT,
  USER_ENABLE_TEXT_EXPORT_ENUM,
} from '@/core/constants';
import { getUserSchema } from '@/schemas/users.schema';
import LimsTelInput from '@/components/Lims/LimsTelInput.vue';
import UserNotifications from '../UserNotifications.vue';
import ModalHasLoginConfirm from '@/components/Lims/modals/ModalHasLoginConfirm.vue';
import ModalViewPermissions from '@/components/Lims/modals/ModalViewPermissions';
import ModalConfirmUpdateMyEntityUser from '@/components/Lims/modals/ModalConfirmUpdateMyEntityUser.vue';
import { MyEntityService } from '@/services';
import { VERIFY_TW0_FA_MODE } from '@/core/constants';
import { ModalInputYourTelephone } from '@/components/Lims/modals';
import { ModalInputNewEmail } from '@/components/Lims/modals';
import { ModalVerifyCode } from '@/components/Lims/modals';
import { ModalUpdateSuccessfully } from '@/components/Lims/modals';
import { MyAccountService } from '@/services';
import { DateTimeHelper } from '@/core/helpers';
import ModalConfirmCreateNewUserMyEntity from '@/components/Lims/modals/ModalConfirmCreateNewUserMyEntity';
import ClinicAssociate from '@/pages/Account/User/Components/ClinicAssociate';

export default {
  components: {
    ModalConfirmCreateNewUserMyEntity,
    ModalViewPermissions,
    LimsTelInput,
    UserNotifications,
    ModalHasLoginConfirm,
    ModalInputYourTelephone,
    ModalInputNewEmail,
    ModalVerifyCode,
    ModalUpdateSuccessfully,
    ModalConfirmUpdateMyEntityUser,
    ClinicAssociate,
  },
  mixins: [FormMixins, UserMixins, UserRoleMixins, TabMixins],
  created() {
    this.fetchData();
  },
  props: {
    userResource: {
      type: Object,
      require: false,
      default: null,
    },
    userId: {
      type: String,
      require: false,
      default: null,
    },
    viewMode: {
      type: Boolean,
      require: false,
      default: null,
    },
  },
  data() {
    return {
      formMode: FORM_MODES.ADD,
      formData: {
        personalInfo: {
          userType: this.USERTYPES_ENUM().find((e) => e.value === this.USER_TYPES().ClinicAssociate),
          userRole: '',
        },
        pendingActionValidations: '',
        login: {
          hasLogin: false,
        },
        notificationSettings: null,
        option: {
          enableTextExport: USER_ENABLE_TEXT_EXPORT_ENUM.find((e) => e.value === USER_ENABLE_TEXT_EXPORT.Disable),
        },
        clinicAssociate: [],
      },
      userTitles: [],
      countries: [],
      roleList: [],
      telephoneMobileData: {},
      telephoneMobileDataInVerify: {},
      roleInformation: null,
      verifyContent: '',
      countryCode: null,
      phoneNumber: null,
      email: null,
      verifyMode: null,
    };
  },
  computed: {
    userSchema: function () {
      return getUserSchema('ClinicAssociate', this.formMode, this.userModel);
    },
    userTypesList() {
      return this.USERTYPES_ENUM();
    },
    pendingActionType() {
      return PENDING_ACTION_TYPE;
    },
    isCheckPendingAction() {
      let isCheck = false;
      if (this.formData.pendingActionValidations && this.formData.pendingActionValidations.length > 0) {
        Object.values(this.userModel.pendingActionValidations).forEach(function (val) {
          if (
            val.pendingActionTypeId == PENDING_ACTION_TYPE.EnableHasLogin ||
            val.pendingActionTypeId == PENDING_ACTION_TYPE.DisableHasLogin
          ) {
            isCheck = true;
          } else {
            isCheck = false;
          }
        });
      }
      return isCheck;
    },
    isCheckActionHasLogin() {
      let isCheck = false;
      if (this.formData.pendingActionValidations && this.formData.pendingActionValidations.length > 0) {
        Object.values(this.userModel.pendingActionValidations).forEach(function (val) {
          if (val.pendingActionTypeId === PENDING_ACTION_TYPE.EnableHasLogin) {
            isCheck = true;
          } else if (val.pendingActionTypeId === PENDING_ACTION_TYPE.DisableHasLogin) {
            isCheck = false;
          } else {
            isCheck = false;
          }
        });
      }
      return isCheck;
    },
    roleIdChange() {
      return this.formData.personalInfo.roleId;
    },
    verifyTwoFaMode() {
      return VERIFY_TW0_FA_MODE.NEW;
    },
    onUpdateEmail() {
      return UPDATE_TWO_FA_MODE.EMAIL;
    },
    enableTextExportList() {
      return USER_ENABLE_TEXT_EXPORT_ENUM.sort((a, b) => b.label - a.label);
    },
  },
  methods: {
    async fetchData() {
      this.countries = await this.loadCountries();
      this.userTitles = this.USER_TITLES();
      if (this.isDefaultView || this.isMyEntityView) {
        this.roleList = await this.loadRoleList(this.USER_TYPES().ClinicAssociate);
      }
      if (this.userResource) {
        this.userModel = this.userResource;
        this.formMode = this.viewMode ? FORM_MODES.VIEW : FORM_MODES.EDIT;
        this.formData = {
          personalInfo: {
            ...this.userModel.personalInfo,
            userType: this.USERTYPES_ENUM().find((e) => e.value === this.USER_TYPES().ClinicAssociate),
            roleId: this.roleList.find((e) => e.value === this.userModel.personalInfo.roleId),
            countryId: this.countries.find((e) => e.value === this.userModel.personalInfo.countryId),
            titleId: this.userTitles.find((e) => e.value === this.userModel.personalInfo.titleId),
          },
          clinicInfo: {
            clinic: this.userModel.clinicInfo,
          },
          pendingActionValidations: this.userModel.pendingActionValidations,
          option: {
            enableTextExport: this.userModel.option.enableTextExport
              ? this.enableTextExportList.find((e) => e.value === this.userModel.option.enableTextExport)
              : this.enableTextExportList.find((e) => e.value === USER_ENABLE_TEXT_EXPORT.Disable),
          },
          login: this.userModel.login
            ? {
                hasLogin: this.userModel.pendingActionValidations
                  ? this.isCheckActionHasLogin
                  : this.userModel.login.hasLogin,
              }
            : null,
          clinicAssociate: this.mappingClinicInfo(this.userModel.clinicsInfo, SELECT_LIST_STATUS.IN_PROGRESS),
          notificationSettings: this.userModel.notificationSettings,
        };

        this.telephoneMobileData = this.$getTelephoneMobileData();
        this.telephoneMobileDataInVerify = this.$getTelephoneMobileDataInVerify();
        this.$setHeadingTitle(this.formData.personalInfo.username);
      }
      this.$nextTick(function () {
        this.$resetChangeDetection();
      });
      this.$setCountryAndMobileCodeFollowGlobalVariablesDataInAddMode();
    },
    createUser() {
      if (this.$isMyEntityView()) {
        return MyEntityService.create({
          userId: this.userId,
          ...this.formDataMyEntity(),
        });
      }
      return UserService.create(this.formDataUser());
    },
    updateUser() {
      //TODO: UPDATE PROFILE PAYLOAD MUST BE CHANGED AFTER API ALREADY UPDATED
      if (this.$isMyProfileView()) {
        return this.updateProfile();
      } else if (this.$isMyEntityView()) {
        return MyEntityService.updateOneMyEntityUser({
          userId: this.userId,
          ...this.formDataMyEntity(),
        });
      }
      return UserService.updateOne({
        userId: this.userId,
        ...this.formDataUser(),
      });
    },
    formDataUser() {
      return {
        personalInfo: {
          ...this.formData.personalInfo,
          roleId: this.formData.personalInfo.roleId.value,
          titleId: this.formData.personalInfo.titleId.value,
          countryId: this.formData.personalInfo.countryId.value,
          countryCodeMobile: this.telephoneMobileData.countryCodeMobile
            ? this.telephoneMobileData.countryCodeMobile.code
            : '',
          localeMobile: this.telephoneMobileData.countryCodeMobile
            ? this.telephoneMobileData.countryCodeMobile.locale
            : '',
          telephoneMobile: this.telephoneMobileData.telephoneMobile,
          countryIdMobile: this.telephoneMobileData.telephoneMobile
            ? this.telephoneMobileData.countryCodeMobile.countryId
            : null,
        },
        clinics: this.mappingClinicAssociateData(),
        option: {
          enableTextExport: this.formData.option.enableTextExport?.value,
        },
        login: {
          hasLogin: this.formData.login.hasLogin,
        },
        notificationSettings: this.formData.notificationSettings,
      };
    },
    formDataMyEntity() {
      return {
        personalInfo: {
          ...this.formData.personalInfo,
          roleId: this.formData.personalInfo.roleId.value,
          userTypeId: this.formData.personalInfo.userType.value,
          titleId: this.formData.personalInfo.titleId.value,
          countryId: this.formData.personalInfo.countryId.value,
          countryCodeMobile: this.telephoneMobileData.countryCodeMobile
            ? this.telephoneMobileData.countryCodeMobile.code
            : '',
          localeMobile: this.telephoneMobileData.countryCodeMobile
            ? this.telephoneMobileData.countryCodeMobile.locale
            : '',
          telephoneMobile: this.telephoneMobileData.telephoneMobile,
          countryIdMobile: this.telephoneMobileData.telephoneMobile
            ? this.telephoneMobileData.countryCodeMobile.countryId
            : null,
        },
        login: {
          hasLogin: this.formData.login.hasLogin,
        },
        notificationSettings: this.formData.notificationSettings,
      };
    },
    updateProfile() {
      return MyAccountService.updateProfile({
        ...this.formData,
        userId: this.userId,
        personalInfo: {
          ...this.formData.personalInfo,
          titleId: this.formData.personalInfo.titleId.value,
          countryId: this.formData.personalInfo.countryId.value,
          telephoneMobile: this.telephoneMobileData.telephoneMobile,
        },
        notificationSettings: this.formData.notificationSettings,
      });
    },
    onClickViewPermissions() {
      if (this.formData.personalInfo.roleId) {
        this.$refs.viewPermissions.open();
      }
    },
    actionMessageVariable(item) {
      const { timezoneValue, dateTimeAppFormat } = this.$store.getters['config/$appConfig'];
      const createdDate = DateTimeHelper.formatDateTimeGlobal(item.createdDate, {
        timezoneValue,
        dateTimeAppFormat,
      });
      return {
        action: item.pendingActionTypeId
          ? this.$t(PENDING_ACTION_TYPE_ENUM.find((e) => e.id === item.pendingActionTypeId)?.text)
          : '',
        updatedValue: this.isCheckActionHasLogin ? 'true' : 'false',
        userType: item.userTypeName,
        userName: item.username,
        newUpdatedValue: item.newUpdatedValue,
        createdDate: createdDate,
      };
    },
    mappingClinicAssociateData() {
      return this.formData.clinicAssociate.map((item, index) => {
        return {
          entityId: item.value,
          displayOrder: index + 1,
        };
      });
    },
  },
};
</script>
<style lang="scss"></style>
