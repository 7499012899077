<template>
  <div>
    <modal-confirm-switch-to-document-viewer-mode
      ref="confirmSwitchToDocumentViewerModeModal"
      @onSaveChanges="onSaveChanges"
      @onDiscardChanges="onDiscardChanges"
      @onClose="onClose"
    >
    </modal-confirm-switch-to-document-viewer-mode>
    <modal-request-view-anonymized
      ref="requestViewAnonymizedModal"
      @onRequest="onConfirmRequestAnonymized"
    ></modal-request-view-anonymized>
    <modal-cancel-request-anonymized
      ref="cancelRequestAnonymizedModal"
      @onCancelRequest="onCancelRequest"
    ></modal-cancel-request-anonymized>
    <modal-search-similar-patient
      ref="searchSimilarPatientModal"
      :case-id="caseFormResource.caseId"
    ></modal-search-similar-patient>
    <div v-if="requestStatus === 'Rejected'" class="anonymise-txt-wrapper">
      <div class="anonymise-txt">
        {{
          $t('pages/case/CaseManagement/rejected.anonymiseTxt', {
            date: formatDateTimeGlobal(caseFormResource.userCaseAnonymizeRequest.createdDate),
          })
        }}
        <br />
        {{ caseFormResource.userCaseAnonymizeRequest.comment }}
        <md-icon>info</md-icon>
      </div>
    </div>
    <div v-if="requestStatus === 'Approved'" class="anonymise-txt-wrapper">
      <div class="anonymise-txt">
        {{
          $t('pages/case/CaseManagement/approved.anonymiseTxt', {
            date: formatDateTimeGlobal(caseFormResource.userCaseAnonymizeRequest.responseOn),
          })
        }}
        <md-icon>info</md-icon>
      </div>
    </div>
    <div v-if="requestStatus === 'Requested'" class="anonymise-txt-wrapper">
      <div class="anonymise-txt">
        {{
          $t('pages/case/CaseManagement/requested.anonymiseTxt', {
            date: formatDateTimeGlobal(caseFormResource.userCaseAnonymizeRequest.createdDate),
          })
        }}
        <br />
        {{ caseFormResource.userCaseAnonymizeRequest.comment }}
        <md-icon>info</md-icon>
      </div>
    </div>
    <div class="gr-button">
      <div class="gr-button-left document-viewer">
        <md-button
          v-if="!isPreLabStatus && !isLabStatus && !isReviewCaseScreen"
          :disabled="isDisable"
          class="md-button lims-form-button md-theme-default"
          @click="onSwitchMode"
        >
          <span v-if="isShowDocumentViewer">{{ $t('pages/case/form/DocumentViewer/hideDocumentViewer') }}</span>
          <span v-else>{{ $t('pages/case/form/DocumentViewer/showDocumentViewer') }}</span>
        </md-button>
      </div>
      <div class="gr-button-middle document-viewer">
        <md-button
          v-if="isShowVirtualSlide"
          @click="onClickVirtualSlide()"
          class="md-button lims-form-button md-theme-default"
        >
          {{ $t('global/button/button.virtualSlide') }}
        </md-button>
      </div>
      <div class="gr-button-right document-viewer">
        <md-button
          v-if="!caseFormResource.anonymiseFields && isPathView"
          @click="onSearchSimilarPatient()"
          class="md-button md-primary lims-form-button md-theme-default"
        >
          {{ $t('pages/case/CaseManagement/btn.searchSimilarPatient') }}
        </md-button>
        <md-button
          v-if="
            $isAuthorized(['Case_AnonymiseInformation-Request_Update']) &&
            caseFormResource.anonymiseFields &&
            requestStatus !== 'Requested'
          "
          @click="onRequestAnonymized()"
          class="md-button md-primary lims-form-button md-theme-default"
        >
          {{ $t('pages/case/CaseManagement/btn.requestToViewUnAnonymizedInfo') }}
        </md-button>
        <md-button
          v-if="$isAuthorized(['Case_AnonymiseInformation-Request_Update']) && requestStatus === 'Requested'"
          @click="onCancelRequestAnonymized()"
          class="md-button lims-form-button md-theme-default md-danger"
        >
          {{ $t('pages/case/CaseManagement/btn.CancelRequestToViewUnAnonymizedInfo') }}
        </md-button>
      </div>
    </div>
    <lims-block v-if="isShowDocumentViewer" id="split-wrapper" class="block-not-title">
      <div slot="blockContent">
        <div class="range-custom">
          <md-radio v-model="rangesliderVal" :value="0" class="position-25">
            <md-icon>format_align_left</md-icon>
          </md-radio>
          <md-radio v-model="rangesliderVal" :value="50" class="position-50">
            <md-icon>format_align_center</md-icon>
          </md-radio>
          <md-radio v-model="rangesliderVal" :value="100" class="position-75">
            <md-icon>format_align_right</md-icon>
          </md-radio>
        </div>
        <div class="md-layout lims-form-row">
          <vue-split :elements="['#slotOne', '#slotTwo']" direction="horizontal" :min-size="0" :gutter-size="10">
            <div id="slotOne" :style="{ width: rangesliderVal + '%' }">
              <document-view :data-edit="caseFormResource" @openNewWindow="rangesliderVal = 0"></document-view>
            </div>
            <div id="slotTwo" class="case-form-block" :style="{ width: 100 - rangesliderVal + '%' }">
              <!-- Begin billing clinic and laboratory block -->
              <lims-block id="billingClinicAndLaboratoryBlock">
                <h4 class="title" slot="blockTitle">
                  {{ $t('pages/case/form/billingClinicAndLaboratory/blockTitle') }}
                </h4>
                <lims-collapse
                  slot="blockContent"
                  :collapse="['Case Collapse']"
                  :is-collapsed="isCollapsedInPathStatusAndDocumentViewerIsOn"
                  icon="keyboard_arrow_down"
                  :expand-collapse-block-name="'billingClinicAndLaboratoryBlock'"
                >
                  <div slot="md-collapse-pane-1">
                    <template v-if="isAdminView">
                      <clinic-and-laboratory
                        :form-mode="formMode"
                        :data-edit="caseFormResource"
                      ></clinic-and-laboratory>
                      <billing-data :form-mode="formMode" :data-edit="caseFormResource"></billing-data>
                    </template>
                    <template v-else>
                      <clinic-and-laboratory-view
                        v-model="formData.clinicAndLaboratory"
                        :form-mode="formMode"
                        :data-edit="caseFormResource"
                      ></clinic-and-laboratory-view>
                      <billing-data-view
                        v-model="formData.billingData"
                        :form-mode="formMode"
                        :data-edit="caseFormResource"
                      ></billing-data-view>
                    </template>
                  </div>
                </lims-collapse>
              </lims-block>
              <!-- End billing clinic and laboratory block -->
              <!-- Begin specimen, pathologist and document block  -->
              <lims-block id="specimenPathologist">
                <h4 class="title" slot="blockTitle">
                  {{ $t('pages/case/form/specimenPathologistAndDocument/blockTitle') }}
                </h4>
                <lims-collapse
                  slot="blockContent"
                  :collapse="['Case Collapse']"
                  :is-collapsed="isCollapsedInPathStatusAndDocumentViewerIsOn"
                  icon="keyboard_arrow_down"
                  :expand-collapse-block-name="'specimenPathologist'"
                >
                  <div slot="md-collapse-pane-1">
                    <template v-if="isAdminView">
                      <specimen-detail :form-mode="formMode" :dataEdit="caseFormResource"></specimen-detail>
                      <pathologist :form-mode="formMode" :dataEdit="caseFormResource"></pathologist>
                    </template>
                    <template v-else>
                      <specimen-detail-view
                        v-if="isSoPathView || isShowDoubleReport"
                        v-model="formData.specimenDetail"
                        :form-mode="formMode"
                        :dataEdit="caseFormResource"
                        :so-path-view="true"
                      ></specimen-detail-view>
                      <specimen-detail v-else :form-mode="formMode" :dataEdit="caseFormResource"></specimen-detail>
                      <pathologist-view
                        v-model="formData.pathologist"
                        :form-mode="formMode"
                        :dataEdit="caseFormResource"
                      ></pathologist-view>
                    </template>
                    <document
                      :so-path-view="isSoPathView"
                      v-model="formData.caseDocuments"
                      :dataEdit="caseFormResource"
                      :form-mode="formMode"
                    ></document>
                  </div>
                </lims-collapse>
              </lims-block>
              <!-- End specimen, pathologist and document block  -->
              <!-- Begin diagnosis histories block -->
              <lims-block
                id="diagnosisHistoryBlock"
                class="diagnosis-block"
                v-if="
                  formMode !== ADD_MODE &&
                  caseFormResource.caseReportHistories &&
                  caseFormResource.caseReportHistories.length > 0 ** !isSoPathView
                "
              >
                <h4 class="title" slot="blockTitle">{{ $t('pages/case/form/diagnosis/blockTitle') }}</h4>
                <lims-collapse
                  slot="blockContent"
                  :collapse="['Case Collapse']"
                  :is-collapsed="isCollapsedInPathStatusAndDocumentViewerIsOn"
                  icon="keyboard_arrow_down"
                >
                  <diagnosis-histories
                    slot="md-collapse-pane-1"
                    :data-edit="caseFormResource"
                    :form-mode="formMode"
                  ></diagnosis-histories>
                </lims-collapse>
              </lims-block>
              <!-- End diagnosis histories block -->
              <!-- Begin diagnosis block -->
              <lims-block
                id="diagnosisBlock"
                class="diagnosis-block"
                v-if="formMode !== ADD_MODE && isPathStatus && !isSoPathView"
              >
                <h4 class="title" slot="blockTitle">{{ $t('pages/case/form/diagnosis/blockTitle') }}</h4>
                <lims-collapse
                  slot="blockContent"
                  :collapse="['Case Collapse']"
                  :is-collapsed="isCollapsedDiagnosisBlock"
                  icon="keyboard_arrow_down"
                  :expand-collapse-block-name="'diagnosisBlock'"
                >
                  <diagnosis
                    slot="md-collapse-pane-1"
                    v-model="formData.diagnosis"
                    :data-edit="caseFormResource"
                    :form-mode="formMode"
                    :is-show-document-viewer="isShowDocumentViewer"
                    @onChangePersonalizedReportId="onChangePersonalizedReportId"
                  ></diagnosis>
                </lims-collapse>
              </lims-block>
              <!-- End diagnosis block -->
              <!-- Begin diagnosis view block -->
              <lims-block
                id="diagnosisViewBlock"
                class="diagnosis-block"
                v-if="(formMode !== ADD_MODE && !isPathStatus) || isSoPathView"
              >
                <h4 class="title" slot="blockTitle">{{ $t('pages/case/form/diagnosis/blockTitle') }}</h4>
                <lims-collapse
                  slot="blockContent"
                  :collapse="['Case Collapse']"
                  :is-collapsed="isCollapsedInPathStatusPathViewAndDocumentViewerIsOn"
                  icon="keyboard_arrow_down"
                  :expand-collapse-block-name="'diagnosisBlock'"
                >
                  <diagnosis-view
                    slot="md-collapse-pane-1"
                    v-model="formData.diagnosis"
                    :data-edit="caseFormResource"
                    :form-mode="formMode"
                    :is-provisionally-reported-status-and-path-view="isProvisionallyReportedStatus && !isSoPathView"
                    :is-reported-status-and-path-view="isReportedStatus && isPathView"
                    :is-show-document-viewer="isShowDocumentViewer"
                  ></diagnosis-view>
                </lims-collapse>
              </lims-block>
              <!-- End diagnosis view block -->
              <!-- Begin double reporting block -->
              <lims-block id="doubleReportingBlock" v-if="isShowDoubleReport && isReportedStatus">
                <h4 class="title" slot="blockTitle">{{ $t('pages/case/form/doubleReporting/blockTitle') }}</h4>
                <lims-collapse
                  slot="blockContent"
                  :collapse="['Case Collapse']"
                  :is-collapsed="isCollapsedInPathStatusAndDocumentViewerIsOn"
                  icon="keyboard_arrow_down"
                  :expand-collapse-block-name="'doubleReportingBlock'"
                >
                  <double-reporting
                    slot="md-collapse-pane-1"
                    v-model="formData.caseDoubleReportResponses"
                    :form-mode="formMode"
                    :dataEdit="caseFormResource"
                  ></double-reporting>
                </lims-collapse>
              </lims-block>
              <!-- End double reporting block -->
              <!-- Begin ALW block  -->
              <lims-block id="ALWBlock">
                <h4 class="title" slot="blockTitle">
                  {{ $t('pages/case/form/ALW/blockTitle') + ' (' + totalNumberOfRequestsALW + ')' }}
                </h4>
                <lims-collapse
                  slot="blockContent"
                  :collapse="['Case Collapse']"
                  :is-collapsed="isCollapsedInPathStatusAndDocumentViewerIsOn"
                  icon="keyboard_arrow_down"
                  :expand-collapse-block-name="'ALWBlock'"
                >
                  <div slot="md-collapse-pane-1">
                    <additional-lab-work
                      :dataEdit="caseFormResource"
                      :so-path-view="isSoPathView"
                      :form-mode="formMode"
                      @totalNumberOfRequests="getTotalNumberOfRequestsALW"
                    ></additional-lab-work>
                  </div>
                </lims-collapse>
              </lims-block>
              <!-- End ALW block  -->
              <!-- Begin SO block  -->
              <lims-block id="SOBlock">
                <h4 class="title" slot="blockTitle">
                  {{ $t('pages/case/form/SO/blockTitle') + ' (' + totalNumberOfRequestsSO + ')' }}
                </h4>
                <lims-collapse
                  slot="blockContent"
                  :collapse="['Case Collapse']"
                  :is-collapsed="isCollapsedInPathStatusAndDocumentViewerIsOn"
                  icon="keyboard_arrow_down"
                  :expand-collapse-block-name="'SOBlock'"
                >
                  <div slot="md-collapse-pane-1">
                    <second-opinion-view v-if="isSoPathView" :dataEdit="caseFormResource"></second-opinion-view>
                    <second-opinion
                      v-else
                      :form-mode="formMode"
                      :dataEdit="caseFormResource"
                      @totalNumberOfRequests="getTotalNumberOfRequestsSO"
                    ></second-opinion>
                  </div>
                </lims-collapse>
              </lims-block>
              <!-- End SO block  -->
              <!-- Begin further options block -->
              <lims-block id="furtherOptionBlock" v-if="!isSoPathView">
                <h4 class="title" slot="blockTitle">{{ $t('pages/case/form/furtherOptions/blockTitle') }}</h4>
                <lims-collapse
                  slot="blockContent"
                  :collapse="['Case Collapse']"
                  :is-collapsed="isCollapsedInPathStatusAndDocumentViewerIsOn"
                  icon="keyboard_arrow_down"
                  :expand-collapse-block-name="'furtherOptionBlock'"
                >
                  <further-options
                    slot="md-collapse-pane-1"
                    v-model="formData.furtherOptions"
                    :form-mode="formMode"
                    :dataEdit="caseFormResource"
                  ></further-options>
                </lims-collapse>
              </lims-block>
              <!-- End further options block -->
              <!-- Begin issue log block -->
              <lims-block id="issueLogBlock" v-if="!isSoPathView">
                <h4 class="title" slot="blockTitle">{{ $t('pages/case/form/issueLog/blockTitle') }}</h4>
                <lims-collapse
                  slot="blockContent"
                  :collapse="['Case Collapse']"
                  :is-collapsed="isCollapsedInPathStatusAndDocumentViewerIsOn"
                  icon="keyboard_arrow_down"
                  :expand-collapse-block-name="'issueLogBlock'"
                >
                  <issue-log slot="md-collapse-pane-1" :form-mode="formMode" :data-edit="caseFormResource"></issue-log>
                </lims-collapse>
              </lims-block>
              <!-- End issue log block -->
            </div>
          </vue-split>
        </div>
      </div>
    </lims-block>
  </div>
</template>

<script>
import { FormMixins } from '@/core/mixins';
import ModalRequestViewAnonymized from '@/components/Lims/modals/ModalRequestViewAnonymized';
import ModalCancelRequestAnonymized from '@/components/Lims/modals/ModalCancelRequestAnonymized';
import { caseFormService } from '@/services';
import LimsCollapse from '@/components/Lims/LimsCollapse';
import DocumentView from '@/pages/Case/CaseManagement/Components/DocumentView';
import ClinicAndLaboratoryView from '@/pages/Case/CaseManagement/Components/ClinicAndLaboratoryView';
import BillingDataView from '@/pages/Case/CaseManagement/Components/BillingDataView';
import BillingData from '@/pages/Case/CaseManagement/Components/BillingData';
import ClinicAndLaboratory from '@/pages/Case/CaseManagement/Components/ClinicAndLaboratory';
import PathologistView from '@/pages/Case/CaseManagement/Components/PathologistView';
import SpecimenDetail from '@/pages/Case/CaseManagement/Components/SpecimenDetail';
import SpecimenDetailView from '@/pages/Case/CaseManagement/Components/SpecimenDetailView';
import Pathologist from '@/pages/Case/CaseManagement/Components/Pathologist';
import Document from '@/pages/Case/CaseManagement/Components/Document';
import DiagnosisView from '@/pages/Case/CaseManagement/Components/DiagnosisView';
import AdditionalLabWork from '@/pages/Case/CaseManagement/Components/AdditionalLabWork';
import SecondOpinion from '@/pages/Case/CaseManagement/Components/SecondOpinion';
import SecondOpinionView from '@/pages/Case/CaseManagement/Components/SecondOpinionView';
import FurtherOptions from '@/pages/Case/CaseManagement/Components/FurtherOptions';
import IssueLog from '@/pages/Case/CaseManagement/Components/IssueLog';
import { CASE_STATUS, DISPLAY_MODE, FORM_MODES } from '@/core/constants';
import ModalSearchSimilarPatient from '@/components/Lims/modals/ModalSearchSimilarPatient';
import { mapActions, mapGetters } from 'vuex';
import DoubleReporting from '@/pages/Case/CaseManagement/Components/DoubleReporting';
import DiagnosisHistories from '@/pages/Case/CaseManagement/Components/DiagnosisHistories';
import Diagnosis from '@/pages/Case/CaseManagement/Components/Diagnosis';
import { newAppEvent } from '@/core/constants';
import { APP_EVENTS } from '@/core/constants';
import store from '@/store';
import ModalConfirmSwitchToDocumentViewerMode from '@/components/Lims/modals/ModalConfirmSwitchToDocumentViewerMode';

export default {
  components: {
    ModalConfirmSwitchToDocumentViewerMode,
    Diagnosis,
    DiagnosisHistories,
    DoubleReporting,
    ModalSearchSimilarPatient,
    IssueLog,
    FurtherOptions,
    SecondOpinion,
    SecondOpinionView,
    AdditionalLabWork,
    DiagnosisView,
    Document,
    Pathologist,
    PathologistView,
    SpecimenDetail,
    SpecimenDetailView,
    BillingData,
    BillingDataView,
    ClinicAndLaboratory,
    ClinicAndLaboratoryView,
    DocumentView,
    ModalCancelRequestAnonymized,
    ModalRequestViewAnonymized,
    LimsCollapse,
  },
  mixins: [FormMixins],
  props: {
    formMode: {
      type: Number,
      validator: function (value) {
        return Object.values(FORM_MODES).indexOf(value) !== -1;
      },
    },
    caseFormResource: {
      type: Object,
      require: true,
    },
    isReviewCaseScreen: {
      type: Boolean,
      require: false,
    },
    isBulkDiagnosis: {
      type: Boolean,
      require: false,
    },
  },
  computed: {
    ...mapGetters('auth', ['userId']),
    ...mapGetters('app/event', [APP_EVENTS.EVT_ON_SHOW_AND_HIDE_VIRTUAL_SLIDE, APP_EVENTS.EVT_ON_SWITCHING_VIEW]),
    ...mapGetters('caseForm', ['isUnsavedChanged', 'isSwitchModeReady']),
    isAdminView() {
      const userType = this.$store.getters['auth/userType'];
      return userType === this.USER_TYPES().Administrator;
    },
    isLabtechView() {
      const userType = this.$store.getters['auth/userType'];
      return userType === this.USER_TYPES().Laboratory;
    },
    isPathView() {
      const userType = this.$store.getters['auth/userType'];
      return userType === this.USER_TYPES().Pathologist;
    },
    caseStatus() {
      return this.caseFormResource.status;
    },
    isPreLabStatus() {
      return this.caseStatus === CASE_STATUS.PRE_LAB;
    },
    isLabStatus() {
      return this.caseStatus === CASE_STATUS.LAB;
    },
    isPathStatus() {
      return this.caseStatus === CASE_STATUS.PATH;
    },
    isProvisionallyReportedStatus() {
      return this.caseStatus === CASE_STATUS.PROVISIONALLY_REPORTED;
    },
    isReportedStatus() {
      return this.caseStatus === CASE_STATUS.REPORTED;
    },
    isSoPathView() {
      return this.caseFormResource.isCaseSoResponder;
    },
    isPartnerDoubleReport() {
      if (
        !Reflect.has(this.caseFormResource, 'caseDoubleReportResponses') ||
        this.caseFormResource.caseDoubleReportResponses.length === 0
      ) {
        return false;
      }
      const caseDoubleReportData = this.caseFormResource.caseDoubleReportResponses[0];
      return caseDoubleReportData.statusName == 'Pending' && caseDoubleReportData.partnerId == this.userId;
    },
    isCollapsedInPathStatusPathViewAndDocumentViewerIsOn() {
      return this.isPathStatus && this.isPathView && this.isShowDocumentViewer;
    },
    isCollapsedInProvisionallyReportedStatusPathViewAndDocumentViewerIsOn() {
      return this.isProvisionallyReportedStatus && this.isPathView && this.isShowDocumentViewer;
    },
    isCollapsedInReportedStatusPathViewAndDocumentViewerIsOn() {
      return this.isReportedStatus && this.isPathView && this.isShowDocumentViewer;
    },
    isCollapsedInPathStatusAndDocumentViewerIsOn() {
      return (
        this.isCollapsedInPathStatusPathViewAndDocumentViewerIsOn ||
        this.isCollapsedInProvisionallyReportedStatusPathViewAndDocumentViewerIsOn ||
        this.isCollapsedInReportedStatusPathViewAndDocumentViewerIsOn
      );
    },
    isCollapsedDiagnosisBlock() {
      return (
        this.isCollapsedInReportedStatusPathViewAndDocumentViewerIsOn ||
        this.isCollapsedInProvisionallyReportedStatusPathViewAndDocumentViewerIsOn
      );
    },
    isShowDoubleReport() {
      const caseDoubleReportData = this.caseFormResource.caseDoubleReportResponses[0];
      if (caseDoubleReportData) {
        return (
          (caseDoubleReportData.statusName == 'Pending' && caseDoubleReportData.partnerId == this.userId) ||
          caseDoubleReportData.statusName != 'Pending'
        );
      } else {
        return false;
      }
    },
  },
  created() {
    if (this.isShowDocumentViewer === null) {
      const isShowDocumentViewer = this.isShowDocumentViewerFirst();
      if (isShowDocumentViewer) {
        const switchMode = this.getToggleSwitchMode(isShowDocumentViewer);
        this.setDisplayMode(switchMode);
      }

      this.isShowDocumentViewer = isShowDocumentViewer;
    }
    this.checkShowVirtualSlideButtonFirstTime();
  },
  watch: {
    [APP_EVENTS.EVT_ON_SHOW_AND_HIDE_VIRTUAL_SLIDE]: {
      deep: true,
      handler: function (val) {
        if (val) {
          this.isShowVirtualSlide = val.isShowVirtualSlide && !this.isPreLabStatus && !this.isLabStatus;
        }
      },
    },
    [APP_EVENTS.EVT_ON_SWITCHING_VIEW]: {
      deep: true,
      handler: function (val) {
        if (val) {
          this.removeEvent(newAppEvent(APP_EVENTS.EVT_ON_SWITCHING_VIEW));
          this.switchViewMode();
        }
      },
    },
    isShowDocumentViewer() {
      this.$emit('changedShowDocumentViewer', this.isShowDocumentViewer);
    },
    isSwitchModeReady: {
      deep: true,
      handler: function (val) {
        if (val == true && this.isDisable) {
          this.$nextTick(() => {
            setTimeout(() => {
              this.isDisable = false;
            }, 2000);
          });
        }
      },
    },
  },
  data() {
    return {
      isDisable: true,
      isShowDocumentViewer: null,
      rangesliderVal: 50,
      totalNumberOfRequestsALW: 0,
      totalNumberOfRequestsSO: 0,
      formData: {
        patientData: {},
        billingData: {},
        clinicAndLaboratory: {},
        specimenDetail: {},
        specimenNumber: null,
        furtherOptions: {},
        pathologist: {},
        diagnosis: {},
      },
      casePersonalizedReportId: null,
      isShowVirtualSlide: false,
    };
  },
  methods: {
    ...mapActions('app/event', ['addEvent', 'removeEvent']),
    ...mapActions('caseForm', ['setDisplayMode']),
    isShowDocumentViewerFirst() {
      const defaultStatus = sessionStorage.getItem('isShowDocumentViewer');
      const showDocumentViewerIsOn = defaultStatus && parseInt(defaultStatus);
      const isShowDocumentViewerOnPathView = !defaultStatus && this.isPathView;
      return (showDocumentViewerIsOn || isShowDocumentViewerOnPathView) && !this.isBulkDiagnosis;
    },
    checkShowVirtualSlideButtonFirstTime() {
      const isHaveVirtualSlide = this.caseFormResource.caseDocuments.find(
        (item) => item.documentTypeName == 'Virtual Slide',
      );
      const isAdminViewOrPathViewFromPathToReportedStatus =
        (this.isAdminView || this.isPathView) && !this.isPreLabStatus && !this.isLabStatus;
      this.isShowVirtualSlide =
        isHaveVirtualSlide && !this.isReviewCaseScreen && isAdminViewOrPathViewFromPathToReportedStatus;
    },
    onSearchSimilarPatient() {
      this.$refs.searchSimilarPatientModal.open();
    },
    onClickVirtualSlide() {
      this.addEvent(
        newAppEvent(APP_EVENTS.EVT_ON_CLICK_VIRTUAL_SLIDE, {
          click: true,
        }),
      );
    },
    onRequestAnonymized() {
      this.$refs.requestViewAnonymizedModal.open();
    },
    onCancelRequestAnonymized() {
      this.$refs.cancelRequestAnonymizedModal.open();
    },
    getTotalNumberOfRequestsALW(val) {
      this.totalNumberOfRequestsALW = val;
    },
    getTotalNumberOfRequestsSO(val) {
      this.totalNumberOfRequestsSO = val;
    },
    async onConfirmRequestAnonymized(comment) {
      const { error, data } = await caseFormService.anonymizeRequests(this.caseFormResource.caseId, comment);
      this.$refs.requestViewAnonymizedModal.isProcessingStatus(false);
      if (error) {
        this.$alertErrorServerSide(error);
      } else {
        if (data) {
          this.$alertSuccess(this.$t('entities/modals/ModalRequestViewAnonymized/requestSuccessfully'));
          setTimeout(async () => {
            await this.$router.go();
          }, 1000);
        }
      }
    },
    async onCancelRequest() {
      const { error, data } = await caseFormService.cancelRequest(this.caseFormResource.caseId);
      if (error) {
        this.$alertErrorServerSide(error);
      } else {
        if (data) {
          this.$alertSuccess(this.$t('components/lims/modals/ModalCancelRequestAnonymized.cancelRequestSuccessfully'));
          setTimeout(async () => {
            await this.$router.go();
          }, 1000);
        }
      }
    },
    onChangePersonalizedReportId(val) {
      this.casePersonalizedReportId = val;
    },
    onSwitchMode() {
      if (this.isDisable) {
        return;
      }
      this.isDisable = true;

      const isUnsavedChanged = store.getters['caseForm/isUnsavedChanged'] || false;

      if (!isUnsavedChanged) {
        // should reload case data to make always have new data
        this.addEvent(
          newAppEvent(APP_EVENTS.EVT_ON_CASE_RELOAD, {
            reload: true,
            switchMode: this.getToggleSwitchMode(!this.isShowDocumentViewer),
          }),
        );
        return;
      }
      // display confirmation modal
      this.$refs.confirmSwitchToDocumentViewerModeModal.viewMode = this.isShowDocumentViewer
        ? 'Normal'
        : 'Document Viewer';
      this.$refs.confirmSwitchToDocumentViewerModeModal.open();
    },
    getToggleSwitchMode(isShowDocumentViewer) {
      return isShowDocumentViewer ? DISPLAY_MODE.DOCUMENT_VIEW : DISPLAY_MODE.NORMAL;
    },
    switchViewMode() {
      this.isShowDocumentViewer = !this.isShowDocumentViewer;
      sessionStorage.setItem('isShowDocumentViewer', this.isShowDocumentViewer ? 1 : 0);
    },
    onSaveChanges() {
      this.$refs.confirmSwitchToDocumentViewerModeModal.close();
      this.addEvent(
        newAppEvent(APP_EVENTS.EVT_ON_TRIGGER_SAVE_CHANGES, {
          switchMode: this.getToggleSwitchMode(!this.isShowDocumentViewer),
        }),
      );
    },
    onDiscardChanges() {
      this.addEvent(
        newAppEvent(APP_EVENTS.EVT_ON_DISCARD_CHANGES, {
          switchMode: this.getToggleSwitchMode(!this.isShowDocumentViewer),
        }),
      );
    },
    onClose() {
      this.isDisable = false;
    },
  },
};
</script>
