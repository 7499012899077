<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit, invalid }">
    <form class="md-layout lims-form form-login" @submit.prevent="handleSubmit()">
      <md-dialog :md-close-on-esc="true" :md-active.sync="isVisible" @md-clicked-outside="close" class="lims-modal">
        <div class="dialog-icon-close" @click="close"><md-icon>close</md-icon></div>
        <md-dialog-title>{{ $t('components/lims/modals/ModalConfirmation.title') }}</md-dialog-title>
        <div class="lims-form dialog-content">
          <p
            class="text-center"
            v-html="
              $t('components/ModalBulkActionRequest.text', {
                bulkAction: $translateBulkActionSelect(bulkActionSelect),
              })
            "
          ></p>
          <ul class="list-cases">
            <li v-for="item in bulkActionItem" :key="item.requestDate">
              {{ item.requesterName }}, {{ item.caseReference }}
            </li>
          </ul>

          <lims-field :model="formData" :schema="schema" field="password">
            <md-input slot="field" v-model="formData.password" type="password"></md-input>
          </lims-field>
        </div>
        <md-dialog-actions>
          <md-button @click="close" class="lims-form-button">{{ $t('global/button/button.cancel') }}</md-button>
          <md-button
            @click="onCompleteAction"
            :disabled="invalid || isProcessing"
            class="lims-form-button md-primary"
            >{{ $t('global/button/button.completeAction') }}</md-button
          >
        </md-dialog-actions>
      </md-dialog>
    </form>
  </ValidationObserver>
</template>

<script>
import modalMixins from './modal.mixins';
import { mapActions } from 'vuex';
import { caseAnonymiseRequestService } from '@/services';

export default {
  name: 'ModalBulkActionRequest',
  mixins: [modalMixins],
  props: {
    bulkActionSelect: {
      require: true,
    },
    bulkActionItem: {
      require: true,
    },
  },
  data() {
    return {
      formData: {
        password: '',
      },
    };
  },
  computed: {
    schema() {
      return {
        entity: 'modals/ModalBulkActionRequest',
        fields: {
          password: 'required',
        },
      };
    },
  },
  methods: {
    ...mapActions('app/event', ['addEvent']),
    async onCompleteAction() {
      this.isProcessing = true;
      let requestIds = [];
      this.bulkActionItem.forEach((item) => {
        requestIds.push(item.caseAnonymiseRequestId);
      });
      const { error, data } = await caseAnonymiseRequestService.bulk(
        requestIds,
        this.bulkActionSelect,
        this.formData.password,
      );
      this.isProcessing = false;
      if (error) {
        this.$alertErrorServerSide(error);
      } else {
        if (data) {
          this.$emit('validated');
        }
      }
    },
    close() {
      this.isVisible = false;
      this.$nextTick(() => {
        this.formData = {
          password: '',
        };
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.list-cases {
  display: table;
  margin: 10px auto;
  padding-left: 0;
}
</style>
