import { APP_ROUTES, LOCAL_STORAGE_KEYS, ZONES } from '@/core/constants';
import { getCookie, getSession, isRestrictedArea } from '@/core/helpers';

import store from '@/store';

const isLoggedIn = () => {
  // user must have access token, permissions, config
  const accessToken = getCookie(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
  const refreshToken = getCookie(LOCAL_STORAGE_KEYS.REFRESH_TOKEN);
  const permissions = getSession(LOCAL_STORAGE_KEYS.PERMISSIONS);
  const config = getSession(LOCAL_STORAGE_KEYS.CONFIG);
  const user = getSession(LOCAL_STORAGE_KEYS.USER);
  const isLogged = accessToken && refreshToken && permissions && config && user;
  if (isLogged && !store.getters['auth/isLogged']) {
    store.dispatch('auth/DO_LOGIN', {
      accessTokenData: { accessToken, refreshToken },
      user: {
        ...user,
      },
    });
  }
  return isLogged;
};

const canAccessPrivateZone = (specifiedZone = '') => {
  // check state
  //store.dispatch('auth/CHECK_USER_COOKIE');
  //const isLogged = store.getters['auth/isLogged'] || false;
  const isLogged = isLoggedIn();

  if (!isLogged) {
    return { unAuthorized: true, forBidden: false };
  }
  try {
    if (!specifiedZone) {
      return { unAuthorized: false, forBidden: true };
    }
    // CHECK STATE FROM STORE, if not existed check cookie and redirect user to waiting page
    if (specifiedZone === 'private') {
      // private zone without public
      return { unAuthorized: false, forBidden: false };
    }

    if (isRestrictedArea({ permissions: specifiedZone, userPermissions: store.getters['auth/permissions'] })) {
      return { unAuthorized: false, forBidden: false };
    }
    // check permission for specific zone
    return { unAuthorized: false, forBidden: true };
  } catch (error) {
    return { unAuthorized: false, forBidden: true };
  }
};
const canAccessPublicZone = () => {
  const isLogged = isLoggedIn();
  // const isLogged = store.getters['auth/isLogged'] || false;
  return !isLogged;
};

export default {
  /*
   * User can only access private zone if is authorized
   **/
  checkAuthorizedGuard: (to, from, next) => {
    const zonesToBeChecked = to.matched.filter((element) => {
      return element.meta.zone != undefined;
    });

    if (zonesToBeChecked.length === 0) {
      next();
      return;
    }
    const authorization = { unAuthorized: false, forBidden: false, isPublicZone: false };
    zonesToBeChecked.every((zoneToBeChecked) => {
      if (!zoneToBeChecked.meta.zone) {
        // break loop
        return false;
      }
      if (zoneToBeChecked.meta.zone === ZONES.PUBLIC) {
        authorization.isPublicZone = true;
        // continue checking subzone
        return true;
      }
      // otherwise
      const { unAuthorized, forBidden } = canAccessPrivateZone(zoneToBeChecked.meta.zone);
      if (unAuthorized) {
        authorization.unAuthorized = unAuthorized;
        return false;
      }
      if (forBidden) {
        authorization.forBidden = forBidden;
        return false;
      }
      return true;
    });

    if (authorization.unAuthorized) {
      store.dispatch('auth/DO_LOGOUT');
      return next({
        path: APP_ROUTES.AUTH_LOGIN,
        query: {
          redirectTo: to.fullPath || to.path,
        },
      });
    }
    if (authorization.forBidden) {
      return next(APP_ROUTES.ERROR_FORBIDDEN);
    }
    if (authorization.isPublicZone) {
      return canAccessPublicZone() ? next() : next(APP_ROUTES.DASHBOARD);
    }
    // default
    next();
  },
};
