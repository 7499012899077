<template>
  <div @mouseover="onHoverTooltip">
    <md-icon>info</md-icon>
    <md-tooltip mdDirection="top" class="about-tooltip" v-html="aboutInformationData"></md-tooltip>
  </div>
</template>

<script>
import { MyEntityService, UserService } from '@/services';

export default {
  name: 'AboutInformation',

  props: {
    userIdOfItem: {
      type: String,
      require: true,
    },

    isClinicianItem: {
      type: Boolean,
      require: true,
    },

    isMyEntityView: {
      type: Boolean,
      require: false,
    },
  },

  data() {
    return {
      aboutInformationData: null,
    };
  },

  methods: {
    async onHoverTooltip() {
      if (!this.aboutInformationData) {
        let res;
        if (this.isMyEntityView) {
          res = await MyEntityService.getUserAboutInformation(this.userIdOfItem);
        } else {
          res = await UserService.getUserAboutInformation(this.userIdOfItem);
        }

        if (res.err) {
          return this.$alertError('Err +', res.error);
        }

        this.aboutInformationData = res.data;
        this.convertDataFromApiToDisplayAboutTooltip();
      }
    },

    convertDataFromApiToDisplayAboutTooltip() {
      if (this.isClinicianItem) {
        let specialityOfClinicianItemIsNull = this.aboutInformationData.additionalInformation === '';

        if (specialityOfClinicianItemIsNull) {
          this.aboutInformationData.additionalInformation += 'Clinic(Dummy)';
        } else {
          this.aboutInformationData.additionalInformation += ', Clinic(Dummy)';
        }
      }

      switch (this.aboutInformationData.pendingActionType) {
        case 1:
          this.aboutInformationData.pendingActionInfo = this.$t('pages/Account/Role/list/about/tooltip/label.create');
          break;
        case 2:
          this.aboutInformationData.pendingActionInfo = this.$t('pages/Account/Role/list/about/tooltip/label.enable');
          break;
        case 3:
          this.aboutInformationData.pendingActionInfo = this.$t('pages/Account/Role/list/about/tooltip/label.disable');
          break;
        case 4:
          this.aboutInformationData.pendingActionInfo = this.$t(
            'pages/Account/Role/list/about/tooltip/label.updateHasLogin',
          );
          break;
        case 5:
          this.aboutInformationData.pendingActionInfo = this.$t(
            'pages/Account/Role/list/about/tooltip/label.updateHasLogin',
          );
          break;
        case 6:
          this.aboutInformationData.pendingActionInfo = this.$t(
            'pages/Account/Role/list/about/tooltip/label.updateEmailValue',
          );
          break;
        case 7:
          this.aboutInformationData.pendingActionInfo = this.$t(
            'pages/Account/Role/list/about/tooltip/label.updateTelephoneValue',
          );
          break;
      }
      if (this.isMyEntityView) {
        this.aboutInformationData = this.$t('pages/MyEntity/user/list.about.tooltip', {
          role: this.aboutInformationData.role,
          pendingActionInfo: this.aboutInformationData.pendingActionInfo,
        });
      } else {
        this.aboutInformationData = this.$t('pages/account/user/list.about.tooltip', {
          role: this.aboutInformationData.role,
          additionalInformation: this.aboutInformationData.additionalInformation,
          pendingActionInfo: this.aboutInformationData.pendingActionInfo,
        });
      }
    },
  },
};
</script>
