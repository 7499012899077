<template>
  <div>
    <modal-document-viewer-resize
      ref="documentViewerResizeModal"
      @onResize="onResizeDocumentViewer"
    ></modal-document-viewer-resize>
    <div class="tab-container">
      <label>
        <input type="radio" name="tab" />
        <span>{{ $t('pages/case/CaseManagement/components/DocumentViewer/tabSlide') }}</span>
      </label>
      <label>
        <input type="radio" name="tab" checked />
        <span>{{ $t('pages/case/CaseManagement/components/DocumentViewer/tabDocument') }}</span>
      </label>

      <div class="tab-content">
        <div class="tab-content-item document-item" id="c1">
          <div v-if="slideList.length > 0" style="height: 100%">
            <div class="select-document">
              <v-select :key="rowVersion" :options="slideList" label="title" v-model="slideItem">
                <template #option="{ title }">{{ title }}</template>
                <template #selected-option="{ title }">{{ title }}</template>
              </v-select>
            </div>
            <div class="slide-cont">
              <!-- <iframe :src="slideItem ? slideItem.link : ''" width="100%" height="100%"></iframe> -->
              <p class="staticTextInSlideView">
                {{ $t('pages/case/CaseManagement/components/DocumentViewer/staticTextInSlideView') }}
              </p>
            </div>
            <div class="control-button">
              <div class="icon-control" @click="onOpenBrowserTab(slideItem.link)"><md-icon>north_east</md-icon></div>
            </div>
          </div>
          <div v-else class="no-slide">{{ $t('pages/case/CaseManagement/components/DocumentViewer/noSlide') }}</div>
        </div>
        <div class="tab-content-item document-item" id="c2">
          <div class="select-document">
            <v-select
              :key="rowVersion"
              :options="documentList"
              label="fileName"
              v-model="documentItem"
              @input="valDocumentView"
            >
              <template #option="{ title }">{{ title }}</template>
              <template #selected-option="{ title }">{{ title }}</template>
            </v-select>
          </div>
          <div class="document-cont">
            <iframe :src="$myPdfViewer(documentFile)" width="100%" height="100%"></iframe>
          </div>
          <div class="control-button">
            <div class="icon-control" @click="onOpenBrowserTab(documentFile.fileUrl)">
              <md-icon>north_east</md-icon>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { pathCaseService } from '@/services';
import ModalDocumentViewerResize from '@/components/Lims/modals/ModalDocumentViewerResize.vue';
import { mapActions, mapGetters } from 'vuex';
import { APP_EVENTS, DOCUMENT_ATTACH_TYPES, DOCUMENT_TYPE_NAME, newAppEvent } from '@/core/constants';
import { cloneDeep } from 'lodash';

// const extractDocumentIds = (documents) => {
//   // there are 2 scenarios: document already saved/document just uploaded to temp directory
//   // document already saved: caseDocumentId
//   // document just uploaded to temp directory: item.attachFile.fileNameInStore
//   const documentIds = [];
//   documents.map((item) => {
//     const caseDocumentId = item.caseDocumentId ? item.caseDocumentId : null;
//     const fileNameInStore =
//       item.attachFile && item.attachFile?.fileNameInStore ? item.attachFile.fileNameInStore : null;
//     if (caseDocumentId) {
//       documentIds.push(caseDocumentId);
//     }
//     if (fileNameInStore) {
//       documentIds.push(fileNameInStore);
//     }
//   });
//   return documentIds;
// };

// const isDocumentListDifference = (listA, listB) => {
//   const listAIds = extractDocumentIds(listA);
//   const listBIds = extractDocumentIds(listB);
//   if (listAIds.length != listBIds.length) {
//     return true;
//   }
//   listAIds.map((id) => {
//     if (!listBIds.includes(id)) {
//       return true;
//     }
//   });
//   listBIds.map((id) => {
//     if (!listAIds.includes(id)) {
//       return true;
//     }
//   });
//   return false;
// };

export default {
  components: {
    ModalDocumentViewerResize,
  },
  props: {
    dataEdit: {
      type: Object,
      require: false,
    },
    noResizePopup: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    [APP_EVENTS.EVT_ON_RELOAD_DOCUMENT_VIEW]: {
      deep: true,
      handler: async function (val) {
        if (val) {
          await this.fetchData(cloneDeep(val.caseData));
          this.removeEvent(newAppEvent(APP_EVENTS.EVT_ON_RELOAD_DOCUMENT_VIEW));
        }
      },
    },
  },
  created() {
    this.fetchData(this.dataEdit);
  },
  data() {
    return {
      rowVersion: null,
      documentList: [],
      documentItem: null,
      documentFile: null,
      slideList: [],
      slideItem: null,
      radio: 50,
      dataDocumentInState: [],
    };
  },
  computed: {
    ...mapGetters('caseData', ['caseData']),
    ...mapGetters('app/event', [APP_EVENTS.EVT_ON_RELOAD_DOCUMENT_VIEW]),
  },
  methods: {
    ...mapActions('app/event', ['removeEvent']),
    async fetchData(dataEdit) {
      if (dataEdit && dataEdit.caseDocuments) {
        this.rowVersion = dataEdit.rowVersion;
        const slideList = [];
        let documentFile = null;
        let documentItem = null;
        const documents = dataEdit.caseDocuments.filter((element) => {
          if (
            element.link &&
            element.link.length > 0 &&
            element.documentTypeName === DOCUMENT_TYPE_NAME.VIRTUAL_SLIDE
          ) {
            slideList.push(element);
          }
          return (
            (element.format == 'pdf' ||
              element.format == 'PDF' ||
              element.format == 'jpeg' ||
              element.format == 'JPEG' ||
              element.format == 'jpg' ||
              element.format == 'JPG' ||
              element.format == 'png' ||
              element.format == 'PNG' ||
              element.format == 'bmp' ||
              element.format == 'BMP' ||
              element.format == 'gif' ||
              element.format == 'GIF') &&
            // only preview pictures and documents
            [DOCUMENT_ATTACH_TYPES.DOCUMENT, DOCUMENT_ATTACH_TYPES.PICTURE].includes(element.documentAttachTypeName)
          );
        });

        // always update slideList
        this.slideList = slideList;
        this.slideItem = slideList.length > 0 ? this.slideList[0] : '';

        // if (!isDocumentListDifference(this.documentList, documents)) {
        //   return;
        // }

        if (documents.length && documents.length > 0) {
          // firstDocument should be Request Form
          const firstRequestForm = documents.find((d) => d.documentTypeName === DOCUMENT_TYPE_NAME.REQUEST_FORM);
          if (firstRequestForm) {
            documentFile = await this.getFileDocument(firstRequestForm);
            documentItem = firstRequestForm;
          } else {
            documentFile = await this.getFileDocument(documents[0]);
            documentItem = documents[0];
          }
        }
        // set component's data
        this.documentList = documents;
        this.documentFile = documentFile;
        this.documentItem = documentItem;
      }
    },
    async valDocumentView(val) {
      this.documentFile = await this.getFileDocument(val);
    },

    async getFileDocument(item) {
      const res = await pathCaseService.getCaseDocuments({
        caseDocumentId: item.caseDocumentId ? item.caseDocumentId : null,
        fileNameInStore: item.attachFile && item.attachFile?.fileNameInStore ? item.attachFile.fileNameInStore : null,
      });
      const fileUrl = res.data;

      if (item.format === 'pdf') {
        return {
          fileUrl,
          link: pathCaseService.getCaseDocumentLink({
            caseDocumentId: item.caseDocumentId ? item.caseDocumentId : null,
            fileNameInStore:
              item.attachFile && item.attachFile?.fileNameInStore ? item.attachFile.fileNameInStore : null,
          }),
          format: item.format,
        };
      }

      return {
        link: fileUrl,
        fileUrl,
        format: item.format,
      };
    },
    onOpenBrowserTab(val) {
      window.open(val, '_blank').focus();
      if (!this.noResizePopup) {
        this.$refs.documentViewerResizeModal.open();
      }
    },
    onResizeDocumentViewer() {
      this.$emit('openNewWindow', true);
    },
  },
};
</script>
