<template>
  <div>
    <modal-confirm-generic
      ref="unSaveChangesModal"
      :description="$t('components/lims/modals/ModalFormCancel.text')"
      @onConfirm="onUnSaveChanges"
    />
    <component
      v-if="userType && userResource"
      v-bind:is="activeUserType"
      :userResource="userResource"
      :userId="userId"
      :userTypeName="userTypeName"
      :viewType="viewType"
      :viewMode="isView"
    ></component>
  </div>
</template>

<script>
import ClinicAssociate from '@/pages/Account/User/Forms/ClinicAssociate/ClinicAssociate';
import LabTechnician from '@/pages/Account/User/Forms/LabTechnician/LabTechnician';

import { userTypeIdToComponent } from '@/core/helpers';
import { USER_PROFILE_VIEW_TYPES } from '@/pages/Account/User/user.mixins';
import { MyEntityService } from '@/services';

import { UnSaveChangesMixins } from '@/core/mixins';

const ACTIVE_USER_TYPES = {
  clinicAssociate: ClinicAssociate,
  labTechnician: LabTechnician,
};

export default {
  mixins: [UnSaveChangesMixins],
  props: {
    userId: {
      require: true,
    },
    isView: {
      type: Boolean,
      required: false,
      default: () => {
        return false;
      },
    },
  },
  component: {
    ClinicAssociate,
    LabTechnician,
  },
  computed: {
    userTypeName() {
      return this.userType;
    },
    activeUserType() {
      return ACTIVE_USER_TYPES[this.userType];
    },
    viewType() {
      return USER_PROFILE_VIEW_TYPES.MY_ENTITY;
    },
  },
  data: function () {
    return {
      userResource: {},
      userType: false,
    };
  },
  watch: {
    userId(val, oldVal) {
      if (val !== oldVal) {
        this.userResolver(val);
      }
    },
  },
  methods: {
    async userResolver(userId) {
      if (userId) {
        const { data } = await MyEntityService.findOne(userId);
        if (data) {
          this.userResource = data;
          this.userType = userTypeIdToComponent(this.USER_TYPES(), data.personalInfo.userTypeId);
        }
      }
    },
  },
  created() {
    this.userResolver(this.userId);
  },
};
</script>

<style lang="scss" scoped></style>
