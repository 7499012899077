<template>
  <div>
    <md-content class="lims-form">
      <div class="md-theme-default md-layout">
        <div class="md-layout-item md-layout-item-rf">
          <label
            >{{ $t('pages/case/CaseManagement/component/RFUpload/label.linkedRf') }}:
            {{ itemLinkedCases.length }}</label
          >
        </div>
      </div>
      <div class="md-theme-default md-layout">
        <div
          v-for="(item, index) in itemLinkedCases"
          :key="index"
          class="md-layout-item md-size-20 md-small-size-50 md-xsmall-size-100 md-medium-size-33"
        >
          <md-card>
            <md-card-content>
              <div class="md-card-content-case-rf-upload">
                <div class="md-card-title-case-ref lims-text-success">
                  <i>{{ item.caseReference }}</i>
                </div>
                <div class="lims-text-success">
                  <i>{{ item.fileNameUpload }}</i>
                </div>
                <div>
                  <i>{{ $t('pages/case/CaseManagement/component/RFUpload/label.labRef') }}: {{ item.labReference }}</i>
                </div>
                <div>
                  <i>{{ $t('pages/case/CaseManagement/component/RFUpload/label.lab') }}: {{ item.laboratoryName }}</i>
                </div>
                <div>
                  <i
                    >{{ $t('pages/case/CaseManagement/component/RFUpload/label.clinician') }}:
                    {{ item.clinicianName }}</i
                  >
                </div>
                <div>
                  <i>{{ $t('pages/case/CaseManagement/component/RFUpload/label.hospital') }}: {{ item.clinicName }}</i>
                </div>
                <div>
                  <i>{{ $t('pages/case/CaseManagement/component/RFUpload/label.path') }}: {{ item.pathologistName }}</i>
                </div>
              </div>
              <md-checkbox
                v-model="disabled"
                class="lims-checkbox"
                :disabled="true"
                style="position: absolute; right: 0 !important; top: 0"
              ></md-checkbox>
            </md-card-content>
          </md-card>
        </div>
      </div>
      <div class="md-layout lims-form-row">
        <div class="md-layout-item md-size-100 md-small-size-100 lims-form-rf-upload-actions">
          <md-button @click="onCancel()" class="lims-form-button md-theme-default">
            {{ $t('pages/case/CaseManagement/component/RFUpload/button.backToCaseList') }}
          </md-button>
          <md-button
            @click="onContinueUpload()"
            class="md-primary lims-form-button md-theme-default md-theme-default"
            :disabled="itemUnLinkedCases.length === 0"
          >
            {{ $t('pages/case/CaseManagement/component/RFUpload/button.continueUpload') }}
          </md-button>
        </div>
      </div>
    </md-content>
  </div>
</template>

<script>
import { FormMixins } from '@/core/mixins';
import { APP_ROUTES } from '@/core/constants';

export default {
  mixins: [FormMixins],
  created: function () {
    this.initState();
  },

  data() {
    return {
      formData: {},
      numOfLinkedRF: 0,
      selectedRows: [],
      itemLinkedCases: [],
      itemUnLinkedCases: [],
      disabled: true,
    };
  },

  methods: {
    async initState() {
      const itemLinkedCases = JSON.parse(sessionStorage.getItem('selectedFileLinkToCase'));
      this.itemLinkedCases = itemLinkedCases;
      const itemUnLinkedCases = JSON.parse(sessionStorage.getItem('unSelectedFileLinkToCase'));
      this.itemUnLinkedCases = itemUnLinkedCases;
    },
    onContinueUpload() {
      sessionStorage.removeItem('caseRfFileUpload');
      sessionStorage.setItem('caseRfUpload', JSON.stringify(this.itemUnLinkedCases));
      this.$router.push({ path: APP_ROUTES.RF_UPLOAD_CASE });
    },
    onCancel() {
      this.$resetChangeDetection();
      setTimeout(() => {
        this.$router.push({ path: '/cases' });
      }, 500);
    },
  },
};
</script>

<style lang="scss">
.md-layout {
  .md-layout-item-rf {
    margin: 15px;
  }
}
</style>
