<template>
  <div>
    <lims-block :cardText="false" class="list-page">
      <modal-delete-dropdown-item ref="deleteDropdownItem" slot="blockTitle" @onYes="onConfirmDeleteDropdownItem">
      </modal-delete-dropdown-item>
      <div class="md-layout" slot="blockContent">
        <div class="md-layout-item" v-if="query">
          <item-list :items="items" @onClickAction="onHandleAction" @onDragAction="onHandleDragAction">
            <md-table-toolbar slot="toolbar">
              <div class="toolbar-row">
                <div class="dropdownName">{{ dropdownName }}</div>
                <md-field class="field-btn">
                  <md-button v-if="$isAuthorized(['Data_DropdownManagement_Create'])" @click="onClickAddItem">{{
                    $t('pages/Data/Dropdown/ItemList/Item/button.add')
                  }}</md-button>
                </md-field>
              </div>
              <div class="toolbar-row">
                <div class="field-rows-per-page">
                  <lims-row-per-page :pageSize="query.pagination.pageSize" @pageSizeChanged="$pageSizeChanged" />
                </div>
                <div class="field-search">
                  <item-list-filter
                    v-model="query.filters"
                    :defaultValues="defaultValues"
                    :fields="fields"
                    @onResetFilters="onResetFilters"
                  ></item-list-filter>
                </div>
              </div>
              <lims-pagination ref="pager" :total-item="totalItem" :pagination="query.pagination"></lims-pagination>
            </md-table-toolbar>
          </item-list>
          <lims-pagination ref="pager" :total-item="totalItem" :pagination="query.pagination"></lims-pagination>
        </div>
      </div>
    </lims-block>
    <!-- Begin action form -->
    <div class="action-btn">
      <md-button class="lims-form-button" @click="onBack">
        {{ $t('global/button/button.cancel') }}
      </md-button>
    </div>
  </div>
</template>

<script>
import LimsRowPerPage from '@/components/Lims/LimsRowPerPage';
import LimsPagination from '@/components/Lims/LimsPagination';
import { ModalDeleteDropdownItem } from '@/components/Lims/modals';
import { FORM_ACTIONS, NUMBER_OF_CHARACTERS_SEARCH } from '@/core/constants';
import ItemList from '@/pages/Data/Dropdown/ItemList/ItemList';
import ItemListFilter from '@/pages/Data/Dropdown/ItemList/ItemListFilter';
import { APP_ROUTES } from '@/core/constants';
import { DropdownService } from '@/services';

import { dropDownItemQuery } from '@/query';
import { ConfigMixins, ListWithFilterMixins } from '@/core/mixins';
import cloneDeep from 'lodash/cloneDeep';
import { buildFilterFromQuery, buildFilterQueryParams, buildSortQueryParams } from '@/query/utils';

export default {
  components: {
    ModalDeleteDropdownItem,
    ItemListFilter,
    ItemList,
    LimsPagination,
    LimsRowPerPage,
  },

  props: {
    dropdownFieldId: {
      type: String,
      require: true,
      default: null,
    },
  },

  mixins: [ConfigMixins, ListWithFilterMixins],

  data() {
    return {
      items: [],
      totalItem: null,
      query: null,
      deletedItemId: null,
      deletedItemName: null,
      dropdownName: '',
    };
  },

  computed: {
    fields() {
      return dropDownItemQuery.filters.fields;
    },
    defaultValues() {
      return dropDownItemQuery.filters.defaultValues;
    },
  },

  created() {
    this.initState();
    this.getDropdownName();
  },

  methods: {
    // common methods
    initState() {
      const queryParams = this.$route.query;
      const filters = buildFilterFromQuery(dropDownItemQuery.filters, queryParams);
      const pagination = buildFilterFromQuery(dropDownItemQuery.pagination, {
        pageSize: this.$appConfig.itemPerPageDefault,
        ...queryParams,
      });
      const sort = buildFilterFromQuery(dropDownItemQuery.sort, queryParams);
      const query = { filters, pagination, sort };
      this.query = query;
    },

    async search(queryObject) {
      const queryObjNew = cloneDeep(queryObject);
      if (queryObjNew.filters.search.length > 0 && queryObjNew.filters.search.length < NUMBER_OF_CHARACTERS_SEARCH) {
        return;
      }
      const { error, data } = await DropdownService.searchItemList({
        fieldId: this.dropdownFieldId,
        filters: buildFilterQueryParams(queryObject.filters),
        pagination: buildFilterQueryParams(queryObject.pagination),
        ...buildSortQueryParams(queryObject.sort),
      });
      // detect error and show alert to user
      if (error) {
        this.$alertError(error);
        // remove loading screen and allow user to interact
        return;
      }

      // binding data
      this.items = data.items;
      this.totalItem = data.pagination.totalItems;

      // update router
      const query = {
        ...buildFilterQueryParams(queryObject.filters),
        ...buildFilterQueryParams(queryObject.pagination),
        ...buildFilterQueryParams(queryObject.sort),
      };

      this.$router
        .push({
          query,
        })
        .catch((error) => {
          if (
            error.name === 'NavigationDuplicated' &&
            error.message.includes('Avoided redundant navigation to current location')
          ) {
            history.pushState({}, '', new URL(new URL(window.location)));
          }
        });
    },
    onResetFilters() {
      this.query.filters = cloneDeep(dropDownItemQuery.filters.defaultValues);
    },

    onHandleAction(action, item) {
      if (action === FORM_ACTIONS.EDIT) {
        this.editRowItem(item);
      }

      if (action === FORM_ACTIONS.DELETE) {
        this.deleteRowItem(item);
      }

      if (action === FORM_ACTIONS.VIEW) {
        this.viewRowItem(item);
      }
    },

    onClickAddItem() {
      this.$router.push(`${APP_ROUTES.DROPDOWN}/${this.dropdownFieldId}/add`);
    },

    viewRowItem(item) {
      this.$router.push(`${APP_ROUTES.DROPDOWN}/${this.dropdownFieldId}/view/${item.itemId}`);
    },

    editRowItem(item) {
      this.$router.push(`${APP_ROUTES.DROPDOWN}/${this.dropdownFieldId}/edit/${item.itemId}`);
    },

    async deleteRowItem(item) {
      this.deletedItemId = item.itemId;
      this.deletedItemName = item.itemName;
      this.$refs.deleteDropdownItem.open();
    },

    async onHandleDragAction(indexOfDragEvent) {
      const getItemIdFromIndexOfDragEvent = {
        fieldItemIdSourceId: this.items[indexOfDragEvent.oldIndex].itemId,
        fieldItemIdDestId: this.items[indexOfDragEvent.newIndex].itemId,
      };

      const { error } = await DropdownService.reOrderItem(getItemIdFromIndexOfDragEvent);

      if (error) {
        return this.$alertError(error);
      }

      await this.search(this.query);
    },

    async onConfirmDeleteDropdownItem() {
      this.$refs.deleteDropdownItem.close();
      const { error } = await DropdownService.deleteItem({
        fieldItemId: this.deletedItemId,
      });

      // detect error and show alert to user
      if (error) {
        if (error === 'itemIsUsed') {
          this.$alertError(this.$t('pages/Data/Dropdown/alertMessage.isUsed'));
        } else {
          this.$alertError(error);
        }
      } else {
        this.$alertSuccess(
          this.$t('pages/Data/Dropdown/alertMessage.deleted', {
            itemName: this.deletedItemName,
          }),
        );

        await this.search(this.query);
      }
    },
    onBack() {
      this.$router.push({
        path: `${APP_ROUTES.DROPDOWN}`,
        query: {
          pageNumber: 1,
          pageSize: this.$appConfig.itemPerPageDefault,
        },
      });
    },

    async getDropdownName() {
      const res = await DropdownService.getFieldsInItemFormByFieldId({
        fieldId: this.dropdownFieldId,
      });

      if (res && res.data) {
        this.dropdownName = res.data.fieldName;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dropdownName {
  float: left;
  font-size: 18px;
  font-weight: bold;
  margin-top: 10px;
}
.action-btn {
  display: table;
  margin: 20px auto;
}
</style>
