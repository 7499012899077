import httpClient from './httpClient';

const RESOURCE = 'countries';
export default {
  find(params = {}) {
    return httpClient.get(`/${RESOURCE}`, params);
  },
  findCountrySupportSms() {
    return httpClient.get(`/${RESOURCE}/support-sms`);
  },
};
