/* eslint-disable security/detect-object-injection */
// i18n-setup.js
import Vue from 'vue';
import VueI18n from 'vue-i18n';
// import environment from '@/core/environment';
// import axios from 'axios';

Vue.use(VueI18n);

export const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  missing: (locale, key, vm, values) => {
    // handle translation missing
    if (!values[key]) {
      return `${locale}:MISSING.${key}`;
    }
  },
  messages: loadLocaleMessages(),
});

function loadLocaleMessages() {
  const locales = require.context('@/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

// LOAD FROM API

// const loadedLanguages = ['en']; // our default language that is preloaded

// function setI18nLanguage(lang) {
//   i18n.locale = lang;
//   axios.defaults.headers.common['Accept-Language'] = lang;
//   document.querySelector('html').setAttribute('lang', lang);
//   return lang;
// }

// export function loadLanguageAsync(lang) {
//   if (loadedLanguages.includes(lang)) {
//     if (i18n.locale !== lang) setI18nLanguage(lang);
//     return Promise.resolve();
//   }
//   return axios
//     .get(`${environment.VUE_APP_API}/translations/${lang}`)
//     .then((response) => {
//       const { data } = response;
//       if (data) {
//         let messages = data.messages || {};
//         loadedLanguages.push(lang);
//         i18n.setLocaleMessage(lang, messages);
//         setI18nLanguage(lang);
//       }
//     });
// }
