<template>
  <lims-block :cardText="false" class="list-page">
    <modal-show-policy slot="blockTitle" ref="showPolicy" :pdf-base64="pdfData"> </modal-show-policy>
    <modal-confirm-generic
      slot="blockTitle"
      ref="modalConfirmDeletePolicy"
      :title="$t('pages/policy/modal/modalConfirmDeletePolicy/title')"
      :description="$t('pages/policy/modal/modalConfirmDeletePolicy/description')"
      @onConfirm="onConfirmDeletePolicy"
    ></modal-confirm-generic>
    <div class="md-layout" slot="blockContent">
      <div class="md-layout-item" v-if="query">
        <policy-list @onShowPdf="onShowPdf" @action="onAction" :policies="items" @sort="sort = $event">
          <md-table-toolbar slot="toolbar">
            <div class="toolbar-row">
              <div v-if="$isAuthorized(['Policy_PolicyManagement_Create'])" class="md-toolbar-section-end">
                <md-button @click="addPolicy">
                  {{ $t('pages/policy/button.addPolicy') }}
                </md-button>
              </div>
            </div>
            <div class="toolbar-row">
              <div class="field-rows-per-page">
                <lims-row-per-page :pageSize="query.pagination.pageSize" @pageSizeChanged="$pageSizeChanged" />
              </div>
              <div class="field-search">
                <PolicyListFilter
                  v-model="query.filters"
                  :defaultValues="defaultValues"
                  :fields="fields"
                  @onResetFilters="onResetFilters"
                />
              </div>
            </div>
            <lims-pagination ref="pager" :total-item="totalItem" :pagination="query.pagination"> </lims-pagination>
          </md-table-toolbar>
        </policy-list>
        <lims-pagination ref="pager" :total-item="totalItem" :pagination="query.pagination"> </lims-pagination>
      </div>
    </div>
  </lims-block>
</template>

<script>
import { APP_ROUTES } from '@/core/constants';
import { FORM_ACTIONS } from '@/core/constants';
import PolicyList from '@/pages/Policies/Policy/List/PolicyList.vue';
import ModalShowPolicy from '@/components/Lims/modals/ModalShowPolicy';
import { ModalConfirmGeneric } from '@/components/Lims/modals';
import LimsPagination from '@/components/Lims/LimsPagination';
import LimsRowPerPage from '@/components/Lims/LimsRowPerPage';
import PolicyListFilter from '@/pages/Policies/Policy/List/PolicyListFilter.vue';

import { cloneDeep } from 'lodash/cloneDeep';
import policyService from '@/services/policy.service';
import { ConfigMixins, ListWithFilterMixins } from '@/core/mixins';
import { policyQuery } from '@/query';
import { buildFilterFromQuery, buildFilterQueryParams } from '@/query/utils';

export default {
  components: {
    ModalShowPolicy,
    ModalConfirmGeneric,
    PolicyList,
    LimsPagination,
    LimsRowPerPage,
    PolicyListFilter,
  },
  mixins: [ConfigMixins, ListWithFilterMixins],
  created() {
    this.initState();
  },
  data() {
    return {
      items: [],
      totalItem: null,
      query: null,
      pdfData: '',
      deletePolicyId: '',
      deletePolicyTitle: '',
    };
  },

  computed: {
    fields() {
      return policyQuery.filters.fields;
    },
    defaultValues() {
      return policyQuery.filters.defaultValues;
    },
  },

  watch: {},

  methods: {
    // common methods
    initState() {
      const queryParams = this.$route.query;
      const filters = buildFilterFromQuery(policyQuery.filters, queryParams);
      const pagination = buildFilterFromQuery(policyQuery.pagination, {
        pageSize: this.$appConfig.itemPerPageDefault,
        ...queryParams,
      });
      const sort = buildFilterFromQuery(policyQuery.sort, queryParams);
      const query = { filters, pagination, sort };
      this.query = query;
    },

    async search(queryObject) {
      const { error, data } = await policyService.search({
        filters: buildFilterQueryParams(queryObject.filters),
        pagination: buildFilterQueryParams(queryObject.pagination),
        sort: `${queryObject.sort.orderField}:${queryObject.sort.orderBy}`,
      });
      // detect error and show alert to user
      if (error) {
        this.$alertError(error);
        // remove loading screen and allow user to interact
        return;
      }

      // binding data
      this.items = data.items;
      this.totalItem = data.pagination.totalItems;

      // update router
      const query = {
        ...buildFilterQueryParams(queryObject.filters),
        ...buildFilterQueryParams(queryObject.pagination),
        ...buildFilterQueryParams(queryObject.sort),
      };

      this.$router
        .push({
          query,
        })
        .catch((error) => {
          if (
            error.name === 'NavigationDuplicated' &&
            error.message.includes('Avoided redundant navigation to current location')
          ) {
            history.pushState({}, '', new URL(new URL(window.location)));
          }
        });
    },
    onResetFilters() {
      this.query.filters = cloneDeep(policyQuery.filters.defaultValues);
    },

    addPolicy() {
      this.$router.push(`${APP_ROUTES.POLICY_ADD}`);
    },

    onShowPdf(value) {
      this.pdfData = value;
      this.$refs.showPolicy.open();
    },

    onAction({ action, item }) {
      if (action === FORM_ACTIONS.EDIT) {
        this.editRow(item);
      }
      if (action === FORM_ACTIONS.VIEW) {
        this.viewRow(item);
      }
      if (action === FORM_ACTIONS.DELETE) {
        this.deleteRow(item);
      }
    },

    editRow(item) {
      this.$router.push(`${APP_ROUTES.POLICY_EDIT}/${item.policyId}`);
    },

    viewRow(item) {
      this.$router.push(`${APP_ROUTES.POLICY_VIEW}/${item.policyId}`);
    },

    deleteRow(item) {
      this.deletePolicyId = item.policyId;
      this.deletePolicyTitle = item.title;
      this.$refs.modalConfirmDeletePolicy.open();
    },

    async onConfirmDeletePolicy() {
      const res = await policyService.deletePolicy(this.deletePolicyId);
      if (res.err) {
        this.$alertError(res.err);
      } else {
        this.search(this.query);
        this.$alertSuccess(
          this.$t(`pages/policy/alert/delete.success`, {
            title: this.deletePolicyTitle,
          }),
        );
      }
      this.$refs.modalConfirmDeletePolicy.close();
    },
  },
};
</script>
