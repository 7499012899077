/* eslint-disable security/detect-object-injection */
import httpClient from './httpClient';
import { advancedSearchQueryMarshalling, convertFromDateTimezoneToIsoString, endDay, startDay } from '@/core/helpers';
import caseQueryFilters from '@/query/case/filters';
import { FIELD_TYPES } from '@/query/constant';

const RESOURCE = 'cases';

export const buildUserQueryParams = ({ filters, pagination, sort }, postBody = false) => {
  const { pageNumber, pageSize } = pagination;
  const userQueryParams = {};
  const { fields } = caseQueryFilters;
  const postBodyParams = {
    pageNumber: 0,
    pageSize: 0,
    sort: '',
    filters: {
      search: '',
      advanceSearch: {},
    },
  };
  const advancedSearch = {};
  const booleanFieldKeys = fields.filter((f) => f.fieldType === FIELD_TYPES.Boolean).map((f) => f.key);

  Object.entries(filters).forEach(([key, value]) => {
    if (key === 'search') {
      userQueryParams['Filters.Search'] = value;
      postBodyParams.filters.search = value;
    } else {
      if (key === 'DoB' && value) {
        userQueryParams['Filters.AdvanceSearch.DoB'] = convertFromDateTimezoneToIsoString(new Date(value));
        advancedSearch['DoB'] = userQueryParams['Filters.AdvanceSearch.DoB'];
      } else if (['TatNumber', 'TatWoWeNumber'].includes(key)) {
        const values = JSON.parse(value);
        Object.keys(values).map((subKey) => {
          userQueryParams[`Filters.AdvanceSearch.${key}${subKey}`] = values[subKey];
          advancedSearch[`${key}${subKey}`] = values[subKey];
        });
      } else if (key === 'LabEntryDate' && value) {
        const values = value.split(',');
        userQueryParams['Filters.AdvanceSearch.LabEntryDateFrom'] = startDay(values[0]);
        userQueryParams['Filters.AdvanceSearch.LabEntryDateTo'] = endDay(values[1]);
        advancedSearch['LabEntryDateFrom'] = userQueryParams['Filters.AdvanceSearch.LabEntryDateFrom'];
        advancedSearch['LabEntryDateTo'] = userQueryParams['Filters.AdvanceSearch.LabEntryDateTo'];
      } else if (key === 'ReportedDate' && value) {
        const values = value.split(',');
        userQueryParams['Filters.AdvanceSearch.ReportedDateFrom'] = startDay(values[0]);
        userQueryParams['Filters.AdvanceSearch.ReportedDateTo'] = endDay(values[1]);
        advancedSearch['ReportedDateFrom'] = userQueryParams['Filters.AdvanceSearch.ReportedDateFrom'];
        advancedSearch['ReportedDateTo'] = userQueryParams['Filters.AdvanceSearch.ReportedDateTo'];
      } else if (key === 'AlwRespondedDate' && value) {
        const values = value.split(',');
        userQueryParams['Filters.AdvanceSearch.AlwRespondDateFrom'] = startDay(values[0]);
        userQueryParams['Filters.AdvanceSearch.AlwRespondDateTo'] = endDay(values[1]);
        advancedSearch['AlwRespondDateFrom'] = userQueryParams['Filters.AdvanceSearch.AlwRespondDateFrom'];
        advancedSearch['AlwRespondDateTo'] = userQueryParams['Filters.AdvanceSearch.AlwRespondDateTo'];
      } else if (key === 'AlwRespondDate' && value) {
        const values = value.split(',');
        userQueryParams['Filters.AdvanceSearch.AlwRespondDateFrom'] = startDay(values[0]);
        userQueryParams['Filters.AdvanceSearch.AlwRespondDateTo'] = endDay(values[1]);
        advancedSearch['AlwRespondDateFrom'] = userQueryParams['Filters.AdvanceSearch.AlwRespondDateFrom'];
        advancedSearch['AlwRespondDateTo'] = userQueryParams['Filters.AdvanceSearch.AlwRespondDateTo'];
      } else if (key === 'ReturnToClinicDate' && value) {
        const values = value.split(',');
        userQueryParams['Filters.AdvanceSearch.SlideReturnToClinicFrom'] = startDay(values[0]);
        userQueryParams['Filters.AdvanceSearch.SlideReturnToClinicTo'] = endDay(values[1]);
        advancedSearch['SlideReturnToClinicFrom'] = userQueryParams['Filters.AdvanceSearch.SlideReturnToClinicFrom'];
        advancedSearch['SlideReturnToClinicTo'] = userQueryParams['Filters.AdvanceSearch.SlideReturnToClinicTo'];
      } else if (key === 'IssueRespondedAt' && value) {
        const values = value.split(',');
        userQueryParams['Filters.AdvanceSearch.IssueRespondedAtFrom'] = startDay(values[0]);
        userQueryParams['Filters.AdvanceSearch.IssueRespondedAtTo'] = endDay(values[1]);
        advancedSearch['IssueRespondedAtFrom'] = userQueryParams['Filters.AdvanceSearch.IssueRespondedAtFrom'];
        advancedSearch['IssueRespondedAtTo'] = userQueryParams['Filters.AdvanceSearch.IssueRespondedAtTo'];
      } else if (key === 'SlideReturnToClinicDate' && value) {
        userQueryParams['Filters.AdvanceSearch.SlideReturnToClinicDateFrom'] = startDay(value);
        userQueryParams['Filters.AdvanceSearch.SlideReturnToClinicDateTo'] = endDay(value);
        advancedSearch['SlideReturnToClinicDateFrom'] =
          userQueryParams['Filters.AdvanceSearch.SlideReturnToClinicDateFrom'];
        advancedSearch['SlideReturnToClinicDateTo'] =
          userQueryParams['Filters.AdvanceSearch.SlideReturnToClinicDateTo'];
      } else if (key === 'IsIncludedReportedCase' && value) {
        userQueryParams['Filters.AdvanceSearch.' + key] = value;
        advancedSearch[key] = value == 'true';
      } else if (key === 'ProcedureDate' && value) {
        const values = value.split(',');
        userQueryParams['Filters.AdvanceSearch.ProcedureDateFrom'] = startDay(values[0]);
        userQueryParams['Filters.AdvanceSearch.ProcedureDateTo'] = endDay(values[1]);
        advancedSearch['ProcedureDateFrom'] = userQueryParams['Filters.AdvanceSearch.ProcedureDateFrom'];
        advancedSearch['ProcedureDateTo'] = userQueryParams['Filters.AdvanceSearch.ProcedureDateTo'];
      } else if (key === 'LabSlideDate' && value) {
        const values = value.split(',');
        userQueryParams['Filters.AdvanceSearch.LabSlideDateFrom'] = startDay(values[0]);
        userQueryParams['Filters.AdvanceSearch.LabSlideDateTo'] = endDay(values[1]);
        advancedSearch['LabSlideDateFrom'] = userQueryParams['Filters.AdvanceSearch.LabSlideDateFrom'];
        advancedSearch['LabSlideDateTo'] = userQueryParams['Filters.AdvanceSearch.LabSlideDateTo'];
      } else if (booleanFieldKeys.includes(key) && value) {
        userQueryParams['Filters.AdvanceSearch.' + key] = value;
        advancedSearch[key] = value === 'true';
      } else {
        if (key != 'onSearch') {
          userQueryParams['Filters.AdvanceSearch.' + key] = value;
          advancedSearch[key] = userQueryParams['Filters.AdvanceSearch.' + key];
        }
      }
    }
  });

  if (pageNumber) {
    userQueryParams['PageNumber'] = pageNumber;
    postBodyParams.pageNumber = parseInt(userQueryParams['PageNumber']);
  }
  if (pageSize) {
    userQueryParams['PageSize'] = pageSize;
    postBodyParams.pageSize = parseInt(userQueryParams['PageSize']);
  }
  if (sort) {
    userQueryParams['Sort'] = sort;
    postBodyParams.sort = userQueryParams['Sort'];
  }
  postBodyParams.filters.advanceSearch = advancedSearch;
  return postBody ? postBodyParams : userQueryParams;
};

export default {
  async getDropdown(shortNames, hasDisabledUsers) {
    const res = await this.privateGetCaseDropdownData(shortNames, hasDisabledUsers);
    // otherwise mapping
    const dropdownOptions = {};
    shortNames.map((name) => {
      // eslint-disable-next-line security/detect-object-injection
      dropdownOptions[name] = res
        .filter((row) => row.itemName === name)[0]
        .items?.map((item) => {
          return {
            value: item.id,
            label: item.text,
            ...item,
          };
        });
    });
    return dropdownOptions;
  },
  async privateGetCaseDropdownData(shortNames, hasDisabledUsers) {
    const { data, error } = await httpClient.get(`/${RESOURCE}/dropdown`, {
      shortCodes: shortNames.join(','),
      hasDisabledUsers: hasDisabledUsers ? hasDisabledUsers : false,
    });
    if (error) {
      return {};
    }
    return data;
  },

  getCaseInterestListAdmin({ filters, pagination, sort }) {
    // Adjust endpoint when API is ready
    const userQueryParams = buildUserQueryParams({ filters, pagination, sort });
    return httpClient.get(`/${RESOURCE}/admin/case-of-interest`, userQueryParams);
  },

  getCaseInterestListPathologist({ filters, pagination, sort }) {
    // Adjust endpoint when API is ready
    const userQueryParams = buildUserQueryParams({ filters, pagination, sort });
    return httpClient.get(`/${RESOURCE}/pathologist/case-of-interest`, userQueryParams);
  },

  getCaseInterestListLabtechnician({ filters, pagination, sort }) {
    // Adjust endpoint when API is ready
    const userQueryParams = buildUserQueryParams({ filters, pagination, sort });
    return httpClient.get(`/${RESOURCE}/lab-technician-case/case-of-interest`, userQueryParams);
  },

  getCaseInterestListClinicUsers({ filters, pagination, sort }) {
    // Adjust endpoint when API is ready
    const userQueryParams = buildUserQueryParams({ filters, pagination, sort });
    return httpClient.get(`/${RESOURCE}/associated-user/case-of-interest`, userQueryParams);
  },

  getCaseAdminList({ filters, pagination, sort }) {
    const userQueryParams = buildUserQueryParams({ filters, pagination, sort });
    return httpClient.get(`/${RESOURCE}/admin`, userQueryParams);
  },

  getReportedCaseList({ filters, pagination, sort }) {
    const userQueryParams = buildUserQueryParams({ filters, pagination, sort });
    return httpClient.get(`/${RESOURCE}/associated-user/reported-case`, userQueryParams);
  },

  getUnReportedCaseList({ filters, pagination, sort }) {
    const userQueryParams = buildUserQueryParams({ filters, pagination, sort });
    return httpClient.get(`/${RESOURCE}/associated-user/unreported-case`, userQueryParams);
  },

  getPendingCaseLabList({ filters, pagination, sort }) {
    const userQueryParams = buildUserQueryParams({ filters, pagination, sort });
    return httpClient.get(`/${RESOURCE}/associated-user/pending-case`, userQueryParams);
  },

  getCaseListLabTechnician({ filters, pagination, sort }) {
    const userQueryParams = buildUserQueryParams({ filters, pagination, sort });
    return httpClient.get(`/${RESOURCE}/lab-technician-case`, userQueryParams);
  },

  getPendingCasePathList({ filters, pagination, sort }) {
    const userQueryParams = buildUserQueryParams({ filters, pagination, sort });
    return httpClient.get(`/${RESOURCE}/pathologist/pending-case`, userQueryParams);
  },
  getDoubleReportCasePathList({ filters, pagination, sort }) {
    const userQueryParams = buildUserQueryParams({ filters, pagination, sort });
    return httpClient.get(`/${RESOURCE}/pathologist/get-case-double-report-response-for-path`, userQueryParams);
  },

  getPullCaseList({ filters, pagination, sort }) {
    const userQueryParams = buildUserQueryParams({ filters, pagination, sort });
    return httpClient.get(`/${RESOURCE}/pathologist/pull-case`, userQueryParams);
  },

  getReadyForReportList({ filters, pagination, sort }) {
    const userQueryParams = buildUserQueryParams({ filters, pagination, sort });
    return httpClient.get(`/${RESOURCE}/pathologist/ready-for-report-case`, userQueryParams);
  },

  getPendingFurtherWorkList({ filters, pagination, sort }) {
    const userQueryParams = buildUserQueryParams({ filters, pagination, sort });
    return httpClient.get(`/${RESOURCE}/pathologist/pending-further-work-case`, userQueryParams);
  },

  getCaseListForReview({ filters, pagination }) {
    const { caseRefIds, search } = filters;
    const { pageNumber, pageSize } = pagination;
    const payload = {
      selectAll: false,
    };
    const searchQuery = {
      filters: {},
    };
    if (caseRefIds) {
      payload['caseRefs'] = caseRefIds.split(',');
    }
    if (search) {
      searchQuery['filters']['search'] = search;
    }
    if (pageNumber) {
      searchQuery['PageNumber'] = pageNumber;
    }
    if (pageSize) {
      searchQuery['PageSize'] = pageSize;
    }
    Reflect.set(payload, 'searchQuery', searchQuery);
    return httpClient.post(`/${RESOURCE}/list-case-for-review`, payload);
  },

  validateBulkDiagnosisCase(payload) {
    return httpClient.post(`/${RESOURCE}/list-case-for-diagnosis`, payload);
  },

  getBulkDiagnosisList(payload) {
    return httpClient.post(`/${RESOURCE}/list-case-for-diagnosis`, payload);
  },

  getReportCaseSearchResultList({ filters, pagination, sort }) {
    const userQueryParams = buildUserQueryParams({ filters, pagination, sort });
    return httpClient.get(`/${RESOURCE}/associated-user/reported-case-full`, userQueryParams);
  },

  removeRequestFormCases(data) {
    return httpClient.post(`/${RESOURCE}/admin/remove-request-form-cases`, data);
  },

  updateBatchCases(data) {
    return httpClient.post(`/${RESOURCE}/admin/update-batch-cases`, data);
  },

  checkWorkStreamBatchChangeSpecimenType(data) {
    return httpClient.post(`/${RESOURCE}/check-workstream-batchChange-specimenType`, data);
  },

  deteteCase(data) {
    return httpClient.post(`/${RESOURCE}/admin/delete-cases`, data);
  },

  markUrgentCases(data) {
    return httpClient.post(`/${RESOURCE}/mark-urgent-cases`, data);
  },

  markCaseAsOptedOut(data) {
    return httpClient.post(`/${RESOURCE}/admin/mark-case-as-opted-out`, data);
  },

  markAsReadCases(data) {
    return httpClient.post(`/${RESOURCE}/mark-as-read-cases-current-user`, data);
  },
  markAsUnReadCases(data) {
    return httpClient.post(`/${RESOURCE}/mark-as-unread-cases`, data);
  },

  markAsCaseOfInterest(data) {
    return httpClient.post(`/${RESOURCE}/mark-as-interest-cases`, data);
  },

  removeBillingFlag(data) {
    return httpClient.post(`/${RESOURCE}/remove-billing-flag`, data);
  },

  checkStatusCaselistForReview(data) {
    return httpClient.post(`/${RESOURCE}/check-status-caselist-for-review`, data);
  },

  checkStatusCaselistForRf(data) {
    return httpClient.post(`/${RESOURCE}/check-status-caselist-for-rf`, data);
  },

  validateSBCase(data) {
    return httpClient.post(`/${RESOURCE}/sb/validate`, data);
  },

  discardTissue(data) {
    return httpClient.post(`/${RESOURCE}/discard-tissues`, data);
  },

  getPrintLabels(data) {
    return httpClient.post(`/${RESOURCE}/export/print-labels`, advancedSearchQueryMarshalling(data));
  },

  getSBCase(caseIds) {
    return httpClient.post(`/${RESOURCE}/sb`, {
      selectAll: false,
      searchQuery: {
        pageSize: caseIds.length,
        pageNumber: 1,
        sort: 'caseReference:asc',
      },
      caseIds,
    });
  },
  saveSBCase({ queryModel, updateModel }) {
    return httpClient.put(`/${RESOURCE}/sb/update-and-query`, {
      queryModel,
      updateModel,
    });
  },

  exports(exportFileName, payload, params = {}) {
    return httpClient.postBlob(
      `/${RESOURCE}/export/${exportFileName}`,
      advancedSearchQueryMarshalling(payload),
      params,
    );
  },

  checkNumberSpecialityNavExports(payload, params = {}) {
    return httpClient.post(
      `/${RESOURCE}/export/nav-export/checkNumberSpeciality`,
      advancedSearchQueryMarshalling(payload),
      params,
    );
  },

  exportBackporting() {
    return httpClient.getBlob(`/${RESOURCE}/export/backporting`);
  },

  getExportBackportingNumberOfUnreadCases() {
    return httpClient.get(`/${RESOURCE}/export/backporting/number-cases`);
  },
};
