import { FIELD_TYPES } from '@/query/constant';
export const sortFields = [
  {
    key: 'orderField',
    listKey: null,
    sort: 1,
    fieldType: FIELD_TYPES.String,
    defaultValue: '',
  },
  {
    key: 'orderBy',
    listKey: null,
    sort: 2,
    fieldType: FIELD_TYPES.String,
    translated: false,
    defaultValue: '',
  },
];
