<template>
  <div>
    <button @click="previewPdf()">Test Print</button>
    <div class="md-layout lims-form-row">
      <div class="md-layout-item md-size-50 md-small-size-100">
        <h3>Number of labels</h3>
        <p>Number of labels: <input type="text" v-model="formData.numberOfLabels" /></p>
        <h3>Page Setting</h3>
        <p>Page Margin Top: <input type="text" v-model="printSettingValues.pageMarginTop" /></p>
        <p>Page Margin Left: <input type="text" v-model="printSettingValues.pageMarginLeft" /></p>
        <h3>Logo Setting</h3>
        <p>Logo Height: <input type="text" v-model="printSettingValues.logoHeight" /></p>
        <h3>Text Setting</h3>
        <p>Line Height: <input type="text" v-model="printSettingValues.lineHeight" /></p>
        <p>Column Data Width: <input type="text" v-model="printSettingValues.columnDataWidth" /></p>
        <p>Column Data Margin Left: <input type="text" v-model="printSettingValues.columnDataMarginLeft" /></p>
        <h3>QR Setting</h3>
        <p>QR Height: <input type="text" v-model="printSettingValues.qrHeight" /></p>
        <p>QR Padding: <input type="text" v-model="printSettingValues.qrPadding" /></p>
        <h3>Names</h3>
        <p>Patient fullname: <input type="text" v-model="formData.patientFullname" /></p>
        <p>Pathologist Name: <input type="text" v-model="formData.pathologistName" /></p>
        <qrcode-vue
          v-for="(item, index) in caseListData"
          :key="index"
          :value="coverData(item)"
          :size="qrCodeImageSize"
          level="L"
        />
      </div>
      <div class="md-layout-item md-size-50 md-small-size-100">
        <h3>Ticker settings</h3>
        <p>Label Width: <input type="text" v-model="printSettingValues.labelWidth" /></p>
        <p>Label Height: <input type="text" v-model="printSettingValues.labelHeight" /></p>
        <p>Label Horizontal Spacing: <input type="text" v-model="printSettingValues.labelHorizontalSpacing" /></p>
        <p>Label Vertical Spacing: <input type="text" v-model="printSettingValues.labelVerticalSpacing" /></p>
        <p>Label Left Padding: <input type="text" v-model="printSettingValues.labelLeftPadding" /></p>
        <p>Label Top Spacing: <input type="text" v-model="printSettingValues.labelTopPadding" /></p>
      </div>
    </div>
    <h3>Final setting</h3>
    <p>{{ JSON.stringify(printSettingValues) }}</p>
  </div>
</template>

<script>
import QrcodeVue from 'qrcode.vue';
import jsPDF from 'jspdf';
import printLabelLogo from '@/assets/images/print-label-logo.png';
const PrintHelper = {
  mmToCm: (x) => {
    return x / 10;
  },
};
export const PRINT_LABEL_SIZES = {
  PAGE_SIZE: 'a4', // A4: 210mm x 297mm (794px x 1123px)
  QRCODE_SIZE: 100, //px
  UNIT: 'cm',
  ORIENTATION: 'portrait',
  FONT_SIZE: 7,
  PAGE_MARGIN_LEFT: 0.78,
  PAGE_MARGIN_TOP: 1.52,
  LABEL_SIZES: {
    WIDTH: 63.5, // mm
    HEIGHT: 29.6, // mm
    VERTICAL_MARGIN: 15.3, // mm
    HORIZONTAL_MARGIN: 7.75, // mm
    HORIZONTAL_SPACING: 2, // mm
    VERTICAL_SPACING: 0.3, // mm
    TOP_PADDING: 0.15, //mm
    LEFT_PADDING: 0.2, //mm
  },
  COLUMN_DATA_WIDTH: 3.5,
  COLUMN_DATA_MARGIN_LEFT: 0.12,
  LOGO_HEIGHT: 0.96,
  LINE_HEIGHT: 0.3,
  QR_HEIGHT: 2.65,
  QR_PADDING: 0.2,
};

export const convertIndexToPageRowCol = (index) => {
  // each row has 3 cols
  // each page has 9 rows
  let i = 0;
  let j = index % 3;
  let page = 0;
  let rowItem = 0;
  let pageItem = 0;

  for (let k = 0; k <= index; k++) {
    rowItem += 1;
    if (rowItem > 3) {
      i += 1;
      // reset rowItem
      rowItem = 1;
    }
    if (i > 8) {
      // reset i
      i = 0;
    }
  }

  for (let k = 0; k <= index; k++) {
    pageItem += 1;
    if (pageItem > 27) {
      page += 1;
      // reset rowItem
      pageItem = 1;
    }
  }

  return { i, j, page };
};
export const addNewLabel = (doc, i, j, caseItem, qrImage, printSettingValues) => {
  const getX = (x) => {
    return x + parseFloat(printSettingValues.pageMarginLeft);
  };
  const getY = (y) => {
    return y + parseFloat(printSettingValues.pageMarginTop);
  };
  const splitName = (name) => {
    const words = name.split(' ');
    if (words.length <= 3) {
      return [name];
    }
    // otherwise
    return [words.slice(0, 3).join(' '), words.slice(3).join(' ')];
  };
  const COLUMN_SPACING = PrintHelper.mmToCm(
    parseFloat(printSettingValues.labelWidth) + parseFloat(printSettingValues.labelHorizontalSpacing),
  );
  const ROW_SPACING = PrintHelper.mmToCm(
    parseFloat(printSettingValues.labelHeight) + parseFloat(printSettingValues.labelVerticalSpacing),
  );
  const COLUMN_DATA_WIDTH = parseFloat(parseFloat(printSettingValues.columnDataWidth));
  const COLUMN_DATA_MARGIN_LEFT = parseFloat(printSettingValues.columnDataMarginLeft);
  const QR_HEIGHT = parseFloat(printSettingValues.qrHeight);
  const QR_PADDING = parseFloat(printSettingValues.qrPadding);
  const LOGO_HEIGHT = parseFloat(printSettingValues.logoHeight);
  const LINE_HEIGHT = parseFloat(printSettingValues.lineHeight);
  const LABEL_TOP_PADDING = parseFloat(printSettingValues.labelTopPadding);

  doc.addImage(
    printLabelLogo,
    'png',
    getX(COLUMN_DATA_MARGIN_LEFT + COLUMN_SPACING * j + printSettingValues.labelLeftPadding),
    getY(ROW_SPACING * i + LABEL_TOP_PADDING),
    0.37,
    0.53,
  );
  const textLines = [];
  textLines.push(caseItem.caseReference);
  if (caseItem.labReference) {
    textLines.push(caseItem.labReference);
  }
  if (caseItem.hospitalReference) {
    textLines.push(caseItem.hospitalReference);
  }
  if (caseItem.patientFullname) {
    const patientFullNameWords = splitName(caseItem.patientFullname);
    textLines.push(...patientFullNameWords);
  }
  if (caseItem.pathologistName) {
    const pathologistNameWords = splitName(caseItem.pathologistName);
    textLines.push(...pathologistNameWords);
  }
  textLines.forEach((line, lineIndex) => {
    doc.text(
      line,
      getX(COLUMN_DATA_MARGIN_LEFT + COLUMN_SPACING * j + printSettingValues.labelLeftPadding),
      getY(LOGO_HEIGHT + ROW_SPACING * i + LINE_HEIGHT * lineIndex + LABEL_TOP_PADDING),
    );
  });

  doc.addImage(
    qrImage,
    'png',
    getX(COLUMN_SPACING * j + COLUMN_DATA_WIDTH + QR_PADDING),
    getY(ROW_SPACING * i + QR_PADDING),
    QR_HEIGHT - 2 * QR_PADDING,
    QR_HEIGHT - 2 * QR_PADDING,
  );
};

export const DEFAULT_PRINT_SETTING_VALUES = {
  pageMarginTop: PRINT_LABEL_SIZES.PAGE_MARGIN_TOP,
  pageMarginLeft: PRINT_LABEL_SIZES.PAGE_MARGIN_LEFT,
  columnDataWidth: PRINT_LABEL_SIZES.COLUMN_DATA_WIDTH,
  columnDataMarginLeft: PRINT_LABEL_SIZES.COLUMN_DATA_MARGIN_LEFT,
  logoHeight: PRINT_LABEL_SIZES.LOGO_HEIGHT,
  lineHeight: PRINT_LABEL_SIZES.LINE_HEIGHT,
  qrHeight: PRINT_LABEL_SIZES.QR_HEIGHT,
  qrPadding: PRINT_LABEL_SIZES.QR_PADDING,
  labelWidth: PRINT_LABEL_SIZES.LABEL_SIZES.WIDTH,
  labelHeight: PRINT_LABEL_SIZES.LABEL_SIZES.HEIGHT,
  labelHorizontalSpacing: PRINT_LABEL_SIZES.LABEL_SIZES.HORIZONTAL_SPACING,
  labelVerticalSpacing: PRINT_LABEL_SIZES.LABEL_SIZES.VERTICAL_SPACING,
  labelLeftPadding: PRINT_LABEL_SIZES.LABEL_SIZES.LEFT_PADDING,
  labelTopPadding: PRINT_LABEL_SIZES.LABEL_SIZES.TOP_PADDING,
};

export default {
  name: 'ExamplePrintLabel',
  mixins: [],
  components: {
    QrcodeVue,
  },
  computed: {
    qrCodeImageSize() {
      return PRINT_LABEL_SIZES.QRCODE_SIZE;
    },
  },
  created() {
    this.caseListData.push({
      caseReference: '22SBBZ20319',
      labReference: 'LAB-22SBBZ20319',
      hospitalReference: 'HSP-22SBBZ20319',
      patientFullname: 'X Y',
      firstName: 'X',
      lastName: 'Y',
      pathologistName: 'Dermato Default LDPath Deparment',
    });
  },
  updated() {},
  props: {},
  data() {
    return {
      printSettingValues: DEFAULT_PRINT_SETTING_VALUES,
      formData: {
        patientFullname: 'X Y',
        pathologistName: 'Dermato Default LDPath Deparment',
        numberOfLabels: 27,
      },
      expandSpace: 0,
      caseListData: [],
    };
  },
  methods: {
    coverData(caseListItem) {
      if (caseListItem) {
        let qrCodeData = '';
        if (caseListItem.caseReference) {
          qrCodeData += caseListItem.caseReference;
        }
        if (caseListItem.labReference) {
          qrCodeData += ',' + caseListItem.labReference;
        }
        if (caseListItem.lastName) {
          qrCodeData += ',' + caseListItem.lastName;
        }
        if (caseListItem.firstName) {
          qrCodeData += ',' + caseListItem.firstName;
        }
        return `${qrCodeData},`;
      }
    },
    previewPdf() {
      // const inchToCm = 2.541;
      let doc = new jsPDF({
        orientation: 'portrait',
        unit: 'cm',
        format: [21, 29.7],
        // format: [8.13 * inchToCm, 11.61 * inchToCm],
      });
      doc.setFontSize(PRINT_LABEL_SIZES.FONT_SIZE);
      const qrImages = document.getElementsByTagName('canvas');
      let currentPage = 0;
      const printSettingValues = this.printSettingValues;
      for (let index = 0; index < this.formData.numberOfLabels; index++) {
        const qrImage = qrImages[0];
        const caseItem = this.caseListData[0];
        caseItem.patientFullname = this.formData.patientFullname;
        caseItem.pathologistName = this.formData.pathologistName;
        const { i, j, page } = convertIndexToPageRowCol(index);
        if (page > currentPage) {
          // create new page
          doc.addPage();
          currentPage = page;
        }
        addNewLabel(doc, i, j, caseItem, qrImage, printSettingValues);
      }
      window.open(doc.output('bloburl'));
      // doc.save(pdfName + '.pdf');
      // this.close();
    },
  },
};
</script>

<style lang="scss" scoped></style>
