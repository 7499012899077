<template>
  <div class="md-layout lims-form">
    <lims-block :cardText="false" class="list-page" v-if="!caseImported">
      <div slot="blockContent">
        <case-uploaded-list v-model="casesUploaded" @caseImportSuccess="caseImportSuccess"></case-uploaded-list>
      </div>
    </lims-block>

    <lims-block :cardText="false" class="list-page mg-top-24" v-show="caseImported.length > 0">
      <div slot="blockContent">
        <case-imported-list v-model="caseImported" :caseImportID="caseImportID"></case-imported-list>
      </div>
    </lims-block>
  </div>
</template>

<script>
import CaseUploadedList from '@/pages/Case/ImportCase/List/CaseUploadedList.vue';
import CaseImportedList from '@/pages/Case/ImportCase/List/CaseImportedList.vue';

export default {
  components: {
    CaseUploadedList,
    CaseImportedList,
  },

  data() {
    return {
      casesUploaded: '',
      caseImported: '',
      caseImportID: '',
    };
  },
  methods: {
    caseImportSuccess(val) {
      if (val) {
        this.caseImported = val.cases;
        this.caseImportID = val.caseImportBatchReference;
      }
    },
  },
};
</script>

<style lang="scss">
.comfirm-upload-action {
  padding: 0;
  display: flex;
  justify-content: center;
  .md-button {
    margin-right: 10px;
  }
}
</style>
