import { getDefaultFilterValues, getFilterFieldTypes } from '@/query/utils';
import { FIELD_TYPES } from '@/query/constant';
import {
  FF_CaseReference,
  FF_CaseStatusIds,
  FF_ClinicIds,
  FF_ClinicTypeIds,
  FF_ClinicianIds,
  FF_HospitalReference,
  FF_LabReference,
  FF_LaboratoryIds,
  FF_PathologistIds,
  FF_PatientFirstName,
  FF_PatientLastName,
  FF_SpecimenTypeIds,
  FF_ALW_CaseSpecimentBlocks,
  FF_ALW_AlwRequestStatusIds,
  FF_ALW_AlwRequestTypeIds,
  FF_ALW_AlwRequestDate,
  FF_ALW_AlwRequestStainIds,
  FF_ALW_AlwRespondDate,
  FF_ALW_AlwRespondStainIds,
} from '../filter-fields';
const fields = [
  {
    key: FF_CaseReference,
    listKey: FF_CaseReference,
    sort: 1,
    fieldType: FIELD_TYPES.String,
    translated: true,
    defaultValue: '',
  },
  {
    key: FF_CaseStatusIds,
    listKey: FF_CaseStatusIds,
    sort: 2,
    fieldType: FIELD_TYPES.ArrayInt,
    translated: true,
    defaultValue: [],
  },
  {
    key: FF_ClinicIds,
    listKey: FF_ClinicIds,
    sort: 3,
    fieldType: FIELD_TYPES.String,
    translated: true,
    defaultValue: [],
  },
  {
    key: FF_ClinicTypeIds,
    listKey: FF_ClinicTypeIds,
    sort: 4,
    fieldType: FIELD_TYPES.ArrayInt,
    translated: true,
    defaultValue: [],
  },
  {
    key: FF_ClinicianIds,
    listKey: FF_ClinicianIds,
    sort: 5,
    fieldType: FIELD_TYPES.ArrayString,
    translated: true,
    defaultValue: [],
  },
  {
    key: FF_HospitalReference,
    listKey: null,
    sort: 6,
    fieldType: FIELD_TYPES.String,
    translated: false,
    defaultValue: '',
  },
  { key: FF_LabReference, listKey: null, sort: 7, fieldType: FIELD_TYPES.String, translated: false, defaultValue: '' },
  {
    key: FF_LaboratoryIds,
    listKey: FF_LaboratoryIds,
    sort: 8,
    fieldType: FIELD_TYPES.ArrayString,
    translated: true,
    defaultValue: [],
  },
  {
    key: FF_PathologistIds,
    listKey: FF_PathologistIds,
    sort: 9,
    fieldType: FIELD_TYPES.ArrayString,
    translated: true,
    defaultValue: [],
  },
  {
    key: FF_PatientFirstName,
    listKey: null,
    sort: 10,
    fieldType: FIELD_TYPES.String,
    translated: false,
    defaultValue: '',
  },
  {
    key: FF_PatientLastName,
    listKey: null,
    sort: 11,
    fieldType: FIELD_TYPES.String,
    translated: false,
    defaultValue: '',
  },
  {
    key: FF_SpecimenTypeIds,
    listKey: FF_SpecimenTypeIds,
    sort: 12,
    fieldType: FIELD_TYPES.ArrayString,
    translated: true,
    defaultValue: [],
  },
  {
    key: FF_ALW_CaseSpecimentBlocks,
    listKey: null,
    sort: 13,
    fieldType: FIELD_TYPES.ArrayString,
    translated: true,
    defaultValue: [],
  },
  {
    key: FF_ALW_AlwRequestStatusIds,
    listKey: FF_ALW_AlwRequestStatusIds,
    sort: 14,
    fieldType: FIELD_TYPES.ArrayInt,
    translated: true,
    defaultValue: [],
  },
  {
    key: FF_ALW_AlwRequestTypeIds,
    listKey: FF_ALW_AlwRequestTypeIds,
    sort: 15,
    fieldType: FIELD_TYPES.ArrayInt,
    translated: true,
    defaultValue: [],
  },
  {
    key: FF_ALW_AlwRequestDate,
    listKey: null,
    sort: 16,
    fieldType: FIELD_TYPES.ArrayDate,
    translated: false,
    defaultValue: null,
  },
  {
    key: FF_ALW_AlwRequestStainIds,
    listKey: FF_ALW_AlwRequestStainIds,
    sort: 17,
    fieldType: FIELD_TYPES.ArrayInt,
    translated: true,
    defaultValue: [],
  },
  {
    key: FF_ALW_AlwRespondDate,
    listKey: null,
    sort: 18,
    fieldType: FIELD_TYPES.ArrayDate,
    translated: false,
    defaultValue: null,
  },
  {
    key: FF_ALW_AlwRespondStainIds,
    listKey: FF_ALW_AlwRespondStainIds,
    sort: 19,
    fieldType: FIELD_TYPES.ArrayInt,
    translated: true,
    defaultValue: [],
  },
  {
    key: 'DashboardRedirectEnum',
    listKey: null,
    sort: 20,
    fieldType: FIELD_TYPES.Int,
    translated: true,
    defaultValue: null,
  },

  { key: 'search', listKey: null, sort: 21, fieldType: FIELD_TYPES.String, translated: false, defaultValue: [] },
];
const defaultValues = getDefaultFilterValues(fields);
const fieldTypes = getFilterFieldTypes(fields);
export default {
  fields,
  defaultValues,
  fieldTypes,
};
