<template>
  <md-dialog :md-close-on-esc="true" :md-active.sync="isVisible" @md-clicked-outside="close" class="lims-modal">
    <div class="dialog-icon-close" @click="close"><md-icon>close</md-icon></div>
    <br />
    <br />
    <div class="dialog-content view-permissions-modal">
      <div v-for="(featureGroup, index) in permissionData" :key="index">
        <p class="feature-group-name">
          {{ translatePermissionFeatureGroupCodeName(featureGroup.featureGroupCodeName) }}
        </p>
        <p v-for="(feature, indexFeature) in featureGroup.features" :key="indexFeature" class="feature-code">
          {{ translatePermissionFeatureName(featureGroup.featureGroupCodeName, feature.featureCodeName) }}
          {{ covertPermissionFeature(feature) }}
        </p>
      </div>
    </div>
    <br />
  </md-dialog>
</template>

<script>
import modalMixins from './modal.mixins';
import RoleMixins from '@/pages/Account/Role/role.mixins';

export default {
  name: 'ModalViewPermissions',

  mixins: [modalMixins, RoleMixins],

  props: {
    permissionData: {
      require: true,
    },
  },

  methods: {
    covertPermissionFeature(roleFeatures) {
      let rolePermissions = [];

      const { permissionTypes } = roleFeatures;
      permissionTypes.map((perm) => {
        if (perm.hasAccess) {
          rolePermissions.push(this.translatePermissionType(perm.permissionName));
        }
      });

      if (rolePermissions.length > 0) {
        return '(' + rolePermissions.join(', ').toString() + ')';
      }
    },
    translatePermissionFeatureGroupCodeName(featureGroupCodeName) {
      const key =
        'pages/Account/Role/Components/FeatureGroupPermissions/featureGroup/featureGroupCodeName/' +
        featureGroupCodeName;
      return [this.$translate(key)];
    },
    translatePermissionFeatureName(featureGroupCodeName, featureCodeName) {
      return this.$translate(
        'pages/Account/Role/Components/FeatureGroupPermissions/' +
          featureGroupCodeName.replace(/\s/g, '') +
          '/featureName/' +
          featureCodeName.replace(/\s/g, ''),
      );
    },
    translatePermissionType(permissionName) {
      return this.$translate(`pages/Account/Role/Components/FeatureGroupPermissions/${permissionName}`);
    },
  },
};
</script>

<style lang="scss">
.feature-group-name {
  font-weight: 500;
}
.view-permissions-modal {
  max-height: 500px;
  overflow: auto;
  margin-right: 15px;
}
.feature-code {
  padding-left: 30px;
}
</style>
