<template>
  <v-select
    class="select-for-chips"
    :multiple="multiple"
    v-model="model"
    :options="options"
    :reduce="(item) => item.value"
    :selectable="(option) => !model.includes(option.value)"
  >
    <template #option="{ label }">{{ translate(label) }}</template>
    <template #selected-option="{ label }">{{ translate(label) }}</template>
    <template #search="{ events }">
      <input ref="vSelectSearch" :placeholder="placeholder" type="search" class="vs__search" v-on="events" />
    </template>
  </v-select>
</template>

<script>
import { isEqual } from 'lodash';
export default {
  props: {
    multiple: {
      type: Boolean,
      require: false,
      default: true,
    },
    translated: {
      type: Boolean,
      required: false,
      default: false,
    },
    placeholder: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: false,
      default: function () {
        return [];
      },
    },
    value: {
      type: Array,
      required: false,
      default: function () {
        return [];
      },
    },
  },
  created() {
    this.fetchData();
  },
  watch: {
    value: {
      handler: function (value) {
        const options = this.options.filter((o) => value.includes(o.value));
        // if different do update
        if (!isEqual(this.model, options)) {
          this.model = options.map((o) => o.value);
        }
        // clear search
        this.$refs.vSelectSearch.value = '';
      },
      deep: true,
    },
    model: {
      handler: function (value) {
        if (!isEqual(value, this.value)) {
          this.$emit('input', value);
        }
      },
      deep: true,
    },
  },
  data: function () {
    return {
      model: [],
    };
  },
  methods: {
    fetchData() {
      this.model = this.value;
    },
    translate(value) {
      if (this.translated) {
        return value;
      }
      return this.$translate(value);
    },
  },
};
</script>

<style lang="scss" scoped></style>
