var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
return [_c('form',{staticClass:"md-layout lims-form form-login",on:{"submit":function($event){$event.preventDefault();return handleSubmit()}}},[_c('md-dialog',{staticClass:"lims-modal",attrs:{"md-close-on-esc":true,"md-active":_vm.isVisible},on:{"update:mdActive":function($event){_vm.isVisible=$event},"update:md-active":function($event){_vm.isVisible=$event},"md-clicked-outside":_vm.close}},[_c('div',{staticClass:"dialog-icon-close",on:{"click":function($event){return _vm.closeAndClear()}}},[_c('md-icon',[_vm._v("close")])],1),_c('md-dialog-title',[_vm._v(_vm._s(_vm.$t('components/ModalBulkActionProblem.title')))]),_c('div',{staticClass:"lims-form dialog-content"},[(_vm.isVisible && _vm.formData.invalidItemAlwRequests.length > 0)?[_c('p',{staticClass:"text-center",domProps:{"innerHTML":_vm._s(
              _vm.$t('components/ModalBulkActionProblem/alw.text1', {
                action: _vm.$t(("entities/case/bulkActionName." + _vm.action)),
              })
            )}}),_c('ul',{staticClass:"list-of-compatible-cases"},_vm._l((_vm.formData.invalidItemAlwRequests),function(item){return _c('li',{key:item.caseAlwRequestId},[_vm._v(" "+_vm._s(item.caseReference)+", "+_vm._s(item.block)+", "+_vm._s(item.stain)+", "+_vm._s(item.typeName)+" ")])}),0)]:_vm._e(),(_vm.isVisible && _vm.formData.itemsAlwRequests.length > 0)?[_c('p',{staticClass:"text-center",domProps:{"innerHTML":_vm._s(_vm.$t('components/ModalBulkActionProblem.text2'))}}),_c('ul',{staticClass:"list-of-compatible-cases"},_vm._l((_vm.formData.itemsAlwRequests),function(item){return _c('li',{key:item.caseAlwRequestId},[_vm._v(" "+_vm._s(item.caseReference)+", "+_vm._s(item.block)+", "+_vm._s(item.stain)+", "+_vm._s(item.typeName)+" ")])}),0)]:(_vm.isVisible)?_c('p',{staticClass:"text-center",domProps:{"innerHTML":_vm._s(_vm.$t('components/ModalBulkActionProblem.text3'))}}):_vm._e()],2),_c('md-dialog-actions',[(_vm.isVisible && _vm.formData.itemsAlwRequests.length > 0)?[_c('md-button',{staticClass:"lims-form-button",on:{"click":function($event){return _vm.closeAndClear()}}},[_vm._v(_vm._s(_vm.$t('global/button/button.cancel')))]),_c('md-button',{staticClass:"lims-form-button md-primary",attrs:{"disabled":invalid},on:{"click":_vm.onContinue}},[_vm._v(_vm._s(_vm.$t('global/button/button.continue')))])]:_c('md-button',{staticClass:"lims-form-button md-primary",on:{"click":function($event){return _vm.closeAndClear()}}},[_vm._v(_vm._s(_vm.$t('global/button/button.backToList')))])],2)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }