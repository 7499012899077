import { DROPDOWN_SHORT_NAME, LOCATION_BULK_ACTIONS, LOCATION_FLAG_NAMES } from '@/core/constants';
import { caseSlideService } from '@/services';
import { mapGetters } from 'vuex';

export const mappingLocationFlagOptionsToObject = (options, withPrefix = false) => {
  const LOCATION_FLAG = {};
  if (options && options.length > 0) {
    options.forEach((flag) => {
      Reflect.set(LOCATION_FLAG, flag.fieldItemName, flag.fieldItemId);
    });

    Reflect.ownKeys(LOCATION_FLAG_NAMES).forEach((k) => {
      const name = Reflect.get(LOCATION_FLAG_NAMES, k);
      if (!Reflect.ownKeys(LOCATION_FLAG).includes(name)) {
        throw new Error(`Not found Slide Location Flag = "${name}"`);
      }
    });

    if (withPrefix) {
      const newFlags = {};
      Reflect.ownKeys(LOCATION_FLAG).forEach((k) => {
        Reflect.set(newFlags, `Location${k}`, Reflect.get(LOCATION_FLAG, k));
      });
      return newFlags;
    }
  }
  return LOCATION_FLAG;
};

export default {
  computed: {
    ...mapGetters('app/data', ['getDatasetByKey']),
  },
  methods: {
    convertActionToLocationFlag(action) {
      const locationFlagOptions = this.getDatasetByKey(DROPDOWN_SHORT_NAME.CASE_SLIDE_LOCATION_FLAG);
      const LOCATION_FLAG = mappingLocationFlagOptionsToObject(locationFlagOptions);
      if (action === LOCATION_BULK_ACTIONS.SendToPath) {
        return LOCATION_FLAG?.SentToPath;
      }
      if (action === LOCATION_BULK_ACTIONS.ReturnToLab) {
        return LOCATION_FLAG?.ReturnedToLab;
      }
      if (action === LOCATION_BULK_ACTIONS.ReturnToClinic) {
        return LOCATION_FLAG?.ReturnedToClinic;
      }
      if (action === LOCATION_BULK_ACTIONS.SetToExternal) {
        return LOCATION_FLAG?.External;
      }
      return null;
    },
    dateIsValid(dateRange) {
      return dateRange && dateRange.substring(0, 10) === dateRange.substring(26, 36);
    },
    filterValue(query) {
      if (query.LocationSentToPath)
        return {
          locationFlag: 'Send To Path',
          date: query.SlideSentToPathDate,
        };
      if (query.LocationReturnedToClinic)
        return {
          locationFlag: 'Returned To Clinic',
          date: query.SlideReturnToClinicDate,
        };
      if (query.LocationExternal)
        return {
          locationFlag: 'External',
          date: query.SlideSentToExternalDate,
        };
      if (query.LocationReturnedToLab)
        return {
          locationFlag: 'Returned To Lab',
          date: query.SlideReturnedToLabDate,
        };
    },
    async checkPreConditions(slides, locationFlag) {
      if (!locationFlag) {
        const query = this.$route.query;
        if (
          (query.LocationSentToPath && this.dateIsValid(query.SlideSentToPathDate)) ||
          (query.LocationReturnedToClinic && query.SlideReturnToClinicDate) ||
          (query.LocationExternal && this.dateIsValid(query.SlideSentToExternalDate)) ||
          (query.LocationReturnedToLab && this.dateIsValid(query.SlideReturnedToLabDate)) ||
          (query.LocationLab && this.dateIsValid(query.SlideSentToLabDate))
        ) {
          return {
            listCaseSuccess: slides.map((item) => {
              const { caseId, caseReference } = item.case;
              const { caseSpecimenBlock, typeName, slide, caseAlwRequestId } = item;
              return {
                caseId,
                caseReference,
                slide,
                ...caseSpecimenBlock,
                slideTypeName: typeName,
                caseAlwRequestId,
              };
            }),
            filterValue: this.filterValue(query),
            hasCaseFailure: false,
            err: false,
          };
        } else {
          this.$alertWarningWithNoTimeOut(this.$t('pages/case/CaseManagement/List/ExportAction/MissingFilter'));
          return {
            err: 'isFilterError',
          };
        }
      }
      // need check
      const { err, data } = await caseSlideService.checkValidBulkAction({
        slides: slides.map((item) => {
          return {
            fieldItemId: item.slide.fieldItemId,
            caseSpecimenBlockId: item.caseSpecimenBlock.caseSpecimenBlockId,
            slideTypeName: item.typeName,
            caseAlwRequestId: item.caseAlwRequestId,
            caseSpecimenBlockItemReferenceId: item.slide.caseSpecimenBlockItemReferenceId,
          };
        }),
        locationFlag,
      });
      if (err) {
        return {
          err,
        };
      }
      const { listCaseSuccess, casesFailure, hasCaseFailure } = data;
      return {
        listCaseSuccess,
        casesFailure,
        hasCaseFailure,
        err: null,
      };
    },
    async $processAction({ action, slides, bulkActionSlideModal, bulkActionProblemSlideModal }) {
      const locationFlag = this.convertActionToLocationFlag(action);

      const preConditionResult = await this.checkPreConditions(slides, locationFlag);
      if (preConditionResult.err) {
        if (preConditionResult.err === 'isFilterError') return;
        else return this.$alertError(preConditionResult.err);
      }
      if (preConditionResult.listCaseSuccess && preConditionResult.listCaseSuccess.length > 0) {
        bulkActionSlideModal.setSlides(preConditionResult.listCaseSuccess, preConditionResult.filterValue);
      }
      if (!preConditionResult.hasCaseFailure) {
        bulkActionSlideModal.open();
        return;
      }
      // setDataForValidation

      // hasCaseFailure
      bulkActionProblemSlideModal.setData({
        bulkAction: { value: action, label: action },
        dataValidate: preConditionResult,
      });
      bulkActionProblemSlideModal.open();
    },
  },
};
