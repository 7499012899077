<template>
  <md-dialog :md-close-on-esc="true" :md-active.sync="isVisible" @md-clicked-outside="close" class="lims-modal">
    <div class="dialog-icon-close" @click="close"><md-icon>close</md-icon></div>
    <md-dialog-title>{{ $t('components/lims/modals/ModalConfirmUpdateMyEntityUser.title') }}</md-dialog-title>
    <div class="dialog-content">
      <p>{{ $t('components/lims/modals/ModalConfirmUpdateMyEntityUser.contentReviewBy') }}</p>
      <ul>
        <li v-if="actionChange.email">
          {{ $t('components/lims/modals/ModalConfirmUpdateMyEntityUser/update.email') }}
        </li>
        <li v-if="actionChange.telephoneMobile">
          {{ $t('components/lims/modals/ModalConfirmUpdateMyEntityUser/update.tel') }}
        </li>
        <li v-if="actionChange.hasLogin">
          {{ $t('components/lims/modals/ModalConfirmUpdateMyEntityUser/update.hasLogin') }}
        </li>
      </ul>
      <p>{{ $t('components/lims/modals/ModalConfirmUpdateMyEntityUser.contentNotEdit') }}</p>
    </div>
    <md-dialog-actions>
      <md-button @click="close" class="lims-form-button">{{ $t('global/button/button.cancel') }}</md-button>
      <md-button @click="onClick" class="lims-form-button md-primary">
        {{ $t('global/button/button.confirm') }}
      </md-button>
    </md-dialog-actions>
  </md-dialog>
</template>

<script>
import modalMixins from './modal.mixins';
export default {
  name: 'ModalConfirmUpdateMyEntityUser',
  mixins: [modalMixins],
  props: ['actionChange'],
  data() {
    return {};
  },
  watch: {},
  computed: {},
  methods: {
    onClick() {
      this.$emit('onConfirm');
    },
  },
};
</script>
<style lang="scss"></style>
