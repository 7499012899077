<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit }">
    <form class="md-layout lims-form" @submit.prevent="handleSubmit()">
      <!-- Personal Info -->
      <lims-block class="personal-info-block">
        <h4 class="title" slot="blockTitle">{{ $t('pages/account/user/forms/personalInfoBlock') }}</h4>
        <modal-confirmation
          ref="confirmModal"
          slot="blockTitle"
          :is-wrong-password="isWrongPassword"
          @onConfirm="$onConfirmDisabled"
        ></modal-confirmation>
        <modal-has-login-confirm
          ref="hasLoginConfirmModal"
          slot="blockTitle"
          @onConfirm="$onHasLoginConfirmModal"
        ></modal-has-login-confirm>
        <modal-view-permissions
          ref="viewPermissions"
          slot="blockTitle"
          :permission-data="roleInformation"
        ></modal-view-permissions>
        <modal-input-your-telephone
          slot="blockTitle"
          ref="inputYourTelephoneModal"
          :user-id="userId"
          :verify-two-fa-mode="verifyTwoFaMode"
          :mobile-phone="telephoneMobileDataInVerify"
          @onNext="onCompleteInputNewTelephone"
        ></modal-input-your-telephone>
        <modal-input-new-email
          slot="blockTitle"
          ref="inputNewEmailModal"
          :user-id="userId"
          @onNext="onCompleteInputNewEmail"
        ></modal-input-new-email>
        <modal-verify-code
          slot="blockTitle"
          ref="verifyCodeModal"
          :option="verifyContent"
          :is-wrong-code="isWrongCode"
          @onVerify="onVerifyCode"
          @onResendCode="onResendVerifyCode"
        ></modal-verify-code>
        <modal-update-successfully
          slot="blockTitle"
          ref="verifySuccessfullyModal"
          :method="
            verifyMode === onUpdateEmail
              ? $t('entities/user/personalInfo/email')
              : $t('entities/user/personalInfo/telephoneMobile')
          "
          @onUpdateSuccessfully="onUpdateSuccessfully"
        ></modal-update-successfully>
        <div slot="blockContent">
          <LimsFormEditButton class="edit-btn" v-if="viewMode && $canEdit()" :editUrl="$editUrl" />
          <!-- new row -->
          <div class="md-layout lims-form-row" v-if="isDefaultView">
            <div class="md-layout-item md-size-25 md-small-size-100">
              <lims-field :model="formData.personalInfo" field="userType" :schema="userSchema.personalInfo">
                <v-select disabled slot="field" :options="userTypesList" v-model="formData.personalInfo.userType">
                  <template #option="{ label }">{{ $t(label) }}</template>
                  <template #selected-option="{ label }">{{ $t(label) }}</template>
                </v-select>
              </lims-field>
            </div>
            <div class="md-layout-item md-size-25 md-small-size-100">
              <lims-field :model="formData.personalInfo" :schema="userSchema.personalInfo" field="role">
                <v-select
                  slot="field"
                  v-model="formData.personalInfo.roleId"
                  :options="roleList"
                  :disabled="viewMode"
                  :tabindex="1"
                  tabenable="yes"
                >
                  <template #option="{ label }">{{ label }}</template>
                  <template #selected-option="{ label }">{{ label }}</template>
                </v-select>
              </lims-field>
            </div>
            <div class="md-layout-item md-size-25 md-small-size-100">
              <div
                class="view-permissions"
                @click="onClickViewPermissions"
                :class="formData.personalInfo.roleId ? '' : 'disable-view-permissions'"
              >
                {{ $t('entities/user/personalInfo/viewPermissions') }}
              </div>
            </div>
          </div>
          <!-- end row -->
          <!-- new row -->
          <div class="md-layout lims-form-row">
            <div class="md-layout-item md-size-25 md-small-size-100">
              <lims-field :model="formData.personalInfo" :schema="userSchema.personalInfo" field="username">
                <lims-tooltip
                  slot="label-info"
                  :content="$t('entities/user/personalInfo/userNameTooltip')"
                ></lims-tooltip>
                <md-input
                  slot="field"
                  v-model="formData.personalInfo.username"
                  type="text"
                  :disabled="isEditMode || viewMode"
                  v-on:blur="
                    $onServerSideValidation($event, userSchema._serverSideValidators.username, [
                      () => $lowercase(formData.personalInfo, 'username'),
                    ])
                  "
                  tabenable="yes"
                >
                </md-input>
                <lims-tooltip
                  slot="icon"
                  :content="$t('entities/user/personalInfo/userNameTooltipRules')"
                ></lims-tooltip>
              </lims-field>
            </div>
            <div class="md-layout-item md-size-25 md-small-size-100">
              <lims-field :model="formData.personalInfo" :schema="userSchema.personalInfo" field="title">
                <v-select
                  slot="field"
                  v-model="formData.personalInfo.titleId"
                  :options="userTitles"
                  :disabled="viewMode"
                  tabenable="yes"
                  :tabindex="3"
                >
                  <template #option="{ label }">{{ label }}</template>
                  <template #selected-option="{ label }">{{ label }}</template>
                </v-select>
              </lims-field>
            </div>
            <div class="md-layout-item md-size-25 md-small-size-100">
              <lims-field :model="formData.personalInfo" :schema="userSchema.personalInfo" field="firstName">
                <md-input
                  slot="field"
                  v-model="formData.personalInfo.firstname"
                  type="text"
                  maxlength="250"
                  :disabled="viewMode"
                  tabenable="yes"
                ></md-input>
              </lims-field>
            </div>
            <div class="md-layout-item md-size-25 md-small-size-100">
              <lims-field :model="formData.personalInfo" :schema="userSchema.personalInfo" field="lastName">
                <md-input
                  slot="field"
                  v-model="formData.personalInfo.lastname"
                  type="text"
                  maxlength="250"
                  :disabled="viewMode"
                  tabenable="yes"
                ></md-input>
              </lims-field>
            </div>
          </div>
          <!-- end row -->
          <!-- new row -->
          <div class="md-layout lims-form-row">
            <div class="md-layout-item md-size-25 md-small-size-100">
              <lims-field :model="formData.personalInfo" :schema="userSchema.personalInfo" field="accountReference">
                <md-input
                  slot="field"
                  v-model="formData.personalInfo.reference"
                  type="text"
                  maxlength="25"
                  :disabled="viewMode"
                  tabenable="yes"
                ></md-input>
              </lims-field>
            </div>
            <div class="md-layout-item md-size-25 md-small-size-100">
              <lims-field :model="formData.personalInfo" :schema="userSchema.personalInfo" field="speciality">
                <v-select
                  slot="field"
                  v-model="formData.personalInfo.specialityId"
                  :options="specialitiesList"
                  :placeholder="$t('entities/user/personalInfo/speciality.placeholder')"
                  :disabled="viewMode"
                  tabenable="yes"
                  :tabindex="7"
                >
                  <template #option="{ label }">{{ label }}</template>
                  <template #selected-option="{ label }">{{ label }}</template>
                </v-select>
              </lims-field>
            </div>
            <div class="md-layout-item md-size-25 md-small-size-100">
              <lims-field :model="formData.personalInfo" :schema="userSchema.personalInfo" field="gmc">
                <md-input
                  slot="field"
                  v-model="formData.personalInfo.gmc"
                  type="text"
                  maxlength="7"
                  :disabled="viewMode"
                  tabenable="yes"
                ></md-input>
              </lims-field>
            </div>
          </div>
          <!-- end row -->
          <!-- new row -->
          <div class="md-layout lims-form-row">
            <div class="md-layout-item md-size-100">
              <lims-field
                :model="formData.personalInfo"
                :schema="userSchema.personalInfo"
                field="address1"
                class="textarea-field"
              >
                <md-textarea
                  slot="field"
                  v-model="formData.personalInfo.address1"
                  :disabled="viewMode"
                  tabenable="yes"
                ></md-textarea>
              </lims-field>
              <lims-field
                :model="formData.personalInfo"
                :schema="userSchema.personalInfo"
                field="address2"
                class="textarea-field"
              >
                <md-textarea
                  slot="field"
                  v-model="formData.personalInfo.address2"
                  :disabled="viewMode"
                  tabenable="yes"
                ></md-textarea>
              </lims-field>
            </div>
          </div>
          <!-- end row -->
          <!-- new row -->
          <div class="md-layout lims-form-row">
            <div class="md-layout-item md-size-25 md-small-size-100">
              <lims-field :model="formData.personalInfo" :schema="userSchema.personalInfo" field="town">
                <md-input
                  slot="field"
                  v-model="formData.personalInfo.town"
                  type="text"
                  maxlength="100"
                  :disabled="viewMode"
                  tabenable="yes"
                ></md-input>
              </lims-field>
            </div>
            <div class="md-layout-item md-size-25 md-small-size-100">
              <lims-field :model="formData.personalInfo" :schema="userSchema.personalInfo" field="country">
                <v-select
                  slot="field"
                  v-model="formData.personalInfo.countryId"
                  :options="countries"
                  :disabled="viewMode"
                  tabenable="yes"
                  :tabindex="12"
                >
                  <template #option="{ label }">{{ label }}</template>
                  <template #selected-option="{ label }">{{ label }}</template>
                </v-select>
              </lims-field>
            </div>
            <div class="md-layout-item md-size-25 md-small-size-100">
              <lims-field :model="formData.personalInfo" :schema="userSchema.personalInfo" field="postCode">
                <md-input
                  slot="field"
                  v-model="formData.personalInfo.postCode"
                  type="text"
                  maxlength="50"
                  :disabled="viewMode"
                  tabenable="yes"
                ></md-input>
              </lims-field>
            </div>
          </div>
          <!-- end row -->
          <!-- new row -->
          <div class="md-layout lims-form-row">
            <div class="md-layout-item md-size-50 md-small-size-100">
              <lims-field :model="formData.personalInfo" :schema="userSchema.personalInfo" field="email">
                <lims-tooltip slot="label-info" :content="$t('entities/user/personalInfo/emailTooltip')"></lims-tooltip>
                <md-input
                  slot="field"
                  v-model="formData.personalInfo.email"
                  type="text"
                  v-on:blur="
                    $onServerSideValidation($event, userSchema._serverSideValidators.email, [
                      () => $lowercase(formData.personalInfo, 'email'),
                    ])
                  "
                  :placeholder="$t('entities/user/personalInfo/email.placeholder')"
                  maxlength="200"
                  :disabled="viewMode || $getUserMixins().isNotAllowedUpdatePhoneOrEmail() || isMyProfileView"
                  tabenable="yes"
                ></md-input>
              </lims-field>
              <div class="text-underline" v-if="isMyProfileView" @click="onClickUpdateEmail">
                {{ $t('pages/myAccount/profile/update/email') }}
              </div>
            </div>
            <div class="md-layout-item md-size-25 md-small-size-100">
              <lims-field :model="formData.personalInfo" :schema="userSchema.personalInfo" field="telephoneOffice">
                <md-input
                  slot="field"
                  v-model="formData.personalInfo.telephoneOffice"
                  type="text"
                  :placeholder="$t('global/placeholder.telephone')"
                  maxlength="50"
                  :disabled="viewMode"
                  tabenable="yes"
                ></md-input>
              </lims-field>
            </div>
            <div class="md-layout-item md-size-25 md-small-size-100">
              <lims-field :model="formData.personalInfo" :schema="userSchema.personalInfo" field="telephoneMobile">
                <lims-tel-input
                  slot="field"
                  :disabled="viewMode || isMyProfileView || $getUserMixins().isNotAllowedUpdatePhoneOrEmail()"
                  v-model="telephoneMobileData"
                  @phoneNumberChange="
                    ($event) => $onServerSideValidation($event, userSchema._serverSideValidators.telephoneMobile)
                  "
                  :tab-index="true"
                ></lims-tel-input>
              </lims-field>
              <div class="text-underline" v-if="isMyProfileView" @click="onClickUpdateTelephone">
                {{ $t('pages/myAccount/profile/update/telephone') }}
              </div>
            </div>
          </div>
          <!-- end row -->
          <!-- new row -->
          <div class="md-layout lims-form-row" v-if="isDefaultView">
            <div class="md-layout-item md-size-50 md-small-size-100">
              <lims-field
                :model="formData.personalInfo"
                :schema="userSchema.personalInfo"
                field="sendMdmEmailTo"
                class="textarea-field"
              >
                <lims-tooltip
                  slot="label-info"
                  :content="$t('pages/account/user/forms/Clinician/sendMdmEmailTo/tooltip')"
                ></lims-tooltip>
                <md-textarea
                  slot="field"
                  v-model="formData.personalInfo.mdmNotificationReceivers"
                  @change="$lowercase(formData.personalInfo, 'mdmNotificationReceivers')"
                  :disabled="isViewMode"
                  tabenable="yes"
                ></md-textarea>
              </lims-field>
            </div>
          </div>
          <!-- end row -->
        </div>
      </lims-block>
      <!-- Clinics -->
      <lims-block v-if="formMode !== ADD_MODE">
        <h4 class="title" slot="blockTitle">{{ $t('pages/account/user/forms/Clinician/clinicBlock') }}</h4>
        <lims-tooltip
          slot="blockTitle-info"
          :content="$t('pages/account/user/forms/Clinician/Clinic/blockTooltip')"
        ></lims-tooltip>
        <div slot="blockContent">
          <div class="md-layout lims-form-row">
            <md-table class="custom-tbl tbl-drag" md-card>
              <md-table-row>
                <md-table-head width="40%">
                  {{ $t('pages/account/user/forms/Clinician/Clinic/table.typeLabel') }}
                </md-table-head>
                <md-table-head>
                  {{ $t('pages/account/user/forms/Clinician/Clinic/table.nameLabel') }}
                </md-table-head>
                <md-table-head class="th-center cell-action">
                  {{ $t('pages/account/user/forms/Clinician/Clinic/table.caseLabel') }}
                </md-table-head>
              </md-table-row>
              <md-table-row v-for="clinician in formData.clinicianCases" :key="clinician.id">
                <md-table-cell width="40%">{{ clinician.clinicType }}</md-table-cell>
                <md-table-cell>{{ clinician.clinicName }}</md-table-cell>
                <md-table-cell class="cell-action">{{ clinician.numOfCase }}</md-table-cell>
              </md-table-row>
            </md-table>
          </div>
        </div>
      </lims-block>
      <!-- Preferred Pathologist -->
      <lims-block class="c-block01">
        <h4 class="title" slot="blockTitle">
          {{ $t('pages/account/user/forms/Clinician/PreferredPathologistBlock') }}
        </h4>
        <div slot="blockContent">
          <div class="md-layout">
            <div class="md-layout-item md-size-50 md-small-size-100 selectClinic">
              <label>{{
                $t('pages/account/user/forms/Clinician/PreferredPathologist/availablePathologistsLabel')
              }}</label>
              <v-select
                multiple
                slot="field-outside"
                v-model="preferredPathologistSelected"
                :options="preferredPathologistsPending"
                :placeholder="
                  $t('pages/account/user/forms/Clinician/PreferredPathologist/availablePathologistsPlaceholder')
                "
                :disabled="viewMode"
                :selectable="(option) => !preferredPathologistSelected.includes(option)"
                tabenable="yes"
                :tabindex="22"
              >
                <template #option="{ label }">{{ label }}</template>
                <template #selected-option="{ label }">{{ label }}</template>
              </v-select>
            </div>
            <div class="md-layout-item md-small-size-100 addBtn">
              <md-button
                type="button"
                class="lims-form-button block01-btn md-primary"
                @click="addPreferredPathologist"
                :disabled="viewMode || noPreferredPathologist"
                >{{ $t('pages/account/user/forms/Clinician/PreferredPathologist/addPreferredPathologist') }}</md-button
              >
            </div>
          </div>
          <div class="md-layout lims-form-row">
            <md-table class="custom-tbl tbl-drag" md-card>
              <md-table-row>
                <md-table-head width="40%">
                  {{ $t('pages/account/user/forms/Clinician/PreferredPathologist/table.nameLabel') }}
                </md-table-head>
                <md-table-head>
                  {{ $t('pages/account/user/forms/Clinician/PreferredPathologist/table.specialtyLabel') }}
                </md-table-head>
                <md-table-head class="th-center cell-action">
                  {{ $t('global/pages/list.actions') }}
                </md-table-head>
              </md-table-row>
              <draggable v-model="formData.preferredPathologists" class="tbl-body" @end="endDrag">
                <md-table-row v-for="item in formData.preferredPathologists" :key="item.id">
                  <md-table-cell class="cell-clinicType">{{ item.pathologistName }}</md-table-cell>
                  <md-table-cell>{{ item.specialty }}</md-table-cell>
                  <md-table-cell class="cell-action">
                    <span><md-icon>open_with</md-icon></span>
                    <span @click="viewMode ? '' : delPreferredPathologist(item)"><md-icon>close</md-icon></span>
                  </md-table-cell>
                </md-table-row>
              </draggable>
            </md-table>
          </div>
        </div>
      </lims-block>
      <!-- Restricted Pathologist -->
      <lims-block class="c-block01">
        <h4 class="title" slot="blockTitle">
          {{ $t('pages/account/user/forms/Clinician/resPathologistBlock') }}
        </h4>
        <div slot="blockContent">
          <div class="md-layout lims-form-row">
            <div class="md-layout-item md-size-50 md-small-size-100">
              <label>{{ $t('pages/account/user/forms/Clinician/resPathologist/availablePathologistsLabel') }}</label>
              <v-select
                multiple
                slot="field-outside"
                v-model="restrictedPathologistSelected"
                :options="restrictedPathologistsPending"
                :placeholder="$t('pages/account/user/forms/Clinician/resPathologist/availablePathologistsPlaceholder')"
                :disabled="viewMode"
                tabenable="yes"
                :selectable="(option) => !restrictedPathologistSelected.includes(option)"
                :tabindex="24"
              >
                <template #option="{ label }">{{ label }}</template>
                <template #selected-option="{ label }">{{ label }}</template>
              </v-select>
            </div>
            <div class="md-layout-item md-size-50 md-small-size-100">
              <md-button
                type="button"
                class="lims-form-button block01-btn md-primary"
                @click="addRestrictedPathologists"
                :disabled="viewMode || noRestrictedPathologist"
              >
                <template v-if="editRestrictedPathologistMode">
                  {{ $t('pages/account/user/forms/Clinician/resPathologist/resPathologistEditBtn') }}
                </template>
                <template v-else>
                  {{ $t('pages/account/user/forms/Clinician/resPathologist/resPathologistAddBtn') }}
                </template>
              </md-button>
            </div>
          </div>
          <div class="md-layout">
            <div class="md-layout-item md-size-100 md-small-size-100 textarea-field">
              <label>{{ $t('pages/account/user/forms/Clinician/resPathologist/commentLabel') }}</label>
              <md-field>
                <md-textarea v-model="restrictedComments" :disabled="viewMode" tabenable="yes"></md-textarea>
              </md-field>
            </div>
          </div>
          <div class="md-layout lims-form-row">
            <md-table class="custom-tbl tbl-drag" md-card>
              <md-table-row>
                <md-table-head width="40%">
                  {{ $t('pages/account/user/forms/Clinician/resPathologist/table.nameLabel') }}
                </md-table-head>
                <md-table-head>
                  {{ $t('pages/account/user/forms/Clinician/resPathologist/table.commentLabel') }}
                </md-table-head>
                <md-table-head class="th-center cell-action">
                  {{ $t('global/pages/list.actions') }}
                </md-table-head>
              </md-table-row>
              <draggable v-model="formData.conflictPathologists" class="tbl-body" @end="endDragRestrictedPathologist">
                <md-table-row v-for="(item, id) in formData.conflictPathologists" :key="item.id">
                  <md-table-cell class="cell-clinicType">{{ item.pathologistName }}</md-table-cell>
                  <md-table-cell>{{ item.comment }}</md-table-cell>
                  <md-table-cell class="custom-cell-action">
                    <span><md-icon>open_with</md-icon></span>
                    <span
                      v-show="!item.isAdminRestricted || !isMyProfileView"
                      @click="viewMode ? '' : editRestrictedPathologist(item, id)"
                      ><md-icon>edit</md-icon></span
                    >
                    <span
                      v-show="!item.isAdminRestricted || !isMyProfileView"
                      @click="viewMode ? '' : delRestrictedPathologist(item)"
                    >
                      <md-icon>close</md-icon>
                    </span>
                  </md-table-cell>
                </md-table-row>
              </draggable>
            </md-table>
          </div>
        </div>
      </lims-block>
      <!-- Clinician Associate -->
      <lims-block class="c-block01">
        <h4 class="title" slot="blockTitle">{{ $t('pages/account/user/forms/Clinician/clinicianAssociateBlock') }}</h4>
        <div slot="blockContent">
          <div class="md-layout" v-if="isDefaultView">
            <div class="md-layout-item md-size-50 md-small-size-100 selectClinic">
              <label>{{
                $t('pages/account/user/forms/Clinician/clinicianAssociate/availableClinicianAssociatesLabel')
              }}</label>
              <v-select
                multiple
                slot="field-outside"
                v-model="clinicianAssociateSelected"
                :options="clinicianAssociatesPending"
                :placeholder="
                  $t(
                    'pages/account/user/forms/Clinician/clinicianAssociate/availableClinicianAssociatesLabelPlaceholder',
                  )
                "
                :disabled="viewMode"
                tabenable="yes"
                :selectable="(option) => !clinicianAssociateSelected.includes(option)"
                :tabindex="1"
              >
                <template #option="{ label }">{{ label }}</template>
                <template #selected-option="{ label }">{{ label }}</template>
              </v-select>
            </div>
            <div class="md-layout-item md-size-50 md-small-size-100">
              <md-button
                type="button"
                class="lims-form-button block01-btn md-primary"
                @click="addClinicianAssociate"
                :disabled="viewMode || noClinicianAssociate"
                >{{ $t('pages/account/user/forms/Clinician/clinicianAssociate/addClinicianAssociate') }}</md-button
              >
            </div>
          </div>
          <div class="md-layout lims-form-row">
            <md-table class="custom-tbl" md-card>
              <md-table-row>
                <md-table-head>{{
                  $t('pages/account/user/forms/Clinician/clinicianAssociate/table.nameLabel')
                }}</md-table-head>
                <md-table-head class="th-center cell-action" v-if="isDefaultView">
                  {{ $t('global/pages/list.actions') }}
                </md-table-head>
              </md-table-row>
              <md-table-row v-for="cl in formData.clinicianAssociates" :key="cl.id">
                <md-table-cell>{{ cl.clinicianAssociateName }}</md-table-cell>
                <md-table-cell class="cell-action" v-if="isDefaultView">
                  <div @click="viewMode ? '' : delClinicianAssociate(cl)"><md-icon>close</md-icon></div>
                </md-table-cell>
              </md-table-row>
            </md-table>
          </div>
        </div>
      </lims-block>
      <!-- Notification -->
      <user-notifications
        v-if="formData.notificationSettings"
        v-model="formData.notificationSettings"
        :disabled="viewMode"
        :disabledSMS="!telephoneMobileData.telephoneMobile || telephoneMobileData.telephoneMobile == ''"
      ></user-notifications>
      <!-- Login -->
      <lims-block v-if="isDefaultView">
        <h4 class="title" slot="blockTitle">{{ $t('pages/account/user/forms/loginBlock') }}</h4>
        <div class="md-layout" slot="blockContent">
          <div class="md-layout lims-form-row">
            <div class="md-layout-item md-size-100 md-small-size-100">
              <md-checkbox v-model="formData.login.hasLogin" class="lims-checkbox" :disabled="viewMode" tabenable="yes">
                {{ $t('pages/account/user/forms/loginLabel') }}
              </md-checkbox>
            </div>
            <div class="md-layout-item md-size-100 md-small-size-100">
              <user-policy-actions
                v-if="!isAddMode"
                :userPolicyActions="userResource.userPolicyActions"
              ></user-policy-actions>
            </div>
          </div>
          <div v-if="userResource && userResource.lastTimeLogin" class="last-login">
            {{ $t('pages/account/user/forms/lastTimeLogin', { lastTimeLogin: getLastTimeLogin }) }}
          </div>
        </div>
      </lims-block>
      <!-- Display if the user has not completed their account -->
      <div v-if="!isMyProfileView" class="isNotCompleteAccount">
        <div v-if="!isAddMode && isCheckCompleteAccount">
          {{ $t('pages/account/user/forms/isNotCompleteAccount') }}
        </div>
      </div>
      <!-- Form action -->
      <div class="md-layout lims-form-row">
        <div class="md-layout-item md-size-100 md-small-size-100 lims-form-actions">
          <LimsFormCancel :redirectUrl="$redirectUrl()" />
          <md-button v-if="canSave" @click="$onSave()" class="md-button md-primary lims-form-button md-theme-default">
            {{ $t('global/button/button.save') }}
          </md-button>
          <LimsFormEditButton v-if="viewMode && $canEdit()" :editUrl="$editUrl" />
          <md-button
            v-if="!isAddMode && isDisabledUser && isDefaultView"
            class="md-primary lims-form-button"
            @click="$onEnableUser"
            >{{ $t('global/button/button.enable') }}</md-button
          >
          <template v-if="!isMyProfileView">
            <md-button
              v-if="isEditMode && isResetPasswordUser && isDefaultView"
              class="md-danger lims-form-button"
              @click="$onResetPassword"
              >{{ $t('global/button/button.resetPassword') }}</md-button
            >
          </template>
          <md-button
            v-if="isEditMode && isEnabledUser && isDefaultView"
            class="md-danger lims-form-button"
            @click="$onDisableUser"
            >{{ $t('global/button/button.disable') }}</md-button
          >
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
import draggable from 'vuedraggable';
import { FormMixins, TabMixins } from '@/core/mixins';
import UserMixins, { UPDATE_TWO_FA_MODE } from '@/pages/Account/User/user.mixins';
import UserRoleMixins from '@/pages/Account/User/user.role.mixins';
import UserService from '@/services/user.service';
import { FORM_MODES, SELECT_LIST_STATUS } from '@/core/constants';
import { getUserSchema } from '@/schemas/users.schema';
import UserNotifications from '../UserNotifications.vue';
import LimsTelInput from '@/components/Lims/LimsTelInput.vue';
import ModalHasLoginConfirm from '@/components/Lims/modals/ModalHasLoginConfirm.vue';
import ModalViewPermissions from '@/components/Lims/modals/ModalViewPermissions';
import { VERIFY_TW0_FA_MODE } from '@/core/constants';
import { ModalInputYourTelephone } from '@/components/Lims/modals';
import { ModalInputNewEmail } from '@/components/Lims/modals';
import { ModalVerifyCode } from '@/components/Lims/modals';
import { ModalUpdateSuccessfully } from '@/components/Lims/modals';
import { MyAccountService } from '@/services';

import { cloneDeep } from 'lodash';

export default {
  components: {
    draggable,
    UserNotifications,
    LimsTelInput,
    ModalHasLoginConfirm,
    ModalViewPermissions,
    ModalInputYourTelephone,
    ModalInputNewEmail,
    ModalVerifyCode,
    ModalUpdateSuccessfully,
  },
  mixins: [FormMixins, UserMixins, UserRoleMixins, TabMixins],
  created() {
    this.fetchData();
  },
  props: {
    userResource: {
      type: Object,
      require: false,
      default: null,
    },
    userId: {
      type: String,
      require: false,
      default: null,
    },
    viewMode: {
      type: Boolean,
      require: false,
      default: null,
    },
  },
  data() {
    return {
      formMode: FORM_MODES.ADD,
      formData: {
        personalInfo: {
          userType: this.USERTYPES_ENUM().find((e) => e.value === this.USER_TYPES().Clinician),
          userRole: '',
        },
        conflictPathologists: [],
        preferredPathologists: [],
        clinicianAssociates: [],
        login: {
          hasLogin: false,
        },
        notificationSettings: null,
      },
      userTitles: [],
      countries: [],
      roleList: [],
      telephoneMobileData: {},
      telephoneMobileDataInVerify: {},
      preferredPathologistSelected: [],
      preferredPathologistSelectList: [],
      preferredPathologistOldSelectedData: [],
      preferredPathologistOldSelectedLength: 0,
      noPreferredPathologist: true,
      onAddPreferredPathologist: false,
      restrictedPathologistSelectList: [],
      restrictedPathologistOldSelectedData: [],
      restrictedPathologistOldSelectedLength: 0,
      restrictedPathologistSelected: [],
      editRestrictedPathologistMode: false,
      selectedId: null,
      selectedUserId: null,
      restrictedComments: '',
      noRestrictedPathologist: true,
      onAddRestrictedPathologist: false,
      clinicianAssociateSelectList: [],
      clinicianAssociateSelected: [],
      noClinicianAssociate: true,
      specialitiesList: [],
      roleInformation: null,
      verifyMode: null,
      verifyContent: '',
      countryCode: null,
      phoneNumber: null,
      email: null,
    };
  },
  computed: {
    userSchema: function () {
      return getUserSchema('Clinician', this.formMode, {
        ...this.userModel,
      });
    },
    userTypesList() {
      return this.USERTYPES_ENUM();
    },
    disabledUsername() {
      return this.formMode === FORM_MODES.EDIT;
    },
    preferredPathologistsPending() {
      return this.preferredPathologistSelectList.filter((cli) => cli.status === SELECT_LIST_STATUS.PENDING);
    },
    restrictedPathologistsPending() {
      return this.restrictedPathologistSelectList.filter((cli) => cli.status === SELECT_LIST_STATUS.PENDING);
    },
    clinicianAssociatesPending() {
      return this.clinicianAssociateSelectList.filter((cli) => cli.status === SELECT_LIST_STATUS.PENDING);
    },
    roleIdChange() {
      return this.formData.personalInfo.roleId;
    },
    verifyTwoFaMode() {
      return VERIFY_TW0_FA_MODE.NEW;
    },
    onUpdateEmail() {
      return UPDATE_TWO_FA_MODE.EMAIL;
    },
  },
  watch: {
    preferredPathologistSelected() {
      this.noPreferredPathologist = this.preferredPathologistSelected.length === 0;

      if (this.onAddPreferredPathologist) {
        this.onAddPreferredPathologist = false;
      } else {
        if (this.preferredPathologistOldSelectedLength < this.preferredPathologistSelected.length) {
          let dataChangedPreferredPathologistList = this.preferredPathologistSelected.filter(
            (x) => !this.preferredPathologistOldSelectedData.includes(x),
          );
          this.changePreferredPathologistListStatus(
            dataChangedPreferredPathologistList[0].value,
            SELECT_LIST_STATUS.IN_PROGRESS,
          );
        } else if (this.preferredPathologistOldSelectedLength > this.preferredPathologistSelected.length) {
          let dataChangedPreferredPathologistList = this.preferredPathologistOldSelectedData.filter(
            (x) => !this.preferredPathologistSelected.includes(x),
          );
          this.changePreferredPathologistListStatus(
            dataChangedPreferredPathologistList[0].value,
            SELECT_LIST_STATUS.PENDING,
          );
        }
      }
      this.preferredPathologistOldSelectedLength = this.preferredPathologistSelected.length;
      this.preferredPathologistOldSelectedData = this.preferredPathologistSelected;
    },
    restrictedPathologistSelected() {
      this.noRestrictedPathologist = this.restrictedPathologistSelected.length === 0;

      if (this.onAddRestrictedPathologist) {
        this.onAddRestrictedPathologist = false;
      } else {
        if (this.restrictedPathologistOldSelectedLength < this.restrictedPathologistSelected.length) {
          let dataChangedRestrictedPathologistList = this.restrictedPathologistSelected.filter(
            (x) => !this.restrictedPathologistOldSelectedData.includes(x),
          );
          if (dataChangedRestrictedPathologistList && dataChangedRestrictedPathologistList.length > 0) {
            this.changeRestrictedPathologistListStatus(
              dataChangedRestrictedPathologistList[0].value,
              SELECT_LIST_STATUS.IN_PROGRESS,
            );
          }
        } else if (this.restrictedPathologistOldSelectedLength > this.restrictedPathologistSelected.length) {
          let dataChangedRestrictedPathologistList = this.restrictedPathologistOldSelectedData.filter(
            (x) => !this.restrictedPathologistSelected.includes(x),
          );
          if (dataChangedRestrictedPathologistList && dataChangedRestrictedPathologistList.length > 0) {
            this.changeRestrictedPathologistListStatus(
              dataChangedRestrictedPathologistList[0].value,
              SELECT_LIST_STATUS.PENDING,
            );
          }
        }
      }
      this.restrictedPathologistOldSelectedLength = this.restrictedPathologistSelected.length;
      this.restrictedPathologistOldSelectedData = cloneDeep(this.restrictedPathologistSelected);
    },
    clinicianAssociateSelected() {
      this.noClinicianAssociate = this.clinicianAssociateSelected.length === 0;
    },
  },
  methods: {
    async fetchData() {
      this.countries = await this.loadCountries();
      this.userTitles = this.USER_TITLES();
      if (this.isDefaultView) {
        this.roleList = await this.loadRoleList(this.USER_TYPES().Clinician);
      }
      this.preferredPathologistSelectList = await this.loadPreferredPathologist();
      this.restrictedPathologistSelectList = await this.loadRestrictedPathologist();
      this.clinicianAssociateSelectList = await this.loadClinicianAssociates();
      this.specialitiesList = await this.loadListSpeciality();
      if (this.userResource) {
        this.userModel = this.userResource;
        this.formMode = this.viewMode ? FORM_MODES.VIEW : FORM_MODES.EDIT;
        this.formData = {
          personalInfo: {
            ...this.userModel.personalInfo,
            userType: this.USERTYPES_ENUM().find((e) => e.value === this.USER_TYPES().Clinician),
            roleId: this.roleList.find((e) => e.value === this.userModel.personalInfo.roleId),
            countryId: this.countries.find((e) => e.value === this.userModel.personalInfo.countryId),
            titleId: this.userTitles.find((e) => e.value === this.userModel.personalInfo.titleId),
            specialityId: this.userModel.personalInfo.clinicianSpecialtyId
              ? this.specialitiesList.find((e) => e.value === this.userModel.personalInfo.clinicianSpecialtyId)
              : null,
          },
          clinicianCases: this.userModel.clinicianCases,
          conflictPathologists: this.userModel.conflictPathologists,
          clinicianAssociates: this.userModel.clinicianAssociates,
          preferredPathologists: this.userModel.preferredPathologists,
          login: this.userModel.login
            ? {
                hasLogin: this.userModel.login.hasLogin,
              }
            : null,
          notificationSettings: this.userModel.notificationSettings,
        };
        this.telephoneMobileData = this.$getTelephoneMobileData();
        this.telephoneMobileDataInVerify = this.$getTelephoneMobileDataInVerify();
        this.$setHeadingTitle(this.formData.personalInfo.username);
      }
      this.$nextTick(function () {
        this.$resetChangeDetection();
      });
      this.$setCountryAndMobileCodeFollowGlobalVariablesDataInAddMode();
    },
    async loadPreferredPathologist() {
      let responseData;

      if (this.isDefaultView) {
        responseData = await UserService.findPreferredPathologist({
          userId: this.userId,
          search: '',
        });
      } else {
        if (this.isMyProfileView) {
          responseData = await MyAccountService.findPreferredPathologist({
            userId: this.userId,
            search: '',
          });
        }
      }
      return responseData.data.map((item) => {
        return {
          value: item.id,
          specialty: item.specialty,
          label: item.text,
          status: SELECT_LIST_STATUS.PENDING,
        };
      });
    },
    async loadRestrictedPathologist() {
      let responseData;
      if (this.isDefaultView) {
        responseData = await UserService.findRestrictedPathologist({
          userId: this.userId,
          search: '',
        });
      } else {
        if (this.isMyProfileView) {
          responseData = await MyAccountService.findRestrictedPathologist({
            userId: this.userId,
            search: '',
          });
        }
      }
      return responseData.data.map((item) => {
        return {
          value: item.id,
          comment: item.comment,
          label: item.text,
          status: SELECT_LIST_STATUS.PENDING,
        };
      });
    },
    async loadClinicianAssociates() {
      let responseData;
      if (this.isDefaultView) {
        responseData = await UserService.findClinicianAsssociates({
          userId: this.userId,
          search: '',
        });
        return responseData.data.map((item) => {
          return {
            value: item.id,
            label: item.text,
            status: SELECT_LIST_STATUS.PENDING,
          };
        });
      }
    },
    async loadListSpeciality() {
      let responseData;
      if (this.isDefaultView) {
        responseData = await UserService.getListSpeciality();
      } else {
        if (this.isMyProfileView) {
          responseData = await MyAccountService.getListSpeciality();
        }
      }
      return responseData.data.map((item) => {
        return {
          value: item.id,
          label: item.text,
        };
      });
    },
    changePreferredPathologistListStatus(userIdPreferredPathologist, status) {
      let dataPreferredPathologist = this.preferredPathologistSelectList.find(
        (e) => e.value === userIdPreferredPathologist,
      );
      let id = this.preferredPathologistSelectList.findIndex(
        (preferredPathologistSelect) => preferredPathologistSelect.value === dataPreferredPathologist.value,
      );
      this.preferredPathologistSelectList[id].status = status;
      let idx = this.restrictedPathologistSelectList.findIndex(
        (preferredPathologistSelect) => preferredPathologistSelect.value === dataPreferredPathologist.value,
      );
      this.restrictedPathologistSelectList[idx].status = status;
    },
    changeRestrictedPathologistListStatus(userIdRestrictedPathologist, status) {
      let dataRestrictedPathologist = this.restrictedPathologistSelectList.find(
        (e) => e.value === userIdRestrictedPathologist,
      );
      let idx = this.restrictedPathologistSelectList.findIndex(
        (restrictedPathologistSelect) => restrictedPathologistSelect.value === dataRestrictedPathologist.value,
      );
      this.restrictedPathologistSelectList[idx].status = status;
      let id = this.preferredPathologistSelectList.findIndex(
        (restrictedPathologistSelect) => restrictedPathologistSelect.value === dataRestrictedPathologist.value,
      );
      this.preferredPathologistSelectList[id].status = status;
    },
    addPreferredPathologist() {
      this.onAddPreferredPathologist = true;
      if (this.preferredPathologistSelected) {
        this.preferredPathologistSelected.forEach((item) => {
          this.changePreferredPathologistListStatus(item.value, SELECT_LIST_STATUS.IN_PROGRESS);
          this.formData.preferredPathologists.push({
            userId: item.value,
            specialty: item.specialty,
            pathologistName: item.label,
          });
        });
      }
      this.preferredPathologistSelected = [];
    },
    delPreferredPathologist(item) {
      let dataPreferred = this.preferredPathologistSelectList.find((e) => e.value === item.userId);
      if (dataPreferred) {
        this.changePreferredPathologistListStatus(dataPreferred.value, SELECT_LIST_STATUS.PENDING);
      } else {
        this.preferredPathologistSelectList.push({
          value: item.userId,
          specialty: item.specialty,
          label: item.pathologistName,
          status: SELECT_LIST_STATUS.PENDING,
        });
        this.restrictedPathologistSelectList.push({
          value: item.userId,
          comment: item.comment,
          label: item.pathologistName,
          status: SELECT_LIST_STATUS.PENDING,
        });
      }
      const idx = this.formData.preferredPathologists.findIndex(
        (preferredPathologist) => preferredPathologist.userId === item.userId,
      );
      this.formData.preferredPathologists.splice(idx, 1);
    },
    addRestrictedPathologists() {
      this.onAddRestrictedPathologist = true;
      if (this.restrictedPathologistSelected) {
        if (this.editRestrictedPathologistMode) {
          this.restrictedPathologistSelected.forEach((item) => {
            this.changeRestrictedPathologistListStatus(item.value, SELECT_LIST_STATUS.IN_PROGRESS);
            if (item.value === this.selectedUserId) {
              this.formData.conflictPathologists[this.selectedId].pathologistName = item.label;
              this.formData.conflictPathologists[this.selectedId].comment = this.restrictedComments;
              this.formData.conflictPathologists[this.selectedId].userId = item.value;
            } else {
              this.formData.conflictPathologists.push({
                pathologistName: item.label,
                comment: this.restrictedComments,
                userId: item.value,
              });
            }
          });
          this.editRestrictedPathologistMode = false;
        } else {
          this.restrictedPathologistSelected.forEach((item) => {
            this.changeRestrictedPathologistListStatus(item.value, SELECT_LIST_STATUS.IN_PROGRESS);
            this.formData.conflictPathologists.push({
              pathologistName: item.label,
              comment: this.restrictedComments,
              userId: item.value,
            });
          });
        }
        this.restrictedComments = '';
      }
      this.restrictedPathologistSelected = [];
    },
    delRestrictedPathologist(item) {
      let restrictedPathologist = this.restrictedPathologistSelectList.find((e) => e.value === item.userId);
      if (restrictedPathologist) {
        this.changeRestrictedPathologistListStatus(restrictedPathologist.value, SELECT_LIST_STATUS.PENDING);
      } else {
        this.restrictedPathologistSelectList.push({
          value: item.userId,
          comment: item.comment,
          label: item.pathologistName,
          status: SELECT_LIST_STATUS.PENDING,
        });
        this.preferredPathologistSelectList.push({
          value: item.userId,
          specialty: item.specialty,
          label: item.pathologistName,
          status: SELECT_LIST_STATUS.PENDING,
        });
      }
      const idx = this.formData.conflictPathologists.findIndex(
        (conflictPathologist) => conflictPathologist.userId === item.userId,
      );
      this.formData.conflictPathologists.splice(idx, 1);
    },
    editRestrictedPathologist(item, idx) {
      if (this.editRestrictedPathologistMode) {
        return;
      } else {
        this.editRestrictedPathologistMode = true;
        let restrictedPathologist = this.restrictedPathologistSelectList.find((e) => e.value === item.userId);
        if (restrictedPathologist) {
          this.changeRestrictedPathologistListStatus(restrictedPathologist.value, SELECT_LIST_STATUS.PENDING);
        } else {
          this.restrictedPathologistSelectList.push({
            value: item.userId,
            comment: item.comment,
            label: item.pathologistName,
            status: SELECT_LIST_STATUS.PENDING,
          });
          this.preferredPathologistSelectList.push({
            value: item.userId,
            specialty: item.specialty,
            label: item.pathologistName,
            status: SELECT_LIST_STATUS.PENDING,
          });
        }
        let restrictedPathologistEdited = this.restrictedPathologistSelectList.find((e) => e.value === item.userId);
        this.restrictedPathologistSelected.push(restrictedPathologistEdited);
        this.restrictedComments = item.comment;
        this.selectedId = idx;
        this.selectedUserId = item.userId;
      }
    },
    addClinicianAssociate() {
      if (this.clinicianAssociateSelected) {
        this.clinicianAssociateSelected.forEach((item) => {
          let clinicianAssociate = this.clinicianAssociateSelectList.find((e) => e.value === item.value);
          this.formData.clinicianAssociates.push({
            userId: clinicianAssociate.value,
            clinicianAssociateName: clinicianAssociate.label,
          });
          let id = this.clinicianAssociateSelectList.findIndex(
            (clinicianAssociateSelect) => clinicianAssociateSelect.value === clinicianAssociate.value,
          );
          this.clinicianAssociateSelectList[id].status = SELECT_LIST_STATUS.IN_PROGRESS;
        });
      }
      this.clinicianAssociateSelected = [];
    },
    delClinicianAssociate(item) {
      let clinicianAssociate = this.clinicianAssociateSelectList.find((e) => e.value === item.userId);
      if (clinicianAssociate) {
        let id = this.clinicianAssociateSelectList.findIndex(
          (clinicianAssociateSelect) => clinicianAssociateSelect.value === clinicianAssociate.value,
        );
        this.clinicianAssociateSelectList[id].status = SELECT_LIST_STATUS.PENDING;
      } else {
        this.clinicianAssociateSelectList.push({
          value: item.id,
          label: item.clinicianAssociateName,
          status: SELECT_LIST_STATUS.PENDING,
        });
      }
      const idx = this.formData.clinicianAssociates.findIndex(
        (clinicianAssociate) => clinicianAssociate.userId === item.userId,
      );
      this.formData.clinicianAssociates.splice(idx, 1);
    },
    endDrag() {
      this.formData.preferredPathologists.forEach((item, index) => {
        item.displayOrder = index + 1;
      });
    },
    mappingPreferredPathologistData() {
      return this.formData.preferredPathologists.map((item, index) => {
        return {
          userId: item.userId,
          displayOrder: index + 1,
        };
      });
    },
    mappingRestrictedPathologistData() {
      return this.formData.conflictPathologists.map((item, index) => {
        return {
          userId: item.userId,
          comment: item.comment,
          displayOrder: index + 1,
        };
      });
    },
    createUser() {
      return UserService.create({
        personalInfo: {
          ...this.formData.personalInfo,
          roleId: this.formData.personalInfo.roleId.value,
          titleId: this.formData.personalInfo.titleId.value,
          countryId: this.formData.personalInfo.countryId.value,
          countryCodeMobile: this.telephoneMobileData.countryCodeMobile
            ? this.telephoneMobileData.countryCodeMobile.code
            : '',
          localeMobile: this.telephoneMobileData.countryCodeMobile
            ? this.telephoneMobileData.countryCodeMobile.locale
            : '',
          telephoneMobile: this.telephoneMobileData.telephoneMobile,
          countryIdMobile: this.telephoneMobileData.telephoneMobile
            ? this.telephoneMobileData.countryCodeMobile.countryId
            : null,
          clinicianSpecialtyId: this.formData.personalInfo.specialityId
            ? this.formData.personalInfo.specialityId.value
            : null,
        },
        preferredPathologists: this.mappingPreferredPathologistData(),
        conflictPathologists: this.mappingRestrictedPathologistData(),
        clinicianAssociates: this.formData.clinicianAssociates,
        login: {
          hasLogin: this.formData.login.hasLogin,
        },
        notificationSettings: this.formData.notificationSettings,
      });
    },
    updateUser() {
      if (this.$isMyProfileView()) {
        return this.updateProfile();
      }
      return UserService.updateOne({
        userId: this.userId,
        personalInfo: {
          ...this.formData.personalInfo,
          roleId: this.formData.personalInfo.roleId.value,
          titleId: this.formData.personalInfo.titleId.value,
          countryId: this.formData.personalInfo.countryId.value,
          countryCodeMobile: this.telephoneMobileData.countryCodeMobile
            ? this.telephoneMobileData.countryCodeMobile.code
            : '',
          localeMobile: this.telephoneMobileData.countryCodeMobile
            ? this.telephoneMobileData.countryCodeMobile.locale
            : '',
          telephoneMobile: this.telephoneMobileData.telephoneMobile,
          countryIdMobile: this.telephoneMobileData.telephoneMobile
            ? this.telephoneMobileData.countryCodeMobile.countryId
            : null,
          clinicianSpecialtyId: this.formData.personalInfo.specialityId
            ? this.formData.personalInfo.specialityId.value
            : null,
        },
        preferredPathologists: this.mappingPreferredPathologistData(),
        conflictPathologists: this.mappingRestrictedPathologistData(),
        clinicianAssociates: this.formData.clinicianAssociates,
        login: {
          hasLogin: this.formData.login.hasLogin,
        },
        notificationSettings: this.formData.notificationSettings,
      });
    },
    updateProfile() {
      return MyAccountService.updateProfile({
        userId: this.userId,
        personalInfo: {
          ...this.formData.personalInfo,
          titleId: this.formData.personalInfo.titleId.value,
          countryId: this.formData.personalInfo.countryId.value,
          clinicianSpecialtyId: this.formData.personalInfo.specialityId
            ? this.formData.personalInfo.specialityId.value
            : null,
          telephoneMobile: this.telephoneMobileData.telephoneMobile,
        },
        preferredPathologists: this.mappingPreferredPathologistData(),
        conflictPathologists: this.mappingRestrictedPathologistData(),
        notificationSettings: this.formData.notificationSettings,
      });
    },
    onClickViewPermissions() {
      if (this.formData.personalInfo.roleId) {
        this.$refs.viewPermissions.open();
      }
    },
    endDragRestrictedPathologist() {
      this.formData.conflictPathologists.forEach((item, index) => {
        item.displayOrder = index + 1;
      });
      //reset data form when drag
      this.editRestrictedPathologistMode = false;
      this.restrictedComments = '';
      this.restrictedPathologistSelected = [];
    },
  },
};
</script>
<style lang="scss">
.resPathologist-block .md-layout-item {
  padding: 0;
}
.custom-cell-action {
  width: 150px;
  min-width: 150px;
  padding-left: 50px;
}
</style>
