<template>
  <div class="md-layout lims-form" v-if="formData">
    <modal-bulk-action-problem-slide
      ref="bulkActionProblemSlideModal"
      :bulkActionSelect="bulkAction"
      @onContinue="onContinueBulkAction"
    ></modal-bulk-action-problem-slide>
    <modal-bulk-action-slide
      ref="bulkActionSlideModal"
      :bulkActionSelect="bulkAction"
      @onCompletedAction="onCompleteBulkAction"
    ></modal-bulk-action-slide>
    <lims-block class="patient-data-block">
      <h4 class="title" slot="blockTitle">{{ $t('pages/case/form/patientData/blockTitle') }}</h4>
      <lims-collapse slot="blockContent" :collapse="['Case Collapse']" icon="keyboard_arrow_down">
        <patient-data
          slot="md-collapse-pane-1"
          :dataEdit="formData.patientData"
          :onHideCountdownPullCase="true"
        ></patient-data>
      </lims-collapse>
    </lims-block>
    <lims-block class="specimen-block">
      <h4 class="title" slot="blockTitle">{{ $t('pages/case/form/specimen/blockTitle') }}</h4>
      <lims-collapse slot="blockContent" :collapse="['Case Collapse']" icon="keyboard_arrow_down">
        <specimen slot="md-collapse-pane-1" :dataEdit="formData.caseSpecimens"></specimen>
      </lims-collapse>
    </lims-block>
    <lims-block class="slide-detail-block">
      <h4 class="title" slot="blockTitle">{{ $t('pages/case/form/slideDetail/blockTitle') }}</h4>
      <lims-collapse slot="blockContent" :collapse="['Case Collapse']" icon="keyboard_arrow_down">
        <slide-detail slot="md-collapse-pane-1" :dataEdit="formData"></slide-detail>
      </lims-collapse>
    </lims-block>
    <!-- Begin action form -->
    <div class="md-layout lims-form-row">
      <div class="md-layout-item md-size-100 md-small-size-100 lims-form-actions">
        <lims-form-cancel></lims-form-cancel>
        <md-button
          v-if="canBeSendToPath()"
          @click="onApplyAction(actionSendToPath)"
          class="md-button md-primary lims-form-button md-theme-default"
        >
          {{ $t('pages/case/slideManagement/list/bulkActions/SendToPath') }}
        </md-button>
        <md-button
          v-if="canBeReturnToLab()"
          @click="onApplyAction(actionReturnToLab)"
          class="md-button md-primary lims-form-button md-theme-default"
        >
          {{ $t('pages/case/slideManagement/list/bulkActions/ReturnToLab') }}
        </md-button>
        <md-button
          v-if="canBeReturnToClinic()"
          @click="onApplyAction(actionReturnToClinic)"
          class="md-button md-primary lims-form-button md-theme-default"
        >
          {{ $t('pages/case/slideManagement/list/bulkActions/ReturnToClinic') }}
        </md-button>
        <md-button
          v-if="canBeSetToExternal()"
          @click="onApplyAction(actionSetToExternal)"
          class="md-button md-primary lims-form-button md-theme-default"
        >
          {{ $t('pages/case/slideManagement/list/bulkActions/SetToExternal') }}
        </md-button>
      </div>
    </div>
  </div>
</template>

<script>
import { FormMixins } from '@/core/mixins';
import {
  CASE_SLIDE_LOCATION_FLAG,
  CASE_STATUS,
  FORM_MODES,
  LOCATION_FLAG_NAMES,
  LOCATION_BULK_ACTIONS,
} from '@/core/constants';
import LimsCollapse from '@/components/Lims/LimsCollapse';
import PatientData from '@/pages/Case/CaseManagement/Components/PatientDataView.vue';
import Specimen from '@/pages/Case/SlideManagement/Components/Specimen.vue';
import SlideDetail from '@/pages/Case/SlideManagement/Components/SlideDetail.vue';
import ModalBulkActionProblemSlide from '@/components/Lims/modals/ModalBulkActionProblemSlide.vue';
import ModalBulkActionSlide from '@/components/Lims/modals/ModalBulkActionSlide.vue';
import { caseSlideService } from '@/services';
import slideMixins, { mappingLocationFlagOptionsToObject } from '../slide.mixins';
import { mapActions } from 'vuex';

const mappingSlideLocationsToPreconditions = (caseSlideLocations) => {
  const locationFlags = Reflect.ownKeys(LOCATION_FLAG_NAMES);
  const preconditions = {};
  locationFlags.forEach((flag) => {
    Reflect.set(preconditions, flag, false);
  });
  caseSlideLocations.forEach((location) => {
    if (Reflect.has(preconditions, location.locationFlag)) {
      Reflect.set(preconditions, location.locationFlag, true);
    }
  });
  return preconditions;
};

export default {
  mixins: [FormMixins, slideMixins],
  components: {
    LimsCollapse,
    PatientData,
    Specimen,
    SlideDetail,
    ModalBulkActionProblemSlide,
    ModalBulkActionSlide,
  },
  async created() {
    await this.fetchData();
  },
  props: {
    formMode: {
      type: Number,
      require: true,
      validator: function (value) {
        // The value must match one of these strings
        return Object.values(FORM_MODES).indexOf(value) !== -1;
      },
    },
    slideResource: {
      type: Object,
      require: false,
    },
  },
  data() {
    return {
      formData: null,
      actionConfirm: '',
      valuelocationFlagChange: '',
      locationFlags: null,
      preconditions: null,
      bulkAction: { value: '' },
    };
  },
  computed: {
    CASE_SLIDE_LOCATION_FLAG: function () {
      return CASE_SLIDE_LOCATION_FLAG;
    },
    actionSendToPath() {
      return LOCATION_BULK_ACTIONS.SendToPath;
    },
    actionSetToExternal() {
      return LOCATION_BULK_ACTIONS.SetToExternal;
    },
    actionAddTrackingNo() {
      return LOCATION_BULK_ACTIONS.AddTrackingNo;
    },
    actionReturnToLab() {
      return LOCATION_BULK_ACTIONS.ReturnToLab;
    },
    actionReturnToClinic() {
      return LOCATION_BULK_ACTIONS.ReturnToClinic;
    },
  },
  watch: {
    slideResource: {
      deep: true,
      handler: function (val) {
        if (val) {
          this.bindingFormData(val);
        }
      },
    },
  },
  methods: {
    ...mapActions('app/data', ['updateDataset']),
    bindingFormData(slideResource) {
      this.formData = {
        patientData: slideResource.case,
        caseSpecimens: slideResource.case,
        slideDetail: [slideResource.caseSpecimen],
        locationFlag: slideResource.locationFlag,
        locationFlagId: slideResource.locationFlagId,
        caseSpecimenBlock: slideResource.caseSpecimenBlock,
        slideId: slideResource.slideId,
        slideName: slideResource.slideName,
        comment: slideResource.comment,
        trackingNo: slideResource.trackingNo,
        caseSlideLocations: slideResource.caseSlideLocations,
        tracking: slideResource.case.tracking ? slideResource.case.tracking : '',
        typeName: slideResource.typeName,
        batchId: slideResource.batchId ? slideResource.batchId : null,
        alwRequestType: slideResource.alwRequestType ? slideResource.alwRequestType : null,
        respondeOn: slideResource.respondeOn ? slideResource.respondeOn : null,
      };
      this.preconditions = mappingSlideLocationsToPreconditions(slideResource.caseSlideLocations);
    },
    async fetchData() {
      const locationFlagOptions = await caseSlideService.getSlideLocationFlags();
      // no need prefix
      this.locationFlags = mappingLocationFlagOptionsToObject(locationFlagOptions, false);
      // save datasource to state
      this.updateDataset({
        ...locationFlagOptions,
      });
      // preconditions
      this.bindingFormData(this.slideResource);
    },

    canBeSendToPath() {
      // Lab = YES
      // AND Sent to Path = No,
      // AND Return to Lab = No,
      // AND Return to Clinic = No,
      // AND case’s Pathologist is NOT PULL
      const isLabYes = this.preconditions[LOCATION_FLAG_NAMES.Lab];
      const isSendToPathNo = !this.preconditions[LOCATION_FLAG_NAMES.SentToPath];
      const isReturnedToLabNo = !this.preconditions[LOCATION_FLAG_NAMES.ReturnedToLab];
      const isReturnToClinicNo = !this.preconditions[LOCATION_FLAG_NAMES.ReturnedToClinic];
      const isPathologistNotPull = !!this.slideResource.case.pathologistId;
      const isValidPrecondition =
        isLabYes && isSendToPathNo && isReturnedToLabNo && isReturnToClinicNo && isPathologistNotPull;
      const isValidStatus =
        this.slideResource.case.status == CASE_STATUS.LAB ||
        this.slideResource.case.status == CASE_STATUS.PATH ||
        this.slideResource.case.status == CASE_STATUS.PROVISIONALLY_REPORTED;
      return isValidStatus && isValidPrecondition;
    },
    canBeReturnToLab() {
      // Lab = YES AND
      // Sent to Path = Yes, AND
      // Returned to Lab = No, AND
      // Return to Clinic = No
      // AND case’s Pathologist is NOT PULL
      const isLabYes = this.preconditions[LOCATION_FLAG_NAMES.Lab];
      const isSendToPathYes = this.preconditions[LOCATION_FLAG_NAMES.SentToPath];
      const isReturnedToLabNo = !this.preconditions[LOCATION_FLAG_NAMES.ReturnedToLab];
      const isReturnToClinicNo = !this.preconditions[LOCATION_FLAG_NAMES.ReturnedToClinic];
      const isPathologistNotPull = !!this.slideResource.case.pathologistId;
      const isValidPrecondition =
        isLabYes && isSendToPathYes && isReturnedToLabNo && isReturnToClinicNo && isPathologistNotPull;
      // const isValidStatus = this.slideResource.case.status == CASE_STATUS.REPORTED;
      // return isValidStatus && isValidPrecondition;
      return isValidPrecondition;
    },
    canBeAddTrackingNo() {
      return true;
    },
    canBeReturnToClinic() {
      // 1st scenario: Lab = YES AND Sent to Path = Yes AND Returned to Lab = Yes AND Returned to Clinic = No
      // AND case’s Pathologist is NOT PULL

      // 2nd scenario: Lab = YES AND Sent To Path = No AND Returned to Lab = No AND Returned to Clinic = No
      // AND case’s Pathologist is NOT
      const isLabYes = this.preconditions[LOCATION_FLAG_NAMES.Lab];
      const isSendToPathYes = this.preconditions[LOCATION_FLAG_NAMES.SentToPath];
      const isSendToPathNo = !this.preconditions[LOCATION_FLAG_NAMES.SentToPath];
      const isReturnedToLabYes = this.preconditions[LOCATION_FLAG_NAMES.ReturnedToLab];
      const isReturnedToLabNo = !this.preconditions[LOCATION_FLAG_NAMES.ReturnedToLab];
      const isReturnToClinicNo = !this.preconditions[LOCATION_FLAG_NAMES.ReturnedToClinic];
      const isPathologistNotPull = !!this.slideResource.case.pathologistId;
      const isValidPrecondition =
        ((isLabYes && isSendToPathYes && isReturnedToLabYes && isReturnToClinicNo) ||
          (isLabYes && isSendToPathNo && isReturnedToLabNo && isReturnToClinicNo)) &&
        isPathologistNotPull;
      const isValidStatus = this.slideResource.case.status == CASE_STATUS.REPORTED;
      return isValidStatus && isValidPrecondition;
    },
    canBeSetToExternal() {
      // any location flags - the current flag is not external and case's pathologist is NOT PULL
      const isSetToExternalNo = this.preconditions[LOCATION_FLAG_NAMES.SetToExternal];

      const isPathologistNotPull = !!this.slideResource.case.pathologistId;
      const isValidPrecondition = isSetToExternalNo && isPathologistNotPull;
      const isValidStatus = this.slideResource.case.status == CASE_STATUS.REPORTED;
      return isValidStatus && isValidPrecondition;
    },

    async onApplyAction(action) {
      this.bulkAction = {
        value: action,
        label: this.$translate(`pages/case/slideManagement/list/bulkActions/${action}`),
      };

      const { caseSpecimenBlock } = this.slideResource;
      const slideData = {
        ...this.slideResource,
        caseSpecimenBlock: {
          ...caseSpecimenBlock,
          block: { id: caseSpecimenBlock.caseSpecimenBlockId, text: caseSpecimenBlock.block },
        },
        slide: {
          fieldItemId: this.slideResource?.slideId,
          caseSpecimenBlockItemReferenceId: this.slideResource?.caseSlideLocations[0]?.caseSpecimenBlockItemReferenceId,
        },
      };
      await this.$processAction({
        action: this.bulkAction?.value,
        slides: [
          {
            ...slideData,
          },
        ],
        bulkActionSlideModal: this.$refs.bulkActionSlideModal,
        bulkActionProblemSlideModal: this.$refs.bulkActionProblemSlideModal,
      });
    },

    setValueChangeLocation() {},
    onContinueBulkAction() {
      this.$refs.bulkActionSlideModal.open();
    },
    onCompleteBulkAction({ action }) {
      // clear selected checkbox and action
      this.$alertSuccess(
        this.$t('pages/case/slideManagement/form/update.success', {
          slide: this.formData.slideName,
          block: this.formData.caseSpecimenBlock.block,
          locationFlagUpdate: this.$t('entities/case/bulkActionName.' + action),
        }),
      );
      this.$emit('onReload');
    },
  },
};
</script>

<style lang="scss"></style>
