<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit, invalid }">
    <form class="md-layout lims-form form-login" @submit.prevent="handleSubmit()">
      <md-dialog :md-close-on-esc="true" :md-active.sync="isVisible" @md-clicked-outside="close" class="lims-modal">
        <div class="dialog-icon-close" @click="close"><md-icon>close</md-icon></div>
        <md-dialog-title>{{ $t('components/ModalBulkActionProblem.title') }}</md-dialog-title>
        <div class="lims-form dialog-content">
          <template v-if="haveAtLeastOneNotCompatibleCase">
            <p
              class="text-center"
              v-html="
                $t('components/ModalBulkActionProblem/actionCannotBePerformed', {
                  bulkAction: $translateBulkActionSelect(bulkActionSelect),
                })
              "
            ></p>
          </template>
          <template v-if="isHaveIncompatibleStatus">
            <p
              class="text-center"
              v-html="
                $t('components/ModalBulkActionProblem/incompatibleStatus', {
                  bulkAction: $translateBulkActionSelect(bulkActionSelect),
                  listOfCaseStatusIsAllowed: notCompatibleCase.incompatibleStatus.allowValue,
                })
              "
            ></p>
            <ul class="list-of-compatible-cases">
              <li
                v-for="item in getPartOfCases(notCompatibleCase.incompatibleStatus.listCasesFailure)"
                :key="item.caseId"
              >
                {{ item.caseReference }}
              </li>
            </ul>
          </template>

          <template v-if="isHaveSbImportSameLaboratory">
            <p
              class="text-center"
              v-html="
                $t('components/ModalBulkActionProblem/sbImportSameLaboratory', {
                  isAnd: isHaveIncompatibleStatus ? 'AND' : '',
                  laboratoryOfCompatibleCases: notCompatibleCase.sbImportSameLaboratory.allowValue,
                })
              "
            ></p>
            <ul class="list-of-compatible-cases">
              <li
                v-for="item in getPartOfCases(notCompatibleCase.sbImportSameLaboratory.listCasesFailure)"
                :key="item.caseId"
              >
                {{ item.caseReference }}
              </li>
            </ul>
          </template>

          <template v-if="isHaveDiscardTissueUnCheckRemaining">
            <p
              class="text-center"
              v-html="
                $t('components/ModalBulkActionProblem/discardTissueUnCheckRemaining', {
                  isAnd: isHaveIncompatibleStatus ? 'AND' : '',
                })
              "
            ></p>
            <ul class="list-of-compatible-cases">
              <li
                v-for="item in getPartOfCases(notCompatibleCase.discardTissueUnCheckRemaining.listCasesFailure)"
                :key="item.caseId"
              >
                {{ item.caseReference }}
              </li>
            </ul>
          </template>

          <template v-if="isHaveInValidCaseSource">
            <p
              class="text-center"
              v-html="
                $t('components/ModalBulkActionProblem/isHaveInValidCaseSource', {
                  isAnd: isHaveIncompatibleStatus ? 'AND' : '',
                })
              "
            ></p>
            <ul class="list-of-compatible-cases">
              <li
                v-for="item in getPartOfCases(notCompatibleCase.inValidCaseSource.listCasesFailure)"
                :key="item.caseId"
              >
                {{ item.caseReference }}
              </li>
            </ul>
          </template>

          <template v-if="bulkActionSelect == bulkAction.RemoveBillingFlag">
            <p
              class="text-center"
              v-html="
                $t('components/ModalBulkActionProblem/RemoveBillingFlag', {
                  bulkAction: $translateBulkActionSelect(bulkActionSelect),
                })
              "
            ></p>
            <ul class="list-of-compatible-cases">
              <li v-for="item in getPartOfCases(notCompatibleCase)" :key="item.caseId">
                {{ item.caseReference }}
              </li>
            </ul>
          </template>

          <template v-if="bulkActionSelect == bulkAction.BatchChange">
            <p
              class="text-center"
              v-html="
                $t('components/ModalBulkActionProblem/BatchChange', {
                  bulkAction: $translateBulkActionSelect(bulkActionSelect),
                  field: batchChangeSelect.fieldName,
                  fieldValue: batchChangeSelect.fieldValue,
                })
              "
            ></p>
            <ul class="list-of-compatible-cases">
              <li v-for="item in getPartOfCases(notCompatibleCase)" :key="item.caseId">
                {{ item.caseReference }} - {{ translateReasonFailBatchChange(item.reason) }}
              </li>
            </ul>
          </template>

          <template v-if="compatibleCase && compatibleCase.length > 0">
            <p class="text-center" v-html="$t('components/ModalBulkActionProblem.text2')"></p>
            <ul class="list-of-compatible-cases">
              <li v-for="item in getPartOfCases(compatibleCase)" :key="item.caseId">
                {{ item.caseReference ? item.caseReference : item }}
              </li>
            </ul>
          </template>
          <p v-else class="text-center" v-html="$t('components/ModalBulkActionProblem.text3')"></p>
        </div>
        <md-dialog-actions>
          <template v-if="compatibleCase && compatibleCase.length > 0">
            <md-button @click="close" class="lims-form-button">{{ $t('global/button/button.cancel') }}</md-button>
            <md-button @click="onContinue" :disabled="invalid" class="lims-form-button md-primary">{{
              $t('global/button/button.continue')
            }}</md-button>
          </template>
          <md-button v-else @click="close" class="lims-form-button md-primary">{{
            $t('global/button/button.backToList')
          }}</md-button>
        </md-dialog-actions>
      </md-dialog>
    </form>
  </ValidationObserver>
</template>

<script>
import { BULK_ACTION } from '@/core/constants';
import modalMixins from './modal.mixins';

export default {
  name: 'ModalBulkActionProblem',
  mixins: [modalMixins],
  props: {
    bulkActionSelect: {
      require: true,
    },
    compatibleCase: {
      type: Array,
      require: true,
    },
    notCompatibleCase: {
      require: true,
    },
    batchChangeSelect: {
      require: true,
    },
  },
  data() {
    return {
      masterCheckBoxData: null,
    };
  },
  computed: {
    bulkAction() {
      return BULK_ACTION;
    },
    haveAtLeastOneNotCompatibleCase() {
      return (
        this.isHaveIncompatibleStatus ||
        this.isHaveDiscardTissueUnCheckRemaining ||
        this.isHaveSbImportSameLaboratory ||
        this.isHaveInValidCaseSource
      );
    },
    isHaveIncompatibleStatus() {
      return (
        this.notCompatibleCase &&
        this.notCompatibleCase.incompatibleStatus &&
        this.notCompatibleCase.incompatibleStatus.listCasesFailure &&
        this.notCompatibleCase.incompatibleStatus.listCasesFailure.length > 0
      );
    },
    isHaveSbImportSameLaboratory() {
      return (
        this.notCompatibleCase &&
        this.notCompatibleCase.sbImportSameLaboratory &&
        this.notCompatibleCase.sbImportSameLaboratory.listCasesFailure &&
        this.notCompatibleCase.sbImportSameLaboratory.listCasesFailure.length > 0
      );
    },
    isHaveDiscardTissueUnCheckRemaining() {
      return (
        this.notCompatibleCase &&
        this.notCompatibleCase.discardTissueUnCheckRemaining &&
        this.notCompatibleCase.discardTissueUnCheckRemaining.listCasesFailure &&
        this.notCompatibleCase.discardTissueUnCheckRemaining.listCasesFailure.length > 0
      );
    },

    //Resend External Report
    isHaveInValidCaseSource() {
      return (
        this.notCompatibleCase &&
        this.notCompatibleCase.inValidCaseSource &&
        this.notCompatibleCase.inValidCaseSource.listCasesFailure &&
        this.notCompatibleCase.inValidCaseSource.listCasesFailure.length > 0
      );
    },
  },
  methods: {
    setMasterCheckBoxData(masterCheckBoxData) {
      this.masterCheckBoxData = masterCheckBoxData;
    },
    getPartOfCases(cases) {
      if (cases.length > 5 && this.masterCheckBoxData && this.masterCheckBoxData.selectAll) {
        return [
          ...cases.slice(0, 5),
          {
            caseId: Math.random(),
            caseReference: '...',
          },
        ];
      }
      return cases;
    },
    onContinue() {
      this.close();
      this.$emit('onContinue');
    },

    translateReasonFailBatchChange(reasons) {
      let reasonList = reasons.split(',').map(function (item) {
        return item.trim();
      });
      return reasonList
        .map((reason) => this.$translate(`components/ModalBulkActionProblem/BatchChange/${reason}`))
        .join(', ');
    },
  },
};
</script>

<style lang="scss"></style>
