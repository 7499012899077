<template>
  <form ref="form" class="list-filter policy-filter" v-if="filters">
    <div class="filter-input">
      <lims-select
        :placeholder="$t('pages/policy/list/PolicyListFilter/placeholderSelectPolicyType')"
        :options="dataSource.policyTypeList"
        v-model="filters.policyTypeIds"
        :translated="true"
      ></lims-select>
      <lims-select
        :placeholder="$t('global/placeholder.allStatus')"
        :options="dataSource.policyStatusList"
        v-model="filters.policyStatusIds"
        :translated="true"
      ></lims-select>
      <md-field class="input-search">
        <md-icon>search</md-icon>
        <md-input
          v-model="filters.search"
          :placeholder="$t('pages/policy/list/PolicyListFilter/placeholderSearch')"
          maxlength="250"
          @keyup.enter="onSearch"
        ></md-input>
      </md-field>
    </div>
    <lims-chip-area :options="chipOptions" @onRemoveChip="$onRemoveChip"></lims-chip-area>
    <div class="filter-action">
      <md-button @click="onClear">
        {{ $t('global/button/button.clear') }}
      </md-button>
    </div>
  </form>
</template>
<script>
import LimsChipArea from '@/components/Lims/LimsChipArea.vue';
import LimsSelect from '@/components/Lims/LimsSelect.vue';
import { ConfigMixins, ListFilterMixins } from '@/core/mixins';
import { DROPDOWN_SHORT_NAME } from '@/core/constants';
import { DropdownService } from '@/services';
import policyService from '@/services/policy.service';

export default {
  mixins: [ConfigMixins, ListFilterMixins],
  components: { LimsChipArea, LimsSelect },
  props: {
    fields: {
      type: Array,
      require: true,
    },
    defaultValues: {
      type: Object,
      require: true,
    },
    value: {
      type: Object,
      require: true,
    },
  },
  async created() {
    this.dataSource = await this.fetchDataSource();
    this.filters = this.value;
  },
  computed: {},
  data() {
    return {
      filters: null,
      dataSource: {
        policyTypeList: [],
        policyStatusList: [],
      },
      chipOptions: [],
      label: '',
    };
  },
  watch: {
    filters: {
      handler(filterValues) {
        this.$updateChipList({ filterValues, dataSource: this.dataSource, filterFields: this.fields });
      },
      deep: true,
    },
  },
  methods: {
    async policyTypeList() {
      const { data } = await DropdownService.getDropdownByShortName(DROPDOWN_SHORT_NAME.POLICY_TYPE);
      return data.map((item) => {
        return {
          value: item.fieldItemId,
          label: item.fieldItemName,
        };
      });
    },
    async policyStatusList() {
      const { data } = await policyService.getPolicyStatusList();
      return data.map((item) => {
        return {
          value: item.fieldItemId,
          label: item.fieldItemName,
        };
      });
    },
    async fetchDataSource() {
      // load and binding datasource
      const policyTypeList = await this.policyTypeList();
      const policyStatusList = await this.policyStatusList();
      return {
        policyStatusList,
        policyTypeList,
      };
    },

    onClear() {
      this.$nextTick(() => {
        this.$refs.form.reset();
      });
      Object.keys(this.filters).map((k) => {
        // eslint-disable-next-line security/detect-object-injection
        this.filters[k] = this.defaultValues[k];
      });
    },
  },
};
</script>
<style lang="scss"></style>
