import { RoleService } from '@/services';

export default {
  watch: {
    'formData.personalInfo.roleId': {
      deep: true,
      handler: async function (role, prevRole) {
        if (role && (!prevRole || (prevRole && role.value !== prevRole.value))) {
          const res = await RoleService.getRoleDetail(role.value);
          if (res.err) {
            return this.$alertError('Err ' + res.error);
          }
          this.roleInformation = res.data.result;
        }
      },
    },
  },
};
