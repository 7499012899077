import httpClient from './httpClient';
const RESOURCE = 'dashboard';

let eventGuid = 0;

function createEventId() {
  return String(eventGuid++);
}
const INITIAL_EVENTS = [];
for (let i = 0; i <= 10; i++) {
  const d = new Date();
  d.setDate(d.getDate() + i);
  INITIAL_EVENTS.push({
    eventId: createEventId(),
    title: `Event ${i + 1} - Travel`,
    startDate: d,
    endDate: d,
  });
}
export default {
  getDashboardAdminStats() {
    return httpClient.get(`/${RESOURCE}/administrator`);
  },
  getDashboardPathologistStats() {
    return httpClient.get(`/${RESOURCE}/pathologist`);
  },
  getDashboardLabtechnicianStats() {
    return httpClient.get(`/${RESOURCE}/lab-technician`);
  },
  getDashboardAssociatedStats() {
    return httpClient.get(`/${RESOURCE}/associated-user`);
  },
  getNewUsersPerMonth() {
    return httpClient.get(`/${RESOURCE}/administrator/new-users-per-month`);
  },
  getAverageTurnAround() {
    return httpClient.get(`/${RESOURCE}/administrator/average-turnarrount-time-per-date`);
  },
  getNewEntitiesPerMonth() {
    return httpClient.get(`/${RESOURCE}/administrator/new-entities-per-month`);
  },
  getDeactivatedEntitiesPerMonth() {
    return httpClient.get(`/${RESOURCE}/administrator/deactivated-entities-per-month`);
  },
  getPathologistCaseReportedPerYear() {
    return httpClient.get(`/${RESOURCE}/pathologist/case-reported-per-year`);
  },
  getPathologistCaseReportedPerDay() {
    return httpClient.get(`/${RESOURCE}/pathologist/case-reported-per-date`);
  },
  getPathologistAverageTurnAround() {
    return httpClient.get(`/${RESOURCE}/pathologist/average-turnarrount-time-per-date`);
  },

  myCalendarDashboard() {
    return new Promise((resolve) => {
      httpClient
        .get(`/${RESOURCE}/pathologist/upcoming-event`)
        .then((res) => {
          const { error, data } = res;
          if (error) {
            resolve({ data: null, error });
          }
          const { myEvents, clinicCalendarModels } = data;
          resolve({
            data: {
              MyCalendar: myEvents,
              ClinicCalendar: clinicCalendarModels,
            },
            error: null,
          });
        })
        .catch((error) => resolve({ data: null, error }));
    });
  },
};
