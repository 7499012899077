<template>
  <form ref="form" class="list-filter importHistory-list-filter" v-if="filters">
    <div class="filter-input">
      <lims-select
        v-model="filters.ListImportIds"
        :options="dataSource.importIdList"
        :translated="true"
        :placeholder="$t('pages/case/ImportHistory/List/ImportHistoryListFilter/placeholder.importeID')"
      ></lims-select>
      <date-picker
        range
        v-model="filters.importDate"
        format="DD/MM/YYYY"
        :lang="lang"
        :placeholder="$t('global/placeholder.dateRangePicker')"
      ></date-picker>
      <lims-select
        v-model="filters.ListImporterIds"
        :options="dataSource.importerList"
        :translated="true"
        :placeholder="$t('pages/case/ImportHistory/List/ImportHistoryListFilter/placeholder.importer')"
      ></lims-select>
      <md-field class="input-search">
        <md-icon>search</md-icon>
        <md-input
          v-model="filters.search"
          :placeholder="$t('pages/case/ImportHistory/List/ImportHistoryListFilter/placeholder.search')"
          maxlength="250"
        ></md-input>
      </md-field>
    </div>

    <lims-chip-area :options="chipOptions" @onRemoveChip="$onRemoveChip"></lims-chip-area>
    <div class="filter-action">
      <md-button @click="onClear">
        {{ $t('global/button/button.clear') }}
      </md-button>
    </div>
  </form>
</template>
<script>
/* eslint-disable security/detect-object-injection */
import { ConfigMixins, ListFilterMixins } from '@/core/mixins';

import LimsChipArea from '@/components/Lims/LimsChipArea.vue';
import LimsSelect from '@/components/Lims/LimsSelect.vue';
import { caseFormService } from '@/services';
import { CASE_LIST_DROPDOWN } from '@/core/constants';

export default {
  mixins: [ConfigMixins, ListFilterMixins],
  components: { LimsChipArea, LimsSelect },
  props: {
    fields: {
      type: Array,
      require: true,
    },
    defaultValues: {
      type: Object,
      require: true,
    },
    value: {
      type: Object,
      require: true,
    },
  },
  async created() {
    this.onCreated();
  },

  computed: {
    lang() {
      return {
        formatLocale: {
          firstDayOfWeek: 1, // SAMPLE FOR DATE-PICKER 1stDayOfTheWeek
        },
      };
    },
  },
  data() {
    return {
      filters: null,
      chipOptions: [],
      dataSource: {
        importIdList: [],
        importerList: [],
      },
    };
  },
  watch: {
    filters: {
      handler(filterValues) {
        this.$updateChipList({ filterValues, dataSource: this.dataSource, filterFields: this.fields });
      },
      deep: true,
    },
  },
  methods: {
    async onCreated() {
      const dropdownStainOptions = await caseFormService.getStainByEntityIdInCaseForm(null, [
        CASE_LIST_DROPDOWN.CASE_IMPORT_BATCH_ID,
        CASE_LIST_DROPDOWN.CASE_IMPORTER,
      ]);

      if (dropdownStainOptions && dropdownStainOptions[CASE_LIST_DROPDOWN.CASE_IMPORT_BATCH_ID]) {
        this.dataSource['importIdList'] = dropdownStainOptions[CASE_LIST_DROPDOWN.CASE_IMPORT_BATCH_ID].map((item) => {
          return {
            ...item,
            value: item.id,
            label: item.text,
          };
        });
      }

      if (dropdownStainOptions && dropdownStainOptions[CASE_LIST_DROPDOWN.CASE_IMPORTER]) {
        this.dataSource['importerList'] = dropdownStainOptions[CASE_LIST_DROPDOWN.CASE_IMPORT_BATCH_ID].map((item) => {
          return {
            ...item,
            value: item.id,
            label: item.text,
          };
        });
      }

      this.filters = this.value;
    },

    onClear() {
      this.$nextTick(() => {
        this.$refs.form.reset();
      });
      Object.keys(this.filters).map((k) => {
        this.filters[k] = this.defaultValues[k];
      });
    },
  },
};
</script>
<style lang="scss">
.importHistory-list-filter {
  .v-select {
    width: 170px;
    @media only screen and (max-width: 767px) {
      width: 100%;
    }
  }
  .mx-datepicker {
    width: 200px;
    margin: 5px 0;
    @media only screen and (max-width: 767px) {
      width: 100%;
      margin: 0;
    }
  }
  .input-search .md-input {
    width: 250px;
    @media only screen and (max-width: 767px) {
      width: 100%;
    }
  }
}
</style>
