// Layouts
const DashboardLayout = () => import('@/layouts/DashboardLayout.vue');
// Example pages
const ExamplePrintLabel = () => import('@/pages/Example/ExamplePrintLabel.vue');
const ExampleCalendar = () => import('@/pages/Example/Calendar/ExampleCalendar.vue');
const ExampleCalendarList = () => import('@/pages/Example/Calendar/ExampleCalendarList.vue');
const ExampleForm = () => import('@/pages/Example/ExampleForm.vue');

import guards from './guards';

export default {
  path: '/examples',
  component: DashboardLayout,
  meta: {
    zone: 'private',
  },
  beforeEnter: guards.checkAuthorizedGuard,
  children: [
    {
      path: 'form',
      name: 'ExampleForm',
      component: ExampleForm,
    },
    {
      path: 'print-labels',
      name: 'ExamplePrintLabel',
      component: ExamplePrintLabel,
    },

    {
      path: 'calendar',
      name: 'ExampleCalendar',
      component: ExampleCalendar,
    },
    {
      path: 'calendar-list',
      name: 'ExampleCalendarList',
      component: ExampleCalendarList,
    },
  ],
};
