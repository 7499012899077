<template>
  <stats-card header-color="primary">
    <template slot="header">
      <div class="card-icon">
        <md-icon>earbuds</md-icon>
      </div>
      <p class="category">{{ $t('pages/Dashboard/Components/ReleasedCasesStats/title') }}</p>
    </template>

    <template slot="footer">
      <div class="group-stats-wrapper">
        <div class="group-stats">
          <div class="group-stats-item">
            <h3>
              <span>{{ data.value }}</span>
            </h3>
          </div>
        </div>
        <div class="stats">
          <md-icon>date_range</md-icon>
          {{ data.time }}
        </div>
      </div>
    </template>
  </stats-card>
</template>

<script>
import StatsMixins from './stats.mixins';
export default {
  props: {
    data: {},
  },
  mixins: [StatsMixins],
};
</script>

<style></style>
