<template>
  <md-dialog :md-close-on-esc="true" :md-active.sync="isVisible" @md-clicked-outside="close" class="lims-modal">
    <div class="dialog-icon-close" @click="close"><md-icon>close</md-icon></div>
    <md-dialog-title>{{ $t('components/lims/modals/ModalValidateUser.title') }}</md-dialog-title>
    <div class="dialog-content">
      <template v-if="pendingAction && Object.keys(pendingAction).length === 1">
        <div v-for="(item, index) in pendingAction" :key="index" class="md-dense md-info action-message">
          <p
            class="text-center"
            v-if="
              item.pendingActionTypeId == pendingActionType.Created ||
              item.pendingActionTypeId == pendingActionType.EnableUser ||
              item.pendingActionTypeId == pendingActionType.DisableUser
            "
          >
            {{ $t(`global/message/actionMessage`, actionMessage(item)) }}
          </p>
          <p
            class="text-center"
            v-if="
              item.pendingActionTypeId == pendingActionType.EnableHasLogin ||
              item.pendingActionTypeId == pendingActionType.DisableHasLogin
            "
          >
            {{ $t(`global/message/actionMessage.hasLogin`, actionMessage(item)) }}
          </p>
          <p
            class="text-center"
            v-if="
              item.pendingActionTypeId == pendingActionType.ChangeEmail ||
              item.pendingActionTypeId == pendingActionType.ChangePhoneNumber
            "
          >
            {{ $t(`global/message/actionMessage.manyActions`, actionMessage(item)) }}
          </p>
        </div>
      </template>
      <template v-if="pendingAction && Object.keys(pendingAction).length > 1">
        <p class="text-center">{{ $t('components/lims/modals/ModalValidateUser/validate.txt') }}</p>
        <ul class="list-validate">
          <li>
            <md-checkbox v-model="selectAll" class="lims-checkbox">
              {{ $t('components/lims/modals/ModalValidateUser/validate.selectAll') }}
            </md-checkbox>
          </li>
          <li v-for="(item, index) in pendingAction" :key="index">
            <md-checkbox v-model="formData.listValidate" :value="item.pendingUserValidationId" class="lims-checkbox">
              <template v-if="item.pendingActionTypeId == pendingActionType.ChangePhoneNumber">
                {{ $t('components/lims/modals/ModalValidateUser/validate.tel') }}
              </template>
              <template v-if="item.pendingActionTypeId == pendingActionType.ChangeEmail">
                {{ $t('components/lims/modals/ModalValidateUser/validate.email') }}
              </template>
              <template
                v-if="
                  item.pendingActionTypeId == pendingActionType.EnableHasLogin ||
                  item.pendingActionTypeId == pendingActionType.DisableHasLogin
                "
              >
                {{ $t('components/lims/modals/ModalValidateUser/validate.hasLogin') }}</template
              >
            </md-checkbox>
          </li>
        </ul>
      </template>

      <md-field class="dialog-texterea-field">
        <md-textarea
          v-model="formData.note"
          :placeholder="$t('components/lims/modals/ModalValidateUser.placeholderTextarea')"
        ></md-textarea>
      </md-field>
    </div>
    <md-dialog-actions>
      <md-button @click="close" class="lims-form-button">{{ $t('global/button/button.cancel') }}</md-button>
      <md-button @click="onApprove" class="lims-form-button md-primary">
        {{ $t('components/lims/modals/ModalValidateUser.approveBtn') }}
      </md-button>
      <md-button @click="onReject" class="lims-form-button md-danger">
        {{ $t('components/lims/modals/ModalValidateUser.rejectBtn') }}
      </md-button>
    </md-dialog-actions>
  </md-dialog>
</template>

<script>
import { PENDING_ACTION_TYPE } from '@/core/constants';
import modalMixins from './modal.mixins';
export default {
  name: 'ModalValidateUser',
  mixins: [modalMixins],
  props: ['actionMessage', 'pendingAction'],
  data() {
    return {
      formData: {
        listValidate: [],
        note: '',
      },
    };
  },
  watch: {},
  computed: {
    pendingActionType() {
      return PENDING_ACTION_TYPE;
    },
    selectAll: {
      get: function () {
        return this.pendingAction ? this.formData.listValidate.length == Object.keys(this.pendingAction).length : false;
      },
      set: function (value) {
        const selected = [];
        if (value) {
          Object.values(this.pendingAction).forEach(function (val) {
            selected.push(val.pendingUserValidationId);
          });
        }
        this.formData.listValidate = selected;
      },
    },
  },
  methods: {
    onApprove() {
      this.$emit('onApprove', this.formData);
    },
    onReject() {
      this.$emit('onReject', this.formData);
    },
  },
};
</script>
<style lang="scss">
.list-validate {
  list-style: none;
  display: table;
  margin: 0 auto;
  .md-checkbox {
    margin: 0 0 15px 0;
  }
}
</style>
