import httpClient from './httpClient';

const RESOURCE = 'constants';
export default {
  getConstantValue() {
    return httpClient.get(`/${RESOURCE}`);
  },
  getCaseSpecimenSettings() {
    return httpClient.get(`/${RESOURCE}/case-specimen-settings`);
  },
};
