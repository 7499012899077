<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit }">
    <form class="md-layout lims-form" @submit.prevent="handleSubmit()">
      <!-- Begin personal information -->
      <lims-block class="personal-info-block">
        <h4 class="title" slot="blockTitle">{{ $t('pages/account/user/forms/personalInfoBlock') }}</h4>
        <modal-confirmation
          ref="confirmModal"
          slot="blockTitle"
          :is-wrong-password="isWrongPassword"
          @onConfirm="$onConfirmDisabled"
        ></modal-confirmation>
        <modal-has-login-confirm
          ref="hasLoginConfirmModal"
          slot="blockTitle"
          @onConfirm="$onHasLoginConfirmModal"
        ></modal-has-login-confirm>
        <modal-view-permissions
          ref="viewPermissions"
          slot="blockTitle"
          :permission-data="roleInformation"
        ></modal-view-permissions>
        <modal-input-your-telephone
          slot="blockTitle"
          ref="inputYourTelephoneModal"
          :user-id="userId"
          :verify-two-fa-mode="verifyTwoFaMode"
          :mobile-phone="telephoneMobileDataInVerify"
          @onNext="onCompleteInputNewTelephone"
        ></modal-input-your-telephone>
        <modal-input-new-email
          slot="blockTitle"
          ref="inputNewEmailModal"
          :user-id="userId"
          @onNext="onCompleteInputNewEmail"
        ></modal-input-new-email>
        <modal-verify-code
          slot="blockTitle"
          ref="verifyCodeModal"
          :option="verifyContent"
          :is-wrong-code="isWrongCode"
          @onVerify="onVerifyCode"
          @onResendCode="onResendVerifyCode"
        ></modal-verify-code>
        <modal-update-successfully
          slot="blockTitle"
          ref="verifySuccessfullyModal"
          :method="verifyMode === onUpdateEmail ? 'Email' : 'Telephone (Mobile)'"
          @onUpdateSuccessfully="onUpdateSuccessfully"
        ></modal-update-successfully>
        <div slot="blockContent">
          <LimsFormEditButton class="edit-btn" v-if="viewMode && $canEdit()" :editUrl="$editUrl" />
          <!-- new row -->
          <div class="md-layout lims-form-row" v-if="isDefaultView">
            <div class="md-layout-item md-size-25 md-small-size-100">
              <lims-field :model="formData.personalInfo" :schema="userSchema.personalInfo" field="userType">
                <v-select disabled slot="field" :options="userTypesList" v-model="formData.personalInfo.userType">
                  <template #option="{ label }">{{ $t(label) }}</template>
                  <template #selected-option="{ label }">{{ $t(label) }}</template>
                </v-select>
              </lims-field>
            </div>
            <div class="md-layout-item md-size-25 md-small-size-100">
              <lims-field :model="formData.personalInfo" :schema="userSchema.personalInfo" field="role">
                <v-select
                  slot="field"
                  v-model="formData.personalInfo.roleId"
                  :options="roleList"
                  :disabled="viewMode"
                  tabenable="yes"
                  :tabindex="1"
                >
                  <template #option="{ label }">{{ label }}</template>
                  <template #selected-option="{ label }">{{ label }}</template>
                </v-select>
              </lims-field>
            </div>
            <div class="md-layout-item md-size-25 md-small-size-100">
              <div
                class="view-permissions"
                @click="onClickViewPermissions"
                :class="formData.personalInfo.roleId ? '' : 'disable-view-permissions'"
              >
                {{ $t('entities/user/personalInfo/viewPermissions') }}
              </div>
            </div>
          </div>
          <!-- end row -->
          <!-- new row -->
          <div class="md-layout lims-form-row">
            <div class="md-layout-item md-size-25 md-small-size-100">
              <lims-field :model="formData.personalInfo" :schema="userSchema.personalInfo" field="username">
                <lims-tooltip
                  slot="label-info"
                  :content="$t('entities/user/personalInfo/userNameTooltip')"
                ></lims-tooltip>
                <md-input
                  slot="field"
                  v-model="formData.personalInfo.username"
                  type="text"
                  :disabled="isEditMode || viewMode"
                  v-on:blur="
                    $onServerSideValidation($event, userSchema._serverSideValidators.username, [
                      () => $lowercase(formData.personalInfo, 'username'),
                    ])
                  "
                  tabenable="yes"
                >
                </md-input>
                <lims-tooltip
                  slot="icon"
                  :content="$t('entities/user/personalInfo/userNameTooltipRules')"
                ></lims-tooltip>
              </lims-field>
            </div>
            <div class="md-layout-item md-size-25 md-small-size-100">
              <lims-field :model="formData.personalInfo" :schema="userSchema.personalInfo" field="title">
                <v-select
                  slot="field"
                  v-model="formData.personalInfo.titleId"
                  :options="userTitles"
                  :disabled="viewMode"
                  tabenable="yes"
                  :tabindex="1"
                >
                  <template #option="{ label }">{{ label }}</template>
                  <template #selected-option="{ label }">{{ label }}</template>
                </v-select>
              </lims-field>
            </div>
            <div class="md-layout-item md-size-25 md-small-size-100">
              <lims-field :model="formData.personalInfo" :schema="userSchema.personalInfo" field="firstName">
                <md-input
                  slot="field"
                  v-model="formData.personalInfo.firstname"
                  type="text"
                  maxlength="250"
                  :disabled="viewMode"
                  tabenable="yes"
                ></md-input>
              </lims-field>
            </div>
            <div class="md-layout-item md-size-25 md-small-size-100">
              <lims-field :model="formData.personalInfo" :schema="userSchema.personalInfo" field="lastName">
                <md-input
                  slot="field"
                  v-model="formData.personalInfo.lastname"
                  type="text"
                  maxlength="250"
                  :disabled="viewMode"
                  tabenable="yes"
                ></md-input>
              </lims-field>
            </div>
          </div>
          <!-- end row -->
          <!-- new row -->
          <div class="md-layout lims-form-row">
            <div class="md-layout-item md-size-100">
              <lims-field
                :model="formData.personalInfo"
                :schema="userSchema.personalInfo"
                field="address1"
                class="textarea-field"
              >
                <md-textarea
                  slot="field"
                  v-model="formData.personalInfo.address1"
                  :disabled="viewMode"
                  tabenable="yes"
                ></md-textarea>
              </lims-field>
              <lims-field
                :model="formData.personalInfo"
                :schema="userSchema.personalInfo"
                field="address2"
                class="textarea-field"
              >
                <md-textarea
                  slot="field"
                  v-model="formData.personalInfo.address2"
                  :disabled="viewMode"
                  tabenable="yes"
                ></md-textarea>
              </lims-field>
            </div>
          </div>
          <!-- end row -->
          <!-- new row -->
          <div class="md-layout lims-form-row">
            <div class="md-layout-item md-size-25 md-small-size-100">
              <lims-field :model="formData.personalInfo" :schema="userSchema.personalInfo" field="town">
                <md-input
                  slot="field"
                  v-model="formData.personalInfo.town"
                  type="text"
                  maxlength="100"
                  :disabled="viewMode"
                  tabenable="yes"
                ></md-input>
              </lims-field>
            </div>
            <div class="md-layout-item md-size-25 md-small-size-100">
              <lims-field :model="formData.personalInfo" :schema="userSchema.personalInfo" field="country">
                <v-select
                  slot="field"
                  v-model="formData.personalInfo.countryId"
                  :options="countries"
                  :disabled="viewMode"
                  tabenable="yes"
                  :tabindex="1"
                >
                  <template #option="{ label }">{{ label }}</template>
                  <template #selected-option="{ label }">{{ label }}</template>
                </v-select>
              </lims-field>
            </div>
            <div class="md-layout-item md-size-25 md-small-size-100">
              <lims-field :model="formData.personalInfo" :schema="userSchema.personalInfo" field="postCode">
                <md-input
                  slot="field"
                  v-model="formData.personalInfo.postCode"
                  type="text"
                  maxlength="50"
                  :disabled="viewMode"
                  tabenable="yes"
                ></md-input>
              </lims-field>
            </div>
          </div>
          <!-- end row -->
          <!-- new row -->
          <div class="md-layout lims-form-row">
            <div class="md-layout-item md-size-50 md-small-size-100">
              <lims-field :model="formData.personalInfo" :schema="userSchema.personalInfo" field="email">
                <lims-tooltip slot="label-info" :content="$t('entities/user/personalInfo/emailTooltip')"></lims-tooltip>
                <md-input
                  slot="field"
                  v-model="formData.personalInfo.email"
                  type="text"
                  v-on:blur="
                    $onServerSideValidation($event, userSchema._serverSideValidators.email, [
                      () => $lowercase(formData.personalInfo, 'email'),
                    ])
                  "
                  :placeholder="$t('entities/user/personalInfo/email.placeholder')"
                  maxlength="200"
                  :disabled="viewMode || $getUserMixins().isNotAllowedUpdatePhoneOrEmail() || isMyProfileView"
                  tabenable="yes"
                ></md-input>
              </lims-field>
              <div class="text-underline" v-if="isMyProfileView" @click="onClickUpdateEmail">
                {{ $t('pages/myAccount/profile/update/email') }}
              </div>
            </div>
            <div class="md-layout-item md-size-25 md-small-size-100">
              <lims-field :model="formData.personalInfo" :schema="userSchema.personalInfo" field="telephoneOffice">
                <md-input
                  slot="field"
                  v-model="formData.personalInfo.telephoneOffice"
                  type="text"
                  :placeholder="$t('global/placeholder.telephone')"
                  maxlength="50"
                  :disabled="viewMode"
                  tabenable="yes"
                ></md-input>
              </lims-field>
            </div>
            <div class="md-layout-item md-size-25 md-small-size-100">
              <lims-field :model="formData.personalInfo" :schema="userSchema.personalInfo" field="telephoneMobile">
                <lims-tel-input
                  slot="field"
                  :disabled="viewMode || $getUserMixins().isNotAllowedUpdatePhoneOrEmail() || isMyProfileView"
                  v-model="telephoneMobileData"
                  @phoneNumberChange="
                    ($event) => $onServerSideValidation($event, userSchema._serverSideValidators.telephoneMobile)
                  "
                ></lims-tel-input>
              </lims-field>
              <div class="text-underline" v-if="isMyProfileView" @click="onClickUpdateTelephone">
                {{ $t('pages/myAccount/profile/update/telephone') }}
              </div>
            </div>
          </div>
          <!-- end row -->
        </div>
      </lims-block>
      <!-- End personal information -->
      <!-- Begin Clinic Types -->
      <lims-block v-if="isEditMode || viewMode">
        <h4 class="title" slot="blockTitle">
          {{ $t('pages/account/user/forms/Administrator/clinicTypes/clinicTypesBlock') }}
        </h4>
        <div class="md-layout" slot="blockContent">
          <div class="md-layout lims-form-row">
            <div class="md-layout-item md-size-100 md-small-size-100">
              <ul v-html="clinicTypes"></ul>
            </div>
            <div
              v-if="userResource && userResource.clinictTypes.length > 0"
              class="md-layout-item md-size-100 md-small-size-100"
            >
              {{ $t('pages/account/user/forms/Administrator/clinicTypes/text') }}
            </div>
            <div v-else>
              {{ $t('pages/account/user/forms/Administrator/clinicTypes/noClinicType') }}
            </div>
          </div>
        </div>
      </lims-block>
      <!-- End Clinic Types -->
      <!-- Begin notification -->
      <user-notifications
        v-if="formData.notificationSettings"
        v-model="formData.notificationSettings"
        :disabled="viewMode"
        :disabledSMS="!telephoneMobileData.telephoneMobile || telephoneMobileData.telephoneMobile == ''"
      ></user-notifications>
      <!-- Begin login -->
      <lims-block v-if="isDefaultView">
        <h4 class="title" slot="blockTitle">{{ $t('pages/account/user/forms/loginBlock') }}</h4>
        <div class="md-layout" slot="blockContent">
          <div class="md-layout lims-form-row">
            <div class="md-layout-item md-size-100 md-small-size-100">
              <md-checkbox v-model="formData.login.hasLogin" class="lims-checkbox" :disabled="viewMode" tabenable="yes">
                {{ $t('pages/account/user/forms/loginLabel') }}
              </md-checkbox>
            </div>
            <div class="md-layout-item md-size-100 md-small-size-100">
              <user-policy-actions
                v-if="!isAddMode"
                :userPolicyActions="userResource.userPolicyActions"
              ></user-policy-actions>
            </div>
          </div>
          <div v-if="userResource && userResource.lastTimeLogin" class="last-login">
            {{ $t('pages/account/user/forms/lastTimeLogin', { lastTimeLogin: getLastTimeLogin }) }}
          </div>
        </div>
      </lims-block>
      <!-- End login -->
      <!-- Display if the user has not completed their account -->
      <div v-if="!isMyProfileView" class="isNotCompleteAccount">
        <div v-if="!isAddMode && isCheckCompleteAccount">
          {{ $t('pages/account/user/forms/isNotCompleteAccount') }}
        </div>
      </div>
      <!-- Begin action form -->
      <div class="md-layout lims-form-row">
        <div class="md-layout-item md-size-100 md-small-size-100 lims-form-actions">
          <LimsFormCancel :redirectUrl="$redirectUrl()" />
          <md-button v-if="canSave" @click="$onSave()" class="md-button md-primary lims-form-button md-theme-default">
            {{ $t('global/button/button.save') }}
          </md-button>
          <LimsFormEditButton v-if="viewMode && $canEdit()" :editUrl="$editUrl" />
          <md-button
            v-if="!isAddMode && isDisabledUser && isDefaultView"
            class="md-primary lims-form-button"
            @click="$onEnableUser"
            >{{ $t('global/button/button.enable') }}</md-button
          >
          <template v-if="!isMyProfileView">
            <md-button
              v-if="isEditMode && isResetPasswordUser && isDefaultView"
              class="md-danger lims-form-button"
              @click="$onResetPassword"
              >{{ $t('global/button/button.resetPassword') }}</md-button
            >
          </template>
          <md-button
            v-if="isEditMode && isEnabledUser && isDefaultView"
            class="md-danger lims-form-button"
            @click="$onDisableUser"
            >{{ $t('global/button/button.disable') }}</md-button
          >
        </div>
      </div>
      <!-- End action form -->
    </form>
  </ValidationObserver>
</template>
<script>
import { FormMixins, TabMixins } from '@/core/mixins';
import UserMixins, { UPDATE_TWO_FA_MODE } from '@/pages/Account/User/user.mixins';
import UserRoleMixins from '@/pages/Account/User/user.role.mixins';
import UserService from '@/services/user.service';
import { FORM_MODES } from '@/core/constants';
import { getUserSchema } from '@/schemas/users.schema';
import ModalConfirmation from '@/components/Lims/modals/ModalConfirmation.vue';
import ModalHasLoginConfirm from '@/components/Lims/modals/ModalHasLoginConfirm.vue';
import LimsTelInput from '@/components/Lims/LimsTelInput.vue';
import UserNotifications from '../UserNotifications.vue';
import ModalViewPermissions from '@/components/Lims/modals/ModalViewPermissions';
import { VERIFY_TW0_FA_MODE } from '@/core/constants';
import { ModalInputYourTelephone } from '@/components/Lims/modals';
import { ModalInputNewEmail } from '@/components/Lims/modals';
import { ModalVerifyCode } from '@/components/Lims/modals';
import { ModalUpdateSuccessfully } from '@/components/Lims/modals';
import { MyAccountService } from '@/services';

export default {
  components: {
    ModalViewPermissions,
    ModalConfirmation,
    LimsTelInput,
    UserNotifications,
    ModalHasLoginConfirm,
    ModalInputYourTelephone,
    ModalInputNewEmail,
    ModalVerifyCode,
    ModalUpdateSuccessfully,
  },
  mixins: [FormMixins, UserMixins, UserRoleMixins, TabMixins],
  created() {
    this.fetchData();
  },
  props: {
    userResource: {
      type: Object,
      require: false,
      default: null,
    },
    userId: {
      type: String,
      require: false,
      default: null,
    },
    viewMode: {
      type: Boolean,
      require: false,
      default: null,
    },
  },
  data() {
    return {
      formMode: FORM_MODES.ADD,
      formData: {
        personalInfo: {
          userType: this.USERTYPES_ENUM().find((e) => e.value === this.USER_TYPES().Administrator),
          userRole: '',
        },
        login: {
          hasLogin: false,
        },
        userPolicyActions: [],
        notificationSettings: null,
      },
      userModel: {},
      userTitles: [],
      countries: [],
      roleList: [],
      telephoneMobileData: {},
      telephoneMobileDataInVerify: {},
      roleInformation: null,
      verifyContent: '',
      countryCode: null,
      locale: null,
      phoneNumber: null,
      email: null,
      verifyMode: null,
    };
  },
  computed: {
    userSchema: function () {
      return getUserSchema('Administrator', this.formMode, this.userModel);
    },
    userTypesList() {
      return this.USERTYPES_ENUM();
    },
    disabledUsername() {
      return this.formMode === FORM_MODES.EDIT;
    },
    clinicTypes() {
      // TODO: Need to change later when API correct the spelling
      // Use clinicTypes instead of clinictTypes
      if (this.userResource.clinictTypes) {
        return this.userResource.clinictTypes.map((item) => `<li>${item.fieldItemName}</li>`).join('');
      }
      return '';
    },
    roleIdChange() {
      return this.formData.personalInfo.roleId;
    },
    verifyTwoFaMode() {
      return VERIFY_TW0_FA_MODE.NEW;
    },
    onUpdateEmail() {
      return UPDATE_TWO_FA_MODE.EMAIL;
    },
  },
  methods: {
    async fetchData() {
      this.countries = await this.loadCountries();
      this.userTitles = this.USER_TITLES();
      if (this.isDefaultView) {
        this.roleList = await this.loadRoleList(this.USER_TYPES().Administrator);
      }
      if (this.userResource) {
        this.userModel = this.userResource;
        this.formMode = this.viewMode ? FORM_MODES.VIEW : FORM_MODES.EDIT;
        this.formData = {
          personalInfo: {
            ...this.userModel.personalInfo,
            userType: this.USERTYPES_ENUM().find((e) => e.value === this.USER_TYPES().Administrator),
            roleId: this.roleList.find((e) => e.value === this.userModel.personalInfo.roleId),
            countryId: this.countries.find((e) => e.value === this.userModel.personalInfo.countryId),
            titleId: this.userTitles.find((e) => e.value === this.userModel.personalInfo.titleId),
          },
          userPolicyActions: this.userModel.userPolicyActions,
          login: this.userModel.login
            ? {
                hasLogin: this.userModel.login.hasLogin,
              }
            : null,
          notificationSettings: this.userModel.notificationSettings,
        };
        this.telephoneMobileData = this.$getTelephoneMobileData();
        this.telephoneMobileDataInVerify = this.$getTelephoneMobileDataInVerify();
        this.$setHeadingTitle(this.formData.personalInfo.username);
      }
      this.$nextTick(function () {
        this.$resetChangeDetection();
      });
      this.$setCountryAndMobileCodeFollowGlobalVariablesDataInAddMode();
    },
    createUser() {
      return UserService.create({
        personalInfo: {
          ...this.formData.personalInfo,
          roleId: this.formData.personalInfo.roleId.value,
          titleId: this.formData.personalInfo.titleId.value,
          countryId: this.formData.personalInfo.countryId.value,
          countryCodeMobile: this.telephoneMobileData.countryCodeMobile
            ? this.telephoneMobileData.countryCodeMobile.code
            : '',
          localeMobile: this.telephoneMobileData.countryCodeMobile
            ? this.telephoneMobileData.countryCodeMobile.locale
            : '',
          countryIdMobile: this.telephoneMobileData.telephoneMobile
            ? this.telephoneMobileData.countryCodeMobile.countryId
            : null,
          telephoneMobile: this.telephoneMobileData.telephoneMobile,
        },
        login: {
          hasLogin: this.formData.login.hasLogin,
        },
        notificationSettings: this.formData.notificationSettings,
      });
    },
    updateUser() {
      if (this.$isMyProfileView()) {
        return this.updateProfile();
      }
      return UserService.updateOne({
        userId: this.userId,
        personalInfo: {
          ...this.formData.personalInfo,
          roleId: this.formData.personalInfo.roleId.value,
          titleId: this.formData.personalInfo.titleId.value,
          countryId: this.formData.personalInfo.countryId.value,
          countryCodeMobile: this.telephoneMobileData.countryCodeMobile
            ? this.telephoneMobileData.countryCodeMobile.code
            : '',
          localeMobile: this.telephoneMobileData.countryCodeMobile
            ? this.telephoneMobileData.countryCodeMobile.locale
            : '',
          telephoneMobile: this.telephoneMobileData.telephoneMobile,
          countryIdMobile: this.telephoneMobileData.telephoneMobile
            ? this.telephoneMobileData.countryCodeMobile.countryId
            : null,
        },
        login: {
          hasLogin: this.formData.login.hasLogin,
        },
        notificationSettings: this.formData.notificationSettings,
      });
    },
    updateProfile() {
      return MyAccountService.updateProfile({
        userId: this.userId,
        personalInfo: {
          ...this.formData.personalInfo,
          titleId: this.formData.personalInfo.titleId.value,
          countryId: this.formData.personalInfo.countryId.value,
          telephoneMobile: this.telephoneMobileData.telephoneMobile,
        },
        notificationSettings: this.formData.notificationSettings,
      });
    },
    onClickViewPermissions() {
      if (this.formData.personalInfo.roleId) {
        this.$refs.viewPermissions.open();
      }
    },
  },
};
</script>
