/* eslint-disable security/detect-object-injection */
import { LOCAL_STORAGE_KEYS } from '@/core/constants';
import { saveSession } from '@/core/helpers';
import Vue from 'vue';

// initial state
const state = () => ({
  dateTimeAppFormat: 'dddd, dd MMMM yyyy HH:mm:ss',
  itemPerPage: [],
  itemPerPageDefault: 50,
  languageId: 0,
  policyAcceptedByUser: [],
  timezoneValue: '07:00',
  userType: '',
  userTypeId: 0,
  userTypes: {},
  userTypeOptions: [],
});

// getters
const getters = {
  $appConfig: (state) => {
    return state;
  },
};

// actions
const actions = {
  $SET_APP_CONFIG({ commit }, payload) {
    commit('setAppConfig', payload);
  },
  $UPDATE_APP_CONFIG({ commit }, { field, value }) {
    commit('updateAppConfig', { field, value });
  },
  $UPDATE_ITEM_PER_PAGE_DEFAULT({ dispatch }, { value }) {
    dispatch('$UPDATE_APP_CONFIG', { field: 'itemPerPageDefault', value: parseInt(value) });
  },
  $UPDATE_TIME_ZONE({ dispatch }, { value }) {
    dispatch('$UPDATE_APP_CONFIG', { field: 'timezoneValue', value: value });
  },
  $UPDATE_PATH_PULL_SYSTEM({ dispatch }, { value }) {
    dispatch('$UPDATE_APP_CONFIG', { field: 'pathPullSystem', value: value });
  },
  $UPDATE_REPORTING_STATUS_ID({ dispatch }, { value }) {
    dispatch('$UPDATE_APP_CONFIG', { field: 'reportingStatusId', value: value });
  },
};

// mutations
const mutations = {
  setAppConfig(state, payload) {
    Object.keys(payload).map((k) => {
      Vue.set(state, k, payload[k]);
    });
    // save sessions
    saveSession(LOCAL_STORAGE_KEYS.CONFIG, state);
  },
  updateAppConfig(state, { field, value }) {
    Vue.set(state, field, value);
    saveSession(LOCAL_STORAGE_KEYS.CONFIG, state);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
