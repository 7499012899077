import { cloneSortFilterFields, getDefaultFilterValues, getFilterFieldTypes } from '@/query/utils';

const fields = cloneSortFilterFields({ orderField: '', orderBy: '' });
const defaultValues = getDefaultFilterValues(fields);
const fieldTypes = getFilterFieldTypes(fields);
export default {
  fields,
  defaultValues,
  fieldTypes,
};
