<template>
  <div class="md-layout text-center mg-top-30">
    <div class="md-layout-item md-size-100 mg-top-24" v-if="activePolicy && sessionId">
      <review-policy-details
        v-if="activePolicy"
        :policyId="activePolicy.policyId"
        :title="activePolicy.title"
        :isNeedAcceptance="activePolicy.isNeedAcceptance"
        :sessionId="this.sessionId"
        @onSubmitPolicy="onSubmitPolicy"
        @onSubmitPolicyError="onSubmitPolicyError"
      ></review-policy-details>
    </div>
  </div>
</template>
<script>
import AuthMixins from '@/pages/Auth/auth.mixins';
import ReviewPolicyDetails from './ReviewPolicyDetails.vue';
import { mapActions, mapState } from 'vuex';
import { APP_ROUTES, CHALLENGE_NAMES } from '@/core/constants';
import authService from '@/services/auth.service';

export default {
  components: {
    ReviewPolicyDetails,
  },

  mixins: [AuthMixins],

  created() {
    // check cookie
    this.CHECK_USER_COOKIE();
  },
  mounted() {
    // getter
    this.sessionId = this.$store.getters['auth/sessionId'];
    this.activePolicy = this.$store.getters['auth/activePolicy'];
    if (!this.sessionId) {
      // logout
      Promise.resolve(this.$router.push(APP_ROUTES.AUTH_LOGIN));
    }
  },

  data() {
    return {
      sessionId: '',
      activePolicy: null,
    };
  },
  computed: {
    ...mapState('auth', {
      isLogged: (state) => state.isLogged,
      policy: (state) => state.activePolicy,
      isFirstTimeLogin: (state) => state.isFirstTimeLogin,
    }),
  },

  watch: {
    isLogged: {
      handler: function (isLogged) {
        if (isLogged) {
          const redirectTo = this.isFirstTimeLogin
            ? APP_ROUTES.MY_ACCOUNT_SETTINGS
            : this.redirectTo || APP_ROUTES.DASHBOARD;

          this.$router.push(redirectTo);
        }
      },
      deep: true,
    },
    policy: {
      handler: function (policy) {
        this.activePolicy = policy;
      },
    },
  },

  methods: {
    ...mapActions('auth', ['CHECK_USER_COOKIE']),
    onSubmitPolicy: function (res) {
      this.handleSubmitPolicyResponse(res);
    },
    onSubmitPolicyError: function (res) {
      const { error } = res;
      this.handleError(error);
    },
    handleSubmitPolicyResponse(res) {
      try {
        const { data, isNeedAcceptance, isAccepted } = res;
        const isChallengeMode = data.challengeName && data.sessionId;
        const isReviewPolicyMode = isChallengeMode && data.challengeName === CHALLENGE_NAMES.POLICY;
        const isDeclined = isNeedAcceptance && !isAccepted;
        const isLoggedIn = !!data.accessToken;
        if (isReviewPolicyMode) {
          authService.saveReviewPolicyState(this.$store, data);
          return;
        }
        if (isDeclined) {
          // logout
          this.redirectToLoginPage();
          return;
        }
        if (isLoggedIn) {
          this.$getAuthMixins().saveLoginState(this.$store, data);
          return;
        }
        // other unknown error
        throw new Error('Unknown response', JSON.stringify(res));
      } catch (error) {
        this.handleError(error);
      }
    },
    handleError(error) {
      this.$alertError(error.toString());
      this.redirectToLoginPage();
    },
    redirectToLoginPage() {
      // logout
      authService.clearReviewPolicyState(this.$store);
      this.$router.push(APP_ROUTES.AUTH_LOGIN);
    },
  },
};
</script>
<style src="./ReviewPolicy.scss" lang="scss" scoped></style>
