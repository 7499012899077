<template>
  <v-select
    :clearable="false"
    class="select-rows-per-page"
    slot="field-outside"
    v-model="size"
    :reduce="(item) => item.value"
    :options="rowPerPageList"
  >
    <template #option="{ value, label }">{{ numberOnly ? value : label }}</template>
    <template #selected-option="{ value, label }">{{ numberOnly ? value : label }}</template>
  </v-select>
</template>
<script>
import { toNumber } from 'lodash/lang';
import { ConfigMixins } from '@/core/mixins';

export default {
  mixins: [ConfigMixins],
  props: {
    numberOnly: {
      require: false,
      default: false,
    },
    pageSize: {
      type: Number,
      require: false,
      default: null,
    },
  },
  computed: {
    rowPerPageList() {
      return [
        // {value:1, label:1},
        ...this.$appConfig.itemPerPage.map(({ fieldItemName }) => {
          return {
            value: parseInt(fieldItemName),
            label: this.$t('entities/rowsPerPage.rows', {
              rows: fieldItemName,
            }),
          };
        }),
      ];
    },

    itemPerPageDefault() {
      return {
        value: this.$appConfig.itemPerPageDefault,
        label: this.$t('entities/rowsPerPage.rows', {
          rows: this.$appConfig.itemPerPageDefault,
        }),
      };
    },
  },

  data() {
    return {
      // default value
      size: null,
    };
  },

  created() {
    if (this.pageSize) {
      this.size = this.pageSize;
    } else {
      this.size = this.$appConfig.itemPerPageDefault;
    }
  },

  watch: {
    size(value) {
      const valueNumber = toNumber(value);
      if (valueNumber != this.pageSize) {
        this.$emit('pageSizeChanged', valueNumber);
      }
    },
  },

  methods: {
    translate(value) {
      if (this.translated) {
        return value;
      }
      return this.$translate(value);
    },
  },
};
</script>
<style lang="scss" scoped>
.select-rows-per-page {
  width: 160px;
  float: left;
  margin: 5px 0;
}
</style>
