<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit, invalid }">
    <md-dialog
      :md-close-on-esc="true"
      :md-active.sync="isVisible"
      @md-clicked-outside="close"
      class="lims-modal large-modal modal-create-alw-request"
    >
      <div class="dialog-icon-close" @click="close"><md-icon>close</md-icon></div>
      <md-dialog-title>{{ $t('components/ModalCreateAlwRequest.newAlwRequests') }} </md-dialog-title>
      <form class="md-layout lims-form" @submit.prevent="handleSubmit()">
        <lims-block class="block-not-title">
          <div slot="blockContent">
            <div class="md-layout lims-form-row">
              <div class="md-layout-item md-size-50 md-small-size-100">
                <lims-field :model="formData" :schema="schema" field="caseSpecimenBlockIds">
                  <multiselect
                    slot="field"
                    v-model="formData.caseSpecimenBlockIds"
                    :options="caseSpecimenBlockList"
                    :multiple="true"
                    :show-labels="false"
                    group-values="nodes"
                    group-label="text"
                    track-by="text"
                    label="text"
                    :hide-selected="true"
                    :group-select="true"
                    :close-on-select="true"
                    :placeholder="$t('components/ModalCaseBatchChange.placeholderCaseSpecimenBlock')"
                    @input="caseSpecimenBlockVal"
                  >
                  </multiselect>
                </lims-field>
              </div>
              <div class="md-layout-item md-size-50 md-small-size-100">
                <lims-field :model="formData" :schema="schema" field="stainIds" class="textarea-field">
                  <multiselect
                    slot="field"
                    v-model="formData.stainIds"
                    :options="specialStainList"
                    :multiple="true"
                    :show-labels="false"
                    group-values="items"
                    group-label="itemName"
                    :placeholder="$t('components/ModalCaseBatchChange.placeholderSpecialStain')"
                    track-by="fieldItemId"
                    label="fieldItemName"
                    :hide-selected="true"
                    :group-select="false"
                  >
                  </multiselect>
                </lims-field>
              </div>
            </div>

            <div class="md-layout lims-form-row">
              <div class="md-layout-item md-size-100 md-small-size-100">
                <lims-field class="textarea-field" :model="formData" :schema="schema" field="comment">
                  <md-textarea
                    slot="field"
                    v-model="formData.comment"
                    :placeholder="$t('components/ModalCreateAlwRequest.placeholderComment')"
                  ></md-textarea>
                </lims-field>
              </div>
            </div>

            <div class="md-layout lims-form-row">
              <lims-collapse-custom-title
                :wrapperClass="'case-collapse double-reporting-collapse'"
                :collapse="['']"
                :is-collapsed="false"
                icon="keyboard_arrow_down"
              >
                <template slot="collapseTitle">
                  <div class="collapse-title-custom">
                    <div class="left-side-collapse">
                      {{ $t('components/ModalCreateAlwRequest/newAlwRequests/alwRequest') }}
                    </div>
                  </div>
                </template>
                <template slot="md-collapse-pane-1">
                  <md-table class="custom-tbl" md-card>
                    <md-table-row>
                      <md-table-head>
                        {{ $t('components/ModalCreateAlwRequest/newAlwRequests/table.alwID') }}
                      </md-table-head>
                      <md-table-head>
                        {{ $t('components/ModalCreateAlwRequest/newAlwRequests/table.type') }}
                      </md-table-head>
                      <md-table-head>
                        {{ $t('components/ModalCreateAlwRequest/newAlwRequests/table.block') }}
                      </md-table-head>
                      <md-table-head>
                        {{ $t('components/ModalCreateAlwRequest/newAlwRequests/table.stain') }}
                      </md-table-head>
                      <md-table-head>
                        {{ $t('components/ModalCreateAlwRequest/newAlwRequests/table.comment') }}
                      </md-table-head>
                      <md-table-head class="th-center cell-action">
                        {{ $t('global/pages/list.actions') }}
                      </md-table-head>
                    </md-table-row>
                    <md-table-row v-for="(item, index) in alwRequestList" :key="index">
                      <md-table-cell>{{ alwID }}</md-table-cell>
                      <md-table-cell>
                        {{ $t('components/ModalCreateAlwRequest/newAlwRequests/table.typeNew') }}
                      </md-table-cell>
                      <md-table-cell>{{ getDataInArray(item.caseSpecimenBlockIds) }}</md-table-cell>
                      <md-table-cell>{{ getDataInArray(item.stainIds) }}</md-table-cell>
                      <md-table-cell>{{ item.comment }}</md-table-cell>
                      <md-table-cell class="cell-action">
                        <md-button class="md-just-icon md-danger md-simple" @click="deleteNewRequest(item)">
                          <md-icon>delete_forever</md-icon>
                        </md-button>
                      </md-table-cell>
                    </md-table-row>
                  </md-table>
                </template>
              </lims-collapse-custom-title>
            </div>
          </div>
        </lims-block>
        <md-dialog-actions>
          <md-button @click="close" class="lims-form-button">{{ $t('global/button/button.cancel') }}</md-button>
          <md-button @click="addNewRequest" :disabled="invalid" class="lims-form-button md-primary">
            {{ $t('components/ModalCreateAlwRequest/newAlwRequests/button.addNewRequest') }}
          </md-button>
          <md-button
            @click="onCreate"
            :disabled="alwRequestList.length == 0 || isProcessing"
            class="lims-form-button md-primary"
          >
            {{ $t('components/ModalCreateAlwRequest/newAlwRequests/button.createAndSend') }}
          </md-button>
        </md-dialog-actions>
      </form>
    </md-dialog>
  </ValidationObserver>
</template>

<script>
import modalMixins from './modal.mixins';
import { caseAlwService, pathCaseService } from '@/services';
import { mapActions, mapGetters } from 'vuex';
import { APP_EVENTS, newAppEvent, DROPDOWN_SHORT_NAME, USER_TYPE } from '@/core/constants';
import { filterDropdownListByHiddenField } from '@/core/helpers';

export default {
  mixins: [modalMixins],
  props: {
    caseId: {
      type: String,
      require: false,
    },
    laboratoryId: {
      type: String,
      require: true,
    },
    isPauseCountReturnToPull: {
      type: Boolean,
      require: false,
    },
  },
  created: function () {},
  data() {
    return {
      formData: {
        alwRequestTypeId: 0,
        caseSpecimenBlockIds: '',
        stainIds: '',
        comment: '',
      },
      data: {},
      caseSpecimenBlockList: [],
      specialStainList: [],
      rowVersion: null,
      alwRequestList: [],
    };
  },
  computed: {
    ...mapGetters('app/data', ['getDatasetByKey']),
    ...mapGetters('caseData', ['caseData']),
    schema() {
      return {
        entity: 'modals/ModalCreateAlwRequest',
        fields: {
          caseSpecimenBlockIds: 'required',
          stainIds: 'required',
          comment: '',
        },
      };
    },
    shortNames() {
      const shortNames = [
        DROPDOWN_SHORT_NAME.H_AND_E,
        DROPDOWN_SHORT_NAME.IMMUNOS,
        DROPDOWN_SHORT_NAME.SPECIAL_STAINS,
        DROPDOWN_SHORT_NAME.ADDITIONAL_TECHNIQUE,
      ];
      return shortNames.toString();
    },
    alwID() {
      if (this.caseData?.caseALWRequests?.length > 0) {
        const lastItem = this.caseData.caseALWRequests[this.caseData.caseALWRequests.length - 1];
        return lastItem.batchId ? lastItem.batchId + 1 : 1;
      }
      return 1;
    },
  },
  methods: {
    ...mapActions('app/event', ['addEvent']),

    async open() {
      this.isVisible = true;
      this.caseSpecimenBlockList = await this.loadCaseSpecimenBlocks({ caseId: this.caseId });
    },

    async loadCaseSpecimenBlocks(caseId) {
      const { data } = await pathCaseService.getCaseSpecimenBlocks(caseId);
      return [
        {
          text: 'Select All',
          nodes: data,
        },
      ];
    },

    async loadSpecialStainList(caseSpecimenBlockIds) {
      const dataGetSlideList = {
        ...this.convertDataCaseAlwRequest(this.alwRequestList),
        slideCaseSpecimenBlockIds: caseSpecimenBlockIds,
        slideEntityId: this.laboratoryId,
        slideFieldShortCodes: this.shortNames,
      };
      const { data, error } = await caseAlwService.getNewAlwRequestSlidesList(this.caseId, dataGetSlideList);
      if (error) {
        return [];
      } else {
        let specialStainArr = [];
        const specialStainListNotHiddenField = data.slides.reduce((i, value) => {
          const listItem = {
            ...value,
            items: filterDropdownListByHiddenField(value.items),
          };
          if (listItem.items.length > 0) {
            specialStainArr.push(listItem);
          }
          return specialStainArr;
        }, 0);
        return specialStainListNotHiddenField;
      }
    },

    async caseSpecimenBlockVal(val) {
      if (val && val.length > 0) {
        const blockSelectedIds = val ? [val.map((x) => x.id)].join(',') : null;
        this.specialStainList = await this.loadSpecialStainList(blockSelectedIds);
      } else {
        this.specialStainList = [];
      }
    },

    getDataInArray(arr) {
      return arr ? [arr.map((x) => x.text || x.fieldItemName)].join(', ') : null;
    },

    getSlideBlockString(stringError) {
      const err = stringError.split('_').pop();
      const mapObj = {
        ',': ' - ',
        ';': ', ',
      };
      const stringErr = err.replaceAll(/\b(?:,|;)\b/gi, (matched) => mapObj[matched]);
      return stringErr;
    },

    convertDataCaseAlwRequest(data) {
      const listData = data && Array.isArray(data) ? data : [data];
      const caseAlwRequestList = [];
      const dataCaseAlwRequest = listData.reduce((i, value) => {
        const listItem = {
          ...value,
          caseSpecimenBlockIds: value.caseSpecimenBlockIds.map((s) => s.id),
          stainIds: value.stainIds.map((i) => i.fieldItemId),
        };
        caseAlwRequestList.push(listItem);
        return caseAlwRequestList;
      }, []);
      return {
        batchItems: dataCaseAlwRequest,
      };
    },

    addNewRequest() {
      this.$refs.form.validate().then(async (success) => {
        if (success) {
          try {
            let formDataArr = [];
            this.formData.caseSpecimenBlockIds.forEach((block) => {
              this.formData.stainIds.forEach((stainId) => {
                formDataArr.unshift({
                  ...this.formData,
                  caseSpecimenBlockIds: [block],
                  stainIds: [stainId],
                });
              });
            });
            const alwRequestListTmp = formDataArr.concat(this.alwRequestList);
            const dataCaseAlwRequestValidate = {
              ...this.convertDataCaseAlwRequest(alwRequestListTmp),
              slideEntityId: this.laboratoryId,
              slideFieldShortCodes: this.shortNames,
            };
            let { data } = await caseAlwService.addNewAlwRequestValidate(this.caseId, dataCaseAlwRequestValidate);
            if (data.hasErrors && data.errors && data.errors.length > 0) {
              this.errorHandlerForValidation(data.errors);
            } else {
              this.formData.caseSpecimenBlockIds.forEach((block) => {
                this.formData.stainIds.forEach((stainId) => {
                  this.alwRequestList.unshift({
                    ...this.formData,
                    caseSpecimenBlockIds: [block],
                    stainIds: [stainId],
                  });
                });
              });
              this.clearData();
            }
          } catch (err) {
            this.isProcessing = false;
            this.$alertError(err);
          }
        } else {
          this.isProcessing = false;
          this.$alertError(this.$t(`global/errors/message`));
        }
      });
    },

    deleteNewRequest(item) {
      const idx = this.alwRequestList.findIndex((alwRequest) => JSON.stringify(alwRequest) === JSON.stringify(item));
      this.alwRequestList.splice(idx, 1);
    },

    async onCreate() {
      this.userType = this.$store.getters['auth/userType'];
      if (this.alwRequestList && this.alwRequestList.length > 0) {
        this.isProcessing = true;
        const dataForm = this.convertDataCaseAlwRequest(this.alwRequestList);
        let res = await caseAlwService.createNewAlwRequest(this.caseId, dataForm);
        this.isProcessing = false;
        if (res.error) {
          // error handler for creation
          this.errorHandlerForCreation(res.error);
        } else {
          this.$alertSuccess(this.$t('components/ModalCreateAlwRequest.newAlwRequests.created.successMessage'));
          if (this.userType === USER_TYPE.Pathologist && !this.isPauseCountReturnToPull) {
            this.addEvent(
              newAppEvent(APP_EVENTS.EVT_ON_CASE_HIDE_COUNT_DOWN, {
                onHideCountDown: true,
              }),
            );
          }
          this.addEvent(
            newAppEvent(APP_EVENTS.EVT_ON_CREATED_ALW_REQUEST, {
              caseId: this.caseId,
            }),
          );
          this.close();
        }
      }
    },

    showErrors(errorMessages) {
      const message = '<ul>' + errorMessages.join('\n') + '</ul>';
      const finalMessage = this.$t('components/ModalCreateAlwRequest/newAlwRequests/error', {
        error: message,
      });
      this.$alertError(finalMessage);
    },

    errorHandlerForCreation(error) {
      const messageType = this.extractErrorMessageType(error);
      // if error includes any of custom message type,
      // transform and display the corresponded error messages.
      if (messageType) {
        const errorMessages = error.split('|').map((e) => this.marshalErrorMessage(e));
        return this.showErrors(errorMessages);
      }
      // otherwise
      this.$alertError(error);
    },

    errorHandlerForValidation(errors) {
      const errorMessages = [];
      const errorGroups = new Map();
      errors.map((e) => {
        const messageType = this.extractErrorMessageType(e);
        const message = this.getSlideBlockString(this.extractErrorMessage(e));
        if (messageType) {
          errorGroups.has(messageType)
            ? errorGroups.get(messageType).push(message)
            : errorGroups.set(messageType, [message]);
        }
      });
      for (const [messageType, messages] of errorGroups) {
        errorMessages.push(this.translateErrorMessage(messageType, messages.join(', ')));
      }
      // group messages
      this.showErrors(errorMessages);
    },

    extractErrorMessageType(errorMessage) {
      let messageType = null;
      if (errorMessage.includes('DuplicateALWRequest')) {
        messageType = 'duplicate';
      } else if (errorMessage.includes('ExistedALWResponded')) {
        messageType = 'existing';
      } else if (errorMessage.includes('DuplicatedOriginalSlideForALWRequest')) {
        messageType = 'duplicateOriginalSlide';
      }
      return messageType;
    },

    extractErrorMessage(errorMessage) {
      return errorMessage.split('_')[1];
    },

    translateErrorMessage(messageType, errorMessage) {
      const output =
        '<li>' +
        this.$t(`components/ModalCreateAlwRequest/newAlwRequests/error/${messageType}`) +
        errorMessage +
        '</li>';
      return output;
    },

    marshalErrorMessage(errorMessage) {
      const messageType = this.extractErrorMessageType(errorMessage);
      const output =
        '<li>' +
        this.$t(`components/ModalCreateAlwRequest/newAlwRequests/error/${messageType}`) +
        this.getSlideBlockString(errorMessage) +
        '</li>';
      return output;
    },

    setRequestTypeId(value) {
      this.formData.alwRequestTypeId = value;
    },

    clearData() {
      this.formData = {
        alwRequestTypeId: this.formData.alwRequestTypeId,
        caseSpecimenBlockIds: '',
        stainIds: '',
        comment: '',
      };
      this.specialStainList = [];
    },

    close() {
      this.isVisible = false;
      this.$nextTick(() => {
        this.formData = {
          caseSpecimenBlockIds: '',
          stainIds: '',
          comment: '',
        };
        this.caseSpecimenBlockList = [];
        this.specialStainList = [];
        this.alwRequestList = [];
      });
    },
  },
};
</script>

<style lang="scss">
.modal-create-alw-request {
  .multiselect {
    margin: 10px 0 10px 0;
  }
}
</style>
