<template>
  <div>
    <lims-block class="lims-form" :visibleHeader="false">
      <div slot="blockContent">
        <div class="md-layout">
          <div class="md-layout-item md-layout-item-rf">
            <label>{{ $t('pages/case/CaseManagement/component/RFUpload/label.numOfRf') }}: {{ numOfRF }}</label>
          </div>
          <div class="md-layout-item md-layout-item-rf">
            <label
              >{{ $t('pages/case/CaseManagement/component/RFUpload/label.numOfValidRf') }}: {{ numOfValidRF }}</label
            >
          </div>
          <div class="md-layout-item md-layout-item-rf lims-text-danger">
            <label
              >{{ $t('pages/case/CaseManagement/component/RFUpload/label.numOfInvalidRf') }}:
              {{ invalidRfFile.length }}</label
            >
            <div>
              <div v-if="invalidRfFile.length > 0">
                <i>{{ $t('pages/case/CaseManagement/component/RFUpload/label.rf') }}: </i>
                <i v-if="!isShowRFFiles"> {{ invalidRfFile[0] }}</i>
                <i v-else>{{ invalidRfFile.join(', ') }} </i>
                <a
                  class="lims-text-danger"
                  href="#"
                  @click="toggleShowRFFiles()"
                  v-if="invalidRfFile.length > 1 && !isShowRFFiles"
                >
                  <b>, more.</b>
                </a>
                <a class="lims-text-danger" href="#" @click="toggleShowRFFiles()" v-else-if="invalidRfFile.length > 1">
                  <b> less.</b>
                </a>
              </div>
              <div v-if="invalidRfFile.length > 0">
                <i
                  >{{ $t('pages/case/CaseManagement/component/RFUpload/label.error') }}:
                  {{ $t('pages/case/CaseManagement/component/RFUpload/error.uploadRfFile') }}</i
                >
              </div>
            </div>
          </div>
          <div class="md-layout-item md-layout-item-rf lims-text-danger">
            <label class="md-danger"
              >{{ $t('pages/case/CaseManagement/component/RFUpload/label.numOfInvaliCase') }}:
              {{ invalidCaseRefs.length }}</label
            >
            <div>
              <div v-if="invalidCaseRefs.length > 0">
                <i>Case Ref: </i>
                <i v-if="!isShowCaseRefs"> {{ invalidCaseRefs[0] }}</i>
                <i v-else>{{ invalidCaseRefs.join(', ') }} </i>
                <a
                  class="lims-text-danger"
                  href="#"
                  @click="toggleShowAllCaseRefs()"
                  v-if="invalidCaseRefs.length > 1 && !isShowCaseRefs"
                >
                  <b>, more.</b>
                </a>
                <a
                  class="lims-text-danger"
                  href="#"
                  @click="toggleShowAllCaseRefs()"
                  v-else-if="invalidCaseRefs.length > 1"
                >
                  <b> less.</b>
                </a>
              </div>
              <div v-if="invalidCaseRefs.length > 0">
                <i
                  >{{ $t('pages/case/CaseManagement/component/RFUpload/label.error') }}:
                  {{ $t('pages/case/CaseManagement/component/RFUpload/error.uploadRfFile') }}</i
                >
              </div>
            </div>
          </div>
        </div>
        <div class="md-theme-default md-layout">
          <div
            v-for="(item, index) in itemCases"
            :key="index"
            class="md-layout-item md-size-20 md-small-size-50 md-xsmall-size-100 md-medium-size-33"
          >
            <md-card>
              <md-card-content>
                <div class="md-card-content-case-rf-upload">
                  <div class="md-card-title-case-ref lims-text-warning">
                    <i>{{ item.caseReference }}</i>
                  </div>
                  <div class="lims-text-warning">
                    <i>{{ item.fileNameUpload }}</i>
                  </div>
                  <div>
                    <i
                      >{{ $t('pages/case/CaseManagement/component/RFUpload/label.labRef') }}: {{ item.labReference }}</i
                    >
                  </div>
                  <div>
                    <i>{{ $t('pages/case/CaseManagement/component/RFUpload/label.lab') }}: {{ item.laboratoryName }}</i>
                  </div>
                  <div>
                    <i
                      >{{ $t('pages/case/CaseManagement/component/RFUpload/label.clinician') }}:
                      {{ item.clinicianName }}</i
                    >
                  </div>
                  <div>
                    <i
                      >{{ $t('pages/case/CaseManagement/component/RFUpload/label.hospital') }}: {{ item.clinicName }}</i
                    >
                  </div>
                  <div>
                    <i
                      >{{ $t('pages/case/CaseManagement/component/RFUpload/label.path') }}:
                      {{ item.pathologistName }}</i
                    >
                  </div>
                </div>
                <md-checkbox
                  v-model="selectedRows"
                  :value="item.caseId"
                  class="lims-checkbox"
                  style="position: absolute; right: 0 !important; top: 0"
                ></md-checkbox>
              </md-card-content>
            </md-card>
          </div>
        </div>
        <div class="md-layout lims-form-row">
          <div class="md-layout-item md-size-100 md-small-size-100 lims-form-rf-upload-actions">
            <md-button @click="onBackToUpload()" class="lims-form-button md-theme-default">
              {{ $t('pages/case/CaseManagement/component/RFUpload/button.backToUpload') }}
            </md-button>
            <md-button
              @click="onLinkToCase()"
              class="md-primary lims-form-button md-theme-default md-theme-default"
              :disabled="selectedRows.length === 0"
            >
              {{ $t('pages/case/CaseManagement/component/RFUpload/button.linkRfFile') }}
            </md-button>
          </div>
        </div>
      </div>
    </lims-block>
  </div>
</template>

<script>
import { FormMixins } from '@/core/mixins';
import { APP_ROUTES } from '@/core/constants';
import { caseUploadRfService } from '@/services';

export default {
  mixins: [FormMixins],
  created: function () {
    this.initState();
  },
  data() {
    return {
      formData: {},
      isShowCaseRefs: false,
      isShowRFFiles: false,
      numOfRF: 0,
      numOfValidRF: 0,
      invalidCaseRefs: [],
      invalidRfFile: [],
      selectedRows: [],
      itemCases: [],
      fileAttachments: [],
      unSelectedCase: [],
    };
  },

  methods: {
    async initState() {
      const caseRFCheckList = JSON.parse(sessionStorage.getItem('caseRFCheckData'));
      const fileUpload = JSON.parse(sessionStorage.getItem('caseRfFileResponse'));
      this.fileAttachments = fileUpload;
      this.numOfRF = fileUpload.length;
      // Get data invalid case
      const invalidRfFile = [];
      caseRFCheckList.invalidRFCases.forEach(function (item) {
        invalidRfFile.push(item);
      });
      this.invalidRfFile = invalidRfFile;
      const invalidCaseRefs = [];
      caseRFCheckList.invalidCases.forEach(function (item) {
        invalidCaseRefs.push(item);
      });
      this.invalidCaseRefs = invalidCaseRefs;
      this.numOfValidRF = fileUpload.length - this.invalidRfFile.length;
      //Get valid file upload
      const validCaseFiles = [];
      caseRFCheckList.validRFCases.forEach(function (item) {
        validCaseFiles.push(item);
      });
      //mapping to list available upload
      const caseItems = JSON.parse(sessionStorage.getItem('caseItems'));
      this.itemCases = caseItems
        .filter((c) => !invalidCaseRefs.includes(c.caseReference))
        .map((item) => {
          return {
            ...item,
            fileNameUpload:
              validCaseFiles.filter((c) => c.split('.').slice(0, -1).join('.') == item.caseReference)[0] ??
              validCaseFiles.filter((c) => c.split('.').slice(0, -1).join('.') == item.labReference)[0] ??
              validCaseFiles.filter(
                (c) =>
                  item.labReference != undefined &&
                  c.split('.').slice(0, -1).join('.') === item.labReference.replace('/', '-'),
              )[0],
          };
        });
      const unSelectedCase = caseItems
        .filter((c) => invalidCaseRefs.includes(c.caseReference))
        .map((item) => {
          return {
            ...item,
          };
        });
      this.unSelectedCase = unSelectedCase;
      // Check on available case upload
      var selected = [];
      this.itemCases.forEach(function (item) {
        selected.push(item.caseId);
      });
      this.selectedRows = selected;
    },
    toggleShowAllCaseRefs() {
      this.isShowCaseRefs = !this.isShowCaseRefs;
    },
    toggleShowRFFiles() {
      this.isShowRFFiles = !this.isShowRFFiles;
    },
    async onLinkToCase() {
      const selectedLinkToCase = this.selectedRows.map(
        (caseId) => this.itemCases.filter((c) => c.caseId === caseId)[0],
      );
      const formUpload = {
        items: selectedLinkToCase.map((item) => {
          return {
            caseId: item.caseId,
            attachFileRF: this.fileAttachments.filter((file) => item.fileNameUpload == file.fileName)[0],
          };
        }),
      };
      const res = await caseUploadRfService.uploadCaseRf(formUpload);
      if (res.err) {
        return this.$alertErrorServerSide(res.err);
      } else {
        const unSelectedLinkToCase = this.itemCases.filter((c) => !this.selectedRows.includes(c.caseId));
        this.unSelectedCase.forEach((item) => {
          unSelectedLinkToCase.push(item);
        });
        sessionStorage.setItem('selectedFileLinkToCase', JSON.stringify(selectedLinkToCase));
        sessionStorage.setItem('unSelectedFileLinkToCase', JSON.stringify(unSelectedLinkToCase));
        this.$router.push({ path: APP_ROUTES.RF_UPLOAD_CASE_SUCCESS });
      }
    },
    onBackToUpload() {
      sessionStorage.removeItem('caseRfFileResponse');
      this.$router.push({ path: APP_ROUTES.RF_UPLOAD_CASE });
    },
  },
};
</script>

<style lang="scss"></style>
