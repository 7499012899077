import httpClient from './httpClient';

const RESOURCE = 'cases';
export default {
  checkValidRfCase(data = {}) {
    return httpClient.post(`/${RESOURCE}/bulk-upload-check-valid-rfcase`, data);
  },
  uploadTemporaryFile(data = {}) {
    return httpClient.postFileAndData(`/${RESOURCE}/upload-attachfiles`, data);
  },
  uploadCaseRf(data = {}) {
    return httpClient.post(`/${RESOURCE}/link-cases-to-files-upload`, data);
  },
};
