<template>
  <div class="md-layout lims-form">
    <lims-block class="patient-data-block">
      <h4 class="title" slot="blockTitle">{{ $t('pages/case/form/patientData/blockTitle') }}</h4>
      <lims-collapse slot="blockContent" :collapse="['Case Collapse']" icon="keyboard_arrow_down">
        <patient-data
          slot="md-collapse-pane-1"
          :dataEdit="alwFormResource"
          :onHideCountdownPullCase="true"
          :alwFormView="true"
        ></patient-data>
      </lims-collapse>
    </lims-block>
    <!-- document viewer -->
    <md-button
      class="md-button lims-form-button md-theme-default"
      @click="isShowDocumentViewer = !isShowDocumentViewer"
    >
      <span v-if="isShowDocumentViewer">{{ $t('pages/case/form/DocumentViewer/hideDocumentViewer') }}</span>
      <span v-else>{{ $t('pages/case/form/DocumentViewer/showDocumentViewer') }}</span>
    </md-button>
    <lims-block id="split-wrapper" v-if="isShowDocumentViewer" class="block-not-title">
      <div slot="blockContent">
        <div class="range-custom">
          <md-radio v-model="rangesliderVal" :value="0" class="position-25">
            <md-icon>format_align_left</md-icon>
          </md-radio>
          <md-radio v-model="rangesliderVal" :value="50" class="position-50">
            <md-icon>format_align_center</md-icon>
          </md-radio>
          <md-radio v-model="rangesliderVal" :value="100" class="position-75">
            <md-icon>format_align_right</md-icon>
          </md-radio>
        </div>
        <div class="md-layout lims-form-row">
          <vue-split :elements="['#slotOne', '#slotTwo']" direction="horizontal" :min-size="0" :gutter-size="10">
            <div id="slotOne" :style="{ width: rangesliderVal + '%' }">
              <document-view :data-edit="alwFormResource" @openNewWindow="rangesliderVal = 0"></document-view>
            </div>
            <div id="slotTwo" class="case-form-block" :style="{ width: 100 - rangesliderVal + '%' }">
              <lims-block class="specimen-block">
                <h4 class="title" slot="blockTitle">{{ $t('pages/case/form/specimen/blockTitle') }}</h4>
                <lims-collapse slot="blockContent" :collapse="['Case Collapse']" icon="keyboard_arrow_down">
                  <specimen slot="md-collapse-pane-1" :dataEdit="alwFormResource"></specimen>
                </lims-collapse>
              </lims-block>

              <lims-block class="alw-block">
                <h4 class="title" slot="blockTitle">
                  {{ $t('pages/case/form/ALW/blockTitle') + ' (' + totalNumberOfRequestsALW + ')' }}
                </h4>
                <lims-collapse slot="blockContent" :collapse="['Case Collapse']" icon="keyboard_arrow_down">
                  <div slot="md-collapse-pane-1">
                    <additional-lab-work
                      :dataEdit="alwFormResource"
                      :formMode="formMode"
                      @totalNumberOfRequests="getTotalNumberOfRequestsALW"
                    ></additional-lab-work>
                  </div>
                </lims-collapse>
              </lims-block>
            </div>
          </vue-split>
        </div>
      </div>
    </lims-block>

    <lims-block v-show="!isShowDocumentViewer" class="specimen-block">
      <h4 class="title" slot="blockTitle">{{ $t('pages/case/form/specimen/blockTitle') }}</h4>
      <lims-collapse slot="blockContent" :collapse="['Case Collapse']" icon="keyboard_arrow_down">
        <specimen slot="md-collapse-pane-1" :dataEdit="alwFormResource"></specimen>
      </lims-collapse>
    </lims-block>

    <lims-block v-show="!isShowDocumentViewer" class="alw-block">
      <h4 class="title" slot="blockTitle">
        {{ $t('pages/case/form/ALW/blockTitle') + ' (' + totalNumberOfRequestsALW + ')' }}
      </h4>
      <lims-collapse slot="blockContent" :collapse="['Case Collapse']" icon="keyboard_arrow_down">
        <div slot="md-collapse-pane-1">
          <additional-lab-work
            :dataEdit="alwFormResource"
            :formMode="formMode"
            @totalNumberOfRequests="getTotalNumberOfRequestsALW"
          ></additional-lab-work>
        </div>
      </lims-collapse>
    </lims-block>

    <!-- Begin action form -->
    <div class="md-layout lims-form-row">
      <div class="md-layout-item md-size-100 md-small-size-100 lims-form-actions">
        <lims-form-cancel></lims-form-cancel>
      </div>
    </div>
  </div>
</template>

<script>
import { FormMixins, TabMixins } from '@/core/mixins';
import CaseAlwMixins from '@/pages/Case/AlwManagement/CaseAlw.mixins';
import { FORM_MODES } from '@/core/constants';
import LimsCollapse from '@/components/Lims/LimsCollapse';
import PatientData from '@/pages/Case/CaseManagement/Components/PatientDataView.vue';
import Specimen from '@/pages/Case/AlwManagement/Components/Specimen.vue';
import AdditionalLabWork from '@/pages/Case/AlwManagement/Components/AdditionalLabWork.vue';
import DocumentView from '@/pages/Case/CaseManagement/Components/DocumentView';

export default {
  mixins: [FormMixins, CaseAlwMixins, TabMixins],
  components: {
    LimsCollapse,
    PatientData,
    Specimen,
    AdditionalLabWork,
    DocumentView,
  },
  props: {
    formMode: {
      type: Number,
      require: true,
      validator: function (value) {
        // The value must match one of these strings
        return Object.values(FORM_MODES).indexOf(value) !== -1;
      },
    },
    alwFormResource: {
      type: Object,
      require: false,
    },
  },
  data() {
    return {
      totalNumberOfRequestsALW: 0,
      isShowDocumentViewer: false,
      rangesliderVal: 50,
    };
  },
  methods: {
    getTotalNumberOfRequestsALW(val) {
      this.totalNumberOfRequestsALW = val;
    },
  },
};
</script>

<style lang="scss" scoped></style>
