import httpClient from './httpClient';

const RESOURCE = 'case-double-reports';

export default {
  async getListPartnerPathologist(currentUserId, specialtyIds) {
    const params = {};
    params['userId'] = currentUserId;
    return await httpClient.post(
      `/${RESOURCE}/get-list-pathalogis-partner-by-specialties-and-userId`,
      specialtyIds,
      params,
    );
  },
  async acceptRequestDoubleReport(requestpartnerId) {
    return await httpClient.put(`/${RESOURCE}/accepted-request-partner/${requestpartnerId}`);
  },
  async rejectRequestDoubleReport(requestpartnerId) {
    return await httpClient.put(`/${RESOURCE}/rejected-request-partner/${requestpartnerId}`);
  },
};
