const DashboardLayout = () => import('@/layouts/DashboardLayout.vue');
import OcrUploadHistory from '@/pages/Case/OcrUploadHistory/OcrUploadHistory.vue';

import guards from './guards';

export default {
  path: '/ocr-upload-history',
  component: DashboardLayout,
  beforeEnter: guards.checkAuthorizedGuard,
  children: [
    {
      path: '',
      name: 'OcrUploadHistory',
      component: OcrUploadHistory,
      meta: {
        zone: ['Case_OCR_Retrieve'],
      },
    },
  ],
};
