<template>
  <div class="chips-area">
    <md-chip
      v-for="(chip, index) in options"
      :key="index"
      :class="[{ 'primary-bg': index % 2 == 0 }, { 'gray-bg': index % 2 != 0 }]"
    >
      {{ `${chip.label}` }}
      <span @click="onRemoveChip(chip)"><md-icon>close</md-icon></span>
    </md-chip>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      required: false,
      default: function () {
        return [];
      },
    },
  },
  computed: {},
  data: () => ({}),
  methods: {
    onRemoveChip(chip) {
      this.$emit('onRemoveChip', chip);
    },
  },
};
</script>
<style lang="scss" scoped>
.model-cancle-user-form {
  display: inline-flex;
}
</style>
