import { LOCAL_STORAGE_KEYS } from '@/core/constants';
import { deleteCookie, getCookie, setCookie } from '@/core/helpers';
import environment from '@/core/environment';
const REMEMBER_ME_COOKIE_EXPIRED_DAYS = parseInt(environment.VUE_APP_EXPIRED_DAYS || 14);

export default {
  methods: {
    $getAuthMixins: function () {
      return {
        saveLoginState: ($store, data) => {
          this.$dispatchLoginState($store, data);
        },

        saveAccountSettingState: ($store, myAccountSettingData) => {
          this.$dispatchAccountSettingState($store, myAccountSettingData);
        },

        toggleRememberMode: ({ loginId, rememberMe }) => {
          if (rememberMe) {
            setCookie(LOCAL_STORAGE_KEYS.REMEMBER_ME, loginId, REMEMBER_ME_COOKIE_EXPIRED_DAYS);
          } else {
            deleteCookie(LOCAL_STORAGE_KEYS.REMEMBER_ME);
          }
        },
        getRememberLoginId() {
          return getCookie(LOCAL_STORAGE_KEYS.REMEMBER_ME) || '';
        },
      };
    },

    $dispatchLoginState($store, data) {
      $store.dispatch('auth/DO_LOGIN', {
        accessTokenData: {
          accessToken: data.accessToken,
          refreshToken: data.refreshToken,
        },
        user: {
          ...data,
        },
      });
    },

    $dispatchAccountSettingState($store, myAccountSettingData) {
      $store.dispatch('app/LOAD_ACCOUNT_SETTING', {
        accountSetting: myAccountSettingData,
      });
    },
  },
};
