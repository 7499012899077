<template>
  <div>
    <modal-delete-alw-request
      ref="deleteAlwResponseModal"
      @OpenModalProblem="OpenModalProblem"
    ></modal-delete-alw-request>
    <modal-edit-alw-response ref="editAlwResponseModal"></modal-edit-alw-response>
    <modal-bulk-action-problem-alw ref="bulkActionProblemAlw" @onContinue="onContinue"></modal-bulk-action-problem-alw>
    <modal-bulk-action-edit-problem-alw
      ref="bulkActionEditProblemAlw"
      @openModalBulkEditAlw="openModalBulkEditAlw"
    ></modal-bulk-action-edit-problem-alw>
    <modal-bulk-action-edit-alw-request ref="bulkActionEditAlwRequest"></modal-bulk-action-edit-alw-request>
    <div class="md-layout lims-form-row">
      <div class="md-layout-item md-size-25 md-small-size-100">
        <label>
          {{ $t('pages/case/CaseManagement/components/AdditionalLabWork/requested') }}
        </label>
        <div class="static-text">{{ caseALWRequestSummary.countRequested }}</div>
      </div>
      <div class="md-layout-item md-size-25 md-small-size-100">
        <label>{{ $t('pages/case/CaseManagement/components/AdditionalLabWork/completed') }}</label>
        <div class="static-text">{{ caseALWRequestSummary.countCompleted }}</div>
      </div>
      <div class="md-layout-item md-size-25 md-small-size-100">
        <label>{{ $t('pages/case/CaseManagement/components/AdditionalLabWork/cancelled') }}</label>
        <div class="static-text">{{ caseALWRequestSummary.countCancelled }}</div>
      </div>
      <div class="md-layout-item md-size-25 md-small-size-100">
        <label>{{ $t('pages/case/CaseManagement/components/AdditionalLabWork/countOfALWSlides') }}</label>
        <div class="static-text">{{ caseALWRequestSummary.countStainALW }}</div>
      </div>
    </div>
    <div class="md-layout lims-form-row">
      <div class="md-layout-item md-size-25 md-small-size-100">
        <v-select
          class="action-dropdown"
          v-model="bulkAction"
          :options="bulkActionList"
          :reduce="(item) => item.value"
          :placeholder="$t('pages/case/CaseManagement/actionDropdown/selectAction.placeholder')"
          tabenable="yes"
          :tabindex="1"
        >
          <template #option="{ label }">{{ $t(label) }}</template>
          <template #selected-option="{ label }">{{ $t(label) }}</template>
        </v-select>
      </div>
      <div class="md-layout-item md-size-75 md-small-size-100 alw-action" v-if="caseStatus !== CASE_STATUS.LAB">
        <md-button @click="onClear" class="md-button lims-form-button md-theme-default">{{
          $t('global/button/button.clear')
        }}</md-button>
        <md-button
          @click="onApply"
          :disabled="selectRow.length === 0 || !bulkAction"
          class="md-button lims-form-button md-theme-default apply-btn"
          >{{ $t('global/button/button.apply') }}</md-button
        >
      </div>
    </div>
    <div class="md-layout lims-form-row">
      <md-table class="custom-tbl" md-card>
        <md-table-row>
          <md-table-head class="checkbox-column">
            <md-checkbox v-model="selectAll" tabenable="yes"></md-checkbox>
          </md-table-head>
          <md-table-head>
            <div v-html="$t('pages/case/CaseManagement/components/AdditionalLabWork/table.alwId')"></div>
          </md-table-head>
          <md-table-head>
            {{ $t('pages/case/CaseManagement/components/AdditionalLabWork/table.block') }}
          </md-table-head>
          <md-table-head>
            {{ $t('pages/case/CaseManagement/components/AdditionalLabWork/table.type') }}
          </md-table-head>
          <md-table-head>
            <div v-html="$t('pages/case/CaseManagement/components/AdditionalLabWork/table.requestedStain')"></div>
          </md-table-head>
          <md-table-head>
            <div v-html="$t('pages/case/CaseManagement/components/AdditionalLabWork/table.requesterComment')"></div>
          </md-table-head>
          <md-table-head>
            <div v-html="$t('pages/case/CaseManagement/components/AdditionalLabWork/table.requestedOnBr')"></div>
          </md-table-head>
          <md-table-head class="th-center">
            {{ $t('pages/case/CaseManagement/components/AdditionalLabWork/table.status') }}
          </md-table-head>
          <md-table-head>
            <div v-html="$t('pages/case/CaseManagement/components/AdditionalLabWork/table.respondedStain')"></div>
          </md-table-head>
          <md-table-head>
            <div v-html="$t('pages/case/CaseManagement/components/AdditionalLabWork/table.responderComment')"></div>
          </md-table-head>
          <md-table-head>
            <div v-html="$t('pages/case/CaseManagement/components/AdditionalLabWork/table.RespondedOnBr')"></div>
          </md-table-head>
          <md-table-head class="th-center cell-action">
            {{ $t('global/pages/list.actions') }}
          </md-table-head>
        </md-table-row>
        <md-table-row
          v-for="item in caseALWRequests"
          :key="`alw${item.caseALWRequestId}`"
          :class="item.status == STATUSES.CANCELLED && item.isCancelledByCurrentUser ? 'strikethrough' : ''"
        >
          <md-table-cell class="checkbox-column">
            <md-checkbox
              v-if="item.status == STATUSES.REQUESTED"
              v-model="selectRow"
              :value="item.caseALWRequestId"
              class="lims-checkbox"
            ></md-checkbox>
          </md-table-cell>
          <md-table-cell>{{ item.batchId }}</md-table-cell>
          <md-table-cell>{{ item.caseSpecimenBlockName }}</md-table-cell>
          <md-table-cell>{{ item.typeName }}</md-table-cell>
          <md-table-cell>{{ item.stainName }}</md-table-cell>
          <md-table-cell>{{ item.comment }}</md-table-cell>
          <md-table-cell>{{ item.createdDate ? formatDateWithoutTime(item.createdDate) : '' }}</md-table-cell>
          <md-table-cell class="th-center status-column">
            <md-icon
              :class="[
                { 'status-enabled': item.status == STATUSES.COMPLETED },
                { 'status-disabled': item.status == STATUSES.REQUESTED },
                { 'status-invited': item.status == STATUSES.PARTIALLY_COMPLETED },
              ]"
              >adjust</md-icon
            >
          </md-table-cell>
          <md-table-cell>{{ displayRespondStain(item) }}</md-table-cell>
          <md-table-cell>{{ item.respondeComment }}</md-table-cell>
          <md-table-cell>{{ item.respondeOn ? formatDateWithoutTime(item.respondeOn) : '' }}</md-table-cell>
          <md-table-cell class="th-center">
            <md-button
              v-if="item.status == STATUSES.REQUESTED"
              class="md-just-icon md-warning md-simple"
              @click="onEditRequestCase(item)"
            >
              <md-icon>edit</md-icon>
            </md-button>
            <md-button
              v-if="item.status == STATUSES.REQUESTED"
              class="md-just-icon md-danger md-simple"
              @click="onDeleteRequestCase(item)"
            >
              <md-icon>delete_forever</md-icon></md-button
            >
          </md-table-cell>
        </md-table-row>
      </md-table>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { FormMixins } from '@/core/mixins';
import { caseFormService } from '@/services';
import { ALW_SO_STATUS, APP_EVENTS, BULK_ACTION, CASE_STATUS, newAppEvent } from '@/core/constants';

import ModalEditAlwResponse from '@/components/Lims/modals/ModalEditAlwResponse.vue';
import ModalDeleteAlwRequest from '@/components/Lims/modals/ModalDeleteAlwRequest.vue';
import ModalBulkActionProblemAlw from '@/components/Lims/modals/ModalBulkActionProblemAlw.vue';
import ModalBulkActionEditProblemAlw from '@/components/Lims/modals/ModalBulkActionEditProblemAlw.vue';
import ModalBulkActionEditAlwRequest from '@/components/Lims/modals/ModalBulkActionEditAlwRequest.vue';

export default {
  mixins: [FormMixins],
  components: {
    ModalEditAlwResponse,
    ModalBulkActionProblemAlw,
    ModalBulkActionEditProblemAlw,
    ModalBulkActionEditAlwRequest,
    ModalDeleteAlwRequest,
  },
  props: {
    dataEdit: {
      type: Object,
      require: false,
    },
    formMode: {
      type: Number,
      required: true,
    },
  },
  created() {
    this.initData(this.dataEdit);
  },
  data() {
    return {
      caseId: null,
      laboratoryId: null,
      caseReference: '',
      caseALWRequests: [],
      caseALWRequestSummary: {
        countRequested: 0,
        countCompleted: 0,
        countCancelled: 0,
        countStainALW: 0,
      },
      selectRow: [],
      bulkAction: '',
      bulkActionList: [
        {
          value: BULK_ACTION.CompleteAsRequested,
          label: 'entities/case/bulkActionName.CompleteAsRequested',
        },
        {
          value: BULK_ACTION.Cancel,
          label: 'entities/case/bulkActionName.Cancel',
        },
      ],
    };
  },
  computed: {
    ...mapGetters('app/event', [APP_EVENTS.EVT_ON_CREATED_ALW_REQUEST, APP_EVENTS.EVT_ON_ALW_FORM_ON_REFRESH]),
    STATUSES: function () {
      return ALW_SO_STATUS;
    },
    selectAll: {
      get: function () {
        if (this.caseALWRequests) {
          const alwListHasStatusRequest = this.caseALWRequests.filter((item) => item.status == ALW_SO_STATUS.REQUESTED);
          return this.caseALWRequests ? alwListHasStatusRequest.length == this.selectRow.length : false;
        } else return null;
      },
      set: function (value) {
        var selected = [];
        if (value) {
          this.caseALWRequests.forEach(function (item) {
            if (item.status == ALW_SO_STATUS.REQUESTED) {
              selected.push(item.caseALWRequestId);
            }
          });
        }
        this.selectRow = selected;
      },
    },
    caseStatus() {
      return this.dataEdit.status;
    },
    CASE_STATUS() {
      return CASE_STATUS;
    },
  },
  watch: {
    caseALWRequests: {
      deep: true,
      handler: function (val) {
        this.$emit('totalNumberOfRequests', val.length);
      },
    },
    [APP_EVENTS.EVT_ON_ALW_FORM_ON_REFRESH]: {
      deep: true,
      handler: async function (val) {
        // reload data
        if (val) {
          this.reloadALWEditData();
        }
      },
    },
  },
  methods: {
    ...mapActions('app/event', ['removeEvent']),
    initData(data) {
      this.caseId = data.caseId;
      this.caseReference = data.caseReference;
      this.laboratoryId = data.laboratoryId;
      this.caseALWRequests = data.caseALWRequests;
      this.caseALWRequestSummary.countRequested = data.caseALWRequestSummary.countRequested;
      this.caseALWRequestSummary.countCompleted = data.caseALWRequestSummary.countCompleted;
      this.caseALWRequestSummary.countCancelled = data.caseALWRequestSummary.countCancelled;
      this.caseALWRequestSummary.countStainALW = data.caseALWRequestSummary.countStainALW;
    },
    displayRespondStain(item) {
      const listResponseStain = item.caseALWResponseItemReferences.map((s) => {
        return s.fieldItemName;
      });
      const name = listResponseStain.join(', ');
      return name;
    },
    async reloadALWEditData() {
      const { data } = await caseFormService.findOne(this.caseId, false);
      if (data) {
        this.initData(data);
        this.onClear();
      }
      this.removeEvent(newAppEvent(APP_EVENTS.EVT_ON_ALW_FORM_ON_REFRESH));
    },
    formatDateWithoutTime(date) {
      return this.formatDateTimeGlobalNotIncludeTime(date);
    },
    onClear() {
      this.bulkAction = '';
      this.selectRow = [];
    },

    onApply() {
      if (this.bulkAction == BULK_ACTION.Cancel) {
        const listData = this.caseALWRequests
          .filter((item) => this.selectRow.includes(item.caseALWRequestId))
          .map((item) => {
            return {
              caseAlwRequestId: item.caseALWRequestId,
              caseReference: this.caseReference,
              block: item.caseSpecimenBlockName,
              stain: item.stainName,
              typeName: item.typeName,
            };
          });
        this.$refs.bulkActionProblemAlw.open({ itemsAlwRequests: listData });
      }
      if (this.bulkAction == BULK_ACTION.CompleteAsRequested) {
        const listData = this.caseALWRequests
          .filter((item) => this.selectRow.includes(item.caseALWRequestId))
          .map((item) => {
            return {
              caseAlwRequestId: item.caseALWRequestId,
              caseReference: this.caseReference,
              block: item.caseSpecimenBlockName,
              stain: item.stainName,
              typeName: item.typeName,
              typeId: item.typeId,
              caseSpecimenBlockId: item.caseSpecimenBlockId,
              stainId: item.stainId,
            };
          });
        this.$refs.bulkActionEditProblemAlw.open({ itemsAlwRequests: listData });
      }
    },
    OpenModalProblem(data) {
      this.$refs.bulkActionProblemAlw.open(data);
    },
    onEditRequestCase(item) {
      this.$refs.editAlwResponseModal.open(item, this.laboratoryId);
    },
    openModalBulkEditAlw(data) {
      this.$refs.bulkActionEditAlwRequest.open(data);
    },
    //openModalDelete to cancel request
    onContinue(data) {
      this.$refs.deleteAlwResponseModal.open({ itemsAlwRequests: data.itemsAlwRequests });
    },
    onDeleteRequestCase(item) {
      const data = {
        itemsAlwRequests: [
          {
            caseAlwRequestId: item.caseALWRequestId,
            caseReference: this.caseReference,
            block: item.caseSpecimenBlockName,
            stain: item.stainName,
            typeName: item.typeName,
          },
        ],
      };
      this.$refs.deleteAlwResponseModal.open(data);
    },
  },
};
</script>
<style lang="scss" scoped>
.alw-action {
  .md-button {
    height: 36px;
    margin: 10px 5px 0;
  }
}
</style>
