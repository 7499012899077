<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit }">
    <form class="md-layout lims-form" @submit.prevent="handleSubmit()">
      <div class="form-wrapper">
        <h4 class="text-center simpleBooking-txt">
          {{ $t('pages/case/SimpleBooking/caseData/text') }}
        </h4>
        <div class="simpleBooking-content-wrapper">
          <div class="md-layout lims-form-row">
            <div class="md-layout-item md-size-33 md-small-size-50">
              <lims-field :model="formData" :schema="simpleBookingSchema" field="firstName">
                <md-input
                  slot="field"
                  v-model="formData.firstName"
                  type="text"
                  maxlength="250"
                  :disabled="disabledCaseData"
                ></md-input>
              </lims-field>
            </div>
            <div class="md-layout-item md-size-33 md-small-size-50">
              <lims-field :model="formData" :schema="simpleBookingSchema" field="lastName">
                <md-input
                  slot="field"
                  v-model="formData.lastName"
                  type="text"
                  maxlength="250"
                  :disabled="disabledCaseData"
                ></md-input>
              </lims-field>
            </div>
            <div class="md-layout-item md-size-33 md-small-size-50">
              <lims-field :model="formData" :schema="simpleBookingSchema" field="sexId">
                <v-select
                  slot="field"
                  :options="sexList"
                  v-model="formData.sexId"
                  label="fieldItemName"
                  :placeholder="$t('entities/case/form/sexId.placeholder')"
                  :reduce="(option) => option.fieldItemId"
                  :disabled="disabledCaseData"
                >
                  <template #option="{ fieldItemName }">{{ fieldItemName }}</template>
                  <template #selected-option="{ fieldItemName }">{{ fieldItemName }}</template>
                </v-select>
              </lims-field>
            </div>
            <div class="md-layout-item md-size-25 md-small-size-50">
              <lims-field :model="formData" :schema="simpleBookingSchema" field="dob">
                <date-picker
                  :lang="{
                    formatLocale: {
                      firstDayOfWeek: 1,
                    },
                    monthBeforeYear: false,
                  }"
                  slot="field"
                  v-model="formData.dob"
                  format="DD/MM/YYYY"
                  :disabled="disabledCaseData"
                ></date-picker>
              </lims-field>
            </div>
            <div class="md-layout-item md-size-8 md-small-size-50 dobNotProvide-col">
              <div class="dobNotProvide-wrapper">
                <div class="age-patientData">(age {{ formData.age }})</div>
              </div>
            </div>
            <div class="md-layout-item md-size-33 md-small-size-50">
              <lims-field :model="formData" :schema="simpleBookingSchema" field="procedureDate">
                <date-picker
                  slot="field"
                  v-model="formData.procedureDate"
                  format="DD/MM/YYYY"
                  :disabled="disabledCaseData"
                ></date-picker>
              </lims-field>
            </div>

            <div class="md-layout-item md-size-33 md-small-size-50">
              <lims-field :model="formData" :schema="simpleBookingSchema" field="laboratoryReference">
                <md-input
                  slot="field"
                  v-model="formData.laboratoryReference"
                  type="text"
                  maxlength="50"
                  :placeholder="$t('entities/case/form/laboratoryReference.placeholder')"
                  :disabled="disabledCaseData"
                ></md-input>
              </lims-field>
            </div>
            <div class="md-layout-item md-size-33 md-small-size-50">
              <lims-field :model="formData" :schema="simpleBookingSchema" field="hospitalReference">
                <md-input
                  slot="field"
                  v-model="formData.hospitalReference"
                  type="text"
                  maxlength="50"
                  :placeholder="$t('entities/case/form/hospitalReference.placeholder')"
                  :disabled="disabledCaseData"
                ></md-input>
              </lims-field>
            </div>

            <div class="md-layout-item md-size-33 md-small-size-50">
              <lims-field :model="formData" :schema="simpleBookingSchema" field="urgent" class="checkbox-field">
                <lims-tooltip slot="label-info" :content="$t('entities/case/form/urgent.txt')"></lims-tooltip>
                <md-checkbox
                  slot="field"
                  v-model="formData.urgent"
                  class="lims-checkbox"
                  :disabled="disabledCaseData"
                ></md-checkbox>
              </lims-field>
            </div>
          </div>
        </div>

        <div class="simpleBooking-content-wrapper">
          <div class="md-layout lims-form-row">
            <div class="md-layout-item md-size-33 md-small-size-100">
              <lims-field :model="formData" :schema="simpleBookingSchema" field="laboratoryId">
                <lims-single-select
                  slot="field"
                  :options="laboratoryList"
                  v-model="formData.laboratoryId"
                  reduceKey="id"
                  labelKey="text"
                  @input="changeLaboratoryVal"
                  :disabled="disabledCaseData || isEntityClinicAndLaboratory || isEntityLaboratory"
                  :placeholder="$t('entities/case/SimpleBooking/form/placeholder.laboratoryId')"
                ></lims-single-select>
              </lims-field>
            </div>
            <div class="md-layout-item md-size-33 md-small-size-100">
              <lims-field :model="formData" :schema="simpleBookingSchema" field="clinicId">
                <lims-single-select
                  slot="field"
                  :options="clinicList"
                  v-model="formData.clinicId"
                  reduceKey="id"
                  labelKey="text"
                  @input="changeClinicVal"
                  :disabled="disabledCaseData || isEntityClinicAndLaboratory"
                  :placeholder="$t('entities/case/SimpleBooking/form/placeholder.clinicId')"
                ></lims-single-select>
              </lims-field>
            </div>
            <div class="md-layout-item md-size-33 md-small-size-100">
              <lims-field :model="formData" :schema="simpleBookingSchema" field="clinicianId">
                <lims-single-select
                  slot="field"
                  v-model="formData.clinicianId"
                  :options="clinicianList"
                  reduceKey="id"
                  labelKey="text"
                  @input="changeClinicianVal"
                  :disabled="disabledCaseData"
                  :placeholder="$t('entities/case/SimpleBooking/form/placeholder.clinicianId')"
                ></lims-single-select>
              </lims-field>
            </div>
            <div class="md-layout-item md-size-33 md-small-size-100">
              <lims-field :model="formData" :schema="simpleBookingSchema" field="technician1Id">
                <lims-single-select
                  slot="field"
                  v-model="formData.technician1Id"
                  :options="technician1List"
                  reduceKey="id"
                  labelKey="text"
                  :disabled="disabledCaseData || isLabTechnicianView"
                ></lims-single-select>
              </lims-field>
            </div>
            <div class="md-layout-item md-size-33 md-small-size-100">
              <lims-field :model="formData" :schema="simpleBookingSchema" field="specimenTypeId">
                <multiselect
                  slot="field"
                  v-model="formData.specimenTypeId"
                  :options="specimenTypeList"
                  :multiple="false"
                  :show-labels="false"
                  group-values="items"
                  group-label="itemName"
                  placeholder=""
                  track-by="fieldItemId"
                  label="fieldItemName"
                  :hide-selected="false"
                  :group-select="false"
                  :disabled="disabledCaseData"
                  @input="changeSpecimenTypeVal"
                  :selectable="(option) => !formData.specimenTypeId.includes(option)"
                >
                </multiselect>
              </lims-field>
            </div>
            <div class="md-layout-item md-size-33 md-small-size-100">
              <lims-field :model="formData" :schema="simpleBookingSchema" field="pathologistId">
                <multiselect
                  slot="field"
                  v-model="pathologistUser"
                  :options="pathologistOptions"
                  :multiple="false"
                  :show-labels="false"
                  group-values="pathologistUsers"
                  group-label="pathologistGroupName"
                  placeholder=""
                  track-by="pathologistId"
                  label="pathologistName"
                  :hide-selected="false"
                  :group-select="false"
                  :disabled="disabledCaseData"
                  :selectable="(option) => !pathologistUser.includes(option)"
                >
                </multiselect>
              </lims-field>
            </div>
            <div class="md-layout-item md-size-33 md-small-size-100">
              <lims-field :model="formData" :schema="simpleBookingSchema" field="slideIds">
                <multiselect
                  slot="field"
                  v-model="formData.slideIds"
                  :options="slideList"
                  :multiple="true"
                  :show-labels="false"
                  group-values="items"
                  group-label="itemName"
                  placeholder=""
                  track-by="fieldItemId"
                  label="fieldItemName"
                  :hide-selected="true"
                  :group-select="false"
                  :disabled="disabledCaseData"
                >
                </multiselect>
              </lims-field>
            </div>
            <div class="md-layout-item md-size-33 md-small-size-100">
              <lims-field :model="formData" :schema="simpleBookingSchema" field="workStreamIds">
                <lims-single-select
                  slot="field"
                  v-model="formData.workStreamId"
                  :options="workStreamList"
                  reduceKey="fieldItemId"
                  labelKey="fieldItemName"
                  :disabled="disabledCaseData"
                  @input="changeWorkStreamVal"
                ></lims-single-select>
              </lims-field>
            </div>
            <div class="md-layout-item md-size-33 md-small-size-100">
              <lims-field :model="formData" :schema="simpleBookingSchema" field="noOfSpecimen">
                <lims-tooltip
                  slot="label-info"
                  :content="
                    $t('pages/case/CaseManagement/components/SpecimenDetail/noOfSpecimens.tooltip', {
                      noOfSpecimens: caseSpecimenSettingNumber,
                    })
                  "
                ></lims-tooltip>
                <md-input
                  slot="field"
                  type="number"
                  min="1"
                  :max="caseSpecimenSettingNumber"
                  v-model="formData.noOfSpecimens"
                  @keypress="isNumber($event)"
                  :class="NoOfSpecimensErr ? 'isErr no-of-specimen' : 'no-of-specimen'"
                  :disabled="disabledCaseData"
                ></md-input>
              </lims-field>
            </div>
          </div>
        </div>

        <div class="md-layout lims-form-row">
          <div class="md-layout-item md-size-100 md-small-size-100 lims-form-actions">
            <lims-form-cancel></lims-form-cancel>
            <md-button
              @click="onCreateCase()"
              :disabled="disabledCaseData || isProcessingCreateCase"
              class="md-button md-primary lims-form-button md-theme-default"
            >
              {{ $t('pages/case/SimpleBooking/caseData/button.createCase') }}
            </md-button>
            <md-button
              @click="onEndBooking()"
              :disabled="disabledCaseData || isProcessingEndBooking"
              class="md-button md-danger lims-form-button md-theme-default"
            >
              {{ $t('pages/case/SimpleBooking/caseData/button.endBooking') }}
            </md-button>
          </div>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
import { FormMixins } from '@/core/mixins';
import { getSimpleBookingSchema } from '@/schemas/simpleBooking.schema';
import { convertDateTimeToUTCFormat, convertFromDateTimezoneToIsoString } from '@/core/helpers';
import SimpleBookingMixins from '@/pages/Case/SimpleBooking/SimpleBooking.mixins';
import { ENTITY_TYPES } from '@/core/constants';
import { caseFormService } from '@/services';
import { isArray, isEmpty } from 'lodash';
import { calculatePatientAge, validateDob, validateProcedureDate } from '@/pages/Case/CaseManagement/Case.helpers';
import { mapGetters } from 'vuex';
import caseMixins from '@/pages/Case/CaseManagement/Case.mixins';

export default {
  mixins: [FormMixins, SimpleBookingMixins, caseMixins],
  props: {
    dataCommonForm: {
      type: Object,
      default: null,
    },
    listDropdown: {
      type: Object,
      default: null,
    },
    isCallEndBooking: {
      type: Boolean,
      default: false,
    },
    disabledCaseData: {
      type: Boolean,
    },
  },
  created() {
    this.fetchData();
  },
  data() {
    return {
      formData: {
        age: 0,
      },
      listCaseCreateSuccess: [],
      NoOfSpecimensErr: false,
      isProcedureDateValid: false,
      isDobValid: false,
      isEntityLaboratory: false,
      specimenNumberSetting: 1,
      isProcessingCreateCase: false,
      isProcessingEndBooking: false,
    };
  },
  computed: {
    ...mapGetters('caseData', ['specimenNumber']),
    caseSpecimenSettingNumber() {
      return this.specimenNumber || this.specimenNumberSetting;
    },
    isDefaultPathologist() {
      let isDefault = false;
      const pathologistUsers = [];
      if (this.pathologistOptions) {
        this.pathologistOptions.forEach((g) => {
          if (g.pathologistUsers) {
            pathologistUsers.push(...g.pathologistUsers);
          }
        });
        if (this.pathologistUser) {
          pathologistUsers.forEach((p) => {
            if (p.pathologistId === this.pathologistUser.pathologistId) {
              isDefault = p.isDefault;
            }
          });
        }
      }
      return isDefault;
    },
    simpleBookingSchema: function () {
      return getSimpleBookingSchema({
        isDobValid: this.isDobValid,
        isProcedureDateValid: this.isProcedureDateValid,
        caseSpecimenSettingNumber: this.caseSpecimenSettingNumber,
      });
    },
    isEntityClinicAndLaboratory() {
      return this.entityClinicAndLaboratoryLogin ? true : false;
    },
    isSelectedClinicAndLab() {
      return this.formData.clinicAndLaboratory;
    },
  },
  watch: {
    listCaseCreateSuccess: {
      handler(val) {
        this.$emit('input', val);
      },
      deep: true,
    },
    isCallEndBooking: {
      handler(val) {
        if (val) {
          this.onEndBooking();
        }
      },
      deep: true,
    },
    'formData.dob': {
      handler(val) {
        this.formData.age = this.getAge(this.formData.procedureDate, val);
        this.isDobValid = validateDob(val, {
          procedureDate: this.formData.procedureDate,
          labEntryDate: this.formData.labEntry,
        });
        if (this.isDobValid) {
          this.isProcedureDateValid = validateProcedureDate(this.formData.procedureDate, {
            labEntryDate: this.formData.labEntry,
            dob: this.formData.dob,
          });
        }
      },
      deep: true,
    },
    'formData.procedureDate': {
      handler(val) {
        this.formData.age = this.getAge(val, this.formData.dob);
        this.isProcedureDateValid = validateProcedureDate(val, {
          dob: this.formData.dob,
          labEntryDate: this.formData.labEntry,
        });
        if (this.isProcedureDateValid) {
          this.isDobValid = validateDob(this.formData.dob, {
            labEntryDate: this.formData.labEntry,
            procedureDate: this.formData.procedureDate,
          });
        }
      },
      deep: true,
    },
    dataCommonForm: {
      handler: async function (val) {
        if (val) {
          this.formData = {
            ...val,
            noOfSpecimens: 1,
          };
        }
      },
      deep: true,
    },
    listDropdown: {
      handler(val) {
        if (val) {
          this.setDataOptions(val);
        }
      },
      deep: true,
    },
  },
  methods: {
    setDataOptions(val){
      this.clinicList = val.clinicList;
      this.laboratoryList = val.laboratoryList;
      this.technician1List = val.technician1List;
      this.clinicianList = val.clinicianList;
      this.slideList = val.slideList;
      this.specimenTypeList = val.specimenTypeList;
      this.specimenTypeListTmp = val.specimenTypeListTmp;
      this.workStreamList = val.workStreamList;
      this.getPathologistUsers();
      this.updateLaboratoryListAndClinicListWhenSelectedClinicAndLaboratoryType();
    },
    async fetchData() {
      this.specimenNumberSetting = this.$getCaseSpecimenSettings();
      if (this.isLabTechnicianView) {
        this.formData.technician1Id = this.user.userId;
        this.clinicAndLaboratoryList = await this.loadEntityType({
          entityType: ENTITY_TYPES.ClinicLaboratory,
          dependencyEntityId: null,
        });
        this.entityClinicAndLaboratoryLogin = this.clinicAndLaboratoryList.find((x) => x.id == this.user.entityId);
        if (this.entityClinicAndLaboratoryLogin) {
          this.formData.laboratoryId = this.user.entityId;
          this.formData.clinicId = this.user.entityId;
        } else {
          this.isEntityLaboratory = true;
          this.formData.laboratoryId = this.user.entityId;
        }
      }
    },
    getAge(procedure, birth) {
      return calculatePatientAge({ procedure, birth });
    },
    async getPathologistUsers() {
      if (
        this.formData.clinicId &&
        this.formData.clinicianId &&
        this.formData.specimenTypeId &&
        this.formData.laboratoryId
      ) {
        const { pathologistOptions, defaultPathologist } = await this.loadPathologistUsers({
          clinicId: this.formData.clinicId,
          clinicianId: this.formData.clinicianId,
          specimenTypeId: this.formData.specimenTypeId.fieldItemId,
          laboratoryId: this.formData.laboratoryId,
        });

        this.pathologistOptions = pathologistOptions;
        this.pathologistUser = defaultPathologist;
      }else{
        this.pathologistOptions = [];
        this.pathologistUser = null;
      }
    },
    async changeLaboratoryVal(val) {
      await this.loadDataDependLaboratory(val);
      if (this.dataCommonForm.clinicAndLaboratory) {
        this.clinicList = this.listDropdown.clinicList;
        this.updateLaboratoryListAndClinicListWhenSelectedClinicAndLaboratoryType();
      } else {
        this.clinicList = await this.loadEntityType({
          entityType: ENTITY_TYPES.Clinic,
          dependencyEntityId: val ? val : null,
        });
      }
      await this.getPathologistUsers();
    },
    async changeClinicVal(val) {
      await this.loadDataDependClinic(val);
      if (this.dataCommonForm.clinicAndLaboratory) {
        this.laboratoryList = this.listDropdown.laboratoryList;
        this.updateLaboratoryListAndClinicListWhenSelectedClinicAndLaboratoryType();
      } else {
        this.laboratoryList = await this.loadEntityType({
          entityType: ENTITY_TYPES.Laboratory,
          dependencyEntityId: val ? val : null,
        });
      }
      await this.getPathologistUsers();
    },
    async changeWorkStreamVal(val) {
      await this.loadSpecimenTypeDependWorkStream(val);
    },
    changeClinicianVal() {
      this.getPathologistUsers();
    },
    changeSpecimenTypeVal() {
      this.getPathologistUsers();
    },
    dataForm() {
      const dataSlide = [];
      if (this.formData.slideIds) {
        this.formData.slideIds.forEach((slide) => {
          dataSlide.push(slide.fieldItemId);
        });
      }

      const dataForm = {
        ...this.formData,
        specimenTypeId: this.formData.specimenTypeId.fieldItemId,
        slideIds: dataSlide,
        pathologistId: this.pathologistUser ? this.pathologistUser.pathologistId : null,
        pathologistType: this.pathologistUser ? this.pathologistUser.pathologistType : 0,
        specimenDetails: this.formData.specimenDetails ? this.formData.specimenDetails : '',
        // have to convert all data related to datetime to UTC
        labEntry: convertDateTimeToUTCFormat(this.formData.labEntry),
        procedureDate: convertFromDateTimezoneToIsoString(this.formData.procedureDate),
        dob: convertFromDateTimezoneToIsoString(this.formData.dob),
      };
      return dataForm;
    },
    async onCreateCase() {
      this.NoOfSpecimensErr = false;
      this.isProcessingCreateCase = true;
      this.$refs.form.validate().then(async (success) => {
        if (success) {
          try {
            const res = await caseFormService.createCaseBySimpleBookingForm(this.dataForm());
            const data = res.data;
            this.isProcessingCreateCase = false;
            if (res.err) {
              return this.handleCreateCaseErrorMessage(data, res.err);
            } else {
              this.$alertSuccess(
                this.$t('pages/case/SimpleBooking/caseData/create.success', {
                  caseReference: data.caseReference,
                }),
              );
              this.listCaseCreateSuccess.push(data.caseId);
              this.resetUserInforCaseDataForm();
            }
          } catch (errors) {
            this.isProcessingCreateCase = false;
            this.$alertError(errors);
          }
        } else {
          this.isProcessingCreateCase = false;
          this.$alertError(this.$t(`global/errors/message`));
        }
      });
    },

    handleCreateCaseErrorMessage(dataErr, err) {
      if (isEmpty(dataErr)) {
        return this.$alertError(err);
      }
      Object.keys(dataErr).forEach((item) => {
        if (isArray(dataErr[item])) {
          dataErr[item].forEach((i) => {
            if ((i === 'maxLength' || i === 'notValid') && item === 'NoOfSpecimens') {
              this.$alertError(this.$t('pages/case/SimpleBooking/caseData/error.NoOfSpecimens'));
              this.NoOfSpecimensErr = true;
            } else {
              this.$alertError(this.$t('pages/case/SimpleBooking/caseData/error'));
            }
          });
        }
      });
    },

    resetUserInforCaseDataForm() {
      this.resetFormData();        
    },

    async onEndBooking() {
      this.isProcessingEndBooking = this.listCaseCreateSuccess.length > 0 ? true : false;
      if (this.listCaseCreateSuccess.length > 0) {
        await caseFormService.endBookingCreateCase(this.listCaseCreateSuccess);
        this.isProcessingEndBooking = false;
      }
      this.resetCaseDataForm();
      this.$emit('endBooking', true);
      window.scrollTo({ top: 900, behavior: 'smooth' });
    },

    resetCaseDataForm() {
      this.$refs.form.reset();
      this.formData = {};
      this.listCaseCreateSuccess = [];
      this.pathologistUser = null;
      if (this.isLabTechnicianView) {
        this.formData.technician1Id = this.user.userId;
        if (this.entityClinicAndLaboratoryLogin) {
          this.formData.laboratoryId = this.user.entityId;
          this.formData.clinicId = this.user.entityId;
        } else {
          this.isEntityLaboratory = true;
          this.formData.laboratoryId = this.user.entityId;
        }
      }
    },

    updateLaboratoryListAndClinicListWhenSelectedClinicAndLaboratoryType() {
      if (this.isSelectedClinicAndLab) {
        this.clinicList = this.clinicList.filter((item) => item.id === this.dataCommonForm.clinicId);
        this.laboratoryList = this.laboratoryList.filter((item) => item.id === this.dataCommonForm.laboratoryId);
      }
    },

    resetFormData(){
      // reset form
      this.$refs.form.reset();
      // reset all values
      this.formData = {
        age: 0,
        clinicAndLaboratory: null,
        clinicId: null,
        clinicalDetails: null,
        clinicianId: null,
        dob: null,
        firstName: '',
        lastName: '',
        hospitalReference: '',
        labEntry: null,
        laboratoryId: '',
        laboratoryReference:'',
        macroDescription: '',
        noOfSpecimens: 1,
        procedureDate: null,
        sexId: null,
        slideIds: [],
        specimenTypeId: null,
        technician1Id: null,
        workStreamId: null,
        // merge with common data
        ...this.dataCommonForm,        
      };
      
      this.pathologistUser = null;
      // set data list
      this.setDataOptions(this.listDropdown);
    }

  }, // end methods
};
</script>
<style lang="scss">
.pathologist-select-block {
  width: calc(100% - 10px);
}
</style>
