<template>
  <div>
    <modal-delete-alw-request
      @OpenModalProblem="OpenModalProblem"
      ref="deleteAlwResponseModal"
    ></modal-delete-alw-request>
    <modal-edit-alw-response ref="editAlwResponseModal"></modal-edit-alw-response>
    <modal-bulk-action-problem-alw
      v-if="bulkAction && selectRow.length > 0"
      ref="bulkActionProblemAlw"
      @onContinue="onContinue"
    ></modal-bulk-action-problem-alw>
    <modal-bulk-action-edit-alw-request
      v-if="bulkAction && selectRow.length > 0"
      ref="bulkActionEditAlwRequest"
    ></modal-bulk-action-edit-alw-request>
    <div class="toolbar-row" v-if="alwList && alwList.length > 0">
      <div class="select-action">
        <v-select
          class="action-dropdown"
          v-model="bulkAction"
          :options="bulkActionList"
          :reduce="(item) => item.value"
          :placeholder="$t('pages/case/CaseManagement/actionDropdown/selectAction.placeholder')"
        >
          <template #option="{ label }">{{ $t(label) }}</template>
          <template #selected-option="{ label }">{{ $t(label) }}</template>
        </v-select>
        <md-button
          @click="onApply"
          class="md-button lims-form-button md-theme-default apply-btn"
          :disabled="selectRow.length === 0 || !bulkAction"
          >{{ $t('global/button/button.apply') }}</md-button
        >
      </div>
      <div
        class="select-action export-template"
        v-if="this.$isAuthorized(['Case_CaseExport_Retrieve']) && isLabTechView"
      >
        <v-select
          class="action-dropdown"
          v-model="templateExport"
          :options="exportTemplateList"
          :reduce="(item) => item.value"
          :placeholder="$t('pages/case/CaseManagement/actionDropdown/exportTemplate.placeholder')"
        >
          <template #option="{ label }">{{ $t(label) }}</template>
          <template #selected-option="{ label }">{{ $t(label) }}</template>
        </v-select>
        <md-button
          @click="onExportTemplate(templateExport)"
          :disabled="!templateExport"
          class="md-button lims-form-button md-theme-default apply-btn"
          >{{ $t('global/button/button.export') }}</md-button
        >
      </div>
    </div>
    <slot name="toolbar"></slot>
    <md-table class="paginated-table table-striped table-hover lims-table case-list-tbl case-alw-list">
      <div class="table-fix-header">
        <thead>
          <md-table-row>
            <md-table-head class="checkbox-column">
              <md-checkbox v-model="selectAll"></md-checkbox>
            </md-table-head>
            <md-table-head v-for="(col, index) in colTabl" :key="index" :class="col.class">
              <div @mouseover="hoverIn(index)" @mouseout="hoverOut()" @click="sortCol(col.key, col.sort)">
                <span v-html="getColumnName(col)"></span>
                <span>
                  <md-icon v-if="col.sort">
                    {{
                      col.key === orderState.orderField
                        ? orderState.orderBy === 'asc'
                          ? 'arrow_drop_up'
                          : 'arrow_drop_down'
                        : hover === index
                        ? 'arrow_drop_up'
                        : ''
                    }}
                  </md-icon>
                </span>
              </div>
            </md-table-head>
          </md-table-row>
        </thead>
        <tbody>
          <md-table-row
            v-for="(item, index) in alwList"
            :key="`case-${index}`"
            v-long-press="300"
            @click.native="onNavigate($event, item)"
            @long-press-start="onPlusStart"
            @long-press-stop="onPlusStop"
            :class="checkRowHighlight(item)"
          >
            <md-table-cell class="checkbox-column">
              <md-checkbox v-model="selectRow" :value="item.caseALWRequestId" class="lims-checkbox"></md-checkbox>
            </md-table-cell>
            <md-table-cell class="caseRef-labRef-column">
              {{ item.case.caseReference }} <br />
              {{ item.case.laboratoryReference }}
            </md-table-cell>
            <md-table-cell>{{ item.batchId }}</md-table-cell>
            <md-table-cell>{{ item.case.firstName }} {{ item.case.lastName }}</md-table-cell>
            <md-table-cell>{{ item.case.clinicName }}</md-table-cell>
            <md-table-cell>{{ item.clinicType }}</md-table-cell>
            <md-table-cell class="pathologist-column">{{
              item.case.pathologistId
                ? item.case.pathologistName
                : item.case.pathologistTypeName === PATHOLOGIST_TYPE_NAME.Pull
                ? item.case.pathologistTypeName
                : ''
            }}</md-table-cell>
            <md-table-cell>{{ item.specimenType }}</md-table-cell>
            <md-table-cell>{{ item.caseSpecimenBlock.block }}</md-table-cell>
            <md-table-cell>{{ item.type.itemName }}</md-table-cell>
            <md-table-cell>{{ item.stain.itemName }}</md-table-cell>
            <md-table-cell class="date-column">{{
              item.requestedOn ? formatDateTimeWithoutTime(item.requestedOn) : ''
            }}</md-table-cell>
            <md-table-cell style="text-align: center">
              <md-button class="md-just-icon md-simple">
                <md-icon>info</md-icon>
                <md-tooltip md-direction="bottom" class="tooltip-modal"> {{ item.comment }} </md-tooltip>
              </md-button>
            </md-table-cell>

            <md-table-cell class="status-column">
              <md-icon v-if="item.status == STATUSES.REQUESTED" class="status-disabled">adjust</md-icon>
              <md-icon v-if="item.status == STATUSES.PARTIALLY_COMPLETED" class="status-invited">adjust</md-icon>
              <md-icon v-if="item.status == STATUSES.COMPLETED" class="status-enabled">adjust</md-icon>
              <md-icon v-if="item.status == STATUSES.CANCELLED">adjust</md-icon>
            </md-table-cell>

            <md-table-cell class="actions-column">
              <div class="cell-action-inner">
                <div style="width: 25px">
                  <md-button class="md-just-icon md-info md-simple" @click.native="handleAction('view', item)">
                    <md-icon>visibility</md-icon>
                  </md-button>
                </div>
                <div v-if="item.status == STATUSES.REQUESTED" style="width: 25px">
                  <md-button class="md-just-icon md-warning md-simple" @click.native="handleAction('edit', item)">
                    <md-icon>edit</md-icon>
                  </md-button>
                </div>
                <div v-if="item.status == STATUSES.REQUESTED" style="width: 25px">
                  <md-button class="md-just-icon md-danger md-simple" @click.stop="deleteRow(item)">
                    <md-icon>delete_forever</md-icon>
                  </md-button>
                </div>
              </div>
            </md-table-cell>
          </md-table-row>
        </tbody>
      </div>
    </md-table>
    <slot name="pagination"></slot>
  </div>
</template>
<script>
import {
  ALW_SO_STATUS,
  BULK_ACTION,
  CASE_SLIDE_TYPE,
  EXPORT_FILENAME,
  EXPORT_TEMPLATE,
  PATHOLOGIST_TYPE_NAME,
} from '@/core/constants';
import { FormMixins } from '@/core/mixins';
import ModalEditAlwResponse from '@/components/Lims/modals/ModalEditAlwResponse.vue';
import ModalDeleteAlwRequest from '@/components/Lims/modals/ModalDeleteAlwRequest.vue';
import ModalBulkActionProblemAlw from '@/components/Lims/modals/ModalBulkActionProblemAlw.vue';
import ModalBulkActionEditAlwRequest from '@/components/Lims/modals/ModalBulkActionEditAlwRequest.vue';
import { mappingLocationFlagOptionsToObject } from '../../SlideManagement/slide.mixins';
import { isArray } from 'lodash';
import { buildFilterQueryParams, buildFilterFromQuery } from '@/query/utils';
import { caseAlwService, caseSlideService } from '@/services';
import { downloadBlob, extractFileNameFromResponseHeader } from '@/core/helpers';
import { alwQuery } from '@/query';

export default {
  mixins: [FormMixins],
  name: 'AlwListAdminLabTechnician',
  components: {
    ModalEditAlwResponse,
    ModalBulkActionProblemAlw,
    ModalBulkActionEditAlwRequest,
    ModalDeleteAlwRequest,
  },
  props: {
    alwList: {
      required: false,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      selectRow: [],
      selectedRF: [],
      bulkAction: '',
      compatibleCase: [],
      notCompatibleCase: [],
      dataBatchChange: {},
      templateExport: '',
      colTabl: [
        {
          name: ['pages.case.alwManagement/list.caseRef', 'pages.case.alwManagement/list.labRef'],
          sort: false,
          class: 'caseRef-labRef-column',
        },
        {
          name: 'pages.case.alwManagement/list.AlwId',
          key: 'batchId',
          sort: false,
          class: 'AlwId-column',
        },
        {
          name: 'pages.case.alwManagement/list.patient',
          key: 'patientFullname',
          sort: false,
          class: 'patient-column',
        },
        {
          name: 'pages.case.alwManagement/list.clinic',
          key: 'clinicName',
          sort: false,
          class: 'clinic-column',
        },
        {
          name: 'pages.case.alwManagement/list.clinicType',
          key: 'clinicianType',
          sort: false,
          class: 'clinicType-column',
        },
        {
          name: 'pages.case.alwManagement/list.pathologist',
          key: 'pathologistName',
          sort: false,
          class: 'pathologist-column',
        },
        {
          name: 'pages.case.alwManagement/list.specimenType',
          key: 'specimenType',
          sort: false,
          class: 'specimenType-column',
        },
        {
          name: 'pages.case.alwManagement/list.block',
          key: 'block',
          sort: false,
          class: 'block-column',
        },
        {
          name: 'pages.case.alwManagement/list.type',
          key: 'type',
          sort: false,
          class: 'type-column',
        },
        {
          name: 'pages.case.alwManagement/list.requestedStain',
          key: 'requestedStain',
          sort: false,
          class: 'requestedStain-column',
        },
        {
          name: 'pages.case.alwManagement/list.requestedOn',
          key: 'requestedOn',
          sort: false,
          class: 'requestedOn-column date-column',
        },
        {
          name: 'pages.case.alwManagement/list.RequesterComment',
          key: 'RequesterComment',
          sort: false,
          class: 'requesterComment-column',
        },
        {
          name: 'pages.case.alwManagement/list.status',
          key: 'statusName',
          sort: false,
          class: 'status-column',
        },
        {
          name: 'global/pages/list.actions',
          key: 'actions',
          sort: false,
          class: 'actions-column',
        },
      ],
      hover: '',
      orderState: {
        orderField: 'caseReference',
        orderBy: 'asc',
      },
      bulkActionList: [
        {
          value: BULK_ACTION.CompleteAsRequested,
          label: 'entities/case/bulkActionName.CompleteAsRequested',
        },
        {
          value: BULK_ACTION.Cancel,
          label: 'entities/case/bulkActionName.Cancel',
        },
      ],
      exportTemplateList: [
        {
          value: EXPORT_TEMPLATE.Alw,
          label: 'entities/case/exportTemplateName.Alw',
        },
        {
          value: EXPORT_TEMPLATE.SlideP1000AlwClinic,
          label: 'entities/case/exportTemplateName.SlideP1000AlwClinic',
        },
        {
          value: EXPORT_TEMPLATE.SlideP1000AlwCaseReference,
          label: 'entities/case/exportTemplateName.SlideP1000AlwCaseReference',
        },
      ],
    };
  },
  computed: {
    isLabTechView() {
      const userType = this.$store.getters['auth/userType'];
      return userType === this.USER_TYPES().LabTechnician;
    },
    STATUSES: function () {
      return ALW_SO_STATUS;
    },
    PATHOLOGIST_TYPE_NAME() {
      return PATHOLOGIST_TYPE_NAME;
    },
    selectAll: {
      get: function () {
        return this.alwList ? this.alwList.length == this.selectRow.length : false;
      },
      set: function (value) {
        var selected = [];

        if (value) {
          this.alwList.forEach(function (item) {
            selected.push(item.caseALWRequestId);
          });
        }
        this.selectRow = selected;
      },
    },
  },
  watch: {
    templateExport() {
      if (this.selectRow && this.selectRow.length === 0) {
        this.selectAll = true;
      }
    },
    alwList: {
      handler: function (val) {
        if (val) {
          if (this.templateExport) {
            this.selectAll = true;
          } else {
            this.selectRow = [];
          }
        }
      },
      deep: true,
    },
  },
  methods: {
    hoverIn(index) {
      this.hover = index;
    },
    hoverOut() {
      this.hover = '';
    },
    sortCol(colName, isSort) {
      if (isSort) {
        if (this.orderState.orderField === colName) {
          if (this.orderState.orderBy === 'asc') {
            this.orderState.orderBy = 'desc';
          } else {
            this.orderState.orderBy = 'asc';
          }
        } else {
          this.orderState.orderField = colName;
          this.orderState.orderBy = 'asc';
        }
      }
    },
    onNavigate(e, item) {
      if (!this.longPressMode) {
        e.preventDefault();
        this.handleAction('view', item);
      }
    },
    onPlusStart() {
      this.plusInterval = setInterval(() => {
        this.longPressMode = true;
      }, 100);
    },
    onPlusStop() {
      setTimeout(() => {
        this.longPressMode = false;
      }, 500);
      clearInterval(this.plusInterval);
    },
    handleAction(action, item) {
      this.$emit('action', {
        action,
        item,
      });
    },
    getColumnName(c) {
      if (isArray(c.name)) {
        return c.name.map((n) => this.$translate(n)).join('<br/>');
      }
      return this.$translate(c.name);
    },
    deleteRow(item) {
      const data = {
        itemsAlwRequests: [
          {
            caseAlwRequestId: item.caseALWRequestId,
            caseReference: item.case.caseReference,
            block: item.caseSpecimenBlockName,
            stain: item.stain.itemName,
            typeName: item.type.itemName,
          },
        ],
      };
      this.$refs.deleteAlwResponseModal.open(data);
    },
    OpenModalProblem(data) {
      this.$refs.bulkActionProblemAlw.open(data);
    },
    checkRowHighlight(item) {
      if (item.case.urgent) {
        return 'red-color';
      } else if (item.case.isMarkReaded) {
        return 'grey-color';
      } else if (item.case.status == ALW_SO_STATUS.CANCELLED && item.case.isCancelledByCurrentUser) {
        return 'strikethrough';
      } else {
        return '';
      }
    },
    formatDateTimeWithoutTime(date) {
      return this.formatDateTimeGlobalNotIncludeTime(date);
    },
    async onApply() {
      let listData;
      if (this.bulkAction == BULK_ACTION.Cancel) {
        listData = this.alwList
          .filter((item) => this.selectRow.includes(item.caseALWRequestId))
          .map((item) => {
            return {
              caseAlwRequestId: item.caseALWRequestId,
              caseReference: item.case.caseReference,
              block: item.caseSpecimenBlock.block,
              stain: item.stain.itemName,
              typeName: item.type.itemName,
            };
          });
      }
      if (this.bulkAction == BULK_ACTION.CompleteAsRequested) {
        listData = this.alwList
          .filter((item) => this.selectRow.includes(item.caseALWRequestId))
          .map((item) => {
            return {
              caseAlwRequestId: item.caseALWRequestId,
              caseReference: item.case.caseReference,
              block: item.caseSpecimenBlock.block,
              stain: item.stain.itemName,
              typeName: item.type.itemName,
              typeId: item.typeId,
              caseSpecimenBlockId: item.caseSpecimenBlockId,
              stainId: item.stain.itemId,
            };
          });
      }
      this.$refs.bulkActionProblemAlw.open({ itemsAlwRequests: listData, action: this.bulkAction });
    },

    onContinue(data) {
      if (this.bulkAction == BULK_ACTION.Cancel) {
        this.$refs.deleteAlwResponseModal.open({ itemsAlwRequests: data.itemsAlwRequests });
      }
      if (this.bulkAction == BULK_ACTION.CompleteAsRequested) {
        this.$refs.bulkActionEditAlwRequest.open(data);
      }
    },

    async onExportTemplate(template) {
      if (this.selectRow && this.selectRow.length === 0) {
        this.$alertWarning(this.$t('pages/case/CaseManagement/List/ExportAction/SelectAtLeastOneCase'));
      } else {
        const query = this.$route.query;
        const filters = buildFilterFromQuery(alwQuery.filters, query);
        const sort = buildFilterFromQuery(alwQuery.sort, query);
        const pagination = buildFilterFromQuery(alwQuery.pagination, {
          ...query,
        });
        const queryParams = { filters, pagination, sort };
        const queryParamsToExports = {
          filters: buildFilterQueryParams(queryParams.filters),
          pagination: buildFilterQueryParams({
            pageSize: queryParams.pagination.pageSize,
            pageNumber: queryParams.pagination.pageNumber[0],
          }),
        };

        if (template && template == EXPORT_TEMPLATE.Alw) {
          const dataExport = this.selectAll
            ? { isExportAllCases: this.selectAll }
            : {
                isExportAllCases: this.selectAll,
                caseAlwRequestIds: this.selectRow,
              };
          const res = await caseAlwService.exportAlwRequest(dataExport, queryParams);
          const fileName = extractFileNameFromResponseHeader(res.headers);
          if (res.err) {
            const responseError = (await res.data.text()) ? JSON.parse(await res.data.text()) : null;
            if (responseError && responseError.error === 'NoDataToExport') {
              this.$alertError(this.$t('pages/case/CaseManagement/List/ExportAction/ALW'));
            } else {
              return this.$alertError(responseError?.error ? responseError.error : res.err);
            }
          } else {
            if (res.data) {
              downloadBlob(res.data, fileName || EXPORT_FILENAME.ALW);
              this.$alertSuccess(this.$t('pages/case/CaseManagement/List/ExportAction/ALW/Success'));
            }
          }
        }

        if (template && template == EXPORT_TEMPLATE.SlideP1000AlwClinic) {
          if (query && query.AlwRespondDate) {
            // get data export
            const dataSlides = [];
            this.selectRow.forEach((item) => {
              dataSlides.push({ blockSlideItemId: item, slideType: CASE_SLIDE_TYPE.ALWSlide });
            });

            const dataExport = this.selectAll
              ? { isExportAllCases: this.selectAll }
              : {
                  isExportAllCases: this.selectAll,
                  slides: dataSlides,
                };

            // get LOCATION_FLAG
            const getSlideLocationFlag = await caseSlideService.getSlideLocationFlags();
            const locationFlagOptions = getSlideLocationFlag.CaseSlideLocationFlag;
            const LOCATION_FLAG = mappingLocationFlagOptionsToObject(locationFlagOptions, true);

            const res = await caseSlideService.exports(
              'alw-request/export-alw-slides-p1000-clinic',
              queryParamsToExports,
              dataExport,
              LOCATION_FLAG,
            );
            const fileName = extractFileNameFromResponseHeader(res.headers);
            if (res.err) {
              const responseError = (await res.data.text()) ? JSON.parse(await res.data.text()) : null;
              if (responseError && responseError.error === 'NoDataToExport') {
                return this.$alertError(this.$t('pages/case/CaseManagement/List/ExportAction/AlwSlideP1000/Error'));
              } else {
                return this.$alertError(responseError?.error ? responseError.error : res.err);
              }
            } else {
              if (res.data) {
                downloadBlob(res.data, fileName || EXPORT_FILENAME.ALW_SLIDE_P1000);
                this.$alertSuccess(this.$t('pages/case/CaseManagement/List/ExportAction/AlwSlideP1000Clinic/Success'));
              }
            }
          } else {
            this.$alertWarning(this.$t('pages/case/CaseManagement/List/ExportAction/SelectAlwRespondedDate'));
          }
        }
        if (template && template == EXPORT_TEMPLATE.SlideP1000AlwCaseReference) {
          if (query && query.AlwRespondDate) {
            // get data export
            const dataSlides = [];
            this.selectRow.forEach((item) => {
              dataSlides.push({ blockSlideItemId: item, slideType: CASE_SLIDE_TYPE.ALWSlide });
            });

            const dataExport = this.selectAll
              ? { isExportAllCases: this.selectAll }
              : {
                  isExportAllCases: this.selectAll,
                  slides: dataSlides,
                };

            // get LOCATION_FLAG
            const getSlideLocationFlag = await caseSlideService.getSlideLocationFlags();
            const locationFlagOptions = getSlideLocationFlag.CaseSlideLocationFlag;
            const LOCATION_FLAG = mappingLocationFlagOptionsToObject(locationFlagOptions, true);

            const res = await caseSlideService.exports(
              'alw-request/export-alw-slides-p1000-case-reference',
              queryParamsToExports,
              dataExport,
              LOCATION_FLAG,
            );
            const fileName = extractFileNameFromResponseHeader(res.headers);
            if (res.err) {
              const responseError = (await res.data.text()) ? JSON.parse(await res.data.text()) : null;
              if (responseError && responseError.error === 'NoDataToExport') {
                return this.$alertError(this.$t('pages/case/CaseManagement/List/ExportAction/AlwSlideP1000/Error'));
              } else {
                return this.$alertError(responseError?.error ? responseError.error : res.err);
              }
            } else {
              if (res.data) {
                downloadBlob(res.data, fileName || EXPORT_FILENAME.ALW_SLIDE_P1000);
                this.$alertSuccess(
                  this.$t('pages/case/CaseManagement/List/ExportAction/AlwSlideP1000CaseReference/Success'),
                );
              }
            }
          } else {
            this.$alertWarning(this.$t('pages/case/CaseManagement/List/ExportAction/SelectAlwRespondedDate'));
          }
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.lims-table.case-alw-list .table-fix-header th,
.lims-table.case-alw-list .table-fix-header td {
  width: 80px;
  min-width: 80px;
}
</style>
