<template>
  <md-card class="md-card-login" :class="{ 'md-card-hidden': cardHidden }">
    <md-card-header :class="getClass(headerColor)">
      <slot name="title"></slot>
    </md-card-header>

    <md-card-content>
      <slot name="new-password"></slot>
      <slot name="password-strength"></slot>
      <slot name="check-password-strength"></slot>
      <slot name="confirm-password"></slot>
      <slot name="actions"></slot>
      <slot name="back-to-login"></slot>
    </md-card-content>
  </md-card>
</template>

<script>
export default {
  name: 'lims-reset-password-card',
  props: {
    headerColor: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      cardHidden: true,
    };
  },
  beforeMount() {
    setTimeout(this.showCard, 0);
  },
  methods: {
    showCard: function () {
      this.cardHidden = false;
    },
    getClass: function (headerColor) {
      return 'md-card-header-' + headerColor + '';
    },
  },
};
</script>

<style lang="css"></style>
