<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit }">
    <modal-concurrency-issue
      slot="blockTitle"
      ref="caseConcurrencyIssueModal"
      @onContinue="onConcurrencyIssueContinue"
    ></modal-concurrency-issue>
    <modal-confirm-generic
      ref="confirmModal"
      :title="$t('components/lims/modals/ModalConfirmation.title')"
      :description="$t('components/lims/modals/ModalConfirmSaveChange.text')"
      @onConfirm="onConfirmChangeSpecimenType"
      @onCancel="onCancelChangeSpecimenType"
    />
    <form class="md-layout lims-form" v-if="caseFormResource" @submit.prevent="handleSubmit()" :key="localKey">
      <anchor-system></anchor-system>
      <!-- Begin patient data block -->
      <lims-block class="patient-data-block">
        <h4 class="title" slot="blockTitle">{{ $t('pages/case/form/patientData/blockTitle') }}</h4>
        <modal-delete-case ref="deleteCase" slot="blockTitle" @onDelete="onDeleteCase"></modal-delete-case>
        <modal-restore-case ref="restoreCaseModal" slot="blockTitle" @onRestore="onRestoreCase"></modal-restore-case>
        <lims-collapse
          slot="blockContent"
          :collapse="['Case Collapse']"
          icon="keyboard_arrow_down"
          :expand-collapse-block-name="'patientDataBlock'"
        >
          <patient-data slot="md-collapse-pane-1" :form-mode="formMode" :dataEdit="caseFormResource"></patient-data>
        </lims-collapse>
      </lims-block>
      <!-- End patient data block -->
      <!-- Begin document viewer and request view information -->
      <document-viewer-and-request-view-information
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
        :case-form-resource="caseFormResource"
        :form-mode="formMode"
      ></document-viewer-and-request-view-information>
      <!-- End document viewer and request view information -->
      <!-- Begin billing clinic and laboratory block -->
      <lims-block id="billingClinicAndLaboratoryBlock">
        <h4 class="title" slot="blockTitle">{{ $t('pages/case/form/billingClinicAndLaboratory/blockTitle') }}</h4>
        <lims-collapse
          slot="blockContent"
          :collapse="['Case Collapse']"
          icon="keyboard_arrow_down"
          :expand-collapse-block-name="'billingClinicAndLaboratoryBlock'"
        >
          <div slot="md-collapse-pane-1">
            <clinic-and-laboratory :form-mode="formMode" :data-edit="caseFormResource"></clinic-and-laboratory>
            <billing-data :form-mode="formMode" :data-edit="caseFormResource"></billing-data>
          </div>
        </lims-collapse>
      </lims-block>
      <!-- End billing clinic and laboratory block -->
      <!-- Begin specimen, pathologist and document block  -->
      <lims-block id="specimenPathologist">
        <h4 class="title" slot="blockTitle">{{ $t('pages/case/form/specimenPathologistAndDocument/blockTitle') }}</h4>
        <lims-collapse
          slot="blockContent"
          :collapse="['Case Collapse']"
          icon="keyboard_arrow_down"
          :expand-collapse-block-name="'specimenPathologist'"
        >
          <div slot="md-collapse-pane-1">
            <specimen-detail :form-mode="formMode" :dataEdit="caseFormResource"></specimen-detail>
            <pathologist :form-mode="formMode" :dataEdit="caseFormResource"></pathologist>
            <document :form-mode="formMode" :dataEdit="caseFormResource"></document>
          </div>
        </lims-collapse>
      </lims-block>
      <!-- End specimen, pathologist and document block -->
      <!-- Begin further options block -->
      <lims-block id="furtherOptionBlock" v-if="isAdminView">
        <h4 class="title" slot="blockTitle">{{ $t('pages/case/form/furtherOptions/blockTitle') }}</h4>
        <lims-collapse
          slot="blockContent"
          :collapse="['Case Collapse']"
          icon="keyboard_arrow_down"
          :expand-collapse-block-name="'furtherOptionBlock'"
        >
          <further-options
            slot="md-collapse-pane-1"
            :form-mode="formMode"
            :dataEdit="caseFormResource"
          ></further-options>
        </lims-collapse>
      </lims-block>
      <!-- End further options block -->
      <!-- Begin action form -->
      <div id="formButton" class="md-layout lims-form-row">
        <div class="md-layout-item md-size-100 md-small-size-100 lims-form-actions">
          <md-button @click="onBack()" class="lims-form-button">
            {{ $t('global/button/button.back') }}
          </md-button>
          <md-button
            v-if="isAdminView"
            @click="onViewAuditTrustChain(caseFormResource.caseId)"
            class="md-button md-primary lims-form-button md-theme-default"
          >
            {{ $t('global/button/button.auditTrustChain') }}
          </md-button>
          <md-button
            v-if="formMode !== VIEW_MODE && !caseFormResource.isDeleted"
            @click="onSaveCase()"
            :disabled="isProcessingSave"
            class="md-button md-primary lims-form-button md-theme-default"
          >
            {{ $t('global/button/button.save') }}
          </md-button>
          <LimsFormEditButton v-if="formMode === VIEW_MODE" :editUrl="editUrl" />
          <template v-if="formMode !== VIEW_MODE">
            <md-button
              v-if="isShowDeleteBtn(caseFormResource)"
              @click="onDelete()"
              class="md-button md-danger lims-form-button md-theme-default"
            >
              {{ $t('global/button/button.delete') }}
            </md-button>
            <md-button
              v-if="isShowRestoreCaseBtn(caseFormResource)"
              @click="onRestore()"
              class="md-button md-danger lims-form-button md-theme-default"
            >
              {{ $t('global/button/button.restore') }}
            </md-button>
          </template>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import { FormMixins, TabMixins } from '@/core/mixins';
import { APP_ROUTES, FORM_MODES } from '@/core/constants';
import CaseEditMixins from '@/pages/Case/CaseManagement/Edit/caseEdit.mixins';
import LimsBlock from '@/components/Lims/LimsBlock.vue';
import BillingData from '@/pages/Case/CaseManagement/Components/BillingData';
import ClinicAndLaboratory from '@/pages/Case/CaseManagement/Components/ClinicAndLaboratory';
import LimsCollapse from '@/components/Lims/LimsCollapse';
import PatientData from '@/pages/Case/CaseManagement/Components/PatientData';
import SpecimenDetail from '@/pages/Case/CaseManagement/Components/SpecimenDetail';
import Pathologist from '@/pages/Case/CaseManagement/Components/Pathologist';
import Document from '@/pages/Case/CaseManagement/Components/Document';
import FurtherOptions from '@/pages/Case/CaseManagement/Components/FurtherOptions';
import caseSaveMixins, { CASE_VIEW_TYPES } from './caseSave.mixins';
import ModalRestoreCase from '@/components/Lims/modals/ModalRestoreCase.vue';
import ModalDeleteCase from '@/components/Lims/modals/ModalDeleteCase';
import DocumentViewerAndRequestViewInformation from '@/pages/Case/CaseManagement/Components/DocumentViewerAndRequestViewInformation';

export default {
  mixins: [FormMixins, TabMixins, CaseEditMixins, caseSaveMixins],
  components: {
    FurtherOptions,
    ModalRestoreCase,
    ModalDeleteCase,
    Pathologist,
    SpecimenDetail,
    PatientData,
    LimsCollapse,
    BillingData,
    ClinicAndLaboratory,
    Document,
    LimsBlock,
    DocumentViewerAndRequestViewInformation,
  },
  props: {
    formMode: {
      type: Number,
      require: true,
      validator: function (value) {
        // The value must match one of these strings
        return Object.values(FORM_MODES).indexOf(value) !== -1;
      },
    },
    caseFormResource: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {
      viewType: CASE_VIEW_TYPES.LAB_STATUS_LABTECH_ADMIN,
      localKey: 0,
      totalNumberOfRequestsALW: 0,
      totalNumberOfRequestsSO: 0,
    };
  },
  computed: {
    editUrl() {
      return APP_ROUTES.CASE_EDIT + '/' + this.caseFormResource.caseId;
    },
    isAdminView() {
      const userType = this.$store.getters['auth/userType'];
      return userType === this.USER_TYPES()?.Administrator;
    },
  },
  methods: {
    onBack() {
      this.$router.back();
    },
    onViewAuditTrustChain(caseId) {
      this.$router.push(`${APP_ROUTES.CASE_AUDIT_TRAIL}/${caseId}`);
    },
    getTotalNumberOfRequestsALW(val) {
      this.totalNumberOfRequestsALW = val;
    },
    getTotalNumberOfRequestsSO(val) {
      this.totalNumberOfRequestsSO = val;
    },
  },
};
</script>
<style lang="scss"></style>
