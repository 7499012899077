<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit }">
    <md-dialog
      :md-close-on-esc="true"
      :md-active.sync="isVisible"
      @md-clicked-outside="close"
      class="lims-modal large-modal"
    >
      <div class="dialog-icon-close" @click="close"><md-icon>close</md-icon></div>
      <form class="md-layout lims-form" @submit.prevent="handleSubmit()">
        <lims-block class="mg-top-24">
          <h4 class="title" slot="blockTitle">{{ $t('pages/account/user/forms/personalInfoBlock') }}</h4>
          <div slot="blockContent">
            <div class="md-layout lims-form-row">
              <div class="md-layout-item md-size-25 md-small-size-100">
                <lims-field :model="formData.personalInfo" :schema="userSchema.personalInfo" field="userType">
                  <v-select disabled slot="field" :options="userTypesList" v-model="formData.personalInfo.userType">
                    <template #option="{ label }">
                      {{ $t(label) }}
                    </template>
                    <template #selected-option="{ label }">
                      {{ $t(label) }}
                    </template>
                  </v-select>
                </lims-field>
              </div>
              <div class="md-layout-item md-size-30 md-small-size-100">
                <lims-field :model="formData.personalInfo" :schema="userSchema.personalInfo" field="role">
                  <v-select slot="field" v-model="formData.personalInfo.role" :options="roleList">
                    <template #option="{ label }">
                      {{ label }}
                    </template>
                    <template #selected-option="{ label }">
                      {{ label }}
                    </template>
                  </v-select>
                </lims-field>
              </div>
            </div>
            <!-- new row -->
            <div class="md-layout lims-form-row">
              <div class="md-layout-item md-size-25 md-small-size-100">
                <lims-field :model="formData.personalInfo" :schema="userSchema.personalInfo" field="username">
                  <lims-tooltip
                    slot="label-info"
                    :content="$t('entities/user/personalInfo/userNameTooltip')"
                  ></lims-tooltip>
                  <md-input
                    slot="field"
                    v-model="formData.personalInfo.username"
                    type="text"
                    :class="errMessage.username ? 'input-err' : ''"
                    @blur="onCheckUnique('username')"
                    @change="$lowercase(formData.personalInfo, 'username')"
                  >
                  </md-input>
                  <lims-tooltip
                    slot="icon"
                    :content="$t('entities/user/personalInfo/userNameTooltipRules')"
                  ></lims-tooltip>
                </lims-field>
                <div v-if="errMessage.username" class="form-error">
                  {{ $t('global/errors/unique', { field: 'Username' }) }}
                </div>
              </div>
              <div class="md-layout-item md-size-25 md-small-size-100">
                <lims-field :model="formData.personalInfo" :schema="userSchema.personalInfo" field="title">
                  <v-select slot="field" v-model="formData.personalInfo.title" :options="userTitles">
                    <template #option="{ label }">{{ label }}</template>
                    <template #selected-option="{ label }">{{ label }}</template>
                  </v-select>
                </lims-field>
              </div>
              <div class="md-layout-item md-size-25 md-small-size-100">
                <lims-field :model="formData.personalInfo" :schema="userSchema.personalInfo" field="firstName">
                  <md-input
                    slot="field"
                    v-model="formData.personalInfo.firstname"
                    type="text"
                    maxlength="250"
                  ></md-input>
                </lims-field>
              </div>
              <div class="md-layout-item md-size-25 md-small-size-100">
                <lims-field :model="formData.personalInfo" :schema="userSchema.personalInfo" field="lastName">
                  <md-input
                    slot="field"
                    v-model="formData.personalInfo.lastname"
                    type="text"
                    maxlength="250"
                  ></md-input>
                </lims-field>
              </div>
            </div>
            <!-- end row -->
            <!-- new row -->
            <div class="md-layout lims-form-row">
              <div class="md-layout-item md-size-100">
                <lims-field :model="formData.personalInfo" :schema="userSchema.personalInfo" field="address1">
                  <md-input
                    slot="field"
                    v-model="formData.personalInfo.address1"
                    type="text"
                    maxlength="1000"
                  ></md-input>
                </lims-field>
                <lims-field :model="formData.personalInfo" :schema="userSchema.personalInfo" field="address2">
                  <md-input
                    slot="field"
                    v-model="formData.personalInfo.address2"
                    type="text"
                    maxlength="1000"
                  ></md-input>
                </lims-field>
              </div>
            </div>
            <!-- end row -->
            <!-- new row -->
            <div class="md-layout lims-form-row">
              <div class="md-layout-item md-size-25 md-small-size-100">
                <lims-field :model="formData.personalInfo" :schema="userSchema.personalInfo" field="town">
                  <md-input slot="field" v-model="formData.personalInfo.town" type="text" maxlength="100"></md-input>
                </lims-field>
              </div>
              <div class="md-layout-item md-size-25 md-small-size-100">
                <lims-field :model="formData.personalInfo" :schema="userSchema.personalInfo" field="country">
                  <v-select slot="field" v-model="formData.personalInfo.country" :options="countries">
                    <template #option="{ label }">{{ label }}</template>
                    <template #selected-option="{ label }">{{ label }}</template>
                  </v-select>
                </lims-field>
              </div>
              <div class="md-layout-item md-size-25 md-small-size-100">
                <lims-field :model="formData.personalInfo" :schema="userSchema.personalInfo" field="postCode">
                  <md-input slot="field" v-model="formData.personalInfo.postCode" type="text" maxlength="50"></md-input>
                </lims-field>
              </div>
            </div>
            <!-- end row -->
            <!-- new row -->
            <div class="md-layout lims-form-row">
              <div class="md-layout-item md-size-50 md-small-size-100">
                <lims-field :model="formData.personalInfo" :schema="userSchema.personalInfo" field="email">
                  <lims-tooltip
                    slot="label-info"
                    :content="$t('entities/user/personalInfo/emailTooltip')"
                  ></lims-tooltip>
                  <md-input
                    slot="field"
                    v-model="formData.personalInfo.email"
                    type="text"
                    @blur="onCheckUnique('email')"
                    @change="$lowercase(formData.personalInfo, 'email')"
                    :class="errMessage.email ? 'input-err' : ''"
                    :placeholder="$t('entities/user/personalInfo/email.placeholder')"
                    maxlength="200"
                  ></md-input>
                </lims-field>
                <div v-if="errMessage.email" class="form-error">
                  {{ $t('global/errors/unique', { field: 'Email' }) }}
                </div>
              </div>
              <div class="md-layout-item md-size-25 md-small-size-100">
                <lims-field :model="formData.personalInfo" :schema="userSchema.personalInfo" field="telephoneOffice">
                  <md-input
                    slot="field"
                    v-model="formData.personalInfo.telephoneOffice"
                    type="text"
                    :placeholder="$t('global/placeholder.telephone')"
                    maxlength="50"
                  ></md-input>
                </lims-field>
              </div>
              <div class="md-layout-item md-size-25 md-small-size-100">
                <lims-field :model="formData.personalInfo" :schema="userSchema.personalInfo" field="telephoneMobile">
                  <lims-tel-input slot="field" v-model="telephoneMobileData"></lims-tel-input>
                </lims-field>
              </div>
            </div>
            <!-- end row -->
          </div>
        </lims-block>
        <!-- Begin notification -->
        <user-notifications v-model="notifications"></user-notifications>
        <!-- End notification -->
        <!-- Begin has login -->
        <lims-block>
          <h4 class="title" slot="blockTitle">{{ $t('pages/account/user/forms/loginBlock') }}</h4>
          <div class="md-layout" slot="blockContent">
            <div class="md-layout lims-form-row">
              <md-checkbox v-model="formData.login.hasLogin" class="lims-checkbox">
                {{ $t('pages/account/user/forms/loginLabel') }}
              </md-checkbox>
            </div>
          </div>
        </lims-block>
        <!-- End has login -->
        <!-- Begin action form -->
        <md-dialog-actions>
          <md-button @click="close" class="md-button lims-form-button md-theme-default">
            {{ $t('global/button/button.cancel') }}
          </md-button>
          <md-button @click="onSave()" class="md-button md-primary lims-form-button md-theme-default">
            {{ $t('global/button/button.save') }}
          </md-button>
        </md-dialog-actions>
      </form>
    </md-dialog>
  </ValidationObserver>
</template>

<script>
import modalMixins from './modal.mixins';
import { FormMixins } from '@/core/mixins';
import UserMixins from '@/pages/Account/User/user.mixins';

import { getUserSchema } from '@/schemas/users.schema';
import { FORM_MODES, USER_STATUSES } from '@/core/constants';
import LimsTelInput from '@/components/Lims/LimsTelInput.vue';
import UserNotifications from '@/pages/Account/User/Forms/UserNotifications.vue';

export default {
  name: 'ModalAddUserEntity',
  components: { UserNotifications, LimsTelInput },
  mixins: [modalMixins, FormMixins, UserMixins],
  props: {
    fData: {
      default: null,
    },
    listUsers: {
      type: Array,
      require: false,
    },
    myEntityView: {
      type: Boolean,
      require: false,
    },
  },
  created() {
    this.fetchData();
  },
  data() {
    return {
      formMode: FORM_MODES.ADD,
      formData: {
        personalInfo: {},
        notifications: [],
        login: {
          hasLogin: false,
        },
      },
      userTypesList: [],
      roleList: [],
      userTitles: [],
      countries: [],
      telephoneMobileData: {},

      isDuplicateUserName: false,
      errMessage: {
        username: false,
        email: false,
      },
      //fake data
      notifications: [
        {
          name: 'Receive notification with Case Ref Link ?',
          lims: false,
          email: false,
        },
        {
          name: 'Receive notification with redacted PDF attched ?',
          lims: false,
          email: false,
        },
        {
          name: 'Receive notification with full PDF attched ?',
          lims: false,
          email: false,
        },
      ],
    };
  },
  watch: {},
  computed: {
    userSchema: function () {
      return getUserSchema('Administrator', this.formMode, this.userModel);
    },
  },

  methods: {
    async fetchData() {
      this.countries = await this.loadCountries();
      this.userTitles = this.USER_TITLES();
    },
    async open(val) {
      this.isVisible = true;
      this.formData.personalInfo.userType = this.USERTYPES_ENUM().find((e) => e.value === val);
      this.userTypeList = this.USERTYPES_ENUM().find((e) => e.value === val);
      this.roleList = await this.loadRoleList(val);
      if (this.fData?.personalInfo?.email) {
        this.formMode = FORM_MODES.EDIT;
      } else {
        this.formMode = FORM_MODES.ADD;
      }
      if (this.fData) {
        this.formData = this.fData;
        this.telephoneMobileData = {
          countryCodeMobile: this.fData.personalInfo.countryCodeMobile,
          telephoneMobile: this.fData.personalInfo.telephoneMobile,
        };
      }
    },

    onCheckUnique(field) {
      if (this.listUsers.length > 0) {
        const newListUsers = this.listUsers.filter((i) => i.tempId);
        newListUsers.forEach((item) => {
          if (
            item.personalInfo[`${field}`] == this.formData.personalInfo[`${field}`] &&
            this.fData.tempId != item.tempId
          ) {
            this.errMessage[`${field}`] = true;
          } else {
            this.errMessage[`${field}`] = false;
          }
        });
      }
    },

    onSave() {
      this.$refs.form.validate().then(async (success) => {
        if (success && !this.errMessage.username && !this.errMessage.email) {
          this.$emit('userData', {
            ...(this.formData = {
              tempId: this.formData.tempId || new Date().getTime(),
              statusId: this.myEntityView ? USER_STATUSES.Invited : USER_STATUSES.Enabled,
              isPending: this.myEntityView ? true : false,
              personalInfo: {
                ...this.formData.personalInfo,
                userTypeId: this.formData.personalInfo.userType.value,
                userTypeName: this.$t(this.formData.personalInfo.userType.label),
                roleId: this.formData.personalInfo.role.value,
                roleName: this.formData.personalInfo.role.label,
                titleId: this.formData.personalInfo.title.value,
                countryId: this.formData.personalInfo.country.value,
              },
              notifications: this.formData.notifications,
              login: {
                hasLogin: this.formData.login.hasLogin,
              },
            }),
          });
          this.close();
        } else {
          this.$alertError(this.$t(`global/errors/message`));
        }
      });
    },

    close() {
      this.isVisible = false;
      this.$nextTick(() => {
        this.formData = {
          personalInfo: {
            userType: '',
            username: '',
            title: '',
            role: '',
            firstName: '',
            lastName: '',
            address1: '',
            address2: '',
            town: '',
            country: '',
            postCode: '',
            email: '',
            telephoneOffice: '',
            telephoneMobile: '',
          },
          notifications: {
            lims: false,
            email: false,
          },
          login: {
            hasLogin: false,
          },
        };
        this.$emit('setData', this.formData);
      });
    },
  },
};
</script>

<style lang="scss"></style>
