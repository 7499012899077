<template>
  <md-dialog :md-close-on-esc="true" :md-active.sync="isVisible" @md-clicked-outside="close" class="lims-modal">
    <div class="dialog-icon-close" @click="close"><md-icon>close</md-icon></div>
    <md-dialog-title>{{ $t('components/lims/modals/ModalConfirmation.title') }}</md-dialog-title>
    <div class="lims-form dialog-content">
      <p
        class="text-center"
        v-html="
          $t('components/lims/modals/ModalApproveRequest.txt', {
            requester: dataView.requesterName,
            caseRef: dataView.caseReference,
          })
        "
      ></p>
      <lims-field :model="formData" :schema="schema" field="password">
        <md-input slot="field" @keyup.enter="onContinue" v-model="formData.password" type="password"></md-input>
      </lims-field>
    </div>
    <md-dialog-actions>
      <md-button @click="close" class="lims-form-button">{{ $t('global/button/button.cancel') }}</md-button>
      <md-button @click="onContinue" :disabled="formData.password == ''" class="lims-form-button md-primary">
        {{ $t('global/button/button.continue') }}
      </md-button>
    </md-dialog-actions>
  </md-dialog>
</template>

<script>
import modalMixins from './modal.mixins';

export default {
  name: 'ModalBulkActionRequest',
  mixins: [modalMixins],
  props: {
    dataView: {
      require: false,
    },
  },
  data() {
    return {
      formData: {
        password: '',
      },
    };
  },
  computed: {
    schema() {
      return {
        entity: 'modals/ModalBulkActionRequest',
        fields: {
          password: 'required',
        },
      };
    },
  },
  methods: {
    onContinue() {
      this.$emit('onContinue', {
        password: this.formData.password,
        dataView: this.dataView,
      });
    },
  },
};
</script>

<style lang="scss"></style>
