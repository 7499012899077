import { NUMBER_OF_CHARACTERS_SEARCH } from '@/core/constants';
import httpClient from './httpClient';
const RESOURCE = 'roles';

export default {
  search({ filters, pagination, sort }) {
    const { search } = filters;
    const { pageNumber, pageSize } = pagination;
    const userQueryParams = {};
    if (search && search.length >= NUMBER_OF_CHARACTERS_SEARCH) {
      userQueryParams['filters.Search'] = search;
    }
    if (pageNumber) {
      userQueryParams['PageNumber'] = pageNumber;
    }
    if (pageSize) {
      userQueryParams['PageSize'] = pageSize;
    }
    if (sort) {
      userQueryParams['Sort'] = sort;
    }
    return httpClient.get(`/${RESOURCE}/`, userQueryParams);
  },
  find(params = {}) {
    return httpClient.get(`/${RESOURCE}`, params);
  },
  findOne(roleId) {
    return httpClient.get(`/${RESOURCE}/${roleId}`);
  },
  create(data = {}) {
    return httpClient.post(`/${RESOURCE}`, data);
  },
  updateOne(payload) {
    const { roleId, ...data } = payload;
    return httpClient.put(`/${RESOURCE}/${roleId}`, data);
  },
  disable(roleId) {
    return httpClient.patch(`/${RESOURCE}/disable/${roleId}`);
  },
  enable(roleId) {
    return httpClient.patch(`/${RESOURCE}/enable/${roleId}`);
  },
  delete(roleId) {
    return httpClient.patch(`/${RESOURCE}/delete/${roleId}`);
  },
  getFeaturePermissions({ userTypeId }) {
    const params = {};
    params['userTypeId'] = userTypeId;
    return httpClient.get(`/${RESOURCE}/list-permission`, params);
  },
  getSelectList({ search, userTypeId }) {
    const params = {};
    if (search) {
      params['search'] = search;
    }
    params['userTypeId'] = userTypeId;
    return httpClient.get(`/${RESOURCE}/select-list`, params);
  },
  roleNameCheckUnique({ value, roleId }) {
    const params = {};
    params['roleName'] = value;
    if (roleId) {
      params['roleId'] = roleId;
    }
    // {isExisted: false/true}
    return httpClient.get(`/${RESOURCE}/check-unique`, params);
  },
  getListUser(roleId) {
    return httpClient.get(`/${RESOURCE}/${roleId}/list-user`);
  },
  getRoleDetail(roleId) {
    return httpClient.get(`/${RESOURCE}/permission`, {
      roleId: roleId,
    });
  },
};
