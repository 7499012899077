<template>
  <stats-card header-color="primary">
    <template slot="header">
      <div class="card-icon">
        <md-icon>group_add</md-icon>
      </div>
      <p class="category">{{ $t('pages/Dashboard/Components/DoubleReportingRequestStats.title') }}</p>
    </template>

    <template slot="footer">
      <div class="group-stats-wrapper">
        <div class="group-stats">
          <div class="group-stats-item">
            <p class="category">{{ $t('pages/Dashboard/Components/DoubleReportingRequestStats.toBeResponded') }}</p>
            <h3 @click="toBeRespondedRequestNavigate()">
              <span>{{ data.toBeResponded }}</span>
            </h3>
          </div>
          <div class="group-stats-item">
            <p class="category">
              {{ $t('pages/Dashboard/Components/DoubleReportingRequestStats.waitingForResponse') }}
            </p>
            <h3 @click="waitingForResponseNavigate()">
              <span>{{ data.waitingForResponse }}</span>
            </h3>
          </div>
        </div>
        <div class="stats">
          <md-icon>date_range</md-icon>
          {{ data.time }}
        </div>
      </div>
    </template>
  </stats-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import StatsMixins from './stats.mixins';
import { DR_RESPONSE_STATUS } from '@/core/constants';
export default {
  props: {
    data: {},
  },
  mixins: [StatsMixins],
  computed: {
    ...mapGetters('auth', ['userId']),
  },
  methods: {
    ...mapActions('app', ['$setRedirectName']),
    waitingForResponseNavigate() {
      this.$setRedirectName('DoubleReporting');
      this.$navigateToList({
        path: 'cases',
        query: {
          IsDoubleReportCase: true,
          DRResponseStatusIds: DR_RESPONSE_STATUS.PENDING,
          PathologistIds: this.userId,
        },
      });
    },
    toBeRespondedRequestNavigate() {
      this.$setRedirectName('DoubleReporting');
      this.$navigateToList({
        path: 'cases',
        query: { DRPartnerIds: this.userId, DRResponseStatusIds: DR_RESPONSE_STATUS.PENDING },
      });
    },
  },
};
</script>

<style></style>
