// Layouts
const DashboardLayout = () => import('@/layouts/DashboardLayout.vue');
// MyAccount's pages
const MyAccount = () => import('@/pages/MyAccount/MyAccount.vue');
const MyAccountPassword = () => import('@/pages/MyAccount/Password/MyAccountPassword.vue');
const MyAccountSettings = () => import('@/pages/MyAccount/Settings/MyAccountSettings.vue');
const MyAccountNotifications = () => import('@/pages/MyAccount/Notifications/MyAccountNotifications.vue');
const Calendar = () => import('@/pages/MyAccount/Calendar/Calendar.vue');

import guards from './guards';

export default {
  path: '/my-account',
  component: DashboardLayout,
  meta: {
    zone: 'private',
  },
  beforeEnter: guards.checkAuthorizedGuard,
  children: [
    {
      path: '',
      name: 'MyAccount',
      component: MyAccount,
    },
    {
      path: 'password',
      name: 'MyAccountPassword',
      component: MyAccountPassword,
    },
    {
      path: 'settings',
      name: 'MyAccountSettings',
      component: MyAccountSettings,
    },
    {
      path: 'notifications',
      name: 'MyAccountNotifications',
      component: MyAccountNotifications,
    },
    {
      path: 'calendar',
      name: 'MyAccountCalendar',
      component: Calendar,
      meta: {
        zone: ['UserType_Pathologist'],
      },
    },
  ],
};
