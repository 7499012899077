import { getDefaultFilterValues, getFilterFieldTypes } from '@/query/utils';
import { FIELD_TYPES } from '@/query/constant';
const fields = [
  {
    key: 'action',
    listKey: 'actionList',
    sort: 1,
    fieldType: FIELD_TYPES.ArrayInt,
    translated: true,
    defaultValue: [],
  },
  {
    key: 'userType',
    listKey: 'userTypeList',
    sort: 2,
    fieldType: FIELD_TYPES.ArrayInt,
    translated: false,
    defaultValue: [],
  },

  { key: 'search', listKey: null, sort: 6, fieldType: FIELD_TYPES.String, translated: false, defaultValue: [] },
];
const defaultValues = getDefaultFilterValues(fields);
const fieldTypes = getFilterFieldTypes(fields);
export default {
  fields,
  defaultValues,
  fieldTypes,
};
