const DashboardLayout = () => import('@/layouts/DashboardLayout.vue');
import ImportHistory from '@/pages/Case/ImportHistory/ImportHistory.vue';

import guards from './guards';

export default {
  path: '/import-history',
  component: DashboardLayout,
  meta: {
    zone: 'private',
    rbac: 'cases',
  },
  beforeEnter: guards.checkAuthorizedGuard,
  children: [
    {
      path: '',
      name: 'ImportHistory',
      component: ImportHistory,
      meta: {
        zone: ['Case_CaseImport_Retrieve'],
      },
    },
  ],
};
