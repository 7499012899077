<template>
  <lims-block>
    <h4 class="title" slot="blockTitle">
      {{ $t('pages/entity/components/DisplayOption/blockTitle') }}
    </h4>
    <div slot="blockContent">
      <div class="md-layout lims-form-row">
        <md-table class="custom-tbl" md-card>
          <md-table-row>
            <md-table-head width="85%"></md-table-head>
            <md-table-head width="15%" class="th-center">
              <md-checkbox
                :disabled="disabled"
                v-model="selectAll"
                :class="disabled ? 'disabled checkbox-all' : 'checkbox-all'"
                tabenable="yes"
              >
                {{ $t('pages/entity/components/DisplayOption/headEnable') }}
                <md-tooltip
                  v-if="!disabled"
                  class="tooltip-selectAll"
                  v-html="$t('global/tooltip/selectAll')"
                ></md-tooltip>
              </md-checkbox>
            </md-table-head>
          </md-table-row>
          <md-table-row v-for="(item, indx) in value" :key="indx">
            <md-table-cell>{{ item.optionName }}</md-table-cell>
            <md-table-cell class="cell-action">
              <md-checkbox
                @change="(checked) => onChange(checked, item)"
                :disabled="disabled || isDisabled(item)"
                v-model="item.enabled"
                class="lims-checkbox"
                tabenable="yes"
              ></md-checkbox>
            </md-table-cell>
          </md-table-row>
        </md-table>
      </div>
    </div>
  </lims-block>
</template>
<script>
import { ENTITY_DISPLAY_OPTION } from '@/core/constants';

export default {
  name: 'LimsDisplayOption',
  props: {
    value: {
      type: Array,
      require: true,
    },
    disabled: {
      type: Boolean,
      require: false,
      default: false,
    },
  },

  computed: {
    selectAll: {
      set(selected) {
        this.value.forEach(function (val) {
          if (!selected || val.optionId !== ENTITY_DISPLAY_OPTION.SKIPPING_IO) {
            val.enabled = selected;
          }
        });
      },
      get() {
        return this.value.every(function (val) {
          return val.enabled || val.optionId !== ENTITY_DISPLAY_OPTION.SKIPPING_IO;
        });
      },
    },
  },

  watch: {
    value: {
      handler: function (value) {
        this.$emit('input', value);
      },
    },
  },

  data() {
    return {};
  },
  mounted() {},
  created() {
    const notUseDefaultA1Option = this.value.find(
      (o) => o.optionId === ENTITY_DISPLAY_OPTION.NOT_USE_A1_SPECIMEN_NAMING_CONVENTION,
    );
    const skippingIOOption = this.value.find((o) => o.optionId === ENTITY_DISPLAY_OPTION.SKIPPING_IO);
    if (notUseDefaultA1Option && skippingIOOption && notUseDefaultA1Option.enabled && skippingIOOption.enabled) {
      skippingIOOption.enabled = false;
    }
  },

  methods: {
    onChange(checked, item) {
      const isNotUseDefaultA1Option = item.optionId === ENTITY_DISPLAY_OPTION.NOT_USE_A1_SPECIMEN_NAMING_CONVENTION;
      const skippingIOOption = this.value.find((o) => o.optionId === ENTITY_DISPLAY_OPTION.SKIPPING_IO);
      if (isNotUseDefaultA1Option && checked && skippingIOOption.enabled) {
        skippingIOOption.enabled = false;
      }
    },
    isDisabled(val) {
      if (val.optionId === ENTITY_DISPLAY_OPTION.SKIPPING_IO) {
        const notUseDefaultA1Option = this.value.find(
          (o) => o.optionId === ENTITY_DISPLAY_OPTION.NOT_USE_A1_SPECIMEN_NAMING_CONVENTION,
        );
        return notUseDefaultA1Option && notUseDefaultA1Option.enabled;
      }
      return false;
    },
  },
};
</script>
<style lang="scss" scoped></style>
