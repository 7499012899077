<template>
  <ValidationObserver ref="billingClinicAndLaboratoryForm">
    <collapse :wrapperClass="'case-collapse'" :collapse="collapseName" icon="keyboard_arrow_down">
      <template slot="md-collapse-pane-1">
        <div class="md-layout lims-form-row">
          <div class="md-layout-item md-size-33 md-small-size-100">
            <label class="required">{{ $t('entities/case/form/workStreamId') }}</label>
            <div class="static-text">{{ formData.workStreamName }}</div>
          </div>
          <div class="md-layout-item md-size-33 md-small-size-100">
            <lims-field :model="formData" :schema="caseFormSchema" field="entityBillingId"> </lims-field>
            <div class="static-text">{{ formData.billingEntityName }}</div>
          </div>
          <div class="md-layout-item md-size-33 md-small-size-100 mg-top-24">
            <md-checkbox v-model="formData.unknowBillingEntity" class="lims-checkbox" :disabled="true" tabenable="yes">
              {{ $t('entities/case/form/unknowBillingEntity') }}
            </md-checkbox>
          </div>
        </div>
        <div v-if="isInsuranceSelfPayer">
          <template v-if="isInsurance">
            <div class="md-layout lims-form-row">
              <md-checkbox
                v-model="formData.incompleteInsuranceDetails"
                class="lims-checkbox"
                :disabled="true"
                tabenable="yes"
              >
                {{ $t('entities/case/form/incompleteInsuranceDetails') }}
              </md-checkbox>
            </div>
            <div class="md-layout lims-form-row">
              <div class="md-layout-item md-size-33 md-small-size-100">
                <lims-field :model="formData" :schema="caseFormSchema" field="insuranceCompanyId"> </lims-field>
                <div class="static-text">{{ formData.insuranceCompanyName }}</div>
              </div>
              <div class="md-layout-item md-size-33 md-small-size-100">
                <lims-field :model="formData" :schema="caseFormSchema" field="insuranceNumber"> </lims-field>
                <div class="static-text">{{ formData.insuranceNumber }}</div>
              </div>
              <div class="md-layout-item md-size-33 md-small-size-100">
                <lims-field :model="formData" :schema="caseFormSchema" field="insuranceAuthorisationNumber">
                </lims-field>
                <div class="static-text">{{ formData.insuranceAuthorisationNumber }}</div>
              </div>
            </div>
            <div class="md-layout lims-form-row">
              <div class="md-layout-item md-size-100 md-small-size-100">
                <lims-field :model="formData" :schema="caseFormSchema" field="insuranceNote"> </lims-field>
                <div class="static-text">{{ formData.insuranceNote }}</div>
              </div>
            </div>
          </template>
          <div class="md-layout lims-form-row">
            <md-checkbox
              v-model="formData.incompleteAddressDetails"
              class="lims-checkbox"
              :disabled="true"
              tabenable="yes"
            >
              {{ $t('entities/case/form/incompleteAddressDetails') }}
            </md-checkbox>
          </div>
          <div>
            <div class="md-layout lims-form-row">
              <div class="md-layout-item md-size-100">
                <lims-field :model="formData" :schema="caseFormSchema" field="address1"> </lims-field>
                <div class="static-text" :class="isAnonymiseField(formData, 'Address1') ? 'anonymise-field' : ''">
                  {{ formData.address1 }}
                </div>
                <div class="static-text" :class="isAnonymiseField(formData, 'Address2') ? 'anonymise-field' : ''">
                  {{ formData.address2 }}
                </div>
              </div>
            </div>
            <div class="md-layout lims-form-row">
              <div class="md-layout-item md-size-33 md-small-size-100">
                <lims-field :model="formData" :schema="caseFormSchema" field="town"> </lims-field>
                <div class="static-text">{{ formData.town }}</div>
              </div>
              <div class="md-layout-item md-size-33 md-small-size-100">
                <lims-field :model="formData" :schema="caseFormSchema" field="countryId"> </lims-field>
                <div class="static-text">{{ formData.countryName }}</div>
              </div>
              <div class="md-layout-item md-size-33 md-small-size-100">
                <lims-field :model="formData" :schema="caseFormSchema" field="postalCode"> </lims-field>
                <div class="static-text">{{ formData.postalCode }}</div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </collapse>
  </ValidationObserver>
</template>
<script>
import { FormMixins } from '@/core/mixins';
import CaseMixins from '@/pages/Case/CaseManagement/Case.mixins';
import { Collapse } from '@/components';
import { mapState } from 'vuex';
import { CASE_FORM_BLOCK, FORM_MODES } from '@/core/constants';
import CaseBlockMixins from '@/pages/Case/CaseManagement/CaseBlock.mixins';
import { pickFields } from '@/core/helpers';
import { constantService } from '@/services';

export default {
  mixins: [FormMixins, CaseMixins, CaseBlockMixins],
  components: {
    Collapse,
  },
  props: {
    formMode: {
      type: Number,
      require: true,
      validator: function (value) {
        // The value must match one of these strings
        return Object.values(FORM_MODES).indexOf(value) !== -1;
      },
    },
    dataEdit: {
      type: Object,
      require: false,
    },
  },
  computed: {
    ...mapState('auth', {
      user: (state) => state.user,
    }),
    collapseName() {
      const name = 'pages/case/CaseManagement/components/BillingData/collapseTitle';
      return [this.$translate(name)];
    },
    isInsuranceSelfPayer() {
      return (
        this.formData.billingEntityFieldItemId === this.befConstantValue?.insurance.id ||
        this.formData.billingEntityFieldItemId === this.befConstantValue?.selfPayers.id
      );
    },
    isInsurance() {
      return this.formData.billingEntityFieldItemId === this.befConstantValue?.insurance.id;
    },
  },
  data() {
    return {
      formData: {},
      blockId: CASE_FORM_BLOCK.BLOCK_BILLING,
      befConstantValue: null,
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      const fields = pickFields(this.dataEdit, [
        'entityBillingId',
        'billingEntityFieldItemId',
        'workStreamName',
        'billingEntityName',
        'unknowBillingEntity',
        'incompleteInsuranceDetails',
        'insuranceCompanyId',
        'insuranceCompanyName',
        'insuranceNumber',
        'insuranceAuthorisationNumber',
        'insuranceNote',
        'incompleteAddressDetails',
        'address1',
        'address2',
        'town',
        'countryId',
        'countryName',
        'postalCode',
      ]);
      this.formData = {
        ...fields,
      };
      this.befConstantValue = await this.getBEFConstantValue();
      this.$resetBlockChanged();
    },
    async getBEFConstantValue() {
      const constantValue = await constantService.getConstantValue();
      if (constantValue.err) {
        return {};
      }
      return constantValue.data?.bef;
    },
    getFullNameTechnician(user) {
      return `${user.firstName} ${user.lastName}`;
    },
  },
};
</script>
<style lang="scss"></style>
