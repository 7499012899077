// import { PATHOLOGIST_TYPE_ID } from '@/core/constants';
import { extend, configure } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';
import validators from './validators';
import { checkIfArrayIsUnique, isValidUrl } from '@/core/helpers';

// https://vee-validate.logaretm.com/v3/guide/interaction-and-ux.html#custom-modes
// setInteractionMode('custom', context => {
//   if (context.value === 'yes') {
//     return {
//       on: ['input'],
//     };
//   }

//   return { on: ['blur', 'input'] };
// });

configure({});

// Install required rule and message.
extend('required', {
  ...required,
  message: `notEmpty`,
});
extend('email', {
  ...email,
  message: `notValid`,
});

// Install email rule and message.
extend('email', email);
extend('notValid', email);

// Create unique rule and message
const uniqueValidator = {
  message: `unique`,
  validate(value, { method, params = '' }) {
    return new Promise((resolve) => {
      // eslint-disable-next-line security/detect-object-injection
      validators[method](value, JSON.parse(params)).then((isUnique) => {
        resolve(isUnique);
      });
    });
  },
  params: ['method', 'params'],
};
extend('unique', uniqueValidator);

const linkValidator = {
  message: `notValidLink`,
  validate(value) {
    // eslint-disable-next-line no-useless-escape
    return /^(https:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm.test(value);
  },
  //
};

extend('link', linkValidator);

const securedLinkValidator = {
  message: 'notValidSecuredLink',
  validate(value) {
    return isValidUrl(value, ['https:']);
  },
};

extend('securedLink', securedLinkValidator);

// Create check code rule
const checkCodeValidator = {
  message: `checkCode`,
  validate(value, { method, params = '' }) {
    return new Promise((resolve) => {
      // eslint-disable-next-line security/detect-object-injection
      validators[method](value, JSON.parse(params)).then((isTrue) => {
        resolve(isTrue);
      });
    });
  },
  params: ['method', 'params'],
};
extend('checkCode', checkCodeValidator);

// Create dob less than procedure date rule
const dobLessThanProcedureDateValidator = {
  message: `dobLessThanProcedureDate`,
  validate(value, { params = '' }) {
    return value ? JSON.parse(params).validateDateTime : true;
  },
  params: ['params'],
};
extend('dobLessThanProcedureDate', dobLessThanProcedureDateValidator);

// Create procedure date greater than procedure date rule
const procedureDateGreaterThanDobValidator = {
  message: `procedureDateGreaterThanDob`,
  validate(value, { params = '' }) {
    return value ? JSON.parse(params).validateDateTime : true;
  },
  params: ['params'],
};
extend('procedureDateGreaterThanDob', procedureDateGreaterThanDobValidator);

// Create lab entry date greater than procedure date rule
const labEntryDateGreaterThanDobValidator = {
  message: `labEntryDateGreaterThanDob`,
  validate(value, { params = '' }) {
    return value ? JSON.parse(params).validateDateTime : true;
  },
  params: ['params'],
};
extend('labEntryDateGreaterThanDob', labEntryDateGreaterThanDobValidator);

// Create list email validation
const listEmailValidValidator = {
  message: `listEmailValid`,
  validate(value) {
    let listEmail = value.split(/[\n;]+/);
    listEmail = listEmail.map((item) => {
      return item.trim();
    });
    listEmail = listEmail.filter((item) => item.length > 0);
    if (
      listEmail.find(
        (item) =>
          !item.match(
            /^(([^<>()/[\]\\.,;:\s@"]+(\.[^<>()/[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          ),
      )
    ) {
      return false;
    } else {
      return true;
    }
  },
  params: ['params'],
};
extend('listEmailValid', listEmailValidValidator);

// Create list email duplicate validation
const listEmailDuplicateValidator = {
  message: `listEmailDuplicate`,
  validate(value) {
    let listEmail = value.split(/[\n;]+/);
    listEmail = listEmail.map((item) => {
      return item.trim();
    });
    listEmail = listEmail.filter((item) => item.length > 0);
    if (!checkIfArrayIsUnique(listEmail)) {
      return false;
    } else {
      return true;
    }
  },
  params: ['params'],
};
extend('listEmailDuplicate', listEmailDuplicateValidator);

// Create must equal rule and message
const mustEqualValidator = {
  message: `mustEqual`,
  validate(value, { method, params = '' }) {
    return new Promise((resolve) => {
      // eslint-disable-next-line security/detect-object-injection
      validators[method](value, JSON.parse(params)).then((isMustEqual) => {
        resolve(isMustEqual);
      });
    });
  },
  params: ['method', 'params'],
};
extend('mustEqual', mustEqualValidator);

// Create must be less than or equal rule and message
const mustBeLessThanOrEqualValidator = {
  message: `mustBeLessThanOrEqual`,
  validate(value, { method, params = '' }) {
    return new Promise((resolve) => {
      // eslint-disable-next-line security/detect-object-injection
      validators[method](value, JSON.parse(params)).then((isMustBeLessThanOrEqual) => {
        resolve(isMustBeLessThanOrEqual);
      });
    });
  },
  params: ['method', 'params'],
};
extend('mustBeLessThanOrEqual', mustBeLessThanOrEqualValidator);

const lessThanOrEqualCurrentDateValidator = {
  message: `lessThanOrEqualCurrentDate`,
  validate(value, { method }) {
    return new Promise((resolve) => {
      validators[method](value).then((islessThanOrEqualCurrentDate) => {
        // eslint-disable-next-line security/detect-object-injection
        resolve(islessThanOrEqualCurrentDate);
      });
    });
  },
  params: ['method', 'params'],
};
extend('lessThanOrEqualCurrentDate', lessThanOrEqualCurrentDateValidator);

// Create valid username rule
const validValidator = {
  message: `notValid`,
  validate(value, { method }) {
    return new Promise((resolve) => {
      // eslint-disable-next-line security/detect-object-injection
      validators[method](value).then((isValid) => {
        resolve(isValid);
      });
    });
  },
  params: ['method', 'params'],
};
extend('notValid', validValidator);

const minNoOfSpecimen = {
  message: `minNoOfSpecimen`,
  validate(value) {
    return parseInt(value) >= 1;
  },
  params: [],
};
extend('minNoOfSpecimen', minNoOfSpecimen);

// Create valid no of specimen
const validNoOfSpecimen = {
  message: `validNoOfSpecimen`,
  validate(value, { params = '' }) {
    const maxSpecimenNumber = JSON.parse(params).caseSpecimenSettingNumber;
    const numberOfSpecimen = parseInt(value);
    return numberOfSpecimen >= 1 && numberOfSpecimen <= maxSpecimenNumber;
  },
  params: ['params'],
};
extend('validNoOfSpecimen', validNoOfSpecimen);

// const requiredPathologist = {
//   message: `required`,
//   validate(value) {
//     debugger;
//     // eslint-disable-next-line security/detect-object-injection
//     if (!value) return false;
//     if (value && !value.pathologistId && value.pathologistType != PATHOLOGIST_TYPE_ID.Pull) {
//       return false;
//     }
//     return true;
//   },
//   params: [],
// };

// extend('requiredPathologist', requiredPathologist);
