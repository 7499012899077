<template>
  <div>
    <collapse :wrapperClass="'case-collapse'" :collapse="['Case Issue']" icon="keyboard_arrow_down">
      <template slot="md-collapse-pane-1">
        <div class="md-layout lims-form-row">
          <modal-concurrency-issue
            ref="concurrencyIssueModal"
            :name-of-block="'Patient Data'"
            @onContinue="$onConcurrencyIssueContinue"
          ></modal-concurrency-issue>
          <modal-create-issue
            ref="createIssueModal"
            :form-mode="formMode"
            :issue-case-list="issueCaseList"
            :case-id="caseId"
            @onAddIssue="onAddIssue"
          ></modal-create-issue>
          <modal-edit-issue
            ref="editIssueModal"
            :form-mode="formMode"
            :issue-case-list="issueCaseList"
            :issue-data="issueData"
            :data-edit="dataEdit"
            @onUpdateIssue="onUpdateIssue"
            @onAcceptAndCloseIssue="onAcceptAndCloseIssue"
            @onRejectIssue="onRejectIssue"
          ></modal-edit-issue>
          <modal-view-issue
            ref="viewIssueModal"
            :issue-data="issueData"
            :issue-case-list="issueCaseList"
          ></modal-view-issue>
          <modal-delete-issue
            ref="deleteIssueModal"
            :delete-issue-name="deleteIssueName"
            :form-mode="formMode"
            :case-issue-id="deleteCaseIssueId"
            @onDelete="onDeletedIssue"
          ></modal-delete-issue>
          <md-button
            @click="onCreateIssue"
            :disabled="viewMode || (dataEdit ? dataEdit.isDeleted : false) || isPartnerDoubleReport || isPathViewOnly"
            class="lims-form-button"
          >
            {{ $t('pages/case/CaseManagement/components/issueLog/button.create') }}
          </md-button>
          <md-table class="custom-tbl" md-card>
            <md-table-row>
              <md-table-head>{{ $t('pages/case/CaseManagement/components/issueLog/table.issue') }}</md-table-head>
              <md-table-head>{{ $t('pages/case/CaseManagement/components/issueLog/table.reportedBy') }}</md-table-head>
              <md-table-head>{{ $t('pages/case/CaseManagement/components/issueLog/table.reportedAt') }}</md-table-head>
              <md-table-head>{{ $t('pages/case/CaseManagement/components/issueLog/table.status') }}</md-table-head>
              <md-table-head>{{ $t('pages/case/CaseManagement/components/issueLog/table.toRespond') }}</md-table-head>
              <md-table-head>{{ $t('pages/case/CaseManagement/components/issueLog/table.respondedAt') }}</md-table-head>
              <md-table-head class="action-column-width">{{ $t('global/pages/list.actions') }}</md-table-head>
            </md-table-row>
            <md-table-row v-for="(item, index) in issueList" :key="index">
              <md-table-cell>{{ item.issue.issueName }}</md-table-cell>
              <md-table-cell>{{ item.reportedBy }}</md-table-cell>
              <md-table-cell>{{ formatDateTimeGlobal(item.reportedAt) }}</md-table-cell>
              <md-table-cell>{{ item.status }}</md-table-cell>
              <md-table-cell>{{ item.toRespond ? item.toRespond.toRespondUserListName : '' }}</md-table-cell>
              <md-table-cell>{{ item.respondedAt ? formatDateTimeGlobal(item.respondedAt) : '' }}</md-table-cell>
              <md-table-cell class="action-column-width">
                <span v-if="isShowEditIcon(item)" @click="viewMode ? '' : onEditIssue(item, index)" class="margin-icon">
                  <md-icon>edit</md-icon>
                </span>
                <span
                  v-if="item.action.includes('view')"
                  @click="viewMode ? '' : onViewIssue(item)"
                  class="margin-icon"
                >
                  <md-icon>visibility</md-icon>
                </span>
                <span
                  v-if="isShowDeleteIcon(item)"
                  @click="viewMode ? '' : onDeleteIssue(item, index)"
                  class="margin-icon"
                >
                  <md-icon>delete_forever</md-icon>
                </span>
                <span
                  v-if="isShowResendIcon(item)"
                  @click="viewMode || isProcessing ? '' : onResendMail(item)"
                  class="margin-icon"
                >
                  <md-icon>lock_reset</md-icon>
                </span>
              </md-table-cell>
            </md-table-row>
          </md-table>
        </div>
      </template>
    </collapse>
    <collapse
      :wrapperClass="'case-collapse'"
      :collapse="['Clinical Incident']"
      icon="keyboard_arrow_down"
      v-if="!isCaseReportScreen"
    >
      <template slot="md-collapse-pane-1">
        <div class="md-layout lims-form-row">
          <md-checkbox
            v-model="formData.isClinicalIncident"
            :disabled="
              viewMode ||
              (dataEdit ? dataEdit.isDeleted : false) ||
              isPartnerDoubleReport ||
              isPathViewOnly ||
              isReportedStatusLabTechView
            "
            class="lims-checkbox"
            tabenable="yes"
          >
            {{ $t('pages/case/CaseManagement/components/issueLog/clinicalIncident') }}
          </md-checkbox>
        </div>
        <div class="md-layout lims-form-row">
          <div class="md-layout-item md-size-100 md-small-size-100">
            <lims-field :model="formData" :schema="caseFormSchema" field="incidentComment" class="textarea-field">
              <md-textarea
                slot="field"
                v-model="formData.clinicalIncidentComment"
                type="text"
                tabenable="yes"
                :disabled="
                  (dataEdit ? dataEdit.isDeleted : false) ||
                  isPartnerDoubleReport ||
                  isPathViewOnly ||
                  viewMode ||
                  isReportedStatusLabTechView
                "
              ></md-textarea>
            </lims-field>
          </div>
        </div>
        <div class="md-layout lims-form-row">
          <div
            v-if="
              formMode === EDIT_MODE &&
              !(dataEdit ? dataEdit.isDeleted : false) &&
              !isPartnerDoubleReport &&
              !isPathViewOnly &&
              !isReportedStatusLabTechView
            "
            class="md-layout lims-form-row"
          >
            <div class="md-layout-item md-size-100 md-small-size-100 lims-form-actions case-block-action">
              <lims-form-cancel></lims-form-cancel>
              <md-button
                @click="onSave()"
                :disabled="isProcessing"
                class="md-button md-primary lims-form-button md-theme-default"
              >
                {{ $t('global/button/button.save') }}
              </md-button>
            </div>
          </div>
        </div>
      </template>
    </collapse>
  </div>
</template>
<script>
import { FormMixins } from '@/core/mixins';
import { CASE_STATUS, FORM_MODES, APP_EVENTS, newAppEvent, CASE_FORM_BLOCK, USER_TYPE } from '@/core/constants';
import { Collapse } from '@/components';
import ModalCreateIssue from '@/components/Lims/modals/ModalCreateIssue';
import ModalEditIssue from '@/components/Lims/modals/ModalEditIssue';
import ModalViewIssue from '@/components/Lims/modals/ModalViewIssue';
import ModalDeleteIssue from '@/components/Lims/modals/ModalDeleteIssue';
import { CaseIssueService, caseFormService } from '@/services';
import CaseMixins from '@/pages/Case/CaseManagement/Case.mixins';
import { CaseIssueMixins } from '@/pages/Case/CaseManagement/Case.mixins';
import { mapActions } from 'vuex';
import CaseBlockMixins from '@/pages/Case/CaseManagement/CaseBlock.mixins';
import ModalConcurrencyIssue from '@/components/Lims/modals/ModalConcurrencyIssue';
import { getCaseFormSchema } from '@/schemas/case-form.schema';

export default {
  mixins: [FormMixins, CaseIssueMixins, CaseMixins, CaseBlockMixins],
  components: {
    ModalConcurrencyIssue,
    ModalDeleteIssue,
    ModalViewIssue,
    ModalEditIssue,
    ModalCreateIssue,
    Collapse,
  },
  props: {
    dataEdit: {
      type: Object,
      require: false,
    },
    formMode: {
      type: Number,
      require: true,
      validator: function (value) {
        // The value must match one of these strings
        return Object.values(FORM_MODES).indexOf(value) !== -1;
      },
    },
    isPathStatusPathView: {
      type: Boolean,
      require: false,
    },
    isReportedStatusLabTechView: {
      type: Boolean,
      require: false,
    },
    isCaseReportScreen: {
      type: Boolean,
      require: false,
    },
    isPartnerDoubleReport: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    formData: {
      deep: true,
      handler: function () {
        // issueLogData
        const dataForm = this.issueLogData();
        this.appendCaseData({
          caseData: dataForm,
          blockId: CASE_FORM_BLOCK.BLOCK_ISSUE_LOG,
        });         
        this.$emit('input', {
          issueList: this.issueList,
          isClinicalIncident: this.formData.isClinicalIncident,
          clinicalIncidentComment: this.formData.clinicalIncidentComment,
        });
      },
    },
  },
  data() {
    return {
      formData: {
        isClinicalIncident: false,
        clinicalIncidentComment: null,
      },
      issueList: [],
      issueData: {},
      issueCaseList: [],
      selectedId: null,
      deleteId: null,
      deleteCaseIssueId: null,
      deleteIssueItem: {},
      deleteIssueName: '',
      blockId: CASE_FORM_BLOCK.BLOCK_ISSUE_LOG,
      isProcessing: false,
    };
  },
  computed: {
    caseFormSchema: function () {
      return getCaseFormSchema(this.formMode, null, null, this.formData.isClinicalIncident);
    },
    caseId() {
      return this.dataEdit?.caseId;
    },
    isPathView() {
      const userType = this.$store.getters['auth/userType'];
      return userType === this.USER_TYPES()?.Pathologist;
    },
    isPathViewOnly() {
      return !this.dataEdit?.isUpdatePermission && this.isPathView;
    },
    userTypeId() {
      return this.$store.getters['auth/userType'];
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    ...mapActions('app/event', ['removeEvent', 'addEvent']),
    async fetchData() {
      if (this.formMode === FORM_MODES.ADD) {
        await this.getListCaseIssue(CASE_STATUS.LAB);
      } else {
        if (this.formMode === FORM_MODES.EDIT) {
          await this.getListCaseIssue(this.dataEdit.status);
        }
      }
      if (this.dataEdit?.caseIssues.length > 0) {
        this.dataEdit.caseIssues.forEach((caseIssueItem) => {
          this.issueList.push({
            issue: {
              issueName: caseIssueItem.issueName,
              issueId: caseIssueItem.issueId,
            },
            isMarkIssueAsClose: caseIssueItem.isMarkIssueAsClose,
            isHaveIssueFields: caseIssueItem.isHaveIssueFields,
            reportedByUserTypeIds: caseIssueItem.reportedByUserTypeIds,
            caseIssueId: caseIssueItem.caseIssueId,
            caseIssueFields: caseIssueItem.caseIssueFields,
            reportedBy: caseIssueItem.reportedBy,
            reportedAt: caseIssueItem.reportedAt,
            status: caseIssueItem.caseIssueStatus,
            toRespond: {
              toRespondUserListName: caseIssueItem.toRespondUser.join(', '),
              toRespondUserTypeIds: caseIssueItem.toRespondUserTypeIds,
            },
            comment: caseIssueItem.comment,
            commentOnResponse: caseIssueItem.commentOnResponse,
            respondedAt: caseIssueItem.respondedAt,
            action: this.actionByStatusAndIssueId(caseIssueItem.caseIssueStatus, caseIssueItem.issueId),
          });
        });
      }
      this.formData.isClinicalIncident = this.dataEdit?.isClinicalIncident;
      this.formData.clinicalIncidentComment = this.dataEdit?.clinicalIncidentComment;
      this.issueList.sort((a, b) => {
        return new Date(b.reportedAt) - new Date(a.reportedAt);
      });
      this.$resetBlockChanged();
    },
    async getListCaseIssue(caseStatus) {
      const { error, data } = await CaseIssueService.getListCaseIssue(caseStatus);
      if (error) {
        this.$alertError(error);
      } else {
        if (data) {
          this.issueCaseList = data;
        }
      }
    },
    isShowResendIcon(item) {
      return (
        item.action.includes('send') &&
        this.formMode !== FORM_MODES.ADD &&
        item.isHaveIssueFields &&
        item.reportedByUserTypeIds?.includes(this.userTypeId) &&
        !this.isPathViewOnly
      );
    },
    isShowEditIcon(item) {
      return (
        item.action.includes('edit') &&
        (item.reportedByUserTypeIds || []).includes(this.userTypeId) &&
        !this.isPathViewOnly
      );
    },
    isShowDeleteIcon(item) {
      return (
        item.action.includes('delete') &&
        (item.reportedByUserTypeIds || []).includes(this.userTypeId) &&
        !this.isPathViewOnly
      );
    },
    onCreateIssue() {
      this.$refs.createIssueModal.open();
    },
    onAddIssue(issueData) {
      this.userType = this.$store.getters['auth/userType'];
      this.issueList.push(issueData);
      this.issueList.sort((a, b) => {
        return new Date(b.reportedAt) - new Date(a.reportedAt);
      });
      this.$refs.createIssueModal.close();
      this.$alertSuccess(
        this.$t('pages/case/CaseManagement/components/issueLog/alert/createIssueSuccess', {
          issue: issueData.issue.issueName,
        }),
      );
      if (this.userType === USER_TYPE.Pathologist && !this.dataEdit.isPauseCountReturnToPull) {
        this.addEvent(
          newAppEvent(APP_EVENTS.EVT_ON_CASE_HIDE_COUNT_DOWN, {
            onHideCountDown: true,
          }),
        );
      }
      this.$resetBlockChanged();
    },
    onEditIssue(item, index) {
      this.selectedId = index;
      this.issueData = {
        ...item,
      };
      this.$refs.editIssueModal.open();
    },
    onUpdateIssue(issueData) {
      this.issueList[this.selectedId].status = issueData.status;
      this.issueList[this.selectedId].action = this.actionByStatusAndIssueId(issueData.status, issueData.issueId);
      this.issueList[this.selectedId].comment = issueData.comment;
      if (issueData.toRespond) {
        this.issueList[this.selectedId].toRespond = issueData.toRespond;
      }
      this.issueList[this.selectedId].reportedAt = issueData?.reportedAt;
      this.$refs.editIssueModal.close();
      this.$alertSuccess(
        this.$t('pages/case/CaseManagement/components/issueLog/alert/updateIssueSuccess', {
          issue: this.issueList[this.selectedId].issueName,
        }),
      );
      this.issueList.sort((a, b) => {
        return new Date(b.reportedAt) - new Date(a.reportedAt);
      });
      this.$resetBlockChanged();
    },
    onViewIssue(item) {
      this.issueData = item;
      this.$refs.viewIssueModal.open();
    },
    onDeleteIssue(item, index) {
      this.$refs.deleteIssueModal.open();
      this.deleteId = index;
      this.deleteIssueItem = item;
      this.deleteIssueName = item.issue.issueName;
      this.deleteCaseIssueId = item.caseIssueId;
    },
    onDeletedIssue() {
      this.$refs.deleteIssueModal.close();
      const index = this.issueList.indexOf(this.deleteIssueItem);
      if (index > -1) {
        this.issueList.splice(index, 1);
      }
      this.$alertSuccess(
        this.$t('pages/case/CaseManagement/components/issueLog/alert/deleteIssueSuccess', {
          issue: this.deleteIssueName,
        }),
      );
      this.$resetBlockChanged();
    },
    async onResendMail(item) {
      this.isProcessing = true;
      const { error, data } = await CaseIssueService.resendMail(item.caseIssueId);
      this.isProcessing = false;
      if (error) {
        this.$alertError(error);
      } else {
        if (data) {
          this.$alertSuccess(
            this.$t('pages/case/CaseManagement/components/issueLog/alert/resendIssueSuccess', {
              issue: item.issue.issueName,
            }),
          );
        }
      }
    },
    async onAcceptAndCloseIssue(item) {
      this.$refs.editIssueModal.close();
      const { error, data } = await CaseIssueService.approveCaseIssue(item.caseIssueId);
      this.$refs.editIssueModal.isProcessingStatus(false);
      if (error) {
        this.$alertError(error);
      } else {
        if (data) {
          this.$alertSuccess(
            this.$t('pages/case/CaseManagement/components/issueLog/alert/acceptSuccess', {
              issue: item.issueName,
            }),
          );
          this.issueList[this.selectedId].status = 'Closed';
          this.issueList[this.selectedId].action = 'view';
          this.issueList[this.selectedId].respondedAt = item.respondedAt;
          this.issueList.sort((a, b) => {
            return new Date(b.reportedAt) - new Date(a.reportedAt);
          });
          this.addEvent(
            newAppEvent(APP_EVENTS.EVT_ON_CASE_HIDE_COUNT_DOWN, {
              onHideCountDown: true,
            }),
          );
        }
      }
    },
    async onRejectIssue(item) {
      this.$refs.editIssueModal.close();
      const { error, data } = await CaseIssueService.rejectCaseIssue(item.caseIssueId);
      this.$refs.editIssueModal.isProcessingStatus(false);
      if (error) {
        this.$alertError(error);
      } else {
        if (data) {
          this.$alertSuccess(
            this.$t('pages/case/CaseManagement/components/issueLog/alert/rejectSuccess', {
              issue: item.issueName,
            }),
          );
        }
        this.issueList[this.selectedId].status = 'Open';
        this.issueList[this.selectedId].action = 'edit, delete, send';
        this.issueList[this.selectedId].respondedAt = item.respondedAt;
        this.issueList.sort((a, b) => {
          return new Date(b.reportedAt) - new Date(a.reportedAt);
        });
      }
    },
    issueLogData() {
      return {
        isClinicalIncident: this.formData.isClinicalIncident,
        clinicalIncidentComment: this.formData.clinicalIncidentComment,
      };
    },
    async onSave(overWrite = false) {
      try {
        this.isProcessing = true;
        const rowVersion = this.getRowVersionByCaseId(this.dataEdit.caseId);
        const dataForm = this.issueLogData();
        const res = await caseFormService.updateClinicalIncident(this.dataEdit.caseId, {
          ...dataForm,
          rowVersion: overWrite ? null : rowVersion,
        });
        this.isProcessing = false;
        this.$onAfterSaveHandler({
          res,
          dataEdit: this.dataEdit,
        });
      } catch (errors) {
        this.isProcessing = false;
        this.$alertError(errors);
      }
    },
  },
};
</script>
<style lang="scss">
.th-action {
  cursor: pointer;
  width: 110px;
}
.action-column-width {
  width: 100px;
}
</style>
