<template>
  <div class="legend-search-wrapper">
    <div @click="isShow = !isShow" class="toggle-legend-btn">
      <span v-if="isShow">{{ $t('global/LegendSearch.hide') }}</span>
      <span v-else>{{ $t('global/LegendSearch.show') }}</span>
      <md-icon :class="!isShow ? 'is-hide' : ''">expand_less</md-icon>
    </div>
    <div class="legend-list-wrapper" v-show="isShow">
      <ul class="legend-list">
        <li>
          {{ $t('pages/case/SlideManagement/List/LegendSearch.OriginalSlide') }}
        </li>
        <li class="orange-color">
          {{ $t('pages/case/SlideManagement/List/LegendSearch.AlwSlide') }}
        </li>
        <li :key="idx" v-for="(name, idx) in locationNames">
          <LocationIcon :name="name" :showLabel="true" />
        </li>
        <li>
          <md-icon class="black-color">hourglass_empty</md-icon>
          {{ $t('pages/case/SlideManagement/List/LegendSearch.Lab') }}
        </li>
        <li>
          <md-icon class="black-color">hourglass_top</md-icon>
          {{ $t('pages/case/SlideManagement/List/LegendSearch.Path') }}
        </li>
        <li>
          <md-icon class="grey-color">hourglass_bottom</md-icon>
          {{ $t('pages/case/SlideManagement/List/LegendSearch.ProvisionallyReported') }}
        </li>
        <li>
          <md-icon class="black-color">hourglass_bottom</md-icon>
          {{ $t('pages/case/SlideManagement/List/LegendSearch.Reported') }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { LOCATION_FLAG_NAMES } from '@/core/constants';
import LocationIcon from '../Components/LocationIcon.vue';
export default {
  components: {
    LocationIcon,
  },
  created() {
    this.userType = this.$store.getters['auth/userType'];
  },

  computed: {
    locationNames: function () {
      return Object.values(LOCATION_FLAG_NAMES);
    },
  },

  data() {
    return {
      isShow: true,
    };
  },
};
</script>
<style lang="scss" scoped>
.md-icon.md-theme-default.md-icon-font.slide-location-legend {
  &-Lab {
    color: navy;
  }
  &-External {
    color: orange;
  }
  &-SentToPath {
    color: red;
  }
  &-ReturnedToLab {
    color: green;
  }
  &-ReturnedToClinic {
    color: gray;
  }
}
</style>
