<template>
  <md-dialog :md-close-on-esc="true" :md-active.sync="isVisible" @md-clicked-outside="close" class="lims-modal">
    <div class="dialog-icon-close" @click="close"><md-icon>close</md-icon></div>
    <md-dialog-title>{{ $t('components/lims/modals/ModalConfirmation.title') }}</md-dialog-title>
    <div class="lims-form dialog-content">
      <p v-if="isMyEntityView" class="text-center">
        {{ $t('components/lims/modals/ModalConfirmationMyEntity.contentTxt') }}
      </p>
      <p v-if="typeEntity" class="text-center">{{ $t('components/lims/modals/ModalConfirmationEntity.contentTxt') }}</p>
      <p v-else class="text-center">{{ $t('components/lims/modals/ModalConfirmation.contentTxt') }}</p>
      <lims-field :model="formData" :schema="checkCodeSchema" field="password" :view-mode="'only'">
        <md-input
          slot="field"
          ref="confirmPassword"
          @keyup.enter="onClick"
          v-model="formData.password"
          type="password"
        ></md-input>
      </lims-field>
    </div>
    <md-dialog-actions>
      <md-button @click="close" class="lims-form-button">{{ $t('global/button/button.cancel') }}</md-button>
      <md-button @click="onClick" :disabled="formData.password == ''" class="lims-form-button md-primary">
        {{ $t('global/button/button.confirm') }}
      </md-button>
    </md-dialog-actions>
  </md-dialog>
</template>

<script>
import modalMixins from './modal.mixins';
import { getCheckCodeSchema } from '@/schemas/check-code.schema';

export default {
  name: 'ModalConfirmation',

  mixins: [modalMixins],

  props: {
    errorMessage: {
      type: String,
      required: false,
      default: '',
    },
    typeEntity: {
      type: Boolean,
      default: false,
    },
    isMyEntityView: {
      type: Boolean,
      default: false,
    },
    isWrongPassword: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      formData: {
        password: '',
      },
    };
  },

  computed: {
    checkCodeSchema() {
      return getCheckCodeSchema(this.isWrongPassword);
    },
  },

  methods: {
    onClick() {
      this.$emit('onConfirm', this.formData);
    },
  },
};
</script>

<style lang="scss"></style>
