<template>
  <md-dialog :md-close-on-esc="true" :md-active.sync="isVisible" @md-clicked-outside="close" class="lims-modal">
    <div class="dialog-icon-close" @click="close"><md-icon>close</md-icon></div>
    <md-dialog-title>{{ $t('entities/modals/ModalSelectTypeAlwRequest/selectType') }}</md-dialog-title>
    <md-dialog-content class="select-type">
      <div class="type-padding" v-for="(t, idx) in requestTypes" :key="idx">
        <input type="radio" :id="`type-${t.value}`" name="selectType" :value="t.value" v-model="selectedType" checked />
        <label :for="`type-${t.value}`">{{ $t(`entities/modals/ModalSelectTypeAlwRequest/${t.label}`) }}</label>
      </div>
    </md-dialog-content>
    <md-dialog-actions>
      <md-button @click="close" class="lims-form-button">{{ $t('global/button/button.cancel') }}</md-button>
      <md-button @click="onContinue" :disabled="!selectedType" class="lims-form-button md-primary">{{
        $t('global/button/button.continue')
      }}</md-button>
    </md-dialog-actions>
  </md-dialog>
</template>

<script>
import modalMixins from '@/components/Lims/modals/modal.mixins';
import { DROPDOWN_SHORT_NAME } from '@/core/constants';
import { DropdownService } from '@/services';

export default {
  mixins: [modalMixins],
  data() {
    return {
      selectedType: null,
      requestTypes: null,
    };
  },
  created() {
    this.fetchData();
  },
  computed: {},
  methods: {
    async fetchData() {
      const dropdownOptions = await DropdownService.getDropdownByShortNames([DROPDOWN_SHORT_NAME.CaseAWLRequestType]);
      this.requestTypes = dropdownOptions[DROPDOWN_SHORT_NAME.CaseAWLRequestType];
    },
    onContinue() {
      // emit, type
      const requestType = this.requestTypes.filter((t) => this.selectedType == t.value);
      this.$emit('onContinue', requestType[0]);
    },
  },
};
</script>

<style lang="scss">
.select-type {
  padding: 30px 0 30px 200px;
  .type-padding {
    padding: 5px;
  }
}
</style>
