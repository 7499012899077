<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit, invalid }">
    <form class="md-layout lims-form form-login" @submit.prevent="handleSubmit()">
      <md-dialog :md-close-on-esc="true" :md-active.sync="isVisible" @md-clicked-outside="close" class="lims-modal">
        <div class="dialog-icon-close" @click="close"><md-icon>close</md-icon></div>
        <md-dialog-title>{{ $t('components/ModalBulkActionProblem.title') }}</md-dialog-title>
        <div class="lims-form dialog-content">
          <template v-if="preventProvisionallyReportedIssues">
            <p
              class="text-center"
              v-html="$t('components/ModalAuthorisationIssue/preventUpdateToProvisionallyReportedStatus')"
            ></p>
            <ul class="list-of-compatible-cases">
              <li v-for="(item, index) in preventProvisionallyReportedIssues" :key="index">
                {{ item }}
              </li>
            </ul>
            <p
              v-if="status !== 'None'"
              class="text-center"
              v-html="$t('components/ModalAuthorisationIssue/canUpdateCaseToReported')"
            ></p>
          </template>
          <template v-if="preventReportIssues">
            <p class="text-center" v-html="$t('components/ModalAuthorisationIssue/preventUpdateToReportedStatus')"></p>
            <ul class="list-of-compatible-cases">
              <li v-for="(item, index) in preventReportIssues" :key="index">
                {{ item }}
              </li>
            </ul>
            <p
              v-if="status !== 'None'"
              class="text-center"
              v-html="$t('components/ModalAuthorisationIssue/canUpdateCaseToProVision')"
            ></p>
          </template>
          <p
            v-if="status === 'None'"
            class="text-center"
            v-html="$t('components/ModalAuthorisationIssue/pleaseResolved')"
          ></p>
        </div>
        <md-dialog-actions>
          <template v-if="status !== 'None'">
            <md-button @click="close" class="lims-form-button">{{ $t('global/button/button.cancel') }}</md-button>
            <md-button @click="onContinue" :disabled="invalid" class="lims-form-button md-primary">{{
              $t('global/button/button.continue')
            }}</md-button>
          </template>
          <md-button v-else @click="close" class="lims-form-button">{{ $t('global/button/button.back') }}</md-button>
        </md-dialog-actions>
      </md-dialog>
    </form>
  </ValidationObserver>
</template>

<script>
import modalMixins from './modal.mixins';

export default {
  name: 'ModalAuthorisationIssue',
  mixins: [modalMixins],
  props: {},
  computed: {
    status() {
      return this.authorizedStatusResponse ? this.authorizedStatusResponse?.status : null;
    },
    preventReportIssues() {
      return this.authorizedStatusResponse?.preventReportIssues
        ? this.authorizedStatusResponse?.preventReportIssues
        : null;
    },
    preventProvisionallyReportedIssues() {
      return this.authorizedStatusResponse?.preventProvisionallyReportedIssues
        ? this.authorizedStatusResponse?.preventProvisionallyReportedIssues
        : null;
    },
  },
  methods: {
    onContinue() {
      this.close();
      this.$emit('onContinue', {
        authorizedStatusResponse: this.authorizedStatusResponse,
      });
    },
    setAuthorizedStatusResponse(authorizedStatusResponse) {
      this.authorizedStatusResponse = authorizedStatusResponse;
    },
  },
  data: function () {
    return {
      authorizedStatusResponse: null,
    };
  },
};
</script>

<style lang="scss"></style>
