<template>
  <div class="sb-filter list-filter">
    <div class="sb-filter01">
      <div class="sb-filter01-item">
        <md-field class="input-search">
          <md-icon>search</md-icon>
          <md-input v-model="formData.search" :placeholder="$t('case/sb/filter/search/placeHolder')"></md-input>
        </md-field>
      </div>
      <lims-custom-select
        class="sb-filter01-item"
        v-model="formData.laboratoryReferences"
        :multiple="true"
        :options="dataSource.laboratoryReferences"
        :translated="true"
        :placeholder="$t('case/sb/filter/labRef')"
      ></lims-custom-select>
      <lims-custom-select
        class="sb-filter01-item"
        v-model="formData.caseReferences"
        :multiple="true"
        :options="dataSource.caseReferences"
        :translated="true"
        :placeholder="$t('case/sb/filter/casesRef')"
      ></lims-custom-select>
      <lims-custom-select
        class="sb-filter01-item"
        v-model="formData.clinicians"
        :multiple="true"
        :options="dataSource.clinicians"
        :translated="true"
        :placeholder="$t('case/sb/filter/clinicians')"
      ></lims-custom-select>
      <lims-custom-select
        v-model="formData.pathologists"
        :multiple="true"
        :options="dataSource.pathologists"
        :translated="true"
        :placeholder="$t('case/sb/filter/pathologists')"
      ></lims-custom-select>
      <lims-custom-select
        class="sb-filter01-item"
        v-model="formData.specimenTypes"
        :multiple="true"
        :options="dataSource.specimenTypes"
        :translated="true"
        :placeholder="$t('case/sb/filter/speTypes')"
      ></lims-custom-select>
      <lims-custom-select
        class="sb-filter01-item"
        v-model="formData.clinics"
        :multiple="true"
        :options="dataSource.clinics"
        :translated="true"
        :placeholder="$t('case/sb/filter/Clinics')"
      ></lims-custom-select>
    </div>
    <div class="sb-filter02">
      <div class="sb-filter02-left">
        <div class="num-of-case">{{ cases.length }} Cases</div>
        <div class="collapse-expand">
          Collapse<md-switch v-model="formData.expand"></md-switch>{{ $t('case/sb/filter/expand') }}
        </div>
        <md-checkbox class="space-locked-cases" v-model="formData.lockedCases">{{
          $t('case/sb/filter/lockedCases')
        }}</md-checkbox>
        <md-checkbox class="space-hidden-cases" v-model="formData.hiddenCases">{{
          $t('case/sb/filter/hiddenCases')
        }}</md-checkbox>
        <a class="sb-deselect-all" @click="onDeselectAll"
          ><span class="material-icons">deselect</span>{{ $t('case/sb/filter/deselectAll') }}
        </a>
      </div>
      <div class="sb-filter02-right">
        <div class="row-per-page">
          <div>{{ $t('case/sb/filter/show') }}</div>
          <slot name="rowsPerPage"></slot>
          <div>{{ $t('case/sb/filter/cases') }}</div>
        </div>
        <div class="space-shorting-cases">
          {{ $t('case/sb/filter/shortingCases') }}
          <v-select
            v-model="formData.sortBy"
            :options="dataSource.sortOptions"
            :translated="true"
            :reduce="(item) => item.value"
            :placeholder="$t('case/sb/filter/caseRef')"
          >
            <template #option="{ label }">{{ $t(label) }}</template>
            <template #selected-option="{ label }">{{ $t(label) }}</template>
          </v-select>
        </div>
        <div class="btn-reset" @click="onRefresh()"><md-icon>sync</md-icon></div>
      </div>
    </div>
  </div>
</template>
<script>
import { pick, cloneDeep, uniqBy } from 'lodash';
import { mapActions } from 'vuex';
import { APP_EVENTS, newAppEvent } from '@/core/constants';
import { searchCases } from '../SB.vue';

export const SB_FILTER_OPTIONS = {
  CASE_REF_ASC: 'CASE_REF_ASC',
  CASE_REF_DESC: 'CASE_REF_DESC',
};

const pickFields = (cases, fields, key, label) => {
  const options = cloneDeep(cases)
    .map((c) => pick(c, fields))
    .map((c) => {
      return {
        label: c[label],
        value: c[key],
      };
    });
  const optionsNotNullValue = options.filter((item) => item.value != null);
  return uniqBy(optionsNotNullValue, 'value');
};

const pickFieldPathologist = (cases, fields, key, label) => {
  const options = cloneDeep(cases).map((c) => {
    return {
      label: c[key] ? c[label] : c.pathologistTypeName === 'Pull' ? c.pathologistTypeName : null,
      value: c[key],
    };
  });
  const optionsNotNullValue = options.filter((item) => item.label != null);
  return uniqBy(optionsNotNullValue, 'value');
};

const pickFieldSpecimenTypes = (cases, fields, key, label) => {
  const options = cloneDeep(cases)
    .filter((c) => c.caseSpecimens[0])
    .map((c) => c.caseSpecimens[0])
    .map((c) => pick(c, fields))
    .map((c) => {
      return {
        label: c[label],
        value: c[key],
      };
    });
  return uniqBy(options, 'value');
};

const buildFilterDataSourceFromCases = (cases) => {
  const dataSource = {
    laboratoryReferences: pickFields(cases, ['laboratoryReference'], 'laboratoryReference', 'laboratoryReference'),
    caseReferences: pickFields(cases, ['caseReference'], 'caseReference', 'caseReference'),
    clinicians: pickFields(cases, ['clinicianId', 'clinicianName'], 'clinicianId', 'clinicianName'),
    pathologists: pickFieldPathologist(cases, ['pathologistId', 'pathologistName'], 'pathologistId', 'pathologistName'),
    specimenTypes: pickFieldSpecimenTypes(
      cases,
      ['specimenTypeId', 'specimentTypeName'],
      'specimenTypeId',
      'specimentTypeName',
    ),
    clinics: pickFields(cases, ['clinicId', 'clinicName'], 'clinicId', 'clinicName'),
    sortOptions: [
      { label: 'case/sb/filter/sort/case_ref_asc', value: SB_FILTER_OPTIONS.CASE_REF_ASC },
      { label: 'case/sb/filter/sort/case_ref_desc', value: SB_FILTER_OPTIONS.CASE_REF_DESC },
    ],
  };

  return dataSource;
};
export default {
  props: {
    cases: {
      type: Array,
      require: true,
    },
  },
  created() {
    this.caseSource = this.cases;
    this.dataSource = buildFilterDataSourceFromCases(this.caseSource);
  },
  watch: {
    'formData.expand': {
      handler: function (expand) {
        this.$toggleExpand(expand);
      },
      deep: true,
    },
    formData: {
      handler: function (newValue) {
        this.$emit('input', newValue);
        const { items } = searchCases(this.cases, newValue);
        this.caseSource = items;

        // current filter keep value dropdownList, Other filters auto reloaded based on the search result list
        const dataSourceNew = buildFilterDataSourceFromCases(this.caseSource);
        Object.entries(newValue).forEach(([key, value]) => {
          if (Array.isArray(value) && value.length == 0) {
            this.dataSource[key] = dataSourceNew[key];
          }
        });
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions('app/event', ['addEvent']),
    ...mapActions('sb', ['$toggleExpand']),
    onDeselectAll() {
      this.addEvent(newAppEvent(APP_EVENTS.EVT_ON_SB_DESELECT_ALL, { trigger: true }));
    },
    onRefresh() {
      this.addEvent(newAppEvent(APP_EVENTS.EVT_ON_SB_ON_REFRESH, { refresh: true }));
    },
  },
  data() {
    return {
      caseSource: [],
      dataSource: null,
      formData: {
        search: '',
        laboratoryReferences: [],
        caseReferences: [],
        clinicians: [],
        pathologists: [],
        specimenTypes: [],
        clinics: [],
        expand: false,
        lockedCases: false,
        hiddenCases: false,
        rowsPerPage: 50,
        sortBy: null,
      },
    };
  },
};
</script>
<style lang="scss"></style>
