var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.range)?_c('date-picker',{attrs:{"range":"","disabled":_vm.disabled,"lang":{
    formatLocale: {
      firstDayOfWeek: 1,
    },
    monthBeforeYear: false,
  },"type":_vm.dataType,"format":_vm.format,"time-picker-options":{ start: '00:00', step: '00:10', end: '23:50' },"placeholder":_vm.placeholder,"tabenable":"yes"},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}}):_c('date-picker',{attrs:{"disabled":_vm.disabled,"lang":{
    formatLocale: {
      firstDayOfWeek: 1,
    },
    monthBeforeYear: false,
  },"type":_vm.dataType,"format":_vm.format,"time-picker-options":{ start: '00:00', step: '00:10', end: '23:50' },"placeholder":_vm.placeholder,"tabenable":"yes"},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})}
var staticRenderFns = []

export { render, staticRenderFns }