<template>
  <div>
    <modal-bulk-action-problem-slide
      ref="bulkActionProblemSlideModal"
      :bulkActionSelect="bulkAction"
      @onContinue="onContinueBulkAction"
    ></modal-bulk-action-problem-slide>
    <modal-bulk-action-slide
      ref="bulkActionSlideModal"
      :bulkActionSelect="bulkAction"
      @onCompletedAction="onCompleteBulkAction"
    ></modal-bulk-action-slide>
    <div v-if="slideList && slideList.length > 0" class="toolbar-row">
      <div class="select-action">
        <v-select
          class="action-dropdown"
          v-model="bulkAction"
          :options="bulkActionList"
          :placeholder="$t('pages/case/CaseManagement/actionDropdown/selectAction.placeholder')"
        >
          <template #option="{ label }">{{ label }}</template>
          <template #selected-option="{ label }">{{ label }}</template>
        </v-select>
        <md-button
          @click="onApply"
          class="md-button lims-form-button md-theme-default apply-btn"
          :disabled="selectedRows.length === 0 || !bulkAction"
          >{{ $t('global/button/button.apply') }}</md-button
        >
      </div>

      <div v-if="isLabTechnicianView" class="select-action export-template">
        <v-select
          class="action-dropdown"
          v-model="templateExport"
          :options="exportList"
          :reduce="(item) => item.value"
          :placeholder="$t('pages/case/CaseManagement/actionDropdown/exportTemplate.placeholder')"
        >
          <template #option="{ label }">{{ $t(label) }}</template>
          <template #selected-option="{ label }">{{ $t(label) }}</template>
        </v-select>
        <md-button
          @click="onExportTemplate(templateExport)"
          :disabled="!templateExport"
          class="md-button lims-form-button md-theme-default apply-btn"
          >{{ $t('global/button/button.export') }}</md-button
        >
      </div>
    </div>
    <slot name="toolbar"></slot>
    <md-table class="paginated-table table-striped table-hover lims-table case-list-tbl">
      <div class="table-fix-header">
        <thead>
          <md-table-row>
            <md-table-head class="checkbox-column">
              <md-checkbox v-model="selectAll"></md-checkbox>
            </md-table-head>
            <md-table-head v-for="(col, index) in colTabl" :key="index" :class="col.class">
              <div @mouseover="hoverIn(index)" @mouseout="hoverOut()" @click="sortCol(col.key, col.sort)">
                {{ $t(col.name) }}
                <span>
                  <md-icon v-if="col.sort">
                    {{
                      col.key === orderState.orderField
                        ? orderState.orderBy === 'asc'
                          ? 'arrow_drop_up'
                          : 'arrow_drop_down'
                        : hover === index
                        ? 'arrow_drop_up'
                        : ''
                    }}
                  </md-icon>
                </span>
              </div>
            </md-table-head>
          </md-table-row>
        </thead>
        <tbody>
          <md-table-row v-for="(item, index) in slideList" :key="`slide-${index}`" :class="checkRowHighlight(item)">
            <md-table-cell class="checkbox-column">
              <md-checkbox v-model="selectedRows" :value="item" class="lims-checkbox"></md-checkbox>
            </md-table-cell>
            <md-table-cell class="caseRef-column">{{ item.case.caseReference }}</md-table-cell>
            <md-table-cell>{{ item.case.laboratoryReference }}</md-table-cell>
            <md-table-cell>{{ item.case.firstName }} {{ item.case.lastName }}</md-table-cell>
            <md-table-cell>{{ item.case.clinicName }}</md-table-cell>
            <md-table-cell>{{ item.case.clinicTypeName }}</md-table-cell>
            <md-table-cell>{{
              item.case.pathologistName ? item.case.pathologistName : item.case.pathologistTypeName
            }}</md-table-cell>
            <md-table-cell>{{ item.caseSpecimenBlock.specimentTypeName }}</md-table-cell>
            <md-table-cell>{{ item.caseSpecimenBlock.block.text }}</md-table-cell>
            <md-table-cell>{{ item.slide.fieldItemName }}</md-table-cell>
            <md-table-cell>
              <LocationIcon :name="item.locationFlag" />
            </md-table-cell>
            <md-table-cell class="date-column">{{
              item.lastUpdated ? formatDateTimeGlobalNotIncludeTime(item.lastUpdated) : ''
            }}</md-table-cell>
            <md-table-cell class="status-column">
              <md-icon v-if="item.case.status == STATUSES.LAB" class="black-color">hourglass_empty</md-icon>
              <md-icon v-if="item.case.status == STATUSES.PATH" class="black-color">hourglass_top</md-icon>
              <md-icon v-if="item.case.status == STATUSES.PROVISIONALLY_REPORTED" class="grey-color"
                >hourglass_bottom</md-icon
              >
              <md-icon v-if="item.case.status == STATUSES.REPORTED" class="black-color">hourglass_bottom</md-icon>
            </md-table-cell>
            <md-table-cell class="actions-column">
              <div class="cell-action-inner" v-if="showViewIcon(item)">
                <div style="width: 25px">
                  <md-button class="md-just-icon md-info md-simple" @click.native="handleAction('view', item)">
                    <md-icon>visibility</md-icon>
                  </md-button>
                </div>
              </div>
              <div class="cell-action-inner" v-if="showEditIcon(item)">
                <div style="width: 25px">
                  <md-button class="md-just-icon md-warning md-simple" @click.native="handleAction('edit', item)">
                    <md-icon>edit</md-icon>
                  </md-button>
                </div>
              </div>
            </md-table-cell>
          </md-table-row>
        </tbody>
      </div>
    </md-table>
    <slot name="pagination"></slot>
  </div>
</template>
<script>
import { LOCATION_BULK_ACTIONS, CASE_STATUS, EXPORT_TEMPLATE, PATHOLOGIST_TYPE_NAME } from '@/core/constants';
import { FormMixins } from '@/core/mixins';
import slideMixins from '@/pages/Case/SlideManagement/slide.mixins';

import ModalBulkActionProblemSlide from '@/components/Lims/modals/ModalBulkActionProblemSlide.vue';
import ModalBulkActionSlide from '@/components/Lims/modals/ModalBulkActionSlide.vue';

import LocationIcon from '../Components/LocationIcon.vue';

export default {
  name: 'SlideList',
  mixins: [FormMixins, slideMixins],
  components: { ModalBulkActionProblemSlide, ModalBulkActionSlide, LocationIcon },
  props: {
    slideList: {
      required: false,
      default: function () {
        return [];
      },
    },
  },
  created() {
    this.userType = this.$store.getters['auth/userType'];
    this.bulkActionList = Reflect.ownKeys(LOCATION_BULK_ACTIONS).map((action) => {
      return { value: action, label: this.$translate(`pages/case/slideManagement/list/bulkActions/${action}`) };
    });
  },
  data() {
    return {
      userType: '',
      selectedRows: [],
      bulkAction: '',
      templateExport: '',
      slidesSelected: [],
      caseReferencesSelected: [],
      colTabl: [
        {
          name: 'pages/case/slideManagement/list/caseRef',
          key: 'caseReference',
          sort: false,
          class: 'caseRef-column',
        },
        {
          name: 'pages/case/slideManagement/list/labRef',
          key: 'labReference',
          sort: false,
          class: 'labRef-column',
        },
        {
          name: 'pages/case/slideManagement/list/patient',
          key: 'patientFullname',
          sort: false,
          class: 'patient-column',
        },
        {
          name: 'pages/case/slideManagement/list/clinic',
          key: 'clinicName',
          sort: false,
          class: 'clinic-column',
        },
        {
          name: 'pages/case/slideManagement/list/clinicType',
          key: 'clinicianType',
          sort: false,
          class: 'clinicType-column',
        },
        {
          name: 'pages/case/slideManagement/list/pathologist',
          key: 'pathologist',
          sort: false,
          class: 'pathologist-column',
        },
        {
          name: 'pages/case/slideManagement/list/specimenType',
          key: 'specimen',
          sort: false,
          class: 'specimen-column',
        },
        {
          name: 'pages/case/slideManagement/list/block',
          key: 'block',
          sort: false,
          class: 'block-column',
        },
        {
          name: 'pages/case/slideManagement/list/slide',
          key: 'slide',
          sort: false,
          class: 'slide-column',
        },
        {
          name: 'pages/case/slideManagement/list/locationFlag',
          key: 'locationFlag',
          sort: false,
          class: 'locationFlag-column',
        },

        {
          name: 'pages/case/slideManagement/list/dateSent',
          key: 'dateSent',
          sort: false,
          class: 'date-column',
        },
        {
          name: 'global/pages/list.status',
          key: 'statusName',
          sort: false,
          class: 'status-column',
        },
        {
          name: 'global/pages/list.actions',
          key: 'actions',
          sort: false,
          class: 'actions-column',
        },
      ],
      hover: '',
      orderState: {
        orderField: 'caseReference',
        orderBy: 'asc',
      },
      exportList: [
        {
          value: EXPORT_TEMPLATE.SlideP1000Pathologist,
          label: 'entities/case/exportTemplateName.SlideP1000Pathologist',
        },
        {
          value: EXPORT_TEMPLATE.SlideP1000Clinic,
          label: 'entities/case/exportTemplateName.SlideP1000Clinic',
        },
        {
          value: EXPORT_TEMPLATE.SlideP1000CaseReference,
          label: 'entities/case/exportTemplateName.SlideP1000CaseReference',
        },
        {
          value: EXPORT_TEMPLATE.ReturnsEmail,
          label: 'entities/case/exportTemplateName.ReturnsEmail',
        },
        {
          value: EXPORT_TEMPLATE.ReturnsPrint,
          label: 'entities/case/exportTemplateName.ReturnsPrint',
        },
        {
          value: EXPORT_TEMPLATE.ReturnsSummary,
          label: 'entities/case/exportTemplateName.ReturnsSummary',
        },
      ],
      bulkActionList: [],
    };
  },
  computed: {
    isLabTechnicianView() {
      return this.userType === this.USER_TYPES().LabTechnician;
    },
    STATUSES: function () {
      return CASE_STATUS;
    },
    selectAll: {
      get: function () {
        return this.slideList ? this.slideList.length == this.selectedRows.length : false;
      },
      set: function (value) {
        var selected = [];
        if (value) {
          this.slideList.forEach(function (item) {
            selected.push(item);
          });
        }
        this.selectedRows = selected;
      },
    },
  },
  watch: {
    templateExport() {
      if (this.selectedRows && this.selectedRows.length === 0) {
        this.selectAll = true;
      }
    },
    selectedRows: {
      handler: function (value) {
        if (value) {
          let slidesSelected = [];
          let caseReferencesSelected = [];
          const caseSelected = this.slideList.filter((item) =>
            value.some((x) => x.slide.blockSlideItemId === item.slide.blockSlideItemId),
          );
          if (caseSelected && caseSelected.length > 0) {
            caseSelected.forEach(function (item) {
              slidesSelected.push({ blockSlideItemId: item.slide.blockSlideItemId, slideType: item.slideType });
              caseReferencesSelected.push(item.case.caseReference);
            });
          }

          this.slidesSelected = slidesSelected;
          this.caseReferencesSelected = caseReferencesSelected;
        }
      },
      deep: true,
    },
    slideList: {
      handler: function (val) {
        if (val) {
          if (this.templateExport) {
            this.selectAll = true;
          } else {
            this.selectedRows = [];
          }
        }
      },
      deep: true,
    },
  },
  methods: {
    hoverIn(index) {
      this.hover = index;
    },
    hoverOut() {
      this.hover = '';
    },
    sortCol(colName, isSort) {
      if (isSort) {
        if (this.orderState.orderField === colName) {
          if (this.orderState.orderBy === 'asc') {
            this.orderState.orderBy = 'desc';
          } else {
            this.orderState.orderBy = 'asc';
          }
        } else {
          this.orderState.orderField = colName;
          this.orderState.orderBy = 'asc';
        }
      }
    },
    checkRowHighlight(item) {
      if (item.typeName == 'ALW') {
        return 'orange-color';
      } else {
        return '';
      }
    },
    handleAction(action, item) {
      this.$emit('action', {
        action,
        item,
      });
    },

    showViewIcon(slide) {
      return (
        !slide.case.pathologistId &&
        slide.case.pathologistTypeName == PATHOLOGIST_TYPE_NAME.Pull &&
        this.$isAuthorized(['Case_SlideManagement_Update'])
      );
    },
    showEditIcon(slide) {
      return slide.case.pathologistId && this.$isAuthorized(['Case_SlideManagement_Update']);
    },
    async onApply() {
      if (this.bulkAction?.value) {
        await this.$processAction({
          action: this.bulkAction.value,
          slides: this.selectedRows,
          bulkActionSlideModal: this.$refs.bulkActionSlideModal,
          bulkActionProblemSlideModal: this.$refs.bulkActionProblemSlideModal,
        });
      }
    },
    onContinueBulkAction() {
      this.$refs.bulkActionSlideModal.open();
    },
    onCompleteBulkAction() {
      this.$alertSuccess(
        this.$t('components/ModalBulkActionSlide.completeActionSuccess', {
          bulkAction: this.bulkAction.label,
        }),
      );
      // clear selected checkbox and action
      this.selectedRows = [];
      this.bulkAction = '';
      this.$emit('onReloadCaseSlide');
    },
    async onExportTemplate(template) {
      if (this.selectedRows && this.selectedRows.length === 0) {
        return this.$alertWarning(this.$t('pages/case/CaseManagement/List/ExportAction/SelectAtLeastOneCase'));
      }
      // otherwise
      const dataExport = this.selectAll
        ? { isExportAllCases: this.selectAll, caseReferences: this.caseReferencesSelected }
        : {
            isExportAllCases: this.selectAll,
            slides: this.slidesSelected,
            caseReferences: this.caseReferencesSelected,
          };
      this.$emit('onExportTemplate', {
        template,
        dataExport,
      });
    },
  },
};
</script>
<style lang="scss"></style>
