<template>
  <md-dialog :md-close-on-esc="true" :md-active.sync="isVisible" @md-clicked-outside="close" class="lims-modal">
    <div class="dialog-icon-close" @click="close"><md-icon>close</md-icon></div>
    <md-dialog-title>{{ $t('pages/account/user/components/modalHasLoginConfirm/dialogTitle') }}</md-dialog-title>
    <div class="lims-form dialog-content">
      <p class="text-center">
        {{ $t('pages/account/user/components/modalConfirmCreateNewUserMyEntity/dialogContent') }}
      </p>
    </div>
    <md-dialog-actions>
      <md-button @click="close" class="lims-form-button">{{ $t('global/button/button.cancel') }}</md-button>
      <md-button @click="onConfirm" class="lims-form-button md-primary">{{
        $t('global/button/button.confirm')
      }}</md-button>
    </md-dialog-actions>
  </md-dialog>
</template>

<script>
import modalMixins from '@/components/Lims/modals/modal.mixins';

export default {
  name: 'modal-confirm-create-new-user-my-entity',

  mixins: [modalMixins],

  methods: {
    onConfirm() {
      this.$emit('onConfirm');
    },
  },
};
</script>

<style lang="scss"></style>
