<template>
  <label v-show="failed && error" class="form-error" :class="isItemOrder ? 'item-order-width' : ''">{{ error }}</label>
</template>
<script>
export default {
  props: {
    failed: {
      type: Boolean,
      required: false,
    },
    passed: {
      type: Boolean,
      required: false,
      default: false,
    },
    error: {
      type: String,
      required: false,
      default: '',
    },
    isItemOrder: {
      type: Boolean,
      required: false,
    },
  },
};
</script>
<style lang="scss">
.item-order-width {
  width: 200px !important;
}
</style>
