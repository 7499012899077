<template>
  <div>
    <h4>{{ $t('pages/case/slideManagement/component/SlideDetail/slideInformation') }}</h4>
    <md-table class="custom-tbl" md-card>
      <md-table-row>
        <md-table-head>{{ $t('pages/case/slideManagement/component/SlideDetail/table/specimenNo') }}</md-table-head>
        <md-table-head>{{ $t('pages/case/slideManagement/component/SlideDetail/table/specimenType') }}</md-table-head>
        <md-table-head>{{
          $t('pages/case/slideManagement/component/SlideDetail/table/specimenDetailsOnPot')
        }}</md-table-head>
        <md-table-head>{{
          $t('pages/case/slideManagement/component/SlideDetail/table/macroDescriptionFromLab')
        }}</md-table-head>
        <md-table-head>{{ $t('pages/case/slideManagement/component/SlideDetail/table/block') }}</md-table-head>
        <md-table-head v-if="dataEdit.typeName == 'ALW'">{{
          $t('pages/case/slideManagement/component/SlideDetail/table/type')
        }}</md-table-head>
        <md-table-head v-if="dataEdit.typeName == 'ALW'"
          ><div v-html="$t('pages/case/slideManagement/component/SlideDetail/table/ALWBatchId')"></div
        ></md-table-head>
        <md-table-head v-if="dataEdit.typeName == 'ALW'"
          ><div v-html="$t('pages/case/slideManagement/component/SlideDetail/table/respondOn')"></div
        ></md-table-head>
        <md-table-head>{{ $t('pages/case/slideManagement/component/SlideDetail/table/slide') }}</md-table-head>
      </md-table-row>
      <md-table-row v-for="(item, index) in formData.caseSpecimenBlocks" :key="index">
        <md-table-cell>{{ item.specimenNumber }}</md-table-cell>
        <md-table-cell>{{ item.specimentTypeName }}</md-table-cell>
        <md-table-cell>{{ item.specimenDetails }}</md-table-cell>
        <md-table-cell>{{ item.macroDescription }}</md-table-cell>
        <md-table-cell>{{ item.block }}</md-table-cell>
        <md-table-cell v-if="dataEdit.typeName == 'ALW'">{{ formData.alwRequestType }}</md-table-cell>
        <md-table-cell v-if="dataEdit.typeName == 'ALW'">{{ formData.batchId }}</md-table-cell>
        <md-table-cell v-if="dataEdit.typeName == 'ALW'">{{
          formData.respondeOn ? formatDateTimeGlobalNotIncludeTime(formData.respondeOn) : ''
        }}</md-table-cell>
        <md-table-cell>{{ formData.slideName }}</md-table-cell>
      </md-table-row>
    </md-table>

    <h4>{{ $t('pages/case/slideManagement/component/SlideDetail/locationFlagDetail') }}</h4>
    <md-table class="custom-tbl" md-card>
      <md-table-row>
        <md-table-head>{{ $t('pages/case/slideManagement/component/SlideDetail/table/locationFlag') }}</md-table-head>
        <md-table-head>{{ $t('pages/case/slideManagement/component/SlideDetail/table/dateSent') }}</md-table-head>
        <md-table-head>{{
          $t('pages/case/slideManagement/component/SlideDetail/table/locationFlagComment')
        }}</md-table-head>
        <md-table-head>{{ $t('pages/case/slideManagement/component/SlideDetail/table/trackingNumber') }}</md-table-head>
        <md-table-head>{{
          $t('pages/case/slideManagement/component/SlideDetail/table/trackingComment')
        }}</md-table-head>
      </md-table-row>
      <md-table-row v-for="(item, index) in formData.caseSlideLocations" :key="index">
        <md-table-cell>{{
          $t(`pages/case/slideManagement/component/SlideDetail/table/locationFlag.${item.locationFlag}`)
        }}</md-table-cell>
        <md-table-cell>{{
          item.createdDate ? formatDateTimeGlobalNotIncludeTime(item.createdDate) : ''
        }}</md-table-cell>
        <md-table-cell>{{ item.comment }}</md-table-cell>
        <md-table-cell>{{ item.trackingNo }}</md-table-cell>
        <md-table-cell>{{ item.trackingComment }}</md-table-cell>
      </md-table-row>
    </md-table>
  </div>
</template>

<script>
import { FormMixins } from '@/core/mixins';
import { FORM_MODES } from '@/core/constants';

export default {
  mixins: [FormMixins],
  components: {},
  props: {
    formMode: {
      type: Number,
      require: true,
      validator: function (value) {
        // The value must match one of these strings
        return Object.values(FORM_MODES).indexOf(value) !== -1;
      },
    },
    dataEdit: {
      type: Object,
      require: false,
    },
  },
  created() {
    this.fetchData();
  },
  data() {
    return {
      formData: {},
    };
  },
  watch: {},
  methods: {
    fetchData() {
      let caseSpecimenBlocks = [];
      let caseSpecimenItem = {};
      this.dataEdit.slideDetail.forEach((caseSpecimen, index) => {
        if (caseSpecimen.caseSpecimenBlocks.length > 0) {
          caseSpecimen.caseSpecimenBlocks.forEach((caseSpecimenBlock) => {
            let slide = [];
            caseSpecimenBlock.blockFieldItems.forEach((blockFieldItem) => {
              slide.push(blockFieldItem.fieldItemName);
            });

            caseSpecimenItem = {
              ...caseSpecimenBlock,
              specimenNumber: index + 1,
              specimentTypeName: caseSpecimen.specimentTypeName,
              specimenDetails: caseSpecimen.specimenDetails,
              macroDescription: caseSpecimen.macroDescription,
              slide: slide.join(', '),
            };
            caseSpecimenBlocks.push(caseSpecimenItem);
          });
        } else {
          caseSpecimenItem = {
            specimenNumber: index + 1,
            specimentTypeName: caseSpecimen.specimentTypeName,
            specimenDetails: caseSpecimen.specimenDetails,
            macroDescription: caseSpecimen.macroDescription,
            slide: '',
          };
          caseSpecimenBlocks.push(caseSpecimenItem);
        }
      });

      this.formData = {
        ...this.dataEdit,
        caseSpecimenBlocks: caseSpecimenBlocks,
      };
    },
  },
};
</script>

<style lang="scss" scoped></style>
