<template>
  <ValidationObserver ref="formPatientData" v-slot="{ handleSubmit }">
    <collapse :wrapperClass="'case-collapse'" :collapse="['Case Information']" icon="keyboard_arrow_down">
      <template slot="md-collapse-pane-1">
        <form class="md-layout lims-form" @submit.prevent="handleSubmit()">
          <div class="form-wrapper">
            <div class="md-layout lims-form-row">
              <div class="md-layout-item md-size-25 md-small-size-100">
                <label>{{ $t('entities/case/form/caseRef') }}</label>
                <div class="static-text">{{ formData.caseReference }}</div>
              </div>
              <div class="md-layout-item md-size-25 md-small-size-100">
                <label>{{ $t('entities/case/form/firstName') }}</label>
                <div class="static-text">{{ formData.firstName }}</div>
              </div>
              <div class="md-layout-item md-size-25 md-small-size-100">
                <label>{{ $t('entities/case/form/lastName') }}</label>
                <div class="static-text">{{ formData.lastName }}</div>
              </div>
              <div class="md-layout-item md-size-25 md-small-size-100">
                <label>{{ $t('entities/case/form/sexId') }}</label>
                <div class="static-text">{{ formData.sexLabel }}</div>
              </div>
            </div>
            <div class="md-layout lims-form-row">
              <div class="md-layout-item md-size-25 md-small-size-100">
                <label>{{ $t('entities/case/form/dob') }}</label>
                <div class="static-text">
                  {{ formData.dob }} <span>(age {{ formData.age }})</span>
                </div>
              </div>
              <div class="md-layout-item md-size-25 md-small-size-100">
                <label>{{ $t('entities/case/form/phone') }}</label>
                <div class="static-text">{{ formData.phone }}</div>
              </div>
              <div class="md-layout-item md-size-25 md-small-size-100">
                <label>{{ $t('entities/case/form/hospitalReference') }}</label>

                <div class="static-text">{{ formData.hospitalReference }}</div>
              </div>
              <div class="md-layout-item md-size-25 md-small-size-100">
                <label>{{ $t('entities/case/form/laboratoryReference') }}</label>
                <div class="static-text">{{ formData.laboratoryReference }}</div>
              </div>
            </div>
            <div class="md-layout lims-form-row">
              <div class="md-layout-item md-size-25 md-small-size-100">
                <label>{{ $t('entities/case/form/urgent') }}</label>
                <md-field class="md-static-field">
                  <md-input disabled :value="formData.urgent ? 'Yes' : 'No'" type="text"></md-input>
                </md-field>
              </div>
              <div class="md-layout-item md-size-25 md-small-size-100">
                <label>{{ $t('entities/case/form/isUnExpectedFindings') }}</label>
                <md-field class="md-static-field">
                  <md-input disabled :value="formData.isUnexpectedFindings ? 'Yes' : 'No'" type="text"></md-input>
                </md-field>
              </div>
              <div class="md-layout-item md-size-25 md-small-size-100">
                <label>{{ $t('entities/case/form/status') }}</label>
                <div class="static-text">{{ formData.statusName }}</div>
              </div>
              <div class="md-layout-item md-size-25 md-small-size-100">
                <label>{{ $t('entities/case/form/procedureDate') }}</label>
                <div class="static-text">{{ formData.procedureDate }}</div>
              </div>
            </div>
            <div class="md-layout lims-form-row">
              <div class="md-layout-item md-size-25 md-small-size-100">
                <label>{{ $t('entities/case/form/labEntryDate') }}</label>
                <div class="static-text">{{ formData.labEntryDate }}</div>
              </div>
              <div class="md-layout-item md-size-25 md-small-size-100">
                <label>{{ $t('entities/case/form/mdm') }}</label>
                <div class="static-text" v-if="formData.isSentMdmNotification">Yes</div>
                <div class="static-text" v-else>No</div>
              </div>
            </div>
            <div class="md-layout lims-form-row">
              <div class="md-layout-item md-size-100 md-small-size-100">
                <label>{{ $t('entities/case/form/specimenDetails') }}</label>
                <div class="static-text" v-lims-text-area="formData.specimenDetails"></div>
              </div>
            </div>
            <div class="md-layout lims-form-row">
              <div class="md-layout-item md-size-100 md-small-size-100">
                <label>{{ $t('entities/case/form/clinicalDetails') }}</label>
                <div class="static-text" v-lims-text-area="formData.clinicalDetails"></div>
              </div>
            </div>
          </div>
        </form>
      </template>
    </collapse>
  </ValidationObserver>
</template>
<script>
import { FormMixins } from '@/core/mixins';
import CaseMixins from '@/pages/Case/CaseManagement/Case.mixins';
import { Collapse } from '@/components';
import { getCaseFormSchema } from '@/schemas/case-form.schema';
import {
  formatDateWithoutTime,
  formatDateAndTime,
  fromISOToCurrentTimezone,
  convertDateTimeToUTCFormat,
} from '@/core/helpers';

export default {
  components: {
    Collapse,
  },

  mixins: [FormMixins, CaseMixins],

  props: {
    formMode: {
      type: Number,
      require: true,
    },
    dataEdit: {
      type: Object,
      require: false,
    },
  },

  data() {
    return {
      formData: {},
    };
  },

  created() {
    this.fetchData();
  },

  computed: {
    caseFormSchema: function () {
      return getCaseFormSchema(this.formMode);
    },
  },

  methods: {
    fetchData() {
      if (this.dataEdit) {
        let dob = this.dataEdit.dob;
        if (!this.dataEdit.anonymiseFields) {
          // transform if not anonymize and not null
          dob = dob ? formatDateWithoutTime(fromISOToCurrentTimezone(dob)) : null;
        }
        this.formData = {
          ...this.dataEdit,
          dob,
          procedureDate: this.dataEdit.procedureDate
            ? formatDateWithoutTime(fromISOToCurrentTimezone(this.dataEdit.procedureDate))
            : null,
          labEntryDate: this.dataEdit.labEntryDate
            ? formatDateAndTime(convertDateTimeToUTCFormat(this.dataEdit.labEntryDate))
            : null,
        };
      }
    },
  },
};
</script>
<style lang="scss">
.form-wrapper {
  width: 100%;
}
.age-patient {
  padding-top: 40px;
}
</style>
