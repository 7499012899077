import httpClient from './httpClient';

const RESOURCE = 'myaccount';

export default {
  changePassword(userId, currentPassword, newPassword) {
    const params = {};
    const userQueryParams = {};

    params['currentPassword'] = currentPassword;
    params['newPassword'] = newPassword;

    userQueryParams['userid'] = userId;

    return httpClient.post(`/${RESOURCE}/change-password`, params, userQueryParams);
  },

  getUserSettings() {
    return httpClient.get(`/${RESOURCE}/get-user-default-setting`, {});
  },

  getMyAccountSettings(userId) {
    return httpClient.get(`/${RESOURCE}/user-setting`, {
      userId: userId,
    });
  },

  updateMyAccountSettings(userId, pageSizeId, mfaMethodId, timezoneId, reportingStatusId) {
    return httpClient.post(
      `/${RESOURCE}/user-setting`,
      {
        userId: userId,
        pagesizeId: pageSizeId,
        mfaMethodId: mfaMethodId,
        timezoneId: timezoneId,
        reportingStatusId: reportingStatusId,
      },
      {
        userId: userId,
      },
    );
  },

  getSecretCodeToSetupTotp(userId) {
    return httpClient.get(`/${RESOURCE}/setup-totp`, {
      userId: userId,
    });
  },

  verifySetupTotp(userId, sid, code) {
    return httpClient.put(
      `/${RESOURCE}/verify-setup-totp`,
      {
        sid: sid,
        code: code,
      },
      {
        userId: userId,
      },
    );
  },

  checkTwoFaMethodIsAvailable(userId, mfaMethodId) {
    return httpClient.post(
      `/${RESOURCE}/check-mfa-method-available`,
      {},
      {
        userId: userId,
        mfaMethodId: mfaMethodId,
      },
    );
  },

  sendCodeUpdateEmail(userId, email) {
    return httpClient.patch(
      `/${RESOURCE}/send-code-update-email`,
      {
        email: email,
      },
      {
        userId: userId,
      },
    );
  },

  sendCodeUpdatePhone(userId, countryCode, phoneNumber) {
    return httpClient.patch(
      `/${RESOURCE}/send-code-update-phone`,
      {
        countryCode: countryCode,
        phoneNumber: phoneNumber,
      },
      {
        userId: userId,
      },
    );
  },

  verifyCodeUpdateEmail(userId, email, code) {
    return httpClient.patch(
      `/${RESOURCE}/verify-code-update-email`,
      {
        email: email,
        code: code,
      },
      {
        userId: userId,
      },
    );
  },

  verifyCodeUpdatePhone({ userId, countryCode, phoneNumber, code, locale, countryIdMobile }) {
    const payload = {
      countryCode: countryCode,
      phoneNumber: phoneNumber,
      code: code,
      localeMobile: locale,
      countryIdMobile,
    };
    const params = { userId };
    return httpClient.patch(`/${RESOURCE}/verify-code-update-phone`, payload, params);
  },

  getMyProfile(userId) {
    return httpClient.get(`/${RESOURCE}`, {
      userId: userId,
    });
  },

  findPreferredPathologist({ search, userId }) {
    const params = {};
    if (search) {
      params['search'] = search;
    }
    if (userId) {
      params['userId'] = userId;
    }
    return httpClient.get(`/${RESOURCE}/preferred-pathologist`, params);
  },

  findRestrictedPathologist({ search, userId }) {
    const params = {};
    if (search) {
      params['search'] = search;
    }
    if (userId) {
      params['userId'] = userId;
    }
    return httpClient.get(`/${RESOURCE}/conflict-pathologist`, params);
  },

  getListSpeciality() {
    return httpClient.get(`/${RESOURCE}/specialty`);
  },

  findAssociatedClinic({ entityIds, userId }) {
    const params = {};
    if (entityIds) {
      params['entityTypeIds'] = entityIds;
    }
    if (userId) {
      params['userId'] = userId;
    }
    return httpClient.get(`/${RESOURCE}/entities-by-entity-type`, params);
  },

  findAssociatedClinician({ search, userId }) {
    const params = {};
    if (search) {
      params['search'] = search;
    }
    if (userId) {
      params['userId'] = userId;
    }
    return httpClient.get(`/${RESOURCE}/associated-clinicians`, params);
  },

  findAvailableSpecimens() {
    // TODO: integrate with API later with filter
    return httpClient.get(`/${RESOURCE}/specimen`);
  },

  findSpecialties() {
    return httpClient.get(`/${RESOURCE}/specialty`);
  },

  findAvailableLaboratories(entityTypeIds, userId) {
    // TODO: integrate with API later with filter
    const params = {
      entityTypeIds,
      userId,
    };
    return httpClient.get(`/${RESOURCE}/entities-by-entity-type`, params);
  },

  updateProfile(payload) {
    const { userId, ...data } = payload;
    return httpClient.put(`/${RESOURCE}`, data, {
      userId: userId,
    });
  },
};
