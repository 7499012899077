<template>
  <ValidationObserver>
    <md-dialog
      :md-close-on-esc="true"
      :md-active.sync="isVisible"
      @md-clicked-outside="close"
      class="lims-modal document-review-modal"
    >
      <div class="dialog-icon-close" @click="close"><md-icon>close</md-icon></div>
      <div class="lims-form dialog-content">
        <div ref="swiper" class="swiper">
          <div class="swiper-wrapper">
            <!-- Slides -->
            <div v-for="(item, index) in pictureList" :key="index" class="swiper-slide">
              <div>
                <div class="picture-name">{{ item.title }}</div>
                <div class="picture-name"><img class="img" :src="item.src" /></div>
              </div>
            </div>
          </div>
          <!-- Navigation arrows -->
          <div class="swiper-button-prev"></div>
          <div class="swiper-button-next"></div>
          <div class="swiper-pagination"></div>
        </div>
      </div>
    </md-dialog>
  </ValidationObserver>
</template>

<script>
import modalMixins from './modal.mixins';
import Swiper, { Navigation, Pagination, Autoplay } from 'swiper';
import 'swiper/swiper-bundle.min.css';

export default {
  mixins: [modalMixins],
  data() {
    return {
      pictureList: [],
      activeIndex: 0,
      mySlide: null,
    };
  },
  watch: {
    isVisible() {
      if (!this.isVisible) {
        this.$emit('onHidePreviewPictures', false);
      }
    },
  },
  methods: {
    setPictures(pictures, index) {
      this.pictureList = pictures;
      setTimeout(() => {
        // init Swiper:
        this.mySlide = new Swiper(this.$refs.swiper, {
          // configure Swiper to use modules
          modules: [Navigation, Pagination, Autoplay],
          loop: true,
          pagination: {
            el: '.swiper-pagination',
            clickable: true,
          },
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
          // Events
          on: {
            slideChange: (swiper) => {
              this.activeIndex = swiper.realIndex;
            },
          },
        });
        this.mySlide.slideTo(index);
      }, 500);
    },
  },
};
</script>

<style lang="scss">
.lims-modal {
  &.document-review-modal {
    .md-dialog-container {
      width: 80% !important;
      height: calc(100vh - 70px);
      .dialog-content {
        overflow: auto;
      }
    }
    .swiper {
      max-width: 95%;
    }
    .swiper-slide {
      display: flex;
      justify-content: center;
      .picture-name {
        text-align: center;
        font-weight: bold;
        font-size: 20px;
        margin-bottom: 15px;
      }
      img {
        width: 100%;
      }
    }
    .swiper-pagination,
    .swiper-button-prev,
    .swiper-button-next {
      position: fixed;
    }
    .swiper-button-next {
      right: 20px;
    }
  }
}
</style>
