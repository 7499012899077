/* eslint-disable security/detect-object-injection */

import { APP_EVENTS } from '@/core/constants';
import Vue from 'vue';

// initial state
const state = () => ({
  events: {},
});

// getters
const getters = {
  [APP_EVENTS.EVT_ON_CREATED_DOCUMENT]: (state) => {
    return state.events ? state.events[APP_EVENTS.EVT_ON_CREATED_DOCUMENT] : null;
  },
  [APP_EVENTS.EVT_ON_CREATED_ALW_REQUEST]: (state) => {
    return state.events ? state.events[APP_EVENTS.EVT_ON_CREATED_ALW_REQUEST] : null;
  },
  [APP_EVENTS.EVT_ON_RELOAD_CASE_LIST]: (state) => {
    return state.events ? state.events[APP_EVENTS.EVT_ON_RELOAD_CASE_LIST] : null;
  },
  [APP_EVENTS.EVT_ON_SB_ADD_SLIDE]: (state) => {
    return state.events ? state.events[APP_EVENTS.EVT_ON_SB_ADD_SLIDE] : null;
  },
  [APP_EVENTS.EVT_ON_SB_ADD_PANEL]: (state) => {
    return state.events ? state.events[APP_EVENTS.EVT_ON_SB_ADD_PANEL] : null;
  },
  [APP_EVENTS.EVT_ON_CANCEL_EDIT_FORM]: (state) => {
    return state.events ? state.events[APP_EVENTS.EVT_ON_CANCEL_EDIT_FORM] : null;
  },
  [APP_EVENTS.EVT_ON_SB_ON_SEARCH]: (state) => {
    return state.events ? state.events[APP_EVENTS.EVT_ON_SB_ON_SEARCH] : null;
  },
  [APP_EVENTS.EVT_ON_SB_DESELECT_ALL]: (state) => {
    return state.events ? state.events[APP_EVENTS.EVT_ON_SB_DESELECT_ALL] : null;
  },
  [APP_EVENTS.EVT_ON_SB_ON_REFRESH]: (state) => {
    return state.events ? state.events[APP_EVENTS.EVT_ON_SB_ON_REFRESH] : null;
  },
  [APP_EVENTS.EVT_ON_RELOAD_SPECIMEN_TYPE_LIST]: (state) => {
    return state.events ? state.events[APP_EVENTS.EVT_ON_RELOAD_SPECIMEN_TYPE_LIST] : null;
  },
  [APP_EVENTS.EVT_ON_CASE_FORM_LABORATORY_CHANGED]: (state) => {
    return state.events ? state.events[APP_EVENTS.EVT_ON_CASE_FORM_LABORATORY_CHANGED] : null;
  },
  [APP_EVENTS.EVT_ON_ALW_FORM_ON_REFRESH]: (state) => {
    return state.events ? state.events[APP_EVENTS.EVT_ON_ALW_FORM_ON_REFRESH] : null;
  },
  [APP_EVENTS.EVT_ON_SLIDE_FORM_ON_REFRESH]: (state) => {
    return state.events ? state.events[APP_EVENTS.EVT_ON_SLIDE_FORM_ON_REFRESH] : null;
  },
  [APP_EVENTS.EVT_ON_CASE_RELOAD]: (state) => {
    return state.events ? state.events[APP_EVENTS.EVT_ON_CASE_RELOAD] : null;
  },
  [APP_EVENTS.EVT_ON_CASE_HIDE_COUNT_DOWN]: (state) => {
    return state.events ? state.events[APP_EVENTS.EVT_ON_CASE_HIDE_COUNT_DOWN] : null;
  },
  [APP_EVENTS.EVT_ON_USER_PATHOLOGIST_RELOAD]: (state) => {
    return state.events ? state.events[APP_EVENTS.EVT_ON_USER_PATHOLOGIST_RELOAD] : null;
  },
  [APP_EVENTS.EVT_NOTIFICATION_LIST_REFRESH]: (state) => {
    return state.events ? state.events[APP_EVENTS.EVT_NOTIFICATION_LIST_REFRESH] : null;
  },

  [APP_EVENTS.EVT_ON_SAVE_ALL_DOCUMENT]: (state) => {
    return state.events ? state.events[APP_EVENTS.EVT_ON_SAVE_ALL_DOCUMENT] : null;
  },
  [APP_EVENTS.EVT_ON_CHANGE_COMMON_USED_SPECIMEN_TYPES]: (state) => {
    return state.events ? state.events[APP_EVENTS.EVT_ON_CHANGE_COMMON_USED_SPECIMEN_TYPES] : null;
  },
  [APP_EVENTS.EVT_ON_LOAD_COMMON_USED_SPECIMEN_DATA]: (state) => {
    return state.events ? state.events[APP_EVENTS.EVT_ON_LOAD_COMMON_USED_SPECIMEN_DATA] : null;
  },
  [APP_EVENTS.EVT_ON_RELOAD_SPECIMEN_DATA]: (state) => {
    return state.events ? state.events[APP_EVENTS.EVT_ON_RELOAD_SPECIMEN_DATA] : null;
  },
  [APP_EVENTS.EVT_ON_CASE_FORM_NO_OF_SPECIMENCE_CHANGED]: (state) => {
    return state.events ? state.events[APP_EVENTS.EVT_ON_CASE_FORM_NO_OF_SPECIMENCE_CHANGED] : null;
  },
  [APP_EVENTS.EVT_ON_CASE_FORM_NO_OF_DIAGNOSIS_SPECIMENCE_CHANGED]: (state) => {
    return state.events ? state.events[APP_EVENTS.EVT_ON_CASE_FORM_NO_OF_DIAGNOSIS_SPECIMENCE_CHANGED] : null;
  },
  [APP_EVENTS.EVT_ON_SAVE_CLINIC_AND_LABORATORY_BLOCK]: (state) => {
    return state.events ? state.events[APP_EVENTS.EVT_ON_SAVE_CLINIC_AND_LABORATORY_BLOCK] : null;
  },
  [APP_EVENTS.EVT_INVALID_ON_DOCUMENT_TABLE]: (state) => {
    return state.events ? state.events[APP_EVENTS.EVT_INVALID_ON_DOCUMENT_TABLE] : null;
  },
  [APP_EVENTS.EVT_ON_TRIGGER_SAVE_CHANGES]: (state) => {
    return state.events ? state.events[APP_EVENTS.EVT_ON_TRIGGER_SAVE_CHANGES] : null;
  },
  [APP_EVENTS.EVT_ON_DISCARD_CHANGES]: (state) => {
    return state.events ? state.events[APP_EVENTS.EVT_ON_DISCARD_CHANGES] : null;
  },
  [APP_EVENTS.EVT_ON_SWITCHING_VIEW]: (state) => {
    return state.events ? state.events[APP_EVENTS.EVT_ON_SWITCHING_VIEW] : null;
  },
  [APP_EVENTS.EVT_ON_SAVE_PATHOLOGIST]: (state) => {
    return state.events ? state.events[APP_EVENTS.EVT_ON_SAVE_PATHOLOGIST] : null;
  },
  [APP_EVENTS.EVT_ON_CANCEL_SAVE_CHANGE_SPECIMEN_TYPE]: (state) => {
    return state.events ? state.events[APP_EVENTS.EVT_ON_CANCEL_SAVE_CHANGE_SPECIMEN_TYPE] : null;
  },
  [APP_EVENTS.EVT_ON_SAVE_SPECIMEN_DETAIL_BLOCK]: (state) => {
    return state.events ? state.events[APP_EVENTS.EVT_ON_SAVE_SPECIMEN_DETAIL_BLOCK] : null;
  },
  [APP_EVENTS.EVT_ON_ADVANCED_FILTER_CHANGED]: (state) => {
    return state.events ? state.events[APP_EVENTS.EVT_ON_ADVANCED_FILTER_CHANGED] : null;
  },
  [APP_EVENTS.EVT_ON_CHANGE_CUSTOM_STAINS]: (state) => {
    return state.events ? state.events[APP_EVENTS.EVT_ON_CHANGE_CUSTOM_STAINS] : null;
  },
  [APP_EVENTS.EVT_ON_FIRST_LOAD_ENTITY_SLIDE]: (state) => {
    return state.events ? state.events[APP_EVENTS.EVT_ON_FIRST_LOAD_ENTITY_SLIDE] : null;
  },
  [APP_EVENTS.EVT_ON_CLICK_VIRTUAL_SLIDE]: (state) => {
    return state.events ? state.events[APP_EVENTS.EVT_ON_CLICK_VIRTUAL_SLIDE] : null;
  },
  [APP_EVENTS.EVT_ON_AUTO_RETRIEVE_DS]: (state) => {
    return state.events ? state.events[APP_EVENTS.EVT_ON_AUTO_RETRIEVE_DS] : null;
  },
  [APP_EVENTS.EVT_ON_AUTO_RETRIEVE_COUNTDOWN]: (state) => {
    return state.events ? state.events[APP_EVENTS.EVT_ON_AUTO_RETRIEVE_COUNTDOWN] : null;
  },
  [APP_EVENTS.EVT_ON_SHOW_AND_HIDE_VIRTUAL_SLIDE]: (state) => {
    return state.events ? state.events[APP_EVENTS.EVT_ON_SHOW_AND_HIDE_VIRTUAL_SLIDE] : null;
  },
  [APP_EVENTS.EVT_ON_SCROLL_TO_BLOCK]: (state) => {
    return state.events ? state.events[APP_EVENTS.EVT_ON_SCROLL_TO_BLOCK] : null;
  },
  [APP_EVENTS.EVT_ON_REBUILD_ENTITY_SLIDE_IN_SPECIMEN]: (state) => {
    return state.events ? state.events[APP_EVENTS.EVT_ON_REBUILD_ENTITY_SLIDE_IN_SPECIMEN] : null;
  },
  [APP_EVENTS.EVT_ON_RELOAD_ALW]: (state) => {
    return state.events ? state.events[APP_EVENTS.EVT_ON_RELOAD_ALW] : null;
  },
  [APP_EVENTS.EVT_ON_RELOAD_SO]: (state) => {
    return state.events ? state.events[APP_EVENTS.EVT_ON_RELOAD_SO] : null;
  },
  [APP_EVENTS.EVT_ON_RELOAD_BILLING_AND_WORK_STREAM]: (state) => {
    return state.events ? state.events[APP_EVENTS.EVT_ON_RELOAD_BILLING_AND_WORK_STREAM] : null;
  },
  [APP_EVENTS.EVT_ON_CASE_FORM_CLINIC_ID_CHANGED]: (state) => {
    return state.events ? state.events[APP_EVENTS.EVT_ON_CASE_FORM_CLINIC_ID_CHANGED] : null;
  },
  [APP_EVENTS.EVT_ON_UPDATE_PATHOLOGIST]: (state) => {
    return state.events ? state.events[APP_EVENTS.EVT_ON_UPDATE_PATHOLOGIST] : null;
  },
  [APP_EVENTS.EVT_ON_RELOAD_DOCUMENT_VIEW]: (state) => {
    return state.events ? state.events[APP_EVENTS.EVT_ON_RELOAD_DOCUMENT_VIEW] : null;
  },
};

// actions
const actions = {
  addEvent({ commit }, event) {
    commit('addEvent', event);
  },
  removeEvent({ commit }, event) {
    commit('removeEvent', event);
  },
  clearEvents({ commit }) {
    commit('clearEvents');
  },
};

// mutations
const mutations = {
  addEvent: (state, event) => {
    Vue.set(state.events, event.key, event.payload);
  },
  removeEvent: (state, event) => {
    Vue.set(state.events, event.key, null);
  },
  clearEvents: (state) => {
    state.events = {};
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
