/* eslint-disable security/detect-object-injection */
import { nextDate } from '@/core/helpers';
import httpClient from './httpClient';

const RESOURCE = 'calendar';

export default {
  createHoliday(data = {}) {
    return httpClient.post(`/${RESOURCE}/holiday/add`, data);
  },
  updateHoliday(data = {}) {
    const { holidayId, ...values } = data;
    return httpClient.put(`/${RESOURCE}/holiday/${holidayId}`, values);
  },
  removeHoliday(data = {}) {
    const { holidayId, ...values } = data;
    return httpClient.post(`/${RESOURCE}/holiday/${holidayId}/remove`, values);
  },
  getNotificationCalendar() {
    return httpClient.get(`/${RESOURCE}/holiday/add`);
  },
  getCalendar() {
    return new Promise((resolve) => {
      httpClient
        .get(`/${RESOURCE}`)
        .then((res) => {
          const { error, data } = res;
          if (error) {
            resolve({ data: null, error });
          }
          const { holidayGroups } = data;

          const calendars = [];
          const events = [];
          let clinicIndex = 0;
          Object.keys(holidayGroups).map((c) => {
            let isMyCalendar = c === 'MyCalendar';
            let groupId = isMyCalendar ? 'myCalendar' : holidayGroups[c][0].clinicId;
            let groupName = isMyCalendar ? 'My Calendar' : holidayGroups[c][0].clinicName;
            calendars.push({ id: groupId, name: groupName });

            if (!isMyCalendar) {
              clinicIndex++;
            }

            holidayGroups[c].map((event) => {
              events.push({
                id: event.holidayId,
                title: `${event.firstname} ${event.lastname} - ${event.comment || ''}`,
                start: event.startDate,
                end: nextDate(event.endDate),
                allDay: true,
                groupId,
                className: isMyCalendar ? 'event event-group-0' : `event event-group-${clinicIndex}`,
                ...event,
              });
            });
          });

          resolve({
            data: {
              calendars,
              events,
            },
            error: null,
          });
        })
        .catch((error) => resolve({ data: null, error }));
    });
  },

  getHolidayOfCurrentUser(holidayId) {
    return httpClient.get(`/${RESOURCE}/holiday/${holidayId}`);
  },

  getHolidayOfClinic({ holidayId, clinicId }) {
    return httpClient.get(`/${RESOURCE}/holiday/${holidayId}/clinic/${clinicId}`);
  },
};
