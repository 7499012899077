import { directive as vClickOutside } from 'vue-clickaway';
import LongPress from 'vue-directive-long-press';
import { LimsTextAreaDirective } from './directives';
/**
 * You can register global directives here and use them as a plugin in your main Vue instance
 */

const GlobalDirectives = {
  install(Vue) {
    Vue.directive('click-outside', vClickOutside);
    Vue.directive('long-press', LongPress);
    Vue.directive('lims-text-area', LimsTextAreaDirective);
  },
};

export default GlobalDirectives;
