<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit }">
    <form class="md-layout lims-form" @submit.prevent="handleSubmit()" :key="localKey">
      <anchor-system :isProvisionallyReportedStatusPathView="true"></anchor-system>
      <!-- Begin patient data block -->
      <lims-block class="patient-data-block">
        <h4 class="title" slot="blockTitle">{{ $t('pages/case/form/patientData/blockTitle') }}</h4>
        <modal-concurrency-issue
          slot="blockTitle"
          ref="caseConcurrencyIssueModal"
          @onContinue="onConcurrencyIssueContinue"
        ></modal-concurrency-issue>
        <modal-authorisation-issue
          ref="authorisationIssueModal"
          slot="blockTitle"
          @onContinue="$onContinueAuthorise"
        ></modal-authorisation-issue>
        <modal-select-mode-authorise
          ref="selectModeAuthorise"
          slot="blockTitle"
          @onContinue="$onSelectedModeAuthorise"
        ></modal-select-mode-authorise>
        <modal-authorise-case
          ref="authoriseCaseModal"
          slot="blockTitle"
          @onAuthorise="$onAuthoriseCase"
        ></modal-authorise-case>
        <modal-recall-to-path
          slot="blockTitle"
          ref="reCallToPathModal"
          @onRecallToPath="onSubmitRecallToPath"
        ></modal-recall-to-path>
        <modal-reassign
          slot="blockTitle"
          ref="reassignModal"
          :dataCase="caseFormResource"
          @onReassigned="onReassigned"
        ></modal-reassign>
        <modal-supplementary-report-confirmation
          slot="blockTitle"
          ref="supplementaryReportConfirmationModal"
          :caseRef="caseFormResource.caseReference"
          @onConfirmYes="onConfirmSupReport"
          @onConfirmNo="onConfirmNoSupReport"
        ></modal-supplementary-report-confirmation>
        <lims-collapse
          slot="blockContent"
          :collapse="['Case Collapse']"
          icon="keyboard_arrow_down"
          :expand-collapse-block-name="'patientDataBlock'"
        >
          <patient-data-view
            slot="md-collapse-pane-1"
            v-model="formData.patientData"
            :form-mode="formMode"
            :dataEdit="caseFormResource"
          ></patient-data-view>
        </lims-collapse>
      </lims-block>
      <!-- End patient data block -->
      <!-- Begin document viewer and request view information -->
      <document-viewer-and-request-view-information
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
        :case-form-resource="caseFormResource"
        :form-mode="formMode"
        :is-bulk-diagnosis="isBulkDiagnosis"
        @changedShowDocumentViewer="isShowDocumentViewer = $event"
      ></document-viewer-and-request-view-information>
      <!-- End document viewer and request view information -->
      <!-- Begin billing clinic and laboratory block -->
      <lims-block id="billingClinicAndLaboratoryBlock" v-show="!isShowDocumentViewer">
        <h4 class="title" slot="blockTitle">{{ $t('pages/case/form/billingClinicAndLaboratory/blockTitle') }}</h4>
        <lims-collapse
          slot="blockContent"
          :collapse="['Case Collapse']"
          icon="keyboard_arrow_down"
          :expand-collapse-block-name="'billingClinicAndLaboratoryBlock'"
        >
          <div slot="md-collapse-pane-1">
            <clinic-and-laboratory-view
              v-model="formData.clinicAndLaboratory"
              :form-mode="formMode"
              :data-edit="caseFormResource"
            ></clinic-and-laboratory-view>
            <billing-data-view
              v-model="formData.billingData"
              :form-mode="formMode"
              :data-edit="caseFormResource"
            ></billing-data-view>
          </div>
        </lims-collapse>
      </lims-block>
      <!-- End billing clinic and laboratory block -->
      <!-- Begin specimen, pathologist and document block  -->
      <lims-block id="specimenPathologist" v-show="!isShowDocumentViewer">
        <h4 class="title" slot="blockTitle">{{ $t('pages/case/form/specimenPathologistAndDocument/blockTitle') }}</h4>
        <lims-collapse
          slot="blockContent"
          :collapse="['Case Collapse']"
          icon="keyboard_arrow_down"
          :expand-collapse-block-name="'specimenPathologist'"
        >
          <div slot="md-collapse-pane-1">
            <specimen-detail-view
              v-model="formData.specimenDetail"
              :form-mode="formMode"
              :dataEdit="caseFormResource"
            ></specimen-detail-view>
            <pathologist-view
              v-model="formData.pathologist"
              :form-mode="formMode"
              :dataEdit="caseFormResource"
            ></pathologist-view>
            <document :form-mode="formMode" :dataEdit="caseFormResource"></document>
          </div>
        </lims-collapse>
      </lims-block>
      <!-- End specimen, pathologist and document block -->
      <!-- Begin diagnosis histories block -->
      <lims-block
        id="diagnosisHistoryBlock"
        class="diagnosis-block"
        v-show="!isShowDocumentViewer"
        v-if="
          formMode !== ADD_MODE &&
          caseFormResource.caseReportHistories &&
          caseFormResource.caseReportHistories.length > 0
        "
      >
        <h4 class="title" slot="blockTitle">{{ $t('pages/case/form/diagnosis/blockTitle') }}</h4>
        <lims-collapse slot="blockContent" :collapse="['Case Collapse']" icon="keyboard_arrow_down">
          <diagnosis-histories
            slot="md-collapse-pane-1"
            v-model="formData.diagnosis"
            :data-edit="caseFormResource"
            :form-mode="formMode"
          ></diagnosis-histories>
        </lims-collapse>
      </lims-block>
      <!-- End diagnosis histories block -->
      <!-- Begin diagnosis view block -->
      <lims-block
        id="diagnosisViewBlock"
        v-show="!isShowDocumentViewer"
        class="diagnosis-block"
        v-if="formMode !== ADD_MODE"
      >
        <h4 class="title" slot="blockTitle">{{ $t('pages/case/form/diagnosis/blockTitle') }}</h4>
        <lims-collapse
          slot="blockContent"
          :collapse="['Case Collapse']"
          icon="keyboard_arrow_down"
          :expand-collapse-block-name="'diagnosisBlock'"
        >
          <diagnosis-view
            slot="md-collapse-pane-1"
            v-model="formData.diagnosis"
            :data-edit="caseFormResource"
            :form-mode="formMode"
            :is-provisionally-reported-status-and-path-view="true"
          ></diagnosis-view>
        </lims-collapse>
      </lims-block>
      <!-- End diagnosis view block -->
      <!-- ALW block  -->
      <lims-block id="ALWBlock" v-show="!isShowDocumentViewer">
        <h4 class="title" slot="blockTitle">
          {{ $t('pages/case/form/ALW/blockTitle') + ' (' + totalNumberOfRequestsALW + ')' }}
        </h4>
        <lims-collapse
          slot="blockContent"
          :collapse="['Case Collapse']"
          icon="keyboard_arrow_down"
          :expand-collapse-block-name="'ALWBlock'"
        >
          <div slot="md-collapse-pane-1">
            <additional-lab-work
              :form-mode="formMode"
              :dataEdit="caseFormResource"
              @totalNumberOfRequests="getTotalNumberOfRequestsALW"
            ></additional-lab-work>
          </div>
        </lims-collapse>
      </lims-block>
      <!-- SO block  -->
      <lims-block id="SOBlock" v-show="!isShowDocumentViewer">
        <h4 class="title" slot="blockTitle">
          {{ $t('pages/case/form/SO/blockTitle') + ' (' + totalNumberOfRequestsSO + ')' }}
        </h4>
        <lims-collapse
          slot="blockContent"
          :collapse="['Case Collapse']"
          icon="keyboard_arrow_down"
          :expand-collapse-block-name="'SOBlock'"
        >
          <div slot="md-collapse-pane-1">
            <second-opinion
              :form-mode="formMode"
              :dataEdit="caseFormResource"
              @totalNumberOfRequests="getTotalNumberOfRequestsSO"
            ></second-opinion>
          </div>
        </lims-collapse>
      </lims-block>
      <!-- Begin further options block -->
      <lims-block id="furtherOptionBlock" v-show="!isShowDocumentViewer">
        <h4 class="title" slot="blockTitle">{{ $t('pages/case/form/furtherOptions/blockTitle') }}</h4>
        <lims-collapse
          slot="blockContent"
          :collapse="['Case Collapse']"
          icon="keyboard_arrow_down"
          :expand-collapse-block-name="'furtherOptionBlock'"
        >
          <further-options
            slot="md-collapse-pane-1"
            v-model="formData.furtherOptions"
            :form-mode="formMode"
            :dataEdit="caseFormResource"
          ></further-options>
        </lims-collapse>
      </lims-block>
      <!-- End further options block -->
      <!-- Begin issue log block -->
      <lims-block id="issueLogBlock" v-show="!isShowDocumentViewer">
        <h4 class="title" slot="blockTitle">{{ $t('pages/case/form/issueLog/blockTitle') }}</h4>
        <lims-collapse
          slot="blockContent"
          :collapse="['Case Collapse']"
          icon="keyboard_arrow_down"
          :expand-collapse-block-name="'issueLogBlock'"
        >
          <issue-log slot="md-collapse-pane-1" :form-mode="formMode" :data-edit="caseFormResource"></issue-log>
        </lims-collapse>
      </lims-block>
      <!-- End issue log block -->
      <!-- Begin action form -->
      <div id="formButton" class="md-layout lims-form-row">
        <div class="md-layout-item md-size-100 md-small-size-100 lims-form-actions common-actions-form">
          <md-button @click="onBack()" class="lims-form-button">
            {{ $t('global/button/button.back') }}
          </md-button>
          <LimsFormEditButton v-if="formMode === VIEW_MODE && caseFormResource.isUpdatePermission" :editUrl="editUrl" />
          <template v-if="formMode !== VIEW_MODE && !isPathViewOnly">
            <md-button
              @click="onClickSaveAndAuthorizeButton($event)"
              :disabled="isProcessingOnSaveAndAuthorise || isProcessingSave"
              class="md-button md-primary lims-form-button md-theme-default"
            >
              {{ $t('global/button/button.saveAndAuthorise') }}
            </md-button>
            <md-button
              v-if="$isAuthorized(['Case_CaseReport_ExportPDF'])"
              @click="onExportAsPdf()"
              :disabled="isProcessingExportPdf"
              class="md-button md-primary lims-form-button md-theme-default"
            >
              {{ $t('global/button/button.exportAsPdf') }}
            </md-button>
            <md-button
              @click="onSaveCase()"
              :disabled="isProcessingSave || isProcessingOnSaveAndAuthorise"
              class="md-button md-primary lims-form-button md-theme-default"
            >
              {{ $t('global/button/button.save') }}
            </md-button>
            <md-button
              @click="onRecallToPath()"
              :disabled="autoRetrieveDSFailed_CancelDiagnosis"
              class="md-button md-danger lims-form-button md-theme-default"
            >
              {{ $t('global/button/button.recallToPath') }}
            </md-button>
            <md-button @click="onReassign()" class="md-button md-danger lims-form-button md-theme-default">
              {{ $t('global/button/button.reassign') }}
            </md-button>
          </template>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import { FormMixins, TabMixins } from '@/core/mixins';
import CaseEditMixins from '@/pages/Case/CaseManagement/Edit/caseEdit.mixins';
import { APP_ROUTES, FORM_MODES } from '@/core/constants';
import LimsBlock from '@/components/Lims/LimsBlock.vue';
import BillingDataView from '@/pages/Case/CaseManagement/Components/BillingDataView';
import ClinicAndLaboratoryView from '@/pages/Case/CaseManagement/Components/ClinicAndLaboratoryView';
import Document from '@/pages/Case/CaseManagement/Components/Document';
import AdditionalLabWork from '@/pages/Case/CaseManagement/Components/AdditionalLabWork.vue';
import SecondOpinion from '@/pages/Case/CaseManagement/Components/SecondOpinion.vue';
import FurtherOptions from '@/pages/Case/CaseManagement/Components/FurtherOptions';
import IssueLog from '@/pages/Case/CaseManagement/Components/IssueLog';
import LimsCollapse from '@/components/Lims/LimsCollapse';
import PatientDataView from '@/pages/Case/CaseManagement/Components/PatientDataView';
import SpecimenDetailView from '@/pages/Case/CaseManagement/Components/SpecimenDetailView';
import PathologistView from '@/pages/Case/CaseManagement/Components/PathologistView';
import DiagnosisView from '@/pages/Case/CaseManagement/Components/DiagnosisView';
import ModalSelectModeAuthorise from '@/components/Lims/modals/ModalSelectModeAuthorise';
import ModalAuthorisationIssue from '@/components/Lims/modals/ModalAuthorisationIssue';
import ModalAuthoriseCase from '@/components/Lims/modals/ModalAuthoriseCase';
import ModalRecallToPath from '@/components/Lims/modals/ModalRecallToPath';
import ModalReassign from '@/components/Lims/modals/ModalReassign.vue';
import caseSaveMixins, { CASE_VIEW_TYPES } from './caseSave.mixins';
import { mapActions, mapGetters } from 'vuex';
import DiagnosisHistories from '@/pages/Case/CaseManagement/Components/DiagnosisHistories';
import caseAuthorizeMixins from '@/pages/Case/CaseManagement/Edit/caseAuthorize.mixins';
import DocumentViewerAndRequestViewInformation from '@/pages/Case/CaseManagement/Components/DocumentViewerAndRequestViewInformation';
import ModalSupplementaryReportConfirmation from '@/components/Lims/modals/ModalSupplementaryReportConfirmation.vue';
import { cloneDeep } from 'lodash';

export default {
  mixins: [FormMixins, CaseEditMixins, TabMixins, caseSaveMixins, caseAuthorizeMixins],
  components: {
    DiagnosisHistories,
    ModalRecallToPath,
    ModalAuthoriseCase,
    ModalSelectModeAuthorise,
    ModalReassign,
    DiagnosisView,
    PathologistView,
    SpecimenDetailView,
    PatientDataView,
    LimsCollapse,
    BillingDataView,
    ClinicAndLaboratoryView,
    Document,
    AdditionalLabWork,
    SecondOpinion,
    FurtherOptions,
    IssueLog,
    LimsBlock,
    ModalAuthorisationIssue,
    DocumentViewerAndRequestViewInformation,
    ModalSupplementaryReportConfirmation,
  },
  props: {
    formMode: {
      type: Number,
      require: true,
      validator: function (value) {
        // The value must match one of these strings
        return Object.values(FORM_MODES).indexOf(value) !== -1;
      },
    },
    caseFormResource: {
      type: Object,
      require: false,
    },
    isBulkDiagnosis: {
      type: Boolean,
      require: false,
    },
  },
  data() {
    return {
      viewType: CASE_VIEW_TYPES.PROVISIONALREPORTED_STATUS_PATH,
      formData: {
        patientData: {},
        billingData: {},
        clinicAndLaboratory: {},
        specimenDetail: {},
        specimenNumber: null,
        furtherOptions: {},
        pathologist: {},
        diagnosis: {},
      },
      dataForm: {},
      isShowDocumentViewer: false,
      totalNumberOfRequestsALW: 0,
      totalNumberOfRequestsSO: 0,
      disabledModalAuthorized: false,
      authoriseMode: null,
      authoriseStatus: null,
      localKey: 0,
      haveAtLeastOneIssuePreventAuthorise: true,
      canBeUpdateTo: null,
      preventErrorMessage: {},
      isProcessingSaveAndAuthorize: false,
    };
  },
  computed: {
    ...mapGetters('caseData', ['caseData', '$caseSupplementaryReport']),
    editUrl() {
      return APP_ROUTES.CASE_EDIT + '/' + this.caseFormResource.caseId;
    },
    isPathViewOnly() {
      return !this.caseFormResource?.isUpdatePermission;
    },
  },
  watch: {
    caseFormResource() {
      this.localKey += 1;
    },
  },
  methods: {
    ...mapActions('app/event', ['addEvent', 'removeEvent']),
    getTotalNumberOfRequestsALW(val) {
      this.totalNumberOfRequestsALW = val;
    },
    getTotalNumberOfRequestsSO(val) {
      this.totalNumberOfRequestsSO = val;
    },
    onBack() {
      this.$router.back();
    },
    async onClickSaveAndAuthorizeButton($event) {
      this.isProcessingSaveAndAuthorize = true;
      const dataDiagnosisForm = this.$caseSupplementaryReport ? cloneDeep(this.$caseSupplementaryReport) : null;
      const res = await this.onSaveCase();
      this.isProcessingSaveAndAuthorize = false;
      if (res) {
        $event.preventDefault();
        if (
          !dataDiagnosisForm ||
          (!dataDiagnosisForm.fieldItems.length > 0 && !dataDiagnosisForm.microscopy && !dataDiagnosisForm.caseComment)
        ) {
          this.$refs.supplementaryReportConfirmationModal.open();
        } else {
          this.checkValidBeforeAuthorise(dataDiagnosisForm);
        }
      }
    },

    checkValidBeforeAuthorise(dataDiagnosisForm) {
      if (dataDiagnosisForm && dataDiagnosisForm.microscopy) {
        if (!(dataDiagnosisForm.fieldItems.length > 0) && !dataDiagnosisForm.caseComment) {
          this.$alertSuccess(this.$t('pages/case/form/authorise/missingsNomedAndComment'));
        }
        if (!(dataDiagnosisForm.fieldItems.length > 0) && dataDiagnosisForm.caseComment) {
          this.$alertSuccess(this.$t('pages/case/form/authorise/missingsNomed'));
        }
        if (dataDiagnosisForm.fieldItems.length > 0 && !dataDiagnosisForm.caseComment) {
          this.$alertSuccess(this.$t('pages/case/form/authorise/missingsComment'));
        }

        this.$onAuthorise(this.caseFormResource.caseId);
      } else {
        this.$alertError(this.$t('pages/case/form/cannotAuthorise/missingMicroscopy'));
      }
    },

    onConfirmSupReport() {
      this.$onAuthorise(this.caseFormResource.caseId);
    },

    onConfirmNoSupReport() {
      const element = document.getElementById('caseSupplementaryReport-input');
      let elementScroll;
      if (document.getElementById('slotTwo')) {
        const otherPosition = document.getElementById('slotTwo').getBoundingClientRect().top + window.scrollY;
        elementScroll = document.getElementById('slotTwo');
        window.scrollTo({ top: otherPosition - 140, behavior: 'smooth' });
      } else {
        elementScroll = window;
      }
      setTimeout(() => {
        const position =
          element.getBoundingClientRect().top +
          (elementScroll.scrollY !== undefined ? elementScroll.scrollY : elementScroll.scrollTop - 80);
        elementScroll.scrollTo({ top: position - 70, behavior: 'smooth' });
      }, 500);
    },
    onReassign() {
      this.$refs.reassignModal.open();
    },
    onReassigned() {
      this.$refs.reassignModal.close();
      if (this.isBulkDiagnosis) {
        this.$emit('updatedCaseInDiagnosisView', this.caseFormResource?.caseId);
      } else {
        setTimeout(async () => {
          await this.$router.push(APP_ROUTES.CASE);
        }, 2000);
      }
    },
  },
};
</script>
<style lang="scss"></style>
