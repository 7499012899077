<template>
  <md-dialog :md-close-on-esc="true" :md-active.sync="isVisible" @md-clicked-outside="close" class="lims-modal">
    <div class="dialog-icon-close" @click="close"><md-icon>close</md-icon></div>
    <md-dialog-title>{{ $t('components/lims/modals/ModalSupplementaryReportConfirmation/title') }}</md-dialog-title>
    <div class="lims-form dialog-content">
      <p
        class="text-center"
        v-html="
          $t('components/lims/modals/ModalSupplementaryReportConfirmation/description', {
            caseRef: caseRef,
          })
        "
      ></p>
    </div>
    <md-dialog-actions>
      <md-button @click="onConfirmNo" class="lims-form-button md-danger">{{
        $t('components/lims/modals/ModalSupplementaryReportConfirmation/no')
      }}</md-button>
      <md-button @click="onConfirmYes" class="lims-form-button md-primary">{{
        $t('components/lims/modals/ModalSupplementaryReportConfirmation/yes')
      }}</md-button>
    </md-dialog-actions>
  </md-dialog>
</template>

<script>
import modalMixins from '@/components/Lims/modals/modal.mixins';

export default {
  name: 'ModalSupplementaryReportConfirmation',
  mixins: [modalMixins],
  props: {
    caseRef: {
      type: String,
      require: true,
    },
  },
  methods: {
    onConfirmYes() {
      this.close();
      this.$emit('onConfirmYes');
    },
    onConfirmNo() {
      this.close();
      this.$emit('onConfirmNo');
    },
  },
};
</script>

<style lang="scss"></style>
