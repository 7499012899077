import { getDefaultFilterValues, getFilterFieldTypes } from '@/query/utils';
import { FIELD_TYPES } from '@/query/constant';
const fields = [
  {
    key: 'AddressTypeId',
    listKey: 'AddressTypeId',
    sort: 1,
    fieldType: FIELD_TYPES.ArrayInt,
    translated: true,
    defaultValue: [],
  },
  {
    key: 'CaseReference',
    listKey: 'CaseReference',
    sort: 2,
    fieldType: FIELD_TYPES.String,
    translated: true,
    defaultValue: '',
  },
  {
    key: 'CaseStatusIds',
    listKey: 'CaseStatusIds',
    sort: 3,
    fieldType: FIELD_TYPES.ArrayInt,
    translated: true,
    defaultValue: [],
  },
  {
    key: 'DoB',
    listKey: null,
    sort: 4,
    fieldType: FIELD_TYPES.Date,
    translated: false,
    defaultValue: null,
  },
  {
    key: 'HospitalReference',
    listKey: null,
    sort: 5,
    fieldType: FIELD_TYPES.String,
    translated: false,
    defaultValue: '',
  },
  {
    key: 'PatientNhsNumber',
    listKey: null,
    sort: 6,
    fieldType: FIELD_TYPES.String,
    translated: false,
    defaultValue: '',
  },
  { key: 'IsUrgent', listKey: null, sort: 7, fieldType: FIELD_TYPES.Boolean, translated: false, defaultValue: null },
  { key: 'LabReference', listKey: null, sort: 8, fieldType: FIELD_TYPES.String, translated: false, defaultValue: '' },
  {
    key: 'PatientFirstName',
    listKey: null,
    sort: 9,
    fieldType: FIELD_TYPES.String,
    translated: false,
    defaultValue: '',
  },
  {
    key: 'PatientLastName',
    listKey: null,
    sort: 10,
    fieldType: FIELD_TYPES.String,
    translated: false,
    defaultValue: '',
  },
  {
    key: 'SnomedM',
    listKey: 'SnomedM',
    sort: 11,
    fieldType: FIELD_TYPES.ArrayInt,
    translated: true,
    defaultValue: [],
  },
  {
    key: 'SnomedP',
    listKey: 'SnomedP',
    sort: 12,
    fieldType: FIELD_TYPES.ArrayInt,
    translated: true,
    defaultValue: [],
  },
  {
    key: 'SnomedT',
    listKey: 'SnomedT',
    sort: 13,
    fieldType: FIELD_TYPES.ArrayInt,
    translated: true,
    defaultValue: [],
  },
  {
    key: 'SpecialtyIds',
    listKey: 'SpecialtyIds',
    sort: 14,
    fieldType: FIELD_TYPES.ArrayInt,
    translated: true,
    defaultValue: [],
  },
  {
    key: 'SpecimenTypeIds',
    listKey: 'SpecimenTypeIds',
    sort: 15,
    fieldType: FIELD_TYPES.ArrayString,
    translated: true,
    defaultValue: [],
  },
  {
    key: 'ClinicIds',
    listKey: 'ClinicIds',
    sort: 16,
    fieldType: FIELD_TYPES.ArrayString,
    translated: true,
    defaultValue: [],
  },
  {
    key: 'ClinicTypeIds',
    listKey: 'ClinicTypeIds',
    sort: 17,
    fieldType: FIELD_TYPES.ArrayInt,
    translated: true,
    defaultValue: [],
  },
  {
    key: 'ClinicianIds',
    listKey: 'ClinicianIds',
    sort: 18,
    fieldType: FIELD_TYPES.ArrayString,
    translated: true,
    defaultValue: [],
  },
  {
    key: 'LaboratoryIds',
    listKey: 'LaboratoryIds',
    sort: 19,
    fieldType: FIELD_TYPES.ArrayString,
    translated: true,
    defaultValue: [],
  },
  {
    key: 'PathologistIds',
    listKey: 'PathologistIds',
    sort: 20,
    fieldType: FIELD_TYPES.ArrayString,
    translated: true,
    defaultValue: [],
  },
  {
    key: 'QualityControlIds',
    listKey: 'QualityControlIds',
    sort: 21,
    fieldType: FIELD_TYPES.ArrayString,
    translated: true,
    defaultValue: [],
  },
  {
    key: 'Technician1Ids',
    listKey: 'Technician1Ids',
    sort: 22,
    fieldType: FIELD_TYPES.ArrayString,
    translated: true,
    defaultValue: [],
  },
  {
    key: 'Technician2Ids',
    listKey: 'Technician2Ids',
    sort: 23,
    fieldType: FIELD_TYPES.ArrayString,
    translated: true,
    defaultValue: [],
  },
  {
    key: 'LabEntryDate',
    listKey: null,
    sort: 24,
    fieldType: FIELD_TYPES.ArrayDate,
    translated: false,
    defaultValue: null,
  },
  {
    key: 'ReportedDate',
    listKey: null,
    sort: 25,
    fieldType: FIELD_TYPES.ArrayDate,
    translated: false,
    defaultValue: null,
  },
  {
    key: 'DoB',
    listKey: null,
    sort: 25,
    fieldType: FIELD_TYPES.Date,
    translated: false,
    defaultValue: null,
  },
  {
    key: 'LabSlideDate',
    listKey: null,
    sort: 26,
    fieldType: FIELD_TYPES.ArrayDate,
    translated: false,
    defaultValue: null,
  },
  {
    key: 'TatNumber',
    listKey: null,
    sort: 27,
    fieldType: FIELD_TYPES.Object,
    translated: false,
    defaultValue: null,
  },
  {
    key: 'TatWoWeNumber',
    listKey: null,
    sort: 28,
    fieldType: FIELD_TYPES.Object,
    translated: false,
    defaultValue: null,
  },
  {
    key: 'WithPath',
    listKey: null,
    sort: 29,
    fieldType: FIELD_TYPES.ArrayDate,
    translated: false,
    defaultValue: null,
  },
  {
    key: 'NumOfBlock',
    listKey: null,
    sort: 30,
    fieldType: FIELD_TYPES.Number,
    translated: false,
    defaultValue: null,
  },
  {
    key: 'CaseIssues',
    listKey: 'CaseIssues',
    sort: 31,
    fieldType: FIELD_TYPES.ArrayInt,
    translated: true,
    defaultValue: [],
  },
  {
    key: 'ClinicalIncident',
    listKey: null,
    sort: 32,
    fieldType: FIELD_TYPES.Boolean,
    translated: false,
    defaultValue: null,
  },
  {
    key: 'DoBNotProvided',
    listKey: null,
    sort: 33,
    fieldType: FIELD_TYPES.Boolean,
    translated: false,
    defaultValue: null,
  },
  {
    key: 'DocumentTypeIds',
    listKey: 'DocumentTypeIds',
    sort: 34,
    fieldType: FIELD_TYPES.ArrayInt,
    translated: true,
    defaultValue: [],
  },
  {
    key: 'NumOfDocument',
    listKey: null,
    sort: 35,
    fieldType: FIELD_TYPES.Number,
    translated: false,
    defaultValue: null,
  },
  { key: 'HasALW', listKey: null, sort: 36, fieldType: FIELD_TYPES.Boolean, translated: false, defaultValue: null },
  { key: 'HasMDM', listKey: null, sort: 37, fieldType: FIELD_TYPES.Boolean, translated: false, defaultValue: null },
  {
    key: 'HasSecondOpinion',
    listKey: null,
    sort: 38,
    fieldType: FIELD_TYPES.Boolean,
    translated: false,
    defaultValue: null,
  },
  {
    key: 'IsCaseOfInterest',
    listKey: null,
    sort: 39,
    fieldType: FIELD_TYPES.Boolean,
    translated: false,
    defaultValue: null,
  },
  { key: 'IsDeleted', listKey: null, sort: 40, fieldType: FIELD_TYPES.Boolean, translated: false, defaultValue: null },
  {
    key: 'IsImported',
    listKey: null,
    sort: 41,
    fieldType: FIELD_TYPES.Boolean,
    translated: false,
    defaultValue: null,
  },
  { key: 'IsRead', listKey: null, sort: 42, fieldType: FIELD_TYPES.Boolean, translated: false, defaultValue: null },
  { key: 'IsSoCase', listKey: null, sort: 43, fieldType: FIELD_TYPES.Boolean, translated: false, defaultValue: null },
  {
    key: 'CaseIssueStatusIds',
    listKey: 'CaseIssueStatusIds',
    sort: 44,
    fieldType: FIELD_TYPES.ArrayInt,
    translated: true,
    defaultValue: [],
  },
  {
    key: 'IssueTypeIds',
    listKey: 'IssueTypeIds',
    sort: 45,
    fieldType: FIELD_TYPES.ArrayInt,
    translated: true,
    defaultValue: [],
  },
  {
    key: 'IsDoubleReportCase',
    listKey: null,
    sort: 46,
    fieldType: FIELD_TYPES.Boolean,
    translated: false,
    defaultValue: null,
  },
  {
    key: 'TissueRemaining',
    listKey: null,
    sort: 47,
    fieldType: FIELD_TYPES.Boolean,
    translated: false,
    defaultValue: null,
  },
  {
    key: 'TissueDiscarded',
    listKey: null,
    sort: 48,
    fieldType: FIELD_TYPES.Boolean,
    translated: false,
    defaultValue: null,
  },
  {
    key: 'NoUpdateAfterRetrieved',
    listKey: null,
    sort: 49,
    fieldType: FIELD_TYPES.Boolean,
    translated: false,
    defaultValue: null,
  },
  {
    key: 'FromSourceIds',
    listKey: 'FromSourceIds',
    sort: 50,
    fieldType: FIELD_TYPES.ArrayInt,
    translated: true,
    defaultValue: [],
  },
  {
    key: 'NumOfOriginalSlides',
    listKey: null,
    sort: 51,
    fieldType: FIELD_TYPES.Number,
    translated: false,
    defaultValue: null,
  },
  {
    key: 'NumOfALWSlides',
    listKey: null,
    sort: 52,
    fieldType: FIELD_TYPES.Number,
    translated: false,
    defaultValue: null,
  },
  {
    key: 'NumOfSpecimens',
    listKey: null,
    sort: 53,
    fieldType: FIELD_TYPES.Number,
    translated: false,
    defaultValue: null,
  },
  {
    key: 'BillingEntityIds',
    listKey: 'BillingEntityIds',
    sort: 54,
    fieldType: FIELD_TYPES.ArrayInt,
    translated: true,
    defaultValue: [],
  },
  {
    key: 'IsExportedBilling',
    listKey: null,
    sort: 55,
    fieldType: FIELD_TYPES.Boolean,
    translated: false,
    defaultValue: null,
  },
  {
    key: 'PmiIds',
    listKey: 'PmiIds',
    sort: 56,
    fieldType: FIELD_TYPES.ArrayInt,
    translated: true,
    defaultValue: [],
  },
  {
    key: 'IsSearchAllCase',
    listKey: null,
    sort: 57,
    fieldType: FIELD_TYPES.Boolean,
    translated: false,
    defaultValue: null,
  },
  {
    key: 'IsIncludedReportedCase',
    listKey: null,
    sort: 58,
    fieldType: FIELD_TYPES.Boolean,
    translated: false,
    defaultValue: null,
  },
  { key: 'HasRF', listKey: null, sort: 59, fieldType: FIELD_TYPES.Boolean, translated: false, defaultValue: null },
  {
    key: 'DRResponseStatusIds',
    listKey: 'DRResponseStatusIds',
    sort: 60,
    fieldType: FIELD_TYPES.ArrayInt,
    translated: true,
    defaultValue: [],
  },
  {
    key: 'DRPartnerIds',
    listKey: 'DRPartnerIds',
    sort: 61,
    fieldType: FIELD_TYPES.ArrayString,
    translated: true,
    defaultValue: [],
  },
  {
    key: 'SoRequestStatus',
    listKey: 'SoRequestStatus',
    sort: 62,
    fieldType: FIELD_TYPES.ArrayInt,
    translated: true,
    defaultValue: [],
  },
  {
    key: 'AlwRequestStatus',
    listKey: 'AlwRequestStatus',
    sort: 63,
    fieldType: FIELD_TYPES.ArrayInt,
    translated: true,
    defaultValue: [],
  },
  {
    key: 'AlwRequestBatchStatusIds',
    listKey: 'AlwRequestBatchStatusIds',
    sort: 64,
    fieldType: FIELD_TYPES.ArrayInt,
    translated: true,
    defaultValue: [],
  },
  {
    key: 'DashboardRedirectEnum',
    listKey: null,
    sort: 65,
    fieldType: FIELD_TYPES.Int,
    translated: true,
    defaultValue: null,
  },
  {
    key: 'AlwRespondedDate',
    listKey: null,
    sort: 66,
    fieldType: FIELD_TYPES.ArrayDate,
    translated: true,
    defaultValue: null,
  },
  {
    key: 'CaseImportBatchId',
    listKey: 'CaseImportBatchId',
    sort: 67,
    fieldType: FIELD_TYPES.ArrayString,
    translated: true,
    defaultValue: [],
  },
  {
    key: 'SentToPathDate',
    listKey: null,
    sort: 68,
    fieldType: FIELD_TYPES.ArrayDate,
    translated: false,
    defaultValue: null,
  },
  {
    key: 'ReturnToLabDate',
    listKey: null,
    sort: 69,
    fieldType: FIELD_TYPES.ArrayDate,
    translated: false,
    defaultValue: null,
  },
  {
    key: 'SlideReturnToClinicDate',
    listKey: null,
    sort: 70,
    fieldType: FIELD_TYPES.Date,
    translated: false,
    defaultValue: null,
  },
  {
    key: 'WorkStreamIds',
    listKey: 'WorkStreamIds',
    sort: 71,
    fieldType: FIELD_TYPES.ArrayInt,
    translated: true,
    defaultValue: [],
  },
  {
    key: 'AdvancedCaseRef',
    listKey: 'AdvancedCaseRef',
    sort: 72,
    fieldType: FIELD_TYPES.String,
    translated: true,
    defaultValue: '',
  },
  { key: 'search', listKey: null, sort: 73, fieldType: FIELD_TYPES.String, translated: false, defaultValue: '' },
  {
    key: 'HasVirtualSlides',
    listKey: null,
    sort: 73,
    fieldType: FIELD_TYPES.Boolean,
    translated: false,
    defaultValue: null,
  },
  {
    key: 'HasUnexpectedFindings',
    listKey: null,
    sort: 74,
    fieldType: FIELD_TYPES.Boolean,
    translated: false,
    defaultValue: null,
  },
  {
    key: 'HasSlides',
    listKey: null,
    sort: 75,
    fieldType: FIELD_TYPES.Boolean,
    translated: false,
    defaultValue: null,
  },
  {
    key: 'UnknowBillingEntity',
    listKey: null,
    sort: 76,
    fieldType: FIELD_TYPES.Boolean,
    translated: false,
    defaultValue: null,
  },
  {
    key: 'IncompleteInsuranceDetails',
    listKey: null,
    sort: 77,
    fieldType: FIELD_TYPES.Boolean,
    translated: false,
    defaultValue: null,
  },
  {
    key: 'IncompleteAddressDetails',
    listKey: null,
    sort: 78,
    fieldType: FIELD_TYPES.Boolean,
    translated: false,
    defaultValue: null,
  },
  {
    key: 'HasDraftDiagnosis',
    listKey: null,
    sort: 79,
    fieldType: FIELD_TYPES.Boolean,
    translated: false,
    defaultValue: null,
  },
  { key: 'IsPullCase', listKey: null, sort: 80, fieldType: FIELD_TYPES.Boolean, translated: false, defaultValue: null },
  {
    key: 'IssueRespondedAt',
    listKey: null,
    sort: 81,
    fieldType: FIELD_TYPES.ArrayDate,
    translated: false,
    defaultValue: null,
  },
  {
    key: 'ProcedureDate',
    listKey: null,
    sort: 82,
    fieldType: FIELD_TYPES.ArrayDate,
    translated: false,
    defaultValue: null,
  },
  {
    key: 'ExportedForBillingDate',
    listKey: null,
    sort: 83,
    fieldType: FIELD_TYPES.ArrayDate,
    translated: true,
    defaultValue: null,
  },
  {
    key: 'HasMDMNotificationSent',
    listKey: null,
    sort: 84,
    fieldType: FIELD_TYPES.Boolean,
    translated: true,
    defaultValue: null,
  },
  { key: 'HasComment', listKey: null, sort: 85, fieldType: FIELD_TYPES.Boolean, translated: false, defaultValue: null },
];
const defaultValues = getDefaultFilterValues(fields);
const fieldTypes = getFilterFieldTypes(fields);
export default {
  fields,
  defaultValues,
  fieldTypes,
};
