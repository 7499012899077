// Layouts
const DashboardLayout = () => import('@/layouts/DashboardLayout.vue');

// Data pages
import Dropdown from '@/pages/Data/Dropdown/Dropdown';
import ItemList from '@/pages/Data/Dropdown/ItemList/Item';
import DropdownItemFormAdd from '@/pages/Data/Dropdown/Add/DropdownItemAdd';
import DropdownItemFormEdit from '@/pages/Data/Dropdown/Edit/DropdownItemEdit';

import guards from './guards';
import { FORM_MODES } from '@/core/constants';

export default {
  path: '/dropdown',
  component: DashboardLayout,
  children: [
    {
      path: '',
      name: 'DropdownManagementFieldList',
      component: Dropdown,
      beforeEnter: guards.checkAuthorizedGuard,
      meta: {
        zone: ['Data_DropdownManagement_Retrieve'],
      },
    },
    {
      path: ':dropdownFieldId',
      name: 'DropdownManagementItemList',
      component: ItemList,
      props: true,
      beforeEnter: guards.checkAuthorizedGuard,
      meta: {
        zone: ['Data_DropdownManagement_Retrieve'],
      },
    },
    {
      path: ':dropdownFieldId/add',
      name: 'DropdownManagementAdd',
      component: DropdownItemFormAdd,
      props: (route) => ({
        formMode: FORM_MODES.ADD,
        dropdownFieldId: route.params.dropdownFieldId,
      }),
      beforeEnter: guards.checkAuthorizedGuard,
      meta: {
        zone: ['Data_DropdownManagement_Create'],
      },
    },
    {
      path: ':dropdownFieldId/edit/:dropdownItemId',
      name: 'DropdownManagementEdit',
      component: DropdownItemFormEdit,
      props: (route) => ({
        formMode: FORM_MODES.EDIT,
        dropdownFieldId: route.params.dropdownFieldId,
        dropdownItemId: route.params.dropdownItemId,
      }),
      beforeEnter: guards.checkAuthorizedGuard,
      meta: {
        zone: ['Data_DropdownManagement_Update'],
      },
    },
    {
      path: ':dropdownFieldId/view/:dropdownItemId',
      name: 'DropdownManagementView',
      component: DropdownItemFormEdit,
      props: (route) => ({
        formMode: FORM_MODES.VIEW,
        dropdownFieldId: route.params.dropdownFieldId,
        dropdownItemId: route.params.dropdownItemId,
      }),
      beforeEnter: guards.checkAuthorizedGuard,
      meta: {
        zone: ['Data_DropdownManagement_Retrieve'],
      },
    },
  ],
};
