<template>
  <ValidationObserver ref="simpleBookingForm" v-slot="{ handleSubmit }">
    <modal-confirm-leave-simple-booking-form
      ref="confirmLeaveSimpleBookingFormModal"
      @onConfirm="onConfirmLeaveSimpleBookingForm"
    />
    <form class="md-layout lims-form" @submit.prevent="handleSubmit()">
      <lims-block>
        <h4 class="title" slot="blockTitle">{{ $t('pages/case/SimpleBooking/caseData/blockTitle') }}</h4>
        <div slot="blockContent">
          <case-data
            v-model="listCaseCreateSuccess"
            :disabledCaseData="disabledCaseData"
            :dataCommonForm="dataCommonForm"
            :listDropdown="listDropdown"
            :isCallEndBooking="isCallEndBooking"
            @endBooking="endBooking"
          ></case-data>
        </div>
      </lims-block>
      <lims-block>
        <h4 class="title" slot="blockTitle">
          {{ $t('pages/case/SimpleBooking/commonData/blockTitle') }}
        </h4>
        <div slot="blockContent">
          <common-data
            ref="commonData"
            v-model="commonData"
            :disabledCommonData="disabledCommonData"
            @listData="listData"
            @startBooking="startBooking"
          ></common-data>
        </div>
      </lims-block>
    </form>
  </ValidationObserver>
</template>

<script>
import { FormMixins } from '@/core/mixins';
import caseData from '@/pages/Case/SimpleBooking/components/CaseData.vue';
import commonData from '@/pages/Case/SimpleBooking/components/CommonData.vue';
import ModalConfirmLeaveSimpleBookingForm from '@/components/Lims/modals/ModalConfirmLeaveSimpleBookingForm';

export default {
  mixins: [FormMixins],
  components: {
    ModalConfirmLeaveSimpleBookingForm,
    caseData,
    commonData,
  },
  mounted() {
    setTimeout(() => {
      window.scrollTo({ top: 900, behavior: 'smooth' });
    }, 0);
  },
  data() {
    return {
      listCaseCreateSuccess: [],
      commonData: {},
      dataCommonForm: {},
      isCallEndBooking: false,
      listDropdown: {},
      toUrl: {},
      isAllowedToLeave: true,

      disabledCaseData: true,
      disabledCommonData: false,
    };
  },
  watch: {
    listCaseCreateSuccess: {
      deep: true,
      handler: function (caseIds) {
        if (caseIds && caseIds.length > 0) {
          this.isAllowedToLeave = false;
        } else {
          this.isAllowedToLeave = true;
        }
      },
    },
  },
  methods: {
    startBooking() {
      this.disabledCaseData = false;
      this.disabledCommonData = true;
      this.dataCommonForm = { ...this.commonData };
    },
    endBooking() {
      this.disabledCaseData = true;
      this.disabledCommonData = false;
    },
    listData(val) {
      this.listDropdown = { ...val };
    },
    onConfirmLeaveSimpleBookingForm() {
      this.isCallEndBooking = true;
      this.isAllowedToLeave = true;
      setTimeout(() => {
        this.$router.push(this.toUrl);
      }, 500);
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.isAllowedToLeave) {
      next(true);
    } else {
      this.toUrl = to;
      this.$refs.confirmLeaveSimpleBookingFormModal.open();
      next(false);
    }
  },
};
</script>

<style lang="scss">
.simpleBooking-txt {
  margin-top: 0;
}
</style>
